import React from 'react';
import { capitalize } from '../../../../shared/utils/utils';
import { paletteColors } from '../../../utils/config';
import { ColorIndicator } from '../colorPicker';
import { NO_KEYNAV } from '../listView';
import { SimplifiedPicker } from '../picker';

export function ColorListPicker({
  state,
  onDone,
  onPicked,
  filterClassName,
  filterPlaceholder = 'Choose a color',
}: {
  state: string[];
  onPicked: (color: string) => void;
  onDone: () => void;
  filterClassName?: string;
  filterPlaceholder?: string;
}) {
  const items = paletteColors.map(color => ({
    id: color,
    name: color,
    contents: capitalize(color),
    icon: <ColorIndicator color={color} />,
  }));

  return (
    <SimplifiedPicker
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder}
      state={state}
      propertiesToSearch={['name']}
      filter={(filterString, search) => {
        const results = search.search(filterString).map(r => r.item);

        const isValid = /^#[0-9A-F]{6}$/i.test(filterString);
        if (isValid) {
          results.push({
            id: filterString,
            name: filterString,
            contents: `Use custom color #${filterString}`,
            icon: <ColorIndicator color={filterString} />,
            onSelected: () => onPicked(filterString.replace('#', '')),
          });
        } else {
          results.push({
            id: NO_KEYNAV,
            key: 'sep-4',
            contents: () => (
              <div className={'grayed'}>
                Custom colors can be used by typing #RRGGBB (e.g FF00FF)
              </div>
            ),
          });
        }

        return results;
      }}
      onAdd={color => {
        onPicked(color);
      }}
      onRemove={color => {
        onPicked(color);
      }}
      onDone={onDone}
      items={items}
    />
  );
}
