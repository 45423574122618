import { groupBy } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Folder } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { foldersForOrganizationSelector } from '../../../syncEngine/selectors/folders';
import { FilteredListView } from '../filteredListView';
import { KeyNavigationProvider } from '../keyNavigation';
import { LISTVIEW_ID, ListViewItem } from '../listView';
import menuStyles from '../menu/menu.module.scss';

export function FolderPicker({
  onPicked,
  omitFolders,
  filterClassName,
}: {
  onPicked: (folderId: string | null) => void;
  omitFolders?: string[];
  filterClassName?: string;
}) {
  const organization = useOrganization();
  const allFolders = useRecoilValue(foldersForOrganizationSelector(organization.id));
  const folderItems: ListViewItem[] = React.useMemo(() => {
    const folders = allFolders.map(folder => ({
      ...folder,
      parentId: folder.parentId || '',
    }));
    const foldersByParent = groupBy(folders, 'parentId');

    const processFolder = (folder: Folder, depth: number) => {
      if (omitFolders?.includes(folder.id)) {
        return [];
      }
      const res: ListViewItem[] = [
        {
          id: folder.id,
          contents: () => <div style={{ paddingLeft: depth * 8 }}>{folder.name}</div>,
          onSelected: () => {
            onPicked(folder.id);
          },
        },
      ];
      res.push(
        ...(foldersByParent[folder.id] || []).flatMap((child: Folder) =>
          processFolder(child, depth + 1)
        )
      );
      return res;
    };

    const results = (foldersByParent[''] || []).flatMap(folder => processFolder(folder, 1));

    results.unshift({
      id: 'documents',
      contents: () => <>Documents</>,
      onSelected: () => {
        onPicked(null);
      },
    });
    return results;
  }, [allFolders, onPicked]);

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <div className="colStretch fullWidth">
        <FilteredListView
          filterLabel="Move to folder"
          filterPlaceholder="Search folders"
          placeholder="No folders found"
          items={folderItems}
          selectFirstOnItemsChanged
          itemClassName={menuStyles.item}
          filterClassName={filterClassName}
        />
      </div>
    </KeyNavigationProvider>
  );
}
