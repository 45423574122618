import * as React from 'react';
import { Button, ButtonSize, ButtonStyle } from '../components/new/button';
import Checkbox from '../components/new/checkbox';
import { toast } from '../components/toast';
import { trackerEvent } from '../tracker';
import styles from './openInAppToast.module.scss';

function ToastContents({
  onHide,
  electronScheme,
  setOpenInApp,
}: {
  onHide: () => void;
  setOpenInApp: (openInApp: boolean) => void;
  electronScheme: string;
}) {
  const [remember, setRemember] = React.useState(false);

  return (
    <>
      <div>Would you like to open this link in the Kitemaker app?</div>
      <div className="mt12 row">
        <Button
          buttonStyle={ButtonStyle.Primary}
          size={ButtonSize.Small}
          className="mr10"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onHide();
            if (remember) {
              trackerEvent('Universal Links Toggled', { activated: true, type: 'toast' });
              setOpenInApp(true);
            }
            window.location.href = window.location.href.replace('https://', `${electronScheme}://`);
          }}
        >
          Open in app
        </Button>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          size={ButtonSize.Small}
          className="mr24"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (remember) {
              trackerEvent('Universal Links Toggled', { activated: false, type: 'toast' });
              setOpenInApp(false);
            }
            onHide();
          }}
        >
          Dismiss
        </Button>
        <div
          className="row clickable"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setRemember(old => !old);
          }}
        >
          <Checkbox checked={remember} />
          <span className={styles.rememberMe}>Remember my choice</span>
        </div>
      </div>
    </>
  );
}

// only ever going to show this once per load
let toastShown = false;

export function showOpenInAppToast(
  setOpenInApp: (openInApp: boolean) => void,
  electronScheme: string
) {
  if (toastShown) {
    return;
  }

  toastShown = true;
  const hideToast = toast.raw(
    <ToastContents
      onHide={() => hideToast()}
      setOpenInApp={setOpenInApp}
      electronScheme={electronScheme}
    />,
    {
      large: true,
      duration: Infinity,
    }
  );
}
