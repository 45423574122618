import { useRecoilValue } from 'recoil';
import { documentFromString } from '../../shared/slate/utils';
import { Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useIsProductTierExceededAndNag } from '../index/billingChecks';
import { organizationPath } from '../syncEngine/selectors/organizations';
import { currentUserOrganizatonsState } from '../syncEngine/selectors/users';
import { createNewEntityFromAnywhere } from '../utils/config';
import { CommandDefinition, CommandGroup } from './state';

export function useOrganizationsCommandGroup(): CommandDefinition[] {
  const modals = useModals();
  const currentOrganization = useOrganization();
  const organizations = useRecoilValue(currentUserOrganizatonsState);
  const checkProductTierExceeded = useIsProductTierExceededAndNag();

  const commands: CommandDefinition[] = organizations
    .filter(org => org.id != currentOrganization.id)
    .map(org => ({
      id: `switch-to-org-${org.id}`,
      description: `Switch to organization ${org.name}`,
      handler: () => {
        window.location.pathname = organizationPath(org);
      },
    }));

  return [
    {
      id: 'invite-member',
      description: `Invite new member to ${currentOrganization.name}`,
      group: CommandGroup.Organizations,
      handler: () => modals.openModal(Modals.InviteMember),
    },
    {
      id: 'create-entity',
      description: `Create new work item or initiative`,
      global: true,
      group: CommandGroup.Organizations,
      hotkey: createNewEntityFromAnywhere,
      handler: () => {
        if (checkProductTierExceeded()) {
          return;
        }
        const selection = window.getSelection() ?? document.getSelection();
        const selectedText = selection?.toString() ?? '';
        modals.openModal(Modals.NewEntity, {
          content: documentFromString(selectedText),
        });
      },
    },
    {
      id: 'create-organization',
      description: `Create new organization`,
      group: CommandGroup.Organizations,
      handler: () => modals.openModal(Modals.CreateOrganization),
    },
    {
      id: 'create-space',
      description: `Create new space`,
      group: CommandGroup.Organizations,
      icon: 'workspace',
      handler: () => modals.openModal(Modals.CreateSpace),
    },
    ...commands.map(c => ({ ...c, group: CommandGroup.Organizations })),
  ];
}
