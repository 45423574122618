import { InitiativeStatus } from './initiativeStatus';

export enum FilterType {
  Has = 'has',
  Is = 'is',
  In = 'in',
  Not = 'not',
  And = 'and',
  Or = 'or',
  Member = 'member',
  Watching = 'watching',
  Label = 'label',
  Initiative = 'initiative',
  InitiativeStatus = 'initiativeStatus',
  Space = 'space',
  Impact = 'impact',
  Effort = 'effort',
  Tag = 'tag',
  Company = 'company',
  Person = 'person',
  Cycle = 'cycles',
  Archived = 'archived',
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FreeText = 'freeText',
  TodosAssigned = 'todosAssigned',
}

export interface HasFilter {
  type: FilterType.Has;
  has: 'members' | 'labels';
}

export interface IsFilter {
  type: FilterType.Is;
  is: 'open' | 'closed' | 'archived';
}

export interface InFilter {
  type: FilterType.In;
  spaceId: string;
}

export interface NotFilter {
  type: FilterType.Not;
  filter: Filter;
}

export interface AndFilter {
  type: FilterType.And;
  filters: Filter[];
}

export interface OrFilter {
  type: FilterType.Or;
  filters: Filter[];
}

export interface ArchivedFilter {
  type: FilterType.Archived;
  archived: boolean;
}

export interface MemberFilter {
  type: FilterType.Member;
  id: string;
}

export interface WatchingFilter {
  type: FilterType.Watching;
  id: string;
}

export interface LabelFilter {
  type: FilterType.Label;
  id: string;
  orgLevel?: boolean;
}

export interface InitiativeFilter {
  type: FilterType.Initiative;
  id: string;
}

export interface InitiativeStatusFilter {
  type: FilterType.InitiativeStatus;
  status: InitiativeStatus;
}

export interface ImpactFilter {
  type: FilterType.Impact;
  id: string | null;
}

export interface EffortFilter {
  type: FilterType.Effort;
  id: string | null;
}

export interface SpaceFilter {
  type: FilterType.Space;
  id: string;
}

export interface TagFilter {
  type: FilterType.Tag;
  id: string;
}

export interface PersonFilter {
  type: FilterType.Person;
  id: string;
}

export interface CompanyFilter {
  type: FilterType.Company;
  id: string;
}

export interface CycleFilter {
  type: FilterType.Cycle;
  id: string | null;
}

export interface CreatedByFilter {
  type: FilterType.CreatedBy;
  id: string;
}

export interface CreatedAtFilter {
  type: FilterType.CreatedAt;
  direction: 'before' | 'after';
  offset: number;
}

export interface UpdatedAtFilter {
  type: FilterType.UpdatedAt;
  direction: 'before' | 'after';
  offset: number;
}

export interface FreeTextFilter {
  type: FilterType.FreeText;
  text: string;
  titleOnly?: boolean;
}

export interface TodosAssignedFilter {
  type: FilterType.TodosAssigned;
  id: string;
}

export type Filter =
  | HasFilter
  | IsFilter
  | InFilter
  | NotFilter
  | AndFilter
  | OrFilter
  | ArchivedFilter
  | MemberFilter
  | WatchingFilter
  | LabelFilter
  | InitiativeFilter
  | InitiativeStatusFilter
  | SpaceFilter
  | ImpactFilter
  | EffortFilter
  | TagFilter
  | CompanyFilter
  | PersonFilter
  | CycleFilter
  | CreatedByFilter
  | CreatedAtFilter
  | UpdatedAtFilter
  | FreeTextFilter
  | TodosAssignedFilter;
