import React from 'react';
import { useRecoilValue } from 'recoil';
import { Space } from '../../../../sync/__generated/models';
import { FilteredListView } from '../../../components/new/filteredListView';
import styles from '../../../components/new/menu/menu.module.scss';
import { statusToIcon } from '../../../components/new/statusIcon';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMoveIssues } from '../../../syncEngine/actions/issues';
import { statusesInBoardOrderSelector } from '../../../syncEngine/selectors/boards';
import { isDefaultStatus } from '../../../syncEngine/selectors/issueStatuses';
import { spacesForCurrentUserMembershipSelector } from '../../../syncEngine/selectors/users';
import commandMenuStyles from '../commandMenu.module.scss';

export function ChangeSpaceContents({
  onClose,
  entityIds,
}: {
  onClose: () => void;
  entityIds: string[];
}) {
  const moveIssues = useMoveIssues();
  const organization = useOrganization();
  const { favoriteSpaces, nonFavoriteSpaces } = useRecoilValue(
    spacesForCurrentUserMembershipSelector(organization.id)
  );
  const [space, setSpace] = React.useState<Space | null>(null);
  const sortedStatuses = useRecoilValue(statusesInBoardOrderSelector({ spaceId: space?.id ?? '' }));
  if (!space) {
    return (
      <div className="colStretch fullWidth fullHeight" key="space">
        <FilteredListView
          className="grow"
          itemClassName={styles.item}
          filterPlaceholder="Select space"
          filterClassName={commandMenuStyles.inputDiv}
          propertiesToSearch={['name']}
          items={[...favoriteSpaces, ...nonFavoriteSpaces].map(space => ({
            id: space.id,
            contents: space.name,
            name: space.name,
            onSelected: () => {
              setSpace(space);
            },
          }))}
        />
      </div>
    );
  }

  return (
    <div className="colStretch fullWidth fullHeight" key="status">
      <FilteredListView
        selectFirstOnItemsChanged
        className="grow"
        itemClassName={styles.item}
        filterPlaceholder="Select status"
        filterClassName={commandMenuStyles.inputDiv}
        propertiesToSearch={['name', 'type']}
        items={sortedStatuses.map(status => {
          const icon = statusToIcon(status.statusType, isDefaultStatus(space, status));
          return {
            id: status.id,
            contents: status.name,
            name: status.name,
            type: status.statusType,
            icon,
            onSelected: () => {
              moveIssues(entityIds, status.id);
              onClose();
            },
          };
        })}
      />
    </div>
  );
}
