import * as React from 'react';
import { useSlateStatic } from 'slate-react';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';

export function useOnKeyDownHandler(onKeyPress: (e: React.KeyboardEvent) => boolean) {
  const selected = useFocusedAndSelected();
  const editor = useSlateStatic();

  React.useEffect(() => {
    if (!selected) {
      return;
    }

    editor.keyPressEvent = onKeyPress;
    return () => {
      if (editor.keyPressEvent === onKeyPress) {
        editor.keyPressEvent = undefined;
      }
    };
  }, [selected, editor, onKeyPress]);
}
