import * as React from 'react';
import { isMac } from './config';

export function metaKeyDown(e: React.KeyboardEvent | KeyboardEvent | React.MouseEvent) {
  return (isMac && e.metaKey) || (!isMac && e.ctrlKey);
}

export function modifierKeyDown(e: React.KeyboardEvent | KeyboardEvent) {
  return e.metaKey || e.ctrlKey || e.altKey;
}

export function codeToKey(e: React.KeyboardEvent | KeyboardEvent) {
  return e.code.toLowerCase().replace('key', '');
}

export function isLinkHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return e.key.toLowerCase() === 'k' && metaKeyDown(e);
}

export function isCommentHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return e.key.toLowerCase() === 'm' && metaKeyDown(e) && e.shiftKey;
}

export function isInsightsHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return e.key.toLowerCase() === 'i' && metaKeyDown(e) && e.shiftKey;
}

export function isCreateHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return codeToKey(e) === 'c' && !metaKeyDown(e) && e.altKey;
}

export function isCopyNumberHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return e.key.toLowerCase() === 'i' && metaKeyDown(e) && e.shiftKey;
}

export function isCopyGitBranchHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return e.key.toLowerCase() === 'g' && metaKeyDown(e) && e.shiftKey;
}

export function isAddToCurrentCycleHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return codeToKey(e) === 'o' && e.shiftKey && e.altKey && !metaKeyDown(e);
}

export function isAddToUpcomingCycleHotkey(e: React.KeyboardEvent | KeyboardEvent) {
  return codeToKey(e) === 'o' && e.shiftKey && e.altKey && metaKeyDown(e);
}
