import { isFunction } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Activity, ActivityType, Comment } from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useMarkUpdatesReadByDependencyId } from '../../../syncEngine/actions/updates';
import {
  ActivityFilterMode,
  activityFeedActiveFilterState,
} from '../../../syncEngine/selectors/activities';
import {
  commentsAndActivitiesForEntitySelector,
  isPartialSelector,
} from '../../../syncEngine/selectors/entities';
import { syncEngineState } from '../../../syncEngine/state';
import { filterHotKey, mainComboKey } from '../../../utils/config';
import { getQueryParameter } from '../../../utils/query';
import { LoadingSpinner } from '../../new/loadingSpinner';
import { Button, ButtonStyle } from '../button';
import { CommentActivity, CommentThread, NEW_COMMENT_ID, NewComment } from '../comments';
import { CustomCommand } from '../customCommand';
import { Hotkey } from '../hotkey';
import { Icon } from '../icon';
import { KeyboardShortcut } from '../keyboardShortcut';
import {
  useEnsureFocusedElementIsVisible,
  useGetKeyNavigationState,
  useKeyNavigationColumn,
  useKeyNavigationWatcher,
  useSetKeyNavigationFocus,
} from '../keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../menu/dropdownMenu';
import { ScrollToLoadMore } from '../scrollToLoadMore';
import { Tooltip } from '../tooltip';
import Watchers from '../watchers';
import styles from './activities.module.scss';
import { AssigneesChangedActivity } from './assigneesChangedActivity';
import { CodeReviewRequestAddedActivity } from './codeReviewRequestAddedActivity';
import { CompanyPersonChangedActivity } from './companyPersonChangedActivity';
import { CreatedActivity } from './createdActivity';
import { CreatedFromActivity } from './createdFromActivity';
import { DependencyActivity } from './dependencyActivity';
import { DueDateChangedActivity } from './dueDateChangedActivity';
import { EffortChangedActivity } from './effortChangedActivity';
import { EntitiesChangedActivity } from './entitiesChangedActivity';
import { EntityArchivedActivity } from './entityArchivedActivity';
import { ImpactChangedActivity } from './impactChangedActivity';
import { LabelsChangedActivity } from './labelsChangedActivity';
import { MentionedActivity } from './mentionedActivity';
import { MentionedInIntegrationActivity } from './mentionedInIntegrationActivity';
import { ProccessedChangedActivity } from './processedChangedActivity';
import { RoadmapColumnChangedActivity } from './roadmapColumnChangedActivity';
import { RoadmapsChangedActivity } from './roadmapsChangedActivity';
import { SpacesChangedActivity } from './spacesChangedActivity';
import { StaleIssueActivity } from './staleIssueActivity';
import { StatusChangedActivity } from './statusChangedActivity';
import { TitleChangedActivity } from './titleChangedActivity';
import { TodoChangedActivity } from './todoChangedActivity';

function isComment(activity: Activity | Comment): activity is Comment {
  return (activity as Comment).reply !== undefined;
}

export function RenderActivity({ activityId }: { activityId: string }) {
  const activity = useRecoilValue(syncEngineState(activityId)) as Comment | Activity | null;

  if (!activity) {
    return null;
  }
  if (isComment(activity)) {
    return <CommentActivity commentId={activityId} />;
  }

  switch (activity.activityType) {
    case ActivityType.AssigneesChanged:
      return <AssigneesChangedActivity activity={activity} />;
    case ActivityType.CodeReviewRequestAdded:
      return <CodeReviewRequestAddedActivity activity={activity} />;
    case ActivityType.Created:
      return <CreatedActivity activity={activity} />;
    case ActivityType.Dependency:
      return <DependencyActivity activity={activity} />;
    case ActivityType.CreatedFrom:
      return <CreatedFromActivity activity={activity} />;
    case ActivityType.LabelsChanged:
      return <LabelsChangedActivity activity={activity} />;
    case ActivityType.ImpactChanged:
      return <ImpactChangedActivity activity={activity} />;
    case ActivityType.EffortChanged:
      return <EffortChangedActivity activity={activity} />;
    case ActivityType.EntitiesChanged:
      return <EntitiesChangedActivity activity={activity} />;
    case ActivityType.Mentioned:
      return <MentionedActivity activity={activity} />;
    case ActivityType.MentionedInIntegration:
      return <MentionedInIntegrationActivity activity={activity} />;
    case ActivityType.StatusChanged:
      return <StatusChangedActivity activity={activity} />;
    case ActivityType.TitleChanged:
      return <TitleChangedActivity activity={activity} />;
    case ActivityType.TodoChanged:
      return <TodoChangedActivity activity={activity} />;
    case ActivityType.StaleIssue:
      return <StaleIssueActivity activity={activity} />;
    case ActivityType.SpacesChanged:
      return <SpacesChangedActivity activity={activity} />;
    case ActivityType.RoadmapsChanged:
      return <RoadmapsChangedActivity activity={activity} />;
    case ActivityType.RoadmapColumnChanged:
      return <RoadmapColumnChangedActivity activity={activity} />;
    case ActivityType.EntityArchived:
      return <EntityArchivedActivity activity={activity} />;
    case ActivityType.DueDateChanged:
      return <DueDateChangedActivity activity={activity} />;
    case ActivityType.ProcessedChanged:
      return <ProccessedChangedActivity activity={activity} />;
    case ActivityType.CompanyPersonChanged:
      return <CompanyPersonChangedActivity activity={activity} />;
  }
  return null;
}

function LoadMoreActivitiesBasedOnKeyNavFocus({
  activityIds,
  onFocused,
}: {
  activityIds: string[];
  onFocused: (id: string) => void;
}) {
  useKeyNavigationWatcher(({ focused }) => {
    if (focused && activityIds.includes(focused)) {
      onFocused(focused);
    }
  });
  return null;
}

function ActivityListComponent(
  { entityId }: { entityId: string },
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const setFocus = useSetKeyNavigationFocus();
  const isPartial = useRecoilValue(isPartialSelector(entityId));
  const activityIds = useRecoilValue(commentsAndActivitiesForEntitySelector(entityId));

  const getKeyNavState = useGetKeyNavigationState();

  const [offset, setOffset] = React.useState(() => {
    const focusedId =
      getQueryParameter(history, 'commentId') ??
      getQueryParameter(history, 'activityId') ??
      getKeyNavState().focused;
    const focusedIdIndex = focusedId ? activityIds.indexOf(focusedId) : -1;
    if (focusedIdIndex !== -1) {
      return Math.max(20, activityIds.length - focusedIdIndex);
    }
    return 20;
  });

  const [activityIdsToRender, setActivityIdsToRender] = React.useState(
    activityIds.slice(Math.max(0, activityIds.length - offset))
  );
  const deferredActivityIds = React.useDeferredValue(activityIdsToRender);
  useKeyNavigationColumn(`right-${entityId}`, [...deferredActivityIds, NEW_COMMENT_ID], scrollRef);

  React.useEffect(() => {
    setActivityIdsToRender(activityIds.slice(Math.max(0, activityIds.length - offset)));
  }, [activityIds, offset]);

  const renderedActivities = deferredActivityIds.map(activityId => (
    <RenderActivity key={activityId} activityId={activityId} />
  ));

  return (
    <div
      className={styles.reverseList}
      ref={r => {
        if (ref) {
          if (isFunction(ref)) {
            ref(r);
          } else {
            ref.current = r;
          }
        }
        scrollRef.current = r;
      }}
    >
      <div className={styles.activityList}>
        {isPartial && <LoadingSpinner />}
        {offset < activityIds.length && (
          <ScrollToLoadMore
            watchForId={deferredActivityIds[0]}
            onLoadMore={() => {
              setFocus(deferredActivityIds[0]);
              setOffset(previous => {
                return Math.min(previous + 20, activityIds.length);
              });
            }}
            key={`loadMore-${deferredActivityIds.length}`}
          />
        )}
        <LoadMoreActivitiesBasedOnKeyNavFocus
          activityIds={activityIds}
          onFocused={id => {
            const index = activityIds.indexOf(id);
            const newOffset = activityIds.length - index;

            setOffset(previous => {
              if (previous > newOffset) {
                return previous;
              }

              return newOffset;
            });
          }}
        />
        {renderedActivities}
      </div>
    </div>
  );
}

const ActivityList = React.forwardRef(ActivityListComponent);

export function ActivitiesComponent({ entityId }: { entityId: string }) {
  const markUpdateReadByDependencyId = useMarkUpdatesReadByDependencyId();
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const setFocus = useSetKeyNavigationFocus();
  const ref = React.useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    ensureVisible();
    markUpdateReadByDependencyId(entityId);
  });

  return (
    <div className={styles.activitiesContainer}>
      <ActivityList entityId={entityId} ref={ref} />
      <NewComment entityId={entityId} scrollParent={ref} />
      <Hotkey
        hotkey={`${mainComboKey}+down`}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (ref.current) {
            ref.current.scrollTop = 0;
            setFocus(NEW_COMMENT_ID);
          }
        }}
      />
    </div>
  );
}

export const Activities = React.memo(ActivitiesComponent);

function ActivityFilter() {
  const [filter, setFilter] = useRecoilState(activityFeedActiveFilterState);
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <CustomCommand
        command={{
          id: `filter-activities`,
          icon: 'filter',
          group: CommandGroup.Other,
          description: 'Filter activities',
          hotkey: filterHotKey,
          handler: () => {
            setMenuOpen(true);
          },
        }}
      />
      <DropdownMenuTrigger asChild>
        <div>
          <Tooltip
            side="bottom"
            content={
              <>
                Filter activities <KeyboardShortcut shortcut={filterHotKey} />
              </>
            }
          >
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              buttonStyle={ButtonStyle.BareSubtle}
              style={{
                color: 'var(--gray12)',
              }}
            >
              <span className="mr6">
                {filter === ActivityFilterMode.All ? 'All activities' : 'Comments only'}
              </span>
              <Icon style={{ fill: 'var(--grayA8)' }} icon={'filter'} />
            </Button>
          </Tooltip>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        <DropdownMenuItem onClick={() => setFilter(ActivityFilterMode.All)} shortcut="a">
          All activity
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setFilter(ActivityFilterMode.Comments)} shortcut="c">
          Comments only
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function ActivitiesTab({
  openCommentThreadId,
  entityId,
}: {
  openCommentThreadId: string | null;
  entityId: string;
}) {
  return (
    <div className="colStretch grow">
      <div className={styles.activitiesHeader}>
        <ActivityFilter />
        <Watchers entityId={entityId} />
      </div>
      {!openCommentThreadId && <Activities key={entityId} entityId={entityId} />}
      {openCommentThreadId && <CommentThread threadId={openCommentThreadId} entityId={entityId} />}
    </div>
  );
}
