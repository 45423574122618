import debugModule from 'debug';
import { SyncEngineValue } from '../syncEngine/types';
import { cache } from '../utils/cache';

const debug = debugModule('offlineCache');

const ctx: Worker = self as any;

async function handleCacheData(
  key: string,
  data: Record<string, SyncEngineValue>,
  metadata?: Record<string, unknown>
) {
  try {
    await cache()?.setItem(key, { data, metadata });
  } catch (e) {
    debug('Error writing to local forage', e);
  }
}

ctx.addEventListener('message', event => {
  if (!event.data) {
    return;
  }

  switch (event.data.type) {
    case 'CACHE':
      handleCacheData(event.data.key, event.data.objects, event.data.metadata);
      break;
  }
});

export function cacheData(
  worker: Worker,
  key: string,
  objects: Record<string, SyncEngineValue>,
  metadata?: Record<string, unknown>
) {
  worker.postMessage({
    type: 'CACHE',
    key,
    objects,
    metadata,
  });
}
