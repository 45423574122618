import * as ToggleGroup from '@radix-ui/react-toggle-group';
import cn from 'classnames';
import * as React from 'react';
import { Icon } from './icon';
import styles from './segmentedControl.module.scss';
import { Tooltip } from './tooltip';

export enum SegmentedControlVariant {
  IconsOnly,
  LabelsOnly,
  Both,
}

export function SegmentedControl<T extends string>({
  label,
  value,
  onValueChanged,
  options,
  variant = SegmentedControlVariant.IconsOnly,
  className,
  style,
}: {
  label?: string;
  value: T;
  onValueChanged: (value: T) => void;
  options: Array<{ id: T; icon: string; label?: string; tooltip?: React.ReactNode }>;
  variant?: SegmentedControlVariant;
  className?: string;
  style?: React.CSSProperties;
}) {
  const items = options.map(option => {
    let content: JSX.Element;
    switch (variant) {
      case SegmentedControlVariant.IconsOnly:
        content = <Icon icon={option.icon} />;
        break;
      case SegmentedControlVariant.LabelsOnly:
        content = (
          <>
            <span className={styles.label}>{option.label}</span>
          </>
        );
        break;
      case SegmentedControlVariant.Both:
        content = (
          <>
            <Icon icon={option.icon} />
            <span className={styles.label}>{option.label}</span>
          </>
        );
        break;
    }

    if (option.tooltip) {
      content = (
        <Tooltip key={option.id} content={option.tooltip}>
          <div className="noLineHeight">{content}</div>
        </Tooltip>
      );
    }
    return (
      <ToggleGroup.Item
        key={option.id}
        value={option.id}
        aria-label={option.label ?? option.id}
        className={cn(styles.item, {
          [styles.withLabel]: variant !== SegmentedControlVariant.IconsOnly,
        })}
      >
        {content}
      </ToggleGroup.Item>
    );
  });
  return (
    <ToggleGroup.Root
      type="single"
      aria-label={label}
      value={value}
      onValueChange={v => {
        if (!v) {
          return;
        }
        onValueChanged(v as T);
      }}
      className={cn(styles.segmentedControl, className)}
      style={style}
    >
      {items}
    </ToggleGroup.Root>
  );
}
