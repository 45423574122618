import { capitalize, first, keyBy, last, uniq } from 'lodash';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../shared/utils/terms';
import {
  Cycle,
  DependencyType,
  Doc,
  Feedback,
  Initiative,
  Issue,
  IssueStatusType,
  Release,
  ReleaseStatus,
} from '../../sync/__generated/models';
import { generateChangelog, mergeWorkItems } from '../api/ai';
import { writeToClipboard } from '../components/clipboardText';
import { useGitBranchName } from '../components/gitBranch';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
  useCopyGitBranchToClipboard,
} from '../components/new/copyAndPaste';
import { toast } from '../components/toast';
import { useConfiguration } from '../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useCurrentUser } from '../contexts/userContext';
import { useSerializeToMarkdown } from '../slate/hooks/useSerializeToMarkdown';
import { useAddEntitiesToCycle, useRemoveEntitiesFromCycle } from '../syncEngine/actions/cycles';
import {
  useArchiveDocuments,
  useDeleteDocumentsAndFolders,
  useUnarchiveDocuments,
} from '../syncEngine/actions/documents';
import {
  useReplaceAssigneesForEntities,
  useToggleAssigneesForEntities,
  useToggleWatchersForEntities,
} from '../syncEngine/actions/entities';
import {
  useDeleteIssues,
  useDuplicateIssues,
  useMoveIssues,
  useUnarchiveIssues,
  useUpdateIssues,
} from '../syncEngine/actions/issues';
import { useUnsnoozeIds } from '../syncEngine/actions/organizationMember';
import {
  useArchiveReleases,
  useUnarchiveReleases,
  useUpdateReleases,
} from '../syncEngine/actions/releases';
import { statusesInBoardOrderSelector } from '../syncEngine/selectors/boards';
import { collaborativeDocsByEntitiesSelector } from '../syncEngine/selectors/collaborativeDoc';
import { entityInCycleSelector, isCycle } from '../syncEngine/selectors/cycles';
import { isDocument } from '../syncEngine/selectors/documents';
import {
  entitiesSelector,
  entitySelector,
  entityType,
  isSpaceBoundEntity,
} from '../syncEngine/selectors/entities';
import { isFeedback } from '../syncEngine/selectors/feedback';
import { isInitiative } from '../syncEngine/selectors/intiatives';
import { isIssue, useGetFilterdIssuesForStatus } from '../syncEngine/selectors/issues';
import {
  archivedStatusForSpaceSelector,
  closedStatusForSpaceSelector,
  openStatusForSpaceSelector,
} from '../syncEngine/selectors/issueStatuses';
import { isRelease } from '../syncEngine/selectors/releases';
import { spaceSelector, spacesForOrganizationSelector } from '../syncEngine/selectors/spaces';
import { todoCountForEntitySelector } from '../syncEngine/selectors/todos';
import { currentUserMembershipState } from '../syncEngine/selectors/users';
import {
  addToCurrentCycleKey,
  addToInitiativeKey,
  addToUpcomingCycleKey,
  archiveIssueKey,
  assignIssueKey,
  closeIssueKey,
  copyIssueGitBranchKey,
  copyIssueLinkKey,
  copyIssueNumberKey,
  deleteKey,
  duplicateKey,
  labelIssueKey,
  moveIssueKey,
  moveIssueToNewSpaceKey,
  moveIssueToNextStatusKey,
  moveIssueToNextStatusKeyBottom,
  moveIssueToPreviousStatusKey,
  moveIssueToPreviousStatusKeyBottom,
  releaseIssueKey,
  selfAssignIssueKey,
  setEffortKey,
  setImpactKey,
  showTodosKey,
  snoozeEntityKey,
  takeOwnershipIssueKey,
  watchIssueKey,
} from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  EntityCommandGroupContext,
  useCommandMenuContext,
} from './state';

export function useEntityCommandGroup(): CommandDefinition[] {
  const organization = useOrganization();
  const paidPlan = !!organization.activeProductId;

  const user = useCurrentUser();
  const modals = useModals();
  const { host } = useConfiguration();

  const entityContext = useCommandMenuContext<EntityCommandGroupContext>(CommandGroup.Entities);
  const entities = useRecoilValue(entitiesSelector(entityContext?.entityIds ?? []));
  const types = entities.map(e => entityType(e));
  const spaceIds = uniq(entities.map(e => (isSpaceBoundEntity(e) ? e.spaceId : undefined)));
  const allSameSpace = spaceIds.length === 1 && spaceIds[0] !== undefined;
  const focusedEntity = useRecoilValue(entitySelector(entityContext?.focusedEntityId ?? ''));
  const focusedEntitySpace = useRecoilValue(
    spaceSelector(focusedEntity && isSpaceBoundEntity(focusedEntity) ? focusedEntity.spaceId : '')
  );
  const branchName = useGitBranchName(entityContext?.focusedEntityId ?? '');
  const copyGitBranch = useCopyGitBranchToClipboard(entityContext?.focusedEntityId ?? '');
  const closedStatus = useRecoilValue(closedStatusForSpaceSelector(first(spaceIds)));
  const archivedStatus = useRecoilValue(archivedStatusForSpaceSelector(first(spaceIds)));
  const defaultStatus = useRecoilValue(openStatusForSpaceSelector(first(spaceIds)));
  const firstSpace = useRecoilValue(spaceSelector(first(spaceIds)));
  const moveIssues = useMoveIssues();
  const duplicateIssues = useDuplicateIssues();
  const deleteIsuses = useDeleteIssues();
  const deleteDocuments = useDeleteDocumentsAndFolders();
  const unarchiveIssues = useUnarchiveIssues();
  const updateIssues = useUpdateIssues();
  const updateRelease = useUpdateReleases();
  const archiveRelease = useArchiveReleases();
  const unArchiveRelease = useUnarchiveReleases();
  const archiveDocuments = useArchiveDocuments();
  const unarchiveDocuments = useUnarchiveDocuments();
  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const toggleAssigneesForEntities = useToggleAssigneesForEntities();
  const toggleWatchersForEntities = useToggleWatchersForEntities();
  const replaceAssigneesForEntities = useReplaceAssigneesForEntities();
  const addEntitiesToCycle = useAddEntitiesToCycle();
  const removeEntitiesFromCycle = useRemoveEntitiesFromCycle();
  const getIssuesForStatus = useGetFilterdIssuesForStatus(
    spaceIds[0] ?? '',
    focusedEntity && isIssue(focusedEntity) ? focusedEntity.statusId : '',
    ''
  );
  const unsnoozeIds = useUnsnoozeIds();

  const todoCount = useRecoilValue(todoCountForEntitySelector(entityContext?.focusedEntityId));

  const { featureFlags } = useConfiguration();

  const orgMember = useRecoilValue(currentUserMembershipState(organization.id));
  const issues = entities.filter(e => isIssue(e)) as Issue[];
  const issueDocuments = useRecoilValue(collaborativeDocsByEntitiesSelector(issues.map(i => i.id)));
  const toMarkdown = useSerializeToMarkdown();
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const initiatives = entities.filter(e => isInitiative(e)) as Initiative[];
  const feedback = entities.filter(e => isFeedback(e)) as Feedback[];
  const cycles = entities.filter(e => isCycle(e)) as Cycle[];
  const releases = entities.filter(e => isRelease(e)) as Release[];
  const documents = entities.filter(e => isDocument(e)) as Doc[];
  const statusIds = uniq(issues.map(i => i.statusId));
  const allWorkItems = entities.length === issues.length;
  const allInitiatives = entities.length === initiatives.length;
  const allReleases = entities.length === releases.length;
  const allDocuments = entities.length === documents.length;

  const plural = types.length > 1 ? 's' : '';
  const inUpcomingCycle = useRecoilValue(
    entityInCycleSelector({
      cycleId: focusedEntitySpace?.upcomingCycleId,
      entityId: focusedEntity?.id ?? '',
    })
  );

  const snoozedById = keyBy(orgMember?.snoozed ?? {}, 'id');

  const inCurrentCycle = useRecoilValue(
    entityInCycleSelector({
      cycleId: focusedEntitySpace?.activeCycleId,
      entityId: focusedEntity?.id ?? '',
    })
  );

  const statuses = useRecoilValue(
    statusesInBoardOrderSelector({ spaceId: focusedEntitySpace?.id ?? '' })
  );

  function previousStatus(statusId: string) {
    const currentStatusIndex = statuses.findIndex(s => s.id == statusId);
    if (currentStatusIndex > 0) {
      return statuses[currentStatusIndex - 1];
    }
    return null;
  }

  function nextStatus(statusId: string) {
    const nonArchivedStatuses = statuses.filter(s => s.statusType !== IssueStatusType.Archived);
    const currentStatusIndex = statuses.findIndex(s => s.id == statusId);

    if (currentStatusIndex < nonArchivedStatuses.length - 1) {
      return nonArchivedStatuses[currentStatusIndex + 1];
    }

    return null;
  }

  let entityTerm = `...`;
  if (allWorkItems) {
    entityTerm = `${issueTerm}${plural}`;
  }

  const commands: CommandDefinition[] = [];

  if (focusedEntity) {
    const focusedEntityType = entityType(focusedEntity);
    commands.push(
      {
        id: 'copy-entity-number',
        hotkey: copyIssueNumberKey,
        description: `Copy number`,
        icon: 'copy',
        handler: () => {
          copyEntityNumber([focusedEntity.id]);
        },
      },
      {
        id: 'copy-entity-link',
        hotkey: copyIssueLinkKey,
        description: `Copy link`,
        icon: 'link',
        handler: () => {
          copyEntityLink([focusedEntity.id]);
        },
      }
    );

    if (isIssue(focusedEntity)) {
      if (branchName) {
        commands.push({
          id: 'copy-git-branch',
          hotkey: copyIssueGitBranchKey,
          description: `Copy git branch for ${focusedEntityType}`,
          icon: 'branch',
          handler: () => {
            copyGitBranch();
          },
        });
      }
      if (focusedEntity.public) {
        commands.push({
          id: `copy-public-link`,
          group: CommandGroup.Entities,
          description: `Copy public link to ${focusedEntityType}`,
          icon: 'none',
          handler: () => {
            writeToClipboard(
              `${host}/sharing/items/${focusedEntity.id}`,
              `public link to ${issueTerm}`
            );
          },
        });
      }
    }

    if (featureFlags.FEATURE_TOGGLE_TODO_LIST && todoCount.total > 0) {
      commands.push({
        id: 'show-todos',
        description: `Show todos`,
        hotkey: showTodosKey,
        icon: 'todo_checkmark',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.ListTodos,
            context: {
              entityId: focusedEntity.id,
            },
          });
        },
      });
    }
  }

  // FIXME: would be cool if moving to done/archved worked even with items from different spaces
  if (allWorkItems && issues.length) {
    if (featureFlags.FEATURE_TOGGLE_CREATE_CHANGELOG) {
      commands.push({
        id: 'generate-changelog',
        description: 'Generate changelog from selection',
        priority: 9,
        handler: async () => {
          const titles = issues.map(i => i.title);
          const changelog = generateChangelog(organization.id, titles);
          modals.openModal(Modals.TextToCopy, {
            content: changelog,
            dialogTitle: 'Changelog',
          });
        },
      });
    }

    if (featureFlags.FEATURE_TOGGLE_MERGE_WORKITEMS && issues.length > 1) {
      commands.push({
        id: 'merge-work-items',
        description: 'Merge selected work items',
        priority: 9,
        handler: async () => {
          const simplifiedWorkItems = issues.map((i, idx) => {
            const space = spaces.find(s => s.id === i.spaceId)!;
            return {
              key: `${space.key}-${i.number}`,
              title: i.title,
              description: toMarkdown(issueDocuments[idx].content),
            };
          });

          await mergeWorkItems(organization.id, simplifiedWorkItems);
        },
      });
    }

    if (featureFlags.FEATURE_TOGGLE_SNOOZE) {
      if (entities.every(e => snoozedById[e.id])) {
        commands.push({
          id: 'unsnooze-issues',
          hotkey: snoozeEntityKey,
          description: `Unsnooze ${issueTerm}${entities.length > 1 ? 's' : ''}`,
          icon: 'snooze',
          priority: 10,
          handler: () => {
            unsnoozeIds(entities.map(e => e.id));
          },
        });
      } else {
        commands.push({
          id: 'snooze-issues',
          hotkey: snoozeEntityKey,
          description: `Snooze ${issueTerm}${entities.length > 1 ? 's' : ''}`,
          icon: 'snooze',
          priority: 10,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Snoozed,
              context: {
                entityIds: entities.map(e => e.id),
              },
            });
          },
        });
      }
    }

    commands.push({
      id: 'change-issue-due-date',
      description: 'Change due date',
      icon: 'due_date',
      priority: 9,
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.DueDate,
          context: {
            entityIds: issues.map(i => i.id),
          },
        });
      },
    });

    if (organization.releasesEnabled) {
      commands.push({
        id: 'issue-change-releases',
        hotkey: releaseIssueKey,
        description: 'Change releases',
        icon: 'release',
        priority: 8,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Releases,
            context: {
              entityIds: issues.map(i => i.id),
            },
          });
        },
      });
    }

    if (allSameSpace) {
      commands.push(
        {
          id: 'change-status',
          hotkey: moveIssueKey,
          description: 'Change status',
          icon: 'status',
          priority: 9,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.ChangeStatus,
              context: {
                entityIds: issues.map(i => i.id),
                spaceId: first(spaceIds),
              },
            });
          },
        },
        {
          id: 'move-to-done',
          hotkey: closeIssueKey,
          description: `Move to ${closedStatus?.name}`,
          icon: 'status_done',
          priority: 9,
          handler: () => {
            if (!closedStatus || issues.every(i => i.statusId === closedStatus.id)) {
              return;
            }

            moveIssues(entityContext!.entityIds, closedStatus.id);
          },
        },
        {
          id: 'move-to-archived',
          hotkey: archiveIssueKey,
          description: `Move to archive`,
          icon: 'archive',
          priority: 9,
          handler: () => {
            if (!archivedStatus || !defaultStatus) {
              return;
            }

            if (issues.every(i => i.statusId === archivedStatus.id)) {
              unarchiveIssues(entityContext!.entityIds);
              return;
            }

            moveIssues(entityContext!.entityIds, archivedStatus.id);
          },
        }
      );
      commands.push({
        id: 'issue-change-initiatives',
        hotkey: addToInitiativeKey,
        description: 'Change initiatives',
        icon: 'initiative',
        priority: 8,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Initiatives,
            context: {
              entityIds: issues.map(i => i.id),
              spaceId: first(spaceIds),
            },
          });
        },
      });

      if (firstSpace?.cyclesEnabled && firstSpace?.upcomingCycleId) {
        commands.push({
          id: 'add-to-upcomfing-cycle',
          hotkey: addToUpcomingCycleKey,
          description: inUpcomingCycle ? 'Remove from upcoming cycle' : 'Add to upcoming cycle',
          icon: 'cycle_current',
          handler: () => {
            if (!firstSpace.upcomingCycleId) {
              return;
            }
            if (inUpcomingCycle) {
              removeEntitiesFromCycle(
                issues.map(i => i.id),
                firstSpace.upcomingCycleId
              );
            } else {
              addEntitiesToCycle(
                issues.map(i => i.id),
                firstSpace.upcomingCycleId
              );
            }
          },
        });
      }

      if (firstSpace?.cyclesEnabled && firstSpace?.activeCycleId) {
        commands.push({
          id: 'add-to-current-cycle',
          hotkey: addToCurrentCycleKey,
          description: inCurrentCycle ? 'Remove from current cycle' : 'Add to current cycle',
          icon: 'cycle_current',
          handler: () => {
            if (!firstSpace.activeCycleId) {
              return;
            }
            if (inCurrentCycle) {
              removeEntitiesFromCycle(
                issues.map(i => i.id),
                firstSpace.activeCycleId
              );
            } else {
              addEntitiesToCycle(
                issues.map(i => i.id),
                firstSpace.activeCycleId
              );
            }
          },
        });
      }

      if (statusIds.length === 1) {
        commands.push(
          {
            id: `issue-previous-status`,
            hotkey: moveIssueToPreviousStatusKey,
            icon: 'status',
            description: `Move to top of previous status`,
            handler: () => {
              const prev = previousStatus(statusIds[0]);
              if (prev) {
                moveIssues(entityContext!.entityIds, prev.id, { top: true });
              }
            },
          },
          {
            id: `issue-next-status`,
            hotkey: moveIssueToNextStatusKey,
            icon: 'status',
            description: `Move to top of next status`,
            handler: () => {
              const next = nextStatus(statusIds[0]);
              if (next) {
                moveIssues(entityContext!.entityIds, next.id, { top: true });
              }
            },
          },
          {
            id: `issue-previous-status-bottom`,
            hotkey: moveIssueToPreviousStatusKeyBottom,
            icon: 'status',
            description: `Move to bottom of previous status`,
            handler: () => {
              const prev = previousStatus(statusIds[0]);
              if (prev) {
                moveIssues(entityContext!.entityIds, prev.id);
              }
            },
          },
          {
            id: `issue-next-status-bottom`,
            hotkey: moveIssueToNextStatusKeyBottom,
            icon: 'status',
            description: `Move to top of next status`,
            handler: () => {
              const next = nextStatus(statusIds[0]);
              if (next) {
                moveIssues(entityContext!.entityIds, next.id);
              }
            },
          },
          {
            id: 'issue-duplicate',
            hotkey: duplicateKey,
            description: `Duplicate`,
            icon: 'copy',
            handler: () => {
              const issueIds = entityContext!.entityIds;
              const issuesForStatus = getIssuesForStatus();
              const itemIndex = issuesForStatus.findIndex(id => last(issueIds) === id);
              const itemAfter = issuesForStatus[itemIndex + 1];
              duplicateIssues(entityContext!.entityIds, issues[0].statusId, issueIds[0], itemAfter);
            },
          }
        );
      }
    }

    const allPublic = issues.every(e => e.public);
    const allPublicMetadata = issues.every(e => e.publicMetadata);

    commands.push(
      {
        id: 'change-space',
        hotkey: moveIssueToNewSpaceKey,
        description: 'Move to a new space',
        icon: 'workspace',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.ChangeSpace,
            context: {
              entityIds: issues.map(i => i.id),
            },
          });
        },
      },
      {
        id: 'issue-dependencies',
        hotkey: '-',
        description: 'Add dependencies',
        icon: 'dependency_enables',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Dependencies,
            context: {
              entityIds: issues.map(i => i.id),
              spaceId: first(spaceIds),
            },
          });
        },
      },
      {
        id: 'issue-dependencies-enables',
        hotkey: '- e',
        hotkeyHintOnly: true,
        description: 'Dependency: enables',
        icon: 'dependency_enables',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Dependencies,
            context: {
              entityIds: issues.map(i => i.id),
              spaceId: first(spaceIds),
              type: DependencyType.DependsOn,
              property: 'dependsOnId',
            },
          });
        },
      },
      {
        id: 'issue-dependencies-depends',
        hotkey: '- d',
        hotkeyHintOnly: true,
        description: 'Dependency: depends on',
        icon: 'dependency_depends',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Dependencies,
            context: {
              entityIds: issues.map(i => i.id),
              spaceId: first(spaceIds),
              type: DependencyType.DependsOn,
              property: 'enablesId',
            },
          });
        },
      },
      {
        id: 'issue-dependencies-blocks',
        hotkey: '- b',
        hotkeyHintOnly: true,
        description: 'Dependency: blocks',
        icon: 'dependency_blocking',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Dependencies,
            context: {
              entityIds: issues.map(i => i.id),
              spaceId: first(spaceIds),
              type: DependencyType.BlockedBy,
              property: 'dependsOnId',
            },
          });
        },
      },
      {
        id: 'issue-dependencies-blocked-by',
        hotkey: '- x',
        hotkeyHintOnly: true,
        description: 'Dependency: blocked by',
        icon: 'dependency_blocked',
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Dependencies,
            context: {
              entityIds: issues.map(i => i.id),
              spaceId: first(spaceIds),
              type: DependencyType.BlockedBy,
              property: 'enablesId',
            },
          });
        },
      },
      {
        id: 'issue-delete',
        hotkey: deleteKey,
        description: `Delete`,
        icon: 'delete',
        handler: () => {
          deleteIsuses(entityContext!.entityIds);
        },
      }
    );

    if (paidPlan) {
      commands.push({
        id: 'toggle-public',
        group: CommandGroup.Entities,
        description: allPublic ? `Make ${entityTerm} private` : `Make ${entityTerm} public`,
        icon: 'none',
        aliases: ['share'],
        handler: () => {
          updateIssues(entityContext!.entityIds, {
            public: !allPublic,
          });
          toast.info(`${capitalize(entityTerm)} now ${allPublic ? 'private' : 'public'}`);
        },
      });
      if (allPublic) {
        commands.push({
          id: 'toggle-public-metadata',
          group: CommandGroup.Entities,
          description: allPublicMetadata
            ? `Unshare metadata for ${entityTerm}`
            : `Share metadata for ${entityTerm}`,
          icon: 'none',
          aliases: ['share'],
          handler: () => {
            updateIssues(entityContext!.entityIds, {
              publicMetadata: !allPublicMetadata,
            });
            toast.info(`${allPublicMetadata ? 'Unshared' : 'Shared'} metadata for ${entityTerm}`);
          },
        });
      }
    }
  }

  if (entities.length) {
    if (allSameSpace || allInitiatives) {
      commands.push(
        {
          id: 'change-labels',
          hotkey: labelIssueKey,
          description: 'Change labels',
          icon: 'label',
          priority: 9,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Labels,
              context: {
                entityIds: entities.map(e => e.id),
                spaceId: first(spaceIds),
              },
            });
          },
        },
        {
          id: 'set-impact',
          hotkey: setImpactKey,
          description: 'Set impact',
          icon: 'impact',
          priority: 9,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Impact,
              context: {
                entityIds: entities.map(e => e.id),
                spaceId: first(spaceIds),
              },
            });
          },
        },
        {
          id: 'set-effort',
          hotkey: setEffortKey,
          description: 'Set effort',
          icon: 'effort',
          priority: 9,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Effort,
              context: {
                entityIds: entities.map(e => e.id),
                spaceId: first(spaceIds),
              },
            });
          },
        }
      );
    }

    if (allReleases) {
      if (releases.some(r => r.releaseStatus !== ReleaseStatus.Released)) {
        commands.push({
          id: 'release-status-released',
          description: `Set status to released`,
          icon: 'release',
          priority: 15,
          handler: () => {
            updateRelease(
              entities.map(e => e.id),
              { releaseStatus: ReleaseStatus.Released }
            );
          },
        });
      }

      if (releases.some(r => r.releaseStatus !== ReleaseStatus.InDevelopment)) {
        commands.push({
          id: 'release-status-in-development',
          description: `Set status to in development`,
          icon: 'release',
          priority: 15,
          handler: () => {
            updateRelease(
              entities.map(e => e.id),
              { releaseStatus: ReleaseStatus.InDevelopment }
            );
          },
        });
      }

      if (releases.some(r => r.releaseStatus !== ReleaseStatus.Planned)) {
        commands.push({
          id: 'release-status-planned',
          description: `Set status to planned`,
          icon: 'release',
          priority: 15,
          handler: () => {
            updateRelease(
              entities.map(e => e.id),
              { releaseStatus: ReleaseStatus.Planned }
            );
          },
        });
      }

      if (releases.some(r => r.archivedAt)) {
        commands.push({
          id: 'unarchive-release',
          hotkey: archiveIssueKey,
          description: `Unarchive`,
          icon: 'archive',
          priority: 16,
          handler: () => {
            unArchiveRelease(entities.map(e => e.id));
          },
        });
      } else {
        commands.push({
          id: 'archive-release',
          hotkey: archiveIssueKey,
          description: `Move to archive`,
          icon: 'archive',
          priority: 13,
          handler: () => {
            archiveRelease(entities.map(e => e.id));
          },
        });
      }
    }

    if (allDocuments) {
      commands.push(
        {
          id: 'doc-delete',
          hotkey: deleteKey,
          description: `Delete`,
          icon: 'delete',
          handler: () => {
            const docIds = documents.map(d => d.id);
            deleteDocuments(docIds, entityContext?.nonEntityIds ?? []);
          },
        },
        {
          id: 'doc-move',
          description: 'Move documents',
          icon: 'folder',
          priority: 10,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.MoveDocumentsAndFolders,
              context: {
                documentIds: documents.map(d => d.id),
                folderIds: entityContext?.nonEntityIds ?? [],
              },
            });
          },
        }
      );
      if (documents.some(r => r.archivedAt)) {
        commands.push({
          id: 'unarchive-document',
          hotkey: archiveIssueKey,
          description: `Unarchive`,
          icon: 'archive',
          priority: 16,
          handler: () => {
            unarchiveDocuments(entities.map(e => e.id));
          },
        });
      } else {
        commands.push({
          id: 'archive-document',
          hotkey: archiveIssueKey,
          description: `Move to archive`,
          icon: 'archive',
          priority: 13,
          handler: () => {
            archiveDocuments(entities.map(e => e.id));
          },
        });
      }
    }

    if (allWorkItems || allInitiatives || allReleases) {
      commands.push(
        {
          id: 'change-members',
          hotkey: assignIssueKey,
          description: 'Change members',
          icon: 'member',
          priority: 10,
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Members,
              context: {
                entityIds: entities.map(e => e.id),
                spaceId: allSameSpace ? first(spaceIds) : null,
              },
            });
          },
        },
        {
          id: 'self-assign',
          hotkey: selfAssignIssueKey,
          description: 'Add yourself as a member',
          icon: 'member',
          priority: 10,
          handler: () => {
            toggleAssigneesForEntities(
              entities.map(e => e.id),
              [user.id]
            );
          },
        },
        {
          id: 'take-ownership',
          hotkey: takeOwnershipIssueKey,
          description: `Take ownership`,
          icon: 'member',
          priority: 10,
          handler: () => {
            replaceAssigneesForEntities(
              entities.map(e => e.id),
              user.id
            );
          },
        }
      );
    }
  }

  if (feedback.length === 0 && cycles.length === 0 && entities.length) {
    commands.push({
      id: 'watch',
      hotkey: watchIssueKey,
      description: entities.every(e => {
        if (isIssue(e) || isInitiative(e) || isDocument(e)) {
          return e.watcherIds?.includes(user.id);
        }
        return false;
      })
        ? `Unwatch`
        : 'Watch',
      icon: 'watch',
      priority: 8,
      handler: () => {
        toggleWatchersForEntities(
          entities.map(e => e.id),
          [user.id]
        );
      },
    });
  }

  return commands.map(c => ({
    ...c,
    group: c.group ?? CommandGroup.Entities,
  }));
}
