import cn from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';
import { atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FilterType } from '../../../../shared/filtering';
import {
  documentFromString,
  isDocumentEmpty,
  stringifyDocument,
} from '../../../../shared/slate/utils';
import { Roadmap as RoadmapModel, RoadmapType } from '../../../../sync/__generated/models';
import { writeToClipboard } from '../../../components/clipboardText';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import {
  MetadataConfigurationButton,
  initiativeDefaultExcludeOptions,
} from '../../../components/metadataConfig';
import {
  Button,
  ButtonSize,
  ButtonStyle,
  IconButton,
  IconButtonTrigger,
} from '../../../components/new/button';
import { ColorPickerContent } from '../../../components/new/colorPicker';
import { InitiativeContents } from '../../../components/new/entityFilters2';
import {
  ContentProps,
  MenuMode,
  addFilter,
  findFilter,
  removeFilter,
} from '../../../components/new/filters';
import { FilterMenu as FilterMenu2 } from '../../../components/new/filters2';
import { Icon } from '../../../components/new/icon';
import { SpacesMetadata } from '../../../components/new/initativeSpaces';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import { KeyNavigationProvider } from '../../../components/new/keyNavigation';
import { LISTVIEW_ID, ListView, ListViewItem, NO_KEYNAV } from '../../../components/new/listView';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import menuStyles from '../../../components/new/menu/menu.module.scss';
import { MetadataSize } from '../../../components/new/metadata/size';
import { SpacePicker } from '../../../components/new/pickers/spacePicker';
import Popover from '../../../components/new/popover';
import { SegmentedControl } from '../../../components/new/segmentedControl';
import { StarredIcon } from '../../../components/new/starredIcon';
import { NewRoadmapSharingPopover } from '../../../components/sharing';
import { useConfiguration } from '../../../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useCurrentUser } from '../../../contexts/userContext';
import { ResponsiveDesignSize, useIsTinyScreen } from '../../../hooks/useResponsiveDesign';
import { TextArea, TextAreaHandle } from '../../../slate/textArea';
import { useDeleteRoadmaps, useUpdateRoadmaps } from '../../../syncEngine/actions/roadmaps';
import { useSetSpaceRoadmap } from '../../../syncEngine/actions/spaces';
import { useToggleStarred } from '../../../syncEngine/actions/starred';
import { localStorageEffect } from '../../../syncEngine/effects';
import { entityTypeString } from '../../../syncEngine/selectors/entities';
import { roadmapPath } from '../../../syncEngine/selectors/roadmaps';
import {
  spaceIdsForRoadmapSelector,
  spacesForRoadmapSelector,
} from '../../../syncEngine/selectors/spaces';
import { isStarredSelector } from '../../../syncEngine/selectors/starred';
import {
  addExistingKey,
  assignIssueKey,
  deleteKey,
  labelIssueKey,
  searchKey,
  selfFilterHotKey,
  spaceInitiativeKey,
  toggleListViewKey,
} from '../../../utils/config';
import { filterState } from '../../../utils/filtering';
import { StarredType } from '../../../utils/starred';
import { timelineViewPeriod } from '../../timelineViewScreen/timeline';
import { RoadmapIcon } from './roadmapIcon';
import styles from './roadmapsScreen.module.scss';

export enum Mode {
  Board = 'board_view',
  List = 'list_view',
}

export const roadmapScreenMode = atomFamily<Mode, string>({
  key: 'RoadmapScreenMode',
  default: Mode.Board,
  effects: key => [localStorageEffect(`__roadmapScreenMode__${key}`)],
});

function RoadmapMenu({
  roadmap,
  closeMenu,
  onEdit,
}: {
  roadmap: RoadmapModel;
  closeMenu: () => void;
  onEdit: () => void;
}) {
  const deleteRoadmaps = useDeleteRoadmaps();
  const organization = useOrganization();
  const modals = useModals();
  const { host } = useConfiguration();
  const maybeSpace = useMaybeSpace();
  const setSpaceRoadmap = useSetSpaceRoadmap();
  const spaceIds = useRecoilValue(spaceIdsForRoadmapSelector(roadmap.id));

  const starType = maybeSpace ? StarredType.SpaceRoadmap : StarredType.Roadmap;
  const starId = maybeSpace ? maybeSpace.id : roadmap.id;

  const isPrivate = maybeSpace?.private ?? false;

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: starType,
      id: starId,
    })
  );
  const toggleStarred = useToggleStarred();

  return (
    <>
      <DropdownMenuItem
        icon="edit"
        onClick={() => {
          onEdit();
          closeMenu();
        }}
      >
        Edit title
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="add"
        shortcut={addExistingKey}
        onClick={() => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.RoadmapInitiatives,
            context: { roadmapId: roadmap.id, spaceId: maybeSpace?.id },
          });
          closeMenu();
        }}
      >
        Add existing initiatives
      </DropdownMenuItem>
      {!maybeSpace && (
        <>
          <Submenu>
            <SubmenuTrigger icon="workspace">Add to space</SubmenuTrigger>
            <SubmenuContent className="menuLarge">
              <SpacePicker
                showRoadmap
                filterPlaceholder="Add to space"
                multi
                state={{ [roadmap.id]: spaceIds }}
                onSpaceAdded={(_, spaceId: string) => {
                  setSpaceRoadmap(spaceId, roadmap.id);
                }}
                onSpaceRemoved={(_, spaceId: string) => {
                  setSpaceRoadmap(spaceId, null);
                }}
                onDone={closeMenu}
              />
            </SubmenuContent>
          </Submenu>
        </>
      )}
      <DropdownMenuItem
        icon="link"
        onClick={() => {
          closeMenu();
          writeToClipboard(`${host}${roadmapPath(organization, roadmap)}`, 'Roadmap link');
        }}
      >
        Copy link
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        onClick={() => {
          toggleStarred({ type: starType, id: starId });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      {maybeSpace && !isPrivate && (
        <>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            icon="roadmap"
            onClick={() => {
              modals.openModal(Modals.CommandMenu, {
                view: CommandMenuView.SpaceRoadmap,
                context: { spaceId: maybeSpace.id },
              });
            }}
          >
            Change space roadmap
          </DropdownMenuItem>
        </>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteRoadmaps([roadmap.id]);
        }}
      >
        Delete
      </DropdownMenuItem>
    </>
  );
}

function RoadmapMenuButton({
  roadmap,
  onEdit,
  className,
}: {
  roadmap: RoadmapModel;
  onEdit: () => void;
  className?: string;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <IconButtonTrigger
        size={ButtonSize.Medium}
        buttonStyle={ButtonStyle.BareSubtle}
        icon="more"
        className={className}
      />
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
      >
        <RoadmapMenu roadmap={roadmap} closeMenu={closeMenu} onEdit={onEdit} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function FilterContents({ id, onModeChanged, entityType, onClose, options }: ContentProps) {
  const setFilters = useSetRecoilState(filterState(id));
  const user = useCurrentUser();

  const items: ListViewItem[] = [
    {
      id: 'members',
      icon: 'member',
      contents: 'Members',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Member,
          placeholder: 'Members',
        });
      },
      hotkey: assignIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'labels',
      icon: 'label',
      contents: 'Labels',
      onSelected: () => {
        onModeChanged(MenuMode.Labels);
      },
      hotkey: labelIssueKey,
      className: menuStyles.item,
    },
    ...(!options?.disableSpaces
      ? [
          {
            id: 'spaces',
            icon: 'workspace',
            contents: 'Spaces',
            onSelected: () => {
              onModeChanged(MenuMode.Space);
            },
            hotkey: spaceInitiativeKey,
            className: menuStyles.item,
          },
        ]
      : []),
    {
      id: NO_KEYNAV,
      key: 'sep-2',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'my',
      icon: 'my_work',
      contents: entityType ? `My ${entityTypeString(entityType)}s` : 'My items',
      onSelected: () => {
        setFilters(previous => {
          if (findFilter(previous, f => f.type === FilterType.Member && f.id === user.id)) {
            return removeFilter(previous, f => f.type === FilterType.Member && f.id === user.id);
          }
          return addFilter(previous, { type: FilterType.Member, id: user.id });
        });
        onClose();
      },
      hotkey: selfFilterHotKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-3',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'creator',
      icon: 'creator',
      contents: 'Creator',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.CreatedBy,
          placeholder: 'Created by',
        });
      },
      hotkey: 'b',
      className: menuStyles.item,
    },
    {
      id: 'freeText',
      icon: 'search_content',
      contents: 'Title and ID',
      onSelected: () => {
        onModeChanged(MenuMode.FreeText);
      },
      hotkey: searchKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-4',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'createdSince',
      icon: 'none',
      contents: 'Created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'after',
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'c',
      className: menuStyles.item,
    },
    {
      id: 'notCreatedSince',
      icon: 'none',
      contents: 'Not created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'before',
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'shift+c',
      className: menuStyles.item,
    },
    {
      id: 'updatedSince',
      icon: 'none',
      contents: 'Updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'after',
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'u',
      className: menuStyles.item,
    },
    {
      id: 'notUpdatedSince',
      icon: 'none',
      contents: 'Not updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'before',
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'shift+u',
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-5',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'clear',
      icon: 'exit',
      contents: 'Clear all filters',
      onSelected: () => {
        setFilters(null);
        onClose();
      },
      hotkey: 'backspace',
      className: menuStyles.item,
    },
  ];

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView items={items} />
    </KeyNavigationProvider>
  );
}

function RoadmapSpaces({ roadmap }: { roadmap: RoadmapModel }) {
  const spaceIds = useRecoilValue(spacesForRoadmapSelector(roadmap.id)).map(s => s.id);
  const maybeSpace = useMaybeSpace();
  const isPrivate = maybeSpace?.private ?? false;
  const setSpaceRoadmap = useSetSpaceRoadmap();

  if (!spaceIds) {
    return null;
  }

  return (
    <SpacesMetadata
      filterPlaceholder="Add to space"
      showRoadmap
      interactable
      onSpaceAdded={
        !isPrivate
          ? (_, spaceId) => {
              setSpaceRoadmap(spaceId, roadmap.id);
            }
          : undefined
      }
      onSpaceRemoved={
        !isPrivate
          ? (_, spaceId) => {
              setSpaceRoadmap(spaceId, null);
            }
          : undefined
      }
      className="mr8"
      spaceIds={spaceIds}
      size={MetadataSize.Medium}
      state={{ [roadmap.id]: spaceIds }}
    />
  );
}

const options = [
  {
    value: 'week' as const,
    label: 'Weekly',
  },
  {
    value: 'month' as const,
    label: 'Monthly',
  },
  {
    value: 'quarter' as const,
    label: 'Quarterly',
  },
];

export function RoadmapTopBar({ roadmap }: { roadmap: RoadmapModel }) {
  const updateRoadmaps = useUpdateRoadmaps();
  const tinyScreen = useIsTinyScreen();
  const [mode, setMode] = useRecoilState(roadmapScreenMode(roadmap.id));
  const ref = React.useRef<TextAreaHandle | null>(null);
  const maybeSpace = useMaybeSpace();
  const onEdit = React.useCallback(() => {
    ref.current?.focus();
    ref.current?.moveSelectionToEnd();
  }, []);

  const [period, setPeriod] = useRecoilState(timelineViewPeriod(roadmap.id));

  const starType = maybeSpace ? StarredType.SpaceRoadmap : StarredType.Roadmap;
  const starId = maybeSpace ? maybeSpace.id : roadmap.id;

  return (
    <div className={styles.topbar}>
      <div className="row noMinWidth">
        <DropdownMenu>
          <IconButtonTrigger
            buttonStyle={ButtonStyle.Bare}
            icon={<RoadmapIcon color={roadmap.color} />}
            className="mr4"
          />
          <DropdownMenuContent>
            <ColorPickerContent
              color={roadmap.color}
              onColorPicked={color => {
                updateRoadmaps([roadmap.id], { color: color ?? 'gray' });
              }}
            />
          </DropdownMenuContent>
        </DropdownMenu>
        <Title key={roadmap.id} roadmap={roadmap} ref={ref} />
        <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
          <RoadmapSpaces roadmap={roadmap} />
          <StarredIcon
            starred={{
              type: starType,
              id: starId,
            }}
            className="ml8"
          />
        </HideIfSmallerThan>
        <RoadmapMenuButton roadmap={roadmap} onEdit={onEdit} className="ml8" />
        {roadmap.roadmapType === RoadmapType.Column && (
          <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
            <FilterMenu2
              className="ml8"
              id={roadmap.id}
              entityType="Initiative"
              compact={tinyScreen}
              DefaultContents={InitiativeContents}
              options={{
                orgLevel: true,
              }}
            />
          </HideIfSmallerThan>
        )}
      </div>
      {roadmap.roadmapType === RoadmapType.Timeline && (
        <DropdownMenu>
          <DropdownMenuTrigger
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
            asChild
          >
            <Button>
              <span className="mr8">{options.find(o => o.value === period)?.label}</span>
              <Icon style={{ fill: 'var(--grayA8)' }} icon={'arrow_down'} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="start"
          >
            {options.map(option => (
              <DropdownMenuCheckboxItem
                checked={option.value === period}
                key={option.value}
                onSelect={() => setPeriod(option.value)}
              >
                {option.label}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {roadmap.roadmapType === RoadmapType.Column && (
        <div className="row">
          <SegmentedControl
            className="ml8"
            label="board and list toggle"
            value={mode}
            onValueChanged={m => setMode(m as Mode)}
            options={[
              {
                id: Mode.Board,
                icon: Mode.Board,
                label: `Change to mode ${Mode.Board}`,
                tooltip:
                  mode === Mode.Board ? undefined : (
                    <>
                      Board view <KeyboardShortcut shortcut={toggleListViewKey} />
                    </>
                  ),
              },
              {
                id: Mode.List,
                icon: Mode.List,
                label: `Change to mode ${Mode.List}`,
                tooltip:
                  mode === Mode.List ? undefined : (
                    <>
                      List view <KeyboardShortcut shortcut={toggleListViewKey} />
                    </>
                  ),
              },
            ]}
          />
          <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
            <Popover
              contentOptions={{ align: 'end' }}
              content={<NewRoadmapSharingPopover roadmapId={roadmap.id} />}
            >
              <div>
                {!roadmap.shared && (
                  <IconButton className="ml8" icon="public" buttonStyle={ButtonStyle.BareSubtle} />
                )}
                {roadmap.shared && (
                  <Button className="ml8" icon="public" buttonStyle={ButtonStyle.SecondarySubtle}>
                    Public
                  </Button>
                )}
              </div>
            </Popover>
            <MetadataConfigurationButton
              className="ml8"
              excludeOptions={[...initiativeDefaultExcludeOptions, 'roadmaps']}
              boardId={roadmap.id}
            />
          </HideIfSmallerThan>
        </div>
      )}
    </div>
  );
}
function TitleComponent(
  { roadmap }: { roadmap: RoadmapModel },
  ref?: React.ForwardedRef<TextAreaHandle | null>
) {
  const [title, setTitle] = React.useState(documentFromString(roadmap.name));
  const titleRef = React.useRef(title);
  titleRef.current = title;
  const textAreaRef = React.useRef<TextAreaHandle | null>(null);
  const roadmapRef = React.useRef(roadmap);
  roadmapRef.current = roadmap;
  const updateRoadmaps = useUpdateRoadmaps();

  const [hasFocus, setHasFocus] = React.useState(false);

  React.useEffect(() => {
    const doc = documentFromString(roadmap.name);
    setTitle(doc);
  }, [roadmap.id, roadmap.name]);

  function submit() {
    if (roadmapRef.current.deleted) {
      return;
    }

    const newName = stringifyDocument(titleRef.current!);
    if (newName !== roadmapRef.current.name) {
      updateRoadmaps([roadmapRef.current.id], {
        name: newName,
      });
    }
  }

  return (
    <div
      className={cn(styles.roadmapTitle, {
        [styles.focused]: hasFocus,
        [styles.empty]: isDocumentEmpty(title),
      })}
    >
      <TextArea
        oneLine
        maxLength={1024}
        tabIndex={-1}
        ref={r => {
          textAreaRef.current = r;
          if (ref) {
            if (isFunction(ref)) {
              ref(r);
            } else {
              ref.current = r;
            }
          }
        }}
        initialValue={title}
        onChange={v => {
          setTitle(v);
        }}
        className={'fs-exclude'}
        placeholder="Give this roadmap a title"
        onFocus={() => {
          scrollTo({ top: 0 });
          setHasFocus(true);
        }}
        onBlur={() => {
          submit();
          setHasFocus(false);
        }}
        onKeyDown={e => {
          if (e.key === 'ArrowDown') {
            e.stopPropagation();
          }
          if (e.key === 'ArrowUp') {
            e.stopPropagation();
          }

          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            const resetValue = documentFromString(roadmapRef.current.name);
            textAreaRef.current?.prepareForNewValue(resetValue);
            setTitle(resetValue);
            setTimeout(() => textAreaRef.current?.blur(), 50);
          }

          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            textAreaRef.current?.blur();
          }
        }}
      />
    </div>
  );
}
export const Title = React.forwardRef(TitleComponent);
