import cn from 'classnames';
import * as React from 'react';
import { useCustomEmojis } from '../../syncEngine/selectors/organizations';
import { allEmojisById } from '../../utils/emoji';
import styles from './emoji.module.scss';

export enum EmojiSize {
  Emoji20 = 20,
  Emoji28 = 28,
  Emoji50 = 50,
}

export function Emoji({
  emoji,
  className,
  style,
  size = EmojiSize.Emoji20,
}: {
  emoji: string;
  className?: string;
  style?: React.CSSProperties;
  size?: EmojiSize;
}) {
  const customEmojis = useCustomEmojis();

  return (
    <span
      className={cn(
        styles.emoji,
        {
          [styles.emoji20]: size === EmojiSize.Emoji20,
          [styles.emoji28]: size === EmojiSize.Emoji28,
          [styles.emoji50]: size === EmojiSize.Emoji50,
        },
        className
      )}
      style={style}
    >
      {customEmojis[emoji] ? (
        <img src={customEmojis[emoji].url} />
      ) : (
        allEmojisById.get(emoji)?.unicode ?? ''
      )}
    </span>
  );
}
