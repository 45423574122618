import { Editor, Node, NodeEntry, Range } from 'slate';
import { EditorType } from '../types';

export function fakeSelection(editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [, path] = entry;
  if (!path.length) {
    return [];
  }

  const selection = editor.fakeSelection();
  if (!selection) {
    return [];
  }

  const nodeRange = Editor.range(editor, path);
  const intersection = Range.intersection(nodeRange, selection);
  if (!intersection) {
    return [];
  }

  return [
    {
      ...intersection,
      fakeSelection: true,
    },
  ];
}
