import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Screen } from '../../../components/screen';
import { useOrganization } from '../../../contexts/organizationContext';
import { FetchedMarker } from '../../../graphql/smartLoad';
import { entityDeletedSelector } from '../../../syncEngine/selectors/entities';
import { initiativeIdByNumberSelector } from '../../../syncEngine/selectors/intiatives';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { NotFoundScreen } from '../../errorScreens';
import { DeletedScreen } from '../../errorScreens/deletedScreen';
import LoadingScreen from '../../loadingScreen';
import InitiativeScreen from './initiativeScreen';

export default function InitiativeScreenWrapper() {
  const organization = useOrganization();
  const match = useRouteMatch<{ initiativeNumber: string }>();
  const fetched = useRecoilValue(markerState(FetchedMarker.id));
  const initiativeId = useRecoilValue(
    initiativeIdByNumberSelector({
      organizationId: organization.id,
      initiativeNumber: match.params.initiativeNumber,
      includeDeleted: true,
    })
  );

  const issueIdRef = React.useRef(initiativeId);
  const lastKnownGoodId = initiativeId ?? issueIdRef.current;

  const entityDeleted = useRecoilValue(entityDeletedSelector(lastKnownGoodId));

  if (lastKnownGoodId && entityDeleted) {
    return (
      <Screen>
        <DeletedScreen type={'initiative'} />
      </Screen>
    );
  }

  if (!initiativeId) {
    if (fetched) {
      return (
        <Screen>
          <NotFoundScreen />
        </Screen>
      );
    }
    return <LoadingScreen />;
  }

  return <InitiativeScreen initiativeId={initiativeId} />;
}
