import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  LabelPicker,
  entitiesToLabelPickerState,
} from '../../../components/new/pickers/labelPicker';
import {
  useAddLabelsToEntities,
  useRemoveLabelsFromEntities,
} from '../../../syncEngine/actions/entities';
import { useUpdateTodoOutsideOfEditor } from '../../../syncEngine/actions/todos';
import {
  LabelableEntity,
  entitiesSelector,
  isLabelableEntity,
} from '../../../syncEngine/selectors/entities';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { todoSelector } from '../../../syncEngine/selectors/todos';
import styles from '../commandMenu.module.scss';

export function ChangeLabelsContents({
  onClose,
  entityIds,
  spaceId,
  orgLevel,
}: {
  onClose: () => void;
  entityIds: string[];
  spaceId: string;
  orgLevel?: boolean;
}) {
  const entities = useRecoilValue(entitiesSelector(entityIds)).filter(e =>
    isLabelableEntity(e)
  ) as LabelableEntity[];
  const space = useRecoilValue(spaceSelector(spaceId));
  const addLabelsToEntities = useAddLabelsToEntities();
  const removeLabelsFromEntities = useRemoveLabelsFromEntities();
  if (!space && !orgLevel) {
    return null;
  }

  return (
    <div className="rowStretch fullWidth fullHeight">
      <LabelPicker
        listMode
        orgLevel={orgLevel}
        filterClassName={styles.inputDiv}
        space={!orgLevel ? space! : undefined}
        state={entitiesToLabelPickerState(entities)}
        onLabelAdded={(issueIds, labelId) => {
          addLabelsToEntities(issueIds, [labelId]);
        }}
        onLabelRemoved={(issueIds, labelId) => {
          removeLabelsFromEntities(issueIds, [labelId]);
        }}
        onDone={onClose}
      />
    </div>
  );
}

export function ChangeTodoLabelsContents({
  onClose,
  todoId,
  spaceId,
}: {
  onClose: () => void;
  todoId: string;
  spaceId: string;
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const todo = useRecoilValue(todoSelector(todoId));
  const updateTodo = useUpdateTodoOutsideOfEditor(todo?.entityId ?? '');

  if (!todo || !space) {
    return null;
  }

  return (
    <div className="rowStretch fullWidth fullHeight">
      <LabelPicker
        listMode
        filterClassName={styles.inputDiv}
        space={space}
        state={{ [todo.id]: todo.labelIds }}
        onLabelAdded={(_, labelId) => {
          updateTodo(todo.id, { labelsToAdd: [labelId] });
        }}
        onLabelRemoved={(_, labelId) => {
          updateTodo(todo.id, { labelsToRemove: [labelId] });
        }}
        onDone={onClose}
      />
    </div>
  );
}
