import React from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { SpaceRoadmap } from '../../../../graphql__generated__/graphql';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { generateKey, generateLookupKey } from '../../../shared/utils/id';
import { randomString } from '../../../shared/utils/random';
import { between } from '../../../shared/utils/sorting';
import { issueTerm } from '../../../shared/utils/terms';
import { cleanStringForPath } from '../../../shared/utils/utils';
import {
  boardColumnsByBoard,
  boardsBySpace,
  issueStatusesBySpace,
  issuesBySpace,
  organizationMembersByUser,
  spaceRoadmapsBySpace,
  spacesByOrganization,
} from '../../../sync/__generated/indexes';
import {
  BackgroundType,
  Board,
  BoardColumn,
  Issue,
  IssueStatus,
  IssueStatusType,
  OrganizationMember,
  Space,
} from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { BoardMarker } from '../../graphql/smartLoad';
import { trackerEvent } from '../../tracker';
import { effortLevelsForSpaceSelector } from '../selectors/effortLevels';
import { entitiesForSpaceSelector } from '../selectors/entities';
import { impactLevelsForSpaceSelector } from '../selectors/impactLevels';
import { initiativeSpacesForSpaceSelector } from '../selectors/intiatives';
import { todoStatusForSpaceSelector } from '../selectors/issueStatuses';
import { labelsForSpaceSelector } from '../selectors/labels';
import { roadmapsForSpaceSelector, spaceRoadmapsForSpaceSelector } from '../selectors/roadmaps';
import { spaceSelector } from '../selectors/spaces';
import { syncEngineState, useStateTransaction } from '../state';
import { createBoardHelper } from './boards';
import { indexHelper, updateSortableSorts } from './helpers';
import { createDefaultImpactEffortLevels } from './impactEffortLevels';
import { createStatusHelper } from './issueStatuses';

export function useCreateSpace() {
  const modelManager = useModelManager();
  const organization = useOrganization();
  const user = useCurrentUser();
  const stateTransaction = useStateTransaction();

  return (name: string, favorite?: boolean, key?: string) => {
    const createdSpace = modelManager.transaction((tx, { get, getIndex }) => {
      const spaceIds = getIndex(spacesByOrganization, organization.id);
      const spaces = filterNotDeletedNotNull(spaceIds.map(spaceId => get<Space>(spaceId)));
      const sort = between({ after: spaces[spaces.length - 1]?.sort });

      const lookupKey = generateLookupKey();

      const space: SyncEngineCreate<Space> = {
        __typename: 'Space',
        actorId: user.id,
        name,
        organizationId: organization.id,
        sort,
        key:
          key ??
          generateKey(
            name,
            spaces.map(s => s.key)
          ),
        slug: `${lookupKey}-${cleanStringForPath(name, 'space')}`,
        automationEvents: {
          git_closed: null,
          git_in_progress: null,
          git_pull_request: null,
        },
        redirects: {},
        tutorial: false,
        roadmapEnabled: false,
        cyclesEnabled: false,
        publicRoadmap: false,
        activeCycleId: null,
        upcomingCycleId: null,
        publicRoadmapMetadata: false,
        backgroundType: BackgroundType.Gradient,
        backgroundConfig: '{}',
        private: false,
        members: [],
        autoArchive: null,
        staleIssues: null,
        defaultDoneStatusId: null,
        defaultNewStatusId: null,
        cycleDuration: 1,
        cycleCooldown: 0,
        cycleStartDay: 1,
        addToCurrentCycle: false,
        utcOffset: 0,
        timezone: 'Etc/GMT',
      };

      const spaceResult = tx.create<Space>(space);

      const todoStatus = createStatusHelper(tx, spaceResult.id, 'Todo', IssueStatusType.Todo);
      const inProgressStatus = createStatusHelper(
        tx,
        spaceResult.id,
        'In progress',
        IssueStatusType.InProgress
      );
      const doneStatus = createStatusHelper(tx, spaceResult.id, 'Done', IssueStatusType.Done);

      tx.update<Space>(spaceResult.id, {
        defaultDoneStatusId: doneStatus.id,
        defaultNewStatusId: todoStatus.id,
      });

      createStatusHelper(tx, spaceResult.id, 'Archived', IssueStatusType.Archived);

      createBoardHelper(
        tx,
        spaceResult.id,
        'Current',
        'current',
        between({}),
        IssueStatusType.InProgress,
        [todoStatus, inProgressStatus, doneStatus]
      );

      createDefaultImpactEffortLevels(tx, organization.id, spaceResult.id);

      trackerEvent('Space Created', { id: spaceResult.id });

      const orgMemberships = indexHelper<OrganizationMember>(
        { get, getIndex },
        organizationMembersByUser,
        user.id
      );
      const orgMembership = orgMemberships.find(org => org?.organizationId === organization.id);

      if (orgMembership && favorite) {
        tx.update<OrganizationMember>(orgMembership.id, {
          favoriteSpaceIds: [...(orgMembership?.favoriteSpaceIds ?? []), spaceResult.id],
        });
      }

      return spaceResult;
    });

    stateTransaction(({ set }) => {
      set([
        BoardMarker.create(createdSpace.id, 'current'),
        BoardMarker.create(createdSpace.id, 'backlog'),
        BoardMarker.create(createdSpace.id, 'archive'),
      ]);
    });

    return createdSpace;
  };
}

export function useUpdateSpaces() {
  const modelManager = useModelManager();
  return (spaceIds: string[], update: Omit<SyncEngineUpdateWithoutDelete<Space>, 'sort'>) => {
    modelManager.transaction((tx, { get }) => {
      for (const spaceId of spaceIds) {
        const space = get<Space>(spaceId);
        tx.update<Space>(spaceId, update);

        if (update.timezone) {
          trackerEvent('Space Updated', {
            id: spaceId,
            type: 'Timezone',
            timezone: update.timezone,
          });
        }
        // when cycles are first enabled, we'll capture this in the cycle modal
        if (space?.cyclesEnabled) {
          if (update.cycleStartDay) {
            trackerEvent('Cycle Settings Updated', {
              type: 'Start Day',
              startDay: update.cycleStartDay,
            });
          }
          if (update.cycleDuration) {
            trackerEvent('Cycle Settings Updated', {
              type: 'Duration',
              duration: update.cycleDuration,
            });
          }
          if (update.cycleCooldown) {
            trackerEvent('Cycle Settings Updated', {
              type: 'Cooldown',
              cooldown: update.cycleCooldown,
            });
          }
          if (update.addToCurrentCycle) {
            trackerEvent('Cycle Settings Updated', {
              type: 'Automatically Add',
              cooldown: update.addToCurrentCycle,
            });
          }
        }
      }
    });
  };
}

export function useDeleteSpaces() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (spaceIds: string[], force = false) => {
    if (!force) {
      const confirmed = await confirm(
        `Delete space${spaceIds.length > 1 ? 's' : ''}`,
        `Are you sure you want to delete ${
          spaceIds.length > 1 ? 'these spaces' : 'this space'
        }? There is no way to undo this operation`,
        {
          label: 'Delete',
          destructive: true,
        }
      );

      if (!confirmed) {
        return [];
      }
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get }) => {
      for (const spaceId of spaceIds) {
        const space = get<Space>(spaceId);
        if (!space || space.deleted) {
          continue;
        }

        tx.update<Space>(spaceId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
          key: `!${randomString(3)}`,
        });

        deleted.push(spaceId);
        trackerEvent('Space Deleted', {
          id: space.id,
        });
      }
    });

    return deleted;
  };
}

export function useAddBacklog() {
  const modelManager = useModelManager();
  const stateTransaction = useStateTransaction();

  return (spaceId: string) => {
    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;

      const statusIds = getIndex(issueStatusesBySpace, spaceId);
      const statuses = filterNotDeletedNotNull(
        statusIds.map(statusId => get<IssueStatus>(statusId))
      );
      const todoStatuses = statuses.filter(status => status.statusType === IssueStatusType.Todo);

      const boardIds = getIndex(boardsBySpace, spaceId);
      const boards = filterNotDeletedNotNull(boardIds.map(boardId => get<Board>(boardId)));
      const sort = between({ before: boards[0]?.sort });

      const backlogStatus = createStatusHelper(tx, spaceId, 'Inbox', IssueStatusType.Backlog);

      tx.update<Space>(spaceId, { defaultNewStatusId: backlogStatus.id });

      createBoardHelper(tx, spaceId, 'Backlog', 'backlog', sort, IssueStatusType.Backlog, [
        backlogStatus,
        ...todoStatuses,
      ]);

      trackerEvent('Backlog Added', { id: spaceId });
    });

    stateTransaction(({ set }) => {
      set([BoardMarker.create(spaceId, 'backlog')]);
    });
  };
}

export function useRemoveBacklog() {
  const modelManager = useModelManager();
  const space = useSpace();
  const todoStatus = useRecoilValue(todoStatusForSpaceSelector(space?.id));
  const { confirm } = useConfirmation();

  return async (spaceId: string, silently = false) => {
    if (!silently) {
      const confirmed = await confirm(
        `Sure you want to remove the backlog from "${space.name}"?`,
        `All ${issueTerm}s in the backlog will be moved to ${todoStatus?.name ?? 'Todo'}`,
        {
          label: 'Remove Backlog',
          destructive: true,
        }
      );
      if (!confirmed) {
        return;
      }
    }

    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const space = get<Space>(spaceId);
      if (!space) {
        return;
      }

      // get the backlog board and columns
      const boards = indexHelper<Board>(getters, boardsBySpace, spaceId);
      const backlogBoard = boards.find(b => b.key === 'backlog');

      if (!backlogBoard) {
        return;
      }

      const columns = indexHelper<BoardColumn>(getters, boardColumnsByBoard, backlogBoard.id);

      // find the backlog statuses and the todo status to move to
      const statuses = indexHelper<IssueStatus>(getters, issueStatusesBySpace, spaceId);
      const todoStatus = statuses.find(status => status.statusType === IssueStatusType.Todo);
      const backlogColumns = columns.filter(
        column =>
          statuses.find(status => status.id === column.statusId)?.statusType ===
          IssueStatusType.Backlog
      );
      const backlogStatuses = backlogColumns.map(column => get<IssueStatus>(column.statusId)!);

      if (!backlogStatuses.length || !todoStatus) {
        return;
      }

      const backlogStatusIds = backlogStatuses.map(s => s.id);
      const todoStatusId = todoStatus.id;

      // find the issuess currently in a backlog status
      const issues = indexHelper<Issue>(getters, issuesBySpace, spaceId);
      const backlogIssues = issues.filter(i => backlogStatusIds.includes(i.statusId));
      const todoIssues = issues.filter(i => i.statusId === todoStatusId);

      // move the issues to todo
      let sort = between({ after: todoIssues[todoIssues.length - 1]?.sort });
      for (const backlogIssue of backlogIssues) {
        tx.update<Issue>(backlogIssue.id, {
          sort,
          statusId: todoStatusId,
        });
        sort = between({ after: sort });
      }

      // make the todo column the default
      tx.update<Space>(spaceId, { defaultNewStatusId: todoStatusId });

      // Delete the columns in the backlog board
      for (const backlogColumn of columns) {
        tx.update<BoardColumn>(backlogColumn.id, { deleted: true });
      }

      for (const backlogStatus of backlogStatuses) {
        tx.update<IssueStatus>(backlogStatus.id, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });
      }

      // clean up the backlog board
      tx.update<Board>(backlogBoard.id, {
        deleted: true,
        name: `__deleted__${randomString(8)}`,
        key: `__deleted__${randomString(8)}`,
      });
    });
  };
}

export function useUpdateSpaceSorts() {
  const modelManager = useModelManager();
  const organization = useOrganization();

  return (spaceIds: string[], afterSpaceId?: string, beforeSpaceId?: string) => {
    modelManager.transaction((tx, getters) => {
      const spaces = indexHelper<Space>(getters, spacesByOrganization, organization.id);
      const updates = updateSortableSorts(spaces, spaceIds, afterSpaceId, beforeSpaceId);
      for (const spaceId in updates) {
        tx.update<Space>(spaceId, { sort: updates[spaceId] });
      }
    });
  };
}

export function useCleanupAfterSpaceDeletion() {
  return useRecoilCallback(({ snapshot, transact_UNSTABLE }) => (spaceId: string) => {
    const entities = snapshot.getLoadable(entitiesForSpaceSelector(spaceId)).getValue();
    const labels = snapshot.getLoadable(labelsForSpaceSelector(spaceId)).getValue();
    const impactLevels = snapshot.getLoadable(impactLevelsForSpaceSelector(spaceId)).getValue();
    const effortLevels = snapshot.getLoadable(effortLevelsForSpaceSelector(spaceId)).getValue();
    const initiativeSpaces = snapshot
      .getLoadable(initiativeSpacesForSpaceSelector(spaceId))
      .getValue();
    const spaceRoadmaps = snapshot.getLoadable(spaceRoadmapsForSpaceSelector(spaceId)).getValue();

    transact_UNSTABLE(({ set }) => {
      for (const entity of entities) {
        set(syncEngineState(entity.id), null);
      }
      for (const label of labels) {
        set(syncEngineState(label.id), null);
      }
      for (const impact of impactLevels) {
        set(syncEngineState(impact.id), null);
      }
      for (const effort of effortLevels) {
        set(syncEngineState(effort.id), null);
      }
      for (const initiativeSpace of initiativeSpaces) {
        set(syncEngineState(initiativeSpace.id), null);
      }
      for (const spaceRoadmap of spaceRoadmaps) {
        set(syncEngineState(spaceRoadmap.id), null);
      }
    });
  });
}

export function useSetSpaceRoadmap() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const getSpace = useRecoilCallback(
    ({ snapshot }) =>
      (spaceId: string) => {
        return snapshot.getLoadable(spaceSelector(spaceId)).getValue();
      },
    []
  );
  const getSpaceRoadmap = useRecoilCallback(
    ({ snapshot }) =>
      (spaceId: string) => {
        return snapshot.getLoadable(roadmapsForSpaceSelector(spaceId)).getValue()[0];
      },
    []
  );

  return async (spaceId: string, roadmapId: string | null, force?: boolean) => {
    const space = getSpace(spaceId);
    if (!space) {
      return;
    }
    const spaceRoadmap = getSpaceRoadmap(spaceId);

    if (spaceRoadmap && !force) {
      const title = roadmapId ? (
        <>
          <span className="semiBold">{space.name}</span> already has a roadmap attached. <br /> Are
          you sure you want to change it?
        </>
      ) : (
        'Remove roadmap from space'
      );

      const content = roadmapId ? (
        <div>
          A space can have only one attached roadmap, and{' '}
          <span className="semiBold">{space.name}</span> already has one. Changing it will replace
          the current roadmap.
        </div>
      ) : (
        <div>
          Are you sure you want to remove <span className="semiBold">{spaceRoadmap.name}</span> from{' '}
          <span>{space.name}</span> space?
        </div>
      );

      const label = roadmapId ? 'Change space roadmap' : 'Remove space roadmap';

      const confirmed = await confirm(title, content, {
        label,
        destructive: true,
      });

      if (!confirmed) {
        return;
      }
    }

    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const space = get<Space>(spaceId);
      if (!space) {
        return;
      }

      const spaceRoadmaps = indexHelper<SpaceRoadmap>(getters, spaceRoadmapsBySpace, space.id);
      for (const spaceRoadmap of spaceRoadmaps) {
        tx.update<SpaceRoadmap>(spaceRoadmap.id, {
          deleted: true,
        });
      }
      if (roadmapId) {
        tx.create<SpaceRoadmap>({
          spaceId,
          roadmapId,
          __typename: 'SpaceRoadmap',
          sort: 'm', // doesn't actually matter for now
        });
      }
    });
  };
}
