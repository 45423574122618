import * as React from 'react';

export enum KeyHold {
  Press,
  Release,
}

export interface HotkeyCommand {
  description?: string;
  global?: boolean;
  unscoped?: boolean;
  hotkey: string;
  handler: (e?: KeyboardEvent, released?: KeyHold) => void;
  aliases?: string[];
  priority?: number;
  hold?: boolean;
  id: string;
}

export interface HotkeyContext {
  registerHotkeyCommand(command: HotkeyCommand): void;
  unregisterHotkeyCommand(command: HotkeyCommand): void;
  depth: number;
}

export const hotkeyContext = React.createContext<HotkeyContext | null>(null);

export function useHotkeys(): HotkeyContext {
  return React.useContext(hotkeyContext)!;
}
