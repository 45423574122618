import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Issue } from '../../../../sync/__generated/models';
import { DefaultFocusElement } from '../../../components/defaultFocusElement';
import { ListView, ListViewItem, NO_KEYNAV } from '../../../components/new/listView';
import styles from '../../../components/new/menu/menu.module.scss';
import { EntityItem } from '../../../components/new/pickers/entityPicker';
import { recentLocalEntitiesSelector, useEntityPath } from '../../../syncEngine/selectors/entities';

function hotkey(index: number) {
  if (index === 9) {
    return '0';
  }
  if (index < 9) {
    return `${index + 1}`;
  }
  return undefined;
}

export function RecentContents({ onClose }: { onClose: () => void }) {
  const recentEntities = useRecoilValue(recentLocalEntitiesSelector) as Array<Issue>;
  const history = useHistory();
  const entityPath = useEntityPath();

  const entityItems: ListViewItem[] = recentEntities.map((entity, index) => ({
    id: entity.id,
    contents: (
      <div
        className="row"
        style={
          {
            gap: `var(--menu-item-gap)`,
          } as React.CSSProperties
        }
      >
        <EntityItem entityId={entity.id} />
      </div>
    ),
    hotkey: hotkey(index),
    onSelected: () => {
      onClose();
      const path = entityPath(entity.id);
      if (!path) {
        return;
      }
      history.push({
        pathname: path,
        state: {
          backUrl: location.pathname,
          backSearch: location.search,
        },
      });
    },
  }));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <DefaultFocusElement />
      <ListView
        className={styles.content}
        itemClassName={styles.item}
        items={[
          {
            id: NO_KEYNAV,
            key: 'header',
            contents: <div className={styles.listItemHeader}>Open recent item</div>,
          },
          ...entityItems,
        ]}
      />
    </div>
  );
}
