import { first, last, uniq } from 'lodash';
import * as React from 'react';
import { filterNotNull } from '../../../../shared/utils/convenience';
import { CommandGroup } from '../../../commands';
import {
  alternateComboKey,
  createNewCardKey,
  mainComboKey,
  vimDown,
  vimUp,
} from '../../../utils/config';
import { CopyAndPasteHotkeys } from '../copyAndPaste';
import { CustomCommand } from '../customCommand';
import { Hotkey } from '../hotkey';
import { useClearSelection, useKeyNavigationState } from '../keyNavigation';

export interface CopyAndPasteProperties {
  onCopy?: (ids: string[]) => Array<{ id: string; name: string; url: string }>;
  onPaste?: (sectionId: string, index: number, pastedIds: string[]) => void;
}

export function Hotkeys({
  itemsRef,
  sectionsRef,
  gridRef,
  commandGroup,
  onNewItem,
  onMoveItems,
  onCopy,
  onPaste,
}: {
  itemsRef: React.RefObject<Record<string, string[]>>;
  sectionsRef: React.RefObject<string[]>;
  gridRef: React.RefObject<string[]>;
  commandGroup?: CommandGroup;
  onNewItem?: (newCardPosition: { sectionId: string; index: number }) => void;
  onMoveItems?: (ids: string[], toSection: string, toIndex: number) => void;
} & CopyAndPasteProperties) {
  const { focused, selected } = useKeyNavigationState();
  const clearSelection = useClearSelection();

  const create = React.useCallback(
    (after: boolean) => {
      if (!focused || !itemsRef.current || !gridRef.current) {
        return;
      }

      // headers, placeholders
      if (focused.includes('-')) {
        const [, sectionId] = focused.split('-');
        clearSelection();
        onNewItem?.({ sectionId, index: 0 });
        return;
      }

      const focusedWithSection = gridRef.current.find(id => id.endsWith(focused));
      if (!focusedWithSection) {
        return;
      }
      const [sectionId, itemId] = focusedWithSection.split('/');
      const index = itemsRef.current[sectionId].indexOf(itemId) ?? -1;
      if (index !== -1) {
        clearSelection();
        onNewItem?.({ sectionId, index: after ? index + 1 : index });
      }
    },
    [focused, itemsRef, gridRef, onNewItem, clearSelection]
  );

  const move = React.useCallback(
    (direction: 'up' | 'down' | 'left' | 'right', moveToTopOrBottom = false) => {
      if (
        !itemsRef.current ||
        !sectionsRef.current ||
        !gridRef.current ||
        (!selected && !focused)
      ) {
        return;
      }

      const itemIds = filterNotNull(selected ?? [focused]).filter(id => !id.includes('-'));
      if (!itemIds.length) {
        return;
      }

      // make sure everything is in the same section
      const fullItemIds = filterNotNull(
        itemIds.map(itemId => gridRef.current!.find(id => id.endsWith(itemId)))
      );
      if (fullItemIds.length !== itemIds.length) {
        return;
      }
      const itemsWithSections = fullItemIds.map(itemId => itemId.split('/'));
      const sections = uniq(itemsWithSections.map(([sectionId]) => sectionId));
      if (sections.length > 1) {
        return;
      }

      const sectionId = sections[0];
      const sectionIndex = sectionsRef.current.indexOf(sectionId);
      const previousSection = sectionsRef.current[sectionIndex - 1];
      const nextSection = sectionsRef.current[sectionIndex + 1];

      const rowIndexes = itemIds.map(itemId => itemsRef.current![sectionId].indexOf(itemId));
      const firstRowIndex = first(rowIndexes) ?? -1;
      const lastRowIndex = last(rowIndexes) ?? -1;

      const isTop = firstRowIndex === 0;
      const isBottom = lastRowIndex === itemsRef.current[sectionId].length - 1;

      if (sectionIndex === -1 || firstRowIndex === -1 || lastRowIndex === -1) {
        return;
      }

      if (direction === 'up') {
        // if we're at the top of our section, move up to the previous section
        if (isTop) {
          if (moveToTopOrBottom || itemIds.length > 1) {
            return;
          }
          if (previousSection) {
            onMoveItems?.(
              itemIds,
              previousSection,
              (itemsRef.current[previousSection] ?? []).length
            );
          }
          return;
        }

        onMoveItems?.(itemIds, sectionId, moveToTopOrBottom ? 0 : firstRowIndex - 1);
      } else if (direction === 'down') {
        // if we're at the bottom of our section, move down to the next section
        if (isBottom) {
          if (moveToTopOrBottom || itemIds.length > 1) {
            return;
          }
          if (nextSection) {
            onMoveItems?.(itemIds, nextSection, 0);
          }
          return;
        }

        onMoveItems?.(
          itemIds,
          sectionId,
          moveToTopOrBottom ? itemsRef.current[sectionId].length - 1 : lastRowIndex + 1
        );
      }
    },
    [focused, selected, itemsRef, sectionsRef, gridRef, onMoveItems]
  );

  const paste = React.useCallback(
    (ids: string[]) => {
      if (!focused || !itemsRef.current || !gridRef.current) {
        return;
      }

      // headers, placeholders
      if (focused.includes('-')) {
        const [, sectionId] = focused.split('-');
        onPaste?.(sectionId, 0, ids);
        return;
      }

      const focusedWithSection = gridRef.current.find(id => id.endsWith(focused));
      if (!focusedWithSection) {
        return;
      }
      const [sectionId, itemId] = focusedWithSection.split('/');
      const index = itemsRef.current[sectionId].indexOf(itemId) ?? -1;
      if (index !== -1) {
        onPaste?.(sectionId, index, ids);
      }
    },
    [focused, itemsRef, gridRef, onPaste]
  );

  return (
    <>
      {onNewItem && (
        <>
          <CustomCommand
            command={{
              id: 'create-new-card',
              hotkey: createNewCardKey,
              group: CommandGroup.Board,
              description: `Create new`,
              priority: 9,
              handler: () => {
                create(false);
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'create-new-card-below',
              hotkey: `shift+${createNewCardKey}`,
              group: CommandGroup.Board,
              description: `Create new below`,
              priority: 9,
              handler: () => {
                create(true);
              },
            }}
          />
        </>
      )}
      {onMoveItems && (
        <>
          <Hotkey
            hotkey={`${alternateComboKey}+${vimDown}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('down');
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+${alternateComboKey}+${vimDown}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('down', true);
            }}
          />
          <Hotkey
            hotkey={`${alternateComboKey}+${vimUp}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('up');
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+${alternateComboKey}+${vimUp}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('up', true);
            }}
          />
          <CustomCommand
            command={{
              id: 'move-up',
              group: commandGroup ?? CommandGroup.Other,
              description: 'Move up',
              hotkey: `${alternateComboKey}+up`,
              handler: () => {
                move('up');
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-top',
              group: commandGroup ?? CommandGroup.Other,
              description: 'Move to top',
              hotkey: `${mainComboKey}+${alternateComboKey}+up`,
              handler: () => {
                move('up', true);
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-down',
              group: commandGroup ?? CommandGroup.Other,
              description: 'Move down',
              hotkey: `${alternateComboKey}+down`,
              handler: () => {
                move('down');
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-bottom',
              group: commandGroup ?? CommandGroup.Other,
              description: 'Move to bottom',
              hotkey: `${mainComboKey}+${alternateComboKey}+down`,
              handler: () => {
                move('down', true);
              },
            }}
          />
        </>
      )}
      {onCopy && <CopyAndPasteHotkeys onCopy={onCopy} onPaste={paste} />}
    </>
  );
}
