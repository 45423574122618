import cn from 'classnames';
import * as React from 'react';
import { isMac, isMobileOS } from '../../utils/config';
import styles from './keyboardShortcut.module.scss';

function tokenize(keys: string): string[] {
  const result: string[] = [];
  let current = '';

  for (const c of keys) {
    if (c === '+' || c === ' ') {
      if (current) {
        result.push(current);
        current = '';
      }
      result.push(c);
      continue;
    }

    current += c;
  }

  if (current) {
    result.push(current);
  }
  return result;
}

export function KeyboardShortcut({
  shortcut,
  className,
  style,
}: {
  shortcut: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  if (isMobileOS) {
    return null;
  }
  const keys = shortcut
    .replace('meta', isMac ? '⌘' : '')
    .replace('shift', '⇧')
    .replace('escape', 'esc')
    .replace('backspace', isMac ? '⌫' : '⟵');

  const tokenized = tokenize(keys);
  const renderedKeys =
    tokenized.length === 1 && tokenized[0] === '+' ? (
      <kbd
        className={cn(styles.keyboardKey, {
          [styles.fixedWidth]: true,
        })}
        style={style}
      >
        +
      </kbd>
    ) : (
      tokenized.map((key, index) => {
        switch (key) {
          case '+':
            return <div key={`key-${index}`} className={styles.spacer}></div>;
          case ' ':
            return (
              <div key={`key-${index}`} className={styles.connector}>
                then
              </div>
            );
          default:
            return (
              <kbd
                key={`key-${index}`}
                className={cn(styles.keyboardKey, {
                  [styles.fixedWidth]: key.length === 1,
                })}
                style={style}
              >
                {key}
              </kbd>
            );
        }
      })
    );

  return <div className={cn(styles.shortcut, className)}>{renderedKeys}</div>;
}
