import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { assigneeTerm, issueTerm } from '../../../../shared/utils/terms';
import {
  Activity as ActivityModel,
  CreatedFromProvenance,
  DuplicatedProvenance,
  EntityProvenanceType,
  ImportedProvenance,
} from '../../../../sync/__generated/models';
import { metaDataForCreatedActivitySelector } from '../../../syncEngine/selectors/activities';
import {
  entitySelector,
  entityType,
  entityTypeString,
  useEntityPath,
} from '../../../syncEngine/selectors/entities';
import { isIssue, spaceKeyForIssueSelector } from '../../../syncEngine/selectors/issues';
import { stringifyIntegrationType } from '../../../utils/integrations';
import ActorName from '../../actorName';
import ExternalLink from '../../externalLink';
import { Tooltip } from '../tooltip';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function CreatedActivityComponent({ activity }: { activity: ActivityModel }) {
  const entityPath = useEntityPath();
  const createdMetaData = useRecoilValue(metaDataForCreatedActivitySelector(activity.id));
  const entity = useRecoilValue(entitySelector(activity.entityId));

  const createdFromEntityId =
    entity &&
    isIssue(entity) &&
    (entity.provenance?.provenanceType === EntityProvenanceType.CreatedFrom ||
      entity.provenance?.provenanceType === EntityProvenanceType.Duplicated)
      ? (entity.provenance as CreatedFromProvenance | DuplicatedProvenance).entityId
      : '';
  const createdFromEntity = useRecoilValue(entitySelector(createdFromEntityId));
  const createdFromSpaceKey = useRecoilValue(spaceKeyForIssueSelector(createdFromEntityId));
  const createdFromLink = entityPath(createdFromEntityId);
  const createdFromComponent = (
    <Link to={createdFromLink}>
      <span className={styles.activityHighlight}>
        {createdFromSpaceKey}-{createdFromEntity?.number}
      </span>
    </Link>
  );

  if (!entity) {
    return null;
  }

  let metadata: React.ReactNode = null;
  let hasMetadata = false;

  if (createdMetaData) {
    const {
      assignees: assigneeObjects,
      labels: labelObjects,
      issues: issueObjects,
      spaces: spaceObjects,
      roadmaps: roadmapObjects,
      entities: entityObjects,
    } = createdMetaData;

    const assignees = assigneeObjects.map(a => `${a?.username ?? 'unknown'}`);
    let addedAssignees = assignees.length > 0 ? `with ${assigneeTerm}` : '';
    if (assignees.length > 1) {
      addedAssignees += 's';
    }

    const labels = labelObjects.map(a => `${a.name}`);
    let addedLabels = labels.length > 0 ? 'with label' : '';
    if (labels.length > 1) {
      addedLabels += 's';
    }
    const labelsAnd = assignees.length && labels.length ? ' and ' : '';

    const entities = entityObjects.map(e => `${e.number} ${e.title}`);
    let addedEntities =
      entities.length > 0 ? `with ${entityTypeString(entityObjects[0].type as any)}` : '';
    if (entities.length > 1) {
      addedEntities += 's';
    }
    const entitiesAnd = (assignees.length || labels.length) && entities.length ? ' and ' : '';

    const issues = issueObjects.map(i => `${i.number} ${i.title}`);
    const issuesAnd = (assignees.length || labels.length) && issues.length ? ' and ' : '';

    const spaces = spaceObjects.map(a => `${a.name}`);
    let addedSpaces = spaces.length > 0 ? 'with space' : '';
    if (spaces.length > 1) {
      addedSpaces += 's';
    }
    const spacesAnd =
      (assignees.length || labels.length || issues.length) && spaces.length ? ' and ' : '';

    const roadmaps = roadmapObjects.map(a => `${a.name} - ${a.column}`);
    let addedRoadmaps = roadmaps.length > 0 ? 'on roadmap' : '';
    if (roadmaps.length > 1) {
      addedRoadmaps += 's';
    }
    const roadmapsAnd =
      (assignees.length || labels.length || entities.length || issues.length || spaces.length) &&
      roadmaps.length
        ? ' and '
        : '';
    hasMetadata =
      assignees.length > 0 ||
      labels.length > 0 ||
      entities.length > 0 ||
      issues.length > 0 ||
      spaces.length > 0 ||
      roadmaps.length > 0;

    metadata = (
      <>
        {assignees.length > 0 && (
          <>
            {addedAssignees}{' '}
            <span className={styles.activityHighlight}>{assignees.join(', ')}</span>
          </>
        )}
        {labels.length > 0 && (
          <>
            {labelsAnd}
            {addedLabels} <span className={styles.activityHighlight}>{labels.join(', ')}</span>
          </>
        )}
        {entities.length > 0 && (
          <>
            {entitiesAnd}
            {addedEntities} <span className={styles.activityHighlight}>{entities.join(', ')}</span>
          </>
        )}
        {issues.length > 0 && (
          <>
            {issuesAnd}
            with{' '}
            <Tooltip
              content={
                <div>
                  {issues.map((issue, index) => (
                    <div key={index}>{issue}</div>
                  ))}
                </div>
              }
            >
              <span className={styles.activityHighlight}>
                {issues.length} {issueTerm}
                {issues.length > 1 ? 's' : ''}
              </span>
            </Tooltip>
          </>
        )}
        {spaces.length > 0 && (
          <>
            {spacesAnd}
            {addedSpaces} <span className={styles.activityHighlight}>{spaces.join(', ')}</span>
          </>
        )}
        {roadmaps.length > 0 && (
          <>
            {roadmapsAnd}
            {addedRoadmaps} <span className={styles.activityHighlight}>{roadmaps.join(', ')}</span>
          </>
        )}
      </>
    );
  }

  let action = <>created this {entityType(entity)}</>;
  if (isIssue(entity) && entity.provenance) {
    if (entity.provenance.provenanceType === EntityProvenanceType.Duplicated) {
      action = (
        <>
          duplicated this {entityType(entity)} from {createdFromComponent}
        </>
      );
    }
    if (entity.provenance.provenanceType === EntityProvenanceType.CreatedFrom) {
      const provenance = entity.provenance as CreatedFromProvenance;
      let from = ' the description of ';
      if (provenance.createdFromCommentId) {
        from = ' a comment in ';
      } else if (provenance.createdFromTodoId) {
        from = ' a todo in ';
      }

      action = (
        <>
          created this {entityType(entity)} from{from}
          {createdFromComponent}
        </>
      );
    }
    if (entity.provenance.provenanceType === EntityProvenanceType.Imported) {
      const provenance = entity.provenance as ImportedProvenance;
      if (provenance.integrationType) {
        action = (
          <>
            imported this {entityType(entity)} from{' '}
            {provenance.link ? (
              <span className={styles.activityHighlight}>
                <ExternalLink href={provenance.link}>
                  {stringifyIntegrationType(provenance.integrationType)}{' '}
                  {provenance.importSourceType}
                </ExternalLink>
              </span>
            ) : (
              <>
                {stringifyIntegrationType(provenance.integrationType)} {provenance.importSourceType}
              </>
            )}
          </>
        );
      }
    }
  }

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> {action}
      {hasMetadata ? <> {metadata}</> : ''}.
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const CreatedActivity = React.memo(CreatedActivityComponent);
