import * as React from 'react';
import { useEnableCycles } from '../api/cycle';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { EnableCycleArgs, Modals, useModals } from '../contexts/modalContext';
import { CycleSettings } from '../screens/new/settingsScreen/cycleSettings';

function EnableCyclesContents({ spaceId }: { spaceId?: string }) {
  const modals = useModals();
  const enableCycles = useEnableCycles();
  const [enabling, setEnabling] = React.useState(false);
  if (!spaceId) {
    return null;
  }
  return (
    <>
      <ModalContentWrapper className="mb24">
        <CycleSettings spaceId={spaceId} />
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <Button
          onClick={() => {
            modals.closeModal(Modals.EnableCycles);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setEnabling(true);
            await enableCycles(spaceId);
            setEnabling(false);
            modals.closeModal(Modals.EnableCycles);
          }}
          buttonStyle={ButtonStyle.Primary}
          disabled={enabling}
        >
          Enable cycles
        </Button>
      </ModalButtonWrapper>
    </>
  );
}

export default function EnableCyclesModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as EnableCycleArgs) : null;

  return (
    <Modal modalId={Modals.EnableCycles} title="Configure cycles">
      <EnableCyclesContents spaceId={args?.spaceId} />
    </Modal>
  );
}
