import * as RadixSwitch from '@radix-ui/react-switch';
import cn from 'classnames';
import * as React from 'react';
import styles from './switch.module.scss';

export function Switch({
  onChange,
  checked,
  disabled,
  small,
}: {
  onChange?: (checked: boolean) => void;
  checked?: boolean | undefined | null;
  disabled?: boolean;
  small?: boolean;
}) {
  return (
    <span className={cn(styles.background, { [styles.small]: small })}>
      <RadixSwitch.Root
        className={cn(styles.container, { [styles.small]: small })}
        onCheckedChange={checked => onChange?.(checked)}
        checked={checked ?? false}
        disabled={disabled}
      >
        <RadixSwitch.Thumb className={cn(styles.thumb, { [styles.small]: small })} />
      </RadixSwitch.Root>
    </span>
  );
}
