import * as React from 'react';
import isEmail from 'validator/lib/isEmail';
import { Button } from '../../../components/new/button';
import { ChangeableAvatar } from '../../../components/new/changeableAvatar';
import {
  Setting,
  SettingsPage,
  SettingsSection,
  SettingsTextInput,
} from '../../../components/new/settings';
import { toast } from '../../../components/toast';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useCurrentUser } from '../../../contexts/userContext';
import { useChangeEmail, useUpdateUsers } from '../../../syncEngine/actions/users';

export function ProfileScreen() {
  const user = useCurrentUser();
  const updateUser = useUpdateUsers();
  const updateEmail = useChangeEmail();
  const modalManager = useModals();

  return (
    <SettingsPage title="Profile">
      <SettingsSection>
        <Setting title="Profile photo" vertical>
          <ChangeableAvatar
            name={user.name ?? user.username}
            img={user.avatar}
            showButtons
            onUploadCompleted={result => {
              updateUser([user.id], { avatar: result[0].url });
              toast.success('Profile photo successfully changed');
            }}
            onRemove={() => updateUser([user.id], { avatar: null })}
            maxSize={{ width: 250, height: 250 }}
          />
        </Setting>

        <Setting title="Full name" vertical>
          <SettingsTextInput
            initialValue={user.name ?? ''}
            onSave={name => updateUser([user.id], { name })}
          ></SettingsTextInput>
        </Setting>

        <Setting title="Username" vertical>
          <SettingsTextInput
            initialValue={user.username}
            onSave={username => updateUser([user.id], { username })}
            validate={username => {
              if (!username.length) {
                return 'Please specfiy a username';
              }
              return null;
            }}
          ></SettingsTextInput>
        </Setting>
      </SettingsSection>

      <SettingsSection title="Authentification">
        <Setting title="Email" vertical>
          <SettingsTextInput
            initialValue={user.primaryEmail}
            onSave={email => updateEmail(email)}
            validate={email => {
              if (!email || !isEmail(email)) {
                return 'Please specify a valid email';
              }
              return null;
            }}
          />
          <Button className="mt16" onClick={() => modalManager.openModal(Modals.ChangePassword)}>
            Change password
          </Button>
        </Setting>
      </SettingsSection>
    </SettingsPage>
  );
}
