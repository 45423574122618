import React from 'react';
import { useRecoilValue } from 'recoil';
import { RoadmapColumn, Roadmap as RoadmapModel } from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { RoadmapIcon } from '../../screens/new/newRoadmapScreen/roadmapIcon';
import {
  useAddInitiativesToRoadmaps,
  useMoveInitiatives,
} from '../../syncEngine/actions/intiatives';
import {
  roadmapInitiativesForInitiativeSelector,
  roadmapsWithStatusForInitiativeSelector,
} from '../../syncEngine/selectors/intiatives';
import { roadmapPath } from '../../syncEngine/selectors/roadmaps';
import { Icon, IconSize } from './icon';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import Pill, { PillStyle } from './metadata/pill';
import Roadmap from './metadata/roadmap';
import { MetadataSize } from './metadata/size';
import { RoadmapColumnPicker } from './pickers/roadmapColumnPicker';
import { RoadmapPicker } from './pickers/roadmapPicker';
import { FollowTooltipContent, MetadataTooltip } from './tooltip';

export function InitiativeRoadmapTooltip({ roadmap }: { roadmap: RoadmapModel }) {
  const organization = useOrganization();

  const link = {
    pathname: roadmapPath(organization, roadmap),
  };

  return (
    <FollowTooltipContent link={link}>
      <RoadmapIcon size={IconSize.Size20} color={roadmap.color} />
      <span className="ml6 bodyM">{roadmap.name}</span>
    </FollowTooltipContent>
  );
}

function InitiativeRoadmap({
  initiativeId,
  roadmap,
  column,
  size,
  className,
}: {
  initiativeId: string;
  roadmap: RoadmapModel;
  column: RoadmapColumn;
  size: MetadataSize;
  className?: string;
}) {
  const move = useMoveInitiatives();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [hoverOpen, setHoverOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const roadmapInitiatives = useRecoilValue(roadmapInitiativesForInitiativeSelector(initiativeId));

  const roadmapInitiativeId = roadmapInitiatives.find(ri => ri.columnId === column.id)?.id;

  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  return (
    <MetadataTooltip
      disabled={menuOpen}
      open={hoverOpen}
      side="bottom"
      align="start"
      className="followTooltip"
      onOpenChange={setHoverOpen}
      content={<InitiativeRoadmapTooltip roadmap={roadmap} />}
    >
      <div className={className}>
        <DropdownMenu onOpenChange={setMenuOpen} open={menuOpen}>
          <DropdownMenuTrigger
            asChild
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div>
              <Roadmap
                color={roadmap.color}
                name={column.name}
                size={size}
                status={column.columnType}
              />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="bottom" align="start" className="menuLarge" sideOffset={10}>
            <RoadmapColumnPicker
              roadmapId={roadmap.id}
              state={{ [initiativeId]: [column.id] }}
              onDone={closeMenu}
              onPicked={(_, columnId) => {
                if (!roadmapInitiativeId) {
                  return;
                }
                move([roadmapInitiativeId], columnId);
              }}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </MetadataTooltip>
  );
}

function PlaceholderRoadmap({ initiativeId, size }: { initiativeId: string; size: MetadataSize }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addInitiativesToRoadmaps = useAddInitiativesToRoadmaps();
  const space = useMaybeSpace();

  const [selected, setSelected] = React.useState<string[]>([]);
  const selectedRef = React.useRef(selected);
  selectedRef.current = selected;

  return (
    <DropdownMenu
      onOpenChange={open => {
        setMenuOpen(open);
        if (!open) {
          addInitiativesToRoadmaps([initiativeId], selectedRef.current);
        }
      }}
      open={menuOpen}
    >
      <DropdownMenuTrigger asChild>
        <Pill size={size} pillStyle={PillStyle.Empty}>
          <Icon icon="roadmap" />
          Roadmap
        </Pill>
      </DropdownMenuTrigger>
      <DropdownMenuContent side="bottom" align="start" className="menuLarge" sideOffset={10}>
        <RoadmapPicker
          multi
          spaceId={space?.id}
          state={{ [initiativeId]: selected }}
          onRoadmapAdded={(_, roadmapId) => {
            setSelected(old => {
              const newVal = old.concat([roadmapId]);
              selectedRef.current = newVal;
              return newVal;
            });
          }}
          onRoadmapRemoved={(_, roadmapId) => {
            setSelected(old => {
              const newVal = old.filter(r => r !== roadmapId);
              selectedRef.current = newVal;
              return newVal;
            });
          }}
          onDone={() => {
            addInitiativesToRoadmaps([initiativeId], selectedRef.current);
            closeMenu();
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function InitiativeRoadmaps({
  initiativeId,
  placeholder,
  size = MetadataSize.Small,
  className,
}: {
  initiativeId: string;
  placeholder?: boolean;
  size?: MetadataSize;
  className?: string;
}) {
  const roadmapsWithStatus = useRecoilValue(roadmapsWithStatusForInitiativeSelector(initiativeId));
  const organization = useOrganization();
  if (!organization.newRoadmapsEnabled) {
    return null;
  }

  if (!roadmapsWithStatus.length) {
    if (placeholder) {
      return <PlaceholderRoadmap initiativeId={initiativeId} size={size} />;
    }
    null;
  }
  return (
    <>
      {roadmapsWithStatus.map(({ roadmap, column }) => (
        <InitiativeRoadmap
          key={`${roadmap.id}-${initiativeId}`}
          size={size}
          initiativeId={initiativeId}
          roadmap={roadmap}
          column={column}
          className={className}
        />
      ))}
    </>
  );
}
