import { groupBy, isEqual, keyBy, partition, sortBy, uniq } from 'lodash';
import { GetRecoilValue, atomFamily, selectorFamily, useRecoilCallback } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { looksLikeId } from '../../../shared/utils/id';
import {
  ImpactEffortScoringAlgorithm,
  defaultImpactEffortScoring,
  effortOnlyScoring,
  generateImpactEffortScoringMetadata,
  impactOnlyScoring,
} from '../../../shared/utils/impactAndEffortLevels';
import { issueTerm } from '../../../shared/utils/terms';
import {
  dependenciesByDependsOnId,
  dependenciesByEnablesId,
  issuesByAssignee,
  issuesBySpace,
  issuesByWatcher,
} from '../../../sync/__generated/indexes';
import {
  CodeReviewRequestState,
  Dependency,
  DependencyType,
  Issue,
  IssueStatus,
  IssueStatusSortMode,
  IssueStatusType,
  Organization,
  Space,
} from '../../../sync/__generated/models';
import { writeToClipboard } from '../../components/clipboardText';
import { useConfiguration } from '../../contexts/configurationContext';
import { filterEntities } from '../../utils/filtering';
import { filterEntities as filterEntities2 } from '../../utils/filtering2';
import { equalSelectorFamily } from '../../utils/recoil';
import { localStorageEffect } from '../effects';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import {
  boardColumnsForBoardSelector,
  boardForStatusSelector,
  boardsForSpaceSelector,
} from './boards';
import { codeReviewsForIssueSelector } from './codeReviewRequests';
import { entityInCycleSelector, sortIssuesByCycles } from './cycles';
import { effortLevelsForSpaceSelector, effortSelector } from './effortLevels';
import { spaceForEntitySelector } from './entities';
import { impactLevelsForSpaceSelector, impactSelector } from './impactLevels';
import { archivedStatusForSpaceSelector, statusesForSpaceSelector } from './issueStatuses';
import { organizationsSelector } from './organizations';
import { spacePath, spaceSelector, spacesForOrganizationSelector } from './spaces';
import { todoCountForEntitySelector } from './todos';
import { currentUserMembershipState } from './users';

export function issuePath(organization: Organization, space: Space, issue: Issue) {
  if (issue.number.startsWith('T') || issue.number.startsWith('U')) {
    return spacePath(organization, space, `items/${issue.id}`);
  }

  return spacePath(organization, space, `items/${issue.number}`);
}

export function isIssue(issue: SyncEngineObject): issue is Issue {
  return issue.__typename === 'Issue';
}

export function useIsIssue() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (issueId: string) => {
        const maybeIssue = snapshot
          .getLoadable(syncEngineState(issueId))
          .getValue() as SyncEngineObject;
        if (!maybeIssue) {
          return false;
        }
        return isIssue(maybeIssue);
      },
    []
  );
}

export const hideSnoozedAtom = atomFamily<boolean, string>({
  key: 'hideSnoozed',
  default: true,
  effects: key => [localStorageEffect(`__hideSnoozed_${key}`)],
});

export const issueSelector = selectorFamily({
  key: 'Issue',
  get:
    (issueId: string | undefined | null) =>
    ({ get }) => {
      if (!issueId) {
        return null;
      }
      return notDeleted(get(syncEngineState(issueId)) as Issue | null);
    },
});
export const issuesSelector = selectorFamily({
  key: 'Issues',
  get:
    (issueIds: string[] | undefined | null) =>
    ({ get }) => {
      if (!issueIds) {
        return null;
      }
      return filterNotDeletedNotNull(issueIds.map(issueId => get(issueSelector(issueId))));
    },
});

export const issueTitleSelector = selectorFamily({
  key: 'IssueTitle',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      if (!issue) {
        return null;
      }
      return issue.title;
    },
});

export const issueLabelIdsSelector = selectorFamily({
  key: 'LabelIdsForIssue',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      return issue?.labelIds;
    },
});

export const issueAssigneeIdsSelector = selectorFamily({
  key: 'assigneeIdsForIssue',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      return issue?.assigneeIds;
    },
});

export const issuePreviousStatusIdSelector = selectorFamily({
  key: 'IssuePreviousStatus',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      return issue?.previousStatusId;
    },
});

export const issueImpactSelector = selectorFamily({
  key: 'IssueImpact',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      const impact = get(impactSelector(issue?.impactId));
      return impact;
    },
});

export const issueEffortSelector = selectorFamily({
  key: 'IssueEffort',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      const effort = get(effortSelector(issue?.effortId));
      return effort;
    },
});

export const issuePartialSelector = selectorFamily({
  key: 'IssuePartial',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      return issue?.partial;
    },
});

export const issuePublicSelector = selectorFamily({
  key: 'IssuePublic',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      return issue?.public;
    },
});

export const issuePublicMetadataSelector = selectorFamily({
  key: 'IssuePublicMetadata',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      return issue?.publicMetadata;
    },
});

export const issueNumberSelector = selectorFamily({
  key: 'IssueNumber',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      if (!issue) {
        return null;
      }
      return issue.number;
    },
});

export const maybeIssueSelector = selectorFamily({
  key: 'MaybeIssue',
  get:
    (issueId?: string) =>
    ({ get }) => {
      if (!issueId) {
        return null;
      }
      return get(syncEngineState(issueId)) as Issue;
    },
});

function validDependency(d: Dependency, get: GetRecoilValue) {
  const dependsIssue = get(issueSelector(d.dependsOnId));
  const enablesIssue = get(issueSelector(d.enablesId));
  if (!dependsIssue || !enablesIssue) {
    return false;
  }
  const dependsSpace = get(spaceSelector(dependsIssue.spaceId));
  const enablesSpace = get(spaceSelector(enablesIssue.spaceId));
  if (!dependsSpace || !enablesSpace) {
    return false;
  }
  return true;
}

export const dependsOnSelector = selectorFamily({
  key: 'DependsOn',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      if (!issueId) {
        return [];
      }
      const dependsOnIds = get(indexKeyState(indexKey(dependenciesByDependsOnId, issueId)));
      return filterNotDeletedNotNull(
        dependsOnIds.map(dependencyId => get(syncEngineState(dependencyId)) as Dependency | null)
      ).filter(d => validDependency(d, get));
    },
});

export const enablesSelector = selectorFamily({
  key: 'Enables',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      if (!issueId) {
        return [];
      }
      const enablesIds = get(indexKeyState(indexKey(dependenciesByEnablesId, issueId)));
      return filterNotDeletedNotNull(
        enablesIds.map(dependencyId => get(syncEngineState(dependencyId)) as Dependency | null)
      ).filter(d => validDependency(d, get));
    },
});

export const blockedSelector = selectorFamily({
  key: 'Blocked',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      if (!issueId) {
        return false;
      }
      const enables = get(enablesSelector(issueId)).filter(
        dependency => dependency.dependencyType === DependencyType.BlockedBy
      );
      const nonResolved = enables.filter(
        dependency => !get(issueResolvedSelector(dependency.dependsOnId))
      );
      return !!nonResolved.length;
    },
});

export const issueResolvedSelector = selectorFamily({
  key: 'IssueResolved',
  get:
    (issueId: string) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      if (!issue || issue.deleted) {
        return true;
      }

      const status = get(statusSelector(issue.statusId));
      if (!status) {
        return true;
      }

      return (
        status.statusType === IssueStatusType.Archived || status.statusType === IssueStatusType.Done
      );
    },
});

export const issueArchivedSelector = selectorFamily({
  key: 'IssueArchived',
  get:
    (issueId: string) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      const archivedStatus = get(archivedStatusForSpaceSelector(issue?.spaceId));
      if (!issue || !archivedStatus) {
        return null;
      }
      return issue.statusId === archivedStatus.id;
    },
});

export const issuesResolvedSelector = selectorFamily({
  key: 'IssuesResolved',
  get:
    (issueIds: string[]) =>
    ({ get }) => {
      if (!issueIds) {
        return true;
      }
      return issueIds.every(id => get(issueResolvedSelector(id)));
    },
});

export const dependencyIdsForIssueSelector = selectorFamily({
  key: 'DependenciesForIssue',
  get:
    (issueId: string) =>
    ({ get }) => {
      const dependsOn = get(dependsOnSelector(issueId));
      const enables = get(enablesSelector(issueId));

      const blocksIssueIds = dependsOn
        .filter(d => d.dependencyType === DependencyType.BlockedBy && d.enablesId && d.dependsOnId)
        .map(d => d.enablesId);

      const blockedByIssueIds = enables
        .filter(d => d.dependencyType === DependencyType.BlockedBy && d.enablesId && d.dependsOnId)
        .map(d => d.dependsOnId);

      const enablesIssueIds = dependsOn
        .filter(d => d.dependencyType === DependencyType.DependsOn && d.enablesId && d.dependsOnId)
        .map(d => d.enablesId);

      const dependsOnIssueIds = enables
        .filter(d => d.dependencyType === DependencyType.DependsOn && d.enablesId && d.dependsOnId)
        .map(d => d.dependsOnId);

      return {
        blocksIssueIds,
        blockedByIssueIds,
        enablesIssueIds,
        dependsOnIssueIds,
      };
    },
});

export const dependencyIdsForIssuesSelector = selectorFamily({
  key: 'DependenciesForIssues',
  get:
    (issueIds: string[]) =>
    ({ get }) => {
      const result: {
        blocksIssueIds: Record<string, string[]>;
        blockedByIssueIds: Record<string, string[]>;
        enablesIssueIds: Record<string, string[]>;
        dependsOnIssueIds: Record<string, string[]>;
      } = {
        blocksIssueIds: {},
        blockedByIssueIds: {},
        enablesIssueIds: {},
        dependsOnIssueIds: {},
      };

      for (const issueId of issueIds) {
        const dependsOn = get(dependsOnSelector(issueId));
        const enables = get(enablesSelector(issueId));

        const blocksIssueIds = dependsOn
          .filter(
            d => d.dependencyType === DependencyType.BlockedBy && d.enablesId && d.dependsOnId
          )
          .map(d => d.enablesId);

        const blockedByIssueIds = enables
          .filter(
            d => d.dependencyType === DependencyType.BlockedBy && d.enablesId && d.dependsOnId
          )
          .map(d => d.dependsOnId);

        const enablesIssueIds = dependsOn
          .filter(
            d => d.dependencyType === DependencyType.DependsOn && d.enablesId && d.dependsOnId
          )
          .map(d => d.enablesId);

        const dependsOnIssueIds = enables
          .filter(
            d => d.dependencyType === DependencyType.DependsOn && d.enablesId && d.dependsOnId
          )
          .map(d => d.dependsOnId);

        result.blocksIssueIds[issueId] = blocksIssueIds;
        result.blockedByIssueIds[issueId] = blockedByIssueIds;
        result.enablesIssueIds[issueId] = enablesIssueIds;
        result.dependsOnIssueIds[issueId] = dependsOnIssueIds;
      }

      return result;
    },
});

export function scoreStatus(type: IssueStatusType): number {
  switch (type) {
    case IssueStatusType.InProgress:
      return 1;
    case IssueStatusType.Todo:
      return 2;
    case IssueStatusType.Backlog:
      return 3;
    case IssueStatusType.Done:
      return 4;
    case IssueStatusType.Archived:
      return 5;
  }
}

export function isClosedStatus(type: IssueStatusType): boolean {
  return type === IssueStatusType.Done || type === IssueStatusType.Archived;
}
export function isOpenStatus(type: IssueStatusType): boolean {
  return !isClosedStatus(type);
}

export const statusForIssueSelector = selectorFamily({
  key: 'StatusForIssue',
  get:
    (issueId: string) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      if (!issue) {
        return null;
      }
      return get(statusSelector(issue.statusId));
    },
});

export const statusSelector = selectorFamily({
  key: 'StatusSelector',
  get:
    (statusId: string | undefined) =>
    ({ get }) => {
      if (!statusId) {
        return null;
      }
      return notDeleted(get(syncEngineState(statusId)) as IssueStatus | null);
    },
});

export const statusesSelector = selectorFamily({
  key: 'StatusesSelector',
  get:
    (statusIds: string[] | undefined) =>
    ({ get }) => {
      if (!statusIds) {
        return null;
      }
      return filterNotNull(statusIds.map(id => get(statusSelector(id))));
    },
});

export const issueByNumberSelector = selectorFamily({
  key: 'IssueByNumber',
  get:
    ({ spaceId, issueNumber }: { spaceId: string; issueNumber: string }) =>
    ({ get }) => {
      const issues = get(allIssuesForSpaceSelector(spaceId));
      return issues.find(i => i.number === issueNumber || i.id === issueNumber);
    },
});

export const issueIdByNumberSelector = selectorFamily({
  key: 'IssueIdByNumber',
  get:
    ({ spaceId, issueNumber }: { spaceId: string; issueNumber: string }) =>
    ({ get }) => {
      if (looksLikeId(issueNumber)) {
        return issueNumber;
      }

      return get(issueByNumberSelector({ spaceId, issueNumber }))?.id;
    },
});

export const assignedIssuesForUserSelector = selectorFamily({
  key: 'AssignedIssuesForUser',
  get:
    (userId: string) =>
    ({ get }) => {
      const issueIds = get(indexKeyState(indexKey(issuesByAssignee, userId)));
      return filterNotDeletedNotNull(
        issueIds.map(issueId => get(syncEngineState(issueId)) as Issue | null)
      );
    },
});

export const watchedIssuesForUserSelector = selectorFamily({
  key: 'WatchedIssuesForUser',
  get:
    (userId: string) =>
    ({ get }) => {
      const issueIds = get(indexKeyState(indexKey(issuesByWatcher, userId)));
      return filterNotDeletedNotNull(
        issueIds.map(issueId => get(syncEngineState(issueId)) as Issue | null)
      );
    },
});

export function sortStatusesInBoardOrder(spaceId: string, get: GetRecoilValue): IssueStatus[] {
  const statuses = get(statusesForSpaceSelector(spaceId));
  const boards = get(boardsForSpaceSelector(spaceId));

  const [archived, unarchived] = partition(
    statuses,
    s => s.statusType === IssueStatusType.Archived
  );

  const columns = uniq(
    sortBy(boards, b => b.sort)
      .flatMap(b => {
        const boardColumns = get(boardColumnsForBoardSelector(b.id));
        return sortBy(boardColumns, c => c.sort).map(c => c.statusId);
      })
      .reverse()
  ).reverse();

  const result = columns
    .map(c => unarchived.find(s => s.id === c))
    .filter(s => !!s) as IssueStatus[];
  return [...result, ...archived];
}

export function compareIssues(
  get: GetRecoilValue,
  statusSorting: Record<string, IssueStatus[]>,
  a: Issue,
  b: Issue
): number {
  const aStatus = get(statusSelector(a.statusId));
  const bStatus = get(statusSelector(b.statusId));
  if (!aStatus || !bStatus) {
    return 0;
  }
  const aScore = scoreStatus(aStatus.statusType);
  const bScore = scoreStatus(bStatus.statusType);

  if (aScore < bScore) {
    return -1;
  }
  if (aScore > bScore) {
    return 1;
  }

  const aSpace = get(spaceSelector(a.spaceId));
  const bSpace = get(spaceSelector(b.spaceId));

  if ((aSpace?.sort ?? 0) < (bSpace?.sort ?? 0)) {
    return -1;
  }

  if ((aSpace?.sort ?? 0) > (bSpace?.sort ?? 0)) {
    return 1;
  }

  const aStatusIndex = statusSorting[a.spaceId].findIndex(s => s.id === a.statusId);
  const bStatusIndex = statusSorting[b.spaceId].findIndex(s => s.id === b.statusId);

  if (aStatusIndex > bStatusIndex) {
    return -1;
  }

  if (aStatusIndex < bStatusIndex) {
    return 1;
  }

  if (a.sort < b.sort) {
    return -1;
  }

  if (a.sort > b.sort) {
    return 1;
  }

  return 0;
}

/**
 * Sorts the issues by status, space, and board order
 */
export function sortIssues(issues: Issue[], get: GetRecoilValue): Issue[] {
  const spaceIds = uniq(issues.map(i => i.spaceId));
  const existingSpaces = filterNotNull(spaceIds.map(space => get(spaceSelector(space)))).map(
    space => space.id
  );
  const validIssues = issues.filter(issue => existingSpaces.includes(issue.spaceId));

  const sorting: Record<string, IssueStatus[]> = {};
  for (const spaceId of spaceIds) {
    sorting[spaceId] = sortStatusesInBoardOrder(spaceId, get);
  }

  return validIssues.sort((a, b) => compareIssues(get, sorting, a, b));
}

export const issuesForStatusSelector = selectorFamily({
  key: 'IssuesForStatus',
  get:
    (statusId: string) =>
    ({ get }) => {
      const status = get(statusSelector(statusId));
      if (!status) {
        return [];
      }
      const issuesForSpace = get(issuesForSpaceSelector(status.spaceId));
      const issuesForStatus = issuesForSpace.filter(i => i.statusId === statusId);

      if (status.sortMode && status.sortMode !== IssueStatusSortMode.Manual) {
        sortStatus(get, issuesForStatus, status);
      }

      return issuesForStatus;
    },
});

export const issueHasStateSelector = selectorFamily({
  key: 'IssueHasState',
  get:
    (issueId: string) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      if (!issue) {
        return false;
      }
      const smartTodoCounts = get(todoCountForEntitySelector(issueId));
      const codeReviewRequests = get(codeReviewsForIssueSelector(issue?.id));
      const dependsOnDependencies = get(dependsOnSelector(issue?.id));
      const enablesDependencies = get(enablesSelector(issue?.id));

      const space = get(spaceForEntitySelector(issue?.id));
      const inActiveCycle = get(
        entityInCycleSelector({ cycleId: space?.activeCycleId, entityId: issue?.id })
      );

      return (
        smartTodoCounts.total > 0 ||
        issue.impactId ||
        issue.effortId ||
        codeReviewRequests.filter(r => r.state !== CodeReviewRequestState.Closed).length > 0 ||
        dependsOnDependencies.length > 0 ||
        enablesDependencies.length > 0 ||
        inActiveCycle
      );
    },
});

// Only use this for short lived data when trying to do a write inside a callback!
// DO NOT USE FOR RENDERING
export function useIssueForCallback() {
  return useRecoilCallback(({ snapshot }) => (issueId: string | undefined | null) => {
    return snapshot.getLoadable(issueSelector(issueId)).getValue();
  });
}

export function useSortIssuesInNumericOrder() {
  return useRecoilCallback(({ snapshot }) => (issueIds: string[]) => {
    const filteredIds = issueIds.filter(id => {
      const issue = snapshot.getLoadable(issueSelector(id)).getValue();
      return snapshot.getLoadable(spaceSelector(issue?.spaceId)).getValue();
    });

    return filteredIds.sort((aId: string, bId: string) => {
      const a = snapshot.getLoadable(issueSelector(aId)).getValue()!;
      const b = snapshot.getLoadable(issueSelector(bId)).getValue()!;

      const aSpace = snapshot.getLoadable(spaceSelector(a.spaceId)).getValue()!;
      const bSpace = snapshot.getLoadable(spaceSelector(b.spaceId)).getValue()!;
      if (aSpace.key > bSpace.key) {
        return -1;
      }

      if (aSpace.key < bSpace.key) {
        return 1;
      }

      const aNumber = parseInt(a.number.replace('T', '').replace('U', ''), 10);
      const bNumber = parseInt(b.number.replace('T', '').replace('U', ''), 10);
      return bNumber - aNumber;
    });
  });
}

export function useSortIssuesInBoardOrder() {
  return useRecoilCallback(({ snapshot }) => (issueIds: string[]) => {
    const issues = filterNotNull(
      issueIds.map(issueId => snapshot.getLoadable(issueSelector(issueId)).getValue())
    );
    return sortIssues(issues, id => snapshot.getLoadable(id).getValue()).map(i => i.id);
  });
}

export function useCopyPublicIssueLink() {
  const { host } = useConfiguration();
  return (issueId: string) =>
    writeToClipboard(`${host}/sharing/items/${issueId}`, `public link to ${issueTerm}`);
}

export function useGetFilterdIssuesForBoard(
  spaceId: string,
  boardId?: string,
  includeArchived?: boolean
) {
  const callback = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(
          issuesByStatusSelector({
            spaceId,
            filterId: boardId ?? '',
            includeArchived,
          })
        );
      },
    [spaceId, boardId]
  );
  return () => callback().getValue().filteredIssueIds;
}

export function useGetFilterdIssuesForStatus(spaceId: string, statusId: string, boardId?: string) {
  const callback = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(
          filteredIssuesForStatusSelector({
            spaceId,
            filterId: boardId ?? '',
            statusId,
          })
        );
      },
    [spaceId, boardId, statusId]
  );
  return () => callback().getValue();
}

export function sortModeToString(sortMode?: IssueStatusSortMode, initiativeMode?: boolean) {
  switch (sortMode) {
    case IssueStatusSortMode.LastStatusAsc:
      if (initiativeMode) {
        return 'added to column';
      }
      return 'added to status';
    case IssueStatusSortMode.LastStatusDesc:
      if (initiativeMode) {
        return 'added to column(reversed)';
      }
      return 'added to status (reversed)';
    case IssueStatusSortMode.CreatedAsc:
      return 'created';
    case IssueStatusSortMode.CreatedDesc:
      return 'created (reversed)';
    case IssueStatusSortMode.EffortAsc:
      return 'effort';
    case IssueStatusSortMode.EffortDesc:
      return 'effort (reversed)';
    case IssueStatusSortMode.ImpactAsc:
      return 'impact';
    case IssueStatusSortMode.ImpactDesc:
      return 'impact (reversed)';
    case IssueStatusSortMode.ImpactEffortAsc:
      return 'impact & effort';
    case IssueStatusSortMode.ImpactEffortDesc:
      return 'impact & effort (reversed)';
    case IssueStatusSortMode.UpdatedAsc:
      return 'updated date';
    case IssueStatusSortMode.UpdatedDesc:
      return 'updated (reversed)';
    case IssueStatusSortMode.CycleAsc:
      return 'cycle';
    case IssueStatusSortMode.CycleDesc:
      return 'cycle (reversed)';
    case IssueStatusSortMode.DueDateAsc:
      return 'due date';
    case IssueStatusSortMode.DueDateDesc:
      return 'due date (reversed)';
    default:
    case IssueStatusSortMode.Manual:
      return 'manually';
  }
}

export function sortStatus(get: GetRecoilValue, issues: Issue[], status: IssueStatus) {
  const sortMode = status.sortMode;
  const spaceId = status.spaceId;
  let scoreIssue: ImpactEffortScoringAlgorithm;
  switch (sortMode) {
    case IssueStatusSortMode.Manual:
      return;
    case IssueStatusSortMode.LastStatusAsc:
      issues.sort((a: Issue, b: Issue) => (b.lastStatusUpdate ?? 0) - (a.lastStatusUpdate ?? 0));
      return;
    case IssueStatusSortMode.LastStatusDesc:
      issues.sort((a: Issue, b: Issue) => (a.lastStatusUpdate ?? 0) - (b.lastStatusUpdate ?? 0));
      return;
    case IssueStatusSortMode.CreatedAsc:
      issues.sort((a: Issue, b: Issue) => b.createdAt - a.createdAt);
      return;
    case IssueStatusSortMode.CreatedDesc:
      issues.sort((a: Issue, b: Issue) => a.createdAt - b.createdAt);
      return;
    case IssueStatusSortMode.UpdatedDesc:
      issues.sort((a: Issue, b: Issue) => a.displayedUpdatedAt - b.displayedUpdatedAt);
      return;
    case IssueStatusSortMode.UpdatedAsc:
      issues.sort((a: Issue, b: Issue) => b.displayedUpdatedAt - a.displayedUpdatedAt);
      return;
    case IssueStatusSortMode.EffortDesc:
    case IssueStatusSortMode.EffortAsc:
      scoreIssue = effortOnlyScoring;
      break;
    case IssueStatusSortMode.ImpactDesc:
    case IssueStatusSortMode.ImpactAsc:
      scoreIssue = impactOnlyScoring;
      break;
    case IssueStatusSortMode.ImpactEffortDesc:
    case IssueStatusSortMode.ImpactEffortAsc:
      scoreIssue = defaultImpactEffortScoring;
      break;
    case IssueStatusSortMode.CycleDesc:
      sortIssuesByCycles(get, spaceId, issues);
      return;
    case IssueStatusSortMode.CycleAsc:
      sortIssuesByCycles(get, spaceId, issues, true);
      return;
    case IssueStatusSortMode.DueDateAsc:
      issues.sort((a: Issue, b: Issue) => {
        if (!a.dueDate) {
          return 1;
        }
        if (!b.dueDate) {
          return -1;
        }
        return a.dueDate - b.dueDate;
      });
      return;
    case IssueStatusSortMode.DueDateDesc:
      issues.sort((a: Issue, b: Issue) => {
        if (!a.dueDate) {
          return -1;
        }
        if (!b.dueDate) {
          return 1;
        }
        return b.dueDate - a.dueDate;
      });
      return;
  }
  const impactLevels = get(impactLevelsForSpaceSelector(spaceId));
  const effortLevels = get(effortLevelsForSpaceSelector(spaceId));

  const scoringMetadata = generateImpactEffortScoringMetadata({ impactLevels, effortLevels });

  issues.sort((a: Issue, b: Issue) => {
    const aImpact = impactLevels.find(impact => a.impactId === impact.id);
    const aEffort = effortLevels.find(effort => a.effortId === effort.id);

    const aScore = scoreIssue(scoringMetadata, {
      impactLevels,
      effortLevels,
      impact: aImpact,
      effort: aEffort,
    });

    const bImpact = impactLevels.find(impact => b.impactId === impact.id);
    const bEffort = effortLevels.find(effort => b.effortId === effort.id);

    const bScore = scoreIssue(scoringMetadata, {
      impactLevels,
      effortLevels,
      impact: bImpact,
      effort: bEffort,
    });

    if (
      [
        IssueStatusSortMode.ImpactEffortAsc,
        IssueStatusSortMode.ImpactAsc,
        IssueStatusSortMode.EffortAsc,
      ].includes(sortMode)
    ) {
      return bScore - aScore;
    }
    return aScore - bScore;
  });
}

// FIXME: optimize this, no point getting all issues then discarding most of them
export const filteredIssuesForStatusSelector = equalSelectorFamily({
  key: 'filteredIssuesForStatus',
  get:
    ({
      spaceId,
      statusId,
      filterId,
      includeArchived,
    }: {
      spaceId: string | undefined;
      statusId: string | undefined;
      filterId: string;
      includeArchived?: boolean;
    }) =>
    ({ get }) => {
      if (!statusId) {
        return [];
      }
      const { filteredIssueIds } = get(
        issuesByStatusSelector({ spaceId, filterId, includeArchived })
      );
      return filteredIssueIds[statusId] ?? [];
    },
  equals: isEqual,
});

export const filteredIssuesForStatusCountSelector = selectorFamily({
  key: 'filteredIssuesForStatusCount',
  get:
    ({
      spaceId,
      statusId,
      filterId,
      includeArchived,
    }: {
      spaceId: string | undefined;
      statusId: string | undefined;
      filterId: string;
      includeArchived?: boolean;
    }) =>
    ({ get }) => {
      if (!statusId || !spaceId) {
        return 0;
      }
      return get(filteredIssuesForStatusSelector({ spaceId, statusId, filterId, includeArchived }))
        .length;
    },
});

export const issuesByStatusSelector = equalSelectorFamily({
  key: 'IssuesByStatus',
  get:
    ({
      spaceId,
      filterId,
      includeArchived,
    }: {
      spaceId?: string | undefined;
      filterId?: string | undefined;
      includeArchived?: boolean;
    }) =>
    ({ get }) => {
      const space = get(spaceSelector(spaceId));
      if (!spaceId || !space) {
        return {
          filteredIssueIds: {},
          issueIdsByStatus: {},
        };
      }
      const hideSnoozed = filterId ? get(hideSnoozedAtom(filterId)) : false;

      const archivedStatus = get(archivedStatusForSpaceSelector(spaceId));

      let issuesToGroup = get(issuesForSpaceSelector(spaceId));

      if (!includeArchived) {
        issuesToGroup = issuesToGroup.filter(issue => issue.statusId !== archivedStatus?.id);
      }

      if (hideSnoozed) {
        const orgMembership = get(currentUserMembershipState(space.organizationId));
        if (orgMembership?.snoozed?.length) {
          const snoozedById = keyBy(orgMembership.snoozed, 'id');
          issuesToGroup = issuesToGroup.filter(e => {
            const snoozed = snoozedById[e.id];
            if (!snoozed) {
              return true;
            }
            return snoozed.snoozedUntil < Date.now().valueOf();
          });
        }
      }

      const issuesByStatus = groupBy(issuesToGroup, issue => issue.statusId);
      const statuses = Object.keys(issuesByStatus).map(s => get(statusSelector(s)));

      for (const status of statuses) {
        if (!status) {
          continue;
        }
        if (status.sortMode && status.sortMode !== IssueStatusSortMode.Manual) {
          sortStatus(get, issuesByStatus[status.id], status);
        }
      }

      const issueIdsByStatus = Object.fromEntries(
        Object.entries(issuesByStatus).map(([statusId, issues]) => [
          statusId,
          issues.map(i => i.id),
        ])
      );
      return {
        issueIdsByStatus,
        filteredIssueIds: filterId
          ? Object.fromEntries(
              Object.entries(issueIdsByStatus).map(([statusId, sortedIssues]) => [
                statusId,
                filterEntities2(sortedIssues, filterId, get),
              ])
            )
          : issueIdsByStatus,
      };
    },
  equals: isEqual,
});

export const archivedIssuesSelector = equalSelectorFamily({
  key: 'ArchivedIssues',
  get:
    ({ spaceId, filterId }: { spaceId: string; filterId: string }) =>
    ({ get }) => {
      const space = get(spaceSelector(spaceId));
      if (!spaceId || !space) {
        return [];
      }

      const archivedStatus = get(archivedStatusForSpaceSelector(spaceId));

      if (!archivedStatus) {
        return [];
      }

      const archivedIssues = sortBy(
        get(issuesForSpaceSelector(spaceId)).filter(issue => issue.statusId === archivedStatus.id),
        i => -1 * (i.archivedAt ?? i.updatedAt)
      ).map(i => i.id);
      return filterEntities(archivedIssues, filterId, get);
    },
  equals: isEqual,
});

export const issuesForSpaceSelector = selectorFamily({
  key: 'IssuesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const issueIds = get(indexKeyState(indexKey(issuesBySpace, spaceId)));
      return filterNotDeletedNotNull(
        issueIds.map(issueId => get(syncEngineState(issueId)) as Issue | null)
      );
    },
});

export const allIssuesForSpaceSelector = selectorFamily({
  key: 'AllIssuesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const issueIds = get(indexKeyState(indexKey(issuesBySpace, spaceId)));
      return filterNotNull(issueIds.map(issueId => get(syncEngineState(issueId)) as Issue | null));
    },
});

export const spaceKeyForIssueSelector = selectorFamily({
  key: 'SpaceKeyForIssue',
  get:
    (issueId: string | undefined | null) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));
      if (!issue) {
        return null;
      }
      const space = get(spaceSelector(issue.spaceId));
      if (!space) {
        return null;
      }
      return space.key;
    },
});

export const issuesForOrganizationSelector = selectorFamily({
  key: 'IssuesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const spaces = get(spacesForOrganizationSelector(organizationId));
      return spaces.flatMap(s => get(issuesForSpaceSelector(s.id)));
    },
});

export const defaultIssueBackPathSelector = selectorFamily({
  key: 'DefaultIssueBackPath',
  get:
    (issueId: string) =>
    ({ get }) => {
      const space = get(spaceForEntitySelector(issueId));
      const archived = get(issueArchivedSelector(issueId));
      const organization = get(organizationsSelector(space?.organizationId));
      const status = get(statusForIssueSelector(issueId));
      const board = get(boardForStatusSelector({ statusId: status?.id, spaceId: space?.id }));

      if (!space || !organization) {
        return '/';
      }

      let defaultPath = spacePath(organization, space);
      if (archived) {
        defaultPath = spacePath(organization, space, 'archive');
      } else if (board) {
        defaultPath = spacePath(organization, space, `boards/${board.key}`);
      }
      return defaultPath;
    },
});
