import { atom, GetRecoilValue, selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import {
  Activity,
  AssigneesChangedActivityDetails,
  CreatedActivityDetails,
  EntitiesChangedActivityDetails,
  EntityChange,
  InitiativeCreatedMetadata,
  IssueCreatedMetadata,
  LabelsChangedActivityDetails,
  RoadmapsChangedActivityDetails,
  SpacesChangedActivityDetails,
} from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { localStorageEffect } from '../effects';
import { syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { entityKeySelector, entitySelector, useEntityPath } from './entities';
import { labelSelector } from './labels';
import { roadmapColumnSelector, roadmapSelector } from './roadmaps';
import { spaceSelector } from './spaces';
import { userSelector } from './users';

export function isIssueCreatedMetadata(
  metadata: IssueCreatedMetadata | InitiativeCreatedMetadata
): metadata is IssueCreatedMetadata {
  return (metadata as IssueCreatedMetadata).statusId !== undefined;
}

export function isInitiativeCreatedMetadata(
  metadata: IssueCreatedMetadata | InitiativeCreatedMetadata
): metadata is InitiativeCreatedMetadata {
  return !isIssueCreatedMetadata(metadata);
}

export const assigneesChangedForActivitySelector = selectorFamily({
  key: 'AssigneesChangedForActivity',
  get:
    (activityId: string) =>
    ({ get }) => {
      const activity = get(syncEngineState(activityId)) as Activity;
      const assigneesChangedDetails = activity.details as AssigneesChangedActivityDetails;

      const added = assigneesChangedDetails.assigneesAddedIds.map(id => {
        const user = get(userSelector(id));
        return `${user?.username ?? 'unknown'}`;
      });

      const removed = assigneesChangedDetails.assigneesRemovedIds.map(id => {
        const user = get(userSelector(id));
        return `${user?.username ?? 'unknown'}`;
      });

      return { added, removed };
    },
});

export const labelsChangedForActivitySelector = selectorFamily({
  key: 'LabelsChangedForActivitySelector',
  get:
    (activityId: string) =>
    ({ get }) => {
      const activity = get(syncEngineState(activityId)) as Activity;
      const labelsChangedDetails = activity.details as LabelsChangedActivityDetails;

      const added = labelsChangedDetails.labelsAdded.map(labelChange => {
        const label = get(labelSelector(labelChange.labelId));
        return `${label?.name ?? labelChange.fallbackLabel}`;
      });

      const removed = labelsChangedDetails.labelsRemoved.map(labelChange => {
        const label = get(userSelector(labelChange.labelId));
        return `${label?.name ?? labelChange.fallbackLabel}`;
      });

      return { added, removed };
    },
});

export const spacesChangedForActivitySelector = selectorFamily({
  key: 'SpacesChangedForActivitySelector',
  get:
    (activityId: string) =>
    ({ get }) => {
      const activity = get(syncEngineState(activityId)) as Activity;
      const spacesChangedDetails = activity.details as SpacesChangedActivityDetails;

      const added = spacesChangedDetails.spacesAdded.map(spaceChange => {
        const space = get(spaceSelector(spaceChange.spaceId));
        return `${space?.name ?? spaceChange.fallbackSpaceName}`;
      });

      const removed = spacesChangedDetails.spacesRemoved.map(spaceChange => {
        const label = get(spaceSelector(spaceChange.spaceId));
        return `${label?.name ?? spaceChange.fallbackSpaceName}`;
      });

      return { added, removed };
    },
});

export const roadmapsChangedForActivitySelector = selectorFamily({
  key: 'RoadmapsChangedForActivitySelector',
  get:
    (activityId: string) =>
    ({ get }) => {
      const activity = get(syncEngineState(activityId)) as Activity;
      const roadmapsChangedDetails = activity.details as RoadmapsChangedActivityDetails;

      const added = roadmapsChangedDetails.roadmapsAdded.map(roadmapChange => {
        const roadmap = get(roadmapSelector(roadmapChange.roadmapId));
        const column = get(roadmapColumnSelector(roadmapChange.columnId));

        return `${roadmap?.name ?? roadmapChange.fallbackRoadmapName} - ${
          column?.name ?? roadmapChange.fallbackColumnName
        }`;
      });

      const removed = roadmapsChangedDetails.roadmapsRemoved.map(roadmapChange => {
        const roadmap = get(roadmapSelector(roadmapChange.roadmapId));
        const column = get(roadmapColumnSelector(roadmapChange.columnId));

        return `${roadmap?.name ?? roadmapChange.fallbackRoadmapName} - ${
          column?.name ?? roadmapChange.fallbackColumnName
        }`;
      });

      return { added, removed };
    },
});

export const entitiesChangedForActivitySelector = selectorFamily({
  key: 'EntitiesChangedForActivitySelector',
  get:
    (activityId: string) =>
    ({ get }) => {
      const activity = get(syncEngineState(activityId)) as Activity;
      const entitiesChangedDetails = activity.details as EntitiesChangedActivityDetails;
      const added = entitiesChangedDetails.entitiesAdded.map(entityChange =>
        getEntity(get, entityChange)
      );
      const removed = entitiesChangedDetails.entitiesRemoved.map(entityChange =>
        getEntity(get, entityChange)
      );

      return { added, removed };
    },
});

export const metaDataForCreatedActivitySelector = selectorFamily({
  key: 'MetaDataForCreatedActivitySelector',

  get:
    (activityId: string) =>
    ({ get }) => {
      const activity = get(syncEngineState(activityId)) as Activity;
      const createdDetails = activity.details as CreatedActivityDetails;

      const createdMetadata = createdDetails.createdMetadata as
        | IssueCreatedMetadata
        | InitiativeCreatedMetadata;

      const assignees = (createdMetadata?.assigneeIds ?? []).map(id => get(userSelector(id)));

      const labels = (createdMetadata?.labels || []).map(labelChange => {
        const label = get(labelSelector(labelChange.labelId));
        if (!label) {
          return { name: labelChange.fallbackLabel };
        }
        return label;
      });

      let entities: Array<{ title: string; number: string; type: string }> = [];

      if (isIssueCreatedMetadata(createdMetadata)) {
        entities = (createdMetadata.entities || []).map(entityChange =>
          getEntity(get, entityChange)
        );
      }

      let issues: Array<{ title: string; number: string }> = [];
      if (!isIssueCreatedMetadata(createdMetadata)) {
        issues = (createdMetadata.issues || []).map(issueChange => getEntity(get, issueChange));
      }

      let spaces: Array<{ name: string }> = [];
      let roadmaps: Array<{ name: string; column: string }> = [];

      if (isInitiativeCreatedMetadata(createdMetadata)) {
        spaces = (createdMetadata.spaces || []).map(spaceChange => {
          const space = get(spaceSelector(spaceChange.spaceId));
          if (!space) {
            return { name: spaceChange.fallbackSpaceName };
          }
          return space;
        });
        roadmaps = (createdMetadata.roadmaps || []).map(roadmapChange => {
          const roadmap = get(spaceSelector(roadmapChange.roadmapId));
          const column = get(roadmapColumnSelector(roadmapChange.columnId));

          if (!roadmap) {
            return {
              name: roadmapChange.fallbackRoadmapName,
              column: column?.name ?? roadmapChange.fallbackColumnName,
            };
          }

          return { name: roadmap.name, column: column?.name ?? roadmapChange.fallbackColumnName };
        });
      }

      return { assignees, labels, entities, issues, spaces, roadmaps };
    },
});

export const activitySelector = selectorFamily({
  key: 'ActivitySelector',
  get:
    (activityId: string) =>
    ({ get }) => {
      return get(syncEngineState(activityId)) as Activity | null;
    },
});

export const activitiesSelector = selectorFamily({
  key: 'ActivitiesSelector',
  get:
    (activityIds: string[]) =>
    ({ get }) => {
      const activities = activityIds.map(id => get(syncEngineState(id)) as Activity);
      return filterNotDeletedNotNull(activities);
    },
});

export function isActivity(obj: SyncEngineObject): obj is Activity {
  return obj.__typename === 'Activity';
}

export const isActivitySelector = selectorFamily({
  key: 'isActivity',
  get:
    (activityId: string | null | undefined) =>
    ({ get }) => {
      if (!activityId) {
        return null;
      }
      const activity = get(syncEngineState(activityId)) as SyncEngineObject;
      return activity?.__typename === 'Activity';
    },
});

export const entityIdForActivitySelector = selectorFamily({
  key: 'EntityIdForActivity',
  get:
    (activityId: string | null | undefined) =>
    ({ get }) => {
      if (!activityId) {
        return null;
      }
      const activity = get(syncEngineState(activityId)) as Activity | null;
      return activity?.entityId;
    },
});

function getEntity(get: GetRecoilValue, entityChange: EntityChange) {
  const entity = get(entitySelector(entityChange.entityId));
  const key = get(entityKeySelector(entityChange.entityId));

  return {
    title: entity?.title ?? entityChange.fallbackEntityTitle,
    number: key ?? `${entityChange.fallbackSpaceKey}-${entityChange.fallbackEntityNumber}`,
    type: entity?.__typename ?? entityChange.fallbackEntityType ?? '',
  };
}

export enum ActivityFilterMode {
  All,
  Comments,
}

export const activityFeedActiveFilterState = atom<ActivityFilterMode>({
  key: 'ActivitiesActiveFilter',
  default: ActivityFilterMode.All,
  effects: [localStorageEffect('__activitiesFilters')],
});

export function useActivityUrl() {
  const entityPath = useEntityPath();
  const { host } = useConfiguration();

  return (activity: Activity) => {
    const path = entityPath(activity.entityId);
    return `${host}${path}?activityId=${activity.id}`;
  };
}
