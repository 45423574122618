import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { uninstallDiscordIntegration } from '../../../../api/discord';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  SettingsSection,
} from '../../../../components/new/settings';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <p>Get information about mentioned {issueTerm}s in Discord and link Discord conversations.</p>
);

export function EditDiscordIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));

  if (!integration) {
    return <IntegrationNotFound />;
  }

  const config = JSON.parse(integration.config);

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Discord}
      description={
        <>
          {description}
          <p>
            The integration is connected to the Discord guild &quot;{config.guildName}&quot; and the
            Kitemaker bot will now look for Kitemaker links and mentions of {issueTerm}s
          </p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton
          buttonStyle={ButtonStyle.Destructive}
          onClick={async () => await uninstallDiscordIntegration(integration.id)}
        >
          Uninstall
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewDiscordIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(
      `${host}/integrations/discord/install?organizationId=${organization.id}`,
      electronScheme,
      {
        redirectBaseUrl: redirectUrl,
        redirectQueryParams: {
          pendingIntegration: 'discord',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Discord}
      description={
        <>
          {description}
          <p>
            To set up the integration, we'll need to install the Kitemaker app in your Discord
            server
          </p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Discord integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
