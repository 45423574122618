import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { RenderElementProps } from 'slate-react';
import { TodoMentionElement } from '../../../shared/slate/types';
import { stringifyDocument } from '../../../shared/slate/utils';
import { Icon, IconSize } from '../../components/new/icon';
import { useEntityPath } from '../../syncEngine/selectors/entities';
import { todoKeySelector, todoSelector, todoStatusToIcon } from '../../syncEngine/selectors/todos';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import styles from './todoMention.module.scss';
import { VoidInline } from './voidBlock';

export function StaticTodoMention({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: TodoMentionElement }) {
  const history = useHistory();
  const entityPath = useEntityPath();

  const { todoId } = element;
  const todo = useRecoilValue(todoSelector(todoId));
  const todoKey = useRecoilValue(todoKeySelector(todoId));

  if (!todo || !todoKey) {
    return (
      <DummyNode as="span" attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  const deleted = todo.deleted || todo.orphaned;
  const link = {
    pathname: entityPath(todo.entityId),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: todo.entityId,
    },
    search: `focusDescription=true&focusSmartTodo=${todo.id}`,
  };

  return (
    <span
      {...attributes}
      className={cn(styles.todoMention, 'clickable', {
        [styles.deleted]: deleted,
      })}
      onClick={() => {
        history.push(link);
      }}
    >
      <Icon icon={todoStatusToIcon(todo.status)} className={styles.icon} size={IconSize.Size20} />
      <span className={styles.key}>{todoKey}</span>
      <span className="semiBold mr2 ml4">{stringifyDocument(todo.todoContents).trim()}</span>
      {children}
    </span>
  );
}

export function TodoMention({
  attributes,
  element,
  children,
}: RenderElementProps & { element: TodoMentionElement }) {
  const history = useHistory();
  const entityPath = useEntityPath();

  const { todoId } = element;
  const todo = useRecoilValue(todoSelector(todoId));
  const todoKey = useRecoilValue(todoKeySelector(todoId));

  if (!todo || !todoKey) {
    return (
      <DummyNode as="span" attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  const deleted = todo.deleted || todo.orphaned;
  const link = {
    pathname: entityPath(todo.entityId),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: todo.entityId,
    },
    search: `focusDescription=true&focusSmartTodo=${todo.id}`,
  };

  return (
    <VoidInline
      attributes={attributes}
      element={element}
      className={cn(styles.todoMention, styles.slate, {
        [styles.deleted]: deleted,
      })}
      onClick={() => {
        history.push(link);
      }}
    >
      <Icon icon={todoStatusToIcon(todo.status)} className={styles.icon} size={IconSize.Size20} />
      <span className={styles.key}>{todoKey}</span>
      <span className="semiBold mr2 ml4">{stringifyDocument(todo.todoContents).trim()}</span>
      {children}
    </VoidInline>
  );
}
