import { minBy } from 'lodash';
import { parse } from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { COMMENT_THREAD_ID_QUERY_PARAM } from '../../shared/comments';
import { threadIdsForCommentsSelector } from '../syncEngine/selectors/comments';
import { scrollIntoView } from './scrolling';

export const currentInlineComment = atom<string | null>({
  key: 'CurrentInlineComment',
  default: null,
});

export function useCurrentInlineComment(commentId: string, open: boolean) {
  const setInlineComment = useSetRecoilState(currentInlineComment);
  React.useEffect(() => {
    setInlineComment(current => {
      if (open) {
        return commentId;
      }
      if (current === commentId) {
        return null;
      }
      return current;
    });
  }, [commentId, open]);
}

export function useFindSmallestCommentAtLocation() {
  return (x: number, y: number) => {
    const elements = document
      .elementsFromPoint(x, y)
      .filter(e => e.hasAttribute('data-comment-id') || e.hasAttribute('data-comment-ids'));

    if (!elements.length) {
      return;
    }

    // find all of the comment IDs
    const commentIds = elements.flatMap(e => {
      const commentId = e.getAttribute('data-comment-id');
      if (commentId) {
        return [commentId];
      }
      const commentIds = e.getAttribute('data-comment-ids');
      if (commentIds) {
        return commentIds.split(' ');
      }
      return [];
    });

    // find all of the elements for each comment ID and calculate the total width of all of those
    // elements so we can find the smallest one
    const widths = commentIds.map(commentId => {
      const commentElements = document.querySelectorAll(`[data-comment-id="${commentId}"]`);
      const voidElements = document.querySelectorAll(`[data-comment-ids~="${commentId}"]`);
      let width = 0;

      for (const element of [...commentElements, ...voidElements]) {
        width += element.getBoundingClientRect().width;
      }
      return { commentId, width };
    });

    const smallest = minBy(widths, w => w.width);
    return smallest?.commentId ?? null;
  };
}

export function useIsCommentOpenInActivityFeed(commentIds: string[]): boolean {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const threadIds = useRecoilValue(threadIdsForCommentsSelector(commentIds));

  React.useEffect(() => {
    const search = parse(location.search);
    setIsOpen(threadIds.includes(search[COMMENT_THREAD_ID_QUERY_PARAM] as string));
  }, [threadIds, location]);

  return isOpen;
}

export function useIsCommentOpenInline(commentIds: string[]): boolean {
  const open = useRecoilValue(currentInlineComment);
  return !!open && commentIds.includes(open);
}

export function scrollCommentIntoView(commentId: string) {
  const commentElements = document.querySelectorAll(`[data-comment-id="${commentId}"]`);
  const voidElements = document.querySelectorAll(`[data-comment-ids~="${commentId}"]`);

  const topMost = minBy([...commentElements, ...voidElements], e => e.getBoundingClientRect().top);
  if (topMost) {
    scrollIntoView(topMost as HTMLElement, {
      block: 'center',
      scrollMode: 'if-needed',
    });
  }
}
