import debugModule from 'debug';
import React from 'react';
import { GithubEntityInfo } from '../../shared/utils/github';
import { Organization } from '../../sync/__generated/models';
import { toast } from '../components/toast';

const debug = debugModule('github');

export interface ImportedAssignee {
  id: number;
  name?: string;
  login: string;
}

export interface GitHubImportRequest {
  id: string;
  importLabels: boolean;
  importClosedIssues: boolean;
  mapAssignees?: { [index: string]: string };
  spaceName: string;
}

export interface PreparedGitHubImport {
  id: string;
  assignees: ImportedAssignee[];
  newOpenIssueCount: number;
  newClosedIssueCount: number;
}

export async function checkGithubToken(): Promise<boolean> {
  try {
    const result = await fetch('/import/github/token', {
      credentials: 'same-origin',
    });

    if (!result.ok) {
      debug('Received non 2XX response fetching version');
      return false;
    }

    const jsonResult = await result.json();
    return jsonResult.data;
  } catch (e) {
    debug('Error checking GitHub token', e);
    toast.error('There was an error processing your request. Please reload and try again');
  }

  return false;
}

export async function importFromGitHub(request: GitHubImportRequest) {
  const response = await fetch('/import/github/import', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });
  const responseJson = await response.json();
  if (responseJson.errors) {
    toast.error(responseJson.errors?.[0]?.message ?? 'Import failed');
    return;
  }

  toast.info(`Import started. You'll be notified when it's done`);
}

export async function fetchReposFromGitHub(): Promise<string[]> {
  const result = await fetch('/import/github/repos', {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!result.ok) {
    toast.error(
      <>
        Unable to retrieve repositories from <span className="semiBold">GitHub</span>. Please try
        again
      </>
    );
    return [];
  }

  const jsonResult = await result.json();
  return jsonResult.data;
}

export async function prepareImportFromGitHub(
  repo: string,
  organization: Organization
): Promise<PreparedGitHubImport> {
  const response = await fetch('/import/github/prepare', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ repo, organizationId: organization.id }),
  });

  const responseJson = await response.json();
  if (responseJson.errors) {
    toast.error(
      responseJson.errors?.[0]?.message ?? (
        <>
          Error preparing to import from <span className="semiBold">GitHub</span>{' '}
        </>
      )
    );
    throw new Error('Unable to prepare import from GitHub');
  }

  const id = responseJson.data.id;
  let retryCount = 30;

  return await new Promise((resolve, reject) => {
    const checkPreparation = async () => {
      const response = await fetch(`/import/github/prepare?id=${id}`, {
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const responseJson = await response.json();
      if (responseJson.errors) {
        toast.error(
          responseJson.errors?.[0]?.message ?? (
            <>
              Error preparing to import from <span className="semiBold">GitHub</span>
            </>
          )
        );
        reject(new Error('Unable to prepare import from GitHub'));
        return;
      }

      if (responseJson.data.status === 'error' || retryCount-- <= 0) {
        reject(new Error('Unable to prepare import from GitHub'));
        return;
      }

      if (responseJson.data.status === 'ready') {
        resolve(responseJson.data);
        return;
      }

      setTimeout(checkPreparation, 5000);
    };

    checkPreparation();
  });
}

export async function fetchGitHubEntity({
  owner,
  repo,
  organization,
  number,
  type,
}: {
  owner: string;
  repo: string;
  organization: string;
  number: number;
  type: string;
}): Promise<GithubEntityInfo | null> {
  const result = await fetch(
    `/integrations/github/link?owner=${owner}&repo=${repo}&organization_id=${organization}&number=${number}&type=${type}`,
    {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (!result.ok) {
    throw Error(
      'Error fetching link. Please ensure the link is valid and that the Kitemaker GitHub application has permission to access it.'
    );
    return null;
  }

  const jsonResult = await result.json();
  return jsonResult.data;
}

export async function prepareInstall(
  organizationId: string,
  enterpriseConfig?: {
    baseUrl: string;
    appId: number;
    privateKey: string;
  } | null
) {
  const preparedResult = await fetch('/integrations/github/prepareInstall', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      organizationId,
      ...(enterpriseConfig ? { enterpriseConfig } : {}),
    }),
  });

  const result: { pendingInstallId: string; installUrl: string } = await preparedResult.json();
  return result;
}
