import { parse } from 'query-string';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { COMMENT_ID_QUERY_PARAM, COMMENT_THREAD_ID_QUERY_PARAM } from '../../../shared/comments';
import { useConfiguration } from '../../contexts/configurationContext';
import { useIsMediumScreen } from '../../hooks/useResponsiveDesign';
import { insightIdsForEntity } from '../../syncEngine/selectors/insights';
import { todoCountForEntitySelector } from '../../syncEngine/selectors/todos';
import { removeQueryParameter } from '../../utils/query';
import { useSetKeyNavigationFocus } from './keyNavigation';
import { Tab } from './tabs';

export function useInitialTab() {
  const mediumScreen = useIsMediumScreen();
  return React.useMemo(() => {
    return mediumScreen ? 'description' : 'activities';
  }, [mediumScreen]);
}

export function useEntityTabs(
  entityId: string,
  additionalTabs?: Tab[],
  defaultTab?: Tab
): Array<Tab> {
  const { featureFlags } = useConfiguration();
  const insightCount = useRecoilValue(insightIdsForEntity(entityId)).length;
  const todoCount = useRecoilValue(todoCountForEntitySelector(entityId));
  const mediumScreen = useIsMediumScreen();
  return React.useMemo(() => {
    return [
      ...(mediumScreen ? [defaultTab ?? { id: 'description', name: 'Description' }] : []),
      ...(additionalTabs ?? []),
      {
        id: 'activities',
        icon: 'activity',
        name: 'Activities',
      },
      {
        id: 'insights',
        icon: 'insights',
        name: 'Insights',
        count: insightCount ? insightCount : undefined,
      },
      ...(featureFlags.FEATURE_TOGGLE_TODO_LIST
        ? [
            {
              id: 'todos',
              icon: 'todo_checkmark',
              name: 'Todos',
              count: todoCount.total,
            },
          ]
        : []),
    ];
  }, [mediumScreen, insightCount, todoCount, featureFlags]);
}

export function QueryParameterManager({
  onOpenThreadIdChanged,
  onCommentOrActivityIdChanged,
  onInsightIdChanged,
}: {
  onOpenThreadIdChanged: (threadId: string | null) => void;
  onCommentOrActivityIdChanged: (commentOrActivityId: string | null) => void;
  onInsightIdChanged: (insightId: string | null) => void;
}) {
  const history = useHistory();
  const location = useLocation();
  const query = parse(location.search);
  const threadId = (query[COMMENT_THREAD_ID_QUERY_PARAM] ?? null) as string | null;
  const commentOrActivityId = (query[COMMENT_ID_QUERY_PARAM] ?? query.activityId ?? null) as
    | string
    | null;
  const insightId = query.insightId as string | null;
  const setFocus = useSetKeyNavigationFocus();

  React.useEffect(() => {
    onOpenThreadIdChanged(threadId);
  }, [onOpenThreadIdChanged, threadId]);

  React.useEffect(() => {
    if (commentOrActivityId) {
      removeQueryParameter(history, COMMENT_ID_QUERY_PARAM);
      removeQueryParameter(history, 'activityId');
      setFocus(commentOrActivityId);
    }
    onCommentOrActivityIdChanged(commentOrActivityId);
  }, [commentOrActivityId]);

  React.useEffect(() => {
    if (insightId) {
      removeQueryParameter(history, 'insightId');
      setFocus(insightId);
    }
    onInsightIdChanged(insightId);
  }, [insightId]);
  return null;
}
