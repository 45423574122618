import cn from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  DependencyActivityDetails,
  DependencyType,
} from '../../../../sync/__generated/models';
import { useEntityPath } from '../../../syncEngine/selectors/entities';
import { issueTitleSelector, spaceKeyForIssueSelector } from '../../../syncEngine/selectors/issues';
import ActorName from '../../actorName';
import { IconSize } from '../icon';
import { DependencyIcon, DependencyState } from '../metadata/dependency';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function getTerm(dependencyType: DependencyType, inverse: boolean, removed: boolean) {
  const setVerb = removed ? 'unset' : 'set';

  if (dependencyType === DependencyType.DependsOn && !inverse) {
    return `${setVerb} as depending on`;
  }
  if (dependencyType === DependencyType.DependsOn && inverse) {
    return `${setVerb} as enabling`;
  }
  if (dependencyType === DependencyType.BlockedBy && !inverse) {
    return `${setVerb} as blocked by`;
  }
  if (dependencyType === DependencyType.BlockedBy && inverse) {
    return `${setVerb} as blocking`;
  }

  return null;
}

function calculateState(dependencyType: DependencyType, inverse: boolean) {
  if (dependencyType === DependencyType.DependsOn && !inverse) {
    return DependencyState.DEPENDS_ON;
  }
  if (dependencyType === DependencyType.DependsOn && inverse) {
    return DependencyState.ENABLES;
  }

  if (dependencyType === DependencyType.BlockedBy && !inverse) {
    return DependencyState.BLOCKED;
  }
  return DependencyState.BLOCKS;
}

function DependencyActivityComponent({ activity }: { activity: ActivityModel }) {
  const {
    targetId,
    dependencyType,
    inverse,
    removed,
    fallbackEntityNumber,
    fallbackEntityTitle,
    fallbackSpaceKey,
  } = activity.details as DependencyActivityDetails;
  const term = getTerm(dependencyType, inverse, removed);

  const entityPath = useEntityPath();
  const path = entityPath(targetId);
  const title = useRecoilValue(issueTitleSelector(targetId));
  const spaceKey = useRecoilValue(spaceKeyForIssueSelector(targetId));

  const issueRef = spaceKey
    ? `${spaceKey}-${fallbackEntityNumber}`
    : `${fallbackSpaceKey}-${fallbackEntityNumber}`;
  const issueTitle = title ?? fallbackEntityTitle;

  const link = path ? (
    <Link to={path}>
      {issueRef} {issueTitle}
    </Link>
  ) : (
    `${issueRef} ${issueTitle}`
  );
  return (
    <Activity
      activity={activity}
      icon={
        <DependencyIcon state={calculateState(dependencyType, inverse)} size={IconSize.Size24} />
      }
    >
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> {term}{' '}
      <span className={cn(styles.activityHighlight, 'fs-exclude')}>{link}</span>.
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const DependencyActivity = React.memo(DependencyActivityComponent);
