import confetti from 'canvas-confetti';
import { hslToHex } from '../../shared/utils/colors';
import { paletteColors } from './config';

const defaults = { startVelocity: 30, spread: 360, ticks: 60 };

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const kite = confetti.shapeFromPath({
  path: `M1.7268 7.18243L21.6721 1.04658C22.4621 0.803571 23.1989 1.55215 22.9512 2.34601L16.7325 22.2759C16.4207 23.2751 15.0015 23.228 14.7557 22.2102L12.3982 12.4477C12.3082 12.0752 12.0211 11.7834 11.6522 11.6894L1.77505 9.17444C0.767433 8.91787 0.732807 7.48821 1.7268 7.18243Z`,
});

function shootConfetti() {
  const duration = 3 * 1000;
  const animationEnd = Date.now() + duration;
  const interval: any = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);

    const computedStyle = getComputedStyle(document.body);

    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.8), y: Math.random() - 0.2 },
        shapes: [kite],
        colors: paletteColors.map(color =>
          hslToHex(computedStyle.getPropertyValue(`--${color}10`))
        ),
        scalar: 2,
      })
    );
  }, 100);
}

export default shootConfetti;
