import React from 'react';
import { useRecoilValue } from 'recoil';
import { RoadmapInitiative } from '../../../../../graphql__generated__/graphql';
import {
  roadmapColumnTypeToIcon,
  roadmapColumnsForRoadmapSelector,
} from '../../../syncEngine/selectors/roadmaps';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import styles from '../menu/menu.module.scss';
import { Picker, PickerState } from '../picker';

export function initiativesToRoadmapColumnPickerState(
  initiatives: Array<RoadmapInitiative>
): PickerState {
  return initiatives.reduce((result, initiative) => {
    result[initiative.id] = [initiative.columnId];
    return result;
  }, {} as PickerState);
}

export function RoadmapColumnPicker({
  state,
  roadmapId,
  onDone,
  onPicked,
  filterClassName,
}: {
  state: PickerState;
  roadmapId: string;
  onPicked: (roadmapInitiativeIds: string[], columnId: string) => void;
  onDone: () => void;
  filterClassName?: string;
}) {
  const columns = useRecoilValue(roadmapColumnsForRoadmapSelector(roadmapId));

  const items: ListViewItem[] = columns.map(column => {
    const icon = roadmapColumnTypeToIcon(column.columnType);

    return {
      ...column,
      contents: () => (
        <>
          <Icon size={IconSize.Size20} icon={icon} />
          <span className={styles.text}>{column.name}</span>
        </>
      ),
    };
  });

  const onAdd = React.useCallback(
    (roadmapInitiativeIds: string[], columnId: string) => {
      onPicked(roadmapInitiativeIds, columnId);
      onDone();
    },
    [onDone, onPicked]
  );

  return (
    <Picker
      noSort
      filterLabel="Change column"
      filterPlaceholder="Change column"
      state={state}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onAdd}
      onRemove={onDone}
      onDone={onDone}
      filterClassName={filterClassName}
    />
  );
}
