import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { isURL } from 'validator';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { prepareInstall } from '../../../../api/github';
import { ClipboardText } from '../../../../components/clipboardText';
import ExternalLink from '../../../../components/externalLink';
import { Button, ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  Setting,
  SettingsSection,
} from '../../../../components/new/settings';
import { TextValidationInput } from '../../../../components/new/textInput';
import { toast } from '../../../../components/toast';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { isElectron, openLinkInBrowser } from '../../../../electronIntegration';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <>
    <p>
      You can automate your workflow, so your work items update based on your commits and pull
      requests.
    </p>
    <p>
      When you mention a work item in a commit or pull request with keywords <i>“Fixes”</i> or{' '}
      <i>“Closes”</i> (e.g. <i>“Fixes KM-123”</i>) we move it to a specified status. You can set
      Automation for each of your spaces.
    </p>
    <p>
      <ExternalLink href="https://guide.kitemaker.co/integrations-overview/github-and-gitlab">
        Read more in the Kitemaker Guide
      </ExternalLink>
    </p>
  </>
);

export function EditGitHubIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));
  if (!integration) {
    return <IntegrationNotFound />;
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Github} description={description}>
      <SettingsSection>
        <AdminButton
          onClick={() => {
            const config = JSON.parse(integration.config);
            if (isElectron()) {
              openLinkInBrowser(config.url);
            } else {
              window.open(config.url);
            }
          }}
        >
          Manage permissions and access in GitHub
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewGitHubIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();
  const [githubHost, setgithubHost] = React.useState('');
  const [appId, setAppId] = React.useState('');
  const [appSecret, setAppSecret] = React.useState('');
  const [selfHosted, setSelfHosted] = React.useState(false);

  async function startInstallation() {
    try {
      let enterpriseConfig: {
        baseUrl: string;
        appId: number;
        privateKey: string;
      } | null = null;

      if (githubHost || appId || appSecret) {
        if (!githubHost || !appId || !appSecret) {
          toast.error('All fields are required');
          return;
        }

        if (isNaN(parseInt(appId))) {
          toast.error('Application ID must be a number');
          return;
        }

        const baseUrl = githubHost.replace(/\/+$/, '');
        enterpriseConfig = {
          baseUrl,
          appId: Number(appId),
          privateKey: appSecret,
        };
      }

      const preparedJson = await prepareInstall(organization.id, enterpriseConfig);
      externalAuthFlow(
        `${host}/integrations/github/install?pendingInstallId=${preparedJson.pendingInstallId}`,
        electronScheme,
        {
          redirectBaseUrl: redirectUrl,
          redirectQueryParams: {
            pendingIntegration: 'github',
          },
        }
      );
    } catch (e) {
      toast.error('Failed to install integration');
    }
  }

  if (selfHosted) {
    return (
      <IntegrationSettingsPage integrationType={IntegrationType.Github} description={description}>
        <SettingsSection largeHeader title="GitHub Enterprise installation">
          <SettingsSection>
            <p>
              <ol>
                <li>
                  Create a github app with your chosen name
                  <ul>
                    <li>
                      Homepage URL
                      <br />
                      <ClipboardText content={host} contentName="Homepage URL">
                        <pre>{host}</pre>
                      </ClipboardText>
                    </li>
                    <li>
                      Callback URL
                      <br />
                      <ClipboardText
                        content={`${host}/integrations/github/auth/redirect`}
                        contentName="Callback URL"
                      >
                        <pre>{host}/integrations/github/auth/redirect</pre>
                      </ClipboardText>
                    </li>
                    <li>
                      Setup URL
                      <br />
                      <ClipboardText
                        content={`${host}/integrations/github/setup`}
                        contentName="Setup URL"
                      >
                        <pre>{host}/integrations/github/setup</pre>
                      </ClipboardText>
                    </li>
                    <li>
                      Webhook URL
                      <br />
                      <ClipboardText
                        content={`${host}/integrations/github/webhook`}
                        contentName="Webhook URL"
                      >
                        <pre>{host}/integrations/github/webhook</pre>
                      </ClipboardText>
                    </li>

                    <li>
                      Permissions
                      <br />
                      Contents, pull-requests, and issues (optional)
                    </li>
                    <li>
                      Events
                      <br />
                      Pull-requests and push
                    </li>
                  </ul>
                </li>

                <li>Setup private key</li>
                <li>
                  Setting up automation to automatically move {issueTerm}s in Kitemaker based on
                  events in github
                </li>
              </ol>
            </p>
          </SettingsSection>

          <SettingsSection>
            <Setting title="GitHub URL">
              <TextValidationInput
                validate={v => (isURL(v) ? null : 'Invalid URL')}
                onChange={e => setgithubHost(e.target.value)}
                placeholder="https://github.yourdomain.com"
              />
            </Setting>

            <Setting title="Application ID">
              <TextValidationInput
                validate={v => (isURL(v) ? null : 'Invalid URL')}
                onChange={e => setAppId(e.target.value)}
                placeholder="42"
              />
            </Setting>

            <Setting vertical title="Application secret">
              <textarea
                className="fullWidth"
                style={{ minHeight: '200px' }}
                onChange={e => setAppSecret(e.target.value)}
                placeholder="-----BEGIN RSA PRIVATE KEY-----
MIIEDMrGjBhbVrfhsTq96tDzlZeELeGK4478xPQ30U53KV9an368iFUaOhvwNwnhZrZ
MIIEDMrGjBhbVrfhsTq96tDzlZeELeGK4478xPQ30U53KV9an368iFUaOhvwNwnhZrZ
SV/ZRihyCfOI81AAAACm5lka7eEBwb3Atb3M=
-----END OPENSSH PRIVATE KEY-----"
              ></textarea>
            </Setting>
          </SettingsSection>

          <div>
            <Button className="mr16" onClick={() => setSelfHosted(false)}>
              Hosted Github
            </Button>
            <AdminButton buttonStyle={ButtonStyle.Primary} onClick={() => startInstallation()}>
              Install GitHub Enterprise integration
            </AdminButton>
          </div>
        </SettingsSection>
      </IntegrationSettingsPage>
    );
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Github} description={description}>
      <SettingsSection largeHeader title="Installation">
        <p>The installation will follow these steps:</p>
        <p>
          <ol>
            <li>Installation of KitemakerHQ GitHub Application</li>
            <li>
              Setting up automation to automatically move work items in Kitemaker based on events in
              GitHub
            </li>
          </ol>
        </p>

        <div className="mb24">
          <AdminButton
            buttonStyle={ButtonStyle.Primary}
            className="mr16"
            onClick={() => startInstallation()}
          >
            Install GitHub integration
          </AdminButton>
          <Button onClick={() => setSelfHosted(true)}>Github Enterprise</Button>
        </div>

        <p className="italic mt16">
          KitemakerHQ Github Application will ask to have code read access in order to see your
          commit messages. We will never read your actual codebase.
        </p>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
