import { CycleEntity, RoadmapInitiative } from '../../../../graphql__generated__/graphql';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { between } from '../../../shared/utils/sorting';
import {
  BoardColumn,
  Doc,
  Folder,
  Initiative,
  InitiativeSpace,
  Issue,
  Roadmap,
  Space,
  View,
} from '../../../sync/__generated/models';
import { SyncEngineGetters } from '../../graphql/modelManager';
import { SyncEngineIndex, SyncEngineObject } from '../types';

export function indexHelper<T extends SyncEngineObject>(
  { get, getIndex }: SyncEngineGetters,
  index: SyncEngineIndex,
  id: string,
  includeDeleted = false
): T[] {
  const ids = getIndex(index, id);
  const values = ids.map(i => get<T>(i));
  if (includeDeleted) {
    return filterNotNull(values);
  }
  return filterNotDeletedNotNull(values);
}

export type Sortable =
  | Space
  | Issue
  | BoardColumn
  | RoadmapInitiative
  | CycleEntity
  | Roadmap
  | Initiative
  | InitiativeSpace
  | Doc
  | Folder
  | View;

export function updateSortableSorts(
  all: Sortable[],
  toMoveIds: string[],
  afterId?: string,
  beforeId?: string,
  forceResort?: boolean
): Record<string, string> {
  const toMove = filterNotNull(
    toMoveIds.map(toMoveId => all.find(sortable => sortable.id === toMoveId) ?? null)
  );
  if (!toMove.length) {
    return {};
  }

  const afterSortable = all.find(sortable => sortable.id === afterId);
  const beforeSortable = all.find(sortable => sortable.id === beforeId);

  let updatedSorts: Record<string, string> = {};
  let sort = between({ after: afterSortable?.sort, before: beforeSortable?.sort });

  for (const sortable of toMove) {
    updatedSorts[sortable.id] = sort;
    sort = between({ after: sort, before: beforeSortable?.sort });
  }

  // check if any of the sorts clashes with beforeSortable. It they do, we need to resort everything
  const newSorts = Object.values(updatedSorts);
  let resort = forceResort ?? false;

  for (const newSort of newSorts) {
    if (beforeSortable && newSort >= beforeSortable.sort) {
      resort = true;
      break;
    }

    if (afterSortable && newSort <= afterSortable.sort) {
      resort = true;
      break;
    }
  }

  if (!resort) {
    return updatedSorts;
  }

  updatedSorts = {};
  sort = between({});

  let tempBeforeSort = beforeSortable?.sort;
  let tempAfterSort = afterSortable?.sort;

  // resort the whole list being sure to keep track of beforeSpace and afterSpace's new sorts
  for (const sortable of all) {
    updatedSorts[sortable.id] = sort;
    if (sortable.id === afterSortable?.id) {
      tempAfterSort = sort;
    }
    if (sortable.id === beforeSortable?.id) {
      tempBeforeSort = sort;
    }
    sort = between({ after: sort });
  }

  // redo the move
  sort = between({ after: tempAfterSort, before: tempBeforeSort });
  for (const sortable of toMove) {
    updatedSorts[sortable.id] = sort;
    sort = between({ after: sort, before: tempBeforeSort });
  }

  return updatedSorts;
}
