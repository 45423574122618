import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { filterNotNull } from '../../../shared/utils/convenience';
import { issueTerm } from '../../../shared/utils/terms';
import { CycleStatus } from '../../../sync/__generated/models';
import {
  CommandGroup,
  EntityCommandGroupContext,
  TodoCommandGroupContenxt,
} from '../../commands/state';
import {
  MetadataConfigOption,
  MetadataConfigurationButton,
  boardMetadataConfig,
} from '../../components/metadataConfig';
import { Button, ButtonStyle, IconButton } from '../../components/new/button';
import { CommandContext } from '../../components/new/commandMenuContext';
import { Count } from '../../components/new/count';
import { CustomCommand } from '../../components/new/customCommand';
import { Hotkey } from '../../components/new/hotkey';
import { Icon } from '../../components/new/icon';
import {
  KeyNavigationProvider,
  useClearSelection,
  useGetKeyNavigationState,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useKeyNavigationElement,
  useKeyNavigationState,
} from '../../components/new/keyNavigation';
import EmptyPlaceholder from '../../components/new/placeholder';
import { TodoListItem } from '../../components/new/todoListItem';
import { VirtualizedListView } from '../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../components/new/virtualizedListViewHelpers';
import { EditWorkItemListItem, WorkItemListItem } from '../../components/new/workItemListItem';
import { CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { SpaceProvider, useSpace } from '../../contexts/spaceContext';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import { useUpdateCycleEntitiesSort } from '../../syncEngine/actions/cycles';
import {
  cycleEntitiesForCycleSelector,
  cycleSelector,
  entityInCycleSelector,
  todoInCycleSelector,
} from '../../syncEngine/selectors/cycles';
import {
  spaceIdForEntitySelector,
  useEntityNumberWidths,
} from '../../syncEngine/selectors/entities';
import { useIsIssue } from '../../syncEngine/selectors/issues';
import { objectTypeSelector } from '../../syncEngine/selectors/myWork';
import { todoSelector } from '../../syncEngine/selectors/todos';
import { syncEngineState } from '../../syncEngine/state';
import { SyncEngineObject } from '../../syncEngine/types';
import { alternateComboKey, mainComboKey, toggleHideKey, vimDown, vimUp } from '../../utils/config';
import { Placeholder } from '../new/workItemBoardScreen/placeholder';
import styles from './cycleScreen.module.scss';
import { completedCycleSelector, cycleScreenSelector, hideCompletedInCycleAtom } from './data';

function CycleTodoItem({
  id,
  cycleId,
  keyNavId,
  className,
  style,
}: {
  id: string;
  cycleId: string;
  keyNavId: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  const todo = useRecoilValue(todoSelector(id));
  const spaceId = useRecoilValue(spaceIdForEntitySelector(todo?.entityId));
  const inCyle = useRecoilValue(todoInCycleSelector({ cycleId, todoId: id }));

  return (
    <SpaceProvider spaceId={spaceId ?? ''}>
      <TodoListItem
        id={id}
        keyNavId={keyNavId}
        style={style}
        className={cn(styles.todo, className, { [styles.grayed]: !inCyle })}
      />
    </SpaceProvider>
  );
}

export function ListItem({
  id: keyNavId,
  cycleId,
  className,
  style,
  onEdit,
  onEditComplete,
  moveToTopBottom,
}: {
  id: string;
  cycleId: string;
  className?: string;
  style?: React.CSSProperties;
  onEdit?: () => void;
  onEditComplete?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom') => void;
}) {
  const id = keyNavId.includes('complete') ? keyNavId.split('-')[1] : keyNavId;

  const spaceId = useRecoilValue(spaceIdForEntitySelector(id));
  const type = useRecoilValue(objectTypeSelector(id));
  const entityInCycle = useRecoilValue(entityInCycleSelector({ cycleId, entityId: id }));
  const ref = React.useRef<HTMLDivElement>(null);
  const metaConfig = useRecoilValue(boardMetadataConfig(`${spaceId}-cycle`));

  const focused = useHasKeyNavigationFocus(keyNavId);
  React.useEffect(() => {
    if (focused && ref.current) {
      scrollIntoView(ref.current, { block: 'center', scrollMode: 'if-needed' });
    }
  }, [focused]);

  switch (type) {
    case 'Issue':
      return (
        <div ref={ref}>
          <SpaceProvider spaceId={spaceId ?? ''}>
            {onEditComplete ? (
              <EditWorkItemListItem
                id={id}
                onDone={onEditComplete}
                className={className}
                style={style}
              />
            ) : (
              <WorkItemListItem
                showStatus
                id={id}
                keyNavId={keyNavId}
                style={style}
                className={cn(className, { [styles.grayed]: !entityInCycle })}
                onChangeTitle={onEdit}
                metadataConfig={metaConfig}
                moveToTopBottom={moveToTopBottom}
              />
            )}
          </SpaceProvider>
        </div>
      );
    case 'Todo':
      return (
        <div ref={ref}>
          <CycleTodoItem
            cycleId={cycleId}
            id={id}
            keyNavId={keyNavId}
            style={style}
            className={className}
          />
        </div>
      );
    default:
      return (
        <div>
          {type} - {id}
        </div>
      );
  }
}

function CycleSectionHeader({
  id,
  label,
  count,
  collapsed,
  onToggleCollapsed,
}: {
  id: string;
  label: string;
  count: number;
  collapsed: boolean;
  onToggleCollapsed: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const focused = useHasKeyNavigationFocus(id);
  useKeyNavigationElement(`header-${id}`, ref);

  return (
    <div className={'listHeaderContainer'}>
      <div className={'listHeader'} onClick={onToggleCollapsed} ref={ref}>
        <div className="row grow ellipsis">
          <Icon
            icon={collapsed ? 'arrow_forward' : 'arrow_down'}
            className="mr8 clickable grayIcon"
          />
          <div className="ellipsis mr8">{capitalize(label)}</div>
          <Count count={count} />
        </div>
      </div>
      {focused && (
        <>
          <Hotkey
            hotkey="space"
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              onToggleCollapsed();
            }}
          />
          <Hotkey
            hotkey="enter"
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              onToggleCollapsed();
            }}
          />
        </>
      )}
    </div>
  );
}

function WorkItemGroup({
  issueId,
  todoIds,
  cycleId,
  lastIndex,
  listIndex,
  moveToTopBottom,
}: {
  issueId: string;
  todoIds: string[];
  cycleId: string;
  lastIndex: number;
  listIndex: number;
  moveToTopBottom?: (direction: 'top' | 'bottom') => void;
}) {
  return (
    <>
      <ListItem
        key={`list-${issueId}`}
        id={issueId}
        cycleId={cycleId}
        className={cn('listItem', {
          first: listIndex === 0,
          last: listIndex === lastIndex,
        })}
        moveToTopBottom={moveToTopBottom}
      />
      {todoIds.map((id, todoIndex) => {
        return (
          <ListItem
            key={`list-${id}`}
            id={id}
            cycleId={cycleId}
            className={cn('listItem', {
              last: listIndex + 1 + todoIndex === lastIndex,
            })}
          />
        );
      })}
    </>
  );
}

function UnfinishedCycleCommandContext() {
  const { focused, selected } = useKeyNavigationState();
  const ids = selected ? selected : focused ? [focused] : [];
  const focusedItem = useRecoilValue(syncEngineState(focused ?? '')) as SyncEngineObject | null;

  const context: TodoCommandGroupContenxt | EntityCommandGroupContext =
    focusedItem?.__typename === 'Todo'
      ? { group: CommandGroup.Todos, todoId: ids[0], focusedTodoId: focused }
      : { group: CommandGroup.Entities, entityIds: ids, focusedEntityId: focused };

  if (context.group === CommandGroup.Todos && !context.todoId) {
    return null;
  }

  return <CommandContext context={context} />;
}

function UnfinishedCycle({
  cycleId,
  numberColumnWidth,
}: {
  cycleId: string;
  numberColumnWidth?: number;
}) {
  const space = useSpace();
  const [hideCompleted, setHideCompleted] = useRecoilState(hideCompletedInCycleAtom(space.id));
  const data = useRecoilValue(cycleScreenSelector(cycleId));
  const keyNavIds = Object.entries(data).reduce(
    (acc, [key, values]) => acc.concat([key, ...values]),
    [] as string[]
  );
  useKeyNavigationColumn('cycle-screen', keyNavIds);
  const updateSorts = useUpdateCycleEntitiesSort();
  const modals = useModals();
  const isTinyScreen = useIsTinyScreen();

  const clearSelection = useClearSelection();
  const [dragCount, setDragCount] = React.useState<number | null>(null);
  const getKeyNavState = useGetKeyNavigationState();

  let listIndex = 0;
  const groups = Object.entries(data).map(([issueId, todoIds], index) => {
    const group = {
      issueId: issueId,
      todoIds: todoIds,
      index: index,
      listIndex: listIndex,
      lastIndex: keyNavIds.length - 1,
    };
    listIndex += 1 + todoIds.length;
    return group;
  });

  const move = useRecoilCallback(
    ({ snapshot }) =>
      (direction: 'up' | 'down', moveToTopOrBottom = false) => {
        const { selected, focused } = getKeyNavState();
        if (!selected && !focused) {
          return;
        }

        const cycleEntities = snapshot
          .getLoadable(cycleEntitiesForCycleSelector(cycleId))
          .getValue();

        const idsToMove = filterNotNull(selected?.length ? selected : [focused]);

        if (moveToTopOrBottom) {
          const cycleEntityIdsToMove = filterNotNull(
            idsToMove.map(id => cycleEntities.find(ce => ce.entityId === id)?.id)
          );

          if (direction === 'up') {
            const beforeId = cycleEntities[0]?.id;
            updateSorts(cycleId, cycleEntityIdsToMove, undefined, beforeId);
          }
          if (direction === 'down') {
            const afterId = cycleEntities[cycleEntities.length - 1]?.id;
            updateSorts(cycleId, cycleEntityIdsToMove, afterId, undefined);
          }
          return;
        }

        // if we aren't moving everything to the top/bottom, try to preserve the grouping of the
        // multi selected items. This _almost_ works, it breaks when things have a 1 item gap
        // between them. The bug is not in this code, but rather a problem with recoil and the
        // updateSorts callback. It ends up with stale data and this the seperate groups get merged

        const indexesToMove = idsToMove
          .map(idToMove => cycleEntities.findIndex(ce => ce.entityId === idToMove))
          .sort();
        const sequences: number[][] = [];
        let currentSequence: number[] = [];

        for (const index of indexesToMove) {
          if (currentSequence.length === 0) {
            currentSequence.push(index);
          } else if (index === currentSequence[currentSequence.length - 1] + 1) {
            currentSequence.push(index);
          } else {
            sequences.push(currentSequence);
            currentSequence = [index];
          }
        }

        if (currentSequence.length > 0) {
          sequences.push(currentSequence);
        }

        const operations = [];

        for (const indexesToMove of sequences) {
          const cycleEntitiesToMove = indexesToMove.map(i => cycleEntities[i]);
          const cycleEntityIdsToMove = cycleEntitiesToMove.map(ce => ce.id);

          if (direction === 'up') {
            if (indexesToMove[0] === 0) {
              continue;
            }
            const afterId = cycleEntities[indexesToMove[0] - 2]?.id;
            const beforeId = cycleEntities[indexesToMove[0] - 1]?.id;
            operations.push({
              cycleId,
              cycleEntityIdsToMove,
              afterId,
              beforeId,
            });
          }
          if (direction === 'down') {
            if (indexesToMove[indexesToMove.length - 1] === cycleEntities.length - 1) {
              continue;
            }
            const afterId = cycleEntities[indexesToMove[indexesToMove.length - 1] + 1]?.id;
            const beforeId = cycleEntities[indexesToMove[indexesToMove.length - 1] + 2]?.id;
            operations.push({
              cycleId,
              cycleEntityIdsToMove,
              afterId,
              beforeId,
            });
          }
        }

        for (const op of operations) {
          updateSorts(op.cycleId, op.cycleEntityIdsToMove, op.afterId, op.beforeId);
        }
      }
  );

  const moveToTopOrBottom = React.useCallback(
    (direction: 'top' | 'bottom') => {
      move(direction === 'top' ? 'up' : 'down', true);
    },
    [move]
  );

  const renderedWorkItems = groups.map(({ issueId, todoIds, listIndex, lastIndex }, index) => {
    const group = (
      <Draggable draggableId={issueId} index={index} key={`draggable-${issueId}`}>
        {itemProvided => {
          return (
            <div
              ref={itemProvided.innerRef}
              {...itemProvided.draggableProps}
              {...itemProvided.dragHandleProps}
              tabIndex={-1}
            >
              <WorkItemGroup
                key={`group-${issueId}`}
                issueId={issueId}
                todoIds={todoIds}
                cycleId={cycleId}
                listIndex={listIndex}
                lastIndex={lastIndex}
                moveToTopBottom={moveToTopOrBottom}
              />
            </div>
          );
        }}
      </Draggable>
    );
    return group;
  });

  const onDragEnd = useRecoilCallback(
    ({ snapshot }) =>
      (result: DropResult) => {
        if (!result.destination || result.destination.index === result.source.index) {
          return;
        }
        const cycleEntities = snapshot
          .getLoadable(cycleEntitiesForCycleSelector(cycleId))
          .getValue();
        const movedCycleEntity = cycleEntities.find(ce => ce.entityId === result.draggableId);

        if (!movedCycleEntity) {
          return;
        }

        const data = snapshot.getLoadable(cycleScreenSelector(cycleId)).getValue();

        const issueIds = Object.keys(data);

        const indexToInsertAt = result.destination.index;

        const [movedItem] = issueIds.splice(result.source.index, 1);
        issueIds.splice(indexToInsertAt, 0, movedItem);

        const afterId = cycleEntities.find(ce => ce.entityId === issueIds[indexToInsertAt - 1])?.id;
        const beforeId = cycleEntities.find(
          ce => ce.entityId === issueIds[indexToInsertAt + 1]
        )?.id;

        const { selected } = getKeyNavState();

        const selectedCycleEntityIds = selected
          ?.map(id => {
            return cycleEntities.find(ce => ce.entityId === id)?.id;
          })
          .filter(id => id) as string[] | undefined;

        updateSorts(cycleId, selectedCycleEntityIds ?? [movedCycleEntity.id], afterId, beforeId);
      },
    [cycleId]
  );

  return (
    <>
      <UnfinishedCycleCommandContext />
      {keyNavIds.length > 0 && (
        <>
          <CustomCommand
            command={{
              id: 'move-down',
              group: CommandGroup.Other,
              description: 'Move down',
              hotkey: `${alternateComboKey}+down`,
              handler: () => {
                move('down');
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-bottom',
              group: CommandGroup.Other,
              description: 'Move to bottom',
              hotkey: `${mainComboKey}+${alternateComboKey}+down`,
              handler: () => {
                move('down', true);
              },
            }}
          />
          <Hotkey
            hotkey={`${alternateComboKey}+${vimDown}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('down');
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+${alternateComboKey}+${vimDown}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('down', true);
            }}
          />

          <CustomCommand
            command={{
              id: 'move-up',
              group: CommandGroup.Other,
              description: 'Move up',
              hotkey: `${alternateComboKey}+up`,
              handler: () => {
                move('up');
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-top',
              group: CommandGroup.Other,
              description: 'Move to top',
              hotkey: `${mainComboKey}+${alternateComboKey}+up`,
              handler: () => {
                move('up', true);
              },
            }}
          />
          <Hotkey
            hotkey={`${alternateComboKey}+${vimUp}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('up');
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+${alternateComboKey}+${vimUp}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('up', true);
            }}
          />

          <div className={styles.unfinishedHeader}>
            <div className="row">
              <Icon className="mr6" icon="order" />
              <span className="headingS semibold">Sorted manually</span>
            </div>
            {!isTinyScreen && (
              <div className="row">
                <MetadataConfigurationButton
                  boardId={`${space.id}-cycle`}
                  showDot={hideCompleted}
                  extraSettings={
                    <MetadataConfigOption
                      title="Hide completed items"
                      checked={hideCompleted}
                      onChange={setHideCompleted}
                      hotkey={{ key: toggleHideKey, hint: 'Hide completed items' }}
                    />
                  }
                />
                <CustomCommand
                  command={{
                    id: 'cycle-hide-completed',
                    icon: hideCompleted ? 'todo_filled' : 'todo_checkmark',
                    description: `${
                      hideCompleted ? 'Show' : 'Hide'
                    } completed ${issueTerm}s and todos`,
                    hotkey: toggleHideKey,
                    handler: async () => {
                      setHideCompleted(!hideCompleted);
                    },
                    group: CommandGroup.Cycle,
                  }}
                />

                <IconButton
                  onClick={() => {
                    modals.openModal(Modals.NewEntity);
                  }}
                  icon="add"
                  buttonStyle={ButtonStyle.BareSubtle}
                />
              </div>
            )}
          </div>
          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeDragStart={drag => {
              const { selected } = getKeyNavState();
              const sourceId = drag.draggableId;
              if (selected && selected.length && !selected.includes(sourceId)) {
                clearSelection();
              }
            }}
            onDragStart={() => {
              const { selected } = getKeyNavState();
              setDragCount(selected?.length ?? 1);
            }}
          >
            <Droppable
              droppableId="list"
              renderClone={(
                provided: DraggableProvided,
                _snapshot: DraggableStateSnapshot,
                rubric: DraggableRubric
              ) => {
                const itemIndex = rubric.source.index;
                const group = groups[itemIndex];
                const { style, ...rest } = provided.draggableProps;
                (style as any)['--number-column-width'] = `${numberColumnWidth}px`;

                return (
                  <div
                    {...rest}
                    {...provided.dragHandleProps}
                    style={style}
                    ref={provided.innerRef}
                    className="relative"
                  >
                    {(dragCount ?? 1) > 1 && (
                      <Count className={styles.dragCount} count={dragCount!} />
                    )}
                    <WorkItemGroup cycleId={cycleId} {...group} key={'clone'} />
                  </div>
                );
              }}
            >
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={styles.workItemContainer}
                >
                  {renderedWorkItems}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
      {keyNavIds.length === 0 && (
        <EmptyPlaceholder
          icon={'cycle_completed'}
          title={`No ${hideCompleted ? 'uncompleted' : ''} work items in cycle`}
        >
          <p className="grayed">
            Add work items to the cycle {hideCompleted ? 'or show completed work items' : ''} to see
            them here
          </p>
          {hideCompleted && (
            <CustomCommand
              command={{
                id: 'cycle-hide-completed',
                icon: hideCompleted ? 'todo_filled' : 'todo_checkmark',
                description: `${hideCompleted ? 'Show' : 'Hide'} completed ${issueTerm}s and todos`,
                hotkey: toggleHideKey,
                handler: async () => {
                  setHideCompleted(!hideCompleted);
                },
                group: CommandGroup.Cycle,
              }}
            />
          )}
          <div className="row mr12 headerGap">
            {hideCompleted && (
              <Button
                icon="todo_checkmark"
                onClick={() => {
                  setHideCompleted(false);
                }}
              >
                Show completed {issueTerm}s
              </Button>
            )}
            <Button
              onClick={() => {
                modals.openModal(Modals.CommandMenu, {
                  view: CommandMenuView.ChangeCycleIssues,
                  context: {
                    cycleId,
                  },
                });
              }}
            >
              Select {issueTerm}
            </Button>
            <Button
              icon="add"
              onClick={() => {
                modals.openModal(Modals.NewEntity);
              }}
            >
              New {issueTerm}
            </Button>
          </div>
        </EmptyPlaceholder>
      )}
    </>
  );
}

function FinishedCycle({ cycleId }: { cycleId: string }) {
  const data = useRecoilValue(completedCycleSelector(cycleId));
  return (
    <VirtualizedListView
      id="cycle-screen"
      className="fullWidth grow"
      sectionIds={['completed', 'incomplete']}
      itemIds={data}
      interactiveHeaders
      sectionHeaderHeight={60}
      itemHeight={41}
      spacerHeight={32}
      renderAccessories={grid => {
        return <ResizeItemsOnStateChange ids={grid} />;
      }}
      renderSectionHeader={(id, collapsed, toggleCollapsed) => {
        const label = id === 'completed' ? 'Completed' : 'Incomplete';
        const count = data[id as 'completed' | 'incomplete'].length;
        return (
          <CycleSectionHeader
            id={id}
            label={label}
            count={count}
            collapsed={collapsed}
            onToggleCollapsed={toggleCollapsed}
          />
        );
      }}
      renderItem={(id, _sectionId, isFirst, isLast, edit) => {
        return (
          <ListItem
            id={id}
            className={cn('listItem', {
              first: isFirst,
              last: isLast,
            })}
            cycleId={cycleId}
            onEdit={edit?.start}
            onEditComplete={edit?.end}
          />
        );
      }}
      renderPlaceholder={id => {
        return <Placeholder statusId={id} list />;
      }}
    />
  );
}

export function WorkItemView({ cycleId }: { cycleId: string }) {
  const cycle = useRecoilValue(cycleSelector(cycleId));
  const numberColumnWidth = useEntityNumberWidths([cycle?.spaceId ?? '']);
  const isIssue = useIsIssue();
  if (!cycle) {
    return null;
  }
  return (
    <KeyNavigationProvider
      multiSelect
      isMultiSelectable={id => !id.includes('-') && isIssue(id)}
      columnIds={['cycle-screen']}
      disableEnsureVisible
    >
      <div
        className={cn('pt20', {
          overflowScrollY: cycle.cycleStatus !== CycleStatus.Stopped,
          fullHeight: cycle.cycleStatus === CycleStatus.Stopped,
        })}
        style={
          {
            '--number-column-width': `${numberColumnWidth}px`,
          } as React.CSSProperties
        }
      >
        {cycle.cycleStatus === CycleStatus.Stopped && <FinishedCycle cycleId={cycleId} />}
        {cycle.cycleStatus !== CycleStatus.Stopped && (
          <UnfinishedCycle cycleId={cycleId} numberColumnWidth={numberColumnWidth} />
        )}
      </div>
    </KeyNavigationProvider>
  );
}
