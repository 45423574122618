import cn from 'classnames';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import menuStyles from '../../components/new/menu/menu.module.scss';
import { useConfiguration } from '../../contexts/configurationContext';
import { useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { useUnmounted } from '../../hooks/useUnmounted';
import Hover from '../hovers';
import { Suggestion, SuggestionState } from '../plugins/suggestions/withSuggestions';
import styles from './suggestionHover.module.scss';

function SuggestionRow({
  suggestion,
  selected,
  onSelected,
}: {
  suggestion: Suggestion;
  selected: boolean;
  onSelected: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // NOTE: without this timeout, we get a race condition that causes the entire
    // screen to scroll on iOS instead of just the popover.
    setTimeout(() => {
      if (!selected || !ref.current) {
        return;
      }
      scrollIntoView(ref.current, {
        block: 'center',
        scrollMode: 'if-needed',
        behavior: 'smooth',
      });
    });
  }, [selected]);

  return (
    <div
      ref={ref}
      className={cn(menuStyles.item, {
        [styles.selected]: selected,
      })}
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
        suggestion.accept();
      }}
      onMouseEnter={onSelected}
    >
      {suggestion.render()}
    </div>
  );
}

export function SuggestionHover({
  suggestions,
  onClearSuggestions,
  children,
}: {
  suggestions: SuggestionState;
  onClearSuggestions: () => void;
  children?: JSX.Element;
}) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [mouseMoved, setMouseMoved] = React.useState(false);
  const unmounted = useUnmounted();
  const smallScreen = useIsSmallScreen();
  const { featureFlags } = useConfiguration();

  React.useEffect(() => {
    let timeout = -1;
    function onMove() {
      setMouseMoved(true);
      if (timeout !== -1) {
        window.clearTimeout(timeout);
      }
      timeout = window.setTimeout(() => setMouseMoved(false), 250);
    }

    document.addEventListener('mousemove', onMove);
    return () => document.removeEventListener('mousemove', onMove);
  }, []);

  React.useEffect(() => {
    if (!unmounted.current) {
      setSelectedIndex(0);
    }
  }, [suggestions, unmounted]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        onClearSuggestions();
        return true;
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        e.stopPropagation();
        setSelectedIndex(current => Math.max(0, current - 1));
        return true;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        e.stopPropagation();
        setSelectedIndex(current =>
          Math.min((suggestions.suggestions?.length ?? 0) - 1, current + 1)
        );
        return true;
      }

      if ((e.key === 'Tab' || e.key === 'Enter') && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();

        const expand = suggestions.suggestions?.[selectedIndex]?.expand;
        if (featureFlags.FEATURE_TOGGLE_TODO_SUGGESTIONS && e.key === 'Tab' && expand) {
          expand();
        } else {
          suggestions.suggestions?.[selectedIndex].accept();
        }
        return true;
      }

      return false;
    },
    [selectedIndex, suggestions, onClearSuggestions]
  );

  const renderedSuggestions = (suggestions.suggestions ?? []).map((suggestion, index) => (
    <SuggestionRow
      key={suggestion.id}
      suggestion={suggestion}
      selected={index === selectedIndex}
      onSelected={() => {
        if (!mouseMoved) {
          return;
        }
        setSelectedIndex(index);
      }}
    />
  ));

  return (
    <Hover
      content={
        <div
          className={cn(
            menuStyles.content,
            styles.suggestionHover,
            suggestions.className ?? 'menuMedium'
          )}
        >
          {suggestions.header && <div className={menuStyles.item}>{suggestions.header()}</div>}
          {renderedSuggestions}
        </div>
      }
      open={!!suggestions.suggestions?.length}
      hoverId="suggestions"
      contentOptions={
        !smallScreen
          ? {
              side: 'bottom',
              align: 'start',
            }
          : undefined
      }
      onKeyDown={onKeyDown}
      onOpenChange={open => {
        if (!open) {
          onClearSuggestions();
        }
      }}
      fixed
    >
      {children}
    </Hover>
  );
}
