import { LocationState } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { writeToClipboard } from '../components/clipboardText';
import { SidebarVisibility, sidebarVisibility } from '../components/new/sidebar';
import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { organizationPath } from '../syncEngine/selectors/organizations';
import {
  hotkeyHelpMenuKey,
  myWorkKey,
  myWorkKeyAlternate,
  searchKey,
  showDependencyGraphKey,
  showDocumentsKey,
  showFeedbackKey,
  showRecentKey,
  showReleasesKey,
  showRoadmapKey,
  showStarredKey,
  toggleSideBarKey,
  updatesScreenKey,
} from '../utils/config';
import { CommandDefinition, CommandGroup } from './state';

export function useNavigationCommandGroup(): CommandDefinition[] {
  const modals = useModals();
  const history = useHistory();
  const organization = useOrganization();
  const location = useLocation<LocationState>();

  const setVisibility = useSetRecoilState(sidebarVisibility);

  const commands: CommandDefinition[] = [
    {
      id: 'search',
      description: 'Search',
      icon: 'search',
      hotkey: searchKey,
      priority: 1,
      handler: () => {
        history.push(organizationPath(organization, 'search'), {
          backUrl: location.pathname,
          backSearch: location.search,
          backBreadcrumbs: location.state?.breadcrumbs,
        });
      },
    },
    {
      id: 'my-work',
      description: 'Go to my work',
      icon: 'my_work',
      hotkey: myWorkKey,
      handler: () => {
        history.push(organizationPath(organization, 'my'));
      },
    },
    {
      id: 'my-work-alternate',
      description: 'Go to my work (alternative hotkey)',
      icon: 'my_work',
      hotkey: myWorkKeyAlternate,
      handler: () => {
        history.push(organizationPath(organization, 'my'));
      },
    },
    {
      id: 'toggle-sidebar',
      description: 'Toggle sidebar',
      hotkey: toggleSideBarKey,
      aliases: ['open', 'close'],
      handler: () => {
        setVisibility(old =>
          old === SidebarVisibility.Open ? SidebarVisibility.AutoHide : SidebarVisibility.Open
        );
      },
    },
    {
      id: 'dependency-graph',
      description: 'Go to dependency graph',
      icon: 'dependency_enables',
      hotkey: showDependencyGraphKey,
      aliases: ['dependencies'],
      handler: () => {
        history.push(organizationPath(organization, 'dependencies'));
      },
    },
    {
      id: 'updates',
      description: 'Go to updates',
      icon: 'updates',
      hotkey: updatesScreenKey,
      handler: () => {
        history.push(organizationPath(organization, 'updates'));
      },
    },
    {
      id: 'customer-feedback',
      description: 'Go to feedback',
      icon: 'feedback',
      hotkey: showFeedbackKey,
      handler: () => {
        history.push(organizationPath(organization, 'feedback'));
      },
      aliases: ['insights'],
    },
    ...(organization.newRoadmapsEnabled
      ? [
          {
            id: 'roadmaps',
            description: 'Go to roadmaps',
            icon: 'roadmap',
            hotkey: showRoadmapKey,
            handler: () => {
              history.push(organizationPath(organization, 'roadmaps'));
            },
          },
        ]
      : []),
    ...(organization.documentsEnabled
      ? [
          {
            id: 'view-documents',
            description: 'Go to documents',
            group: CommandGroup.Navigation,
            icon: 'document',
            hotkey: showDocumentsKey,
            handler: () =>
              history.push({
                pathname: organizationPath(organization, 'documents'),
                state: {
                  backUrl: location.pathname,
                  backSearch: history.location.search,
                },
              }),
          },
        ]
      : []),
      ...(organization.releasesEnabled
        ? [
            {
              id: 'view-releases',
              description: 'Go to releases',
              group: CommandGroup.Navigation,
              icon: 'document',
              hotkey: showReleasesKey,
              handler: () =>
                history.push({
                  pathname: organizationPath(organization, 'releases'),
                  state: {
                    backUrl: location.pathname,
                    backSearch: history.location.search,
                  },
                }),
            },
          ]
        : []),
    {
      id: 'recent',
      description: 'See recent items',
      hotkey: showRecentKey,
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.Recent,
          context: {},
        });
      },
    },
    {
      id: 'starred',
      description: 'Show Starred',
      hotkey: showStarredKey,
      icon: 'starred',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.Starred,
          context: {},
        });
      },
    },
    {
      id: 'view-archive',
      description: 'View archive',
      group: CommandGroup.Navigation,
      icon: 'archive',
      handler: () =>
        history.push({
          pathname: organizationPath(organization, 'archive'),
          state: {
            backUrl: location.pathname,
            backSearch: history.location.search,
          },
        }),
    },
    {
      id: 'goto-space',
      description: 'Go to space',
      icon: 'workspace',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.GotoSpace,
          context: {},
        });
      },
    },
    {
      id: 'copy-link-to-screen',
      description: 'Copy link to current screen',
      handler: () => {
        writeToClipboard(window.location.href, 'link');
      },
      priority: 12,
      group: CommandGroup.Navigation,
    },

    {
      id: 'hotkey-modal',
      description: 'Keyboard shortcuts',
      hotkey: hotkeyHelpMenuKey,
      aliases: ['Hotkey', 'vim', 'superhuman'],
      handler: () => {
        modals.openModal(Modals.HotkeyMenu);
      },
    },
  ];

  return commands.map(c => ({ ...c, group: CommandGroup.Navigation }));
}
