/**
* THIS FILE WAS AUTOMATICALLY GENERATED. NEVER EVER (EVER) EDIT IT BY HAND.
* MODIFY sync.json AND RE-RUN yarn generate-sync-engine
**/

/* eslint-disable quotes */
export const integrationUserIntegrationusers = {
    name: 'integrationUsers',
    type: 'IntegrationUser',
    property: 'userIds',
};
export const issueStatusWatchers = {
    name: 'watchers',
    type: 'IssueStatus',
    property: 'watcherIds',
};
export const issueAssignees = {
    name: 'assignees',
    type: 'Issue',
    property: 'assigneeIds',
};
export const issueWatchers = {
    name: 'watchers',
    type: 'Issue',
    property: 'watcherIds',
};
export const issueLabels = {
    name: 'labels',
    type: 'Issue',
    property: 'labelIds',
};
export const issueCodereviewrequests = {
    name: 'codeReviewRequests',
    type: 'Issue',
    property: 'codeReviewRequestIds',
};
export const cycleActivities = {
    name: 'activities',
    type: 'Cycle',
    property: 'activityIds',
};
export const cycleComments = {
    name: 'comments',
    type: 'Cycle',
    property: 'commentIds',
};
export const cycleGoalMembers = {
    name: 'members',
    type: 'CycleGoal',
    property: 'memberIds',
};
export const themeAssignees = {
    name: 'assignees',
    type: 'Theme',
    property: 'assigneeIds',
};
export const themeWatchers = {
    name: 'watchers',
    type: 'Theme',
    property: 'watcherIds',
};
export const themeLabels = {
    name: 'labels',
    type: 'Theme',
    property: 'labelIds',
};
export const themeIssues = {
    name: 'issues',
    type: 'Theme',
    property: 'issueIds',
};
export const feedbackOwners = {
    name: 'owners',
    type: 'Feedback',
    property: 'ownerIds',
};
export const feedbackTags = {
    name: 'tags',
    type: 'Feedback',
    property: 'tagIds',
};
export const feedbackWatchers = {
    name: 'watchers',
    type: 'Feedback',
    property: 'watcherIds',
};
export const insightEntities = {
    name: 'entities',
    type: 'Insight',
    property: 'entityIds',
};
export const todoMembers = {
    name: 'members',
    type: 'Todo',
    property: 'memberIds',
};
export const todoLabels = {
    name: 'labels',
    type: 'Todo',
    property: 'labelIds',
};
export const todoCodereviewrequests = {
    name: 'codeReviewRequests',
    type: 'Todo',
    property: 'codeReviewRequestIds',
};
export const docWatchers = {
    name: 'watchers',
    type: 'Doc',
    property: 'watcherIds',
};
export const initiativeMembers = {
    name: 'members',
    type: 'Initiative',
    property: 'memberIds',
};
export const initiativeIssues = {
    name: 'issues',
    type: 'Initiative',
    property: 'issueIds',
};
export const initiativeLabels = {
    name: 'labels',
    type: 'Initiative',
    property: 'labelIds',
};
export const initiativeWatchers = {
    name: 'watchers',
    type: 'Initiative',
    property: 'watcherIds',
};
export const releaseMembers = {
    name: 'members',
    type: 'Release',
    property: 'memberIds',
};
export const releaseWatchers = {
    name: 'watchers',
    type: 'Release',
    property: 'watcherIds',
};
export const releaseIssues = {
    name: 'issues',
    type: 'Release',
    property: 'issueIds',
};
export const releaseSpaces = {
    name: 'spaces',
    type: 'Release',
    property: 'spaceIds',
};
export const allCollections = [
    integrationUserIntegrationusers,
    issueStatusWatchers,
    issueAssignees,
    issueWatchers,
    issueLabels,
    issueCodereviewrequests,
    cycleActivities,
    cycleComments,
    cycleGoalMembers,
    themeAssignees,
    themeWatchers,
    themeLabels,
    themeIssues,
    feedbackOwners,
    feedbackTags,
    feedbackWatchers,
    insightEntities,
    todoMembers,
    todoLabels,
    todoCodereviewrequests,
    docWatchers,
    initiativeMembers,
    initiativeIssues,
    initiativeLabels,
    initiativeWatchers,
    releaseMembers,
    releaseWatchers,
    releaseIssues,
    releaseSpaces
];
