import { kebabCase, trimEnd } from 'lodash';
import { useRecoilValue } from 'recoil';
import { stringifyDocument } from '../../../../shared/slate/utils';
import { useCurrentUser } from '../../../contexts/userContext';
import { entityKeySelector } from '../../../syncEngine/selectors/entities';
import { todoSelector } from '../../../syncEngine/selectors/todos';

export function useGitTodoBranchName(todoId?: string) {
  const todo = useRecoilValue(todoSelector(todoId));

  const entityKey = useRecoilValue(entityKeySelector(todo?.entityId));
  const user = useCurrentUser();

  if (!entityKey || !user || !todo) {
    return null;
  }

  const title = kebabCase(stringifyDocument(todo.todoContents));
  const lastHyphen = title.indexOf('-', 50);

  return trimEnd(
    `${user.username}/${entityKey}${todo.key}-${title}`.substring(
      0,
      lastHyphen > 0 ? lastHyphen : undefined
    ),
    '-'
  ).toLowerCase();
}
