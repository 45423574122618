import { flatten, uniq } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import {
  activeCyclesSelector,
  cycleSelector,
  upcomingCyclesSelector,
} from '../../../syncEngine/selectors/cycles';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { CycleStatusLabel } from '../../cycle';
import { FilteredListViewHandle } from '../filteredListView';
import { Picker, PickerState } from '../picker';

function CycleItem({ cycleId }: { cycleId: string }) {
  const cycle = useRecoilValue(cycleSelector(cycleId));
  const space = useRecoilValue(spaceSelector(cycle?.spaceId));
  const maybeSpace = useMaybeSpace();
  return (
    <div className="row metadataGap">
      <span className="ellipsis">
        {maybeSpace ? '' : `${space?.name} - `}
        {cycle?.title}
      </span>
      <CycleStatusLabel cycleId={cycleId} />
    </div>
  );
}

export function CyclePicker({
  state,
  filterPlaceholder,
  filterClassName,
  onDone,
  onCycleAdded,
  onCycleRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  onDone: () => void;
  onCycleAdded: (issueIds: string[], cycleId: string) => void;
  onCycleRemoved: (issueIds: string[], cycleId: string) => void;
}) {
  const organization = useOrganization();
  const maybeSpace = useMaybeSpace();

  const activeCycles = useRecoilValue(
    activeCyclesSelector({ organizationId: organization.id, spaceId: maybeSpace?.id })
  );
  const upcomingCycles = useRecoilValue(
    upcomingCyclesSelector({ organizationId: organization.id, spaceId: maybeSpace?.id })
  );

  const selectedItems = uniq(flatten(Object.values(state))).filter(
    id =>
      id !== 'none' &&
      id !== 'current' &&
      id !== 'upcoming' &&
      activeCycles[0]?.cycle.id !== id &&
      upcomingCycles[0]?.cycle.id !== id
  );

  const items = [
    {
      id: 'none',
      contents: 'None',
    },
    ...selectedItems.map(id => ({
      id,
      contents: <CycleItem cycleId={id} />,
    })),
    ...activeCycles.map(({ cycle }) => ({
      id: maybeSpace ? 'current' : cycle.id,
      contents: <CycleItem cycleId={cycle.id} />,
    })),
    ...upcomingCycles.map(({ cycle }) => ({
      id: maybeSpace ? 'upcoming' : cycle.id,
      contents: <CycleItem cycleId={cycle.id} />,
    })),
  ];

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterLabel="Pick cycle"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Pick cycles'}
      placeholder={'No cycles found'}
      ref={filterRef}
      multi
      state={state}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onCycleAdded}
      onRemove={onCycleRemoved}
      onDone={onDone}
    />
  );
}
