import * as React from 'react';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { ModalProvider, Modals, useModals } from '../../contexts/modalContext';
import { PersistentSettingsProvider } from '../../contexts/usePersistentSettings';
import { NewOrganizationModal } from '../../modals/newOrganizationModal';
import { trackerPageLoad } from '../../tracker';
import { ErrorScreen } from './errorScreen';
import styles from './errorScreen.module.scss';

function NoOrganizationsScreenContents() {
  const modals = useModals();

  return (
    <ErrorScreen showLogo>
      <div className={styles.errorHeading}>No organizations</div>
      <div>You are currently not a member of any organizations</div>
      <Button
        className="mt8"
        buttonStyle={ButtonStyle.PrimaryBlack}
        size={ButtonSize.Large}
        autoFocus
        onClick={() => {
          modals.openModal(Modals.CreateOrganization);
        }}
      >
        Create organization
      </Button>
    </ErrorScreen>
  );
}
export function NoOrganizationsScreen() {
  React.useEffect(() => {
    trackerPageLoad('No organizations');
  }, []);

  return (
    <ModalProvider>
      <PersistentSettingsProvider>
        <NewOrganizationModal />
        <NoOrganizationsScreenContents />
      </PersistentSettingsProvider>
    </ModalProvider>
  );
}
