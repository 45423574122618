import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu';
import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Feedback } from '../../../sync/__generated/models';
import { Icon } from '../../components/new/icon';
import { DropdownMenu, DropdownMenuContent } from '../../components/new/menu/dropdownMenu';
import Pill, { PillStyle } from '../../components/new/metadata/pill';
import { MetadataSize } from '../../components/new/metadata/size';
import { TagPicker, feedbackToTagPickerState } from '../../components/new/pickers/tagPicker';
import { useAddTagToFeedback, useRemoveTagFromFeedbak } from '../../syncEngine/actions/feedback';
import { tagSelector, tagsSelector } from '../../syncEngine/selectors/tags';

export function Tag({
  color,
  name,
  size,
  forceDarkMode,
  className,
}: {
  color: string;
  name: string;
  size: MetadataSize;
  forceDarkMode?: boolean;
  className?: string;
}) {
  return (
    <Pill
      pillStyle={PillStyle.Primary}
      color={color}
      size={size}
      textOnly
      forceDarkMode={forceDarkMode}
      className={className}
    >
      <span>{name}</span>
    </Pill>
  );
}

export function FeedbackTag({
  tagId,
  feedback,
  size = MetadataSize.Medium,
  className,
}: {
  tagId: string;
  feedback: Feedback;
  size?: MetadataSize;
  className?: string;
}) {
  const addTagsToFeedback = useAddTagToFeedback();
  const removeTagsFromFeedback = useRemoveTagFromFeedbak();

  const tag = useRecoilValue(tagSelector(tagId));
  const [menuOpen, _setMenuOpen] = React.useState(false);

  const onDone = React.useCallback(() => {
    if (removeSelfRef.current && feedback && tag) {
      removeTagsFromFeedback([feedback.id], [tag.id]);
    }
  }, [feedback, tag, removeTagsFromFeedback]);

  const setMenuOpen = React.useCallback(
    (open: boolean) => {
      _setMenuOpen(open);
      if (!open) {
        onDone();
      }
    },
    [onDone]
  );

  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  const [removeSelf, setRemoveSelf] = React.useState(false);
  const removeSelfRef = React.useRef(false);

  if (!tag || !feedback) {
    return null;
  }

  const state = feedbackToTagPickerState([feedback]);
  if (removeSelf) {
    state[feedback.id] = state[feedback.id].filter(t => t !== tagId);
  }

  const content = (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={cn({ invisible: removeSelf }, className)}
    >
      <Tag color={tag!.color} name={tag!.name} size={size} className={className} />
    </div>
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>{content}</DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuMedium"
      >
        <TagPicker
          state={state}
          onTagAdded={(feedbackIds, toAdd) => {
            if (tagId === toAdd) {
              removeSelfRef.current = false;
              setRemoveSelf(false);
            } else {
              addTagsToFeedback(feedbackIds, [toAdd]);
            }
          }}
          onTagRemoved={(feedbackIds, toRemove) => {
            if (tagId === toRemove) {
              removeSelfRef.current = true;
              setRemoveSelf(true);
            } else {
              removeTagsFromFeedback(feedbackIds, [toRemove]);
            }
          }}
          onDone={() => {
            onDone();
            closeMenu();
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function FeedbackTags({
  feedback,
  size,
  className,
}: {
  feedback: Feedback;

  size?: MetadataSize;
  className?: string;
}) {
  const tagIds = feedback.tagIds;
  if (!tagIds.length) {
    return null;
  }

  return (
    <>
      {tagIds.map(tagId => (
        <FeedbackTag
          key={tagId}
          feedback={feedback}
          tagId={tagId}
          size={size}
          className={className}
        />
      ))}
    </>
  );
}

export function FeedbackTagsWithPlaceholder({
  feedback,
  size = MetadataSize.Medium,
}: {
  feedback: Feedback;
  size?: MetadataSize;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const tags = useRecoilValue(tagsSelector(feedback.tagIds));
  const renderedTags = tags.map(tag => (
    <Tag key={tag.id} color={tag.color} name={tag.name} size={size} />
  ));

  const addTagsToFeedback = useAddTagToFeedback();
  const removeTagsFromFeedback = useRemoveTagFromFeedbak();

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
      >
        <TagPicker
          state={feedbackToTagPickerState([feedback])}
          onTagAdded={(feedbackIds, userId) => {
            addTagsToFeedback(feedbackIds, [userId]);
          }}
          onTagRemoved={(feedbackIds, userId) => {
            removeTagsFromFeedback(feedbackIds, [userId]);
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      <DropdownMenuTrigger asChild>
        <div className="row metadataGapL">
          {tags.length === 0 && (
            <Pill pillStyle={PillStyle.Empty} size={MetadataSize.Medium}>
              <Icon icon="add" /> Add tag
            </Pill>
          )}
          {renderedTags}
        </div>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
}
