import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { KitemakerElement } from '../../shared/slate/kitemakerNode';
import {
  AIPlaceholderElement,
  BulletedElement,
  ChatElement,
  ChatMessageElement,
  CodeElement,
  CommentElement,
  CustomEmojiElement,
  Elements,
  EntityElement,
  ExternalIssueLinkElement,
  FigmaElement,
  FileElement,
  GiphyElement,
  GithubElement,
  GroupElement,
  ImageElement,
  InlineExternalIssueLinkElement,
  InsightElement,
  IssueElement,
  LabelElement,
  LineElement,
  LinkElement,
  LoomElement,
  MathElement,
  MathExpressionElement,
  NumberedElement,
  ParagraphElement,
  SmartTodoElement,
  TodoElement,
  TodoMentionElement,
  UserElement,
  VideoElement,
} from '../../shared/slate/types';
import { AIPlaceholder } from './elements/aiPlaceholder';
import { BlockQuote } from './elements/blockQuote';
import { Bulleted } from './elements/bulleted';
import { Chat } from './elements/chat';
import { ChatMessage } from './elements/chatMessage';
import { Code } from './elements/code';
import { Comment } from './elements/comment';
import { CustomEmoji } from './elements/customEmoji';
import { DummyNode } from './elements/dummyNode';
import { EntityMention } from './elements/entityMention';
import { ExternalIssueLink } from './elements/externalIssueLink';
import { Figma } from './elements/figma';
import { File } from './elements/file';
import { Giphy } from './elements/giphy';
import { Github } from './elements/github';
import { GroupMention } from './elements/groupMention';
import { Headline1 } from './elements/headline1';
import { Headline2 } from './elements/headline2';
import { Headline3 } from './elements/headline3';
import { Image } from './elements/image';
import { InlineExternalIssueLink } from './elements/inlineExternalIssueLink';
import { Insight } from './elements/insight';
import { LabelMention } from './elements/labelMention';
import { Line } from './elements/line';
import { Link } from './elements/link';
import { Loom } from './elements/loom';
import { Math } from './elements/math';
import { MathExpression } from './elements/mathExpression';
import { Numbered } from './elements/numbered';
import { Paragraph } from './elements/paragraph';
import { SmartTodo } from './elements/smartTodo';
import { Todo } from './elements/todo';
import { TodoMention } from './elements/todoMention';
import { UserMention } from './elements/userMention';
import { Video } from './elements/video';

export function RenderElement(props: RenderElementProps) {
  const { element, ...rest } = props;

  switch (element.type) {
    // blocks
    case Elements.Paragraph:
      return <Paragraph {...props} element={element as ParagraphElement} />;
    case Elements.Headline1:
      return <Headline1 {...props} />;
    case Elements.Headline2:
      return <Headline2 {...props} />;
    case Elements.Headline3:
      return <Headline3 {...props} />;
    case Elements.BlockQuote:
      return <BlockQuote {...props} />;
    case Elements.Bulleted:
      return <Bulleted {...rest} element={element as BulletedElement} />;
    case Elements.Numbered:
      return <Numbered {...rest} element={element as NumberedElement} />;
    case Elements.Todo:
      return <Todo {...rest} element={element as TodoElement} />;
    case Elements.SmartTodo:
      return <SmartTodo {...rest} element={element as SmartTodoElement} />;
    case Elements.Code:
      return <Code {...rest} element={element as CodeElement} />;
    case Elements.Line:
      return <Line {...props} element={element as LineElement} />;
    case Elements.Image:
      return <Image {...rest} element={element as ImageElement} />;
    case Elements.MathExpression:
      return <MathExpression {...rest} element={element as MathExpressionElement} />;
    case Elements.Video:
      return <Video {...rest} element={element as VideoElement} />;
    case Elements.File:
      return <File {...rest} element={element as FileElement} />;
    case Elements.Loom:
      return <Loom {...rest} element={element as LoomElement} />;
    case Elements.Figma:
      return <Figma {...rest} element={element as FigmaElement} />;
    case Elements.Github:
      return <Github {...rest} element={element as GithubElement} />;
    case Elements.Giphy:
      return <Giphy {...rest} element={element as GiphyElement} />;
    case Elements.Chat:
      return <Chat {...rest} element={element as ChatElement} />;
    case Elements.ChatMessage:
      return <ChatMessage {...rest} element={element as ChatMessageElement} />;
    case Elements.AIPlaceholder:
      return <AIPlaceholder {...rest} element={element as AIPlaceholderElement} />;
    case Elements.ExternalIssueLink:
      return <ExternalIssueLink {...rest} element={element as ExternalIssueLinkElement} />;

    // inlines
    case Elements.Link:
      return <Link {...rest} element={element as LinkElement} />;
    case Elements.User:
      return <UserMention {...rest} element={element as UserElement} />;
    case Elements.Group:
      return <GroupMention {...rest} element={element as GroupElement} />;
    case Elements.Entity:
      return <EntityMention {...rest} element={element as EntityElement} />;
    case Elements.Issue:
      // FIXME: legacy - remove once we write a DB migration to convert all Issue mentions to Entity mentions
      return <EntityMention {...rest} element={element as IssueElement} />;
    case Elements.Label:
      return <LabelMention {...rest} element={element as LabelElement} />;
    case Elements.Math:
      return <Math {...rest} element={element as MathElement} />;
    case Elements.Insight:
      return <Insight {...rest} element={element as InsightElement} />;
    case Elements.Comment:
      return <Comment {...rest} element={element as CommentElement} />;
    case Elements.CustomEmoji:
      return <CustomEmoji {...rest} element={element as CustomEmojiElement} />;
    case Elements.TodoMention:
      return <TodoMention {...rest} element={element as TodoMentionElement} />;
    case Elements.InlineExternalIssueLink:
      return (
        <InlineExternalIssueLink {...rest} element={element as InlineExternalIssueLinkElement} />
      );

    // slate gets sad if an element is altogether omitted, so just return a dump div/span if we don't know what
    // else to do
    default:
      return (
        <DummyNode
          as={KitemakerElement.isInline(element) ? 'span' : 'div'}
          {...rest}
          element={element}
        />
      );
  }
}
