import React from 'react';
import { useRecoilValue } from 'recoil';
import { SpacePicker } from '../../../components/new/pickers/spacePicker';
import {
  useAddSpacesToInitiatives,
  useRemoveSpacesFromInitiatives,
} from '../../../syncEngine/actions/intiatives';
import { spacesIdsForInitiativesSelector } from '../../../syncEngine/selectors/intiatives';
import styles from '../commandMenu.module.scss';

export function ChangeSpacesContents({
  onClose,
  initiativeIds,
}: {
  onClose: () => void;
  initiativeIds: string[];
}) {
  const spacePickerState = useRecoilValue(spacesIdsForInitiativesSelector(initiativeIds));
  const addSpacesToInitiatives = useAddSpacesToInitiatives();
  const removeSpacesFromInitiatives = useRemoveSpacesFromInitiatives();

  return (
    <div className="rowStretch fullWidth fullHeight">
      <SpacePicker
        multi
        filterPlaceholder="Change spaces"
        filterClassName={styles.inputDiv}
        state={spacePickerState}
        onSpaceAdded={(ids, spaceId) => {
          addSpacesToInitiatives(ids, [spaceId]);
        }}
        onSpaceRemoved={(ids, spaceId) => {
          removeSpacesFromInitiatives(ids, [spaceId]);
        }}
        onDone={onClose}
      />
    </div>
  );
}
