import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  MemberPicker,
  entitiesToMemberPickerState,
} from '../../../components/new/pickers/memberPicker';
import {
  useAddAssigneesToEntities,
  useRemoveAssigneesFromEntities,
} from '../../../syncEngine/actions/entities';
import { useUpdateTodoOutsideOfEditor } from '../../../syncEngine/actions/todos';
import {
  AssignableEntity,
  entitiesSelector,
  isAssignableEntity,
} from '../../../syncEngine/selectors/entities';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { todoSelector } from '../../../syncEngine/selectors/todos';
import styles from '../commandMenu.module.scss';

export function ChangeMembersContents({
  onClose,
  entityIds,
  spaceId,
}: {
  onClose: () => void;
  entityIds: string[];
  spaceId?: string | null;
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const addAssignees = useAddAssigneesToEntities();
  const removeAssignees = useRemoveAssigneesFromEntities();

  const entities = useRecoilValue(entitiesSelector(entityIds)).filter(e =>
    isAssignableEntity(e)
  ) as AssignableEntity[];

  return (
    <div className="rowStretch fullWidth fullHeight">
      <MemberPicker
        filterClassName={styles.inputDiv}
        space={space}
        state={entitiesToMemberPickerState(entities)}
        onMemberAdded={(issueIds, memberId) => {
          addAssignees(issueIds, [memberId]);
        }}
        onMemberRemoved={(issueIds, memberId) => {
          removeAssignees(issueIds, [memberId]);
        }}
        onDone={onClose}
      />
    </div>
  );
}

export function ChangeTodoMembersContents({
  onClose,
  todoId,
  spaceId,
}: {
  onClose: () => void;
  todoId: string;
  spaceId?: string | null;
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const todo = useRecoilValue(todoSelector(todoId));
  const updateTodo = useUpdateTodoOutsideOfEditor(todo?.entityId ?? '');

  if (!todo) {
    return null;
  }

  return (
    <div className="rowStretch fullWidth fullHeight">
      <MemberPicker
        filterClassName={styles.inputDiv}
        space={space}
        state={{ [todo.id]: todo.memberIds }}
        onMemberAdded={(_, memberId) => {
          updateTodo(todo.id, { membersToAdd: [memberId] });
        }}
        onMemberRemoved={(_, memberId) => {
          updateTodo(todo.id, { membersToRemove: [memberId] });
        }}
        onDone={onClose}
      />
    </div>
  );
}
