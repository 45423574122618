import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { responsiveDesign, ResponsiveDesignSize } from '../hooks/useResponsiveDesign';

export function HideIfSmallerThan({
  size,
  children,
}: {
  size: ResponsiveDesignSize;
  children: React.ReactNode;
}) {
  const screenSize = useRecoilValue(responsiveDesign);
  if (size >= screenSize) {
    return null;
  }
  return <>{children}</>;
}
