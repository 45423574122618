import React from 'react';
import { useRecoilValue } from 'recoil';
import { InitiativePicker } from '../../../components/new/pickers/initiativePicker';
import {
  useAddInitiativesToRoadmaps,
  useRemoveInitiativesFromRoadmaps,
  useUpdateInitiatives,
} from '../../../syncEngine/actions/intiatives';
import { roadmapInitiativesForRoadmapSelector } from '../../../syncEngine/selectors/intiatives';
import styles from '../commandMenu.module.scss';

export function AddToTimelineContents({
  onClose,
  startDate,
  endDate,
  roadmapId,
  sort,
}: {
  onClose: () => void;
  startDate: number;
  endDate: number;
  roadmapId: string;
  sort: string;
}) {
  const roadmapInitiatives = useRecoilValue(roadmapInitiativesForRoadmapSelector(roadmapId));
  const initiativeIdsInRoadmap = roadmapInitiatives.map(i => i.initiativeId);
  const excluseInitiativeIds = React.useMemo(() => initiativeIdsInRoadmap, []);

  const updateInitiatives = useUpdateInitiatives();
  const addInitiativesToRoadmaps = useAddInitiativesToRoadmaps();
  const removeInitiativesFromRoadmaps = useRemoveInitiativesFromRoadmaps();

  return (
    <div className="rowStretch fullWidth fullHeight">
      <InitiativePicker
        excluseInitiativeIds={excluseInitiativeIds}
        filterClassName={styles.inputDiv}
        state={{ DUMMY: initiativeIdsInRoadmap }}
        onInitiativeAdded={(_, initiativeId) => {
          updateInitiatives([initiativeId], { startDate, dueDate: endDate });
          addInitiativesToRoadmaps([initiativeId], [roadmapId], sort);
        }}
        onInitiativeRemoved={(_, initiativeId) => {
          removeInitiativesFromRoadmaps([initiativeId], [roadmapId]);
        }}
        onDone={onClose}
        showMetadata
      />
    </div>
  );
}
