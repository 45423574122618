import * as React from 'react';
import Modal from '../components/modal';
import { useModals, Modals, FigmaArgs } from '../contexts/modalContext';
import styles from './figmaEmbedModal.module.scss';

function FigmaEmbedModalContents({ url }: { url?: string }) {
  return (
    <div className={styles.figmaModalContents}>
      <iframe
        src={`https://www.figma.com/embed?embed_host=kitemaker&url=${url}`}
        allowFullScreen={true}
      />
    </div>
  );
}

export default function FigmaEmbedModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as FigmaArgs) : null;

  return (
    <Modal modalId={Modals.FigmaEmbed} hideHeader={true} onClose={args?.onClose}>
      <FigmaEmbedModalContents url={args?.url} />
    </Modal>
  );
}
