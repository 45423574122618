import * as React from 'react';
import Hotkey from '../components/hotkey';
import { CustomCommand } from '../components/new/customCommand';
import { isElectron, zoomIn } from '../electronIntegration';
import DiagnosticsModal from '../modals/diagnosticsModal';
import { NewOrganizationModal } from '../modals/newOrganizationModal';
import TextToCopyModal from '../modals/textToCopyModal';
import shootConfetti from '../utils/confetti';
import { mainComboKey } from '../utils/config';

export function AppCommandsAndModals() {
  return (
    <>
      <TextToCopyModal />
      <DiagnosticsModal />
      <NewOrganizationModal />
      <Hotkey
        command={{
          id: 'swallow-select-all',
          hotkey: `${mainComboKey}+a`,
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        }}
      />

      {isElectron() && (
        <>
          <Hotkey
            command={{
              id: 'zoom-in-plus',
              hotkey: `${mainComboKey}+plus`,
              handler: e => {
                e?.preventDefault();
                e?.stopPropagation();
                zoomIn();
              },
            }}
          />
          <Hotkey
            command={{
              id: 'zoom-in-equals',
              hotkey: `${mainComboKey}+=`,
              handler: e => {
                e?.preventDefault();
                e?.stopPropagation();
                zoomIn();
              },
            }}
          />
        </>
      )}
      <CustomCommand
        command={{
          id: 'confetti',
          description: 'Confetti 🎉',
          handler: () => {
            shootConfetti();
          },
        }}
      />
    </>
  );
}
