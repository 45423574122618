import * as React from 'react';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import { Logo, LogoSize } from '../../components/new/logo';
import { AuxiliaryScreen } from '../../components/screen';
import styles from './loadingScreen.module.scss';

export default function LoadingScreen() {
  return (
    <AuxiliaryScreen className={styles.loadingScreen}>
      <div className={styles.content}>
        <Logo size={LogoSize.LogoSize90} />
        <div className={styles.spinner}>
          <LoadingSpinner />
        </div>
      </div>
    </AuxiliaryScreen>
  );
}
