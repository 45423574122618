import * as React from 'react';
import { useHistory } from 'react-router';
import { Range, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { LinkElement } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import { writeToClipboard } from '../../components/clipboardText';
import { ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
} from '../../components/new/keyNavigation';
import { TextInput } from '../../components/new/textInput';
import { useConfiguration } from '../../contexts/configurationContext';
import { openExternalUrl, sanitizeUrl } from '../../utils/urls';
import Hover, { hoverContext } from '../hovers';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './link.module.scss';

export function StaticLink({
  attributes,
  children,
  element,
}: OptionalAttributesRenderElementProps & { element: LinkElement }) {
  const text = KitemakerNode.safeString(element);
  const url = element.url ?? text;
  const config = useConfiguration();
  const internalUrl = url.startsWith(config.host) ? new URL(url) : null;
  const history = useHistory();

  return (
    <a
      className="link"
      href={url}
      onClick={e => {
        e.preventDefault();
        if (internalUrl) {
          e.preventDefault();
          history.push({
            pathname: internalUrl.pathname,
            search: internalUrl.search,
            state: {
              backUrl: history.location.pathname,
              backSearch: history.location.search,
            },
          });
          return;
        }
        openExternalUrl(url);
      }}
      {...attributes}
    >
      {children}
    </a>
  );
}

function HoverContents({
  initialUrl,
  onHide,
  onBlur,
  onChangeUrl,
  hideTimeoutRef,
  startHidingHover,
}: {
  initialUrl: string;
  onHide: () => void;
  onBlur: () => void;
  onChangeUrl: (url: string | null) => void;
  hideTimeoutRef: React.MutableRefObject<number>;
  startHidingHover: () => void;
}) {
  const [url, setUrl] = React.useState(sanitizeUrl(initialUrl));
  const inputRef = React.useRef(null);
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  return (
    <div
      onMouseEnter={() => {
        if (hideTimeoutRef.current !== -1) {
          window.clearTimeout(hideTimeoutRef.current);
          hideTimeoutRef.current === -1;
        }
      }}
      onMouseLeave={() => {
        if (inputRef.current !== document.activeElement) {
          startHidingHover();
        }
      }}
      className={styles.linkHover}
    >
      <TextInput
        ref={inputRef}
        className="grow mr4"
        placeholder="Enter a URL"
        value={url}
        onChange={e => setUrl(e.currentTarget.value)}
        onFocus={() => {
          disableKeyNav('link-editor');
        }}
        onBlur={() => {
          enableKeyNav('link-editor');
          onBlur();
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            if (!url) {
              onChangeUrl(null);
              return;
            }

            onChangeUrl(sanitizeUrl(url));
          }

          if (e.key === 'Escape') {
            onHide();
          }
        }}
      />
      <IconButton
        icon="copy"
        size={ButtonSize.Medium}
        buttonStyle={ButtonStyle.BareSubtle}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          writeToClipboard(url, 'Link');
        }}
      />
      <IconButton
        icon="delete"
        size={ButtonSize.Medium}
        buttonStyle={ButtonStyle.BareSubtle}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onChangeUrl(null);
        }}
      />
    </div>
  );
}

export function Link({
  attributes,
  children,
  element,
}: RenderElementProps & { element: LinkElement }) {
  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const text = KitemakerNode.safeString(element);
  const url = element.url ?? text;
  const config = useConfiguration();
  const internalUrl = url.startsWith(config.host) ? new URL(url) : null;
  const history = useHistory();
  const id = React.useRef(uuid.v4());
  const [showHover, setShowHover] = React.useState(false);
  const showTimeoutRef = React.useRef(-1);
  const hideTimeoutRef = React.useRef(-1);
  const [restoreSelection, setRestoreSelection] = React.useState<Range | null>(null);
  const hovers = React.useContext(hoverContext);

  function startHidingHover() {
    hideTimeoutRef.current = window.setTimeout(() => {
      if (restoreSelection) {
        Transforms.select(editor, restoreSelection);
        ReactEditor.focus(editor);
      }
      setShowHover(false);
    }, 1000);
  }

  const linkContents = (
    <a
      className="link"
      href={url}
      onClick={e => {
        e.preventDefault();
        if (internalUrl) {
          e.preventDefault();
          ReactEditor.blur(editor);
          history.push({
            pathname: internalUrl.pathname,
            search: internalUrl.search,
            state: {
              backUrl: history.location.pathname,
              backSearch: history.location.search,
            },
          });
          return;
        }
        openExternalUrl(url);
      }}
      onMouseEnter={() => {
        if (hovers.claimedById?.startsWith('link-')) {
          return;
        }
        if (hideTimeoutRef.current !== -1) {
          window.clearTimeout(hideTimeoutRef.current);
          hideTimeoutRef.current === -1;
        }
        const selection = safeSelection(editor);
        if (selection && !Range.isCollapsed(selection)) {
          return;
        }
        showTimeoutRef.current = window.setTimeout(() => {
          const selection = safeSelection(editor);
          if (selection) {
            setRestoreSelection(selection);
          }
          setShowHover(true);
        }, 1000);
      }}
      onMouseLeave={() => {
        if (showTimeoutRef.current !== -1) {
          window.clearTimeout(showTimeoutRef.current);
          showTimeoutRef.current === -1;
        }
        if (showHover) {
          startHidingHover();
        }
      }}
      {...attributes}
    >
      {children}
    </a>
  );

  if (readonly) {
    return linkContents;
  }

  return (
    <Hover
      hoverId={`link-${id.current}`}
      open={showHover}
      onOpenChange={setShowHover}
      content={
        <HoverContents
          hideTimeoutRef={hideTimeoutRef}
          startHidingHover={startHidingHover}
          initialUrl={url}
          onHide={() => {
            setShowHover(false);
            if (restoreSelection) {
              Transforms.select(editor, restoreSelection);
              ReactEditor.focus(editor);
            }
            setRestoreSelection(null);
          }}
          onBlur={() => {
            setShowHover(false);
          }}
          onChangeUrl={url => {
            setShowHover(false);
            if (restoreSelection) {
              Transforms.select(editor, restoreSelection);
              ReactEditor.focus(editor);
            }
            setRestoreSelection(null);

            const path = ReactEditor.findPath(editor, element);

            if (!url) {
              Transforms.unwrapNodes(editor, { at: path, mode: 'lowest' });
              return;
            }
            Transforms.setNodes(editor, { url, fromHover: true }, { at: path });
          }}
        />
      }
      onKeyDown={e => {
        if (!['Shift', 'Meta', 'Ctrl'].includes(e.key)) {
          setShowHover(false);
        }
        return false;
      }}
    >
      {linkContents}
    </Hover>
  );
}
