import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { RenderElementProps } from 'slate-react';
import { useCurrentUser } from '../../contexts/userContext';
import { groupMembersSelector } from '../../syncEngine/selectors/groups';
import { GroupElement, OptionalAttributesRenderElementProps } from '../types';
import styles from './groupMention.module.scss';
import { VoidInline } from './voidBlock';

export function StaticGroupMention({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: GroupElement }) {
  const { groupType } = element;
  const groupMembers = useRecoilValue(groupMembersSelector(element));
  const currentUser = useCurrentUser();

  return (
    <span
      {...attributes}
      className={cn(styles.groupMention, {
        [styles.highlighted]: !!groupMembers.find(m => m.id === currentUser.id),
      })}
    >
      @{groupType}
      {children}
    </span>
  );
}

export function GroupMention({
  attributes,
  element,
  children,
}: RenderElementProps & { element: GroupElement }) {
  const { groupType } = element;
  const groupMembers = useRecoilValue(groupMembersSelector(element));
  const currentUser = useCurrentUser();

  return (
    <VoidInline
      element={element}
      attributes={attributes}
      className={cn(styles.groupMention, styles.slate, {
        [styles.highlighted]: !!groupMembers.find(m => m.id === currentUser.id),
      })}
    >
      @{groupType}
      {children}
    </VoidInline>
  );
}
