import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { CycleListItem } from '../../components/cycle';
import { Breadcrumbs, useSpaceBreadcrumb } from '../../components/new/breadcrumbs';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import { ScreenHeader } from '../../components/new/screenHeader';
import { VirtualizedListView } from '../../components/new/virtualizedListView';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useOrganization } from '../../contexts/organizationContext';
import { useSpace } from '../../contexts/spaceContext';
import { cyclesForSpaceSelector } from '../../syncEngine/selectors/cycles';
import { StarredType } from '../../utils/starred';
import { NotFoundScreen } from '../errorScreens';
import styles from './cycleScreen.module.scss';

function Header() {
  const space = useSpace();
  const spaceBreadcrumb = useSpaceBreadcrumb();

  return (
    <ScreenHeader compensateForMacOSTrafficLights="auto" showSidebarOpener>
      <Breadcrumbs
        breadcrumbs={[...spaceBreadcrumb, { name: 'Cycles' }]}
        starred={{
          type: StarredType.Cycles,
          id: space.id,
        }}
      />
    </ScreenHeader>
  );
}

function Cycles() {
  const space = useSpace();

  const cycleIds = useRecoilValue(cyclesForSpaceSelector(space.id)).map(c => c.id);

  return (
    <VirtualizedListView
      id="cycles"
      className={styles.list}
      sectionIds={['current']}
      itemIds={{ current: cycleIds }}
      renderSectionHeader={() => {
        return null;
      }}
      sectionHeaderHeight={32}
      itemHeight={55}
      renderPlaceholder={() => <div></div>}
      renderItem={(id, _sectionId, isFirst, isLast) => {
        return (
          <CycleListItem
            className={cn('listItem', {
              first: isFirst,
              last: isLast,
            })}
            id={id}
          />
        );
      }}
    />
  );
}

export function CyclesScreen() {
  const organization = useOrganization();
  const space = useSpace();

  return (
    <Screen>
      {space.cyclesEnabled && (
        <>
          <TitleSetter title={`${organization.name} · ${space.name} · Cycles`} />
          <Header />
          <KeyNavigationProvider columnIds={['cycles']} disableLeftAndRight disableEnsureVisible>
            <Cycles />
          </KeyNavigationProvider>
        </>
      )}
      {!space.cyclesEnabled && <NotFoundScreen />}
    </Screen>
  );
}
