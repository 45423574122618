import { keyBy } from 'lodash';
import { Operation, Node, Point, Path, Editor, Text } from 'slate';
import { KitemakerNode } from '../../shared/slate/kitemakerNode';
import { filterNotNull } from '../../shared/utils/convenience';
import { IdentifiedOperation } from '../graphql/modelManager';
import { CursorLocation, CursorLocations, EditorType } from './types';

export function transformCursors(
  locations: CursorLocations,
  operations: Operation[]
): CursorLocations {
  const updatedLocations = Object.values(locations).map(location => {
    let point: Point = location;
    for (const operation of operations) {
      const updatedPoint = Point.transform(point, operation);
      if (!updatedPoint) {
        return null;
      }
      point = updatedPoint;
    }
    return {
      ...location,
      ...point,
    };
  });

  return keyBy(filterNotNull(updatedLocations), 'clientId');
}

export function calculateCursor(
  editor: EditorType,
  operation: IdentifiedOperation
): CursorLocation | null {
  const actorId = operation.actorId;
  const clientId = operation.clientId;

  if (actorId == null || clientId == null) {
    return null;
  }

  let offset: number;
  let path: Path | null = null;

  switch (operation.type) {
    case 'insert_text':
      offset = operation.offset;
      break;
    case 'remove_text':
      offset = operation.offset - 1;
      break;
    case 'insert_node': {
      offset = KitemakerNode.safeString(operation.node).length - 1;
      break;
    }
    case 'remove_node': {
      if (!Path.hasPrevious(operation.path)) {
        return null;
      }
      path = Path.previous(operation.path);
      const nodes = Editor.last(editor, path);
      if (!nodes.length) {
        return null;
      }
      const [node] = nodes;
      offset = KitemakerNode.safeString(node).length - 1;
      break;
    }
    case 'set_node': {
      path = Path.next(operation.path);
      if (!Node.has(editor, path)) {
        return null;
      }
      offset = 0;
      break;
    }
    case 'split_node': {
      if (!Editor.hasPath(editor, operation.path)) {
        return null;
      }
      const nodeEntry = Editor.node(editor, operation.path);
      if (!nodeEntry) {
        return null;
      }
      const [node] = nodeEntry;
      if (!Text.isText(node)) {
        return null;
      }

      path = Path.next(operation.path);
      offset = -1;
      break;
    }
    case 'merge_node': {
      if (!Path.hasPrevious(operation.path)) {
        return null;
      }
      path = Path.previous(operation.path);
      offset = operation.position - 1;
      break;
    }
    case 'move_node': {
      if (operation.newPath.length <= operation.path.length || !Node.has(editor, operation.path)) {
        return null;
      }
      const nodeEntry = Editor.node(editor, operation.path);
      if (!nodeEntry) {
        return null;
      }
      const [node] = nodeEntry;
      path = operation.path;
      offset = KitemakerNode.safeString(node).length - 1;
      break;
    }
    default:
      return null;
  }

  return {
    actorId,
    clientId,
    path: path ?? operation.path,
    offset,
    time: Date.now(),
  };
}
