import cn from 'classnames';
import { isFunction, isUndefined } from 'lodash';
import * as React from 'react';
import Hotkey from '../hotkey';
import { Icon, IconSize } from './icon';
import { KeyboardShortcut } from './keyboardShortcut';
import {
  FocusReason,
  KeyNavigationElement,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useSetKeyNavigationFocus,
} from './keyNavigation';
import styles from './listView.module.scss';
import { VerticalScrollArea } from './scrollArea';

export const LISTVIEW_ID = 'listView';
export const NO_KEYNAV = 'no_keynav';

export interface ListViewItem {
  id: string;
  contents: React.ReactNode | ((selected: boolean) => React.ReactNode);
  icon?: string | React.ReactNode | null;
  hotkey?: string;
  className?: string;
  selectedClassName?: string;
  onSelected?: (shift: boolean) => void;
  mouseDown?: boolean;
  hotkeyHint?: React.ReactNode;
  iconSize?: IconSize;
  disabled?: boolean;
  key?: string;
  [key: string]: unknown;
}

export function ListViewItemComponent({
  id,
  item,
  iconSize,
  className,
  selectedClassName,
}: {
  id: string;
  item: ListViewItem;
  iconSize?: IconSize;
  className?: string;
  selectedClassName?: string;
}) {
  const {
    contents,
    icon,
    hotkey,
    className: customClassName,
    selectedClassName: customSelectedClassName,
    onSelected,
    mouseDown,
  } = item;
  const focused = useHasKeyNavigationFocus(id);
  const ref = React.useRef<HTMLDivElement>(null);

  if (id === NO_KEYNAV) {
    return (
      <>
        {isFunction(contents) ? (
          contents(focused)
        ) : (
          <div className={styles.contents}>{contents}</div>
        )}
      </>
    );
  }

  const listItem = (
    <KeyNavigationElement
      id={id}
      ref={ref}
      className={cn(
        'row',
        {
          [selectedClassName ?? '']: focused,
          [customSelectedClassName ?? '']: focused,
        },
        className,
        customClassName
      )}
      onMouseDown={e => {
        if (onSelected && mouseDown) {
          e.preventDefault();
          e.stopPropagation();
          onSelected(e.shiftKey);
        }
      }}
      onClick={e => {
        if (onSelected && !mouseDown) {
          e.preventDefault();
          e.stopPropagation();
          onSelected(e.shiftKey);
        }
      }}
    >
      {onSelected && focused && (
        <>
          <Hotkey
            command={{
              id: `select-and-done-${id}`,
              hotkey: 'enter',
              priority: -1,
              global: true,
              handler: e => {
                e?.preventDefault();
                e?.stopPropagation();
                onSelected(false);
              },
            }}
          />
          <Hotkey
            command={{
              id: `select-${id}`,
              hotkey: 'shift+enter',
              global: true,
              priority: -1,
              handler: e => {
                e?.preventDefault();
                e?.stopPropagation();
                onSelected(true);
              },
            }}
          />
        </>
      )}
      {!isUndefined(icon) && typeof icon === 'string' && <Icon size={iconSize} icon={icon} />}
      {!isUndefined(icon) && typeof icon !== 'string' && icon}
      {isFunction(contents) ? contents(focused) : <div className={styles.contents}>{contents}</div>}
      {hotkey && (
        <>
          <KeyboardShortcut shortcut={hotkey} />
          {onSelected && (
            <Hotkey
              command={{
                id: `hotkey-${id}`,
                hotkey,
                handler: e => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  onSelected(false);
                },
              }}
            />
          )}
        </>
      )}
    </KeyNavigationElement>
  );

  return listItem;
}

export interface ListViewProps<T extends ListViewItem> {
  items: T[];
  selectFirstOnItemsChanged?: boolean;
  className?: string;
  itemClassName?: string;
  itemSelectedClassName?: string;
  keyNavId?: string;
  iconSize?: IconSize;
  itemRenderFunction?: (item: T) => JSX.Element;
}

export function ListView<T extends ListViewItem>({
  items,
  className,
  selectFirstOnItemsChanged,
  itemClassName,
  itemSelectedClassName,
  keyNavId = LISTVIEW_ID,
  iconSize = IconSize.Size16,
  itemRenderFunction,
}: ListViewProps<T>) {
  const ref = React.useRef<HTMLDivElement>(null);

  useKeyNavigationColumn(
    keyNavId,
    items.filter(i => i.id !== NO_KEYNAV).map(i => i.id!),
    ref
  );

  const setFocus = useSetKeyNavigationFocus();
  React.useEffect(() => {
    if (selectFirstOnItemsChanged && items.length) {
      const first = items.find(id => id.id !== NO_KEYNAV);
      if (first) {
        setFocus(first.id, FocusReason.Programmatic);
      }
    }
  }, [JSON.stringify(items.map(i => i.id))]);

  const defaultRenderFunction = React.useCallback(
    (item: ListViewItem) => {
      return (
        <ListViewItemComponent
          key={item.key ?? item.id}
          id={item.id}
          item={item}
          className={itemClassName}
          selectedClassName={itemSelectedClassName}
          iconSize={iconSize}
        ></ListViewItemComponent>
      );
    },
    [itemClassName, itemSelectedClassName]
  );

  const renderedItems = items.map(item =>
    itemRenderFunction ? itemRenderFunction(item) : defaultRenderFunction(item)
  );
  return (
    <VerticalScrollArea className={cn(className ?? '', 'fullWidth')} ref={ref}>
      {renderedItems}
    </VerticalScrollArea>
  );
}
