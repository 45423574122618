import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { FULL_SYNC_SCOPES, FULL_SYNC_USER_SCOPES } from '../../../../shared/slack';
import { IntegrationType } from '../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../api/auth';
import { Button } from '../../../components/new/button';
import { IntegrationIcon } from '../../../components/new/integrationIcon';
import {
  SettingsPage,
  SettingsSection,
  SettingsSectionDivider,
} from '../../../components/new/settings';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useConfirmation } from '../../../contexts/confirmationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useDisconnectIntegrationUser } from '../../../syncEngine/actions/integrationUsers';
import {
  integrationsSelector,
  integrationUsersForCurrentUserSelector,
} from '../../../syncEngine/selectors/integrations';
import {
  integrationsForOrganizationSelector,
  organizationPath,
} from '../../../syncEngine/selectors/organizations';
import { getQueryParameter } from '../../../utils/query';

const SUPPORTED_CONNECTED_ACCOUNTS = [IntegrationType.Slack2];

function useStartConnectionFlow() {
  const { host, electronScheme } = useConfiguration();
  const organization = useOrganization();

  return useRecoilCallback(({ snapshot }) => async (integrationId: string) => {
    const integration = snapshot.getLoadable(integrationsSelector(integrationId)).getValue();
    if (!integration) {
      return;
    }

    const redirectUrl = `${host}${organizationPath(organization, 'settings/connectedAccounts')}`;
    const params = new URLSearchParams();
    let type = integration.type.toLowerCase();

    switch (integration.type) {
      case IntegrationType.Slack2:
        {
          const config = JSON.parse(integration.config) as {
            teamId: string;
            teamName: string;
            scopes: string[];
          };
          const fullSync = FULL_SYNC_SCOPES.every(scope => config.scopes.includes(scope));
          params.append('organizationId', integration.organizationId);
          params.append('additionalUserScopes', fullSync ? FULL_SYNC_USER_SCOPES.join(',') : '');
          params.append('teamId', config.teamId);
          params.append('userAuth', 'true');

          type = 'slack';
        }
        break;
    }

    externalAuthFlow(`${host}/integrations/${type}/install?${params.toString()}`, electronScheme, {
      redirectBaseUrl: redirectUrl,
      exchange: true,
    });
  });
}

function SlackConnections() {
  const organization = useOrganization();
  const startFlow = useStartConnectionFlow();
  const disconnect = useDisconnectIntegrationUser();
  const { confirm } = useConfirmation();

  const integrationUsers = useRecoilValue(integrationUsersForCurrentUserSelector).filter(
    u => u.type === IntegrationType.Slack2 && u.configValid
  );
  const integrations = useRecoilValue(integrationsForOrganizationSelector(organization.id)).filter(
    i => i.type === IntegrationType.Slack2
  );

  const integrationsWithoutUser = integrations.filter(i => {
    return !integrationUsers.some(u => u.integrationExternalId === i.externalId);
  });

  return (
    <SettingsSection title="Slack users associated with your Kitemaker account">
      {integrationUsers.map(user => {
        const integration = integrations.find(i => i.externalId === user.integrationExternalId);

        return (
          <div className="rowBetween fullWidth ellipsis mb4" key={user.id}>
            <div className="row grow ellipsis">
              <IntegrationIcon integrationType={IntegrationType.Slack2} colored className="mr8" />
              Slack user&nbsp;<span className="semiBold">{user.externalName}</span>&nbsp;connected
              to Slack workspace&nbsp;
              <span className="semiBold">
                {integration ? JSON.parse(integration.config).teamName : 'Unknown team'}
              </span>
            </div>
            <Button
              onClick={async () => {
                const confirmed = await confirm(
                  `Disconect Slack account`,
                  'Are you sure you want to disconnect this Slack account? This will mean that you can no longer interact with Kitemaker from Slack',
                  { label: 'Disconnect', destructive: true }
                );
                if (!confirmed) {
                  return;
                }
                disconnect(user.id);
              }}
            >
              Disconnect
            </Button>
          </div>
        );
      })}
      {integrationUsers.length > 0 && integrationsWithoutUser.length > 0 && (
        <SettingsSectionDivider />
      )}
      {integrationsWithoutUser.map(integration => {
        const config = JSON.parse(integration.config) as { teamName: string };
        return (
          <div className="rowBetween fullWidth ellipsis mb4" key={integration.id}>
            <div className="grow ellipsis row">
              <IntegrationIcon integrationType={IntegrationType.Slack2} colored className="mr8" />
              Slack workspace&nbsp;<span className="semiBold">{config.teamName}</span>&nbsp;is not
              connected
            </div>
            <Button onClick={() => startFlow(integration.id)}>Connect</Button>
          </div>
        );
      })}
      {integrationUsers.length === 0 && integrationsWithoutUser.length === 0 && (
        <div className="slightlyGrayed">No Slack accounts or integrations found</div>
      )}
    </SettingsSection>
  );
}

export function ConnectedAccountsScreen() {
  const history = useHistory();
  const { params } = useRouteMatch<{ integrationType?: string }>();
  const startFlow = useStartConnectionFlow();
  const integrationId = React.useMemo(() => {
    return getQueryParameter(history, 'integrationId');
  }, [history]);

  useComponentDidMount(() => {
    if (
      integrationId &&
      params.integrationType &&
      SUPPORTED_CONNECTED_ACCOUNTS.includes(params.integrationType.toUpperCase() as IntegrationType)
    ) {
      startFlow(integrationId);
    }
  });

  return (
    <SettingsPage
      title="Connected Accounts"
      description="Manage external accounts that are connected to your Kitemaker account"
    >
      <SlackConnections />
    </SettingsPage>
  );
}
