import { useRecoilCallback } from 'recoil';
import { serializeToMarkdown } from '../../../shared/slate/markdown';
import { DocumentLike } from '../../../shared/slate/types';
import { TodoStatus } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { entityKeySelector, useEntityPath } from '../../syncEngine/selectors/entities';
import { todoSelector } from '../../syncEngine/selectors/todos';
import { userSelector } from '../../syncEngine/selectors/users';

export function useSerializeToMarkdown() {
  const { host } = useConfiguration();
  const entityPath = useEntityPath();
  return useRecoilCallback(({ snapshot }) => (doc: DocumentLike, voidPlaceholders?: boolean) => {
    return serializeToMarkdown(
      doc,
      {
        userLookup: userId => {
          return snapshot.getLoadable(userSelector(userId)).getValue()?.username ?? null;
        },
        entityLookup: entityId => {
          const key = snapshot.getLoadable(entityKeySelector(entityId)).getValue();
          if (!key) {
            return null;
          }
          const path = entityPath(entityId);
          if (!path) {
            return null;
          }

          return {
            link: `${host}${path}`,
            number: key,
          };
        },
        todoLookup: todoId => {
          const todo = snapshot.getLoadable(todoSelector(todoId)).getValue();
          if (!todo) {
            return null;
          }

          return { checked: todo.status === TodoStatus.Done };
        },
      },
      voidPlaceholders
    );
  });
}
