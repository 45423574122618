import cn from 'classnames';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { atomFamily, useRecoilState, useRecoilValue } from 'recoil';
import { documentFromString } from '../../../shared/slate/utils';
import { issueTerm } from '../../../shared/utils/terms';
import { capitalize } from '../../../shared/utils/utils';
import { Release } from '../../../sync/__generated/models';
import { generateChangelog } from '../../api/ai';
import { CommandGroup } from '../../commands';
import { ArchivedNotice } from '../../components/archivedNotice';
import { writeToClipboard } from '../../components/clipboardText';
import { CollaborativeDocEditor } from '../../components/collaborativeDocEditor';
import { DescriptionHistory } from '../../components/descriptionHistory';
import { DescriptionPlaceholder, PlaceholderType } from '../../components/descriptionPlaceholder';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { ActivitiesTab } from '../../components/new/activities/activities';
import BackButton from '../../components/new/backButton';
import { Breadcrumbs, TitleBreadcrumb } from '../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../components/new/button';
import { ButtonGroup } from '../../components/new/buttonGroup';
import { CommandContext } from '../../components/new/commandMenuContext';
import { CustomCommand } from '../../components/new/customCommand';
import { EntityDueDate } from '../../components/new/dueDateMetadata';
import { EntityMembers, InsightsTab } from '../../components/new/entityMetadata';
import {
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS,
  EntityScreenKeyNavHacks,
} from '../../components/new/entityScreenKeyNavHacks';
import { QueryParameterManager, useEntityTabs } from '../../components/new/entityTabs';
import { EntityTitleEditor } from '../../components/new/entityTitleEditor';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  KeyNavigationProvider,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
} from '../../components/new/keyNavigation';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { AvatarSize } from '../../components/new/metadata/avatar';
import { MetadataSize } from '../../components/new/metadata/size';
import { NotificationsIndicator } from '../../components/new/notifications';
import { ReleaseMenu } from '../../components/new/releaseMenu';
import { ReleaseSpaces } from '../../components/new/releaseSpaces';
import { ScreenHeader } from '../../components/new/screenHeader';
import { Tab, Tabs, TabStyle } from '../../components/new/tabs';
import { TodoList } from '../../components/new/todoList';
import { Tooltip } from '../../components/new/tooltip';
import {
  RightBar,
  RightSidebarButton,
  rightSidebarCollapsed,
  RightSidebarGroup,
} from '../../components/rightBar';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { FetchedMarker } from '../../graphql/smartLoad';
import {
  ResponsiveDesignSize,
  useIsMediumScreen,
  useIsSmallScreen,
} from '../../hooks/useResponsiveDesign';
import useSaveScroll from '../../hooks/useSaveScroll';
import { useNextPreviousSiblings } from '../../hooks/useSiblingEntities';
import { TextAreaHandle } from '../../slate/textArea';
import { useAddAssigneesToEntities } from '../../syncEngine/actions/entities';
import { useAppendToDocument } from '../../syncEngine/actions/issues';
import { useUpdateReleases } from '../../syncEngine/actions/releases';
import { localStorageEffect } from '../../syncEngine/effects';
import {
  collaborativeDocIdByEntitySelector
} from '../../syncEngine/selectors/collaborativeDoc';
import {
  breadcrumbsForEntitySelector,
  entityDeletedSelector,
  isPartialSelector,
  useEntityPath,
  useUpdateRecents,
} from '../../syncEngine/selectors/entities';
import { issuesSelector } from '../../syncEngine/selectors/issues';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { releaseIdByNumberSelector, releaseSelector } from '../../syncEngine/selectors/releases';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { copyIssueLinkKey, copyIssueNumberKey, vimDown, vimUp } from '../../utils/config';
import { LocationState } from '../../utils/history';
import { HistoryCheckpoint } from '../../utils/revisions';
import { StarredType } from '../../utils/starred';
import { NotFoundScreen } from '../errorScreens';
import { DeletedScreen } from '../errorScreens/deletedScreen';
import LoadingScreen from '../loadingScreen';
import styles from './releaseDetailsScreen.module.scss';
import { ReleaseIssuesList } from './releaseIssuesList';
import { ReleaseStatusIndicator } from './releaseStatusIndicator';

const releaseScreenView = atomFamily<View, string>({
  key: 'ReleaseScreenView',
  default: 'issues',
  effects: key => [localStorageEffect(`__releaseScreenView_${key}`)],
});

const views = ['issues', 'notes'] as const;
type View = typeof views[number];

function Header({ release }: { release: Release }) {
  const location = useLocation<LocationState>();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(release.id));
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'releases');
  const entityPath = useEntityPath();
  const { host } = useConfiguration();
  const isMediumScreen = useIsMediumScreen();

  const sidebarCollapsed = useRecoilValue(rightSidebarCollapsed(RightSidebarGroup.Releases));

  const { previousEntity, nextEntity, totalCount, position } = useNextPreviousSiblings(release.id);

  if (!entityBreadcrumbs) {
    return null;
  }

  const rightSideContent = (
    <div className="row metadataGapL">
      <Tooltip
        content={
          <>
            Copy release number
            <KeyboardShortcut shortcut={copyIssueNumberKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="copy"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            writeToClipboard(`R-${release.number}`, `release number`);
          }}
        />
      </Tooltip>
      <Tooltip
        content={
          <>
            Copy release link
            <KeyboardShortcut shortcut={copyIssueLinkKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="link"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            writeToClipboard(`${host}${entityPath(release.id)}`, `link to release`);
          }}
        />
      </Tooltip>

      {sidebarCollapsed && (
        <>
          <RightSidebarButton group={RightSidebarGroup.Releases} />
          <NotificationsIndicator />
        </>
      )}
    </div>
  );

  return (
    <ScreenHeader
      className={styles.header}
      hideNotifications
      compensateForMacOSTrafficLights="always"
      rightSideContent={isMediumScreen ? null : rightSideContent}
    >
      <div className="row headerGap ellipsis">
        <BackButton defaultPath={defaultPath} />
        <div className="row metadataGapL">
          <ButtonGroup>
            <Tooltip
              content={
                <>
                  Previous release <KeyboardShortcut shortcut={vimUp} />
                </>
              }
            >
              <IconButton disabled={position === 0} icon={'arrow_up'} onClick={previousEntity} />
            </Tooltip>
            <Tooltip
              content={
                <>
                  Next release <KeyboardShortcut shortcut={vimDown} />
                </>
              }
            >
              <IconButton
                disabled={position === totalCount - 1}
                icon={'arrow_down'}
                onClick={nextEntity}
              />
            </Tooltip>
          </ButtonGroup>

          <CustomCommand
            command={{
              id: 'next-release',
              icon: 'arrow_down',
              description: `Move to the next release`,
              handler: () => nextEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimDown,
              priority: 20,
            }}
          />
          <CustomCommand
            command={{
              id: 'previous-release',
              icon: 'arrow_up',
              description: `Move to the previous release`,
              handler: () => previousEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimUp,
              priority: 20,
            }}
          />
          <div className={styles.count}>
            {position + 1}
            <span className="mr2 ml2">/</span>
            {totalCount}
          </div>
        </div>
        {isMediumScreen && <Breadcrumbs breadcrumbs={[{ name: `R-${release.number}` }]} />}
        {!isMediumScreen && (
          <Breadcrumbs
            breadcrumbs={[
              ...(location.state?.breadcrumbs ?? entityBreadcrumbs),
              {
                name: <TitleBreadcrumb number={`R-${release.number}`} title={release.title} />,
              },
            ]}
            starred={{
              type: StarredType.Release,
              id: release.id,
            }}
          />
        )}
      </div>
    </ScreenHeader>
  );
}

function SupersetTabs({
  releaseId,
  currentTab,
  onTabChanged,
}: {
  releaseId: string;
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  const tabs = useEntityTabs(
    releaseId,
    [
      {
        id: 'notes',
        icon: 'notes',
        name: 'Release notes',
      },
    ],
    { id: 'issues', name: capitalize(`${issueTerm}s`) }
  );

  return (
    <ScreenHeader
      className={cn(styles.header, 'overflowScrollX')}
      collapsibleGroup={RightSidebarGroup.Releases}
    >
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function Title({ release }: { release: Release }) {
  const updateReleases = useUpdateReleases();
  const addMembers = useAddAssigneesToEntities();
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState('');

  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  const editTitle = React.useCallback(() => {
    updateReleases([release.id], { title });
    setTitle('');
    setEditing(false);
  }, [updateReleases, release.id, title]);

  const ref = React.useRef<TextAreaHandle>(null);

  if (!editing) {
    return (
      <div
        onMouseDown={() => {
          setTitle(release.title);
          setEditing(true);
        }}
        className="rowAlignStart gap16 heading2XL bold wrap fs-exclude fullWidth breakWord"
      >
        <CustomCommand
          command={{
            id: 'edit-title',
            group: CommandGroup.Entities,
            description: 'Edit title',
            hotkey: 't',
            priority: 99,
            handler: () => {
              setTitle(release.title);
              setEditing(true);
              ref.current?.focus();
            },
          }}
        />
        {release.title || 'Untitled release'}
      </div>
    );
  }

  return (
    <div className="rowAlignStart gap16 heading2XL bold wrap fullWidth fs-exclude">
      <EntityTitleEditor
        ref={ref}
        className="grow"
        initialTitle={release.title}
        onChange={v => {
          setTitle(v);
        }}
        autoFocus
        onSubmit={editTitle}
        onFocus={() => {
          disableKeyNav('title');
        }}
        onBlur={() => {
          enableKeyNav('title');
          editTitle();
        }}
        supportedMetadata={{
          users: true,
        }}
        onMetadataAdded={(type, id) => {
          switch (type) {
            case 'user':
              addMembers([release.id], [id]);
              break;
          }
        }}
        placeholder="Give your release a title"
      />
    </div>
  );
}

function TitleHeader({
  release,
  requestFocusDescription,
}: {
  release: Release;
  requestFocusDescription: () => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const isSmallScreen = useIsSmallScreen();

  const dropdownMenu = (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <IconButton icon="more" buttonStyle={ButtonStyle.Bare} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        <ReleaseMenu
          showHistory
          release={release}
          closeMenu={() => setMenuOpen(false)}
          onShowHistory={requestFocusDescription}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <div className={styles.titleHeaderContainer}>
      <div className={styles.gutter} />
      <div className={styles.titleHeader}>
        <div className="col gap16 grow">
          {isSmallScreen && (
            <div className="rowBetween fullWidth">
              <div className="row gap8 flexWrap">
                <ReleaseStatusIndicator release={release} size={MetadataSize.Medium} interactable />
                <EntityDueDate item={release} interactable size={MetadataSize.Medium} />
              </div>
              <div className="row">
                <EntityMembers interactable max={2} entity={release} size={AvatarSize.Size24} />
                {isSmallScreen && dropdownMenu}
              </div>
            </div>
          )}
          <Title release={release} />
          <div className="row gap8 flexWrap">
            {!isSmallScreen && (
              <>
                <ReleaseStatusIndicator release={release} size={MetadataSize.Medium} interactable />
                <EntityDueDate item={release} interactable size={MetadataSize.Medium} />
              </>
            )}
            <ReleaseSpaces interactable release={release} size={MetadataSize.Medium} />
          </div>
        </div>

        {!isSmallScreen && (
          <div className="row flexAlignStart metadataGapL ml8">
            <EntityMembers interactable max={2} entity={release} size={AvatarSize.Size24} />
            {dropdownMenu}
          </div>
        )}
      </div>
      <div className={styles.gutter} />
    </div>
  );
}

function RightHeader({
  tabs,
  currentTab,
  onTabChanged,
}: {
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  return (
    <ScreenHeader className={styles.header} collapsibleGroup={RightSidebarGroup.Releases}>
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function ViewHeader({
  currentView,
  onViewChanged,
  release,
}: {
  currentView: string;
  onViewChanged: (tab: View) => void;
  release: Release;
}) {
  const issues = useRecoilValue(issuesSelector(release.issueIds));
  const appendToDocument = useAppendToDocument();

  const [isLoading, setIsLoading] = React.useState(false);

  const tabs = [
    {
      id: 'issues',
      icon: 'work_items',
      name: capitalize(`${issueTerm}s`),
    },
    {
      id: 'notes',
      icon: 'notes',
      name: 'Release notes',
    },
  ];

  return (
    <div className={styles.tabHeaderContainer}>
      <div className={styles.gutter} />
      <div className={styles.tabHeader}>
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentView}
          onTabChanged={t => onViewChanged(t as View)}
        />
        {issues && issues.length > 0 && (
          <Button
            buttonStyle={ButtonStyle.SecondarySubtle}
            icon={isLoading ? <LoadingSpinner style={{ maxHeight: '20px' }} /> : 'ai'}
            onClick={async () => {
              setIsLoading(true);
              if (currentView !== 'notes') {
                onViewChanged('notes');
              }

              const content = await generateChangelog(
                release.organizationId,
                issues.map(i => i.title)
              );
              const fragment = documentFromString(content);
              appendToDocument(release.id, { document: fragment });

              setIsLoading(false);
            }}
          >
            Generate changelog
          </Button>
        )}
      </div>
      <div className={styles.gutter} />
    </div>
  );
}

function Description({ releaseId }: { releaseId: string }) {
  const partialEntity = useRecoilValue(isPartialSelector(releaseId));
  const [descriptionDisabled, setDescriptionDisabled] = React.useState(false);
  const [historyDescription, setHistoryDescription] = React.useState<HistoryCheckpoint | null>(
    null
  );
  const ref = React.useRef<TextAreaHandle>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const docId = useRecoilValue(collaborativeDocIdByEntitySelector(releaseId));

  useSaveScroll(releaseId, scrollRef);

  return (
    <>
      {!partialEntity && (
        <div className={styles.descriptionContainer} ref={scrollRef}>
          <div className={styles.gutter} />
          <CollaborativeDocEditor
            key={`notes-${releaseId}`}
            className={cn(styles.description, 'fs-exclude')}
            documentId={docId ?? ''}
            entityId={releaseId}
            inlineComments
            historyDescription={historyDescription}
            textAreaRef={ref}
            disabled={descriptionDisabled}
            bottomSpacing={24}
            placeholder={<DescriptionPlaceholder type={PlaceholderType.Release} />}
          />
          <div className={styles.gutter} />
        </div>
      )}
      {partialEntity && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      )}
      <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
        <DescriptionHistory
          documentId={docId ?? ''}
          onDescriptionChanged={d => {
            setHistoryDescription(d);
          }}
          onRestore={d => {
            setHistoryDescription(null);
            setTimeout(() => ref.current?.replaceValue(d.content));
          }}
          onOpen={() => {
            setDescriptionDisabled(true);
          }}
          onClose={() => {
            setDescriptionDisabled(false);
          }}
        />
      </HideIfSmallerThan>
    </>
  );
}

function MainScrollArea({ children, releaseId }: { children: React.ReactNode; releaseId: string }) {
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationColumn(`notes-${releaseId}`, [`notes-${releaseId}`]);
  const selected = useHasKeyNavigationFocus(`notes-${releaseId}`);

  React.useEffect(() => {
    if (selected && document.activeElement === document.body) {
      ref.current?.focus();
    }
  }, [selected]);

  return (
    <div className="colStretch grow overflowScrollY fullWidth" ref={ref} tabIndex={-1}>
      {children}
    </div>
  );
}

function Context({ releaseId }: { releaseId: string }) {
  return <CommandContext context={{ group: CommandGroup.Entities, entityIds: [releaseId] }} />;
}

function ReleaseDetailsScreen({ releaseId }: { releaseId: string }) {
  const organization = useOrganization();
  const release = useRecoilValue(releaseSelector(releaseId));
  const rightBarCollapsed = useRecoilValue(rightSidebarCollapsed(RightSidebarGroup.Releases));
  const [currentView, setCurrentView] = useRecoilState(releaseScreenView(releaseId));
  const [supersetTab, setSupersetTab] = React.useState<string>(currentView);
  const isMediumScreen = useIsMediumScreen();
  const [openCommentThreadId, setOpenCommentThreadId] = React.useState<string | null>(null);
  const tabs = useEntityTabs(releaseId);
  const [currentTab, setCurrentTab] = React.useState(isMediumScreen ? 'issues' : 'activities');
  const updateReleases = useUpdateReleases();

  useUpdateRecents(releaseId);

  if (!release) {
    return null;
  }

  const columnIds = currentView === 'issues' ? [`issues-${releaseId}`] : [`notes-${releaseId}`];
  if (!rightBarCollapsed) {
    columnIds.push(...[...ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS, `right-${releaseId}`]);
  }

  return (
    <KeyNavigationProvider
      columnIds={columnIds}
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <Screen hideSidebar row={!isMediumScreen} className={styles.releaseScreen}>
        <TitleSetter title={`${organization.name} · Releases · ${release.title}`} />
        <Context releaseId={releaseId} />

        {!rightBarCollapsed && <EntityScreenKeyNavHacks />}
        <div className={cn(styles.left)}>
          <Header release={release} />
          <MainScrollArea releaseId={release.id}>
            {release.archivedAt && (
              <ArchivedNotice
                type={'release'}
                onUnarchive={() => updateReleases([releaseId], { archivedAt: null })}
              />
            )}

            {isMediumScreen && (
              <>
                <SupersetTabs
                  releaseId={release.id}
                  currentTab={supersetTab}
                  onTabChanged={setSupersetTab}
                />

                {supersetTab === 'notes' && (
                  <>
                    <TitleHeader
                      release={release}
                      requestFocusDescription={() => setCurrentView('notes')}
                    />
                    <Description releaseId={releaseId} />
                  </>
                )}
                {!['notes', 'activities', 'insights', 'todos'].includes(supersetTab) && (
                  <ReleaseIssuesList releaseId={releaseId} />
                )}

                {supersetTab === 'activities' && (
                  <ActivitiesTab entityId={releaseId} openCommentThreadId={openCommentThreadId} />
                )}
                {supersetTab === 'insights' && (
                  <InsightsTab entityId={releaseId} entityType={'initiative'} />
                )}
                {supersetTab === 'todos' && (
                  <TodoList
                    entityId={releaseId}
                    className={styles.todoTab}
                    keyNavColumnId={`right-${releaseId}`}
                  />
                )}
              </>
            )}

            {!isMediumScreen && (
              <>
                <TitleHeader
                  release={release}
                  requestFocusDescription={() => setCurrentView('notes')}
                />
                <ViewHeader
                  currentView={currentView}
                  onViewChanged={setCurrentView}
                  release={release}
                />

                {currentView === 'notes' && <Description releaseId={releaseId} />}
                {currentView !== 'notes' && <ReleaseIssuesList releaseId={releaseId} />}
              </>
            )}
          </MainScrollArea>
        </div>

        {!isMediumScreen &&
          (currentTab === 'activities' || currentTab === 'insights' || currentTab === 'todos') && (
            <RightBar className={styles.right} collapsibleGroup={RightSidebarGroup.Releases}>
              <RightHeader tabs={tabs} currentTab={currentTab} onTabChanged={setCurrentTab} />

              {currentTab === 'activities' && (
                <ActivitiesTab entityId={releaseId} openCommentThreadId={openCommentThreadId} />
              )}
              {currentTab === 'insights' && (
                <InsightsTab entityId={releaseId} entityType={'initiative'} />
              )}
              {currentTab === 'todos' && (
                <TodoList
                  entityId={releaseId}
                  className={styles.todoTab}
                  keyNavColumnId={`right-${releaseId}`}
                />
              )}
            </RightBar>
          )}
        <QueryParameterManager
          onOpenThreadIdChanged={threadId => {
            if (threadId && threadId !== openCommentThreadId) {
              setCurrentTab('activities');
              setSupersetTab('activities');
            }
            setOpenCommentThreadId(threadId);
          }}
          onCommentOrActivityIdChanged={commentOrActivityId => {
            if (commentOrActivityId) {
              setCurrentTab('activities');
              setSupersetTab('activities');
            }
          }}
          onInsightIdChanged={insightId => {
            if (insightId) {
              setCurrentTab('insights');
              setSupersetTab('insights');
            }
          }}
        />
      </Screen>
    </KeyNavigationProvider>
  );
}

export default function ReleaseDetailsScreenWrapper() {
  const organization = useOrganization();
  const match = useRouteMatch<{ releaseNumber: string }>();
  const fetched = useRecoilValue(markerState(FetchedMarker.id));
  const releaseId = useRecoilValue(
    releaseIdByNumberSelector({
      organizationId: organization.id,
      releaseNumber: match.params.releaseNumber,
      includeDeleted: true,
    })
  );

  const issueIdRef = React.useRef(releaseId);
  const lastKnownGoodId = releaseId ?? issueIdRef.current;

  const entityDeleted = useRecoilValue(entityDeletedSelector(lastKnownGoodId));

  if (lastKnownGoodId && entityDeleted) {
    return (
      <Screen>
        <DeletedScreen type={'release'} />
      </Screen>
    );
  }

  if (!releaseId) {
    if (fetched) {
      return (
        <Screen>
          <NotFoundScreen />
        </Screen>
      );
    }
    return <LoadingScreen />;
  }

  return <ReleaseDetailsScreen releaseId={releaseId} />;
}
