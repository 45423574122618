import { DateHeader } from '@kitemaker/react-calendar-timeline';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import styles from './timelineViewScreen.module.scss';

const MAIN = {
  week: {
    label: '',
    format: 'MMM',
    unit: 'month',
  },
  month: {
    label: '',
    format: 'YYYY',
    unit: 'year',
  },
  quarter: {
    label: '',
    format: 'YYYY',
    unit: 'year',
  },
};

const SUB = {
  week: {
    label: '',
    format: 'DD',
    unit: 'day',
  },
  month: {
    label: '',
    format: 'MMM',
    unit: 'month',
  },
  quarter: {
    label: 'Q',
    format: 'Q',
    unit: 'month',
  },
};

export function MainHeader({ period }: { period: 'week' | 'month' | 'quarter' }) {
  const config = MAIN[period];
  return (
    <DateHeader
      height={20}
      className={styles.dateTopHeader}
      labelFormat={([startTime]: [moment.Moment]) => {
        return config.label + startTime.format(config.format);
      }}
      unit={config.unit}
      intervalRenderer={({
        getIntervalProps,
        intervalContext,
      }: {
        getIntervalProps: any;
        intervalContext: any;
      }) => {
        // Hack to stop the calender zooming in like crazy when you click the header
        const { onClick, ...intervalProps } = getIntervalProps();
        return (
          <div {...intervalProps} className={styles.dateTopHeader}>
            <span>{intervalContext.intervalText}</span>
          </div>
        );
      }}
    />
  );
}

export function SubHeader({ period }: { period: 'week' | 'month' | 'quarter' }) {
  const config = SUB[period];
  const now = moment();
  return (
    <DateHeader
      height={40}
      unit={config.unit}
      className={styles.dateHeader}
      labelFormat={([startTime]: [moment.Moment]) => {
        const isToday = now.isSame(startTime, 'day');
        if (period === 'week' && isToday) {
          return '';
        }

        return config.label + startTime.format(config.format);
      }}
      intervalRenderer={({
        getIntervalProps,
        intervalContext,
      }: {
        getIntervalProps: any;
        intervalContext: any;
      }) => {
        const startOfPeriod =
          moment(intervalContext.interval.startTime.valueOf()).startOf(period).valueOf() ===
          intervalContext.interval.startTime.valueOf();
        const isToday = now.isSame(intervalContext.interval.startTime, 'day');

        // Hack to stop the calender zooming in like crazy when you click the header
        const { onClick, ...intervalProps } = getIntervalProps();
        return (
          <div
            {...intervalProps}
            className={cn([
              styles.dateHeaderItem,
              {
                [styles.start]: startOfPeriod,
                [styles.today]: isToday,
              },
            ])}
          >
            <span>{startOfPeriod ? intervalContext.intervalText : '\u00A0'}</span>
          </div>
        );
      }}
    />
  );
}
