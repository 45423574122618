import { Editor } from 'slate';
import { Elements } from '../../../../shared/slate/types';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { Matcher } from './withStringMatching';

function markdownBlockMatcher(
  editor: EditorType,
  pattern: string,
  element: Elements,
  options: { noSpace?: boolean } = {}
): Matcher {
  return ({ text, texts }) => {
    // we only these types of shortcuts at the beginning of blocks
    if (texts.length > 1) {
      return false;
    }

    const [, textNodePath] = texts[0];

    if (text === `${pattern}${options.noSpace ? '' : ' '}`) {
      const anchor = Editor.start(editor, textNodePath);
      const focus = { ...anchor, offset: anchor.offset + pattern.length + 1 };

      HistoryEditor.asBatch(editor, () => {
        KitemakerTransforms.delete(editor, { at: { anchor, focus } });
        KitemakerTransforms.changeTextBlockType(editor, element);
      });
      return true;
    }

    return false;
  };
}

export function markdownBlockMatchers(editor: EditorType): Matcher[] {
  const todoType = editor.smartTodosEnabled ? Elements.SmartTodo : Elements.Todo;

  return [
    markdownBlockMatcher(editor, '#', Elements.Headline1),
    markdownBlockMatcher(editor, '##', Elements.Headline2),
    markdownBlockMatcher(editor, '###', Elements.Headline3),
    markdownBlockMatcher(editor, '>', Elements.BlockQuote),
    markdownBlockMatcher(editor, '1.', Elements.Numbered),
    markdownBlockMatcher(editor, '*', Elements.Bulleted),
    markdownBlockMatcher(editor, '-', Elements.Bulleted),
    markdownBlockMatcher(editor, '+', Elements.Bulleted),
    markdownBlockMatcher(editor, '[]', todoType),
    markdownBlockMatcher(editor, '[ ]', todoType),
    markdownBlockMatcher(editor, 'x', todoType),
    markdownBlockMatcher(editor, '---', Elements.Line, { noSpace: true }),
    markdownBlockMatcher(editor, '```', Elements.Code, { noSpace: true }),
  ];
}
