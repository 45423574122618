import * as RadixSelect from '@radix-ui/react-select';
import cn from 'classnames';
import * as React from 'react';
import { ZIndexContext } from '../modal';
import { Icon, IconSize } from './icon';
import styles from './select.module.scss';

export enum SelectSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

const sizeClasses = {
  [SelectSize.Large]: styles.large,
  [SelectSize.Medium]: styles.medium,
  [SelectSize.Small]: styles.small,
};

export function Select({
  values,
  value,
  onChange,
  placeholder,
  className,
  size = SelectSize.Medium,
}: {
  values: { value: string; label: string; disabled?: boolean }[];
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  size?: SelectSize;
}) {
  const zIndex = React.useContext(ZIndexContext) ?? 0;

  const items = values.map(v => (
    <RadixSelect.Item
      key={v.value}
      className={cn(styles.SelectItem, { [styles.disabled]: v.disabled })}
      value={v.value}
      disabled={v.disabled}
    >
      <RadixSelect.ItemIndicator className={styles.itemIndicator}>
        <Icon size={IconSize.Size20} icon="select_checkmark" />
      </RadixSelect.ItemIndicator>
      <RadixSelect.ItemText className={styles.itemLabel}>{v.label}</RadixSelect.ItemText>
    </RadixSelect.Item>
  ));

  return (
    <RadixSelect.Root value={value} onValueChange={v => onChange?.(v)}>
      <RadixSelect.Trigger className={cn(styles.SelectTrigger, sizeClasses[size], className)}>
        <RadixSelect.Value placeholder={placeholder} />
        <RadixSelect.Icon className={styles.SelectIcon}>
          <Icon icon="expand_more" />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content style={{ zIndex: zIndex + 200 }} className={styles.SelectContent}>
          <RadixSelect.ScrollUpButton className={styles.SelectScrollButton}>
            <Icon icon="expand_less" />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport className={styles.SelectViewport}>{items}</RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton className={styles.SelectScrollButton}>
            <Icon icon="expand_more" />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
}
