import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { isElectron } from '../../electronIntegration';
import { ResponsiveDesignSize } from '../../hooks/useResponsiveDesign';
import { isMac } from '../../utils/config';
import { HideIfSmallerThan } from '../hideIfSmallerThan';
import { RightSidebarButton, RightSidebarGroup } from '../rightBar';
import { NotificationsIndicator } from './notifications';
import styles from './screenHeader.module.scss';
import { SidebarOpener, SidebarVisibility, sidebarVisibility } from './sidebar';

export function ScreenHeader({
  showSidebarOpener,
  rightSideContent,
  children,
  className,
  hideNotifications,
  collapsibleGroup,
  compensateForMacOSTrafficLights,
}: {
  showSidebarOpener?: boolean;
  rightSideContent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  hideNotifications?: boolean;
  collapsibleGroup?: RightSidebarGroup;
  compensateForMacOSTrafficLights?: 'auto' | 'always';
}) {
  const sidebar = useRecoilValue(sidebarVisibility);

  const trafficLights =
    compensateForMacOSTrafficLights === 'always' ||
    (compensateForMacOSTrafficLights === 'auto' && sidebar !== SidebarVisibility.Open);

  return (
    <div className={cn(styles.header, className)}>
      <div
        className={cn(styles.left, {
          [styles.trafficLightsMargin]: trafficLights && isMac && isElectron(),
          [styles.breadcrumbMargin]:
            compensateForMacOSTrafficLights === 'auto' && sidebar === SidebarVisibility.Open,
          [styles.showSidebarOpener]: showSidebarOpener,
        })}
      >
        {showSidebarOpener && <SidebarOpener className="mr16" />}
        {children}
      </div>
      <div className={styles.right}>
        {rightSideContent}
        {!hideNotifications && (
          <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
            {collapsibleGroup && <RightSidebarButton group={collapsibleGroup} className="mr8" />}
            <NotificationsIndicator />
          </HideIfSmallerThan>
        )}
      </div>
    </div>
  );
}
