import { capitalize, cloneDeep, isEqual } from 'lodash';
import { BaseOperation, createEditor, Descendant, Editor, Node, Transforms } from 'slate';
import { DocumentLike, Elements } from '../../shared/slate/types';
import { KitemakerElement } from './kitemakerNode';
import { withSchema } from './plugins/withSchema';
import { withOperationCapture } from './withOperationCapture';

export function emptyDocument(): Descendant[] {
  return [
    {
      type: Elements.Paragraph,
      children: [{ text: '' }],
    },
  ];
}

export function documentFromString(text: string): Descendant[] {
  return [
    {
      type: Elements.Paragraph,
      children: [{ text }],
    },
  ];
}

export function isDocumentEmpty(value: Descendant[]): boolean {
  return isEqual(value, emptyDocument());
}

export function stringifyDocument(value: Descendant[], joinCharacter = ''): string {
  return value.map(Node.string).join(joinCharacter);
}

export const defaultSelection = {
  anchor: {
    path: [0, 0],
    offset: 0,
  },
  focus: {
    path: [0, 0],
    offset: 0,
  },
};

export function normalizeDocument(document: DocumentLike): DocumentLike {
  const editor = withSchema(createEditor());
  Transforms.insertNodes(editor, document);
  Editor.normalize(editor, { force: true });

  // if we don't clone it, we end with a weird immutable type thing
  return cloneDeep(editor.children);
}

export function generateDocumentDiff(
  previousDescription: DocumentLike | null,
  description: DocumentLike
): BaseOperation[] {
  const editor = withSchema(createEditor());
  if (previousDescription) {
    Transforms.insertNodes(editor, previousDescription);
  }
  Editor.normalize(editor);

  const editorWithOperations = withOperationCapture(editor);
  Editor.withoutNormalizing(editorWithOperations, () => {
    if (previousDescription) {
      const [, startPath] = Editor.first(editorWithOperations, []);
      const [, endPath] = Editor.last(editorWithOperations, []);
      const anchor = Editor.start(editorWithOperations, startPath);
      const focus = Editor.end(editorWithOperations, endPath);
      Transforms.select(editorWithOperations, { anchor, focus });
    }
    Transforms.insertNodes(editorWithOperations, description);
  });

  return editorWithOperations.capturedOperations ?? [];
}

export function safeSelection(editor: Editor) {
  const selection = editor.selection;
  if (!selection) {
    return null;
  }
  if (
    !Editor.hasPath(editor, selection.focus.path) ||
    !Editor.hasPath(editor, selection.anchor.path)
  ) {
    return null;
  }

  return editor.selection;
}

export function flattenDocument(document: DocumentLike): DocumentLike {
  // Need to use a bunch of 'any' here because Slate's typing is pretty wonky
  return document.flatMap(node => {
    if (KitemakerElement.isElement(node)) {
      switch (node.type) {
        case Elements.Chat: {
          if (node.integrationType && node.url) {
            const chatTitle = `${capitalize(node.integrationType)} Chat`;

            return flattenDocument([
              {
                type: Elements.Paragraph,
                children: [
                  { text: '' },
                  { type: Elements.Link, url: node.url, children: [{ text: chatTitle }] },
                  { text: '' },
                ] as any,
              },
              ...node.children,
            ]);
          }

          return flattenDocument(node.children);
        }
        case Elements.ChatMessage: {
          const firstChild = {
            type: Elements.Paragraph,
            children: [{ text: `${node.sender}: `, italic: true }],
          };
          const remainingChildren = node.children;
          if (
            remainingChildren.length &&
            KitemakerElement.isElement(remainingChildren[0]) &&
            remainingChildren[0].type === Elements.Paragraph
          ) {
            firstChild.children.push(...(remainingChildren[0].children as any));
            remainingChildren.shift();
          }

          return flattenDocument([firstChild as any, ...remainingChildren]);
        }
      }
    }

    return [node];
  });
}
