import cn from 'classnames';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { Folder } from '../../../../graphql__generated__/graphql';
import { documentFromString, stringifyDocument } from '../../../shared/slate/utils';
import { filterNotNull } from '../../../shared/utils/convenience';
import { CommandGroup } from '../../commands';
import ExternalLink from '../../components/externalLink';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import {
  Button,
  ButtonSize,
  ButtonStyle,
  IconButton,
  IconButtonTrigger,
} from '../../components/new/button';
import { EntityCommandContext } from '../../components/new/commandMenuContext';
import { Count } from '../../components/new/count';
import { CustomCommand } from '../../components/new/customCommand';
import { DocumentListItem, EditDocumentListItem } from '../../components/new/documentListItem';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMenu,
  EntityListItemTitle,
} from '../../components/new/entityListItem';
import listItemStyles from '../../components/new/entityListItem.module.scss';
import { EntityTitleEditor } from '../../components/new/entityTitleEditor';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  KeyNavigationProvider,
  useClearSelection,
  useDisableKeyNavigation,
  useDisableMissingKeyNavigationElementDetection,
  useEnableKeyNavigation,
  useEnableMissingKeyNavigationElementDetection,
  useGetKeyNavigationState,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useKeyNavigationElement,
  useKeyNavigationState,
  useSetKeyNavigationFocus,
} from '../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { FolderPicker } from '../../components/new/pickers/folderPicker';
import Placeholder from '../../components/new/placeholder';
import { ScreenHeader } from '../../components/new/screenHeader';
import { Tooltip } from '../../components/new/tooltip';
import { Hotkeys } from '../../components/new/virtualizedListView/hotkeys';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { OrganizationMarker } from '../../graphql/smartLoad';
import { ResponsiveDesignSize, useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { useNewEntityModalArgs } from '../../modals/newEntityModal/newEntityModal';
import TextArea, { TextAreaHandle } from '../../slate/textArea';
import {
  useCreateDocument,
  useDeleteDocumentsAndFolders,
  useMoveDocumentsAndFoldersToFolder,
  usePartitionDocsAndFolders,
  useUpdateDocumentSorts,
} from '../../syncEngine/actions/documents';
import { useUpdateFolders, useUpdateFolderSorts } from '../../syncEngine/actions/folders';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import {
  documentPath,
  documentsSelector,
  isDocument,
  unarchivedDocumentIdsForFolderSelector,
} from '../../syncEngine/selectors/documents';
import {
  GlobalNumberWidths,
  useGlobalEntityNumberWidths,
} from '../../syncEngine/selectors/entities';
import {
  folderBreadcrumbsSelector,
  folderIdByPathSelector,
  folderIdsByParentIdSelector,
  folderPathSelector,
  folderSelector,
  foldersSelector,
  isFolder,
} from '../../syncEngine/selectors/folders';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import { trackerPageLoad } from '../../tracker';
import {
  createNewEntityFromAnywhere,
  deleteKey,
  documentsGuideURL,
  editTitleKey,
  isMobileOS,
  moveIssueKey,
} from '../../utils/config';
import { LocationState, silentlyUpdateHistoryState } from '../../utils/history';
import { metaKeyDown, modifierKeyDown } from '../../utils/keyEvents';
import { StarredType } from '../../utils/starred';
import { NotFoundScreen } from '../errorScreens';
import LoadingScreen from '../loadingScreen';
import styles from './documentsScreen.module.scss';

const FOLDERS_DROPPABLE = 'folders';
const DOCUMENTS_DROPPABLE = 'documents';
const FOLDERS_DRAGGABLE = 'folders';
const DOCUMENTS_DRAGGABLE = 'documents';
const FOLDER_DROPPABLE = 'folder-';

function Header({ folderId }: { folderId: string | null }) {
  const organization = useOrganization();
  const history = useHistory();
  const folderBreadcrumbs = useRecoilValue(
    folderBreadcrumbsSelector({ folderId, organizationPath: organizationPath(organization) })
  );
  const isSmallScreen = useIsSmallScreen();

  const modals = useModals();
  const createDocument = useCreateDocument();

  const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isMobileOS) {
      e.preventDefault();
      e.stopPropagation();
      const doc = createDocument('New document');
      history.push(documentPath(organization, doc));
      return;
    }

    modals.openModal(Modals.NewEntity);
  };

  return (
    <ScreenHeader
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={
        <>
          {isSmallScreen && (
            <Tooltip content="New document">
              <IconButton icon="add" onClick={onClick} />
            </Tooltip>
          )}
          {!isSmallScreen && (
            <>
              <Tooltip
                content={
                  <>
                    Create document <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
                  </>
                }
              >
                <Button icon="add" className="mr16" onClick={onClick}>
                  New document
                </Button>
              </Tooltip>

              <Button
                icon="folder"
                className="mr8"
                onClick={() => {
                  modals.openModal(Modals.NewFolder, {
                    parentId: folderId,
                  });
                }}
                buttonStyle={ButtonStyle.Secondary}
              >
                New folder
              </Button>
            </>
          )}
        </>
      }
    >
      <HideIfSmallerThan size={ResponsiveDesignSize.Tiny}>
        <Breadcrumbs
          breadcrumbs={folderBreadcrumbs}
          starred={{
            type: StarredType.Folder,
            id: folderId ?? `root-folder`,
          }}
        />
      </HideIfSmallerThan>
    </ScreenHeader>
  );
}

function FolderMenu({
  folder,
  onChangeName,
}: {
  folder: Folder;
  closeMenu?: () => void;
  onChangeName?: () => void;
}) {
  const { selected } = useKeyNavigationState();
  const selectedIds = selected?.includes(folder.id) ? selected : [folder.id];
  const docs = useRecoilValue(documentsSelector(selectedIds)).filter(i => isDocument(i));
  const docIds = docs.map(doc => doc.id);
  const folders = useRecoilValue(foldersSelector(selectedIds)).filter(f => isFolder(f));
  const folderIds = folders.map(folder => folder.id);

  const organization = useOrganization();
  const moveToFolder = useMoveDocumentsAndFoldersToFolder();
  const deleteDocsAndFolders = useDeleteDocumentsAndFolders();
  const toggleStarred = useToggleStarred();
  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.Folder,
      id: folder.id,
    })
  );

  return (
    <>
      {onChangeName && (
        <DropdownMenuItem icon="edit" shortcut={editTitleKey} onClick={onChangeName}>
          Rename
        </DropdownMenuItem>
      )}
      <Submenu>
        <SubmenuTrigger icon="folder" shortcut={moveIssueKey}>
          Move
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuHuge">
          <FolderPicker
            omitFolders={folderIds}
            onPicked={parentId => {
              moveToFolder(docIds, folderIds, parentId);
            }}
          />
        </SubmenuContent>
      </Submenu>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="starred"
        onClick={() => {
          toggleStarred({ type: StarredType.Folder, id: folder.id });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteDocsAndFolders(docIds, folderIds);
        }}
      >
        Delete
      </DropdownMenuItem>
    </>
  );
}

function EditFolderListItem({
  folder,
  onDone,
  style,
  isFirst,
  isLast,
}: {
  folder: Folder;
  onDone: () => void;
  style?: React.CSSProperties;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const [name, setName] = React.useState(folder.name ?? '');
  const updateFolder = useUpdateFolders();

  function save() {
    if (folder?.name !== name) {
      updateFolder([folder.id], { name });
    }
    onDone();
  }

  return (
    <EntityListItem
      entityNumber={null}
      style={style}
      className={cn(styles.listItem, {
        [styles.first]: isFirst,
        [styles.last]: isLast,
      })}
    >
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <div className={styles.folderIcon}>
            <Icon icon="folder" />
          </div>
          <EntityTitleEditor
            className={cn('headingS', listItemStyles.titleEditor)}
            initialTitle={name}
            onChange={setName}
            onSubmit={save}
            onReset={onDone}
            autoFocus
            oneLine
            placeholder={<span className="bodyM">Enter a title</span>}
          />
        </div>
      </EntityListItemMainContents>
      <div className="rowEnd ml24">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityListItem>
  );
}

function FolderListItem({
  folderId,
  index,
  isFirst,
  isLast,
  onEdit,
  onEditComplete,
  style,
}: {
  folderId: string;
  index: number;
  isFirst?: boolean;
  isLast?: boolean;
  onEdit: () => void;
  onEditComplete?: () => void;
  style?: React.CSSProperties;
}) {
  const history = useHistory();
  const organization = useOrganization();
  const ref = React.useRef<HTMLDivElement>(null);
  const folder = useRecoilValue(folderSelector(folderId));
  const path = useRecoilValue(folderPathSelector(folderId));
  const fullPath = `${organizationPath(organization, `documents/${path ?? ''}`)}`;
  const focused = useHasKeyNavigationFocus(folderId);

  useKeyNavigationElement(folderId, ref);

  if (!folder) {
    return null;
  }

  if (onEditComplete) {
    return (
      <EditFolderListItem
        folder={folder}
        onDone={onEditComplete}
        style={style}
        isFirst={isFirst}
        isLast={isLast}
      />
    );
  }

  const link = {
    pathname: fullPath,
    state: {
      folder: folderId,
    },
  };

  return (
    <Draggable draggableId={folderId} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Link to={link}>
            <Droppable droppableId={`${FOLDER_DROPPABLE}${folderId}`} type={DOCUMENTS_DRAGGABLE}>
              {(droppableProvided, snapshot) => (
                <div ref={droppableProvided.innerRef}>
                  <EntityListItem
                    entityNumber={null}
                    className={cn(styles.listItem, {
                      [styles.draggingOver]: snapshot.isDraggingOver,
                      [styles.first]: isFirst,
                      [styles.last]: isLast,
                    })}
                    ref={ref}
                    style={style}
                  >
                    <EntityListItemMainContents>
                      <div className="row">
                        <div className={styles.folderIcon}>
                          <Icon icon="folder" />
                        </div>
                        <EntityListItemTitle type="folder">{folder.name} </EntityListItemTitle>
                      </div>
                    </EntityListItemMainContents>
                    <EntityListItemMenu
                      menuContents={closeMenu => (
                        <FolderMenu folder={folder} closeMenu={closeMenu} onChangeName={onEdit} />
                      )}
                    />
                  </EntityListItem>
                </div>
              )}
            </Droppable>
            {focused && (
              <>
                <CustomCommand
                  command={{
                    id: `open-${folderId}`,
                    group: CommandGroup.Entities,
                    description: 'Open folder',
                    hotkey: 'enter',
                    handler: () => {
                      history.push(link);
                    },
                  }}
                />
                <CustomCommand
                  command={{
                    id: `rename-${folderId}`,
                    group: CommandGroup.Entities,
                    description: 'Rename folder',
                    aliases: ['Edit title'],
                    hotkey: 't',
                    handler: () => {
                      onEdit?.();
                    },
                  }}
                />
              </>
            )}
          </Link>
        </div>
      )}
    </Draggable>
  );
}

function DocListItem({
  id,
  index,
  style,
  onEdit,
  onEditComplete,
  isFirst,
  isLast,
}: {
  id: string;
  index: number;
  style?: React.CSSProperties;
  onEdit: () => void;
  onEditComplete?: () => void;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  if (onEditComplete) {
    return <EditDocumentListItem id={id} onDone={onEditComplete} style={style} />;
  }

  return (
    <Draggable draggableId={id} index={index}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <DocumentListItem
            id={id}
            style={style}
            className={cn(styles.listItem, {
              [styles.first]: isFirst,
              [styles.last]: isLast,
            })}
            onChangeTitle={onEdit}
          />
        </div>
      )}
    </Draggable>
  );
}

function FolderListClone({ folderId }: { folderId: string }) {
  const folder = useRecoilValue(folderSelector(folderId));
  if (!folder) {
    return null;
  }

  return (
    <EntityListItem entityNumber={null} className={styles.listItem}>
      <EntityListItemMainContents>
        <div className="row">
          <div className={styles.folderIcon}>
            <Icon icon="folder" />
          </div>
          <EntityListItemTitle type="folder">{folder.name} </EntityListItemTitle>
        </div>
      </EntityListItemMainContents>
    </EntityListItem>
  );
}

function FolderList({
  folderIds,
  hasDocs,
  editing,
  onEdit,
  dragCount,
}: {
  folderIds: string[];
  hasDocs: boolean;
  editing: string | null;
  onEdit: (id: string | null) => void;
  dragCount: number | null;
}) {
  const numberColumnWidth = useGlobalEntityNumberWidths(GlobalNumberWidths.Documents);

  if (!folderIds.length) {
    return null;
  }

  return (
    <div className={cn(styles.list)}>
      <Droppable
        droppableId={FOLDERS_DROPPABLE}
        type={FOLDERS_DRAGGABLE}
        renderClone={(
          provided: DraggableProvided,
          _snapshot: DraggableStateSnapshot,
          rubric: DraggableRubric
        ) => {
          const itemIndex = rubric.source.index;
          const itemId = folderIds[itemIndex];

          const { style, ...rest } = provided.draggableProps;

          return (
            <div
              id={`clone-${itemId}`}
              {...rest}
              style={{ ...style, '--number-column-width': `${numberColumnWidth}px` } as any}
              className="relative"
            >
              {(dragCount ?? 1) > 1 && <Count className={styles.dragCount} count={dragCount!} />}
              <FolderListClone folderId={itemId} />
            </div>
          );
        }}
      >
        {(provided, _snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {folderIds.map((id, index) => (
              <FolderListItem
                folderId={id}
                key={id}
                index={index}
                isFirst={index === 0}
                isLast={index === folderIds.length - 1 && !hasDocs}
                onEdit={() => {
                  onEdit(id);
                }}
                onEditComplete={
                  editing === id
                    ? () => {
                        onEdit(null);
                      }
                    : undefined
                }
                style={
                  {
                    '--number-column-width': `${numberColumnWidth}px`,
                  } as React.CSSProperties
                }
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

function DocumentList({
  docIds,
  hasFolders,
  editing,
  onEdit,
  dragCount,
}: {
  docIds: string[];
  hasFolders: boolean;
  editing: string | null;
  onEdit: (id: string | null) => void;
  dragCount: number | null;
}) {
  const numberColumnWidth = useGlobalEntityNumberWidths(GlobalNumberWidths.Documents);

  if (!docIds.length) {
    return null;
  }

  return (
    <div className={styles.list}>
      <Droppable
        droppableId={DOCUMENTS_DROPPABLE}
        type={DOCUMENTS_DRAGGABLE}
        renderClone={(
          provided: DraggableProvided,
          _snapshot: DraggableStateSnapshot,
          rubric: DraggableRubric
        ) => {
          const itemIndex = rubric.source.index;
          const itemId = docIds[itemIndex];

          const { style, ...rest } = provided.draggableProps;

          return (
            <div
              id={`clone-${itemId}`}
              {...rest}
              style={
                {
                  ...style,
                  '--number-column-width': `${numberColumnWidth}px`,
                } as any
              }
              className="relative"
            >
              {(dragCount ?? 1) > 1 && <Count className={styles.dragCount} count={dragCount!} />}
              <DocumentListItem id={itemId} />
            </div>
          );
        }}
      >
        {(provided, _snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {docIds.map((id, index) => (
              <DocListItem
                id={id}
                index={index}
                key={id}
                style={
                  {
                    '--number-column-width': `${numberColumnWidth}px`,
                  } as React.CSSProperties
                }
                isFirst={index === 0 && !hasFolders}
                isLast={index === docIds.length - 1}
                onEdit={() => {
                  onEdit(id);
                }}
                onEditComplete={
                  editing === id
                    ? () => {
                        onEdit(null);
                      }
                    : undefined
                }
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

function Context({
  docIds,
  folderIds,
  folderId,
  moveDocs,
  moveFolders,
}: {
  docIds: string[];
  folderIds: string[];
  folderId: string | null;
  moveDocs: (docIds: string[], index: number) => void;
  moveFolders: (folderIds: string[], index: number) => void;
}) {
  const modals = useModals();
  const { focused, selected } = useKeyNavigationState();
  const selectedItems = filterNotNull([focused, ...(selected ?? [])]);
  const someDocs = selectedItems.length && selectedItems.some(id => docIds.includes(id));
  const someFolders = selectedItems.length && selectedItems.some(id => folderIds.includes(id));
  const allDocs = selectedItems.length && selectedItems.every(id => docIds.includes(id));
  const allFolders = selectedItems.length && selectedItems.every(id => folderIds.includes(id));
  const mountHotKeys = !!(allDocs || allFolders);
  const history = useHistory<LocationState>();
  const organization = useOrganization();
  const setFocus = useSetKeyNavigationFocus();
  const folder = useRecoilValue(folderSelector(folderId));
  const parentPath = useRecoilValue(folderPathSelector(folder?.parentId ?? ''));
  const fullParentPath = organizationPath(organization, `documents/${parentPath}`);
  const deleteDocsAndFolders = useDeleteDocumentsAndFolders();

  const { backUrl, backSearch, backBreadcrumbs, ...rest } = history.location.state || {};

  // nonsense for making hotkeys work
  const sectionId = allDocs ? 'documents' : 'folders';
  const fakeItemsRef = React.useRef<Record<string, string[]>>({
    [sectionId]: allDocs ? docIds : folderIds,
  });
  fakeItemsRef.current = { [sectionId]: allDocs ? docIds : folderIds };
  const fakeGridRef = React.useRef<string[]>(
    (allDocs ? docIds : folderIds).map(i => `${sectionId}/${i}`)
  );
  fakeGridRef.current = (allDocs ? docIds : folderIds).map(i => `${sectionId}/${i}`);
  const fakeSectionsRef = React.useRef([sectionId]);
  fakeSectionsRef.current = [sectionId];

  useKeyNavigationColumn('documents', [...folderIds, ...docIds]);
  useNewEntityModalArgs({
    type: 'Doc',
    folderId,
    onCreated: entityId => {
      if (entityId) {
        setFocus(entityId);
      }
    },
  });

  return (
    <>
      {!someDocs && someFolders && (
        <>
          <CustomCommand
            command={{
              id: 'delete-folders',
              group: CommandGroup.Entities,
              icon: 'delete',
              description: `Delete folder${selectedItems.length > 1 ? 's' : ''}`,
              hotkey: deleteKey,
              handler: () => {
                deleteDocsAndFolders([], selectedItems);
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-folders',
              group: CommandGroup.Entities,
              icon: 'folder',
              description: `Move folder${selectedItems.length > 1 ? 's' : ''}`,
              handler: () => {
                modals.openModal(Modals.CommandMenu, {
                  view: CommandMenuView.MoveDocumentsAndFolders,
                  context: {
                    documentIds: [],
                    folderIds: selectedItems,
                  },
                });
              },
            }}
          />
        </>
      )}
      <EntityCommandContext />
      {mountHotKeys && (
        <Hotkeys
          itemsRef={fakeItemsRef}
          gridRef={fakeGridRef}
          sectionsRef={fakeSectionsRef}
          onMoveItems={(ids, _toSection, toIndex) => {
            if (allDocs) {
              moveDocs(ids, toIndex);
            }
            if (allFolders) {
              moveFolders(ids, toIndex);
            }
          }}
        />
      )}
      {parent && (
        <CustomCommand
          command={{
            id: 'go-back',
            group: CommandGroup.Navigation,
            hotkey: 'escape',
            description: 'Go back',
            priority: 10,
            handler: () => {
              const backUrl =
                history.location.state?.backUrl ||
                fullParentPath ||
                organizationPath(organization, 'documents');

              history.push({
                pathname: backUrl,
                search: backSearch,
                state: { ...rest, breadcrumbs: backBreadcrumbs },
              });
            },
          }}
        />
      )}
    </>
  );
}

function FolderTitle({ folder }: { folder: Folder }) {
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  const updateFolders = useUpdateFolders();

  const [editing, setEditing] = React.useState(false);
  const [name, setName] = React.useState('');
  const editorRef = React.useRef<TextAreaHandle | null>(null);

  const editName = React.useCallback(() => {
    updateFolders([folder.id], { name });
    setName('');
    setEditing(false);
    enableKeyNav('title');
  }, [name, setName, setEditing, updateFolders, folder.id]);

  if (!editing) {
    return (
      <div
        className="headingS semiBold fs-exclude wrap"
        onMouseDown={() => {
          setName(folder.name.trim());
          setEditing(true);
        }}
      >
        {folder.name || 'Untitled folder'}
      </div>
    );
  }

  return (
    <div className="row relative fs-exclude">
      <TextArea
        ref={editorRef}
        className={cn(styles.folderNameEditor, {
          [styles.minWidth]: !name,
        })}
        autoFocus
        oneLine
        richText={false}
        onFocus={() => {
          disableKeyNav('title');
        }}
        onBlur={() => {
          enableKeyNav('title');
          editName();
        }}
        onKeyDown={e => {
          if (e.key.toLowerCase() === 'enter' && !metaKeyDown(e) && !modifierKeyDown(e)) {
            e.preventDefault();
            editName();
            return;
          }
          if (e.key.toLowerCase() === 'escape') {
            e.preventDefault();
            e.stopPropagation();
            editorRef.current?.blur();
          }
        }}
        placeholder="Folder name"
        initialValue={documentFromString(name || '')}
        onChange={value => {
          setName(stringifyDocument(value));
        }}
      />
    </div>
  );
}

function FolderHeader({ folderId }: { folderId?: string | null }) {
  const history = useHistory<LocationState>();
  const organization = useOrganization();

  const folder = useRecoilValue(folderSelector(folderId));
  const parentPath = useRecoilValue(folderPathSelector(folder?.parentId ?? ''));

  if (!folder) {
    return null;
  }

  return (
    <Droppable
      droppableId={`${FOLDER_DROPPABLE}${folder.parentId ?? 'root'}`}
      type={DOCUMENTS_DRAGGABLE}
    >
      {(droppableProvided, snapshot) => (
        <div
          className={cn(styles.folderHeader, {
            [styles.draggingOver]: snapshot.isDraggingOver,
          })}
          ref={droppableProvided.innerRef}
        >
          <IconButton
            className="mr4"
            size={ButtonSize.Medium}
            buttonStyle={ButtonStyle.BareSubtle}
            icon="arrow_back"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              history.push(organizationPath(organization, `documents/${parentPath}`));
            }}
          />
          <Icon icon="folder" className="mr8 fs-exclude" />
          <div className="mr8">
            <FolderTitle folder={folder} />
          </div>
          <DropdownMenu>
            <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
            <DropdownMenuContent
              onClick={e => {
                e.stopPropagation();
              }}
              side="bottom"
              align="end"
            >
              <FolderMenu folder={folder} />
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </Droppable>
  );
}

function DocumentsScreenContents({
  folderId,
  folderIds,
  documentIds,
}: {
  folderId: string | null;
  folderIds: string[];
  documentIds: string[];
}) {
  const { folder: path } = useRouteMatch<{ folder?: string }>().params;
  const organization = useOrganization();
  const orgLoaded = useRecoilValue(markerState(OrganizationMarker.id(organization.id, false)));
  const history = useHistory<LocationState>();
  const getKeyNavState = useGetKeyNavigationState();
  const clearSelection = useClearSelection();
  const createDocument = useCreateDocument();
  const modals = useModals();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const updateFolderSorts = useUpdateFolderSorts();
  const updateDocSorts = useUpdateDocumentSorts();
  const moveDocsToFolder = useMoveDocumentsAndFoldersToFolder();
  const partition = usePartitionDocsAndFolders();

  const folder = useRecoilValue(folderSelector(folderId));

  const [editing, setEditing] = React.useState<string | null>(null);
  const [dragCount, setDragCount] = React.useState<number | null>(null);

  const moveFolders = useRecoilCallback(
    ({ snapshot }) =>
      async (foldersToMoveIds: string[], index: number) => {
        const allFolders = [...snapshot.getLoadable(foldersSelector(folderIds)).getValue()];
        const folders = allFolders.filter(i => !foldersToMoveIds.includes(i.id));
        const foldersToMove = allFolders.filter(i => foldersToMoveIds.includes(i.id));
        folders.splice(index, 0, ...foldersToMove);
        const previousFolderId: string | undefined = folders[index - 1]?.id;
        const nextFolderId: string | undefined = folders[index + foldersToMove.length]?.id;

        disableMissingElementDetection();
        updateFolderSorts(
          folderId,
          foldersToMove.map(i => i.id),
          previousFolderId,
          nextFolderId
        );
        enableMissingElementDetection();
      },
    [folderIds]
  );

  const moveDocs = useRecoilCallback(
    ({ snapshot }) =>
      async (docsToMoveIds: string[], index: number) => {
        const allDocs = [...snapshot.getLoadable(documentsSelector(documentIds)).getValue()];
        const docs = allDocs.filter(i => !docsToMoveIds.includes(i.id));
        const docsToMove = allDocs.filter(i => docsToMoveIds.includes(i.id));
        docs.splice(index, 0, ...docsToMove);
        const previousDocId: string | undefined = docs[index - 1]?.id;
        const nextDocId: string | undefined = docs[index + docsToMove.length]?.id;

        disableMissingElementDetection();
        updateDocSorts(
          folderId,
          docsToMove.map(i => i.id),
          previousDocId,
          nextDocId
        );
        enableMissingElementDetection();
      },
    [documentIds]
  );

  if (!orgLoaded) {
    return <LoadingScreen />;
  }

  if (path && !folder) {
    return <NotFoundScreen />;
  }

  return (
    <DragDropContext
      onBeforeDragStart={drag => {
        const { selected } = getKeyNavState();
        const sourceId = drag.draggableId;
        if (selected && selected.length && !selected.includes(sourceId)) {
          clearSelection();
        }
      }}
      onDragStart={() => {
        const { selected } = getKeyNavState();
        setDragCount(selected?.length ?? 1);
      }}
      onDragEnd={({ destination, draggableId }) => {
        if (destination?.droppableId === DOCUMENTS_DROPPABLE) {
          moveDocs([draggableId], destination.index);
        }
        if (destination?.droppableId === FOLDERS_DROPPABLE) {
          moveFolders([draggableId], destination.index);
        }
        if (destination?.droppableId.startsWith(FOLDER_DROPPABLE)) {
          const { selected } = getKeyNavState();
          const ids = selected?.includes(draggableId) ? selected : [draggableId];
          const [, folderId] = destination.droppableId.split('-');
          const { docIds, folderIds } = partition(ids);
          moveDocsToFolder(docIds, folderIds, folderId === 'root' ? null : folderId);
        }
      }}
    >
      <FolderHeader folderId={folderId} />
      {!documentIds.length && !folderIds.length && (
        <Placeholder icon={'notes'} title={`No documents yet`}>
          <span className="grayed textCenter">
            <p>
              Documents are a great place for writing long-lived design docs, meeting notes,
              changelogs and more.
              <br />
              Learn more in the{' '}
              <ExternalLink href={documentsGuideURL} className="link hoverOnly headingS">
                Kitemaker Guide.
              </ExternalLink>
            </p>
          </span>

          <Button
            icon="add"
            onClick={e => {
              if (isMobileOS) {
                e.preventDefault();
                e.stopPropagation();
                const doc = createDocument('New document');
                history.push(documentPath(organization, doc));
                return;
              }

              modals.openModal(Modals.NewEntity);
            }}
          >
            Create new document
          </Button>
        </Placeholder>
      )}
      <div
        className={cn(styles.listContainer, {
          [styles.noFolder]: !folder,
        })}
      >
        <Context
          docIds={documentIds}
          folderIds={folderIds}
          folderId={folderId}
          moveDocs={moveDocs}
          moveFolders={moveFolders}
        />
        <FolderList
          folderIds={folderIds}
          hasDocs={!!documentIds.length}
          editing={editing}
          onEdit={setEditing}
          dragCount={dragCount}
        />
        <DocumentList
          docIds={documentIds}
          hasFolders={!!folderIds.length}
          editing={editing}
          onEdit={setEditing}
          dragCount={dragCount}
        />
      </div>
    </DragDropContext>
  );
}

export function DocumentsScreen() {
  const organization = useOrganization();
  const history = useHistory<LocationState>();
  const { folder: path } = useRouteMatch<{ folder?: string }>().params;
  const orgLoaded = useRecoilValue(markerState(OrganizationMarker.id(organization.id, false)));
  const folderId = useRecoilValue(
    folderIdByPathSelector({ organizationId: organization.id, path })
  );
  const folder = useRecoilValue(folderSelector(folderId));
  const folderIds = useRecoilValue(
    folderIdsByParentIdSelector({ organizationId: organization.id, parentId: folderId })
  );
  const documentIds = useRecoilValue(
    unarchivedDocumentIdsForFolderSelector({ organizationId: organization.id, folderId })
  );

  const focusedElementId = React.useMemo(() => {
    // if we come back from the initiative screen, force the selection to where it was before
    if (history.location.state?.entity || history.location.state?.folder) {
      const { entity, folder, ...rest } = history.location.state;
      silentlyUpdateHistoryState(rest);
      return entity ?? folder;
    }

    return folderIds[0] ?? [...documentIds][0];
  }, [folderId]);

  React.useEffect(() => {
    trackerPageLoad('Documents');
  }, []);

  if (!orgLoaded) {
    return <LoadingScreen />;
  }

  if (path && !folder) {
    return <NotFoundScreen />;
  }

  return (
    <Screen key={folderId}>
      <TitleSetter title={`${organization.name} · Documents`} />
      <Header folderId={folderId} />
      <KeyNavigationProvider
        columnIds={['documents']}
        disableEnsureVisible
        multiSelect
        initiallyFocusedElementId={focusedElementId}
      >
        <DocumentsScreenContents
          folderId={folderId}
          folderIds={folderIds}
          documentIds={documentIds}
        />
      </KeyNavigationProvider>
    </Screen>
  );
}
