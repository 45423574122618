import { Operation } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { ModelManager } from '../../graphql/modelManager';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { transformCursors } from '../cursors';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { EditorType } from '../types';
import { debug } from './withDebug';

const CURSOR_COLORS = ['pink', 'red', 'orange', 'green', 'teal', 'aqua', 'blue', 'purple'];

export function withCollaboration(
  modelManager: ModelManager,
  id: string,
  type: string,
  initialVersion: number,
  options?: { enableCursors?: boolean }
) {
  return (editor: EditorType) => {
    const { apply: applyLocal, onChange } = editor;
    const operations: Operation[] = [];
    let paused = false;
    let version = initialVersion;

    editor.version = () => version;
    editor.updateVersion = r => {
      version = r;
    };

    editor.pauseOperationCollection = () => {
      paused = true;
    };
    editor.resumeOperationCollection = () => {
      paused = false;
    };

    editor.colorMap = {};
    editor.colors = CURSOR_COLORS;

    editor.getColor = (actorId: string) => {
      let color = editor.colorMap[actorId];
      if (color) {
        return color;
      }
      if (editor.colors.length === 0) {
        editor.colors = CURSOR_COLORS;
      }
      color = editor.colors.shift()!;
      editor.colorMap[actorId] = color;

      return color;
    };

    let shutdown = false;
    editor.shutdown = () => {
      shutdown = true;
      if (!operations.length) {
        return;
      }

      modelManager.applyCollaborativeDocumentOperations(
        id,
        [...operations],
        editor.children,
        type,
        version,
        {
          forceFlush: true,
        }
      );
    };

    editor.apply = (operation: Operation) => {
      applyLocal(operation);
      if (
        !paused &&
        !shutdown &&
        operation.type !== 'set_selection' &&
        !(operation.type === 'remove_text' && operation.text === '')
      ) {
        operations.push(operation);
      }
    };

    editor.onChange = () => {
      onChange();
      if (!shutdown && operations.length) {
        debug('withCollaboration - onChange ', operations.length, 'operations');
        const toApply = operations.splice(0, operations.length);
        if (options?.enableCursors) {
          const transformedCursors = transformCursors(editor.cursors(), toApply);
          editor.updateCursors(transformedCursors);
        }
        modelManager.applyCollaborativeDocumentOperations(
          id,
          toApply,
          editor.children,
          type,
          version
        );
      }
    };

    editor.resetCollaboriativeEditor = (contents, resetVersion) => {
      KitemakerTransforms.clear(editor);
      KitemakerTransforms.insertFragment(editor, contents);
      ReactEditor.deselect(editor);
      ReactEditor.blur(editor);
      version = resetVersion;
      operations.splice(0, operations.length);
    };

    return editor;
  };
}

export interface CollaborationPlugin {
  setEditor(editor: EditorType): void;
  unsetEditor(): void;
  plugin: (editor: EditorType) => EditorType;
}

export function SetCollaborativeEditor({
  withCollaboration,
}: {
  withCollaboration: CollaborationPlugin;
}) {
  const editor = useSlateStatic();
  useComponentDidMount(() => {
    if (withCollaboration) {
      withCollaboration.setEditor(editor);
    }

    return () => {
      withCollaboration?.unsetEditor();
    };
  });
  return null;
}
