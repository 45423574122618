import gql from 'graphql-tag';
import { Client } from 'urql';
import {
  BillingInfoQuery,
  BillingInfoQueryVariables,
  CheckoutSessionQuery,
  CheckoutSessionQueryVariables,
  Organization,
} from '../../../graphql__generated__/graphql';
import { toast } from '../components/toast';
import { messageFromGraphQLError } from '../graphql/errors';
import { billingInfoFragment } from '../graphql/fragments';
import { checkoutSessionQuery } from '../graphql/queries';

export type BillingInformation = BillingInfoQuery['billingInformation'];
export type BillingProduct = BillingInformation['availableProducts'][0];

export async function fetchBillingInfo(
  client: Client,
  organizationId: string,
  redirectURL: string
) {
  try {
    const result = await client
      .query<BillingInfoQuery, BillingInfoQueryVariables>(
        gql`
          query BillingInfo($organization: ID!, $redirectURL: String!) {
            billingInformation(organizationId: $organization, redirectURL: $redirectURL) {
              ...BillingInfoFragment
            }
          }
          ${billingInfoFragment}
        `,
        {
          organization: organizationId,
          redirectURL,
        }
      )
      .toPromise();

    return result.data?.billingInformation ?? null;
  } catch (e) {
    const error = messageFromGraphQLError(e.graphQLErrors) ?? 'Failed to fetch billing information';
    toast.error(error);
  }

  return null;
}

export async function checkoutSession(
  client: Client,
  organizationId: string,
  product: string,
  plan: string,
  redirectURL: string
): Promise<string> {
  try {
    const result = await client
      .query<CheckoutSessionQuery, CheckoutSessionQueryVariables>(checkoutSessionQuery, {
        organization: organizationId,
        product,
        plan,
        redirectURL,
      })
      .toPromise();

    if (result.error) {
      throw result.error;
    }

    if (!result.data) {
      throw new Error('Failed to create checkout session');
    }

    return result.data.checkoutSession.url;
  } catch (e) {
    const error = messageFromGraphQLError(e.graphQLErrors) ?? 'Failed to create checkout session';
    throw new Error(error);
  }
}

export function findProductAndPlan(
  organization: Organization,
  billingInformation: BillingInformation,
  productId?: string,
  planId?: string
) {
  const product = billingInformation.availableProducts.find(
    p => p.id === (productId ?? organization.activeProductId)
  )!;

  if (!product) {
    const freeTier = billingInformation.availableProducts.find(
      p => !p.monthlyPlan && !p.yearlyPlan
    );
    return { product: freeTier! };
  }

  if (
    product.yearlyPlan &&
    (planId ?? organization.activeProductPlanId)?.startsWith(product.yearlyPlan.id)
  ) {
    return { product, plan: { ...product.yearlyPlan, period: 'yearly' } };
  }

  if (
    product.monthlyPlan &&
    (planId ?? organization.activeProductPlanId)?.startsWith(product.monthlyPlan.id)
  ) {
    return { product, plan: { ...product.monthlyPlan, period: 'monthly' } };
  }

  return { product };
}
