import { uniqBy } from 'lodash';
import { GetRecoilValue, RecoilValueReadOnly, selectorFamily } from 'recoil';
import { filterLoadedEntities } from '../../../fe/utils/filtering2';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { initiativesByMember, issuesByAssignee } from '../../../sync/__generated/indexes';
import {
  Entity,
  Initiative,
  Issue,
  IssueStatusType,
  Todo,
  TodoStatus,
} from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { isSyncEngineObject } from '../types';
import { entitiesSelector, entitySelector, isSpaceBoundEntity } from './entities';
import { compareInitiatives, isInitiative } from './intiatives';
import { isIssue, sortIssues, statusSelector } from './issues';
import { spacesForOrganizationSelector } from './spaces';
import { currentUserTodosForEntitySelector, todosForMemberSelector } from './todos';
import { currentUserMembershipState, currentUserState } from './users';

export enum MyWorkMode {
  Assigned = 0,
  Watched = 1,
}

function filterActiveEntities(get: GetRecoilValue, entities: Entity[]): Entity[] {
  return entities.filter(entity => {
    if (isIssue(entity)) {
      const status = get(statusSelector(entity.statusId));
      return (
        status?.statusType !== IssueStatusType.Archived &&
        status?.statusType !== IssueStatusType.Done
      );
    }
    if (isInitiative(entity)) {
      return !entity.archivedAt;
    }
    return false;
  });
}

export const objectTypeSelector = selectorFamily({
  key: 'ObjectTypeSelector',
  get:
    (id: string) =>
    ({ get }) => {
      const obj = get(syncEngineState(id));
      if (!obj) {
        return null;
      }
      return isSyncEngineObject(obj) ? obj?.__typename : null;
    },
});

export const myWorkSelector: (param: {
  organizationId: string;
  filterId: string;
  visibleIds: Set<string>;
}) => RecoilValueReadOnly<{
  assignedCount: number;
  workItemsAndTodos: string[];
  initiativesAndTodos: string[];
}> = selectorFamily({
  key: 'MyWorkSelector',
  get:
    ({
      organizationId,
      filterId,
      visibleIds,
    }: {
      organizationId: string;
      filterId: string;
      visibleIds: Set<string>;
    }) =>
    ({ get }) => {
      const user = get(currentUserState);
      if (!user) {
        return {
          assignedCount: 0,
          workItemsAndTodos: [],
          initiativesAndTodos: [],
        };
      }

      const assignedIds = [
        ...get(indexKeyState(indexKey(issuesByAssignee, user.id))),
        ...get(indexKeyState(indexKey(initiativesByMember, user.id))),
      ];
      const assignedTodos = get(todosForMemberSelector(user.id)).filter(
        t => t.status !== TodoStatus.Done || visibleIds.has(t.id)
      );

      const allEntities = uniqBy(
        [
          ...get(entitiesSelector(assignedIds)),
          ...filterNotDeletedNotNull(assignedTodos.map(todo => get(entitySelector(todo.entityId)))),
        ],
        'id'
      );

      const orgMembership = get(currentUserMembershipState(organizationId));
      const unsnoozedEntities = allEntities.filter(e => {
        const snoozed = orgMembership?.snoozed?.find(s => s.id === e.id);
        if (!snoozed) {
          return true;
        }
        return snoozed.snoozedUntil < Date.now().valueOf();
      });

      const assignedEntities = filterActiveEntities(get, unsnoozedEntities);
      const filteredEntities = filterLoadedEntities(assignedEntities, filterId, get);

      const spaces = get(spacesForOrganizationSelector(organizationId));
      const spaceIds = spaces.map(s => s.id);

      const sortedWorkItems = sortIssues(
        filteredEntities.filter(e => e.__typename === 'Issue') as Issue[],
        get
      );
      const sortedInitiatives = (
        filteredEntities.filter(e => e.__typename === 'Initiative') as Initiative[]
      ).sort((a, b) => compareInitiatives(get, a, b));

      function getSmartTodosForEntity(entity: Entity): Todo[] {
        const smartTodos = get(currentUserTodosForEntitySelector(entity.id));
        const parents = new Set<string>();
        for (const todo of smartTodos) {
          if (todo.parentId && todo.status !== TodoStatus.Done) {
            parents.add(todo.parentId);
          }
        }
        return smartTodos.filter(
          t => t.status !== TodoStatus.Done || parents.has(t.id) || visibleIds.has(t.id)
        );
      }

      const sortedWorkItemsWithTodos = sortedWorkItems.flatMap(entity => [
        entity,
        ...getSmartTodosForEntity(entity),
      ]);
      const sortedInitiativesWithTodos = sortedInitiatives.flatMap(entity => [
        entity,
        ...getSmartTodosForEntity(entity),
      ]);

      return {
        assignedCount: filteredEntities.filter(
          i => isSpaceBoundEntity(i) && spaceIds.includes(i.spaceId)
        ).length,
        workItemsAndTodos: sortedWorkItemsWithTodos.map(i => i.id),
        initiativesAndTodos: sortedInitiativesWithTodos.map(i => i.id),
      };
    },
});
