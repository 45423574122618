import React from 'react';
import { useRecoilValue } from 'recoil';
import { Feedback, MemberRole } from '../../../sync/__generated/models';
import { ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import AvatarGroup from '../../components/new/metadata/avatarGroup';
import { MemberPicker } from '../../components/new/pickers/memberPicker';
import { Tooltip } from '../../components/new/tooltip';
import { useOrganization } from '../../contexts/organizationContext';
import {
  useAddOwnersToFeedback,
  useRemoveOwnersFromFeedback,
} from '../../syncEngine/actions/feedback';
import { usersAndMemberSelector } from '../../syncEngine/selectors/users';
import { assignIssueKey } from '../../utils/config';

export function FeedbackOwners({
  feedback,
  showPlaceholder,
  align,
}: {
  feedback: Feedback;
  showPlaceholder?: boolean;
  align?: 'start' | 'end';
}) {
  const organization = useOrganization();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addOwnersToFeedback = useAddOwnersToFeedback();
  const removeOwnersFromFeedback = useRemoveOwnersFromFeedback();
  const userMembers = useRecoilValue(
    usersAndMemberSelector({ organizationId: organization.id, userIds: feedback.ownerIds })
  );

  if (!showPlaceholder && !userMembers.length) {
    return null;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align={align ?? 'end'}
      >
        <MemberPicker
          term="owner"
          state={{ [feedback.id]: feedback.ownerIds }}
          onMemberAdded={(feedbackIds, userId) => {
            addOwnersToFeedback(feedbackIds, [userId]);
          }}
          onMemberRemoved={(feedbackIds, userId) => {
            removeOwnersFromFeedback(feedbackIds, [userId]);
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
      <DropdownMenuTrigger asChild>
        <div>
          {userMembers.length === 0 && (
            <Tooltip
              content={
                <>
                  Set owners <KeyboardShortcut shortcut={assignIssueKey} />
                </>
              }
            >
              <IconButton
                icon="member"
                size={ButtonSize.Small}
                buttonStyle={ButtonStyle.BareSubtle}
              />
            </Tooltip>
          )}
          <AvatarGroup
            avatarData={userMembers.map(user => ({
              name: user.name || user.username,
              img: user.avatar,
              id: user.id,
              deactivated: user.member?.deactivated,
              invited: user.member?.invited,
              guest: user.member?.role === MemberRole.Guest,
            }))}
          />
        </div>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
}
