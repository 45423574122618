import React from 'react';
import { useHistory } from 'react-router-dom';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { DocumentLike } from '../../../shared/slate/types';
import { emptyDocument } from '../../../shared/slate/utils';
import { generateId } from '../../../shared/utils/id';
import { randomString } from '../../../shared/utils/random';
import {
  releaseIssues,
  releaseMembers,
  releaseSpaces,
  releaseWatchers,
} from '../../../sync/__generated/collections';
import { collaborativeDocsByEntity } from '../../../sync/__generated/indexes';
import { CollaborativeDoc, Release, ReleaseStatus } from '../../../sync/__generated/models';
import NewEntityToastContents from '../../components/newEntityToastContents';
import { toast } from '../../components/toast';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { nextAvailableNumber } from '../../utils/entities';
import { checkTierExceededAndShowToast } from '../../utils/paywall';
import { createCollaborativeDocHelper } from './collaborativeDoc';

export type CreateReleaseProperties = {
  organizationId: string;
  title: string;
  actorId: string;
};

export type CreateReleaseOptions = {
  id?: string;
  releaseNotes?: DocumentLike;
  releaseStatus?: ReleaseStatus;
  dueDate?: Date | null;
  archivedAt?: Date | null;
  issueIds?: string[];
  spaceIds?: string[];
  suppressToast?: boolean;
};

function createReleaseHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  props: CreateReleaseProperties,
  opts?: CreateReleaseOptions
) {
  const { organizationId, title, actorId } = props;

  const release: SyncEngineCreate<Release> = {
    __typename: 'Release',
    id: opts?.id ?? generateId(),
    organizationId,
    title,
    number: nextAvailableNumber(getters, organizationId, 'release'),

    releaseStatus: opts?.releaseStatus ?? ReleaseStatus.Planned,
    dueDate: opts?.dueDate?.getTime() ?? null,
    archivedAt: opts?.archivedAt?.getTime() ?? null,
    actorId,

    memberIds: [],
    watcherIds: [],
    issueIds: [],
    spaceIds: [],
  };

  const result = tx.create<Release>(release);
  createCollaborativeDocHelper(
    tx,
    organizationId,
    opts?.releaseNotes ?? emptyDocument(),
    result.id
  );

  tx.addToCollection(releaseWatchers, result.id, [actorId]);

  if (opts?.issueIds?.length) {
    tx.addToCollection(releaseIssues, result.id, opts.issueIds);
  }
  if (opts?.spaceIds?.length) {
    tx.addToCollection(releaseSpaces, result.id, opts.spaceIds);
  }

  trackerEvent('Release Created', { id: result.id });
  if (!opts?.suppressToast) {
    toast.success(<NewEntityToastContents entityId={result.id} />);
  }

  return result;
}

export function useCreateRelease() {
  const history = useHistory();
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (title: string, opts?: CreateReleaseOptions): Release | null => {
    return modelManager.transaction((tx, getters) => {
      const payTierExceeded = checkTierExceededAndShowToast(organization, history);
      if (payTierExceeded) {
        return null;
      }

      const result = createReleaseHelper(
        tx,
        getters,
        {
          organizationId: organization.id,
          actorId: user.id,
          title,
        },
        opts
      );

      return result;
    });
  };
}

export function useUpdateReleases() {
  const modelManager = useModelManager();

  return (
    releaseIds: string[],
    rawUpdate: Omit<SyncEngineUpdateWithoutDelete<Release>, 'sort'>
  ) => {
    const update: SyncEngineUpdateWithoutDelete<Release> = { ...rawUpdate };

    modelManager.transaction((tx, { get }) => {
      for (const releaseId of releaseIds) {
        const existingRelease = get<Release>(releaseId);
        if (!existingRelease) {
          continue;
        }

        if (update.title) {
          trackerEvent('Release Updated', { id: releaseId, type: 'Title' });
        }

        if (update.dueDate) {
          trackerEvent('Release Updated', { id: releaseId, type: 'Release Date' });
        }

        if (update.releaseStatus) {
          trackerEvent('Release Updated', { id: releaseId, type: 'Release Status' });
        }

        tx.update(releaseId, update);
      }
    });
  };
}

export function useArchiveReleases() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  return (releaseIds: string[]) => {
    const update: SyncEngineUpdateWithoutDelete<Release> = { archivedAt: Date.now() };

    modelManager.transaction((tx, { get }) => {
      for (const releaseId of releaseIds) {
        const existingRelease = get<Release>(releaseId);
        if (!existingRelease) {
          continue;
        }
        if (existingRelease.archivedAt) {
          continue;
        }
        tx.update(releaseId, update);
      }
      setUndo(`Release${releaseIds.length > 1 ? 's' : ''} archived`, () => {
        modelManager.transaction(tx => {
          for (const releaseId of releaseIds) {
            tx.update<Release>(releaseId, {
              archivedAt: null,
            });
          }
        });
      });
    });
  };
}

export function useUnarchiveReleases() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  return (releaseIds: string[]) => {
    const update: SyncEngineUpdateWithoutDelete<Release> = { archivedAt: null };

    modelManager.transaction((tx, { get }) => {
      for (const releaseId of releaseIds) {
        const existingRelease = get<Release>(releaseId);
        if (!existingRelease) {
          continue;
        }
        if (!existingRelease.archivedAt) {
          continue;
        }

        tx.update(releaseId, update);
      }
      const action = 'unarchived';
      setUndo(`Release${releaseIds.length > 1 ? 's' : ''} ${action}`, () => {
        modelManager.transaction(tx => {
          for (const releaseId of releaseIds) {
            tx.update<Release>(releaseId, {
              archivedAt: Date.now(),
            });
          }
        });
      });
    });
  };
}

const deleteReleasesContext = atom<{
  onDelete?: (releases: Release[]) => void;
} | null>({
  key: 'DeleteReleasesContext',
  default: null,
});

export function useDeleteReleasesContext(callbacks: { onDelete?: (releases: Release[]) => void }) {
  const setContext = useSetRecoilState(deleteReleasesContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useDeleteReleases() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const context = useRecoilValue(deleteReleasesContext);

  return async (releaseIds: string[]): Promise<boolean> => {
    if (!releaseIds.length) {
      return false;
    }

    const confirmed = await confirm(
      `Delete release${releaseIds.length > 1 ? 's' : ''}`,
      `${
        releaseIds.length > 1 ? `These releases` : `This release`
      } will be deleted permanently. There is no way to undo this operation. Are you sure you want to proceed?`,
      {
        label: 'Delete',
        destructive: true,
      }
    );

    if (!confirmed) {
      return false;
    }

    const deleted: Release[] = [];

    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      for (const releaseId of releaseIds) {
        const release = get<Release>(releaseId);
        if (!release || release.deleted) {
          continue;
        }

        // do the actual deletion
        tx.update<Release>(releaseId, {
          deleted: true,
          title: `__deleted__${randomString(8)}`,
        });

        const docIds = getIndex(collaborativeDocsByEntity, releaseId);
        for (const docId of docIds) {
          tx.update<CollaborativeDoc>(docId, { deleted: true, content: emptyDocument() });
        }

        tx.removeFromCollection(releaseMembers, releaseId, release.memberIds);
        tx.removeFromCollection(releaseWatchers, releaseId, release.watcherIds);
        tx.removeFromCollection(releaseIssues, releaseId, release.watcherIds);
        tx.removeFromCollection(releaseSpaces, releaseId, release.watcherIds);

        deleted.push(release);
        trackerEvent('Work Item Deleted', { id: releaseId });
      }
    });

    context?.onDelete?.(deleted);
    return true;
  };
}

export function useAddIssuesToRelease() {
  const modelManager = useModelManager();
  return (releaseIds: string[], issueIds: string[]) => {
    modelManager.transaction(tx => {
      for (const releaseId of releaseIds) {
        tx.addToCollection(releaseIssues, releaseId, issueIds);
      }
    });
  };
}

export function useRemoveIssuesFromRelease() {
  const modelManager = useModelManager();
  return (releaseIds: string[], issueIds: string[]) => {
    modelManager.transaction(tx => {
      for (const releaseId of releaseIds) {
        tx.removeFromCollection(releaseIssues, releaseId, issueIds);
      }
    });
  };
}

export function useAddSpacesToReleases() {
  const modelManager = useModelManager();
  return (releaseIds: string[], spaceIds: string[]) => {
    modelManager.transaction(tx => {
      for (const releaseId of releaseIds) {
        tx.addToCollection(releaseSpaces, releaseId, spaceIds);
      }
    });
  };
}

export function useRemoveSpacesFromReleases() {
  const modelManager = useModelManager();
  return (releaseIds: string[], spaceIds: string[]) => {
    modelManager.transaction(tx => {
      for (const releaseId of releaseIds) {
        tx.removeFromCollection(releaseSpaces, releaseId, spaceIds);
      }
    });
  };
}
