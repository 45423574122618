import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { DescriptionPlaceholder } from '../../components/descriptionPlaceholder';
import Hotkey from '../../components/hotkey';
import { useOrganization } from '../../contexts/organizationContext';
import { useSearchOnce } from '../../contexts/searchContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { entitySuggestionMatcher } from '../../slate/plugins/suggestions/entitySuggestionMatcher';
import { userSuggestionMatcher } from '../../slate/plugins/suggestions/userSuggestionMatcher';
import TextArea, { TextAreaHandle } from '../../slate/textArea';
import { DocumentLike } from '../../slate/types';
import { useCreateInitiative } from '../../syncEngine/actions/intiatives';
import { useCreateIssue } from '../../syncEngine/actions/issues';
import {
  useRecentEntitiesForOrganization,
  useRecentEntitiesForSpace,
} from '../../syncEngine/selectors/entities';
import { useFilterInitiativesForSpace } from '../../syncEngine/selectors/intiatives';
import { activeUsersForOrganizationSelector } from '../../syncEngine/selectors/organizations';
import { spacesForOrganizationSelector } from '../../syncEngine/selectors/spaces';
import { useGetTodosForEntity } from '../../syncEngine/selectors/todos';
import styles from './newEntityModal.module.scss';

export function Description({
  description,
  onDescriptionChanged,
  descriptionRef,
}: {
  description: DocumentLike;
  onDescriptionChanged: (description: DocumentLike) => void;
  descriptionRef: React.RefObject<TextAreaHandle>;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const defaultOptionsForSpace = useRecentEntitiesForSpace();
  const defaultOptionsForOrganization = useRecentEntitiesForOrganization();
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const users = useRecoilValue(
    activeUsersForOrganizationSelector({
      organizationId: organization.id,
      preferSpaceId: space?.id,
    })
  );
  const user = useCurrentUser();
  const search = useSearchOnce();
  const createIssue = useCreateIssue();
  const createInitiative = useCreateInitiative();
  const filterInitiatives = useFilterInitiativesForSpace();
  const getTodos = useGetTodosForEntity();

  return (
    <div className={styles.description}>
      <Hotkey
        command={{
          id: 'edit-description',
          hotkey: 'd',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            descriptionRef.current?.focus();
            descriptionRef.current?.moveSelectionToEnd();
          },
        }}
      />
      <TextArea
        ref={descriptionRef}
        className={styles.descriptionEditor}
        initialValue={description}
        onChange={v => {
          onDescriptionChanged(v);
        }}
        richText
        disableModals
        placeholder={<DescriptionPlaceholder />}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            descriptionRef?.current?.blur();
          }
        }}
        suggestions={[
          ...entitySuggestionMatcher(
            organization,
            user,
            spaces,
            defaultOptionsForSpace,
            defaultOptionsForOrganization,
            search,
            createIssue,
            (title: string) =>
              createInitiative(title, { spaceIds: space ? [space.id] : [] }).initiative,
            initiatives => filterInitiatives(initiatives, space?.id),
            getTodos
          ),
          userSuggestionMatcher(user, users, {
            spaceId: space?.id,
            organizationId: organization.id,
          }),
        ]}
      />
    </div>
  );
}
