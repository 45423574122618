import * as React from 'react';
import { trackerPageLoad } from '../../tracker';
import { ErrorScreen } from './errorScreen';
import styles from './errorScreen.module.scss';

export function ForceVersionScreen() {
  React.useEffect(() => {
    trackerPageLoad('Force new version');
  }, []);
  return (
    <ErrorScreen fullScreen>
      <div className={styles.errorHeading}>Your version of Kitemaker is out of date</div>
      <div>
        Please{' '}
        <a
          className="link headingS"
          onClick={() => {
            window.location.reload();
          }}
        >
          click here
        </a>{' '}
        to load the latest version
      </div>
    </ErrorScreen>
  );
}
