import { isArray, sortBy } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { isURL } from 'validator';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import {
  GitLabProject,
  installWebhooks,
  listProjects,
  uninstallIntegration,
  uninstallWebhooks,
} from '../../../../api/gitlab';
import { ClipboardText } from '../../../../components/clipboardText';
import ExternalLink from '../../../../components/externalLink';
import BackButton from '../../../../components/new/backButton';
import { Button, ButtonStyle } from '../../../../components/new/button';
import { LoadingSpinner } from '../../../../components/new/loadingSpinner';
import {
  AdminButton,
  IntegrationSettingsPage,
  Setting,
  SettingsSection,
} from '../../../../components/new/settings';
import { Switch } from '../../../../components/new/switch';
import { TextValidationInput } from '../../../../components/new/textInput';
import { toast } from '../../../../components/toast';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useConfirmation } from '../../../../contexts/confirmationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { useHasAdminRights } from '../../../../contexts/userContext';
import { useComponentDidMount } from '../../../../hooks/useComponentDidMount';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { organizationPath } from '../../../../syncEngine/selectors/organizations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <>
    <p>
      You can automate your workflow, so your work items update based on your commits and merge
      requests.
    </p>
    <p>
      When you mention a work item in a commit or pull request with keywords <i>“Fixes”</i> or{' '}
      <i>“Closes”</i> (e.g. <i>“Fixes KM-123”</i>) we move it to a specified status. You can set
      Automation for each of your spaces.
    </p>
    <p>
      <ExternalLink href="https://guide.kitemaker.co/integrations-overview/github-and-gitlab">
        Read more in the Kitemaker Guide
      </ExternalLink>
    </p>
  </>
);

function Project({
  project,
  disabled,
  onToggled,
}: {
  project: GitLabProject;
  disabled: boolean;
  onToggled: () => void;
}) {
  return (
    <div className="rowBetween fullWidth">
      <span className="headingS">{project.name}</span>
      <Switch checked={project.installed} onChange={onToggled} disabled={disabled} />
    </div>
  );
}

export function EditGitLabIntegration({ id }: { id: string }) {
  const hasAdminRights = useHasAdminRights();
  const integration = useRecoilValue(integrationsSelector(id));
  const { confirm } = useConfirmation();
  const [projects, setProjects] = React.useState<GitLabProject[]>([]);
  const [projectsFetched, setProjectsFetched] = React.useState(false);
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  useComponentDidMount(() => {
    (async () => {
      const fetchedProjects = await listProjects(id);
      if (fetchedProjects && isArray(fetchedProjects)) {
        setProjects(fetchedProjects);
        setProjectsFetched(true);
      }
    })();
  });

  if (!integration) {
    return <IntegrationNotFound />;
  }

  async function uninstall() {
    const confirmed = await confirm(
      `Sure you want to uninstall?`,
      'This will uninstall the GitLab integration. You can always reinstall it at a later time.',
      { label: 'Uninstall', destructive: true }
    );

    if (!confirmed) {
      return;
    }
    try {
      await uninstallIntegration(id);
    } catch (e) {
      toast.error(
        <>
          Error uninstalling <span className="semiBold">GitLab integration</span>
        </>
      );
    }
  }

  const allProjectsInstalled = projects.every(p => p.installed);

  function updateInstalledProjects(installed: string[]) {
    setProjects(projects =>
      projects.map(project => ({ ...project, installed: installed.includes(project.id) }))
    );
  }

  const renderedProjects = sortBy(projects, p => p.name.toLowerCase()).map(project => (
    <Project
      key={project.id}
      disabled={requestInProgress}
      project={project || !hasAdminRights}
      onToggled={async () => {
        setRequestInProgress(true);
        try {
          if (project.installed) {
            const installed = await uninstallWebhooks(integration.id, [project.id]);
            updateInstalledProjects(installed);
          } else {
            const installed = await installWebhooks(integration.id, [project.id]);
            updateInstalledProjects(installed);
          }
        } catch (e) {
          toast.error(
            <>
              Error managing <span className="semiBold">GitLab installation</span>
            </>
          );
        }
        setRequestInProgress(false);
      }}
    />
  ));

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Gitlab} description={description}>
      <SettingsSection
        title="Watched projects"
        rightHeaderContent={
          <>
            {projects.length > 0 && (
              <AdminButton
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setRequestInProgress(true);

                  try {
                    if (allProjectsInstalled) {
                      const allIds = projects.map(p => p.id);
                      const installed = await uninstallWebhooks(integration.id, allIds);
                      updateInstalledProjects(installed);
                    } else {
                      const nonInstalledIds = projects.filter(p => !p.installed).map(p => p.id);
                      const installed = await installWebhooks(integration.id, nonInstalledIds);
                      updateInstalledProjects(installed);
                    }
                  } catch (e) {
                    toast.error(
                      <>
                        Error managing <span className="semiBold">GitLab installation</span>
                      </>
                    );
                  }

                  setRequestInProgress(false);
                }}
              >
                {allProjectsInstalled ? 'Watch none' : 'Watch all'}
              </AdminButton>
            )}
          </>
        }
      >
        <Setting vertical>
          {projectsFetched ? (
            renderedProjects
          ) : (
            <div className="rowCenter">
              <LoadingSpinner />
            </div>
          )}
        </Setting>
      </SettingsSection>

      <AdminButton buttonStyle={ButtonStyle.Destructive} onClick={uninstall}>
        Uninstall integration
      </AdminButton>
    </IntegrationSettingsPage>
  );
}

export function NewGitLabIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();
  const defaultPath = organizationPath(organization, 'settings/integrations');
  const [selfHosted, setSelfHosted] = React.useState(false);
  const [gitlabHost, setGitlabHost] = React.useState('');
  const [appId, setAppId] = React.useState('');
  const [appSecret, setAppSecret] = React.useState('');

  const startInstallation = React.useCallback(() => {
    const url = selfHosted
      ? `${host}/integrations/gitlab/install?organization_id=${organization.id}&selfHostedUrl=${gitlabHost}&selfHostedAppId=${appId}&selfHostedAppSecret=${appSecret}`
      : `${host}/integrations/gitlab/install?organization_id=${organization.id}`;
    externalAuthFlow(url, electronScheme, {
      redirectBaseUrl: redirectUrl,
      redirectQueryParams: {
        pendingIntegration: 'gitlab',
      },
    });
  }, [
    selfHosted,
    host,
    organization.id,
    gitlabHost,
    appId,
    appSecret,
    electronScheme,
    redirectUrl,
  ]);

  if (selfHosted) {
    return (
      <IntegrationSettingsPage integrationType={IntegrationType.Gitlab} description={description}>
        <SettingsSection largeHeader title={'Self-hosted GitLab installation'}>
          <p>
            <ol>
              <li>
                Create a GitLab app with the <b>api</b> permission and the callback url: <br />
                <ClipboardText content={`${host}/integrations/gitlab/setup`} contentName="URL">
                  <pre>{host}/integrations/gitlab/setup</pre>
                </ClipboardText>
              </li>
              <li>Fill in the fields below</li>
              <li>
                Setting up automation to automatically move {issueTerm}s in Kitemaker based on
                events in GitLab
              </li>
            </ol>
          </p>
          <p>
            <em>
              Note: Kitemaker has access to your commits in order to read the commit messages. It
              will never read your actual codebase.
            </em>
          </p>
        </SettingsSection>

        <SettingsSection>
          <Setting title="Gitlab Host">
            <TextValidationInput
              validate={v => (isURL(v) ? null : 'Invalid URL')}
              onChange={e => setGitlabHost(e.target.value)}
              placeholder="https://gitlab.kitemaker.com"
            />
          </Setting>

          <Setting title="Application ID">
            <TextValidationInput
              validate={v => (v.length > 0 ? null : 'Application ID cannot be empty')}
              onChange={e => setAppId(e.target.value)}
              placeholder="96eb156781bb3388..."
            />
          </Setting>

          <Setting title="Application secret">
            <TextValidationInput
              validate={v => (v.length > 0 ? null : 'Application secret cannot be empty')}
              onChange={e => setAppSecret(e.target.value)}
              placeholder="96eb156781bb3388..."
            />
          </Setting>
        </SettingsSection>

        <SettingsSection>
          <div className="rowBetween">
            <BackButton defaultPath={defaultPath}>Back</BackButton>
            <div className="rowEnd">
              <Button className="mr16" onClick={() => setSelfHosted(false)}>
                Hosted GitLab
              </Button>
              <Button buttonStyle={ButtonStyle.Primary} onClick={() => startInstallation()}>
                Install self-hosted GitLab integration
              </Button>
            </div>
          </div>
        </SettingsSection>
      </IntegrationSettingsPage>
    );
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Gitlab} description={description}>
      <SettingsSection>
        <Button className="mr16" onClick={() => setSelfHosted(true)}>
          Self-hosted GitLab
        </Button>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={() => startInstallation()}>
          Install GitLab integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
