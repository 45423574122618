import localForage from 'localforage';
import { CACHE_VERSION } from '../../shared/utils/version';

let configured = false;

export function cache(): LocalForage | null {
  try {
    if (!configured) {
      localForage.config({
        driver: localForage.INDEXEDDB,
        name: 'kitemaker',
        version: CACHE_VERSION,
        storeName: 'offline_cache',
        description: `Stores Kitemaker's data offline for a faster refresh experience`,
      });
      configured = true;
    }

    return localForage;
  } catch (e) {
    return null;
  }
}
