import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { CodeReviewRequest, CodeReviewRequestState } from '../../../sync/__generated/models';
import { syncEngineState } from '../state';
import { issueSelector } from './issues';
import { todoSelector } from './todos';

function scoreCodeReviewRequestState(state: CodeReviewRequestState): number {
  switch (state) {
    case CodeReviewRequestState.Open:
      return 0;
    case CodeReviewRequestState.Locked:
      return 1;
    case CodeReviewRequestState.Merged:
      return 2;
    case CodeReviewRequestState.Closed:
      return 3;
  }
}

function sort(request1: CodeReviewRequest, request2: CodeReviewRequest) {
  const state1 = scoreCodeReviewRequestState(request1.state);
  const state2 = scoreCodeReviewRequestState(request2.state);

  if (state1 < state2) {
    return -1;
  }
  if (state1 > state2) {
    return 1;
  }

  if (request1.details.number < request2.details.number) {
    return -1;
  }
  if (request1.details.number > request2.details.number) {
    return 1;
  }

  return 0;
}

export const codeReviewsForIssueSelector = selectorFamily({
  key: 'CodeReviewsForIssue',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      if (!issueId) {
        return [];
      }
      const codeReviewIds = get(issueSelector(issueId))?.codeReviewRequestIds ?? [];
      return filterNotDeletedNotNull(
        codeReviewIds.map(
          codeReviewId => get(syncEngineState(codeReviewId)) as CodeReviewRequest | null
        )
      ).sort(sort);
    },
});

export const codeReviewsForTodoSelector = selectorFamily({
  key: 'CodeReviewsForTodo',
  get:
    (todoId: string | undefined) =>
    ({ get }) => {
      if (!todoId) {
        return [];
      }
      const codeReviewIds = get(todoSelector(todoId))?.codeReviewRequestIds ?? [];
      return filterNotDeletedNotNull(
        codeReviewIds.map(
          codeReviewId => get(syncEngineState(codeReviewId)) as CodeReviewRequest | null
        )
      ).sort(sort);
    },
});
