import cn from 'classnames';
import * as React from 'react';
import { isSafari } from '../../utils/config';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../utils/search';
import styles from './filteredListView.module.scss';
import { ListView, ListViewItem, ListViewProps } from './listView';
import menuStyles from './menu/menu.module.scss';
import { TextInput, TextInputType } from './textInput';

export interface FilteredListViewProps<T extends ListViewItem> extends ListViewProps<T> {
  placeholder?: string;
  filterLabel?: string;
  filterPlaceholder?: string;
  propertiesToSearch?: string[] | Array<{ name: string; weight: number }>;
  filter?: (filterString: string, search: FuzzySearcher<T>) => T[];
  onFilterChanged?: (filterString: string) => void;
  filterClassName?: string;
  placeholderClassName?: string;
  maxItems?: number;
  defaultItems?: string[];
  alwaysShownItems?: T[];
  populate?: (items: T[], alwaysShownItems: T[], filter: string) => T[];
  fuzzySearchConfiguration?: FuzzySearcherConfiguration;
  inputType?: TextInputType;
  disableAutoFocus?: boolean;
}

export interface FilteredListViewHandle {
  clear(): void;
}

export function Filter({
  filter,
  onFilterChange,
  placeholder,
  className,
  inputType = TextInputType.Bare,
  disableAutoFocus,
}: {
  filter: string;
  onFilterChange: (filter: string) => void;
  placeholder?: string;
  className?: string;
  inputType?: TextInputType;
  disableAutoFocus?: boolean;
}) {
  const filterRef = React.useRef<HTMLInputElement | null>(null);
  const leftKeyHitRef = React.useRef<boolean>(false);

  React.useEffect(() => {
    const resolvedFilterRef = filterRef.current;
    setTimeout(() => {
      if (!disableAutoFocus) {
        resolvedFilterRef?.focus();
      }
    }, 0);
    return () => resolvedFilterRef?.blur();
  }, []);

  return (
    <TextInput
      inputType={inputType}
      // hack to keep focus from getting stolen by radix
      onBlur={e => {
        if ((e?.relatedTarget as any)?.dataset?.state === 'open' && !leftKeyHitRef.current) {
          filterRef.current?.focus();
        }
        leftKeyHitRef.current = false;
      }}
      // For some reason, if we give this text input the ID password, Safari will stop trying to
      // autofill user names
      id={isSafari ? 'password' : ''}
      ref={filterRef}
      className={cn(styles.filter, className)}
      placeholder={placeholder}
      value={filter}
      autoComplete="off"
      onChange={e => onFilterChange(e.currentTarget.value)}
      onKeyDown={e => {
        if (e.key === 'Tab') {
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault();
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
        }
        if (e.key === 'ArrowLeft') {
          leftKeyHitRef.current = true;
          if (filterRef.current?.value !== '') {
            e.stopPropagation();
          }
        }
      }}
      spellCheck={false}
    />
  );
}

function FilteredListViewComponent<T extends ListViewItem>(
  {
    filter,
    items,
    className,
    filterLabel,
    filterPlaceholder,
    placeholder,
    propertiesToSearch,
    filterClassName,
    placeholderClassName,
    maxItems,
    defaultItems,
    alwaysShownItems,
    onFilterChanged,
    selectFirstOnItemsChanged,
    populate,
    fuzzySearchConfiguration,
    inputType,
    disableAutoFocus,
    ...rest
  }: FilteredListViewProps<T>,
  ref: any
) {
  const [filterString, setFilterString] = React.useState('');
  const defaults = defaultItems?.length
    ? (defaultItems.map(id => items.find(i => i.id === id)).filter(item => !!item) as T[])
    : items;
  const [results, setResults] = React.useState(defaults);
  React.useImperativeHandle<FilteredListViewHandle, FilteredListViewHandle>(ref, () => ({
    clear: () => {
      setFilterString('');
      onFilterChanged?.('');
    },
  }));

  const search = React.useMemo(() => {
    return new FuzzySearcher<T>(
      fuzzySearchConfiguration ?? FuzzySearcherConfiguration.Autocomplete,
      propertiesToSearch ?? [],
      items as T[]
    );
  }, [items, propertiesToSearch]);

  React.useEffect(() => {
    if (!filterString) {
      setResults(defaults);
      return;
    }

    if (filter) {
      setResults(filter(filterString, search));
    } else {
      setResults(search.search(filterString).map(result => result.item));
    }
  }, [filterString, search]);

  let shownItems;
  if (populate) {
    shownItems = populate(results, alwaysShownItems ?? [], filterString);
  } else {
    shownItems = [...results, ...(alwaysShownItems ?? [])];
  }

  return (
    <>
      <Filter
        inputType={inputType}
        filter={filterString}
        onFilterChange={f => {
          setFilterString(f);
          onFilterChanged?.(f);
        }}
        placeholder={filterPlaceholder}
        className={filterClassName}
        disableAutoFocus={disableAutoFocus}
      />
      <div className={menuStyles.separator} />
      <ListView
        selectFirstOnItemsChanged={filterString.length > 0 || selectFirstOnItemsChanged}
        {...rest}
        items={shownItems.slice(0, maxItems)}
        className={className}
      />
      {!results.length && placeholder && (
        <div className={placeholderClassName ?? ''}>{placeholder}</div>
      )}
    </>
  );
}

// TODO: Tis should support generics
export const FilteredListView = React.forwardRef(FilteredListViewComponent);
