import debugModule from 'debug';
import * as React from 'react';
import { parseBoolean } from '../../shared/utils/utils';
import { useSpace } from './spaceContext';

export const localStorageOpenInAppKey = '__openInApp';
const localStorageCollapsedKey = '__collapsedStatuses_';
const localStorageLastSearchKey = '__lastSearch_';
const localStorageWideColumnsKey = '__wideColumns';
const localStorageAutoHideSideBarKey = '__autoHideSideBar';
const localStorageRightBarWidthLegacyKey = 'rightBarWidth';
const localStorageRightBarWidthKey = '__rightBarWidth';

const debug = debugModule('usePersistentSettings');

function collapsedColumnsFromLocalStorage(spaceId: string) {
  const fromLocalStorage = localStorage.getItem(`${localStorageCollapsedKey}${spaceId}`);
  return new Set((fromLocalStorage ?? '').split(',').filter(s => !!s));
}

interface PersistentSpaceSettings {
  collapsedColumns: Set<string>;
  uncollapse(statusId: string): void;
  toggleCollapsed(statusId: string): void;
  clearCollapsedColumns(): void;
  lastSearch: string;
  setLastSearch(search: string): void;
}

const persistentSpaceSettingsContext = React.createContext<PersistentSpaceSettings | null>(null);

export function PersistentSpaceSettingsProvider({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}) {
  const space = useSpace();

  const [collapsedColumns, setCollapsedColumns] = React.useState(
    collapsedColumnsFromLocalStorage(space.id)
  );

  const [lastSearch, _setLastSearch] = React.useState(
    localStorage.getItem(`${localStorageLastSearchKey}${space.id}`) ?? ''
  );

  function clearCollapsedColumns() {
    localStorage.removeItem(`${localStorageCollapsedKey}${space.id}`);
    setCollapsedColumns(new Set());
  }

  function uncollapse(statusId: string) {
    const newCollapsedColumns = new Set([...collapsedColumns]);

    newCollapsedColumns.delete(statusId);
    if (newCollapsedColumns.size === 0) {
      clearCollapsedColumns();
    } else {
      localStorage.setItem(
        `${localStorageCollapsedKey}${space.id}`,
        [...newCollapsedColumns].join(',')
      );
      setCollapsedColumns(newCollapsedColumns);
    }
  }

  function toggleCollapsed(statusId: string) {
    const newCollapsedColumns = new Set([...collapsedColumns]);

    if (newCollapsedColumns.has(statusId)) {
      uncollapse(statusId);
      return;
    }
    newCollapsedColumns.add(statusId);
    localStorage.setItem(
      `${localStorageCollapsedKey}${space.id}`,
      [...newCollapsedColumns].join(',')
    );
    setCollapsedColumns(newCollapsedColumns);
  }

  function setLastSearch(search: string) {
    _setLastSearch(search);
    localStorage.setItem(`${localStorageLastSearchKey}${space.id}`, search);
  }

  const value = React.useMemo(
    () => ({
      collapsedColumns,
      clearCollapsedColumns,
      uncollapse,
      toggleCollapsed,
      lastSearch,
      setLastSearch,
    }),
    [collapsedColumns, lastSearch]
  );

  return (
    <persistentSpaceSettingsContext.Provider value={value}>
      {children}
    </persistentSpaceSettingsContext.Provider>
  );
}

export function usePersistentSpaceSettings() {
  const context = React.useContext(persistentSpaceSettingsContext)!;
  return context;
}

interface PersistentSettings {
  wideColumns: boolean;
  toggleWideColumns(): void;
  openInApp: boolean | null;
  setOpenInApp(openInApp: boolean): void;
  autoHideSideBar: boolean;
  toggleAutoHideSideBar(): void;
  rightBarWidth: number;
  setRightBarWidth(width: number): void;
}

const persistentSettingsContext = React.createContext<PersistentSettings | null>(null);

export function PersistentSettingsProvider({ children }: { children: React.ReactNode }) {
  const [wideColumns, setWideColumns] = React.useState(
    !!localStorage.getItem(localStorageWideColumnsKey)
  );
  function toggleWideColumns() {
    if (!wideColumns) {
      localStorage.setItem(localStorageWideColumnsKey, 'true');
    } else {
      localStorage.removeItem(localStorageWideColumnsKey);
    }
    setWideColumns(!wideColumns);
  }

  const [openInApp, _setOpenInApp] = React.useState(() => {
    const setting = localStorage.getItem(localStorageOpenInAppKey);
    if (!setting) {
      return null;
    }
    return parseBoolean(setting);
  });

  function setOpenInApp(openInApp: boolean) {
    localStorage.setItem(localStorageOpenInAppKey, `${openInApp}`);
    _setOpenInApp(openInApp);
  }

  const [autoHideSideBar, setAutoHideSideBar] = React.useState(
    !!localStorage.getItem(localStorageAutoHideSideBarKey)
  );
  function toggleAutoHideSideBar() {
    if (!autoHideSideBar) {
      localStorage.setItem(localStorageAutoHideSideBarKey, 'true');
    } else {
      localStorage.removeItem(localStorageAutoHideSideBarKey);
    }
    setAutoHideSideBar(!autoHideSideBar);
  }

  const widthFromStorage = React.useMemo(() => {
    const localStorageWidth =
      localStorage.getItem(localStorageRightBarWidthKey) ??
      localStorage.getItem(localStorageRightBarWidthLegacyKey);
    if (localStorageWidth) {
      try {
        const width = parseInt(localStorageWidth, 10);
        return width;
      } catch (e) {
        debug('Invalid right bar width from local storage', e);
      }
    }

    return 0;
  }, []);

  const [rightBarWidth, _setRightBarWidth] = React.useState(widthFromStorage);
  function setRightBarWidth(width: number) {
    _setRightBarWidth(width);
    localStorage.setItem(localStorageRightBarWidthKey, `${width}`);
  }

  return (
    <persistentSettingsContext.Provider
      value={{
        wideColumns,
        toggleWideColumns,
        openInApp,
        setOpenInApp,
        autoHideSideBar,
        toggleAutoHideSideBar,
        rightBarWidth,
        setRightBarWidth,
      }}
    >
      {children}
    </persistentSettingsContext.Provider>
  );
}

export function usePersistentSettings() {
  const context = React.useContext(persistentSettingsContext)!;
  return context;
}
