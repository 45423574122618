import { DocumentLike } from '../slate/types';

export interface DocumentModification {
  original: DocumentLike;
  document: DocumentLike;
}

export async function translate(
  organizationId: string,
  document: DocumentLike
): Promise<DocumentModification> {
  const response = await fetch(`/ai/${organizationId}/translate`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      document,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to translate');
  }

  const result = responseJson.data as DocumentModification;

  return result;
}

export async function fixText(
  organizationId: string,
  document: DocumentLike
): Promise<DocumentModification> {
  const response = await fetch(`/ai/${organizationId}/fix`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      document,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to fix spelling and grammar');
  }

  const result = responseJson.data as DocumentModification;

  return result;
}

export async function improveText(
  organizationId: string,
  document: DocumentLike
): Promise<DocumentModification> {
  const response = await fetch(`/ai/${organizationId}/improve`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      document,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to improve text');
  }

  const result = responseJson.data as DocumentModification;

  return result;
}

export async function shortenText(
  organizationId: string,
  document: DocumentLike
): Promise<DocumentModification> {
  const response = await fetch(`/ai/${organizationId}/shorten`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      document,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to shorten text');
  }

  const result = responseJson.data as DocumentModification;

  return result;
}

export async function createTitle(organizationId: string, content: string): Promise<string> {
  const response = await fetch(`/ai/${organizationId}/createTitle`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      content,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to create title');
  }

  const result = responseJson.data.title;

  return result;
}

export async function generateChangelog(
  organizationId: string,
  workItemTitles: string[]
): Promise<string> {
  const response = await fetch(`/ai/${organizationId}/generateChangelog`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      workItemTitles,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to generate changelog');
  }

  const result = responseJson.data.changelog;

  return result;
}

export async function createTaskTitle(organizationId: string, content: string): Promise<string[]> {
  const response = await fetch(`/ai/${organizationId}/createTaskTitle`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ content }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to create task title');
  }

  const result = responseJson.data.title.split('\n');

  return result;
}

export async function mergeWorkItems(
  organizationId: string,
  workItems: { title: string; description: string }[]
): Promise<any> {
  const response = await fetch(`/ai/${organizationId}/mergeWorkItems`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      workItems,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to merge work items');
  }
}
