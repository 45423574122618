import * as React from 'react';
import { Icon, IconSize } from '../icon';
import { Tooltip } from '../tooltip';
import Pill from './pill';

export default function Watching({ noBorder }: { noBorder?: boolean }) {
  return (
    <Tooltip side="bottom" align="start" content="Watching">
      <Pill noBorder={noBorder} iconOnly noHover>
        <Icon size={IconSize.Size16} icon="watch" />
      </Pill>
    </Tooltip>
  );
}
