import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import { isEqual, range } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Range } from 'slate';
import {
  RenderElementProps,
  useFocused,
  useReadOnly,
  useSelected,
  useSlateSelector,
  useSlateStatic,
} from 'slate-react';
import uuid from 'uuid';
import { Elements, FormatHoverMode, SmartTodoElement } from '../../../../shared/slate/types';
import {
  CodeReviewRequestState,
  IntegrationType,
  Todo,
  TodoStatus,
} from '../../../../sync/__generated/models';
import { integrationTypeToExternalIssueType } from '../../../api/externalIssues';
import { ButtonSize, ButtonStyle, IconButton } from '../../../components/new/button';
import { EntityDueDate } from '../../../components/new/dueDateMetadata';
import { EntityCycleTooltip } from '../../../components/new/entityMetadata';
import { CycleMenu } from '../../../components/new/menu/cycleMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import { EffortMenu } from '../../../components/new/menu/effortMenu';
import { ImpactMenu } from '../../../components/new/menu/impactMenu';
import AvatarGroup from '../../../components/new/metadata/avatarGroup';
import CodeReviewRequest, {
  CodeReviewTooltipContents,
} from '../../../components/new/metadata/codeReviewRequest';
import Cycle from '../../../components/new/metadata/cycle';
import Effort from '../../../components/new/metadata/effort';
import Impact from '../../../components/new/metadata/impact';
import { MetadataSize } from '../../../components/new/metadata/size';
import { MemberPicker } from '../../../components/new/pickers/memberPicker';
import { MetadataTooltip, Tooltip } from '../../../components/new/tooltip';
import { useCurrentUser, useMaybeCurrentUser } from '../../../contexts/userContext';
import { useAddTodosToCycle, useRemoveTodosFromCycle } from '../../../syncEngine/actions/cycles';
import { useToggleTodos, useUpdateTodos } from '../../../syncEngine/actions/todos';
import { codeReviewsForTodoSelector } from '../../../syncEngine/selectors/codeReviewRequests';
import {
  currentCycleSelector,
  cycleSelector,
  todoInCycleSelector,
} from '../../../syncEngine/selectors/cycles';
import { effortSelector } from '../../../syncEngine/selectors/effortLevels';
import {
  entityKeySelector,
  spaceForEntitySelector,
  useEntityPath,
} from '../../../syncEngine/selectors/entities';
import { impactSelector } from '../../../syncEngine/selectors/impactLevels';
import {
  todoKeySelector,
  todoKeyWidthSelector,
  todoSelector,
} from '../../../syncEngine/selectors/todos';
import { usersSelector } from '../../../syncEngine/selectors/users';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { useOnKeyDownHandler } from '../../onKeyDownHandlers/useKeyDownHandler';
import { useDragAndDrop } from '../../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../../types';
import { DummyNode } from '../dummyNode';
import styles from './smartTodo.module.scss';
import { CheckboxIcon } from './smartTodoCheckbox';
import {
  copyNumberToClipboard,
  onLabelAdded,
  onLabelRemoved,
  onMemberAdded,
  onMemberRemoved,
} from './smartTodoHelpers';
import { SmartTodoMenu } from './smartTodoMenu';
import { useSmartTodoHotkeys } from './useSmartTodoHotkeys';

export function StaticSmartTodo({
  element,
  attributes,
  children,
}: OptionalAttributesRenderElementProps & { element: SmartTodoElement }) {
  const history = useHistory();
  const todo = useRecoilValue(todoSelector(element.todoId));
  const impact = useRecoilValue(impactSelector(todo?.impactId));
  const effort = useRecoilValue(effortSelector(todo?.effortId));
  const toggleTodos = useToggleTodos('Static');
  const entityPath = useEntityPath();
  const key = useRecoilValue(todoKeySelector(todo?.id));
  const todoKeyWidth = useRecoilValue(todoKeyWidthSelector(todo?.id));

  if (!todo || !key) {
    return (
      <DummyNode attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  const indent = todo.indent;
  const checked = todo.status === TodoStatus.Done;

  const link = {
    pathname: entityPath(todo.entityId)!,
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: todo.entityId,
    },
    search: `focusDescription=true&focusSmartTodo=${todo.id}`,
  };

  const childNode = range(indent).reduce(
    result => <ul className="overflowHidden">{result}</ul>,
    <li className="rowAlignStart overflowHidden">
      <div className="row noGrow relative">
        <div contentEditable={false} className={cn(styles.controlContainer, 'mr8')}>
          <CheckboxIcon
            status={todo.status}
            linkedToEntity={!!todo.connectedEntityId}
            linkedToExternalIssue={!!todo.connectedExternalIssueId}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (e.altKey) {
                toggleTodos([todo.id], true);
              } else {
                toggleTodos([todo.id]);
              }
            }}
          />
        </div>
      </div>
      <div className={cn(styles.todoText, styles.static)}>{children}</div>
      <div
        className="row noGrow unselectable metadataGap metadataHeightL ml8 "
        contentEditable={false}
      >
        <TodoCycleIndicator todo={todo} />
        <TodoCodeReviewRequests todoId={todo.id} />
        {impact !== null && (
          <Impact
            color={impact.color}
            size={MetadataSize.Small}
            longName={impact.name}
            shortName={impact.abbrevation}
          />
        )}
        {effort !== null && (
          <Effort
            color={effort.color}
            size={MetadataSize.Small}
            longName={effort.name}
            shortName={effort.abbrevation}
          />
        )}
        <TodoMembers className={styles.avatars} todoId={todo.id} memberIds={todo.memberIds} />
      </div>
    </li>
  );

  return (
    <ul
      className={cn('block', 'listBlock', styles.smartTodo, 'row', {
        [styles.todoChecked]: checked,
      })}
      onClick={(e: React.MouseEvent<HTMLUListElement>) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(link);
      }}
      {...attributes}
    >
      <div
        style={{
          width: `${todoKeyWidth}ch`,
          textAlign: 'right',
        }}
        className={cn(styles.key, 'mr2')}
        contentEditable={false}
      >
        {todo.key.toLocaleLowerCase()}
      </div>
      <div className={cn(styles.contents, 'row', 'overflowHidden')}>{childNode}</div>
    </ul>
  );
}

export function TodoCodeReviewRequests({ todoId }: { todoId: string }) {
  const codeReviews = useRecoilValue(codeReviewsForTodoSelector(todoId));
  if (!codeReviews.length) {
    return null;
  }

  const hasOpen = codeReviews.find(review => review.state === CodeReviewRequestState.Open);
  const hasMerged = codeReviews.find(review => review.state === CodeReviewRequestState.Merged);

  if (!hasOpen && !hasMerged) {
    return null;
  }

  let state = CodeReviewRequestState.Closed;
  if (hasMerged) {
    state = CodeReviewRequestState.Merged;
  }
  if (hasOpen) {
    state = CodeReviewRequestState.Open;
  }

  return (
    <Tooltip
      side="bottom"
      align="start"
      className={styles.tooltip}
      content={<CodeReviewTooltipContents codeReviews={codeReviews} />}
    >
      <div>
        <CodeReviewRequest size={MetadataSize.Small} state={state} />
      </div>
    </Tooltip>
  );
}

export function TodoImpact({ todo }: { todo: Todo }) {
  const updateTodos = useUpdateTodos();
  const impact = useRecoilValue(impactSelector(todo?.impactId));

  if (!impact) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Impact
          color={impact.color}
          size={MetadataSize.Small}
          longName={impact.name}
          shortName={impact.abbrevation}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuTiny"
      >
        <ImpactMenu
          onSelect={impactId => updateTodos([todo.id], { impactId })}
          impactId={todo.impactId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function TodoEffort({ todo }: { todo: Todo }) {
  const updateTodos = useUpdateTodos();
  const effort = useRecoilValue(effortSelector(todo?.effortId));
  if (!effort) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Effort
          color={effort.color}
          size={MetadataSize.Small}
          longName={effort.name}
          shortName={effort.abbrevation}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuTiny"
      >
        <EffortMenu
          onSelect={effortId => updateTodos([todo.id], { effortId })}
          effortId={todo.effortId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function TodoCycleIndicator({ todo, size }: { todo: Todo; size?: MetadataSize }) {
  const space = useRecoilValue(spaceForEntitySelector(todo.entityId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const [hoverOpen, setHoverOpen] = React.useState(false);
  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  const inCurrentCycle = useRecoilValue(
    todoInCycleSelector({ cycleId: space?.activeCycleId ?? '', todoId: todo.id })
  );
  const inUpcomingCycle = useRecoilValue(
    todoInCycleSelector({ cycleId: space?.upcomingCycleId ?? '', todoId: todo.id })
  );
  const currentCycle = useRecoilValue(currentCycleSelector(space?.id));
  const upcomingCycle = useRecoilValue(cycleSelector(space?.upcomingCycleId));

  const removeTodosFromCycle = useRemoveTodosFromCycle();
  const addTodosToCycle = useAddTodosToCycle();

  let cycle = null;
  if (inCurrentCycle) {
    cycle = currentCycle;
  } else if (inUpcomingCycle) {
    cycle = upcomingCycle;
  }

  if (!cycle || !space) {
    return null;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>
          <MetadataTooltip
            disabled={menuOpen}
            open={hoverOpen}
            side="bottom"
            align="start"
            className={cn(styles.cycleTooltip)}
            onOpenChange={setHoverOpen}
            content={<EntityCycleTooltip cycle={cycle} />}
          >
            <div>
              <Cycle size={size} cycleStatus={cycle.cycleStatus} name={cycle.title} />
            </div>
          </MetadataTooltip>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuHuge"
      >
        <CycleMenu
          inCurrentCycle={inCurrentCycle}
          inUpcomingCycle={inUpcomingCycle}
          spaceId={space.id}
          onSelect={(cycleId: string | null) => {
            if (cycleId === null) {
              if (inUpcomingCycle && space.upcomingCycleId) {
                removeTodosFromCycle([todo.id], space.upcomingCycleId);
              }
              if (inCurrentCycle && space.activeCycleId) {
                removeTodosFromCycle([todo.id], space.activeCycleId);
              }
              closeMenu();
              return;
            }
            if (cycleId === space.activeCycleId && !inCurrentCycle) {
              addTodosToCycle([todo.id], cycleId);
            }
            if (cycleId === space.upcomingCycleId && !inUpcomingCycle) {
              addTodosToCycle([todo.id], cycleId);
            }
            closeMenu();
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function TodoMembersComponent({
  todoId,
  memberIds,
  className,
  onMemberAdded,
  onMemberRemoved,
}: {
  todoId: string;
  memberIds: string[];
  className?: string;
  onMemberAdded?: (memberId: string) => void;
  onMemberRemoved?: (memberId: string) => void;
}) {
  const user = useMaybeCurrentUser();
  const members = useRecoilValue(usersSelector(memberIds ?? [])).filter(u => u.id !== user?.id);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  // current user always comes first
  if (user && memberIds.includes(user.id)) {
    members.unshift(user);
  }

  if (!memberIds) {
    Sentry.captureMessage('Found smart todo without members', { extra: { todoId } });
  }

  const memberAvatars = (
    <AvatarGroup
      className={className}
      max={2}
      avatarData={members.map(member => ({
        name: member.name || member.username,
        img: member.avatar,
        id: member.id,
      }))}
    />
  );

  if (!onMemberAdded || !onMemberRemoved) {
    return members.length > 0 ? memberAvatars : null;
  }

  const contents =
    members.length > 0 ? (
      memberAvatars
    ) : (
      <div className={cn(styles.membersPlaceholder, { [styles.showOnHover]: !menuOpen })}>
        <Tooltip content={'Set members'}>
          <IconButton size={ButtonSize.Small} buttonStyle={ButtonStyle.Bare} icon="member" />
        </Tooltip>
      </div>
    );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {contents}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
        className="menuPicker menuMedium"
      >
        <MemberPicker
          state={{ todo: memberIds }}
          onMemberAdded={(_, memberId) => {
            onMemberAdded(memberId);
          }}
          onMemberRemoved={(_, memberId) => {
            onMemberRemoved(memberId);
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export const TodoMembers = React.memo(
  TodoMembersComponent,
  (prevProps, newProps) =>
    prevProps.todoId === newProps.todoId && isEqual(prevProps.memberIds, newProps.memberIds)
);

function TodoKeyComponent({
  todoLetter,
  entityKey: entityKey,
  selected,
}: {
  todoLetter: string;
  entityKey: string;
  selected: boolean;
}) {
  return (
    <div
      className={cn(styles.keyContainer, {
        [styles.selected]: selected,
      })}
      data-dnd-ignore="true"
      data-slate-no-copy="true"
    >
      <Tooltip
        noPortal
        asChild
        side="right"
        content={
          <div className={styles.keyTooltip}>
            <div className={styles.key} contentEditable={false}>
              {entityKey}
              <span className={styles.todoLetter}>{todoLetter}</span>
            </div>
          </div>
        }
      >
        <div
          className={cn(styles.keyPill, {
            [styles.selected]: selected,
          })}
          onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => e.preventDefault()}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();
            copyNumberToClipboard(`${entityKey}${todoLetter.toLocaleLowerCase()}`);
          }}
        >
          <div className={styles.key} contentEditable={false}>
            <span className={styles.entityKey}>{entityKey}</span>
            <span className={styles.todoLetter}>{todoLetter}</span>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

const TodoKey = React.memo(TodoKeyComponent);

export function SmartTodo({
  element,
  attributes,
  children,
}: RenderElementProps & { element: SmartTodoElement }) {
  const readonly = useReadOnly();
  const user = useCurrentUser();
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const collapsedFocus = useSlateSelector(e => !!e.selection && Range.isCollapsed(e.selection));

  const todo = useRecoilValue(todoSelector(element.todoId));

  const [hovered, setHovered] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const key = useRecoilValue(entityKeySelector(todo?.entityId));
  const toggleTodos = useToggleTodos('Document');

  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop({ smallPadding: true });

  const onWorkItemCreated = React.useCallback(
    (entityId: string | null) => {
      if (entityId) {
        KitemakerTransforms.selectElement(editor, element);
        KitemakerTransforms.insertNodes(editor, {
          type: Elements.Entity,
          entityId,
          mentionId: uuid.v4(),
          actorId: user.id,
          children: [{ text: '' }],
        });
      }
    },
    [editor, element]
  );

  const onCommented = React.useCallback(() => {
    editor.emit('forceFormatHoverMode', FormatHoverMode.Comment);
    KitemakerTransforms.selectElement(editor, element);
  }, [editor, element]);

  const onNewExternalIssue = React.useCallback(
    (type: IntegrationType, externalIssueId: string | null) => {
      if (externalIssueId) {
        KitemakerTransforms.selectElement(editor, element);
        KitemakerTransforms.insertNodes(editor, {
          type: Elements.InlineExternalIssueLink,
          externalIssueId,
          externalIssueType: integrationTypeToExternalIssueType(type),
          children: [{ text: '' }],
        });
      }
    },
    [editor, element]
  );

  const onKeyPress = useSmartTodoHotkeys(element, todo);
  useOnKeyDownHandler(onKeyPress);

  const onCheckboxClicked = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (readonly || !todo) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      toggleTodos([todo.id], e.shiftKey);
    },
    [todo, readonly, toggleTodos]
  );

  const indent = todo?.indent ?? element.indent;
  const checked = todo?.status === TodoStatus.Done;

  const childNode = range(indent).reduce(
    result => <ul>{result}</ul>,
    <li className="rowAlignStart">
      <div className="row noGrow">
        <div className={cn(styles.controlContainer, 'mr8')}>
          <CheckboxIcon
            status={todo?.status ?? TodoStatus.NotStarted}
            linkedToEntity={!!todo?.connectedEntityId}
            linkedToExternalIssue={!!todo?.connectedExternalIssueId}
            onClick={onCheckboxClicked}
          />
        </div>
      </div>
      <div className={styles.todoText}>{children}</div>

      <div className="row noGrow unselectable metadataGap ml8" contentEditable={false}>
        {todo && (
          <>
            <TodoCodeReviewRequests todoId={todo.id} />
            <TodoCycleIndicator todo={todo} />
            <TodoImpact todo={todo} />
            <TodoEffort todo={todo} />
            <EntityDueDate interactable size={MetadataSize.Small} item={todo} />
            <TodoMembers
              className={styles.avatars}
              todoId={todo.id}
              memberIds={todo.memberIds}
              onMemberAdded={memberId => {
                onMemberAdded(editor, element, user.id, memberId);
              }}
              onMemberRemoved={memberId => {
                onMemberRemoved(editor, element, memberId);
              }}
            />
          </>
        )}
      </div>

      {todo && (
        <div
          key={todo.id}
          contentEditable={false}
          className={cn(styles.menuContainer, {
            [styles.showOnHover]: !menuOpen,
          })}
          data-slate-no-copy="true"
        >
          {!readonly && (
            <SmartTodoMenu
              todo={todo}
              onMemberAdded={memberId => {
                onMemberAdded(editor, element, user.id, memberId);
              }}
              onMemberRemoved={memberId => {
                onMemberRemoved(editor, element, memberId);
              }}
              onLabelAdded={labelId => {
                onLabelAdded(editor, element, labelId);
              }}
              onLabelRemoved={labelId => {
                onLabelRemoved(editor, element, labelId);
              }}
              onWorkItemCreated={onWorkItemCreated}
              onCommented={onCommented}
              onNewExternalIssue={onNewExternalIssue}
              onHidden={() => {
                setMenuOpen(false);
              }}
              onShow={() => {
                setMenuOpen(true);
              }}
            >
              <div>
                <IconButton
                  icon="more_vertical"
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.Bare}
                />
              </div>
            </SmartTodoMenu>
          )}
        </div>
      )}
    </li>
  );

  return (
    <ul
      {...attributes}
      {...dndAttributes}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => setHovered(false)}
      className={cn('block', 'listBlock', dndClassName, styles.smartTodo, {
        [styles.isFocused]: (selected && focused && collapsedFocus) || menuOpen,
        [styles.todoChecked]: checked,
      })}
    >
      {todo && key && (
        <TodoKey
          entityKey={key}
          todoLetter={todo.key}
          selected={hovered || (selected && focused && collapsedFocus)}
        />
      )}
      {dndComponents}
      <div className={styles.contents}>{childNode}</div>
    </ul>
  );
}
