import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import isEmail from 'validator/lib/isEmail';
import { assigneeTerm } from '../../../../shared/utils/terms';
import {
  Feedback,
  Initiative,
  Issue,
  MemberRole,
  Release,
  Space,
  User,
} from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useInviteUsers } from '../../../syncEngine/actions/organizations';
import { isFeedback } from '../../../syncEngine/selectors/feedback';
import { isInitiative } from '../../../syncEngine/selectors/intiatives';
import { allUsersForOrganizationSelector } from '../../../syncEngine/selectors/organizations';
import { isRelease } from '../../../syncEngine/selectors/releases';
import { allUsersForMaybeSpaceSelector } from '../../../syncEngine/selectors/spaces';
import { toast } from '../../toast';
import { FilteredListViewHandle } from '../filteredListView';
import { ListViewItem } from '../listView';
import styles from '../menu/menu.module.scss';
import { Picker, PickerState } from '../picker';
import { User as UserComponent } from '../user';

export function entitiesToMemberPickerState(
  entities: Array<Issue | Feedback | Initiative | Release>
): PickerState {
  return entities.reduce((result, entity) => {
    let ids;
    if (isInitiative(entity) || isRelease(entity)) {
      ids = entity.memberIds;
    } else if (isFeedback(entity)) {
      ids = entity.ownerIds;
    } else {
      ids = entity.assigneeIds;
    }
    result[entity.id] = ids;
    return result;
  }, {} as PickerState);
}

function sanitizeUserFilterString(filterString: string): string {
  if (filterString.startsWith('@')) {
    return filterString.substring(1);
  }

  return filterString.includes('@')
    ? filterString.substring(0, filterString.indexOf('@'))
    : filterString;
}

function inviteUserItem({
  email,
  onDone,
  onMemberAdded,
  issueIds,
  invite,
  filterRef,
  role,
  showMemberRole,
}: {
  email: string;
  issueIds: string[];
  invite: (emails: string[], role: MemberRole) => Promise<string[]>;
  filterRef: React.RefObject<FilteredListViewHandle>;
  onDone: () => void;
  onMemberAdded: (issueIds: string[], memberId: string) => void;
  role: MemberRole;
  showMemberRole?: boolean;
}): ListViewItem {
  return {
    id: `invite_${role.toLowerCase()}`,
    mouseDown: true,
    icon: 'send_feedback',
    className: 'createItem',
    onSelected: async shift => {
      const validEmail = isEmail(email);
      if (!validEmail) {
        toast.warn('Please enter a valid email address');
        return;
      }

      const userIds = await invite([email], role);
      if (!userIds.length) {
        toast.error(
          <>
            An unknown error occurred while inviting <span className="semiBold">{email}</span>
          </>
        );
        return;
      }
      onMemberAdded(issueIds, userIds[0]);
      filterRef.current?.clear();

      if (!shift) {
        onDone();
      }
    },
    contents: (
      <div className="row overflowHidden">
        Invite '<span className="ellipsis">{email}</span>'
        {showMemberRole && ` as ${role.toLowerCase()}`}
      </div>
    ),
  };
}

export function MemberPicker({
  state,
  term = assigneeTerm,
  filterPlaceholder,
  filterClassName,
  placeholder,
  space: propSpace,
  additionalItems,
  additionalItemsAtStart,
  allUsers,
  hideInvite,
  disableCreation,
  onDone,
  onMemberAdded,
  onMemberRemoved,
}: {
  state: PickerState;
  term?: string;
  filterPlaceholder?: string;
  filterClassName?: string;
  placeholder?: string;
  space?: Space | null;
  additionalItems?: ListViewItem[];
  additionalItemsAtStart?: boolean;
  hideInvite?: boolean;
  allUsers?: boolean;
  disableCreation?: boolean;
  onDone: () => void;
  onMemberAdded: (issueIds: string[], memberId: string) => void;
  onMemberRemoved: (issueIds: string[], memberId: string) => void;
}) {
  const contextSpace = useMaybeSpace();
  const space = propSpace ?? contextSpace;
  const organization = useOrganization();
  const allUsersForSpace = useRecoilValue(
    allUsersForMaybeSpaceSelector({ organizationId: organization.id, spaceId: space?.id })
  );
  const allUsersForOrg = useRecoilValue(
    allUsersForOrganizationSelector({ organizationId: organization.id, preferSpaceId: space?.id })
  );
  const allMemberIds = Object.values(state).flat();
  const invite = useInviteUsers();

  const filteredUsers = allUsersForOrg.filter(user => {
    if (allMemberIds.includes(user.user.id)) {
      return true;
    }

    if (!allUsers && !allUsersForSpace.find(u => u.user.id === user.user.id)) {
      return false;
    }

    return !user.member.deactivated;
  });

  const items: ListViewItem[] = filteredUsers.map(user => {
    return {
      ...user.user,
      mouseDown: true,
      contents: () => (
        <>
          <UserComponent className={styles.text} member={user.member} user={user.user} />
        </>
      ),
    };
  });

  if (additionalItems) {
    if (additionalItemsAtStart) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterPlaceholder={filterPlaceholder ?? `Add ${term}s`}
      filterLabel={`Add ${term}s`}
      filterClassName={cn(filterClassName, 'emailInput')}
      filter={(filterString, search) => {
        const sanitizedFilterString = sanitizeUserFilterString(filterString);
        const results = search.search(sanitizedFilterString).map(r => r.item);
        if (disableCreation) {
          return results;
        }
        const exactEmailMatch =
          results.filter(u => (u as unknown as User).primaryEmail === filterString).length > 0;
        if (!exactEmailMatch && !hideInvite) {
          results.push(
            inviteUserItem({
              invite,
              email: filterString,
              issueIds: Object.keys(state),
              onDone,
              onMemberAdded,
              filterRef,
              role: MemberRole.Admin,
              showMemberRole: !!organization.activeProductId,
            })
          );

          if (organization.activeProductId) {
            results.push(
              inviteUserItem({
                invite,
                email: filterString,
                issueIds: Object.keys(state),
                onDone,
                onMemberAdded,
                filterRef,
                role: MemberRole.Member,
                showMemberRole: true,
              })
            );

            results.push(
              inviteUserItem({
                invite,
                email: filterString,
                issueIds: Object.keys(state),
                onDone,
                onMemberAdded,
                filterRef,
                role: MemberRole.Guest,
                showMemberRole: true,
              })
            );
          }
        }
        return results;
      }}
      multi
      ref={filterRef}
      maxItems={10}
      state={state}
      items={items}
      propertiesToSearch={['username', 'name', 'primaryEmail']}
      placeholder={placeholder}
      onAdd={onMemberAdded}
      onRemove={onMemberRemoved}
      onDone={onDone}
    />
  );
}
