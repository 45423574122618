import debugModule from 'debug';
const debug = debugModule('localStorage');

// overload declaration to tell typescript that if a default value is present, the return type
// will never be null
export function safeLocalStorageGet(key: string, defaultValue: string): string;

export function safeLocalStorageGet(key: string, defaultValue?: string): string | null {
  try {
    const value = window.localStorage?.getItem(key);
    return value ?? defaultValue ?? null;
  } catch (e) {
    debug('Error reading from localStorage', e);
  }

  return defaultValue ?? null;
}
