import * as React from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { between } from '../../../../shared/utils/sorting';
import { issueTerm } from '../../../../shared/utils/terms';
import { IssueStatusType } from '../../../../sync/__generated/models';
import { CommandGroup, StatusCommandGroupContext } from '../../../commands/state';
import {
  ButtonSize,
  ButtonStyle,
  IconButton,
  IconButtonTrigger,
} from '../../../components/new/button';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { Count } from '../../../components/new/count';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import { useColumnHasKeyNavigationFocus } from '../../../components/new/keyNavigation';
import { DropdownMenu, DropdownMenuContent } from '../../../components/new/menu/dropdownMenu';
import { StatusTypeMenu } from '../../../components/new/menu/statusTypeMenu';
import { StatusIcon, statusToIcon } from '../../../components/new/statusIcon';
import { TextInput, TextInputSize } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import {
  BoardColumnHeader,
  columnDragDestinationAtom,
  columnEditIdState,
} from '../../../components/new/virtualizedBoardView/columnHeader';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useCreateColumn, useUpdateColumn } from '../../../syncEngine/actions/boards';
import { useUpdateStatuses } from '../../../syncEngine/actions/issueStatuses';
import {
  boardColumnsForBoardSelector,
  boardSelector,
  boardsForSpaceSelector,
} from '../../../syncEngine/selectors/boards';
import {
  filteredIssuesForStatusSelector,
  statusSelector,
} from '../../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../../syncEngine/selectors/issueStatuses';
import { createNewIssueKey } from '../../../utils/config';
import styles from './columnHeader.module.scss';
import { StatusSortIndicator } from './sortIndicator';
import { StatusMenu } from './statusMenu';
import { WatchingStatusIndicator } from './watchingStatusIndicator';

export function ColumnHeader({
  statusId,
  boardId,
  hideLimit,
  includeArchived,
  disableEditing,
  onNewCard,
}: {
  statusId: string;
  boardId: string;
  hideLimit?: boolean;
  includeArchived?: boolean;
  disableEditing?: boolean;
  onNewCard: () => void;
}) {
  const space = useMaybeSpace();
  const [editingId, setEditingId] = useRecoilState(columnEditIdState);
  const editing = editingId === statusId;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const hasFocus = useColumnHasKeyNavigationFocus(statusId);
  const status = useRecoilValue(statusSelector(statusId));
  const isDefault = useRecoilValue(isDefaultStatusSelector(statusId));
  const count = useRecoilValue(
    filteredIssuesForStatusSelector({
      spaceId: space?.id,
      statusId: status?.id,
      filterId: boardId,
      includeArchived,
    })
  ).length;
  const backlogEnabled = useRecoilValue(boardsForSpaceSelector(space?.id)).some(
    b => b.name === 'Backlog'
  );
  const boardColumns = useRecoilValue(boardColumnsForBoardSelector(boardId));
  const boardColumn = boardColumns.find(c => c.statusId === statusId);
  const updateColumn = useUpdateColumn();
  const updateStatuses = useUpdateStatuses();

  const getDestinationAtom = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(columnDragDestinationAtom);
      },
    []
  );

  if (!status) {
    return null;
  }

  if (editing) {
    return (
      <EditColumn
        types={
          backlogEnabled
            ? [
                IssueStatusType.Backlog,
                IssueStatusType.Todo,
                IssueStatusType.InProgress,
                IssueStatusType.Done,
              ]
            : [IssueStatusType.Todo, IssueStatusType.InProgress, IssueStatusType.Done]
        }
        boardId={boardId}
        onComplete={(_boardId: string, name: string, statusType: IssueStatusType) => {
          updateStatuses([statusId], { name, statusType });
          setEditingId(null);
        }}
        initialName={status.name}
        initialStatusType={status.statusType}
        onCancel={() => {
          setEditingId(null);
        }}
      />
    );
  }

  return (
    <BoardColumnHeader
      disableEditing={disableEditing}
      onDragStart={() => {
        setEditingId(null);
      }}
      onDragEnd={() => {
        const dest = getDestinationAtom().getValue();
        if (!dest || !boardColumn) {
          return;
        }
        const { after, id } = dest;
        const index = boardColumns.findIndex(c => c.statusId === id);
        if (index < 0) {
          return;
        }
        let sort;
        if (after) {
          sort = between({
            after: boardColumns[index]?.sort,
            before: boardColumns[index + 1]?.sort,
          });
        } else {
          sort = between({
            before: boardColumns[index]?.sort,
            after: boardColumns[index - 1]?.sort,
          });
        }
        updateColumn([boardColumn.id], { sort });
      }}
      onClick={() => {
        if (!disableEditing) {
          setEditingId(statusId);
        }
      }}
      id={statusId}
    >
      <div className="row grow ellipsis">
        {status && <StatusIcon type={status.statusType} isDefault={isDefault} className="mr8" />}
        <div className="ellipsis mr8">{status?.name}</div>
        <Count count={count} limit={!hideLimit ? status?.issueLimit : undefined} />
        <div className="row ml4">
          <StatusSortIndicator disableEditing={disableEditing} status={status} />
          <WatchingStatusIndicator statusId={status.id} />
        </div>
      </div>
      <div className="row">
        <Tooltip
          content={
            <>
              {`Create ${issueTerm}`} <KeyboardShortcut shortcut={createNewIssueKey} />
            </>
          }
        >
          <IconButton
            icon="add"
            buttonStyle={ButtonStyle.BareSubtle}
            className="mr8"
            onClick={e => {
              e.stopPropagation();
              onNewCard();
            }}
          />
        </Tooltip>
        {space && (
          <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
            <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
            <DropdownMenuContent
              onClick={e => {
                e.stopPropagation();
              }}
              side="bottom"
              align="end"
            >
              <StatusMenu
                disableEditing={disableEditing}
                onEdit={() => setEditingId(statusId)}
                boardId={boardId}
                statusId={statusId}
                onClose={closeMenu}
              />
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      {status && hasFocus && (
        <CommandContext<StatusCommandGroupContext>
          context={{ group: CommandGroup.Status, statusId: status.id }}
        />
      )}
    </BoardColumnHeader>
  );
}

function EditColumn({
  boardId,
  types,
  initialName,
  initialStatusType,
  onComplete,
  onCancel,
}: {
  boardId: string;
  types: IssueStatusType[];
  initialName?: string;
  initialStatusType?: IssueStatusType;
  onComplete: (boardId: string, name: string, statusType: IssueStatusType) => void;
  onCancel: () => void;
}) {
  const [name, setName] = React.useState(initialName ?? '');
  const board = useRecoilValue(boardSelector(boardId));
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const [statusType, setStatusType] = React.useState(
    initialStatusType ??
      (board?.name === 'Current' ? IssueStatusType.InProgress : IssueStatusType.Backlog)
  );

  useComponentDidMount(() => {
    ref.current?.scrollIntoView({ block: 'nearest' });
  });

  return (
    <div className={styles.editHeader}>
      <DropdownMenu>
        <IconButtonTrigger size={ButtonSize.Small} icon={statusToIcon(statusType)} />
        <DropdownMenuContent className="menuTiny" side="bottom" sideOffset={4} align="start">
          <StatusTypeMenu
            boardName={board?.name}
            values={types}
            statusType={statusType}
            onSelect={setStatusType}
          />
        </DropdownMenuContent>
      </DropdownMenu>
      <TextInput
        inputSize={TextInputSize.Small}
        className="noMinWidth grow"
        placeholder="New status name"
        autoFocus
        value={name}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onComplete(boardId, name, statusType);
          } else if (e.key === 'Escape') {
            onCancel();
          }
        }}
        onChange={e => setName(e.target.value)}
      />
      <IconButton
        size={ButtonSize.Small}
        onClick={() => {
          onComplete(boardId, name, statusType);
        }}
        icon="select_checkmark"
        buttonStyle={ButtonStyle.Primary}
      />
      <IconButton ref={ref} size={ButtonSize.Small} onClick={onCancel} icon="exit" />
    </div>
  );
}

export function NewColumnHeader({ boardId, types }: { boardId: string; types: IssueStatusType[] }) {
  const [creating, setCreating] = React.useState(false);
  const createColumn = useCreateColumn();

  return (
    <div className={styles.newHeader}>
      {!creating && (
        <Tooltip content={<>Add new status</>}>
          <IconButton
            size={ButtonSize.Small}
            className={styles.newButton}
            onClick={() => setCreating(true)}
            icon="add"
            buttonStyle={ButtonStyle.Secondary}
          />
        </Tooltip>
      )}
      {creating && (
        <EditColumn
          types={types}
          boardId={boardId}
          onComplete={(boardId: string, name: string, statusType: IssueStatusType) => {
            createColumn(boardId, name, statusType);
            setCreating(false);
          }}
          onCancel={() => {
            setCreating(false);
          }}
        />
      )}
    </div>
  );
}
