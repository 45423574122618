import * as React from 'react';
import { MINOR_VERSION, VERSION } from '../shared/utils/version';
import { Organization, Space, User } from '../sync/__generated/models';
import { getElectronVersion, isElectron } from './electronIntegration';
import { useComponentDidMount } from './hooks/useComponentDidMount';

let organizationId: string | undefined = undefined;
const trackingConfig = { disabled: false };

export function disableTracking() {
  trackingConfig.disabled = true;
  window.analytics?.reset();
  window.Intercom?.('shutdown');
  window.FS?.shutdown();
  window.clarity?.('stop');
}

export function trackerSimpleIdentify(userId: string, email: string) {
  if (trackingConfig.disabled) {
    return;
  }
  window.analytics?.identify(userId, { email });
}

export function trackerIdentifyUser(
  user: User,
  organization: Organization,
  numberActiveUsers: number,
  numberGuestUsers: number
) {
  if (trackingConfig.disabled) {
    return;
  }

  // remmember the organizationId so we can send it with all of our other events and make Segment/MixPanel happy
  organizationId = organization.id;
  const activeProductId = organization.activeProductId;

  const userProps = {
    username: user.username,
    email: user.primaryEmail,
    verified: user.verified,
    name: user.name,
    organizationId,
    organizationSlug: organization.slug,
    organizationName: organization.name,
    organizationActiveUsers: numberActiveUsers,
    organizationGuestUsers: numberGuestUsers,
    organizationProductId: activeProductId,
    createdAt: new Date(user.createdAt).toISOString(),
    updatedAt: new Date(user.updatedAt).toISOString(),
    electron: isElectron(),
    electronVersion: getElectronVersion(),
    version: `${VERSION}.${MINOR_VERSION}`,
  };

  window.analytics?.identify(user.id, userProps);
  window.analytics?.group(organizationId, {
    organizationId,
    slug: organization.slug,
    name: organization.name,
    activeUsers: numberActiveUsers,
    guestUsers: numberGuestUsers,
    productId: activeProductId,
  });
}

export function trackerClearUser() {
  window.analytics?.reset();
  organizationId = undefined;
}

export function trackerPageLoad(name: string, pageProps?: any) {
  if (trackingConfig.disabled) {
    return;
  }
  window.analytics?.page(name, { ...pageProps, page: name, organizationId });
}

export function trackerEvent(name: string, props?: any) {
  if (trackingConfig.disabled) {
    return;
  }
  window.analytics?.track(name, { ...props, organizationId });
}

export function TrackSpace({
  space,
  screenName,
  trackingProps,
}: {
  space: Space;
  screenName: string;
  trackingProps?: Record<string, unknown>;
}) {
  useComponentDidMount(() => {
    if (trackingConfig.disabled) {
      return;
    }
    trackerPageLoad(screenName, {
      spaceName: space.name,
      spaceId: space.id,
      organizationId,
      ...trackingProps,
    });
  });
  return <></>;
}
