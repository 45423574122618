import cn from 'classnames';
import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { TodoStatus } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import {
  TodoCodeReviewRequests,
  TodoCycleIndicator,
  TodoEffort,
  TodoImpact,
  TodoMembers,
} from '../../slate/elements/smartTodo/smartTodo';
import { CheckboxIcon } from '../../slate/elements/smartTodo/smartTodoCheckbox';
import { MenuContents } from '../../slate/elements/smartTodo/smartTodoMenu';
import { StaticSlateDocument } from '../../slate/staticSlate';
import { useToggleTodos, useUpdateTodoOutsideOfEditor } from '../../syncEngine/actions/todos';
import { useEntityPath } from '../../syncEngine/selectors/entities';
import { todoSelector } from '../../syncEngine/selectors/todos';
import { CustomCommand } from './customCommand';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMembers,
  EntityListItemMenu,
  EntityListItemMetadata,
  EntityListItemTitle,
} from './entityListItem';
import { Icon } from './icon';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import styles from './todoListItem.module.scss';

export function TodoListItem({
  id,
  keyNavId,
  routingState,
  className,
  style,
  noLeftMargin,
}: {
  id: string;
  keyNavId?: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  noLeftMargin?: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const focused = useHasKeyNavigationFocus(keyNavId ?? id);
  const todo = useRecoilValue(todoSelector(id));
  const toggleTodos = useToggleTodos();
  const entityPath = useEntityPath();
  const updateTodo = useUpdateTodoOutsideOfEditor(todo?.entityId ?? '');

  useKeyNavigationElement(keyNavId ?? id, ref);

  if (!todo) {
    return null;
  }

  const link = {
    pathname: entityPath(todo.entityId)!,
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: todo.entityId,
      ...routingState,
    },
    search: `focusDescription=true&focusSmartTodo=${todo.id}`,
  };

  return (
    <Link to={link}>
      <EntityListItem
        ref={ref}
        entityNumber={<span className={styles.todoKey}>{todo.key}</span>}
        className={cn(className, styles.todo)}
        statusIcon={<Icon icon="none" />}
        style={{ ...style }}
        archived={todo.status === TodoStatus.Done}
      >
        <EntityListItemMainContents>
          <EntityListItemTitle
            style={{ marginLeft: 26 * todo.indent }}
            className="row metadataGapL grow"
            type={issueTerm}
          >
            <CheckboxIcon
              status={todo.status}
              linkedToEntity={!!todo.connectedEntityId}
              linkedToExternalIssue={!!todo.connectedExternalIssueId}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                toggleTodos([todo.id], e.shiftKey);
              }}
            />
            <StaticSlateDocument
              value={todo.todoContents}
              className={cn(styles.truncateBlocks, 'normal')}
            />
          </EntityListItemTitle>
          <EntityListItemMetadata>
            <TodoCodeReviewRequests todoId={todo.id} />
            <TodoCycleIndicator todo={todo} />
            <TodoImpact todo={todo} />
            <TodoEffort todo={todo} />
          </EntityListItemMetadata>
          {focused && (
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Navigation,
                description: `Go to todo`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
          )}
        </EntityListItemMainContents>
        <EntityListItemMembers>
          <TodoMembers
            onMemberAdded={memberId => {
              updateTodo(todo.id, { membersToAdd: [memberId] });
            }}
            onMemberRemoved={memberId => {
              updateTodo(todo.id, { membersToRemove: [memberId] });
            }}
            todoId={todo.id}
            memberIds={todo.memberIds}
          />
        </EntityListItemMembers>
        <EntityListItemMenu
          narrow={noLeftMargin}
          menuContents={closeMenu => (
            <MenuContents
              todo={todo}
              onMemberAdded={memberId => {
                updateTodo(todo.id, { membersToAdd: [memberId] });
              }}
              onMemberRemoved={memberId => {
                updateTodo(todo.id, { membersToRemove: [memberId] });
              }}
              onLabelAdded={labelId => {
                updateTodo(todo.id, { labelsToAdd: [labelId] });
              }}
              onLabelRemoved={labelId => {
                updateTodo(todo.id, { labelsToRemove: [labelId] });
              }}
              closeMenu={closeMenu}
            />
          )}
        />
      </EntityListItem>
    </Link>
  );
}
