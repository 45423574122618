import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { NodeEntry, Node, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import { generateId } from '../../../shared/utils/id';
import { IntegrationType } from '../../../sync/__generated/models';
import { externalAuthFlow } from '../../api/auth';
import { createFigmaThumbnail } from '../../api/figma';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { integrationUsersForCurrentUserSelector } from '../../syncEngine/selectors/integrations';
import Hover from '../hovers';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { HistoryEditor } from '../plugins/history';
import { debug } from '../plugins/withDebug';

export function FigmaLinkHover() {
  const [visible, setVisible] = React.useState(true);
  const editor = useSlateStatic();
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  const integrationUser = useRecoilValue(integrationUsersForCurrentUserSelector).find(
    u => u.type === IntegrationType.Figma && u.configValid
  );

  const hasFigmaUser = !!integrationUser;

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        setVisible(false);
        return true;
      }

      if (editor.isAccept(e)) {
        e.preventDefault();
        e.stopPropagation();

        let parent: NodeEntry<Node> | undefined = KitemakerEditor.above(editor, {
          match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
        });

        if (!parent) {
          // Are we right at the beginning of a text node? Maybe the thing before us is a link
          const selection = safeSelection(editor);
          if (selection?.focus.offset === 0) {
            const previous = KitemakerEditor.previous(editor, {
              at: selection,
              mode: 'all',
              match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
            });
            if (previous) {
              parent = previous;
            }
          }
        }

        if (!parent) {
          return true;
        }

        const [parentNode, parentPath] = parent;

        if (!KitemakerElement.isElement(parentNode) || parentNode.type !== Elements.Link) {
          return true;
        }

        const url = parentNode.url;
        if (!url) {
          return true;
        }

        const edges = KitemakerEditor.edges(editor, parentPath);
        HistoryEditor.asBatch(editor, async () => {
          const refId = uuid.v4();

          KitemakerTransforms.select(editor, { anchor: edges[0], focus: edges[1] });
          KitemakerTransforms.unwrapNodes(editor, {
            match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
          });
          const thumbnailId = generateId();
          KitemakerTransforms.setBlockElementAndSplitIfNeeded(editor, Elements.Figma, {
            properties: { url, thumbnailId, refId },
          });

          try {
            debug(`ThumbnailId (${thumbnailId}) set on Figma node with refId: ${refId}`);

            const { needsAuth } = await createFigmaThumbnail(organization.id, url, thumbnailId);

            if (needsAuth) {
              debug(`Figma authentication required`);
              editor.shutdown?.();
              ReactEditor.blur(editor);
              setTimeout(() => {
                externalAuthFlow(
                  `${host}/integrations/figma/auth?organizationId=${organization.id}&pendingThumbnails=${thumbnailId}`,
                  electronScheme,
                  { exchange: true }
                );
              });
            }
          } catch (e) {
            const nodes = Array.from(KitemakerNode.descendants(editor)).filter(
              ([node]) =>
                KitemakerElement.isElement(node) &&
                node.type === Elements.Figma &&
                node.refId === refId
            );

            if (!nodes.length) {
              debug(`Unable to find Figma node with refId: ${refId}`);
              return;
            }
            const [, path] = nodes[0];

            HistoryEditor.withoutSaving(editor, () => {
              Transforms.removeNodes(editor, { at: path });
              Transforms.insertText(editor, url, { at: path });
            });

            debug(e);
          }
        });
        return true;
      }

      return false;
    },
    [editor, electronScheme, host, organization.id]
  );

  return (
    <Hover
      hoverId="link-figma"
      fixed
      open={visible}
      contentOptions={{
        side: 'top',
      }}
      onOpenChange={setVisible}
      onKeyDown={onKeyDown}
      content={
        <div className="mr8 ml8 mt4 mb4 headingS noWrap row">
          <div className="mr8">
            <KeyboardShortcut shortcut="enter" />
          </div>
          to{!hasFigmaUser ? ' authenticate and' : ''} insert from Figma
        </div>
      }
    ></Hover>
  );
}
