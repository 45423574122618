// Docs say we should import this *before* react and react-dom
import { TooltipProvider } from '@radix-ui/react-tooltip';
import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import debugModule from 'debug';
import * as React from 'react';
import { Profiler } from 'react';
import { createRoot } from 'react-dom/client';
import {
  Redirect,
  Route,
  RouteComponentProps,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import 'react-virtualized/styles.css';
import { RecoilRoot, useRecoilValue } from 'recoil';
import smoothscroll from 'smoothscroll-polyfill';
import { looksLikeId } from '../shared/utils/id';
import { parseBoolean } from '../shared/utils/utils';
import {
  User as CurrentUserModel,
  Organization as OrganizationModel,
  Space as SpaceModel,
} from '../sync/__generated/models';
import { AuthResult } from './api/auth';
import { CommandHotkeys } from './commands/commandHotkeys';
import { CommandGroup, SpaceCommandGroupContext } from './commands/state';
import { EntityRedirect } from './components/entityRedirect';
import { HotkeyScope, hotkeysInitiallyDisabledAtom } from './components/hotkeys';
import { CommandContext } from './components/new/commandMenuContext';
import { useMouseMoveTracker } from './components/new/keyNavigation';
import { AvatarClipPath } from './components/new/metadata/avatarGroup';
import { OldThemeRedirect } from './components/new/oldThemeRedirect';
import { Sidebar } from './components/new/sidebar';
import { Screen } from './components/screen';
import { SentryManager } from './components/sentryManager';
import { Toast } from './components/toast';
import VersionCheck from './components/versionCheck';
import { useClient } from './contexts/clientContext';
import { ConfigurationProvider, useConfiguration } from './contexts/configurationContext';
import { ConfirmationProvider } from './contexts/confirmationContext';
import { GraphQLProvider } from './contexts/graphqlContext';
import { ModalProvider } from './contexts/modalContext';
import { OrganizationProvider, useOrganization } from './contexts/organizationContext';
import { SearchProvider } from './contexts/searchContext';
import { SpaceProvider } from './contexts/spaceContext';
import { UndoProvider } from './contexts/undoContext';
import {
  PersistentSettingsProvider,
  PersistentSpaceSettingsProvider,
  localStorageOpenInAppKey,
  usePersistentSettings,
} from './contexts/usePersistentSettings';
import { UserProvider, useCurrentUser } from './contexts/userContext';
import {
  DeepLinkInOrganization,
  configureElectronApp,
  isElectron,
  setBadgeCount,
} from './electronIntegration';
import { ModelManagerProvider, useModelManager } from './graphql/modelManager';
import { FetchedMarker, OrganizationMarker } from './graphql/smartLoad';
import { Subscriptions } from './graphql/subscriptions';
import { useComponentDidMount } from './hooks/useComponentDidMount';
import { DarkMode, useDarkMode } from './hooks/useDarkMode';
import useNoDuplicateHistory from './hooks/useNoDuplicateHistory';
import { useIsTinyScreen, useWatchMediaQueries } from './hooks/useResponsiveDesign';
import styles from './index.module.scss';
import { AppCommandsAndModals } from './index/appCommandsAndModals';
import { BasicModals } from './index/basicModals';
import BillingChecks from './index/billingChecks';
import { showOpenInAppToast } from './index/openInAppToast';
import { OrganizationCommandsAndModals } from './index/organizationCommandsAndModals';
import { SpaceCommandsAndModals } from './index/spaceCommandsAndModals';
import { CommandMenu } from './modals/commandMenu';
import { CycleScreen } from './screens/cycleScreen';
import { CyclesScreen } from './screens/cycleScreen/cyclesScreen';
import { DependencyGraphScreen } from './screens/dependencyGraphScreen/dependencyGraphScreen';
import DocumentScreen from './screens/documentScreen';
import { DocumentsScreen } from './screens/documentsScreen';
import {
  BasicErrorScreen,
  MaintenanceModeScreen,
  NoOrganizationsScreen,
  NoSpacesScreen,
  NotFoundScreen,
} from './screens/errorScreens';
import { DeletedScreen } from './screens/errorScreens/deletedScreen';
import FeedbackScreenWrapper from './screens/feedbackScreen';
import { ForgotPasswordScreen } from './screens/forgotPasswordScreen';
import { OAuthInstallFlowScreen } from './screens/installIntegrationScreen/installIntegrationScreen';
import { LinkAccountScreen } from './screens/linkAccountScreen';
import LoadingScreen from './screens/loadingScreen';
import { LoginScreen } from './screens/loginScreen';
import { ArchiveScreen } from './screens/new/archiveScreen';
import FeedbackDetailScreenWrapper from './screens/new/feedbackDetailScreen';
import InitiativeScreenWrapper from './screens/new/initiativeScreen';
import { MyWorkScreen } from './screens/new/myWorkScreen';
import { InitiativesScreen } from './screens/new/newRoadmapScreen/initiativesScreen';
import { RoadmapScreen as NewRoadmapScreen } from './screens/new/newRoadmapScreen/roadmapScreen';
import { RoadmapsScreen } from './screens/new/newRoadmapScreen/roadmapsScreen';
import { SearchScreen } from './screens/new/searchScreen';
import { SettingsScreen } from './screens/new/settingsScreen';
import { UpdatesScreen } from './screens/new/updatesScreen';
import { WorkItemBoardScreen } from './screens/new/workItemBoardScreen';
import WorkItemScreen from './screens/new/workItemScreen';
import { NewOrganizationScreen } from './screens/newOrganizationScreen';
import OldFeedbackScreenWrapper from './screens/oldFeedbackScreen';
import { ONBOARDING_TUTORIAL_ID, OnboardingScreen } from './screens/onboardingScreen';
import { OpenInAppScreen } from './screens/openInAppScreen';
import { PendingInviteScreen } from './screens/pendingInviteScreen';
import { RegisterScreen } from './screens/registerScreen';
import ReleaseDetailsScreenWrapper from './screens/releaseScreen/releaseDetailsScreen';
import { ReleasesScreen } from './screens/releaseScreen/releasesScreen';
import { ResetPasswordScreen } from './screens/resetPasswordScreen';
import { SharedBoardScreen } from './screens/sharedBoardScreen';
import { SharedIssueScreen } from './screens/sharedIssueScreen';
import { SharedRoadmapScreen } from './screens/sharedRoadmapScreen/sharedRoadmapScreen';
import { TermsScreen } from './screens/termsScreen';
import { DraggableLayer } from './slate/plugins/dragAndDrop/draggableLayer';
import './styles/new/colors.scss';
import './styles/new/editor.scss';
import './styles/new/global.scss';
import './styles/new/typography.scss';
import { useUpdateOrganizationMembers } from './syncEngine/actions/organizationMember';
import { useMarkTutorialCompleted, useUpdateUsers } from './syncEngine/actions/users';
import {
  useUpdateEntityNumberWidths,
  useUpdateRecentEntities,
} from './syncEngine/selectors/entities';
import {
  organizationMemberCountSelector,
  organizationPath,
} from './syncEngine/selectors/organizations';
import { markerState } from './syncEngine/selectors/smartLoader';
import { allSpacesForOrganizationSelector, spacePath } from './syncEngine/selectors/spaces';
import { unreadUpdatesForCurrentUserSelector } from './syncEngine/selectors/updates';
import {
  animationsDisabledSelector,
  currentUserMarkerState,
  currentUserMembershipState,
  currentUserOrganizatonsState,
  currentUserState,
  wideDescriptionsSelector,
} from './syncEngine/selectors/users';
import { disableTracking, trackerIdentifyUser } from './tracker';
import { isFirefox, isIOS, isMac, isMobileOS, isSafari } from './utils/config';
import { checkForAlwaysOnScrollbars, translateFix } from './utils/dom';
import { LocationState } from './utils/history';
import { safeLocalStorageGet } from './utils/localStorage';
import { isMaybeOrganizationPath } from './utils/paths';
import { getQueryParameter, removeQueryParameter } from './utils/query';

smoothscroll.polyfill();
// FIMXE: some weird shim needed when we moved to vite to keep the elk stuff in dependency graph working
window.g = window;

const OPEN_IN_APP_DISABLED_KEY = '__openInAppDisabled';

function isOpeningInAppDisabledForSession() {
  return !!window.sessionStorage.getItem(OPEN_IN_APP_DISABLED_KEY);
}

let pendingRedirect: string | null = null;
const isValidLinkToOpenInApp = isMaybeOrganizationPath(window.location.pathname) && !isMobileOS;

function SpaceRoutes({ space, match, history }: { space: SpaceModel } & RouteComponentProps) {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const fetched = true;
  const updateOrganizationMembers = useUpdateOrganizationMembers();

  useComponentDidMount(() => {
    if (orgMembership && orgMembership.lastSpaceId !== space.id) {
      updateOrganizationMembers([orgMembership.id], { lastSpaceId: space.id });
    }
  });

  return (
    <SpaceProvider spaceId={space.id}>
      <PersistentSpaceSettingsProvider>
        <CommandContext<SpaceCommandGroupContext>
          context={{ group: CommandGroup.Space, spaceId: space.id }}
        />
        <SpaceCommandsAndModals />
        <Switch>
          <Route
            path={match.path}
            exact
            render={() => (
              <Redirect
                to={`${match.url}${match.url.endsWith('/') ? '' : '/'}board${
                  history.location.search
                }`}
              />
            )}
          />
          <Route
            path={`${match.path}/:entityNumber([1-9][0-9]*)`}
            render={props => <EntityRedirect entityNumber={props.match.params.entityNumber} />}
          />
          <Route
            path={`${match.path}/board`}
            exact
            render={() => (
              <Redirect
                to={spacePath(organization, space, `boards/current${history.location.search}`)}
              />
            )}
          />
          <Route
            path={`${match.path}/boards`}
            exact
            render={() => (
              <Redirect
                to={spacePath(organization, space, `boards/current${history.location.search}`)}
              />
            )}
          />
          <Route
            path={`${match.path}/boards/:boardKey`}
            exact
            render={() => <WorkItemBoardScreen />}
          />
          <Route path={`${match.path}/archive/:archive?`} render={() => <ArchiveScreen />} />
          <Route
            path={`${match.path}/issues/:issueNumber`}
            exact
            render={({ match: redirectMatch }) => (
              <Redirect to={redirectMatch.url.replace('/issues/', '/items/')} />
            )}
          />
          <Route
            path={`${match.path}/items/:issueNumber`}
            exact
            render={props => <WorkItemScreen {...props} />}
          />
          <Route path={`${match.path}/cycles/`} exact render={() => <CyclesScreen />} />
          <Route
            path={`${match.path}/cycles/:cycleNumber`}
            exact
            render={props => <CycleScreen {...props} />}
          />
          <Route path={`${match.path}/initiatives`} exact render={() => <InitiativesScreen />} />
          <Route
            path={`${match.path}/initiatives/:initiativeNumber?`}
            render={() => <InitiativeScreenWrapper />}
          />

          {organization.newRoadmapsEnabled && (
            <Route path={`${match.path}/roadmap`} exact render={() => <NewRoadmapScreen />} />
          )}
          <Route path={`${match.path}/themes`} exact render={() => <OldThemeRedirect />} />
          <Route
            path={`${match.path}/themes/:themeNumber`}
            exact
            render={props => <OldThemeRedirect number={props.match.params.themeNumber} />}
          />
          {fetched && (
            <Route
              render={() => (
                <Screen>
                  <NotFoundScreen />
                </Screen>
              )}
            />
          )}
          {!fetched && <Route component={LoadingScreen} />}
        </Switch>
      </PersistentSpaceSettingsProvider>
    </SpaceProvider>
  );
}

function UpdateCounts() {
  const organization = useOrganization();
  const unreadNotifications = useRecoilValue(unreadUpdatesForCurrentUserSelector(organization.id));

  React.useEffect(() => {
    setBadgeCount(unreadNotifications.length);
  }, [unreadNotifications.length]);

  return null;
}

function OrganizationRoutes({
  organization,
  match,
  history,
}: {
  organization: OrganizationModel;
} & RouteComponentProps) {
  const { europeanDataResidency, featureFlags } = useConfiguration();
  const user = useCurrentUser();
  const updateUsers = useUpdateUsers();

  const { numberActiveUsers, numberGuestUsers } = useRecoilValue(
    organizationMemberCountSelector(organization.id)
  );
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const fetched = useRecoilValue(markerState(FetchedMarker.id));
  const orgPartiallyLoaded = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, true))
  );
  const onboardingComplete = user.completedTutorials.includes(ONBOARDING_TUTORIAL_ID);
  const spaces = useRecoilValue(allSpacesForOrganizationSelector(organization.id));

  useComponentDidMount(() => {
    if (user.lastOrganizationId !== organization.id) {
      updateUsers([user.id], { lastOrganizationId: organization.id });
    }
  });

  // 2023.09.07 - backwards compat. Let's maybe not keep it more than 6 months?
  const showUpdates = React.useMemo(() => {
    if (getQueryParameter(history, 'showUpdates')) {
      removeQueryParameter(history, 'showUpdates');
      return true;
    }
    return false;
  }, []);

  // 2024.04.29 - backwards compat with the old URL we gave Vanta to install our integration
  const pendingIntegration = React.useMemo(() => {
    const pending = getQueryParameter(history, 'pendingIntegration');
    if (pending && !history.location.pathname.includes('/settings/integrations')) {
      removeQueryParameter(history, 'pendingIntegration');
      return pending;
    }
    return null;
  }, []);

  React.useEffect(() => {
    // don't track until we've at least fetched the org to avoid noisy org-related
    // tracking data
    if (!orgPartiallyLoaded) {
      return;
    }

    if (!europeanDataResidency) {
      trackerIdentifyUser(user, organization, numberActiveUsers, numberGuestUsers);
      window.Intercom?.('update', {
        user_id: user.id,
        name: user.name || user.username,
        email: user.primaryEmail,
        created_at: user.createdAt / 1000, // Signup date as a Unix timestamp,
        userVerified: user.verified,
        organizationName: organization.name,
        organizationId: organization.id,
        organizationSlug: organization.slug,
      });

      window.FS?.identify(user.id, {
        displayName: user.username,
        email: user.primaryEmail,
      });
    }
  }, [
    user.id,
    user.username,
    user.primaryEmail,
    user.verified,
    organization.id,
    organization.name,
    organization.activeProductId,
    numberActiveUsers,
    numberGuestUsers,
    orgPartiallyLoaded,
  ]);

  const lastSpace = orgMembership?.lastSpaceId
    ? spaces.find(s => s.id === orgMembership.lastSpaceId && !s.deleted) ?? spaces[0]
    : spaces[0];

  if (!orgPartiallyLoaded) {
    return <LoadingScreen />;
  }

  let defaultPath = `/${organization.slug}/onboarding`;
  if (onboardingComplete) {
    if (showUpdates) {
      defaultPath = `/${organization.slug}/updates`;
    } else if (pendingIntegration) {
      defaultPath = `/${organization.slug}/settings/integrations/new/${pendingIntegration}`;
    } else if (spaces.filter(s => !s.deleted).length > 0) {
      defaultPath = `/${organization.slug}/${lastSpace.slug}${history.location.search}`;
    } else {
      defaultPath = `/${organization.slug}/nospaces`;
    }
  }

  const spaceRoutes = spaces.map(space => {
    if (!space.slug) {
      Sentry.captureMessage('Space without space', {
        extra: {
          spaceId: space.id,
        },
      });
      return null;
    }
    const lookupKey = space.slug.split('-')[0];
    return (
      <Route
        key={space.id}
        path={`${match.path}/${lookupKey}-([A-Za-z0-9_-]+)`}
        render={props => {
          if (space.deleted) {
            return (
              <Screen>
                <DeletedScreen type="Space" />
              </Screen>
            );
          }
          return <SpaceRoutes {...props} space={space} />;
        }}
      />
    );
  });

  return (
    <OrganizationProvider organizationId={organization.id}>
      <DeepLinkInOrganization />
      <SearchProvider>
        <CommandMenu />
        <CommandHotkeys />
        <Subscriptions />
        <UpdateCounts />
        <BillingChecks />
        <OrganizationCommandsAndModals />
        <div className={styles.screenContainer}>
          <Sidebar />
          <Switch>
            {spaceRoutes}
            <Route path={match.path} exact render={_ => <Redirect to={defaultPath} />} />
            <Route path={`${match.path}/onboarding/:step?`} render={() => <OnboardingScreen />} />
            <Route
              path={`${match.path}/nospaces`}
              render={() => (
                <Screen>
                  <NoSpacesScreen />
                </Screen>
              )}
            />
            <Route
              path={`${match.path}/billing`}
              render={() => {
                return <Redirect to={organizationPath(organization, 'settings/billing')} />;
              }}
            />
            <Route path={`${match.path}/my`} render={() => <MyWorkScreen />} />
            {organization.newRoadmapsEnabled && (
              <Route
                path={`${match.path}/roadmaps/:roadmapId?`}
                render={() => <RoadmapsScreen />}
              />
            )}
            <Route path={`${match.path}/documents/:folder*`} render={() => <DocumentsScreen />} />
            <Route
              path={`${match.path}/document/:docNumber`}
              exact
              render={props => <DocumentScreen {...props} />}
            />
            <Route
              path={`${match.path}/initiatives`}
              exact
              render={() => <Redirect to={organizationPath(organization, 'roadmaps/all')} />}
            />
            <Route
              path={`${match.path}/initiatives/:initiativeNumber`}
              render={() => <InitiativeScreenWrapper />}
            />
            <Route path={`${match.path}/releases`} exact render={() => <ReleasesScreen />} />
            <Route
              path={`${match.path}/release/:releaseNumber`}
              exact
              render={() => <ReleaseDetailsScreenWrapper />}
            />
            <Route
              path={`${match.path}/updates/:openActivityOrCommentId?`}
              render={() => <UpdatesScreen />}
            />
            {featureFlags.FEATURE_TOGGLE_NEW_FEEDBACK && (
              <Route
                path={`${match.path}/feedback/:feedbackNumber`}
                render={props => <FeedbackDetailScreenWrapper {...props} />}
              />
            )}
            {featureFlags.FEATURE_TOGGLE_NEW_FEEDBACK && (
              <Route path={`${match.path}/feedback/`} render={() => <FeedbackScreenWrapper />} />
            )}
            {!featureFlags.FEATURE_TOGGLE_NEW_FEEDBACK && (
              <Route
                path={`${match.path}/feedback/:feedbackNumber?`}
                render={() => <OldFeedbackScreenWrapper />}
              />
            )}
            <Route path={`${match.path}/settings`} render={() => <SettingsScreen />} />
            <Route path={`${match.path}/search/:entityType?`} render={() => <SearchScreen />} />
            <Route path={`${match.path}/dependencies`} render={() => <DependencyGraphScreen />} />
            <Route path={`${match.path}/archive/:archive?`} render={() => <ArchiveScreen />} />
            {fetched && (
              <Route
                render={() => (
                  <Screen>
                    <NotFoundScreen />
                  </Screen>
                )}
              />
            )}
            {!fetched && <Route component={LoadingScreen} />}
          </Switch>
        </div>
      </SearchProvider>
    </OrganizationProvider>
  );
}

function FocusPlayDuringFullScreenVideo() {
  useComponentDidMount(() => {
    function onFullScreenChanged() {
      if (document.fullscreenElement?.tagName.toLowerCase() === 'video') {
        (document.fullscreenElement as HTMLVideoElement).focus();
      }
    }

    document.addEventListener('fullscreenchange', onFullScreenChanged);
    return () => document.removeEventListener('fullscreenchange', onFullScreenChanged);
  });
  return null;
}

function LoggedInRoutes({ user }: { user: CurrentUserModel }) {
  const { production, electronScheme, defaultOrganizationId, europeanDataResidency } =
    useConfiguration();
  const history = useHistory();
  const { openInApp, setOpenInApp } = usePersistentSettings();
  const markTutorialCompleted = useMarkTutorialCompleted(user.id);

  useComponentDidMount(() => {
    if (europeanDataResidency) {
      disableTracking();
    }

    if (production) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ id: user.id });
      });
    }

    if (isElectron()) {
      if (!user.completedTutorials.includes('electron')) {
        markTutorialCompleted('electron');
      }
    } else {
      if (
        !isMobileOS &&
        user.completedTutorials.includes('electron') &&
        openInApp === null &&
        isValidLinkToOpenInApp &&
        !isOpeningInAppDisabledForSession()
      ) {
        showOpenInAppToast(setOpenInApp, electronScheme);
      }
    }

    if (pendingRedirect) {
      const redirect = pendingRedirect;
      pendingRedirect = null;
      history.replace(redirect);
    }
  });

  const organizations = useRecoilValue(currentUserOrganizatonsState);

  useComponentDidMount(() => {
    if (!isElectron()) {
      return;
    }
    setTimeout(async () => {
      await configureElectronApp(organizations);
    });
  });

  if (pendingRedirect) {
    return null;
  }

  const defaultOrganization =
    organizations.find(org => org.id === defaultOrganizationId) ?? organizations[0];
  const organizationRoutes = organizations.map(organization => {
    const lookupKey = organization.slug.split('-')[0];
    return (
      <Route
        key={organization.id}
        path={`/${lookupKey}-([A-Za-z0-9_-]+)`}
        render={props => <OrganizationRoutes {...props} organization={organization} />}
      />
    );
  });

  return (
    <UndoProvider>
      <ConfirmationProvider>
        <UserProvider user={user}>
          <DraggableLayer />
          <FocusPlayDuringFullScreenVideo />
          <AppCommandsAndModals />
          {organizations.length > 0 && (
            <Switch>
              <Route
                path="/"
                exact
                render={() => (
                  <Redirect to={`/${defaultOrganization.slug}${history.location.search}`} />
                )}
              />
              {organizationRoutes}
              {defaultOrganization && (
                <Route
                  path="/billing"
                  render={() => <Redirect to={`/${defaultOrganization.slug}/settings/billing`} />}
                />
              )}
              <Route path="/sharing/items/:issueId" render={() => <SharedIssueScreen />} />
              <Route path="/sharing/board/:boardId" render={() => <SharedBoardScreen />} />
              <Route path="/sharing/roadmaps/:roadmapId" render={() => <SharedRoadmapScreen />} />
              <Route path="/login" render={() => <Redirect to="/" />} />
              <Route path="/loginsso" render={() => <Redirect to="/" />} />
              <Route path="/signup" render={() => <Redirect to="/" />} />
              <Route path="/forgotpassword" render={() => <Redirect to="/" />} />
              <Route path="/resetpassword" render={() => <Redirect to="/" />} />
              <Route path="/create" render={() => <Redirect to="/" />} />
              <Route path="/terms" render={() => <Redirect to="/" />} />
              <Route path="/linkExternalAccount" render={() => <Redirect to="/" />} />
              <Route path="/install/:type" render={() => <OAuthInstallFlowScreen />} />
              <Route component={NotFoundScreen} />
            </Switch>
          )}
          {organizations.length === 0 && (
            <Switch>
              <Route path="/login" render={() => <Redirect to="/" />} />
              <Route path="/loginsso" render={() => <Redirect to="/" />} />
              <Route path="/signup" render={() => <Redirect to="/" />} />
              <Route path="/forgotpassword" render={() => <Redirect to="/" />} />
              <Route path="/resetpassword" render={() => <Redirect to="/" />} />
              <Route path="/create" render={() => <Redirect to="/" />} />
              <Route path="/terms" render={() => <Redirect to="/" />} />
              <Route path="/linkExternalAccount" render={() => <Redirect to="/" />} />
              <Route component={NoOrganizationsScreen} />
            </Switch>
          )}
        </UserProvider>
      </ConfirmationProvider>
    </UndoProvider>
  );
}

function LoggedOutRoutes({
  refreshLoggedInState,
}: {
  refreshLoggedInState: (result: AuthResult) => void;
}) {
  const location = useLocation();

  React.useEffect(() => {
    (async () => await configureElectronApp([]))();
  }, []);

  return (
    <TransitionGroup component={'div'} className={styles.loggedOutScreen}>
      <CSSTransition timeout={150} classNames="fade" key={location.key}>
        <Switch location={location}>
          <Route
            path="/login/:token?"
            render={() => <LoginScreen onCompleted={refreshLoggedInState} />}
          />
          <Route path="/signup/:token?" render={() => <RegisterScreen />} />
          <Route
            path="/create"
            render={() => <NewOrganizationScreen onCompleted={refreshLoggedInState} />}
          />
          <Route path="/terms" render={() => <TermsScreen onCompleted={refreshLoggedInState} />} />
          <Route
            path="/linkExternalAccount"
            render={() => <LinkAccountScreen onCompleted={refreshLoggedInState} />}
          />
          <Route path="/pendinginvite" render={() => <PendingInviteScreen />} />
          <Route path="/forgotpassword" render={() => <ForgotPasswordScreen />} />
          <Route path="/resetpassword/:token" render={() => <ResetPasswordScreen />} />
          <Route path="/sharing/items/:issueId" render={() => <SharedIssueScreen />} />
          <Route path="/sharing/board/:boardId" render={() => <SharedBoardScreen />} />
          <Route path="/sharing/roadmaps/:roadmapId" render={() => <SharedRoadmapScreen />} />
          <Route
            render={(props: RouteComponentProps) => (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )}
          />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App({ fetchData }: { fetchData: () => Promise<void> }) {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const currentUser = useRecoilValue(currentUserState);
  const { setConfiguration } = useConfiguration();

  const refreshLoggedInState = React.useCallback(
    async ({ redirect: redirectFromServer, config }: AuthResult) => {
      if (config.europeanDataResidency) {
        disableTracking();
      }

      if (redirectFromServer) {
        pendingRedirect = redirectFromServer;
      } else if (location.state?.from) {
        pendingRedirect = `${location.state.from.pathname}${location.state.from.search}`;
      }

      setConfiguration(config);
      await fetchData();
    },
    [setConfiguration, history.location, fetchData]
  );

  if (!currentUser) {
    return <LoggedOutRoutes refreshLoggedInState={refreshLoggedInState} />;
  }

  return <LoggedInRoutes user={currentUser} />;
}

function NoPointerEventObserver({
  onNoPointerEventsChanged,
}: {
  onNoPointerEventsChanged: (noPointerEvents: boolean) => void;
}) {
  useComponentDidMount(() => {
    const config = { attributes: true, childList: false, subtree: false };
    const callback: MutationCallback = (mutationList, _observer) => {
      if (!isMac) {
        return;
      }
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          onNoPointerEventsChanged(getComputedStyle(document.body).pointerEvents === 'none');
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document.body, config);

    return () => {
      observer.disconnect();
    };
  });

  return null;
}

function AppWrapper() {
  useNoDuplicateHistory();
  useWatchMediaQueries();
  useUpdateRecentEntities();
  useUpdateEntityNumberWidths();
  useMouseMoveTracker();

  const modelManager = useModelManager();
  const currentUserMarker = useRecoilValue(currentUserMarkerState);
  const location = useLocation();
  const { error, errorMessage, maintenanceMode } = useConfiguration();
  const tinyScreen = useIsTinyScreen();

  React.useEffect(() => {
    window.radixPopperShiftCrossAxis = tinyScreen;
  }, [tinyScreen]);

  const maintenanceModeOverride = React.useMemo(() => {
    if (!maintenanceMode) {
      return false;
    }
    return parseBoolean(safeLocalStorageGet('__maintenanceModeOverride', 'false'));
  }, [maintenanceMode]);

  const fetchData = React.useCallback(async () => {
    const pathname = location.pathname;
    const components = pathname.split('/').filter(component => !!component);
    const maybeOrganizationSlug = components[0] ?? '';
    const organizationSlug = maybeOrganizationSlug.match(/.+-[A-Za-z0-9_-]+/)
      ? maybeOrganizationSlug
      : undefined;
    const maybeSpaceSlug = components[1] ?? '';
    const spaceSlug = maybeSpaceSlug.match(/.+-[A-Za-z0-9_-]+/) ? maybeSpaceSlug : undefined;

    let board: 'current' | 'backlog' | 'archive' | undefined = undefined;
    if (components[2] === 'boards' && components[3] === 'current') {
      board = 'current';
    } else if (components[2] === 'boards' && components[3] === 'backlog') {
      board = 'backlog';
    } else if (components[2] === 'archive') {
      board = 'archive';
    }

    let workItemNumber: string | undefined = undefined;
    if (
      components[2] === 'items' &&
      components[3] &&
      !looksLikeId(components[3]) &&
      components[3].match(/^[0-9]+$/)
    ) {
      workItemNumber = components[3];
    }

    let orgScreen: 'search' | 'my' | undefined = undefined;
    if (components[1] === 'search') {
      orgScreen = 'search';
    } else if (components[1] === 'my') {
      orgScreen = 'my';
    }

    await modelManager.loadFromCache(organizationSlug);
    await modelManager.fetchData({
      organizationSlug,
      spaceSlug,
      board,
      workItemNumber,
      orgScreen,
    });
  }, [modelManager, location.pathname]);

  useComponentDidMount(() => {
    if (error) {
      return;
    }

    if (maintenanceMode && !maintenanceModeOverride) {
      setTimeout(() => {
        window.location.reload();
      }, 60000);
      return;
    }
    fetchData();
  });

  // Setup Intercom
  const { intercomAppId } = useConfiguration();
  useComponentDidMount(() => {
    if (intercomAppId) {
      window.Intercom?.('boot', {
        app_id: intercomAppId,
        hide_default_launcher: true,
      });
      window.Intercom?.('onHide', () => {
        // FIXME: Doesn't set the focus back on the body...
        document.body.focus();
      });
    }
  });

  if (maintenanceMode && !maintenanceModeOverride) {
    return <MaintenanceModeScreen />;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{errorMessage}</BasicErrorScreen>;
  }

  if (!currentUserMarker) {
    return <LoadingScreen />;
  }

  return <App fetchData={fetchData} />;
}

const debug = debugModule('profiler');

function BodyClassnames() {
  const hotkeysInitiallyDisabled = useRecoilValue(hotkeysInitiallyDisabledAtom);
  const animationsDisabled = useRecoilValue(animationsDisabledSelector);
  const wideDescriptions = useRecoilValue(wideDescriptionsSelector);
  const [bodyNoPointerEvents, setBodyNoPointerEvents] = React.useState(false);
  const { darkMode } = useDarkMode();
  const [alwaysOnScrollbars, setAlwaysOnScrollbars] = React.useState(() => {
    return checkForAlwaysOnScrollbars();
  });

  React.useEffect(() => {
    document.body.className = cn({
      'dark-theme': darkMode,
      mobile: isMobileOS,
      hotkeysInitiallyDisabled,
      iOS: isIOS,
      firefox: isFirefox,
      macOS: isMac,
      notMacOS: !isMac,
      safari: isSafari,
      alwaysOnScrollbars,
      noPointerEvents: bodyNoPointerEvents,
      noAnimations: animationsDisabled,
      wideDescriptions: wideDescriptions,
    });
  }, [
    darkMode,
    hotkeysInitiallyDisabled,
    animationsDisabled,
    bodyNoPointerEvents,
    alwaysOnScrollbars,
    wideDescriptions,
  ]);

  useComponentDidMount(() => {
    function visibilityChanged() {
      setAlwaysOnScrollbars(checkForAlwaysOnScrollbars());
    }

    window.addEventListener('focus', visibilityChanged);
    return () => window.removeEventListener('focus', visibilityChanged);
  });

  return <NoPointerEventObserver onNoPointerEventsChanged={setBodyNoPointerEvents} />;
}

function Index() {
  const clientId = useClient();
  function clockPerformance(profilerId: string, mode: 'mount' | 'update', actualTime: number) {
    debug({ profilerId, mode, actualTime });
  }

  React.useEffect(() => {
    if (!isValidLinkToOpenInApp) {
      window.sessionStorage.setItem(OPEN_IN_APP_DISABLED_KEY, 'true');
    }
  }, []);

  const openInAppSettingActive = React.useMemo(() => {
    if (isElectron()) {
      return false;
    }

    if (isOpeningInAppDisabledForSession()) {
      return false;
    }

    return parseBoolean(safeLocalStorageGet(localStorageOpenInAppKey, 'false'));
  }, []);

  const [openInAppOverride, setOpenInAppOverride] = React.useState(false);
  const openInApp = isValidLinkToOpenInApp && openInAppSettingActive && !openInAppOverride;

  return (
    <RecoilRoot>
      <HotkeyScope depth={0}>
        <Profiler id="kitemaker_base" onRender={clockPerformance}>
          <PersistentSettingsProvider>
            <DarkMode>
              <BodyClassnames />
              <TooltipProvider>
                <ConfigurationProvider>
                  <div
                    id="app"
                    className={cn(styles.app, {
                      electron: isElectron(),
                    })}
                  >
                    {openInApp && (
                      <OpenInAppScreen
                        onOverride={() => {
                          window.sessionStorage.setItem(OPEN_IN_APP_DISABLED_KEY, 'true');
                          setOpenInAppOverride(true);
                        }}
                      />
                    )}
                    {!openInApp && (
                      <>
                        <SentryManager />
                        <GraphQLProvider>
                          <ModalProvider>
                            <ModelManagerProvider clientId={clientId}>
                              <Router
                                getUserConfirmation={() => {
                                  /* Empty callback to block the default browser prompt */
                                }}
                              >
                                <VersionCheck />
                                <AppWrapper />
                                <BasicModals />
                                <Toast />
                              </Router>
                            </ModelManagerProvider>
                          </ModalProvider>
                        </GraphQLProvider>
                      </>
                    )}
                  </div>
                </ConfigurationProvider>
              </TooltipProvider>
            </DarkMode>
          </PersistentSettingsProvider>
          <canvas id="textMeasurer" style={{ position: 'absolute', left: -10000, top: -10000 }} />
          <AvatarClipPath />
        </Profiler>
      </HotkeyScope>
    </RecoilRoot>
  );
}

// NOTE: this needs to be called before we mount the React stuff to the DOM. It works
// around a weird bug caused by translation extensions mucking with the DOM.
translateFix();

const container = document.getElementById('main');
const root = createRoot(container!);
root.render(<Index />);
