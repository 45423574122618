import cn from 'classnames';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { Timestamp } from '../../components/new/timestamp';
import { renderDate } from '../../utils/datetime';
import { ChatMessageElement, OptionalAttributesRenderElementProps } from '../types';
import styles from './chatMessage.module.scss';

export function StaticChatMessage({
  attributes,
  children,
  element,
}: OptionalAttributesRenderElementProps & { element: ChatMessageElement }) {
  const { direction } = element;
  return (
    <div
      {...attributes}
      className={cn('block', 'row', styles.chatMessageContainer)}
      style={{
        justifyContent: direction === 'incoming' ? 'flex-start' : 'flex-end',
      }}
    >
      <div className={cn(styles.chatMessage, { [styles.outgoing]: direction === 'outgoing' })}>
        <div contentEditable={false} className={styles.header}>
          <span>{element.sender}</span>
          <span className="grayed bodyM ml8">{renderDate(element.timestamp)} </span>
        </div>
        {children}
      </div>
    </div>
  );
}

export function ChatMessage({
  attributes,
  children,
  element,
}: RenderElementProps & { element: ChatMessageElement }) {
  const { direction } = element;
  return (
    <div
      {...attributes}
      className={cn('block', 'row', styles.chatMessageContainer)}
      style={{
        justifyContent: direction === 'incoming' ? 'flex-start' : 'flex-end',
      }}
    >
      <div className={cn(styles.chatMessage, { [styles.outgoing]: direction === 'outgoing' })}>
        <div contentEditable={false} className={styles.header}>
          <span>{element.sender}</span>
          <Timestamp className="bodyM grayed ml8" timestamp={element.timestamp} />
        </div>
        {children}
      </div>
    </div>
  );
}
