import { last } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import {
  PreparedGitHubImport,
  checkGithubToken,
  fetchReposFromGitHub,
  importFromGitHub,
  prepareImportFromGitHub,
} from '../../../../api/github';
import BackButton from '../../../../components/new/backButton';
import { Button, ButtonStyle } from '../../../../components/new/button';
import { Icon, IconSize } from '../../../../components/new/icon';
import { LoadingSpinner } from '../../../../components/new/loadingSpinner';
import { Setting, SettingsPage, SettingsSection } from '../../../../components/new/settings';
import { Switch } from '../../../../components/new/switch';
import { TextValidationInput } from '../../../../components/new/textInput';
import SelectButton from '../../../../components/selectButton';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { organizationPath } from '../../../../syncEngine/selectors/organizations';
import { spacesForOrganizationSelector } from '../../../../syncEngine/selectors/spaces';
import { stringifyIntegrationType } from '../../../../utils/integrations';
import styles from './import.module.scss';
import { ImportAssigneePicker } from './importAssigneePicker';
import { ImportType } from '.';

function Import({ onImportCompleted }: { onImportCompleted: () => void }) {
  const organization = useOrganization();
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));

  const [repo, setRepo] = React.useState('');
  const [importClosed, setImportClosed] = React.useState(true);
  const [importLabels, setImportLabels] = React.useState(true);
  const [allRepos, setAllRepos] = React.useState<string[]>([]);
  const [reposFetched, setReposFetched] = React.useState(false);
  const [preparing, setPreparing] = React.useState(false);
  const [importInProgress, setImportInProgress] = React.useState(false);
  const [preparedImport, setPreparedImport] = React.useState<PreparedGitHubImport | null>(null);
  const [mapAssignees, setMapAssignees] = React.useState<{ [index: string]: string }>({});
  const [newSpaceName, setNewSpaceName] = React.useState('');

  const defaultPath = organizationPath(organization, 'settings/import');

  const newSpaceNameAlreadyExists = !!spaces.find(
    s => s.name.toLowerCase() === newSpaceName.toLowerCase()
  );

  const [importCount, setImportCount] = React.useState(0);

  React.useEffect(() => {
    if (!preparedImport) {
      setImportCount(0);
      return;
    }
    const importing = importClosed
      ? preparedImport.newClosedIssueCount + preparedImport.newOpenIssueCount
      : preparedImport.newOpenIssueCount;

    setImportCount(importing);
  }, [importClosed, preparedImport]);

  React.useEffect(() => {
    (async () => {
      const fetchedRepos = await fetchReposFromGitHub();
      setAllRepos(fetchedRepos);
      setReposFetched(true);
    })();
  }, []);

  React.useEffect(() => {
    setPreparedImport(null);
    setMapAssignees({});
    if (!allRepos.includes(repo)) {
      return;
    }

    setPreparing(true);
    (async () => {
      const preparedImportResult = await prepareImportFromGitHub(repo, organization);
      setPreparing(false);
      setPreparedImport(preparedImportResult);

      let count = 1;
      const repoName = last(repo.split('/').filter(s => !!s))!;
      let newName = repoName;

      while (spaces.find(s => s.name === newName)) {
        newName = `${repoName}${count}`;
        count += 1;
      }
      setNewSpaceName(newName);
    })();
  }, [repo]);

  async function startImport() {
    if (importInProgress) {
      return;
    }
    setImportInProgress(true);

    await importFromGitHub({
      id: preparedImport!.id,
      spaceName: newSpaceName,
      importClosedIssues: importClosed,
      importLabels: importLabels,
      mapAssignees,
    });

    setImportInProgress(false);
    onImportCompleted();
  }

  if (!reposFetched) {
    return (
      <SettingsPage title="Import from GitHub">
        <div className="colCenter mt32">
          <LoadingSpinner />
          <p className="mt16">Fetching repos</p>
        </div>
      </SettingsPage>
    );
  }

  return (
    <SettingsPage title="Import from GitHub">
      <SettingsSection>
        <Setting title="Source repository">
          <SelectButton
            placeholder="Please select a repo"
            value={repo}
            propertiesToSearch={['name']}
            items={allRepos.map(repo => ({
              id: repo,
              name: repo,
              contents: <>{repo}</>,
            }))}
            onSelect={setRepo}
          />
        </Setting>

        {preparing && (
          <div className="colCenter mt32">
            <LoadingSpinner />
            <p className="mt16">Preparing import</p>
          </div>
        )}
      </SettingsSection>

      {preparedImport && (
        <>
          <SettingsSection title={`Importing ${importCount} issues`}>
            <Setting title="Import closed issues">
              <Switch checked={importClosed} onChange={setImportClosed} />
            </Setting>
            <Setting title="Import labels">
              <Switch checked={importLabels} onChange={setImportLabels} />
            </Setting>
          </SettingsSection>

          <Setting title="Space name" description="Give your new space a name">
            <TextValidationInput
              value={newSpaceName}
              error={
                newSpaceNameAlreadyExists ? 'A space with that name already exists' : undefined
              }
              onChange={e => setNewSpaceName(e.currentTarget.value)}
            />
          </Setting>

          <SettingsSection
            title={`Import assignees (${Object.keys(mapAssignees).length}/${
              preparedImport.assignees.length
            })`}
            description={`Choose existing Kitemaker users or invite your teammates to Kitemaker to automatically import ${stringifyIntegrationType(
              IntegrationType.Github
            )} assignees`}
          >
            <ImportAssigneePicker
              assignees={preparedImport.assignees}
              mapAssignees={mapAssignees}
              onMapAssigneesChanged={setMapAssignees}
            />
          </SettingsSection>
        </>
      )}

      <SettingsSection>
        <div className="rowBetween">
          <BackButton defaultPath={defaultPath} hideIcon>
            Back
          </BackButton>
          <Button
            buttonStyle={ButtonStyle.Primary}
            disabled={!preparedImport || !newSpaceName || importInProgress}
            onClick={() => startImport()}
          >
            {!importInProgress && <span>Import</span>}
            {importInProgress && (
              <>
                Importing&nbsp;
                <LoadingSpinner />
              </>
            )}
          </Button>
        </div>
      </SettingsSection>
    </SettingsPage>
  );
}

function Auth({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();
  const defaultPath = organizationPath(organization, 'settings/import');

  async function startAuthFlow() {
    await externalAuthFlow(`${host}/import/github/auth`, electronScheme, {
      exchange: true,
      redirectQueryParams: {
        pendingImport: ImportType.ImportGitHubIssueToSpace,
        forceRefresh: 'true',
      },
      redirectBaseUrl: redirectUrl,
    });
  }

  return (
    <SettingsPage
      title="Import from GitHub"
      description="Create a new space with all of the issues from a GitHub repository"
    >
      <p>The process of importing is just a few easy steps:</p>
      <ul className="mb32">
        <li>We'll need to authenticate you with GitHub so we can access your data</li>
        <li>
          You'll choose which repository you want to import from and give your new space a name
        </li>
        <li>You'll choose whether or not you want to import closed issues or not</li>
        <li>You'll choose whether to import labels from GitHub or not</li>
        <li>
          You'll optionally map assignees from your GitHub issues to Kitemaker users so that people
          were assigned to the GitHub issues will automatically be members of the Kitemaker work
          items
        </li>
      </ul>
      <SettingsSection>
        <div className="rowBetween">
          <BackButton hideIcon defaultPath={defaultPath}>
            Back
          </BackButton>
          <Button buttonStyle={ButtonStyle.Primary} onClick={startAuthFlow}>
            Authenticate
          </Button>
        </div>
      </SettingsSection>
    </SettingsPage>
  );
}

export function GiHhubImportScreen({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const [tokenChecked, setTokenChecked] = React.useState(false);
  const [hasToken, setHasToken] = React.useState(false);
  const [importCompleted, setImportCompleted] = React.useState(false);
  const defaultPath = organizationPath(organization, 'settings/import');

  React.useEffect(() => {
    (async () => {
      const haveToken = await checkGithubToken();
      setHasToken(haveToken);
      setTokenChecked(true);
    })();
  }, []);

  if (importCompleted) {
    return (
      <SettingsPage title="Import from GitHub">
        <div className="colCenter mt32">
          <Icon icon="success" size={IconSize.Size40} className={styles.successIcon} />
          <p className="mt16">Import started</p>
        </div>

        <SettingsSection>
          <BackButton defaultPath={defaultPath} hideIcon>
            Back
          </BackButton>
        </SettingsSection>
      </SettingsPage>
    );
  }

  return (
    <>
      {!tokenChecked && (
        <SettingsPage title="Import from GitHub">
          <div className="colCenter mt32">
            <LoadingSpinner />
            <p className="mt16">Verifying GitHub token</p>
          </div>
        </SettingsPage>
      )}
      {tokenChecked && !hasToken && <Auth redirectUrl={redirectUrl} />}
      {tokenChecked && hasToken && <Import onImportCompleted={() => setImportCompleted(true)} />}
    </>
  );
}
