import * as React from 'react';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from './basicErrorScreen';
import styles from './errorScreen.module.scss';

export function DeletedScreen({
  type,
}: {
  type: string;
}) {
  React.useEffect(() => {
    trackerPageLoad('Deleted');
  }, []);

  return (
    <BasicErrorScreen>
      <div className={styles.errorHeading}>{`The ${type} you were on has been deleted!`}</div>
    </BasicErrorScreen>
  );
}
