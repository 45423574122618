export enum InitiativeStatus {
  NotStarted,
  Started,
  Completed,
  Archived,
}

export function initiativeStatusToString(status: InitiativeStatus) {
  switch (status) {
    case InitiativeStatus.NotStarted:
      return 'Not started';
    case InitiativeStatus.Started:
      return 'Started';
    case InitiativeStatus.Completed:
      return 'Completed';
    case InitiativeStatus.Archived:
      return 'Archived';
  }
}
