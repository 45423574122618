import { capitalize } from 'lodash';
import * as React from 'react';
import { Release, ReleaseStatus } from '../../../sync/__generated/models';
import { Icon, IconSize } from '../../components/new/icon';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import Pill from '../../components/new/metadata/pill';
import { MetadataSize } from '../../components/new/metadata/size';
import { useUpdateReleases } from '../../syncEngine/actions/releases';
import { releaseStatusName } from '.';

export function ReleaseStatusIndicator({
  release,
  size,
  interactable,
}: {
  release: Release;
  size?: MetadataSize;
  interactable?: boolean;
}) {
  const updateReleases = useUpdateReleases();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const content = (
    <Pill size={size}>
      <Icon
        size={size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20}
        icon={release.releaseStatus === ReleaseStatus.Released ? 'released' : 'release'}
      />
      {capitalize(releaseStatusName(release.releaseStatus))}
    </Pill>
  );

  if (!interactable) {
    return content;
  }

  return (
    <div>
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger
          asChild
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div>{content}</div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onClick={e => {
            e.stopPropagation();
          }}
          side="bottom"
          align="start"
          className="menuHuge"
        >
          {Object.values(ReleaseStatus).map(status => (
            <DropdownMenuCheckboxItem
              key={status}
              onSelect={() =>
                updateReleases([release.id], { releaseStatus: status as ReleaseStatus })
              }
              checked={release.releaseStatus === status}
            >
              {capitalize(releaseStatusName(status))}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
