import cn from 'classnames';
import { isFunction, isUndefined, uniq } from 'lodash';
import * as React from 'react';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { scrollIntoView } from '../../utils/scrolling';
import Hotkey from '../hotkey';
import { FilteredListView, FilteredListViewProps } from './filteredListView';
import { Icon, IconSize } from './icon';
import { KeyboardShortcut } from './keyboardShortcut';
import {
  KeyNavigationElement,
  KeyNavigationProvider,
  useHasKeyNavigationFocus,
  useHasKeyNavigationSelection,
} from './keyNavigation';
import { LISTVIEW_ID, ListViewItem, NO_KEYNAV } from './listView';
import menuStyles from './menu/menu.module.scss';
import styles from './picker.module.scss';

export type PickerState = { [index: string]: string[] };

export interface PickerProps<T extends ListViewItem> extends FilteredListViewProps<T> {
  state: PickerState;
  multi?: boolean;
  noSort?: boolean;
  hideFooter?: boolean;
  onAdd: (stateIds: string[], itemId: string) => void;
  onRemove: (stateIds: string[], itemId: string) => void;
  onDone?: () => void;
}

export enum PickedState {
  Some = 'some',
  All = 'all',
}

export function mapPickerState(state: PickerState): { [index: string]: PickedState } {
  const itemIdLists: string[][] = Object.values(state);
  const itemIds: string[] = uniq(itemIdLists.flat());
  const result: { [index: string]: PickedState } = {};

  for (const itemId of itemIds) {
    if (itemIdLists.every(list => list.includes(itemId))) {
      result[itemId] = PickedState.All;
    } else {
      result[itemId] = PickedState.Some;
    }
  }
  return result;
}

export function pickedStateToIcon(state?: PickedState, multi?: boolean): string | null {
  if (multi) {
    if (!state) {
      return 'multiselect_blank';
    }
    return state === PickedState.Some ? 'multiselect_half' : 'multiselect_checkmark';
  }

  if (!state) {
    return 'none';
  }
  return 'select_checkmark';
}

export function PickerFooter() {
  return (
    <div className={cn(menuStyles.item, styles.footer)}>
      Press <KeyboardShortcut shortcut="shift+enter" /> to select multiple
    </div>
  );
}

export function PickerItem({
  item,
  scrollIntoView: shouldScrollIntoView,
}: {
  item: ListViewItem;
  scrollIntoView?: boolean;
}) {
  const {
    contents,
    icon,
    hotkey,
    className: customClassName,
    selectedClassName: customSelectedClassName,
    onSelected,
    mouseDown,
    id,
  } = item;

  const selected = useHasKeyNavigationSelection(id);
  const focused = useHasKeyNavigationFocus(id);
  const ref = React.useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    if (shouldScrollIntoView && ref.current) {
      scrollIntoView(ref.current, { block: 'start' });
    }
  });

  if (id === NO_KEYNAV) {
    return (
      <div id={id} ref={ref} className={cn(menuStyles.item)}>
        {isFunction(contents) ? (
          contents(selected)
        ) : (
          <div className={cn(menuStyles.text, styles.placeholder)}>{contents}</div>
        )}
      </div>
    );
  }

  return (
    <KeyNavigationElement
      id={id}
      ref={ref}
      className={cn(
        menuStyles.item,
        styles.item,
        {
          [styles.focused]: focused,
          [customSelectedClassName ?? '']: selected,
        },
        customClassName
      )}
      onMouseDown={e => {
        if (onSelected && mouseDown && e.button === 0) {
          e.preventDefault();
          e.stopPropagation();
          onSelected(e.shiftKey);
        }
      }}
      onClick={e => {
        if (onSelected && !mouseDown) {
          e.preventDefault();
          e.stopPropagation();
          onSelected(e.shiftKey);
        }
      }}
    >
      {onSelected && focused && (
        <>
          <Hotkey
            command={{
              id: `select-and-done-${id}`,
              hotkey: 'enter',
              global: true,
              handler: e => {
                e?.preventDefault();
                e?.stopPropagation();
                onSelected(false);
              },
            }}
          />
          <Hotkey
            command={{
              id: `select-${id}`,
              hotkey: 'shift+enter',
              global: true,
              handler: e => {
                e?.preventDefault();
                e?.stopPropagation();
                onSelected(true);
              },
            }}
          />
        </>
      )}
      {!isUndefined(icon) && typeof icon !== 'string' && icon}
      {!isUndefined(icon) && typeof icon === 'string' && icon && (
        <Icon
          style={{ fill: 'var(--foreground-color)' }}
          onMouseDown={e => {
            if (onSelected && mouseDown && e.button === 0) {
              e.preventDefault();
              e.stopPropagation();
              onSelected(true);
            }
          }}
          onClick={e => {
            if (onSelected && !mouseDown) {
              e.preventDefault();
              e.stopPropagation();
              onSelected(true);
            }
          }}
          size={IconSize.Size20}
          icon={icon}
        />
      )}
      {isFunction(contents) ? (
        contents(selected)
      ) : (
        <div className={menuStyles.text}>{contents}</div>
      )}
      {hotkey && (
        <>
          <KeyboardShortcut shortcut={hotkey} />
          {onSelected && (
            <Hotkey
              command={{
                id: `hotkey-${id}`,
                hotkey,
                handler: e => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  onSelected(false);
                },
              }}
            />
          )}
        </>
      )}
    </KeyNavigationElement>
  );
}

function PickerComponent<T extends ListViewItem>(
  {
    state,
    multi,
    items,
    noSort,
    onAdd,
    onRemove,
    onDone,
    filterClassName,
    defaultItems,
    hideFooter,
    className,
    ...props
  }: PickerProps<T>,
  ref: any
) {
  const [filter, setFilter] = React.useState('');
  const mappedState = mapPickerState(state);
  const [multiSelectMode, setMultiSelectMode] = React.useState(false);

  const [done, setDone] = React.useState(false);

  // we don't want selected items to jump all over as they're selected, so we'll try to keep them
  // in the same place unless the filter is changed
  const stableMappedState = React.useMemo(() => mappedState, [filter]);

  // we want things that are already in the state to bubble to the top so we always show
  // users the already picked stuff before they filter
  const sortedItems = noSort
    ? items
    : items.sort((itemA, itemB) => {
        // disabled items at the bottom
        if (!itemA.disabled && itemB.disabled) {
          return -1;
        }
        if (itemA.disabled && !itemB.disabled) {
          return 1;
        }

        if (
          stableMappedState[itemA.id] === PickedState.All &&
          stableMappedState[itemB.id] !== PickedState.All
        ) {
          return -1;
        }
        if (
          stableMappedState[itemB.id] === PickedState.All &&
          stableMappedState[itemA.id] !== PickedState.All
        ) {
          return 1;
        }
        if (stableMappedState[itemA.id] === PickedState.Some && !stableMappedState[itemB.id]) {
          return -1;
        }
        if (stableMappedState[itemB.id] === PickedState.Some && !stableMappedState[itemA.id]) {
          return 1;
        }

        // default to sort order we got in
        return 0;
      });

  const statefulItems: ListViewItem[] = sortedItems.map((item: ListViewItem) => ({
    icon: pickedStateToIcon(mappedState[item.id], multi),
    onSelected: (shift: boolean) => {
      if ((onDone && done) || item.disabled) {
        return;
      }

      if (!mappedState[item.id]) {
        onAdd(Object.keys(state), item.id);
      } else if (mappedState[item.id] === PickedState.All && (!multiSelectMode || shift)) {
        onRemove(Object.keys(state), item.id);
      } else {
        onAdd(
          Object.keys(state).filter(stateId => !state[stateId].includes(item.id)),
          item.id
        );
      }
      if (!shift) {
        setDone(true);
        onDone?.();
      } else {
        setMultiSelectMode(true);
      }
    },
    ...item,
    className: cn(item.className, { [styles.disabled]: item.disabled }),
  }));

  // in the case where no filter exists, we want to ensure that selected things always
  // come first, regardless of what has been passed in as defaultItems
  const defaults = defaultItems
    ? uniq([
        ...sortedItems.filter(item => stableMappedState[item.id]).map(item => item.id),
        ...defaultItems,
      ])
    : undefined;

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <div className={cn('col fullWidth', styles.picker)}>
        <FilteredListView
          ref={ref}
          items={statefulItems}
          defaultItems={defaults}
          className={cn(styles.list, className)}
          filterClassName={filterClassName}
          selectFirstOnItemsChanged={true}
          onFilterChanged={setFilter}
          itemRenderFunction={item => <PickerItem key={item.id} item={item} />}
          placeholderClassName={props.placeholderClassName ?? styles.filterPlaceholder}
          {...(props as any)}
        />
        {multi && !hideFooter && (
          <>
            <div className={menuStyles.separator} />
            <PickerFooter />
          </>
        )}
      </div>
    </KeyNavigationProvider>
  );
}

export const Picker = React.forwardRef(PickerComponent);

export interface SimplifiedPickerProps<T extends ListViewItem> extends FilteredListViewProps<T> {
  state: string[];
  noSort?: boolean;
  onAdd: (itemId: string) => void;
  onRemove: (itemId: string) => void;
  onDone: () => void;
}

function SimplifiedPickerCompoent<T extends ListViewItem>(
  { state, onAdd, onRemove, onDone, ...rest }: SimplifiedPickerProps<T>,
  ref: any
) {
  return (
    <Picker
      ref={ref}
      state={state}
      onAdd={(_, itemId) => onAdd(itemId)}
      onRemove={(_, itemId) => onRemove(itemId)}
      onDone={onDone}
      {...(rest as any)}
    />
  );
}

export const SimplifiedPicker = React.forwardRef(SimplifiedPickerCompoent);
