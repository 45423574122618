import { round, upperCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useClient } from 'urql';
import {
  BillingInformation,
  BillingProduct,
  checkoutSession,
  findProductAndPlan,
} from '../../../api/billing';
import ExternalLink from '../../../components/externalLink';
import { Button, ButtonStyle } from '../../../components/new/button';
import { Icon } from '../../../components/new/icon';
import { LoadingSpinner } from '../../../components/new/loadingSpinner';
import {
  SegmentedControl,
  SegmentedControlVariant,
} from '../../../components/new/segmentedControl';
import { SettingsPage, SettingsSection } from '../../../components/new/settings';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { isElectron } from '../../../electronIntegration';
import useBillingInfo from '../../../hooks/useBillingInfo';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { openExternalUrl } from '../../../utils/urls';
import styles from './settingsScreen.module.scss';

function redirectURL(host: string, electronScheme: string) {
  const url = window.location.href;
  if (!isElectron()) {
    return url;
  }

  const redirect = url.replace(host, '');
  return `${host}/billing/redirect?redirect=${electronScheme}:/${redirect}`;
}

function FormatPrice({ price, currency }: { price: number; currency: string }) {
  let currencySymbol: string | null = null;

  switch (currency.toLowerCase()) {
    case 'usd':
      currencySymbol = '$';
      break;
    case 'eur':
      currencySymbol = '€';
      break;
  }

  return (
    <span className="headingL mr6">
      {currencySymbol ? currencySymbol : `${upperCase(currency)} `}
      {round(price, 2)}
    </span>
  );
}

function Feature({ children }: { children: React.ReactNode }) {
  return (
    <div className="rowAlignStart mb8">
      <Icon icon={'select_checkmark'} className="mr8" />
      <span>{children}</span>
    </div>
  );
}

function ProductCard({ product }: { product: BillingProduct }) {
  enum Period {
    Yearly = 'yearly',
    Monthly = 'monthly',
  }

  const organization = useOrganization();
  const client = useClient();
  const { host, electronScheme } = useConfiguration();

  const [period, setPeriod] = React.useState<Period>(Period.Yearly);
  const features = product.description
    .split('|')
    .map((d, index) => <Feature key={index}>{d}</Feature>);

  let priceNode: React.ReactNode;
  if (product.id.startsWith('pro_')) {
    priceNode = <div className="headingL grayed">Custom pricing</div>;
  } else if (product.id.startsWith('standard_')) {
    const billingPlan = period === Period.Monthly ? product.monthlyPlan! : product.yearlyPlan!;
    priceNode = (
      <div className="row grayed">
        <FormatPrice
          price={period === Period.Monthly ? billingPlan.price : billingPlan.price / 12}
          currency={billingPlan.currency}
        />
        per user/month
      </div>
    );
  } else {
    priceNode = (
      <div>
        <div className="headingL grayed">Free</div>
      </div>
    );
  }

  return (
    <div className={styles.billingCard}>
      <div className="headingL">Upgrade to {product.displayName}</div>
      {priceNode}
      {product.id.startsWith('standard_') && (
        <div>
          <SegmentedControl<Period>
            value={period}
            onValueChanged={v => setPeriod(v)}
            variant={SegmentedControlVariant.LabelsOnly}
            options={[
              {
                id: Period.Monthly,
                icon: 'none',
                label: 'Monthly',
              },
              { id: Period.Yearly, icon: 'none', label: 'Yearly' },
            ]}
          />
        </div>
      )}
      <div>{features}</div>

      <div>
        {!organization.activeProductId?.startsWith('pro_') && (
          <Button
            buttonStyle={ButtonStyle.Primary}
            className="mr8"
            onClick={async () => {
              if (product.id.startsWith('pro_')) {
                const mailTo = `mailto:hi@kitemaker.co?subject=${encodeURIComponent(
                  'Kitemaker Pro'
                )}`;
                openExternalUrl(mailTo);

                return;
              }

              const checkoutUrl = await checkoutSession(
                client,
                organization.id,
                product.id,
                period === Period.Monthly ? product.monthlyPlan!.id : product.yearlyPlan!.id,
                redirectURL(host, electronScheme)
              );
              openExternalUrl(checkoutUrl);
            }}
          >
            {product.id.startsWith('pro_') && <>Contact sales</>}
            {!product.id.startsWith('pro_') && <>Upgrade to {product.displayName.split(' ')[0]}</>}
          </Button>
        )}
        <Button onClick={() => openExternalUrl('https://kitemaker.co/pricing')}>
          Compare plans
        </Button>
      </div>
    </div>
  );
}

function ExistingSubscription({ billingInformation }: { billingInformation: BillingInformation }) {
  const organization = useOrganization();
  const { product, plan } = findProductAndPlan(organization, billingInformation);

  let upgradeSection: JSX.Element | null;

  if (organization.activeProductId?.startsWith('standard_')) {
    const proTier = billingInformation.availableProducts.find(p => p.id.startsWith('pro_'));
    upgradeSection = proTier ? <ProductCard product={proTier} /> : null;
  } else if (organization.activeProductId?.startsWith('pro_')) {
    upgradeSection = null;
  } else {
    const standardTier = billingInformation.availableProducts.find(p =>
      p.id.startsWith('standard_')
    );
    upgradeSection = standardTier ? <ProductCard product={standardTier} /> : null;
  }

  return (
    <>
      <SettingsSection
        largeHeader
        title={product.displayName}
        description={
          <>
            {billingInformation.cancelAtPeriodEnd && (
              <>
                Your plan will be canceled on{' '}
                {moment(billingInformation.currentPeriodEnd).format('Do of MMMM YYYY')}.
              </>
            )}
            {!billingInformation.cancelAtPeriodEnd && billingInformation.nextInvoice && (
              <>
                Your total cost is{' '}
                <b>
                  {billingInformation.nextInvoice.currency === 'usd'
                    ? '$'
                    : `${upperCase(billingInformation.nextInvoice.currency)} `}
                  {plan?.period === 'yearly'
                    ? Math.round(billingInformation.nextInvoice.amount / 12)
                    : billingInformation.nextInvoice.amount}
                </b>{' '}
                per month, billed {plan?.period}. Your plan renews on{' '}
                {moment(billingInformation.currentPeriodEnd).format('Do of MMMM YYYY')}.
              </>
            )}
          </>
        }
      >
        <Button
          disabled={!billingInformation.customerPortalURL}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (billingInformation.customerPortalURL) {
              openExternalUrl(billingInformation.customerPortalURL);
            }
          }}
        >
          Manage subscription
        </Button>
      </SettingsSection>

      <SettingsSection>
        <div className="row metadataGapL">{upgradeSection}</div>
      </SettingsSection>

      <SettingsSection
        description={
          <>
            For questions about billing,{' '}
            <ExternalLink href="mailto:hi@kitemaker.co">please contact us</ExternalLink>
          </>
        }
      />
    </>
  );
}

function ActivateProductsSection({
  billingInformation,
}: {
  billingInformation: BillingInformation;
}) {
  const organization = useOrganization();

  let upgradeSection: JSX.Element | null;

  if (organization.activeProductId?.startsWith('standard_')) {
    const proTier = billingInformation.availableProducts.find(p => p.id.startsWith('pro_'));
    upgradeSection = proTier ? <ProductCard product={proTier} /> : null;
  } else if (organization.activeProductId?.startsWith('pro_')) {
    upgradeSection = null;
  } else {
    const standardTier = billingInformation.availableProducts.find(p =>
      p.id.startsWith('standard_')
    );
    upgradeSection = standardTier ? <ProductCard product={standardTier} /> : null;
  }

  const currentTier =
    billingInformation.availableProducts.find(p => p.id === organization.activeProductId) ??
    billingInformation.availableProducts.find(p => p.id === 'free')!;

  return (
    <>
      <SettingsSection
        largeHeader
        title={currentTier.displayName}
        description={
          <>
            You are currently on the {currentTier.displayName}
            {/* TODO: Add issue count status for free plan */}
          </>
        }
      ></SettingsSection>
      <SettingsSection>
        <div className="row metadataGapL">{upgradeSection}</div>
      </SettingsSection>
      <SettingsSection
        description={
          <>
            For questions about billing,{' '}
            <ExternalLink href="mailto:hi@kitemaker.co">please contact us</ExternalLink>
          </>
        }
      />
    </>
  );
}

export function BillingScreen() {
  const { host, electronScheme } = useConfiguration();
  const organization = useOrganization();
  const { billingInfo, loadBillingInfo } = useBillingInfo();
  const [loading, setLoading] = React.useState(true);

  useComponentDidMount(() => {
    if (billingInfo) {
      setLoading(false);
    }

    (async () => {
      await loadBillingInfo(redirectURL(host, electronScheme));
      setLoading(false);
    })();
  });

  if (loading) {
    return (
      <SettingsPage title="Billing">
        <div className="fullWidth rowCenter">
          <LoadingSpinner />
        </div>
      </SettingsPage>
    );
  }

  if (!billingInfo) {
    return (
      <SettingsPage title="Billing">
        Something went wrong retrieving your billing information. Please refresh.
      </SettingsPage>
    );
  }

  return (
    <SettingsPage title="Billing">
      {organization.activeProductId && <ExistingSubscription billingInformation={billingInfo} />}
      {!organization.activeProductId && (
        <ActivateProductsSection billingInformation={billingInfo} />
      )}
    </SettingsPage>
  );
}
