import { selectorFamily } from 'recoil';
import { filterNotNull, notDeleted } from '../../../shared/utils/convenience';
import { organizatiionLabelsByOrgnization } from '../../../sync/__generated/indexes';
import { OrganizationLabel } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';

export const organizationLabelSelector = selectorFamily({
  key: 'OrganizationLabel',
  get:
    (labelId: string) =>
    ({ get }) => {
      return notDeleted(get(syncEngineState(labelId)) as OrganizationLabel | null);
    },
});

export const organizationLabelsSelector = selectorFamily({
  key: 'OrganizationLabels',
  get:
    (labelIds: string[]) =>
    ({ get }) => {
      return filterNotNull(labelIds.map(id => get(organizationLabelSelector(id))));
    },
});

export const orgLabelsForOrganizationSelector = selectorFamily({
  key: 'OrgLabelsForOrganization',
  get:
    (organizationId?: string | null) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }
      const labelIds = get(
        indexKeyState(indexKey(organizatiionLabelsByOrgnization, organizationId))
      );
      return filterNotNull(labelIds.map(labelId => get(organizationLabelSelector(labelId))));
    },
});
