import * as React from 'react';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from './basicErrorScreen';
import styles from './errorScreen.module.scss';

export function NotFoundScreen() {
  React.useEffect(() => {
    trackerPageLoad('Not found');
  }, []);
  return (
    <BasicErrorScreen>
      <div className={styles.errorHeading}>Whoa, how did you find this place?</div>
      <div>Sorry, the page you were looking for does not exist.</div>
    </BasicErrorScreen>
  );
}
