import moment from 'moment';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Select } from '../../../components/new/select';
import { Setting, SettingsPage } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCurrentUser } from '../../../contexts/userContext';
import { useUpdateOrganizationMembers } from '../../../syncEngine/actions/organizationMember';
import { useUpdateUsers } from '../../../syncEngine/actions/users';
import { currentUserMembershipState } from '../../../syncEngine/selectors/users';
import { haveNotificationPermission } from '../../../utils/notifications';

const emailNotificationIntervals = [
  {
    id: 'ASAP',
    value: '1',
    label: 'ASAP',
  },
  {
    id: '1-hour',
    value: `${moment.duration(1, 'hours').asMilliseconds()}`,
    label: 'Once an hour',
  },
  {
    id: '4-hour',
    value: `${moment.duration(4, 'hours').asMilliseconds()}`,
    label: 'Once every four hours',
  },
  {
    id: '1-day',
    value: `${moment.duration(1, 'days').asMilliseconds()}`,
    label: 'Once a day',
  },
  { id: 'never', value: '', label: 'Never' },
];

export function NotificationSettingsScreen() {
  const [browserNotificationsEnabled, setBrowserNotificationsEnabled] = React.useState(
    haveNotificationPermission()
  );

  const organization = useOrganization();
  const user = useCurrentUser();
  const updateUsers = useUpdateUsers();
  const updateMembers = useUpdateOrganizationMembers();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));

  return (
    <SettingsPage title="Notifications">
      <Setting
        title={'Desktop notifications'}
        description={'You will receive desktop notifications from your operating system'}
      >
        <Switch
          checked={browserNotificationsEnabled}
          onChange={v => setBrowserNotificationsEnabled(v)}
        />
      </Setting>

      <Setting
        title={'New feedback notifications'}
        description={'You will recieve notifications about new feedback being created'}
      >
        <Switch
          checked={orgMembership?.feedbackNotificationsEnabled}
          onChange={v =>
            updateMembers([orgMembership!.id], {
              feedbackNotificationsEnabled: v,
            })
          }
        />
      </Setting>

      <Setting
        title={'Email notifications frequency'}
        description={'Set how often we should send you an email summary of your notifications'}
      >
        <Select
          placeholder="Select interval"
          value={user.emailNotificationDelay?.toString() ?? ''}
          values={emailNotificationIntervals}
          onChange={interval => {
            const parsed = parseInt(interval, 10);
            updateUsers([user.id], { emailNotificationDelay: parsed });
          }}
        />
      </Setting>
    </SettingsPage>
  );
}
