import * as React from 'react';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useLockKeyNavigation,
} from '../keyNavigation';

export function KeyNavigationDisablingElement({
  disableKey,
  children,
}: {
  disableKey: string;
  children?: React.ReactNode;
}) {
  const disable = useDisableKeyNavigation();
  const enable = useEnableKeyNavigation();

  useComponentDidMount(() => {
    disable(disableKey);
    return () => enable(disableKey);
  });
  return <>{children}</>;
}

export function KeyNavigationLockingElement({ children }: { children?: React.ReactNode }) {
  useLockKeyNavigation();
  return <>{children}</>;
}
