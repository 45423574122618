import cn from 'classnames';
import * as React from 'react';
import { Descendant } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { Elements, FileElement } from '../../../shared/slate/types';
import { CommentButton } from '../../components/new/commentButton';
import { DownloadButton } from '../../components/new/downloadButton';
import { Icon, IconSize } from '../../components/new/icon';
import { VoidActions } from '../../components/new/voidActions';
import { useConfiguration } from '../../contexts/configurationContext';
import UploadPlaceholder from '../../slate/components/uploadPlaceholder';
import { prettyFileSize } from '../../utils/units';
import { openExternalUrl } from '../../utils/urls';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import styles from './file.module.scss';
import { VoidBlock } from './voidBlock';

function nameFromUrl(url: string) {
  let name = url;
  name = name.replace('://', '');
  const lastUrlComponent =
    name.indexOf('/') !== -1 ? name.substring(name.lastIndexOf('/') + 1) : '';
  return lastUrlComponent || url;
}

export function StaticFile({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: FileElement }) {
  const { host } = useConfiguration();
  const { url, name, size } = element;

  if (!url) {
    return (
      <DummyNode element={element} attributes={attributes}>
        {children}
      </DummyNode>
    );
  }
  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;

  return (
    <div className={styles.fileContainer} {...attributes}>
      <div
        className={styles.file}
        onClick={e => {
          if (!fullUrl) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          openExternalUrl(fullUrl);
        }}
      >
        <Icon size={IconSize.Size20} icon="attachment" className="mr12" />
        <span className="mr8 headingS ellipsis oneLine">{name ?? nameFromUrl(url)}</span>
        {size && <span className="oneLine bodyM grayed">{prettyFileSize(size)}</span>}
      </div>
      <VoidActions className={styles.actions}>
        <DownloadButton url={downloadUrl} />
      </VoidActions>
      {children}
    </div>
  );
}

export function File({
  attributes,
  element,
  children,
}: RenderElementProps & { element: FileElement }) {
  const { host } = useConfiguration();
  const selected = useFocusedAndSelected();
  const editor = useSlateStatic();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  const { url, name, size } = element;

  if (!url) {
    return (
      <UploadPlaceholder
        requireUrl
        attributes={attributes}
        element={element}
        icon="attachment"
        placeholder="Add a file"
        focusedPlaceholder="Drop a file, upload from your computer (press enter), or paste a URL"
        onUploadPrepared={result => {
          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          const [first, ...rest] = result;
          KitemakerTransforms.setNodes(editor, first, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);

          if (rest.length) {
            const additionalNodes: Descendant[] = rest.map(file => ({
              type: Elements.File,
              ...file,
              children: [{ type: 'text', text: '' }],
            }));
            KitemakerTransforms.insertNodes(editor, additionalNodes);
            KitemakerTransforms.move(editor, {
              distance: additionalNodes.length - 1,
              unit: 'line',
            });
          }
        }}
        onSubmit={result => {
          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { url: result }, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }
  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <VoidBlock
        element={element}
        className={styles.fileContainer}
        onClick={e => {
          if (!fullUrl) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          openExternalUrl(fullUrl);
        }}
      >
        <div className="rowCenter">{children}</div>
        <div
          className={cn(styles.file, {
            [styles.voidElementSelected]: selected,
            [styles.voidElementInsight]: !!element.insightId,
          })}
        >
          <Icon size={IconSize.Size20} icon="attachment" className="mr12" />
          <span className="mr8 headingS ellipsis oneLine">{name ?? nameFromUrl(url)}</span>
          {size && <span className="oneLine bodyM grayed">{prettyFileSize(size)}</span>}
          <VoidActions className={styles.actions}>
            {editor.inlineComments && editor.entityId && <CommentButton element={element} />}
            <DownloadButton url={downloadUrl} />
          </VoidActions>
        </div>
      </VoidBlock>
    </div>
  );
}
