import moment from 'moment';

const TIME_FORMAT = 'LT';
const LONG_DATE_FORMAT = 'MMM D, YYYY';
const SAME_YEAR_DATE_FORMAT = 'MMM D';
const OTHER_YEARS_DATE_FORMAT = `MMM 'YY`;

export function renderDate(
  date: number,
  options?: {
    showTime?: boolean;
    compact?: boolean;
    daysAgo?: boolean;
    hoursAndMinutesAgo?: boolean;
    timezone?: string;
    ignoreYear?: boolean;
  }
): string {
  const now = moment();
  const then = options?.timezone ? moment(date).tz(options.timezone) : moment(date);
  const daysDiff = now.diff(then, 'days');
  const hoursDiff = now.diff(then, 'hours');
  const minutesDiff = now.diff(then, 'minutes');
  const secondsDiff = now.diff(then, 'seconds');

  const isThisYear = now.year() === then.year();
  const isToday = daysDiff === 0 && now.dayOfYear() === then.dayOfYear();

  if (secondsDiff >= 0 && secondsDiff < 60) {
    return 'now';
  }

  let renderedDate = then.format(LONG_DATE_FORMAT);
  if (isToday) {
    if (options?.hoursAndMinutesAgo && hoursDiff < 12) {
      if (hoursDiff < 1) {
        return `${minutesDiff} minute${minutesDiff === 1 ? '' : 's'} ago`;
      }
      return `${hoursDiff} hour${hoursDiff === 1 ? '' : 's'} ago`;
    }
    renderedDate = 'today';
  } else if (daysDiff < 7 && options?.daysAgo) {
    const days = Math.max(1, daysDiff);
    renderedDate = `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (isThisYear || options?.ignoreYear) {
    renderedDate = then.format(SAME_YEAR_DATE_FORMAT);
  } else if (options?.compact) {
    renderedDate = then.format(OTHER_YEARS_DATE_FORMAT);
  }

  const time = options?.showTime ? ` ${then.format(TIME_FORMAT)}` : '';
  return `${renderedDate}${time}`;
}

export function renderTime(date: number) {
  return moment(date).format(TIME_FORMAT);
}

export function renderDateLongForm(date: number) {
  const then = moment(date);
  return then.format(LONG_DATE_FORMAT);
}

export function isSameYear(d1: number, d2: number, opts?: { timezone?: string }): boolean {
  const date1 = opts?.timezone ? moment(d1).tz(opts.timezone) : moment(d1);
  const date2 = opts?.timezone ? moment(d2).tz(opts.timezone) : moment(d2);

  return date1.year() === date2.year();
}
