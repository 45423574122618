import cn from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';
import { TEXT_IDLE_TIMEOUT } from '../../utils/config';
import styles from './textInput.module.scss';

export enum TextInputType {
  Bare = 'bare',
  Input = 'input',
}
const typeClasses = {
  [TextInputType.Bare]: styles.bareType,
  [TextInputType.Input]: styles.inputType,
};

export enum TextInputSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

const sizeClasses = {
  [TextInputSize.Large]: styles.large,
  [TextInputSize.Medium]: styles.medium,
  [TextInputSize.Small]: styles.small,
};

function TextInputComponent(
  {
    inputSize = TextInputSize.Medium,
    inputType = TextInputType.Input,
    onIdle,
    idleTimeoutLength,
    onChange,
    className,
    error,
    ...rest
  }: {
    inputSize?: TextInputSize;
    inputType?: TextInputType;
    onIdle?: (value: string) => void;
    idleTimeoutLength?: number;
    error?: boolean;
  } & React.ComponentPropsWithoutRef<'input'>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const idleTimeout = React.useRef(-1);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    return () => {
      if (idleTimeout.current !== -1) {
        window.clearTimeout(idleTimeout.current);
        idleTimeout.current = -1;
      }
    };
  }, []);

  return (
    <input
      ref={r => {
        inputRef.current = r;
        if (ref) {
          if (isFunction(ref)) {
            ref(r);
          } else {
            ref.current = r;
          }
        }
      }}
      className={cn(styles.textInput, typeClasses[inputType], sizeClasses[inputSize], className, {
        [styles.errorInput]: error,
      })}
      onChange={e => {
        if (idleTimeout.current !== -1) {
          window.clearTimeout(idleTimeout.current);
        }
        if (onIdle) {
          idleTimeout.current = window.setTimeout(
            () => onIdle(inputRef.current?.value ?? ''),
            idleTimeoutLength ?? TEXT_IDLE_TIMEOUT
          );
        }
        onChange?.(e);
      }}
      {...rest}
    />
  );
}

function TextValidationInputComponent(
  {
    error: externalError,
    validate,
    onBlur,
    onChange,
    ...rest
  }: {
    error?: string | null;
    validate?: (value: string) => string | null;
    inputSize?: TextInputSize;
    inputType?: TextInputType;
    onIdle?: (value: string) => void;
    idleTimeoutLength?: number;
  } & React.ComponentPropsWithoutRef<'input'>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const [error, setError] = React.useState<string | null>(null);

  return (
    <div className={styles.textValidationInput}>
      <TextInput
        ref={ref}
        {...rest}
        onBlur={e => {
          const err = validate?.(e.currentTarget.value);
          if (err) {
            setError(err);
          }
          onBlur?.(e);
        }}
        onChange={e => {
          setError(null);
          onChange?.(e);
        }}
      />
      {(externalError || error) && <span className={styles.error}>{externalError ?? error}</span>}
    </div>
  );
}

export const TextInput = React.forwardRef(TextInputComponent);
export const TextValidationInput = React.forwardRef(TextValidationInputComponent);
