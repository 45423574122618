import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { looksLikeId } from '../../../../shared/utils/id';
import { issueTerm } from '../../../../shared/utils/terms';
import { Screen } from '../../../components/screen';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { FetchedMarker, OrganizationMarker } from '../../../graphql/smartLoad';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import {
  entityDeletedSelector,
  entityExistsSelector,
  useEntityPath,
} from '../../../syncEngine/selectors/entities';
import { issueIdByNumberSelector, issueNumberSelector } from '../../../syncEngine/selectors/issues';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { NotFoundScreen } from '../../errorScreens';
import { DeletedScreen } from '../../errorScreens/deletedScreen';
import LoadingScreen from '../../loadingScreen';
import WorkItemScreen from './workItemScreen';

interface RouteParams {
  issueNumber: string;
}

export default function WorkItemScreenWrapper(props: RouteComponentProps<RouteParams>) {
  const { match } = props;
  const space = useSpace();
  const organization = useOrganization();

  const { issueNumber: issueNumberOrId } = match.params;

  const issueId = useRecoilValue(
    issueIdByNumberSelector({ spaceId: space.id, issueNumber: issueNumberOrId })
  );
  const issueIdRef = React.useRef(issueId);

  const lastKnownGoodId = issueId ?? issueIdRef.current;

  const issueNumber = useRecoilValue(issueNumberSelector(issueId));
  const entityDeleted = useRecoilValue(entityDeletedSelector(lastKnownGoodId));
  const entityExists = useRecoilValue(entityExistsSelector(lastKnownGoodId));
  const entityPath = useEntityPath();
  const history = useHistory();

  useComponentDidMount(() => {
    // check if it moved to another space
    if (space.redirects && space.redirects[issueNumberOrId]) {
      const path = entityPath(space.redirects[issueNumberOrId]);
      if (path) {
        history.replace(path);
      }
    }
  });

  React.useEffect(() => {
    /* If we end up with an id in the URL, redirect to the number url instead. This
    will stop us from having a desynced url, where the issue referenced by the
    ID is in space A but the url contains space B */
    if (
      issueNumber &&
      !issueNumber.startsWith('T') &&
      !issueNumber.startsWith('U') &&
      looksLikeId(issueNumberOrId)
    ) {
      history.replace(history.location.pathname.replace(issueNumberOrId, issueNumber));
    }
  }, [issueNumberOrId, issueNumber]);

  React.useEffect(() => {
    /*
    issueId comes from the selector which is based on the number in the URL.
    issueIdRef is the last good id we had.
    If the selector cannot return an ID, but we had an ID at some point, it
     means the issue got moved to another space. So we need to redirect there
    */
    if (issueIdRef.current && !issueId) {
      const path = entityPath(issueIdRef.current);
      if (path) {
        history.replace(path);
      }
    }
  }, [issueId, entityPath]);

  const currentOrgPartiallyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, true))
  );
  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );
  const fetched = useRecoilValue(markerState(FetchedMarker.id));

  if (lastKnownGoodId && entityDeleted) {
    return (
      <Screen>
        <DeletedScreen type={issueTerm} />
      </Screen>
    );
  }

  if (!lastKnownGoodId || !entityExists || !currentOrgPartiallyFetched) {
    if (!currentOrgPartiallyFetched || !currentOrgFullyFetched || !fetched) {
      return <LoadingScreen />;
    }

    return (
      <Screen>
        <NotFoundScreen />
      </Screen>
    );
  }

  return <WorkItemScreen issueId={lastKnownGoodId} />;
}
