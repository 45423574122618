import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { FilterType } from '../../../shared/filtering';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { entityTypeString } from '../../syncEngine/selectors/entities';
import {
  addToCurrentCycleKey,
  addToInitiativeKey,
  assignIssueKey,
  labelIssueKey,
  searchKey,
  selfFilterHotKey,
  setEffortKey,
  setImpactKey,
  watchIssueKey,
} from '../../utils/config';
import { FilterableEntityType, filterState } from '../../utils/filtering';
import { ButtonStyle } from './button';
import { ContentProps, FilterMenu, MenuMode, addFilter, findFilter, removeFilter } from './filters';
import { KeyNavigationProvider } from './keyNavigation';
import { LISTVIEW_ID, ListView, ListViewItem, NO_KEYNAV } from './listView';
import menuStyles from './menu/menu.module.scss';

function DefaultContents({ id, onModeChanged, entityType, onClose }: ContentProps) {
  const setFilters = useSetRecoilState(filterState(id));
  const user = useCurrentUser();
  const space = useMaybeSpace();

  const items: ListViewItem[] = [
    {
      id: 'members',
      icon: 'member',
      contents: 'Members',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Member,
          placeholder: 'Members',
        });
      },
      hotkey: assignIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'labels',
      icon: 'label',
      contents: 'Labels',
      onSelected: () => {
        onModeChanged(MenuMode.Labels);
      },
      hotkey: labelIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'impact',
      icon: 'impact',
      contents: 'Impact',
      onSelected: () => {
        onModeChanged(MenuMode.Impact);
      },
      hotkey: setImpactKey,
      className: menuStyles.item,
    },
    {
      id: 'effort',
      icon: 'effort',
      contents: 'Effort',
      onSelected: () => {
        onModeChanged(MenuMode.Effort);
      },
      hotkey: setEffortKey,
      className: menuStyles.item,
    },
    {
      id: 'initiatives',
      icon: 'initiative',
      contents: 'Initiatives',
      onSelected: () => {
        onModeChanged(MenuMode.Initiatives);
      },
      hotkey: addToInitiativeKey,
      className: menuStyles.item,
    },
    ...(space
      ? []
      : [
          {
            id: 'space',
            icon: 'workspace',
            contents: 'Space',
            onSelected: () => {
              onModeChanged(MenuMode.Space);
            },
            hotkey: 's',
            className: menuStyles.item,
          },
        ]),
    {
      id: 'cycles',
      icon: 'cycle_current',
      contents: 'Cycles',
      onSelected: () => {
        onModeChanged(MenuMode.Cycles);
      },
      hotkey: addToCurrentCycleKey.replace('shift', ''),
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-2',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'my',
      icon: 'my_work',
      contents: entityType ? `My ${entityTypeString(entityType)}s` : 'My items',
      onSelected: () => {
        setFilters(previous => {
          if (findFilter(previous, f => f.type === FilterType.Member && f.id === user.id)) {
            return removeFilter(previous, f => f.type === FilterType.Member && f.id === user.id);
          }
          return addFilter(previous, { type: FilterType.Member, id: user.id });
        });
        onClose();
      },
      hotkey: selfFilterHotKey,
      className: menuStyles.item,
    },
    {
      id: 'watched',
      icon: 'watch',
      contents: 'Watched by me',
      onSelected: () => {
        setFilters(previous => {
          if (findFilter(previous, f => f.type === FilterType.Watching && f.id === user.id)) {
            return removeFilter(previous, f => f.type === FilterType.Watching && f.id === user.id);
          }
          return addFilter(previous, { type: FilterType.Watching, id: user.id });
        });
        onClose();
      },
      hotkey: watchIssueKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-3',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'creator',
      icon: 'creator',
      contents: 'Creator',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.CreatedBy,
          placeholder: 'Created by',
        });
      },
      hotkey: 'b',
      className: menuStyles.item,
    },
    {
      id: 'freeText',
      icon: 'search_content',
      contents: 'Title and ID',
      onSelected: () => {
        onModeChanged(MenuMode.FreeText);
      },
      hotkey: searchKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-4',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'createdSince',
      icon: 'none',
      contents: 'Created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'after',
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'c',
      className: menuStyles.item,
    },
    {
      id: 'notCreatedSince',
      icon: 'none',
      contents: 'Not created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'before',
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'shift+c',
      className: menuStyles.item,
    },
    {
      id: 'updatedSince',
      icon: 'none',
      contents: 'Updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'after',
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'u',
      className: menuStyles.item,
    },
    {
      id: 'notUpdatedSince',
      icon: 'none',
      contents: 'Not updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'before',
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'shift+u',
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-5',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'clear',
      icon: 'exit',
      contents: 'Clear all filters',
      onSelected: () => {
        setFilters(null);
        onClose();
      },
      hotkey: 'backspace',
      className: menuStyles.item,
    },
  ];

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView items={items} />
    </KeyNavigationProvider>
  );
}

export function EntityFilterMenu({
  entityType,
  id,
  buttonStyle,
  className,
  style,
  compact,
}: {
  entityType?: FilterableEntityType;
  buttonStyle?: ButtonStyle;
  id: string;
  style?: React.CSSProperties;
  className?: string;
  compact?: boolean;
}) {
  return (
    <div className={className}>
      <FilterMenu
        id={id}
        entityType={entityType}
        buttonStyle={buttonStyle}
        style={style}
        DefaultContents={DefaultContents}
        compact={compact}
      />
    </div>
  );
}
