import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import styles from './clipboardText.module.scss';
import { ButtonSize, IconButton } from './new/button';
import { toast } from './toast';

export async function writeToClipboard(content: string, subject?: string) {
  try {
    await navigator.clipboard.writeText(content);

    if (subject) {
      toast.info(
        <>
          <span className="semiBold">{capitalize(subject)}</span> copied to the clipboard
        </>
      );
    } else {
      toast.info(`Copied to the clipboard`);
    }
  } catch (e) {
    toast.error(
      <>
        Your browser blocked the attempt to copy{' '}
        <span className="semiBold">{subject ? 'the ' + subject + ' ' : ''}</span> to your clipboard
      </>
    );
  }
}

export function ClipboardText({
  children,
  className,
  content,
  contentName,
}: {
  children: React.ReactNode;
  content: string;
  contentName?: string;
  className?: string;
}) {
  function copyToClipboard() {
    writeToClipboard(content, contentName);
  }

  return (
    <span className={cn(styles.clipboard, className)}>
      {children}
      <IconButton
        size={ButtonSize.ExtraSmall}
        className="ml4 mr4"
        icon="copy"
        onClick={copyToClipboard}
      />
    </span>
  );
}
