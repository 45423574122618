import React from 'react';
import { isElectron } from '../electronIntegration';

export default function TitleSetter({ title }: { title: string }) {
  React.useEffect(() => {
    document.title = isElectron() ? '' : title;
  }, [title]);

  return null;
}
