import { capitalize } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { assignVerb, issueTerm } from '../../shared/utils/terms';
import Modal, { ModalContentWrapper } from '../components/modal';
import { Hotkey } from '../components/new/hotkey';
import { KeyboardShortcut } from '../components/new/keyboardShortcut';
import { TextInput } from '../components/new/textInput';
import { Modals, useModals } from '../contexts/modalContext';
import { useMaybeSpace } from '../contexts/spaceContext';
import { maybeClosedStatusForSpaceSelector } from '../syncEngine/selectors/issueStatuses';
import {
  addBlockedByKey,
  addBlocksKey,
  addDependsOnKey,
  addEnablesKey,
  addInsightKey,
  addToInitiativeKey,
  alternateComboKey,
  archiveIssueKey,
  assignIssueKey,
  closeIssueKey,
  commandMenuKey,
  copyIssueGitBranchKey,
  copyIssueLinkKey,
  copyIssueNumberKey,
  createNewEntityFromAnywhere,
  createNewIssueKey,
  deleteKey,
  editTitleKey,
  hotkeyHelpMenuKey,
  labelIssueKey,
  mainComboKey,
  moveIssueKey,
  moveIssueToNextStatusKey,
  moveIssueToNextStatusKeyBottom,
  moveIssueToPreviousStatusKey,
  moveIssueToPreviousStatusKeyBottom,
  searchKey,
  selfAssignIssueKey,
  setEffortKey,
  setImpactKey,
  showArchiveKey,
  showDependencyGraphKey,
  showFeedbackKey,
  showIssueBoardKey,
  showRoadmapKey,
  takeOwnershipIssueKey,
  toggleSideBarKey,
  vimDown,
  vimLeft,
  vimRight,
  vimUp,
  watchIssueKey,
} from '../utils/config';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../utils/search';
import styles from './hotkeyMenu.module.scss';

interface HotkeyInfo {
  label: string;
  hotkey: string;
  aliases?: string[];
}

function HotkeyMenuContents() {
  const space = useMaybeSpace();
  const closedStatus = useRecoilValue(maybeClosedStatusForSpaceSelector(space?.id));
  const closedColumnName = closedStatus?.name ?? 'Done';

  const NavigationHotkeys: HotkeyInfo[] = React.useMemo(
    () => [
      {
        label: 'Kitemaker Command',
        hotkey: commandMenuKey,
      },

      {
        label: 'View all keyboard shortcuts',
        hotkey: '?',
      },
      {
        label: 'Go to space',
        hotkey: '0-9',
      },
      {
        label: 'Go back',
        hotkey: 'escape',
      },
      {
        label: `Create ${issueTerm} or initiative (global)`,
        hotkey: createNewEntityFromAnywhere,
      },
      {
        label: `Go to current ${issueTerm}s`,
        hotkey: showIssueBoardKey,
      },
      {
        label: `Go to archived ${issueTerm}s`,
        hotkey: showArchiveKey,
      },
      {
        label: 'Go to roadmap',
        aliases: ['initiative'],
        hotkey: showRoadmapKey,
      },

      {
        label: 'Go to feedback',
        aliases: ['insight'],
        hotkey: showFeedbackKey,
      },

      {
        label: 'Go to dependencies',
        aliases: ['block', 'enables', 'connect'],
        hotkey: showDependencyGraphKey,
      },
      {
        label: 'Toggle sidebar',
        aliases: ['menu', 'open', 'close'],
        hotkey: toggleSideBarKey,
      },
    ],
    []
  );

  const IssueBoardHotkeys: HotkeyInfo[] = React.useMemo(
    () => [
      {
        label: `Navigate work items/initiative`,
        hotkey: '←/→/↑/↓',
      },
      {
        label: `Navigate work items/initiative (old school)`,
        hotkey: `${vimLeft}/${vimRight}/${vimUp}/${vimDown}`,
      },
      {
        label: 'Navigate to top or bottom',
        hotkey: `${mainComboKey}+↑/↓`,
      },
      {
        label: `Open work item/initiative`,
        aliases: ['Open issue'],
        hotkey: 'Enter',
      },
      {
        label: `Create work item/initiative`,
        aliases: ['Create issue'],
        hotkey: createNewIssueKey,
      },
      {
        label: `Create work item/initiative below current work item/initiative`,
        aliases: ['Create issue'],
        hotkey: `shift+${createNewIssueKey}`,
      },
      {
        label: `Select`,
        aliases: ['Multiselect'],
        hotkey: `x`,
      },
      {
        label: `Select`,
        aliases: ['Multiselect'],
        hotkey: `shift+click`,
      },
      {
        label: `Select multipe`,
        aliases: ['Multiselect'],
        hotkey: `shift+←/→/↑/↓`,
      },
      {
        label: `Edit work item/initiative title`,
        aliases: ['Change issue title', 'Edit issue title'],
        hotkey: 't',
      },
      {
        label: `Edit work item/initiative description`,
        aliases: ['Change issue description', 'Edit issue description'],
        hotkey: 'd',
      },
      {
        label: 'Search',
        aliases: ['Find'],
        hotkey: searchKey,
      },
      {
        label: 'Filter',
        hotkey: 'f',
      },
      {
        label: `Toggle filtering of ${issueTerm}s you're working on`,
        aliases: ['Me', 'My', 'Mine'],
        hotkey: 'q',
      },
      {
        label: `Move work item/initiative`,
        hotkey: `${alternateComboKey}+←/→/↑/↓`,
      },
      {
        label: `Move work item/initiative (old school)`,
        hotkey: `${alternateComboKey}+${vimLeft}/${vimRight}/${vimUp}/${vimDown}`,
      },
      {
        label: `Move work item/initiative to top or bottom`,
        hotkey: `${alternateComboKey}+${mainComboKey}+↑/↓`,
      },
      { label: `Toggle between list and board view`, hotkey: `${alternateComboKey}+v` },
    ],
    [closedColumnName]
  );

  const IssuesHotkeyInfo: HotkeyInfo[] = React.useMemo(
    () => [
      {
        label: `Edit work item/initiative title`,
        hotkey: editTitleKey,
      },
      {
        label: `Navigate to next`,
        hotkey: vimDown,
      },
      {
        label: `Navigate to previous`,
        hotkey: vimUp,
      },
      {
        label: `Move to another space`,
        hotkey: `${mainComboKey}+shift+m`,
      },
      {
        label: `Move, navigate to next `,
        hotkey: moveIssueKey,
      },
      {
        label: `Move to bottom of a status, navigate to next `,
        hotkey: 'shift+m',
      },
      {
        label: `Move  to top of next status, navigate to next `,
        hotkey: moveIssueToNextStatusKey,
      },
      {
        label: `Move to top of previous status, navigate to next `,
        hotkey: moveIssueToPreviousStatusKey,
      },
      {
        label: `Move to bottom of next status, navigate to next`,
        hotkey: moveIssueToNextStatusKeyBottom,
      },
      {
        label: `Move to bottom of previous status, navigate to next `,
        hotkey: moveIssueToPreviousStatusKeyBottom,
      },
      { label: `Watch`, hotkey: watchIssueKey },
      {
        label: `Add myself`,
        hotkey: selfAssignIssueKey,
      },
      {
        label: `Take ownership`,
        hotkey: takeOwnershipIssueKey,
      },
      {
        label: `${capitalize(assignVerb)}`,
        hotkey: assignIssueKey,
      },
      {
        label: `Edit labels`,
        hotkey: labelIssueKey,
      },
      {
        label: `Set impact`,
        hotkey: setImpactKey,
      },
      {
        label: `Set effort`,
        hotkey: setEffortKey,
      },
      {
        label: `Edit initiatives`,
        hotkey: addToInitiativeKey,
      },

      {
        label: `Set blocking (work items only)`,
        hotkey: addBlocksKey,
      },
      {
        label: `Set blocked (work items only)`,
        hotkey: addBlockedByKey,
      },
      {
        label: `Set enables (work items only)`,
        hotkey: addEnablesKey,
      },
      {
        label: `Set dependency (work items only)`,
        hotkey: addDependsOnKey,
      },
      {
        label: `Copy work item/initiative number`,
        hotkey: copyIssueNumberKey,
      },
      {
        label: `Copy work item/initiative link`,
        hotkey: copyIssueLinkKey,
      },
      {
        label: `Copy git branch name for ${issueTerm}`,
        hotkey: copyIssueGitBranchKey,
      },
      {
        label: `Move ${issueTerm} to ${closedColumnName}`,
        hotkey: closeIssueKey,
      },
      {
        label: `Archive work item/initiative`,
        hotkey: archiveIssueKey,
      },
    ],
    [closedColumnName]
  );

  const FeedbackHotkeyInfo: HotkeyInfo[] = React.useMemo(
    () => [
      {
        label: `Create new feedback`,
        hotkey: createNewIssueKey,
      },
      {
        label: `Tag feedback`,
        hotkey: labelIssueKey,
      },
      {
        label: `Set feedback owner`,
        hotkey: assignIssueKey,
      },
      {
        label: `Edit feedback title`,
        hotkey: editTitleKey,
      },
      {
        label: `Edit feedback content`,
        hotkey: 'd',
      },
      {
        label: `Capture selected text as insight`,
        hotkey: addInsightKey,
      },
      {
        label: `Mark feedback as processed`,
        hotkey: 'p',
      },
      {
        label: `Copy link to feedback`,
        hotkey: copyIssueNumberKey,
      },
      {
        label: `Delete feedback`,
        hotkey: deleteKey,
      },
    ],
    []
  );

  const allHotkeys = React.useMemo(
    () => [...NavigationHotkeys, ...IssueBoardHotkeys, ...IssuesHotkeyInfo, ...FeedbackHotkeyInfo],
    [IssueBoardHotkeys, IssuesHotkeyInfo, NavigationHotkeys, FeedbackHotkeyInfo]
  );

  const [searchString, setSearchString] = React.useState('');
  const [hotkeyList, setHotkeyList] = React.useState<HotkeyInfo[]>([]);
  const searchEngine = React.useMemo(
    () =>
      new FuzzySearcher(
        FuzzySearcherConfiguration.Autocomplete,
        ['label', 'hotkey', 'aliases'],
        allHotkeys
      ),
    [allHotkeys]
  );

  React.useEffect(() => {
    if (!searchString) {
      return;
    }
    const results = searchEngine.search(searchString).map(result => result.item);
    setHotkeyList(results);
  }, [searchEngine, searchString]);

  function renderHotkeyInfo(hki: HotkeyInfo[]) {
    return hki.map((k, idx) => (
      <div key={idx} className={styles.entry}>
        <span>{k.label}</span>
        <KeyboardShortcut shortcut={k.hotkey} />
      </div>
    ));
  }

  return (
    <ModalContentWrapper className={styles.hotkeyMenu}>
      <TextInput
        type="text"
        placeholder={'Search for hotkey'}
        onChange={e => setSearchString(e.currentTarget.value)}
        value={searchString}
        autoComplete="false"
        spellCheck={false}
        autoFocus={true}
        className="fullWidth mt16 noShrink"
      />
      {!searchString && (
        <>
          <h3 className="headingL mb8 mt8">Navigation</h3>
          {renderHotkeyInfo(NavigationHotkeys)}
          <h3 className="headingL mb8 mt24">Board</h3>
          {renderHotkeyInfo(IssueBoardHotkeys)}
          <h3 className="headingL mb8 mt24">{capitalize(issueTerm)}s and initiatives</h3>
          {renderHotkeyInfo(IssuesHotkeyInfo)}
          <h3 className="headingL mb8 mt24">Feedback</h3>
          {renderHotkeyInfo(FeedbackHotkeyInfo)}
        </>
      )}
      {searchString && renderHotkeyInfo(hotkeyList)}
    </ModalContentWrapper>
  );
}
export default function HotkeyMenu() {
  const modalManager = useModals();

  return (
    <>
      <Hotkey
        hotkey={hotkeyHelpMenuKey}
        unscoped
        handler={e => {
          e?.preventDefault();
          modalManager.openModal(Modals.HotkeyMenu);
        }}
      />
      <Modal modalId={Modals.HotkeyMenu} title="Keyboard shortcuts">
        <HotkeyMenuContents />
      </Modal>
    </>
  );
}
