import { isArray } from 'lodash';
import Papa from 'papaparse';

/**
 * Extract all parameters of type 'Type' where the parameter type satisfies
 * 'AcceptedType'.
 *
 * Example
 * ```ts
 * interface Image {
 *   width: number;
 *   height: number;
 *   format: string;
 *   options: any;
 * }
 *
 * type ImageNumberParams = ExtractedParams<Image, number> // 'width', 'height', 'options'
 * ```
 */
export type ExtractedParams<Type, AcceptedType> = {
  [Param in keyof Type]: AcceptedType extends Type[Param] ? Param : never;
}[keyof Type];

export function trimInput<T>(input: T, fieldsToTrim: string[]): T {
  for (const field of fieldsToTrim) {
    const inputAsAny = input as any;
    if (inputAsAny[field]) {
      if (isArray(inputAsAny[field])) {
        inputAsAny[field] = inputAsAny[field].map((v: any) => v.trim());
      } else {
        inputAsAny[field] = inputAsAny[field].trim();
      }
    }
  }
  return input;
}

export function isBooleanLike(val: string) {
  return val === '1' || val === 'true' || val === '0' || val === 'false';
}

export function parseBoolean(val?: string): boolean {
  if (!val) {
    return false;
  }
  return val === '1' || val === 'true';
}

export function parseInteger(val: string | undefined, fallback: number): number {
  if (!val) {
    return fallback;
  }
  return parseInt(val, 10) || fallback;
}

export function parseStringArray(value?: string): string[] {
  if (!value) {
    return [];
  }

  return value.split(',').map(s => s.trim());
}

export function cleanStringForPath(val: string, fallback: string): string {
  const result = val
    .replace(/ /gm, '_')
    .replace(/[^A-Za-z0-9-_]/gm, '')
    .substr(0, 20);

  return result ? result : fallback;
}

export function cleanOutUndefineds(object: any) {
  Object.keys(object).forEach(
    key => (object as any)[key] === undefined && delete (object as any)[key]
  );
}

export function indexArray<I extends string | number | symbol, T>(
  array: T[],
  createIndex: (item: T, index: number) => I
): Partial<Record<I, T>> {
  const index: Partial<Record<I, T>> = {};

  array.forEach((item, i) => {
    index[createIndex(item, i)] = item;
  });

  return index;
}

export function capitalize(string: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
}

export const exportToCsv = (filename: string, rows: object[]): void => {
  if (!rows || !rows.length) {
    return;
  }

  const csvContent = Papa.unparse(rows);

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  if (link.download !== undefined) {
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
