import { capitalize } from 'lodash';
import * as React from 'react';
import { legacyColorMap } from '../../../../shared/utils/colors';
import { hexToRgb } from '../../../utils/color';
import { paletteColors } from '../../../utils/config';
import { Icon, IconSize } from '../icon';
import { Tooltip } from '../tooltip';
import Pill from './pill';
import { MetadataSize } from './size';

export default function Effort({
  color: baseColor,
  shortName,
  longName,
  size = MetadataSize.Small,
  disableTooltip,
  className,
}: {
  color: string;
  shortName: string;
  longName: string;
  size?: MetadataSize;
  disableTooltip?: boolean;
  className?: string;
}) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  let fill;

  const color = legacyColorMap[baseColor] ?? baseColor;
  if (paletteColors.includes(color)) {
    fill = `var(--${color}A9)`;
  } else {
    const rgb = hexToRgb(color);
    if (!rgb) {
      fill = 'var(--grayA9)';
    } else {
      fill = `rgba(${rgb.join(',')}, 0.85)`;
    }
  }

  const content = (
    <Pill className={className} style={{ color: fill }} size={size}>
      <Icon style={{ fill }} size={iconSize} icon="effort" />
      <span>{shortName}</span>
    </Pill>
  );

  if (disableTooltip) {
    return content;
  }

  return (
    <Tooltip side="bottom" align="start" content={`${capitalize(longName)} effort`}>
      {content}
    </Tooltip>
  );
}
