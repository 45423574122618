export const languages: { [index: string]: string } = {
  bash: 'bash',
  c: 'C',
  cpp: 'C++',
  csharp: 'C#',
  css: 'CSS',
  d: 'D',
  dart: 'Dart',
  diff: 'Diff',
  gherkin: 'Gherkin',
  go: 'Go',
  graphql: 'GraphQL',
  markup: 'HTML/XML',
  java: 'Java',
  javascript: 'JavaScript',
  json: 'JSON',
  kotlin: 'Kotlin',
  perl: 'Perl',
  php: 'PHP',
  protobuf: 'Protobuf',
  python: 'Python',
  ruby: 'Ruby',
  rust: 'Rust',
  scala: 'Scala',
  sql: 'SQL',
  swift: 'Swift',
  typescript: 'TypeScript',
  yaml: 'YAML',
};
