import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { ListViewItem } from '../../../../components/new/listView';
import { Setting } from '../../../../components/new/settings';
import { User } from '../../../../components/new/user';
import SelectButton from '../../../../components/selectButton';
import { useOrganization } from '../../../../contexts/organizationContext';
import { activeUsersForOrganizationSelector } from '../../../../syncEngine/selectors/organizations';
import { ImportedAssignee } from '.';

export function ImportAssigneePicker({
  assignees,
  mapAssignees,
  onMapAssigneesChanged,
}: {
  assignees: ImportedAssignee[];
  mapAssignees: { [index: string]: string };
  onMapAssigneesChanged: (mapAssignees: { [index: string]: string }) => void;
}) {
  const organization = useOrganization();
  const users = useRecoilValue(
    activeUsersForOrganizationSelector({ organizationId: organization.id })
  );

  const items: ListViewItem[] = users.map(u => ({
    id: u.user.id,
    username: u.user.username,
    name: u.user.name,
    contents: <User className="ml8" member={u.member} user={u.user} />,
  }));

  return (
    <>
      {assignees.map(assignee => (
        <Setting key={assignee.id} title={assignee.name ?? assignee.login}>
          <SelectButton
            items={items}
            value={mapAssignees[assignee.id] ?? ''}
            onSelect={v => {
              onMapAssigneesChanged({ ...mapAssignees, [assignee.id]: v });
            }}
            propertiesToSearch={['username', 'name']}
            placeholder="Select user"
          />
        </Setting>
      ))}
    </>
  );
}
