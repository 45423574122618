import * as React from 'react';
import { Icon, IconSize } from '../../components/new/icon';
import styles from './error.module.scss';

export function ErrorElement({ error }: { error: string }) {
  return (
    <div className={styles.error}>
      {error}
      <Icon size={IconSize.Size24} icon="error" />
    </div>
  );
}
