import { useRecoilValue } from 'recoil';
import { writeToClipboard } from '../components/clipboardText';
import { activitySelector, useActivityUrl } from '../syncEngine/selectors/activities';
import { copyActivityLinkKey } from '../utils/config';
import {
  ActivityGroupContext,
  CommandDefinition,
  CommandGroup,
  useCommandMenuContext,
} from './state';

export function useActivityCommandGroup(): CommandDefinition[] {
  const activityContext = useCommandMenuContext<ActivityGroupContext>(CommandGroup.Activity);
  const activity = useRecoilValue(activitySelector(activityContext?.activityId ?? ''));
  const activityUrl = useActivityUrl();

  if (!activity) {
    return [];
  }

  const commands: CommandDefinition[] = [
    {
      id: 'copy-activity-link',
      description: 'Copy link to activity',
      handler: () => {
        writeToClipboard(activityUrl(activity), 'Activity link');
      },
      aliases: ['url'],
      hotkey: copyActivityLinkKey,
      icon: 'link',
    },
  ];

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Activity }));
}
