import { padEnd } from 'lodash';
const chars = 'abcdefghijklmnopqrstuvwxyz'.split('');

export const lastSort = 'zzzzzz';

export function between({ after, before }: { after?: string; before?: string }): string {
  if (!after && !before) {
    return chars[chars.length / 2 - 1];
  }

  if (!after) {
    return between({ before, after: 'a' });
  }

  if (!before) {
    return between({ after, before: padEnd('', after.length, 'z') });
  }

  const afterPadded = padEnd(after, before.length, 'a');
  const beforePadded = padEnd(before, after.length, 'a');

  let result = '';

  for (let i = 0; i < afterPadded.length; i++) {
    if (afterPadded[i] === beforePadded[i]) {
      result += afterPadded[i];
    } else {
      const afterCharIndex = chars.indexOf(afterPadded[i]);
      const beforeCharIndex =
        chars.indexOf(beforePadded[i]) < afterCharIndex
          ? chars.indexOf(beforePadded[i]) + chars.length
          : chars.indexOf(beforePadded[i]);

      if (beforeCharIndex - afterCharIndex > 1) {
        return `${result}${chars[Math.floor((beforeCharIndex + afterCharIndex) / 2)]}`;
      }

      result += afterPadded[i];
    }
  }

  return `${result}${chars[chars.length / 2 - 1]}`;
}
