import * as React from 'react';
import { CommandDefinition, useRegisterCustomCommand, CommandGroup } from '../../commands';

export function CustomCommand({
  command,
}: {
  command: Omit<CommandDefinition, 'group'> & { group?: CommandGroup };
}) {
  const { registerCommand, unregisterCommand } = useRegisterCustomCommand();
  React.useEffect(() => {
    registerCommand({ group: CommandGroup.Other, ...command });

    return () => {
      unregisterCommand(command.id);
    };
  }, [command]);
  return null;
}
