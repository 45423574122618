import cn from 'classnames';
import * as React from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { Editor, Range, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { KitemakerElement } from '../../../../shared/slate/kitemakerNode';
import { safeSelection } from '../../../../shared/slate/utils';
import { Comment } from '../../../../sync/__generated/models';
import { Icon, IconSize } from '../../../components/new/icon';
import { useClient } from '../../../contexts/clientContext';
import { commentSelector, useCommentUrl } from '../../../syncEngine/selectors/comments';
import { trackerEvent } from '../../../tracker';
import { findScrollParent, getElementsInRange } from '../../../utils/dom';
import { fileUploader, uploadFilesFromDragAndDrop } from '../../../utils/fileUploader';
import { useInteractivityDisabled } from '../../hooks/useInteractivityDisabled';
import { useSerializeToMarkdown } from '../../hooks/useSerializeToMarkdown';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType, Elements } from '../../types';
import {
  DRAG_PREVIEW_ID,
  dragPreviewClassnameState,
  dragPreviewElementIdState,
  dragPreviewOffsetState,
  dragPreviewState,
} from './draggableLayer';
import { DropHighlight, DropHighlightPosition } from './dropHighlight';
import styles from './useDragAndDrop.module.scss';

const SCROLL_THRESHOLD = 125;
const SCROLL_INCREMENT = 4;

const dragRefs: Record<string, HTMLElement[]> = {};
export const emptyImage = new Image();
emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

function onDropBlock(
  slate: EditorType,
  position: DropHighlightPosition,
  target: HTMLElement,
  source: Range
) {
  const targetPath = ReactEditor.findPath(slate, ReactEditor.toSlateNode(slate, target));
  if (Range.includes(source, targetPath)) {
    return;
  }

  const newTargetPath = targetPath.slice();
  if (position === 'top' && source.anchor.path[0] < targetPath[0]) {
    newTargetPath[targetPath.length - 1] -= 1;
  } else if (position === 'bottom' && source.focus.path[0] > targetPath[0]) {
    newTargetPath[targetPath.length - 1] += 1;
  }

  Transforms.moveNodes(slate, {
    at: source,
    to: newTargetPath,
    mode: 'highest',
  });
  trackerEvent('Drag and Drop', { type: 'block' });
}

function onDropComment(
  slate: EditorType,
  position: string,
  target: HTMLElement,
  comment: Comment,
  url: string,
  clientId: string,
  toMarkdown: ReturnType<typeof useSerializeToMarkdown>
) {
  const targetPath = [...ReactEditor.findPath(slate, ReactEditor.toSlateNode(slate, target))];
  if (position === 'bottom') {
    targetPath[targetPath.length - 1] += 1;
  }

  slate.forceFocus();
  KitemakerTransforms.insertNodes(
    slate,
    [
      {
        type: Elements.AIPlaceholder,
        clientId,
        context: {
          content: toMarkdown(JSON.parse(comment.body)),
          url,
        },
        operation: 'todo',
        children: [
          {
            text: '',
          },
        ],
      },
    ],
    { at: targetPath, select: true }
  );
  return;
}

function onDropFile(
  slate: EditorType,
  position: DropHighlightPosition,
  target: HTMLElement,
  files: any[],
  attachmentUploadPath: string
) {
  if (!files.length) {
    return;
  }
  const targetPath = [...ReactEditor.findPath(slate, ReactEditor.toSlateNode(slate, target))];
  if (position === 'bottom') {
    targetPath[targetPath.length - 1] += 1;
  }
  slate.forceFocus();
  const uploader = fileUploader(attachmentUploadPath, { multi: true });
  uploadFilesFromDragAndDrop(slate, uploader, files, targetPath, attachmentUploadPath);
  trackerEvent('Drag and Drop', { type: 'file' });
}

function draggableById(id: string): HTMLElement | null {
  return document.querySelector<HTMLElement>(`[data-dnd="${id}"]`);
}

function getClone(element: HTMLElement) {
  const clone: HTMLElement = element.cloneNode(true) as HTMLElement;
  clone.removeAttribute('data-dnd');
  clone.style.paddingTop = '0px';
  clone.style.paddingBottom = '0px';
  clone.style.marginTop = '0px';
  clone.style.marginBottom = '0px';
  clone.style.opacity = '1';
  const handle = clone.querySelector('[data-dnd-handle]');
  handle?.remove();
  const ignored = clone.querySelectorAll('[data-dnd-ignore]');
  ignored.forEach(element => {
    element.remove();
  });

  return clone;
}

export function DragAndDropComponent({
  id,
  smallPadding,
  direction,
}: {
  id: string;
  direction: DropHighlightPosition | null;
  smallPadding?: boolean;
}) {
  const slate = useSlateStatic();
  const setNode = useSetRecoilState(dragPreviewState);
  const setOffset = useSetRecoilState(dragPreviewOffsetState);
  const setClassname = useSetRecoilState(dragPreviewClassnameState);
  const setPreviewDestination = useSetRecoilState(dragPreviewElementIdState);
  const documentEmpty = slate.isDocumentEmpty;

  return (
    <>
      {direction !== null && !documentEmpty && (
        <div contentEditable={false}>
          <DropHighlight
            smallPadding={smallPadding}
            position={direction}
            fileOnly={!slate.blockDragAndDropEnabled}
          />
        </div>
      )}
      {slate.blockDragAndDropEnabled && (
        <>
          <div className={styles.expandedDropTarget} contentEditable={false}>
            {/*
             * You may ask why this nbsp is here. Let me tell you friend: if you don't include it, Slate seems to ignore the contentEditable=false
             * and all hell breaks lose. Just leave it.
             */}
            &nbsp;
          </div>
          <div
            onMouseDown={e => {
              // If this escapes when the doc is unfocused, it'll cause a scroll to the top as the doc gets focus but doesn't
              // have a seletion (and thus default to the start of the doc)
              e.stopPropagation();
            }}
          >
            <div
              data-dnd-handle={true}
              className={styles.handle}
              contentEditable={false}
              draggable
              onDragStart={e => {
                setTimeout(() => {
                  e.preventDefault();
                }, 3000);
                setClassname(styles.dragPreview);
                setPreviewDestination(DRAG_PREVIEW_ID);
                setOffset({ x: 10, y: 10 });
                const element = draggableById(id);
                if (!element) {
                  return;
                }

                const sourcePath = ReactEditor.findPath(
                  slate,
                  ReactEditor.toSlateNode(slate, element)
                );
                const singleBlockRange = Editor.range(slate, sourcePath);
                let range: Range = KitemakerElement.listViewChildrenRange(slate, sourcePath);
                const selection = safeSelection(slate);
                if (
                  selection &&
                  !Range.isCollapsed(selection) &&
                  Range.intersection(singleBlockRange, selection)
                ) {
                  const start = Range.start(selection);
                  const end = Range.end(selection);
                  const startRange = Editor.range(slate, start.path);
                  const endRange = Editor.range(slate, end);
                  range = {
                    anchor: startRange.anchor,
                    focus: endRange.focus,
                  };
                }

                e.dataTransfer.setData(
                  'application/json',
                  JSON.stringify({ type: 'block', id, range })
                );
                e.dataTransfer.effectAllowed = 'move';
                e.dataTransfer.setDragImage(emptyImage, -1000, -1000);

                if (Range.equals(range, singleBlockRange)) {
                  const clone = getClone(element);
                  setTimeout(() => {
                    setNode(clone);
                  }, 1);
                  return;
                }
                const domRange = ReactEditor.toDOMRange(slate, range);
                const elements = getElementsInRange(domRange);
                dragRefs[id] = elements;

                for (const element of elements) {
                  setTimeout(() => {
                    element.style.opacity = '0.3';
                  }, 1);
                }
                setTimeout(() => {
                  const clones = elements.map(element => getClone(element));
                  const clone = document.createElement('div');
                  clone.append(...clones);
                  setNode(clone);
                }, 1);
              }}
              onDragEnd={() => {
                ReactEditor.deselect(slate);
                const elements = dragRefs[id] ?? [];
                for (const element of elements) {
                  element.style.opacity = '1';
                }
                delete dragRefs[id];
                setNode(null);

                const element = draggableById(id);
                if (!element) {
                  return;
                }

                element.style.opacity = '1';
              }}
            >
              <Icon icon="drag" size={IconSize.Size20} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export function useDragAndDrop(options?: { smallPadding?: boolean; fileDropDisabled?: boolean }) {
  const clientId = useClient();
  const toMarkdown = useSerializeToMarkdown();
  const interactivityDisabled = useInteractivityDisabled();
  const slate = useSlateStatic();
  const dragTimeoutRef = React.useRef(-1);
  const [direction, setDirection] = React.useState<DropHighlightPosition | null>(null);
  const id = React.useMemo(() => uuid.v4(), []);

  const getComment = useRecoilCallback(
    ({ snapshot }) =>
      (id: string) => {
        return snapshot.getLoadable(commentSelector(id)).getValue();
      },
    []
  );
  const commentUrl = useCommentUrl();

  const onDragLeave = React.useCallback(() => {
    dragTimeoutRef.current = window.setTimeout(() => setDirection(null), 100);
  }, []);

  const onDragOver = React.useCallback((e: React.DragEvent) => {
    e.preventDefault();

    const element = draggableById(id);

    if (
      !element ||
      (!slate.blockDragAndDropEnabled && e.dataTransfer?.types.includes('application/json')) ||
      (!slate.fileDragAndDropEnabled && e.dataTransfer?.types.includes('Files'))
    ) {
      setDirection(null);
      return;
    }
    if (dragTimeoutRef.current) {
      clearTimeout(dragTimeoutRef.current);
      dragTimeoutRef.current = -1;
    }

    // scroll if needed
    if (e.clientY < SCROLL_THRESHOLD) {
      const scrollParent = findScrollParent(element);
      if (scrollParent) {
        scrollParent.scrollBy({
          top: ((SCROLL_THRESHOLD - e.clientY) / SCROLL_THRESHOLD) * -SCROLL_INCREMENT,
        });
      }
    }
    if (window.innerHeight - e.clientY < SCROLL_THRESHOLD) {
      const scrollParent = findScrollParent(element);
      if (scrollParent) {
        scrollParent.scrollBy({
          top:
            ((SCROLL_THRESHOLD - (window.innerHeight - e.clientY)) / SCROLL_THRESHOLD) *
            SCROLL_INCREMENT,
        });
      }
    }

    // Determine rectangle on screen
    const hoverBoundingRect = element?.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = hoverBoundingRect.height / 2 + hoverBoundingRect.y;

    // Determine mouse position
    const clientOffset = e.clientY;

    if (!clientOffset) {
      return;
    }
    if (clientOffset > hoverMiddleY) {
      setDirection('bottom');
      return;
    }
    if (clientOffset < hoverMiddleY) {
      setDirection('top');
      return;
    }
  }, []);

  const onDrop = React.useCallback(
    async (e: React.DragEvent) => {
      setDirection(null);
      e.preventDefault();
      e.stopPropagation();

      const element = draggableById(id);

      if (!element) {
        return;
      }

      const data = e.dataTransfer?.getData('application/json');
      if (data) {
        const { type, range, id }: { type: string; id: string; range?: Range } = JSON.parse(data);
        if (type === 'block' && range) {
          onDropBlock(slate, direction!, element, range);
        } else if (type === 'comment' && id) {
          const comment = getComment(id);
          if (comment) {
            onDropComment(
              slate,
              direction!,
              element,
              comment,
              commentUrl(comment, true),
              clientId,
              toMarkdown
            );
          }
        }
      } else if (
        !options?.fileDropDisabled &&
        e.dataTransfer?.files?.length &&
        slate.attachmentUploadPath
      ) {
        const files = Array.from(e.dataTransfer?.files);
        onDropFile(slate, direction!, element, files, slate.attachmentUploadPath);
      }
    },
    [direction]
  );

  if (interactivityDisabled) {
    return {
      dndAttributes: {},
      dndComponents: null,
      dndClassName: undefined,
    };
  }

  return {
    dndAttributes: {
      onDragOver,
      onDragLeave,
      onDrop,
      [`data-dnd`]: id,
    },
    dndComponents: (
      <DragAndDropComponent id={id} smallPadding={options?.smallPadding} direction={direction} />
    ),
    dndClassName: cn(styles.block, {
      [styles.blockDrag]: slate.blockDragAndDropEnabled,
    }),
  };
}
