import * as React from 'react';
import { OrganizationMember } from '../../../sync/__generated/models';
import LinkButton from '../../components/linkButton';
import { useOrganization } from '../../contexts/organizationContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import { SyncEngineTransaction, useModelManager } from '../../graphql/modelManager';
import { Starred, renderStarred } from '../../utils/starred';
import { SyncEngineObject } from '../types';

function addToStarred(
  tx: SyncEngineTransaction,
  get: <T extends SyncEngineObject>(id: string) => T | null,
  organizationId: string,
  userId: string,
  starred: Starred
) {
  const orgMembership = get<OrganizationMember>(`${organizationId}:${userId}`);
  if (!orgMembership) {
    return;
  }
  tx.update<OrganizationMember>(orgMembership.id, {
    starred: [...(orgMembership.starred ?? []), starred],
  });
}

function removeFromStarred(
  tx: SyncEngineTransaction,
  get: <T extends SyncEngineObject>(id: string) => T | null,
  organizationId: string,
  userId: string,
  starred: Starred
) {
  const orgMembership = get<OrganizationMember>(`${organizationId}:${userId}`);
  if (!orgMembership) {
    return;
  }
  tx.update<OrganizationMember>(orgMembership.id, {
    starred: (orgMembership.starred ?? []).filter(
      (s: Starred) => s.id !== starred.id || s.type !== starred.type
    ),
  });
}

export function useToggleStarred() {
  const organization = useOrganization();
  const currentUser = useCurrentUser();
  const modelManager = useModelManager();
  const { setUndo } = useUndo();

  return (starred: Starred) => {
    modelManager.transaction((tx, { get, getIndex }) => {
      const orgMembership = get<OrganizationMember>(`${organization.id}:${currentUser.id}`);
      if (!orgMembership) {
        return;
      }

      const isStarred = (orgMembership.starred ?? []).some(
        (f: Starred) => f.id === starred.id && f.type === starred.type
      );

      const rendered = renderStarred(get, getIndex, starred, organization);
      const action = rendered ? (
        <>
          <LinkButton to={rendered.link}>{rendered.name}</LinkButton>was{' '}
          {isStarred ? 'removed from' : 'added to'} <span className="medium">Starred</span>
        </>
      ) : null;

      if (isStarred) {
        removeFromStarred(tx, get, organization.id, currentUser.id, starred);

        if (action) {
          setUndo(action, () => {
            modelManager.transaction((tx, { get }) => {
              addToStarred(tx, get, organization.id, currentUser.id, starred);
            });
          });
        }

        return;
      }

      addToStarred(tx, get, organization.id, currentUser.id, starred);

      if (action) {
        setUndo(action, () => {
          modelManager.transaction((tx, { get }) => {
            removeFromStarred(tx, get, organization.id, currentUser.id, starred);
          });
        });
      }
    });
  };
}
