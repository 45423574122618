import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { highlightAssignedSelector } from '../../syncEngine/selectors/users';
import { isMobileOS } from '../../utils/config';
import { renderDate } from '../../utils/datetime';
import { ButtonStyle, IconButton } from './button';
import styles from './entityCard.module.scss';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import { Tooltip } from './tooltip';

function EntityCardComponent(
  {
    children,
    className,
    style,
    onClick,
    blocked,
    focused,
    highlight,
  }: {
    children: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    blocked?: boolean;
    focused?: boolean;
    highlight?: boolean;
  },
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  const highlightEnabled = useRecoilValue(highlightAssignedSelector);
  return (
    <div
      onClick={onClick}
      className={cn(styles.card, className, {
        [styles.blocked]: blocked,
        [styles.focused]: focused,
        [styles.highlight]: highlight && highlightEnabled,
      })}
      style={style}
      ref={ref}
    >
      {children}
    </div>
  );
}

export const EntityCard = React.forwardRef(EntityCardComponent);

export function EntityCardTitle({ children, type }: { children: string; type: string }) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [toolTipEnabled, setToolTipEnabled] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setToolTipEnabled(true);
    }
  }, [children]);

  const title = children || `Untitled ${type}`;
  const content = (
    <div ref={ref} className={cn(styles.title, 'fs-exclude')}>
      {title}
    </div>
  );

  if (!toolTipEnabled) {
    return content;
  }

  return <Tooltip content={<div className="menuLarge">{title}</div>}>{content}</Tooltip>;
}

export function EntityCardHeader({
  entityNumber,
  menuContents,
  date,
  children,
  leftContent,
}: {
  entityNumber: string;
  menuContents?: (closeMenu: () => void) => React.ReactNode;
  date?: number;
  children?: React.ReactNode;
  leftContent?: React.ReactNode;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  return (
    <div
      className={cn(styles.header, {
        [styles.menuOpen]: menuOpen,
        [styles.mobile]: isMobileOS,
      })}
    >
      <div className="row grow metadataGap">
        {leftContent}
        <div className={styles.number}>{entityNumber}</div>
        {children}
      </div>
      {date && <div className={styles.date}>{capitalize(renderDate(date, { compact: true }))}</div>}
      {menuContents && (
        <div
          className={styles.menu}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
            <DropdownMenuTrigger asChild>
              <IconButton buttonStyle={ButtonStyle.BareSubtle} icon="more" />
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="menuMedium"
              onClick={e => {
                e.stopPropagation();
              }}
              side="right"
              align="start"
            >
              {menuContents?.(closeMenu)}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </div>
  );
}

export function EntityMetadataRow({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn(styles.marginIfNotEmpty, 'row', 'flexWrap', 'metadataGap', className)}>
      {children}
    </div>
  );
}

export function EntityCardMetadataContainer({
  children,
  members,
}: {
  children: React.ReactNode;
  members: React.ReactNode;
}) {
  return (
    <div className="rowBetween metadataGap">
      <EntityMetadataRow>{children}</EntityMetadataRow>
      <div className={cn(styles.marginIfNotEmpty, 'noShrink', 'flexAlignStart')}>{members}</div>
    </div>
  );
}
