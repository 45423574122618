import React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Hotkey } from '../../../components/new/hotkey';
import { useKeyNavigationState } from '../../../components/new/keyNavigation';
import { TodoList } from '../../../components/new/todoList';
import { CommandMenuView } from '../../../contexts/modalContext';
import { SpaceProvider } from '../../../contexts/spaceContext';
import { useToggleTodos } from '../../../syncEngine/actions/todos';
import {
  entitySelector,
  isSpaceBoundEntity,
  useEntityPath,
} from '../../../syncEngine/selectors/entities';
import {
  assignIssueKey,
  labelIssueKey,
  markTodoCompleted,
  markTodoInProgress,
  setEffortKey,
  setImpactKey,
} from '../../../utils/config';
import { ChangeTodoLabelsContents } from './changeLabels';
import { ChangeTodoMembersContents } from './changeMembers';
import { SetTodoEffortContents } from './setEffort';
import { SetTodoImpactContents } from './setImpact';

type View =
  | CommandMenuView.ListTodos
  | CommandMenuView.TodoEffort
  | CommandMenuView.TodoImpact
  | CommandMenuView.TodoLabels
  | CommandMenuView.TodoMembers;

function Hotkeys({
  entityId,
  view,
  setView,
  onClose,
}: {
  entityId: string;
  view: View | null | undefined;
  setView: (viewOps: { view: View; todoId: string }) => void;
  onClose: () => void;
}) {
  const entityPath = useEntityPath();
  const toggleTodos = useToggleTodos();
  const history = useHistory();
  const { focused } = useKeyNavigationState();

  if (!focused) {
    return null;
  }

  return (
    <>
      {!view && (
        <>
          <Hotkey
            hotkey={'enter'}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              if (focused) {
                const link = {
                  pathname: entityPath(entityId),
                  state: {
                    backUrl: location.pathname,
                    backSearch: location.search,
                    entity: entityId,
                  },
                  search: `focusDescription=true&focusSmartTodo=${focused}`,
                };

                history.push(link);
                onClose();
              }
            }}
          />
          <Hotkey
            hotkey={markTodoCompleted}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              if (focused) {
                toggleTodos([focused]);
              }
            }}
          />
          <Hotkey
            hotkey={markTodoInProgress}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              if (focused) {
                toggleTodos([focused], true);
              }
            }}
          />
        </>
      )}
      <Hotkey
        hotkey={assignIssueKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          setView({ view: CommandMenuView.TodoMembers, todoId: focused });
        }}
      />

      <Hotkey
        hotkey={labelIssueKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          setView({ view: CommandMenuView.TodoLabels, todoId: focused });
        }}
      />
      <Hotkey
        hotkey={setImpactKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          setView({ view: CommandMenuView.TodoImpact, todoId: focused });
        }}
      />
      <Hotkey
        hotkey={setEffortKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          setView({ view: CommandMenuView.TodoEffort, todoId: focused });
        }}
      />
    </>
  );
}

export function ListTodosContents({
  onClose,
  entityId,
}: {
  onClose: () => void;
  entityId: string;
}) {
  const [view, setView] = React.useState<{ view: View; todoId: string } | null>(null);
  const [lastFocused, setLastFocused] = React.useState<string | undefined>(undefined);
  const entity = useRecoilValue(entitySelector(entityId));

  if (!entity || !isSpaceBoundEntity(entity)) {
    return null;
  }

  let contents;
  switch (view?.view) {
    case CommandMenuView.TodoMembers: {
      contents = (
        <ChangeTodoMembersContents
          onClose={() => {
            setLastFocused(view.todoId);
            setView(null);
          }}
          todoId={view.todoId}
          spaceId={entity.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.TodoLabels: {
      contents = (
        <ChangeTodoLabelsContents
          onClose={() => {
            setLastFocused(view.todoId);
            setView(null);
          }}
          todoId={view.todoId}
          spaceId={entity.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.TodoImpact: {
      contents = (
        <SetTodoImpactContents
          onClose={() => {
            setLastFocused(view.todoId);
            setView(null);
          }}
          todoId={view.todoId}
          spaceId={entity.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.TodoEffort: {
      contents = (
        <SetTodoEffortContents
          onClose={() => {
            setLastFocused(view.todoId);
            setView(null);
          }}
          todoId={view.todoId}
          spaceId={entity.spaceId}
        />
      );
      break;
    }
    default: {
      contents = (
        <SpaceProvider spaceId={entity.spaceId}>
          <TodoList entityId={entityId} className="fullWidth" focusedId={lastFocused} />
        </SpaceProvider>
      );
      break;
    }
  }

  return (
    <div className="rowStretch fullWidth fullHeight p16">
      <Hotkeys entityId={entityId} onClose={onClose} view={view?.view} setView={setView} />
      {contents}
    </div>
  );
}
