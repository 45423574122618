import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  ImpactChangedActivityDetails,
} from '../../../../sync/__generated/models';
import { impactSelector } from '../../../syncEngine/selectors/impactLevels';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function ImpactChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as ImpactChangedActivityDetails;
  const impact = useRecoilValue(impactSelector(details.impactId));

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> set the impact
      to{' '}
      <span className={styles.activityHighlight}>{impact?.name ?? details.fallbackImpactName}</span>
      .
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const ImpactChangedActivity = React.memo(ImpactChangedActivityComponent);
