import { values } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import {
  AutoArchivePeriod,
  IssueStatusType,
  StaleIssuePeriod,
} from '../../../../sync/__generated/models';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import { Select } from '../../../components/new/select';
import {
  Setting,
  SettingsPage,
  SettingsSection,
  SettingsSectionDivider,
} from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { toast } from '../../../components/toast';
import { useSpace } from '../../../contexts/spaceContext';
import { useMarkStatusDefault } from '../../../syncEngine/actions/issueStatuses';
import {
  useAddBacklog,
  useRemoveBacklog,
  useUpdateSpaces,
} from '../../../syncEngine/actions/spaces';
import {
  boardsForSpaceSelector,
  statusesForSpaceInBoardOrderSelector,
} from '../../../syncEngine/selectors/boards';
import { closeIssueKey } from '../../../utils/config';

function autoArchivePeriodToString(period: AutoArchivePeriod | null) {
  switch (period) {
    case AutoArchivePeriod.Day:
      return 'After a day';
    case AutoArchivePeriod.Week:
      return 'After a week';
    case AutoArchivePeriod.Month:
      return 'After a month';
    case AutoArchivePeriod.Quarter:
      return 'After 3 months';
    default:
      return 'Disabled';
  }
}

function staleIssuePeriodToString(period: StaleIssuePeriod | null) {
  switch (period) {
    case StaleIssuePeriod.Month:
      return 'After a month';
    case StaleIssuePeriod.Quarter:
      return 'After 3 months';
    case StaleIssuePeriod.Semester:
      return 'After 6 months';
    case StaleIssuePeriod.Year:
      return 'After a year';
    default:
      return 'Disabled';
  }
}

export function StatusConfigurationScreen() {
  const space = useSpace();
  const updateSpaces = useUpdateSpaces();
  const boards = useRecoilValue(boardsForSpaceSelector(space.id));
  const addBacklog = useAddBacklog();
  const removeBacklog = useRemoveBacklog();
  const markStatusDefault = useMarkStatusDefault();

  const backlogBoard = boards.find(b => b.key === 'backlog');

  const issueStatuses = useRecoilValue(statusesForSpaceInBoardOrderSelector(space.id));

  const possibleNewIssueStatuses = issueStatuses.filter(
    s => s.statusType === IssueStatusType.Todo || s.statusType === IssueStatusType.Backlog
  );
  const defaultNewStatus = possibleNewIssueStatuses.find(s => s.id === space.defaultNewStatusId);
  const defaultNewOptions = possibleNewIssueStatuses.map(s => ({
    value: s.id,
    label: s.name,
  }));

  const possibleClosedIssueStatuses = issueStatuses.filter(
    s => s.statusType === IssueStatusType.Done
  );
  const defaultClosedStatus = possibleClosedIssueStatuses.find(
    s => s.id === space.defaultDoneStatusId
  );
  const defaultClosedOptions = possibleClosedIssueStatuses.map(s => ({
    value: s.id,
    label: s.name,
  }));

  const autoArchiveOptions = [
    ...values(AutoArchivePeriod).map(value => ({
      value,
      label: autoArchivePeriodToString(value),
    })),
    {
      value: 'disabled',
      label: 'Disabled',
    },
  ];

  const staleIssueOptions = [
    ...values(StaleIssuePeriod).map(value => ({
      value,
      label: staleIssuePeriodToString(value),
    })),
    {
      value: 'disabled',
      label: 'Disabled',
    },
  ];

  return (
    <SettingsPage
      title="Workflow"
      description="Setup the ideal workflow for your team. From inbox all the way to completion."
    >
      <SettingsSection>
        <Setting
          title={`Enable planning board for “${space.name}” space`}
          description="If you need a place manage upcoming work items. This is a separate board that allows you to flesh out and prioritize, before items are moved to the board for current work."
        >
          <Switch
            checked={!!backlogBoard}
            onChange={v => {
              if (v) {
                addBacklog(space.id);
              } else {
                removeBacklog(space.id);
              }
            }}
          />
        </Setting>
      </SettingsSection>

      <SettingsSectionDivider />
      <SettingsSection>
        <Setting
          title={`Default status for new ${issueTerm}s`}
          description="The default status when you make new work items."
        >
          <Select
            values={defaultNewOptions}
            value={defaultNewStatus?.id ?? defaultNewOptions[0].value}
            onChange={v => {
              markStatusDefault(v, true);
            }}
          />
        </Setting>

        <Setting
          title={'Default completed status'}
          description={
            <>
              The default status for work items that are completed. This is where {issueTerm}s will
              end up after using the{' '}
              <span style={{ display: 'inline-block' }}>
                <KeyboardShortcut shortcut={closeIssueKey} />
              </span>{' '}
              shortcut.
            </>
          }
        >
          <Select
            values={defaultClosedOptions}
            value={defaultClosedStatus?.id ?? defaultClosedOptions[0].value}
            onChange={v => {
              markStatusDefault(v, false);
            }}
          ></Select>
        </Setting>
      </SettingsSection>

      <SettingsSectionDivider />

      <SettingsSection largeHeader title="Status automation">
        <Setting
          title={`Automatically archive completed ${issueTerm}`}
          description={
            <>
              Kitemaker will automatically archive work items with a status of &quot;
              <span className="bodyM semiBold">Done</span>&quot; after a specified period of time.
            </>
          }
        >
          <Select
            values={autoArchiveOptions}
            value={space.autoArchive ?? 'disabled'}
            onChange={v => {
              updateSpaces([space.id], {
                autoArchive: v === 'disabled' ? null : (v as AutoArchivePeriod),
              });
              if (v) {
                toast.info(
                  'Auto-archive enabled. Please allow a few minutes for the setting to take effect'
                );
              }
            }}
          ></Select>
        </Setting>

        <Setting
          title={`Notify of stale ${issueTerm}`}
          description="Kitemaker will alert members and watchers of a work item if it hasn't been updated for a specified period of time."
        >
          <Select
            values={staleIssueOptions}
            value={space.staleIssues ?? 'disabled'}
            onChange={v => {
              updateSpaces([space.id], {
                staleIssues: v === 'disabled' ? null : (v as StaleIssuePeriod),
              });
              if (v) {
                toast.info(
                  `Stale ${issueTerm} notification enabled. Please allow a few minutes for the setting to take effect`
                );
              }
            }}
          ></Select>
        </Setting>
      </SettingsSection>
    </SettingsPage>
  );
}
