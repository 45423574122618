import * as React from 'react';
import LinkButton from '../../components/linkButton';
import { trackerPageLoad } from '../../tracker';
import { ErrorScreen } from './errorScreen';

export function BasicErrorScreen({
  showLogo,
  children,
}: {
  showLogo?: boolean;
  children: React.ReactNode;
}) {
  React.useEffect(() => {
    trackerPageLoad('Error');
  }, []);

  return (
    <ErrorScreen showLogo={showLogo}>
      {children}
      <div>
        <LinkButton to={'/'}>Go home</LinkButton> or{' '}
        <LinkButton
          to={'/'}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            window.Intercom?.('showMessages');
          }}
        >
          get some help
        </LinkButton>
      </div>
    </ErrorScreen>
  );
}
