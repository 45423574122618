import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { statusSelector } from '../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../syncEngine/selectors/issueStatuses';
import { moveIssueKey } from '../../utils/config';
import { Button, ButtonSize, ButtonStyle } from './button';
import { Hotkey } from './hotkey';
import { KeyboardShortcut } from './keyboardShortcut';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import { StatusColumnPicker } from './pickers/statusColumnPicker';
import { statusToIcon } from './statusIcon';
import { Tooltip } from './tooltip';

export function StatusIndicator({
  statusId,
  issueId,
  className,
  size,
  onPicked,
  hotkey,
}: {
  statusId: string;
  issueId: string;
  className?: string;
  size?: ButtonSize;
  onPicked: (issueIds: string[], statusId: string) => void;
  hotkey?: boolean;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const status = useRecoilValue(statusSelector(statusId));
  const isDefault = useRecoilValue(isDefaultStatusSelector(statusId));
  if (!status) {
    return null;
  }
  const icon = statusToIcon(status.statusType, isDefault);
  const state = { [issueId]: [statusId] };

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <div>
          <Tooltip
            content={
              <>
                Change status <KeyboardShortcut shortcut={'m'} />
              </>
            }
          >
            <Button
              size={size}
              className={className}
              buttonStyle={ButtonStyle.Secondary}
              icon={icon}
            >
              {status.name}
            </Button>
          </Tooltip>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        <StatusColumnPicker state={state} onPicked={onPicked} onDone={closeMenu} />
      </DropdownMenuContent>
      {hotkey && !menuOpen && (
        <Hotkey
          hotkey={moveIssueKey}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setMenuOpen(true);
          }}
        />
      )}
    </DropdownMenu>
  );
}
