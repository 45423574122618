import cn from 'classnames';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import { isMobileOS } from '../../../utils/config';
import { useHasKeyNavigationSelection } from '../keyNavigation';
import styles from './card.module.scss';

export function CardComponent(
  {
    className,
    style,
    children,
  }: {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref} className={cn(styles.card, className)} style={style}>
      {children}
    </div>
  );
}

export const Card = React.forwardRef(CardComponent);

export function DraggableCardComponent(
  {
    id,
    draggingId,
    index,
    disabled,
    children,
    noTransform,
    dragSize,
    className,
    ...rest
  }: {
    id: string;
    draggingId: string | null;
    index: number;
    disabled?: boolean;
    noTransform?: boolean;
    dragSize?: number;
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const selected = useHasKeyNavigationSelection(id);
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={disabled || isMobileOS}>
      {cardProvided => {
        const style = { ...cardProvided.draggableProps.style };

        if (noTransform && draggingId) {
          if (dragSize) {
            (style as any).transform = `translate(0px, -${dragSize}px)`;
          } else {
            (style as any).transform = `none`;
          }
        }
        return (
          <div
            ref={cardProvided.innerRef}
            {...cardProvided.draggableProps}
            {...cardProvided.dragHandleProps}
            tabIndex={-1}
            style={style}
          >
            <Card
              ref={ref}
              {...rest}
              className={cn(
                {
                  [styles.multiDrag]: selected && draggingId && draggingId !== id,
                },
                className
              )}
            >
              {children}
            </Card>
          </div>
        );
      }}
    </Draggable>
  );
}
export const DraggableCard = React.forwardRef(DraggableCardComponent);

export function ResizableCard({
  onResized,
  children,
  ...rest
}: {
  onResized: () => void;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver(ref, onResized, { triggerOnMount: true });

  return (
    <Card {...rest}>
      <div ref={ref}>{children}</div>
    </Card>
  );
}
