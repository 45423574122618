import cn from 'classnames';
import React from 'react';
import styles from './blinkingDots.module.scss';

export function BlinkingDots({
  style,
  className,
}: {
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <div style={style} className={cn(styles.blinkingDots, className ?? '')}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  );
}
