import cn from 'classnames';
import EventEmitter from 'eventemitter3';
import { isEqual, last } from 'lodash';
import React, { CSSProperties, KeyboardEvent, useState } from 'react';
import { useHistory } from 'react-router';
import { atom, useRecoilValue } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Descendant, Editor, Element, Path, Range, Text, Transforms, createEditor } from 'slate';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps,
  Slate,
  withReact,
} from 'slate-react';
import { KitemakerElement } from '../../shared/slate/kitemakerNode';
import { withHistory } from '../../shared/slate/plugins/withHistory';
import { DocumentLike, EditorEvents, Elements } from '../../shared/slate/types';
import {
  defaultSelection,
  emptyDocument,
  isDocumentEmpty,
  safeSelection,
  stringifyDocument,
} from '../../shared/slate/utils';
import { ErrorBoundary } from '../components/errorBoundary';
import { useClient } from '../contexts/clientContext';
import { useModals } from '../contexts/modalContext';
import { useMaybeOrganization } from '../contexts/organizationContext';
import { useMaybeSpace } from '../contexts/spaceContext';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { localStorageEffect } from '../syncEngine/effects';
import { useCommentUrl, useFindComment } from '../syncEngine/selectors/comments';
import { useDetectLinks, useDetectMentions } from '../syncEngine/selectors/entities';
import { useCustomEmojis } from '../syncEngine/selectors/organizations';
import {
  snippetsForOrganizationSelector,
  snippetsForSpaceSelector,
} from '../syncEngine/selectors/snippets';
import { nativeSelection } from '../utils/dom';
import { fileUploader, uploadFilesFromDragAndDrop } from '../utils/fileUploader';
import { metaKeyDown, modifierKeyDown } from '../utils/keyEvents';
import { attachmentsPath } from '../utils/paths';
import { graphemeLength } from '../utils/text';
import { decorate } from './decorators/decorate';
import { useCopy } from './hooks/useCopy';
import { useSerializeToMarkdown } from './hooks/useSerializeToMarkdown';
import { hoverContext } from './hovers';
import { FormatHover } from './hovers/formatHover';
import { SuggestionHover } from './hovers/suggestionHover';
import { KitemakerEditor } from './kitemakerEditor';
import { KitemakerTransforms } from './kitemakerTransforms';
import { elementsOnKeyDown } from './onKeyDownHandlers/elements';
import { hotkeysOnKeyDown } from './onKeyDownHandlers/hotkeys';
import { saveOnKeyDown } from './onKeyDownHandlers/save';
import { snippetHotkeys } from './onKeyDownHandlers/snippets';
import { clearMarksOnCustomHardbreaks, insertSoftBreak } from './onKeyDownHandlers/softbreaks';
import { emojiShortcutMatcher } from './plugins/stringMatching/emojiShortcutMatcher';
import { linkMatcher } from './plugins/stringMatching/linkMatcher';
import { markdownBlockMatchers } from './plugins/stringMatching/markdownBlockMatchers';
import { markdownInlineMatchers } from './plugins/stringMatching/markdownInlineMatchers';
import { markdownLinkMatcher } from './plugins/stringMatching/markdownLinkMatcher';
import { markdownMarkMatchers } from './plugins/stringMatching/markdownMarkMatchers';
import { Matcher, match, withStringMatching } from './plugins/stringMatching/withStringMatching';
import { emojiSuggestionMatcher } from './plugins/suggestions/emojiSuggestionMatcher';
import {
  InsertMenuInlineChoice,
  insertMenuSuggestionMatcher,
} from './plugins/suggestions/insertMenuSuggestionMatcher';
import { snippetSuggestionMatcher } from './plugins/suggestions/snippetSuggestionMatcher';
import {
  SuggestionMatcher,
  SuggestionState,
  withSuggestions,
} from './plugins/suggestions/withSuggestions';
import { withBottomOffset } from './plugins/withBottomOffset';
import { CollaborationPlugin, SetCollaborativeEditor } from './plugins/withCollaboration';
import { debug, withDebug } from './plugins/withDebug';
import { withInlineBreaking } from './plugins/withInlineBreaking';
import { withLegacyFileDragAndDrop } from './plugins/withLegacyFileDragAndDrop';
import { withMarkBreaking } from './plugins/withMarkBreaking';
import { withNonInteractiveElements } from './plugins/withNonInteractiveElements';
import { withPasting } from './plugins/withPasting';
import { withSchema } from './plugins/withSchema';
import { RenderElement } from './renderElement';
import { RenderLeaf } from './renderLeaf';
import { RenderPlaceholder } from './renderPlaceholder';
import styles from './textArea.module.scss';
import { CursorLocations, EditorType } from './types';
export type { SuggestionMatcher } from './plugins/suggestions/withSuggestions';

const IDLE_TIMEOUT = 250;

export enum TextAreaType {
  Bare = 'bare',
  InputLarge = 'inputLarge',
  InputMedium = 'inputMedium',
  InputSmall = 'inputSmall',
}

const typeClasses = {
  [TextAreaType.Bare]: [],
  [TextAreaType.InputLarge]: [styles.inputType, styles.large],
  [TextAreaType.InputMedium]: [styles.inputType, styles.medium],
  [TextAreaType.InputSmall]: [styles.inputType, styles.small],
};

const emitters = new WeakMap<EditorType, EventEmitter<EditorEvents>>();

export interface TextAreaHandle {
  focus(): void;
  blur(): void;
  selectAll(): void;
  clearSelection(): void;
  moveSelectionToStart(): void;
  moveSelectionToEnd(): void;
  moveSelectionToPath(path: Path): void;
  clear(): void;
  forceStringMatch(newLine?: boolean): DocumentLike;
  prepareForNewValue(value: Descendant[]): void;
  replaceValue(value: Descendant[]): void;
  ensureBlankLastLine(): void;
  ensureBottomMarginVisible(): void;
  isOnFirstLine(): boolean;
  isAtStart(): boolean;
  raw(): EditorType;
}

export interface TextAreaProps {
  type?: TextAreaType;
  autoFocus?: boolean;
  autoFocusPath?: Path | null;
  initialValue: Descendant[];
  onChange?: (value: Descendant[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onIdle?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  className?: string;
  style?: CSSProperties;
  richText?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: React.ReactNode;
  showPlaceholderOnFirstParagraph?: boolean;
  tabIndex?: number;
  maxLength?: number;
  bottomMargin?: number;
  bottomOffset?: number;
  suggestions?: SuggestionMatcher[];
  isHardBreak?: (e: KeyboardEvent) => boolean;
  isSoftBreak?: (e: KeyboardEvent) => boolean;
  withCollaboration?: CollaborationPlugin;
  enableBlockDragging?: boolean;
  disableTodos?: boolean;
  withSmartTodos?: {
    plugin: (editor: EditorType) => EditorType;
    onKeyDownHandler: (editor: EditorType, e: React.KeyboardEvent<HTMLDivElement>) => boolean;
  };
  entityId?: string;
  feedbackId?: string;
  inlineComments?: boolean;
  oneLine?: boolean;
  disableModals?: boolean;
  additionalInsertMenuChoices?: InsertMenuInlineChoice[];
  // schema? FIXME-SLATE
}

function matchers(e: EditorType): Matcher[] {
  return [
    ...markdownMarkMatchers(e),
    ...markdownBlockMatchers(e),
    ...markdownInlineMatchers(e),
    markdownLinkMatcher(e),
    linkMatcher(e),
    emojiShortcutMatcher(e),
  ];
}

function handle(
  editor: EditorType,
  bottomMarginRef: React.RefObject<HTMLDivElement>,
  isDisabled: () => boolean
) {
  return {
    focus() {
      debug('focus');
      if (isDisabled()) {
        return;
      }
      ReactEditor.focus(editor);
    },
    blur() {
      debug('blur');
      Transforms.deselect(editor);
      ReactEditor.blur(editor);
    },
    selectAll() {
      debug('selectAll');
      KitemakerTransforms.selectAll(editor);
    },
    clearSelection() {
      debug('clearSection');
      ReactEditor.deselect(editor);
    },
    moveSelectionToStart() {
      debug('moveSelectionToStart');
      KitemakerTransforms.moveSelectionToStart(editor);
    },
    moveSelectionToEnd() {
      debug('moveSelectionToEnd');
      ReactEditor.deselect(editor);
      KitemakerTransforms.moveSelectionToEnd(editor);
    },
    moveSelectionToPath(path: Path) {
      debug('moveSelectionToPath');
      KitemakerTransforms.moveSelectionToPath(editor, path);
    },
    clear() {
      debug('clear');
      KitemakerTransforms.clear(editor);
    },
    forceStringMatch(newLine?: boolean) {
      debug('forceStringMatch');
      match(editor, matchers(editor), newLine);
      return editor.children;
    },
    ensureBottomMarginVisible() {
      debug('ensureBottomMarginVisible');
      if (!bottomMarginRef.current) {
        debug('ensureBottomMarginVisible - no endRef');
        return;
      }
      scrollIntoView(bottomMarginRef.current, {
        scrollMode: 'if-needed',
      });
    },
    ensureBlankLastLine() {
      debug('ensureBlankLastLine');
      KitemakerTransforms.ensureBlankLastLine(editor);
    },
    prepareForNewValue(value: Descendant[]) {
      const selection = safeSelection(editor);
      if (!selection) {
        return;
      }

      if (!value.length) {
        Transforms.select(editor, defaultSelection);
        return;
      }

      const { path, offset } = Range.end(selection);
      const mutablePath = [...path];

      if (!mutablePath.length) {
        Transforms.select(editor, defaultSelection);
        return;
      }

      const first = mutablePath.shift()!;
      let current = value[first];

      while (mutablePath.length) {
        if (!Element.isElement(current)) {
          break;
        }

        const pathElement = mutablePath.shift()!;
        current = current.children[pathElement];
      }

      if (!Text.isText(current)) {
        Transforms.select(editor, defaultSelection);
        return;
      }

      if (current.text.length <= offset) {
        Transforms.select(editor, {
          anchor: {
            path,
            offset: current.text.length,
          },
          focus: {
            path,
            offset: current.text.length,
          },
        });
      }
    },
    replaceValue(value: Descendant[]) {
      this.clear();
      this.prepareForNewValue(value);
      editor.insertFragment(value);
    },
    isOnFirstLine: () => {
      if (!editor) {
        return false;
      }
      const selection = safeSelection(editor);
      if (!selection) {
        return false;
      }
      const nativeSelectionRects = nativeSelection();
      if (nativeSelectionRects.length !== 1) {
        return false;
      }
      const [textNode, path] = Editor.node(editor, selection);
      if (!textNode || !path.length || path[0] !== 0) {
        return false;
      }
      const domNode = ReactEditor.toDOMNode(editor, textNode);
      if (!domNode) {
        return false;
      }
      return domNode.getBoundingClientRect().y === nativeSelectionRects[0].y;
    },
    raw() {
      return editor;
    },
    isAtStart: () => {
      const [, path] = Editor.first(editor, []);
      const focus = Editor.start(editor, path);
      return (
        !!editor.selection &&
        Range.isCollapsed(editor.selection) &&
        isEqual(focus, editor.selection.focus)
      );
    },
  };
}

export const spellCheckDisabledState = atom<boolean>({
  key: 'SpellCheckDisabled',
  default: false,
  effects: [localStorageEffect('__spellCheckDisabled')],
});

function TextAreaComponent(
  {
    type,
    autoFocus,
    autoFocusPath,
    initialValue,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onIdle,
    className,
    style,
    richText,
    readOnly,
    disabled,
    placeholder,
    showPlaceholderOnFirstParagraph,
    tabIndex,
    maxLength,
    suggestions: suggestionMatchers,
    bottomMargin,
    bottomOffset,
    isHardBreak,
    isSoftBreak,
    withCollaboration,
    enableBlockDragging,
    disableTodos,
    withSmartTodos: withTodos,
    entityId,
    feedbackId,
    inlineComments,
    oneLine,
    disableModals,
    additionalInsertMenuChoices,
  }: TextAreaProps,
  ref: React.Ref<TextAreaHandle>
) {
  const [suggestions, _setSuggestions] = React.useState<SuggestionState>({ suggestions: null });
  const [forceDisabled, setForceDisabled] = React.useState(false);

  const suggestionsRef = React.useRef(suggestions);
  const spellCheckDisabled = useRecoilValue(spellCheckDisabledState);

  const setSuggestions = React.useCallback(
    (newSuggestions: SuggestionState) => {
      if (!newSuggestions.suggestions && !suggestionsRef.current.suggestions) {
        return;
      }
      suggestionsRef.current = newSuggestions;
      _setSuggestions(newSuggestions);
    },
    [_setSuggestions]
  );

  const history = useHistory();
  const modals = useModals();
  const hovers = React.useContext(hoverContext);
  const space = useMaybeSpace();
  const organization = useMaybeOrganization();
  const spaceSnippets = useRecoilValue(snippetsForSpaceSelector(space?.id));
  const orgSnippets = useRecoilValue(snippetsForOrganizationSelector(organization?.id));

  const snippets = space ? spaceSnippets : orgSnippets;

  const customEmojis = useCustomEmojis();
  const detectLinks = useDetectLinks(organization);
  const detectMentions = useDetectMentions(organization);
  const bottomMarginRef = React.useRef<HTMLDivElement>(null);
  const [fakeSelection, setFakeSelection] = React.useState<Range | null>(null);
  const fakeSelectionRef = React.useRef(fakeSelection);
  fakeSelectionRef.current = fakeSelection;
  const idleTimeout = React.useRef(-1);

  const [cursors, _setCursors] = useState({} as CursorLocations);
  const cursorsRef = React.useRef(cursors);
  cursorsRef.current = cursors;
  function setCursors(newCursors: CursorLocations) {
    if (!isEqual(cursorsRef.current, newCursors)) {
      _setCursors(newCursors);
    }
  }

  const editor = React.useMemo(() => {
    let e = withMarkBreaking(withSchema(withReact(createEditor())));
    e.on = <T extends EventEmitter.EventNames<EditorEvents>>(
      event: T,
      callback: EventEmitter.EventListener<EditorEvents, T>
    ) => {
      if (!emitters.has(e)) {
        emitters.set(e, new EventEmitter());
      }
      emitters.get(e)!.on(event, callback);
      return e;
    };

    e.off = <T extends EventEmitter.EventNames<EditorEvents>>(
      event: T,
      callback: EventEmitter.EventListener<EditorEvents, T>
    ) => {
      if (!emitters.has(e)) {
        emitters.set(e, new EventEmitter());
      }
      emitters.get(e)!.off(event, callback);
      return e;
    };

    e.emit = <T extends EventEmitter.EventNames<EditorEvents>>(
      event: T,
      ...args: EventEmitter.EventArgs<EditorEvents, T>
    ) => {
      if (!emitters.has(e)) {
        emitters.set(e, new EventEmitter());
      }

      return emitters.get(e)!.emit(event, ...args);
    };

    e = withBottomOffset(e);
    e = withNonInteractiveElements(e);
    e = withPasting(
      e,
      !!richText,
      detectLinks,
      detectMentions,
      attachmentsPath(organization, space)
    );
    e = withHistory(e);

    e.smartTodosEnabled = !!withTodos;
    e.removeSmartTodos = !withTodos;
    e.removeInvalidComments = true;
    e.showPlaceholderOnFirstParagraph = showPlaceholderOnFirstParagraph;
    e.disableModals = disableModals;
    e.todosDisabled = disableTodos;
    e.snippetsEnabled = organization !== null;

    e.applyLocal = e.apply;

    if (!readOnly) {
      if (richText) {
        e = withStringMatching(e, matchers(e));
        e = withSuggestions(
          e,
          [
            ...(suggestionMatchers ?? []),
            insertMenuSuggestionMatcher(e, additionalInsertMenuChoices),
            emojiSuggestionMatcher(customEmojis),
            ...(organization
              ? [snippetSuggestionMatcher(organization, space, snippets, history)]
              : []),
          ],
          setSuggestions
        );
        e = withInlineBreaking(e);

        e.fileDragAndDropEnabled = true;
        if (enableBlockDragging) {
          e.blockDragAndDropEnabled = true;
        }
        e = withLegacyFileDragAndDrop(e, attachmentsPath(organization, space));
      } else {
        e = withSuggestions(e, suggestionMatchers ?? [], setSuggestions);
      }
    }

    if (debug.enabled) {
      e = withDebug(e);
    }

    e.isHardBreak =
      isHardBreak ??
      ((event: KeyboardEvent) =>
        event.key == 'Enter' && !event.shiftKey && !modifierKeyDown(event) && !metaKeyDown(event));
    e.isSoftBreak =
      isSoftBreak ??
      ((event: KeyboardEvent) =>
        event.key == 'Enter' && event.shiftKey && !modifierKeyDown(event) && !metaKeyDown(event));
    e.isAccept = (event: KeyboardEvent) =>
      event.key === 'Enter' && !event.shiftKey && !modifierKeyDown(event);
    e.bottomOffset = bottomOffset;
    e.fakeSelection = () => fakeSelectionRef.current;
    e.setFakeSelection = setFakeSelection;
    e.cursors = () => cursorsRef.current;
    e.updateCursors = newCursors => {
      setCursors(newCursors);
    };
    e.forceFocus = () => {
      if (!ReactEditor.isFocused(e)) {
        ReactEditor.focus(e);
        onFocus?.();
      }
    };
    e.richText = richText;
    e.entityId = entityId;
    e.feedbackId = feedbackId;
    e.inlineComments = inlineComments;

    Object.defineProperty(e, 'disabled', {
      get: () => forceDisabled,
      set: (d: boolean) => setForceDisabled(d),
    });

    if (withCollaboration) {
      if (withTodos) {
        e = withTodos.plugin(e);
      }
      e = withCollaboration.plugin(e);
    }

    return e;
    // FIXME: we want to make sure we only initiaze the editor once ever but there's probably a nicer way
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copy = useCopy(editor);
  const [showDragHandle, setShowDragHandle] = React.useState(!!editor.blockDragAndDropEnabled);

  useComponentDidMount(() => {
    editor.isDocumentEmpty = isDocumentEmpty(editor.children);

    // FIXME: this probably shouldn't be around forever, but it's here to handle past mistakes where
    // void blocks ended up with invalid children that make slate unhappy
    const forceNormalize = initialValue.some(
      node =>
        KitemakerElement.isElement(node) &&
        KitemakerElement.isVoid(node) &&
        !isEqual(node.children, [{ text: '' }])
    );
    if (forceNormalize) {
      editor.normalize({ force: true });
    }
  });

  const editorHandle = React.useMemo(
    () => handle(editor, bottomMarginRef, () => disabled ?? false),
    [editor, disabled]
  );

  React.useEffect(() => {
    if (autoFocus) {
      editorHandle.focus();
      if (autoFocusPath) {
        editorHandle.moveSelectionToPath(autoFocusPath);
      } else {
        editorHandle.moveSelectionToEnd();
      }
    }
  }, [editorHandle, autoFocus, autoFocusPath]);

  React.useImperativeHandle<TextAreaHandle, TextAreaHandle>(ref, () => editorHandle);

  const renderElement = React.useCallback(
    (props: RenderElementProps) => <RenderElement {...props} />,
    []
  );
  const renderLeaf = React.useCallback((props: RenderLeafProps) => <RenderLeaf {...props} />, []);
  const renderPlaceholder = React.useCallback(
    (props: RenderPlaceholderProps) => (
      <RenderPlaceholder
        {...props}
        placeholderElement={placeholder}
        richText={richText}
        showOnFirstParagraph={showPlaceholderOnFirstParagraph}
      />
    ),
    [placeholder, richText]
  );

  const collaborationEnabled = !!withCollaboration;
  const decorateNode = React.useMemo(
    () => decorate(editor, !!richText, collaborationEnabled),
    [editor, richText, collaborationEnabled]
  );

  const onClearSuggestions = React.useCallback(() => {
    setSuggestions({ suggestions: null });
  }, [setSuggestions]);

  // stuff needed for dragging/dropping comments
  const findComment = useFindComment();
  const commentUrl = useCommentUrl();
  const toMarkdown = useSerializeToMarkdown();
  const clientId = useClient();

  const appendAIPlacholderForComment = React.useCallback((commentId: string) => {
    const comment = findComment(commentId);
    if (!comment) {
      return;
    }
    const url = commentUrl(comment);
    const element: any = {
      type: Elements.AIPlaceholder,
      clientId,
      context: {
        content: toMarkdown(JSON.parse(comment.body)),
        url,
      },
      operation: 'todo',
      children: [
        {
          text: '',
        },
      ],
    };

    ReactEditor.focus(editor);

    if (isDocumentEmpty(editor.children)) {
      KitemakerTransforms.setNodes(editor, element, { at: [0] });
      KitemakerTransforms.select(editor, [0]);
    } else {
      KitemakerTransforms.insertNodes(editor, [element], {
        select: true,
        at: [editor.children.length],
      });
    }
  }, []);

  return (
    <div
      className={cn(
        styles.textArea,
        className,
        {
          [styles.disabled]: disabled || forceDisabled,
          [styles.richText]: richText,
          [styles.readOnly]: readOnly,
          [styles.dragAndDrop]: enableBlockDragging,
          [styles.showDragHandle]: showDragHandle && !editor.isDocumentEmpty,
          [styles.oneLine]: oneLine,
        },
        ...typeClasses[type ?? TextAreaType.Bare]
      )}
      style={style}
      data-editor-container="true"
    >
      <div
        className={cn('grow', 'relative', {
          colStretch: !oneLine,
        })}
      >
        <ErrorBoundary className={cn(styles.textArea, className)}>
          <Slate
            editor={editor}
            initialValue={initialValue ?? emptyDocument()}
            onChange={newValue => {
              editor.isDocumentEmpty = isDocumentEmpty(newValue);
              setShowDragHandle(false);
              onChange?.(newValue);
            }}
          >
            {richText && <FormatHover />}
            {withCollaboration && <SetCollaborativeEditor withCollaboration={withCollaboration} />}
            <SuggestionHover suggestions={suggestions} onClearSuggestions={onClearSuggestions} />
            <Editable
              className={styles.editable}
              onMouseMove={() => {
                if (editor.blockDragAndDropEnabled) {
                  setShowDragHandle(true);
                }
              }}
              tabIndex={disabled || readOnly ? undefined : tabIndex}
              autoFocus={autoFocus}
              readOnly={readOnly || disabled || forceDisabled}
              disabled={disabled || forceDisabled}
              onKeyDown={e => {
                if (idleTimeout.current !== -1) {
                  window.clearTimeout(idleTimeout.current);
                }
                if (onIdle) {
                  idleTimeout.current = window.setTimeout(onIdle, IDLE_TIMEOUT);
                }

                if (hovers.onKeyDown(e)) {
                  return;
                }

                if (
                  maxLength &&
                  !e.metaKey &&
                  editor.selection &&
                  graphemeLength(e.key) === 1 &&
                  Range.isCollapsed(editor.selection) &&
                  stringifyDocument(editor.children).length >= maxLength
                ) {
                  e.preventDefault();
                  return;
                }

                if (richText) {
                  if (insertSoftBreak(editor, e)) {
                    return;
                  }
                  if (hotkeysOnKeyDown(editor, e)) {
                    return;
                  }
                  if (saveOnKeyDown(editor, e)) {
                    return;
                  }
                  if (withTodos?.onKeyDownHandler(editor, e)) {
                    return;
                  }
                  if (
                    KitemakerElement.focusIsInNonInteractiveElement(editor) &&
                    !(e.key.toLowerCase().startsWith('arrow') && !e.altKey) &&
                    !(metaKeyDown(e) && e.key.toLowerCase() === 'c')
                  ) {
                    e.preventDefault();
                    return;
                  }
                  if (elementsOnKeyDown(editor, e)) {
                    return;
                  }
                  if (
                    editor.snippetsEnabled &&
                    snippetHotkeys(editor, space?.id, e, modals, snippets)
                  ) {
                    return;
                  }
                  if (isHardBreak && clearMarksOnCustomHardbreaks(editor, e)) {
                    return;
                  }
                }

                if (e.key.toLowerCase() === 'enter' && ReactEditor.isComposing(editor)) {
                  return;
                }

                if (editor.keyPressEvent?.(e)) {
                  return;
                }

                onKeyDown?.(e);

                if (!modifierKeyDown(e) && e.key.toLowerCase() !== 'shift') {
                  KitemakerEditor.ensureBottomOffset(editor);
                }
              }}
              onFocusCapture={() => {
                // We use this to prevent void block onClicks when the editor first
                // gets focus, so you always need to select a void block before clicking it
                editor.isFocusing = true;
                setTimeout(() => {
                  editor.isFocusing = false;
                }, 100);
              }}
              onFocus={onFocus}
              onBlur={() => {
                const selection = safeSelection(editor);
                if (
                  selection &&
                  Range.isCollapsed(selection) &&
                  KitemakerElement.focusIsInTopLevelVoidElement(editor)
                ) {
                  editorHandle.clearSelection();
                }
                onBlur?.();
              }}
              onClick={e => {
                if (disabled || !editor.children.length) {
                  return;
                }
                // let's get the rect of the last child element
                const domNode = ReactEditor.toDOMNode(editor, last(editor.children)!);
                if (!domNode) {
                  return;
                }

                // are we clicking below the last node? If so, let's append a paragraph
                const rect = domNode.getBoundingClientRect();
                if (rect.bottom < e.clientY) {
                  editorHandle.ensureBlankLastLine();
                  editorHandle.moveSelectionToEnd();
                }
              }}
              renderLeaf={renderLeaf}
              renderElement={renderElement}
              renderPlaceholder={renderPlaceholder}
              placeholder={'__dummyPlaceholder__'}
              decorate={decorateNode}
              spellCheck={!spellCheckDisabled}
              onCopy={e => {
                copy(e.clipboardData);
                e.preventDefault();
              }}
              onDragOver={e => {
                if (!richText) {
                  return;
                }
                e.preventDefault();
              }}
              onDrop={e => {
                if (!richText) {
                  return;
                }

                const data = e.dataTransfer.getData('application/json');
                if (data) {
                  const parsed = JSON.parse(data);
                  if (parsed.type === 'comment' && parsed.id) {
                    e.preventDefault();
                    appendAIPlacholderForComment(parsed.id);
                    return;
                  }
                }
              }}
            />
            {(bottomMargin ?? 0) > 0 && (
              <div
                ref={bottomMarginRef}
                className={styles.bottomMargin}
                style={{ minHeight: `${bottomMargin}px` }}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  editorHandle.ensureBlankLastLine();
                  editorHandle.focus();
                  editorHandle.moveSelectionToEnd();
                  editorHandle.ensureBottomMarginVisible();
                }}
                onDragOver={e => {
                  if (!richText) {
                    return;
                  }
                  // required to make onDrop work
                  e.preventDefault();
                }}
                onDrop={e => {
                  if (!richText) {
                    return;
                  }
                  const data = e.dataTransfer.getData('application/json');
                  if (data) {
                    const parsed = JSON.parse(data);
                    if (parsed.type === 'comment' && parsed.id) {
                      appendAIPlacholderForComment(parsed.id);
                      return;
                    }
                  }
                  if (!e.dataTransfer.files?.length || !editor.attachmentUploadPath) {
                    return false;
                  }

                  // handle when the user drops a file on the bottom margin
                  e.preventDefault();
                  e.dataTransfer.dropEffect = 'move';

                  const files = Array.from(e.dataTransfer.files);
                  editor.forceFocus();
                  const uploader = fileUploader(editor.attachmentUploadPath, { multi: true });
                  uploadFilesFromDragAndDrop(
                    editor,
                    uploader,
                    files,
                    [editor.children.length],
                    editor.attachmentUploadPath
                  );

                  return true;
                }}
              />
            )}
          </Slate>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export const TextArea = React.forwardRef(TextAreaComponent);
export default TextArea;
