import * as ToastPrimitive from '@radix-ui/react-toast';
import cn from 'classnames';
import { EventEmitter } from 'eventemitter3';
import { uniq } from 'lodash';
import * as React from 'react';
import uuid from 'uuid';
import { Button, ButtonSize, ButtonStyle, IconButton } from './new/button';
import { Icon } from './new/icon';
import { KeyboardShortcut } from './new/keyboardShortcut';
import styles from './toast.module.scss';

const DEFAULT_DURATION = 5000;
type ToastContents = JSX.Element | string | null;

interface ToastAction {
  label: string;
  hotkey?: string;
  onClick?: () => void;
}

interface ToastOptions {
  duration?: number;
  id?: string;
  large?: boolean;
  center?: boolean;
  action?: ToastAction;
  hideClose?: boolean;
}

interface ShowToastEvent {
  type: ToastType;
  contents: ToastContents;
  id: string;
  duration: number;
  action?: ToastAction;
  large?: boolean;
  center?: boolean;
  hideClose?: boolean;
}

interface RenderedToast {
  type: ToastType;
  contents: ToastContents;
  action?: ToastAction;
  duration: number;
  // the ID is the ID the client provides (e.g. 'undo'). If we receive the same ID again,
  // we hide the previous one and show the new one meaning for a brief moment, two (or more)
  // toasts with the same ID exist
  id: string;
  // a totally unique ID (across duplicate client provided IDs) that we use to identify a
  // particular instance of a toast
  internalId: string;
  // when we replace one toast with another of the same ID, we don't want an animation
  suppressShowAnimation?: boolean;
  // if the toast should be large or not
  large?: boolean;
  // if the toast should be a center toast or not
  center?: boolean;
  // if the toast 'x' should be hidden
  hideClose?: boolean;
}

interface HideToastEvent {
  id: string;
}

enum ToastType {
  Info,
  Success,
  Error,
  Warn,
  Hint,
  Raw,
}

const emitter = new EventEmitter<'show' | 'hide'>();

export function Toast() {
  const [hiding, setHiding] = React.useState<string[]>([]);
  const [toasts, setToasts] = React.useState<RenderedToast[]>([]);

  React.useEffect(() => {
    function onHide(event: HideToastEvent) {
      const toHide: string[] = [];
      for (const toast of toasts) {
        // the hide event is delivering the client supplied ID, so we match on that but toHide stores the internal ID
        if (toast.id === event.id) {
          toHide.push(toast.internalId);
        }
      }
      if (toHide.length) {
        setHiding(previous => uniq([...previous, ...toHide]));
        //  give the animation time to run, then remove the toasts
        setTimeout(() => {
          setHiding(previous => previous.filter(id => !toHide.includes(id)));
          setToasts(previous => previous.filter(t => !toHide.includes(t.internalId)));
        }, 1000);
      }
    }

    function onShow(event: ShowToastEvent) {
      const newToast: RenderedToast = {
        ...event,
        internalId: uuid.v4(),
        suppressShowAnimation: !!toasts.find(t => t.id === event.id),
      };
      // add the new toast, immediately removing any other toasts with the same ID
      setToasts(previous => [...previous.filter(t => t.id !== newToast.id), newToast]);
    }

    emitter.on('hide', onHide);
    emitter.on('show', onShow);

    return () => {
      emitter.off('hide', onHide);
      emitter.off('show', onShow);
    };
  }, [setHiding, setToasts, toasts]);

  const renderedToasts = toasts.map(toast => {
    let icon;
    switch (toast.type) {
      case ToastType.Info:
      case ToastType.Warn:
      case ToastType.Error:
      case ToastType.Raw:
        icon = 'info';
        break;
      case ToastType.Success:
        icon = 'success';
        break;
      case ToastType.Hint:
        icon = 'keyboard';
        break;
    }
    return (
      <ToastPrimitive.Root
        key={toast.internalId}
        className={cn(styles.toast, {
          [styles.success]: toast.type === ToastType.Success,
          [styles.warn]: toast.type === ToastType.Warn,
          [styles.error]: toast.type === ToastType.Error,
          [styles.suppressShowAnimation]: toast.suppressShowAnimation,
          [styles.large]: toast.large,
          [styles.center]: toast.center,
        })}
        open={!hiding.includes(toast.internalId)}
        onOpenChange={open => {
          if (open) {
            return;
          }
          setHiding(previous => uniq([...previous, toast.internalId]));
          //  give the animation time to run, then remove the toast
          setTimeout(() => {
            setHiding(previous => previous.filter(id => id !== toast.internalId));
            setToasts(previous => previous.filter(t => t.internalId !== toast.internalId));
          }, 1000);
        }}
        duration={toast.duration}
        onMouseDown={(e: React.MouseEvent) => {
          e.preventDefault();
        }}
      >
        {toast.type !== ToastType.Raw && (
          <Icon style={{ marginTop: 1 }} className="mr8" icon={icon} />
        )}
        <div className="grow">
          {toast.contents}
          {toast.action && (
            <div className="row fullWidth" style={{ marginTop: 10 }}>
              <ToastPrimitive.Action altText="Undo" asChild>
                <Button onClick={toast.action.onClick}>
                  {toast.action.label}
                  {toast.action.hotkey && (
                    <KeyboardShortcut className="ml8" shortcut={toast.action.hotkey} />
                  )}
                </Button>
              </ToastPrimitive.Action>
            </div>
          )}
        </div>
        {!toast.hideClose && (
          <ToastPrimitive.Close asChild>
            <IconButton
              style={{ marginTop: 1 }}
              size={ButtonSize.ExtraSmall}
              icon="exit"
              buttonStyle={ButtonStyle.BareSubtle}
              onClick={() => hide(toast.id)}
            />
          </ToastPrimitive.Close>
        )}
      </ToastPrimitive.Root>
    );
  });

  return (
    <ToastPrimitive.Provider swipeDirection="right">
      {renderedToasts}
      <ToastPrimitive.Viewport className={styles.toastViewport} />
    </ToastPrimitive.Provider>
  );
}

function show(type: ToastType, contents: ToastContents, options?: ToastOptions): string {
  const event: ShowToastEvent = {
    type,
    contents,
    id: options?.id ?? uuid.v4(),
    duration: options?.duration ?? DEFAULT_DURATION,
    action: options?.action,
    large: options?.large,
    center: options?.center,
    hideClose: options?.hideClose,
  };
  emitter.emit('show', event);
  return event.id;
}

export function hide(id: string) {
  const event: HideToastEvent = {
    id,
  };
  emitter.emit('hide', event);
}

export const toast = {
  info(contents: ToastContents, options?: ToastOptions) {
    const id = show(ToastType.Info, contents, options);
    return () => hide(id);
  },
  success(contents: ToastContents, options?: ToastOptions) {
    const id = show(ToastType.Success, contents, options);
    return () => hide(id);
  },
  error(contents: ToastContents, options?: ToastOptions) {
    const id = show(ToastType.Error, contents, options);
    return () => hide(id);
  },
  warn(contents: ToastContents, options?: ToastOptions) {
    const id = show(ToastType.Warn, contents, options);
    return () => hide(id);
  },

  hint(contents: ToastContents, options?: ToastOptions) {
    const id = show(ToastType.Hint, contents, options);
    return () => hide(id);
  },
  raw(contents: ToastContents, options?: ToastOptions) {
    const id = show(ToastType.Raw, contents, options);
    return () => hide(id);
  },
};
