import { toast } from '../components/toast';

export interface Export {
  id: string;
  format: 'csv' | 'json';
  status: string;
  error: string | null;
  createdAt: string;
  updatedAt: string;
}

export async function fetchExports(organizationId: string): Promise<Export[]> {
  const response = await fetch(`/export/${organizationId}`, {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Unable to fetch exports');
  }

  const responseJson = await response.json();
  if (responseJson.errors) {
    throw new Error('Unable to fetch exports');
  }

  const result = responseJson.data;
  return result;
}

export async function createExport(
  organizationId: string,
  format: 'csv' | 'json'
): Promise<Export | null> {
  const response = await fetch(`/export/${organizationId}`, {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      format,
    }),
  });

  if (!response.ok) {
    toast.error('Unable to start export');
    return null;
  }

  const responseJson = await response.json();
  if (responseJson.errors) {
    toast.error('Unable to start export');
    return null;
  }

  const result = responseJson.data;
  return result;
}
