import React from 'react';
import ExternalLink from '../../../components/externalLink';
import { Setting, SettingsPage, SettingsSection } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useSpace } from '../../../contexts/spaceContext';
import { useUpdateSpaces } from '../../../syncEngine/actions/spaces';
import { CycleSettings } from './cycleSettings';

export function CycleSettingsScreen() {
  const space = useSpace();
  const modals = useModals();
  const updateSpaces = useUpdateSpaces();

  function toggle() {
    if (!space.cyclesEnabled) {
      modals.openModal(Modals.EnableCycles, { spaceId: space.id });
      return;
    }
    updateSpaces([space.id], { cyclesEnabled: !space.cyclesEnabled });
  }

  const toggleSwitch = <Switch checked={space.cyclesEnabled} onChange={toggle} />;

  return (
    <SettingsPage
      title="Cycles"
      description={
        <>
          <p>
            Cycles or sprints are meant to help teams maintain forward momentum and focus by setting
            goals to achieve within a specific time period and following through on them.
          </p>
          <p>
            <ExternalLink className="link" href="https://guide.kitemaker.co/overview/cycles">
              Read more in the guide
            </ExternalLink>
          </p>
        </>
      }
    >
      <SettingsSection>
        <Setting
          title={'Enable cycles'}
          description={
            'Kitemaker will schedule Upcoming and Current cycles based on your preference and maintain them going forward.'
          }
        >
          {toggleSwitch}
        </Setting>
      </SettingsSection>

      {space.cyclesEnabled && <CycleSettings spaceId={space.id} />}
    </SettingsPage>
  );
}
