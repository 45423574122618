import cn from 'classnames';
import * as React from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { TodoStatus } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { entitySelector, isEntity } from '../../syncEngine/selectors/entities';
import { isTodo, todoSearchSelector, useFillTodoParents } from '../../syncEngine/selectors/todos';
import { syncEngineState } from '../../syncEngine/state';
import { isSyncEngineObject } from '../../syncEngine/types';
import { focusSearchKey, toggleHideKey } from '../../utils/config';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../utils/search';
import { CommandContext } from './commandMenuContext';
import { Hotkey } from './hotkey';
import { KeyboardShortcut } from './keyboardShortcut';
import {
  FocusReason,
  useGetKeyNavigationState,
  useKeyNavigationColumn,
  useKeyNavigationState,
  useSetKeyNavigationFocus,
} from './keyNavigation';
import { LISTVIEW_ID, ListView } from './listView';
import { Switch } from './switch';
import { TextInput } from './textInput';
import { TodoListItem } from './todoListItem';
import styles from './todoListItem.module.scss';

const hideCompletedTodosAtom = atom<boolean>({
  key: 'HideCompletedTodos',
  default: false,
});

interface TodoListProps {
  entityId: string;
  className?: string;
  headerClassName?: string;
  manualHotkeys?: boolean;
  keyNavColumnId?: string;
  focusedId?: string;
}

function TodoListContext({ entityId }: { entityId: string }) {
  const entity = useRecoilValue(entitySelector(entityId));
  const { focused } = useKeyNavigationState();
  const focusedObject = useRecoilValue(syncEngineState(focused ?? ''));

  if (
    !entity ||
    !focused ||
    (focusedObject &&
      isSyncEngineObject(focusedObject) &&
      !isEntity(focusedObject) &&
      !isTodo(focusedObject))
  ) {
    return null;
  }

  return (
    <CommandContext
      context={{ group: CommandGroup.Todos, todoId: focused, focusedTodoId: focused }}
    />
  );
}

function TodoListContent({
  entityId,
  className,
  headerClassName,
  keyNavColumnId,
  focusedId,
}: TodoListProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  const allTodos = useRecoilValue(todoSearchSelector(entityId));
  const fillTodoParents = useFillTodoParents();
  const [hideCompleted, setHideCompleted] = useRecoilState(hideCompletedTodosAtom);
  const [searchString, setSearchString] = React.useState('');

  const inputRef = React.useRef<HTMLInputElement>(null);

  const filteredTodos = React.useMemo(() => {
    return allTodos.filter(t => !hideCompleted || t.status !== TodoStatus.Done);
  }, [allTodos, hideCompleted]);

  const todoSearcher = React.useMemo(() => {
    return new FuzzySearcher(
      FuzzySearcherConfiguration.Autocomplete,
      ['labelNames', 'userNames', 'textContent'],
      filteredTodos
    );
  }, [filteredTodos]);

  const todos = React.useMemo(() => {
    return searchString.length ? todoSearcher.search(searchString).map(r => r.item) : filteredTodos;
  }, [filteredTodos, searchString, todoSearcher]);

  const filledTodos = React.useMemo(() => {
    return fillTodoParents(todos);
  }, [fillTodoParents, todos]);

  useKeyNavigationColumn(
    keyNavColumnId ?? LISTVIEW_ID,
    filledTodos.map(i => i.id),
    ref
  );

  const setFocus = useSetKeyNavigationFocus();
  const getKeyNavState = useGetKeyNavigationState();

  useComponentDidMount(() => {
    if (focusedId && getKeyNavState().focusedColumn === (keyNavColumnId ?? LISTVIEW_ID)) {
      setFocus(focusedId, FocusReason.Programmatic);
    }
  });

  return (
    <div className={cn(styles.content, className)} ref={ref}>
      <TodoListContext entityId={entityId} />
      <div className={headerClassName}>
        <TextInput
          className="fullWidth"
          onChange={v => setSearchString(v.currentTarget.value)}
          placeholder="Search todos"
          ref={inputRef}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'arrowdown') {
              e.preventDefault();
              e.stopPropagation();
              inputRef.current?.blur();
            }
            if (e.key.toLowerCase() === 'escape') {
              e.preventDefault();
              e.stopPropagation();
              inputRef.current?.blur();
            }
          }}
        />
        <div className="rowEnd metadataGap grayed bodyS mb16 mt4">
          Hide completed <KeyboardShortcut shortcut={toggleHideKey} />
          <Switch small checked={hideCompleted} onChange={v => setHideCompleted(v)} />
        </div>
      </div>

      <ListView
        className={styles.list}
        items={filledTodos.map(t => ({
          id: t.id,
          contents: <TodoListItem noLeftMargin id={t.id} />,
        }))}
      />

      <Hotkey
        hotkey={focusSearchKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          inputRef.current?.focus();
        }}
      />
      <Hotkey
        hotkey={toggleHideKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          setHideCompleted(!hideCompleted);
        }}
      />
    </div>
  );
}

export function TodoList(props: TodoListProps) {
  return <TodoListContent {...props} />;
}
