import React from 'react';
import { toast } from '../components/toast';

export async function uninstallDiscordIntegration(integrationId: string) {
  const res = await fetch(`/integrations/discord/uninstall?integrationId=${integrationId}`, {
    method: 'POST',
  });

  if (res.status == 200) {
    toast.success(
      <>
        Successfully uninstalled <span className="semiBold">Discord integration</span>
      </>
    );
  } else {
    toast.error(
      <>
        Failed to uninstall <span className="semiBold">Discord integration</span>. Code {res.status}
      </>
    );
  }
}
