import { groupBy, mapValues, orderBy } from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router';
import { GetRecoilValue, atom, selectorFamily, useRecoilCallback } from 'recoil';
import { filterNotDeleted, filterNotNull } from '../../../shared/utils/convenience';
import {
  organizationMembersByUser,
  updatesByOrganizationMember,
} from '../../../sync/__generated/indexes';
import {
  Activity,
  CodeReviewRequest,
  Comment,
  Entity,
  Organization,
  OrganizationMember,
  Space,
  Update,
  UpdateType,
} from '../../../sync/__generated/models';
import { useModals } from '../../contexts/modalContext';
import { showNotification } from '../../utils/notifications';
import { useMarkUpdatesRead } from '../actions/updates';
import { localStorageEffect } from '../effects';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { isSpaceBoundEntity } from './entities';
import { organizationsSelector } from './organizations';
import { spaceSelector } from './spaces';
import { currentUserState } from './users';

export const updatesScreenActiveUpdateState = atom<string | null>({
  key: 'UpdatesScreenActiveUpdate',
  default: null,
});

export enum UpdateFilterMode {
  ALL = 'all',
  UNREAD = 'unread',
  METNIONED = 'mentioned',
  SNOOZED = 'snoozed',
}

export const updatesScreenActiveFilterState = atom<UpdateFilterMode>({
  key: 'UpdatesScreenActiveFilter',
  default: UpdateFilterMode.ALL,
  effects: [localStorageEffect('__updatesSetting')],
});

export function useUpdateScreenState() {
  return useRecoilCallback(({ snapshot }) => () => {
    return snapshot.getLoadable(updatesScreenActiveUpdateState).getValue();
  });
}

export const updateActiveSelector = selectorFamily({
  key: 'UpdateActive',
  get:
    (updateId: string) =>
    ({ get }) => {
      return updateId === get(updatesScreenActiveUpdateState);
    },
});

export const updatesForCurrentUserSelector = selectorFamily({
  key: 'CurrentUserUpdates',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const user = get(currentUserState);
      if (!user) {
        return [];
      }
      const organizationMemberIds = get(
        indexKeyState(indexKey(organizationMembersByUser, user.id))
      );

      const organizationMemberId = organizationMemberIds.find(id => {
        const organizationMember = get(syncEngineState(id)) as OrganizationMember;
        return organizationMember.organizationId === organizationId;
      });

      if (!organizationMemberId) {
        return [];
      }

      const updateIds = get(
        indexKeyState(indexKey(updatesByOrganizationMember, organizationMemberId))
      ) as string[];
      const updates = filterNotDeleted(
        updateIds.map(updateId => get(syncEngineState(updateId)) as Update)
      );

      return updates.filter(update => validateUpdate(update, get));
    },
});

export const updatesScreenSelecctor = selectorFamily({
  key: 'UpdatesScreenSelector',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const updates = get(updatesForCurrentUserSelector(organizationId));
      if (!updates) {
        return null;
      }

      const filter = get(updatesScreenActiveFilterState);
      let filteredUpdates;

      switch (filter) {
        case UpdateFilterMode.UNREAD:
          filteredUpdates = updates.filter(u => !u.read);
          break;
        case UpdateFilterMode.METNIONED:
          filteredUpdates = updates.filter(
            u =>
              u.updateType === UpdateType.Mentioned ||
              u.updateType === UpdateType.MentionedInIntegration ||
              u.updateType === UpdateType.CommentReplyUserMentioned ||
              u.updateType === UpdateType.CommentUserMentioned ||
              u.updateType === UpdateType.Assigned
          );
          break;
        case UpdateFilterMode.ALL:
        default:
          filteredUpdates = updates;
      }

      const now = moment();
      const updatesByTime = groupBy(filteredUpdates, update => {
        const then = moment(update.createdAt);
        const daysDiff = now.diff(then, 'days');
        const isToday = daysDiff === 0 && now.dayOfYear() === then.dayOfYear();
        const isThisWeek = daysDiff < 7 && now.week() === then.week();

        if (isToday) {
          return 'Today';
        } else if (isThisWeek) {
          return 'This week';
        } else {
          return 'Earlier';
        }
      });

      return mapValues(updatesByTime, updatesForPeriod => {
        return mapValues(groupBy(updatesForPeriod, 'entityId'), updatesForEntity => {
          return orderBy(updatesForEntity, ['createdAt'], ['desc']);
        });
      });
    },
});

export const updateSelector = selectorFamily({
  key: 'Updates',
  get:
    (updateId: string) =>
    ({ get }) => {
      return get(syncEngineState(updateId)) as Update | null;
    },
});

export const unreadUpdatesForCurrentUserSelector = selectorFamily({
  key: 'CurrentUserUnreadUpdates',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const updates = get(updatesForCurrentUserSelector(organizationId));
      return updates.filter(u => u.notification && !u.read);
    },
});

export const updatesCountForCurrentUserSelector = selectorFamily({
  key: 'UpdatesCountForCurrentUser',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const updates = get(updatesForCurrentUserSelector(organizationId));
      return updates.length;
    },
});

export const unreadUpdatesCountForCurrentUserSelector = selectorFamily({
  key: 'UnreadUpdatesCountForCurrentUser',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const updates = get(unreadUpdatesForCurrentUserSelector(organizationId));
      return updates.length;
    },
});

export const codeReviewRequestSelector = selectorFamily({
  key: 'CodeReviewRequest',
  get:
    (requestId: string) =>
    ({ get }) => {
      return get(syncEngineState(requestId)) as CodeReviewRequest | null;
    },
});

export const codeReviewRequestsSelector = selectorFamily({
  key: 'CodeReviewRequests',
  get:
    (requestIds: string[]) =>
    ({ get }) => {
      return filterNotNull(requestIds.map(requestId => get(codeReviewRequestSelector(requestId))));
    },
});

// FIXME-SYNC maybe move these into the appropriate files and try to reuse them?
// FIXME-SYNC JV, in hindsight, yeah definitely move all of this into selectors
function validateActivity(activity: Activity, get: GetRecoilValue): boolean {
  if (activity.deleted) {
    return false;
  }

  const entity = get(syncEngineState(activity.entityId)) as Entity;
  if (!entity) {
    return false;
  }
  return validateEntity(entity, get);
}

function validateComment(comment: Comment, get: GetRecoilValue): boolean {
  if (comment.deleted) {
    return false;
  }

  const entity = get(syncEngineState(comment.entityId)) as Entity;
  if (!entity) {
    return false;
  }

  return validateEntity(entity, get);
}

function validateEntity(entity: Entity, get: GetRecoilValue): boolean {
  if (entity.deleted) {
    return false;
  }
  if (!isSpaceBoundEntity(entity)) {
    return true;
  }

  const space = get(spaceSelector(entity.spaceId));
  if (!space) {
    return false;
  }
  return validateSpace(space, get);
}

function validateSpace(space: Space, get: GetRecoilValue): boolean {
  if (space.deleted) {
    return false;
  }
  const organization = get(organizationsSelector(space.organizationId));
  if (!organization) {
    return false;
  }
  return validateOrganization(organization);
}

function validateOrganization(organization: Organization): boolean {
  return !organization.deleted;
}

export function validateUpdate(update: Update, get: GetRecoilValue): boolean {
  if (update.deleted || !update.dependencyIds) {
    return false;
  }

  for (const dependencyId of update.dependencyIds) {
    const dependency = get(syncEngineState(dependencyId)) as SyncEngineObject;
    if (!dependency) {
      return false;
    }

    switch (dependency.__typename) {
      case 'Activity':
        if (!validateActivity(dependency as Activity, get)) {
          return false;
        }
        break;
      case 'Comment':
        if (!validateComment(dependency as Comment, get)) {
          return false;
        }
        break;
      case 'Issue':
        if (!validateEntity(dependency as Entity, get)) {
          return false;
        }
        break;
      case 'Initiative':
        if (!validateEntity(dependency as Entity, get)) {
          return false;
        }
        break;
      case 'Feedback':
        if (!validateEntity(dependency as Entity, get)) {
          return false;
        }
        break;
      case 'Space':
        if (!validateSpace(dependency as Space, get)) {
          return false;
        }
        break;
      case 'Organization':
        if (!validateOrganization(dependency as Organization)) {
          return false;
        }
        break;
    }
  }
  return true;
}

export function useShowNotification() {
  const history = useHistory();
  const modals = useModals();
  const markUpdatesRead = useMarkUpdatesRead();

  return useRecoilCallback(({ snapshot }) => async (updateId: string) => {
    const update = snapshot.getLoadable(updateSelector(updateId)).valueMaybe();
    if (!update || update.deleted || update.read) {
      return;
    }
    const get: GetRecoilValue = (value: any) => snapshot.getLoadable<any>(value).valueMaybe();
    if (!validateUpdate(update, get)) {
      return;
    }

    await showNotification(
      history,
      () => {
        modals.closeModal();
        markUpdatesRead([update.id], true);
      },
      update.link,
      update.plainTextAction,
      update.context ?? update.fallbackTitle
    );
  });
}
