import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull, notNull } from '../../../shared/utils/convenience';
import { issueStatusesBySpace } from '../../../sync/__generated/indexes';
import { IssueStatus, IssueStatusType, Space } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { statusesForSpaceInBoardOrderSelector } from './boards';
import { statusSelector } from './issues';
import { spaceSelector, spacesForOrganizationSelector } from './spaces';
import { currentUserState } from './users';

export const statusesForSpaceSelector = selectorFamily({
  key: 'StatusesForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }
      const statusIds = get(indexKeyState(indexKey(issueStatusesBySpace, spaceId)));
      return filterNotDeletedNotNull(
        statusIds.map(statusId => get(syncEngineState(statusId)) as IssueStatus | null)
      );
    },
});

export const openStatusForSpaceSelector = selectorFamily({
  key: 'OpenStatusForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return null;
      }
      const space = get(spaceSelector(spaceId));
      if (!space) {
        return null;
      }

      const status = get(statusSelector(space?.defaultNewStatusId ?? ''));
      if (status) {
        return status;
      }
      return get(statusesForSpaceSelector(spaceId)).filter(
        s => s.statusType === IssueStatusType.Backlog || s.statusType === IssueStatusType.Todo
      )[0];
    },
});

export const archivedStatusForSpaceSelector = selectorFamily({
  key: 'ArchivedStatusForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return null;
      }
      const statuses = get(statusesForSpaceSelector(spaceId));
      return notNull(statuses.find(s => s.statusType === IssueStatusType.Archived));
    },
});

export const archivedStatusForSpacesSelector = selectorFamily({
  key: 'ArchivedStatusForSpace',
  get:
    (spaceIds: string[] | undefined) =>
    ({ get }) => {
      if (!spaceIds) {
        return null;
      }
      return spaceIds.map(spaceId => {
        if (!spaceId) {
          return null;
        }
        const statuses = get(statusesForSpaceSelector(spaceId));
        return notNull(statuses.find(s => s.statusType === IssueStatusType.Archived));
      });
    },
});

export const todoStatusForSpaceSelector = selectorFamily({
  key: 'TodoStatusForSpaceState',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return null;
      }
      const statuses = get(statusesForSpaceSelector(spaceId));
      return notNull(statuses.find(s => s.statusType === IssueStatusType.Todo));
    },
});

export const closedStatusForSpaceSelector = selectorFamily({
  key: 'ClosedStatusForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return null;
      }
      const space = get(spaceSelector(spaceId));
      if (!space) {
        return null;
      }

      const status = get(statusSelector(space.defaultDoneStatusId ?? ''));
      if (status) {
        return status;
      }

      return get(statusesForSpaceSelector(spaceId)).filter(
        s => s.statusType === IssueStatusType.Done
      )[0];
    },
});

export const maybeClosedStatusForSpaceSelector = selectorFamily({
  key: 'MaybeClosedStatusForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      return get(closedStatusForSpaceSelector(spaceId));
    },
});

export const statusesForOrganizationSelector = selectorFamily({
  key: 'StatusesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const spaces = get(spacesForOrganizationSelector(organizationId));
      return spaces.flatMap(s => get(statusesForSpaceSelector(s.id)));
    },
});

export const currentUserWatchingStatusSelector = selectorFamily({
  key: 'CurrentUserWatchingStatus',
  get:
    (statusId: string | undefined) =>
    ({ get }) => {
      const user = get(currentUserState);
      if (!user) {
        return false;
      }
      const status = get(statusSelector(statusId));
      return status?.watcherIds?.includes(user.id) ?? false;
    },
});

export const isDefaultStatusSelector = selectorFamily({
  key: 'CurrentUserWatchingStatus',
  get:
    (statusId: string | undefined) =>
    ({ get }) => {
      const status = get(statusSelector(statusId));
      if (!status) {
        return false;
      }
      const space = get(spaceSelector(status.spaceId));
      if (!space) {
        return false;
      }
      return isDefaultStatus(space, status);
    },
});

export function isDefaultStatus(space: Space, status: IssueStatus) {
  return space.defaultNewStatusId === status.id || space.defaultDoneStatusId === status.id;
}

export const firstTodoStatusIdSelector = selectorFamily({
  key: 'FirstTodoStatusId',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const statuses = get(statusesForSpaceInBoardOrderSelector(spaceId));
      const firstTodoStatus = statuses.find(s => s.statusType === IssueStatusType.Todo);
      return firstTodoStatus?.id;
    },
});
