import React from 'react';
import { useRecoilValue } from 'recoil';
import { cycleSelector } from '../../../syncEngine/selectors/cycles';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { addToCurrentCycleKey, addToUpcomingCycleKey } from '../../../utils/config';
import { CycleStatusLabel } from '../../cycle';
import { DropdownMenuCheckboxItem } from './dropdownMenu';

export function CycleMenu({
  spaceId,
  inCurrentCycle,
  inUpcomingCycle,
  inNoCycles,
  onSelect,
  hotkeys,
}: {
  spaceId: string;
  inCurrentCycle?: boolean;
  inUpcomingCycle?: boolean;
  inNoCycles?: boolean;
  onSelect: (cycleId: string | null) => void;
  hotkeys?: {
    addToCurrentCycleKey: string;
    addToUpcomingCycleKey: string;
  };
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const activeCycle = useRecoilValue(cycleSelector(space?.activeCycleId));
  const upcomingCycle = useRecoilValue(cycleSelector(space?.upcomingCycleId));

  return (
    <>
      <DropdownMenuCheckboxItem
        onSelect={() => onSelect(null)}
        checked={
          inNoCycles ||
          (inNoCycles === undefined && inCurrentCycle === false && inUpcomingCycle === false)
        }
      >
        None
      </DropdownMenuCheckboxItem>
      {space?.activeCycleId && activeCycle && (
        <DropdownMenuCheckboxItem
          checked={inCurrentCycle}
          icon="cycle_current"
          shortcut={hotkeys?.addToCurrentCycleKey ?? addToCurrentCycleKey}
          onSelect={() => {
            onSelect(space.activeCycleId);
          }}
        >
          <div className="row metadataGap">
            <span className="ellipsis">{activeCycle.title}</span>
            <CycleStatusLabel cycleId={space.activeCycleId} />
          </div>
        </DropdownMenuCheckboxItem>
      )}
      {space?.upcomingCycleId && upcomingCycle && (
        <DropdownMenuCheckboxItem
          checked={inUpcomingCycle}
          icon="cycle_upcoming"
          shortcut={hotkeys?.addToUpcomingCycleKey ?? addToUpcomingCycleKey}
          onSelect={() => {
            onSelect(space.upcomingCycleId);
          }}
        >
          <div className="row metadataGap">
            <span className="ellipsis">{upcomingCycle.title}</span>
            <CycleStatusLabel cycleId={space.upcomingCycleId} />
          </div>
        </DropdownMenuCheckboxItem>
      )}
    </>
  );
}
