import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import ExternalLink from '../../../../components/externalLink';
import BackButton from '../../../../components/new/backButton';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  SettingsSection,
} from '../../../../components/new/settings';
import { toast } from '../../../../components/toast';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useConfirmation } from '../../../../contexts/confirmationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { organizationPath } from '../../../../syncEngine/selectors/organizations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

export function EditHubspotIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'settings/integrations');
  const history = useHistory();
  const { confirm } = useConfirmation();

  const config = React.useMemo(
    () =>
      JSON.parse(integration?.config || '{}') as {
        url: string;
      },
    [integration]
  );

  if (!integration) {
    return <IntegrationNotFound />;
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Hubspot}
      description={
        <>
          <p>
            Create feedback from Hubspot conversations and extract insights, linking them to{' '}
            {issueTerm}s.
          </p>
          <p>
            Your Hubspot integration is enabled. To disable it, please do so from Hubspot here:{' '}
            <ExternalLink href={config.url}>Manage hubspot</ExternalLink>
          </p>
          <p>Afterwards, please hit the uninstall button below.</p>
        </>
      }
    >
      <SettingsSection>
        <div className="rowBetween">
          <BackButton defaultPath={defaultPath}>Back</BackButton>
          <div className="row">
            <AdminButton
              buttonStyle={ButtonStyle.Primary}
              onClick={async () => {
                const confrimed = await confirm(
                  'Uninstall hubspot integration',
                  <>
                    <p>
                      Please make sure you've uninstalled the Kitemaker integration from Hubspot
                      before proceeding.
                    </p>
                    <p>Are you sure you want to uninstall the Hubspot integration?</p>
                  </>
                );

                if (!confrimed) {
                  return;
                }

                const result = await fetch('/integrations/hubspot/uninstall', {
                  credentials: 'same-origin',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                  },
                  method: 'POST',
                  body: JSON.stringify({
                    integrationId: integration.id,
                  }),
                });

                if (!result.ok) {
                  toast.error('Error uninstalling integration');
                  return;
                }

                toast.success('Integration uninstalled');
                history.push(organizationPath(organization, 'settings/integrations'));
              }}
            >
              Uninstall
            </AdminButton>
          </div>
        </div>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewHubspotIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(
      `${host}/integrations/hubspot/install?organization_id=${organization.id}`,
      electronScheme,
      {
        redirectBaseUrl: redirectUrl,
        redirectQueryParams: {
          pendingIntegration: 'hubspot',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Hubspot}
      description={
        <>
          <p>Easily link Hubspot tickets and conversations with Kitemaker feedback</p>
          <p>
            To set up the integration, we'll need to install the Kitemaker app in your Hubspot
            organization.
          </p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Hubspot integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
