import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import {
  effortLevelsForOrganizationSelector,
  effortLevelsForSpaceSelector,
} from '../../../syncEngine/selectors/effortLevels';
import { DropdownMenuCheckboxItem } from './dropdownMenu';
import styles from './menu.module.scss';

export function EffortMenu({
  effortId,
  onSelect,
  forceOrganizationLevel,
}: {
  effortId?: string | null;
  onSelect: (effortId: string | null) => void;
  forceOrganizationLevel?: boolean;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const spaceEffortLevels = useRecoilValue(effortLevelsForSpaceSelector(space?.id));
  const organizationEffortLevels = useRecoilValue(
    effortLevelsForOrganizationSelector(organization.id)
  ).filter(e => !e.spaceId);
  const effortLevels =
    space && !forceOrganizationLevel ? spaceEffortLevels : organizationEffortLevels;
  const twoCharacters = effortLevels.some(l => l.abbrevation.length === 2);

  return (
    <>
      <DropdownMenuCheckboxItem
        onSelect={() => onSelect(null)}
        checked={effortId === null}
        shortcut="0"
      >
        None
      </DropdownMenuCheckboxItem>
      {effortLevels.map((effort, index) => (
        <DropdownMenuCheckboxItem
          checked={effortId === effort.id}
          key={effort.id}
          shortcut={index < 9 ? `${index + 1}` : undefined}
          onSelect={() => onSelect(effort.id)}
        >
          <span
            className={cn(styles.abbreviation, { [styles.twoCharacters]: twoCharacters })}
            style={{ color: mapColor(effort.color) }}
          >
            {effort.abbrevation}
          </span>
          {effort.name}
        </DropdownMenuCheckboxItem>
      ))}
    </>
  );
}
