import { last } from 'lodash';
import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { foldersByParent, foldersByOrganization } from '../../../sync/__generated/indexes';
import { Folder } from '../../../sync/__generated/models';
import { Breadcrumb } from '../../components/new/breadcrumbs';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';

export function isFolder(entity: SyncEngineObject): entity is Folder {
  return entity.__typename === 'Folder';
}

export const folderSelector = selectorFamily({
  key: 'Folder',
  get:
    (folderId: string | undefined | null) =>
    ({ get }) => {
      if (!folderId) {
        return null;
      }
      return get(syncEngineState(folderId)) as Folder | null;
    },
});

export const foldersSelector = selectorFamily({
  key: 'Folders',
  get:
    (folderIds?: string[] | null) =>
    ({ get }) => {
      return filterNotDeletedNotNull(
        (folderIds ?? []).map(folderId => get(folderSelector(folderId)))
      );
    },
});

export const folderIdsForOrganizationSelector = selectorFamily({
  key: 'FolderIdsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const folderIds = get(indexKeyState(indexKey(foldersByOrganization, organizationId)));
      return folderIds;
    },
});

export const foldersForOrganizationSelector = selectorFamily({
  key: 'FoldersForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const folderIds = get(folderIdsForOrganizationSelector(organizationId));
      return get(foldersSelector(folderIds));
    },
});

export const folderByLookupKeySelector = selectorFamily({
  key: 'FolderByLookupKey',
  get:
    ({ organizationId, lookupKey }: { organizationId: string; lookupKey: string }) =>
    ({ get }) => {
      const folders = get(foldersForOrganizationSelector(organizationId));
      return folders.find(folder => folder.lookupKey === lookupKey) || null;
    },
});

export const folderIdsByParentIdSelector = selectorFamily({
  key: 'FolderIdsByParentId',
  get:
    ({ organizationId, parentId }: { organizationId: string; parentId: string | null }) =>
    ({ get }) => {
      if (!parentId) {
        const folders = get(foldersForOrganizationSelector(organizationId));
        return folders.filter(f => !f.parentId).map(f => f.id);
      }

      const folderIds = get(indexKeyState(indexKey(foldersByParent, parentId)));
      return folderIds;
    },
});

export const foldersByParentIdSelector = selectorFamily({
  key: 'FoldersByParentId',
  get:
    ({ organizationId, parentId }: { organizationId: string; parentId: string | null }) =>
    ({ get }) => {
      const folderIds = get(
        folderIdsByParentIdSelector({ organizationId: organizationId, parentId })
      );
      return get(foldersSelector(folderIds));
    },
});

export const folderIdByPathSelector = selectorFamily({
  key: 'FolderIdByPath',
  get:
    ({ organizationId, path }: { organizationId: string; path?: string }) =>
    ({ get }) => {
      const pathElement = last(path?.split('/'));
      if (!pathElement) {
        return null;
      }
      const [lookupKey] = pathElement.split('-');
      return (
        get(folderByLookupKeySelector({ organizationId: organizationId, lookupKey }))?.id || null
      );
    },
});

export function getFolderPath(
  get: <T extends SyncEngineObject>(id: string) => T | null,
  folderId: string
): string {
  let path = '';
  let current: string | null = folderId;
  while (current) {
    const folder: Folder | null = get<Folder>(current);
    if (!folder) {
      break;
    }
    path = `${folder?.lookupKey}-${folder?.name}/${path}`;
    current = folder?.parentId || null;
  }
  return path;
}

export const folderPathSelector = selectorFamily({
  key: 'FolderPath',
  get:
    (folderId: string) =>
    ({ get }) => {
      return getFolderPath(
        <T extends SyncEngineObject>(id: string) => get(syncEngineState(id)) as T | null,
        folderId
      );
    },
});

export const folderBreadcrumbsSelector = selectorFamily({
  key: 'FolderBreadcrumbs',
  get:
    ({
      folderId,
      topLevelInteractive,
      organizationPath,
    }: {
      folderId: string | null;
      organizationPath: string;
      topLevelInteractive?: boolean;
    }) =>
    ({ get }) => {
      const result: Breadcrumb[] = [];

      let current: string | null = folderId;

      while (current) {
        const folder: Folder | null = get(folderSelector(current));
        const path = get(folderPathSelector(current));
        result.unshift({ name: folder?.name || '', link: `${organizationPath}/documents/${path}` });
        current = folder?.parentId || null;
      }

      result.unshift({ name: 'Documents', link: `${organizationPath}/documents` });
      if (!topLevelInteractive) {
        delete last(result)?.link;
      }

      return result;
    },
});
