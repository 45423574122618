import moment, { Moment } from 'moment-timezone';

export enum Weekday {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export const weekdayToString: Record<Weekday, string> = {
  [Weekday.Monday]: 'Monday',
  [Weekday.Tuesday]: 'Tuesday',
  [Weekday.Wednesday]: 'Wednesday',
  [Weekday.Thursday]: 'Thursday',
  [Weekday.Friday]: 'Friday',
  [Weekday.Saturday]: 'Saturday',
  [Weekday.Sunday]: 'Sunday',
};

export function formatUtcOffset(utcOffset: number) {
  if (utcOffset > 0) {
    return `UTC+${utcOffset}`;
  } else if (utcOffset < 0) {
    return `UTC${utcOffset}`;
  } else {
    return 'UTC';
  }
}

export function timezoneToUtcOffset(timezone: string) {
  return moment.tz(timezone).utcOffset();
}

export function daysBetween(from: Moment, to: Moment) {
  return to.diff(from, 'days');
}

export function nextAvailableCycle(
  from: Moment,
  weekday: Weekday,
  duration: number,
  cooldown: number,
  opts?: {
    adjustment?: (moment: Moment) => Moment;
  }
) {
  const nextAvailableDay =
    weekday <= from.isoWeekday()
      ? from.clone().add(1, 'week').isoWeekday(weekday)
      : from.clone().isoWeekday(weekday);

  const startDate = moment(nextAvailableDay).tz('Etc/GMT').add(cooldown, 'weeks').startOf('day');
  const endDate = startDate
    .clone()
    .add(duration, 'weeks')
    .subtract(1, 'day')
    .endOf('day')
    .subtract(1, 'second');

  if (opts?.adjustment) {
    return { startDate: opts.adjustment(startDate), endDate: opts.adjustment(endDate) };
  }

  return { startDate, endDate };
}
