import { perfectShuffle } from './random';

export const paletteColors = [
  'gray',
  'brown',
  'amber',
  'orange',
  'tomato',
  'pink',
  'plum',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'grass',
];

export const labelColors = perfectShuffle(paletteColors, 5);

export interface Rgb {
  r: number;
  g: number;
  b: number;
  a: number;
}

export type Rgba = Rgb & { a: number };

export function rgbToHex(color: Rgb): string {
  const { r, g, b } = color;

  const red = Math.floor(Math.min(1.0, r) * 255)
    .toString(16)
    .padStart(2, '0');
  const green = Math.floor(Math.min(1.0, g) * 255)
    .toString(16)
    .padStart(2, '0');
  const blue = Math.floor(Math.min(1.0, b) * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${red}${green}${blue}`;
}

export function hslToHex(hsl: string) {
  const match = hsl.match(/\d+(\.\d+)?/g);
  if (!match) {
    return '#FFFFFF';
  }

  const [h, s, l] = match.map(Number);

  if (s === 0) {
    // If saturation is 0, it's a shade of gray
    const gray = Math.round((l / 100) * 255).toString(16);
    const hexGray = gray.length === 1 ? '0' + gray : gray;
    return `#${hexGray}${hexGray}${hexGray}`;
  }

  const hueToRgb = (p: number, q: number, t: number) => {
    if (t < 0) {
      t += 1;
    }
    if (t > 1) {
      t -= 1;
    }
    if (t < 1 / 6) {
      return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
      return q;
    }
    if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
  };

  const lumen = l / 100;
  const saturation = s / 100;
  const q = lumen < 0.5 ? lumen * (1 + saturation) : lumen + saturation - lumen * saturation;
  const p = 2 * lumen - q;

  const r = hueToRgb(p, q, h / 360 + 1 / 3);
  const g = hueToRgb(p, q, h / 360);
  const b = hueToRgb(p, q, h / 360 - 1 / 3);

  const toHex = (x: number) => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const legacyColorMap: Record<string, string> = {
  e91e63: 'pink',
  d63864: 'pink',
  f44336: 'tomato',
  ff9800: 'orange',
  e15241: 'orange',
  ffc107: 'amber',
  f19e38: 'amber',
  f6c344: 'yellow',
  '67ad5b': 'grass',
  '4caf50': 'grass',
  '429488': 'teal',
  '009688': 'teal',
  '4994eb': 'blue',
  '2196f3': 'blue',
  '3657f2': 'indigo',
  '2b58fb': 'indigo',
  '9031aa': 'plum',
  '9c27b0': 'plum',
};

export function mapColor(baseColor: string, alpha?: boolean): string {
  const color = legacyColorMap[baseColor] ?? baseColor;
  return paletteColors.includes(color)
    ? `var(--${color}${alpha ? 'A' : ''}9)`
    : alpha
    ? `rgba(${color}, 0.85)`
    : `#${color}`;
}

export const customColorOpacities: Record<number, number> = {
  1: 0.05,
  2: 0.1,
  3: 0.15,
  4: 0.2,
  5: 0.34,
  6: 0.47,
  7: 0.53,
  8: 0.64,
  9: 0.72,
  10: 0.82,
  11: 0.92,
  12: 1,
};
