import { capitalize } from 'lodash';
import * as React from 'react';
import { gql, useMutation } from 'urql';
import {
  CreateApplicationMutation,
  CreateApplicationMutationVariables,
  CreatePersonalAccessTokenMutation,
  CreatePersonalAccessTokenMutationVariables,
} from '../../../graphql__generated__/graphql';
import { applicationFragment, personalAccessTokenFragment } from '../../sync/__generated/fragments';
import { writeToClipboard } from '../components/clipboardText';
import Modal from '../components/modal';
import { Button, ButtonStyle, IconButton } from '../components/new/button';
import { Setting, SettingsSection } from '../components/new/settings';
import { TextInput, TextValidationInput } from '../components/new/textInput';
import { Tooltip } from '../components/new/tooltip';
import { toast } from '../components/toast';
import { Modals, NewTokenArgs, NewTokenType, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import styles from './newTokenModal.module.scss';

function typeName(type: NewTokenType, ucFirst?: boolean) {
  const name = type === 'Application' ? 'application' : 'personal access token';
  return ucFirst ? capitalize(name) : name;
}

function TokenCreatedContent({ token }: { token: string }) {
  const modalManager = useModals();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SettingsSection>
          <Setting
            vertical
            title="Token"
            description="Please copy your token now because it will not be displayed again"
          >
            <div className="row fullWidth">
              <TextInput className="grow mr8" disabled contentEditable={false} value={token} />
              <Tooltip content={<>Copy token to clipboard</>}>
                <IconButton
                  icon="copy"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    writeToClipboard(token, 'token');
                  }}
                />
              </Tooltip>
            </div>
          </Setting>
        </SettingsSection>
      </div>
      <div className="rowEnd">
        <Button onClick={() => modalManager.closeModal(Modals.NewToken)}>Close</Button>
      </div>
    </div>
  );
}

function NewTokenModalContent({
  type,
  onTitleChanged,
}: {
  type: NewTokenType;
  onTitleChanged: (newTitle: string) => void;
}) {
  const organization = useOrganization();
  const modalManager = useModals();
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [name, setName] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);

  const [, createApplication] = useMutation<
    CreateApplicationMutation,
    CreateApplicationMutationVariables
  >(gql`
    mutation CreateApplication($name: String!, $avatar: String, $organization: ID!) {
      createApplication(input: { name: $name, avatar: $avatar, organizationId: $organization }) {
        application {
          ...ApplicationFragment
        }
      }
    }
    ${applicationFragment}
  `);
  const [, createPat] = useMutation<
    CreatePersonalAccessTokenMutation,
    CreatePersonalAccessTokenMutationVariables
  >(gql`
    mutation CreatePersonalAccessToken($name: String!, $organization: ID!) {
      createPersonalAccessToken(input: { name: $name, organizationId: $organization }) {
        personalAccessToken {
          ...PersonalAccessTokenFragment
        }
      }
    }
    ${personalAccessTokenFragment}
  `);

  const [application, setApplication] = React.useState<
    CreateApplicationMutation['createApplication']['application'] | null
  >(null);
  const [pat, setPat] = React.useState<
    CreatePersonalAccessTokenMutation['createPersonalAccessToken']['personalAccessToken'] | null
  >(null);

  async function create() {
    setRequestInProgress(true);
    try {
      if (type === 'Application') {
        const result = await createApplication({
          name,
          // avatar, // TODO: Consider adding in avatar again
          organization: organization.id,
        });
        if (result.error || !result.data?.createApplication.application) {
          throw new Error();
        }
        setApplication(result.data.createApplication.application);
      } else {
        const result = await createPat({
          name,
          organization: organization.id,
        });
        if (result.error || !result.data?.createPersonalAccessToken.personalAccessToken) {
          throw new Error();
        }
        setPat(result.data.createPersonalAccessToken.personalAccessToken);
      }
    } catch (e) {
      toast.error(`There was an error creating your ${typeName(type)}. Please try again`);
    }
    setRequestInProgress(false);
  }

  if (application) {
    onTitleChanged(`Application "${application.name}" created`);
    return <TokenCreatedContent token={application.token} />;
  } else if (pat) {
    onTitleChanged(`Personal access token "${pat.name}" created`);
    return <TokenCreatedContent token={pat.token} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Setting
          title={`${typeName(type, true)} name`}
          description={`The name you want to use for identifying the created ${typeName(type)}`}
          vertical
        >
          <TextValidationInput
            value={name}
            autoFocus
            className={styles.input}
            placeholder={`${typeName(type, true)} name`}
            error={error}
            onChange={e => {
              setError(null);
              setName(e.currentTarget.value);
            }}
            onKeyDown={async e => {
              if (e.key.toLowerCase() === 'enter' && name) {
                e.preventDefault();
                e.stopPropagation();
                if (requestInProgress) {
                  setError('Request in progress');
                  return;
                }

                await create();
              }
              if (e.key.toLowerCase() === 'escape') {
                e.preventDefault();
                e.stopPropagation();
                modalManager.closeModal(Modals.NewToken);
              }
            }}
          />
        </Setting>
      </div>
      <div className="rowEnd">
        <Button className="mr12" onClick={() => modalManager.closeModal(Modals.NewToken)}>
          Cancel
        </Button>
        <Button
          buttonStyle={ButtonStyle.Primary}
          disabled={requestInProgress || !!error || !name}
          onClick={async () => {
            if (requestInProgress) {
              setError('Request in progress');
              return;
            }

            await create();
          }}
        >
          Create
        </Button>
      </div>
    </div>
  );
}

export default function NewTokenModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as NewTokenArgs) : null;
  const type = args?.tokenType ?? 'Application';
  const [title, setTitle] = React.useState(`New ${typeName(type)}`);

  return (
    <Modal modalId={Modals.NewToken} title={title}>
      <NewTokenModalContent type={type} onTitleChanged={t => setTitle(t)} />
    </Modal>
  );
}
