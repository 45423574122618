import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  RoadmapColumnChangedActivityDetails,
} from '../../../../sync/__generated/models';
import { roadmapColumnSelector, roadmapSelector } from '../../../syncEngine/selectors/roadmaps';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function RoadmapColumnChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as RoadmapColumnChangedActivityDetails;
  const roadmap = useRecoilValue(roadmapSelector(details.roadmapId));
  const column = useRecoilValue(roadmapColumnSelector(details.columnId));
  const originalColumn = useRecoilValue(roadmapColumnSelector(details.originalColumnId));

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> moved this
      inititiative on{' '}
      <span className={styles.activityHighlight}>
        {roadmap?.name ?? details.fallbackRoadmapName}
      </span>
      .<ActivityTimestamp timestamp={activity.createdAt} />
      <div className={cn(styles.activityContext, styles.activityHighlight)}>
        <span className={styles.activityHighlight}>
          {originalColumn?.name} {`->`} {column?.name}
        </span>
      </div>
    </Activity>
  );
}

export const RoadmapColumnChangedActivity = React.memo(RoadmapColumnChangedActivityComponent);
