import cn from 'classnames';
import { capitalize } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { stringifyDocument } from '../../shared/slate/utils';
import { daysBetween } from '../../shared/utils/dateUtils';
import { CycleStatus } from '../../sync/__generated/models';
import { CommandGroup } from '../commands';
import { useOrganization } from '../contexts/organizationContext';
import { useIsTinyScreen } from '../hooks/useResponsiveDesign';
import { CycleMenuContents } from '../screens/cycleScreen/cycleMenu';
import { UpdateDate } from '../screens/cycleScreen/cycleScreen';
import { TextAreaHandle } from '../slate/textArea';
import { useUpdateCycles } from '../syncEngine/actions/cycles';
import { cycleCompletedSelector, cyclePath, cycleSelector } from '../syncEngine/selectors/cycles';
import { spaceSelector } from '../syncEngine/selectors/spaces';
import { isSameYear, renderDate } from '../utils/datetime';
import styles from './cycle.module.scss';
import { Button, ButtonSize, ButtonStyle, IconButton } from './new/button';
import { CommandContext } from './new/commandMenuContext';
import listItemStyles from './new/entityListItem.module.scss';
import { EntityTitleEditor } from './new/entityTitleEditor';
import { Hotkey } from './new/hotkey';
import {
  useHasKeyNavigationFocus,
  useKeyNavigationElement,
  useKeyNavigationState,
} from './new/keyNavigation';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './new/menu/dropdownMenu';
import { CycleIcon } from './new/metadata/cycle';
import Pill, { PillStyle } from './new/metadata/pill';
import { MetadataSize } from './new/metadata/size';

function CycleContext() {
  const { focused, selected } = useKeyNavigationState();

  if (!focused) {
    return null;
  }

  return (
    <CommandContext
      context={{ group: CommandGroup.Cycle, focusedCycleId: focused, cycleIds: selected ?? [] }}
    />
  );
}

export function CycleListItem({ id, className }: { id: string; className?: string }) {
  const cycle = useRecoilValue(cycleSelector(id));
  const organization = useOrganization();
  const space = useRecoilValue(spaceSelector(cycle?.spaceId));
  const history = useHistory();
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationElement(id, ref);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [mode, setMode] = React.useState<'default' | 'date'>('default');
  const updateCycles = useUpdateCycles();

  const [editTitle, setEditTitle] = React.useState(false);
  const [editSummary, setEditSummary] = React.useState(false);

  const { total, completed } = useRecoilValue(cycleCompletedSelector({ cycleId: id }));

  const titleRef = React.useRef<TextAreaHandle | null>(null);
  const summaryRef = React.useRef<TextAreaHandle | null>(null);
  const isTinyScreen = useIsTinyScreen();

  const saveTitle = React.useCallback(() => {
    if (!titleRef.current) {
      return;
    }
    updateCycles([id], { title: stringifyDocument(titleRef.current.raw().children) });
    setEditTitle(false);
  }, [id, updateCycles]);

  const saveSummary = React.useCallback(() => {
    if (!summaryRef.current) {
      return;
    }
    updateCycles([id], { summary: stringifyDocument(summaryRef.current.raw().children) });
    setEditSummary(false);
  }, [id, updateCycles]);

  const focused = useHasKeyNavigationFocus(id);
  if (!space || !cycle) {
    return null;
  }

  const link = {
    pathname: cyclePath(organization, space, cycle),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: cycle.id,
    },
  };

  const daysLeft = daysBetween(moment(), moment(cycle.endDate));
  const editing = editTitle || editSummary;

  return (
    <Link
      onClick={e => {
        if (editing) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      to={link}
    >
      {focused && (
        <Hotkey
          hotkey="enter"
          handler={e => {
            if (editing) {
              return;
            }
            e?.preventDefault();
            e?.stopPropagation();
            history.push(link);
          }}
        />
      )}
      <CycleContext />
      <div className={cn(styles.cycle, className, { [styles.menuOpen]: menuOpen })} ref={ref}>
        <div
          className={cn(styles.cycleContents, {
            [styles.completed]: cycle.cycleStatus === CycleStatus.Stopped,
          })}
        >
          <CycleIcon cycleStatus={cycle.cycleStatus} className="mr8" />
          <div className={cn('row', 'grow', styles.left)}>
            {!editTitle && <div className={'headingS'}>{cycle.title || 'Untitled cycle'}</div>}
            {editTitle && (
              <EntityTitleEditor
                className={cn('headingS', styles.textInput, listItemStyles.titleEditor)}
                initialTitle={cycle.title}
                ref={titleRef}
                onSubmit={saveTitle}
                onReset={() => setEditTitle(false)}
                autoFocus
                oneLine
              />
            )}
            {!isTinyScreen && (
              <>
                <CycleStatusLabel cycleId={cycle.id} />
                {!editSummary && <span className="grayed bodyM">{cycle.summary}</span>}
                {editSummary && (
                  <EntityTitleEditor
                    className={cn('bodyM grayed', styles.textInput, listItemStyles.titleEditor)}
                    initialTitle={cycle.summary ?? ''}
                    ref={summaryRef}
                    onSubmit={saveSummary}
                    onReset={() => setEditSummary(false)}
                    autoFocus
                    oneLine
                  />
                )}
              </>
            )}
          </div>
          <div className={cn('row', 'noGrow', styles.right)}>
            {!isTinyScreen && (
              <div className={styles.count}>
                {completed}/{total}{' '}
                {cycle.cycleStatus === CycleStatus.NotStarted ? 'planned' : 'completed'}
              </div>
            )}
            {!editing && (
              <>
                <div className={styles.menu}>
                  <DropdownMenu
                    open={menuOpen}
                    onOpenChange={open => {
                      setMenuOpen(open);
                      if (!open) {
                        setMode('default');
                      }
                    }}
                  >
                    <DropdownMenuTrigger asChild>
                      <IconButton buttonStyle={ButtonStyle.BareSubtle} icon="more" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                      className={cn('overflowHidden', {
                        menuSmall: mode === 'default',
                        menuMedium: mode === 'date',
                      })}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      side="bottom"
                      align="end"
                    >
                      {mode === 'default' && (
                        <CycleMenuContents
                          cycle={cycle}
                          ignoreCurrentAndUpcomingWhenStarring
                          onEditTitle={() => setEditTitle(true)}
                          onEditSummary={() => setEditSummary(true)}
                          onEditDates={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setMode('date');
                            setMenuOpen(true);
                          }}
                        />
                      )}
                      {mode === 'date' && (
                        <UpdateDate
                          cycle={cycle}
                          onDone={() => {
                            setMenuOpen(false);
                            setMode('default');
                          }}
                        />
                      )}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
                <div className={styles.date}>
                  {cycle.cycleStatus !== CycleStatus.Started && (
                    <>
                      {capitalize(
                        renderDate(cycle.startDate, {
                          ignoreYear: isSameYear(cycle.startDate, cycle.endDate, {
                            timezone: 'Etc/GMT',
                          }),
                          timezone: 'Etc/GMT',
                        })
                      )}{' '}
                      &ndash;{' '}
                      {capitalize(
                        renderDate(cycle.endDate, { compact: false, timezone: 'Etc/GMT' })
                      )}
                    </>
                  )}
                  {cycle.cycleStatus === CycleStatus.Started && (
                    <span>
                      {daysLeft} day {daysLeft > 1 ? 's' : ''} left
                    </span>
                  )}
                </div>
              </>
            )}
            {editing && (
              <div className={styles.buttons}>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEditTitle(false);
                    setEditSummary(false);
                  }}
                  size={ButtonSize.Small}
                  className="mr8"
                >
                  Cancel
                </Button>
                <Button
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (editTitle) {
                      saveTitle();
                    } else if (editSummary) {
                      saveSummary();
                    }
                  }}
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.Primary}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export function CycleStatusLabel({ cycleId, size }: { cycleId: string; size?: MetadataSize }) {
  const cycle = useRecoilValue(cycleSelector(cycleId));
  if (!cycle) {
    return null;
  }
  switch (cycle.cycleStatus) {
    case CycleStatus.NotStarted:
      return (
        <Pill color="gray" size={size} pillStyle={PillStyle.Secondary} noHover>
          Upcoming
        </Pill>
      );
    case CycleStatus.Stopped:
      return (
        <Pill color="gray" size={size} pillStyle={PillStyle.Secondary} noHover>
          Completed
        </Pill>
      );
    case CycleStatus.Started:
      return (
        <Pill color="blue" size={size} pillStyle={PillStyle.Secondary} noHover>
          Current
        </Pill>
      );
  }
}
