import { parseInt, range } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Weekday, nextAvailableCycle } from '../../../../shared/utils/dateUtils';
import { issueTerm } from '../../../../shared/utils/terms';
import { useRescheduleCycle } from '../../../api/cycle';
import { Select } from '../../../components/new/select';
import { Setting, SettingsSection } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { useUpdateSpaces } from '../../../syncEngine/actions/spaces';
import { cycleSelector } from '../../../syncEngine/selectors/cycles';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';

export function CycleSettings({ spaceId }: { spaceId: string }) {
  const updateSpaces = useUpdateSpaces();
  const space = useRecoilValue(spaceSelector(spaceId))!;
  const rescheduleCycle = useRescheduleCycle();

  const upcomingCycle = useRecoilValue(cycleSelector(space.upcomingCycleId));
  const currentCycle = useRecoilValue(cycleSelector(space.activeCycleId));

  const cycleLengthValues = range(1, 8).map(i => ({
    label: `${i} ${i > 1 ? 'weeks' : 'week'}`,
    value: i.toString(),
  }));

  const cycleStartDayValues = [
    {
      label: 'Monday',
      value: Weekday.Monday.toString(),
    },
    {
      label: 'Tuesday',
      value: Weekday.Tuesday.toString(),
    },
    {
      label: 'Wednesday',
      value: Weekday.Wednesday.toString(),
    },
    {
      label: 'Thursday',
      value: Weekday.Thursday.toString(),
    },
    {
      label: 'Friday',
      value: Weekday.Friday.toString(),
    },
    {
      label: 'Saturday',
      value: Weekday.Saturday.toString(),
    },
    {
      label: 'Sunday',
      value: Weekday.Sunday.toString(),
    },
  ];

  const cycleCooldownValues = [
    {
      label: 'None',
      value: '0',
    },
    {
      label: '1 week',
      value: '1',
    },
    {
      label: '2 weeks',
      value: '2',
    },
    {
      label: '3 weeks',
      value: '3',
    },
    {
      label: '4 weeks',
      value: '4',
    },
  ];

  const cycleStartTime = React.useMemo(() => {
    const { startDate: nextCycle } = nextAvailableCycle(
      moment(),
      space.cycleStartDay,
      space.cycleDuration,
      0
    );

    return `Starts on ${nextCycle.tz(space.timezone, true).local().format('dddd hh:mm A (Z)')}`;
  }, [space.timezone, space.cycleStartDay, space.cycleDuration]);

  return (
    <>
      <SettingsSection>
        <Setting title="Cycle duration" vertical>
          <Select
            values={cycleLengthValues}
            value={space.cycleDuration.toString()}
            onChange={v => {
              const cycleDuration = parseInt(v, 10);
              updateSpaces([space.id], { cycleDuration });
              if (upcomingCycle) {
                const { startDate, endDate } = nextAvailableCycle(
                  moment(currentCycle?.endDate).utcOffset(0) ?? moment(),
                  space.cycleStartDay,
                  cycleDuration,
                  space.cycleCooldown
                );
                rescheduleCycle({
                  id: upcomingCycle.id,
                  startDate: startDate.toDate(),
                  endDate: endDate.toDate(),
                });
              }
            }}
          />
        </Setting>
        <Setting title="Start cycle on" vertical>
          <div className="row">
            <Select
              className="mr16"
              values={cycleStartDayValues}
              value={space.cycleStartDay.toString()}
              onChange={v => {
                const cycleStartDay = parseInt(v, 10);
                updateSpaces([space.id], { cycleStartDay });
                if (upcomingCycle) {
                  const { startDate, endDate } = nextAvailableCycle(
                    moment(currentCycle?.endDate).utcOffset(0) ?? moment(),
                    cycleStartDay,
                    space.cycleDuration,
                    space.cycleCooldown
                  );
                  rescheduleCycle({
                    id: upcomingCycle.id,
                    startDate: startDate.toDate(),
                    endDate: endDate.toDate(),
                  });
                }
              }}
            />
            <span className="grayed">{cycleStartTime}</span>
          </div>
        </Setting>
        <Setting title="Cooldown duration" vertical>
          <Select
            values={cycleCooldownValues}
            value={space.cycleCooldown.toString()}
            onChange={v => {
              const cycleCooldown = parseInt(v, 10);
              updateSpaces([space.id], {
                cycleCooldown,
              });
              if (upcomingCycle) {
                const { startDate, endDate } = nextAvailableCycle(
                  moment(currentCycle?.endDate).utcOffset(0) ?? moment(),
                  space.cycleStartDay,
                  space.cycleDuration,
                  cycleCooldown
                );
                rescheduleCycle({
                  id: upcomingCycle.id,
                  startDate: startDate.toDate(),
                  endDate: endDate.toDate(),
                });
              }
            }}
          />
        </Setting>
      </SettingsSection>

      <SettingsSection>
        <Setting
          title={`Automatically add ${issueTerm}s to current cycle`}
          description={
            'If a work item is moved to the current board, Kitemaker will automatically add it to the current cycle.'
          }
        >
          <Switch
            checked={space.addToCurrentCycle}
            onChange={v =>
              updateSpaces([space.id], {
                addToCurrentCycle: v,
              })
            }
          />
        </Setting>
      </SettingsSection>
    </>
  );
}
