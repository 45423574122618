import { useRecoilValue } from 'recoil';
import { OrganizationMember } from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { SyncEngineUpdate, useModelManager } from '../../graphql/modelManager';
import { currentUserMembershipState } from '../selectors/users';

export function useUpdateOrganizationMembers() {
  const modelManager = useModelManager();
  return (memberIds: string[], update: SyncEngineUpdate<OrganizationMember>) => {
    modelManager.transaction(tx => {
      for (const memberId of memberIds) {
        tx.update<OrganizationMember>(memberId, update);
      }
    });
  };
}

export function useSnoozeIds() {
  const modelManager = useModelManager();
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  return (ids: string[], snoozedUntil: number) => {
    if (!orgMembership) {
      return;
    }
    modelManager.transaction(tx => {
      const snoozed = (orgMembership.snoozed ?? []).map(s => ({
        id: s.id,
        snoozedUntil: s.snoozedUntil,
      }));

      for (const id of ids) {
        const snooze = snoozed.find(s => s.id === id);
        if (snooze) {
          snooze.snoozedUntil = snoozedUntil;
        } else {
          snoozed.push({ id, snoozedUntil });
        }
      }

      tx.update<OrganizationMember>(orgMembership?.id, { snoozed });
    });
  };
}

export function useUnsnoozeIds() {
  const modelManager = useModelManager();
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  return (ids: string[]) => {
    if (!orgMembership) {
      return;
    }
    modelManager.transaction(tx => {
      const snoozed = (orgMembership.snoozed ?? [])
        .map(s => ({
          id: s.id,
          snoozedUntil: s.snoozedUntil,
        }))
        .filter(s => !ids.includes(s.id));
      tx.update<OrganizationMember>(orgMembership?.id, { snoozed });
    });
  };
}
