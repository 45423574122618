import { atomFamily, useRecoilValue } from 'recoil';
import { localStorageEffect } from '../../../syncEngine/effects';

export const collapsedSections = atomFamily<string[], string | { key: string; default: string[] }>({
  key: 'CollapsedSections',
  default: key => {
    if (typeof key === 'string') {
      return [];
    }
    return key.default;
  },
  effects: key => {
    const stringKey = typeof key === 'string' ? key : key.key;
    return [localStorageEffect(`__collapsedSections__${stringKey}`)];
  },
});

export function useCollapsedSections(listId: string) {
  return useRecoilValue(collapsedSections(listId));
}
