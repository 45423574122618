import { KeyboardEvent } from 'react';
import { Editor } from 'slate';
import { metaKeyDown } from '../../utils/keyEvents';

export function saveOnKeyDown(_editor: Editor, e: KeyboardEvent): boolean {
  if (e.key === 's' && metaKeyDown(e) && !e.shiftKey) {
    e.preventDefault();
    return true;
  }

  return false;
}
