import cn from 'classnames';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';

export function StaticHeadline1({ children, attributes }: OptionalAttributesRenderElementProps) {
  return (
    <h1 className="block headline headline1" {...attributes}>
      {children}
    </h1>
  );
}

export function Headline1({ attributes, children }: RenderElementProps) {
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  return (
    <h1
      {...attributes}
      {...dndAttributes}
      className={cn('block', 'headline', 'headline1', dndClassName)}
    >
      {dndComponents}
      {children}
    </h1>
  );
}
