import { uniq } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../../shared/utils/fuzzySearch';
import { MemberRole } from '../../../../sync/__generated/models';
import { Button, ButtonStyle, IconButtonTrigger } from '../../../components/new/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import { MemberPicker } from '../../../components/new/pickers/memberPicker';
import { SettingsListItem, SettingsPage } from '../../../components/new/settings';
import { TextInput } from '../../../components/new/textInput';
import { toast } from '../../../components/toast';
import { useSpace } from '../../../contexts/spaceContext';
import { useUpdateOrganizationMembers } from '../../../syncEngine/actions/organizationMember';
import { useUpdateSpaces } from '../../../syncEngine/actions/spaces';
import { spaceMemberScreenSelector } from '../../../syncEngine/selectors/groups';
import { useFindMemberByUserId } from '../../../syncEngine/selectors/organizations';
import { UserAndMember } from '../../../syncEngine/selectors/users';
import { UserAndEmailRow } from './manageTeamScreen';
import styles from './settingsScreen.module.scss';

function MemberItem({
  user,
  isFirst,
  isLast,
  removeMember,
}: {
  user: UserAndMember;
  isFirst?: boolean;
  isLast?: boolean;
  removeMember: (userId: string) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);

  return (
    <SettingsListItem
      isFirst={isFirst}
      isLast={isLast}
      menuOpen={menuOpen}
      meta={
        <DropdownMenu open={menuOpen} onOpenChange={e => setMenuOpen(e)}>
          <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
          <DropdownMenuContent>
            <DropdownMenuItem destructive onClick={() => removeMember(user.user.id)}>
              Remove
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      }
    >
      <UserAndEmailRow user={user} />
    </SettingsListItem>
  );
}

export function SpaceMembersScreen() {
  const space = useSpace();
  const updateOrganizationMembers = useUpdateOrganizationMembers();
  const findMember = useFindMemberByUserId();
  const spaceMembers = useRecoilValue(spaceMemberScreenSelector({ spaceId: space.id }));
  const updateSpaces = useUpdateSpaces();

  const [searchString, setSearchString] = React.useState('');
  const [addNewOpen, setAddNewOpen] = React.useState(false);
  const [filteredMembers, setFilteredMembers] = React.useState(spaceMembers);

  function addMember(userId: string) {
    const member = findMember(userId);

    if (!member) {
      toast.error(`Couldn't find member to add`);
      return;
    }

    updateOrganizationMembers([member.id], {
      favoriteSpaceIds: [...(member.favoriteSpaceIds ?? []), space.id],
    });

    if (space.private || member.role === MemberRole.Guest) {
      updateSpaces([space.id], {
        members: uniq([...space.members, userId]),
      });
    }
  }

  function removeMember(userId: string) {
    const member = spaceMembers.find(m => m.user.id === userId);

    if (!member) {
      toast.error(`Couldn't find member to remove`);
      return;
    }

    if (member?.member.role === MemberRole.Guest || space.private) {
      updateSpaces([space.id], {
        members: space.members.filter(m => m !== member.user.id),
      });
    }

    updateOrganizationMembers([member.member.id], {
      favoriteSpaceIds: member.member.favoriteSpaceIds!.filter(s => s !== space.id),
    });
  }

  const search = React.useMemo(() => {
    return new FuzzySearcher(
      FuzzySearcherConfiguration.Autocomplete,
      ['name', 'email', 'username'],
      spaceMembers.map(i => ({
        ...i,
        name: i.user.name,
        email: i.user.primaryEmail,
        username: i.user.username,
      }))
    );
  }, [spaceMembers]);

  React.useEffect(() => {
    if (!searchString) {
      setFilteredMembers(spaceMembers);
      return;
    }

    setFilteredMembers(search.search(searchString).map(i => i.item));
  }, [search, spaceMembers, searchString]);

  return (
    <SettingsPage
      title="Space Members"
      description="All members will have the space visible in the `My Spaces` section in the sidebar."
    >
      <div className="rowStretch mb16">
        <DropdownMenu open={addNewOpen} onOpenChange={setAddNewOpen}>
          <DropdownMenuTrigger
            asChild
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Button
              className="mr16"
              buttonStyle={ButtonStyle.Primary}
              icon="add"
              onClick={() => {
                setAddNewOpen(true);
              }}
            >
              Add new member
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="end"
            className="menuPicker menuMedium"
          >
            <MemberPicker
              allUsers
              state={{ [space.id]: spaceMembers.map(m => m.user.id) }}
              onMemberAdded={(_, memberId) => {
                addMember(memberId);
              }}
              onMemberRemoved={(_, memberId) => {
                removeMember(memberId);
              }}
              onDone={() => setAddNewOpen(false)}
            />
          </DropdownMenuContent>
        </DropdownMenu>

        <TextInput
          className={styles.searchInput}
          value={searchString}
          onChange={v => setSearchString(v.currentTarget.value)}
          placeholder="Filter members"
        />
      </div>

      <div>
        {filteredMembers.map((user, index) => (
          <MemberItem
            key={user.user.id}
            user={user}
            isFirst={index === 0}
            isLast={index === spaceMembers.length - 1}
            removeMember={removeMember}
          />
        ))}
      </div>
    </SettingsPage>
  );
}
