import { KeyboardEvent } from 'react';
import { Editor, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { Snippet } from '../../../sync/__generated/models';
import { CommandMenuView, ModalContext, Modals } from '../../contexts/modalContext';
import { metaKeyDown } from '../../utils/keyEvents';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { EditorType } from '../types';

export function snippetHotkeys(
  editor: EditorType,
  spaceId: string | undefined,
  e: KeyboardEvent,
  modals: ModalContext,
  snippets: Snippet[]
) {
  if (!modals) {
    return false;
  }

  if (!((e.key === ';' || e.which === 186) && metaKeyDown(e))) {
    return false;
  }
  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node] = block;

  if (!KitemakerElement.isElement(node) || !KitemakerElement.isTextBlock(node)) {
    return false;
  }

  e.preventDefault();

  ReactEditor.blur(editor);

  modals?.openModal(Modals.CommandMenu, {
    view: CommandMenuView.Snippets,
    context: {
      spaceId: spaceId,
      trackingContext: 'Insert snippet into document',
      onSnippetPicked: (snippetId: string) => {
        ReactEditor.focus(editor);
        KitemakerTransforms.select(editor, selection);
        const snippet = snippets.find(s => s.id === snippetId);
        if (snippet) {
          const fragment = JSON.parse(snippet.contents);
          KitemakerTransforms.intelligentlyInsertFragment(editor, fragment);
        }
      },
    },
  });
  return true;
}
