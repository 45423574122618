import {
  GitMergeIcon,
  GitPullRequestIcon,
  GitPullRequestClosedIcon,
  GitPullRequestDraftIcon,
  IssueClosedIcon,
  IssueDraftIcon,
  IssueOpenedIcon,
} from '@primer/octicons-react';
import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import styles from './gitStatus.module.scss';

export type GitStatusType = 'open' | 'closed' | 'merged' | 'draft';
export function GitStatus({
  state,
  mergeRequest,
}: {
  state: GitStatusType;
  mergeRequest: boolean;
}) {
  let icon = <IssueOpenedIcon />;

  if (mergeRequest) {
    switch (state) {
      case 'draft':
        icon = <GitPullRequestDraftIcon />;
        break;
      case 'closed':
        icon = <GitPullRequestClosedIcon />;
        break;
      case 'merged':
        icon = <GitMergeIcon />;
        break;
      default:
        icon = <GitPullRequestIcon />;
    }
  } else {
    switch (state) {
      case 'draft':
        icon = <IssueDraftIcon />;
        break;
      case 'closed':
        icon = <IssueClosedIcon />;
        break;
    }
  }
  return (
    <div
      className={cn(styles.status, {
        [styles.open]: state === 'open',
        [styles.closed]: state === 'closed' && mergeRequest,
        [styles.merged]:
          (state === 'merged' && mergeRequest) || (state === 'closed' && !mergeRequest),
        [styles.draft]: state === 'draft',
      })}
    >
      <div className={styles.icon}>{icon}</div>
      {capitalize(state)}
    </div>
  );
}
