import cn from 'classnames';
import * as React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import {
  GetRecoilValue,
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { emptyDocument } from '../../../../shared/slate/utils';
import { issueTerm } from '../../../../shared/utils/terms';
import {
  Board,
  BoardColumn,
  Entity,
  Issue,
  IssueStatusSortMode,
  IssueStatusType,
} from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { FetchChaosMode } from '../../../components/chaos/fetchChaosMode';
import { IssueBoardChaosMode } from '../../../components/chaos/issueBoardChaosMode';
import { writeToClipboard } from '../../../components/clipboardText';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import {
  MetadataConfigurationButton,
  boardMetadataConfig,
  workItemDefaultExcludeOptions,
} from '../../../components/metadataConfig';
import { autoSortedMessage, hideAutoSortedMessage } from '../../../components/new/autoSortedToast';
import { Breadcrumbs, useSpaceBreadcrumb } from '../../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { EntityCommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { CustomCommand } from '../../../components/new/customCommand';
import { EntityFilterMenu2 } from '../../../components/new/entityFilters2';
import {
  Filters as Filters2,
  QuickFilters as QuickFilters2,
} from '../../../components/new/filters2';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  FocusReason,
  KeyNavigationProvider,
  KeyNavigationWatcher,
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
  useKeyNavigationState,
  useSetKeyNavigationFocus,
  useSetKeyNavigationSelection,
} from '../../../components/new/keyNavigation';
import { SnippetPicker } from '../../../components/new/pickers/snippetPicker';
import Popover from '../../../components/new/popover';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { SegmentedButton } from '../../../components/new/segmentedButton';
import { SegmentedControl } from '../../../components/new/segmentedControl';
import { Tooltip } from '../../../components/new/tooltip';
import { VirtualizedBoardView, useSizeCache } from '../../../components/new/virtualizedBoardView';
import { columnEditIdState } from '../../../components/new/virtualizedBoardView/columnHeader';
import {
  VirtualizedListView,
  useCollapsedSections,
} from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { EditWorkItemCard, WorkItemCard } from '../../../components/new/workItemCard';
import { EditWorkItemListItem, WorkItemListItem } from '../../../components/new/workItemListItem';
import { Screen } from '../../../components/screen';
import { BoardSharingPopover } from '../../../components/sharing';
import TitleSetter from '../../../components/titleSetter';
import { toast } from '../../../components/toast';
import { useConfiguration } from '../../../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { BoardType } from '../../../graphql/modelManager';
import { BoardMarker, FetchedMarker } from '../../../graphql/smartLoad';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { ResponsiveDesignSize, useIsTinyScreen } from '../../../hooks/useResponsiveDesign';
import { useIsProductTierExceededAndNag } from '../../../index/billingChecks';
import { useNewEntityModalArgs } from '../../../modals/newEntityModal/newEntityModal';
import { useUpdateBoards } from '../../../syncEngine/actions/boards';
import {
  useDuplicateIssues,
  useMoveIssuesContext,
  useUpdateIssueSorts,
} from '../../../syncEngine/actions/issues';
import { localStorageEffect } from '../../../syncEngine/effects';
import {
  allBoardsForSpaceSelector,
  boardColumnsForBoardSelector,
  boardSelector,
} from '../../../syncEngine/selectors/boards';
import {
  useEntityNumberWidths,
  useFindRelevantEntities,
} from '../../../syncEngine/selectors/entities';
import {
  filteredIssuesForStatusCountSelector,
  filteredIssuesForStatusSelector,
  isIssue,
  issueSelector,
  issuesByStatusSelector,
  sortModeToString,
  statusesSelector,
  useGetFilterdIssuesForBoard,
  useGetFilterdIssuesForStatus,
  useSortIssuesInBoardOrder,
} from '../../../syncEngine/selectors/issues';
import { organizationPath } from '../../../syncEngine/selectors/organizations';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { useSnippetForCallback } from '../../../syncEngine/selectors/snippets';
import {
  StateEvents,
  offStateEvent,
  onStateEvent,
  syncEngineState,
} from '../../../syncEngine/state';
import { SyncEngineObject } from '../../../syncEngine/types';
import { trackerPageLoad } from '../../../tracker';
import {
  createNewEntityFromAnywhere,
  insertSnipppetKey,
  toggleListViewKey,
} from '../../../utils/config';
import { filterState } from '../../../utils/filtering';
import { filterPropertiesSelector } from '../../../utils/filtering2';
import { LocationState, silentlyUpdateHistoryState } from '../../../utils/history';
import { StarredType } from '../../../utils/starred';
import { DisabledScreen } from '../../errorScreens/disabledScreen';
import { NotFoundScreen } from '../../errorScreens/notFoundScreen';
import LoadingScreen from '../../loadingScreen/loadingScreen';
import { ColumnHeader, NewColumnHeader } from './columnHeader';
import { NewWorkItem } from './newWorkItem';
import { Placeholder } from './placeholder';
import { SectionHeader } from './sectionHeader';
import styles from './workItemBoardScreen.module.scss';

enum Mode {
  Board = 'board_view',
  List = 'list_view',
}

const workItemBoardScreenMode = atomFamily<Mode, string>({
  key: 'WorkItemBoardScreenMode',
  default: Mode.Board,
  effects: key => [localStorageEffect(`__workItemBoardScreenMode__${key}`)],
});

export function WorkItemContext({
  id,
  keyNavFocus,
}: {
  id: string;
  keyNavFocus: React.MutableRefObject<string | null>;
}) {
  const organization = useOrganization();
  const paidPlan = !!organization.activeProductId;
  const modals = useModals();
  const space = useSpace();
  const board = useRecoilValue(boardSelector(id));
  const updateBoards = useUpdateBoards();
  const { host } = useConfiguration();
  const getSnippet = useSnippetForCallback();
  const { focused, selected } = useKeyNavigationState();
  const setFocus = useSetKeyNavigationFocus();
  const setSelected = useSetKeyNavigationSelection();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const [mode, setMode] = useRecoilState(workItemBoardScreenMode(id));
  const checkProductTierExceeded = useIsProductTierExceededAndNag();
  const { filteredIssueIds: itemsByStatus } = useRecoilValue(
    issuesByStatusSelector({
      spaceId: space.id,
      filterId: id ?? '',
    })
  );

  const props = useRecoilValue(filterPropertiesSelector(id));

  useNewEntityModalArgs({
    ...props,
    type: 'Issue',
    onCreated: entityId => {
      if (entityId) {
        setFocus(entityId);
      }
    },
  });

  const undo = React.useCallback(() => {
    disableMissingElementDetection();
    setFocus(focused);
    if (selected) {
      setSelected(selected);
    }
    enableMissingElementDetection();
  }, [focused, selected]);

  useMoveIssuesContext({
    onMove: (issues: Issue[], statusId: string) => {
      const focusedIssue = issues.find(i => i.id === focused);
      // if the destination status is where we already are, nothing to do
      if (!focused || !focusedIssue || focusedIssue.statusId === statusId) {
        return undo;
      }

      const idsByStatus = (itemsByStatus[focusedIssue.statusId] ?? []).filter(
        id => id === focused || !issues.find(i => i.id === id)
      );
      const indexOfFocused = idsByStatus.indexOf(focused ?? '');

      const idToFocus =
        idsByStatus[indexOfFocused + 1] ??
        idsByStatus[indexOfFocused - 1] ??
        `placeholder-${focusedIssue.statusId}`;

      // Note: we have a performance fix where we pass the current focused element down as a ref instead
      // of reading the keynav state. Unfortunately, this causes a race condition where the board tries to ensure
      // the focused element is on the screen before we managed to update this ref. So we update it explicity here.
      keyNavFocus.current = idToFocus;

      disableMissingElementDetection();
      setFocus(idToFocus, FocusReason.Programmatic);
      enableMissingElementDetection();

      return undo;
    },
  });

  const copyPublicLink = React.useCallback(
    () => writeToClipboard(`${host}/sharing/board/${id}`, 'public link to board'),
    [host, id]
  );

  return (
    <>
      <IssueBoardChaosMode issues={itemsByStatus} spaceId={space.id} />
      <FetchChaosMode />
      <EntityCommandContext />
      <CustomCommand
        command={{
          id: `toggle-${mode}-view`,
          group: CommandGroup.Board,
          hotkey: toggleListViewKey,
          description: mode === Mode.Board ? 'Switch to list view' : 'Switch to board view',
          icon: mode === Mode.Board ? 'list_view' : 'board_view',
          handler: () => {
            setMode(previous => (previous === Mode.Board ? Mode.List : Mode.Board));
          },
          aliases: ['list', 'board'],
        }}
      />
      <CustomCommand
        command={{
          id: `create-from-snippet`,
          group: CommandGroup.Board,
          priority: 9,
          hotkey: insertSnipppetKey,
          description: `Create new from snippet`,
          icon: 'none',
          handler: () => {
            if (checkProductTierExceeded()) {
              return;
            }

            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.Snippets,
              context: {
                spaceId: space.id,
                trackingContext: 'Create work item from snippet',
                onSnippetPicked: (snippetId: string) => {
                  const snippet = getSnippet(snippetId);
                  const content = snippet ? JSON.parse(snippet.contents) : emptyDocument();
                  modals.openModal(Modals.NewEntity, {
                    content,
                  });
                },
              },
            });
          },
        }}
      />
      <CustomCommand
        command={{
          id: `toggle-public`,
          group: CommandGroup.Board,
          description: board?.shared ? 'Make board private' : 'Make board public',
          aliases: ['public', 'share'],
          icon: 'none',
          handler: () => {
            if (!paidPlan) {
              toast.info(
                <Link to={organizationPath(organization, 'settings/billing')}>
                  Public sharing is a paid feature. Click here to upgrade.
                </Link>
              );
              return;
            }

            if (!board?.shared) {
              updateBoards([id], { shared: true });
              copyPublicLink();
              toast.info(`Board now public`);
            } else {
              updateBoards([id], { shared: false });
              toast.info(`Board now private`);
            }
          },
        }}
      />
      {board?.shared && (
        <>
          <CustomCommand
            command={{
              id: `toggle-public-metadata`,
              group: CommandGroup.Board,
              aliases: ['share'],
              description: board.sharedMetadata ? 'Unshare board metadata' : 'Share board metadata',
              icon: 'none',
              handler: () => {
                updateBoards([board.id], {
                  sharedMetadata: !board.sharedMetadata,
                  shared: true,
                  sharedWorkItems: board.sharedWorkItems,
                });
                toast.info(
                  `Roadmap metadata ${space.publicRoadmapMetadata ? 'unshared' : 'shared'}`
                );
              },
            }}
          />
          <CustomCommand
            command={{
              id: `copy-public-link`,
              group: CommandGroup.Board,
              aliases: ['share'],
              description: 'Copy public link to board',
              icon: 'none',
              handler: () => {
                copyPublicLink();
              },
            }}
          />
        </>
      )}
    </>
  );
}

function ResizeCardsOnStateChange({ statusId, boardId }: { statusId: string; boardId: string }) {
  const space = useSpace();
  const getIds = useGetFilterdIssuesForStatus(space.id, statusId, boardId);

  const findRelevantEntities = useFindRelevantEntities();
  const { invalidateSizeCache } = useSizeCache();

  useComponentDidMount(() => {
    function handleSet({ objects, get }: { objects: SyncEngineObject[]; get: GetRecoilValue }) {
      const ids = getIds();
      // we need to clear the size cache based on changes in the sync engine
      const relevantForEntities = findRelevantEntities(objects);
      for (const entityId of relevantForEntities) {
        const entity = get(syncEngineState(entityId)) as Entity | null;
        if (entity && isIssue(entity) && entity.statusId === statusId) {
          const cardIndex = ids.indexOf(entityId);
          if (cardIndex === -1) {
            continue;
          }
          invalidateSizeCache(cardIndex);
        }
      }
    }

    onStateEvent(StateEvents.Set, handleSet);
    return () => {
      offStateEvent(StateEvents.Set, handleSet);
    };
  });
  return null;
}

function Header({ board }: { board: Board }) {
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const space = useSpace();
  const modals = useModals();
  const setFocus = useSetKeyNavigationFocus();
  const [mode, setMode] = useRecoilState(workItemBoardScreenMode(board.id));
  const getSnippet = useSnippetForCallback();
  const tinyScreen = useIsTinyScreen();
  const excludeOptions = [...workItemDefaultExcludeOptions];
  const productTierExceeded = useIsProductTierExceededAndNag();

  return (
    <ScreenHeader
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={
        <>
          <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
            <Tooltip
              content={
                <>
                  Create {issueTerm} <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
                </>
              }
            >
              <SegmentedButton
                className="mr16"
                onClick={async () => {
                  if (await productTierExceeded()) {
                    return;
                  }
                  modals.openModal(Modals.NewEntity, {
                    type: 'Issue',
                    spaceId: space.id,
                    onCreated: entityId => {
                      if (entityId) {
                        setFocus(entityId);
                      }
                    },
                  });
                }}
                icon="add"
                dropdownContents={closeMenu => (
                  <SnippetPicker
                    onPicked={async snippetId => {
                      if (await productTierExceeded()) {
                        return;
                      }
                      const snippet = getSnippet(snippetId);
                      const content = snippet ? JSON.parse(snippet.contents) : emptyDocument();
                      modals.openModal(Modals.NewEntity, {
                        content,
                      });
                      closeMenu();
                    }}
                  />
                )}
              >
                New work item
              </SegmentedButton>
            </Tooltip>
          </HideIfSmallerThan>
          <SegmentedControl
            className="mr8"
            label="board and list toggle"
            value={mode}
            onValueChanged={m => setMode(m as Mode)}
            options={[
              {
                id: Mode.Board,
                icon: Mode.Board,
                label: `Change to mode ${Mode.Board}`,
                tooltip:
                  mode === Mode.Board ? undefined : (
                    <>
                      Board view <KeyboardShortcut shortcut={toggleListViewKey} />
                    </>
                  ),
              },
              {
                id: Mode.List,
                icon: Mode.List,
                label: `Change to mode ${Mode.List}`,
                tooltip:
                  mode === Mode.List ? undefined : (
                    <>
                      List view <KeyboardShortcut shortcut={toggleListViewKey} />
                    </>
                  ),
              },
            ]}
          />
          <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
            <Popover
              contentOptions={{ align: 'end' }}
              content={<BoardSharingPopover boardId={board.id} />}
            >
              <div>
                {!board.shared && <IconButton icon="public" buttonStyle={ButtonStyle.BareSubtle} />}
                {board.shared && (
                  <Button
                    className="mr8 ml8"
                    icon="public"
                    buttonStyle={ButtonStyle.SecondarySubtle}
                  >
                    Public
                  </Button>
                )}
              </div>
            </Popover>
          </HideIfSmallerThan>
          <MetadataConfigurationButton excludeOptions={excludeOptions} boardId={board.id} />
        </>
      }
    >
      <Breadcrumbs
        breadcrumbs={[
          ...spaceBreadcrumb,
          { name: board.name === 'Backlog' ? 'Planning' : board.name },
        ]}
        starred={{ type: StarredType.Board, id: board.id }}
      />
      <EntityFilterMenu2 className="mr8" id={board.id} entityType="Issue" compact={tinyScreen} />
    </ScreenHeader>
  );
}

function Card({
  id,
  boardId,
  onEdit,
  onEditComplete,
  moveToTopBottom,
  initiativeId,
}: {
  id: string;
  boardId: string;
  onEdit?: () => void;
  onEditComplete?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
  initiativeId?: string;
}) {
  const routingState = React.useMemo(
    () => ({
      filterId: boardId,
    }),
    [boardId]
  );

  const metaConfig = useRecoilValue(boardMetadataConfig(boardId));

  if (onEditComplete) {
    return <EditWorkItemCard metadataConfig={metaConfig} id={id} onDone={onEditComplete} />;
  }

  return (
    <WorkItemCard
      metadataConfig={metaConfig}
      id={id}
      onChangeTitle={onEdit}
      routingState={routingState}
      initiativeId={initiativeId}
      moveToTopBottom={moveToTopBottom}
    />
  );
}

function ListItem({
  id,
  boardId,
  className,
  style,
  onEdit,
  onEditComplete,
  moveToTopBottom,
  initiativeId,
}: {
  id: string;
  boardId: string;
  className?: string;
  style?: React.CSSProperties;
  onEdit?: () => void;
  onEditComplete?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
  initiativeId?: string;
}) {
  const routingState = React.useMemo(
    () => ({
      filterId: boardId,
    }),
    [boardId]
  );
  const metaConfig = useRecoilValue(boardMetadataConfig(boardId));

  if (onEditComplete) {
    return (
      <EditWorkItemListItem
        id={id}
        onDone={onEditComplete}
        className={className}
        style={style}
        metadataConfig={metaConfig}
      />
    );
  }

  return (
    <WorkItemListItem
      id={id}
      initiativeId={initiativeId}
      onChangeTitle={onEdit}
      className={className}
      style={style}
      routingState={routingState}
      metadataConfig={metaConfig}
      moveToTopBottom={moveToTopBottom}
    />
  );
}

export function WorkItemListView({
  boardId,
  statusIds,
  disabledColumnDndMessages,
  keyNavFocus,
  includeArchived,
  disableColumnEditing,
  numberColumnWidth: numberColumnWidthProp,
  defaultCollapsed,
  copyEntities,
  onPaste,
  move,
  initiativeId,
}: {
  boardId: string;
  statusIds: string[];
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  keyNavFocus?: React.RefObject<string | null>;
  includeArchived?: boolean;
  disableColumnEditing?: boolean;
  numberColumnWidth?: number;
  defaultCollapsed?: string[];
  copyEntities: (ids: string[]) => {
    id: string;
    name: string;
    url: string;
  }[];
  onPaste: (statusId: string, index: number, ids: string[]) => void;
  move: (rawIssueIdsToMove: string[], statusId: string, index: number) => Promise<void>;
  initiativeId?: string;
}) {
  const space = useSpace();
  const hasFilter = !!useRecoilValue(filterState(boardId));
  const numberColumnWidthSpace = useEntityNumberWidths([space.id]);
  const numberColumnWidth = numberColumnWidthProp ?? numberColumnWidthSpace;

  const checkProductTierExceeded = useIsProductTierExceededAndNag();

  const { filteredIssueIds: itemIds } = useRecoilValue(
    issuesByStatusSelector({ spaceId: space.id, filterId: boardId, includeArchived })
  );

  const moveToTopBottom = React.useCallback(
    (direction: 'top' | 'bottom', ids: string[], statusId: string) => {
      move(ids, statusId, direction === 'top' ? 0 : 99999999);
    },
    [move]
  );

  const getCountSelector = React.useCallback(
    (columnId: string) =>
      filteredIssuesForStatusCountSelector({
        spaceId: space.id,
        filterId: boardId,
        statusId: columnId,
        includeArchived,
      }),
    [boardId, includeArchived, space.id]
  );

  const renderSectionHeader = React.useCallback(
    (statusId: string, collapsed: boolean, toggleCollapsed: () => void, onNewItem: () => void) => {
      return (
        <SectionHeader
          disableEditing={disableColumnEditing}
          hideLimit={hasFilter}
          getCountSelector={getCountSelector}
          statusId={statusId}
          boardId={boardId}
          collapsed={collapsed}
          onToggleCollapsed={toggleCollapsed}
          onNewCard={onNewItem}
        />
      );
    },
    [boardId, hasFilter, disableColumnEditing]
  );

  const renderItem = React.useCallback(
    (
      id: string,
      _statusId: string,
      isFirst: boolean,
      isLast: boolean,
      edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => {
      return (
        <ListItem
          id={id}
          moveToTopBottom={moveToTopBottom}
          initiativeId={initiativeId}
          boardId={boardId}
          className={cn('listItem', {
            first: isFirst,
            last: isLast,
          })}
          style={
            {
              '--number-column-width': `${numberColumnWidth}px`,
            } as React.CSSProperties
          }
          onEdit={edit?.start}
          onEditComplete={edit?.end}
        />
      );
    },
    [boardId, numberColumnWidth]
  );

  const renderNewItem = React.useCallback(
    (statusId: string, index: number, isFirst: boolean, isLast: boolean, onDone: () => void) => {
      return (
        <NewWorkItem
          boardId={boardId}
          statusId={statusId}
          index={index}
          onDone={onDone}
          className={cn('listItem', {
            first: isFirst,
            last: isLast,
          })}
          style={
            {
              '--number-column-width': `${numberColumnWidth}px`,
            } as React.CSSProperties
          }
          list
        />
      );
    },
    [boardId, numberColumnWidth]
  );

  const canCreate = React.useCallback(() => {
    return !checkProductTierExceeded();
  }, [checkProductTierExceeded]);

  return (
    <VirtualizedListView
      defaultCollapsed={defaultCollapsed}
      id={boardId}
      className={styles.list}
      sectionIds={statusIds}
      itemIds={itemIds}
      disabledColumnDndMessages={disabledColumnDndMessages ?? {}}
      sectionHeaderHeight={60}
      itemHeight={41}
      spacerHeight={32}
      interactiveHeaders
      commandGroup={CommandGroup.Entities}
      keyNavFocus={keyNavFocus}
      canCreate={canCreate}
      onMoveItems={(ids, toStatus, toIndex) => {
        move(ids, toStatus, toIndex);
      }}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      renderNewItem={renderNewItem}
      renderPlaceholder={(statusId, onCreateNew) => {
        return <Placeholder statusId={statusId} onCreateNew={onCreateNew} list />;
      }}
      renderAccessories={grid => {
        return <ResizeItemsOnStateChange ids={grid} />;
      }}
      onCopy={copyEntities}
      onPaste={onPaste}
    />
  );
}

export function WorkItemBoardView({
  boardId,
  statusIds,
  disabledColumnDndMessages,
  keyNavFocus,
  includeArchived,
  disableColumnEditing,
  copyEntities,
  onPaste,
  move,
  initiativeId,
}: {
  boardId: string;
  statusIds: string[];
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  keyNavFocus?: React.RefObject<string | null>;
  includeArchived?: boolean;
  disableColumnEditing?: boolean;
  copyEntities: (ids: string[]) => {
    id: string;
    name: string;
    url: string;
  }[];
  onPaste: (statusId: string, index: number, ids: string[]) => void;
  move: (rawIssueIdsToMove: string[], statusId: string, index: number) => Promise<void>;
  initiativeId?: string;
}) {
  const space = useSpace();
  const hasFilter = !!useRecoilValue(filterState(boardId));
  const getFilteredIssuesForBoard = useGetFilterdIssuesForBoard(space.id, boardId, includeArchived);
  const board = useRecoilValue(boardSelector(boardId));
  const checkProductTierExceeded = useIsProductTierExceededAndNag();

  const canCreate = React.useCallback(() => {
    return !checkProductTierExceeded();
  }, [checkProductTierExceeded]);

  const moveToTopBottom = React.useCallback(
    (direction: 'top' | 'bottom', ids: string[], statusId: string) => {
      move(ids, statusId, direction === 'top' ? 0 : 99999999);
    },
    [move]
  );

  const renderCard = React.useCallback(
    (
      id: string,
      _statusId: string,
      edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => (
      <Card
        id={id}
        boardId={boardId}
        onEdit={edit?.start}
        onEditComplete={edit?.end}
        initiativeId={initiativeId}
        moveToTopBottom={moveToTopBottom}
      />
    ),
    [boardId]
  );

  const renderColumnHeader = React.useCallback(
    (statusId: string, onNewCard: () => void) => {
      return (
        <ColumnHeader
          includeArchived={includeArchived}
          hideLimit={hasFilter}
          statusId={statusId}
          boardId={boardId}
          onNewCard={onNewCard}
          disableEditing={disableColumnEditing}
        />
      );
    },
    [boardId, disableColumnEditing, hasFilter, includeArchived]
  );

  const renderNewCard = React.useCallback(
    (statusId: string, index: number, onDone: () => void) => {
      return <NewWorkItem index={index} boardId={boardId} statusId={statusId} onDone={onDone} />;
    },
    [boardId]
  );

  const renderColumnAccessories = React.useCallback(
    (statusId: string) => {
      return <ResizeCardsOnStateChange boardId={boardId} statusId={statusId} />;
    },
    [boardId]
  );

  const renderNewColumn = React.useCallback(
    () => (
      <NewColumnHeader
        types={
          board?.name === 'Current'
            ? [IssueStatusType.Todo, IssueStatusType.InProgress, IssueStatusType.Done]
            : [IssueStatusType.Backlog, IssueStatusType.Todo]
        }
        boardId={boardId}
      />
    ),
    [board?.name, boardId]
  );

  const getSelector = React.useCallback(
    (columnId: string) =>
      filteredIssuesForStatusSelector({
        spaceId: space.id,
        filterId: boardId,
        statusId: columnId,
        includeArchived,
      }),
    [boardId, includeArchived, space.id]
  );

  return (
    <VirtualizedBoardView
      dragEnabled
      id={boardId}
      className={styles.board}
      getSelector={getSelector}
      getAllColumns={getFilteredIssuesForBoard}
      columnIds={statusIds}
      disabledColumnDndMessages={disabledColumnDndMessages ?? {}}
      spacerHeight={8}
      columnHeaderHeight={32}
      commandGroup={CommandGroup.Entities}
      keyNavFocus={keyNavFocus}
      canCreate={canCreate}
      renderColumnHeader={renderColumnHeader}
      renderNewColumn={!disableColumnEditing ? renderNewColumn : undefined}
      renderCard={renderCard}
      renderNewCard={renderNewCard}
      renderPlaceholder={(columnId: string, onCreateNew: () => void) => {
        return <Placeholder statusId={columnId} onCreateNew={onCreateNew} />;
      }}
      renderColumnAccessories={renderColumnAccessories}
      onMoveCards={(ids, toStatus, toIndex) => {
        move(ids, toStatus, toIndex);
      }}
      onCopy={copyEntities}
      onPaste={onPaste}
    />
  );
}

function WorksItems({
  board,
  columns,
  keyNavFocus,
}: {
  board: Board;
  columns: BoardColumn[];
  keyNavFocus: React.RefObject<string | null>;
}) {
  const space = useSpace();
  const mode = useRecoilValue(workItemBoardScreenMode(board.id));
  const copyEntities = useCopyEntitiesToClipboard();

  const setFocus = useSetKeyNavigationFocus();
  const updateIssueSorts = useUpdateIssueSorts();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const sortIssues = useSortIssuesInBoardOrder();
  const duplicateIssues = useDuplicateIssues();

  const statusIds = columns.map(c => c.statusId);
  const statuses = useRecoilValue(statusesSelector(statusIds));
  const disabledColumnDndMessages = React.useMemo(
    () =>
      statuses?.reduce((ret, status) => {
        if (status.sortMode !== IssueStatusSortMode.Manual) {
          ret[status.id] = (
            <>
              <span className="semiBold">{status.name}</span> is ordered by{' '}
              <span className="semiBold">{sortModeToString(status.sortMode)}</span>.
            </>
          );
        }
        return ret;
      }, {} as { [columnId: string]: React.ReactNode }),
    [statuses]
  );

  const move = useRecoilCallback(
    ({ snapshot }) =>
      async (rawIssueIdsToMove: string[], statusId: string, index: number) => {
        const { filteredIssueIds, issueIdsByStatus } = snapshot
          .getLoadable(
            issuesByStatusSelector({
              spaceId: space.id,
              filterId: board?.id ?? '',
            })
          )
          .getValue();

        let issueIdsToMove = rawIssueIdsToMove;
        // need to sort the ids in board order in the multiselect case
        if (issueIdsToMove.length > 1) {
          issueIdsToMove = sortIssues(rawIssueIdsToMove);
        }
        const issuesForStatus = [...(issueIdsByStatus[statusId] ?? [])].filter(
          id => !issueIdsToMove.includes(id)
        );

        // if this is true, it means we are moving up/down
        if (issuesForStatus.length !== (issueIdsByStatus[statusId]?.length ?? 0)) {
          const disabledMessage = disabledColumnDndMessages?.[statusId];
          if (disabledMessage) {
            autoSortedMessage(disabledMessage, true);
            return;
          }
        } else {
          hideAutoSortedMessage();
        }

        const filteredIssuesForStatus = [...(filteredIssueIds[statusId] ?? [])].filter(
          id => !issueIdsToMove.includes(id)
        );

        // we're inserting into the filtered list, but need to actually insert into the real list
        const filteredIndexToInsertAt = Math.min(index, filteredIssuesForStatus.length);

        // if we're moving to the top, let's just put it at the top of the real list
        let indexToInsertAt = 0;

        // if we're not moving to the top, find the card we're going to place the card after
        if (filteredIndexToInsertAt !== 0) {
          const issueToInsertAfter = filteredIssuesForStatus[filteredIndexToInsertAt - 1];
          indexToInsertAt = issuesForStatus.indexOf(issueToInsertAfter) + 1;
        }

        issuesForStatus.splice(indexToInsertAt, 0, ...issueIdsToMove);
        const previousIssueId: string | undefined = issuesForStatus[indexToInsertAt - 1];
        const nextIssueId: string | undefined =
          issuesForStatus[indexToInsertAt + issueIdsToMove.length];

        disableMissingElementDetection();
        updateIssueSorts(statusId, issueIdsToMove, previousIssueId, nextIssueId);
        enableMissingElementDetection();
      },
    [space.id, board.id, disabledColumnDndMessages]
  );

  const onPaste = useRecoilCallback(
    ({ snapshot }) =>
      function RecoilColumnAccessories(statusId: string, index: number, ids: string[]) {
        const items = snapshot
          .getLoadable(
            filteredIssuesForStatusSelector({ spaceId: space.id, filterId: board.id, statusId })
          )
          .getValue();
        const current = items[index];
        const previous = items[index - 1];
        const results = duplicateIssues(ids, statusId, previous, current);
        if (results.length) {
          setTimeout(() => {
            setFocus(results[0]);
          });
        }
      },
    [board.id, space.id, setFocus, duplicateIssues]
  );

  return (
    <>
      <Filters2 id={board.id} />
      <QuickFilters2 id={board.id} />
      <WorkItemContext id={board.id} keyNavFocus={keyNavFocus} />
      {mode === Mode.Board && (
        <WorkItemBoardView
          boardId={board.id}
          statusIds={statusIds}
          disabledColumnDndMessages={disabledColumnDndMessages ?? {}}
          keyNavFocus={keyNavFocus}
          move={move}
          copyEntities={copyEntities}
          onPaste={onPaste}
        />
      )}
      {mode === Mode.List && (
        <WorkItemListView
          boardId={board.id}
          statusIds={statusIds}
          disabledColumnDndMessages={disabledColumnDndMessages ?? {}}
          keyNavFocus={keyNavFocus}
          move={move}
          copyEntities={copyEntities}
          onPaste={onPaste}
        />
      )}
    </>
  );
}

export function WorkItemBoardScreen() {
  const location = useLocation<LocationState>();
  const organization = useOrganization();
  const space = useSpace();
  const match = useRouteMatch<{ boardKey: string }>();
  const boardKey = match.params.boardKey;
  const boards = useRecoilValue(allBoardsForSpaceSelector(space.id));
  const setColumnEditState = useSetRecoilState(columnEditIdState);
  // This is kind of hacky, if we deleted the board, most identifying information
  // isn't available. But, we only ever have two boards, current and
  const board = boards.find(
    b =>
      b.key === boardKey ||
      (boardKey === 'backlog' && b.defaultStatusType === IssueStatusType.Backlog)
  );
  const mode = useRecoilValue(workItemBoardScreenMode(board?.id ?? ''));

  React.useEffect(() => {
    setColumnEditState(null);
    trackerPageLoad('WorkItemBoard', { listView: mode === Mode.List });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, board?.id]);

  const fetched = useRecoilValue(markerState(FetchedMarker.id));
  const boardLoaded = useRecoilValue(markerState(BoardMarker.id(space.id, boardKey as BoardType)));
  const columns = useRecoilValue(boardColumnsForBoardSelector(board?.id));
  const statusIds = columns.map(column => column.statusId);

  const collapsedStatuses = useCollapsedSections(board?.id ?? '');
  const findDefaultFocusedId = useRecoilCallback(({ snapshot }) => (boardId: string) => {
    const { filteredIssueIds } = snapshot
      .getLoadable(issuesByStatusSelector({ spaceId: space.id, filterId: boardId }))
      .getValue();
    const firstNonCollapsedColumnId = statusIds
      .filter(columnId => !collapsedStatuses.includes(columnId))
      .shift();
    if (!firstNonCollapsedColumnId) {
      return undefined;
    }
    return (
      (filteredIssueIds[firstNonCollapsedColumnId] ?? [])[0] ??
      `placeholder-${firstNonCollapsedColumnId}`
    );
  });

  const selectedAll = useRecoilCallback(
    ({ snapshot }) =>
      (selectedIds: string[], elementId: string, boardId: string) => {
        const { filteredIssueIds } = snapshot
          .getLoadable(issuesByStatusSelector({ spaceId: space.id, filterId: boardId }))
          .getValue();

        const item = snapshot.getLoadable(issueSelector(elementId)).getValue();
        if (!item) {
          return selectedIds;
        }

        const columnId = item.statusId;
        const allIds = Object.values(filteredIssueIds).flat();
        const idsInColumn = filteredIssueIds[columnId] ?? [];

        if (!idsInColumn.length) {
          return allIds;
        }

        if (idsInColumn.every(id => selectedIds.includes(id))) {
          return allIds;
        }
        return idsInColumn;
      }
  );

  const focusedElementId = React.useMemo(() => {
    // if we come back from the issue screen, force the selection to where it was before
    if (location.state?.entity) {
      const { entity, ...rest } = location.state;
      silentlyUpdateHistoryState(rest);
      return entity;
    }

    if (mode === Mode.List && board) {
      return findDefaultFocusedId(board.id);
    }

    return undefined;
  }, []);

  const keynavFocusRef = React.useRef<string | null>(focusedElementId ?? null);

  if (!board) {
    if (fetched) {
      return (
        <Screen>
          <NotFoundScreen />
        </Screen>
      );
    } else {
      return <LoadingScreen />;
    }
  }

  if (board.deleted) {
    return (
      <Screen>
        <DisabledScreen type="backlog" />
      </Screen>
    );
  }

  if (!boardLoaded) {
    return <LoadingScreen />;
  }

  return (
    <Screen>
      <TitleSetter title={`${organization.name} · ${space.name}`} />
      <KeyNavigationProvider
        initiallyFocusedElementId={focusedElementId}
        columnIds={mode === Mode.Board ? statusIds : [board.id]}
        disableEnsureVisible
        multiSelect
        isMultiSelectable={id => !id.includes('-')}
        onSelectAll={
          mode === Mode.List
            ? (elementId, _, selectedIds) => selectedAll(selectedIds, elementId, board.id)
            : undefined
        }
      >
        <Header board={board} />
        <WorksItems board={board} columns={columns} keyNavFocus={keynavFocusRef} />
        <KeyNavigationWatcher focusedRef={keynavFocusRef} />
      </KeyNavigationProvider>
    </Screen>
  );
}
