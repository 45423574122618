import React from 'react';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { ButtonStyle, IconButton, IconButtonTrigger } from '../../../components/new/button';
import { Count } from '../../../components/new/count';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  useHasKeyNavigationFocus,
  useKeyNavigationElement,
} from '../../../components/new/keyNavigation';
import { DropdownMenu, DropdownMenuContent } from '../../../components/new/menu/dropdownMenu';
import { Tooltip } from '../../../components/new/tooltip';
import {
  roadmapColumnSelector,
  roadmapColumnTypeToIcon,
} from '../../../syncEngine/selectors/roadmaps';
import { createNewIssueKey } from '../../../utils/config';
import { RoadmapColumnMenu } from './roadmapColumnHeader';

export function RoadmapSectionHeader({
  columnId,
  getCountSelector,
  onNewCard,
  collapsed,
  onToggleCollapsed,
}: {
  columnId: string;
  getCountSelector: (columnId: string) => RecoilValueReadOnly<number>;
  onNewCard: () => void;
  collapsed: boolean;
  onToggleCollapsed: () => void;
}) {
  const id = `header-${columnId}`;
  const ref = React.useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const focused = useHasKeyNavigationFocus(id);
  const count = useRecoilValue(getCountSelector(columnId));
  const column = useRecoilValue(roadmapColumnSelector(columnId));

  useKeyNavigationElement(id, ref);

  if (!column) {
    return null;
  }

  return (
    <div className="listHeaderContainer">
      <div className="listHeader" onClick={onToggleCollapsed} ref={ref}>
        <div className="row grow ellipsis">
          <Icon
            icon={collapsed ? 'arrow_forward' : 'arrow_down'}
            className="mr8 clickable grayIcon"
          />

          <Icon className="mr8" icon={roadmapColumnTypeToIcon(column.columnType)} />
          <div className="ellipsis mr8">{column.name}</div>
          <Count count={count} />
        </div>
        <div className="row">
          <Tooltip
            content={
              <>
                {`Create initiative`} <KeyboardShortcut shortcut={createNewIssueKey} />
              </>
            }
          >
            <IconButton
              icon="add"
              buttonStyle={ButtonStyle.BareSubtle}
              className="mr8"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onNewCard();
              }}
            />
          </Tooltip>
          <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
            <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
            <DropdownMenuContent
              onClick={e => {
                e.stopPropagation();
              }}
              side="bottom"
              align="end"
            >
              <RoadmapColumnMenu columnId={columnId} />
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {focused && (
          <>
            <Hotkey
              hotkey="space"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
            <Hotkey
              hotkey="enter"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
