import * as React from 'react';
import {
  Activity as ActivityModel,
  ProcessedChangedActivityDetails,
} from '../../../../sync/__generated/models';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function ProccessedChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const { processed, originalProcessed } = activity.details as ProcessedChangedActivityDetails;

  if (processed === originalProcessed) {
    return null;
  }

  return (
    <Activity activity={activity} icon="select_checkmark">
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> marked this
      feedback as {processed ? 'processed' : 'unprocessed'}.
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const ProccessedChangedActivity = React.memo(ProccessedChangedActivityComponent);
