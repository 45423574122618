/**
 * Find the length of a string, is "kind of" grapheme aware.
 *
 * @param str: the string to check the length of
 * @see https://blog.jonnew.com/posts/poo-dot-length-equals-two
 */
export function graphemeLength(str: string) {
  // Note: We'll probably need to look in to something better than this hack
  // maybe: https://github.com/orling/grapheme-splitter ?
  const joiner = '\u{200D}';
  const split = str.split(joiner);
  let count = 0;

  for (const s of split) {
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join('')).length;
    count += num;
  }

  return count / split.length;
}

export function codePoints(str: string): number[] {
  const points: number[] = [];
  let codePointCount = 0;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const _codePoint of str) {
    codePointCount += 1;
  }

  for (let i = 0; i < codePointCount; i++) {
    points.push(str.codePointAt(i)!);
  }
  return points;
}

const letter = /\w/;
export function isNonLetter(str: string, ignore?: string[]): boolean {
  if (!str) {
    return false;
  }
  return !str.match(letter) && str !== '_' && !ignore?.includes(str);
}

export function measureText(text: string, fontStyle: string) {
  const canvas = document.getElementById('textMeasurer') as HTMLCanvasElement | null;
  if (!canvas) {
    return -1;
  }

  const context = canvas.getContext('2d');
  if (!context) {
    return -1;
  }
  context.font = fontStyle;
  const metrics = context.measureText(text);
  return metrics.width;
}
