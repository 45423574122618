import cn from 'classnames';
import { uniq } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { between } from '../../../../shared/utils/sorting';
import { Button, ButtonSize, ButtonStyle } from '../../../components/new/button';
import cardStyles from '../../../components/new/entityCard.module.scss';
import listItemStyles from '../../../components/new/entityListItem.module.scss';
import { EntityTitleEditor } from '../../../components/new/entityTitleEditor';
import { Icon, IconSize } from '../../../components/new/icon';
import {
  FocusReason,
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import Pill, { PillStyle } from '../../../components/new/metadata/pill';
import { MetadataSize } from '../../../components/new/metadata/size';
import Spaces from '../../../components/new/metadata/space';
import { SpacePicker } from '../../../components/new/pickers/spacePicker';
import { HorizontalScrollArea } from '../../../components/new/scrollArea';
import { SubmitHotkeyTooltip } from '../../../components/submitHotkeyTooltip';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { TextAreaHandle } from '../../../slate/textArea';
import { useCreateInitiative } from '../../../syncEngine/actions/intiatives';
import {
  initiativePath,
  roadmapInitiativesForColumnSelector,
} from '../../../syncEngine/selectors/intiatives';
import { roadmapColumnSelector } from '../../../syncEngine/selectors/roadmaps';
import { spacesSelector } from '../../../syncEngine/selectors/spaces';
import { useNextAvailableNumber } from '../../../utils/entities';
import { filterPropertiesSelector } from '../../../utils/filtering2';
import { metaKeyDown } from '../../../utils/keyEvents';
import { scrollIntoView } from '../../../utils/scrolling';
import { Labels, Members } from '../workItemBoardScreen/newWorkItem';

enum CreateMode {
  Done,
  Open,
  Additional,
}

function SpacesList({
  spaceIds,
  onSpacesChanged,
  className,
}: {
  spaceIds: string[];
  onSpacesChanged: (spaceIds: string[]) => void;
  className?: string;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const spaces = useRecoilValue(spacesSelector(spaceIds));

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        asChild
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={cn('row', 'flexWrap', 'metadataGap', className)}>
          {spaces.length === 0 && (
            <Pill pillStyle={PillStyle.Empty} size={MetadataSize.Small}>
              <Icon icon={'workspace'} size={IconSize.Size16} />
              Space
            </Pill>
          )}
          {spaces.length > 0 && (
            <Spaces names={spaces.map(s => s.name)} privateSpace={spaces.some(s => s.private)} />
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuMedium"
      >
        <SpacePicker
          multi
          state={{ __new__: spaceIds }}
          onSpaceAdded={(_, spaceId) => {
            onSpacesChanged([...spaceIds, spaceId]);
          }}
          onSpaceRemoved={(_, spaceId) => {
            onSpacesChanged(spaceIds.filter(l => l !== spaceId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function NewInitiative({
  roadmapColumnId,
  filterId,
  index,
  onDone,
  onCreate,
  list,
  className,
  style,
}: {
  roadmapColumnId: string;
  filterId: string;
  index: number;
  onDone: () => void;
  onCreate?: (id: string) => void;
  list?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  const organization = useOrganization();
  const history = useHistory();
  const createInitiative = useCreateInitiative();
  const setFocus = useSetKeyNavigationFocus();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const ref = React.useRef<HTMLDivElement>(null);
  const editorRef = React.useRef<TextAreaHandle>(null);
  const maybeSpace = useMaybeSpace();

  const props = useRecoilValue(filterPropertiesSelector(filterId));

  const initiativeNumber = useNextAvailableNumber(organization.id, 'initiative');

  const impactId = props.impactId;
  const effortId = props.effortId;
  const [title, setTitle] = React.useState('');
  const [memberIds, setMemberIds] = React.useState<string[]>(props.assigneeIds);
  const [labelIds, setLabelIds] = React.useState<string[]>(props.labelIds);
  const [spaceIds, setSpaceIds] = React.useState<string[]>(
    maybeSpace ? [...((props as any).spaceIds ?? []), maybeSpace.id] : (props as any).spaceIds ?? []
  );

  const create = useRecoilCallback(
    ({ snapshot }) =>
      (mode: CreateMode) => {
        if (!title.length) {
          return;
        }

        const column = snapshot.getLoadable(roadmapColumnSelector(roadmapColumnId)).getValue();
        if (!column) {
          return;
        }

        const items = snapshot
          .getLoadable(roadmapInitiativesForColumnSelector(roadmapColumnId))
          .getValue();
        const afterItem = items[index - 1];
        const beforeItem = items[index];

        const sort = between({ after: afterItem?.sort, before: beforeItem?.sort });

        const { initiative, roadmapInitiatives } = createInitiative(title, {
          memberIds: memberIds,
          effortId,
          impactId,
          spaceIds,
          labelIds,
          roadmaps: [
            {
              roadmapColumnId,
              sort,
              roadmapId: column?.roadmapId,
            },
          ],
        });
        const roadmapInitiative = roadmapInitiatives[0];

        if (!initiative) {
          return;
        }
        onCreate?.(initiative.id);
        if (mode === CreateMode.Open) {
          history.push({
            pathname: initiativePath(organization, initiative),
            search: 'focusDescription=true',
            state: {
              backUrl: location.pathname,
              backSearch: location.search,
              entity: initiative.id,
            },
          });
          return;
        }

        disableMissingElementDetection();
        if (roadmapInitiative) {
          setFocus(roadmapInitiative.id, FocusReason.Programmatic);
        }
        enableMissingElementDetection();

        if (mode === CreateMode.Additional) {
          setTitle('');
          setMemberIds([]);
          editorRef.current?.clear();
          return;
        }
        onDone();
      },
    [title, roadmapColumnId, index, memberIds]
  );

  const titleEditor = (
    <EntityTitleEditor
      className={cn('headingS', { mt4: !list, [listItemStyles.titleEditor]: list })}
      initialTitle=""
      onChange={setTitle}
      onSubmit={e => {
        let mode = CreateMode.Done;
        if (e.shiftKey && metaKeyDown(e)) {
          mode = CreateMode.Additional;
        } else if (metaKeyDown(e) && e.altKey) {
          mode = CreateMode.Open;
        } else if (e.shiftKey) {
          // backwards compat to keep things working the same even though we're changing the hotkeys
          mode = CreateMode.Open;
        } else if (metaKeyDown(e)) {
          // backwards compat to keep things working the same even though we're changing the hotkeys
          mode = CreateMode.Additional;
        }

        create(mode);
      }}
      onFocus={() => {
        setTimeout(() => {
          if (ref.current) {
            scrollIntoView(ref.current, { block: 'center', scrollMode: 'if-needed' });
          }
        });
      }}
      onReset={onDone}
      autoFocus
      supportedMetadata={{
        labels: true,
        users: true,
      }}
      onMetadataAdded={(type, id) => {
        switch (type) {
          case 'user':
            setMemberIds(previous => uniq([...previous, id]));
            break;
        }
      }}
      placeholder={<>Enter a title, use @ for members</>}
      ref={editorRef}
      oneLine={list}
    />
  );

  return (
    <div
      className={cn(
        'relative',
        {
          [cardStyles.card]: !list,
          [listItemStyles.listItem]: list,
        },
        className
      )}
      style={style}
      ref={ref}
    >
      <div className="row metadataHeight">
        <div
          className={cn('finePrint', {
            mr8: !list,
            [listItemStyles.number]: list,
          })}
        >
          I{initiativeNumber.replace('T', '')}
        </div>
      </div>
      {list ? (
        <HorizontalScrollArea className="mr8 grow">{titleEditor}</HorizontalScrollArea>
      ) : (
        titleEditor
      )}
      <div
        className={cn('row', 'metadataGap', {
          mt4: !list,
          flexWrap: !list,
        })}
      >
        <Labels orgLevel labelIds={labelIds} onLabelsChanged={setLabelIds} />
        <span className="grow">
          <SpacesList spaceIds={spaceIds} onSpacesChanged={setSpaceIds} />
        </span>
        <Members users={memberIds} onUsersChanged={setMemberIds} />
      </div>
      <div className={cn('rowEnd', { mt12: !list, ml24: list })}>
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <SubmitHotkeyTooltip entityType="Issue">
          <Button
            size={ButtonSize.Small}
            onClick={e => {
              let mode = CreateMode.Done;
              if (metaKeyDown(e)) {
                mode = CreateMode.Additional;
              } else if (e.shiftKey) {
                mode = CreateMode.Open;
              }
              create(mode);
            }}
            buttonStyle={ButtonStyle.Primary}
          >
            Create
          </Button>
        </SubmitHotkeyTooltip>
      </div>
    </div>
  );
}
