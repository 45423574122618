import { Organization, Space } from '../../sync/__generated/models';
import { organizationPath } from '../syncEngine/selectors/organizations';
import { spacePath } from '../syncEngine/selectors/spaces';

export function isMaybeOrganizationPath(path: string): boolean {
  const components = path.split('/').filter(component => !!component);
  if (!components.length) {
    return false;
  }
  return !!components[0].match(/.+-[A-Za-z0-9_-]+/);
}

export function attachmentsPath(organization: Organization | null, space: Space | null) {
  if (!organization) {
    return '';
  }

  if (!space) {
    return organizationPath(organization, 'attachments').substr(1);
  }

  return spacePath(organization, space, 'attachments').substr(1);
}
