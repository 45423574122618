import { useRecoilValue } from 'recoil';
import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { useDeleteFeedbacks, useUpdateFeedbacks } from '../syncEngine/actions/feedback';
import { entitiesSelector } from '../syncEngine/selectors/entities';
import { isFeedback } from '../syncEngine/selectors/feedback';
import {
  assignIssueKey,
  deleteKey,
  labelIssueKey,
  toggleFeedbackProcessedKey,
} from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  EntityCommandGroupContext,
  useCommandMenuContext,
} from './state';

export function useFeedbackCommandGroup(): CommandDefinition[] {
  const context = useCommandMenuContext<EntityCommandGroupContext>(CommandGroup.Entities);
  const updateFeedback = useUpdateFeedbacks();
  const modals = useModals();

  const { entityIds } = context ?? {};
  const entities = useRecoilValue(entitiesSelector(entityIds ?? []));
  const feedbacks = entities.filter(isFeedback);
  const allFeedbacks = feedbacks.length === entities.length;
  const deleteFeedback = useDeleteFeedbacks();

  if (feedbacks.length > 0 && allFeedbacks) {
    const allProcessed = feedbacks.every(f => f.processed);
    const feedbackIds = feedbacks.map(f => f.id);

    const commands: CommandDefinition[] = [
      {
        id: 'toggle-processed',
        hotkey: toggleFeedbackProcessedKey,
        description: allProcessed ? 'Unprocess feedback' : 'Process feedback',
        icon: 'none',
        priority: 90,
        handler: () => {
          updateFeedback(feedbackIds, { processed: !allProcessed });
        },
      },
      {
        id: 'change-tags',
        hotkey: labelIssueKey,
        description: 'Change tags',
        icon: 'label',
        priority: 90,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Tags,
            context: {
              feedbackIds,
            },
          });
        },
      },
      {
        id: 'change-owners',
        hotkey: assignIssueKey,
        description: 'Change owners',
        icon: 'member',
        priority: 10,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Owners,
            context: {
              feedbackIds: entities.map(e => e.id),
            },
          });
        },
      },
      {
        id: 'delete-feedback',
        hotkey: deleteKey,
        description: 'Delete feedback',
        icon: 'delete',
        priority: 9,
        handler: () => {
          deleteFeedback(feedbacks.map(i => i.id));
        },
      },
    ];

    return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Entities }));
  }
  return [];
}
