import cn from 'classnames';
import { orderBy, values } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClient } from 'urql';
import {
  HasPrivateSpacesQuery,
  HasPrivateSpacesQueryVariables,
} from '../../../../../graphql__generated__/graphql';
import { capitalize } from '../../../../shared/utils/utils';
import {
  MemberRole,
  OrganizationMember,
  User as UserModel,
} from '../../../../sync/__generated/models';
import { Button, ButtonStyle, IconButtonTrigger } from '../../../components/new/button';
import { Icon, IconSize } from '../../../components/new/icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import Avatar from '../../../components/new/metadata/avatar';
import Pill, { PillStyle } from '../../../components/new/metadata/pill';
import { MetadataSize } from '../../../components/new/metadata/size';
import { SettingsListItem, SettingsPage } from '../../../components/new/settings';
import { TextInput } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import { toast } from '../../../components/toast';
import { useConfirmation } from '../../../contexts/confirmationContext';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCurrentUser, useHasAdminRights } from '../../../contexts/userContext';
import { hasPrivateSpacesQuery } from '../../../graphql/queries';
import { useUpdateOrganizationMembers } from '../../../syncEngine/actions/organizationMember';
import { useResendInvite } from '../../../syncEngine/actions/organizations';
import {
  activeUsersForOrganizationSelector,
  allUsersForOrganizationSelector,
} from '../../../syncEngine/selectors/organizations';
import { UserAndMember } from '../../../syncEngine/selectors/users';
import { getQueryParameter, removeQueryParameter } from '../../../utils/query';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import styles from './settingsScreen.module.scss';

function roleVerb(memberRole: MemberRole) {
  switch (memberRole) {
    case MemberRole.Admin:
      return 'an admin';
    case MemberRole.Member:
      return 'a member';
    case MemberRole.Guest:
      return 'a guest';
  }
}

export function UserAndEmailRow({ user }: { user: UserAndMember }) {
  return (
    <div className="rowBetween grow">
      <span
        className={cn('headingS', 'row')}
        style={{
          flexBasis: '50%',
          color: user.member.deactivated ? 'var(--gray10)' : 'var(--gray12)',
        }}
      >
        {!user.member.deactivated && (
          <Avatar
            className="mr8"
            name={user.user.name ?? user.user.username}
            img={user.user.avatar}
          />
        )}
        {user.member.deactivated && (
          <Icon
            icon={'disabled'}
            size={IconSize.Size20}
            className="mr8"
            style={{ fill: 'var(--gray10)' }}
          />
        )}
        {user.user.name ?? user.user.username}

        {user.member.role === MemberRole.Admin && (
          <Pill
            className="ml10"
            pillStyle={PillStyle.Primary}
            color={user.member.deactivated ? 'gray' : 'orange'}
            size={MetadataSize.Small}
            noHover
            textOnly
          >
            Admin
          </Pill>
        )}

        {user.member.invited && (
          <Pill
            className="ml10"
            pillStyle={PillStyle.Primary}
            color={user.member.deactivated ? 'gray' : 'blue'}
            size={MetadataSize.Small}
            noHover
            textOnly
          >
            Invited
          </Pill>
        )}
        {user.member.role === MemberRole.Guest && (
          <Pill
            className="ml10"
            pillStyle={PillStyle.Primary}
            color={user.member.deactivated ? 'gray' : 'grass'}
            size={MetadataSize.Small}
            noHover
            textOnly
          >
            Guest
          </Pill>
        )}
        {user.member.deactivated && (
          <Pill
            className="ml10"
            pillStyle={PillStyle.Primary}
            color={'gray'}
            size={MetadataSize.Small}
            noHover
            textOnly
          >
            Deactivated
          </Pill>
        )}
      </span>
      <span style={{ flexBasis: '50%' }}>{user.user.primaryEmail}</span>
    </div>
  );
}

function MemberItem({
  user,
  isFirst,
  isLast,
}: {
  user: UserAndMember;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const resend = useResendInvite();
  const client = useClient();

  const updateOrganizationMembers = useUpdateOrganizationMembers();

  async function resendInvite(user: UserModel) {
    await resend(user.id);
  }

  async function deactivate(user: UserModel, member: OrganizationMember) {
    updateOrganizationMembers([member.id], { deactivated: true });
    toast.info(
      <>
        <span className="semiBold">{user.primaryEmail}</span> no longer has access to this
        organization
      </>
    );
  }

  async function remove(user: UserModel, member: OrganizationMember) {
    updateOrganizationMembers([member.id], { deleted: true });
    toast.info(
      <>
        <span className="semiBold">{user.primaryEmail}</span> has been permamently removed from the
        organization
      </>
    );
  }

  async function reactivate(user: UserModel, member: OrganizationMember) {
    updateOrganizationMembers([member.id], { deactivated: false });
    toast.info(
      <>
        <span className="semiBold">{user.primaryEmail}</span> now has access to this organization
      </>
    );
  }

  function changeRole(user: UserModel, member: OrganizationMember, role: MemberRole) {
    updateOrganizationMembers([member.id], {
      role,
    });
    toast.info(
      <>
        <span className="semiBold">{user.primaryEmail}</span> is now {roleVerb(role)} of the
        organization
      </>
    );
  }

  const organization = useOrganization();
  const paidTier = !!organization.activeProductId;
  const { confirm } = useConfirmation();
  const currentUser = useCurrentUser();
  const hasAdminRights = useHasAdminRights();
  const orgAdminCount = useRecoilValue(
    activeUsersForOrganizationSelector({ organizationId: organization.id })
  ).filter(m => m.member.role === MemberRole.Admin).length;

  const [menuOpen, setMenuOpen] = React.useState(false);

  const meta = (
    <DropdownMenu open={menuOpen} onOpenChange={e => setMenuOpen(e)}>
      <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
      <DropdownMenuContent>
        <Submenu>
          <SubmenuTrigger>Change role</SubmenuTrigger>
          <SubmenuContent className="menuTiny">
            {values(MemberRole)
              .filter(role => role !== user.member.role)
              .map(role => {
                const lastAdmin = user.member.role === MemberRole.Admin && orgAdminCount <= 1;

                return (
                  <Tooltip
                    key={role}
                    disabled={hasAdminRights && paidTier && !lastAdmin}
                    content={
                      <>
                        {!paidTier && 'This action is only available on paid plans'}
                        {paidTier && !hasAdminRights && 'Only admins can perform this action'}
                        {paidTier &&
                          hasAdminRights &&
                          lastAdmin &&
                          'Cannot demote the last admin of an organization'}
                      </>
                    }
                  >
                    <DropdownMenuItem
                      key={role}
                      onClick={() => changeRole(user.user, user.member, role)}
                      disabled={!hasAdminRights || !paidTier || lastAdmin}
                    >
                      {capitalize(role)}
                    </DropdownMenuItem>
                  </Tooltip>
                );
              })}
          </SubmenuContent>
        </Submenu>

        {!user.member.deactivated && (
          <>
            {user.member.invited && (
              <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
                <DropdownMenuItem
                  onClick={() => resendInvite(user.user)}
                  disabled={!hasAdminRights}
                >
                  Re-send invite
                </DropdownMenuItem>
              </Tooltip>
            )}

            {user.user.id !== currentUser.id && (
              <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
                <DropdownMenuItem
                  destructive
                  onClick={() => deactivate(user.user, user.member)}
                  disabled={!hasAdminRights}
                >
                  Deactivate
                </DropdownMenuItem>
              </Tooltip>
            )}
          </>
        )}

        {user.member.deactivated && (
          <>
            <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
              <DropdownMenuItem
                onClick={() => reactivate(user.user, user.member)}
                disabled={!hasAdminRights}
              >
                Reactivate
              </DropdownMenuItem>
            </Tooltip>
            {user.user.id !== currentUser.id && (
              <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
                <DropdownMenuItem
                  destructive
                  onClick={async () => {
                    let hasPrivateSpaces = false;
                    const result = await client
                      .query<HasPrivateSpacesQuery, HasPrivateSpacesQueryVariables>(
                        hasPrivateSpacesQuery,
                        {
                          organizationId: organization.id,
                          userId: user.user.id,
                        }
                      )
                      .toPromise();
                    if (!result.error && result.data) {
                      hasPrivateSpaces = result.data.hasPrivateSpaces;
                    }
                    const confirmed = await confirm(
                      'Permanently remove user from organization?',
                      `This action is non-reversible. ${
                        hasPrivateSpaces
                          ? 'Please note: this user has private spaces for which they are the only member.'
                          : ''
                      }`,
                      { label: 'Remove', destructive: true }
                    );
                    if (confirmed) {
                      remove(user.user, user.member);
                    }
                  }}
                  disabled={!hasAdminRights}
                >
                  Remove
                </DropdownMenuItem>
              </Tooltip>
            )}
          </>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <SettingsListItem isFirst={isFirst} isLast={isLast} menuOpen={menuOpen} meta={meta}>
      <UserAndEmailRow user={user} />
    </SettingsListItem>
  );
}

export function ManageTeamScreen() {
  const modalManager = useModals();
  const organization = useOrganization();
  const history = useHistory();
  const modals = useModals();
  const allUsers = useRecoilValue(
    allUsersForOrganizationSelector({ organizationId: organization.id })
  );

  const hasAdminRights = useHasAdminRights();

  const [searchString, setSearchString] = React.useState('');
  const [members, _setMembers] = React.useState(allUsers);
  const setMembers = (userAndMember: UserAndMember[]) => {
    _setMembers(
      orderBy(userAndMember, ['member.deactivated', 'user.primaryEmail'], ['asc', 'asc'])
    );
  };

  const search = React.useMemo(() => {
    return new FuzzySearcher(
      FuzzySearcherConfiguration.Autocomplete,
      ['name', 'email', 'username'],
      allUsers.map(i => ({
        ...i,
        name: i.user.name,
        email: i.user.primaryEmail,
        username: i.user.username,
      }))
    );
  }, [allUsers]);

  React.useEffect(() => {
    if (!searchString) {
      setMembers(allUsers);
      return;
    }

    setMembers(search.search(searchString).map(i => i.item));
  }, [search, allUsers, searchString]);

  React.useEffect(() => {
    const queryParam = getQueryParameter(history, 'invite');
    if (queryParam === 'true') {
      removeQueryParameter(history, 'invite');
      modals.openModal(Modals.InviteMember);
    }
  }, []);

  return (
    <SettingsPage
      title="Members"
      description="Manage members of your organization. Full members are included in your billing."
    >
      <div className="rowStretch mb16">
        <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
          <Button
            className="mr16"
            buttonStyle={ButtonStyle.Primary}
            icon="add"
            onClick={() => {
              modalManager.openModal(Modals.InviteMember);
            }}
            disabled={!hasAdminRights}
          >
            Invite new members
          </Button>
        </Tooltip>
        <TextInput
          className={styles.searchInput}
          value={searchString}
          onChange={v => setSearchString(v.currentTarget.value)}
          placeholder="Filter members"
        />
      </div>

      <div>
        {members.map((user, index) => (
          <MemberItem
            key={user.member.id}
            user={user}
            isFirst={index === 0}
            isLast={index === members.length - 1}
          />
        ))}
      </div>
    </SettingsPage>
  );
}
