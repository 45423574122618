import * as React from 'react';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { FocusReason, useKeyNavigationWatcher } from './keyNavigation';

export function ScrollToLoadMore({
  onLoadMore,
  watchForId,
}: {
  onLoadMore: () => void;
  watchForId: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const notifiedRef = React.useRef(false);

  useKeyNavigationWatcher(({ focused, focusedReason }) => {
    if (focusedReason !== FocusReason.Keyboard) {
      return;
    }
    if (focused === watchForId && !notifiedRef.current) {
      notifiedRef.current = true;
      onLoadMore();
    }
  });

  useComponentDidMount(() => {
    let observer: IntersectionObserver | null = null;
    let observerFired = false;

    const observed = ref.current;

    if (observed) {
      observer = new IntersectionObserver(
        entries => {
          for (const entry of entries) {
            if (entry.isIntersecting && !observerFired) {
              onLoadMore();
              observerFired = true;
              return;
            }
          }
        },
        {
          threshold: 0,
        }
      );
      observer.observe(observed);
    }

    return () => {
      if (observed) {
        observer?.unobserve(observed);
      }
    };
  });
  return (
    <div className="rowCenter mb4 mt4 bodyS" ref={ref}>
      Scroll to load more
    </div>
  );
}
