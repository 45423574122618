import React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { ButtonStyle, IconButton } from '../../../components/new/button';
import { Tooltip } from '../../../components/new/tooltip';
import { useWatchStatuses } from '../../../syncEngine/actions/issueStatuses';
import { currentUserWatchingStatusSelector } from '../../../syncEngine/selectors/issueStatuses';

export function WatchingStatusIndicator({
  statusId,
  className,
}: {
  statusId: string;
  className?: string;
}) {
  const isWatchingStatus = useRecoilValue(currentUserWatchingStatusSelector(statusId));
  const watchStatuses = useWatchStatuses();

  if (!isWatchingStatus) {
    return null;
  }

  return (
    <Tooltip
      content={
        <div className="textCenter">
          Stop watching status
          <br />
          <span className="grayed">
            You are currently being notified about new {issueTerm}s in this status
          </span>
        </div>
      }
    >
      <IconButton
        className={className}
        icon="watch"
        buttonStyle={ButtonStyle.BareSubtle}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          watchStatuses([statusId], false);
        }}
      />
    </Tooltip>
  );
}
