import * as React from 'react';
import { useHistory } from 'react-router';
import { AuthResult, acceptTerms } from '../../api/auth';

import ExternalLink from '../../components/externalLink';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { toast } from '../../components/toast';
import { trackerPageLoad } from '../../tracker';

export function TermsScreenContents({
  onSubmit,
}: {
  onSubmit: (data: { onError: () => void }) => void;
}) {
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL">Terms of Service &amp; Privacy Policy</div>
      <p className="grayed bodyM mt16">
        By clicking below and continuing you agree that you've read and accepted our{' '}
        <ExternalLink
          className="link subtle hoverOnly"
          tabIndex={-1}
          href="https://www.notion.so/kitemaker/Terms-of-Service-183bd4e61ab9484086fd838c6b05d1b3"
        >
          Terms of Service
        </ExternalLink>{' '}
        &amp;{' '}
        <ExternalLink
          tabIndex={-1}
          className="link subtle hoverOnly"
          href="https://www.notion.so/kitemaker/Privacy-Policy-31157360db4f4bf9ac2715b2648a234f"
        >
          Privacy Policy
        </ExternalLink>
        .
      </p>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          setRequestInProgress(true);
          onSubmit({
            onError: () => setRequestInProgress(false),
          });
        }}
      >
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          type="submit"
          className="fullWidth mt24"
          disabled={requestInProgress}
          autoFocus
        >
          <div className="row">Agree and continue</div>
        </Button>
      </form>
    </div>
  );
}

export function TermsScreen({ onCompleted }: { onCompleted: (result: AuthResult) => void }) {
  const history = useHistory();

  React.useEffect(() => {
    trackerPageLoad('Terms');
  }, []);

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Terms & privacy policy" />
      <TermsScreenContents
        onSubmit={async ({ onError }) => {
          try {
            await acceptTerms(history, onCompleted);
          } catch (e) {
            toast.error(e.message);
            onError();
          }
        }}
      />
    </AuxiliaryScreen>
  );
}
