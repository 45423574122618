import cn from 'classnames';
import { times } from 'lodash';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Hotkey } from '../../components/new/hotkey';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useMarkTutorialCompleted } from '../../syncEngine/actions/users';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { commandMenuKey, isMobileOS } from '../../utils/config';
import { NotFoundScreen } from '../errorScreens';
import { CollaborativeSpecsStep } from './collaborativeSpecsStep';
import { FeedbackStep } from './feedbackStep';
import { IntegrationStep } from './integrationStep';
import { InviteStep } from './inviteStep';
import { KitemakerCommandStep } from './kitemakerCommandStep';
import styles from './onboardingScreen.module.scss';
import { SplashContents } from './splash';
import { VisualizeAndPlanWorkStep } from './visualizeAndPlanWorkStep';

export const ONBOARDING_TUTORIAL_ID = 'onboarding-tutorial';

export enum OnboardingStep {
  CollaborativeSpecs = 'collaborativeSpecs',
  Feedback = 'feedback',
  VisualizeAndPlanWork = 'visualizeAndPlanWork',
  KitemakerCommand = 'kitemakerCommand',
  Invite = 'invite',
  Integration = 'integration',
}

export const ownerSteps = [
  OnboardingStep.CollaborativeSpecs,
  OnboardingStep.Feedback,
  OnboardingStep.VisualizeAndPlanWork,
  OnboardingStep.KitemakerCommand,
  OnboardingStep.Invite,
  OnboardingStep.Integration,
];
export const nonOwnerSteps = [
  OnboardingStep.CollaborativeSpecs,
  OnboardingStep.Feedback,
  OnboardingStep.VisualizeAndPlanWork,
  OnboardingStep.KitemakerCommand,
  OnboardingStep.Integration,
];
export const mobileOwnerSteps = [OnboardingStep.Invite];
export const mobileNonOwnerSteps = [];

function Step({ step, onNext }: { step: OnboardingStep; onNext: () => void }) {
  switch (step) {
    case OnboardingStep.CollaborativeSpecs:
      return <CollaborativeSpecsStep onNext={onNext} />;
    case OnboardingStep.Feedback:
      return <FeedbackStep onNext={onNext} />;
    case OnboardingStep.VisualizeAndPlanWork:
      return <VisualizeAndPlanWorkStep onNext={onNext} />;
    case OnboardingStep.KitemakerCommand:
      return <KitemakerCommandStep onNext={onNext} />;
    case OnboardingStep.Invite:
      return <InviteStep onNext={onNext} />;
    case OnboardingStep.Integration:
      return <IntegrationStep onNext={onNext} />;
  }
}

function StepProgressDot({ active, onClick }: { active: boolean; onClick: () => void }) {
  return (
    <div className={styles.dotContainer} onClick={onClick}>
      <div
        className={cn(styles.dot, {
          [styles.active]: active,
        })}
      />
    </div>
  );
}

function StepProgress({
  current,
  total,
  goToIndex,
}: {
  current: number;
  total: number;
  goToIndex: (index: number) => void;
}) {
  const dots = times(total, i => (
    <StepProgressDot key={i} active={i === current} onClick={() => goToIndex(i)} />
  ));
  return <div className="row mt32">{total > 1 && dots}</div>;
}

export function OnboardingScreenContents({
  steps,
  index,
  onIndexChanged,
}: {
  index: number;
  steps: OnboardingStep[];
  onIndexChanged: (index: number) => void;
}) {
  const [fadingIn, setFadingIn] = React.useState(true);
  const [fadingOut, setFadingOut] = React.useState(true);

  useComponentDidMount(() => {
    setFadingOut(false);
    setTimeout(() => {
      setFadingIn(false);
    }, 200);
  });

  function changeIndex(newIndex: number) {
    setFadingOut(true);
    setTimeout(() => {
      setFadingOut(false);
      setFadingIn(true);
      onIndexChanged(newIndex);
      setTimeout(() => {
        setFadingIn(false);
      }, 200);
    }, 150);
  }

  return (
    <div className={styles.onboardindContainer}>
      <StepProgress current={index} total={steps.length} goToIndex={changeIndex} />
      <div
        className={cn(styles.step, {
          [styles.fadeStepIn]: fadingIn,
          [styles.fadeStepOut]: fadingOut,
          [styles.stepAnimationComplete]: !fadingIn && !fadingOut,
        })}
      >
        <Step step={steps[index]} onNext={() => changeIndex(index + 1)} />
      </div>
      <div className={styles.footer}></div>
    </div>
  );
}

export function OnboardingScreen() {
  const user = useCurrentUser();
  const organization = useOrganization();
  const history = useHistory();
  const match = useRouteMatch<{ step?: string }>();
  const isOwner = user.id === organization.actorId;
  const stepsForOwner = isMobileOS ? mobileOwnerSteps : ownerSteps;
  const stepsForNonOwner = isMobileOS ? mobileNonOwnerSteps : nonOwnerSteps;
  const steps = isOwner ? stepsForOwner : stepsForNonOwner;
  const { step } = match.params;
  const markTutorialCompleted = useMarkTutorialCompleted();

  if (!steps.length) {
    markTutorialCompleted(ONBOARDING_TUTORIAL_ID);
    history.push(organizationPath(organization));
    return null;
  }

  if (!step) {
    return (
      <AuxiliaryScreen>
        <TitleSetter title="Kitemaker · Onboarding" />
        <SplashContents
          onContinue={() => {
            history.push(organizationPath(organization, `onboarding/${steps[0]}`));
          }}
        />
      </AuxiliaryScreen>
    );
  }

  const stepIndex = steps.findIndex(s => s === step);
  if (stepIndex === -1) {
    return <NotFoundScreen />;
  }

  return (
    <AuxiliaryScreen verticalCenter>
      <TitleSetter title="Kitemaker · Onboarding" />
      <OnboardingScreenContents
        steps={steps}
        index={stepIndex}
        onIndexChanged={newIndex => {
          markTutorialCompleted(`onboarding-${steps[stepIndex]}`);
          if (newIndex < steps.length) {
            history.push(organizationPath(organization, `onboarding/${steps[newIndex]}`));
          } else {
            markTutorialCompleted(ONBOARDING_TUTORIAL_ID);
            history.push(organizationPath(organization));
          }
        }}
      />
      <Hotkey
        hotkey={commandMenuKey}
        priority={1}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          // just a hack to prevent the real command menu from showing up during the onboarding
        }}
      />
    </AuxiliaryScreen>
  );
}
