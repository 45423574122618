import * as React from 'react';
import { Editor } from 'slate';
import uuid from 'uuid';
import { KitemakerElement } from '../../../../shared/slate/kitemakerNode';
import { Elements, GroupType, isGroupType } from '../../../../shared/slate/types';
import { User as UserModel } from '../../../../sync/__generated/models';
import { Icon, IconSize } from '../../../components/new/icon';
import { User } from '../../../components/new/user';
import { UserAndMember } from '../../../syncEngine/selectors/users';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { SuggestionMatcher, SuggestionOption } from './withSuggestions';

type GroupFormat = `${GroupType}:${string}`;

function groupFormatMatch(str: string): { groupId: string; groupType: GroupType } | null {
  const options = Object.values(GroupType).map(t => `${t}:`);
  if (options.find(o => str.startsWith(o))) {
    const [groupType, groupId] = str.split(':');
    return { groupId, groupType: groupType as GroupType };
  }
  return null;
}

const groupLabels = {
  [GroupType.Members]: 'Notify members',
  [GroupType.Space]: 'Notify space',
  [GroupType.Organization]: 'Notify organization',
};

const groupIcons = {
  [GroupType.Members]: 'member',
  [GroupType.Space]: 'workspace',
  [GroupType.Organization]: 'org',
};

function Group({ groupType }: { groupType: GroupType }) {
  return (
    <div className="row">
      <Icon icon={groupIcons[groupType]} size={IconSize.Size20} className="mr8" />@{groupType}
      <span className="ml4 grayed">{groupLabels[groupType]}</span>
    </div>
  );
}

export function userSuggestionMatcher(
  currentUser: UserModel,
  users: UserAndMember[],
  groups?: {
    spaceId?: string;
    organizationId?: string;
    entityId?: string;
  },
  opts?: {
    overrideIgnoredElements?: Elements[];
  }
): SuggestionMatcher {
  const elementsToIgnore: Elements[] = opts?.overrideIgnoredElements ?? [Elements.SmartTodo];

  return {
    id: 'user',
    prefix: '@',
    propertiesToSearch: ['name', 'primaryEmail', 'alias'],
    options: (_, editor) => {
      const providedUsers = users.map(userAndMember => ({
        ...userAndMember,
        ...(userAndMember.user.id === currentUser.id ? { alias: 'me' } : {}),
        id: userAndMember.user.id,
        value: userAndMember.user.username,
        render: function RenderUserSuggestion() {
          return (
            <User
              key={userAndMember.user.id}
              user={userAndMember.user}
              member={userAndMember.member}
            />
          );
        },
      }));

      const groupElements: SuggestionOption[] = [];

      let showGroups = true;
      if (elementsToIgnore.length) {
        const nodes = Editor.nodes(editor, {
          mode: 'lowest',
          match: n => KitemakerElement.isElement(n) && elementsToIgnore.includes(n.type),
        });

        const node = nodes.next().value;
        if (node) {
          showGroups = false;
        }
      }

      if (showGroups && groups?.entityId) {
        const entityId = groups.entityId;
        const id: GroupFormat = `members:${entityId}`;
        groupElements.push({
          id,
          value: 'members',
          render: function RenderUserSuggestion() {
            return <Group groupType={GroupType.Members}></Group>;
          },
        });
      }

      if (showGroups && groups?.spaceId) {
        const spaceId = groups.spaceId;
        const id: GroupFormat = `space:${spaceId}`;
        groupElements.push({
          id,
          value: 'space',
          render: function RenderUserSuggestion() {
            return <Group groupType={GroupType.Space}></Group>;
          },
        });
      }

      if (showGroups && groups?.organizationId) {
        const organizationId = groups.organizationId;
        const id: GroupFormat = `organization:${organizationId}`;
        groupElements.push({
          id,
          value: 'organization',
          render: function RenderUserSuggestion() {
            return <Group groupType={GroupType.Organization}></Group>;
          },
        });
      }

      return [...providedUsers, ...groupElements];
    },
    onMatch: (editor, option, range, autocomplete) => {
      const groupMatch = groupFormatMatch(option.id);
      if (groupMatch) {
        const { groupType, groupId } = groupMatch;

        if (!isGroupType(groupType)) {
          return;
        }
        KitemakerTransforms.insertMention(
          editor,
          {
            type: Elements.Group,
            groupType: groupType,
            groupId,
            mentionId: uuid.v4(),
            actorId: currentUser.id,
            children: [{ text: '' }],
          },
          range,
          autocomplete
        );
      } else {
        KitemakerTransforms.insertMention(
          editor,
          {
            type: Elements.User,
            userId: option.id,
            mentionId: uuid.v4(),
            actorId: currentUser.id,
            children: [{ text: '' }],
          },
          range,
          autocomplete
        );
      }
    },
    handleTrailingSpace: true,
  };
}
