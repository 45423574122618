import * as React from 'react';
import { ResponsiveDesignSize, useIsTinyScreen } from '../hooks/useResponsiveDesign';
import { HideIfSmallerThan } from './hideIfSmallerThan';
import { ButtonSize } from './new/button';
import { InitiativeContents } from './new/entityFilters2';
import { FilterMenu as FilterMenu2 } from './new/filters2';
import { Tab, Tabs } from './new/tabs';

export function FilterTabs({
  id,
  tab,
  setTab,
}: {
  id: string;
  tab: string;
  setTab: (tab: string) => void;
}) {
  const tinyScreen = useIsTinyScreen();

  const tabs: Tab[] = React.useMemo(() => {
    const t = [
      {
        id: 'all',
        name: 'All',
      },
      {
        id: 'started',
        name: 'Started',
      },
      {
        id: 'not started',
        name: 'Not started',
      },
      {
        id: 'completed',
        name: 'Completed',
      },
    ];

    return t;
  }, []);

  return (
    <>
      <div className="row gap16">
        <Tabs size={ButtonSize.Medium} tabs={tabs} currentTab={tab} onTabChanged={setTab} />
        <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
          <FilterMenu2
            className="ml8 mr8"
            id={id}
            entityType="Initiative"
            compact={tinyScreen}
            DefaultContents={InitiativeContents}
            options={{
              orgLevel: true,
            }}
          />
        </HideIfSmallerThan>
      </div>
    </>
  );
}
