import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { DefaultFocusElement } from '../../../components/defaultFocusElement';
import { ListView, NO_KEYNAV } from '../../../components/new/listView';
import styles from '../../../components/new/menu/menu.module.scss';
import { useOrganization } from '../../../contexts/organizationContext';
import { useUpdateEntities } from '../../../syncEngine/actions/entities';
import { useUpdateTodos } from '../../../syncEngine/actions/todos';
import {
  effortLevelsForOrganizationSelector,
  effortLevelsForSpaceSelector,
} from '../../../syncEngine/selectors/effortLevels';

function SetEffort({
  onClose,
  onSetEffort,
  spaceId,
}: {
  onClose: () => void;
  onSetEffort: (effortId: string | null) => void;
  spaceId?: string;
}) {
  const organization = useOrganization();
  const spaceEfforts = useRecoilValue(effortLevelsForSpaceSelector(spaceId));
  const orgEfforts = useRecoilValue(effortLevelsForOrganizationSelector(organization.id)).filter(
    e => !e.spaceId
  );
  const efforts = spaceId ? spaceEfforts : orgEfforts;
  const twoCharacters = efforts.some(l => l.abbrevation.length === 2);

  return (
    <div className="rowStretch fullWidth fullHeight">
      <DefaultFocusElement />
      <ListView
        className={styles.content}
        itemClassName={styles.item}
        items={[
          {
            id: NO_KEYNAV,
            key: 'header',
            contents: <div className={styles.listItemHeader}>Set effort</div>,
          },
          {
            id: 'none',
            contents: 'None',
            hotkey: '0',
            onSelected: () => {
              onSetEffort(null);
              onClose();
            },
          },
          ...efforts.map((effort, index) => ({
            id: effort.id,
            contents: effort.name,
            icon: (
              <span
                className={cn(styles.abbreviation, { [styles.twoCharacters]: twoCharacters })}
                style={{ color: mapColor(effort.color) }}
              >
                {effort.abbrevation}
              </span>
            ),
            hotkey: `${index + 1}`,
            onSelected: () => {
              onSetEffort(effort.id);
              onClose();
            },
          })),
        ]}
      />
    </div>
  );
}

export function SetEffortContents({
  onClose,
  entityIds,
  spaceId,
}: {
  onClose: () => void;
  entityIds: string[];
  spaceId?: string;
}) {
  const updateEntities = useUpdateEntities();

  return (
    <SetEffort
      onClose={onClose}
      onSetEffort={effortId => updateEntities(entityIds, { effortId })}
      spaceId={spaceId}
    />
  );
}

export function SetTodoEffortContents({
  onClose,
  todoId,
  spaceId,
}: {
  onClose: () => void;
  todoId: string;
  spaceId: string;
}) {
  const updateTodos = useUpdateTodos();

  return (
    <SetEffort
      onClose={onClose}
      onSetEffort={effortId => updateTodos([todoId], { effortId })}
      spaceId={spaceId}
    />
  );
}
