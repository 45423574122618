import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { MemberRole, User } from '../../sync/__generated/models';
import { currentUserMembershipState } from '../syncEngine/selectors/users';
import { useOrganization } from './organizationContext';

const userContext = React.createContext<User | null>(null);

export function UserProvider({ user, children }: { user: User; children: React.ReactNode }) {
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
}

export function useCurrentUser(): User {
  return React.useContext(userContext)!;
}

export function useMaybeCurrentUser(): User | null {
  return React.useContext(userContext);
}

export function useHasAdminRights(): boolean {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));

  return orgMembership?.role === MemberRole.Admin;
}

export function useIsGuest(): boolean {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id))!;

  return orgMembership.role === MemberRole.Guest;
}
