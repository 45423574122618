import { Editor, Point, Range, Text } from 'slate';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { Marks } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import { EditorType } from '../types';

const marksThatShouldNotExpand = [Marks.Code];

export function withMarkBreaking(editor: EditorType) {
  const { insertText, insertBreak, delete: editorDelete } = editor;

  editor.insertText = text => {
    const selection = safeSelection(editor);

    if (!selection || !Range.isCollapsed(selection)) {
      insertText(text);
      return;
    }

    const [node, path] = Editor.node(editor, selection);
    if (!Text.isText(node)) {
      insertText(text);
      return;
    }

    const [, end] = Editor.edges(editor, path);
    if (!Point.equals(end, selection.focus)) {
      insertText(text);
      return;
    }

    const marks = Editor.marks(editor);
    if (!marks) {
      insertText(text);
      return;
    }

    for (const mark of marksThatShouldNotExpand) {
      if (marks[mark]) {
        Editor.removeMark(editor, mark);
      }
    }

    insertText(text);
  };

  editor.insertBreak = () => {
    const marks = Editor.marks(editor);
    if (marks) {
      for (const mark of Object.values(Marks)) {
        if (marks[mark]) {
          Editor.removeMark(editor, mark);
        }
      }
    }
    insertBreak();
  };

  editor.delete = options => {
    editorDelete(options);
    if (!editor.selection) {
      return;
    }
    const nodeEntry = Editor.node(editor, editor.selection);
    if (!nodeEntry) {
      return;
    }
    const [node, path] = nodeEntry;

    // first check that the current text node is non-empty
    if (!Text.isText(node) || node.text) {
      return;
    }

    // then check if the parent is non-empty
    const parentEntry = Editor.parent(editor, path);
    if (!parentEntry) {
      return;
    }

    const [parent] = parentEntry;
    if (!KitemakerElement.isElement(parent) || parent.children.length !== 1) {
      return;
    }

    const marks = Editor.marks(editor);
    if (!marks) {
      return;
    }

    for (const mark of Object.keys(marks)) {
      Editor.removeMark(editor, mark);
    }
  };

  return editor;
}
