import * as React from 'react';
import uuid from 'uuid';
import { documentFromString, stringifyDocument } from '../../../shared/slate/utils';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
} from '../../components/new/keyNavigation';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import Hover from '../hovers';
import { TextArea, TextAreaHandle, TextAreaType } from '../textArea';
import styles from './mathHover.module.scss';

export function MathHover({
  katex,
  children,
  onChange,
  onHide,
}: {
  katex: string;
  children?: JSX.Element;
  onChange: (value: string) => void;
  onHide: (escape?: boolean) => void;
}) {
  const id = React.useRef(uuid.v4());
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  const [value, setValue] = React.useState(documentFromString(katex));
  const valueRef = React.useRef(value);
  valueRef.current = value;
  const handle = React.useRef<TextAreaHandle>(null);

  useComponentDidMount(() => {
    disableKeyNav('math-hover');
    return () => {
      enableKeyNav('math-hover');
    };
  });

  return (
    <Hover
      content={
        <div className={styles.mathHover}>
          <TextArea
            type={TextAreaType.InputMedium}
            richText={false}
            ref={handle}
            autoFocus={true}
            initialValue={value}
            onChange={setValue}
            placeholder="e.g. x = \frac{-b \pm \sqrt{b^2-4ac}}{2a}"
            isHardBreak={e => e.key === 'Enter' && e.shiftKey && !e.metaKey && !e.ctrlKey}
            isSoftBreak={() => false}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                e.preventDefault();
                e.stopPropagation();
                onHide(true);
              }
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                onHide();
                onChange(stringifyDocument(valueRef.current));
              }
            }}
            suggestions={[]}
          />
          <div className="rowEnd mt12">
            <Button onClick={() => onHide()} size={ButtonSize.Small} className="mr8">
              Cancel
            </Button>
            <Button
              size={ButtonSize.Small}
              onClick={() => {
                onHide();
                onChange(stringifyDocument(valueRef.current));
              }}
              buttonStyle={ButtonStyle.Primary}
            >
              Save
            </Button>
          </div>
        </div>
      }
      open
      fixed
      hoverId={`math-${id.current}`}
      contentOptions={{
        side: 'bottom',
      }}
      onOpenChange={open => {
        if (!open) {
          onHide();
        }
      }}
    >
      {children}
    </Hover>
  );
}
