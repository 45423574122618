/**
* THIS FILE WAS AUTOMATICALLY GENERATED. NEVER EVER (EVER) EDIT IT BY HAND.
* MODIFY sync.json AND RE-RUN yarn generate-sync-engine
**/

/* eslint-disable quotes */
export const organizationMembersByOrganization = {
    name: 'organizationMembersByOrganization',
    type: 'OrganizationMember',
    property: 'organizationId',


};
export const organizationMembersByUser = {
    name: 'organizationMembersByUser',
    type: 'OrganizationMember',
    property: 'userId',


};
export const updatesByOrganizationMember = {
    name: 'updatesByOrganizationMember',
    type: 'Update',
    property: 'organizationMemberId',
    sortedBy: 'createdAt',
    sortReversed: true
};
export const integrationUsersByUser = {
    name: 'integrationUsersByUser',
    type: 'IntegrationUser',
    property: 'userIds',


};
export const integrationsByOrganization = {
    name: 'integrationsByOrganization',
    type: 'Integration',
    property: 'organizationId',


};
export const applicationsByOrganization = {
    name: 'applicationsByOrganization',
    type: 'Application',
    property: 'organizationId',


};
export const personalAccessTokensByOrganizationMember = {
    name: 'personalAccessTokensByOrganizationMember',
    type: 'PersonalAccessToken',
    property: 'organizationMemberId',


};
export const spacesByOrganization = {
    name: 'spacesByOrganization',
    type: 'Space',
    property: 'organizationId',
    sortedBy: 'sort',

};
export const spaceRoadmapsBySpace = {
    name: 'spaceRoadmapsBySpace',
    type: 'SpaceRoadmap',
    property: 'spaceId',
    sortedBy: 'sort',

};
export const spaceRoadmapsByRoadmap = {
    name: 'spaceRoadmapsByRoadmap',
    type: 'SpaceRoadmap',
    property: 'roadmapId',


};
export const issueStatusesBySpace = {
    name: 'issueStatusesBySpace',
    type: 'IssueStatus',
    property: 'spaceId',


};
export const issueStatusesByWatcher = {
    name: 'issueStatusesByWatcher',
    type: 'IssueStatus',
    property: 'watcherIds',


};
export const boardsBySpace = {
    name: 'boardsBySpace',
    type: 'Board',
    property: 'spaceId',
    sortedBy: 'sort',

};
export const boardColumnsByBoard = {
    name: 'boardColumnsByBoard',
    type: 'BoardColumn',
    property: 'boardId',
    sortedBy: 'sort',

};
export const issueLabelsBySpace = {
    name: 'issueLabelsBySpace',
    type: 'IssueLabel',
    property: 'spaceId',


};
export const organizatiionLabelsByOrgnization = {
    name: 'organizatiionLabelsByOrgnization',
    type: 'OrganizationLabel',
    property: 'organizationId',


};
export const commentsByEntity = {
    name: 'commentsByEntity',
    type: 'Comment',
    property: 'entityId',
    sortedBy: 'createdAt',
    sortReversed: true
};
export const commentsByThread = {
    name: 'commentsByThread',
    type: 'Comment',
    property: 'threadId',
    sortedBy: 'createdAt',

};
export const issuesBySpace = {
    name: 'issuesBySpace',
    type: 'Issue',
    property: 'spaceId',
    sortedBy: 'sort',

};
export const issuesByAssignee = {
    name: 'issuesByAssignee',
    type: 'Issue',
    property: 'assigneeIds',


};
export const issuesByWatcher = {
    name: 'issuesByWatcher',
    type: 'Issue',
    property: 'watcherIds',


};
export const issuesByLabel = {
    name: 'issuesByLabel',
    type: 'Issue',
    property: 'labelIds',


};
export const issuesByEffort = {
    name: 'issuesByEffort',
    type: 'Issue',
    property: 'effortId',


};
export const issuesByImpact = {
    name: 'issuesByImpact',
    type: 'Issue',
    property: 'impactId',


};
export const cyclesBySpace = {
    name: 'cyclesBySpace',
    type: 'Cycle',
    property: 'spaceId',
    sortedBy: 'number',
    sortReversed: true
};
export const cycleEntitiesByEntity = {
    name: 'cycleEntitiesByEntity',
    type: 'CycleEntity',
    property: 'entityId',
    sortedBy: 'createdAt',

};
export const cycleEntitesByCycle = {
    name: 'cycleEntitesByCycle',
    type: 'CycleEntity',
    property: 'cycleId',
    sortedBy: 'sort',

};
export const cycleTodosByCycle = {
    name: 'cycleTodosByCycle',
    type: 'CycleTodo',
    property: 'cycleId',
    sortedBy: 'sort',

};
export const cycleTodosByTodoId = {
    name: 'cycleTodosByTodoId',
    type: 'CycleTodo',
    property: 'todoId',
    sortedBy: 'sort',

};
export const cycleGoalsByCycle = {
    name: 'cycleGoalsByCycle',
    type: 'CycleGoal',
    property: 'cycleId',
    sortedBy: 'sort',
    sortReversed: true
};
export const cycleGoalsByEntity = {
    name: 'cycleGoalsByEntity',
    type: 'CycleGoal',
    property: 'entityId',
    sortedBy: 'sort',
    sortReversed: true
};
export const cycleGoalsByMember = {
    name: 'cycleGoalsByMember',
    type: 'CycleGoal',
    property: 'memberIds',


};
export const dependenciesByEnablesId = {
    name: 'dependenciesByEnablesId',
    type: 'Dependency',
    property: 'enablesId',


};
export const dependenciesByDependsOnId = {
    name: 'dependenciesByDependsOnId',
    type: 'Dependency',
    property: 'dependsOnId',


};
export const themesBySpace = {
    name: 'themesBySpace',
    type: 'Theme',
    property: 'spaceId',
    sortedBy: 'sort',

};
export const themesByAssignee = {
    name: 'themesByAssignee',
    type: 'Theme',
    property: 'assigneeIds',


};
export const themesByWatcher = {
    name: 'themesByWatcher',
    type: 'Theme',
    property: 'watcherIds',


};
export const themesByLabel = {
    name: 'themesByLabel',
    type: 'Theme',
    property: 'labelIds',


};
export const themesByIssue = {
    name: 'themesByIssue',
    type: 'Theme',
    property: 'issueIds',


};
export const themesByEffort = {
    name: 'themesByEffort',
    type: 'Theme',
    property: 'effortId',


};
export const themesByImpact = {
    name: 'themesByImpact',
    type: 'Theme',
    property: 'impactId',


};
export const collaborativeDocsByEntity = {
    name: 'collaborativeDocsByEntity',
    type: 'CollaborativeDoc',
    property: 'entityId',


};
export const feedbackByOrganization = {
    name: 'feedbackByOrganization',
    type: 'Feedback',
    property: 'organizationId',
    sortedBy: 'createdAt',
    sortReversed: true
};
export const feedbackByOwner = {
    name: 'feedbackByOwner',
    type: 'Feedback',
    property: 'ownerIds',


};
export const feedbackByTag = {
    name: 'feedbackByTag',
    type: 'Feedback',
    property: 'tagIds',


};
export const feedbackTagsByOrganization = {
    name: 'feedbackTagsByOrganization',
    type: 'FeedbackTag',
    property: 'organizationId',


};
export const companiesByOrganization = {
    name: 'companiesByOrganization',
    type: 'Company',
    property: 'organizationId',
    sortedBy: 'createdAt',
    sortReversed: true
};
export const peopleByOrganization = {
    name: 'peopleByOrganization',
    type: 'Person',
    property: 'organizationId',
    sortedBy: 'createdAt',
    sortReversed: true
};
export const insightsByFeedback = {
    name: 'insightsByFeedback',
    type: 'Insight',
    property: 'feedbackId',


};
export const insightsByEntity = {
    name: 'insightsByEntity',
    type: 'Insight',
    property: 'entityIds',


};
export const snippetsBySpace = {
    name: 'snippetsBySpace',
    type: 'Snippet',
    property: 'spaceId',
    sortedBy: 'name',

};
export const activitiesByEntity = {
    name: 'activitiesByEntity',
    type: 'Activity',
    property: 'entityId',
    sortedBy: 'createdAt',
    sortReversed: true
};
export const todosByEntity = {
    name: 'todosByEntity',
    type: 'Todo',
    property: 'entityId',
    sortedBy: 'sort',

};
export const todosByEffort = {
    name: 'todosByEffort',
    type: 'Todo',
    property: 'effortId',


};
export const todosByImpact = {
    name: 'todosByImpact',
    type: 'Todo',
    property: 'impactId',


};
export const todosByMember = {
    name: 'todosByMember',
    type: 'Todo',
    property: 'memberIds',


};
export const todosByConnectedEntity = {
    name: 'todosByConnectedEntity',
    type: 'Todo',
    property: 'connectedEntityId',
    sortedBy: 'sort',

};
export const impactLevelsByOrganization = {
    name: 'impactLevelsByOrganization',
    type: 'Impact',
    property: 'organizationId',
    sortedBy: 'level',

};
export const impactLevelsBySpace = {
    name: 'impactLevelsBySpace',
    type: 'Impact',
    property: 'spaceId',
    sortedBy: 'level',

};
export const effortLevelsByOrganization = {
    name: 'effortLevelsByOrganization',
    type: 'Effort',
    property: 'organizationId',
    sortedBy: 'level',

};
export const effortLevelsBySpace = {
    name: 'effortLevelsBySpace',
    type: 'Effort',
    property: 'spaceId',
    sortedBy: 'level',

};
export const foldersByOrganization = {
    name: 'foldersByOrganization',
    type: 'Folder',
    property: 'organizationId',
    sortedBy: 'sort',

};
export const foldersByParent = {
    name: 'foldersByParent',
    type: 'Folder',
    property: 'parentId',
    sortedBy: 'sort',

};
export const documentsByFolder = {
    name: 'documentsByFolder',
    type: 'Doc',
    property: 'folderId',
    sortedBy: 'sort',

};
export const documentsByOrganization = {
    name: 'documentsByOrganization',
    type: 'Doc',
    property: 'organizationId',
    sortedBy: 'sort',

};
export const initiativesByOrganization = {
    name: 'initiativesByOrganization',
    type: 'Initiative',
    property: 'organizationId',
    sortedBy: 'sort',

};
export const initiativesByMember = {
    name: 'initiativesByMember',
    type: 'Initiative',
    property: 'memberIds',


};
export const initiativesByIssue = {
    name: 'initiativesByIssue',
    type: 'Initiative',
    property: 'issueIds',


};
export const initiativesByLabel = {
    name: 'initiativesByLabel',
    type: 'Initiative',
    property: 'labelIds',


};
export const initiativesByWatcher = {
    name: 'initiativesByWatcher',
    type: 'Initiative',
    property: 'watcherIds',


};
export const initiativeSpacesBySpace = {
    name: 'initiativeSpacesBySpace',
    type: 'InitiativeSpace',
    property: 'spaceId',
    sortedBy: 'sort',

};
export const initiativeSpacesByInitiative = {
    name: 'initiativeSpacesByInitiative',
    type: 'InitiativeSpace',
    property: 'initiativeId',


};
export const roadmapsByOrganization = {
    name: 'roadmapsByOrganization',
    type: 'Roadmap',
    property: 'organizationId',
    sortedBy: 'sort',

};
export const roadmapColumnsByRoadmap = {
    name: 'roadmapColumnsByRoadmap',
    type: 'RoadmapColumn',
    property: 'roadmapId',
    sortedBy: 'sort',

};
export const roadmapInitiativesByInitiative = {
    name: 'roadmapInitiativesByInitiative',
    type: 'RoadmapInitiative',
    property: 'initiativeId',
    sortedBy: 'sort',

};
export const roadmapInitiativesByColumn = {
    name: 'roadmapInitiativesByColumn',
    type: 'RoadmapInitiative',
    property: 'columnId',
    sortedBy: 'sort',

};
export const viewsByOrganization = {
    name: 'viewsByOrganization',
    type: 'View',
    property: 'organizationId',
    sortedBy: 'sort',

};
export const viewsByActor = {
    name: 'viewsByActor',
    type: 'View',
    property: 'actorId',


};
export const releasesByOrganization = {
    name: 'releasesByOrganization',
    type: 'Release',
    property: 'organizationId',
    sortedBy: 'number',
    sortReversed: true
};
export const releasesByMember = {
    name: 'releasesByMember',
    type: 'Release',
    property: 'memberIds',


};
export const releasesByWatcher = {
    name: 'releasesByWatcher',
    type: 'Release',
    property: 'watcherIds',


};
export const releasesByIssue = {
    name: 'releasesByIssue',
    type: 'Release',
    property: 'issueIds',


};
export const releasesBySpace = {
    name: 'releasesBySpace',
    type: 'Release',
    property: 'spaceIds',


};
export const allIndexes = [
    organizationMembersByOrganization,
    organizationMembersByUser,
    updatesByOrganizationMember,
    integrationUsersByUser,
    integrationsByOrganization,
    applicationsByOrganization,
    personalAccessTokensByOrganizationMember,
    spacesByOrganization,
    spaceRoadmapsBySpace,
    spaceRoadmapsByRoadmap,
    issueStatusesBySpace,
    issueStatusesByWatcher,
    boardsBySpace,
    boardColumnsByBoard,
    issueLabelsBySpace,
    organizatiionLabelsByOrgnization,
    commentsByEntity,
    commentsByThread,
    issuesBySpace,
    issuesByAssignee,
    issuesByWatcher,
    issuesByLabel,
    issuesByEffort,
    issuesByImpact,
    cyclesBySpace,
    cycleEntitiesByEntity,
    cycleEntitesByCycle,
    cycleTodosByCycle,
    cycleTodosByTodoId,
    cycleGoalsByCycle,
    cycleGoalsByEntity,
    cycleGoalsByMember,
    dependenciesByEnablesId,
    dependenciesByDependsOnId,
    themesBySpace,
    themesByAssignee,
    themesByWatcher,
    themesByLabel,
    themesByIssue,
    themesByEffort,
    themesByImpact,
    collaborativeDocsByEntity,
    feedbackByOrganization,
    feedbackByOwner,
    feedbackByTag,
    feedbackTagsByOrganization,
    companiesByOrganization,
    peopleByOrganization,
    insightsByFeedback,
    insightsByEntity,
    snippetsBySpace,
    activitiesByEntity,
    todosByEntity,
    todosByEffort,
    todosByImpact,
    todosByMember,
    todosByConnectedEntity,
    impactLevelsByOrganization,
    impactLevelsBySpace,
    effortLevelsByOrganization,
    effortLevelsBySpace,
    foldersByOrganization,
    foldersByParent,
    documentsByFolder,
    documentsByOrganization,
    initiativesByOrganization,
    initiativesByMember,
    initiativesByIssue,
    initiativesByLabel,
    initiativesByWatcher,
    initiativeSpacesBySpace,
    initiativeSpacesByInitiative,
    roadmapsByOrganization,
    roadmapColumnsByRoadmap,
    roadmapInitiativesByInitiative,
    roadmapInitiativesByColumn,
    viewsByOrganization,
    viewsByActor,
    releasesByOrganization,
    releasesByMember,
    releasesByWatcher,
    releasesByIssue,
    releasesBySpace
];
