import * as Sentry from '@sentry/browser';
import { capitalize } from 'lodash';
import {
  Editor,
  Element,
  ElementEntry,
  Location,
  Node,
  NodeEntry,
  Path,
  Point,
  Range,
  Text,
} from 'slate';
import { Elements, ListElement, SmartTodoElement, VoidElement } from './types';
import { safeSelection } from './utils';

export const KitemakerNode = {
  ...Node,
  offsetIntoTextNodes(editor: Editor, textNodes: NodeEntry<Text>[], offset: number): Point | null {
    let current = offset;
    for (let i = 0; i < textNodes.length; i++) {
      const [textNode, textPath] = textNodes[i];
      const textNodeText = Node.string(textNode);
      if (current - textNodeText.length === 0) {
        if (i === textNodes.length - 1) {
          const endPoint = Editor.end(editor, textPath);
          return endPoint;
        } else {
          const [, nextPath] = textNodes[i + 1];
          const startPoint = Editor.start(editor, nextPath);
          return startPoint;
        }
      }
      if (current - textNodeText.length < 0) {
        const startPoint = Editor.start(editor, textPath);
        startPoint.offset = current;
        return startPoint;
      } else {
        current -= textNodeText.length;
      }
    }

    return null;
  },
  lineOfTextNode(_editor: Editor, textNode: Text, offset: number): string {
    const lines = textNode.text.split('\n');
    let length = offset;

    for (const line of lines) {
      length = length - line.length - 1;
      if (length < 0) {
        return line;
      }
    }

    return lines[lines.length - 1];
  },
  nextSibling(editor: Editor, path: Path): NodeEntry<Node> | null {
    const [, parentPath] = Editor.parent(editor, path);
    const children = Array.from(this.children(editor, parentPath));

    const index = children.findIndex(child => Path.equals(child[1], path));
    if (index < 0 || index === children.length - 1) {
      return null;
    }

    return children[index + 1];
  },
  previousSibling(editor: Editor, path: Path): NodeEntry<Node> | null {
    const [, parentPath] = Editor.parent(editor, path);
    const children = Array.from(this.children(editor, parentPath));

    const index = children.findIndex(child => Path.equals(child[1], path));
    if (index <= 0) {
      return null;
    }

    return children[index - 1];
  },
  isFirstElement(editor: Editor, path: Path) {
    return Editor.hasPath(editor, path) && path.every(p => p === 0);
  },
  isLastElement(editor: Editor, path: Path) {
    if (!Editor.hasPath(editor, path)) {
      return false;
    }

    let p = path;
    while (p.length > 0) {
      if (KitemakerNode.nextSibling(editor, p)) {
        return false;
      }
      p = p.slice(0, p.length - 1);
    }

    return true;
  },
  safeString(node: Node) {
    try {
      const result = Node.string(node);
      return result;
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          node,
        },
      });
    }
    return '';
  },
};

export const KitemakerElement = {
  ...Element,
  isTopLevelElement(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return (
      this.isHeadline(element) ||
      this.isListBlock(element) ||
      [
        Elements.Paragraph,
        Elements.BlockQuote,
        Elements.Code,
        Elements.Image,
        Elements.Video,
        Elements.File,
        Elements.Figma,
        Elements.Github,
        Elements.Loom,
        Elements.MathExpression,
        Elements.Line,
        Elements.Giphy,
        Elements.SmartTodo,
        Elements.Chat,
        Elements.AIPlaceholder,
        Elements.ExternalIssueLink,
      ].includes(type)
    );
  },
  isVoid(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [
      Elements.Image,
      Elements.Video,
      Elements.File,
      Elements.Figma,
      Elements.Github,
      Elements.Loom,
      Elements.Math,
      Elements.MathExpression,
      Elements.Line,
      Elements.AIPlaceholder,
      Elements.User,
      Elements.Group,
      Elements.Issue,
      Elements.Entity,
      Elements.TodoMention,
      Elements.Label,
      Elements.Giphy,
      Elements.CustomEmoji,
      Elements.ExternalIssueLink,
      Elements.InlineExternalIssueLink,
    ].includes(type);
  },
  isInline(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [
      Elements.Link,
      Elements.User,
      Elements.Group,
      Elements.Issue,
      Elements.Entity,
      Elements.TodoMention,
      Elements.Label,
      Elements.Math,
      Elements.Insight,
      Elements.Comment,
      Elements.CustomEmoji,
      Elements.InlineExternalIssueLink,
    ].includes(type);
  },
  isInlineVoid(node: Node) {
    return this.isElement(node) && this.isInline(node) && this.isVoid(node);
  },
  isNoneSelectableInlineVoid(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [Elements.CustomEmoji].includes(type);
  },
  isMention(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [
      Elements.User,
      Elements.Group,
      Elements.Issue,
      Elements.Label,
      Elements.Entity,
      Elements.TodoMention,
    ].includes(type);
  },
  isNonInteractive(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [Elements.Chat].includes(type);
  },
  isSmartTodo(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return type === Elements.SmartTodo;
  },
  isHeadline(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [Elements.Headline1, Elements.Headline2, Elements.Headline3].includes(type);
  },
  isTextBlock(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return (
      this.isHeadline(element) ||
      this.isFormattedTextBlock(element) ||
      this.isListBlock(element) ||
      type === Elements.SmartTodo ||
      type === Elements.Paragraph
    );
  },
  isFormattedTextBlock(element: Element | Elements) {
    const type = this.isElement(element) ? element.type : element;
    return [Elements.BlockQuote, Elements.Code].includes(type);
  },
  isListBlock(element: Element | Elements): element is ListElement {
    const type = this.isElement(element) ? element.type : element;
    return [Elements.Numbered, Elements.Bulleted, Elements.Todo].includes(type);
  },
  calculateIndent(element: Element) {
    return this.isListBlock(element) || element.type === Elements.SmartTodo ? element.indent : 0;
  },
  isTypePreservingBlock(element: Element | Elements): boolean {
    const type = this.isElement(element) ? element.type : element;
    // what are the blocks that should retain their type when something is pasted into them?
    return (
      this.isListBlock(element) ||
      this.isHeadline(element) ||
      type === Elements.Code ||
      type === Elements.BlockQuote ||
      type === Elements.SmartTodo
    );
  },
  predecesssorListViewSiblings(editor: Editor, element: ListElement, path: Path) {
    const siblings: Array<NodeEntry<Node>> = [];

    let sibling = KitemakerNode.previousSibling(editor, path);
    while (sibling) {
      const [node, siblingPath] = sibling as NodeEntry<ListElement>;
      sibling = KitemakerNode.previousSibling(editor, siblingPath);

      if (node.type === element.type && node.indent === element.indent) {
        siblings.push([node, siblingPath]);
        continue;
      } else if (
        Element.isElement(node) &&
        this.isListBlock(node) &&
        node.indent > element.indent
      ) {
        continue;
      }

      break;
    }

    return siblings;
  },
  successorListViewSiblings(editor: Editor, element: ListElement, path: Path) {
    const siblings: Array<NodeEntry<Node>> = [];

    let sibling = KitemakerNode.nextSibling(editor, path);
    while (sibling) {
      const [node, siblingPath] = sibling as NodeEntry<ListElement>;
      sibling = KitemakerNode.nextSibling(editor, siblingPath);

      if (node.type === element.type && node.indent === element.indent) {
        siblings.push([node, siblingPath]);
        continue;
      } else if (
        Element.isElement(node) &&
        this.isListBlock(node) &&
        node.indent > element.indent
      ) {
        continue;
      }

      break;
    }

    return siblings;
  },
  listViewChildrenRange(editor: Editor, path: Path): Range {
    const startRange = Editor.range(editor, path);

    const [node] = Editor.node(editor, path);
    if (!this.isElement(node) || !(this.isListBlock(node) || node.type === Elements.SmartTodo)) {
      return startRange;
    }

    let endPath = path;
    let sibling = KitemakerNode.nextSibling(editor, endPath);

    while (sibling) {
      const [siblingNode, siblingPath] = sibling as NodeEntry<Element>;

      if (
        !this.isElement(siblingNode) ||
        !(this.isListBlock(siblingNode) || siblingNode.type === Elements.SmartTodo) ||
        node.type !== siblingNode.type ||
        node.indent >= siblingNode.indent
      ) {
        break;
      }
      endPath = siblingPath;
      sibling = KitemakerNode.nextSibling(editor, endPath);
    }

    const endRange = Editor.range(editor, endPath);

    return {
      anchor: startRange.anchor,
      focus: endRange.focus,
    };
  },
  determineCurrentTextBlockType(editor: Editor, at?: Location | null) {
    const nodes: ElementEntry[] = Array.from(
      Editor.nodes(editor, {
        at: at ?? undefined,
        match: n =>
          KitemakerElement.isElement(n) &&
          KitemakerElement.isTopLevelElement(n) &&
          !KitemakerElement.isVoid(n),
        mode: 'highest',
      })
    );
    if (!nodes.length) {
      return null;
    }

    const firstType = nodes[0][0].type;
    return firstType;
  },
  humanReadableName(element: Elements): string {
    switch (element) {
      case Elements.Headline1:
        return 'Headline 1';
      case Elements.Headline2:
        return 'Headline 2';
      case Elements.Headline3:
        return 'Headline 3';
      case Elements.BlockQuote:
        return 'Block quote';
      case Elements.MathExpression:
        return 'Math expression';
      case Elements.SmartTodo:
        return 'Todo list';
      case Elements.Todo:
        return 'Todo list';
      default:
        return capitalize(element);
    }
  },
  smartTodoElements(editor: Editor): NodeEntry<SmartTodoElement>[] {
    const results: NodeEntry<SmartTodoElement>[] = [];
    for (let i = 0; i < editor.children.length; i++) {
      const node = editor.children[i];
      if (this.isElement(node) && node.type === Elements.SmartTodo) {
        results.push([node, [i]]);
      }
    }
    return results;
  },
  focusIsInNonInteractiveElement(editor: Editor) {
    const selection = safeSelection(editor);
    if (!selection) {
      return false;
    }
    const nonInteractiveNodes = Array.from(
      Editor.nodes(editor, {
        match: n => this.isElement(n) && this.isNonInteractive(n),
        at: selection,
        mode: 'highest',
      })
    );
    return !!nonInteractiveNodes.length;
  },
  focusIsInTopLevelVoidElement(editor: Editor, match?: (n: VoidElement) => boolean) {
    const selection = safeSelection(editor);
    if (!selection) {
      return false;
    }
    const voidNodes = Array.from(
      Editor.nodes(editor, {
        match: n =>
          this.isElement(n) &&
          this.isVoid(n) &&
          this.isTopLevelElement(n) &&
          (!match || match(n as VoidElement)),
        at: selection,
        mode: 'highest',
      })
    );
    return !!voidNodes.length;
  },
};
