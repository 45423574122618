import debugModule from 'debug';
import * as React from 'react';
import * as uuid from 'uuid';

const debug = debugModule('clientContext');

let clientId = sessionStorage.getItem('clientId');
if (clientId) {
  debug(`clientId loaded from sessionStorage: ${clientId}`);
} else {
  clientId = uuid.v4().toString();
  debug(`allocating new clientId: ${clientId}`);
  sessionStorage.setItem('clientId', clientId);
}

const clientContext = React.createContext(clientId);

export function useClient(): string {
  return React.useContext(clientContext);
}
