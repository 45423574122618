import { capitalize } from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { filterNotNull } from '../../../shared/utils/convenience';
import { Release, ReleaseStatus } from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import { releaseStatusName } from '../../screens/releaseScreen';
import {
  useAddAssigneesToEntities,
  useRemoveAssigneesFromEntities,
  useToggleAssigneesForEntities,
  useToggleWatchersForEntities,
} from '../../syncEngine/actions/entities';
import {
  useAddSpacesToReleases,
  useArchiveReleases,
  useDeleteReleases,
  useRemoveSpacesFromReleases,
  useUnarchiveReleases,
  useUpdateReleases,
} from '../../syncEngine/actions/releases';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import {
  releasesSelector,
  spacesIdsForReleasesSelector,
} from '../../syncEngine/selectors/releases';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import {
  archiveIssueKey,
  assignIssueKey,
  deleteKey,
  editTitleKey,
  openHistoryKey,
  selfAssignIssueKey,
  spaceInitiativeKey,
  toggleStarredKey,
  watchIssueKey,
} from '../../utils/config';
import { StarredType } from '../../utils/starred';
import { descriptionHistoryOpenAtom } from '../descriptionHistory';
import { DueDatePicker } from './dueDateMetadata';
import { useClearSelection, useKeyNavigationState } from './keyNavigation';
import {
  DropdownMenuCheckboxItem,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from './menu/dropdownMenu';
import { MemberPicker, entitiesToMemberPickerState } from './pickers/memberPicker';
import { SpacePicker } from './pickers/spacePicker';

export function ReleaseMenu({
  release,
  closeMenu,
  onChangeTitle,
  showHistory,
  onShowHistory,
}: {
  release: Release;
  closeMenu: () => void;
  onChangeTitle?: () => void;
  showHistory?: boolean;
  onShowHistory?: () => void;
}) {
  const user = useCurrentUser();
  const organization = useOrganization();

  const tinyScreen = useIsTinyScreen();
  const updateReleases = useUpdateReleases();
  const deleteReleases = useDeleteReleases();
  const archiveReleases = useArchiveReleases();
  const unarchiveReleases = useUnarchiveReleases();
  const addSpacesToReleases = useAddSpacesToReleases();
  const removeSpacesFromReleases = useRemoveSpacesFromReleases();
  const addAssignees = useAddAssigneesToEntities();
  const removeAssignees = useRemoveAssigneesFromEntities();
  const toggleAssigneesForEntities = useToggleAssigneesForEntities();
  const toggleWatching = useToggleWatchersForEntities();

  const { selected } = useKeyNavigationState();
  const clearSelection = useClearSelection();
  const selectedReleases = useRecoilValue(releasesSelector(selected));
  const selectedReleaseIds = selectedReleases.map(i => i.id);

  const releases = filterNotNull(selectedReleases.length ? selectedReleases : [release]);
  const releaseIds = releases.map(i => i.id);

  const archived = !!releases[0]?.archivedAt;
  const watching = releases.every(r => r.watcherIds?.includes(user.id));
  const spacePickerState = useRecoilValue(spacesIdsForReleasesSelector(releaseIds));
  const isMember = release.memberIds.includes(user.id);

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.Initiative,
      id: release.id,
    })
  );
  const toggleStarred = useToggleStarred();

  const [openHistory, setOpenHistory] = useRecoilState(descriptionHistoryOpenAtom);

  useComponentDidMount(() => {
    if (selectedReleaseIds?.length && !selectedReleaseIds.includes(release.id)) {
      clearSelection();
    }
  });

  return (
    <>
      <Submenu>
        <SubmenuTrigger icon="release">Status</SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          {Object.values(ReleaseStatus).map(status => (
            <DropdownMenuCheckboxItem
              key={status}
              onSelect={() =>
                updateReleases([release.id], { releaseStatus: status as ReleaseStatus })
              }
              checked={release.releaseStatus === status}
            >
              {capitalize(releaseStatusName(status))}
            </DropdownMenuCheckboxItem>
          ))}
        </SubmenuContent>
      </Submenu>

      <Submenu>
        <SubmenuTrigger icon="workspace" shortcut={spaceInitiativeKey}>
          Spaces
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <SpacePicker
            multi
            state={spacePickerState}
            onSpaceAdded={(ids, spaceId) => {
              addSpacesToReleases(ids, [spaceId]);
            }}
            onSpaceRemoved={(ids, spaceId) => {
              removeSpacesFromReleases(ids, [spaceId]);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>

      <Submenu>
        <SubmenuTrigger icon="member" shortcut={assignIssueKey}>
          Members
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <MemberPicker
            state={entitiesToMemberPickerState(releases)}
            onMemberAdded={(issueIds, memberId) => {
              addAssignees(issueIds, [memberId]);
            }}
            onMemberRemoved={(issueIds, memberId) => {
              removeAssignees(issueIds, [memberId]);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>

      <Submenu>
        <SubmenuTrigger icon="due_date">Due date</SubmenuTrigger>
        <SubmenuContent>
          <DueDatePicker
            date={releases.find(i => !!i.dueDate)?.dueDate ?? null}
            onSave={dueDate => {
              updateReleases(releaseIds, {
                dueDate: dueDate?.getTime() ?? null,
              });
              closeMenu?.();
            }}
            onCancel={() => closeMenu?.()}
          />
        </SubmenuContent>
      </Submenu>

      <DropdownMenuSeparator />

      {!tinyScreen && releases.length === 1 && onChangeTitle && (
        <DropdownMenuItem icon="edit" shortcut={editTitleKey} onClick={onChangeTitle}>
          Change title
        </DropdownMenuItem>
      )}

      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="member"
        shortcut={selfAssignIssueKey}
        onClick={() => {
          toggleAssigneesForEntities(releaseIds, [user.id]);
        }}
      >
        {isMember ? 'Remove' : 'Add'} myself
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="watch"
        shortcut={watchIssueKey}
        onClick={() => {
          toggleWatching(releaseIds, [user.id]);
        }}
      >
        {watching ? 'Stop watching' : 'Watch'}
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        shortcut={toggleStarredKey}
        onClick={() => {
          toggleStarred({ type: StarredType.Initiative, id: release.id });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      {showHistory && (
        <DropdownMenuItem
          icon="history"
          shortcut={openHistoryKey}
          onClick={() => {
            onShowHistory?.();
            setTimeout(() => setOpenHistory(v => !v), 100);
          }}
        >
          {openHistory ? 'Close' : 'Open'} history
        </DropdownMenuItem>
      )}
      <DropdownMenuSeparator />

      <DropdownMenuItem
        icon="archive"
        shortcut={archiveIssueKey}
        onClick={() => {
          if (archived) {
            unarchiveReleases(releaseIds);
          } else {
            archiveReleases(releaseIds);
          }
        }}
      >
        {archived ? 'Unarchive' : 'Archive'}
      </DropdownMenuItem>

      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteReleases(releaseIds);
        }}
      >
        Delete
      </DropdownMenuItem>
    </>
  );
}
