import * as React from 'react';
import { useResizeObserver } from '../../../hooks/useResizeObserver';

export function ResizableItem({
  onResized,
  children,
}: {
  onResized: () => void;
  children: React.ReactNode;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver(ref, onResized);

  return <div ref={ref}>{children}</div>;
}
