import { Editor, Node, NodeEntry, Path, Range, Text } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { safeSelection } from '../../../../shared/slate/utils';
import { EditorType } from '../../types';

interface ToMatch {
  texts: NodeEntry<Text>[];
  text: string;
  textsSinceLastInline: NodeEntry<Text>[];
  textSinceLastInline: string;
  selection: Range;
  newLine?: boolean;
}

export type Matcher = (toMatch: ToMatch) => boolean;

export function match(editor: EditorType, matchers: Matcher[], newLine?: boolean) {
  const selection = safeSelection(editor);
  if (!selection || !Range.isCollapsed(selection)) {
    return;
  }

  const blockEntry = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });
  if (!blockEntry) {
    return;
  }

  const [block, path] = blockEntry;
  if (KitemakerElement.isElement(block) && block.type === Elements.Code) {
    return;
  }

  const texts: NodeEntry<Text>[] = [];
  let textsSinceLastInline: NodeEntry<Text>[] = [];

  const nodes = Node.nodes(editor, {
    from: Editor.start(editor, path).path,
    to: selection.focus.path,
  });

  const nodeEntries = Array.from(nodes);
  for (const nodeEntry of nodeEntries) {
    const [node] = nodeEntry;
    if (Text.isText(node)) {
      texts.push(nodeEntry as NodeEntry<Text>);
      textsSinceLastInline.push(nodeEntry as NodeEntry<Text>);
    } else {
      textsSinceLastInline = [];
    }
  }

  let textToFocus = '';
  for (const [textNode, textPath] of texts) {
    if (Path.equals(textPath, selection.focus.path)) {
      textToFocus += KitemakerNode.safeString(textNode).substring(0, selection.focus.offset);
    } else {
      textToFocus += KitemakerNode.safeString(textNode);
    }
  }

  let textSinceInlineToFocus = '';
  for (const [textNode, textPath] of textsSinceLastInline) {
    if (Path.equals(textPath, selection.focus.path)) {
      textSinceInlineToFocus += KitemakerNode.safeString(textNode).substring(
        0,
        selection.focus.offset
      );
    } else {
      textSinceInlineToFocus += KitemakerNode.safeString(textNode);
    }
  }

  for (const matcher of matchers) {
    if (
      matcher({
        text: textToFocus,
        textSinceLastInline: textSinceInlineToFocus,
        texts,
        textsSinceLastInline,
        selection,
        newLine,
      })
    ) {
      break;
    }
  }
}

export function withStringMatching(editor: EditorType, matchers: Matcher[]) {
  const { insertText, insertBreak } = editor;
  editor.insertBreak = () => {
    match(editor, matchers, true);
    insertBreak();
  };

  editor.insertText = text => {
    insertText(text);
    match(editor, matchers);
  };

  return editor;
}
