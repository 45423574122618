import cn from 'classnames';
import * as React from 'react';
import { Icon, IconProps, IconSize } from '../icon';
import styles from './dependency.module.scss';
import Pill from './pill';
import { MetadataSize } from './size';

export enum DependencyState {
  BLOCKED = 'blocked',
  BLOCKS = 'blocks',
  ENABLES = 'enables',
  DEPENDS_ON = 'dependsOn',
}

export function DependencyIcon({
  state,
  resolved,
  ...rest
}: {
  state: DependencyState;
  resolved?: boolean;
} & Omit<IconProps, 'icon'>) {
  let icon;
  switch (state) {
    case DependencyState.BLOCKS:
      icon = 'dependency_blocking';
      break;
    case DependencyState.BLOCKED:
      icon = 'dependency_blocked';
      break;
    case DependencyState.ENABLES:
      icon = 'dependency_enables';
      break;
    case DependencyState.DEPENDS_ON:
      icon = 'dependency_depends';
      break;
  }

  return (
    <Icon
      className={cn({
        [styles.blocked]: state === DependencyState.BLOCKED && !resolved,
        [styles.blocks]: state === DependencyState.BLOCKS && !resolved,
      })}
      {...rest}
      icon={icon}
    />
  );
}

export default function Dependency({
  state,
  size = MetadataSize.Small,
  resolved,
}: {
  state: DependencyState;
  size?: MetadataSize;
  resolved?: boolean;
}) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  return (
    <Pill size={size} iconOnly>
      <DependencyIcon state={state} size={iconSize} resolved={resolved} />
    </Pill>
  );
}
