import cn from 'classnames';
import { debounce, uniq } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { Issue } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSearch } from '../../../contexts/searchContext';
import { isDocument } from '../../../syncEngine/selectors/documents';
import {
  entityKeySelector,
  entitySelector,
  isSpaceBoundEntity,
  spaceForEntitySelector,
  useSortEntitiesInNumericOrder,
} from '../../../syncEngine/selectors/entities';
import { isInitiative } from '../../../syncEngine/selectors/intiatives';
import { isIssue, statusSelector } from '../../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../../syncEngine/selectors/issueStatuses';
import { spacesForOrganizationSelector } from '../../../syncEngine/selectors/spaces';
import { Filter } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { KeyNavigationProvider } from '../keyNavigation';
import { LISTVIEW_ID, ListView, ListViewItem, NO_KEYNAV } from '../listView';
import menuStyles from '../menu/menu.module.scss';
import { InitiativeIcon } from '../metadata/initiative';
import {
  PickedState,
  PickerFooter,
  PickerItem,
  PickerState,
  mapPickerState,
  pickedStateToIcon,
} from '../picker';
import styles from '../picker.module.scss';
import { statusToIcon } from '../statusIcon';
import { TooltipIfTruncated } from '../tooltipIfTruncated';

const maxEntities = 25;

export function EntityItem({
  entityId,
  disableTooltip,
  showDeletedPlaceholder,
}: {
  entityId: string;
  disableTooltip?: boolean;
  showDeletedPlaceholder?: boolean;
}) {
  const entity = useRecoilValue(entitySelector(entityId));
  const space = useRecoilValue(spaceForEntitySelector(entity?.id));
  const status = useRecoilValue(statusSelector((entity as Issue)?.statusId));
  const isDefault = useRecoilValue(isDefaultStatusSelector(status?.id));
  const entityKey = useRecoilValue(entityKeySelector(entityId));

  if (!entity || (isSpaceBoundEntity(entity) && !space)) {
    if (showDeletedPlaceholder) {
      return (
        <>
          <Icon size={IconSize.Size20} icon={'none'} />
          <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
            Inaccessible work item or initiative
          </span>
        </>
      );
    }
    return null;
  }
  const term = isIssue(entity) ? issueTerm : entity.__typename.toLowerCase();

  let icon = undefined;

  if (status) {
    icon = <Icon size={IconSize.Size20} icon={statusToIcon(status.statusType, isDefault)} />;
  } else if (isInitiative(entity)) {
    icon = entity.archivedAt ? (
      <Icon size={IconSize.Size20} icon="archive" />
    ) : (
      <InitiativeIcon color={entity.color} />
    );
  } else if (isDocument(entity)) {
    icon = <Icon size={IconSize.Size20} icon="document" />;
  }

  return (
    <>
      {icon}
      <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
        {entityKey}
      </span>
      <TooltipIfTruncated disableTooltip={disableTooltip}>
        {entity.title || `Untitled ${term}`}
      </TooltipIfTruncated>
    </>
  );
}

export function EntityPicker({
  state,
  onAdd,
  onRemove,
  onDone,
  entityTypes,
  defaultEntityIds,
  mouseDown,
  placeholder,
  multi,
  spaceId,
  createItemOnTop,
  showPrivate,
  createNewItem,
  filterClassName,
}: {
  state: PickerState;
  onDone: () => void;
  onAdd: (entityIds: string[], entityId: string) => void;
  onRemove: (entities: string[], entityId: string) => void;
  entityTypes: Array<'Issue' | 'Cycle' | 'Feedback' | 'Initiative' | 'Doc' | 'Release'>;
  defaultEntityIds: string[];
  mouseDown?: boolean;
  placeholder?: string;
  multi?: boolean;
  spaceId?: string;
  filterClassName?: string;
  createItemOnTop?: boolean;
  showPrivate?: boolean;
  createNewItem?: (options: {
    onAdd: (id: string) => void;
    onRemove: (id: string) => void;
    onDone: () => void;
    filter: string;
    setFilter: (f: string) => void;
  }) => ListViewItem;
}) {
  const organization = useOrganization();
  const privateSpaces = useRecoilValue(spacesForOrganizationSelector(organization.id))
    .filter(s => s.private)
    .map(s => s.id);

  const sortEntitiesInNumericOrder = useSortEntitiesInNumericOrder();
  const [filter, setFilter] = React.useState('');
  const filterRef = React.useRef(filter);
  filterRef.current = filter;

  const ids = Object.keys(state);
  const sortedCurrentEntities = sortEntitiesInNumericOrder(Object.values(state).flat());
  const defaultChoices = uniq(
    [...sortedCurrentEntities, ...defaultEntityIds]
      .slice(0, maxEntities + sortedCurrentEntities.length)
      .filter(i => !ids.includes(i))
  );
  const defaultChoicesRef = React.useRef(defaultChoices);
  defaultChoicesRef.current = defaultChoices;

  const [entities, setEntities] = React.useState(defaultChoices);
  const [exactMatch, setExactMatch] = React.useState(false);

  const search = useSearch(rawResults => {
    if (!filterRef.current) {
      return;
    }
    const results = rawResults
      .filter(result => {
        if (
          (spaceId && !isSpaceBoundEntity(result)) ||
          (spaceId && isSpaceBoundEntity(result) && result.spaceId !== spaceId)
        ) {
          return false;
        }

        // Skip results from private spaces when querying the entire organization
        if (
          !showPrivate &&
          !spaceId &&
          isSpaceBoundEntity(result) &&
          privateSpaces.includes(result.spaceId)
        ) {
          return false;
        }

        return entityTypes.includes(result.__typename);
      })
      .map(result => result.id);

    setEntities(results.filter(entityId => !ids.includes(entityId)).slice(0, 20));
    setExactMatch(!!rawResults.find(i => i.title === filterRef.current));
  });

  const debouncedSearch = React.useMemo(() => {
    return debounce(search, 250);
  }, []);

  React.useEffect(() => {
    if (!filterRef.current) {
      setEntities(defaultChoicesRef.current.filter(entityId => !ids.includes(entityId)));
      setExactMatch(false);
      return;
    }

    debouncedSearch(filterRef.current);
  }, [filter]);

  const mappedEntities = mapPickerState(state);
  const items: ListViewItem[] = entities.map(entityId => {
    const picked: PickedState | undefined = mappedEntities[entityId];

    return {
      id: `entity-${entityId}`,
      icon: pickedStateToIcon(picked, multi),
      contents: () => <EntityItem entityId={entityId} />,
      mouseDown,
      onSelected: shift => {
        if (picked !== PickedState.All) {
          onAdd(ids, entityId);
        } else {
          onRemove(ids, entityId);
        }
        if (!shift) {
          onDone();
        }
      },
    };
  });

  // if it looks like someone's just typing a number, we won't suggest to create a new item
  const typingNumber = filter.match(/^\w+-\d+$/) || filter.match(/^\d+$/);
  if (!exactMatch && !typingNumber && createNewItem) {
    const item = createNewItem({
      onAdd: id => onAdd(ids, id),
      onRemove: id => onRemove(ids, id),
      onDone,
      filter,
      setFilter,
    });
    if (createItemOnTop) {
      items.unshift(item);
    } else if (filter.length) {
      items.push(item);
    }
  }

  let hideFooter = false;

  if (filter.length === 0 && items.length === 0 && createNewItem) {
    items.push({
      id: NO_KEYNAV,
      contents: 'Type to create a new work item',
    });
    hideFooter = true;
  }

  // FIXME: in an ideal world, this would use Picker instead of ListView but because Picker can't
  // currently handle an async search function we're outta luck.
  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <div className={cn('col fullWidth overflowHidden', styles.picker)}>
        <Filter
          placeholder={placeholder}
          filter={filter}
          onFilterChange={setFilter}
          className={filterClassName}
        />
        <div className={menuStyles.separator} />
        <ListView
          items={items}
          itemClassName={styles.item}
          className={styles.list}
          selectFirstOnItemsChanged
          itemRenderFunction={item => <PickerItem key={item.id} item={item} />}
        />
        {multi && !hideFooter && (
          <>
            <div className={menuStyles.separator} />
            <PickerFooter />
          </>
        )}
      </div>
    </KeyNavigationProvider>
  );
}
