import * as React from 'react';
import { ModalButtonWrapper, ModalContentWrapper, ModalContents } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';

export interface ConfirmationContext {
  confirm(
    title: React.ReactNode,
    details: React.ReactNode,
    options?: {
      label?: string;
      icon?: string;
      destructive?: boolean;
      cancelLabel?: string;
      cancelIcon?: string;
    }
  ): Promise<boolean>;
  isOpen: boolean;
}

const confirmationContext = React.createContext<ConfirmationContext | null>(null);

export function ConfirmationContent({
  onClose,
  details,
  confirmLabel = 'Confirm',
  confirmIcon,
  destructive,
  cancelLabel = 'Cancel',
  cancelIcon,
}: {
  onClose: (result?: boolean) => void;
  details: React.ReactNode;
  confirmLabel?: string;
  confirmIcon?: string;
  destructive?: boolean;
  cancelLabel?: string;
  cancelIcon?: string;
}) {
  return (
    <div style={{ maxWidth: 540, minWidth: 300 }}>
      <ModalContentWrapper>{details}</ModalContentWrapper>
      <ModalButtonWrapper>
        <Button
          type="button"
          icon={cancelIcon}
          buttonStyle={ButtonStyle.Secondary}
          onClick={_ => {
            onClose();
          }}
        >
          {cancelLabel}
        </Button>
        <Button
          autoFocus
          icon={confirmIcon}
          buttonStyle={destructive ? ButtonStyle.Destructive : ButtonStyle.Primary}
          onClick={_ => {
            onClose(true);
          }}
        >
          {confirmLabel}
        </Button>
      </ModalButtonWrapper>
    </div>
  );
}

export function ConfirmationProvider({ children }: { children: React.ReactNode }) {
  const [confirmationProps, setConfirmationProps] = React.useState<{
    title: React.ReactNode;
    details: React.ReactNode;
    label?: string;
    icon?: string;
    destructive?: boolean;
    cancelLabel?: string;
    cancelIcon?: string;
  } | null>(null);
  const pendingPromise = React.useRef<(result: boolean) => void | null>();

  async function confirm(
    title: React.ReactNode,
    details: React.ReactNode,
    options: {
      label?: string;
      icon?: string;
      destructive?: boolean;
      cancelLabel?: string;
      cancelIcon?: string;
    }
  ): Promise<boolean> {
    setConfirmationProps({ title, details, ...options });
    const result = await new Promise(resolve => {
      pendingPromise.current = resolve;
    });
    setConfirmationProps(null);
    return result as boolean;
  }

  async function complete(result: boolean) {
    pendingPromise.current?.(result);
  }

  return (
    <confirmationContext.Provider value={{ confirm, isOpen: !!confirmationProps }}>
      <ModalContents
        title={confirmationProps?.title}
        id="confirmation"
        depth={9999999}
        zIndex={999999}
        isOpen={!!confirmationProps}
        onRequestClose={() => {
          complete(false);
        }}
      >
        {confirmationProps && (
          <ConfirmationContent
            onClose={confirmed => {
              complete(!!confirmed);
            }}
            details={confirmationProps.details}
            confirmLabel={confirmationProps.label}
            confirmIcon={confirmationProps.icon}
            destructive={confirmationProps.destructive}
            cancelLabel={confirmationProps.cancelLabel}
            cancelIcon={confirmationProps.cancelIcon}
          />
        )}
      </ModalContents>
      {children}
    </confirmationContext.Provider>
  );
}

export function DummyConfirmationProvider({ children }: { children: React.ReactNode }) {
  return (
    <confirmationContext.Provider
      value={{
        isOpen: false,
        confirm: async () => {
          return false;
        },
      }}
    >
      {children}
    </confirmationContext.Provider>
  );
}

export function useConfirmation() {
  return React.useContext(confirmationContext)!;
}
