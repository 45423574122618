import { atom, selectorFamily } from 'recoil';
import { localStorageEffect } from '../syncEngine/effects';

export const collapsedState = atom({
  key: 'collapsedState',
  default: [],
  effects: [localStorageEffect(`__collapsed`)],
});

export const collapsedSelector = selectorFamily<boolean, string>({
  key: 'collapsedSelector',
  get:
    (itemID: string) =>
    ({ get }) => {
      const state = get(collapsedState);
      return state.includes(itemID);
    },
  set:
    (itemId: string) =>
    ({ set, get }, newValue) => {
      const state = new Set(get(collapsedState));
      if (newValue) {
        state.add(itemId);
      } else {
        state.delete(itemId);
      }
      set(collapsedState, [...state]);
    },
});
