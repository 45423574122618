import * as React from 'react';
import { IntegrationType } from '../../../sync/__generated/models';
import { Icon, IconProps } from './icon';

export function iconForIntegrationType(
  integrationType: IntegrationType,
  color?: boolean
): string | null {
  if (color) {
    switch (integrationType) {
      case IntegrationType.Github:
        return 'github';
      case IntegrationType.Figma:
        return 'figma_color';
      case IntegrationType.Slack:
        return 'slack_color';
      case IntegrationType.Slack2:
        return 'slack_color';
      case IntegrationType.Intercom:
        return 'intercom';
      case IntegrationType.Gitlab:
        return 'gitlab_color';
      case IntegrationType.Trello:
        return 'trello_color';
      case IntegrationType.Discord:
        return 'discord_color';
      case IntegrationType.Vanta:
        return 'vanta';
      case IntegrationType.Zapier:
        return 'zapier_color';
      case IntegrationType.Sentry:
        return 'sentry_color';
      case IntegrationType.Linear:
        return 'linear_color';
      case IntegrationType.Hubspot:
        return 'hubspot_color';
    }
  }

  switch (integrationType) {
    case IntegrationType.Github:
      return 'github';
    case IntegrationType.Figma:
      return 'figma';
    case IntegrationType.Slack:
      return 'logo_slack';
    case IntegrationType.Slack2:
      return 'logo_slack';
    case IntegrationType.Intercom:
      return 'intercom';
    case IntegrationType.Gitlab:
      return 'gitlab';
    case IntegrationType.Trello:
      return 'trello';
    case IntegrationType.Discord:
      return 'discord';
    case IntegrationType.Vanta:
      return 'vanta';
    case IntegrationType.Zapier:
      return 'zapier';
    case IntegrationType.Sentry:
      return 'sentry';
    case IntegrationType.Linear:
      return 'linear';
    case IntegrationType.Hubspot:
      return 'hubspot';
  }

  return null;
}

export function IntegrationIcon({
  integrationType,
  colored,
  ...rest
}: { integrationType: IntegrationType; colored?: boolean } & Omit<IconProps, 'icon'>) {
  const icon = iconForIntegrationType(integrationType, colored);
  if (!icon) {
    return null;
  }
  return <Icon icon={icon} {...rest} />;
}
