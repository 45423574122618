import { Editor, Range } from 'slate';
import isURL from 'validator/lib/isURL';
import { KitemakerElement, KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { sanitizeUrl, urlHasValidTld } from '../../../utils/urls';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { Matcher } from './withStringMatching';

const breakingCharacters = [' ', '(', ')', '[', ']', '{', '}'];

export function linkMatcher(editor: EditorType): Matcher {
  return ({ textSinceLastInline, textsSinceLastInline, selection, newLine }) => {
    const lastChar = textSinceLastInline[textSinceLastInline.length - 1];
    if (textSinceLastInline.length < 2 || (!breakingCharacters.includes(lastChar) && !newLine)) {
      return false;
    }

    const offsetAdjustment = newLine ? 0 : 1;
    let offset = textSinceLastInline.length - 1 - offsetAdjustment;

    while (offset >= 0) {
      if (breakingCharacters.includes(textSinceLastInline[offset])) {
        offset += 1;
        break;
      }
      offset -= 1;
    }

    const [parent, parentPath] = Editor.parent(editor, selection);
    if (!KitemakerElement.isElement(parent) || !KitemakerElement.isTextBlock(parent)) {
      return false;
    }

    const maybeLink = textSinceLastInline.substring(
      offset,
      textSinceLastInline.length - offsetAdjustment
    );

    if (!isURL(maybeLink)) {
      return false;
    }

    const url = sanitizeUrl(maybeLink);
    if (!urlHasValidTld(url)) {
      return false;
    }

    const anchor = KitemakerNode.offsetIntoTextNodes(
      editor,
      textsSinceLastInline,
      Math.max(offset, 0)
    );
    if (!anchor) {
      return false;
    }
    const focus = { path: selection.focus.path, offset: selection.focus.offset - offsetAdjustment };
    const range: Range = { anchor, focus };

    // already a link?
    const [links] = Editor.nodes(editor, {
      at: range,
      match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
    });
    if (links) {
      return false;
    }

    HistoryEditor.asBatch(editor, () => {
      Editor.withoutNormalizing(editor, () => {
        // we need to do this trick or else when the insertBreak() happens, it will
        // split the link itself, result in the new block getting an empty link at the front of it
        if (newLine) {
          KitemakerTransforms.insertNodes(editor, [{ text: '' }], {
            at: [parentPath[0] + 1],
            select: true,
          });
        }

        KitemakerTransforms.wrapNodes(
          editor,
          { type: Elements.Link, url, children: [] },
          { at: range, split: true, mode: 'lowest' }
        );
      });
    });
    return true;
  };
}
