import { generateLookupKey } from '../../../shared/utils/id';
import { between } from '../../../shared/utils/sorting';
import { foldersByParent, foldersByOrganization } from '../../../sync/__generated/indexes';
import { Folder } from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import {
  SyncEngineCreate,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { indexHelper, updateSortableSorts } from './helpers';

export function useCreateFolder() {
  const modelManager = useModelManager();
  const organization = useOrganization();

  return (name: string, parentId?: string | null) => {
    return modelManager.transaction(async (tx, getters) => {
      const siblings = parentId
        ? indexHelper<Folder>(getters, foldersByParent, parentId)
        : indexHelper<Folder>(getters, foldersByOrganization, organization.id).filter(
            f => !f.parentId
          );
      const sort = between({ before: siblings?.[0]?.sort });

      const folder: SyncEngineCreate<Folder> = {
        __typename: 'Folder',
        name,
        organizationId: organization.id,
        parentId: parentId ?? null,
        sort,
        lookupKey: generateLookupKey(),
        icon: null,
      };

      const result = tx.create(folder);
      return result;
    });
  };
}

export function useUpdateFolders() {
  const modelManager = useModelManager();
  return (folderIds: string[], update: SyncEngineUpdateWithoutDelete<Folder>) => {
    modelManager.transaction((tx, { get }) => {
      for (const folderId of folderIds) {
        const doc = get<Folder>(folderId);
        if (!doc || doc.deleted) {
          continue;
        }
        tx.update<Folder>(folderId, update);
      }
    });
  };
}

export function useUpdateFolderSorts() {
  const modelManager = useModelManager();
  const organization = useOrganization();

  return (
    parentId: string | null,
    folderIds: string[],
    afterFolderId?: string,
    beforeFolderId?: string
  ) => {
    modelManager.transaction((tx, getters) => {
      const folders = parentId
        ? indexHelper<Folder>(getters, foldersByParent, parentId)
        : indexHelper<Folder>(getters, foldersByOrganization, organization.id).filter(
            f => !f.parentId
          );

      const updates = updateSortableSorts(folders, folderIds, afterFolderId, beforeFolderId);
      for (const docId in updates) {
        tx.update<Folder>(docId, {
          sort: updates[docId],
        });
      }
    });
  };
}
