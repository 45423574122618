import gql from 'graphql-tag';
import * as React from 'react';
import { useClient } from 'urql';
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
} from '../../../graphql__generated__/graphql';
import ErrorText from '../components/errorText';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { TextInput } from '../components/new/textInput';
import { useClient as useClientId } from '../contexts/clientContext';
import { Modals, useModals } from '../contexts/modalContext';
import styles from './newOrganizationModal.module.scss';

function NewOrganizationContents() {
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [name, setName] = React.useState('');
  const modals = useModals();
  const client = useClient();
  const clientId = useClientId();

  const [error, setError] = React.useState('');
  const modalManager = useModals();

  async function submit() {
    if (requestInProgress) {
      return;
    }

    setRequestInProgress(true);

    try {
      const result = await client
        .mutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
          gql`
            mutation CreateOrganization($name: String!, $clientId: String!) {
              createOrganization(input: { name: $name, clientId: $clientId }) {
                organization {
                  id
                  name
                  slug
                }
              }
            }
          `,
          {
            name,
            clientId,
          }
        )
        .toPromise();

      setRequestInProgress(false);

      if (result.error || !result.data) {
        setError('Unable to create organization');
        return;
      }

      const slug = result.data.createOrganization.organization.slug;
      window.location.pathname = `/${slug}`;
    } catch (e) {
      if (e.graphQLErrors) {
        setError(e.graphQLErrors[0].message);
      } else {
        setError('Unable to create organization');
      }

      setRequestInProgress(false);
      return;
    }

    setRequestInProgress(false);
    modalManager.closeModal(Modals.ManageOrganizations);
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        if (name !== '') {
          submit();
        }
      }}
    >
      <ModalContentWrapper>
        <div className={styles.inputGroup}>
          <span className="oneLine headingS">Organization name</span>
          <TextInput
            className={styles.input}
            autoFocus
            placeholder="e.g. Acme Corp"
            onChange={e => {
              const val = e.currentTarget.value;
              setError('');
              setName(val);
            }}
          />
        </div>

        {error && <ErrorText error={error} />}
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <Button
          type="button"
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => modals.closeModal(Modals.CreateOrganization)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          buttonStyle={ButtonStyle.Primary}
          disabled={!name || requestInProgress}
        >
          Create organization
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export function NewOrganizationModal() {
  return (
    <>
      <Modal modalId={Modals.CreateOrganization} title="Create organization">
        <NewOrganizationContents />
      </Modal>
    </>
  );
}
