import gql from 'graphql-tag';
import {
  currentUserQueryResultFragment,
  cyclesQueryResultFragment,
  externalIssuesQueryResultFragment,
  documentsQueryResultFragment,
  initiativesQueryResultFragment,
  issueQueryResultFragment,
  issuesQueryResultFragment,
  organizationQueryResultFragment,
  releasesQueryResultFragment,
  roadmapsQueryResultFragment,
  feedbackQueryResultFragment,
} from '../../sync/__generated/fragments';
import { nonCurrentUserFragment, tombstoneFragment } from './fragments';

export const currentUserQuery = gql`
  query CurrentUser {
    currentUser {
      ...CurrentUserQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${currentUserQueryResultFragment}
  ${tombstoneFragment}
`;

export const currentOrganizationQuery = gql`
  query CurrentOrganization($id: ID!) {
    organization(id: $id) {
      serverTime
      users {
        ...NonCurrentUserFragment
      }
      ...OrganizationQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${organizationQueryResultFragment}
  ${nonCurrentUserFragment}
  ${tombstoneFragment}
`;

export const issuesQuery = gql`
  query Issues(
    $space: ID!
    $cursor: String
    $count: Int
    $offset: Int
    $updatedSince: Date
    $statusFilter: IssueStatusFilter
  ) {
    issues(
      spaceId: $space
      cursor: $cursor
      count: $count
      offset: $offset
      updatedSince: $updatedSince
      statusFilter: $statusFilter
    ) {
      hasMore
      cursor
      total
      ...IssuesQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${issuesQueryResultFragment}
  ${tombstoneFragment}
`;

export const documentsQuery = gql`
  query Documents(
    $organization: ID!
    $cursor: String
    $count: Int
    $offset: Int
    $updatedSince: Date
  ) {
    documents(
      organizationId: $organization
      cursor: $cursor
      count: $count
      offset: $offset
      updatedSince: $updatedSince
    ) {
      hasMore
      cursor
      total
      ...DocumentsQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${documentsQueryResultFragment}
  ${tombstoneFragment}
`;

export const feedbackQuery = gql`
  query Feedback(
    $organization: ID!
    $cursor: String
    $count: Int
    $offset: Int
    $updatedSince: Date
  ) {
    feedback(
      organizationId: $organization
      cursor: $cursor
      count: $count
      offset: $offset
      updatedSince: $updatedSince
    ) {
      hasMore
      cursor
      total
      ...FeedbackQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${feedbackQueryResultFragment}
  ${tombstoneFragment}
`;

export const releasesQuery = gql`
  query Releases($organizationId: ID!, $updatedSince: Date) {
    releases(organizationId: $organizationId, updatedSince: $updatedSince) {
      ...ReleasesQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${releasesQueryResultFragment}
  ${tombstoneFragment}
`;

export const roadmapsQuery = gql`
  query Roadmaps($organizationId: ID!) {
    roadmaps(organizationId: $organizationId) {
      ...RoadmapsQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${roadmapsQueryResultFragment}
  ${tombstoneFragment}
`;

export const initiativesQuery = gql`
  query Initiatives(
    $organizationId: ID!
    $spaceIds: [ID!]
    $initiativeSpaceIds: [ID!]
    $cursor: String
    $count: Int
    $offset: Int
    $updatedSince: Date
  ) {
    initiatives(
      organizationId: $organizationId
      spaceIds: $spaceIds
      initiativeSpaceIds: $initiativeSpaceIds
      cursor: $cursor
      count: $count
      offset: $offset
      updatedSince: $updatedSince
    ) {
      hasMore
      cursor
      total
      ...InitiativesQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${initiativesQueryResultFragment}
  ${tombstoneFragment}
`;

export const externalIssuesQuery = gql`
  query ExternalIssues($organizationId: ID!, $count: Int, $offset: Int, $updatedSince: Date) {
    externalIssues(
      organizationId: $organizationId
      count: $count
      offset: $offset
      updatedSince: $updatedSince
    ) {
      hasMore
      total
      ...ExternalIssuesQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${externalIssuesQueryResultFragment}
  ${tombstoneFragment}
`;

export const issueQuery = gql`
  query Issue($id: ID!) {
    issue(id: $id) {
      ...IssueQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${issueQueryResultFragment}
  ${tombstoneFragment}
`;

export const issueByNumberQuery = gql`
  query IssueByNumber($spaceId: ID!, $issueNumber: ID!) {
    issueByNumber(spaceId: $spaceId, issueNumber: $issueNumber) {
      ...IssueQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${issueQueryResultFragment}
  ${tombstoneFragment}
`;

export const collaborativeDocumentQuery = gql`
  query CollaborativeDocument($id: ID!) {
    collaborativeDocument(id: $id) {
      id
      content
      version
    }
  }
`;

export const missingChangesQuery = gql`
  query MissedChanges($id: ID!, $sinceVersion: Int!) {
    missedDocumentChanges(id: $id, sinceVersion: $sinceVersion) {
      id
      missedChanges {
        version
        operations
      }
    }
  }
`;

export const cyclesQuery = gql`
  query Cycles($space: ID!, $count: Int, $offset: Int, $updatedSince: Date) {
    cycles(spaceId: $space, count: $count, offset: $offset, updatedSince: $updatedSince) {
      hasMore
      total
      ...CyclesQueryResultFragment
      tombstones {
        ...TombstoneFragment
      }
    }
  }
  ${tombstoneFragment}
  ${cyclesQueryResultFragment}
`;

export const checkoutSessionQuery = gql`
  query CheckoutSession(
    $organization: ID!
    $product: String!
    $plan: String!
    $redirectURL: String!
  ) {
    checkoutSession(
      organizationId: $organization
      productId: $product
      planId: $plan
      redirectURL: $redirectURL
    ) {
      url
    }
  }
`;

export const organizationNameAvailableQuery = gql`
  query OrganizationNameAvailable($name: String!) {
    organizationNameAvailable(name: $name)
  }
`;

export const emailAddressAvailableQuery = gql`
  query EmailAddressAvailable($email: String!) {
    emailAddressAvailable(email: $email)
  }
`;

export const invitedUserQuery = gql`
  query InvitedUser($token: String!) {
    invitedUser(token: $token) {
      user {
        id
        primaryEmail
        updatedAt
        createdAt
      }
      organization {
        id
        name
        ssoDomain
        slug
      }
    }
  }
`;

export const hasPrivateSpacesQuery = gql`
  query HasPrivateSpaces($organizationId: String!, $userId: String!) {
    hasPrivateSpaces(organizationId: $organizationId, userId: $userId)
  }
`;

export const validateResetPasswordTokenQuery = gql`
  query ValidateResetPasswordToken($token: String!) {
    passwordResetTokenValid(token: $token)
  }
`;

export const sharedIssueQuery = gql`
  query SharedIssue($id: ID!) {
    sharedIssue(id: $id) {
      issue {
        id
        number
        title
        impactId
        effortId
        statusId
        assigneeIds
        labelIds
        spaceId
      }
      space {
        id
        name
        key
        organizationId
      }
      organization {
        id
        name
      }
      labels {
        id
        name
        color
        spaceId
      }
      statuses {
        id
        name
        statusType
        default
        spaceId
      }
      impactLevels {
        id
        name
        color
        level
        abbrevation
      }
      effortLevels {
        id
        name
        color
        level
        abbrevation
      }
      members {
        id
        username
        name
        avatar
      }
      todos {
        id
        status
        key
        entityId
        memberIds
        labelIds
      }
      collaborativeDocs {
        id
        content
        entityId
      }
      metadata
    }
  }
`;

export const sharedRoadmapQuery = gql`
  query sharedRoadmap($id: ID!) {
    sharedRoadmap(id: $id) {
      roadmap {
        id
        organizationId
        name
        number
        color
      }
      roadmapInitiatives {
        id
        initiativeId
        columnId
        sort
      }
      columns {
        id
        roadmapId
        name
        columnType
        sort
      }
      initiatives {
        id
        number
        title
        organizationId
        labelIds
        memberIds
        spaceIds
        issueIds
        color
      }
      initiativeSpaces {
        id
        spaceId
        initiativeId
      }
      organization {
        id
        name
      }
      labels {
        id
        name
        color
      }
      spaces {
        id
        name
        organizationId
      }
      issues {
        id
        statusId
      }
      statuses {
        id
        name
        statusType
        default
        spaceId
      }
      members {
        id
        username
        name
        avatar
      }
      metadata
    }
  }
`;

export const sharedBoardQuery = gql`
  query SharedBoard($id: ID!) {
    sharedBoard(id: $id) {
      board {
        id
        name
        spaceId
        key
        sharedWorkItems
      }
      space {
        id
        name
        key
        organizationId
        backgroundType
      }
      organization {
        id
        name
      }
      labels {
        id
        name
        color
        spaceId
      }
      statuses {
        id
        name
        statusType
        default
        spaceId
      }
      boardColumns {
        id
        sort
        boardId
        statusId
      }
      impactLevels {
        id
        name
        color
        level
        abbrevation
      }
      effortLevels {
        id
        name
        color
        level
        abbrevation
      }
      issues {
        id
        number
        statusId
        spaceId
        title
        sort
        impactId
        effortId
        labelIds
        assigneeIds
        createdAt
        updatedAt
      }
      members {
        id
        username
        name
        avatar
      }
      metadata
    }
  }
`;
