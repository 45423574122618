import cn from 'classnames';
import { capitalize, isFunction } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import {
  documentFromString,
  emptyDocument,
  isDocumentEmpty,
  stringifyDocument,
} from '../../../shared/slate/utils';
import { issueTerm } from '../../../shared/utils/terms';
import { Cycle, CycleStatus } from '../../../sync/__generated/models';
import { useRescheduleCycle } from '../../api/cycle';
import { CommandGroup } from '../../commands';
import { CollaborativeDocEditor } from '../../components/collaborativeDocEditor';
import { CycleStatusLabel } from '../../components/cycle';
import { DescriptionPlaceholder, PlaceholderType } from '../../components/descriptionPlaceholder';
import {
  Breadcrumb,
  Breadcrumbs,
  TitleBreadcrumb,
  useSpaceBreadcrumb,
} from '../../components/new/breadcrumbs';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import { CommandContext } from '../../components/new/commandMenuContext';
import { CustomCommand } from '../../components/new/customCommand';
import { Hotkey } from '../../components/new/hotkey';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
} from '../../components/new/keyNavigation';
import Pill from '../../components/new/metadata/pill';
import { MetadataSize } from '../../components/new/metadata/size';
import { SnippetPicker } from '../../components/new/pickers/snippetPicker';
import Popover from '../../components/new/popover';
import { ScreenHeader } from '../../components/new/screenHeader';
import { SegmentedButton } from '../../components/new/segmentedButton';
import { TabStyle, Tabs } from '../../components/new/tabs';
import { TextInput, TextInputSize, TextInputType } from '../../components/new/textInput';
import { Tooltip } from '../../components/new/tooltip';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { toast } from '../../components/toast';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useSpace } from '../../contexts/spaceContext';
import { OrganizationMarker } from '../../graphql/smartLoad';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useIsSmallScreen, useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import { useIsProductTierExceededAndNag } from '../../index/billingChecks';
import { useNewEntityModalArgs } from '../../modals/newEntityModal/newEntityModal';
import { TextArea, TextAreaHandle } from '../../slate/textArea';
import { useUpdateCycles } from '../../syncEngine/actions/cycles';
import { localStorageEffect } from '../../syncEngine/effects';
import { collaborativeDocIdByEntitySelector } from '../../syncEngine/selectors/collaborativeDoc';
import {
  cycleCompletedSelector,
  cycleIdByNumberSelector,
  cycleStarredIdSelector,
  cycleStatusToIcon,
  cycleSelector,
} from '../../syncEngine/selectors/cycles';
import { breadcrumbsForEntitySelector } from '../../syncEngine/selectors/entities';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { useSnippetForCallback } from '../../syncEngine/selectors/snippets';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { trackerEvent, trackerPageLoad } from '../../tracker';
import { createNewIssueKey, cycleNotesKey, editTitleKey } from '../../utils/config';
import { isSameYear, renderDate } from '../../utils/datetime';
import { LocationState } from '../../utils/history';
import { getQueryParameter, removeQueryParameter } from '../../utils/query';
import { StarredType } from '../../utils/starred';
import { NotFoundScreen } from '../errorScreens';
import LoadingScreen from '../loadingScreen';
import { CycleMenuButton } from './cycleMenu';
import styles from './cycleScreen.module.scss';
import { SummaryView } from './summaryView';
import { WorkItemView } from './workItemView';

interface RouteParams {
  cycleNumber: string;
}

const cycleTabState = atom({
  key: 'cycleTab',
  default: 'work',
  effects: [localStorageEffect('__cycleTab')],
});

function CycleBreadcrums({ cycle }: { cycle: Cycle }) {
  const space = useRecoilValue(spaceSelector(cycle.spaceId));
  const location = useLocation<LocationState>();
  const getSnippet = useSnippetForCallback();
  const productTierExceeded = useIsProductTierExceededAndNag();
  const modals = useModals();
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(cycle.id));
  const starredId = useRecoilValue(cycleStarredIdSelector({ cycleId: cycle.id }));
  const isSmallScreen = useIsSmallScreen();
  const isTinyScreen = useIsTinyScreen();

  if (!space || !entityBreadcrumbs) {
    return null;
  }

  const breadcrumbs: Breadcrumb[] = location.state?.breadcrumbs ?? [
    ...spaceBreadcrumb,
    ...entityBreadcrumbs,
  ];
  if (!isTinyScreen) {
    breadcrumbs.push({
      name: <TitleBreadcrumb number={`${space.key}-C${cycle.number}`} title={cycle.title} />,
    });
  }

  if (!space) {
    return null;
  }

  return (
    <ScreenHeader
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={
        <>
          {!isSmallScreen && (
            <Tooltip
              content={
                <>
                  Create {issueTerm} <KeyboardShortcut shortcut={createNewIssueKey} />
                </>
              }
            >
              <SegmentedButton
                className="mr16"
                onClick={async () => {
                  if (await productTierExceeded()) {
                    return;
                  }
                  modals.openModal(Modals.NewEntity);
                }}
                icon="add"
                dropdownContents={closeMenu => (
                  <SnippetPicker
                    onPicked={async snippetId => {
                      if (await productTierExceeded()) {
                        return;
                      }
                      const snippet = getSnippet(snippetId);
                      const content = snippet ? JSON.parse(snippet.contents) : emptyDocument();
                      modals.openModal(Modals.NewEntity, {
                        content,
                        onCreated: () => {
                          trackerEvent('Snippet Used', {
                            type: 'button',
                            context: 'Create work item from snippet',
                          });
                        },
                      });
                      closeMenu();
                    }}
                  />
                )}
              >
                New work item
              </SegmentedButton>
            </Tooltip>
          )}
          {isSmallScreen && !isTinyScreen && (
            <Tooltip
              content={
                <>
                  Create {issueTerm} <KeyboardShortcut shortcut={createNewIssueKey} />
                </>
              }
            >
              <IconButton
                icon="add"
                buttonStyle={ButtonStyle.BareSubtle}
                onClick={async () => {
                  if (await productTierExceeded()) {
                    return;
                  }
                  modals.openModal(Modals.NewEntity);
                }}
              />
            </Tooltip>
          )}
        </>
      }
    >
      <div className="row headerGap">
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          starred={{ type: StarredType.Cycle, id: starredId }}
        />
      </div>
    </ScreenHeader>
  );
}

function TitleComponent(
  { cycle }: { cycle: Cycle },
  ref?: React.ForwardedRef<TextAreaHandle | null>
) {
  const history = useHistory();
  const [title, setTitle] = React.useState(documentFromString(cycle.title));
  const titleRef = React.useRef(title);
  titleRef.current = title;
  const textAreaRef = React.useRef<TextAreaHandle | null>(null);
  const cycleRef = React.useRef(cycle);
  cycleRef.current = cycle;
  const disableKeyNav = useDisableKeyNavigation();
  const enableKeyNav = useEnableKeyNavigation();

  const updateCycles = useUpdateCycles();

  useComponentDidMount(() => {
    const autoFocus = getQueryParameter(history, 'focusTitle');
    if (autoFocus) {
      removeQueryParameter(history, 'focusTitle');
      setTimeout(() => textAreaRef.current?.focus());
    }
  });

  React.useEffect(() => {
    const doc = documentFromString(cycle.title);
    setTitle(doc);
  }, [cycle.id, cycle.title]);

  function submit() {
    if (cycleRef.current.deleted) {
      return;
    }

    const newTitle = stringifyDocument(titleRef.current!);
    if (newTitle !== cycleRef.current.title) {
      updateCycles([cycleRef.current.id], {
        title: newTitle,
      });
    }

    enableKeyNav('cycle-title');
  }

  return (
    <div
      className={cn(styles.cycleTitle, {
        [styles.empty]: isDocumentEmpty(textAreaRef.current?.raw().children ?? emptyDocument()),
      })}
    >
      <TextArea
        maxLength={1024}
        tabIndex={-1}
        ref={r => {
          textAreaRef.current = r;
          if (ref) {
            if (isFunction(ref)) {
              ref(r);
            } else {
              ref.current = r;
            }
          }
        }}
        disabled={cycle.cycleStatus === CycleStatus.Stopped}
        initialValue={title}
        onChange={v => {
          setTitle(v);
        }}
        className={cn(styles.cycleTitleEditor, 'fs-exclude')}
        placeholder="Give this cycle a title"
        onFocus={() => {
          disableKeyNav('cycle-title');
          scrollTo({ top: 0 });
        }}
        onBlur={submit}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            enableKeyNav('cycle-title');
            const resetValue = documentFromString(cycleRef.current.title);
            textAreaRef.current?.prepareForNewValue(resetValue);
            setTitle(resetValue);
            setTimeout(() => textAreaRef.current?.blur(), 50);
          }

          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            textAreaRef.current?.blur();
          }
        }}
      />
    </div>
  );
}

const Title = React.forwardRef(TitleComponent);

function NotesView({ cycleId }: { cycleId: string }) {
  const cycle = useRecoilValue(cycleSelector(cycleId));
  const documentId = useRecoilValue(collaborativeDocIdByEntitySelector(cycleId));
  const textAreaRef = React.useRef<TextAreaHandle>(null);

  if (!cycle) {
    return null;
  }
  return (
    <div className={styles.notesContainer}>
      <div className={styles.gutter} />
      <div className={styles.notes}>
        <CollaborativeDocEditor
          entityId={cycleId}
          documentId={documentId ?? ''}
          historyDescription={null}
          textAreaClassName={styles.description}
          placeholder={<DescriptionPlaceholder type={PlaceholderType.Cycle} />}
          textAreaRef={textAreaRef}
        />
      </div>
      <div className={styles.gutter} />
    </div>
  );
}

export function UpdateDate({ cycle, onDone }: { cycle: Cycle; onDone: () => void }) {
  const [startDate, setStateDate] = React.useState(
    moment(cycle.startDate).tz('Etc/GMT').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    moment(cycle.endDate).tz('Etc/GMT').format('YYYY-MM-DD')
  );

  const rescheduleCycle = useRescheduleCycle();
  const cycleStarted = cycle.cycleStatus === CycleStatus.Started;

  const onSave = React.useCallback(async () => {
    const adjustedStart = moment(startDate).tz('Etc/GMT', true);
    const adjustedEnd = moment(endDate).tz('Etc/GMT', true);

    try {
      await rescheduleCycle({
        id: cycle.id,
        startDate:
          cycle.cycleStatus === CycleStatus.NotStarted ? adjustedStart.toDate() : undefined,
        endDate: adjustedEnd.toDate(),
      });
      onDone();
    } catch (e) {
      const message = e.message.split(':')[1];
      if (message) {
        toast.error(message);
      }
    }
  }, [startDate, endDate, rescheduleCycle, cycle.id, cycle.cycleStatus, onDone]);

  const disabled =
    (cycle.cycleStatus === CycleStatus.NotStarted &&
      moment(startDate).tz('Etc/GMT', true).startOf('day').valueOf() <
        moment().tz('Etc/GMT', true).startOf('day').valueOf()) ||
    moment(endDate).tz('Etc/GMT', true).startOf('day').valueOf() <
      moment().tz('Etc/GMT', true).startOf('day').valueOf() ||
    moment(startDate).valueOf() > moment(endDate).valueOf();

  return (
    <div className={styles.datePopover}>
      <span className="headingS mt8 mb4">Start date</span>
      {/* TODO: make this a component */}
      <input
        disabled={cycleStarted}
        className={cn(styles.dateInput, 'mb16')}
        min={moment().tz('Etc/GMT').format('YYYY-MM-DD')}
        value={startDate}
        onChange={e => setStateDate(e.target.value)}
        type="date"
      />
      <span className="headingS mb4">End date</span>
      <input
        className={cn(styles.dateInput, 'mb16')}
        min={moment().tz('Etc/GMT').format('YYYY-MM-DD')}
        onChange={e => setEndDate(e.target.value)}
        value={endDate}
        type="date"
      />
      <div className="rowEnd fullWidth mb8">
        <Button
          onClick={onDone}
          className="mr8"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
        >
          Cancel
        </Button>
        <Button
          disabled={disabled}
          onClick={onSave}
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.Primary}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export interface DatePopover {
  openPopover: () => void;
}

function DatePillComponent({ cycle }: { cycle: Cycle }, ref: React.ForwardedRef<DatePopover>) {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const closePopover = React.useCallback(() => {
    setPopoverOpen(false);
  }, []);

  React.useImperativeHandle(ref, () => ({
    openPopover: () => {
      setPopoverOpen(true);
    },
  }));

  const completed = cycle.cycleStatus === CycleStatus.Stopped;

  const content = (
    <Pill textOnly size={MetadataSize.Medium} className="grayed" noBorder noHover={completed}>
      <span className="semiBold">
        {capitalize(
          renderDate(cycle.startDate, {
            ignoreYear: isSameYear(cycle.startDate, cycle.endDate, {
              timezone: 'Etc/GMT',
            }),
            timezone: 'Etc/GMT',
          })
        )}
      </span>{' '}
      &ndash;{' '}
      <span className="semiBold">
        {capitalize(renderDate(cycle.endDate, { compact: false, timezone: 'Etc/GMT' }))}
      </span>
    </Pill>
  );
  if (completed) {
    return content;
  }

  return (
    <Popover
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      content={<UpdateDate cycle={cycle} onDone={closePopover} />}
    >
      {content}
    </Popover>
  );
}

const DatePill = React.forwardRef(DatePillComponent);

function CycleCommandContext({
  cycleId,
  onEditTitle,
  onEditSummary,
  onEditDates,
}: {
  cycleId: string;
  onEditTitle?: () => void;
  onEditSummary?: () => void;
  onEditDates?: () => void;
}) {
  return (
    <>
      <CommandContext
        context={{ group: CommandGroup.Cycle, focusedCycleId: cycleId, cycleIds: [cycleId] }}
      />
      {onEditTitle && (
        <CustomCommand
          command={{
            id: `cycle-edit-title`,
            group: CommandGroup.Cycle,
            hotkey: editTitleKey,
            description: 'Edit title',
            // priority: 10,
            handler: onEditTitle,
          }}
        />
      )}
      {onEditSummary && (
        <CustomCommand
          command={{
            id: `cycle-edit-summary`,
            group: CommandGroup.Cycle,
            // hotkey: desc,
            description: 'Edit summary',
            // priority: 10,
            handler: onEditSummary,
          }}
        />
      )}
      {onEditDates && (
        <CustomCommand
          command={{
            id: `cycle-edit-dates`,
            group: CommandGroup.Cycle,
            // hotkey: editTitleKey,
            description: 'Edit dates',
            // priority: 10,
            handler: onEditDates,
          }}
        />
      )}
    </>
  );
}

function CycleScreenContents({ cycleId }: { cycleId: string }) {
  const organization = useOrganization();
  const cycle = useRecoilValue(cycleSelector(cycleId));
  const updateCycles = useUpdateCycles();
  const space = useSpace();
  const [currentTab, setCurrentTab] = useRecoilState(cycleTabState);
  const { total, completed } = useRecoilValue(cycleCompletedSelector({ cycleId }));
  const titleRef = React.useRef<TextAreaHandle>(null);
  const summaryRef = React.useRef<HTMLInputElement>(null);
  const dateRef = React.useRef<DatePopover | null>(null);
  const [summary, setSummary] = React.useState(cycle?.summary);
  const summaryValueRef = React.useRef(summary);
  summaryValueRef.current = summary;

  useNewEntityModalArgs({
    type: 'Issue',
    cycleId,
  });

  const onEditTitle = React.useCallback(() => {
    titleRef.current?.focus();
    titleRef.current?.moveSelectionToEnd();
  }, []);

  const onEditSummary = React.useCallback(() => {
    if (!cycle?.summary) {
      updateCycles([cycleId], { summary: '' });
    }
    setTimeout(() => summaryRef.current?.focus(), 10);
  }, [cycle?.summary]);

  React.useEffect(() => {
    if (summaryRef.current?.value !== cycle?.summary) {
      setSummary(cycle?.summary);
    }
  }, [setSummary, cycle?.summary]);

  useComponentDidMount(() => {
    // save summary on component unmount
    return () => {
      const value = summaryValueRef.current;
      if (value === cycle?.summary) {
        return;
      }
      if (value === '') {
        updateCycles([cycleId], { summary: null });
      } else {
        updateCycles([cycleId], { summary: value });
      }
    };
  });

  const onEditDates = React.useCallback(() => {
    setTimeout(() => dateRef?.current?.openPopover(), 10);
  }, []);
  const tabs = React.useMemo(() => {
    return [
      {
        id: 'work',
        icon: 'list_view',
        name: `${capitalize(issueTerm)}s`,
      },
      {
        id: 'summary',
        icon: 'activity',
        name: 'Activity summary',
      },
      {
        id: 'notes',
        icon: 'snippet',
        name: 'Notes',
      },
    ];
  }, []);

  if (!cycle) {
    return null;
  }

  return (
    <Screen>
      <CycleCommandContext
        cycleId={cycleId}
        onEditTitle={onEditTitle}
        onEditSummary={onEditSummary}
        onEditDates={onEditDates}
      />
      <Hotkey
        hotkey={cycleNotesKey}
        handler={() => {
          setCurrentTab(currentTab === 'work' ? 'notes' : 'work');
        }}
      />
      <div className={cn(styles.cycleScreen)}>
        <TitleSetter
          title={`${organization.name} · ${space.name} · ${space.key}-${cycle.number}`}
        />
        <div className={styles.cycleMainContainer}>
          <div className={styles.cycleMainWrapper}>
            <CycleBreadcrums cycle={cycle} />
            <div
              className={cn(styles.cycleMain, {
                // FIXME: only apply style to notes because it breaks react-beautiful-dnd
                [styles.notesTab]: currentTab === 'notes',
              })}
            >
              <div className={styles.cycleMainContents}>
                <div className={styles.cycleHeader}>
                  <div className="row gap16 mb6 flexWrap">
                    <div className="row gap24">
                      <div className="row gap8">
                        <Icon className="white" icon={cycleStatusToIcon(cycle.cycleStatus)} />
                        <Title ref={titleRef} key={`title-${cycleId}`} cycle={cycle} />
                      </div>
                      <CycleStatusLabel size={MetadataSize.Medium} cycleId={cycleId} />
                    </div>
                    <div className="row gap8">
                      <DatePill ref={dateRef} cycle={cycle} />
                      <Pill textOnly size={MetadataSize.Medium} className="grayed" noBorder noHover>
                        <span className="semiBold">
                          {completed}/{total}
                        </span>{' '}
                        completed
                      </Pill>
                      <CycleMenuButton
                        className={'ml4'}
                        onEditSummary={onEditSummary}
                        onEditTitle={onEditTitle}
                        onEditDates={onEditDates}
                        cycleId={cycleId}
                      />
                    </div>
                  </div>
                  <div className={styles.cycleMetadata}>
                    {cycle.summary === null && (
                      <Pill
                        onClick={onEditSummary}
                        size={MetadataSize.Medium}
                        className="grayed"
                        noBorder
                      >
                        <Icon icon="desc" />
                        Add description
                      </Pill>
                    )}
                    {cycle.summary !== null && (
                      <div className={styles.summaryWrapper}>
                        <TextInput
                          placeholder="Add description"
                          ref={summaryRef}
                          className={styles.summaryInput}
                          onBlur={e => {
                            const value = e.target.value;
                            if (value === '') {
                              updateCycles([cycleId], { summary: null });
                            } else {
                              updateCycles([cycleId], { summary: value });
                            }
                          }}
                          onKeyDown={e => {
                            if (e.key === 'Escape' || e.key === 'Enter') {
                              e.preventDefault();
                              e.stopPropagation();
                              summaryRef.current?.blur();
                            }
                          }}
                          onChange={e => setSummary(e.target.value)}
                          inputType={TextInputType.Bare}
                          inputSize={TextInputSize.Small}
                          autoFocus={cycle.summary === ''}
                          value={summary ?? ''}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.tabWrapper}>
                    <Tabs
                      className={styles.cycleTabs}
                      tabStyle={TabStyle.Subtle}
                      tabs={tabs}
                      currentTab={currentTab}
                      onTabChanged={setCurrentTab}
                    />
                  </div>
                </div>
                {currentTab === 'work' && <WorkItemView cycleId={cycleId} />}
                {currentTab === 'summary' && <SummaryView cycleId={cycleId} />}
                {currentTab === 'notes' && <NotesView key={cycleId} cycleId={cycleId} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
}

export function CycleScreen(props: RouteComponentProps<RouteParams>) {
  const organization = useOrganization();
  const space = useSpace();
  const { match } = props;
  const { cycleNumber } = match.params;
  const cycleId = useRecoilValue(cycleIdByNumberSelector({ spaceId: space.id, cycleNumber }));

  React.useEffect(() => trackerPageLoad('Cycle'));
  const organizationLoaded = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  if (!organizationLoaded) {
    return <LoadingScreen />;
  }

  if (!cycleId) {
    return <NotFoundScreen />;
  }

  return <CycleScreenContents cycleId={cycleId} />;
}
