import cn from 'classnames';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import {
  Initiative,
  Issue,
  IssueStatusType,
  Release,
  ReleaseStatus,
} from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { ArchivedNotice } from '../../../components/archivedNotice';
import { CollaborativeDocEditor } from '../../../components/collaborativeDocEditor';
import { DescriptionHistory } from '../../../components/descriptionHistory';
import {
  DescriptionPlaceholder,
  PlaceholderType,
} from '../../../components/descriptionPlaceholder';
import { useGitBranchName } from '../../../components/gitBranch';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import { ActivitiesTab } from '../../../components/new/activities/activities';
import BackButton from '../../../components/new/backButton';
import {
  Breadcrumbs,
  TitleBreadcrumb,
  useSpaceBreadcrumb,
} from '../../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { ButtonGroup } from '../../../components/new/buttonGroup';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { NEW_COMMENT_ID } from '../../../components/new/comments';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
  useCopyGitBranchToClipboard,
  writeItemsToClipboard,
} from '../../../components/new/copyAndPaste';
import { CustomCommand } from '../../../components/new/customCommand';
import { EntityDueDate } from '../../../components/new/dueDateMetadata';
import {
  EntityCycleIndicator,
  EntityEffort,
  EntityImpact,
  EntityInsights,
  EntityLabels,
  EntityMembers,
  InsightsTab,
} from '../../../components/new/entityMetadata';
import {
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS,
  EntityScreenKeyNavHacks,
} from '../../../components/new/entityScreenKeyNavHacks';
import {
  QueryParameterManager,
  useEntityTabs,
  useInitialTab,
} from '../../../components/new/entityTabs';
import { EntityTitleEditor } from '../../../components/new/entityTitleEditor';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  FocusReason,
  KeyNavigationProvider,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import { LoadingSpinner } from '../../../components/new/loadingSpinner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import { AvatarSize } from '../../../components/new/metadata/avatar';
import { InitiativeIcon } from '../../../components/new/metadata/initiative';
import { MetadataSize } from '../../../components/new/metadata/size';
import {
  InitiativePicker,
  workItemsToInitiativePickerState,
} from '../../../components/new/pickers/initiativePicker';
import {
  issuesToReleasePickerState,
  ReleasePicker,
} from '../../../components/new/pickers/releasePicker';
import Popover from '../../../components/new/popover';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { StatusIndicator } from '../../../components/new/statusIndicator';
import { Tab, Tabs, TabStyle } from '../../../components/new/tabs';
import { TodoList } from '../../../components/new/todoList';
import { MetadataTooltip, Tooltip } from '../../../components/new/tooltip';
import { WorkItemDependencies } from '../../../components/new/workItemDependencies';
import { WorkItemMenuButton } from '../../../components/new/workItemMenu';
import {
  WorkItemCodeReviewRequests,
  WorkItemInitiativeTooltip,
  WorkItemReleaseTooltip,
} from '../../../components/new/workItemMetadata';
import { RightBar } from '../../../components/rightBar';
import { Screen } from '../../../components/screen';
import { WorkItemSharingPopover } from '../../../components/sharing';
import TitleSetter from '../../../components/titleSetter';
import { useConfiguration } from '../../../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { useModelManager } from '../../../graphql/modelManager';
import { ResponsiveDesignSize, useIsMediumScreen } from '../../../hooks/useResponsiveDesign';
import useSaveScroll from '../../../hooks/useSaveScroll';
import { useNextPreviousSiblings } from '../../../hooks/useSiblingEntities';
import { TextAreaHandle } from '../../../slate/textArea';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../../syncEngine/actions/entities';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../../syncEngine/actions/intiatives';
import {
  useDeleteIssuesContext,
  useMoveIssues,
  useUpdateIssues,
} from '../../../syncEngine/actions/issues';
import {
  useAddIssuesToRelease,
  useRemoveIssuesFromRelease,
} from '../../../syncEngine/actions/releases';
import { collaborativeDocIdByEntitySelector } from '../../../syncEngine/selectors/collaborativeDoc';
import {
  breadcrumbsForEntitySelector,
  useEntityPath,
  useUpdateRecents,
} from '../../../syncEngine/selectors/entities';
import {
  initiativesForIssueSelector,
  initiativesForOrganizationSelector,
} from '../../../syncEngine/selectors/intiatives';
import {
  defaultIssueBackPathSelector,
  issueArchivedSelector,
  issuePartialSelector,
  issueSelector,
  statusForIssueSelector,
} from '../../../syncEngine/selectors/issues';
import {
  releasesForIssueSelector,
  releasesForOrganizationSelector,
} from '../../../syncEngine/selectors/releases';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { trackerPageLoad } from '../../../tracker';
import {
  copyIssueGitBranchKey,
  copyIssueLinkKey,
  copyIssueNumberKey,
  mainComboKey,
  vimDown,
  vimUp,
} from '../../../utils/config';
import { LocationState } from '../../../utils/history';
import { HistoryCheckpoint } from '../../../utils/revisions';
import { StarredType } from '../../../utils/starred';
import styles from './workItemScreen.module.scss';

function InitiativeButton({ initiative, issue }: { initiative: Initiative; issue: Issue }) {
  const [hoverOpen, setHoverOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const organization = useOrganization();
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addIssuesToInitiative = useAddIssuesToInitiatives();
  const removeIssuesFromInitaitve = useRemoveIssuesFromInitiatives();

  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  const [removeSelf, setRemoveSelf] = React.useState(false);
  const removeSelfRef = React.useRef(false);
  const initiatives = useRecoilValue(initiativesForOrganizationSelector(organization.id));

  const state = workItemsToInitiativePickerState([issue], initiatives);
  if (removeSelf) {
    state[issue.id] = state[issue.id].filter(t => t !== initiative.id);
  }

  return (
    <MetadataTooltip
      disabled={menuOpen}
      open={hoverOpen}
      side="bottom"
      align="start"
      className="followTooltip"
      onOpenChange={setHoverOpen}
      content={<WorkItemInitiativeTooltip initiative={initiative} />}
    >
      <div>
        <DropdownMenu
          open={menuOpen}
          onOpenChange={open => {
            setMenuOpen(open);
            if (open === false && removeSelfRef.current) {
              removeIssuesFromInitaitve([initiative.id], [issue.id]);
            }
          }}
        >
          <div
            className={cn(styles.initiativesButtonGroup, styles.headerMetadata, {
              [styles.hidden]: removeSelf,
            })}
          >
            <DropdownMenuTrigger
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              asChild
            >
              <Button
                buttonStyle={ButtonStyle.Secondary}
                icon={<InitiativeIcon color={initiative.color} />}
                className={styles.initiativeButton}
              >
                <span className="ellipsis">{initiative.title}</span>
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="start"
            className="menuHuge"
          >
            <InitiativePicker
              state={state}
              onInitiativeAdded={(issueIds, initiativeId) => {
                if (initiativeId === initiative.id) {
                  setRemoveSelf(false);
                  removeSelfRef.current = false;
                } else {
                  addIssuesToInitiative([initiativeId], issueIds);
                }
              }}
              onInitiativeRemoved={(issueIds, initiativeId) => {
                if (initiativeId === initiative.id) {
                  setRemoveSelf(true);
                  removeSelfRef.current = true;
                } else {
                  removeIssuesFromInitaitve([initiativeId], issueIds);
                }
              }}
              onDone={() => {
                if (removeSelfRef.current) {
                  removeIssuesFromInitaitve([initiative.id], [issue.id]);
                }
                closeMenu();
              }}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </MetadataTooltip>
  );
}

function InitiativeMetadata({ issue }: { issue: Issue }) {
  const initiatives = useRecoilValue(initiativesForIssueSelector(issue.id));
  return (
    <>
      {initiatives.map(i => (
        <InitiativeButton key={i.id} initiative={i} issue={issue} />
      ))}
    </>
  );
}

function ReleaseButton({ release, issue }: { release: Release; issue: Issue }) {
  const [hoverOpen, setHoverOpen] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const organization = useOrganization();
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addIssuesToRelease = useAddIssuesToRelease();
  const removeIssuesFromRelease = useRemoveIssuesFromRelease();

  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  const [removeSelf, setRemoveSelf] = React.useState(false);
  const removeSelfRef = React.useRef(false);
  const releases = useRecoilValue(releasesForOrganizationSelector(organization.id));

  const state = issuesToReleasePickerState([issue], releases);
  if (removeSelf) {
    state[issue.id] = state[issue.id].filter(t => t !== release.id);
  }

  return (
    <MetadataTooltip
      disabled={menuOpen}
      open={hoverOpen}
      side="bottom"
      align="start"
      className="followTooltip"
      onOpenChange={setHoverOpen}
      content={<WorkItemReleaseTooltip release={release} />}
    >
      <div>
        <DropdownMenu
          open={menuOpen}
          onOpenChange={open => {
            setMenuOpen(open);
            if (open === false && removeSelfRef.current) {
              removeIssuesFromRelease([release.id], [issue.id]);
            }
          }}
        >
          <div
            className={cn(styles.initiativesButtonGroup, styles.headerMetadata, {
              [styles.hidden]: removeSelf,
            })}
          >
            <DropdownMenuTrigger
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              asChild
            >
              <Button
                buttonStyle={ButtonStyle.Secondary}
                icon={
                  <Icon
                    icon={release.releaseStatus === ReleaseStatus.Released ? 'released' : 'release'}
                  />
                }
                className={styles.initiativeButton}
              >
                <span className="ellipsis">{release.title}</span>
              </Button>
            </DropdownMenuTrigger>
          </div>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="start"
            className="menuHuge"
          >
            <ReleasePicker
              state={state}
              onReleaseAdded={(issueIds, releaseId) => {
                if (releaseId === release.id) {
                  setRemoveSelf(false);
                  removeSelfRef.current = false;
                } else {
                  addIssuesToRelease([releaseId], issueIds);
                }
              }}
              onReleaseRemoved={(issueIds, releaseId) => {
                if (releaseId === release.id) {
                  setRemoveSelf(true);
                  removeSelfRef.current = true;
                } else {
                  removeIssuesFromRelease([releaseId], issueIds);
                }
              }}
              onDone={() => {
                if (removeSelfRef.current) {
                  removeIssuesFromRelease([release.id], [issue.id]);
                }
                closeMenu();
              }}
            />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </MetadataTooltip>
  );
}

function ReleaseMetadata({ issue }: { issue: Issue }) {
  const releases = useRecoilValue(releasesForIssueSelector(issue.id));
  return (
    <>
      {releases.map(r => (
        <ReleaseButton key={r.id} release={r} issue={issue} />
      ))}
    </>
  );
}

function Context({ issueId }: { issueId: string }) {
  const defaultPath = useRecoilValue(defaultIssueBackPathSelector(issueId));
  const history = useHistory();
  const { previousEntity, nextEntity } = useNextPreviousSiblings(issueId);
  useDeleteIssuesContext({
    onDelete: () => {
      if (previousEntity()) {
        return;
      }

      if (nextEntity()) {
        return;
      }

      history.push(defaultPath);
    },
  });

  return (
    <CommandContext
      context={{ group: CommandGroup.Entities, entityIds: [issueId], focusedEntityId: issueId }}
    />
  );
}

function LeftHeader({ issue }: { issue: Issue }) {
  const location = useLocation<LocationState>();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(issue.id));
  const defaultPath = useRecoilValue(defaultIssueBackPathSelector(issue.id));
  const space = useSpace();
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const entityPath = useEntityPath();
  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const { host } = useConfiguration();
  const branchName = useGitBranchName(issue.id);
  const copyGitBranch = useCopyGitBranchToClipboard(issue.id);
  const { previousEntity, nextEntity, totalCount, position } = useNextPreviousSiblings(issue.id);

  if (!entityBreadcrumbs) {
    return null;
  }

  const rightSideContent = (
    <div className="row metadataGapL">
      <Tooltip
        content={
          <>
            {`Copy number`}
            <KeyboardShortcut shortcut={copyIssueNumberKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="copy"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            copyEntityNumber([issue.id]);
          }}
        />
      </Tooltip>
      <Tooltip
        content={
          <>
            {`Copy link`}
            <KeyboardShortcut shortcut={copyIssueLinkKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="link"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            copyEntityLink([issue.id]);
          }}
        />
      </Tooltip>
      {branchName && (
        <Tooltip
          content={
            <>
              {`Copy git branch name`}
              <KeyboardShortcut shortcut={copyIssueGitBranchKey} />
            </>
          }
        >
          <IconButton
            buttonStyle={ButtonStyle.BareSubtle}
            icon="branch"
            onClick={e => {
              e?.preventDefault();
              e?.stopPropagation();
              if (branchName) {
                copyGitBranch();
              }
            }}
          />
        </Tooltip>
      )}
      {issue.public && (
        <Popover
          contentOptions={{ align: 'end' }}
          content={<WorkItemSharingPopover issue={issue} />}
        >
          <Button buttonStyle={ButtonStyle.SecondarySubtle} icon="public">
            Public
          </Button>
        </Popover>
      )}
    </div>
  );

  return (
    <ScreenHeader
      className={styles.header}
      hideNotifications
      compensateForMacOSTrafficLights="always"
      rightSideContent={rightSideContent}
    >
      <div className="row headerGap ellipsis pb4 pt4">
        <BackButton defaultPath={defaultPath} />
        <div className="row metadataGapL">
          <ButtonGroup>
            <Tooltip
              content={
                <>
                  {`Previous ${issueTerm}`} <KeyboardShortcut shortcut={vimUp} />
                </>
              }
            >
              <IconButton disabled={position === 0} icon={'arrow_up'} onClick={previousEntity} />
            </Tooltip>
            <Tooltip
              content={
                <>
                  {`Next ${issueTerm}`} <KeyboardShortcut shortcut={vimDown} />
                </>
              }
            >
              <IconButton
                disabled={position === totalCount - 1}
                icon={'arrow_down'}
                onClick={nextEntity}
              />
            </Tooltip>
          </ButtonGroup>

          <CustomCommand
            command={{
              id: 'previous-issue',
              icon: 'arrow_up',
              description: `Up`,
              aliases: ['navigate', 'move to', 'go to'],
              handler: () => previousEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimUp,
              priority: 20,
            }}
          />

          <CustomCommand
            command={{
              id: 'next-issue',
              icon: 'arrow_down',
              description: `Down`,
              aliases: ['navigate', 'move to', 'go to'],
              handler: () => nextEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimDown,
              priority: 20,
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+c`}
            handler={e => {
              const selection = window.getSelection();
              if (selection && !selection.isCollapsed) {
                return;
              }
              e?.preventDefault();
              e?.stopPropagation();
              writeItemsToClipboard(
                [
                  {
                    id: issue.id,
                    shortName: `${space?.key}-${issue.number}`,
                    name: issue.title,
                    url: entityPath(issue.id),
                    type: 'work item',
                  },
                ],
                host
              );
            }}
          />

          <div className={styles.count}>
            {position + 1}
            <span className="mr2 ml2">/</span>
            {totalCount}
          </div>
        </div>
        <Breadcrumbs
          breadcrumbs={[
            ...(location.state?.breadcrumbs ?? [...spaceBreadcrumb, ...entityBreadcrumbs]),
            {
              name: <TitleBreadcrumb number={`${space.key}-${issue.number}`} title={issue.title} />,
            },
          ]}
          starred={{ type: StarredType.WorkItem, id: issue.id }}
        />
      </div>
    </ScreenHeader>
  );
}

function RightHeader({
  tabs,
  currentTab,
  onTabChanged,
}: {
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  return (
    <ScreenHeader className={styles.header}>
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function SmallScreenHeader({
  issue,
  tabs,
  currentTab,
  onTabChanged,
}: {
  issue: Issue;
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(issue.id));
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const defaultPath = useRecoilValue(defaultIssueBackPathSelector(issue.id));
  const space = useSpace();
  const location = useLocation<LocationState>();

  if (!entityBreadcrumbs) {
    return null;
  }

  return (
    <>
      <ScreenHeader className={styles.header} hideNotifications>
        <BackButton defaultPath={defaultPath} className="mr8" />
        <Breadcrumbs
          breadcrumbs={[
            ...(location.state?.breadcrumbs ?? [...spaceBreadcrumb, ...entityBreadcrumbs]),
            { name: `${space.key}-${issue.number}` },
          ]}
        />
      </ScreenHeader>
      <div className={styles.smallScreenHeader}>
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </>
  );
}

function Title({ issueId }: { issueId: string }) {
  const updateIssues = useUpdateIssues();
  const addLabels = useAddLabelsToEntities();
  const addMembers = useAddAssigneesToEntities();
  const addInitiatives = useAddIssuesToInitiatives();
  const issue = useRecoilValue(issueSelector(issueId));
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  const editTitle = React.useCallback(() => {
    updateIssues([issueId], { title });
    setTitle('');
    setEditing(false);
  }, [title, setTitle, setEditing, updateIssues, issueId]);

  if (!issue) {
    return null;
  }

  if (!editing) {
    return (
      <div
        className="heading2XL bold fullWidth fs-exclude wrap"
        onMouseDown={() => {
          setTitle(issue.title);
          setEditing(true);
        }}
      >
        <CustomCommand
          command={{
            id: 'edit-title',
            group: CommandGroup.Entities,
            description: 'Edit title',
            hotkey: 't',
            priority: 99,
            handler: () => {
              setTitle(issue.title);
              setEditing(true);
            },
          }}
        />
        {issue.title || 'Untitled work item'}
      </div>
    );
  }

  return (
    <div className="row relative fullWidth fs-exclude">
      <EntityTitleEditor
        className="heading2XL bold noShrink"
        initialTitle={issue.title}
        onChange={v => {
          setTitle(v);
        }}
        autoFocus
        onSubmit={editTitle}
        onFocus={() => {
          disableKeyNav('title');
        }}
        onBlur={() => {
          enableKeyNav('title');
          editTitle();
        }}
        supportedMetadata={{
          initiatives: true,
          labels: true,
          users: true,
        }}
        onMetadataAdded={(type, id) => {
          switch (type) {
            case 'label':
              addLabels([issueId], [id]);
              break;
            case 'user':
              addMembers([issueId], [id]);
              break;
            case 'initiative':
              addInitiatives([id], [issueId]);
              break;
          }
        }}
        placeholder="Give your work item a title"
      />
    </div>
  );
}

function Description({ issueId }: { issueId: string }) {
  const issuePartial = useRecoilValue(issuePartialSelector(issueId));
  const currentStatus = useRecoilValue(statusForIssueSelector(issueId));
  const [descriptionDisabled, setDescriptionDisabled] = React.useState(false);
  const [historyDescription, setHistoryDescription] = React.useState<HistoryCheckpoint | null>(
    null
  );
  const ref = React.useRef<TextAreaHandle>(null);
  const documentId = useRecoilValue(collaborativeDocIdByEntitySelector(issueId));

  return (
    <>
      {!issuePartial && (
        <>
          <CustomCommand
            command={{
              id: 'edit-description',
              group: CommandGroup.Entities,
              description: 'Edit description',
              hotkey: 'd',
              priority: 99,
              handler: () => {
                ref.current?.focus();
                ref.current?.moveSelectionToEnd();
                ref.current?.ensureBottomMarginVisible();
              },
            }}
          />
          <CollaborativeDocEditor
            key={`description-${issueId}`}
            className={cn(styles.description, 'fs-exclude')}
            documentId={documentId ?? ''}
            entityId={issueId}
            inlineComments
            historyDescription={historyDescription}
            textAreaRef={ref}
            disabled={descriptionDisabled || currentStatus?.statusType === IssueStatusType.Archived}
            placeholder={<DescriptionPlaceholder type={PlaceholderType.Issue} />}
          />
        </>
      )}
      {issuePartial && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      )}
      <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
        <DescriptionHistory
          documentId={documentId ?? ''}
          onDescriptionChanged={d => {
            setHistoryDescription(d);
          }}
          onRestore={d => {
            setHistoryDescription(null);
            setTimeout(() => ref.current?.replaceValue(d.content));
          }}
          onOpen={() => {
            setDescriptionDisabled(true);
          }}
          onClose={() => {
            setDescriptionDisabled(false);
          }}
        />
      </HideIfSmallerThan>
    </>
  );
}

function MainScrollArea({ children, issueId }: { children: React.ReactNode; issueId: string }) {
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationColumn(`left-${issueId}`, [`main-${issueId}`]);
  const selected = useHasKeyNavigationFocus(`main-${issueId}`);
  const setFocus = useSetKeyNavigationFocus();

  useSaveScroll(issueId, ref);

  React.useEffect(() => {
    if (selected && document.activeElement === document.body) {
      ref.current?.focus();
    }
  }, [selected]);

  React.useEffect(() => {
    setFocus(`main-${issueId}`);
  }, [issueId]);

  return (
    <div className="rowStretch grow overflowScrollY fullWidth" ref={ref} tabIndex={-1}>
      {children}
      {selected && (
        <Hotkey
          hotkey="right"
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setFocus(NEW_COMMENT_ID, FocusReason.Keyboard);
          }}
        />
      )}
    </div>
  );
}

function WorkItemScreen({ issueId }: { issueId: string }) {
  const issue = useRecoilValue(issueSelector(issueId));
  const isArchived = useRecoilValue(issueArchivedSelector(issueId));
  const modelManager = useModelManager();
  const modals = useModals();

  const organization = useOrganization();
  const space = useRecoilValue(spaceSelector(issue?.spaceId));
  const moveIssues = useMoveIssues();
  const mediumScreen = useIsMediumScreen();

  const [openCommentThreadId, setOpenCommentThreadId] = React.useState<string | null>(null);
  const initialTab = useInitialTab();
  const [currentTab, setCurrentTab] = React.useState(initialTab);
  const tabs = useEntityTabs(issueId);

  useUpdateRecents(issueId);

  React.useEffect(() => {
    trackerPageLoad('WorkItem');
  }, [issueId]);

  React.useEffect(() => {
    if (issue?.partial) {
      (async () => {
        await modelManager.fetchIssue(issue.id);
      })();
    }
  }, [issue, modelManager]);

  if (!space || !issue || !organization) {
    return null;
  }

  return (
    <KeyNavigationProvider
      columnIds={[`left-${issueId}`, ...ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS, `right-${issueId}`]}
    >
      <Screen hideSidebar row={!mediumScreen} className={styles.workItemScreen}>
        <TitleSetter title={`${organization.name} · ${space.name} · ${issue.title}`} />
        <Context issueId={issueId} />
        {mediumScreen && (
          <SmallScreenHeader
            tabs={tabs}
            currentTab={currentTab}
            onTabChanged={setCurrentTab}
            issue={issue}
          />
        )}
        <EntityScreenKeyNavHacks />

        {(!mediumScreen || currentTab === 'description') && (
          <div className={cn(styles.left)}>
            {!mediumScreen && <LeftHeader issue={issue} />}
            {isArchived && (
              <ArchivedNotice
                className="fullWidth"
                type={issueTerm}
                onUnarchive={() =>
                  modals.openModal(Modals.CommandMenu, {
                    view: CommandMenuView.ChangeStatus,
                    context: {
                      entityIds: [issueId],
                      spaceId: issue.spaceId,
                    },
                  })
                }
              />
            )}

            <MainScrollArea issueId={issueId} key={issueId}>
              <div className={styles.gutter} />
              <div className={styles.content}>
                <div className={cn('col', 'noGrow', styles.contentHeader)}>
                  <div className="row rowBetween fullWidth hideWhilePrinting">
                    <div className={cn('row', 'flexWrap', styles.metadataGap)}>
                      {!isArchived && (
                        <StatusIndicator
                          onPicked={(issueIds, statusId) => {
                            moveIssues(issueIds, statusId);
                          }}
                          issueId={issue.id}
                          statusId={issue.statusId}
                          className={'mr12'}
                        />
                      )}
                      <InitiativeMetadata issue={issue} />
                      <ReleaseMetadata issue={issue} />
                    </div>
                    <div className="row metadataGapL noShrink flexAlignStart">
                      <EntityMembers
                        interactable
                        max={null}
                        entity={issue}
                        size={AvatarSize.Size24}
                      />
                      <WorkItemMenuButton item={issue} buttonStyle={ButtonStyle.Bare} />
                    </div>
                  </div>
                  <Title issueId={issueId} key={`title-${issueId}`} />
                  <div className="row metadataGapL maxWidth flexWrap hideWhilePrinting">
                    <EntityDueDate interactable size={MetadataSize.Medium} item={issue} />
                    <EntityImpact interactable entity={issue} size={MetadataSize.Medium} />
                    <EntityEffort interactable entity={issue} size={MetadataSize.Medium} />
                    <WorkItemCodeReviewRequests
                      interactable
                      size={MetadataSize.Medium}
                      id={issue.id}
                    />
                    <WorkItemDependencies interactable size={MetadataSize.Medium} id={issue.id} />
                    <EntityInsights interactable size={MetadataSize.Medium} entity={issue} />
                    <EntityCycleIndicator interactable size={MetadataSize.Medium} entity={issue} />
                    <EntityLabels interactable entity={issue} size={MetadataSize.Medium} />
                  </div>
                </div>
                <Description issueId={issueId} key={`description-${issueId}`} />
              </div>
              <div className={styles.gutter} />
            </MainScrollArea>
          </div>
        )}
        {(currentTab === 'activities' || currentTab === 'insights' || currentTab === 'todos') && (
          <RightBar className={styles.right}>
            {!mediumScreen && (
              <RightHeader tabs={tabs} currentTab={currentTab} onTabChanged={setCurrentTab} />
            )}
            {currentTab === 'activities' && (
              <ActivitiesTab entityId={issueId} openCommentThreadId={openCommentThreadId} />
            )}
            {currentTab === 'insights' && <InsightsTab entityId={issueId} entityType={issueTerm} />}
            {currentTab === 'todos' && (
              <TodoList
                entityId={issueId}
                className={styles.todoTab}
                keyNavColumnId={`right-${issueId}`}
              />
            )}
          </RightBar>
        )}
        <QueryParameterManager
          onOpenThreadIdChanged={threadId => {
            if (threadId && threadId !== openCommentThreadId) {
              setCurrentTab('activities');
            }
            setOpenCommentThreadId(threadId);
          }}
          onCommentOrActivityIdChanged={commentOrActivityId => {
            if (commentOrActivityId) {
              setCurrentTab('activities');
            }
          }}
          onInsightIdChanged={insightId => {
            if (insightId) {
              setCurrentTab('insights');
            }
          }}
        />
      </Screen>
    </KeyNavigationProvider>
  );
}

export default React.memo(WorkItemScreen);
