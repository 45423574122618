import { padStart, random, sampleSize, sortBy } from 'lodash';
import { randomString } from './random';
export const customEpoch = new Date(2019, 3, 5, 16, 24, 33).getTime();

const hostComponent = random(0, Math.pow(2, 14) - 1) << 10;
let increment = 0;
let lastTimestamp = 0;

// IDs are 8 bytes (64 bits):
// timestamp relative to custom epoch - 40 bits
// random host specific component - 14 bits
// incrementing counter per millescond - 10 bits
export function generateId(): string {
  const timestamp = new Date().getTime();
  if (timestamp !== lastTimestamp) {
    lastTimestamp = timestamp;
    increment = 0;
  }
  const timestampComponent = padStart((timestamp - customEpoch).toString(16), 10, '0');
  const hostAndIncrementComponent = padStart((hostComponent + increment).toString(16), 6, '0');
  increment += 1;
  return `${timestampComponent}${hostAndIncrementComponent}`;
}

export function generateLookupKey(): string {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
  return sampleSize(chars, 6).join('');
}

export function looksLikeId(maybeId: string): boolean {
  return maybeId.length === 16;
}

export const validKey = /^[a-zA-Z0-9]{2,4}$/;

export function generateKey(name: string, spaceKeys: string[]) {
  let key = name
    .replace(/[^a-z0-9]/gim, '')
    .slice(0, 3)
    .toUpperCase()
    .trim();

  if (!key) {
    key = randomString(3);
  }

  if (key.length < 2) {
    key = `${key}1`;
  }

  if (!spaceKeys.includes(key)) {
    return key;
  }
  // First try the easy case. Just add a number to the end
  for (let i = 1; i <= 9; i++) {
    const longerKey = `${key}${i}`;
    if (!spaceKeys.includes(longerKey)) {
      return longerKey;
    }
  }

  // That didn't work add double digits to the end
  for (let i = 10; i <= 99; i++) {
    const longerKey = `${key.substr(0, 2)}${i}`;
    if (!spaceKeys.includes(longerKey)) {
      return longerKey;
    }
  }

  // Oh god, make it stop
  for (let i = 100; i <= 999; i++) {
    const longerKey = `${key.substr(0, 2)}${i}`;
    if (!spaceKeys.includes(longerKey)) {
      return longerKey;
    }
  }

  throw Error('Unable to generate key');
}

export function incrementTodoKey(key: string): string {
  const lastChar = key[key.length - 1];
  const keyWithoutLastChar = key.substring(0, key.length - 1);
  if (lastChar !== 'Z') {
    const keyCode = lastChar.charCodeAt(0);
    return `${keyWithoutLastChar}${String.fromCharCode(keyCode + 1)}`;
  }

  if (key.length === 1) {
    return 'AA';
  }

  return `${incrementTodoKey(keyWithoutLastChar)}A`;
}

export function findMaxTodoKey(keys: string[]): string | null {
  if (!keys.length) {
    return null;
  }
  const sortedKeys = sortBy(
    keys,
    key => key.length,
    key => key
  );
  const maxKeyLength = sortedKeys.reduce(
    (result, key) => (key.length > result ? key.length : result),
    0
  );
  const maybeMaxKeys = sortedKeys.filter(key => key.length === maxKeyLength).sort();
  return maybeMaxKeys[maybeMaxKeys.length - 1];
}

export function generateTodoKey(keys: string[]): string {
  const maxKey = findMaxTodoKey(keys);
  if (!maxKey) {
    return 'A';
  }
  return incrementTodoKey(maxKey);
}
