import * as React from 'react';
import { Button, ButtonProperties, IconButtonTrigger } from './button';
import { ButtonGroup } from './buttonGroup';
import { DropdownMenu, DropdownMenuContent } from './menu/dropdownMenu';

function SegmentedButtonComponent(
  {
    children,
    icon,
    size,
    className,
    buttonStyle,
    dropdownContents,
    dropdownProps,
    ...buttonProps
  }: {
    children: string;
    icon?: React.ReactNode;
    dropdownContents: (closeMenu: () => void) => React.ReactNode;
    dropdownProps?: {
      side?: 'bottom' | 'top' | 'right' | 'left';
      align?: 'start' | 'end' | 'center';
    };
  } & ButtonProperties,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  return (
    <ButtonGroup className={className}>
      <Button icon={icon} buttonStyle={buttonStyle} size={size} {...buttonProps} ref={ref}>
        {children}
      </Button>
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <IconButtonTrigger buttonStyle={buttonStyle} size={size} icon={'arrow_down'} />
        <DropdownMenuContent
          onClick={e => {
            e.stopPropagation();
          }}
          side="bottom"
          align="center"
          className="menuMedium"
          {...dropdownProps}
        >
          {dropdownContents?.(closeMenu)}
        </DropdownMenuContent>
      </DropdownMenu>
    </ButtonGroup>
  );
}

export const SegmentedButton = React.forwardRef(SegmentedButtonComponent);
