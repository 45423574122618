import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useOrganization } from '../contexts/organizationContext';
import DeveloperModal from '../modals/developerModal';
import EnableCyclesModal from '../modals/enableCyclesModal';
import IssueLimitModal from '../modals/issueLimitModal';
import { currentUserMembershipState } from '../syncEngine/selectors/users';

export function SpaceCommandsAndModals() {
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));

  if (!orgMembership) {
    return null;
  }

  return (
    <>
      <IssueLimitModal />
      <DeveloperModal />
      <EnableCyclesModal />
    </>
  );
}
