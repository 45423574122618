import cn from 'classnames';
import * as React from 'react';
import { Element, Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { useIsCommentOpenInActivityFeed, useIsCommentOpenInline } from '../../utils/comments';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { hoverContext } from '../hovers';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { VoidElement } from '../types';
import { InsightHoverHandle, InsightWrapper } from './insight';
import styles from './voidBlock.module.scss';

export function VoidBlock({
  children,
  style,
  className,
  element,
  onClick,
  iframe,
  forceSelected,
}: {
  children: React.ReactNode | React.ReactNode[];
  element: Element & VoidElement;
  iframe?: boolean;
  forceSelected?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) {
  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const insightId = element.insightId;
  const hasInsights = !!insightId; // FIXME: use annotations for insights too
  const insightHoverRef = React.useRef<InsightHoverHandle>(null);

  const commentIds = (element.annotations ?? []).filter(a => a.type === 'comment').map(a => a.id);
  const hasComments = !!commentIds.length;
  const inlineCommentOpen = useIsCommentOpenInline(commentIds);
  const commentOpenInActivity = useIsCommentOpenInActivityFeed(commentIds);

  const collapsed = useSelectionCollapsed();
  const selected = useFocusedAndSelected();
  const hovers = React.useContext(hoverContext);
  const id = React.useRef(uuid.v4());

  return (
    <div
      contentEditable={false}
      className={styles.voidBlock}
      {...(commentIds.length ? { ['data-comment-ids']: commentIds.join(' ') } : {})}
    >
      <div
        contentEditable={false}
        className={cn(
          styles.content,
          {
            [styles.iframe]: iframe && !selected,
            [styles.selected]: selected || forceSelected,
            [styles.insight]: hasInsights,
            [styles.comment]: hasComments && !hasInsights,
            [styles.commentOpen]: inlineCommentOpen || commentOpenInActivity,
          },
          className
        )}
        style={style}
        onClick={e => {
          if (readonly || hovers.claimedById === `insight-${id.current}`) {
            return;
          }

          if (e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();

            if (selected) {
              return;
            }

            const path = ReactEditor.findPath(editor, element);
            KitemakerTransforms.expandSelectionToInclude(editor, path);
            return;
          }

          if (!collapsed || !selected) {
            e.preventDefault();
            e.stopPropagation();
            ReactEditor.focus(editor);
            Transforms.select(editor, ReactEditor.findPath(editor, element));
            return;
          }
          if (editor.isFocusing) {
            return;
          }
          onClick?.(e);
        }}
        onDragStart={e => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
        onMouseEnter={() => {
          insightHoverRef.current?.show();
        }}
        onMouseLeave={() => {
          insightHoverRef.current?.hide();
        }}
      >
        {/* If we have an insight or comment, we make a small div at the top of the void block to contain the popover since wrapping
          the whole block messes with its height/width */}
        {insightId && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <InsightWrapper insightId={insightId} handle={insightHoverRef} hoverId={id.current}>
              <div></div>
            </InsightWrapper>
          </div>
        )}
        {iframe && !selected && <div className={styles.iframeOverlay} />}
        {children}
      </div>
    </div>
  );
}

export function VoidInline({
  children,
  style,
  className,
  element,
  attributes,
  onClick,
}: {
  children: React.ReactNode | React.ReactNode[];
  element: Element & VoidElement;
} & React.HTMLAttributes<HTMLSpanElement> &
  Pick<RenderElementProps, 'attributes'>) {
  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const collapsed = useSelectionCollapsed();
  const selected = useFocusedAndSelected();

  return (
    <span contentEditable={false} className={styles.voidInline} {...attributes}>
      <span
        className={cn(
          styles.content,
          {
            [styles.selected]: selected,
          },
          className
        )}
        style={style}
        onClickCapture={e => {
          if (readonly) {
            onClick?.(e);
            return;
          }
          if (!collapsed || !selected) {
            e.preventDefault();
            e.stopPropagation();
            ReactEditor.focus(editor);
            Transforms.select(editor, ReactEditor.findPath(editor, element));
            return;
          }
          onClick?.(e);
        }}
      >
        {children}
      </span>
    </span>
  );
}

export function UnselectableVoidInline({
  children,
  style,
  className,
  element,
  attributes,
}: {
  children: React.ReactNode | React.ReactNode[];
  element: Element & VoidElement;
} & React.HTMLAttributes<HTMLSpanElement> &
  Pick<RenderElementProps, 'attributes'>) {
  const editor = useSlateStatic();
  const collapsed = useSelectionCollapsed();
  const selected = useFocusedAndSelected();

  React.useEffect(() => {
    // We sometimes end up with the collapsed focus, which is bad. Just move to the right
    // a tiny bit if that happens.
    if (selected && collapsed) {
      KitemakerTransforms.move(editor, { distance: 1, unit: 'offset' });
    }
  }, [selected, collapsed, editor]);

  return (
    <span contentEditable={false} className={cn(styles.voidInline, 'textCursor')} {...attributes}>
      <span
        className={cn(className, {
          [styles.textSelection]: selected && !collapsed,
        })}
        style={style}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          ReactEditor.focus(editor);
          Transforms.select(editor, ReactEditor.findPath(editor, element));
          Transforms.move(editor, { distance: 1, unit: 'offset' });
        }}
      >
        {children}
      </span>
    </span>
  );
}
