import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { SlackConfig } from '../../../../shared/slack';
import { Integration, IntegrationType } from '../../../../sync/__generated/models';
import { importEmojis, useReauthorize } from '../../../api/slack';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { Setting, SettingsPage } from '../../../components/new/settings';
import { TextInput } from '../../../components/new/textInput';
import { useConfirmation } from '../../../contexts/confirmationContext';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useUpdateOrganization } from '../../../syncEngine/actions/organizations';
import { actorNameSelector } from '../../../syncEngine/selectors/actors';
import {
  CustomEmoji,
  integrationsForOrganizationSelector,
} from '../../../syncEngine/selectors/organizations';
import { renderDate } from '../../../utils/datetime';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import styles from './settingsScreen.module.scss';

function EmojiRow({ emoji }: { emoji: CustomEmoji & { name: string } }) {
  const actor = useRecoilValue(actorNameSelector(emoji.actorId));
  const updateOrganization = useUpdateOrganization();
  const organization = useOrganization();
  const { confirm } = useConfirmation();

  return (
    <div className={cn(styles.emojiRow, 'tableRow')}>
      <div className={styles.image}>
        <img className={styles.image} src={emoji.url} />{' '}
      </div>
      <div className={cn(styles.name, 'semiBold')}>:{emoji.name}:</div>
      <div className={styles.added}>
        {capitalize(renderDate(emoji.timestamp, { daysAgo: true, hoursAndMinutesAgo: true }))}
      </div>
      <div className={styles.actor}>{actor}</div>
      <div className={styles.delete}>
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="delete"
          onClick={async () => {
            const newEmojis = { ...organization.customEmojis };
            delete newEmojis[emoji.name];

            const confirmed = await confirm(
              'Delete emoji',
              'Are you sure you want to delete this emoji?',
              { destructive: true }
            );
            if (!confirmed) {
              return;
            }
            updateOrganization({
              customEmojis: newEmojis,
            });
          }}
        />
      </div>
    </div>
  );
}

function SlackImport({ integration }: { integration: Integration }) {
  const config = React.useMemo(() => JSON.parse(integration.config) as SlackConfig, [integration]);
  const [importing, setImporting] = React.useState(false);
  const reauthorize = useReauthorize();
  const hasEmojiScope = React.useMemo(() => {
    return (config.scopes ?? []).includes('emoji:read');
  }, [config]);

  return (
    <Setting
      title={`Import icons from Slack team ${JSON.parse(integration.config).teamName}`}
      description={
        !hasEmojiScope
          ? 'You need to reauthorize your Slack integration in order to import emojis'
          : `Import all of your emojis from the Slack team you've connected.`
      }
    >
      <Button
        icon="slack"
        disabled={importing}
        onClick={async () => {
          if (!hasEmojiScope) {
            reauthorize();
            return;
          }
          setImporting(true);
          await importEmojis(integration.id, integration.type === IntegrationType.Slack);
          setImporting(false);
        }}
      >
        {hasEmojiScope ? 'Import from Slack' : 'Reauthorize'}
      </Button>
    </Setting>
  );
}

export function CustomEmojiSettings() {
  const organization = useOrganization();
  const [searchString, setSearchString] = React.useState('');
  const modalManager = useModals();
  const slackIntegrations = useRecoilValue(
    integrationsForOrganizationSelector(organization.id)
  ).filter(i => i.type === IntegrationType.Slack || i.type === IntegrationType.Slack2);

  const [allEmojis, setAllEmojis] = React.useState(() =>
    Object.keys(organization.customEmojis ?? {})
  );
  const [emojis, _setEmojis] = React.useState(() => allEmojis.sort());
  const setEmojis = (emojis: string[]) => {
    _setEmojis(emojis.sort());
  };

  const search = React.useMemo(() => {
    return new FuzzySearcher(
      FuzzySearcherConfiguration.Autocomplete,
      ['name'],
      allEmojis.map(e => ({ name: e }))
    );
  }, [allEmojis]);

  React.useEffect(() => {
    setAllEmojis(Object.keys(organization.customEmojis ?? {}));
  }, [organization.customEmojis]);

  React.useEffect(() => {
    if (!searchString) {
      setEmojis(allEmojis);
      return;
    }
    setEmojis(search.search(searchString).map(i => i.item.name));
  }, [search, searchString, allEmojis]);

  return (
    <SettingsPage
      title="Custom emojis"
      description={
        <>Add your own custom emojis to bring a little extra fun to your organization.</>
      }
    >
      {slackIntegrations.map(integration => (
        <SlackImport key={integration.id} integration={integration} />
      ))}
      <div className="rowStretch mb16">
        <Button
          className="mr16"
          buttonStyle={ButtonStyle.Primary}
          icon="add"
          onClick={() => {
            modalManager.openModal(Modals.NewCustomEmoji);
          }}
        >
          Add emoji
        </Button>
        <TextInput
          className={styles.searchInput}
          value={searchString}
          onChange={v => setSearchString(v.currentTarget.value)}
          placeholder="Filter emojis"
        />
      </div>

      {emojis.length > 0 && (
        <div className="tableish">
          <div className={cn('tableHeader', styles.emojiRow)}>
            <div className={styles.image}>Image</div>
            <div className={styles.name}>Name</div>
            <div className={styles.added}>Date added</div>
            <div className={styles.actor}>Added by</div>
            <div className={styles.delete} />
          </div>
          <div className="tableBody">
            {emojis.map(emoji => (
              <EmojiRow key={emoji} emoji={{ name: emoji, ...organization.customEmojis[emoji] }} />
            ))}
          </div>
        </div>
      )}
    </SettingsPage>
  );
}
