import { GraphQLError } from 'graphql';

export function statusFromGraphQLError(errors: GraphQLError[]): number | null {
  for (const error of errors) {
    if (error.extensions?.status) {
      return error.extensions?.status as number;
    }
  }

  return null;
}

export function codeFromGraphQLError(errors: GraphQLError[]): string | null {
  for (const error of errors) {
    if (error.extensions?.code) {
      return error.extensions?.code as string;
    }
  }

  return null;
}

export function detailFromGraphQLError(errors: GraphQLError[], detail: string): string | null {
  for (const error of errors) {
    if (error.extensions && error.extensions[detail]) {
      return error.extensions[detail] as string;
    }
  }

  return null;
}

export function messageFromGraphQLError(errors?: GraphQLError[]): string | null {
  if (!errors) {
    return null;
  }
  for (const error of errors) {
    if (error.message) {
      return error.message;
    }
  }

  return null;
}
