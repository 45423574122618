import { companiesByOrganization } from '../../../sync/__generated/indexes';
import { Company } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import {
  SyncEngineCreate,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { indexHelper } from './helpers';

export const NO_COMPANY_ID = 'noCompany';

export function useCreateCompany() {
  const modelManager = useModelManager();
  return (organizationId: string, name: string, domain: string | null): Company => {
    return modelManager.transaction((tx, getters) => {
      const existingCompanies = indexHelper<Company>(
        getters,
        companiesByOrganization,
        organizationId
      );
      const companyWithDomain = domain
        ? existingCompanies.find(c => c.domain?.toLowerCase() === domain.toLowerCase())
        : null;

      if (companyWithDomain) {
        throw Error(`There is already a company with the domain "${domain}"`);
      }

      const company: SyncEngineCreate<Company> = {
        __typename: 'Company',
        organizationId,
        name,
        domain,
        avatar: null,
      };
      return tx.create<Company>(company);
    });
  };
}

export function useUpdateCompanies() {
  const modelManager = useModelManager();
  return (companyIds: string[], update: SyncEngineUpdateWithoutDelete<Company>) => {
    modelManager.transaction((tx, { get }) => {
      for (const companyId of companyIds) {
        const company = get<Company>(companyId);
        if (!company || company.deleted) {
          continue;
        }
        tx.update<Company>(companyId, update);
        trackerEvent('Company Edited', {
          name: company.name,
        });
      }
    });
  };
}

export function useDeleteCompanies() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (companyIds: string[]) => {
    const confirmed = await confirm(
      `Delete company${companyIds.length > 1 ? 's' : ''}`,
      `Are you sure you want to delete ${
        companyIds.length > 1 ? 'these companies' : 'this company'
      }? There is no way to undo this operation`,
      { label: 'Delete', destructive: true }
    );

    if (!confirmed) {
      return [];
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get }) => {
      for (const companyId of companyIds) {
        const company = get<Company>(companyId);
        if (!company || company.deleted) {
          continue;
        }
        tx.update<Company>(companyId, {
          deleted: true,
        });

        deleted.push(companyId);
        trackerEvent('Company Deleted', {
          id: company.id,
        });
      }
    });

    return deleted;
  };
}
