import * as Dialog from '@radix-ui/react-dialog';
import cn from 'classnames';
import * as React from 'react';
import { Modals, useModals } from '../contexts/modalContext';
import Hotkey from './hotkey';
import { HotkeyScope } from './hotkeys';
import styles from './modal.module.scss';

interface ModalProps {
  modalId: Modals;
  children?: React.ReactNode;
  title?: React.ReactNode;
  hideHeader?: boolean;
  disableEscape?: boolean;
  disableInteractOutside?: boolean;
  onClose?: () => void;
  topAligned?: boolean;
  className?: string;
}

export function ModalContentWrapper({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={cn(styles.content, className)}>{children}</div>;
}

export function ModalButtonWrapper({ children }: { children: React.ReactNode }) {
  return <div className={styles.buttonRow}>{children}</div>;
}

function ModalInnerContents(props: {
  id: string;
  hideHeader?: boolean;
  children?: React.ReactNode;
  onRequestClose(): void;
  disableEscape?: boolean;
  title?: React.ReactNode;
}) {
  const { id, hideHeader, children, title, onRequestClose, disableEscape } = props;
  return (
    <div id={`modalContents-${id}`}>
      {!hideHeader && <div className="headingL mt32 mb12 ml32 mr32">{title}</div>}
      {!disableEscape && (
        <Hotkey
          command={{
            id: `close-modal-${id}`,
            hotkey: 'escape',
            priority: 22,
            global: true,
            handler: e => {
              e?.preventDefault();
              e?.stopPropagation();
              onRequestClose?.();
            },
          }}
        />
      )}
      <div>{children}</div>
    </div>
  );
}

export const ZIndexContext = React.createContext(0);

export function ModalContents({
  isOpen,
  onRequestClose,
  id,
  title,
  hideHeader,
  disableEscape,
  disableInteractOutside,
  children,
  className,
  depth,
  topAligned,
  zIndex = 1000,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string;
  className?: string;
  depth?: number;
  zIndex?: number;
} & Omit<ModalProps, 'modalId'>) {
  return (
    <ZIndexContext.Provider value={zIndex}>
      <Dialog.Root modal open={isOpen} onOpenChange={onRequestClose}>
        <Dialog.Portal>
          <Dialog.Overlay
            data-overlay={true}
            style={{ zIndex }}
            className={cn(styles.overlay, { [styles.darken]: id !== Modals.CommandMenu })}
          >
            <Dialog.Content
              className={cn(
                styles.modal,
                {
                  [styles.top]: topAligned,
                },
                className
              )}
              onOpenAutoFocus={e => {
                e.preventDefault();
              }}
              onFocusCapture={e => {
                // this allows dropdown menus to work in a modal
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Property 'role' does not exist on type
                if (!e.target.role?.includes('menu')) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onEscapeKeyDown={e => {
                e.preventDefault();
              }}
              onInteractOutside={e => {
                if (disableInteractOutside) {
                  e.preventDefault();
                }
              }}
              onPointerDownOutside={e => {
                if (disableInteractOutside) {
                  e.preventDefault();
                }
              }}
            >
              <HotkeyScope depth={depth ?? 10}>
                <ModalInnerContents
                  id={id}
                  hideHeader={hideHeader}
                  title={title}
                  onRequestClose={onRequestClose}
                  disableEscape={disableEscape}
                >
                  {children}
                </ModalInnerContents>
              </HotkeyScope>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </ZIndexContext.Provider>
  );
}

export default function Modal({ modalId, onClose, ...rest }: ModalProps) {
  const modalManager = useModals();
  const isOpen = modalManager.isModalOpen(modalId);
  const lastFocusedRef = React.useRef<any | null>(null);

  React.useEffect(() => {
    if (isOpen) {
      lastFocusedRef.current = document.activeElement;
    } else if (lastFocusedRef.current) {
      lastFocusedRef.current.focus?.();
    }
  }, [isOpen]);

  return (
    <ModalContents
      isOpen={isOpen}
      onRequestClose={() => {
        modalManager.closeModal(modalId);
        onClose?.();
      }}
      id={modalId}
      {...rest}
    />
  );
}
