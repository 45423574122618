import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DependencyType } from '../../../sync/__generated/models';
import {
  useAddDependenciesToIssues,
  useRemoveDependenciesFromIssues,
} from '../../syncEngine/actions/issues';
import { useEntityPath } from '../../syncEngine/selectors/entities';
import {
  dependencyIdsForIssueSelector,
  issueSelector,
  issuesResolvedSelector,
} from '../../syncEngine/selectors/issues';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import menuStyles from './menu/menu.module.scss';
import Dependency, { DependencyState } from './metadata/dependency';
import { MetadataSize } from './metadata/size';
import DependencyPickerWrapper from './pickers/dependencyPicker';
import { EntityItem } from './pickers/entityPicker';
import { MetadataTooltip } from './tooltip';
import styles from './workItemDependencies.module.scss';

export function WorkItemDependencies({
  id,
  size,
  interactable,
}: {
  id: string;
  size?: MetadataSize;
  interactable?: boolean;
}) {
  const { blocksIssueIds, blockedByIssueIds, enablesIssueIds, dependsOnIssueIds } = useRecoilValue(
    dependencyIdsForIssueSelector(id)
  );
  const blocksResolved = useRecoilValue(issuesResolvedSelector(blocksIssueIds));
  const blockedByResolved = useRecoilValue(issuesResolvedSelector(blockedByIssueIds));

  return (
    <>
      {blocksIssueIds.length > 0 && (
        <DependencyIcon
          size={size}
          issueId={id}
          relatedIds={blocksIssueIds}
          state={DependencyState.BLOCKS}
          type={'blocks'}
          property={'dependsOnId'}
          dependencyType={DependencyType.BlockedBy}
          resolved={blocksResolved}
          interactable={interactable}
        />
      )}
      {blockedByIssueIds.length > 0 && (
        <DependencyIcon
          size={size}
          issueId={id}
          relatedIds={blockedByIssueIds}
          state={DependencyState.BLOCKED}
          type={'blockedBy'}
          property={'enablesId'}
          dependencyType={DependencyType.BlockedBy}
          resolved={blockedByResolved}
          interactable={interactable}
        />
      )}
      {enablesIssueIds.length > 0 && (
        <DependencyIcon
          size={size}
          issueId={id}
          relatedIds={enablesIssueIds}
          state={DependencyState.ENABLES}
          type={'enables'}
          property={'dependsOnId'}
          dependencyType={DependencyType.DependsOn}
          interactable={interactable}
        />
      )}
      {dependsOnIssueIds.length > 0 && (
        <DependencyIcon
          size={size}
          issueId={id}
          relatedIds={dependsOnIssueIds}
          state={DependencyState.DEPENDS_ON}
          type={'dependsOn'}
          property={'enablesId'}
          dependencyType={DependencyType.DependsOn}
          interactable={interactable}
        />
      )}
    </>
  );
}

function DependencyTooltipContent({
  issueIds,
  type,
}: {
  issueIds: string[];

  type: 'enables' | 'dependsOn' | 'blocks' | 'blockedBy';
}) {
  const issuePath = useEntityPath();

  let label;
  switch (type) {
    case 'enables':
      label = 'Enables';
      break;
    case 'dependsOn':
      label = 'Depends on';
      break;
    case 'blocks':
      label = 'Blocks';
      break;
    case 'blockedBy':
      label = 'Blocked by';
      break;
  }
  return (
    <div
      className={styles.container}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <span className={styles.header}>{label}</span>
      {issueIds.map(issueId => {
        const link = {
          pathname: issuePath(issueId) ?? '',
          state: {
            backUrl: location.pathname,
            backSearch: location.search,
            entity: issueId,
          },
        };
        return (
          <Link className={cn('row', menuStyles.item, styles.item)} key={issueId} to={link}>
            <EntityItem disableTooltip entityId={issueId} />
          </Link>
        );
      })}
    </div>
  );
}

function DependencyIcon({
  issueId,
  relatedIds,
  state,
  type,
  dependencyType,
  property,
  size,
  resolved,
  interactable,
}: {
  issueId: string;
  relatedIds: string[];
  state: DependencyState;
  type: 'enables' | 'dependsOn' | 'blocks' | 'blockedBy';
  dependencyType: DependencyType;
  property: 'dependsOnId' | 'enablesId';
  size?: MetadataSize;
  resolved?: boolean;
  interactable?: boolean;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const issue = useRecoilValue(issueSelector(issueId));
  const addDepenencies = useAddDependenciesToIssues();
  const removeDependencies = useRemoveDependenciesFromIssues();

  if (!issue || relatedIds.length === 0) {
    return null;
  }

  const content = (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Dependency size={size} state={state} resolved={resolved} />
    </div>
  );

  if (!interactable) {
    return content;
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <MetadataTooltip
        side="bottom"
        align="start"
        content={<DependencyTooltipContent issueIds={relatedIds} type={type} />}
      >
        <DropdownMenuTrigger asChild>{content}</DropdownMenuTrigger>
      </MetadataTooltip>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuHuge"
      >
        <DependencyPickerWrapper
          issues={[issue]}
          dependencyType={type}
          onDependenciesAdded={(issueIds, toAdd) => {
            addDepenencies(issueIds, property, dependencyType, [toAdd]);
          }}
          onDependenciesRemoved={(issueIds, toRemove) => {
            removeDependencies(issueIds, property, dependencyType, [toRemove]);
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
