import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { RenderElementProps } from 'slate-react';
import { UserElement } from '../../../shared/slate/types';
import { useCurrentUser, useMaybeCurrentUser } from '../../contexts/userContext';
import { userSelector } from '../../syncEngine/selectors/users';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './userMention.module.scss';
import { VoidInline } from './voidBlock';

export function StaticUserMention({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: UserElement }) {
  const { userId } = element;
  const user = useRecoilValue(userSelector(userId));
  const currentUser = useMaybeCurrentUser();

  return (
    <span
      {...attributes}
      className={cn(styles.userMention, {
        [styles.highlighted]: currentUser?.id === user?.id,
      })}
    >
      @{user && !user.deleted ? user.username : 'unknown'}
      {children}
    </span>
  );
}

export function UserMention({
  attributes,
  element,
  children,
}: RenderElementProps & { element: UserElement }) {
  const { userId } = element;
  const user = useRecoilValue(userSelector(userId));
  const currentUser = useCurrentUser();

  return (
    <VoidInline
      element={element}
      attributes={attributes}
      className={cn(styles.userMention, styles.slate, {
        [styles.highlighted]: currentUser.id === user?.id,
      })}
    >
      @{user && !user.deleted ? user.username : 'unknown'}
      {children}
    </VoidInline>
  );
}
