import * as Sentry from '@sentry/browser';
import debugModule from 'debug';
import { Client as SubscriptionClient, createClient as createWSClient } from 'graphql-ws';
import * as React from 'react';
import { Provider, createClient, defaultExchanges, mapExchange, subscriptionExchange } from 'urql';
import { codeFromGraphQLError, detailFromGraphQLError } from '../graphql/errors';
import { clearCache } from '../graphql/offlineCache';
import { useConfiguration } from './configurationContext';

const debug = debugModule('graphql');

interface SubscriptionClientContext {
  subscriptionClient?: SubscriptionClient | null;
}
const subscriptionClientContext = React.createContext<SubscriptionClientContext>({
  subscriptionClient: null,
});

export function GraphQLProvider({ children }: { children: React.ReactNode }) {
  const { websocketToken, host } = useConfiguration();
  const url = `${host}/graphql`;

  const [client, subscriptionClient] = React.useMemo(() => {
    let pingTimeout = -1;

    const subscriptionClient = createWSClient({
      url: url.replace('http', 'ws'),
      keepAlive: 10000,
      connectionParams: {
        websocketToken,
      },
      lazy: false,
      // needed to get the client to automatically reconnect.
      // graphql-ws has it's own exponential backoff thing baked in
      shouldRetry: () => true,
      retryAttempts: Infinity,
      retryWait: async (retries: number) =>
        new Promise(resolve => setTimeout(resolve, Math.min(retries * 1000, 10000))),
      onNonLazyError: e => {
        let error = '';
        if (e instanceof Error) {
          error = e.message;
        } else if (e instanceof CloseEvent) {
          error = 'Retry attempts exceeded';
        }
        if (error) {
          Sentry.captureMessage('WebSocket lazy error', {
            extra: { error },
          });
        }
      },
      on: {
        ping: () => {
          if (pingTimeout !== -1) {
            window.clearTimeout(pingTimeout);
          }
          pingTimeout = window.setTimeout(() => {
            subscriptionClient.terminate();
          }, 3000);
        },
        pong: received => {
          if (received && pingTimeout !== -1) {
            window.clearTimeout(pingTimeout);
            pingTimeout = -1;
          }
        },
      },
    });

    subscriptionClient.on('error', e => {
      let error = '';
      if (e instanceof Error) {
        error = e.message;
      } else if (e instanceof CloseEvent) {
        error = 'Retry attempts exceeded';
      }
      if (error) {
        Sentry.captureMessage('WebSocket error', {
          extra: { error },
        });
      }
    });

    const client = createClient({
      url,
      requestPolicy: 'network-only',
      exchanges: [
        mapExchange({
          onError(error) {
            if (codeFromGraphQLError(error.graphQLErrors ?? []) === 'AUTHENTICATION_REQUIRED') {
              debug('Received AUTHENTICATION_REQUIRED. Redirecting to login');
              // if we get an auth error, just clear the cache and do a hard redirect back to the root.
              // That'll do all the right things in terms of forcing a login
              localStorage.clear();
              clearCache(true).then(() => {
                window.location.href = '/';
              });
            }
            if (codeFromGraphQLError(error.graphQLErrors ?? []) === 'REQUIRED_LOGIN_METHOD') {
              const method = detailFromGraphQLError(
                error.graphQLErrors ?? [],
                'requiredLoginMethod'
              );

              // if the org requires a particular login method, kick the user out and head back to the login screen
              localStorage.clear();
              clearCache(true).then(() => {
                window.location.href = `/auth/logout?requiredLoginMethod=${method}`;
              });
            }
          },
        }),
        ...defaultExchanges,
        subscriptionExchange({
          forwardSubscription: operation => ({
            subscribe: sink => ({
              unsubscribe: subscriptionClient.subscribe(operation, sink),
            }),
          }),
        }),
      ],
    });

    return [client, subscriptionClient];
  }, [websocketToken, url]);

  return (
    <Provider value={client}>
      <subscriptionClientContext.Provider value={{ subscriptionClient }}>
        {children}
      </subscriptionClientContext.Provider>
    </Provider>
  );
}

export function useSubscriptionClient(): SubscriptionClientContext {
  const context = React.useContext(subscriptionClientContext)!;
  return context;
}
