import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import cn from 'classnames';
import { LocationDescriptor } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonStyle } from './button';
import { Icon, IconSize } from './icon';
import styles from './tooltip.module.scss';

type TooltipProps = TooltipPrimitive.TooltipProps &
  TooltipPrimitive.TooltipContentProps & {
    content: string | React.ReactNode;
    disabled?: boolean;
    noPortal?: boolean;
  };

export function Tooltip({
  children,
  content,
  open,
  disabled,
  delayDuration,
  defaultOpen,
  onOpenChange,
  side = 'bottom',
  align,
  className,
  disableHoverableContent,
  noPortal,
  ...props
}: TooltipProps) {
  const tooltipDisabled = React.useContext(TooltipDisableContext);
  if (tooltipDisabled) {
    return <>{children}</>;
  }

  const Portal = noPortal ? React.Fragment : TooltipPrimitive.Portal;

  return (
    <TooltipPrimitive.Root
      delayDuration={delayDuration ?? 350}
      open={disabled ? false : open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <Portal>
        <TooltipPrimitive.Content
          className={cn(styles.tooltip, className ?? '')}
          side={side}
          align={align}
          sideOffset={8}
          collisionPadding={{ top: 48, bottom: 20, left: 20, right: 20 }}
          {...props}
        >
          {content}
        </TooltipPrimitive.Content>
      </Portal>
    </TooltipPrimitive.Root>
  );
}

export function MetadataTooltip(props: TooltipProps) {
  return <Tooltip className={styles.metadataTooltip} {...props} />;
}

export function FollowTooltipContent({
  link,
  children,
}: {
  link: LocationDescriptor<any>;
  children: React.ReactNode;
}) {
  return (
    <Link to={link}>
      <Button buttonStyle={ButtonStyle.Bare}>
        {children}
        <Icon
          size={IconSize.Size20}
          icon="chevron_right"
          className="ml8"
          style={{ fill: 'var(--grayA9)' }}
        />
      </Button>
    </Link>
  );
}
export function TooltipHeader({ title }: { title: string }) {
  return <div className={styles.header}>{title}</div>;
}

export const TooltipDisableContext = React.createContext<boolean>(false);

export function DisableTooltips({ children }: { children: React.ReactNode }) {
  return <TooltipDisableContext.Provider value={true}>{children}</TooltipDisableContext.Provider>;
}
