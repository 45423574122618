import * as React from 'react';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from './basicErrorScreen';
import styles from './errorScreen.module.scss';

export function DisabledScreen({ type }: { type: string }) {
  React.useEffect(() => {
    trackerPageLoad('Disabled');
  }, []);

  return (
    <BasicErrorScreen>
      <div className={styles.errorHeading}>{`The ${type} was disabled in this space!`}</div>
    </BasicErrorScreen>
  );
}
