import React from 'react';
import { toast } from '../components/toast';

export interface GitLabProject {
  id: string;
  name: string;
  installed: boolean;
}

export async function uninstallIntegration(integrationId: string) {
  const response = await fetch(`/integrations/gitlab/uninstall?integration_id=${integrationId}`, {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  const responseJson = await response.json();
  if (responseJson.errors) {
    throw new Error('Error uninstalling GitLab integration');
  }
}

export async function listProjects(integrationId: string): Promise<GitLabProject[]> {
  try {
    const response = await fetch(`/integrations/gitlab/groups?integration_id=${integrationId}`, {
      credentials: 'same-origin',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const responseJson = await response.json();
    if (responseJson.errors) {
      throw new Error('Error uninstalling GitLab integration');
    }
    return responseJson;
  } catch (e) {
    toast.error(
      <>
        Unable to fetch projects from <span className="semiBold">GitLab</span>
      </>
    );
    return [];
  }
}

export async function installWebhooks(
  integrationId: string,
  projectIds: string[]
): Promise<string[]> {
  const response = await fetch(
    `/integrations/gitlab/hook?integration_id=${integrationId}&project_ids=${projectIds.join(',')}`,
    {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status !== 201) {
    throw new Error('Error installing webhook');
  }

  const responseJson = await response.json();
  if (responseJson.errors) {
    throw new Error('Error installing webhook');
  }

  return responseJson.data.webhooks;
}

export async function uninstallWebhooks(
  integrationId: string,
  projectIds: string[]
): Promise<string[]> {
  const response = await fetch(
    `/integrations/gitlab/hook?integration_id=${integrationId}&project_ids=${projectIds.join(',')}`,
    {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.status !== 200) {
    throw new Error('Error uninstalling webhook');
  }

  const responseJson = await response.json();
  if (responseJson.errors) {
    throw new Error('Error uninstalling webhook');
  }

  return responseJson.data.webhooks;
}
