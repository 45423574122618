import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from '../components/toast';
import { useConfirmation } from '../contexts/confirmationContext';
import { useOrganization } from '../contexts/organizationContext';
import { settingsPath } from '../syncEngine/selectors/organizations';

export function useIsProductTierExceededAndNag() {
  const organization = useOrganization();
  const { confirm } = useConfirmation();
  const history = useHistory();

  return React.useCallback(() => {
    if (!organization.productTierExceeded) {
      return false;
    }

    (async () => {
      const confirmed = await confirm(
        '🚀 Free plan exceeded',
        <>
          <div>
            Thanks so much for using Kitemaker! We hope it's working great for you and your team.
          </div>
          <div>
            You now have <b>over 100 active work items</b>, which exceeds the limit of our Free
            plan. To create more work items, please upgrade to the Standard plan.
          </div>
        </>,
        { icon: 'upgrade', label: 'Upgrade' }
      );
      if (confirmed) {
        history.push(settingsPath(organization, 'billing'));
      }
    })();

    return true;
  }, [organization, confirm, history]);
}

export default function BillingChecks() {
  const organization = useOrganization();
  const paymentErrorRef = React.useRef<(() => void) | null>();

  React.useEffect(() => {
    if (organization.lastPaymentError && !paymentErrorRef.current) {
      paymentErrorRef.current = toast.warn(
        <>There was a problem processing your payment. Please check your billing settings</>,
        { duration: Infinity, id: 'payment-error' }
      );
      return;
    }

    if (!organization.lastPaymentError && paymentErrorRef.current) {
      paymentErrorRef.current();
      paymentErrorRef.current = null;
    }

    return () => {
      if (paymentErrorRef.current) {
        paymentErrorRef.current();
        paymentErrorRef.current = null;
      }
    };
  }, [organization.id, organization.lastPaymentError]);

  return null;
}
