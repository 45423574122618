import { Descendant, Range, Text as TextNode } from 'slate';
import uuid from 'uuid';
import isURL from 'validator/lib/isURL';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import {
  deserializeMarkdown,
  DetectEntityLink,
  DetectEntityMention,
  detectMentionsInElement,
} from '../../../shared/slate/markdown';
import { DocumentLike, Elements, Marks } from '../../../shared/slate/types';
import { documentFromString, normalizeDocument } from '../../../shared/slate/utils';
import { Entity, Todo } from '../../../sync/__generated/models';
import { isElement, isSimpleTextDocument, isText } from '../../utils/dom';
import {
  fileUploader,
  parseUploadResult,
  pendingUploads,
  prepareFileUrl,
} from '../../utils/fileUploader';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { EditorType } from '../types';
import { debug } from './withDebug';

const inlineTagNames = ['span', 'strong', 'b', 'i', 'em', 'a', 'u', 's', 'br'];

function isInlineElement(element: Element): boolean {
  const tagName = element.tagName.toLowerCase();
  if (!inlineTagNames.includes(tagName)) {
    return false;
  }
  return Array.from(element.childNodes).every(n => !isElement(n) || isInlineElement(n));
}

function deserializeInlines(nodes: ChildNode[], marks?: Record<string, boolean>): Descendant[] {
  return nodes.flatMap(n => {
    if (isElement(n)) {
      const tagName = n.tagName.toLowerCase();
      if (tagName === 'br') {
        return [];
      }

      if (tagName === 'a') {
        const href = n.getAttribute('href') ?? '';
        const text = n.textContent;
        if (href) {
          // notion provides some huge weird links associated with images
          if (href.startsWith('data:') || !text) {
            return [];
          }
          return [{ type: Elements.Link, url: href, children: [{ text }] }];
        }
      }

      if (!inlineTagNames.includes(tagName)) {
        return [{ text: n.textContent ?? '', ...marks }];
      }

      // some editors are really annoying with their use of font-weights, etc. combined
      // with things like 'b' and 'em'. Let's try to handle it as best we can
      const style = n.getAttribute('style') || '';
      const fontStyle = style.match(new RegExp('font-style:([^;]+)'));
      const fontWeight = style.match(new RegExp('font-weight:([^;]+)'));
      const fontFamily = style.match(new RegExp('font-family:([^;]+)'));
      const textDecoration = style.match(new RegExp('text-decoration:([^;]+)'));

      let explicitFontWeightNormal = false;
      const newMarks: Record<string, boolean> = {};

      if (fontStyle) {
        switch (fontStyle[1].trim()) {
          case 'italic':
            newMarks[Marks.Italic] = true;
            break;
        }
      }

      if (fontWeight) {
        switch (fontWeight[1].trim()) {
          case 'normal':
          case '400':
            explicitFontWeightNormal = true;
            break;
          case 'bold':
          case '600':
          case '700':
            newMarks[Marks.Bold] = true;
            break;
        }
      }

      if (fontFamily) {
        if (fontFamily[1].toLowerCase().includes('monospace')) {
          newMarks[Marks.Code] = true;
        }
      }

      if (textDecoration) {
        const decorations = textDecoration[1].trim().split(' ');
        decorations.forEach(d => {
          switch (d.trim()) {
            case 'underline':
              newMarks[Marks.Underline] = true;
              break;
            case 'line-through':
              newMarks[Marks.Strikethrough] = true;
              break;
          }
        });
      }

      switch (n.tagName.toLowerCase()) {
        case 'strong':
        case 'b':
          if (!explicitFontWeightNormal) {
            newMarks[Marks.Bold] = true;
          }
          break;
        case 'i':
        case 'em':
          newMarks[Marks.Italic] = true;
          break;
        case 'u':
          newMarks[Marks.Underline] = true;
          break;
        case 's':
          newMarks[Marks.Strikethrough] = true;
          break;
        case 'code':
          newMarks[Marks.Code] = true;
          break;
      }

      return deserializeInlines(Array.from(n.childNodes), { ...marks, ...newMarks });
    }
    return [{ text: n.textContent ?? '', ...marks }];
  });
}

function stringifyChildren(nodes: ChildNode[]): string {
  let result = '';
  for (const node of nodes) {
    if (isText(node)) {
      result += node.textContent ?? '';
    }
    if (isElement(node)) {
      const tagName = node.tagName.toLowerCase();
      if (tagName === 'br') {
        result += '\n';
      } else {
        result += node.textContent ?? '';
      }
    }
  }
  return result;
}

function uploadIfNeeded(uploadPath: string, url: string): string {
  // FIXME: extend this to handle giant base64 data URLs too
  if (!(url.startsWith('blob:') || url.startsWith('data:'))) {
    return url;
  }

  debug('Received blob URL, need to upload a file instead');
  const fileId = uuid.v4();

  const blobUrl = prepareFileUrl(uploadPath, fileId, 'image');
  pendingUploads.set(blobUrl, true);

  setTimeout(async () => {
    try {
      // There doesn't seem to be a clean way to get from a blob: URL to a blob except to do a
      // fetch. Browsers don't like this if the blob URL isn't from the same domain.
      const blob = (await fetch(url).then(r => r.blob())) as Blob;
      const uploader = fileUploader(uploadPath, { multi: true });
      uploader.addFile({
        name: 'image',
        type: blob.type,
        data: blob,
        isRemote: false,
        meta: {
          fileId,
        },
      });

      const uploaded = await uploader.upload();
      parseUploadResult(uploaded);
    } catch (e) {
      debug('Error uploading blob', e);
      pendingUploads.del(blobUrl);
    }
  });

  return blobUrl;
}

function deserializeElement(
  element: Element,
  uploadPath: string,
  options?: { type?: Elements; indent?: number }
): Descendant[] {
  const children: Array<Element | Text> = Array.from(element.childNodes).filter(n => {
    if (isText(n)) {
      return n.textContent;
    }
    return isElement(n);
  }) as Array<Element | Text>;

  function deserializeChildren(childOpts?: {
    type?: Elements;
    indent?: number;
    checked?: boolean;
  }): Descendant[] {
    let results: Descendant[] = [];
    let inlines: ChildNode[] = [];
    const indent =
      childOpts?.type &&
      [Elements.Numbered, Elements.Bulleted, Elements.Todo].includes(childOpts.type)
        ? childOpts.indent
        : undefined;
    const checked = childOpts?.checked;

    for (const child of children) {
      if (isText(child)) {
        if (child.textContent && child.textContent !== '\n') {
          inlines.push(child);
        }
        continue;
      }

      if (isInlineElement(child)) {
        inlines.push(child);
        continue;
      }

      if (inlines.length) {
        const children = deserializeInlines(inlines) as any;
        if (children.length) {
          results.push({
            type: (childOpts?.type || Elements.Paragraph) as any,
            indent,
            checked,
            children,
          });
        }
        inlines = [];
      }

      const childElement = deserializeElement(child, uploadPath, childOpts);
      results = results.concat(childElement);
    }

    if (inlines.length) {
      const children = deserializeInlines(inlines) as any;
      if (children.length) {
        results.push({
          type: (childOpts?.type || Elements.Paragraph) as any,
          indent,
          checked,
          children: deserializeInlines(inlines) as any,
        });
      }
    }

    return results;
  }

  switch (element.tagName.toLowerCase()) {
    case 'img': {
      if (!element.getAttribute('src')) {
        return [];
      }

      const url = uploadIfNeeded(uploadPath, element.getAttribute('src')!);
      if (!url) {
        return [];
      }
      return [{ type: Elements.Image, url, children: [{ text: '' }] }] as Descendant[];
    }
    case 'ul':
      // dropbox paper
      if (element.className.includes('listtype-quote')) {
        return deserializeChildren({
          type: Elements.BlockQuote,
          indent: (options?.indent ?? -1) + 1,
        });
      }
      if (element.getAttribute('data-todo') === 'true') {
        return deserializeChildren({
          type: Elements.Todo,
          indent: (options?.indent ?? -1) + 1,
          checked: element.getAttribute('data-checked') === 'true',
        });
      }

      return deserializeChildren({
        type: Elements.Bulleted,
        indent: (options?.indent ?? -1) + 1,
      });
    case 'ol':
      return deserializeChildren({
        type: Elements.Numbered,
        indent: (options?.indent ?? -1) + 1,
      });
    case 'h1':
      return deserializeChildren({
        type: Elements.Headline1,
      });
    case 'h2':
      return deserializeChildren({
        type: Elements.Headline2,
      });
    case 'h3':
      return deserializeChildren({
        type: Elements.Headline3,
      });
    case 'code':
    case 'pre':
      return [
        {
          type: Elements.Code,
          language: null,
          children: [{ text: stringifyChildren(Array.from(element.childNodes)) }],
        },
      ];
    case 'blockquote':
      return [
        {
          type: Elements.BlockQuote,
          children: [{ text: stringifyChildren(Array.from(element.childNodes)) }],
        },
      ];
    default:
      return deserializeChildren(options);
  }
}

export function deserializeHtml(
  html: HTMLElement,
  richText: boolean,
  uploadPath: string,
  options?: {
    detectEntityMention?: (
      text: string
    ) => { matchIndex: number; match: string; todo?: Todo; entity: Entity }[];
  }
): Descendant[] {
  function detectMentions(
    document: DocumentLike,
    detectEntityMention: (
      text: string
    ) => { matchIndex: number; match: string; todo?: Todo; entity: Entity }[]
  ) {
    for (const block of document) {
      if (KitemakerElement.isElement(block)) {
        detectMentionsInElement(block, detectEntityMention);
      }
    }
  }

  if (!richText) {
    const result = [
      { type: Elements.Paragraph, children: [{ text: html.innerText }] },
    ] as DocumentLike;
    const normalized = normalizeDocument(result);
    if (options?.detectEntityMention) {
      detectMentions(normalized, options.detectEntityMention);
    }
    return normalized;
  }

  debug('Deserializing HTML', html);
  const result = deserializeElement(html, uploadPath);
  debug('Resulting deserialized form', result);

  // we sometimes just get weird empty paragraphs at the end of the document on Chrome, so strip those out
  let offset = result.length;
  while (offset > 0) {
    const node = result[offset - 1];
    if (
      !KitemakerElement.isElement(node) ||
      node.type !== Elements.Paragraph ||
      node.children.length !== 1 ||
      !TextNode.isText(node.children[0]) ||
      node.children[0].text.replace(/\s+/g, '') !== ''
    ) {
      break;
    }
    offset -= 1;
  }

  const normalized = normalizeDocument(result.slice(0, offset));
  if (options?.detectEntityMention) {
    detectMentions(normalized, options.detectEntityMention);
  }

  return normalized;
}

const catchSlateFragment = /data-slate-fragment="(.+?)"/m;
export const getSlateFragmentAttribute = (dataTransfer: DataTransfer): string | void => {
  const htmlData = dataTransfer.getData('text/html');
  const [, fragment] = htmlData.match(catchSlateFragment) || [];
  return fragment;
};

export function withPasting(
  editor: EditorType,
  richText: boolean,
  detectEntityLink: DetectEntityLink,
  detectEntityMention: DetectEntityMention,
  attachmentUploadPath: string
) {
  const { insertData } = editor;

  editor.insertData = data => {
    const codeParent = KitemakerEditor.isInCodeBlock(editor, true);

    const fragment =
      data.getData('application/x-slate-fragment') || getSlateFragmentAttribute(data);
    if (fragment) {
      const decoded = decodeURIComponent(window.atob(fragment));
      const parsed = JSON.parse(decoded) as DocumentLike;
      KitemakerTransforms.intelligentlyInsertFragment(editor, parsed);
      return;
    }

    const html = data.getData('text/html');
    const parsed = html ? new DOMParser().parseFromString(html, 'text/html') : null;

    if (parsed && !isSimpleTextDocument(parsed) && !codeParent) {
      const fragment = deserializeHtml(parsed.body, richText, attachmentUploadPath, {
        detectEntityMention,
      });
      KitemakerTransforms.intelligentlyInsertFragment(editor, fragment);
      return;
    }

    const text = data.getData('text/plain');
    if (text && richText) {
      const { selection } = editor;
      if (selection && !Range.isCollapsed(selection) && isURL(text)) {
        KitemakerTransforms.wrapNodes(
          editor,
          { type: Elements.Link, url: text, children: [] },
          { at: selection, split: true, mode: 'lowest' }
        );
        return;
      }

      const fragment = codeParent
        ? documentFromString(text)
        : deserializeMarkdown(text, {
            detectEntityLink,
            detectEntityMention,
            ignoreComments: codeParent,
          });

      if (fragment) {
        KitemakerTransforms.intelligentlyInsertFragment(editor, fragment);
        return;
      }
    }
    insertData(data);
  };

  return editor;
}
