import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { sendForgotPasswordLink } from '../../api/auth';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { TextInput, TextInputSize } from '../../components/new/textInput';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { toast } from '../../components/toast';
import { trackerPageLoad } from '../../tracker';

export function ForgotPasswordScreenContents({
  onSubmit,
}: {
  onSubmit: (data: { email: string; onSent: () => void }) => void;
}) {
  const location = useLocation<{ backPath?: string; backLabel?: string }>();
  const backLabel = location.state?.backLabel ?? 'Back to login';
  const backPath = location.state?.backPath ?? '/login';

  const [email, setEmail] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const valid = React.useMemo(() => {
    return isEmail(email);
  }, [email]);

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL oneLine mb4">Forgot password</div>
      <div className="bodyM grayed mb32">No worries! We’ll get you rolling again in no time.</div>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          setRequestInProgress(true);
          onSubmit({ email, onSent: () => setRequestInProgress(false) });
        }}
      >
        <label htmlFor="email">Email</label>
        <TextInput
          name="email"
          inputSize={TextInputSize.Large}
          className="fullWidth emailInput"
          autoFocus
          disabled={requestInProgress}
          placeholder="yourname@company.com"
          value={email}
          onChange={e => setEmail(e.currentTarget.value)}
        />
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          type="submit"
          className="fullWidth mt24 mb8"
          disabled={!valid || requestInProgress}
        >
          <div className="row">Send reset link</div>
        </Button>
        <Link className="headingS link subtle hoverOnly grayed" to={backPath}>
          {backLabel}
        </Link>
      </form>
    </div>
  );
}

export function ForgotPasswordScreen() {
  React.useEffect(() => {
    trackerPageLoad('Forgot password');
  }, []);

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Forgot password" />
      <ForgotPasswordScreenContents
        onSubmit={async ({ email, onSent }) => {
          try {
            await sendForgotPasswordLink(email);
            toast.info(
              <>
                A password reset link has been sent to <span className="semiBold">{email}</span>
              </>
            );
          } catch (e) {
            toast.error(e.message);
          }
          onSent();
        }}
      />
    </AuxiliaryScreen>
  );
}
