import * as React from 'react';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { CommandGroup, StatusCommandGroupContext } from '../../../commands/state';
import { ButtonStyle, IconButton, IconButtonTrigger } from '../../../components/new/button';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { Count } from '../../../components/new/count';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  useColumnHasKeyNavigationFocus,
  useHasKeyNavigationFocus,
  useKeyNavigationElement,
} from '../../../components/new/keyNavigation';
import { DropdownMenu, DropdownMenuContent } from '../../../components/new/menu/dropdownMenu';
import { StatusIcon } from '../../../components/new/statusIcon';
import { Tooltip } from '../../../components/new/tooltip';
import { statusSelector } from '../../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../../syncEngine/selectors/issueStatuses';
import { createNewIssueKey } from '../../../utils/config';
import { StatusSortIndicator } from './sortIndicator';
import { StatusMenu } from './statusMenu';
import { WatchingStatusIndicator } from './watchingStatusIndicator';

export function SectionHeader({
  statusId,
  getCountSelector,
  onNewCard,
  disableEditing,
  collapsed,
  boardId,
  hideLimit,
  onToggleCollapsed,
}: {
  statusId: string;
  getCountSelector: (columnId: string) => RecoilValueReadOnly<number>;
  onNewCard: () => void;
  disableEditing?: boolean;
  collapsed: boolean;
  boardId: string;
  hideLimit?: boolean;
  onToggleCollapsed: () => void;
}) {
  const id = `header-${statusId}`;
  const ref = React.useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const columnHasFocus = useColumnHasKeyNavigationFocus(statusId);
  const focused = useHasKeyNavigationFocus(id);
  const status = useRecoilValue(statusSelector(statusId));
  const isDefault = useRecoilValue(isDefaultStatusSelector(statusId));
  const count = useRecoilValue(getCountSelector(statusId));

  useKeyNavigationElement(id, ref);

  if (!status) {
    return null;
  }

  return (
    <div className="listHeaderContainer">
      <div className="listHeader" onClick={onToggleCollapsed} ref={ref}>
        <div className="row grow ellipsis">
          <Icon
            icon={collapsed ? 'arrow_forward' : 'arrow_down'}
            className="mr8 clickable grayIcon"
          />
          {status && <StatusIcon type={status.statusType} isDefault={isDefault} className="mr8" />}
          <div className="ellipsis mr8">{status?.name}</div>
          <Count count={count} limit={!hideLimit ? status?.issueLimit : undefined} />
          <div className="row ml4">
            <StatusSortIndicator disableEditing={disableEditing} status={status} />
            <WatchingStatusIndicator statusId={status.id} />
          </div>
        </div>
        <div className="row">
          <Tooltip
            content={
              <>
                {`Create ${issueTerm}`} <KeyboardShortcut shortcut={createNewIssueKey} />
              </>
            }
          >
            <IconButton
              icon="add"
              buttonStyle={ButtonStyle.BareSubtle}
              className="mr8"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onNewCard();
              }}
            />
          </Tooltip>
          <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
            <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
            <DropdownMenuContent
              onClick={e => {
                e.stopPropagation();
              }}
              side="bottom"
              align="end"
            >
              <StatusMenu
                disableEditing={disableEditing}
                boardId={boardId}
                statusId={statusId}
                onClose={closeMenu}
              />
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        {status && columnHasFocus && (
          <CommandContext<StatusCommandGroupContext>
            context={{ group: CommandGroup.Status, statusId: status.id }}
          />
        )}
        {focused && (
          <>
            <Hotkey
              hotkey="space"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
            <Hotkey
              hotkey="enter"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
