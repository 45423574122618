import { maxBy } from 'lodash';
import { randomString } from '../../../shared/utils/random';
import {
  effortLevelsByOrganization,
  effortLevelsBySpace,
  issuesByEffort,
} from '../../../sync/__generated/indexes';
import { Effort, Issue } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { SyncEngineCreate, SyncEngineUpdate, useModelManager } from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';

export function useCreateEffort() {
  const organization = useOrganization();
  const modelManager = useModelManager();
  return (spaceId: string | null, name: string, abbrevation: string): Effort => {
    return modelManager.transaction((tx, { get, getIndex }) => {
      let effortLevels;
      if (spaceId) {
        const effortLevelIds = getIndex(effortLevelsBySpace, spaceId);
        effortLevels = effortLevelIds
          .map(id => get(id) as Effort)
          .filter(e => e !== null && !e.deleted);
      } else {
        const effortLevelIds = getIndex(effortLevelsByOrganization, organization.id);
        effortLevels = effortLevelIds
          .map(id => get(id) as Effort)
          .filter(e => e !== null && !e.deleted && !e.spaceId);
      }

      const maxLevel = maxBy(effortLevels, 'level')?.level ?? 1;
      const effort: SyncEngineCreate<Effort> = {
        __typename: 'Effort',
        name,
        color: 'gray',
        level: maxLevel + 1,
        organizationId: organization.id,
        spaceId,
        abbrevation,
      };

      return tx.create(effort);
    });
  };
}

export function useUpdateEfforts() {
  const modelManager = useModelManager();
  return (effortIds: string[], update: SyncEngineUpdate<Effort>) => {
    modelManager.transaction(tx => {
      for (const effortId of effortIds) {
        tx.update<Effort>(effortId, update);
      }
    });
  };
}

export function useDeleteEfforts() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (effortIds: string[], force?: boolean) => {
    if (!force) {
      const confirmed = await confirm(
        `Delete effort${effortIds.length > 1 ? 's' : ''}`,
        `Are you sure you want to delete ${
          effortIds.length > 1 ? 'these efforts' : 'this effort'
        }? There is no way to undo this operation`,
        {
          label: 'Delete',
          destructive: true,
        }
      );

      if (!confirmed) {
        return [];
      }
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const effortId of effortIds) {
        const effort = get<Effort>(effortId);
        if (!effort || effort.deleted) {
          continue;
        }

        // clean up issues
        const issueIds = getIndex(issuesByEffort, effort.id);
        for (const issueId of issueIds) {
          tx.update<Issue>(issueId, { effortId: null });
        }

        tx.update<Effort>(effortId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });

        deleted.push(effortId);
        trackerEvent('Effort Deleted', {
          id: effort.id,
          name: effort.name,
        });
      }
    });

    return deleted;
  };
}

export function useReorderEfforts() {
  const modelManager = useModelManager();
  return (effortIds: string[]) => {
    modelManager.transaction(tx => {
      for (const [index, effortId] of effortIds.entries()) {
        tx.update<Effort>(effortId, { level: index + 1 });
      }
    });
  };
}
