import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { Issue, Space } from '../../../../sync/__generated/models';
import { useSpace } from '../../../contexts/spaceContext';
import { useCreateIssue } from '../../../syncEngine/actions/issues';
import { useRecentEntitiesForSpace } from '../../../syncEngine/selectors/entities';
import { dependencyIdsForIssuesSelector, isIssue } from '../../../syncEngine/selectors/issues';
import { EntityPicker } from './entityPicker';

export default function DependencyPickerWrapper({
  issues,
  onDependenciesAdded,
  onDependenciesRemoved,
  onDone,
  dependencyType,
  space: providedSpace,
  filterClassName,
}: {
  filterClassName?: string;
  issues: Issue[];
  dependencyType: 'enables' | 'dependsOn' | 'blocks' | 'blockedBy';
  onDependenciesAdded: (issueIds: string[], toAdd: string) => void;
  onDependenciesRemoved: (issueIds: string[], toRemove: string) => void;
  onDone: () => void;
  space?: Space;
}) {
  const dependencies = useRecoilValue(dependencyIdsForIssuesSelector(issues.map(i => i.id)));
  const contextSpace = useSpace();
  const space = providedSpace ?? contextSpace;
  const defaultIds = useRecentEntitiesForSpace()(space.id)
    .filter(e => isIssue(e) && e.archivedAt === null)
    .map(i => i.id);
  const createIssue = useCreateIssue();

  let label = '';
  let currentDependencies: Record<string, string[]> = {};

  switch (dependencyType) {
    case 'enables':
      label = 'Enables';
      currentDependencies = dependencies.enablesIssueIds;
      break;
    case 'dependsOn':
      label = 'Depends on';
      currentDependencies = dependencies.dependsOnIssueIds;
      break;
    case 'blocks':
      label = 'Blocks';
      currentDependencies = dependencies.blocksIssueIds;
      break;
    case 'blockedBy':
      label = 'Blocked by';
      currentDependencies = dependencies.blockedByIssueIds;
      break;
  }

  return (
    <EntityPicker
      filterClassName={filterClassName}
      placeholder={label}
      onAdd={onDependenciesAdded}
      multi
      onRemove={onDependenciesRemoved}
      onDone={onDone}
      state={currentDependencies}
      entityTypes={['Issue']}
      defaultEntityIds={defaultIds}
      createNewItem={({ onAdd, onDone, filter, setFilter }) => {
        return {
          id: 'create-new-dependency',
          className: 'createItem',
          icon: 'add',
          contents: (
            <div>
              Create {issueTerm} {`"${filter}"`}
            </div>
          ),
          onSelected: shift => {
            const newIssue = createIssue(filter, space.id);
            if (newIssue) {
              onAdd(newIssue.id);
            }

            setFilter('');
            if (!shift) {
              onDone();
            }
          },
        };
      }}
    />
  );
}
