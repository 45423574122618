import React from 'react';
import { IssueStatus, IssueStatusSortMode } from '../../../../sync/__generated/models';
import { SortIndicator } from '../../../components/sortIndicator';
import {
  inverseSortMode,
  useUpdateStatusSortMode,
} from '../../../syncEngine/actions/issueStatuses';

export function StatusSortIndicator({
  status,
  disableEditing,
  className,
}: {
  status: IssueStatus;
  disableEditing?: boolean;
  className?: string;
}) {
  const updateStatusSortMode = useUpdateStatusSortMode();
  const changeOrder = React.useCallback(
    (originalSortMode: IssueStatusSortMode) => {
      return (e: React.MouseEvent<HTMLButtonElement>) => {
        if (disableEditing) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();

        const sortMode = inverseSortMode(originalSortMode);
        updateStatusSortMode(status.id, sortMode);
      };
    },
    [disableEditing, status.id, updateStatusSortMode]
  );

  if (!status.sortMode || status.sortMode === IssueStatusSortMode.Manual) {
    return null;
  }

  return (
    <SortIndicator sortMode={status.sortMode} className={className} changeOrder={changeOrder} />
  );
}
