import { selectorFamily, useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { collaborativeDocsByEntity } from '../../../sync/__generated/indexes';
import { CollaborativeDoc } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';

export const collaborativeDocSelector = selectorFamily({
  key: 'CollaborativeDoc',
  get:
    (collaborativeDocId: string) =>
    ({ get }) => {
      return get(syncEngineState(collaborativeDocId)) as CollaborativeDoc | null;
    },
});

export const collaborativeDocIdByEntitySelector = selectorFamily({
  key: 'CollaborativeDocIdByEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const docIds = get(indexKeyState(indexKey(collaborativeDocsByEntity, entityId)));
      return docIds[0] || null;
    },
});

export const collaborativeDocByEntitySelector = selectorFamily({
  key: 'CollaborativeDocByEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const docId = get(collaborativeDocIdByEntitySelector(entityId));
      if (!docId) {
        return null;
      }
      return collaborativeDocSelector(docId);
    },
});

export const collaborativeDocsByEntitiesSelector = selectorFamily({
  key: 'CollaborativeDocsByEntities',
  get:
    (entityIds: string[]) =>
    ({ get }) => {
      const docIds = entityIds.flatMap(entityId =>
        get(indexKeyState(indexKey(collaborativeDocsByEntity, entityId)))
      );
      return filterNotDeletedNotNull(
        docIds.map(id => get(syncEngineState(id)) as CollaborativeDoc | null)
      );
    },
});

export function useLookupCollaborativeDoc() {
  return useRecoilCallback(({ transact_UNSTABLE }) => (documentId: string) => {
    let res: CollaborativeDoc | null = null;
    transact_UNSTABLE(({ get }) => {
      res = get(syncEngineState(documentId)) as CollaborativeDoc | null;
    });
    return res as CollaborativeDoc | null;
  });
}
