import cn from 'classnames';
import { range } from 'lodash';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { BulletedElement } from '../../../shared/slate/types';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './bulleted.module.scss';

export function StaticBulleted({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: BulletedElement }) {
  const childNode = range(element.indent).reduce(result => <ul>{result}</ul>, <li>{children}</li>);
  return (
    <ul className={cn('block', 'listBlock', styles.bulleted)} {...attributes}>
      {childNode}
    </ul>
  );
}

export function Bulleted({
  element,
  attributes,
  children,
}: RenderElementProps & { element: BulletedElement }) {
  const childNode = range(element.indent).reduce(result => <ul>{result}</ul>, <li>{children}</li>);
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop({ smallPadding: true });

  return (
    <ul
      {...attributes}
      {...dndAttributes}
      className={cn('block', 'listBlock', styles.bulleted, dndClassName)}
    >
      {dndComponents}
      {childNode}
    </ul>
  );
}
