import * as React from 'react';
import { useComponentDidMount } from './useComponentDidMount';

export function useResizeObserver(
  ref: React.RefObject<HTMLDivElement> | React.MutableRefObject<HTMLDivElement | null | undefined>,
  onResized: () => void,
  options?: {
    triggerOnMount?: boolean;
  }
) {
  useComponentDidMount(() => {
    let observer: ResizeObserver | null = null;
    let prevHeight = -1;

    function installObserver() {
      if (!ref.current) {
        return;
      }

      observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          let height = -1;
          let width = -1;

          if (entry.borderBoxSize && entry.borderBoxSize.length) {
            height = entry.borderBoxSize[0].blockSize;
            width = entry.borderBoxSize[0].inlineSize;
          } else {
            height = entry.contentRect.height;
            width = entry.contentRect.width;
          }

          if (height <= 0 || width <= 0) {
            continue;
          }

          if (!options?.triggerOnMount && prevHeight <= 0) {
            prevHeight = height;
            continue;
          }

          if (height !== prevHeight) {
            prevHeight = height;
            onResized();
          }
        }
      });
      observer.observe(ref.current, {});
    }

    installObserver();
    const observed = ref.current;

    return () => {
      if (observed) {
        observer?.unobserve(observed);
      }
    };
  });

  return null;
}
