import cn from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Initiative, Issue, Release, Todo } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { useUpdateInitiatives } from '../../syncEngine/actions/intiatives';
import { useUpdateIssues } from '../../syncEngine/actions/issues';
import { useUpdateReleases } from '../../syncEngine/actions/releases';
import { useUpdateTodos } from '../../syncEngine/actions/todos';
import { EntityStatus, entityStatusSelector } from '../../syncEngine/selectors/entities';
import { isInitiative } from '../../syncEngine/selectors/intiatives';
import { isIssue } from '../../syncEngine/selectors/issues';
import { isRelease } from '../../syncEngine/selectors/releases';
import { isTodo } from '../../syncEngine/selectors/todos';
import { isSafari } from '../../utils/config';
import { Button, ButtonSize, ButtonStyle, IconButton } from './button';
import styles from './dueDateMetadata.module.scss';
import { Icon, IconSize } from './icon';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from './menu/dropdownMenu';
import Pill from './metadata/pill';
import { MetadataSize } from './metadata/size';
import { Tooltip } from './tooltip';

export function DueDatePicker({
  date: inputDate,
  label = 'due',
  onSave,
}: {
  date: number | null;
  label?: string;
  onSave: (date: Date | null) => void;
  onCancel?: () => void;
}) {
  const [date, setDate] = React.useState(
    inputDate ? moment(inputDate).utc().format('YYYY-MM-DD') : undefined
  );

  const keyDownRef = React.useRef(false);

  const verb = inputDate ? 'Change' : 'Set';

  return (
    <div className={styles.popover}>
      <span className="headingS mb4">
        {verb} {label} date
      </span>
      <div className="row fullWidth mb4">
        <input
          className={cn(styles.dateInput, 'fullWidth', 'mr8', {
            [styles.safari]: isSafari,
          })}
          min={moment().format('YYYY-MM-DD')}
          onBlur={() => onSave(moment(date).utc(true).toDate())}
          onChange={e => {
            if (keyDownRef.current) {
              setDate(e.target.value);
              keyDownRef.current = false;
            } else {
              onSave(moment(e.target.value).utc(true).toDate());
            }
          }}
          onKeyDown={e => {
            keyDownRef.current = true;
            if (e.key === 'Enter') {
              onSave(moment(date).utc(true).toDate());
            }
          }}
          value={date}
          type="date"
        />
        {inputDate && (
          <Tooltip content="Clear date">
            <IconButton
              buttonStyle={ButtonStyle.SecondarySubtle}
              icon="exit"
              size={ButtonSize.Small}
              onClick={() => onSave(null)}
            />
          </Tooltip>
        )}
      </div>
      <TimeShortchuts
        onUpdate={date => {
          onSave(moment(date).utc(true).toDate());
        }}
      />
    </div>
  );
}

function TimeShortchuts({ onUpdate }: { onUpdate: (date: Date | null) => void }) {
  return (
    <div className="row gap8 mt4">
      <Button
        buttonStyle={ButtonStyle.SecondarySubtle}
        size={ButtonSize.Small}
        onClick={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onUpdate(moment().startOf('day').toDate());
        }}
      >
        Today
      </Button>
      <Button
        buttonStyle={ButtonStyle.SecondarySubtle}
        size={ButtonSize.Small}
        onClick={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onUpdate(moment().add(1, 'day').startOf('day').toDate());
        }}
      >
        Tomorrow
      </Button>
      <Button
        buttonStyle={ButtonStyle.SecondarySubtle}
        size={ButtonSize.Small}
        onClick={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onUpdate(moment().add(1, 'week').startOf('isoWeek').toDate());
        }}
      >
        Next week
      </Button>
    </div>
  );
}

const enum OverdueStatus {
  Overdue = 'overdue',
  Today = 'due',
}

function isOverdue(dueEpoch: number | null): OverdueStatus | null {
  if (!dueEpoch) {
    return null;
  }
  const dueDate = moment(dueEpoch).utc().local(true).endOf('day');
  const now = moment();

  if (now.isSame(dueDate, 'day')) {
    return OverdueStatus.Today;
  } else if (now.isAfter(dueDate)) {
    return OverdueStatus.Overdue;
  }
  return null;
}

function DueDate({
  item,
  size,
  disableTooltip,
  className,
}: {
  item: Issue | Todo | Initiative | Release;
  size?: MetadataSize;
  disableTooltip?: boolean;
  className?: string;
}) {
  const { featureFlags } = useConfiguration();
  const itemStatus = useRecoilValue(entityStatusSelector(item.id));
  if (!item.dueDate && !(item as Initiative).startDate) {
    return null;
  }

  const status = itemStatus === EntityStatus.Open ? isOverdue(item.dueDate) : null;
  let formattedDate;

  if (isInitiative(item) && featureFlags.FEATURE_TOGGLE_TIMELINE) {
    const formattedStartDate = item.startDate ? moment(item.startDate).format('MMM D') : '\u00A0';
    let formattedDueDate = '\u00A0';

    if (item.dueDate) {
      if (item.startDate && moment(item.startDate).isSame(item.dueDate, 'month')) {
        formattedDueDate = moment(item.dueDate).format('D');
      } else {
        formattedDueDate = moment(item.dueDate).format('MMM D');
      }
    }

    formattedDate = `${formattedStartDate} \u2013 ${formattedDueDate}`;
  } else {
    formattedDate = moment(item.dueDate).utc().format('MMM D');
  }

  const content = (
    <Pill
      size={size}
      className={cn(className, {
        [styles.overdue]: status === OverdueStatus.Overdue,
        [styles.today]: status === OverdueStatus.Today,
      })}
    >
      <Icon
        className="mr4"
        icon="due_date"
        size={size === MetadataSize.Medium ? IconSize.Size20 : IconSize.Size16}
      />
      {formattedDate}
    </Pill>
  );

  if (disableTooltip) {
    return content;
  }

  return (
    <Tooltip side="bottom" align="start" content={`Due ${formattedDate}`}>
      {content}
    </Tooltip>
  );
}

export function EntityDueDate({
  item,
  size,
  interactable,
  className,
}: {
  item: Issue | Todo | Initiative | Release;
  size?: MetadataSize;
  interactable?: boolean;
  className?: string;
}) {
  const { featureFlags } = useConfiguration();
  const updateIssues = useUpdateIssues();
  const updateTodos = useUpdateTodos();
  const updateInitiatives = useUpdateInitiatives();
  const updateReleases = useUpdateReleases();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  if (!item.dueDate) {
    return null;
  }

  function update(
    item: Issue | Todo | Release | Initiative,
    date: Date | null,
    field?: 'dueDate' | 'startDate'
  ) {
    if (isIssue(item)) {
      updateIssues([item.id], {
        dueDate: date ? date.getTime() : null,
      });
    } else if (isInitiative(item) && field) {
      updateInitiatives([item.id], {
        [field]: date ? date.getTime() : null,
      });
    } else if (isTodo(item)) {
      updateTodos([item.id], {
        dueDate: date ? date.getTime() : null,
      });
    } else if (isRelease(item)) {
      updateReleases([item.id], {
        dueDate: date ? date.getTime() : null,
      });
    }
  }

  const content = (
    <DueDate item={item} size={size} className={className} disableTooltip={!interactable} />
  );

  if (!interactable) {
    return content;
  }

  let menuContent;

  if (isInitiative(item) && featureFlags.FEATURE_TOGGLE_TIMELINE) {
    menuContent = (
      <>
        <Submenu>
          <SubmenuTrigger icon="due_date">Start date</SubmenuTrigger>
          <SubmenuContent>
            <DueDatePicker
              date={item.startDate}
              onSave={startDate => {
                updateInitiatives([item.id], {
                  startDate: startDate?.getTime() ?? null,
                });
                closeMenu?.();
              }}
              onCancel={() => closeMenu?.()}
            />
          </SubmenuContent>
        </Submenu>
        <Submenu>
          <SubmenuTrigger icon="due_date">Due date</SubmenuTrigger>
          <SubmenuContent>
            <DueDatePicker
              date={item.dueDate}
              onSave={dueDate => {
                updateInitiatives([item.id], {
                  dueDate: dueDate?.getTime() ?? null,
                });
                closeMenu?.();
              }}
              onCancel={() => closeMenu?.()}
            />
          </SubmenuContent>
        </Submenu>
      </>
    );
  } else {
    menuContent = (
      <DueDatePicker
        date={item.dueDate}
        onSave={dueDate => {
          update(item, dueDate, isInitiative(item) ? 'dueDate' : undefined);
          closeMenu?.();
        }}
        onCancel={() => closeMenu?.()}
      />
    );
  }

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger>{content}</DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="right"
        align="start"
      >
        {menuContent}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
