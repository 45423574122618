import { groupBy, orderBy, sortBy, uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import {
  ImpactEffortScoringAlgorithm,
  defaultImpactEffortScoring,
  effortOnlyScoring,
  generateImpactEffortScoringMetadata,
  impactOnlyScoring,
} from '../../../shared/utils/impactAndEffortLevels';
import { randomString } from '../../../shared/utils/random';
import { between } from '../../../shared/utils/sorting';
import { issueTerm } from '../../../shared/utils/terms';
import { issueStatusWatchers } from '../../../sync/__generated/collections';
import {
  boardColumnsByBoard,
  boardsBySpace,
  effortLevelsBySpace,
  impactLevelsBySpace,
  issueStatusesBySpace,
  issuesBySpace,
} from '../../../sync/__generated/indexes';
import {
  Board,
  BoardColumn,
  Effort,
  Impact,
  Issue,
  IssueStatus,
  IssueStatusSortMode,
  IssueStatusType,
  Organization,
  Space,
} from '../../../sync/__generated/models';
import { statusToString } from '../../components/new/statusIcon';
import { toast } from '../../components/toast';
import { useOrganization } from '../../contexts/organizationContext';
import { useSpace } from '../../contexts/spaceContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { EntitySortField, ImpactAndEffortScoringMode } from '../../utils/entities';
import { sortModeToString } from '../selectors/issues';
import { spaceSettingsPath } from '../selectors/organizations';
import { spacePath } from '../selectors/spaces';
import { createColumnHelper } from './boards';
import { indexHelper } from './helpers';

export function useSortStatusByImpactAndEffort() {
  const modelManager = useModelManager();

  return (statusId: string, scoringMode?: ImpactAndEffortScoringMode) => {
    // TODO: Select scoring algorithm based on feature flags
    let scoreIssue: ImpactEffortScoringAlgorithm = defaultImpactEffortScoring;

    if (scoringMode === ImpactAndEffortScoringMode.IMPACT_ONLY) {
      scoreIssue = impactOnlyScoring;
    } else if (scoringMode === ImpactAndEffortScoringMode.EFFORT_ONLY) {
      scoreIssue = effortOnlyScoring;
    }
    modelManager.transaction((tx, getters) => {
      const { get } = getters;
      const status = get<IssueStatus>(statusId);
      if (!status) {
        return;
      }
      const space = get<Space>(status.spaceId);
      if (!space) {
        return;
      }

      const impactLevels = indexHelper<Impact>(getters, impactLevelsBySpace, space.id);
      const effortLevels = indexHelper<Effort>(getters, effortLevelsBySpace, space.id);

      const issues = indexHelper<Issue>(getters, issuesBySpace, space.id).filter(
        issue => issue.statusId === statusId
      );
      const scoringMetadata = generateImpactEffortScoringMetadata({ impactLevels, effortLevels });
      const issuesWithScores = issues.map(issue => {
        const impact = impactLevels.find(impact => issue.impactId === impact.id);
        const effort = effortLevels.find(effort => issue.effortId === effort.id);

        return {
          issue,
          score: scoreIssue(scoringMetadata, { impactLevels, effortLevels, impact, effort }),
        };
      });

      const sortedIssues = sortBy(
        issuesWithScores,
        e => -e.score,
        e => e.issue.sort
      );

      let sort = between({});
      for (const { issue } of sortedIssues) {
        tx.update<Issue>(issue.id, { sort });
        sort = between({ after: sort });
      }
    });
  };
}

export function useSortStatusByDate() {
  const modelManager = useModelManager();

  return (
    statusId: string,
    sortField: EntitySortField.RECENTLY_CREATED | EntitySortField.RECENTLY_UPDATED
  ) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;
      const status = get<IssueStatus>(statusId);
      if (!status) {
        return;
      }
      const space = get<Space>(status.spaceId);
      if (!space) {
        return;
      }

      const issues = indexHelper<Issue>(getters, issuesBySpace, space.id).filter(
        issue => issue.statusId === statusId
      );

      const sortedIssues = orderBy(
        issues,
        i => (sortField === EntitySortField.RECENTLY_CREATED ? i.createdAt : i.displayedUpdatedAt),
        'desc'
      );

      let sort = between({});
      for (const issue of sortedIssues) {
        tx.update<Issue>(issue.id, { sort });
        sort = between({ after: sort });
      }
    });
  };
}

export function createStatusHelper(
  tx: SyncEngineTransaction,
  spaceId: string,
  name: string,
  type: IssueStatusType,
  issueLimit?: number
): IssueStatus {
  const status: SyncEngineCreate<IssueStatus> = {
    __typename: 'IssueStatus',
    spaceId,
    name,
    issueLimit: issueLimit ?? null,
    statusType: type,
    sortMode: IssueStatusSortMode.Manual,
    watcherIds: [],
  };
  return tx.create(status);
}

function hasOneValidStatus(
  statuses: IssueStatus[],
  currentStatus: IssueStatus,
  newStatusType?: IssueStatusType
) {
  const otherStatuses = statuses.filter(s => s.id !== currentStatus.id);
  const statusesByType = groupBy(otherStatuses, 'statusType');
  if (
    currentStatus.statusType !== newStatusType &&
    currentStatus.statusType !== IssueStatusType.InProgress &&
    !statusesByType[currentStatus.statusType]
  ) {
    toast.error(`You must have at least one ${statusToString(currentStatus.statusType)} status`);
    return false;
  }
  return true;
}

function updateDefaultStatus(
  organization: Organization,
  space: Space,
  statuses: IssueStatus[],
  status: IssueStatus,
  history: any,
  tx: SyncEngineTransaction,
  newStatusType?: IssueStatusType
) {
  const otherStatuses = statuses.filter(s => s.id !== status.id);
  const statusesByType = groupBy(otherStatuses, 'statusType');
  if (
    space.defaultNewStatusId === status.id &&
    newStatusType !== IssueStatusType.Todo &&
    newStatusType !== IssueStatusType.Backlog
  ) {
    const defaultNewStatus =
      statusesByType[IssueStatusType.Backlog]?.[0] ?? statusesByType[IssueStatusType.Todo]?.[0];
    const defaultNewStatusId = defaultNewStatus?.id;
    if (!defaultNewStatusId) {
      // this _shouldn't_ happen since we check for this above
      toast.error(`Cannot change status type of ${status.name} since it is a default status`);
      return false;
    }
    toast.info(`${defaultNewStatus.name} is now the default status for new ${issueTerm}s`, {
      action: {
        label: 'Manage default status settings',
        onClick: () => {
          history.push(spaceSettingsPath(organization, space, 'statuses'));
        },
      },
    });
    tx.update<Space>(space.id, { defaultNewStatusId });
  } else if (space.defaultDoneStatusId === status.id && newStatusType !== IssueStatusType.Done) {
    const defaultDoneStatus = statusesByType[IssueStatusType.Done]?.[0];
    const defaultDoneStatusId = defaultDoneStatus?.id;
    if (!defaultDoneStatusId) {
      // this _shouldn't_ happen since we check for this above
      toast.error(`Cannot change status type of ${status.name} since it is a default status`);
      return false;
    }
    toast.info(`${defaultDoneStatus.name} is now the default status for completed ${issueTerm}s`, {
      action: {
        label: 'Manage default status settings',
        onClick: () => {
          history.push(spaceSettingsPath(organization, space, 'statuses'));
        },
      },
    });
    tx.update<Space>(space.id, { defaultDoneStatusId });
  }
  return true;
}

export function useDeleteStatuses() {
  const modelManager = useModelManager();
  const history = useHistory();
  return (statusIds: string[]) => {
    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      const statuses = filterNotDeletedNotNull(
        statusIds.map(statusId => get<IssueStatus>(statusId))
      );

      // Get columns pointing to the same status so we avoid ghost columns
      const spaceIds = uniq(statuses.map(s => s.spaceId));
      const boardIds = spaceIds.flatMap(spaceId => getIndex(boardsBySpace, spaceId));
      const allColumns = boardIds.flatMap(boardId =>
        indexHelper<BoardColumn>(getters, boardColumnsByBoard, boardId)
      );
      const columns = allColumns.filter(c => statusIds.includes(c.statusId));

      if (!columns.length || !statuses.length) {
        return;
      }

      const spaceId = statuses[0].spaceId;
      const wrongSpaceStatuses = statuses.filter(status => status.spaceId !== spaceId);
      const space = get(spaceId) as Space;
      const organization = get(space.organizationId) as Organization;

      if (wrongSpaceStatuses.length) {
        throw Error('All statuses should be in the same space');
      }

      const issues = indexHelper<Issue>(getters, issuesBySpace, spaceId);
      const filteredIssues = issues.filter(issue => statusIds.includes(issue.statusId));

      if (filteredIssues.length) {
        toast.info('Only empty columns may be deleted');
        return;
      }

      const allStatuses = indexHelper<IssueStatus>(
        { get, getIndex },
        issueStatusesBySpace,
        spaceId
      );

      for (const status of statuses) {
        if (!hasOneValidStatus(allStatuses, status)) {
          return;
        }
        if (!updateDefaultStatus(organization, space, allStatuses, status, history, tx)) {
          return;
        }
      }

      for (const column of columns) {
        tx.update<BoardColumn>(column.id, { deleted: true });
      }

      for (const status of statuses) {
        tx.update<IssueStatus>(status.id, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });
      }
    });
  };
}

export function useUpdateStatuses() {
  const modelManager = useModelManager();
  const space = useSpace();
  const organization = useOrganization();
  const history = useHistory();

  return (statusIds: string[], update: SyncEngineUpdateWithoutDelete<IssueStatus>) => {
    modelManager.transaction((tx, { get, getIndex }) => {
      for (const statusId of statusIds) {
        if (update.sortMode) {
          trackerEvent('Column SortMode Set', { id: statusId, mode: update.sortMode });
        }

        const status = get(statusId) as IssueStatus | undefined;
        if (update.statusType && status) {
          const boards = indexHelper<Board>({ get, getIndex }, boardsBySpace, status.spaceId);
          const backlog = boards.find(b => b.name === 'Backlog');
          const current = boards.find(b => b.name === 'Current');

          const backlogColumns = indexHelper<BoardColumn>(
            { get, getIndex },
            boardColumnsByBoard,
            backlog?.id ?? ''
          );
          const currentColumns = indexHelper<BoardColumn>(
            { get, getIndex },
            boardColumnsByBoard,
            current?.id ?? ''
          );

          const statuses = indexHelper<IssueStatus>(
            { get, getIndex },
            issueStatusesBySpace,
            status.spaceId
          );
          if (!hasOneValidStatus(statuses, status, update.statusType)) {
            continue;
          }

          if (
            !updateDefaultStatus(
              organization,
              space,
              statuses,
              status,
              history,
              tx,
              update.statusType
            )
          ) {
            continue;
          }

          if (update.statusType === IssueStatusType.Backlog) {
            // if it's backlog delete columns with that status in current
            const columnId = currentColumns.find(c => c.statusId === statusId)?.id;
            if (columnId) {
              toast.success(
                `Status ${status.name} moved to the planning board because it's a backlog type.`,
                {
                  action: {
                    label: 'View status on Planning board',
                    onClick: () => {
                      history.push(spacePath(organization, space, 'boards/planning'));
                    },
                  },
                }
              );
              tx.update<IssueStatus>(columnId, { deleted: true });
            }
            // if it doesn't exist in backlog create it
            const columnId2 = backlogColumns.find(c => c.statusId === statusId)?.id;
            if (!columnId2 && backlog) {
              createColumnHelper(
                tx,
                backlog.id,
                statusId,
                between({ before: backlogColumns[0]?.sort })
              );
            }
          } else if (update.statusType === IssueStatusType.Todo) {
            // if it's todo, make sure it exists in current board
            const columnId = currentColumns.find(c => c.statusId === statusId)?.id;
            if (!columnId && current) {
              createColumnHelper(
                tx,
                current.id,
                statusId,
                between({ before: currentColumns[0]?.sort })
              );
            }
            // and make sure it exists in the backlog
            if (backlog) {
              const columnId = backlogColumns.find(c => c.statusId === statusId)?.id;
              if (!columnId) {
                createColumnHelper(
                  tx,
                  backlog.id,
                  statusId,
                  between({ after: backlogColumns[backlogColumns.length - 1]?.sort })
                );
              }
            }
          } else if (
            [IssueStatusType.Done, IssueStatusType.InProgress].includes(update.statusType)
          ) {
            // if it's done/inprogress, delete columns with that status in backlog
            const columnId = backlogColumns.find(c => c.statusId === statusId)?.id;
            if (columnId) {
              tx.update<IssueStatus>(columnId, { deleted: true });
              toast.success(
                `Status ${status.name} moved to the current board because it's not a backlog or not started type.`,
                {
                  action: {
                    label: 'View status on Current board',
                    onClick: () => {
                      history.push(spacePath(organization, space, 'boards/current'));
                    },
                  },
                }
              );
            }
            // if it doesn't exist in current create it
            const columnId2 = currentColumns.find(c => c.statusId === statusId)?.id;
            if (!columnId2 && current) {
              createColumnHelper(
                tx,
                current.id,
                statusId,
                between({ after: currentColumns[currentColumns.length - 1]?.sort })
              );
            }
          }
        }
        tx.update<IssueStatus>(statusId, update);
      }
    });
  };
}

export function useUpdateStatusSortMode() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();

  return (statusId: string, newSortMode: IssueStatusSortMode) => {
    modelManager.transaction((tx, { get }) => {
      const status = get(statusId) as IssueStatus | undefined;
      if (!status) {
        return;
      }
      trackerEvent('Column SortMode Set', { id: statusId, mode: newSortMode });
      const oldSortMode = status.sortMode;

      tx.update<IssueStatus>(statusId, { sortMode: newSortMode });

      let undoContent = null;
      switch (newSortMode) {
        case IssueStatusSortMode.LastStatusAsc:
        case IssueStatusSortMode.CreatedAsc:
        case IssueStatusSortMode.UpdatedAsc:
        case IssueStatusSortMode.EffortAsc:
        case IssueStatusSortMode.ImpactAsc:
        case IssueStatusSortMode.CycleAsc:
        case IssueStatusSortMode.DueDateAsc:
        // @ts-expect-error we intentionally want to fall through here
        // eslint-disable-next-line no-fallthrough
        case IssueStatusSortMode.ImpactEffortAsc:
          if (newSortMode !== inverseSortMode(status.sortMode)) {
            undoContent = (
              <>
                <span className="semiBold">{status.name}</span> is now ordered by{' '}
                <span className="semiBold">{sortModeToString(newSortMode)}.</span>
              </>
            );
            break;
          }

        // eslint-disable-next-line no-fallthrough
        case IssueStatusSortMode.LastStatusDesc:
        case IssueStatusSortMode.CreatedDesc:
        case IssueStatusSortMode.UpdatedDesc:
        case IssueStatusSortMode.EffortDesc:
        case IssueStatusSortMode.ImpactDesc:
        case IssueStatusSortMode.ImpactEffortDesc:
        case IssueStatusSortMode.CycleDesc:
        case IssueStatusSortMode.DueDateDesc:
          undoContent = (
            <>
              You reversed the order of <span className="semiBold">{status.name}.</span>
            </>
          );
          break;

        case IssueStatusSortMode.Manual:
          undoContent = (
            <>
              <span className="semiBold">{status.name}</span> is now ordered manually. Drag & drop{' '}
              {issueTerm}s to rearrange them.
            </>
          );
          break;
      }

      setUndo(undoContent, () => {
        modelManager.transaction(tx => {
          tx.update<IssueStatus>(statusId, {
            sortMode: oldSortMode,
          });
        });
      });
    });
  };
}

export function useWatchStatuses() {
  const currentUser = useCurrentUser();
  const modelManager = useModelManager();
  return (statusIds: string[], watching: boolean) => {
    modelManager.transaction(tx => {
      for (const statusId of statusIds) {
        if (watching) {
          tx.addToCollection(issueStatusWatchers, statusId, [currentUser.id]);
        } else {
          tx.removeFromCollection(issueStatusWatchers, statusId, [currentUser.id]);
        }
      }
    });
  };
}

export function inverseSortMode(sortMode: IssueStatusSortMode) {
  switch (sortMode) {
    case IssueStatusSortMode.LastStatusAsc:
      return IssueStatusSortMode.LastStatusDesc;
    case IssueStatusSortMode.LastStatusDesc:
      return IssueStatusSortMode.LastStatusAsc;
    case IssueStatusSortMode.CreatedAsc:
      return IssueStatusSortMode.CreatedDesc;
    case IssueStatusSortMode.CreatedDesc:
      return IssueStatusSortMode.CreatedAsc;
    case IssueStatusSortMode.UpdatedAsc:
      return IssueStatusSortMode.UpdatedDesc;
    case IssueStatusSortMode.UpdatedDesc:
      return IssueStatusSortMode.UpdatedAsc;
    case IssueStatusSortMode.ImpactAsc:
      return IssueStatusSortMode.ImpactDesc;
    case IssueStatusSortMode.ImpactDesc:
      return IssueStatusSortMode.ImpactAsc;
    case IssueStatusSortMode.EffortAsc:
      return IssueStatusSortMode.EffortDesc;
    case IssueStatusSortMode.EffortDesc:
      return IssueStatusSortMode.EffortAsc;
    case IssueStatusSortMode.ImpactEffortAsc:
      return IssueStatusSortMode.ImpactEffortDesc;
    case IssueStatusSortMode.ImpactEffortDesc:
      return IssueStatusSortMode.ImpactEffortAsc;
    case IssueStatusSortMode.CycleAsc:
      return IssueStatusSortMode.CycleDesc;
    case IssueStatusSortMode.CycleDesc:
      return IssueStatusSortMode.CycleAsc;
    case IssueStatusSortMode.DueDateAsc:
      return IssueStatusSortMode.DueDateDesc;
    case IssueStatusSortMode.DueDateDesc:
      return IssueStatusSortMode.DueDateAsc;
    default:
      return IssueStatusSortMode.Manual;
  }
}

export function useMarkStatusDefault() {
  const modelManager = useModelManager();
  return (statusId: string, defaultNew: boolean) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const status = get<IssueStatus>(statusId);
      if (!status) {
        return;
      }

      const space = get<Space>(status.spaceId);
      if (!space) {
        return;
      }

      if (defaultNew) {
        tx.update<Space>(space.id, { defaultNewStatusId: statusId });
      } else {
        tx.update<Space>(space.id, { defaultDoneStatusId: statusId });
      }
    });
  };
}
