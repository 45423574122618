import React from 'react';
import { Release } from '../../../sync/__generated/models';
import {
  useAddSpacesToReleases,
  useRemoveSpacesFromReleases,
} from '../../syncEngine/actions/releases';
import { SpacesMetadata } from './initativeSpaces';
import { MetadataSize } from './metadata/size';

export function ReleaseSpaces({
  release,
  interactable,
  size = MetadataSize.Small,
  className,
}: {
  release: Release;
  interactable?: boolean;
  size?: MetadataSize;
  className?: string;
}) {
  const addSpacesToReleases = useAddSpacesToReleases();
  const removeSpacesFromReleases = useRemoveSpacesFromReleases();

  return (
    <SpacesMetadata
      className={className}
      spaceIds={release.spaceIds}
      interactable={interactable}
      size={size}
      state={{ [release.id]: release.spaceIds }}
      onSpaceAdded={(ids, spaceId) => addSpacesToReleases(ids, [spaceId])}
      onSpaceRemoved={(ids, spaceId) => removeSpacesFromReleases(ids, [spaceId])}
    />
  );
}
