import * as ToastPrimitive from '@radix-ui/react-toast';
import { capitalize } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { entityTypeSelector, useEntityPath } from '../syncEngine/selectors/entities';
import { Button } from './new/button';
import { EntityLink } from './new/entityLink';
import { hide } from './toast';

export default function NewEntityToastContents({ entityId }: { entityId: string }) {
  const typeString = useRecoilValue(entityTypeSelector(entityId));
  const entityPath = useEntityPath();
  const history = useHistory();

  return (
    <>
      <div className="mb8">{capitalize(typeString)} created</div>
      <span className="semiBold">
        <EntityLink
          onClick={() => {
            hide(`CREATE-ENTITY-${entityId}`);
          }}
          id={entityId}
        />
      </span>
      <div className="row fullWidth" style={{ marginTop: 10 }}>
        <ToastPrimitive.Action altText="View" asChild>
          <Button
            onClick={() => {
              hide(`CREATE-ENTITY-${entityId}`);
              history.push(entityPath(entityId), {
                backUrl: location.pathname,
                backSearch: location.search,
                entity: entityId,
              });
            }}
          >
            View {typeString}
          </Button>
        </ToastPrimitive.Action>
      </div>
    </>
  );
}
