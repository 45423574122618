import * as React from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export enum ResponsiveDesignSize {
  Tiny = 0,
  Small = 1,
  Medium = 2,
  Large = 3,
  XLarge = 4,
}

export const responsiveDesign = atom<ResponsiveDesignSize>({
  key: 'screenSize',
  default: ResponsiveDesignSize.XLarge,
});

export const shortScreen = atom<boolean>({
  key: 'shortScreen',
  default: false,
});

export function useWatchMediaQueries() {
  const updateState = useSetRecoilState(responsiveDesign);

  const printMatch = React.useMemo(() => window.matchMedia('print'), []);
  const tinyMatch = React.useMemo(() => window.matchMedia('(max-width: 428px)'), []);
  const smallMatch = React.useMemo(() => window.matchMedia('(max-width: 926px)'), []);
  const mediumMatch = React.useMemo(() => window.matchMedia('(max-width: 1024px)'), []);
  const largeMatch = React.useMemo(() => window.matchMedia('(max-width: 1280px)'), []);

  const shortMatch = React.useMemo(() => window.matchMedia('(max-height: 1000px)'), []);

  const [print, setPrint] = React.useState(printMatch.matches);
  const [tiny, setTiny] = React.useState(tinyMatch.matches);
  const [small, setSmall] = React.useState(smallMatch.matches);
  const [medium, setMedium] = React.useState(mediumMatch.matches);
  const [large, setLarge] = React.useState(largeMatch.matches);

  const setShort = useSetRecoilState(shortScreen);

  React.useEffect(() => {
    const onPrintMatchChanged = (m: MediaQueryListEvent) => {
      setPrint(m.matches);
    };
    const onTinyMatchChanged = (m: MediaQueryListEvent) => {
      setTiny(m.matches);
    };
    const onSmallMatchChanged = (m: MediaQueryListEvent) => {
      setSmall(m.matches);
    };
    const onMediumMatchChanged = (m: MediaQueryListEvent) => {
      setMedium(m.matches);
    };
    const onLargeMatchchanged = (m: MediaQueryListEvent) => {
      setLarge(m.matches);
    };
    const onShortMatchChanged = (m: MediaQueryListEvent) => {
      setShort(m.matches);
    };

    printMatch.addEventListener('change', onPrintMatchChanged);
    tinyMatch.addEventListener('change', onTinyMatchChanged);
    smallMatch.addEventListener('change', onSmallMatchChanged);
    mediumMatch.addEventListener('change', onMediumMatchChanged);
    largeMatch.addEventListener('change', onLargeMatchchanged);
    shortMatch.addEventListener('change', onShortMatchChanged);

    return () => {
      printMatch.removeEventListener('change', onPrintMatchChanged);
      tinyMatch.removeEventListener('change', onTinyMatchChanged);
      smallMatch.removeEventListener('change', onSmallMatchChanged);
      mediumMatch.removeEventListener('change', onMediumMatchChanged);
      largeMatch.removeEventListener('change', onLargeMatchchanged);
      shortMatch.removeEventListener('change', onShortMatchChanged);
    };
  }, [
    tinyMatch,
    smallMatch,
    mediumMatch,
    largeMatch,
    shortMatch,
    setTiny,
    setSmall,
    setMedium,
    setLarge,
    setShort,
  ]);

  React.useEffect(() => {
    if (tiny && !print) {
      updateState(ResponsiveDesignSize.Tiny);
    } else if (small && !print) {
      updateState(ResponsiveDesignSize.Small);
    } else if (medium && !print) {
      updateState(ResponsiveDesignSize.Medium);
    } else if (large) {
      updateState(ResponsiveDesignSize.Large);
    } else {
      updateState(ResponsiveDesignSize.XLarge);
    }
  }, [tiny, small, medium, large, print, updateState]);
}

export function useIsTinyScreen() {
  const size = useRecoilValue(responsiveDesign);
  return size === ResponsiveDesignSize.Tiny;
}

export function useIsSmallScreen() {
  const size = useRecoilValue(responsiveDesign);
  return size === ResponsiveDesignSize.Small || size === ResponsiveDesignSize.Tiny;
}

export function useIsMediumScreen() {
  const size = useRecoilValue(responsiveDesign);
  return (
    size === ResponsiveDesignSize.Medium ||
    size === ResponsiveDesignSize.Small ||
    size === ResponsiveDesignSize.Tiny
  );
}
