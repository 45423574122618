import cn from 'classnames';
import { Location } from 'history';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';
import {
  atom,
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { isElectron } from '../../../electronIntegration';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useResizeObserver } from '../../../hooks/useResizeObserver';
import { useIsSmallScreen } from '../../../hooks/useResponsiveDesign';
import { localStorageEffect } from '../../../syncEngine/effects';
import { unreadUpdatesCountForCurrentUserSelector } from '../../../syncEngine/selectors/updates';
import { isMac, isMobileOS, toggleSideBarKey } from '../../../utils/config';
import { ButtonSize, ButtonStyle, IconButton, IconButtonTrigger } from '../button';
import { Icon } from '../icon';
import { KeyboardShortcut } from '../keyboardShortcut';
import { DropdownMenu, DropdownMenuContent } from '../menu/dropdownMenu';
import { NotificationDot } from '../notifications';
import { Tooltip } from '../tooltip';
import styles from './sidebar.module.scss';

export enum SidebarVisibility {
  Open,
  Closed,
  AutoHide,
}

export const sidebarVisibility = atom<SidebarVisibility>({
  key: 'SidebarVisibility',
  default: isMobileOS ? SidebarVisibility.Closed : SidebarVisibility.Open,
  effects: [localStorageEffect(`__sidebarVisibility`)],
});

export const sidebarSpaceCollapsed = atomFamily<boolean, string>({
  key: 'SidebarSpaceCollapsed',
  default: true,
  effects: key => [localStorageEffect(`__sidebarSpaceCollapsed__${key}`)],
});

export function useSetSidebarSpaceCollapsed() {
  return useRecoilCallback(({ set }) => (key: string, value: boolean) => {
    set(sidebarSpaceCollapsed(key), value);
  });
}

export const sidebarGroupCollapsed = atomFamily<boolean, string>({
  key: 'SidebarSectionCollapsed',
  default: false,
});

const sidebarOpenerHovered = atom({
  key: 'SidebarOpenerHovered',
  default: false,
});

export function SidebarContainer({
  children,
  forceVisible,
  className,
}: {
  children: React.ReactNode;
  forceVisible?: boolean;
  className?: string;
}) {
  const visibilityFromState = useRecoilValue(sidebarVisibility);
  const visibility = forceVisible ? SidebarVisibility.Open : visibilityFromState;
  const openerHovered = useRecoilValue(sidebarOpenerHovered);
  const [closing, setClosing] = React.useState(false);

  React.useLayoutEffect(() => {
    if (visibility === SidebarVisibility.AutoHide) {
      setClosing(true);
      setTimeout(() => {
        setClosing(false);
      }, 200);
    }
  }, [visibility]);

  return (
    <div
      className={cn(styles.sidebar, className, {
        [styles.closed]: visibility === SidebarVisibility.Closed,
        [styles.autoHide]: visibility === SidebarVisibility.AutoHide,
        [styles.openerHovered]:
          visibility === SidebarVisibility.AutoHide && openerHovered && !closing,
        [styles.closing]: closing,
        [styles.trafficLights]: visibility === SidebarVisibility.Open && isMac && isElectron(),
      })}
    >
      <div className={styles.hoverArea}></div>
      {children}
    </div>
  );
}

export function SidebarHeader({
  mobile,
  children,
}: {
  mobile: boolean;
  children: React.ReactNode;
}) {
  const [visibility, setVisibility] = useRecoilState(sidebarVisibility);
  const macOSElectron = isMac && isElectron();

  const hideButton = (
    <Tooltip
      content={
        <>
          Hide sidebar <KeyboardShortcut shortcut={toggleSideBarKey} />
        </>
      }
    >
      <IconButton
        buttonStyle={ButtonStyle.BareSubtle}
        className="ml8"
        icon="sidebar"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setVisibility(mobile ? SidebarVisibility.Closed : SidebarVisibility.AutoHide);
        }}
      />
    </Tooltip>
  );

  return (
    <div
      className={cn(styles.header, {
        colStretch: macOSElectron,
      })}
    >
      {macOSElectron && visibility !== SidebarVisibility.AutoHide && (
        <div className={styles.trafficLightsHideButton}>{hideButton}</div>
      )}
      <div className="row grow mr">{children}</div>
      {!macOSElectron && visibility !== SidebarVisibility.AutoHide && hideButton}
    </div>
  );
}

export function SidebarNavigation({ children }: { children: React.ReactNode }) {
  return <div className={styles.navigation}>{children}</div>;
}

export function SidebarNavigationElement({
  to,
  onIconClick,
  onClick,
  children,
  icon,
  iconColor,
  hotkey,
  spaceSubElement,
  exact,
  className,
  accessory,
  isActive,
}: {
  to?: string;
  onIconClick?: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  icon?: string;
  iconColor?: string;
  hotkey?: string;
  spaceSubElement?: boolean;
  exact?: boolean;
  className?: string;
  accessory?: React.ReactNode;
  isActive?: (match: unknown | null, location: Location) => boolean;
}) {
  const setSidebarVisibility = useSetRecoilState(sidebarVisibility);

  return (
    <NavLink
      isActive={isActive}
      to={to ?? window.location.href}
      activeClassName={styles.selected}
      className={cn(
        styles.navigationElement,
        {
          [styles.spaceSubElement]: spaceSubElement,
          [styles.noIcon]: icon === undefined,
        },
        className
      )}
      onClick={e => {
        if (isMobileOS) {
          setSidebarVisibility(SidebarVisibility.Closed);
        }

        onClick?.(e);
      }}
      exact={exact ?? !spaceSubElement}
    >
      <div className="row noMinWidth grow">
        {icon && (
          <div
            className="row"
            onClick={e => {
              if (onIconClick) {
                onIconClick(e);
              } else {
                onClick?.(e);
              }
            }}
          >
            <Icon
              style={iconColor ? { fill: iconColor } : undefined}
              icon={icon}
              className={styles.icon}
            />
          </div>
        )}
        {children}
      </div>
      {hotkey && !isMobileOS && (
        <div className={styles.hotkey}>
          <KeyboardShortcut shortcut={hotkey} />
        </div>
      )}
      {accessory && <div className={styles.accessory}>{accessory}</div>}
    </NavLink>
  );
}

export function SidebarSpaces({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLDivElement>(null);
  const [scrollable, setScrollable] = React.useState(false);

  useResizeObserver(innerRef, () => {
    setScrollable((innerRef.current?.clientHeight ?? 0) > (scrollRef.current?.clientHeight ?? 0));
  });

  useComponentDidMount(() => {
    setScrollable((innerRef.current?.clientHeight ?? 0) > (scrollRef.current?.clientHeight ?? 0));
  });

  return (
    <div
      className={cn(styles.spaces, className, {
        [styles.scrollable]: scrollable,
      })}
      ref={scrollRef}
    >
      <div className="colStretch" ref={innerRef}>
        {children}
      </div>
    </div>
  );
}

export function SidebarSpaceGroup({
  id,
  name,
  onReordered,
  children,
}: {
  id: string;
  name: string;
  onReordered: (from: number, to: number) => void;
  children: React.ReactNode;
}) {
  const [collapsed, setCollapsed] = useRecoilState(sidebarGroupCollapsed(id));
  const modals = useModals();

  return (
    <DragDropContext
      onDragEnd={async ({ source, destination }) => {
        if (!destination) {
          return;
        }
        onReordered(source.index, destination.index);
      }}
    >
      <Droppable ignoreContainerClipping droppableId={id}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={cn(styles.spaceGroup, {
              [styles.dragging]: snapshot.isDraggingOver,
            })}
          >
            <div
              className={styles.spaceGroupHeader}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setCollapsed(c => !c);
              }}
            >
              <div className="row">
                {name}
                <Icon className="ml4" icon={collapsed ? 'arrow_forward' : 'arrow_down'} />
              </div>
              <Tooltip content="Create new space">
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtleNoHover}
                  size={ButtonSize.Small}
                  icon="add"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    modals.openModal(Modals.CreateSpace, { favorite: id === 'my' });
                  }}
                />
              </Tooltip>
            </div>
            {!collapsed && (
              <>
                {children}
                {provided.placeholder}
              </>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export function SidebarSpaceElement({
  id,
  name,
  to,
  index,
  hotkey,
  menuContents,
  className,
  children,
  current,
}: {
  id: string;
  name: React.ReactNode;
  index: number;
  to?: string;
  hotkey?: string;
  menuContents?: () => React.ReactNode;
  className?: string;
  activeClassName?: string;
  children: React.ReactNode;
  current: boolean;
}) {
  const [down, setDown] = React.useState(false);
  const [drag, setDrag] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [collapsed, setCollapsed] = useRecoilState(sidebarSpaceCollapsed(id));
  const ref = React.useRef<HTMLDivElement | null>(null);

  // we don't get a mouse up when a real drag happens
  React.useEffect(() => {
    function onMouseUp() {
      setDown(false);
      setDrag(false);
    }

    if (drag) {
      document.addEventListener('mouseup', onMouseUp);
      return () => document.removeEventListener('mouseup', onMouseUp);
    }

    return () => {
      //
    };
  }, [drag, setDown, setDrag]);

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isMobileOS}>
      {(provided, snapshot) => (
        <div
          ref={r => {
            provided.innerRef(r);
            ref.current = r;
          }}
          {...provided.draggableProps}
          className={cn(styles.space, {
            [styles.current]: current,
          })}
        >
          <div
            {...provided.dragHandleProps}
            onMouseDown={() => {
              setDown(true);
            }}
            onMouseMove={() => {
              if (down) {
                setDrag(true);
              }
            }}
            onMouseUp={() => {
              setDown(false);
              setDrag(false);
            }}
          >
            <SidebarNavigationElement
              icon={collapsed ? 'arrow_forward' : 'arrow_down'}
              hotkey={hotkey}
              accessory={
                <div
                  className={styles.menu}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DropdownMenu onOpenChange={setMenuOpen}>
                    <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtleNoHover} icon="more" />
                    <DropdownMenuContent side="right" align="start">
                      {menuContents?.()}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              }
              onClick={e => {
                if (current) {
                  e.preventDefault();
                  e.stopPropagation();
                  setCollapsed(c => !c);
                } else {
                  setCollapsed(false);
                }
              }}
              className={cn(
                styles.spaceHeader,
                {
                  [styles.menuOpen]: menuOpen,
                },
                className
              )}
              to={to}
              onIconClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setCollapsed(c => !c);
              }}
            >
              <div className="rowBetween maxWidth fullWidth ellipsis">
                <div className="ellipsis">{name}</div>
              </div>
            </SidebarNavigationElement>
          </div>
          {!collapsed && !snapshot.isDragging && !drag && children}
        </div>
      )}
    </Draggable>
  );
}

export function SidebarFooter({ children }: { children: React.ReactNode }) {
  return <div className={styles.footer}>{children}</div>;
}

export function SidebarOpener({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  const organization = useOrganization();
  const [visibility, setVisibility] = useRecoilState(sidebarVisibility);
  const setHovered = useSetRecoilState(sidebarOpenerHovered);
  const unreadNotificationCount = useRecoilValue(
    unreadUpdatesCountForCurrentUserSelector(organization.id)
  );
  const smallScreen = useIsSmallScreen();

  if (visibility === SidebarVisibility.Open) {
    return null;
  }

  let content = (
    <Tooltip content="Show sidebar">
      <IconButton
        buttonStyle={ButtonStyle.Bare}
        icon="sidebar"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setVisibility(SidebarVisibility.Open);
          setHovered(false);
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      />
    </Tooltip>
  );

  if (smallScreen) {
    content = <NotificationDot show={unreadNotificationCount > 0}>{content}</NotificationDot>;
  }

  return (
    <div className={className} style={style}>
      {content}
    </div>
  );
}
