import { capitalize } from 'lodash';
import * as React from 'react';
import { issueTerm } from '../../../../shared/utils/terms';
import {
  Activity as ActivityModel,
  StaleIssueActivityDetails,
  StaleIssuePeriod,
} from '../../../../sync/__generated/models';
import { Activity, ActivityTimestamp } from './activity';

const periodToString: Record<StaleIssuePeriod, string> = {
  [StaleIssuePeriod.Month]: 'a month',
  [StaleIssuePeriod.Quarter]: '3 months',
  [StaleIssuePeriod.Semester]: '6 months',
  [StaleIssuePeriod.Year]: '12 months',
};

function StaleIssueActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as StaleIssueActivityDetails;

  return (
    <Activity activity={activity}>
      {capitalize(issueTerm)} has been stale for {periodToString[details.period]}
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const StaleIssueActivity = React.memo(StaleIssueActivityComponent);
