import cn from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ButtonSize } from '../../components/new/button';
import { CompanyPerson } from '../../components/new/companyPerson';
import { EntityCard } from '../../components/new/entityCard';
import { Icon, IconSize } from '../../components/new/icon';
import {
  useHasKeyNavigationFocus,
  useSetKeyNavigationFocus,
} from '../../components/new/keyNavigation';
import { AvatarSize } from '../../components/new/metadata/avatar';
import Pill from '../../components/new/metadata/pill';
import { MetadataSize } from '../../components/new/metadata/size';
import { Tooltip } from '../../components/new/tooltip';
import { feedbackIsOpenSelector, feedbackSelector } from '../../syncEngine/selectors/feedback';
import { insightCountForFeedbackSelector } from '../../syncEngine/selectors/insights';
import { renderDate } from '../../utils/datetime';
import { FeedbackMenuButton } from './feedbackMenu';
import { FeedbackOwners } from './feedbackOwners';
import styles from './feedbackScreen.module.scss';
import { FeedbackTags } from './feedbackTags';

export function FeedbackBlock({
  feedbackId,
  onClick,
}: {
  feedbackId: string;
  onClick?: () => void;
}) {
  const feedback = useRecoilValue(feedbackSelector(feedbackId));
  const focused = useHasKeyNavigationFocus(feedback?.id ?? '');
  const open = useRecoilValue(feedbackIsOpenSelector(feedbackId));
  const insightCount = useRecoilValue(insightCountForFeedbackSelector(feedbackId));
  const setFocus = useSetKeyNavigationFocus();
  const hasMeta = !!(feedback?.ownerIds.length || feedback?.tagIds.length || insightCount > 0);

  const [menuOpen, setMenuOpen] = React.useState(false);

  if (!feedback) {
    return null;
  }
  return (
    <div className={styles.feedback}>
      <EntityCard
        className={cn(styles.card, {
          [styles.focused]: focused,
          [styles.open]: open,
          [styles.menuOpen]: menuOpen,
        })}
        onClick={() => {
          setFocus(feedback.id);
          onClick?.();
        }}
      >
        <div className="relative row metadataGapL maxWidth noMinWidth">
          {!feedback.processed && <div className={styles.indicator} />}
          <CompanyPerson noName size={AvatarSize.Size40} feedbackId={feedbackId} />
          <div className="maxWidth fullWidth noMinWidth">
            <div className={styles.feedbackBy}>
              <div className="grow row metadataGap ellipsis">
                <CompanyPerson noLogo feedbackId={feedbackId} />
              </div>

              <div className={styles.date}>
                {capitalize(renderDate(feedback.createdAt, { compact: true }))}
              </div>
              <div className={styles.menu}>
                <FeedbackMenuButton
                  feedback={feedback}
                  size={ButtonSize.ExtraSmall}
                  onOpenChange={v => setMenuOpen(v)}
                />
              </div>
            </div>
            <div className="ellipsis maxWidth fs-exclude">
              {feedback.title || 'Untitled feedback'}
            </div>
          </div>
        </div>
        {hasMeta && (
          <div className={styles.meta}>
            <span className="row metadataGap">
              <FeedbackTags feedback={feedback} size={MetadataSize.Small} />
              {insightCount > 0 && (
                <Tooltip content="Connected insights">
                  <Pill>
                    <Icon icon="insights" size={IconSize.Size16}></Icon>
                    {insightCount}
                  </Pill>
                </Tooltip>
              )}
            </span>
            <FeedbackOwners feedback={feedback} align="start" />
          </div>
        )}
      </EntityCard>
    </div>
  );
}
