import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import {
  Activity as ActivityModel,
  TodoChangedActivityDetails,
  TodoStatus,
} from '../../../../sync/__generated/models';
import ActorName from '../../actorName';
import Markdown from '../../markdown';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function TodoChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as TodoChangedActivityDetails;
  function statusName(todoStatus: TodoStatus) {
    switch (todoStatus) {
      case TodoStatus.Done:
        return 'done';
      case TodoStatus.InProgress:
        return 'in progress';
      case TodoStatus.NotStarted:
        return 'not started';
    }
  }
  return (
    <Activity activity={activity} icon="tasks">
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> changed todo{' '}
      {details.key} status.
      <ActivityTimestamp timestamp={activity.createdAt} />
      <div>
        <Markdown>{details.context}</Markdown>
      </div>
      <div className={cn(styles.activityContext, styles.activityHighlight)}>
        {capitalize(statusName(details.previousStatus))} {`->`}{' '}
        {capitalize(statusName(details.newStatus))}
      </div>
    </Activity>
  );
}

export const TodoChangedActivity = React.memo(TodoChangedActivityComponent);
