import React from 'react';
import { useRecoilValue } from 'recoil';
import { Issue } from '../../../../sync/__generated/models';
import {
  InitiativePicker,
  workItemsToInitiativePickerState,
} from '../../../components/new/pickers/initiativePicker';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../../syncEngine/actions/intiatives';
import { entitiesSelector } from '../../../syncEngine/selectors/entities';
import { initiativesForOrganizationSelector } from '../../../syncEngine/selectors/intiatives';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import styles from '../commandMenu.module.scss';

export function ChangeInitiativesContents({
  onClose,
  spaceId,
  entityIds,
}: {
  onClose: () => void;
  spaceId?: string;
  entityIds: string[];
}) {
  const organization = useOrganization();
  const space = useRecoilValue(spaceSelector(spaceId));
  const entities = useRecoilValue(entitiesSelector(entityIds)).filter(
    e => e.__typename === 'Issue'
  ) as Issue[];
  const initiatives = useRecoilValue(initiativesForOrganizationSelector(organization.id));

  const addIssuesToInitiative = useAddIssuesToInitiatives();
  const removeIssuesFromInitiative = useRemoveIssuesFromInitiatives();

  return (
    <div className="rowStretch fullWidth fullHeight">
      <InitiativePicker
        space={space}
        filterClassName={styles.inputDiv}
        state={workItemsToInitiativePickerState(entities, initiatives)}
        onInitiativeAdded={(issueIds, initiativeId) => {
          addIssuesToInitiative([initiativeId], issueIds);
        }}
        onInitiativeRemoved={(issueIds, initiativeId) => {
          removeIssuesFromInitiative([initiativeId], issueIds);
        }}
        onDone={onClose}
        showMetadata
      />
    </div>
  );
}
