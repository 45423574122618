import { CollaborativeDoc } from '../../../sync/__generated/models';
import { SyncEngineTransaction } from '../../graphql/modelManager';
import { DocumentLike } from '../../slate/types';

export function createCollaborativeDocHelper(
  tx: SyncEngineTransaction,
  organizationId: string,
  content: DocumentLike,
  entityId: string
) {
  const doc = tx.create<CollaborativeDoc>({
    __typename: 'CollaborativeDoc',
    organizationId,
    content,
    entityId,
  });

  return doc;
}
