import { cloneDeep, difference, isEqual, sortBy, uniq } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { Editor, Text, createEditor } from 'slate';
import uuid from 'uuid';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { Mentions, findMentions } from '../../../shared/slate/mentions';
import { emptyDocument, normalizeDocument } from '../../../shared/slate/utils';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { generateId, generateTodoKey } from '../../../shared/utils/id';
import { between } from '../../../shared/utils/sorting';
import { todoLabels, todoMembers } from '../../../sync/__generated/collections';
import { todosByConnectedEntity, todosByEntity } from '../../../sync/__generated/indexes';
import {
  Entity,
  ExternalIssue,
  ExternalIssueStatus,
  IssueStatus,
  IssueStatusType,
  Todo,
  TodoStatus,
} from '../../../sync/__generated/models';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { KitemakerTransforms } from '../../slate/kitemakerTransforms';
import { withCollaboration } from '../../slate/plugins/withCollaboration';
import { withSchema } from '../../slate/plugins/withSchema';
import { useWithSmartTodos } from '../../slate/plugins/withSmartTodos';
import { DocumentLike, Elements } from '../../slate/types';
import { trackerEvent } from '../../tracker';
import {
  collaborativeDocIdByEntitySelector,
  collaborativeDocSelector,
} from '../selectors/collaborativeDoc';
import { isIssue } from '../selectors/issues';
import { todoSelector } from '../selectors/todos';
import { indexKey, syncEngineState, useStateTransaction } from '../state';
import { SyncEngineIndexValue } from '../types';
import { indexHelper } from './helpers';

export type CreateTodo = ReturnType<typeof useCreateTodo>;
export type UpdateTodos = ReturnType<typeof useUpdateTodos>;
export type ToggleTodos = ReturnType<typeof useToggleTodos>;
export type CalculateTodoSort = ReturnType<typeof useCalculateTodoSort>;
export type HasCorrectEntity = ReturnType<typeof useHasCorrectEntity>;
export type SetOrphanedStatus = ReturnType<typeof useUpdateTodosIfNeeded>;

function uniqueUserMentions(contents: DocumentLike): string[] {
  return uniq(
    findMentions(contents)
      .filter(mention => mention.type === Mentions.User)
      .map(mention => mention.id)
  );
}

function uniqueLabelMentions(contents: DocumentLike): string[] {
  return uniq(
    findMentions(contents)
      .filter(mention => mention.type === Mentions.Label)
      .map(mention => mention.id)
  );
}

function findConnecetedEntityId(contents: DocumentLike): string | null {
  const parent = contents[0];
  if (!parent || !KitemakerElement.isElement(parent) || parent.type !== Elements.Paragraph) {
    return null;
  }

  if (parent.children.length !== 3) {
    return null;
  }

  const [maybeEmptyLeadingText, maybeEntityMention, maybeEmptyTrailingText] = parent.children;
  if (
    Text.isText(maybeEmptyLeadingText) &&
    maybeEmptyLeadingText.text.trim() === '' &&
    Text.isText(maybeEmptyTrailingText) &&
    maybeEmptyTrailingText.text.trim() === '' &&
    KitemakerElement.isElement(maybeEntityMention) &&
    maybeEntityMention.type === Elements.Entity
  ) {
    return maybeEntityMention.entityId;
  }
  return null;
}

function findConnectedExternalIssueId(contents: DocumentLike): string | null {
  const parent = contents[0];
  if (!parent || !KitemakerElement.isElement(parent) || parent.type !== Elements.Paragraph) {
    return null;
  }

  if (parent.children.length !== 3) {
    return null;
  }

  const [maybeEmptyLeadingText, maybeExternalIssueMention, maybeEmptyTrailingText] =
    parent.children;
  if (
    Text.isText(maybeEmptyLeadingText) &&
    maybeEmptyLeadingText.text.trim() === '' &&
    Text.isText(maybeEmptyTrailingText) &&
    maybeEmptyTrailingText.text.trim() === '' &&
    KitemakerElement.isElement(maybeExternalIssueMention) &&
    maybeExternalIssueMention.type === Elements.InlineExternalIssueLink &&
    maybeExternalIssueMention.externalIssueId
  ) {
    return maybeExternalIssueMention.externalIssueId;
  }
  return null;
}

function calculateStatus(
  getters: SyncEngineGetters,
  defaultStatus: TodoStatus,
  options: { connectedEntityId?: string | null; connectedExternalIssueId?: string | null }
) {
  if (options.connectedEntityId) {
    const entity = getters.get<Entity>(options.connectedEntityId);
    if (entity && isIssue(entity)) {
      const status = getters.get<IssueStatus>(entity.statusId);
      switch (status?.statusType) {
        case IssueStatusType.InProgress:
          return TodoStatus.InProgress;
        case IssueStatusType.Done:
        case IssueStatusType.Archived:
          return TodoStatus.Done;
        default:
          return TodoStatus.NotStarted;
      }
    }
  }
  if (options.connectedExternalIssueId) {
    const externalIssue = getters.get<ExternalIssue>(options.connectedExternalIssueId);
    if (externalIssue) {
      switch (externalIssue.externalIssueStatus) {
        case ExternalIssueStatus.InProgress:
          return TodoStatus.InProgress;
        case ExternalIssueStatus.Done:
        case ExternalIssueStatus.Archived:
          return TodoStatus.Done;
        default:
          return TodoStatus.NotStarted;
      }
    }
  }
  return defaultStatus;
}

export function updateConnectedTodosForWorkItemStatusChange(
  getters: SyncEngineGetters,
  tx: SyncEngineTransaction,
  workItemId: string,
  statusId: string
) {
  const status = getters.get<IssueStatus>(statusId);
  if (!status) {
    return;
  }
  let todoStatus: TodoStatus = TodoStatus.NotStarted;
  if (status.statusType === IssueStatusType.InProgress) {
    todoStatus = TodoStatus.InProgress;
  } else if (
    status.statusType === IssueStatusType.Done ||
    status.statusType === IssueStatusType.Archived
  ) {
    todoStatus = TodoStatus.Done;
  }

  const todosForWorkItem = indexHelper<Todo>(getters, todosByConnectedEntity, workItemId);

  for (const todo of todosForWorkItem) {
    if (todo.status !== todoStatus) {
      tx.update<Todo>(todo.id, {
        status: todoStatus,
      });
    }
  }
}

export function createSmartTodoHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  id: string,
  entityId: string,
  actorId: string,
  contents: DocumentLike,
  options?: {
    copyPropsFromTodoWithId?: string;
    status?: TodoStatus;
    sort?: string;
    indent?: number;
    effortId?: string | null;
    impactId?: string | null;
    parentId?: string | null;
    dueDate?: Date | null;
  }
) {
  const normalizedContents = normalizeDocument(contents);
  const { get } = getters;

  const existingTodo = options?.copyPropsFromTodoWithId
    ? get<Todo>(options.copyPropsFromTodoWithId)
    : null;

  const todosForEntity = indexHelper<Todo>(getters, todosByEntity, entityId);
  const keys = todosForEntity.map(todo => todo.key);
  const lastSort = todosForEntity
    .map(t => t.sort)
    .sort()
    .reverse()[0];

  const recycled = !!get<Todo>(id);
  if (recycled) {
    tx.update<Todo>(id, {
      todoContents: normalizedContents,
      orphaned: false,
      sort: options?.sort ?? between({ after: lastSort }),
      indent: existingTodo?.indent ?? options?.indent ?? 0,
      status: existingTodo?.status ?? options?.status ?? TodoStatus.NotStarted,
      effortId: existingTodo?.effortId ?? options?.effortId ?? null,
      impactId: existingTodo?.impactId ?? options?.impactId ?? null,
      dueDate: existingTodo?.dueDate ?? options?.dueDate?.getTime() ?? null,
    });
    const mentions = uniqueUserMentions(normalizedContents).filter(userId => !!get(userId));
    tx.addToCollection(todoMembers, id, mentions);

    const labels = uniqueLabelMentions(normalizedContents).filter(labelId => !!get(labelId));
    tx.addToCollection(todoLabels, id, labels);
    return get<Todo>(id)!;
  }

  // did we get a duplicate ID?
  let resolvedId = id;
  const maybeDuplicateTodo = get<Todo>(id);
  if (maybeDuplicateTodo) {
    resolvedId = generateId();
  }

  const connectedEntityId = findConnecetedEntityId(normalizedContents);
  const connectedExternalIssueId = findConnectedExternalIssueId(normalizedContents);
  const status = calculateStatus(
    getters,
    existingTodo?.status ?? options?.status ?? TodoStatus.NotStarted,
    {
      connectedEntityId,
      connectedExternalIssueId,
    }
  );

  const todo: SyncEngineCreate<Todo> = {
    __typename: 'Todo',
    id: resolvedId,
    entityId,
    key: generateTodoKey(keys),
    actorId,
    todoContents: normalizedContents,
    orphaned: false,
    memberIds: [],
    labelIds: [],
    codeReviewRequestIds: [],
    sort: options?.sort ?? between({ after: lastSort }),
    indent: existingTodo?.indent ?? options?.indent ?? 0,
    status,
    effortId: existingTodo?.effortId ?? options?.effortId ?? null,
    impactId: existingTodo?.impactId ?? options?.impactId ?? null,
    parentId: options?.parentId ?? null,
    connectedEntityId,
    connectedExternalIssueId,
    explicitLinkStatus: false,
    dueDate: options?.dueDate?.getTime() ?? null,
  };

  const result = tx.create<Todo>(todo);

  const mentions = uniqueUserMentions(normalizedContents);
  tx.addToCollection(todoMembers, id, mentions);

  const labels = uniqueLabelMentions(normalizedContents);
  tx.addToCollection(todoLabels, id, labels);

  trackerEvent('Todo Created', {
    id: result.id,
  });
  return result;
}

export function duplicateTodosHelper(
  { get }: SyncEngineGetters,
  contents: DocumentLike | null,
  entityId: string,
  actorId: string
): {
  contents: DocumentLike | null;
  createTodos: (tx: SyncEngineTransaction, getters: SyncEngineGetters) => void;
} {
  if (!contents) {
    return {
      contents: contents,
      createTodos: () => {
        // no op
      },
    };
  }

  const todosToCreate: Array<{
    id: string;
    copyPropsFromTodoWithId: string;
    todoContents: DocumentLike;
  }> = [];
  const duplicated = cloneDeep(contents);
  for (const element of duplicated) {
    if (!KitemakerElement.isElement(element) || element.type !== Elements.SmartTodo) {
      continue;
    }

    const srcTodo = get<Todo>(element.todoId);
    if (!srcTodo) {
      continue;
    }

    const newTodo = {
      id: generateId(),
      copyPropsFromTodoWithId: srcTodo.id,
      todoContents: srcTodo.todoContents,
    };

    element.todoId = newTodo.id!;
    todosToCreate.push(newTodo);
  }

  return {
    contents: duplicated,
    createTodos: (tx, getters) => {
      for (const todo of todosToCreate) {
        createSmartTodoHelper(tx, getters, todo.id, entityId, actorId, todo.todoContents, {
          copyPropsFromTodoWithId: todo.copyPropsFromTodoWithId,
        });
      }
    },
  };
}

export function convertTodosHelper(contents: DocumentLike) {
  const todosToCreate: Array<{
    id: string;
    todoContents: DocumentLike;
  }> = [];

  const duplicate = cloneDeep(contents);
  for (const element of duplicate) {
    if (!KitemakerElement.isElement(element) || element.type !== Elements.Todo) {
      continue;
    }

    const newTodo = {
      id: generateId(),
      todoContents: [{ type: Elements.Paragraph, children: element.children }] as DocumentLike,
    };

    Object.assign(element, { todoId: newTodo.id, type: Elements.SmartTodo, checked: null });
    todosToCreate.push(newTodo);
  }

  return {
    contents: duplicate,
    createTodos: (
      tx: SyncEngineTransaction,
      getters: SyncEngineGetters,
      entityId: string,
      actorId: string
    ) => {
      for (const todo of todosToCreate) {
        createSmartTodoHelper(tx, getters, todo.id, entityId, actorId, todo.todoContents);
      }
    },
  };
}

export function useCreateTodo() {
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (
    id: string,
    entityId: string,
    contents: DocumentLike,
    options?: {
      copyPropsFromTodoWithId?: string;
      status?: TodoStatus;
      sort?: string;
      indent?: number;
      effortId?: string | null;
      impactId?: string | null;
      parentId?: string | null;
      dueDate?: Date | null;
    }
  ) => {
    return modelManager.transaction((tx, getters) => {
      return createSmartTodoHelper(tx, getters, id, entityId, user.id, contents, options);
    });
  };
}

export function useUpdateTodos() {
  const modelManager = useModelManager();
  return (todoIds: string[], update: SyncEngineUpdateWithoutDelete<Todo>) => {
    modelManager.transaction((tx, { get, getIndex }) => {
      for (const todoId of todoIds) {
        const todo = get<Todo>(todoId);
        if (!todo) {
          continue;
        }

        if (update.todoContents) {
          update.todoContents = normalizeDocument(update.todoContents);
          update.connectedEntityId = findConnecetedEntityId(update.todoContents);
          update.connectedExternalIssueId = findConnectedExternalIssueId(update.todoContents);
          update.status = calculateStatus({ get, getIndex }, todo.status, {
            connectedEntityId: update.connectedEntityId,
            connectedExternalIssueId: update.connectedExternalIssueId,
          });

          if (
            (todo.connectedEntityId && !update.connectedEntityId) ||
            (todo.connectedExternalIssueId && !update.connectedExternalIssueId)
          ) {
            update.status = TodoStatus.NotStarted;
          }
        }

        if (
          update.explicitLinkStatus &&
          (update.connectedEntityId || update.connectedExternalIssueId)
        ) {
          update.status = calculateStatus({ get, getIndex }, todo.status, {
            connectedEntityId: update.connectedEntityId,
            connectedExternalIssueId: update.connectedExternalIssueId,
          });
        }

        tx.update<Todo>(todoId, update);

        if (update.impactId) {
          trackerEvent('Todo Updated', { id: todoId, type: 'Impact' });
        }
        if (update.effortId) {
          trackerEvent('Todo Updated', { id: todoId, type: 'Effort' });
        }
        if (update.status) {
          trackerEvent('Todo Updated', { id: todoId, type: 'Status', status: update.status });
        }

        // update memberIds
        if (update.todoContents) {
          const currentMentions = uniqueUserMentions(update.todoContents);

          const membersToRemove = difference(todo.memberIds, currentMentions).filter(userId =>
            todo.memberIds.includes(userId)
          );
          const membersToAdd = difference(currentMentions, todo.memberIds)
            .filter(userId => !todo.memberIds.includes(userId))
            .filter(userId => !!get(userId));

          tx.addToCollection(todoMembers, todoId, membersToAdd);
          tx.removeFromCollection(todoMembers, todoId, membersToRemove);

          if (membersToAdd.length || membersToRemove.length) {
            trackerEvent('Todo Updated', {
              id: todoId,
              type: 'Members',
              membersAdded: membersToAdd.length,
              membersRemoved: membersToRemove.length,
            });
          }

          const currentLabels = uniqueLabelMentions(update.todoContents);

          const labelsToRemove = difference(todo.labelIds, currentLabels).filter(labelId =>
            todo.labelIds.includes(labelId)
          );
          const labelsToAdd = difference(currentLabels, todo.labelIds)
            .filter(labelId => !todo.labelIds.includes(labelId))
            .filter(labelId => !!get(labelId));

          tx.addToCollection(todoLabels, todoId, labelsToAdd);
          tx.removeFromCollection(todoLabels, todoId, labelsToRemove);

          if (membersToAdd.length || membersToRemove.length) {
            trackerEvent('Todo Updated', {
              id: todoId,
              type: 'Labels',
              labelsAdded: labelsToAdd.length,
              labelsRemoved: labelsToRemove.length,
            });
          }
        }
      }
    });
  };
}

export function useHasCorrectEntity() {
  return useRecoilCallback(({ snapshot }) => {
    return (todoId: string, entityId: string) => {
      const todo = snapshot.getLoadable(todoSelector(todoId)).getValue();
      if (!todo) {
        return false;
      }

      return todo.entityId === entityId;
    };
  });
}

export function useToggleTodos(trackingTag?: string) {
  const modelManager = useModelManager();
  return (todoIds: string[], toggleInProgress?: boolean) => {
    modelManager.transaction((tx, { get }) => {
      for (const todoId of todoIds) {
        const todo = get<Todo>(todoId);
        if (!todo) {
          continue;
        }
        if (toggleInProgress) {
          tx.update<Todo>(todoId, {
            status:
              todo.status === TodoStatus.InProgress ? TodoStatus.NotStarted : TodoStatus.InProgress,
          });
        } else {
          tx.update<Todo>(todoId, {
            status: todo.status !== TodoStatus.Done ? TodoStatus.Done : TodoStatus.NotStarted,
          });
        }

        if (trackingTag) {
          trackerEvent('Todo Toggled', {
            id: todoId,
            context: trackingTag,
          });
        }
      }
    });
  };
}

export function useCalculateTodoSort() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (opts: { beforeId?: string | null; afterId?: string | null; afterSort?: string }) => {
        const before = opts.beforeId
          ? snapshot.getLoadable(todoSelector(opts.beforeId)).getValue()?.sort
          : undefined;
        let after = opts.afterSort;
        if (!after && opts.afterId) {
          after = snapshot.getLoadable(todoSelector(opts.afterId)).getValue()?.sort;
        }

        return between({ after, before });
      },
    []
  );
}

export function useUpdateTodosIfNeeded() {
  const modelManager = useModelManager();
  return (entityId: string, nonOrphanedTodos: Array<{ id: string; parentId: string | null }>) => {
    modelManager.transaction((tx, getters) => {
      const todosForEntity = indexHelper<Todo>(getters, todosByEntity, entityId);
      for (const todo of todosForEntity) {
        const nonOrphanedTodo = nonOrphanedTodos.find(t => t.id === todo.id);
        if (nonOrphanedTodo) {
          if (todo.orphaned || todo.parentId !== nonOrphanedTodo.parentId) {
            tx.update<Todo>(todo.id, { orphaned: false, parentId: nonOrphanedTodo.parentId });
          }
        } else if (!todo.orphaned) {
          tx.update<Todo>(todo.id, { orphaned: true });
        }
      }
    });
  };
}

export function useFindRecyclableTodos() {
  const user = useCurrentUser();

  useStateTransaction();
  return useRecoilCallback(({ transact_UNSTABLE }) => (entityId: string) => {
    const recyclable: string[] = [];

    // FIXME: transaction hack to ensure we get the latest snapshot
    transact_UNSTABLE(({ get }) => {
      const todosForEntityIndex = get(syncEngineState(indexKey(todosByEntity, entityId))) as
        | SyncEngineIndexValue[]
        | null;
      const todoIds = (todosForEntityIndex ?? []).map(i => i.value);
      const todosForEntity = filterNotDeletedNotNull(
        todoIds.map(todoId => get(syncEngineState(todoId)) as Todo | null)
      );
      const todosByCreatedAt = sortBy(todosForEntity, todo => -todo.createdAt);
      for (const todo of todosByCreatedAt) {
        if (
          todo &&
          todo.actorId === user.id &&
          todo.orphaned &&
          isEqual(todo.todoContents, emptyDocument())
        ) {
          recyclable.push(todo.id);
        } else {
          break;
        }
      }
    });
    return recyclable;
  });
}

export function useFindTodosWithIncorrectEntityParent() {
  useStateTransaction();
  return useRecoilCallback(({ transact_UNSTABLE }) => (entityId: string, todoIds: string[]) => {
    const wrongParent: string[] = [];

    // FIXME: transaction hack to ensure we get the latest snapshot
    transact_UNSTABLE(({ get }) => {
      const todos = filterNotNull(
        todoIds.map(todoId => get(syncEngineState(todoId)) as Todo | null)
      );
      for (const todo of todos) {
        if (todo.entityId !== entityId) {
          wrongParent.push(todo.id);
        }
      }
    });
    return wrongParent;
  });
}

export function useUpdateTodoOutsideOfEditor(entityId?: string) {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const withSmartTodos = useWithSmartTodos(entityId);

  return useRecoilCallback<
    [
      id: string,
      options: {
        membersToAdd?: string[] | undefined;
        membersToRemove?: string[] | undefined;
        labelsToAdd?: string[] | undefined;
        labelsToRemove?: string[] | undefined;
      }
    ],
    void
  >(({ snapshot }) => {
    if (!entityId) {
      return (
        _id: string,
        _options: {
          membersToAdd?: string[];
          membersToRemove?: string[];
          labelsToAdd?: string[];
          labelsToRemove?: string[];
        }
      ) => {
        // No-op
      };
    }

    return (
      id: string,
      options: {
        membersToAdd?: string[];
        membersToRemove?: string[];
        labelsToAdd?: string[];
        labelsToRemove?: string[];
      }
    ) => {
      if (
        !options.membersToAdd &&
        !options.membersToRemove &&
        !options.labelsToAdd &&
        !options.labelsToRemove
      ) {
        return;
      }

      const todo = snapshot.getLoadable(todoSelector(id)).getValue();
      if (!todo) {
        return;
      }

      if (!KitemakerElement.isElement(todo.todoContents[0])) {
        return;
      }

      const documentId = snapshot
        .getLoadable(collaborativeDocIdByEntitySelector(todo.entityId))
        .getValue();
      if (!documentId) {
        return;
      }
      let editor = modelManager.getCollaborativeDocumentEditor(documentId);
      const hasActiveEditor = !!editor;

      if (!editor) {
        const document = snapshot
          .getLoadable(collaborativeDocSelector(documentId ?? ''))
          .getValue();

        if (!document) {
          return;
        }

        const contents = document.content;

        // instantiate a collaborative editor with the smart todo stuff turned on and do a quick edit
        const collaborationPlugin = withCollaboration(
          modelManager,
          document.id,
          'CollaborativeDoc',
          document.version
        );
        const nonCollaborativeEditor = withSchema(createEditor());
        KitemakerTransforms.insertNodes(nonCollaborativeEditor, contents);
        editor = collaborationPlugin(withSmartTodos(nonCollaborativeEditor));
        modelManager.setCollaborativeDocumentEditor(document.id, editor);
      }

      // find the path of the todo in the description
      const index = editor.children.findIndex(
        n => KitemakerElement.isElement(n) && n.type === Elements.SmartTodo && n.todoId === id
      );
      if (index === -1) {
        return;
      }

      if (options.membersToRemove) {
        KitemakerTransforms.removeNodes(editor, {
          at: [index],
          mode: 'lowest',
          match: n =>
            KitemakerElement.isElement(n) &&
            n.type === Elements.User &&
            options.membersToRemove!.includes(n.userId),
        });
      }

      for (const memberId of options.membersToAdd ?? []) {
        const [, end] = Editor.edges(editor, [index]);
        KitemakerTransforms.insertNodes(
          editor,
          [
            {
              text: ' ',
            },
            {
              type: Elements.User,
              userId: memberId,
              mentionId: uuid.v4(),
              actorId: user.id,
              children: [{ text: '' }],
            },
          ],
          { at: end }
        );
      }

      if (options.labelsToRemove) {
        KitemakerTransforms.removeNodes(editor, {
          at: [index],
          mode: 'lowest',
          match: n =>
            KitemakerElement.isElement(n) &&
            n.type === Elements.Label &&
            options.labelsToRemove!.includes(n.labelId),
        });
      }

      for (const labelId of options.labelsToAdd ?? []) {
        const [, end] = Editor.edges(editor, [index]);
        KitemakerTransforms.insertNodes(
          editor,
          [
            {
              text: ' ',
            },
            {
              type: Elements.Label,
              labelId: labelId,
              children: [{ text: '' }],
            },
          ],
          { at: end }
        );
      }

      if (editor && !hasActiveEditor) {
        modelManager.unsetCollaborativeDocumentEditor(documentId);
      }
    };
  });
}
