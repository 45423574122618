import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  ActivityType,
  MentionedInIntegrationActivityDetails,
  TodoChangedActivityDetails,
} from '../../sync/__generated/models';
import ActorName from '../components/actorName';
import Modal from '../components/modal';
import { RenderActivity } from '../components/new/activities/activities';
import { ActivityTimestamp, EmptyActivity } from '../components/new/activities/activity';
import { Modals, TodoDetailsArgs, useModals } from '../contexts/modalContext';
import { activitiesForEntitySelector } from '../syncEngine/selectors/entities';
import { todoKeySelector, todoSelector } from '../syncEngine/selectors/todos';
import styles from './todoDetailsModal.module.scss';

function TodoDetailsContent({ todoId }: { todoId: string }) {
  const todo = useRecoilValue(todoSelector(todoId));
  const todoKey = useRecoilValue(todoKeySelector(todo?.id));

  const activities = useRecoilValue(activitiesForEntitySelector(todo?.entityId))
    .filter(activity => {
      if (activity.activityType === ActivityType.TodoChanged) {
        return (activity.details as TodoChangedActivityDetails).todoId === todoId;
      }

      // TODO: We could be smart about it and expand mentioned in integration details with an optional todo and create and index. This is fine for now though
      if (todoKey && activity.activityType === ActivityType.MentionedInIntegration) {
        const details = activity.details as MentionedInIntegrationActivityDetails;
        return details.description.includes(todoKey) || details.context.includes(todoKey);
      }

      return false;
    })
    .reverse();

  if (!todo) {
    return null;
  }

  return (
    <div className={styles.content}>
      <div className={styles.todoActivities}>
        <EmptyActivity keynavId="todo-activity-created">
          <ActorName actorId={todo.actorId} className={styles.activityHighlight} /> created this
          todo.
          <ActivityTimestamp timestamp={todo.createdAt} />
        </EmptyActivity>

        {activities.map(a => (
          <RenderActivity key={a.id} activityId={a.id} />
        ))}

        <EmptyActivity keynavId="todo-activity-updated">
          Last updated
          <ActivityTimestamp timestamp={todo.updatedAt} />
        </EmptyActivity>
      </div>
    </div>
  );
}

export default function TodoDetailsModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() as TodoDetailsArgs | null;
  const todoKey = useRecoilValue(todoKeySelector(args?.todoId));

  if (!args?.todoId) {
    return null;
  }

  return (
    <>
      <Modal modalId={Modals.TodoDetails} title={`Details for ${todoKey}`}>
        <TodoDetailsContent todoId={args.todoId} />
      </Modal>
    </>
  );
}
