import cn from 'classnames';
import * as React from 'react';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { createTaskTitle } from '../../api/ai';
import { BlinkingDots } from '../../components/blinkingDots';
import { Icon } from '../../components/new/icon';
import { toast } from '../../components/toast';
import { useClient } from '../../contexts/clientContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useUnmounted } from '../../hooks/useUnmounted';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { AIPlaceholderElement, Elements, OptionalAttributesRenderElementProps } from '../types';
import styles from './aiPlaceholder.module.scss';

export function StaticAIPlaceholder({
  attributes,
  children,
}: OptionalAttributesRenderElementProps) {
  return (
    <div className="block row fullWidth unselectable" {...attributes}>
      <div className={styles.aiPlaceholder} contentEditable={false}>
        <Icon icon="ai" />
        <div className="grayed row">
          Generating a todo <BlinkingDots className={styles.dots} />
        </div>
      </div>
      {children}
    </div>
  );
}

export function AIPlaceholder({
  attributes,
  children,
  element,
}: RenderElementProps & { element: AIPlaceholderElement }) {
  const organization = useOrganization();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();
  const clientId = useClient();
  const editor = useSlateStatic();
  const unmounted = useUnmounted();

  useComponentDidMount(() => {
    if (element.clientId !== clientId || element.operation !== 'todo') {
      return;
    }

    (async () => {
      try {
        const generatedTodos = await createTaskTitle(organization.id, element.context.content);
        if (unmounted.current) {
          return;
        }

        const path = ReactEditor.findPath(editor, element);
        const todoId = uuid.v4();
        KitemakerEditor.withoutNormalizing(editor, () => {
          KitemakerTransforms.select(editor, path);
          KitemakerTransforms.delete(editor);
          if (!editor.richText) {
            return;
          }
          for (const todo of generatedTodos) {
            const children: any = [
              {
                text: `${todo} `,
              },
              {
                type: Elements.Link,
                url: element.context.url,
                children: [{ text: '(link)' }],
              },
            ];

            KitemakerTransforms.insertNodes(
              editor,
              [
                editor.smartTodosEnabled
                  ? {
                      type: Elements.SmartTodo,
                      todoId: todoId,
                      indent: 0,
                      children,
                    }
                  : {
                      type: Elements.Todo,
                      checked: false,
                      indent: 0,
                      children,
                    },
              ],
              { at: path, select: true }
            );
          }
        });
      } catch (e) {
        toast.error('Error generating todo with AI');
        const path = ReactEditor.findPath(editor, element);
        KitemakerEditor.withoutNormalizing(editor, () => {
          KitemakerTransforms.select(editor, path);
          KitemakerTransforms.delete(editor);
        });
      }
    })();
  });

  return (
    <div {...attributes} {...dndAttributes} className={cn('block listBlock', dndClassName)}>
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <div className={styles.aiPlaceholder} contentEditable={false}>
        <Icon icon="ai" />
        <div className="grayed row">
          Generating a todo <BlinkingDots className={styles.dots} />
        </div>
      </div>
    </div>
  );
}
