import { ScrollMode } from 'scroll-into-view-if-needed/typings/types';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { findScrollParent } from '../../../utils/dom';
import { scrollIntoView } from '../../../utils/scrolling';

export const KEY_NAVIGATION_DATA_ATTRIBUTE = 'data-key-nav-id';
export const KEY_NAVIGATION_PROVIDER_DATA_ATTRIBUTE = 'data-key-nav-provider-id';

export let hasMouseMoved = false;
export let isMouseDown = false;
export let mouseX = -1;
export let mouseY = -1;
export let hasScrolled = false;

let mouseMovedTimeout = -1;
let scrolledTimeout = -1;

export function mouseDown() {
  isMouseDown = true;
}

export function mouseUp() {
  isMouseDown = false;
}

export function mouseMoved(e: MouseEvent) {
  mouseX = e.clientX;
  mouseY = e.clientY;

  hasMouseMoved = true;
  if (mouseMovedTimeout !== -1) {
    window.clearTimeout(mouseMovedTimeout);
  }
  mouseMovedTimeout = window.setTimeout(() => {
    mouseMovedTimeout = -1;
    hasMouseMoved = false;
  }, 100);
}

export function useMouseMoveTracker() {
  useComponentDidMount(() => {
    function onMouseMove(e: MouseEvent) {
      mouseMoved(e);
    }

    function onMouseDown() {
      mouseDown();
    }

    function onMouseUp() {
      mouseUp();
    }

    document.body.addEventListener('mousemove', onMouseMove);
    document.body.addEventListener('mousedown', onMouseDown);
    document.body.addEventListener('mouseup', onMouseUp);

    return () => {
      document.body.removeEventListener('mousemove', onMouseMove);
      document.body.removeEventListener('mousedown', onMouseDown);
      document.body.removeEventListener('mouseup', onMouseUp);
    };
  });
}

export function scrolled() {
  if (scrolledTimeout !== -1) {
    window.clearTimeout(scrolledTimeout);
  }

  hasScrolled = true;
  scrolledTimeout = window.setTimeout(() => {
    hasScrolled = false;
  }, 1000);
}

export function ensureVisible(
  id: string,
  providerId: string,
  options?: { blockMode?: string; inlineMode?: string; scrollMode?: ScrollMode }
) {
  if (hasScrolled) {
    return;
  }
  const element = document.querySelector(
    `[${KEY_NAVIGATION_DATA_ATTRIBUTE}="${id}"][${KEY_NAVIGATION_PROVIDER_DATA_ATTRIBUTE}="${providerId}"]`
  );
  if (!element) {
    return;
  }

  scrollIntoView(element as HTMLElement, {
    block: options?.blockMode ?? 'start',
    inline: options?.inlineMode ?? 'center',
    scrollMode: options?.scrollMode ?? 'if-needed',
    behavior: 'auto',
  });
}

export function scrollToTop(id: string, providerId: string) {
  const element = document.querySelector(
    `[${KEY_NAVIGATION_DATA_ATTRIBUTE}="${id}"][${KEY_NAVIGATION_PROVIDER_DATA_ATTRIBUTE}="${providerId}"]`
  );
  if (!element) {
    return;
  }
  const parent = findScrollParent(element as HTMLElement);
  if (parent && getComputedStyle(parent).flexDirection !== 'column-reverse') {
    parent.scrollTop = 0;
  }
}
