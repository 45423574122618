import cn from 'classnames';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { Feedback } from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { CollaborativeDocEditor } from '../../../components/collaborativeDocEditor';
import { DescriptionHistory } from '../../../components/descriptionHistory';
import {
  DescriptionPlaceholder,
  PlaceholderType,
} from '../../../components/descriptionPlaceholder';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import { ActivitiesTab } from '../../../components/new/activities/activities';
import BackButton from '../../../components/new/backButton';
import { Breadcrumbs, TitleBreadcrumb } from '../../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { ButtonGroup } from '../../../components/new/buttonGroup';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { NEW_COMMENT_ID } from '../../../components/new/comments';
import { CompanyPerson } from '../../../components/new/companyPerson';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
  writeItemsToClipboard,
} from '../../../components/new/copyAndPaste';
import { CustomCommand } from '../../../components/new/customCommand';
import { EntityMembers, InsightsTab } from '../../../components/new/entityMetadata';
import {
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS,
  EntityScreenKeyNavHacks,
} from '../../../components/new/entityScreenKeyNavHacks';
import {
  QueryParameterManager,
  useEntityTabs,
  useInitialTab,
} from '../../../components/new/entityTabs';
import { EntityTitleEditor } from '../../../components/new/entityTitleEditor';
import { FeedbackWorkItems } from '../../../components/new/feedbackWorkItems';
import { Hotkey } from '../../../components/new/hotkey';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  FocusReason,
  KeyNavigationProvider,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import { AvatarSize } from '../../../components/new/metadata/avatar';
import { MetadataSize } from '../../../components/new/metadata/size';
import {
  CompanyPersonPicker,
  CompanyPersonPickerOptions,
  feedbackToCompanyPersonPickerState,
} from '../../../components/new/pickers/companyPersonPicker';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { Tab, TabStyle, Tabs } from '../../../components/new/tabs';
import { Tooltip } from '../../../components/new/tooltip';
import { RightBar } from '../../../components/rightBar';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { ResponsiveDesignSize, useIsMediumScreen } from '../../../hooks/useResponsiveDesign';
import useSaveScroll from '../../../hooks/useSaveScroll';
import { useNextPreviousSiblings } from '../../../hooks/useSiblingEntities';
import { TextAreaHandle } from '../../../slate/textArea';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../../syncEngine/actions/entities';
import { useUpdateFeedbacks } from '../../../syncEngine/actions/feedback';
import { useAddIssuesToInitiatives } from '../../../syncEngine/actions/intiatives';
import { useDeleteIssuesContext, useUpdateIssues } from '../../../syncEngine/actions/issues';
import { collaborativeDocIdByEntitySelector } from '../../../syncEngine/selectors/collaborativeDoc';
import {
  breadcrumbsForEntitySelector,
  useEntityPath,
  useUpdateRecents,
} from '../../../syncEngine/selectors/entities';
import { feedbackSelector } from '../../../syncEngine/selectors/feedback';
import { defaultIssueBackPathSelector, issueSelector } from '../../../syncEngine/selectors/issues';
import { organizationPath } from '../../../syncEngine/selectors/organizations';
import { trackerPageLoad } from '../../../tracker';
import {
  copyIssueLinkKey,
  copyIssueNumberKey,
  mainComboKey,
  vimDown,
  vimUp,
} from '../../../utils/config';
import { LocationState } from '../../../utils/history';
import { HistoryCheckpoint } from '../../../utils/revisions';
import { FeedbackMenuButton } from '../../feedbackScreen/feedbackMenu';
import { FeedbackTags } from '../../feedbackScreen/feedbackTags';
import styles from './feedbackDetailScreen.module.scss';

function Context({ feedbackId }: { feedbackId: string }) {
  const defaultPath = useRecoilValue(defaultIssueBackPathSelector(feedbackId));
  const history = useHistory();
  const { previousEntity, nextEntity } = useNextPreviousSiblings(feedbackId);
  useDeleteIssuesContext({
    onDelete: () => {
      if (previousEntity()) {
        return;
      }

      if (nextEntity()) {
        return;
      }

      history.push(defaultPath);
    },
  });

  return (
    <CommandContext
      context={{
        group: CommandGroup.Entities,
        entityIds: [feedbackId],
        focusedEntityId: feedbackId,
      }}
    />
  );
}

function CompanyBlock({ feedbackId }: { feedbackId: string }) {
  const feedback = useRecoilValue(feedbackSelector(feedbackId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const close = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const updateFeedback = useUpdateFeedbacks();

  if (!feedback) {
    return null;
  }

  return (
    <DropdownMenu onOpenChange={setMenuOpen} open={menuOpen}>
      <DropdownMenuTrigger style={{ marginLeft: -6 }} asChild>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          icon={<CompanyPerson size={AvatarSize.Size20} noName feedbackId={feedbackId} />}
        >
          <div className="row metadataGap">
            <CompanyPerson noLogo feedbackId={feedbackId} />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        align="start"
        sideOffset={10}
        alignOffset={-2}
        className="menuHuge menuPicker"
        style={{ maxHeight: 450 }}
      >
        <CompanyPersonPicker
          state={feedbackToCompanyPersonPickerState([feedback])}
          onDone={close}
          onCompanyAdded={companyId => {
            updateFeedback([feedback.id], { companyId, personId: null });
            close();
          }}
          onCompanyRemoved={() => {
            updateFeedback([feedback.id], { companyId: null, personId: null });
            close();
          }}
          onPersonAdded={personId => {
            updateFeedback([feedback.id], { companyId: null, personId: personId });
            close();
          }}
          onPersonRemoved={() => {
            updateFeedback([feedback.id], { companyId: null, personId: null });
            close();
          }}
          options={CompanyPersonPickerOptions.All}
          currentId={feedback.companyId ?? feedback.personId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function LeftHeader({ feedback }: { feedback: Feedback }) {
  const organization = useOrganization();
  const location = useLocation<LocationState>();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(feedback.id));
  const defaultPath = organizationPath(organization, 'feedback');
  const entityPath = useEntityPath();
  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const { host } = useConfiguration();
  const { previousEntity, nextEntity, totalCount, position } = useNextPreviousSiblings(feedback.id);

  if (!entityBreadcrumbs) {
    return null;
  }

  const rightSideContent = (
    <div className="row metadataGapL">
      <Tooltip
        content={
          <>
            {`Copy number`}
            <KeyboardShortcut shortcut={copyIssueNumberKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="copy"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            copyEntityNumber([feedback.id]);
          }}
        />
      </Tooltip>
      <Tooltip
        content={
          <>
            {`Copy link`}
            <KeyboardShortcut shortcut={copyIssueLinkKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="link"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            copyEntityLink([feedback.id]);
          }}
        />
      </Tooltip>
    </div>
  );

  return (
    <ScreenHeader
      className={styles.header}
      hideNotifications
      compensateForMacOSTrafficLights="always"
      rightSideContent={rightSideContent}
    >
      <div className="row headerGap ellipsis pb4 pt4">
        <BackButton defaultPath={defaultPath} />
        <div className="row metadataGapL">
          <ButtonGroup>
            <Tooltip
              content={
                <>
                  {`Previous ${issueTerm}`} <KeyboardShortcut shortcut={vimUp} />
                </>
              }
            >
              <IconButton disabled={position === 0} icon={'arrow_up'} onClick={previousEntity} />
            </Tooltip>
            <Tooltip
              content={
                <>
                  {`Next ${issueTerm}`} <KeyboardShortcut shortcut={vimDown} />
                </>
              }
            >
              <IconButton
                disabled={position === totalCount - 1}
                icon={'arrow_down'}
                onClick={nextEntity}
              />
            </Tooltip>
          </ButtonGroup>

          <CustomCommand
            command={{
              id: 'previous-issue',
              icon: 'arrow_up',
              description: `Up`,
              aliases: ['navigate', 'move to', 'go to'],
              handler: () => previousEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimUp,
              priority: 20,
            }}
          />

          <CustomCommand
            command={{
              id: 'next-issue',
              icon: 'arrow_down',
              description: `Down`,
              aliases: ['navigate', 'move to', 'go to'],
              handler: () => nextEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimDown,
              priority: 20,
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+c`}
            handler={e => {
              const selection = window.getSelection();
              if (selection && !selection.isCollapsed) {
                return;
              }
              e?.preventDefault();
              e?.stopPropagation();
              writeItemsToClipboard(
                [
                  {
                    id: feedback.id,
                    shortName: `F-${feedback.number}`,
                    name: feedback.title,
                    url: entityPath(feedback.id),
                    type: 'feedback',
                  },
                ],
                host
              );
            }}
          />

          <div className={styles.count}>
            {position + 1}
            <span className="mr2 ml2">/</span>
            {totalCount}
          </div>
        </div>
        <Breadcrumbs
          breadcrumbs={[
            ...(location.state?.breadcrumbs ?? [...entityBreadcrumbs]),
            {
              name: <TitleBreadcrumb number={`F-${feedback.number}`} title={feedback.title} />,
            },
          ]}
        />
      </div>
    </ScreenHeader>
  );
}

function RightHeader({
  tabs,
  currentTab,
  onTabChanged,
}: {
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  return (
    <ScreenHeader className={styles.header}>
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function SmallScreenHeader({
  feedback,
  tabs,
  currentTab,
  onTabChanged,
}: {
  feedback: Feedback;
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  const organization = useOrganization();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(feedback.id));
  const defaultPath = organizationPath(organization, 'feedback');
  const location = useLocation<LocationState>();

  if (!entityBreadcrumbs) {
    return null;
  }

  return (
    <>
      <ScreenHeader className={styles.header} hideNotifications>
        <BackButton defaultPath={defaultPath} className="mr8" />
        <Breadcrumbs
          breadcrumbs={[
            ...(location.state?.breadcrumbs ?? [...entityBreadcrumbs]),
            { name: `F-${feedback.number}` },
          ]}
        />
      </ScreenHeader>
      <div className={styles.smallScreenHeader}>
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </>
  );
}

function Title({ feedbackId }: { feedbackId: string }) {
  const updateIssues = useUpdateIssues();
  const addLabels = useAddLabelsToEntities();
  const addMembers = useAddAssigneesToEntities();
  const addInitiatives = useAddIssuesToInitiatives();
  const issue = useRecoilValue(issueSelector(feedbackId));
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  const editTitle = React.useCallback(() => {
    updateIssues([feedbackId], { title });
    setTitle('');
    setEditing(false);
  }, [title, setTitle, setEditing, updateIssues, feedbackId]);

  if (!issue) {
    return null;
  }

  if (!editing) {
    return (
      <div
        className="heading2XL bold fullWidth fs-exclude wrap"
        onMouseDown={() => {
          setTitle(issue.title);
          setEditing(true);
        }}
      >
        <CustomCommand
          command={{
            id: 'edit-title',
            group: CommandGroup.Entities,
            description: 'Edit title',
            hotkey: 't',
            priority: 99,
            handler: () => {
              setTitle(issue.title);
              setEditing(true);
            },
          }}
        />
        {issue.title || 'Untitled work item'}
      </div>
    );
  }

  return (
    <div className="row relative fullWidth fs-exclude">
      <EntityTitleEditor
        className="heading2XL bold noShrink"
        initialTitle={issue.title}
        onChange={v => {
          setTitle(v);
        }}
        autoFocus
        onSubmit={editTitle}
        onFocus={() => {
          disableKeyNav('title');
        }}
        onBlur={() => {
          enableKeyNav('title');
          editTitle();
        }}
        supportedMetadata={{
          initiatives: true,
          labels: true,
          users: true,
        }}
        onMetadataAdded={(type, id) => {
          switch (type) {
            case 'label':
              addLabels([feedbackId], [id]);
              break;
            case 'user':
              addMembers([feedbackId], [id]);
              break;
            case 'initiative':
              addInitiatives([id], [feedbackId]);
              break;
          }
        }}
        placeholder="Give your work item a title"
      />
    </div>
  );
}

function Description({ feedbackId }: { feedbackId: string }) {
  const [descriptionDisabled, setDescriptionDisabled] = React.useState(false);
  const [historyDescription, setHistoryDescription] = React.useState<HistoryCheckpoint | null>(
    null
  );
  const ref = React.useRef<TextAreaHandle>(null);
  const documentId = useRecoilValue(collaborativeDocIdByEntitySelector(feedbackId));

  return (
    <>
      <CustomCommand
        command={{
          id: 'edit-description',
          group: CommandGroup.Entities,
          description: 'Edit description',
          hotkey: 'd',
          priority: 99,
          handler: () => {
            ref.current?.focus();
            ref.current?.moveSelectionToEnd();
            ref.current?.ensureBottomMarginVisible();
          },
        }}
      />
      <CollaborativeDocEditor
        key={`description-${feedbackId}`}
        className={cn(styles.description, 'fs-exclude')}
        documentId={documentId ?? ''}
        entityId={feedbackId}
        inlineComments
        historyDescription={historyDescription}
        textAreaRef={ref}
        disabled={descriptionDisabled}
        feedbackId={feedbackId}
        placeholder={<DescriptionPlaceholder type={PlaceholderType.Issue} />}
      />
      <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
        <DescriptionHistory
          documentId={documentId ?? ''}
          onDescriptionChanged={d => {
            setHistoryDescription(d);
          }}
          onRestore={d => {
            setHistoryDescription(null);
            setTimeout(() => ref.current?.replaceValue(d.content));
          }}
          onOpen={() => {
            setDescriptionDisabled(true);
          }}
          onClose={() => {
            setDescriptionDisabled(false);
          }}
        />
      </HideIfSmallerThan>
    </>
  );
}

function MainScrollArea({
  children,
  feedbackId,
}: {
  children: React.ReactNode;
  feedbackId: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationColumn(`left-${feedbackId}`, [`main-${feedbackId}`]);
  const selected = useHasKeyNavigationFocus(`main-${feedbackId}`);
  const setFocus = useSetKeyNavigationFocus();

  useSaveScroll(feedbackId, ref);

  React.useEffect(() => {
    if (selected && document.activeElement === document.body) {
      ref.current?.focus();
    }
  }, [selected]);

  React.useEffect(() => {
    setFocus(`main-${feedbackId}`);
  }, [feedbackId]);

  return (
    <div className="rowStretch grow overflowScrollY fullWidth" ref={ref} tabIndex={-1}>
      {children}
      {selected && (
        <Hotkey
          hotkey="right"
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setFocus(NEW_COMMENT_ID, FocusReason.Keyboard);
          }}
        />
      )}
    </div>
  );
}

function ProcessedButton({ feedbackId }: { feedbackId: string }) {
  const updateFeedback = useUpdateFeedbacks();
  const feedback = useRecoilValue(feedbackSelector(feedbackId));

  if (!feedback) {
    return null;
  }

  return (
    <Button
      icon={feedback.processed ? 'select_checkmark' : 'select_checkmark'}
      buttonStyle={feedback.processed ? ButtonStyle.SecondarySubtle : ButtonStyle.Secondary}
      onClick={() => {
        updateFeedback([feedbackId], { processed: !feedback.processed });
      }}
    >
      {feedback.processed ? 'Mark as unprocessed' : 'Mark as processed'}
    </Button>
  );
}

function FeedbackScreen({ feedbackId }: { feedbackId: string }) {
  const feedback = useRecoilValue(feedbackSelector(feedbackId));

  const organization = useOrganization();
  const mediumScreen = useIsMediumScreen();

  const [openCommentThreadId, setOpenCommentThreadId] = React.useState<string | null>(null);
  const initialTab = useInitialTab();
  const [currentTab, setCurrentTab] = React.useState(initialTab);
  const tabs = useEntityTabs(feedbackId);

  useUpdateRecents(feedbackId);

  React.useEffect(() => {
    trackerPageLoad('Feedback');
  }, [feedbackId]);

  if (!feedback || !organization) {
    return null;
  }

  return (
    <KeyNavigationProvider
      columnIds={[
        `left-${feedbackId}`,
        ...ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS,
        `right-${feedbackId}`,
      ]}
    >
      <Screen hideSidebar row={!mediumScreen} className={styles.feedbackScreen}>
        <TitleSetter title={`${organization.name} · ${feedback.title}`} />
        <Context feedbackId={feedbackId} />
        {mediumScreen && (
          <SmallScreenHeader
            tabs={tabs}
            currentTab={currentTab}
            onTabChanged={setCurrentTab}
            feedback={feedback}
          />
        )}
        <EntityScreenKeyNavHacks />

        {(!mediumScreen || currentTab === 'description') && (
          <div className={cn(styles.left)}>
            {!mediumScreen && <LeftHeader feedback={feedback} />}
            <MainScrollArea feedbackId={feedbackId} key={feedbackId}>
              <div className={styles.gutter} />
              <div className={styles.content}>
                <div className={cn('col', 'noGrow', styles.contentHeader)}>
                  <div className="row rowBetween fullWidth hideWhilePrinting">
                    <div className={cn('row', 'gap8', 'flexWrap', styles.metadataGap)}>
                      <CompanyBlock feedbackId={feedback.id} />
                      <ProcessedButton feedbackId={feedback.id} />
                    </div>
                    <div className="row metadataGapL noShrink flexAlignStart">
                      <EntityMembers
                        interactable
                        max={null}
                        entity={feedback}
                        size={AvatarSize.Size24}
                      />
                      <FeedbackMenuButton feedback={feedback} />
                    </div>
                  </div>
                  <Title feedbackId={feedbackId} key={`title-${feedbackId}`} />
                  <div className="row metadataGapL maxWidth flexWrap hideWhilePrinting">
                    <FeedbackTags feedback={feedback} size={MetadataSize.Medium} />
                    <FeedbackWorkItems initiativeId={feedbackId} size={MetadataSize.Medium} />
                  </div>
                </div>
                <Description feedbackId={feedbackId} key={`description-${feedbackId}`} />
              </div>
              <div className={styles.gutter} />
            </MainScrollArea>
          </div>
        )}
        {(currentTab === 'activities' || currentTab === 'insights') && (
          <RightBar className={styles.right}>
            {!mediumScreen && (
              <RightHeader tabs={tabs} currentTab={currentTab} onTabChanged={setCurrentTab} />
            )}
            {currentTab === 'activities' && (
              <ActivitiesTab entityId={feedbackId} openCommentThreadId={openCommentThreadId} />
            )}
            {currentTab === 'insights' && (
              <InsightsTab feedbackMode entityId={feedbackId} entityType={'feedback'} />
            )}
          </RightBar>
        )}
        <QueryParameterManager
          onOpenThreadIdChanged={threadId => {
            if (threadId && threadId !== openCommentThreadId) {
              setCurrentTab('activities');
            }
            setOpenCommentThreadId(threadId);
          }}
          onCommentOrActivityIdChanged={commentOrActivityId => {
            if (commentOrActivityId) {
              setCurrentTab('activities');
            }
          }}
          onInsightIdChanged={insightId => {
            if (insightId) {
              setCurrentTab('insights');
            }
          }}
        />
      </Screen>
    </KeyNavigationProvider>
  );
}

export default React.memo(FeedbackScreen);
