import * as React from 'react';
import { useEnsureFocusedElementIsVisible } from '../components/new/keyNavigation';

export function useResizeObserver(ref: React.MutableRefObject<any>, url?: string) {
  const ensureVisible = useEnsureFocusedElementIsVisible();

  const sizeObserver = React.useRef(
    window.ResizeObserver
      ? new window.ResizeObserver(() => {
          ensureVisible();
        })
      : null
  );

  React.useEffect(() => {
    if (!ref.current || !sizeObserver.current) {
      return;
    }
    const observer = sizeObserver.current;
    const observed = ref.current;
    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, [sizeObserver, url]);
}
