import React from 'react';
import { useRecoilValue } from 'recoil';
import { InitiativePicker } from '../../../components/new/pickers/initiativePicker';
import {
  useAddInitiativesToRoadmapColumn,
  useAddInitiativesToRoadmaps,
  useRemoveInitiativesFromRoadmaps,
} from '../../../syncEngine/actions/intiatives';
import { roadmapInitiativesForRoadmapSelector } from '../../../syncEngine/selectors/intiatives';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import styles from '../commandMenu.module.scss';

export function ChangeRoadmapInitiativesContents({
  onClose,
  roadmapId,
  columnId,
  spaceId,
}: {
  onClose: () => void;
  roadmapId: string;
  columnId?: string;
  spaceId?: string;
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const addInitiativesToRoadmap = useAddInitiativesToRoadmaps();
  const addInitiativesToRoadmapColumn = useAddInitiativesToRoadmapColumn();
  const removeInitiativesFromRoadmap = useRemoveInitiativesFromRoadmaps();

  const roadmapInitiatives = useRecoilValue(roadmapInitiativesForRoadmapSelector(roadmapId));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <InitiativePicker
        space={space}
        filterClassName={styles.inputDiv}
        state={{ [roadmapId]: roadmapInitiatives.map(ri => ri.initiativeId) }}
        onInitiativeAdded={(roadmapIds, initiativeId) => {
          if (columnId) {
            addInitiativesToRoadmapColumn([initiativeId], columnId);
          } else {
            addInitiativesToRoadmap([initiativeId], roadmapIds);
          }
        }}
        onInitiativeRemoved={(roadmapIds, initiativeId) => {
          removeInitiativesFromRoadmap([initiativeId], roadmapIds);
        }}
        onDone={onClose}
        showMetadata
      />
    </div>
  );
}
