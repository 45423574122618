import * as React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import { Breadcrumbs, useSpaceBreadcrumb } from '../../../components/new/breadcrumbs';
import { Button } from '../../../components/new/button';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { Tooltip } from '../../../components/new/tooltip';
import { Screen } from '../../../components/screen';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { ResponsiveDesignSize } from '../../../hooks/useResponsiveDesign';
import { useCreateInitiative } from '../../../syncEngine/actions/intiatives';
import { initiativePath } from '../../../syncEngine/selectors/intiatives';
import { roadmapIdForSpaceSelector } from '../../../syncEngine/selectors/roadmaps';
import { spacePath } from '../../../syncEngine/selectors/spaces';
import { createNewEntityFromAnywhere, isMobileOS } from '../../../utils/config';
import { Roadmap } from './roadmap';

function Header({ roadmapId }: { roadmapId: string }) {
  const organization = useOrganization();
  const history = useHistory();
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const modals = useModals();
  const createInitiative = useCreateInitiative();

  return (
    <ScreenHeader
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={
        <Tooltip
          content={
            <>
              Create initiative <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
            </>
          }
        >
          <Button
            icon="add"
            className="mr8"
            onClick={e => {
              if (isMobileOS) {
                e.preventDefault();
                e.stopPropagation();
                const initiative = createInitiative('New initiative', {
                  roadmaps: [
                    {
                      roadmapId,
                    },
                  ],
                });
                history.push(initiativePath(organization, initiative.initiative));
                return;
              }

              modals.openModal(Modals.NewEntity);
            }}
          >
            New initiative
          </Button>
        </Tooltip>
      }
    >
      <HideIfSmallerThan size={ResponsiveDesignSize.Tiny}>
        <Breadcrumbs breadcrumbs={[...spaceBreadcrumb, { name: 'Roadmap' }]} />
      </HideIfSmallerThan>
    </ScreenHeader>
  );
}

export function RoadmapScreen() {
  const space = useSpace();
  const organization = useOrganization();
  const roadmapId = useRecoilValue(roadmapIdForSpaceSelector(space.id));

  if (!roadmapId) {
    return <Redirect to={spacePath(organization, space, 'initiatives')} />;
  }

  return (
    <Screen>
      <Header roadmapId={roadmapId} />
      <Roadmap roadmapId={roadmapId} />
    </Screen>
  );
}
