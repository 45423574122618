import { Path, Range, Transforms } from 'slate';
import { KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { Matcher } from './withStringMatching';

function markdownInlineMatcher(editor: EditorType, pattern: string, type: Elements): Matcher {
  return ({ text, texts, selection }) => {
    const prefixIndex = text.lastIndexOf(pattern, text.length - pattern.length - 1);

    if (prefixIndex === -1) {
      return false;
    }

    // We don't really have a match, but we need to watch for cases where the user has type __foo_ and give them a chance
    // to type that last _. So we'll pretend that we've got a match even though we don't
    if (!text.endsWith(pattern)) {
      return pattern.includes(text[text.length - 1]);
    }

    const anchor = KitemakerNode.offsetIntoTextNodes(editor, texts, prefixIndex);
    if (!anchor) {
      return false;
    }

    const range = { anchor, focus: selection.focus };
    const start = Range.start(range);
    const end = Range.end(range);

    if (
      Path.equals(start.path, end.path) &&
      end.offset - pattern.length <= start.offset + pattern.length
    ) {
      return false;
    }

    HistoryEditor.asBatch(editor, () => {
      Transforms.delete(editor, {
        at: {
          anchor: start,
          focus: end,
        },
      });
      Transforms.insertNodes(
        editor,
        [
          {
            type: type as any,
            katex: text.substring(prefixIndex + pattern.length, text.length - pattern.length),
            children: [{ text: '' }],
          },
        ],
        {
          at: start,
          mode: 'lowest',
        }
      );
      Transforms.select(editor, { anchor: start, focus: start });
      Transforms.move(editor, { distance: 1, unit: 'character' });
    });
    return true;
  };
}

export function markdownInlineMatchers(editor: EditorType): Matcher[] {
  return [markdownInlineMatcher(editor, '$$', Elements.Math)];
}
