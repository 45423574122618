import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  SettingsSection,
} from '../../../../components/new/settings';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { isElectron, openLinkInBrowser } from '../../../../electronIntegration';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <p>
    Create feedback from Intercom conversations and extract insights, linking them to {issueTerm}s.
  </p>
);

export function EditIntercomIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));

  if (!integration) {
    return <IntegrationNotFound />;
  }

  const config = JSON.parse(integration.config);

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Intercom}
      description={
        <>
          {description}
          <p>Your Intercom integration is enabled. To disable it, please do so from Intercom</p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton
          onClick={() => {
            if (isElectron()) {
              openLinkInBrowser(config.url);
            } else {
              window.open(config.url);
            }
          }}
        >
          Intercom settings
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewIntercomIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(
      `${host}/integrations/intercom/install?organization_id=${organization.id}`,
      electronScheme,
      {
        redirectBaseUrl: redirectUrl,
        redirectQueryParams: {
          pendingIntegration: 'intercom',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Intercom} description={description}>
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Intercom integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
