import { selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { viewsByOrganization } from '../../../sync/__generated/indexes';
import { Organization, View } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';

export function feedbackViewPath(organization: Organization, view: View | string) {
  return `/${organization.slug}/feedback/${typeof view === 'string' ? view : view.id}`;
}

export const viewSelector = selectorFamily({
  key: 'View',
  get:
    (viewId: string) =>
    ({ get }) => {
      return notDeleted(get(syncEngineState(viewId)) as View | null);
    },
});

export const viewsSelector = selectorFamily({
  key: 'Views',
  get:
    (viewIds: string[]) =>
    ({ get }) => {
      return filterNotNull(viewIds.map(id => get(viewSelector(id))));
    },
});

export const viewsForOrganizationSelector = selectorFamily({
  key: 'ViewsForOrganization',
  get:
    (organizationId: string | undefined) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }
      const viewIds = get(indexKeyState(indexKey(viewsByOrganization, organizationId)));
      return filterNotDeletedNotNull(
        viewIds.map(viewId => get(syncEngineState(viewId)) as View | null)
      );
    },
});
