import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { installFigmaWebhooks, uninstallFigmaWebhooks } from '../../../../api/figma';
import ExternalLink from '../../../../components/externalLink';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  Setting,
  SettingsSection,
} from '../../../../components/new/settings';
import { TextValidationInput } from '../../../../components/new/textInput';
import { toast } from '../../../../components/toast';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import {
  integrationUsersForCurrentUserSelector,
  integrationsSelector,
} from '../../../../syncEngine/selectors/integrations';
import { getQueryParameter, removeQueryParameter } from '../../../../utils/query';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <p>
    Automatically update Figma thumbnails used in work items when they get updated and create
    activities from Figma comments when designs are embedded in work items.
  </p>
);

function extractTeamId(inputUrl: string): string | undefined {
  try {
    const url = new URL(inputUrl);
    const urlParts = url.pathname.split('/').filter(e => e);

    if (urlParts.length < 3 || urlParts[1] != 'team') {
      return;
    }
    const teamId = urlParts[2];
    if (isNaN(parseInt(teamId))) {
      return;
    }

    return teamId;
  } catch {
    return;
  }
}

export function EditFigmaIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));

  if (!integration) {
    return <IntegrationNotFound />;
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Figma} description={description}>
      <SettingsSection>
        <AdminButton
          buttonStyle={ButtonStyle.Destructive}
          onClick={async () => await uninstallFigmaWebhooks(integration.id)}
        >
          Uninstall
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewFigmaIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const integrationUsers = useRecoilValue(integrationUsersForCurrentUserSelector);
  const { host, electronScheme } = useConfiguration();
  const history = useHistory();

  const [teamId, setTeamId] = React.useState<undefined | string>();

  const integrationUser = integrationUsers.find(
    u => u.type === IntegrationType.Figma && u.configValid
  );
  const hasFigmaUser = !!integrationUser;

  const errorOccurred = getQueryParameter(history, 'error');
  if (errorOccurred) {
    if (errorOccurred == '401') {
      toast.error(
        <>
          An error occurred when adding <span className="semiBold">Figma integration</span>. Do you
          have admin rights on the specified team?
        </>
      );
    } else {
      toast.error(
        <>
          An unknown error occurred when adding <span className="semiBold">Figma integration</span>.
          Code {errorOccurred}
        </>
      );
    }
    removeQueryParameter(history, 'error');
  }

  function startInstallation() {
    const tId = teamId;
    if (tId) {
      if (hasFigmaUser) {
        installFigmaWebhooks(organization.id, tId);
      } else {
        externalAuthFlow(
          `${host}/integrations/figma/auth?organizationId=${organization.id}&teamId=${tId}`,
          electronScheme,
          {
            redirectBaseUrl: redirectUrl,
            redirectQueryParams: {
              pendingIntegration: 'figma',
            },
            exchange: true,
          }
        );
      }
    }
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Figma} description={description}>
      <SettingsSection title="Pre-requisites">
        <p>Before proceeding, we need to identify your Figma team</p>
        <ul>
          <li>
            Open your browser and go to you team page in{' '}
            <ExternalLink href="https://www.figma.com">Figma</ExternalLink>, it should be in bold
            text in the sidebar
          </li>
          <li>Copy the url of this page and paste it in below</li>
        </ul>
      </SettingsSection>

      <SettingsSection>
        <Setting title="Figma team URL">
          <TextValidationInput
            placeholder="https://www.figma.com/files/team/123456789012345678/MyTeam"
            type="text"
            onChange={e => {
              setTeamId(extractTeamId(e.currentTarget.value));
            }}
            validate={v => {
              const teamId = extractTeamId(v);
              if (!teamId) {
                return 'Invalid team URL';
              }

              return null;
            }}
          />
        </Setting>
      </SettingsSection>

      <SettingsSection>
        <AdminButton
          buttonStyle={ButtonStyle.Primary}
          onClick={startInstallation}
          disabled={!teamId}
        >
          Install Figma integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
