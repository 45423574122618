import { History } from 'history';
import * as React from 'react';
import { DocumentLike } from '../../../../shared/slate/types';
import { Organization, Snippet, Space } from '../../../../sync/__generated/models';
import { SpacesMetadata } from '../../../components/new/initativeSpaces';
import { MetadataSize } from '../../../components/new/metadata/size';
import { spaceSettingsPath } from '../../../syncEngine/selectors/organizations';
import { trackerEvent } from '../../../tracker';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { SuggestionMatcher } from './withSuggestions';

export function snippetSuggestionMatcher(
  organization: Organization,
  space: Space | undefined | null,
  snippets: Snippet[],
  history: History
): SuggestionMatcher {
  return {
    id: 'snippet',
    prefix: '/snippet ',
    propertiesToSearch: ['name'],
    className: space ? undefined : 'menuHuge',
    options: () => {
      if (snippets.length) {
        return snippets.map(snippet => ({
          ...snippet,
          id: snippet.id,
          value: snippet.name,
          render: function RenderSnippetSuggestion() {
            return (
              <div className="rowBetween fullWidth oneLine">
                <span className="ellipsis">{snippet.name ?? 'Untitled snippet'}</span>
                {!space && (
                  <SpacesMetadata
                    spaceIds={[snippet.spaceId]}
                    size={MetadataSize.Small}
                    state={{}}
                  />
                )}
              </div>
            );
          },
        }));
      }
      if (space) {
        return [
          {
            id: 'placeholder',
            value: '__placeholder',
            render: function RenderSnippetPlaceholder() {
              return <div className="row">Create new snippet</div>;
            },
          },
        ];
      }
      return [
        {
          id: 'placeholder',
          value: '__placeholder',
          render: function RenderSnippetPlaceholder() {
            return <div className="row">No snippets found</div>;
          },
        },
      ];
    },
    onMatch: (editor, option, range, _autocomplete) => {
      if (option.id === 'placeholder' && space) {
        history.push(spaceSettingsPath(organization, space, 'snippets'));
        return;
      }

      const snippet = snippets.find(snippet => snippet.id === option.id);
      if (!snippet) {
        return;
      }

      trackerEvent('Snippet Used', {
        type: 'suggestion',
      });
      const parsedSnippet: DocumentLike = JSON.parse(snippet.contents);
      KitemakerTransforms.intelligentlyInsertFragment(editor, parsedSnippet, range);
    },
  };
}
