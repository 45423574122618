import * as React from 'react';
import { RoadmapColumnStatusType } from '../../../../sync/__generated/models';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { roadmapColumnTypeToIcon } from '../../../syncEngine/selectors/roadmaps';
import { colorWithOpacity } from '../../../utils/color';
import { Icon } from '../icon';
import { Tooltip } from '../tooltip';
import Pill, { PillStyle } from './pill';
import { MetadataSize } from './size';

export default function Roadmap({
  color,
  name,
  size,
  status = RoadmapColumnStatusType.Present,
  noHover,
  className,
}: {
  color: string;
  name: string;
  size: MetadataSize;
  status?: RoadmapColumnStatusType;
  noHover?: boolean;
  className?: string;
}) {
  const ref = React.useRef<HTMLSpanElement>(null);
  const { darkMode } = useDarkMode();
  const [toolTipEnabled, setToolTipEnabled] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setToolTipEnabled(true);
    }
  }, []);

  const content = (
    <Pill size={size} pillStyle={PillStyle.Secondary} className={className} noHover={noHover}>
      <Icon
        style={{ fill: colorWithOpacity(color, 10, darkMode) }}
        icon={roadmapColumnTypeToIcon(status)}
      />
      <span ref={ref}>{name}</span>
    </Pill>
  );

  if (!toolTipEnabled) {
    return content;
  }
  return (
    <Tooltip disableHoverableContent content={<div>{name}</div>}>
      {content}
    </Tooltip>
  );
}
