import * as React from 'react';
import { parseBoolean } from '../../shared/utils/utils';
import { useComponentDidMount } from './useComponentDidMount';

const forceLightModeKey = 'forceLightMode';
const forceDarkModeKey = 'forceDarkMode';

interface DarkModeContext {
  darkMode: boolean;
  systemDefaultDarkMode: boolean;
  forceDarkMode(): void;
  forceLightMode(): void;
  forced: boolean;
  resetDarkModePreference(): void;
}

const darkModeContext = React.createContext<DarkModeContext | null>(null);

export function DarkMode({ children }: { children: React.ReactNode }) {
  const match = window.matchMedia('(prefers-color-scheme: dark)');
  const [systemDefaultDarkMode, setSystemDefaultDarkMode] = React.useState(match.matches);

  const lightModeForcedFromStorage = React.useMemo(() => {
    const lightModeForcedValue = localStorage.getItem(forceLightModeKey);
    return lightModeForcedValue ? parseBoolean(lightModeForcedValue) : false;
  }, []);

  const darkModeForcedFromStorage = React.useMemo(() => {
    const darkModeForcedValue = localStorage.getItem(forceDarkModeKey);
    return darkModeForcedValue ? parseBoolean(darkModeForcedValue) : false;
  }, []);

  const [lightModeForced, setLightModeForced] = React.useState(lightModeForcedFromStorage);
  const [darkModeForced, setDarkModeForced] = React.useState(darkModeForcedFromStorage);

  useComponentDidMount(() => {
    function handleColorSchemeChange(e: MediaQueryListEvent) {
      setSystemDefaultDarkMode(e.matches);
    }
    match.addListener(handleColorSchemeChange);
    return () => match.removeListener(handleColorSchemeChange);
  });

  const darkMode = (darkModeForced || systemDefaultDarkMode) && !lightModeForced;

  return (
    <darkModeContext.Provider
      value={{
        darkMode,
        systemDefaultDarkMode,
        forced: lightModeForced || darkModeForced,
        forceDarkMode: () => {
          localStorage.removeItem(forceLightModeKey);
          localStorage.setItem(forceDarkModeKey, 'true');
          setDarkModeForced(true);
          setLightModeForced(false);
        },
        forceLightMode: () => {
          localStorage.removeItem(forceDarkModeKey);
          localStorage.setItem(forceLightModeKey, 'true');
          setLightModeForced(true);
          setDarkModeForced(false);
        },
        resetDarkModePreference: () => {
          localStorage.removeItem(forceLightModeKey);
          localStorage.removeItem(forceDarkModeKey);
          setLightModeForced(false);
          setDarkModeForced(false);
        },
      }}
    >
      {children}
    </darkModeContext.Provider>
  );
}

export function useDarkMode() {
  const context = React.useContext(darkModeContext)!;
  return context;
}
