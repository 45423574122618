import cn from 'classnames';
import * as React from 'react';
import { labelColors } from '../../../shared/utils/colors';
import { hexToRgb } from '../../utils/color';
import { BaseButton, Button, ButtonStyle } from './button';
import styles from './colorPicker.module.scss';
import { Icon } from './icon';
import Popover from './popover';
import { TextInput } from './textInput';
import { Tooltip } from './tooltip';

function colorAsCSSProperty(color: string | null): React.CSSProperties {
  let foreground;

  if (color === null) {
    foreground = 'var(--gray12)';
  } else if (labelColors.includes(color)) {
    foreground = `var(--${color}A10)`;
  } else {
    const rgb = hexToRgb(color);
    if (!rgb) {
      foreground = 'var(--grayA10)';
    } else {
      foreground = color.includes('#') ? color : `#${color}`;
    }
  }

  return {
    '--color': foreground,
  } as React.CSSProperties;
}

export function ColorIndicator({
  children,
  color,
  className,
  onClick,
}: {
  color: string | null;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div
      onClick={onClick}
      className={cn(styles.colorIndicator, className)}
      style={colorAsCSSProperty(color)}
    >
      {children}
    </div>
  );
}

function ColorIndicatorHover({
  children,
  color,
  className,
  onClick,
}: {
  color: string | null;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className={styles.colorIndicatorContainer} style={colorAsCSSProperty(color)}>
      <ColorIndicator onClick={onClick} className={className} color={color}>
        {children}
      </ColorIndicator>
    </div>
  );
}

export function ColorPickerContent({
  color,
  onColorPicked,
  renderPreview,
  noCustomColor,
  showNull,
}: {
  color?: string | null;
  onColorPicked: (color: string | null) => void;
  renderPreview?: (color: string, forceDarkMode: boolean) => React.ReactNode;
  noCustomColor?: boolean;
  showNull?: boolean;
}) {
  const [colorPickerVariant, setColorPickerVariant] = React.useState<'preset' | 'custom'>(
    color && !labelColors.includes(color) ? 'custom' : 'preset'
  );
  const [customColor, setCustomColor] = React.useState(
    color && !labelColors.includes(color) ? color : '858585'
  );

  const [isValidColor, setIsValidColor] = React.useState(true);

  const content =
    colorPickerVariant === 'custom' ? (
      <>
        <div className={cn('row', styles.customColor)}>
          <ColorIndicator color={customColor} />
          <span className="oneLine bodyM">HEX #</span>
          <TextInput
            className={styles.input}
            value={customColor}
            onChange={e => {
              const isValid = /^[0-9A-F]{0,6}$/i.test(e.currentTarget.value);
              if (isValid) {
                setCustomColor(e.currentTarget.value);
              }

              const isComplete = /^[0-9A-F]{6}$/i.test(e.currentTarget.value);
              setIsValidColor(isComplete);
            }}
          />
          <Tooltip disabled={isValidColor} content="The specified color is invalid">
            <Button
              disabled={!isValidColor}
              buttonStyle={ButtonStyle.Primary}
              onClick={() => onColorPicked(customColor)}
            >
              Save
            </Button>
          </Tooltip>
        </div>
        {renderPreview && (
          <>
            <div className={cn('col', styles.customColor)}>
              <div className={styles.colorPreviewContainer}>
                <div className={styles.light}>{renderPreview(customColor ?? 'gray', false)}</div>
                <div className={styles.dark}>{renderPreview(customColor ?? 'gray', true)}</div>
              </div>
              <div className="bodyS grayed">Custom colors are adjusted to ensure readability.</div>
            </div>
          </>
        )}
      </>
    ) : (
      <div className={styles.colorPickerGrid}>
        {showNull && (
          <div className={styles.colorPickerGridItem} key={`color-null`}>
            <ColorIndicatorHover color={null} onClick={() => onColorPicked(null)}>
              {color === null && <Icon icon="select_checkmark" className={styles.checkIcon} />}
            </ColorIndicatorHover>
          </div>
        )}
        {labelColors.map(c => (
          <div className={styles.colorPickerGridItem} key={`color-${c}`}>
            <ColorIndicatorHover color={c} onClick={() => onColorPicked(c)}>
              {color === c && <Icon icon="select_checkmark" className={styles.checkIcon} />}
            </ColorIndicatorHover>
          </div>
        ))}
      </div>
    );

  return (
    <div className={styles.content}>
      <div className={styles.contentHeading}>
        {colorPickerVariant === 'preset' ? 'Choose a color' : 'Custom color'}
      </div>
      {content}
      {!noCustomColor && (
        <div className={styles.contentFooter}>
          <Button
            onClick={() =>
              setColorPickerVariant(colorPickerVariant === 'custom' ? 'preset' : 'custom')
            }
          >
            {colorPickerVariant === 'preset' ? 'Custom color' : 'Choose from color presets'}
          </Button>
        </div>
      )}
    </div>
  );
}

export function ColorPicker({
  initialColor,
  onColorPicked,
  children,
  renderPreview,
  onOpenChanged,
}: {
  initialColor?: string;
  onColorPicked?: (color: string | null) => void;
  children?: React.ReactNode;
  renderPreview?: (color: string, forceDarkMode: boolean) => React.ReactNode;
  onOpenChanged?: (open: boolean) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [color, setColor] = React.useState<string | null>(initialColor ?? null);

  return (
    <Popover
      asChild
      content={
        <ColorPickerContent
          renderPreview={renderPreview}
          color={color}
          onColorPicked={c => {
            setColor(c);
            onColorPicked?.(c);
            onOpenChanged?.(false);
            setOpen(false);
          }}
        />
      }
      open={open}
      onOpenChange={o => {
        setOpen(o);
        onOpenChanged?.(o);
      }}
      contentOptions={{
        align: 'end',
      }}
    >
      {children ?? (
        <BaseButton className={styles.colorButton} buttonStyle={ButtonStyle.SecondarySubtle}>
          <ColorIndicator color={color ?? '858585'} />
        </BaseButton>
      )}
    </Popover>
  );
}
