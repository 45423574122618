import React from 'react';
import { IssueStatusSortMode } from '../../sync/__generated/models';
import { sortModeToString } from '../syncEngine/selectors/issues';
import { ButtonStyle, IconButton } from './new/button';
import { Tooltip } from './new/tooltip';

export function SortIndicator({
  sortMode,
  disableEditing,
  className,
  changeOrder,
}: {
  sortMode: IssueStatusSortMode;
  disableEditing?: boolean;
  className?: string;
  changeOrder: (
    originalSortMode: IssueStatusSortMode
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  if (!sortMode || sortMode === IssueStatusSortMode.Manual) {
    return null;
  }

  let icon = 'none';

  switch (sortMode) {
    case IssueStatusSortMode.LastStatusAsc:
    case IssueStatusSortMode.CreatedAsc:
    case IssueStatusSortMode.UpdatedAsc:
    case IssueStatusSortMode.ImpactAsc:
    case IssueStatusSortMode.EffortAsc:
    case IssueStatusSortMode.ImpactEffortAsc:
    case IssueStatusSortMode.CycleAsc:
    case IssueStatusSortMode.DueDateAsc:
      icon = 'sort_up';
      break;
    case IssueStatusSortMode.LastStatusDesc:
    case IssueStatusSortMode.CreatedDesc:
    case IssueStatusSortMode.UpdatedDesc:
    case IssueStatusSortMode.ImpactDesc:
    case IssueStatusSortMode.EffortDesc:
    case IssueStatusSortMode.ImpactEffortDesc:
    case IssueStatusSortMode.CycleDesc:
    case IssueStatusSortMode.DueDateDesc:
      icon = 'sort_down';
  }

  return (
    <Tooltip
      content={
        <div className="textCenter">
          {!disableEditing && (
            <>
              Reverse the order
              <br />
            </>
          )}
          <span className="grayed">Ordered by {sortModeToString(sortMode)}</span>
        </div>
      }
    >
      <IconButton
        disabled={disableEditing}
        onClick={changeOrder(sortMode)}
        className={className}
        icon={icon}
        buttonStyle={ButtonStyle.BareSubtle}
      />
    </Tooltip>
  );
}
