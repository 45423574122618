import cn from 'classnames';
import * as React from 'react';
import {
  CodeReviewRequest as CodeReviewRequestModel,
  CodeReviewRequestState,
} from '../../../../sync/__generated/models';
import ExternalLink from '../../externalLink';
import { Icon, IconSize } from '../icon';
import menuStyles from '../menu/menu.module.scss';
import { Tooltip } from '../tooltip';
import styles from './codeReviewRequest.module.scss';
import Pill from './pill';
import { MetadataSize } from './size';

export default function CodeReviewRequest({
  state,
  count,
  size = MetadataSize.Small,
  showLabel = false,
}: {
  state: CodeReviewRequestState;
  count?: number;
  size?: MetadataSize;
  showLabel?: boolean;
}) {
  let icon;
  let label;

  switch (state) {
    case CodeReviewRequestState.Open:
      icon = 'git_pull_request_open';
      label = 'Open';
      break;
    case CodeReviewRequestState.Merged:
      icon = 'git_merge';
      label = 'Merged';
      break;
    case CodeReviewRequestState.Closed:
    case CodeReviewRequestState.Locked:
      icon = 'git_pull_request_closed';
      label = 'Closed';
      break;
  }

  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  return (
    <Pill
      size={size}
      iconOnly={count === undefined}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Icon
        className={cn({
          [styles.open]: state === CodeReviewRequestState.Open,
        })}
        size={iconSize}
        icon={icon}
      />
      {count}
      {showLabel && (
        <span
          className={cn({
            [styles.open]: state === CodeReviewRequestState.Open,
          })}
        >
          {label}
        </span>
      )}
    </Pill>
  );
}

export function CodeReviewRequestDetail({
  codeReview,
  className,
  noTooltip,
}: {
  codeReview: CodeReviewRequestModel;
  className?: string;
  noTooltip?: boolean;
}) {
  const link = codeReview.links[0] ?? null;

  const linkContent = (
    <div>
      <ExternalLink href={link?.url}>
        <Pill>
          <Icon icon="external_link" size={IconSize.Size16} />
          <span className="ml2">Deployment</span>
        </Pill>
      </ExternalLink>
    </div>
  );

  return (
    <ExternalLink
      className={cn('row', menuStyles.item, styles.item, className)}
      href={codeReview.details.url}
    >
      <span className={styles.number}>#{codeReview.details.number}</span>
      <span className="ellipsis bodyM grow">{codeReview.details.title}</span>
      <CodeReviewRequest showLabel state={codeReview.state} />
      {link && noTooltip && linkContent}
      {link && !noTooltip && <Tooltip content={link.name}>{linkContent}</Tooltip>}
    </ExternalLink>
  );
}

export function CodeReviewTooltipContents({
  codeReviews,
}: {
  codeReviews: CodeReviewRequestModel[];
}) {
  return (
    <div
      className={styles.container}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {codeReviews.map(cr => {
        return <CodeReviewRequestDetail key={cr.id} codeReview={cr} noTooltip />;
      })}
    </div>
  );
}
