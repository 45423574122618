import debugModule from 'debug';
import { EditorType } from '../types';

export const debug = debugModule('TextArea');

export function withDebug(editor: EditorType) {
  const { insertText, insertFragment, insertData } = editor;

  editor.insertText = text => {
    debug('insertText', text);
    insertText(text);
  };

  editor.insertFragment = fragment => {
    debug('insertFragment', fragment);
    insertFragment(fragment);
  };

  editor.insertData = data => {
    debug('insertData', data);

    function debugData(type: string) {
      const d = data.getData(type);
      if (d) {
        debug(`insertData - ${type}`, d);
      }
    }

    debugData('text/plain');
    debugData('text/html');
    debugData('application/x-slate-fragment');

    insertData(data);
  };

  return editor;
}
