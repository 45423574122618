import { KeyboardEvent } from 'react';
import { Editor, Path, Point, Range, Text } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { HistoryEditor } from '../plugins/history';
import { EditorType, Elements } from '../types';

function breakOutOfSmartTodos(editor: EditorType, e: KeyboardEvent): boolean {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || node.type !== Elements.SmartTodo) {
    return false;
  }

  // current text is empty?
  if (KitemakerNode.safeString(node).length || node.children.some(e => !Text.isText(e))) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.setBlockElement(editor, Elements.Paragraph, { at: path });
  });

  return true;
}

function deleteOutOfSmartTodos(editor: EditorType, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || node.type !== Elements.SmartTodo) {
    return false;
  }

  // is our cursor at the start of the block?
  const nodes = Array.from(Editor.nodes(editor, { at: path, mode: 'lowest' }));
  if (!nodes.length) {
    return false;
  }

  if (!Path.equals(selection.focus.path, nodes[0][1]) || selection.focus.offset !== 0) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.setBlockElement(editor, Elements.Paragraph, { at: path });
  });

  return true;
}

function splitSmartTodos(editor: EditorType, e: KeyboardEvent): boolean {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || node.type !== Elements.SmartTodo) {
    return false;
  }

  const [start] = Editor.edges(editor, path);

  const isFocusAtBeginning = Point.equals(start, selection.focus);
  if (!isFocusAtBeginning) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.insertNodes(
      editor,
      [
        {
          type: Elements.SmartTodo,
          todoId: node.todoId,
          indent: node.indent,
          children: [{ text: '' }],
        },
      ],
      { at: path }
    );
  });

  return true;
}

export function smartTodosOnKeyDown() {
  return (editor: EditorType, e: KeyboardEvent): boolean => {
    for (const handler of [breakOutOfSmartTodos, deleteOutOfSmartTodos, splitSmartTodos]) {
      if (handler(editor, e)) {
        return true;
      }
    }

    return false;
  };
}
