/**
* THIS FILE WAS AUTOMATICALLY GENERATED. NEVER EVER (EVER) EDIT IT BY HAND.
* MODIFY sync.json AND RE-RUN yarn generate-sync-engine
**/

/* eslint-disable quotes */
import { Descendant } from "slate";

export interface User {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'User';
    username: string;
    name: string | null;
    primaryEmail: string;
    avatar: string | null;
    verified: boolean;
    completedTutorials: string[];
    preferences: Record<string, unknown>;
    emailNotificationDelay: number | null;
    lastOrganizationId: string | null;
}

export interface Organization {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Organization';
    name: string;
    slug: string;
    actorId: string;
    ssoDomain: string | null;
    activeProductId: string | null;
    activeProductPlanId: string | null;
    lastPaymentError: number | null;
    productTierExceeded: boolean;
    webhooksEnabled: boolean;
    newRoadmapsEnabled: boolean | null;
    aiEnabled: boolean | null;
    documentsEnabled: boolean | null;
    releasesEnabled: boolean | null;
    customEmojis: Record<string, { url: string; actorId: string; timestamp: number; }>;
    avatar: string | null;
}

export interface OrganizationMember {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'OrganizationMember';
    organizationId: string;
    userId: string;
    lastSpaceId: string | null;
    favoriteSpaceIds: string[] | null;
    starred: any;
    snoozed: Snooze[] | null;
    feedbackNotificationsEnabled: boolean;
    invited: boolean;
    guest: boolean;
    role: MemberRole;
    deactivated: boolean;
}

export interface Update {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Update';
    key: string;
    link: string;
    dependencyIds: string[];
    actorIds: string[];
    notification: boolean;
    read: boolean;
    organizationId: string;
    userId: string;
    organizationMemberId: string;
    context: string | null;
    integrationType: IntegrationType | null;
    icon: string | null;
    entityId: string | null;
    commentThreadId: string | null;
    updateType: UpdateType;
    plainTextAction: string;
    markdownAction: string;
    fallbackTitle: string;
    richContent: Descendant[] | null;
    inlineCommentContext: Descendant[] | null;
    siblingEntityIds: string[] | null;
    breadcrumbs: string[] | null;
}

export interface IntegrationUser {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'IntegrationUser';
    type: IntegrationType;
    customIntegrationType: string | null;
    domain: string | null;
    externalId: string;
    externalName: string;
    integrationExternalId: string | null;
    organizationId: string;
    organizationMemberIds: string[];
    userIds: string[];
    configValid: boolean;
}

export interface Integration {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Integration';
    type: IntegrationType;
    customIntegrationType: string | null;
    domain: string | null;
    config: string;
    externalId: string;
    organizationId: string;
}

export interface Application {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Application';
    name: string;
    avatar: string | null;
    token: string;
    revoked: boolean;
    internal: boolean;
    organizationId: string;
}

export interface PersonalAccessToken {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'PersonalAccessToken';
    name: string;
    token: string;
    revoked: boolean;
    organizationId: string;
    userId: string;
    organizationMemberId: string;
}

export interface Space {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Space';
    name: string;
    slug: string;
    key: string;
    organizationId: string;
    tutorial: boolean;
    roadmapEnabled: boolean;
    cyclesEnabled: boolean;
    publicRoadmap: boolean;
    publicRoadmapMetadata: boolean;
    actorId: string;
    backgroundType: BackgroundType;
    backgroundConfig: string;
    private: boolean;
    members: string[];
    sort: string;
    automationEvents: AutomationEvents;
    redirects: any;
    activeCycleId: string | null;
    upcomingCycleId: string | null;
    autoArchive: AutoArchivePeriod | null;
    staleIssues: StaleIssuePeriod | null;
    cycleDuration: number;
    cycleCooldown: number;
    cycleStartDay: number;
    addToCurrentCycle: boolean;
    utcOffset: number | null;
    timezone: string;
    defaultNewStatusId: string | null;
    defaultDoneStatusId: string | null;
}

export interface SpaceRoadmap {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'SpaceRoadmap';
    spaceId: string;
    roadmapId: string;
    sort: string;
}

export interface IssueStatus {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'IssueStatus';
    spaceId: string;
    name: string;
    statusType: IssueStatusType;
    sortMode: IssueStatusSortMode;
    issueLimit: number | null;
    watcherIds: string[] | null;
}

export interface Board {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Board';
    spaceId: string;
    name: string;
    key: string;
    sort: string;
    shared: boolean | null;
    sharedMetadata: boolean | null;
    sharedWorkItems: boolean | null;
    defaultStatusType: IssueStatusType;
}

export interface BoardColumn {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'BoardColumn';
    boardId: string;
    statusId: string;
    sort: string;
}

export interface IssueLabel {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'IssueLabel';
    spaceId: string;
    name: string;
    color: string;
}

export interface OrganizationLabel {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'OrganizationLabel';
    organizationId: string;
    name: string;
    color: string;
}

export interface Comment {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Comment';
    actorId: string;
    body: string;
    entityId: string;
    threadId: string;
    reply: boolean;
    reactions: Reaction[];
    inline: boolean;
    resolved: boolean;
    commentContext: any | null;
}

export interface Issue {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Issue';
    number: string;
    actorId: string;
    spaceId: string;
    title: string;
    statusId: string;
    previousStatusId: string | null;
    sort: string;
    assigneeIds: string[];
    watcherIds: string[];
    labelIds: string[];
    partial: boolean | null;
    effortId: string | null;
    impactId: string | null;
    public: boolean;
    publicMetadata: boolean;
    displayedUpdatedAt: number;
    lastStatusUpdate: number | null;
    codeReviewRequestIds: string[];
    dueDate: number | null;
    closedAt: number | null;
    archivedAt: number | null;
    provenance: EntityProvenance | null;
}

export interface Cycle {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Cycle';
    spaceId: string;
    actorId: string;
    number: string;
    title: string;
    cycleStatus: CycleStatus;
    summary: string | null;
    startDate: number;
    endDate: number;
    activityIds: string[];
    commentIds: string[];
    history: CycleHistory | null;
}

export interface CycleEntity {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'CycleEntity';
    entityId: string;
    cycleId: string;
    sort: string;
    ghost: boolean;
}

export interface CycleTodo {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'CycleTodo';
    cycleId: string;
    todoId: string;
    sort: string;
}

export interface CycleGoal {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'CycleGoal';
    cycleId: string;
    entityId: string | null;
    actorId: string;
    sort: string;
    status: TodoStatus;
    goalContents: Descendant[];
    memberIds: string[];
}

export interface Dependency {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Dependency';
    enablesId: string;
    dependsOnId: string;
    dependencyType: DependencyType;
}

export interface Theme {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Theme';
    number: string;
    actorId: string;
    spaceId: string;
    title: string;
    horizon: ThemeHorizon;
    previousHorizon: ThemeHorizon | null;
    sort: string;
    color: string;
    image: string | null;
    assigneeIds: string[];
    watcherIds: string[];
    labelIds: string[];
    issueIds: string[];
    effortId: string | null;
    impactId: string | null;
    displayedUpdatedAt: number;
    archivedAt: number | null;
}

export interface CodeReviewRequest {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'CodeReviewRequest';
    codeReviewType: IntegrationType;
    organizationId: string;
    state: CodeReviewRequestState;
    details: CodeReviewRequestDetails;
    links: CodeReviewRequestLink[];
}

export interface CollaborativeDoc {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'CollaborativeDoc';
    organizationId: string;
    content: Descendant[];
    entityId: string;
}

export interface Feedback {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Feedback';
    number: string;
    organizationId: string;
    actorId: string;
    companyId: string | null;
    ownerIds: string[];
    personId: string | null;
    tagIds: string[];
    title: string;
    processed: boolean;
    watcherIds: string[];
    processedAt: number | null;
}

export interface FeedbackTag {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'FeedbackTag';
    organizationId: string;
    name: string;
    color: string;
}

export interface Company {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Company';
    organizationId: string;
    name: string;
    avatar: string | null;
    domain: string | null;
}

export interface Person {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Person';
    organizationId: string;
    companyId: string | null;
    name: string;
    email: string | null;
    externalId: string | null;
}

export interface Insight {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Insight';
    actorId: string;
    feedbackId: string;
    entityIds: string[];
    contents: string;
    orphaned: boolean;
}

export interface Snippet {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Snippet';
    spaceId: string;
    name: string;
    contents: string;
}

export interface Activity {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Activity';
    entityId: string;
    actorId: string;
    activityType: ActivityType;
    details: ActivityDetails;
    reactions: Reaction[];
}

export interface Todo {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Todo';
    entityId: string;
    actorId: string;
    key: string;
    sort: string;
    todoContents: Descendant[];
    status: TodoStatus;
    orphaned: boolean;
    indent: number;
    effortId: string | null;
    impactId: string | null;
    parentId: string | null;
    memberIds: string[];
    labelIds: string[];
    codeReviewRequestIds: string[];
    connectedEntityId: string | null;
    connectedExternalIssueId: string | null;
    explicitLinkStatus: boolean;
    dueDate: number | null;
}

export interface Impact {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Impact';
    organizationId: string;
    spaceId: string | null;
    level: number;
    abbrevation: string;
    name: string;
    color: string;
}

export interface Effort {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Effort';
    organizationId: string;
    spaceId: string | null;
    level: number;
    abbrevation: string;
    name: string;
    color: string;
}

export interface Folder {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Folder';
    lookupKey: string;
    name: string;
    organizationId: string;
    parentId: string | null;
    icon: string | null;
    sort: string;
}

export interface Doc {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Doc';
    number: string;
    title: string;
    sort: string;
    folderId: string | null;
    organizationId: string;
    actorId: string;
    icon: string | null;
    archivedAt: number | null;
    watcherIds: string[];
}

export interface Initiative {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Initiative';
    number: string;
    title: string;
    sort: string;
    organizationId: string;
    memberIds: string[];
    issueIds: string[];
    labelIds: string[];
    watcherIds: string[] | null;
    actorId: string;
    color: string;
    impactId: string | null;
    effortId: string | null;
    archivedAt: number | null;
    startDate: number | null;
    dueDate: number | null;
    oldTheme: { spaceId: string, number: string } | null;
    displayedUpdatedAt: number;
}

export interface InitiativeSpace {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'InitiativeSpace';
    spaceId: string;
    initiativeId: string;
    sort: string;
}

export interface Roadmap {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Roadmap';
    name: string;
    number: string;
    sort: string;
    organizationId: string;
    roadmapType: RoadmapType;
    shared: boolean;
    sharedMetadata: boolean;
    actorId: string;
    color: string;
}

export interface RoadmapColumn {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'RoadmapColumn';
    roadmapId: string;
    name: string;
    columnType: RoadmapColumnStatusType;
    sort: string;
    sortMode: IssueStatusSortMode;
}

export interface RoadmapInitiative {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'RoadmapInitiative';
    initiativeId: string;
    columnId: string;
    previousColumnId: string | null;
    sort: string;
    lastColumnUpdate: number | null;
}

export interface ExternalIssue {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'ExternalIssue';
    externalId: string;
    type: IntegrationType;
    organizationId: string;
    externalIssueStatus: ExternalIssueStatus;
    title: string;
    url: string;
    number: string;
    fauxDeleted: boolean;
    statusDetails: { id: string; name: string; color?: string | null; icon?: string | null; iconUrl?: string | null; } | null;
    assignees: { id: string; name: string; avatarUrl: string | null; }[];
    labels: { id: string; name: string; color: string | null; }[];
    project: { id: string; name: string; url: string | null; key: string | null; };
    priority: { id: string; name: string; color: string | null; } | null;
}

export interface View {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'View';
    organizationId: string;
    actorId: string;
    filters: any;
    sort: string;
    name: string;
    color: string;
    viewType: ViewType;
    visibility: ViewVisibility;
}

export interface Release {
    id: string;
    version: number;
    deleted: boolean;
    createdAt: number;
    updatedAt: number;
    __typename: 'Release';
    organizationId: string;
    title: string;
    number: string;
    releaseStatus: ReleaseStatus;
    dueDate: number | null;
    actorId: string;
    memberIds: string[];
    watcherIds: string[];
    issueIds: string[];
    spaceIds: string[];
    archivedAt: number | null;
}

export interface Reaction {
    actorId: string;
    reactions: string[];
}

export interface Snooze {
    id: string;
    snoozedUntil: number;
}

export interface CodeReviewRequestDetails {
    number: string;
    title: string;
    url: string;
    commits: string[] | null;
    fromBranch: string | null;
    toBranch: string | null;
    defaultBranch: boolean | null;
}

export interface CodeReviewRequestLink {
    name: string;
    url: string;
}

export interface AutomationEvent {
    statusId: string;
}

export interface AutomationEvents {
    git_closed: AutomationEvent | null;
    git_in_progress: AutomationEvent | null;
    git_pull_request: AutomationEvent | null;
}

export interface IssueCreatedMetadata {
    statusId: string;
    fallbackStatus: string;
    labels: LabelChange[] | null;
    assigneeIds: string[] | null;
    entities: EntityChange[] | null;
}

export interface ThemeCreatedMetadata {
    horizon: ThemeHorizon;
    labels: LabelChange[] | null;
    assigneeIds: string[] | null;
    issues: EntityChange[] | null;
}

export interface InitiativeCreatedMetadata {
    labels: LabelChange[] | null;
    assigneeIds: string[] | null;
    issues: EntityChange[] | null;
    spaces: SpaceChange[] | null;
    roadmaps: RoadmapChange[] | null;
}

export interface StatusChangedMetadata {
    description: string;
    link: string;
    context: string | null;
    type: IntegrationType | null;
    autoArchived: boolean | null;
}

export interface LabelChange {
    labelId: string;
    fallbackLabel: string;
}

export interface EntityChange {
    entityId: string;
    fallbackSpaceKey: string;
    fallbackEntityTitle: string;
    fallbackEntityNumber: string;
    fallbackEntityType: string | null;
}

export interface SpaceChange {
    spaceId: string;
    fallbackSpaceName: string;
}

export interface RoadmapChange {
    roadmapId: string;
    columnId: string;
    fallbackColumnName: string;
    fallbackRoadmapName: string;
}

export interface CreatedActivityDetails {
    createdMetadata: CreatedMetadata;
}

export interface StatusChangedActivityDetails {
    statusId: string;
    fallbackStatus: string;
    originalStatusId: string | null;
    spaceId: string | null;
    fallbackSpace: string | null;
    statusChangedMetadata: StatusChangedMetadata | null;
}

export interface LabelsChangedActivityDetails {
    labelsAdded: LabelChange[];
    labelsRemoved: LabelChange[];
}

export interface ImpactChangedActivityDetails {
    impactId: string;
    fallbackImpactName: string;
    originalImpactId: string | null;
}

export interface EffortChangedActivityDetails {
    effortId: string;
    fallbackEffortName: string;
    originalEffortId: string | null;
}

export interface EntitiesChangedActivityDetails {
    entitiesAdded: EntityChange[];
    entitiesRemoved: EntityChange[];
}

export interface AssigneesChangedActivityDetails {
    assigneesAddedIds: string[];
    assigneesRemovedIds: string[];
}

export interface ImportedActivityDetails {
    type: IntegrationType;
    customIntegrationType: string | null;
    link: string;
    importType: string;
    importSource: string;
    importSourceType: string;
}

export interface MentionedActivityDetails {
    entityId: string;
    fallbackSpaceKey: string;
    fallbackEntityTitle: string;
    fallbackEntityNumber: string;
    context: string;
    commentThreadId: string | null;
    commentId: string | null;
    commentReply: boolean | null;
}

export interface MentionedInIntegrationActivityDetails {
    type: IntegrationType;
    description: string;
    context: string;
    link: string;
    icon: string | null;
    publicMetadata: any | null;
}

export interface CreatedFromActivityDetails {
    entityId: string;
    fallbackSpaceKey: string;
    fallbackEntityTitle: string;
    fallbackEntityNumber: string;
    duplicated: boolean | null;
    createdFromCommentId: string | null;
    createdFromTodoId: string | null;
}

export interface HorizonChangedActivityDetails {
    originalHorizon: ThemeHorizon;
    horizon: ThemeHorizon;
}

export interface CodeReviewRequestAddedActivityDetails {
    codeReviewRequestId: string;
}

export interface DependencyActivityDetails {
    targetId: string;
    dependencyType: DependencyType;
    inverse: boolean;
    removed: boolean;
    fallbackSpaceKey: string;
    fallbackEntityTitle: string;
    fallbackEntityNumber: string;
}

export interface TitleChangedActivityDetails {
    originalTitle: string;
    newTitle: string;
}

export interface TodoChangedActivityDetails {
    previousStatus: TodoStatus;
    newStatus: TodoStatus;
    key: string;
    todoId: string;
    context: string;
}

export interface StaleIssueActivityDetails {
    period: StaleIssuePeriod;
}

export interface SpacesChangedActivityDetails {
    spacesAdded: SpaceChange[];
    spacesRemoved: SpaceChange[];
}

export interface RoadmapsChangedActivityDetails {
    roadmapsAdded: RoadmapChange[];
    roadmapsRemoved: RoadmapChange[];
}

export interface RoadmapColumnChangedActivityDetails {
    roadmapId: string;
    columnId: string;
    originalColumnId: string;
    fallbackColumnName: string;
    fallbackRoadmapName: string;
}

export interface EntityArchivedActivityDetails {
    action: EntityArchivedAction;
}

export interface DueDateChangedActivityDetails {
    dueDate: number | null;
    originalDueDate: number | null;
}

export interface ProcessedChangedActivityDetails {
    processed: boolean;
    originalProcessed: boolean;
}

export interface CompanyPersonChangedActivityDetails {
    companyId: string | null;
    originalCompanyId: string | null;
    personId: string | null;
    originalPersonId: string | null;
}

export interface CycleHistory {
    completedEntities: string[];
    completedTodos: string[];
    incompleteEntities: string[];
    incompleteTodos: string[];
}

export interface ImportedProvenance {
    id: string;
    link: string | null;
    integrationType: IntegrationType | null;
    importType: string;
    importSource: string;
    importSourceType: string;
    provenanceType: EntityProvenanceType;
}

export interface DuplicatedProvenance {
    entityId: string;
    provenanceType: EntityProvenanceType;
}

export interface CreatedFromProvenance {
    entityId: string;
    createdFromTodoId: string | null;
    createdFromCommentId: string | null;
    provenanceType: EntityProvenanceType;
}

export type CreatedMetadata = IssueCreatedMetadata | ThemeCreatedMetadata | InitiativeCreatedMetadata;
export type ActivityDetails = CreatedActivityDetails | StatusChangedActivityDetails | LabelsChangedActivityDetails | ImpactChangedActivityDetails | EffortChangedActivityDetails | EntitiesChangedActivityDetails | AssigneesChangedActivityDetails | ImportedActivityDetails | CreatedFromActivityDetails | MentionedActivityDetails | MentionedInIntegrationActivityDetails | HorizonChangedActivityDetails | CodeReviewRequestAddedActivityDetails | DependencyActivityDetails | TitleChangedActivityDetails | TodoChangedActivityDetails | StaleIssueActivityDetails | SpacesChangedActivityDetails | RoadmapsChangedActivityDetails | RoadmapColumnChangedActivityDetails | EntityArchivedActivityDetails | DueDateChangedActivityDetails | ProcessedChangedActivityDetails | CompanyPersonChangedActivityDetails;
export type Entity = Issue | Cycle | Feedback | Initiative | Doc | Release;
export type Actor = User | Application | Integration | IntegrationUser;
export type EntityProvenance = ImportedProvenance | DuplicatedProvenance | CreatedFromProvenance;

export enum EffortSize {
    Small = "SMALL",
    Medium = "MEDIUM",
    Large = "LARGE"
}

export enum ImpactSize {
    Small = "SMALL",
    Medium = "MEDIUM",
    Large = "LARGE"
}

export enum BackgroundType {
    Color = "COLOR",
    DailyImage = "DAILY_IMAGE",
    Gradient = "GRADIENT",
    Image = "IMAGE"
}

export enum IntegrationType {
    Discord = "DISCORD",
    Email = "EMAIL",
    Github = "GITHUB",
    Gitlab = "GITLAB",
    Figma = "FIGMA",
    Slack = "SLACK",
    Slack2 = "SLACK2",
    Trello = "TRELLO",
    Vanta = "VANTA",
    Zapier = "ZAPIER",
    Custom = "CUSTOM",
    Intercom = "INTERCOM",
    Sentry = "SENTRY",
    Linear = "LINEAR",
    Hubspot = "HUBSPOT"
}

export enum ActivityType {
    Created = "CREATED",
    StatusChanged = "STATUS_CHANGED",
    LabelsChanged = "LABELS_CHANGED",
    ImpactChanged = "IMPACT_CHANGED",
    EffortChanged = "EFFORT_CHANGED",
    EntitiesChanged = "ENTITIES_CHANGED",
    AssigneesChanged = "ASSIGNEES_CHANGED",
    Imported = "IMPORTED",
    DuplicatedTo = "DUPLICATED_TO",
    DuplicatedFrom = "DUPLICATED_FROM",
    CreatedFrom = "CREATED_FROM",
    Mentioned = "MENTIONED",
    MentionedInIntegration = "MENTIONED_IN_INTEGRATION",
    HorizonChanged = "HORIZON_CHANGED",
    CodeReviewRequestAdded = "CODE_REVIEW_REQUEST_ADDED",
    Dependency = "DEPENDENCY",
    TitleChanged = "TITLE_CHANGED",
    TodoChanged = "TODO_CHANGED",
    StaleIssue = "STALE_ISSUE",
    SpacesChanged = "SPACES_CHANGED",
    RoadmapsChanged = "ROADMAPS_CHANGED",
    RoadmapColumnChanged = "ROADMAP_COLUMN_CHANGED",
    EntityArchived = "ENTITY_ARCHIVED",
    DueDateChanged = "DUE_DATE_CHANGED",
    ProcessedChanged = "PROCESSED_CHANGED",
    CompanyPersonChanged = "COMPANY_PERSON_CHANGED"
}

export enum DependencyType {
    DependsOn = "DEPENDS_ON",
    BlockedBy = "BLOCKED_BY"
}

export enum IssueStatusType {
    Backlog = "BACKLOG",
    Todo = "TODO",
    InProgress = "IN_PROGRESS",
    Done = "DONE",
    Archived = "ARCHIVED"
}

export enum IssueStatusSortMode {
    Manual = "MANUAL",
    ImpactEffortAsc = "IMPACT_EFFORT_ASC",
    ImpactEffortDesc = "IMPACT_EFFORT_DESC",
    ImpactAsc = "IMPACT_ASC",
    ImpactDesc = "IMPACT_DESC",
    EffortAsc = "EFFORT_ASC",
    EffortDesc = "EFFORT_DESC",
    CreatedAsc = "CREATED_ASC",
    CreatedDesc = "CREATED_DESC",
    UpdatedAsc = "UPDATED_ASC",
    UpdatedDesc = "UPDATED_DESC",
    LastStatusAsc = "LAST_STATUS_ASC",
    LastStatusDesc = "LAST_STATUS_DESC",
    CycleAsc = "CYCLE_ASC",
    CycleDesc = "CYCLE_DESC",
    DueDateAsc = "DUE_DATE_ASC",
    DueDateDesc = "DUE_DATE_DESC"
}

export enum ThemeHorizon {
    Now = "NOW",
    Next = "NEXT",
    Later = "LATER",
    Archived = "ARCHIVED"
}

export enum RoadmapColumnStatusType {
    Past = "PAST",
    Present = "PRESENT",
    Future = "FUTURE"
}

export enum RoadmapType {
    Column = "COLUMN",
    Timeline = "TIMELINE"
}

export enum CodeReviewRequestState {
    Open = "OPEN",
    Closed = "CLOSED",
    Merged = "MERGED",
    Locked = "LOCKED"
}

export enum TodoStatus {
    NotStarted = "NOT_STARTED",
    InProgress = "IN_PROGRESS",
    Done = "DONE"
}

export enum ExternalIssueStatus {
    NotStarted = "NOT_STARTED",
    InProgress = "IN_PROGRESS",
    Done = "DONE",
    Archived = "ARCHIVED"
}

export enum CycleStatus {
    NotStarted = "NOT_STARTED",
    Started = "STARTED",
    Stopped = "STOPPED"
}

export enum MemberRole {
    Admin = "ADMIN",
    Member = "MEMBER",
    Guest = "GUEST"
}

export enum AutoArchivePeriod {
    Day = "DAY",
    Week = "WEEK",
    Month = "MONTH",
    Quarter = "QUARTER"
}

export enum StaleIssuePeriod {
    Month = "MONTH",
    Quarter = "QUARTER",
    Semester = "SEMESTER",
    Year = "YEAR"
}

export enum UpdateType {
    Assigned = "ASSIGNED",
    Unassigned = "UNASSIGNED",
    Commented = "COMMENTED",
    CommentReplied = "COMMENT_REPLIED",
    LabelChanged = "LABEL_CHANGED",
    StatusChanged = "STATUS_CHANGED",
    CommentUserMentioned = "COMMENT_USER_MENTIONED",
    CommentReplyUserMentioned = "COMMENT_REPLY_USER_MENTIONED",
    DescriptionUserMentioned = "DESCRIPTION_USER_MENTIONED",
    Mentioned = "MENTIONED",
    MentionedInIntegration = "MENTIONED_IN_INTEGRATION",
    ImportCompleted = "IMPORT_COMPLETED",
    Dependency = "DEPENDENCY",
    TodoAssigned = "TODO_ASSIGNED",
    FeedbackCreated = "FEEDBACK_CREATED",
    CollaborativeDocumentMentioned = "COLLABORATIVE_DOCUMENT_MENTIONED",
    NewIssueInWatchedStatus = "NEW_ISSUE_IN_WATCHED_STATUS",
    StaleIssue = "STALE_ISSUE",
    AutoArchive = "AUTO_ARCHIVE",
    DueIssues = "DUE_ISSUES"
}

export enum EntityProvenanceType {
    Imported = "IMPORTED",
    Duplicated = "DUPLICATED",
    CreatedFrom = "CREATED_FROM"
}

export enum EntityArchivedAction {
    Archived = "ARCHIVED",
    Unarchived = "UNARCHIVED"
}

export enum ViewType {
    Feedback = "FEEDBACK"
}

export enum ViewVisibility {
    Public = "PUBLIC"
}

export enum ReleaseStatus {
    Planned = "PLANNED",
    InDevelopment = "IN_DEVELOPMENT",
    Released = "RELEASED"
}
