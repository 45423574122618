import { capitalize } from 'lodash';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClient } from 'urql';
import {
  SharedBoardQuery,
  SharedRoadmapQueryVariables,
} from '../../../../graphql__generated__/graphql';
import { generateId } from '../../../shared/utils/id';
import { issueTerm } from '../../../shared/utils/terms';
import { Board, Space } from '../../../sync/__generated/models';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import { Count } from '../../components/new/count';
import {
  EntityCard,
  EntityCardHeader,
  EntityCardMetadataContainer,
  EntityCardTitle,
  EntityMetadataRow,
} from '../../components/new/entityCard';
import {
  EntityEffort,
  EntityImpact,
  EntityLabels,
  EntityMembers,
} from '../../components/new/entityMetadata';
import { Logo } from '../../components/new/logo';
import { MetadataSize } from '../../components/new/metadata/size';
import { ScreenHeader } from '../../components/new/screenHeader';
import { VirtualizedBoardView } from '../../components/new/virtualizedBoardView';
import TitleSetter from '../../components/titleSetter';
import { OrganizationProvider } from '../../contexts/organizationContext';
import { SpaceProvider, useSpace } from '../../contexts/spaceContext';
import { messageFromGraphQLError } from '../../graphql/errors';
import { sharedBoardQuery } from '../../graphql/queries';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { boardColumnsForBoardSelector, boardSelector } from '../../syncEngine/selectors/boards';
import {
  filteredIssuesForStatusCountSelector,
  filteredIssuesForStatusSelector,
  issueSelector,
  statusSelector,
  useGetFilterdIssuesForBoard,
} from '../../syncEngine/selectors/issues';
import { organizationsSelector } from '../../syncEngine/selectors/organizations';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { useStateTransaction } from '../../syncEngine/state';
import { SyncEngineObject } from '../../syncEngine/types';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from '../errorScreens';
import LoadingScreen from '../loadingScreen';
import { Placeholder } from '../new/workItemBoardScreen/placeholder';
import styles from './sharedBoardScreen.module.scss';

function WorkItemCard({
  id,
  metadata,
  board,
  space,
}: {
  id: string;
  metadata: boolean;
  board?: Board | null;
  space: Space;
}) {
  const workItem = useRecoilValue(issueSelector(id));
  if (!workItem) {
    return null;
  }

  let content = (
    <EntityCard>
      <EntityCardHeader entityNumber={`${space?.key}-${workItem.number}`} />
      <div className="rowAlignStart">
        <EntityCardTitle type={issueTerm}>{workItem.title}</EntityCardTitle>
      </div>
      {metadata && (
        <>
          <EntityMetadataRow>
            <EntityLabels entity={workItem} size={MetadataSize.Small} />
          </EntityMetadataRow>
          <EntityCardMetadataContainer members={<EntityMembers entity={workItem} />}>
            <EntityImpact entity={workItem} />
            <EntityEffort entity={workItem} />
          </EntityCardMetadataContainer>
        </>
      )}
    </EntityCard>
  );

  if (board?.sharedWorkItems) {
    const link = {
      pathname: `/sharing/items/${workItem.id}`,
      state: {
        board: { name: board.name, link: `/sharing/board/${board.id}` },
      },
    };
    content = <Link to={link}>{content}</Link>;
  }

  return content;
}

function Header({ statusId }: { statusId: string }) {
  const space = useSpace();
  const status = useRecoilValue(statusSelector(statusId));
  const count = useRecoilValue(
    filteredIssuesForStatusCountSelector({ spaceId: space.id, statusId, filterId: '' })
  );
  if (!status) {
    return null;
  }
  return (
    <div className={styles.header}>
      <div className="row grow ellipsis">
        <div className="ellipsis mr8">{capitalize(status.name)}</div>
        <Count count={count} />
      </div>
    </div>
  );
}

function SharedBoardScreenContents({ boardId, metadata }: { boardId: string; metadata: boolean }) {
  const board = useRecoilValue(boardSelector(boardId));
  const space = useRecoilValue(spaceSelector(board?.spaceId));
  const organization = useRecoilValue(organizationsSelector(space?.organizationId));

  const columns = useRecoilValue(boardColumnsForBoardSelector(board?.id));
  const statusIds = columns.map(column => column.statusId);

  const getAllColumns = useGetFilterdIssuesForBoard(space?.id ?? '', board?.id);

  const renderColumnHeader = React.useCallback((statusId: string) => {
    return <Header statusId={statusId} />;
  }, []);
  const getSelector = React.useCallback(
    (columnId: string) =>
      filteredIssuesForStatusSelector({
        spaceId: space?.id,
        filterId: '',
        statusId: columnId,
      }),
    [space?.id]
  );

  if (!space || !organization) {
    return null;
  }

  return (
    <OrganizationProvider organizationId={organization.id}>
      <SpaceProvider spaceId={space.id}>
        <div className={styles.sharedRoadmapScreen}>
          <TitleSetter title={`${organization.name}  · Roadmap by Kitemaker`} />
          <ScreenHeader hideNotifications>
            <Breadcrumbs
              breadcrumbs={[
                { name: organization.name },
                { name: space.name },
                { name: board?.name ?? 'Current' },
              ]}
            />
          </ScreenHeader>
          <VirtualizedBoardView
            id={boardId}
            className={styles.board}
            columnIds={statusIds}
            getSelector={getSelector}
            getAllColumns={getAllColumns}
            spacerHeight={8}
            columnHeaderHeight={32}
            renderColumnHeader={renderColumnHeader}
            renderCard={id => {
              return <WorkItemCard board={board} id={id} metadata={metadata} space={space} />;
            }}
            renderPlaceholder={columnId => {
              return <Placeholder statusId={columnId} />;
            }}
          />
          <Logo className={styles.logo} link />
        </div>
      </SpaceProvider>
    </OrganizationProvider>
  );
}

export function SharedBoardScreen() {
  const { params } = useRouteMatch<{ boardId: string }>();
  const client = useClient();
  const [loaded, setLoaded] = React.useState(false);
  const [renderMetadata, setRenderMetadata] = React.useState(false);
  const [error, setError] = React.useState('');
  const stateTransaction = useStateTransaction();

  trackerPageLoad('Shared Board', {
    boardId: params.boardId,
  });

  useComponentDidMount(() => {
    (async () => {
      const result = await client
        .query<SharedBoardQuery, SharedRoadmapQueryVariables>(sharedBoardQuery, {
          id: params.boardId,
        })
        .toPromise();

      if (result.error) {
        setError(
          messageFromGraphQLError(result.error.graphQLErrors) ?? 'An unknown error has occurred'
        );
        return;
      }

      if (!result.data?.sharedBoard) {
        setError('An unknown error has ocurred');
        return;
      }

      const {
        board,
        boardColumns,
        space,
        organization,
        issues,
        statuses,
        impactLevels,
        effortLevels,
        metadata,
        labels,
        members,
      } = result.data.sharedBoard;

      const now = Date.now();
      const defaultProps = {
        updatedAt: now,
        createdAt: now,
        deleted: false,
        version: 0,
      };

      const labelsToLoad: SyncEngineObject[] = (labels ?? []).map(label => ({
        ...label,
        ...defaultProps,
        __typename: 'IssueLabel',
      }));

      const usersToLoad: SyncEngineObject[] = (members ?? []).map(user => ({
        ...user,
        ...defaultProps,
        __typename: 'User',
      }));

      const issuesToLoad: SyncEngineObject[] = (issues ?? []).map(issue => ({
        ...issue,
        ...defaultProps,
        __typename: 'Issue',
      }));

      const organizationToLoad: SyncEngineObject = {
        ...organization,
        ...defaultProps,
        __typename: 'Organization',
      };

      const spaceToLoad: Partial<Space> & SyncEngineObject = {
        ...space,
        ...defaultProps,
        __typename: 'Space',
      } as any;

      const boardToLoad: Partial<Board> & SyncEngineObject = {
        ...board,
        ...defaultProps,
        __typename: 'Board',
      } as any;

      const organizationMembersToLoad: SyncEngineObject[] = usersToLoad.map(user => ({
        id: generateId(),
        userId: user.id,
        organizationId: organizationToLoad.id,
        ...defaultProps,
        __typename: 'OrganizationMember',
      }));

      const statusesToLoad: SyncEngineObject[] = (statuses ?? []).map(status => ({
        ...status,
        ...defaultProps,
        __typename: 'IssueStatus',
      }));

      const boardColumnsToLoad: SyncEngineObject[] = (boardColumns ?? []).map(boardColumn => ({
        ...boardColumn,
        ...defaultProps,
        __typename: 'BoardColumn',
      }));

      const impactsToLoad: SyncEngineObject[] = (impactLevels ?? []).map(impact => ({
        ...impact,
        ...defaultProps,
        __typename: 'Impact',
      }));

      const effortsToLoad: SyncEngineObject[] = (effortLevels ?? []).map(effort => ({
        ...effort,
        ...defaultProps,
        __typename: 'Effort',
      }));

      const allObjects = [
        ...usersToLoad,
        ...labelsToLoad,
        ...organizationMembersToLoad,
        ...issuesToLoad,
        ...statusesToLoad,
        ...impactsToLoad,
        ...effortsToLoad,
        ...boardColumnsToLoad,
        organizationToLoad,
        spaceToLoad,
        boardToLoad,
      ];

      stateTransaction(({ set }) => {
        set(allObjects);
      });
      setRenderMetadata(metadata);
      setLoaded(true);
    })();
  });

  if (!loaded) {
    return <LoadingScreen />;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{error}</BasicErrorScreen>;
  }

  return <SharedBoardScreenContents boardId={params.boardId} metadata={renderMetadata} />;
}
