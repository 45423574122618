import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  SegmentedControl,
  SegmentedControlVariant,
} from '../../../components/new/segmentedControl';
import { Setting, SettingsPage } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { usePersistentSettings } from '../../../contexts/usePersistentSettings';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { useUpdatePreferences } from '../../../syncEngine/actions/users';
import {
  animationsDisabledSelector,
  highlightAssignedSelector,
  moveCycleIndicatorSelector,
  wideDescriptionsSelector,
  wideInitiativeHeadersSelector,
} from '../../../syncEngine/selectors/users';

export function UserSettingsScreen() {
  const { darkMode, forceDarkMode, forceLightMode, resetDarkModePreference, forced } =
    useDarkMode();
  const { openInApp, setOpenInApp } = usePersistentSettings();
  const updatePreferences = useUpdatePreferences();
  const animationsDisabled = useRecoilValue(animationsDisabledSelector);
  const highlightAssigned = useRecoilValue(highlightAssignedSelector);
  const moveCycleIndicator = useRecoilValue(moveCycleIndicatorSelector);
  const wideDescriptions = useRecoilValue(wideDescriptionsSelector);
  const wideInitiativeHeaders = useRecoilValue(wideInitiativeHeadersSelector);

  return (
    <SettingsPage title="Account preferences">
      <Setting title="Interface appearance" vertical>
        <SegmentedControl
          options={[
            { id: 'light', icon: 'light', label: 'Light mode' },
            { id: 'dark', icon: 'dark', label: 'Dark mode' },
            { id: 'reset', icon: 'refresh', label: 'System default' },
          ]}
          value={forced ? (darkMode ? 'dark' : 'light') : 'reset'}
          variant={SegmentedControlVariant.Both}
          onValueChanged={(value: string) => {
            switch (value) {
              case 'dark':
                forceDarkMode();
                break;
              case 'light':
                forceLightMode();
                break;
              case 'reset':
                resetDarkModePreference();
                break;
            }
          }}
        />
      </Setting>

      <Setting
        title={'Open links in app by default'}
        description={'Tells the browser to open Kitemaker links in the app'}
      >
        <Switch checked={openInApp} onChange={checked => setOpenInApp(checked)} />
      </Setting>
      <Setting title={'Disable animations'} description={'Disables all animations in the UI'}>
        <Switch
          checked={animationsDisabled}
          onChange={checked => updatePreferences({ animationsDisabled: checked })}
        />
      </Setting>
      <Setting
        title={'Highlight my items'}
        description={'Applies borders to items where you are a member'}
      >
        <Switch
          checked={highlightAssigned}
          onChange={checked => updatePreferences({ highlightAssigned: checked })}
        />
      </Setting>
      <Setting
        title={'Cycle indicator top-left'}
        description={'Moves the cycle indicator to the top-left of work item cards'}
      >
        <Switch
          checked={moveCycleIndicator}
          onChange={checked => updatePreferences({ moveCycleIndicator: checked })}
        />
      </Setting>
      <Setting
        title={'Wider descriptions fields'}
        description={'Increases the width of description fields'}
      >
        <Switch
          checked={wideDescriptions}
          onChange={checked => updatePreferences({ wideDescriptions: checked })}
        />
      </Setting>
      <Setting
        title={'Wider initiative headers'}
        description={'Increases the width of initiative headers'}
      >
        <Switch
          checked={wideInitiativeHeaders}
          onChange={checked => updatePreferences({ wideInitiativeHeaders: checked })}
        />
      </Setting>
    </SettingsPage>
  );
}
