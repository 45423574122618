import cn from 'classnames';
import * as React from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { colorWithOpacity } from '../../../utils/color';
import styles from './pill.module.scss';
import { MetadataSize } from './size';

export enum PillStyle {
  Default,
  Primary,
  Secondary,
  Subtle,
  Empty,
}

export function pillColors(
  color: string,
  pillStyle: PillStyle,
  darkMode: boolean
): React.CSSProperties {
  let foreground;
  let background;
  let border;
  let icon;
  let hoverForeground;
  let hoverBackground;
  let hoverBorder;
  let hoverIcon;

  if (pillStyle === PillStyle.Primary) {
    foreground = colorWithOpacity(color, 11, darkMode);
    background = colorWithOpacity(color, 4, darkMode);
    border = colorWithOpacity(color, 3, darkMode);
    icon = colorWithOpacity(color, 10, darkMode);
    hoverForeground = colorWithOpacity(color, 11, darkMode);
    hoverBackground = colorWithOpacity(color, 4, darkMode);
    hoverBorder = colorWithOpacity(color, 7, darkMode);
    hoverIcon = colorWithOpacity(color, 10, darkMode);
  } else if (pillStyle === PillStyle.Secondary) {
    foreground = colorWithOpacity(color, 10, darkMode);
    background = `transparent`;
    border = colorWithOpacity(color, 4, darkMode);
    hoverForeground = colorWithOpacity(color, 10, darkMode);
    hoverBackground = colorWithOpacity(color, 3, darkMode);
    hoverBorder = colorWithOpacity(color, 7, darkMode);
  } else if (pillStyle === PillStyle.Subtle) {
    foreground = colorWithOpacity(color, 8, darkMode);
    background = `transparent`;
    icon = colorWithOpacity(color, 8, darkMode);
    border = colorWithOpacity(color, 3, darkMode);
  } else if (pillStyle === PillStyle.Empty) {
    foreground = colorWithOpacity(color, 9, darkMode);
    background = `transparent`;
    icon = colorWithOpacity(color, 8, darkMode);
    border = colorWithOpacity(color, 3, darkMode);
  }

  return {
    '--pill-color': foreground,
    '--pill-border': border,
    '--pill-background': background,
    '--pill-icon': icon,
    '--pill-hover-color': hoverForeground,
    '--pill-hover-border': hoverBorder,
    '--pill-hover-background': hoverBackground,
    '--pill-hover-icon': hoverIcon,
  } as React.CSSProperties;
}

function PillComponent(
  {
    children,
    color,
    size = MetadataSize.Small,
    pillStyle = PillStyle.Default,
    textOnly,
    iconOnly,
    noHover,
    noBorder,
    forceDarkMode,
    style,
    className,
    ...props
  }: {
    children: React.ReactNode;
    color?: string;
    size?: MetadataSize;
    pillStyle?: PillStyle;
    iconOnly?: boolean;
    textOnly?: boolean;
    noHover?: boolean;
    noBorder?: boolean;
    forceDarkMode?: boolean;
  } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { darkMode } = useDarkMode();
  const colorStyles = React.useMemo(
    () =>
      pillColors(
        color ?? 'gray',
        pillStyle,
        forceDarkMode !== undefined ? forceDarkMode : darkMode
      ),
    [color, darkMode, forceDarkMode, pillStyle]
  );

  return (
    <div
      {...props}
      ref={ref}
      className={cn(
        styles.pill,
        {
          [styles.noHover]: noHover,
          [styles.noBorder]: noBorder,
          [styles.iconOnly]: iconOnly,
          [styles.textOnly]: textOnly,
          [styles.small]: size === MetadataSize.Small,
          [styles.medium]: size === MetadataSize.Medium,
          bodyS: size === MetadataSize.Small,
          bodyM: size === MetadataSize.Medium,
        },
        className
      )}
      style={
        {
          ...colorStyles,
          ...style,
        } as React.CSSProperties
      }
    >
      {children}
    </div>
  );
}

const Pill = React.forwardRef(PillComponent);
export default Pill;
