import * as React from 'react';
import Hotkey from '../components/hotkey';
import { toast } from '../components/toast';

const undoContext = React.createContext<{
  setUndo: (content: string | React.ReactNode, undoAction: () => void) => void;
} | null>(null);

export function UndoProvider({ children }: { children: React.ReactNode }) {
  const undoInfo = React.useRef<{ hideToast: () => void; action: () => void } | null>(null);

  const value = React.useMemo(
    () => ({
      setUndo: (content: string | React.ReactNode, undoAction: () => void) => {
        const hideToast = toast.info(<div>{content}</div>, {
          id: 'undo',
          action: {
            label: 'Undo',
            hotkey: 'z',
            onClick: () => {
              hideToast();
              undoInfo.current = null;
              undoAction();
            },
          },
        });

        undoInfo.current = { action: undoAction, hideToast: () => hideToast() };
      },
    }),
    []
  );

  return (
    <undoContext.Provider value={value}>
      <Hotkey
        command={{
          id: `undo-action`,
          hotkey: 'z',
          unscoped: true,
          handler: () => {
            if (!undoInfo.current) {
              return;
            }
            undoInfo.current.hideToast();
            undoInfo.current.action();
            undoInfo.current = null;
          },
        }}
      />
      {children}
    </undoContext.Provider>
  );
}

export function useUndo() {
  return React.useContext(undoContext)!;
}
