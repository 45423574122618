import { safeLocalStorageGet } from './utils/localStorage';

const KEY = '__diagnostics';
const MAX_LENGTH = 1000;
const diagnostics: Array<Record<string, unknown>> = [];

const fromLocalStorage = safeLocalStorageGet(KEY, '[]');
try {
  const parsed: Array<Record<string, unknown>> = JSON.parse(fromLocalStorage);
  diagnostics.push(...parsed);
} catch (e) {
  //
}

let flushTimeout = -1;

function flushToLocalStorage() {
  if (flushTimeout !== -1) {
    window.clearTimeout(flushTimeout);
  }

  flushTimeout = window.setTimeout(() => {
    try {
      localStorage.setItem(KEY, JSON.stringify(diagnostics));
    } catch (e) {
      //
    }
  }, 10000);
}

export function appendDiagnostic(value: Record<string, unknown>) {
  diagnostics.push({ ...value, time: new Date().toISOString() });
  diagnostics.splice(0, diagnostics.length - MAX_LENGTH);
  flushToLocalStorage();
}

export function getDiagnostics() {
  return diagnostics;
}
