import { selectorFamily } from 'recoil';
import { ExternalIssue } from '../../../sync/__generated/models';
import { EXTERNAL_ISSUE_INTEGRATION_TYPES } from '../../api/externalIssues';
import { syncEngineState } from '../state';
import { integrationsForOrganizationSelector } from './organizations';

export const externalIssueSelector = selectorFamily({
  key: 'ExternalIssue',
  get:
    (externalIssueId: string | undefined | null) =>
    ({ get }) => {
      if (!externalIssueId) {
        return null;
      }
      return get(syncEngineState(externalIssueId)) as ExternalIssue | null;
    },
});

export const externalIssueIntegrationsForOrganizationSelector = selectorFamily({
  key: 'ExternalIssueIntegrationsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      return get(integrationsForOrganizationSelector(organizationId)).filter(i =>
        EXTERNAL_ISSUE_INTEGRATION_TYPES.includes(i.type)
      );
    },
});
