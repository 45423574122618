import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Screen } from '../../components/screen';
import { useOrganization } from '../../contexts/organizationContext';
import { FetchedMarker, OrganizationMarker } from '../../graphql/smartLoad';
import { documentIdByNumberSelector } from '../../syncEngine/selectors/documents';
import { entityDeletedSelector, entityExistsSelector } from '../../syncEngine/selectors/entities';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { NotFoundScreen } from '../errorScreens';
import { DeletedScreen } from '../errorScreens/deletedScreen';
import LoadingScreen from '../loadingScreen';
import DocumentScreen from './documentScreen';

interface RouteParams {
  docNumber: string;
}

export default function DocumentScreenWrapper(props: RouteComponentProps<RouteParams>) {
  const { match } = props;
  const organization = useOrganization();

  const { docNumber: docNumberOrId } = match.params;

  const docId = useRecoilValue(
    documentIdByNumberSelector({ organizationId: organization.id, docNumber: docNumberOrId })
  );
  const docIdRef = React.useRef(docId);
  const lastKnownGoodId = docId ?? docIdRef.current;
  const entityDeleted = useRecoilValue(entityDeletedSelector(lastKnownGoodId));
  const entityExists = useRecoilValue(entityExistsSelector(lastKnownGoodId));

  const currentOrgPartiallyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, true))
  );
  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );
  const fetched = useRecoilValue(markerState(FetchedMarker.id));

  if (lastKnownGoodId && entityDeleted) {
    return (
      <Screen>
        <DeletedScreen type="document" />
      </Screen>
    );
  }

  if (!lastKnownGoodId || !entityExists || !currentOrgPartiallyFetched) {
    if (!currentOrgPartiallyFetched || !currentOrgFullyFetched || !fetched) {
      return <LoadingScreen />;
    }

    return (
      <Screen>
        <NotFoundScreen />
      </Screen>
    );
  }

  return <DocumentScreen docId={lastKnownGoodId} />;
}
