import cn from 'classnames';
import { capitalize } from 'lodash';
import { parse } from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { ExternalAuthProviderType } from '../../../shared/auth';
import { externalAuthFlow } from '../../api/auth';
import googleLogo from '../../assets/images/new/google.svg?url';
import microsoftLogo from '../../assets/images/new/microsoft.svg?url';
import { useConfiguration } from '../../contexts/configurationContext';
import { trackerEvent } from '../../tracker';
import { LocationState } from '../../utils/history';
import { Button, ButtonSize, ButtonStyle } from './button';
import styles from './externalAuthButton.module.scss';

function providerToLogo(provider: ExternalAuthProviderType) {
  switch (provider) {
    case ExternalAuthProviderType.Google:
      return googleLogo;
    case ExternalAuthProviderType.Microsoft:
      return microsoftLogo;
  }
}

export function ExternalAuthButton({
  token,
  provider,
  className,
}: {
  token?: string;
  provider: ExternalAuthProviderType;
  className?: string;
}) {
  const { host, electronScheme } = useConfiguration();
  const location = useLocation<LocationState>();

  return (
    <Button
      className={cn('fullWidth', className)}
      size={ButtonSize.Large}
      buttonStyle={ButtonStyle.SecondaryOverlay}
      onClick={() => {
        trackerEvent(`${capitalize(provider)} Auth`);

        externalAuthFlow(
          `${host}/auth/external/${provider}/login${
            token ? `?token=${encodeURIComponent(token)}` : ''
          }`,
          electronScheme,
          {
            redirectBaseUrl: `${host}${location.state?.from?.pathname ?? ''}`,
            redirectQueryParams: location.state?.from?.search
              ? parse(location.state.from.search)
              : undefined,
          }
        );
      }}
    >
      <div className="rowCenter">
        <img src={providerToLogo(provider)} className={styles.logo} /> Log in with{' '}
        {capitalize(provider)}
      </div>
    </Button>
  );
}
