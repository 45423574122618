import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilteredListView } from '../../../components/new/filteredListView';
import { SpacesMetadata } from '../../../components/new/initativeSpaces';
import { ListViewItem } from '../../../components/new/listView';
import styles from '../../../components/new/menu/menu.module.scss';
import { MetadataSize } from '../../../components/new/metadata/size';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  snippetsForOrganizationSelector,
  snippetsForSpaceSelector,
} from '../../../syncEngine/selectors/snippets';
import { spaceSelector, spaceSettingsPath } from '../../../syncEngine/selectors/spaces';
import { trackerEvent } from '../../../tracker';
import commandMenuStyles from '../commandMenu.module.scss';

export function SnippetsContents({
  onClose,
  onSnippetPicked,
  spaceId,
  trackingContext,
}: {
  onClose: () => void;
  onSnippetPicked: (snippetId: string) => void;
  spaceId: string;
  trackingContext?: string;
}) {
  const location = useLocation();
  const history = useHistory();
  const organization = useOrganization();
  const space = useRecoilValue(spaceSelector(spaceId));

  const spaceSnippets = useRecoilValue(snippetsForSpaceSelector(space?.id));
  const orgSnippets = useRecoilValue(snippetsForOrganizationSelector(organization?.id));

  const snippets = space ? spaceSnippets : orgSnippets;

  const snippetItems: ListViewItem[] = snippets.map(snippet => ({
    id: snippet.id,
    icon: 'none',
    name: snippet.name,
    contents: (
      <div className="rowBetween fullWidth">
        {snippet.name || 'Untitled snippet'}

        {!space && (
          <SpacesMetadata spaceIds={[snippet.spaceId]} size={MetadataSize.Small} state={{}} />
        )}
      </div>
    ),
    onSelected: () => {
      if (trackingContext) {
        trackerEvent('Snippet Used', {
          type: 'modal',
          context: trackingContext,
        });
      }
      onClose();
      onSnippetPicked(snippet.id);
    },
  }));
  {
    space &&
      snippetItems.push({
        id: 'manage',
        contents: 'Manage snippets',
        name: 'Manage snippets',
        icon: 'settings',
        className: styles.manage,
        onSelected: () => {
          onClose();
          history.push({
            pathname: spaceSettingsPath(organization, space, 'snippets'),
            state: {
              backUrl: location.pathname,
              backSearch: history.location.search,
            },
          });
        },
      });
  }

  return (
    <div className="colStretch fullWidth fullHeight">
      <FilteredListView
        className="grow"
        itemClassName={styles.item}
        propertiesToSearch={['name']}
        items={snippetItems}
        filterPlaceholder="Select snippet"
        filterClassName={commandMenuStyles.inputDiv}
      />
    </div>
  );
}
