import { Editor, Point, Range } from 'slate';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { safeSelection } from '../../../shared/slate/utils';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { EditorType, Elements } from '../types';

const TYPES_TO_BREAK = [Elements.Link, Elements.Comment];

export function withInlineBreaking(editor: EditorType) {
  const { insertText } = editor;

  editor.insertText = text => {
    const selection = safeSelection(editor);

    if (!selection || !Range.isCollapsed(selection)) {
      insertText(text);
      return;
    }

    const parentEntry = Editor.above(editor, {
      match: node => KitemakerElement.isElement(node) && TYPES_TO_BREAK.includes(node.type),
      at: selection,
    });

    if (!parentEntry) {
      insertText(text);
      return;
    }

    const [, path] = parentEntry;
    const [, end] = Editor.edges(editor, path);

    if (Point.equals(end, selection.focus)) {
      KitemakerTransforms.move(editor, { distance: 1, unit: 'offset', reverse: false });
    }

    insertText(text);
  };

  return editor;
}
