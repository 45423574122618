import { sortBy } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { Feedback, FeedbackTag } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCreateTag } from '../../../syncEngine/actions/tags';
import { tagsForOrganizationSelector } from '../../../syncEngine/selectors/tags';
import { FilteredListViewHandle } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { ListViewItem, NO_KEYNAV } from '../listView';
import { Picker, PickerState } from '../picker';

export function feedbackToTagPickerState(feedback: Array<Feedback>): PickerState {
  return feedback.reduce((result, f) => {
    result[f.id] = f.tagIds;
    return result;
  }, {} as PickerState);
}

function createTagItem({
  newTagName,
  createTag,
  onDone,
  onTagAdded,
  issueIds,
  filterRef,
}: {
  newTagName: string;
  issueIds: string[];
  filterRef: React.RefObject<FilteredListViewHandle>;
  createTag: (name: string) => Promise<FeedbackTag | null>;
  onDone: () => void;
  onTagAdded: (issueIds: string[], tagId: string) => void;
}): ListViewItem {
  return {
    id: 'add',
    mouseDown: true,
    className: 'createItem',
    icon: 'add',
    onSelected: async shift => {
      const l = await createTag(newTagName);
      if (!l) {
        return;
      }

      onTagAdded(issueIds, l.id);
      filterRef.current?.clear();

      if (!shift) {
        onDone();
      }
    },
    contents: <div className="ellipsis">Create a tag named &quot;{newTagName}&quot;</div>,
  };
}

export function TagPicker({
  state,
  filterPlaceholder,
  filterClassName,
  additionalItems,
  additionalItemsFirst,
  onDone,
  onTagAdded,
  onTagRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  onDone: () => void;
  onTagAdded: (issueIds: string[], tagId: string) => void;
  onTagRemoved: (issueIds: string[], tagId: string) => void;
}) {
  const organization = useOrganization();
  const createTag = useCreateTag();
  const requestInProgress = React.useRef(false);
  const tags = useRecoilValue(tagsForOrganizationSelector(organization.id));

  const items: ListViewItem[] = sortBy(tags, tag => tag.name).map(tag => {
    const color = mapColor(tag.color);
    return {
      ...tag,
      mouseDown: true,
      contents: () => (
        <>
          <Icon icon="label" size={IconSize.Size20} style={{ fill: color }} />
          <span className="ellipsis">{tag.name}</span>
        </>
      ),
    };
  });

  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  const hideFooter = tags.length === 0;

  if (hideFooter) {
    items.push({
      id: NO_KEYNAV,
      contents: 'Type to create a new tag',
    });
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterLabel="Add tags"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Add tags'}
      ref={filterRef}
      multi
      hideFooter={hideFooter}
      state={state}
      items={items}
      propertiesToSearch={['name']}
      filter={(filterString, search) => {
        const results = search.search(filterString).map(r => r.item);
        const exactMatch = !!tags.find(l => l.name.toLowerCase() === filterString.toLowerCase());
        if (!exactMatch) {
          results.push(
            createTagItem({
              newTagName: filterString,
              issueIds: Object.keys(state),
              onDone,
              onTagAdded: onTagAdded,
              filterRef,
              createTag: async name => {
                if (requestInProgress.current) {
                  return null;
                }
                requestInProgress.current = true;
                const tag = await createTag(organization.id, name);
                requestInProgress.current = false;
                return tag;
              },
            })
          );
        }
        return results;
      }}
      onAdd={onTagAdded}
      onRemove={onTagRemoved}
      onDone={onDone}
    />
  );
}
