import { uniqBy } from 'lodash';
import * as React from 'react';
import { Emoji, EmojiSize } from '../../../components/new/emoji';
import { CustomEmoji } from '../../../syncEngine/selectors/organizations';
import { allEmojis, emojify } from '../../../utils/emoji';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { Elements } from '../../types';
import { HistoryEditor } from '../history';
import { SuggestionMatcher } from './withSuggestions';

export function emojiSuggestionMatcher(
  customEmojis?: Record<string, CustomEmoji>
): SuggestionMatcher {
  return {
    id: 'emoji',
    prefix: ':',
    suffix: ':',
    propertiesToSearch: ['name'],
    options: partialMatch => {
      if (!partialMatch) {
        return [];
      }

      return uniqBy(
        [
          ...allEmojis,
          ...Object.keys(customEmojis ?? {}).map(e => ({ id: e, name: e, value: `:${e}:` })),
        ],
        emoji => emoji.id
      ).map(emoji => ({
        id: emoji.id,
        value: emoji.id,
        name: emoji.name ?? emoji.id,
        render: function RenderEmojiSuggestion() {
          return (
            <div className="ellipsis noWrap row">
              <Emoji emoji={emoji.id} size={EmojiSize.Emoji20} className="mr8" />
              {emoji.value}
            </div>
          );
        },
      }));
    },
    onMatch: (editor, option, range) => {
      HistoryEditor.asBatch(editor, () => {
        if (customEmojis?.[option.id]) {
          KitemakerTransforms.insertNodes(
            editor,
            [{ type: Elements.CustomEmoji, emojiId: option.id, children: [{ text: '' }] }],
            {
              at: range,
              mode: 'lowest',
              select: true,
            }
          );
          KitemakerTransforms.move(editor, { distance: 1, unit: 'offset' });
          return;
        }

        KitemakerTransforms.insertNodes(editor, [{ text: emojify(`:${option.id}:`) }], {
          at: range,
          mode: 'lowest',
          select: true,
        });
      });
    },
    className: 'menuSmall',
  };
}
