import * as React from 'react';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import {
  FocusReason,
  useHasKeyNavigationFocus,
  useHasKeyNavigationSelection,
  useIsMultiSelectable,
  useKeyNavigationProviderId,
  useSetKeyNavigationFocus,
  useToggleKeyNavigationSelection,
} from '../keyNavigation';
import { KEY_NAVIGATION_DATA_ATTRIBUTE, KEY_NAVIGATION_PROVIDER_DATA_ATTRIBUTE } from './dom';

export function KeyNavigationElementComponent(
  {
    children,
    id,
    style,
    ...rest
  }: { children: React.ReactNode; id: string } & React.ComponentPropsWithoutRef<'div'>,
  ref: any
) {
  const providerId = useKeyNavigationProviderId();
  const setFocus = useSetKeyNavigationFocus();
  const toggleSelected = useToggleKeyNavigationSelection();
  const hasFocus = useHasKeyNavigationFocus(id);
  const hasSelection = useHasKeyNavigationSelection(id);
  const isMultiSelectable = useIsMultiSelectable(id);

  return (
    <div
      {...{
        [KEY_NAVIGATION_DATA_ATTRIBUTE]: id,
        [KEY_NAVIGATION_PROVIDER_DATA_ATTRIBUTE]: providerId,
      }}
      onMouseEnter={() => setFocus(id, FocusReason.Mouse)}
      onClick={e => {
        if (e.shiftKey && isMultiSelectable) {
          let target = e.target as HTMLElement | null;
          while (target && target !== e.currentTarget) {
            const role = target.getAttribute('role');
            if (role === 'checkbox' || role === 'textbox') {
              return;
            }
            target = target.parentElement;
          }

          e.preventDefault();
          e.stopPropagation();
          toggleSelected();
        }
      }}
      ref={ref}
      style={
        {
          ...style,
          backgroundColor: hasFocus ? 'var(--keynav-focus-color)' : style?.backgroundColor,
          border: hasSelection ? '1px solid var(--keynav-border-color)' : style?.border,
          transition: 'var(--keynav-animation)',
        } as React.CSSProperties
      }
      {...rest}
    >
      {children}
    </div>
  );
}

export function useKeyNavigationElement(id: string, ref: React.RefObject<HTMLElement>) {
  const providerId = useKeyNavigationProviderId();
  const setFocus = useSetKeyNavigationFocus();
  const toggleSelected = useToggleKeyNavigationSelection();
  const hasFocus = useHasKeyNavigationFocus(id);
  const hasSelection = useHasKeyNavigationSelection(id);
  const isMultiSelectable = useIsMultiSelectable(id);

  const initialBackgroundRef = React.useRef('');
  const initialBorderRef = React.useRef('');
  const firstRender = React.useRef(true);

  useComponentDidMount(() => {
    function onMouseEnter() {
      setFocus(id, FocusReason.Mouse);
    }

    function onClick(e: MouseEvent) {
      if (e.shiftKey && isMultiSelectable) {
        let target = e.target as HTMLElement | null;
        while (target && target !== e.currentTarget) {
          const role = target.getAttribute('role');
          if (role === 'checkbox' || role === 'textbox') {
            return;
          }
          target = target.parentElement;
        }

        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        toggleSelected();
      }
    }

    if (ref.current) {
      initialBackgroundRef.current = ref.current.style.backgroundColor;
      initialBorderRef.current = ref.current.style.border;
      ref.current.setAttribute(KEY_NAVIGATION_DATA_ATTRIBUTE, id);
      ref.current.setAttribute(KEY_NAVIGATION_PROVIDER_DATA_ATTRIBUTE, providerId ?? '');
    }

    ref.current?.addEventListener('mouseenter', onMouseEnter);
    ref.current?.addEventListener('click', onClick);
    return () => {
      ref.current?.removeEventListener('mouseenter', onMouseEnter);
      ref.current?.removeEventListener('click', onClick);
    };
  });

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const backgroundColor = hasFocus ? 'var(--keynav-focus-color)' : null;
    const border = hasSelection ? '1px solid var(--keynav-selection-color)' : null;
    ref.current.style.backgroundColor = backgroundColor ?? initialBackgroundRef.current;
    ref.current.style.border = border ?? initialBorderRef.current;

    // FIXME: this is gruesome but it's a quick fix while we figure out a better idea
    if (!firstRender.current) {
      ref.current.style.transition = 'var(--keynav-animation)';
      return;
    }
    firstRender.current = false;
  }, [hasFocus, hasSelection]);

  return null;
}

export const KeyNavigationElement = React.forwardRef(KeyNavigationElementComponent);
