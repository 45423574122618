import { useConfiguration } from '../contexts/configurationContext';
import { Modals, useModals } from '../contexts/modalContext';
import { useModelManager } from '../graphql/modelManager';
import { clearCacheAndReload } from '../graphql/offlineCache';
import { calculateRecoilStats, useStateTransaction } from '../syncEngine/state';
import { alternateComboKey, mainComboKey } from '../utils/config';
import { CommandDefinition, CommandGroup } from './state';

export function useDeveloperCommandGroup(): CommandDefinition[] {
  const { production } = useConfiguration();
  const stateTransaction = useStateTransaction();
  const modelManager = useModelManager();
  const { featureFlags } = useConfiguration();
  const modals = useModals();

  const commands: CommandDefinition[] = [
    {
      id: 'clear-offline-cache',
      description: 'Clear offline cache and reload',
      handler: async () => {
        await clearCacheAndReload();
      },
      group: CommandGroup.Other,
    },
  ];

  if (featureFlags.FEATURE_TOGGLE_DEVELOPER) {
    commands.push(
      ...[
        {
          id: 'developer-modal',
          aliases: ['feature', 'flags', 'developer'],
          description: 'Open system settings (developer)',
          icon: 'settings',
          handler: () => {
            modals.openModal(Modals.SystemSettings);
          },
        },
        {
          id: 'developer-diagnostics',
          aliases: ['diagnostics'],
          description: 'Diagnostic Information (developer)',
          icon: 'settings',
          hotkey: `${mainComboKey}+${alternateComboKey}+shift+d`,
          handler: () => {
            modals.openModal(Modals.Diagnostics);
          },
        },
      ]
    );
  }

  if (!production) {
    commands.push({
      id: 'graphql-sandbox',
      description: 'Go to GraphQL sandbox',
      handler: () => {
        window.location.pathname = '/graphql';
      },
    });
    commands.push({
      id: 'graphql-developer-sandbox',
      description: 'Go to Developer API GraphQL sandbox',
      handler: () => {
        window.location.pathname = '/developers/graphql';
      },
    });
    commands.push({
      id: 'dump-recoil-state',
      hotkey: `${alternateComboKey}+shift+d`,
      description: 'Dump Recoil state',
      handler: () => {
        stateTransaction(({ dumpState }) => {
          const state = dumpState();
          const stats = calculateRecoilStats(state);
          // eslint-disable-next-line no-console
          console.log({ state, stats });
        });
      },
    });
    commands.push({
      id: 'transaction-queue-control',
      description: modelManager.transactionQueuePaused()
        ? 'Resume transaction queue'
        : 'Pause transaction queue',
      handler: () => {
        if (modelManager.transactionQueuePaused()) {
          modelManager.resumeTransactionQueue();
        } else {
          modelManager.pauseTransactionQueue();
        }
      },
    });
  }

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Developer }));
}
