import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { IntegrationType } from '../../../../sync/__generated/models';
import { IntegrationCard, SettingsPage, SettingsSection } from '../../../components/new/settings';
import { toast } from '../../../components/toast';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  integrationsForOrganizationSelector,
  organizationPath,
} from '../../../syncEngine/selectors/organizations';
import { getQueryParameter, removeQueryParameter } from '../../../utils/query';
import styles from './settingsScreen.module.scss';

export const allIntegrationTypes = [
  IntegrationType.Github,
  IntegrationType.Gitlab,
  IntegrationType.Figma,
  IntegrationType.Slack,
  IntegrationType.Slack2,
  IntegrationType.Discord,
  IntegrationType.Zapier,
  IntegrationType.Intercom,
  IntegrationType.Sentry,
  IntegrationType.Vanta,
  IntegrationType.Linear,
  IntegrationType.Hubspot,
] as const;

type VisibleIntegrations = typeof allIntegrationTypes[number];

const descriptions: Record<VisibleIntegrations, string> = {
  [IntegrationType.Discord]: `Get information about mentioned ${issueTerm}s in Discord and link Discord conversations.`,
  [IntegrationType.Figma]: `Automatically updated Figma thumbnails in ${issueTerm}s & mentions from Figma comments.`,
  [IntegrationType.Github]: `Automate workflows based on commits & PRs and link mentions of ${issueTerm}s back to Kitemaker.`,
  [IntegrationType.Gitlab]: `Automatically move ${issueTerm}s based on ${issueTerm} numbers mentioned in GitLab commit messages.`,
  [IntegrationType.Intercom]: `Create feedback from Intercom conversations.`,
  [IntegrationType.Slack]: `Get information about mentioned ${issueTerm}s in Slack and to link Slack conversations to Kitemaker ${issueTerm}s.`,
  [IntegrationType.Slack2]: `Create feedback and ${issueTerm}s from Slack and retrieve info from Kitemaker directly in Slack.`,
  [IntegrationType.Zapier]: `Build Zaps that connect to Kitemaker and automate tasks across a wide range of tools.`,
  [IntegrationType.Sentry]: `Easily create ${issueTerm}s from Sentry issues.`,
  [IntegrationType.Vanta]: `Push security tasks to Vanta to ensure your security compliance.`,
  [IntegrationType.Linear]: `Easily link and create Linear issues right from Kitemaker.`,
  [IntegrationType.Hubspot]: `Create feedback from Hubspot tickets`,
};

function integrationTypeSupportsMultipleIntances(type: IntegrationType) {
  return type === IntegrationType.Slack2;
}

export function IntegrationsSettingsScreen() {
  const organization = useOrganization();
  const { featureFlags } = useConfiguration();

  const allIntegrations = allIntegrationTypes.filter(integration => {
    if (integration === IntegrationType.Linear && !featureFlags.FEATURE_TOGGLE_ISSUE_TRACKERS) {
      return false;
    }
    if (integration === IntegrationType.Slack2 && !featureFlags.FEATURE_TOGGLE_NEW_SLACK) {
      return false;
    }
    return true;
  });

  const history = useHistory();
  const installedIntegrations = useRecoilValue(
    integrationsForOrganizationSelector(organization.id)
  );

  const pending = getQueryParameter(history, 'pendingIntegration');
  if (pending) {
    removeQueryParameter(history, 'pendingIntegration');
    toast.success(`The ${pending} integration was successfully installed`);
  }

  return (
    <SettingsPage
      title="Integrations"
      description={
        <>
          <p>Manage your organization's integrations and enhance the capabilities of Kitemaker.</p>
          {pending && <p>The {pending} installation was successfully installed</p>}
        </>
      }
    >
      <SettingsSection>
        <div className={styles.integrationsGrid}>
          {allIntegrations.map(type => {
            const integration = installedIntegrations.find(i => i.type === type);
            let link = integration
              ? organizationPath(organization, `settings/integration/${integration.id}`)
              : organizationPath(organization, `settings/integrations/new/${type.toLowerCase()}`);

            if (integrationTypeSupportsMultipleIntances(type)) {
              link = organizationPath(organization, `settings/integrations/${type.toLowerCase()}`);
            }

            return (
              <IntegrationCard
                key={type}
                integrationType={type}
                description={descriptions[type]}
                link={link}
                installed={!!integration}
              />
            );
          })}
        </div>
      </SettingsSection>
    </SettingsPage>
  );
}
