import { Descendant } from 'slate';
import { filterNotNull } from '../utils/convenience';
import { KitemakerElement, KitemakerNode } from './kitemakerNode';
import { DocumentLike, Elements, MentionElement } from './types';

export enum Mentions {
  User,
  Group,
  Issue,
  Label,
  Entity,
  Todo,
}

export interface Mention {
  type: Mentions;
  id: string;
  mentionId?: string;
  actorId?: string;
  context: Descendant;
  contextId: number;
}

export function findMentions(doc: DocumentLike): Mention[] {
  const mentions = doc.flatMap((node, index) => {
    const mentionNodeEntries = Array.from(KitemakerNode.descendants(node, {})).filter(([n]) => {
      return KitemakerElement.isElement(n) && KitemakerElement.isMention(n);
    });

    return mentionNodeEntries.map(([n]) => {
      const element = n as MentionElement;

      switch (element.type) {
        case Elements.User:
          return {
            type: Mentions.User,
            id: element.userId,
            context: node,
            contextId: index,
            actorId: element.actorId,
            mentionId: element.mentionId,
          };
        case Elements.Group:
          return {
            type: Mentions.Group,
            id: `${element.groupType}:${element.groupId}`,
            context: node,
            contextId: index,
            actorId: element.actorId,
            mentionId: element.mentionId,
          };
        case Elements.Issue:
          if (!element.issueId) {
            return null;
          }
          return {
            type: Mentions.Issue,
            id: element.issueId,
            mentionId: element.mentionId,
            actorId: element.actorId,
            context: node,
            contextId: index,
          };
        case Elements.Entity:
          return {
            type: Mentions.Entity,
            id: element.entityId,
            mentionId: element.mentionId,
            actorId: element.actorId,
            context: node,
            contextId: index,
          };
        case Elements.TodoMention:
          return {
            type: Mentions.Todo,
            id: element.todoId,
            mentionId: element.mentionId,
            actorId: element.actorId,
            context: node,
            contextId: index,
          };
        case Elements.Label:
          return { type: Mentions.Label, id: element.labelId, context: node, contextId: index };
      }
    });
  });

  return filterNotNull(mentions);
}
