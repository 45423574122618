import cn from 'classnames';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { resendPendingInvite } from '../../api/auth';
import linkButtonStyles from '../../components/linkButton.module.scss';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { AuxiliaryScreen } from '../../components/screen';

export function PendingInviteScreen() {
  const location = useLocation<{ organizationName: string; email: string }>();
  const history = useHistory();

  const organizationName = React.useMemo(
    () => location.state?.organizationName ?? 'an existing organization',
    [location]
  );
  const email = React.useMemo(() => location.state?.email ?? 'email@example.com', [location]);

  return (
    <AuxiliaryScreen>
      <div className="mt24 mb24 form">
        <form
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push('/terms');
          }}
        >
          <Logo className="mb32" />
          <div className="headingXL oneLine mb4">Join {organizationName}</div>
          <div className="mt16 grayed bodyM">
            You've been invited to <span className="headingS">{organizationName}</span>. Please
            check your email <span className="headingS">{email}</span> to join.
          </div>
          <div className="mt16 mb40 bodyM">
            <span className="grayed">Didn't get the invite? </span>
            <span
              className={cn('clickable', linkButtonStyles.medium)}
              onClick={() => resendPendingInvite()}
            >
              Re-send invitation.
            </span>
          </div>
          <div className="grayed bodyM textCenter mb24">OR</div>

          <Button
            buttonStyle={ButtonStyle.Secondary}
            size={ButtonSize.Large}
            type="submit"
            className="fullWidth"
          >
            <div className="row">Create new organization</div>
          </Button>
        </form>
      </div>
    </AuxiliaryScreen>
  );
}
