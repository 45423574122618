import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { columnDragLocationAtom } from '../../../components/new/virtualizedBoardView/columnHeader';
import styles from './columnDragHandle.module.scss';

export function ColumnDragHandle() {
  const dragLocation = useRecoilValue(columnDragLocationAtom);
  if (!dragLocation) {
    return null;
  }
  return (
    <div
      className={styles.dragHandle}
      style={{
        top: dragLocation.y,
        left: dragLocation.x,
      }}
    />
  );
}
