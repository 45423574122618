import React from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { localStorageEffect } from '../syncEngine/effects';

export const scrollPositionState = atomFamily<number, string>({
  key: 'ScrollPosition',
  default: 0,
  effects: key => [localStorageEffect(`__scroll__${key}`)],
});

export default function useSaveScroll(
  objId: string,
  domRef: React.MutableRefObject<HTMLElement | null>
) {
  const [scrollTop, setScrollTop] = useRecoilState(scrollPositionState(objId));
  React.useLayoutEffect(() => {
    const node = domRef.current;
    if (scrollTop >= 0) {
      node?.scrollTo(0, scrollTop);
    }
    return () => {
      if (node?.scrollTop !== undefined) {
        setScrollTop(node?.scrollTop);
      }
    };
  }, [objId]);
}
