import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Snippet } from '../../../../sync/__generated/models';
import ExternalLink from '../../../components/externalLink';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { SettingsListItem, SettingsPage } from '../../../components/new/settings';
import { TextInput } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import { useSpace } from '../../../contexts/spaceContext';
import { useDeleteSnippets } from '../../../syncEngine/actions/snippets';
import { snippetsForSpaceSelector } from '../../../syncEngine/selectors/snippets';
import { useSpaceSettingsPath } from '../../../syncEngine/selectors/spaces';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import styles from './settingsScreen.module.scss';

function SnippetRow({
  snippet,
  isFirst,
  isLast,
}: {
  snippet: Snippet;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const space = useSpace();
  const deleteSnippets = useDeleteSnippets();
  const spaceSettingsPath = useSpaceSettingsPath();

  return (
    <SettingsListItem
      isFirst={isFirst}
      isLast={isLast}
      link={spaceSettingsPath(space.id, `snippets/${snippet.id}`)}
      meta={
        <Tooltip content="Delete">
          <IconButton
            buttonStyle={ButtonStyle.BareSubtle}
            className={styles.deleteIcon}
            icon="delete"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              deleteSnippets([snippet.id]);
            }}
          />
        </Tooltip>
      }
    >
      {snippet.name}
    </SettingsListItem>
  );
}

export function SnippetsSettingsScreen() {
  const space = useSpace();
  const allSnippets = useRecoilValue(snippetsForSpaceSelector(space.id));
  const spaceSettingsPath = useSpaceSettingsPath();
  const history = useHistory();

  const [searchString, setSearchString] = React.useState('');
  const [snippets, setSnippets] = React.useState(allSnippets);

  const search = React.useMemo(() => {
    return new FuzzySearcher(FuzzySearcherConfiguration.Autocomplete, ['name'], allSnippets);
  }, [allSnippets]);

  React.useEffect(() => {
    if (!searchString) {
      setSnippets(allSnippets);
      return;
    }

    setSnippets(search.search(searchString).map(i => i.item));
  }, [search, allSnippets, searchString]);

  return (
    <SettingsPage
      title="Snippets"
      description={
        <>
          <p>
            Snippets are shortcuts for things you write repeatedly or that you want to standardize
            in descriptions and comments. They are similar to templates, but are more versatile in
            how and where they can be applied.
          </p>
          <p>
            <ExternalLink className="link" href="https://guide.kitemaker.co/snippets">
              Read more in the guide
            </ExternalLink>
          </p>
        </>
      }
    >
      <div className="rowStretch mb16">
        <Button
          className="mr16"
          buttonStyle={ButtonStyle.Primary}
          icon="add"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push(spaceSettingsPath(space.id, `snippets/new`));
          }}
        >
          New snippet
        </Button>
        <TextInput
          className={styles.searchInput}
          value={searchString}
          onChange={v => setSearchString(v.currentTarget.value)}
          placeholder="Filter snippets"
        />
      </div>

      {snippets.map((snippet, index) => (
        <SnippetRow
          key={index}
          snippet={snippet}
          isFirst={index === 0}
          isLast={index === snippets.length - 1}
        />
      ))}
    </SettingsPage>
  );
}
