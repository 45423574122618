import { uniq } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Editor, Path, Point, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import uuid from 'uuid';
import { KitemakerElement, KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { filterNotNull } from '../../../../shared/utils/convenience';
import { IntegrationType } from '../../../../sync/__generated/models';
import {
  integrationTypeToExternalIssueType,
  searchExternalIssues,
  storeExternalIssueBExternalId,
} from '../../../api/externalIssues';
import { Icon } from '../../../components/new/icon';
import { iconForIntegrationType } from '../../../components/new/integrationIcon';
import { TooltipIfTruncated } from '../../../components/new/tooltipIfTruncated';
import { ModalContext, Modals, useModals } from '../../../contexts/modalContext';
import { externalIssueIntegrationsForOrganizationSelector } from '../../../syncEngine/selectors/externalIssues';
import { stringifyIntegrationType } from '../../../utils/integrations';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { HistoryEditor } from '../history';
import { InsertMenuInlineChoice } from './insertMenuSuggestionMatcher';
import { SuggestionMatcher, SuggestionOption } from './withSuggestions';

const CREATE_VALUE = '__create__';
const SEARCH_TIMEOUT = 300;

function externalIssueSuggestionMatcher(
  organizationId: string,
  type: IntegrationType,
  modals: ModalContext
): SuggestionMatcher {
  const cache: { query: string } = {
    query: '',
  };
  let searchTimeout = -1;

  return {
    id: type.toLowerCase(),
    prefix: `/${type.toLowerCase()} `,
    handleTrailingSpace: true,
    searchOnEmptyString: true,
    options: () => {
      return [
        {
          id: 'placeholder',
          value: 'placeholder',
          render: () => (
            <div className="grayed">Type to search {stringifyIntegrationType(type)} issues</div>
          ),
        },
        {
          id: 'create',
          value: CREATE_VALUE,
          render: () => (
            <>
              <Icon icon="add" />
              <span className="grayed">Create {stringifyIntegrationType(type)} issue</span>
            </>
          ),
        },
      ];
    },
    search: async (query: string, asyncResults: (results: SuggestionOption[]) => void) => {
      cache.query = query.trim();

      if (!query.trim().length) {
        return [
          {
            id: 'placeholder',
            value: 'placeholder',
            render: () => (
              <div className="grayed">Type to search {stringifyIntegrationType(type)} issues</div>
            ),
          },
          {
            id: 'create',
            value: CREATE_VALUE,
            render: () => (
              <>
                <Icon icon="add" />
                <span className="grayed">Create {stringifyIntegrationType(type)} issue</span>
              </>
            ),
          },
        ];
      }

      if (searchTimeout !== -1) {
        window.clearTimeout(searchTimeout);
      }

      searchTimeout = window.setTimeout(async () => {
        try {
          const results = await searchExternalIssues(organizationId, type, query);
          if (!query.trim() || cache.query.trim() !== query.trim()) {
            return;
          }
          const options = filterNotNull(
            results.map(result => {
              if (!result.externalId) {
                return null;
              }

              return {
                id: result.externalId,
                value: result.externalId,
                render: () => (
                  <>
                    <span className="noWrap grayed mr4">{result.number}</span>
                    <TooltipIfTruncated>{result.title}</TooltipIfTruncated>
                  </>
                ),
              };
            })
          );

          asyncResults([
            ...(results.length === 0
              ? [
                  {
                    id: 'placeholder',
                    value: query,
                    render: () => <div className="grayed">No issues found</div>,
                  },
                ]
              : []),
            ...options,
            {
              id: 'create',
              value: query,

              render: () => (
                <>
                  <Icon icon="add" />
                  <span className="grayed">Create {stringifyIntegrationType(type)} issue</span>
                </>
              ),
              footer: true,
            },
          ]);
        } catch (e) {
          return asyncResults([
            {
              id: 'placeholder',
              value: 'placeholder',
              render: () => <div className="grayed">No issues found</div>,
            },
          ]);
        }
      }, SEARCH_TIMEOUT);

      return [
        {
          id: 'placeholder',
          value: 'placeholder',
          render: () => <div className="grayed">Searching...</div>,
        },
        {
          id: 'create',
          value: query,
          render: () => (
            <>
              <Icon icon="add" />
              <span className="grayed">Create {stringifyIntegrationType(type)} issue</span>
            </>
          ),
        },
      ];
    },
    onMatch(editor, option, range, autoComplete) {
      cache.query = '';

      if (option.id === 'placeholder') {
        return;
      }

      let elementType: Elements.InlineExternalIssueLink | Elements.ExternalIssueLink =
        Elements.InlineExternalIssueLink;
      if (Path.equals(range.anchor.path, range.focus.path)) {
        const parent = KitemakerNode.parent(editor, range.anchor.path);
        if (KitemakerElement.isElement(parent) && parent.type === Elements.Paragraph) {
          const [start, end] = Editor.edges(editor, range.anchor.path.slice(0, -1));

          if (Point.equals(start, Range.start(range)) && Point.equals(end, Range.end(range))) {
            elementType = Elements.ExternalIssueLink;
          }
        }
      }

      const refId = uuid.v4();

      if (option.id === 'create') {
        // FIXME: crude back to make the suggestion hover disappear
        KitemakerTransforms.select(editor, range);
        KitemakerTransforms.collapse(editor, { edge: 'start' });
        ReactEditor.blur(editor);

        modals.openModal(Modals.NewExternalIssue, {
          type,
          title: option.value.replace(CREATE_VALUE, ''),
          onCreated: externalIssueId => {
            if (!externalIssueId) {
              KitemakerTransforms.select(editor, range);
              KitemakerTransforms.collapse(editor, { edge: 'end' });
              return;
            }
            if (elementType === Elements.InlineExternalIssueLink) {
              KitemakerTransforms.insertMention(
                editor,
                {
                  type: elementType,
                  externalIssueId,
                  externalIssueType: integrationTypeToExternalIssueType(type),
                  children: [{ text: '' }],
                },
                range,
                autoComplete
              );
            } else {
              KitemakerTransforms.delete(editor, { at: range });
              KitemakerTransforms.setNodes(
                editor,
                {
                  type: elementType,
                  externalIssueId,
                  externalIssueType: integrationTypeToExternalIssueType(type),
                },
                { at: range.anchor }
              );
            }
            ReactEditor.focus(editor);
          },
        });
        return;
      }

      if (elementType === Elements.InlineExternalIssueLink) {
        KitemakerTransforms.insertMention(
          editor,
          {
            type: elementType,
            refId,
            externalIssueType: integrationTypeToExternalIssueType(type),
            children: [{ text: '' }],
          },
          range,
          autoComplete
        );
      } else {
        KitemakerTransforms.delete(editor, { at: range });
        KitemakerTransforms.setNodes(
          editor,
          { type: elementType, refId, externalIssueType: integrationTypeToExternalIssueType(type) },
          { at: range.anchor }
        );
      }

      HistoryEditor.asBatch(editor, async () => {
        const externalIssueId = await storeExternalIssueBExternalId(
          organizationId,
          type,
          option.id
        );
        if (externalIssueId) {
          const nodes = Array.from(KitemakerNode.descendants(editor)).filter(
            ([node]) =>
              KitemakerElement.isElement(node) && node.type === elementType && node.refId === refId
          );

          for (const [, at] of nodes) {
            KitemakerTransforms.setNodes(editor, { externalIssueId }, { at });
          }
        }
      });
    },
  };
}

export function useExternalIssueSuggestionMatchers(organizationId: string): {
  matchers: SuggestionMatcher[];
  insertMenuItems: InsertMenuInlineChoice[];
} {
  const modals = useModals();

  const integrations = useRecoilValue(
    externalIssueIntegrationsForOrganizationSelector(organizationId)
  );
  const types = uniq(integrations.map(i => i.type));
  return {
    matchers: types.map(type => externalIssueSuggestionMatcher(organizationId, type, modals)),
    insertMenuItems: types.map(type => ({
      id: type,
      icon: iconForIntegrationType(type) ?? 'link',
      description: `Link ${stringifyIntegrationType(type)} issue`,
      content: () => ({ text: `/${type.toLowerCase()} ` }),
    })),
  };
}
