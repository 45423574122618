import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { CommandGroup } from '../../commands';
import { LocationState } from '../../utils/history';
import { Button, IconButton } from './button';
import { CustomCommand } from './customCommand';
import { KeyboardShortcut } from './keyboardShortcut';
import { Tooltip } from './tooltip';

export default function BackButton({
  defaultPath,
  className,
  children,
  hideIcon,
}: {
  defaultPath: string;
  className?: string;
  children?: string;
  hideIcon?: boolean;
}) {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { backUrl, backSearch, backBreadcrumbs, ...rest } = location.state || {};
  const path = backUrl ? backUrl : defaultPath;

  let button = (
    <IconButton
      icon={'full_arrow_back'}
      className={className}
      onClick={() =>
        history.push({
          pathname: path,
          search: backSearch,
          state: { ...rest, breadcrumbs: backBreadcrumbs },
        })
      }
    />
  );

  if (children) {
    button = (
      <Button
        icon={hideIcon ? null : 'full_arrow_back'}
        className={className}
        onClick={() =>
          history.push({
            pathname: path,
            search: backSearch,
            state: { ...rest, breadcrumbs: backBreadcrumbs },
          })
        }
      >
        {children}
      </Button>
    );
  }

  return (
    <>
      <CustomCommand
        command={{
          id: 'back-button-back',
          group: CommandGroup.Navigation,
          hotkey: 'escape',
          description: 'Go back',
          priority: 10,
          handler: () => {
            history.push({
              pathname: path,
              search: backSearch,
              state: { ...rest, breadcrumbs: backBreadcrumbs },
            });
          },
        }}
      />

      <Tooltip
        content={
          <>
            Go back <KeyboardShortcut shortcut="esc" />
          </>
        }
      >
        {button}
      </Tooltip>
    </>
  );
}
