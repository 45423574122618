import { maxBy, minBy } from 'lodash';

export const defaultSmallImpact = {
  abbrevation: 'S',
  level: 1,
  name: 'Small',
  color: 'gray',
};

export const defaultMediumImpact = {
  abbrevation: 'M',
  level: 2,
  name: 'Medium',
  color: 'gray',
};

export const defaultLargeImpact = {
  abbrevation: 'L',
  level: 3,
  name: 'Large',
  color: 'gray',
};

export const defaultSmallEffort = {
  abbrevation: 'S',
  level: 1,
  name: 'Small',
  color: 'gray',
};

export const defaultMediumEffort = {
  abbrevation: 'M',
  level: 2,
  name: 'Medium',
  color: 'gray',
};

export const defaultLargeEffort = {
  abbrevation: 'L',
  level: 3,
  name: 'Large',
  color: 'gray',
};

interface WithWeight {
  id: string;
  level: number;
}
export interface ImpactEffortScoringAlgorithm {
  (
    metadata: ImpactEffortScoringMetadata,
    opts: {
      impactLevels: WithWeight[];
      effortLevels: WithWeight[];
      impact?: WithWeight;
      effort?: WithWeight;
    }
  ): number;
}

interface ImpactEffortScoringMetadata {
  maxImpact: number;
  minImpact: number;
  maxEffort: number;
  minEffort: number;
}

export function generateImpactEffortScoringMetadata(opts: {
  impactLevels: WithWeight[];
  effortLevels: WithWeight[];
}): ImpactEffortScoringMetadata {
  const maxImpact = maxBy(opts.impactLevels, e => e.level)?.level ?? 1.0;
  const minImpact = minBy(opts.impactLevels, e => e.level)?.level ?? 0.0;
  const maxEffort = maxBy(opts.effortLevels, e => e.level)?.level ?? 1.0;
  const minEffort = minBy(opts.effortLevels, e => e.level)?.level ?? 0.0;

  return {
    maxImpact,
    minImpact,
    maxEffort,
    minEffort,
  };
}

export const defaultImpactEffortScoring: ImpactEffortScoringAlgorithm = (
  metadata: ImpactEffortScoringMetadata,
  opts: {
    impactLevels: WithWeight[];
    effortLevels: WithWeight[];
    impact?: WithWeight;
    effort?: WithWeight;
  }
) => {
  const worstImpact = metadata.minImpact * 0.9;
  const worstEffort = metadata.maxEffort * 1.1;

  const impactValue = (opts.impact?.level ?? worstImpact) / metadata.maxImpact;
  const effortValue = (opts.effort?.level ?? worstEffort) / metadata.maxEffort;
  const successorScalar = impactValue * 0.0001;

  return (Math.pow(impactValue, 2) + successorScalar) / effortValue;
};

export const impactOnlyScoring: ImpactEffortScoringAlgorithm = (
  metadata: ImpactEffortScoringMetadata,
  opts: {
    impactLevels: WithWeight[];
    effortLevels: WithWeight[];
    impact?: WithWeight;
    effort?: WithWeight;
  }
) => {
  const worstImpact = metadata.minImpact * 0.9;
  const impactValue = (opts.impact?.level ?? worstImpact) / metadata.maxImpact;
  return impactValue;
};

export const effortOnlyScoring: ImpactEffortScoringAlgorithm = (
  metadata: ImpactEffortScoringMetadata,
  opts: {
    impactLevels: WithWeight[];
    effortLevels: WithWeight[];
    impact?: WithWeight;
    effort?: WithWeight;
  }
) => {
  const worstEffort = metadata.maxEffort * 1.1;
  const effortValue = (opts.effort?.level ?? worstEffort) / metadata.maxEffort;
  return 1 / effortValue;
};
