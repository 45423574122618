import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import cn from 'classnames';
import * as React from 'react';
import styles from './scrollArea.module.scss';

interface ScrollAreaProperties {
  children: React.ReactNode;
  className?: string;
  viewportClassName?: string;
  style?: React.CSSProperties;
  onScroll?: (e: React.UIEvent) => void;
}

function ScrollAreaCompnent(
  {
    orientation,
    children,
    className,
    viewportClassName,
    style,
    onScroll,
  }: { orientation: 'horizontal' | 'vertical' } & ScrollAreaProperties,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <RadixScrollArea.Root className={cn(styles.scrollArea, className)} style={style}>
      <RadixScrollArea.Viewport
        className={cn(styles.viewport, viewportClassName)}
        ref={ref}
        onScroll={onScroll}
      >
        {children}
      </RadixScrollArea.Viewport>
      <RadixScrollArea.Scrollbar className={styles.scrollBar} orientation={orientation}>
        <RadixScrollArea.Thumb className={styles.thumb} />
      </RadixScrollArea.Scrollbar>
    </RadixScrollArea.Root>
  );
}

const ScrollArea = React.forwardRef(ScrollAreaCompnent);

function VerticalScrollAreaComponent(
  props: ScrollAreaProperties,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  return <ScrollArea {...props} orientation="vertical" ref={ref} />;
}

function HorizontalScrollAreaComponent(
  props: ScrollAreaProperties,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  return <ScrollArea {...props} orientation="horizontal" ref={ref} />;
}

export const VerticalScrollArea = React.forwardRef(VerticalScrollAreaComponent);
export const HorizontalScrollArea = React.forwardRef(HorizontalScrollAreaComponent);
