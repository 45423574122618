import debugModule from 'debug';
import { Cancelable, debounce, last } from 'lodash';
import NodeCache from 'node-cache';
import { Editor, Node, NodeEntry, Operation, Path } from 'slate';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { filterNotNull } from '../../../shared/utils/convenience';
import { generateId } from '../../../shared/utils/id';
import { TodoStatus } from '../../../sync/__generated/models';
import {
  UpdateTodos,
  useCalculateTodoSort,
  useCreateTodo,
  useFindRecyclableTodos,
  useFindTodosWithIncorrectEntityParent,
  useHasCorrectEntity,
  useUpdateTodosIfNeeded,
  useUpdateTodos,
} from '../../syncEngine/actions/todos';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { DocumentLike, EditorType, Elements, FormattedText, SmartTodoElement } from '../types';

const debug = debugModule('smartTodos');

type UpdateFunc = (
  updateTodos: UpdateTodos,
  id: string,
  contents: DocumentLike,
  indent: number
) => void;
type CancelableUpdateFunc = UpdateFunc & Cancelable;
const UPDATE_DEBOUNCE = 3000;

function findTodoAbove(editor: EditorType, path: Path): SmartTodoElement | null {
  if (!Editor.hasPath(editor, path)) {
    if (path.length > 1) {
      const parentPath = path.slice(0, -1);
      return findTodoAbove(editor, parentPath);
    }
    return null;
  }

  const [node] = Editor.node(editor, path);
  if (KitemakerElement.isElement(node) && node.type === Elements.SmartTodo) {
    return node as SmartTodoElement;
  }

  if (KitemakerElement.isElement(node) && !KitemakerElement.isInline(node)) {
    return null;
  }

  const block = Editor.above(editor, {
    at: path,
    match: n => KitemakerElement.isElement(n) && n.type === Elements.SmartTodo,
    mode: 'highest',
  });
  if (block) {
    return block[0] as SmartTodoElement;
  }
  return null;
}

export function useWithSmartTodos(entityId?: string) {
  const createTodo = useCreateTodo();
  const updateTodos = useUpdateTodos();
  const calculateSort = useCalculateTodoSort();
  const hasCorrectEntity = useHasCorrectEntity();
  const updateTodosIfNeeded = useUpdateTodosIfNeeded();
  const findRecyclableTodos = useFindRecyclableTodos();
  const findTodosWithIncorrectParent = useFindTodosWithIncorrectEntityParent();

  if (!entityId) {
    return (editor: EditorType): EditorType => editor;
  }

  const updaterCache = new NodeCache({
    stdTTL: 300,
  });

  let documentStructureChanged = false;
  let recyclableTodos: string[] | null = null;

  const createdTodos = new Map<string, TodoStatus>();
  const movedTodos = new Set<string>();
  const duplicatedTodos: Record<string, string> = {};
  const updatesToFlush: Record<
    string,
    { immediate: boolean; contents: FormattedText[]; indent: number }
  > = {};

  function handleTodoOperations(editor: EditorType, entityId: string, operation: Operation) {
    if (operation.type !== 'set_selection') {
      debug('Operation', operation);
    }

    // If we've got an insert, we need to check if it's a cut/paste into the same doc.
    // Only in that case will we preserve the todoId
    if (
      operation.type === 'insert_node' &&
      KitemakerElement.isElement(operation.node) &&
      !Object.isFrozen(operation.node) &&
      operation.node.type === Elements.SmartTodo &&
      operation.node.todoId
    ) {
      documentStructureChanged = true;

      const todoId = operation.node.todoId;
      const existingNode: SmartTodoElement | undefined = editor.children.find(
        node =>
          KitemakerElement.isElement(node) &&
          node.type === Elements.SmartTodo &&
          node.todoId === todoId
      ) as SmartTodoElement | undefined;

      if (existingNode || !hasCorrectEntity(todoId, entityId)) {
        debug('Inserting node. Assigning todoId');
        const newId = nextTodoId();
        operation.node.todoId = newId;
        createdTodos.set(newId, TodoStatus.NotStarted);
      } else {
        debug('Inserting node. Preserving todoId');
        // if someone cuts and pastes, treat it like a move
        movedTodos.add(operation.node.todoId);
      }
    }

    // If we're splitting, set the todoId
    if (operation.type === 'split_node') {
      const props = operation.properties as {
        todoId?: string | null;
        type?: Elements;
      };
      if (props.type === Elements.SmartTodo) {
        debug('Splitting. Setting todoId');
        const newId = nextTodoId();
        documentStructureChanged = true;
        props.todoId = newId;
        createdTodos.set(newId, TodoStatus.NotStarted);
      }
    }

    if (
      operation.type === 'merge_node' ||
      operation.type === 'move_node' ||
      operation.type === 'remove_node'
    ) {
      const elementEntry = Editor.hasPath(editor, operation.path)
        ? Editor.node(editor, operation.path)
        : null;
      if (elementEntry) {
        const [element] = elementEntry;
        if (KitemakerElement.isElement(element) && element.type === Elements.SmartTodo) {
          documentStructureChanged = true;
          if (operation.type === 'move_node' && element.todoId) {
            movedTodos.add(element.todoId);
          }
        }
      }
    }

    if (operation.type === 'set_node') {
      const elementEntry = Editor.hasPath(editor, operation.path)
        ? Editor.node(editor, operation.path)
        : null;
      if (elementEntry) {
        const [element] = elementEntry;
        if (KitemakerElement.isElement(element) && element.type === Elements.SmartTodo) {
          documentStructureChanged = true;
        }
      }

      const newProps: { type?: Elements; todoId?: string } = operation.newProperties as Record<
        string,
        unknown
      >;
      if (newProps.type === Elements.SmartTodo && !newProps.todoId) {
        const newId = nextTodoId();
        createdTodos.set(newId, TodoStatus.NotStarted);
        newProps.todoId = newId;
        documentStructureChanged = true;
      }
    }
  }

  function updateTodoContents(editor: EditorType, operation: Operation) {
    if (operation.type === 'set_selection') {
      return;
    }

    const todoElement: SmartTodoElement | null = findTodoAbove(editor, operation.path);
    if (!todoElement || !todoElement.todoId) {
      return;
    }

    const { todoId, children } = todoElement;
    const existingUpdate = updatesToFlush[todoId];

    updatesToFlush[todoId] = {
      contents: children,
      indent: todoElement.indent,
      immediate:
        existingUpdate?.immediate ||
        ['insert_node', 'remove_node', 'merge_node', 'split_node', 'set_node'].includes(
          operation.type
        ),
    };
  }

  function flushSmartTodoUpdates() {
    if (Object.keys(updatesToFlush).length) {
      debug('Flushing updates', Object.keys(updatesToFlush));
    }

    for (const todoId in updatesToFlush) {
      const update = updatesToFlush[todoId];
      if (!updaterCache.get(todoId)) {
        const updater: CancelableUpdateFunc = debounce((update, id, contents, indent) => {
          update([id], { todoContents: contents, indent });
        }, UPDATE_DEBOUNCE);
        updaterCache.set(todoId, updater);
      }

      const updater = updaterCache.get<CancelableUpdateFunc>(todoId)!;
      updater(
        updateTodos,
        todoId,
        [{ type: Elements.Paragraph, children: update.contents }],
        update.indent
      );

      if (update.immediate) {
        updater.flush();
      }
      delete updatesToFlush[todoId];
    }
  }

  function nextTodoId() {
    if (!recyclableTodos) {
      recyclableTodos = entityId ? findRecyclableTodos(entityId) : [];
    }

    if (recyclableTodos.length) {
      const id = recyclableTodos.shift()!;
      return id;
    }

    return generateId();
  }

  return (editor: EditorType): EditorType => {
    const {
      apply: applyLocal,
      onChange: onChangeLocal,
      normalizeNode: normalizeNodeLocal,
    } = editor;

    editor.apply = (operation: Operation) => {
      handleTodoOperations(editor, entityId, operation);
      applyLocal(operation);
      updateTodoContents(editor, operation);
    };

    editor.onChange = () => {
      flushSmartTodoUpdates();
      onChangeLocal();
      if (documentStructureChanged) {
        const smartTodoElements = KitemakerElement.smartTodoElements(editor);

        const wrongParent = findTodosWithIncorrectParent(
          entityId,
          smartTodoElements.map(e => e[0].todoId)
        );
        const seenIds = new Set<string>();
        const sorts: Record<string, string> = {};

        const parents: Record<string, string | null> = smartTodoElements.reduce(
          (result, [todo]) => {
            result[todo.todoId] = null;
            return result;
          },
          {} as Record<string, string | null>
        );
        const parentStack: SmartTodoElement[] = [];
        let previousPath: Path | null = null;

        for (let i = 0; i < smartTodoElements.length; i++) {
          const [node, path] = smartTodoElements[i];

          // if the previous element wasn't a smart todo, clear the parent stack
          if (!previousPath || previousPath[0] !== path[0] - 1) {
            parentStack.splice(0);
          }
          previousPath = path;

          // calculate the parent
          if (!parentStack.length) {
            parentStack.push(node);
            parents[node.todoId] = null;
          } else {
            const parent = last(parentStack)!;
            if (parent.indent < node.indent) {
              parents[node.todoId] = parent.todoId;
              parentStack.push(node);
            } else {
              while (last(parentStack) && last(parentStack)!.indent >= node.indent) {
                parentStack.pop();
              }
              parents[node.todoId] = last(parentStack)?.todoId ?? null;
              parentStack.push(node);
            }
          }

          const created = createdTodos.has(node.todoId);
          const moved = movedTodos.has(node.todoId);
          const reparent = wrongParent.includes(node.todoId);
          const duplicate = seenIds.has(node.todoId);

          if (!created && !moved && !reparent && !duplicate) {
            seenIds.add(node.todoId);
            continue;
          }

          const afterId = smartTodoElements[i - 1]?.[0].todoId;
          // find the first non-new todo to anchor the sort to
          const beforeTodo = smartTodoElements
            .slice(i + 1)
            .find(([todoElement]) => !createdTodos.has(todoElement.todoId));
          const beforeId = beforeTodo?.[0].todoId;

          const sort = calculateSort({
            beforeId,
            afterId,
            afterSort: sorts[afterId ?? ''],
          });

          if (created) {
            debug('Creating new Todo');
            const todo = createTodo(
              node.todoId,
              entityId,
              [{ type: Elements.Paragraph, children: node.children }],
              {
                sort,
                copyPropsFromTodoWithId: duplicatedTodos[node.todoId],
                indent: node.indent,
                status: createdTodos.get(node.todoId),
                parentId: parents[node.todoId],
              }
            );
            sorts[todo.id] = sort;
            delete duplicatedTodos[todo.id];
          } else if (moved) {
            debug('Todo moved. Updating sort');
            updateTodos([node.todoId], { sort });
          } else if (reparent || duplicate) {
            debug('Incorrect parent found. Creating new Todo');
            const todo = createTodo(
              generateId(),
              entityId,
              [{ type: Elements.Paragraph, children: node.children }],
              {
                sort,
                copyPropsFromTodoWithId: node.todoId,
              }
            );
            sorts[todo.id] = sort;
            smartTodoElements[i][0] = { ...node, todoId: todo.id };
            KitemakerTransforms.setNodes(editor, { todoId: todo.id }, { at: path });
          }

          seenIds.add(node.todoId);
        }

        movedTodos.clear();
        createdTodos.clear();

        debug('Setting orphaned status');
        updateTodosIfNeeded(
          entityId,
          filterNotNull(smartTodoElements.map(([node]) => node.todoId)).map(todoId => ({
            id: todoId,
            parentId: parents[todoId],
          }))
        );
      }
      documentStructureChanged = false;
      recyclableTodos = null;
    };

    // convert todos -> smart todos
    editor.normalizeNode = (entry: NodeEntry<Node>) => {
      const [node, path] = entry;
      if (KitemakerElement.isElement(node) && node.type === Elements.Todo) {
        const todoId = nextTodoId();

        createdTodos.set(todoId, node.checked ? TodoStatus.Done : TodoStatus.NotStarted);
        documentStructureChanged = true;
        KitemakerTransforms.setNodes(
          editor,
          { type: Elements.SmartTodo, indent: node.indent, todoId },
          { at: path }
        );
        return;
      }
      normalizeNodeLocal(entry);
    };

    return editor;
  };
}
