import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useMaybeOrganization } from '../contexts/organizationContext';
import { Button, ButtonSize, ButtonStyle } from './new/button';
import { ScreenHeader } from './new/screenHeader';
import { SidebarVisibility, sidebarVisibility } from './new/sidebar';
import { useSidebarHidden } from './new/sidebar/sidebar';
import styles from './screen.module.scss';

export function Screen({
  children,
  row,
  hideSidebar,
  className,
  style,
}: {
  children: React.ReactNode;
  hideSidebar?: boolean;
  row?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  const org = useMaybeOrganization();
  const visibility = useRecoilValue(sidebarVisibility);
  useSidebarHidden(hideSidebar ?? false);

  return (
    <div
      className={cn(
        styles.screen,
        {
          [styles.hasSidebar]: !!org,
          [styles.hideSidebar]: hideSidebar,
          [styles.sidebarOpen]: visibility === SidebarVisibility.Open,
          [styles.row]: row,
        },
        className
      )}
      style={style}
    >
      {children}
    </div>
  );
}

export function AuxiliaryScreen({
  children,
  verticalCenter,
  className,
  style,
}: {
  children: React.ReactNode;
  verticalCenter?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <Screen hideSidebar className={cn(styles.auxiliaryScreen, className)} style={style}>
      <ScreenHeader hideNotifications className={styles.header} />
      <div className="fullWidth overflowScrollY colStretch fullScreenHeight">
        <div
          className={cn('grow fullWidth relative', {
            colAlignCenter: !verticalCenter,
            colCenter: verticalCenter,
          })}
        >
          <div
            className={cn({
              [styles.fixedTopAlignment]: !verticalCenter,
            })}
          >
            {children}
          </div>
          <div className={styles.helpButton}>
            <Button
              onClick={() => {
                window.Intercom?.('showMessages');
              }}
              buttonStyle={ButtonStyle.BareSubtle}
              size={ButtonSize.Small}
            >
              Need help?
            </Button>
          </div>
        </div>
      </div>
    </Screen>
  );
}
