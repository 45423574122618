import debugModule from 'debug';
import { toast } from '../components/toast';
import { ImportedAssignee } from './github';

const debug = debugModule('trello');

export interface Board {
  id: string;
  name: string;
  organizationName?: string;
}

export interface TrelloImportRequest {
  id: string;
  importLabels: boolean;
  importArchivedCards: boolean;
  spaceName: string;
  mapAssignees?: { [index: string]: string };
}

export interface PreparedTrelloImport {
  id: string;
  openCardCount: number;
  archivedCardCount: number;
  assignees: ImportedAssignee[];
}

export async function checkToken(): Promise<boolean> {
  try {
    const result = await fetch('/import/trello/token', {
      credentials: 'same-origin',
    });

    if (!result.ok) {
      debug('Received non 2XX response validating token');
      return false;
    }

    const jsonResult = await result.json();
    return jsonResult.data;
  } catch (e) {
    debug('Error checking Trello token', e);
    toast.error('There was an error processing your request. Please reload and try again');
  }

  return false;
}

export async function fetchBoards(): Promise<Board[]> {
  const result = await fetch('/import/trello/boards', {
    credentials: 'same-origin',
  });

  if (!result.ok) {
    toast.error('There was an error processing your request. Please reload and try again');
    debug('Received non 2XX response validating token');
    return [];
  }

  const jsonResult = await result.json();
  return jsonResult.data;
}

export async function prepareImport(
  organizationId: string,
  boardId: string
): Promise<PreparedTrelloImport> {
  const response = await fetch('/import/trello/prepare', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ organizationId, boardId }),
  });

  const responseJson = await response.json();
  if (responseJson.errors) {
    toast.error(responseJson.errors?.[0]?.message ?? 'Error preparing to import from Trello');
    throw new Error('Unable to prepare import from Trello');
  }

  const id = responseJson.data.id;
  let retryCount = 30;

  return await new Promise((resolve, reject) => {
    const checkPreparation = async () => {
      const response = await fetch(`/import/trello/prepare?id=${id}`, {
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });

      const responseJson = await response.json();
      if (responseJson.errors) {
        toast.error(responseJson.errors?.[0]?.message ?? 'Error preparing to import from Trello');
        reject(new Error('Unable to prepare import from Trello'));
        return;
      }

      if (responseJson.data.status === 'error' || retryCount-- <= 0) {
        reject(new Error('Unable to prepare import from Trello'));
        return;
      }

      if (responseJson.data.status === 'ready') {
        resolve(responseJson.data);
        return;
      }

      setTimeout(checkPreparation, 5000);
    };

    checkPreparation();
  });
}

export async function importFromTrello(request: TrelloImportRequest) {
  const response = await fetch('/import/trello/import', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });
  const responseJson = await response.json();
  if (responseJson.errors) {
    toast.error(responseJson.errors?.[0]?.message ?? 'Import failed');
    return;
  }

  toast.info(`Import started. You'll be notified when it's done`);
}
