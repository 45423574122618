import { capitalize } from 'lodash';
import * as React from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { between } from '../../../../shared/utils/sorting';
import { IssueStatusSortMode, RoadmapColumnStatusType } from '../../../../sync/__generated/models';
import {
  ButtonSize,
  ButtonStyle,
  IconButton,
  IconButtonTrigger,
} from '../../../components/new/button';
import { Count } from '../../../components/new/count';
import { Icon } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '../../../components/new/menu/dropdownMenu';
import { TextInput, TextInputSize } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import {
  BoardColumnHeader,
  columnDragDestinationAtom,
  columnEditIdState,
} from '../../../components/new/virtualizedBoardView/columnHeader';
import { CommandMenuView, Modals, useModals } from '../../../contexts/modalContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import {
  useCreateRoadmapColumn,
  useDeleteColumn,
  useUpdateRoadmapColumn,
  useUpdateRoadmapColumnSortMode,
} from '../../../syncEngine/actions/roadmaps';
import { initiativeCountForColumn } from '../../../syncEngine/selectors/intiatives';
import {
  roadmapColumnSelector,
  roadmapColumnTypeToIcon,
  roadmapColumnsForRoadmapSelector,
} from '../../../syncEngine/selectors/roadmaps';
import { addExistingKey, createNewIssueKey } from '../../../utils/config';
import styles from './roadmapsScreen.module.scss';
import { RoadmapColumnSortIndicator } from './sortIndicator';

export function RoadmapColumnMenu({ columnId, onEdit }: { columnId: string; onEdit?: () => void }) {
  const deleteColumn = useDeleteColumn();
  const column = useRecoilValue(roadmapColumnSelector(columnId));
  const updateColumnSortMode = useUpdateRoadmapColumnSortMode();
  const modals = useModals();
  const maybeSpace = useMaybeSpace();

  const addExisting = React.useCallback(() => {
    modals.openModal(Modals.CommandMenu, {
      view: CommandMenuView.RoadmapInitiatives,
      context: { roadmapId: column?.roadmapId, columnId, spaceId: maybeSpace?.id },
    });
  }, []);

  if (!column) {
    return null;
  }

  return (
    <>
      <DropdownMenuItem icon="add" shortcut={addExistingKey} onClick={addExisting}>
        Add existing initiatives
      </DropdownMenuItem>
      {onEdit && (
        <>
          <DropdownMenuItem icon="edit" onClick={onEdit}>
            Edit column
          </DropdownMenuItem>
          <DropdownMenuSeparator />{' '}
        </>
      )}
      <DropdownMenuLabel>Ordering</DropdownMenuLabel>
      <DropdownMenuItem
        icon={
          column.sortMode === IssueStatusSortMode.Manual || column.sortMode === undefined
            ? 'select_checkmark'
            : 'none'
        }
        onClick={() => {
          updateColumnSortMode(column.id, IssueStatusSortMode.Manual);
        }}
      >
        Manual
      </DropdownMenuItem>
      <DropdownMenuItem
        icon={
          column.sortMode === IssueStatusSortMode.ImpactEffortAsc ||
          column.sortMode === IssueStatusSortMode.ImpactEffortDesc
            ? 'select_checkmark'
            : 'none'
        }
        onClick={() => {
          updateColumnSortMode(column.id, IssueStatusSortMode.ImpactEffortAsc);
        }}
      >
        Impact and effort
      </DropdownMenuItem>
      <DropdownMenuItem
        icon={
          column.sortMode === IssueStatusSortMode.ImpactAsc ||
          column.sortMode === IssueStatusSortMode.ImpactDesc
            ? 'select_checkmark'
            : 'none'
        }
        onClick={() => {
          updateColumnSortMode(column.id, IssueStatusSortMode.ImpactAsc);
        }}
      >
        Impact
      </DropdownMenuItem>
      <DropdownMenuItem
        icon={
          column.sortMode === IssueStatusSortMode.EffortAsc ||
          column.sortMode === IssueStatusSortMode.EffortDesc
            ? 'select_checkmark'
            : 'none'
        }
        onClick={() => {
          updateColumnSortMode(column.id, IssueStatusSortMode.EffortAsc);
        }}
      >
        Effort
      </DropdownMenuItem>
      <DropdownMenuItem
        icon={
          column.sortMode === IssueStatusSortMode.CreatedAsc ||
          column.sortMode === IssueStatusSortMode.CreatedDesc
            ? 'select_checkmark'
            : 'none'
        }
        onClick={() => {
          updateColumnSortMode(column.id, IssueStatusSortMode.CreatedAsc);
        }}
      >
        Created
      </DropdownMenuItem>
      <DropdownMenuItem
        icon={
          column.sortMode === IssueStatusSortMode.LastStatusAsc ||
          column.sortMode === IssueStatusSortMode.LastStatusDesc
            ? 'select_checkmark'
            : 'none'
        }
        onClick={() => {
          updateColumnSortMode(column.id, IssueStatusSortMode.LastStatusAsc);
        }}
      >
        Added to column
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="delete"
        onClick={() => {
          deleteColumn(columnId);
        }}
      >
        Delete column
      </DropdownMenuItem>
    </>
  );
}

export function RoadmapColumnHeader({
  roadmapColumnId,
  onNewCard,
}: {
  roadmapColumnId: string;
  onNewCard: () => void;
}) {
  const column = useRecoilValue(roadmapColumnSelector(roadmapColumnId));
  const boardColumns = useRecoilValue(roadmapColumnsForRoadmapSelector(column?.roadmapId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const updateColumn = useUpdateRoadmapColumn();
  const count = useRecoilValue(
    initiativeCountForColumn({ columnId: roadmapColumnId, filterId: column?.roadmapId ?? '' })
  );

  const [editingId, setEditingId] = useRecoilState(columnEditIdState);

  const editing = editingId === roadmapColumnId;

  const getDestinationAtom = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(columnDragDestinationAtom);
      },
    []
  );

  if (!column) {
    return null;
  }

  if (editing) {
    return (
      <EditColumn
        initialName={column.name}
        initialType={column.columnType}
        roadmapId={roadmapColumnId}
        onComplete={(_roadmapId: string, name: string, columnType: RoadmapColumnStatusType) => {
          updateColumn([roadmapColumnId], { name, columnType });
          setEditingId(null);
        }}
        onCancel={() => {
          setEditingId(null);
        }}
      />
    );
  }

  return (
    <BoardColumnHeader
      onDragStart={() => {
        setEditingId(null);
      }}
      onDragEnd={() => {
        const dest = getDestinationAtom().getValue();
        if (!dest || !column) {
          return;
        }
        const { after, id } = dest;
        const index = boardColumns.findIndex(c => c.id === id);
        if (index < 0) {
          return;
        }
        let sort;
        if (after) {
          sort = between({
            after: boardColumns[index]?.sort,
            before: boardColumns[index + 1]?.sort,
          });
        } else {
          sort = between({
            before: boardColumns[index]?.sort,
            after: boardColumns[index - 1]?.sort,
          });
        }
        updateColumn([roadmapColumnId], { sort });
      }}
      onClick={() => {
        setEditingId(roadmapColumnId);
      }}
      id={roadmapColumnId}
    >
      <div className="row grow ellipsis">
        <Icon className="mr8" icon={roadmapColumnTypeToIcon(column.columnType)} />
        <div className="ellipsis mr8">{column.name}</div>
        <Count count={count} />
        <div className="row ml4">
          <RoadmapColumnSortIndicator roadmapColumn={column} />
        </div>
      </div>
      <div className="row">
        <Tooltip
          content={
            <>
              Create initaitive <KeyboardShortcut shortcut={createNewIssueKey} />
            </>
          }
        >
          <IconButton
            icon="add"
            buttonStyle={ButtonStyle.BareSubtle}
            className="mr8"
            onClick={e => {
              e.stopPropagation();
              onNewCard();
            }}
          />
        </Tooltip>
        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
          <IconButtonTrigger buttonStyle={ButtonStyle.BareSubtle} icon="more" />
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="end"
            className="menuSmall"
          >
            <RoadmapColumnMenu columnId={column.id} onEdit={() => setEditingId(column.id)} />
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </BoardColumnHeader>
  );
}
export function EditColumn({
  roadmapId,
  initialName,
  initialType,
  onComplete,
  onCancel,
}: {
  roadmapId: string;
  initialName?: string;
  initialType?: RoadmapColumnStatusType;
  onComplete: (roadmapId: string, name: string, type: RoadmapColumnStatusType) => void;
  onCancel: () => void;
}) {
  const [name, setName] = React.useState(initialName ?? '');
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const [columnType, setColumnType] = React.useState(initialType ?? RoadmapColumnStatusType.Future);

  useComponentDidMount(() => {
    ref.current?.scrollIntoView({ block: 'nearest' });
  });

  return (
    <div className={styles.editHeader}>
      <DropdownMenu>
        <IconButtonTrigger size={ButtonSize.Small} icon={roadmapColumnTypeToIcon(columnType)} />
        <DropdownMenuContent className="menuTiny" side="bottom" sideOffset={4} align="start">
          {[
            RoadmapColumnStatusType.Past,
            RoadmapColumnStatusType.Present,
            RoadmapColumnStatusType.Future,
          ].map(type => (
            <DropdownMenuCheckboxItem
              checked={columnType === type}
              key={type}
              icon={roadmapColumnTypeToIcon(type)}
              onSelect={() => setColumnType(type)}
            >
              {capitalize(type)}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <TextInput
        inputSize={TextInputSize.Small}
        className="noMinWidth grow"
        placeholder="New column name"
        autoFocus
        value={name}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onComplete(roadmapId, name, columnType);
          } else if (e.key === 'Escape') {
            onCancel();
          }
        }}
        onChange={e => setName(e.target.value)}
      />
      <IconButton
        size={ButtonSize.Small}
        onClick={() => {
          onComplete(roadmapId, name, columnType);
        }}
        icon="select_checkmark"
        buttonStyle={ButtonStyle.Primary}
      />
      <IconButton ref={ref} size={ButtonSize.Small} onClick={onCancel} icon="exit" />
    </div>
  );
}
export function NewColumnHeader({ roadmapId }: { roadmapId: string }) {
  const [creating, setCreating] = React.useState(false);
  const createColumn = useCreateRoadmapColumn();

  return (
    <div className={styles.newHeader}>
      {!creating && (
        <Tooltip content={<>Add a new column</>}>
          <IconButton
            size={ButtonSize.Small}
            className={styles.newButton}
            onClick={() => setCreating(true)}
            icon="add"
            buttonStyle={ButtonStyle.Secondary}
          />
        </Tooltip>
      )}
      {creating && (
        <EditColumn
          roadmapId={roadmapId}
          onComplete={(roadmapId: string, name: string, type: RoadmapColumnStatusType) => {
            createColumn(roadmapId, name, type);
            setCreating(false);
          }}
          onCancel={() => {
            setCreating(false);
          }}
        />
      )}
    </div>
  );
}
