import { capitalize, partition, startCase, uniqBy } from 'lodash';
import * as React from 'react';
import { createExport, Export, fetchExports } from '../../../api/export';
import { Button } from '../../../components/new/button';
import {
  Setting,
  SettingsPage,
  SettingsSection,
  SettingsSectionDivider,
} from '../../../components/new/settings';
import { Tooltip } from '../../../components/new/tooltip';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useHasAdminRights } from '../../../contexts/userContext';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { organizationPath } from '../../../syncEngine/selectors/organizations';
import { renderDate } from '../../../utils/datetime';

function ExportComponent({ exportData, number }: { exportData: Export; number: string }) {
  const { host } = useConfiguration();
  const organization = useOrganization();

  return (
    <div className="rowBetween mb8">
      <div>
        <div>
          {exportData.format.toUpperCase()} export #{number}
        </div>
        {!exportData.error && (
          <>
            {exportData.status === 'completed' && (
              <div>
                Export completed{' '}
                {renderDate(new Date(exportData.updatedAt).getTime(), { showTime: true })}
              </div>
            )}
            {exportData.status !== 'completed' && (
              <div>
                Export initiated{' '}
                {renderDate(new Date(exportData.createdAt).getTime(), { showTime: true })}
              </div>
            )}
          </>
        )}
        {exportData.error && <div className="errorText">{exportData.error}</div>}
      </div>
      <Button
        disabled={exportData.status !== 'completed'}
        onClick={() => {
          const url = `${host}/content${organizationPath(organization)}/export/km-export-${
            exportData.id
          }.zip`;
          window.open(url, '_blank');
        }}
      >
        {exportData.status === 'completed' ? 'Download' : capitalize(startCase(exportData.status))}
      </Button>
    </div>
  );
}

export function ExportSettingsScreen() {
  const organization = useOrganization();
  const hasAdminRights = useHasAdminRights();
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [exports, setExports] = React.useState<Export[]>([]);

  const [csvExports, jsonExports] = React.useMemo(
    () => partition(exports, e => e.format === 'csv'),
    [exports]
  );

  const hasPendingCsvExport = csvExports.some(e =>
    ['not-started', 'in-progress'].includes(e.status)
  );
  const hasPendingJsonExport = jsonExports.some(e =>
    ['not-started', 'in-progress'].includes(e.status)
  );

  useComponentDidMount(() => {
    async function retrieveExports() {
      const exports = await fetchExports(organization.id);
      setExports(exports);
    }

    const interval = setInterval(retrieveExports, 10000);
    retrieveExports();

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <SettingsPage
      title="Export"
      description="You can choose to export your data from Kitemaker into CSV or JSON format. Data from private spaces will not be exported."
    >
      <SettingsSectionDivider />
      <SettingsSection>
        <Setting title="CSV export" description="Export data from Kitemaker in CSV format.">
          <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
            <Button
              disabled={!hasAdminRights || requestInProgress || hasPendingCsvExport}
              onClick={async () => {
                setRequestInProgress(true);
                const newExport = await createExport(organization.id, 'csv');
                if (newExport) {
                  setExports(uniqBy([newExport, ...exports], 'id'));
                }
                setRequestInProgress(false);
              }}
            >
              Export to CSV
            </Button>
          </Tooltip>
        </Setting>
        {csvExports.map((exportData, i) => (
          <ExportComponent
            key={exportData.id}
            exportData={exportData}
            number={`${csvExports.length - i}`}
          />
        ))}
      </SettingsSection>
      <SettingsSectionDivider />
      <SettingsSection>
        <Setting title="JSON export" description="Export data from Kitemaker in JSON format.">
          <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
            <Button
              disabled={!hasAdminRights || requestInProgress || hasPendingJsonExport}
              onClick={async () => {
                setRequestInProgress(true);
                const newExport = await createExport(organization.id, 'json');
                if (newExport) {
                  setExports(uniqBy([newExport, ...exports], 'id'));
                }
                setRequestInProgress(false);
              }}
            >
              Export to JSON
            </Button>
          </Tooltip>
        </Setting>
        {jsonExports.map((exportData, i) => (
          <ExportComponent
            key={exportData.id}
            exportData={exportData}
            number={`${jsonExports.length - i}`}
          />
        ))}
      </SettingsSection>
    </SettingsPage>
  );
}
