import cn from 'classnames';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useHasKeyNavigationSelection } from '../keyNavigation';
import styles from './draggableItem.module.scss';

export function DraggableItem({
  itemId,
  draggingId,
  index,
  dragDisabled,
  noTransform,
  dragSize,
  className,
  children,
}: {
  itemId: string;
  draggingId: string | null;
  index: number;
  dragDisabled: boolean;
  noTransform?: boolean;
  dragSize?: number;
  className?: string;
  children: React.ReactNode;
}) {
  const [, id] = itemId.split('/');
  const selected = useHasKeyNavigationSelection(id);

  return (
    <Draggable
      draggableId={itemId}
      index={index}
      isDragDisabled={dragDisabled}
      key={`draggable-${itemId}`}
    >
      {itemProvided => {
        const style = { ...itemProvided.draggableProps.style };

        if (noTransform && draggingId) {
          if (dragSize) {
            (style as any).transform = `translate(0px, -${dragSize}px)`;
          } else {
            (style as any).transform = `none`;
          }
        }
        return (
          <div
            ref={itemProvided.innerRef}
            {...itemProvided.draggableProps}
            {...itemProvided.dragHandleProps}
            style={style}
            tabIndex={-1}
            className={cn(className ?? '', {
              [styles.multiDrag]: selected && draggingId && draggingId !== itemId,
            })}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
}
