import cn from 'classnames';
import * as React from 'react';
import { Logo } from '../../components/new/logo';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import styles from './onboardingScreen.module.scss';

export function SplashContents({ onContinue }: { onContinue: () => void }) {
  const [loaded, setLoaded] = React.useState(0);
  const [fadeOut, setFadeOut] = React.useState(false);
  const [timedOut, setTimedOut] = React.useState(false);
  useComponentDidMount(() => {
    setTimeout(() => setTimedOut(true), 1600);
  });

  React.useEffect(() => {
    if (loaded === 5 && timedOut) {
      setFadeOut(true);
    }
  }, [loaded, timedOut]);

  const onLoad = React.useCallback(() => {
    setLoaded(prev => prev + 1);
  }, []);

  return (
    <div
      className={cn(styles.splash, { [styles.splashFadeOut]: fadeOut })}
      onAnimationEnd={e => {
        if (e.animationName === styles.splashFadeOut) {
          onContinue();
        }
      }}
    >
      <div className={styles.splashContent}>
        <Logo className={styles.logo} />
        <div className={styles.welcome}>Welcome to Kitemaker</div>
      </div>
      <img
        onLoad={onLoad}
        src="https://static.kitemaker.co/onboarding/collaborative_specs.png"
        style={{ display: 'none' }}
      />
      <img
        onLoad={onLoad}
        style={{ display: 'none' }}
        src="https://static.kitemaker.co/onboarding/feedback_screen_20231102.png"
      />
      <img
        onLoad={onLoad}
        style={{ display: 'none' }}
        src="https://static.kitemaker.co/onboarding/link_insights_20231102.png"
      />
      <img
        onLoad={onLoad}
        style={{ display: 'none' }}
        src="https://static.kitemaker.co/onboarding/insights_tab_20231102.png"
      />
      <img
        onLoad={onLoad}
        style={{ display: 'none' }}
        src="https://static.kitemaker.co/onboarding/visualize_and_plan.png"
      />
    </div>
  );
}
