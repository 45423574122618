import cn from 'classnames';
import React from 'react';
import styles from './dropHighlight.module.scss';

export type DropHighlightPosition = 'top' | 'bottom';

export function DropHighlight({
  position,
  fileOnly,
  smallPadding,
}: {
  position: DropHighlightPosition;
  fileOnly?: boolean;
  smallPadding?: boolean;
}) {
  return (
    <div
      contentEditable={false}
      className={cn(styles[position], {
        [styles.fileOnly]: fileOnly,
        [styles.smallPadding]: smallPadding,
      })}
    >
      <span className={styles.circle} />
    </div>
  );
}
