import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import {
  impactLevelsForSpaceSelector,
  uniqueImpactLevelsForOrganizationSelector,
} from '../../../syncEngine/selectors/impactLevels';
import { FilteredListViewHandle } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import { Picker, PickerState } from '../picker';

export function ImpactPicker({
  state,
  filterPlaceholder,
  filterClassName,
  additionalItems,
  additionalItemsFirst,
  onDone,
  onImpactAdded,
  onImpactRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  onDone: () => void;
  onImpactAdded: (issueIds: string[], impactId: string) => void;
  onImpactRemoved: (issueIds: string[], impactId: string) => void;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();

  const spaceImpactLevels = useRecoilValue(impactLevelsForSpaceSelector(space?.id));
  const organizationImpactLevels = useRecoilValue(
    uniqueImpactLevelsForOrganizationSelector(organization.id)
  );
  const impactLevels = space ? spaceImpactLevels : organizationImpactLevels;

  const items: ListViewItem[] = impactLevels.map(tag => {
    const color = mapColor(tag.color);
    return {
      ...tag,
      mouseDown: true,
      contents: () => (
        <>
          <Icon icon="impact" size={IconSize.Size20} style={{ fill: color }} />
          <span className="ellipsis">{tag.name}</span>
        </>
      ),
    };
  });

  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterLabel="Pick impact"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Pick impact'}
      placeholder={'No impact found'}
      ref={filterRef}
      multi
      state={state}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onImpactAdded}
      onRemove={onImpactRemoved}
      onDone={onDone}
    />
  );
}
