import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  entityPath,
  entitySelector,
  isSpaceBoundEntity,
} from '../../syncEngine/selectors/entities';
import { organizationsSelector } from '../../syncEngine/selectors/organizations';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import LinkButton from '../linkButton';
import { EntityKey } from './entityKey';

export function EntityLink({ id, onClick }: { id: string; onClick?: () => void }) {
  const entity = useRecoilValue(entitySelector(id));
  const space = useRecoilValue(
    spaceSelector(entity && isSpaceBoundEntity(entity) ? entity.spaceId : '')
  );
  const org = useRecoilValue(
    organizationsSelector(
      (entity && isSpaceBoundEntity(entity) ? space?.organizationId : entity?.organizationId) ?? ''
    )
  );

  if (org === null || entity === null || (isSpaceBoundEntity(entity) && space === null)) {
    return <>Unknown entity</>;
  }

  const link = {
    pathname: entityPath(org, space, entity),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: entity.id,
    },
  };

  return (
    <LinkButton onClick={onClick} to={link}>
      <EntityKey id={entity.id} /> {entity.title}
    </LinkButton>
  );
}
