import { repeat } from 'lodash';
import MarkdownIt from 'markdown-it';
import Token from 'markdown-it/lib/token';
import { Descendant, Element, Node, Text } from 'slate';
import uuid from 'uuid';
import isUrl from 'validator/lib/isURL';
import { Entity, Todo } from '../../sync/__generated/models';
import { KitemakerElement } from './kitemakerNode';
import { languages } from './syntaxHighlightingLanguages';
import {
  ChatMessageElement,
  CommentElement,
  DocumentLike,
  Elements,
  InsightElement,
  LinkElement,
  TextfulElement,
} from './types';
import { normalizeDocument } from './utils';

type UserLookup = (id: string) => string | null;
type EntityLookup = (id: string) => { number: string; link: string } | null;
type SmartTodoLookup = (id: string) => { checked: boolean } | null;

type Lookups = {
  userLookup: UserLookup;
  entityLookup: EntityLookup;
  todoLookup: SmartTodoLookup;
};

export type DetectEntityLink = (
  url: string
) => { todo?: Todo; entity: Entity; actorId?: string } | null;
export type DetectEntityMention = (
  text: string
) => { matchIndex: number; match: string; todo?: Todo; entity: Entity; actorId?: string }[];

const md = new MarkdownIt();

const imgTagRegex = /<img[^>]*src="([^"]*)"[^>]*>/gm;
const codeLanguages = Object.keys(languages);

const breakingCharacters = [' ', '(', ')', '[', ']', '{', '}'];

const startComment = '<!--';
const endComment = '-->';
const taskCompleted = '[x] ';
const taskNotCompleted = '[ ] ';
const taskNotCompletedAlternate = '[] ';
const codeBlockIndicator = '```';

const plainTextTokenTypes = ['text', 'fence', 'code_block', 'emoji', 'code'];
const closeTokenTypes = [...plainTextTokenTypes.map(token => `${token}_close`), 'link_close'];

function deserializeTokensToPlainText(tokens: Token[]): string {
  let result = '';
  for (const token of tokens) {
    if (token.children?.length) {
      result += deserializeTokensToPlainText(token.children);
      continue;
    }

    if (plainTextTokenTypes.includes(token.type)) {
      result += token.content;
      continue;
    }

    if (closeTokenTypes.includes(token.type)) {
      result += ' ';
      continue;
    }
  }

  return result;
}

export function deserializeMarkdownToPlainText(markdown: string): string {
  const preprocessed = preprocessMarkdown(markdown);
  const parsed = md.parse(preprocessed, {});
  return deserializeTokensToPlainText(parsed);
}

function chunkOutUrls(text: string): string[] {
  const result = [];
  let offset = 0;

  while (offset < text.length) {
    const startOfMaybeUrl = text.indexOf('http', offset);
    if (startOfMaybeUrl === -1) {
      result.push(text.substr(offset));
      break;
    }

    let endOfMaybeUrl = startOfMaybeUrl + 1;
    while (endOfMaybeUrl < text.length && !breakingCharacters.includes(text[endOfMaybeUrl])) {
      endOfMaybeUrl += 1;
    }

    const maybeUrl = text.substring(startOfMaybeUrl, endOfMaybeUrl);
    if (
      isUrl(maybeUrl, {
        require_host: true,
        require_protocol: true,
        require_valid_protocol: true,
        require_tld: false,
      })
    ) {
      result.push(text.substring(offset, startOfMaybeUrl));
      result.push(text.substring(startOfMaybeUrl, endOfMaybeUrl));
    } else {
      result.push(text.substring(offset, endOfMaybeUrl));
    }
    offset = endOfMaybeUrl;
  }

  return result;
}

function processInline(
  inline: Token,
  options?: { type?: Elements; indent?: number; ignoreComments?: boolean }
): DocumentLike {
  const result: DocumentLike = [];
  let type = options?.type ?? Elements.Paragraph;

  let currentUrl: string | null = null;
  let inComment = false;

  let checked: boolean | undefined = undefined;
  let bold: boolean | undefined = undefined;
  let italic: boolean | undefined = undefined;
  let strikethrough: boolean | undefined = undefined;

  function assertNode() {
    if (!result.length || (result[result.length - 1] as Element).type !== type) {
      result.push({
        type,
        indent: options?.indent,
        checked,
        children: [{ text: '' }],
      } as Descendant);
    }
  }

  function pushChild(c: any) {
    assertNode();
    (result[result.length - 1] as Element).children.push(c);
  }

  for (let i = 0; i < inline.children.length; i++) {
    const child = inline.children[i];
    checked = undefined;

    switch (child.type) {
      case 'text':
        {
          if (currentUrl) {
            pushChild({
              children: [
                {
                  text: child.content,
                },
              ],
              type: Elements.Link,
              url: currentUrl,
            });
            pushChild({
              text: '',
            });
            continue;
          }

          // remove any comments that start and stop in the same text line. We'll handle
          // multi line ones below
          let contentWithoutSingleLineComments = child.content.replace(/<!--.*-->/, '');
          if (type === Elements.Paragraph || type === Elements.Bulleted) {
            if (contentWithoutSingleLineComments.trim().startsWith(taskCompleted)) {
              type = Elements.Todo;
              checked = true;
              contentWithoutSingleLineComments = contentWithoutSingleLineComments.substring(
                taskCompleted.length
              );
            } else if (contentWithoutSingleLineComments.trim().startsWith(taskNotCompleted)) {
              type = Elements.Todo;
              checked = false;
              contentWithoutSingleLineComments = contentWithoutSingleLineComments.substring(
                taskNotCompleted.length
              );
            } else if (
              contentWithoutSingleLineComments.trim().startsWith(taskNotCompletedAlternate)
            ) {
              type = Elements.Todo;
              checked = false;
              contentWithoutSingleLineComments = contentWithoutSingleLineComments.substring(
                taskNotCompletedAlternate.length
              );
            }
          }

          const chunks = chunkOutUrls(contentWithoutSingleLineComments);

          for (const chunk of chunks) {
            const textNode: {
              text: string;
              bold?: boolean;
              italic?: boolean;
              strikethrough?: boolean;
            } = {
              text: chunk,
            };
            if (bold) {
              textNode.bold = true;
            }
            if (italic) {
              textNode.italic = true;
            }
            if (strikethrough) {
              textNode.strikethrough = true;
            }

            // handle multi line comments
            if (!options?.ignoreComments && textNode.text.includes(startComment)) {
              inComment = true;
              textNode.text = textNode.text.substr(0, textNode.text.indexOf(startComment));
            } else if (inComment && textNode.text.includes(endComment)) {
              inComment = false;
              textNode.text = textNode.text.substr(
                textNode.text.indexOf(endComment) + endComment.length
              );
            } else if (inComment) {
              continue;
            }

            if (
              isUrl(textNode.text, {
                require_host: true,
                require_protocol: true,
                require_valid_protocol: true,
                require_tld: false,
              })
            ) {
              pushChild({
                children: [
                  {
                    text: textNode.text,
                  },
                ],
                type: Elements.Link,
                url: textNode.text,
              });
              pushChild({
                text: '',
              });
              continue;
            }

            pushChild(textNode);
          }
        }
        break;
      case 'image':
        result.push({
          type: Elements.Image,
          children: [{ text: '' }],
          url: child.attrGet('src') ?? undefined,
        });
        break;
      case 'link_open':
        currentUrl = child.attrGet('href');
        break;
      case 'link_close':
        currentUrl = null;
        break;
      case 'strong_open':
        bold = true;
        break;
      case 'strong_close':
        bold = undefined;
        break;
      case 'em_open':
        italic = true;
        break;
      case 'em_close':
        italic = undefined;
        break;
      case 's_open':
        strikethrough = true;
        break;
      case 's_close':
        strikethrough = undefined;
        break;
      case 'code_inline':
        pushChild({
          text: child.content,
          code: true,
        });
        break;
      case 'softbreak':
        if (inComment) {
          continue;
        }
        pushChild({
          text: '\n',
        });
        break;
    }
  }

  return result;
}

export function tagToHeadline(
  tag: string
): Elements.Headline1 | Elements.Headline2 | Elements.Headline3 {
  switch (tag) {
    case 'h1':
      return Elements.Headline1;
    case 'h2':
      return Elements.Headline2;
    default:
      return Elements.Headline3;
  }
}

function processTextLike(
  tokens: Token[],
  options?: {
    type?: Elements;
    ignoreComments?: boolean;
  }
): DocumentLike {
  if (tokens.length !== 1 && tokens[0].type !== 'inline') {
    return [];
  }

  return processInline(tokens[0], options);
}

function processListItem(
  tokens: Token[],
  options?: {
    type?: Elements.Bulleted | Elements.Numbered;
    indent?: number;
    ignoreComments?: boolean;
  }
): DocumentLike {
  let results: DocumentLike = [];
  let currentTokenType: string | null = null;
  let current: Token[] = [];
  let openCount = 0;

  for (const token of tokens) {
    if (!currentTokenType) {
      currentTokenType = token.type;
      openCount = 1;
      continue;
    }

    if (currentTokenType.replace('_open', '_close') === token.type) {
      openCount--;

      if (openCount === 0) {
        switch (currentTokenType) {
          case 'paragraph_open':
            results = results.concat(processTextLike(current, options));
            break;
          case 'bullet_list_open':
            results = results.concat(
              processList(current, {
                ...options,
                type: Elements.Bulleted,
                indent: (options?.indent ?? 0) + 1,
              })
            );
            break;
          case 'ordered_list_open':
            results = results.concat(
              processList(current, {
                ...options,
                type: Elements.Numbered,
                indent: (options?.indent ?? 0) + 1,
              })
            );
            break;
        }
        current = [];
        currentTokenType = null;
        continue;
      }
    }

    if (currentTokenType === token.type) {
      openCount++;
    }

    current.push(token);
  }

  return results;
}

function processList(
  tokens: Token[],
  options?: {
    type?: Elements.Bulleted | Elements.Numbered;
    indent?: number;
    ignoreComments?: boolean;
  }
): DocumentLike {
  let results: DocumentLike = [];
  let current: Token[] = [];
  let openCount = 0;

  for (const token of tokens) {
    if (token.type === 'list_item_open') {
      openCount++;
      if (openCount === 1) {
        continue;
      }
    }

    if (token.type === 'list_item_close') {
      openCount--;

      if (openCount === 0) {
        results = results.concat(processListItem(current, options));
        current = [];
        continue;
      }
    }

    current.push(token);
  }

  return results;
}

function processTopLevel(
  tokens: Token[],
  options?: { type?: Elements; ignoreComments?: boolean }
): DocumentLike {
  const token = tokens.shift()!;
  tokens.pop();

  if (token.type === 'bullet_list_open' || token.type === 'ordered_list_open') {
    return processList(tokens, {
      ...options,
      indent: 0,
      type: token.type === 'bullet_list_open' ? Elements.Bulleted : Elements.Numbered,
    });
  }

  if (token.type === 'paragraph_open') {
    return processTextLike(tokens, { ...options, type: options?.type ?? Elements.Paragraph });
  }

  if (token.type === 'heading_open') {
    return processTextLike(tokens, { ...options, type: tagToHeadline(token.tag) });
  }

  if (token.type === 'blockquote_open') {
    return processTopLevel(tokens, { ...options, type: Elements.BlockQuote });
  }

  return [];
}

function preprocessMarkdown(markdown: string): string {
  return markdown.replace(imgTagRegex, '\n\n![image]($1)\n\n');
}

function detectLinksInElement(element: Element, detectEntityLink: DetectEntityLink) {
  for (let i = 0; i < element.children.length; i++) {
    const child = element.children[i];
    if (KitemakerElement.isElement(child)) {
      if (child.type === Elements.Link) {
        const link = detectEntityLink(child.url ?? '');

        if (link?.todo) {
          element.children[i] = {
            type: Elements.TodoMention,
            todoId: link.todo.id,
            mentionId: uuid.v4(),
            actorId: link.actorId,
            children: [{ text: '' }],
          };
        } else if (link?.entity) {
          element.children[i] = {
            type: Elements.Entity,
            entityId: link.entity.id,
            mentionId: uuid.v4(),
            actorId: link.actorId,
            children: [{ text: '' }],
          };
        }

        continue;
      }
      detectLinksInElement(child, detectEntityLink);
    }
  }
}

export function detectMentionsInElement(
  element: Element,
  detectEntityMention: DetectEntityMention
) {
  function createElement(entity: Entity, todo?: Todo, actorId?: string): Element {
    return todo
      ? {
          type: Elements.TodoMention,
          todoId: todo.id,
          mentionId: uuid.v4(),
          actorId,
          children: [{ text: '' }],
        }
      : {
          type: Elements.Entity,
          entityId: entity.id,
          mentionId: uuid.v4(),
          actorId,
          children: [{ text: '' }],
        };
  }

  if (
    KitemakerElement.isElement(element) &&
    KitemakerElement.isTextBlock(element) &&
    !KitemakerElement.isFormattedTextBlock(element)
  ) {
    const children: Element[] = [];

    for (let i = 0; i < element.children.length; i++) {
      const child = element.children[i];

      if (Text.isText(child)) {
        const matches = detectEntityMention(child.text);
        if (matches.length === 0) {
          children.push(child as any);
          continue;
        }

        const replacedElements: Element[] = [];
        const textString = child.text;

        let stringIndex = 0;
        for (const { matchIndex, match, todo, entity, actorId } of matches) {
          if (matchIndex === stringIndex) {
            replacedElements.push(createElement(entity, todo, actorId));
          } else {
            if (matchIndex > stringIndex) {
              replacedElements.push({ text: textString.substring(stringIndex, matchIndex) } as any);
              replacedElements.push(createElement(entity, todo, actorId));
            }
          }

          stringIndex = matchIndex + match.length;
        }

        if (stringIndex < textString.length - 1) {
          replacedElements.push({ text: textString.substring(stringIndex) } as any);
        }

        children.push(...replacedElements);
      } else {
        children.push(child as any);
      }
    }

    element.children = children;
  }
}

function detectLinks(document: DocumentLike, detectEntityLink: DetectEntityLink) {
  for (const block of document) {
    if (KitemakerElement.isElement(block)) {
      detectLinksInElement(block, detectEntityLink);
    }
  }
}

function detectMentions(document: DocumentLike, detectEntityMention: DetectEntityMention) {
  for (const block of document) {
    if (KitemakerElement.isElement(block)) {
      detectMentionsInElement(block, detectEntityMention);
    }
  }
}

export function deserializeMarkdown(
  markdown: string,
  options?: {
    ignoreComments?: boolean;
    detectEntityLink?: DetectEntityLink;
    detectEntityMention?: DetectEntityMention;
  }
): DocumentLike {
  try {
    const preprocessed = preprocessMarkdown(markdown);
    const parsed = md.parse(preprocessed, {});
    let result: DocumentLike = [];

    let currentTokenType: string | null = null;
    let current: Token[] = [];
    let openCount = 0;

    for (const token of parsed) {
      if ((token.type === 'fence' || token.type === 'code_block') && token.tag === 'code') {
        const language = codeLanguages.includes(token.info.toLowerCase())
          ? token.info.toLowerCase()
          : null;
        result.push({
          language,
          type: Elements.Code,
          children: [{ text: token.content }],
        });
        continue;
      }

      if (token.type === 'hr') {
        result.push({
          type: Elements.Line,
          children: [{ text: '' }],
        });
        continue;
      }

      if (!currentTokenType) {
        currentTokenType = token.type;
        openCount = 1;
        current.push(token);
        continue;
      }

      if (currentTokenType.replace('_open', '_close') === token.type) {
        openCount--;

        if (openCount === 0) {
          current.push(token);
          result = result.concat(processTopLevel(current));
          current = [];
          currentTokenType = null;
          continue;
        }
      }

      if (currentTokenType === token.type) {
        openCount++;
      }

      current.push(token);
    }

    const normalized = normalizeDocument(result);
    if (options?.detectEntityLink) {
      detectLinks(normalized, options.detectEntityLink);
    }
    if (options?.detectEntityMention) {
      detectMentions(normalized, options.detectEntityMention);
    }
    return normalized;
  } catch (e) {
    return [];
  }
}

function serializeChildren(
  element: TextfulElement | LinkElement | CommentElement | InsightElement | ChatMessageElement,
  lookups: Lookups
): string {
  return element.children.map((n: any) => serializeNode(n, lookups)).join('');
}

function serializeElement(
  element: Element,
  nextElement: Element | null,
  voidPlaceholders: boolean,
  lookups: Lookups
): string {
  const newLine = nextElement && nextElement.type === element.type ? '' : '\n';

  switch (element.type) {
    case Elements.Paragraph: {
      const childrenText = serializeChildren(element, lookups);
      if (childrenText === '\n' || childrenText === '') {
        return childrenText;
      }
      return `${childrenText}\n`;
    }
    case Elements.Headline1: {
      const childrenText = serializeChildren(element, lookups);
      return `# ${childrenText}\n`;
    }
    case Elements.Headline2: {
      const childrenText = serializeChildren(element, lookups);
      return `## ${childrenText}\n`;
    }
    case Elements.Headline3: {
      const childrenText = serializeChildren(element, lookups);
      return `### ${childrenText}\n`;
    }
    case Elements.BlockQuote: {
      const childrenText = serializeChildren(element, lookups);
      return `${childrenText
        .split('\n')
        .map(s => `> ${s}`)
        .join('\n')}\n`;
    }
    case Elements.Code: {
      const childrenText = serializeChildren(element, lookups);
      return `${codeBlockIndicator}${
        element.language ?? ''
      }\n${childrenText}\n${codeBlockIndicator}\n`;
    }
    case Elements.Numbered: {
      const childrenText = serializeChildren(element, lookups);
      return `${repeat('   ', element.indent)}1. ${childrenText}${newLine}`;
    }
    case Elements.Bulleted: {
      const childrenText = serializeChildren(element, lookups);
      return `${repeat('  ', element.indent)}* ${childrenText}${newLine}`;
    }
    case Elements.Todo: {
      const childrenText = serializeChildren(element, lookups);
      return `${repeat('  ', element.indent)}- [${
        element.checked ? 'x' : ' '
      }] ${childrenText}${newLine}`;
    }
    case Elements.SmartTodo: {
      const childrenText = serializeChildren(element, lookups);
      const todo = lookups.todoLookup(element.todoId);
      return `${repeat('  ', element.indent)}- [${
        todo?.checked ? 'x' : ' '
      }] ${childrenText}${newLine}`;
    }
    case Elements.Image: {
      if (voidPlaceholders) {
        return '\\[Image\\]\n';
      }
      return element?.url ? `![](${element.url})\n` : '';
    }
    case Elements.Video: {
      if (voidPlaceholders) {
        return '\\[Video\\]\n';
      }
      return element?.url ? `![](${element.url})\n` : '';
    }
    case Elements.File: {
      if (voidPlaceholders) {
        return '\\[File\\]\n';
      }
      if (!element.url) {
        return '';
      }
      if (element.name) {
        return `[${element.name}](${element.url})\n`;
      }
      return `${element.url}\n`;
    }
    case Elements.Loom: {
      if (voidPlaceholders) {
        return '\\[Loom\\]\n';
      }
      return element.url ? `${element.url}\n` : '';
    }
    case Elements.Figma: {
      if (voidPlaceholders) {
        return '\\[Figma\\]\n';
      }
      return element.url ? `${element.url}\n` : '';
    }
    case Elements.Giphy: {
      if (voidPlaceholders) {
        return '\\[Giphy\\]\n';
      }
      return element.url ? `${element.url}\n` : '';
    }
    case Elements.MathExpression: {
      if (voidPlaceholders) {
        return element.katex ?? '\\[Math\\]\n';
      }
      return `${codeBlockIndicator}latex\n${element.katex ?? ''}\n${codeBlockIndicator}\n`;
    }
    case Elements.Line:
      return '-----\n';
    case Elements.Chat: {
      return element.children
        .map(c => serializeElement(c, null, voidPlaceholders, lookups))
        .join('\n\n');
    }
    case Elements.ChatMessage: {
      const messageText = element.children
        .map(c => serializeElement(c, null, voidPlaceholders, lookups))
        .join('\n');
      return `${element.sender}: ${messageText}`;
    }
    case Elements.Github: {
      return element.url ? `${element.url}\n` : '';
    }
  }

  // if in doubt, serialize the children so at least we don't lose text data
  return serializeChildren(element as any, lookups);
}

function serializeInline(inline: Element, lookups: Lookups): string {
  switch (inline.type) {
    case Elements.Link: {
      const linkText = serializeChildren(inline, lookups);
      const url = inline.url;
      if (
        !url ||
        linkText === url ||
        `https://${linkText}` === url ||
        `mailto:${linkText}` === url ||
        !inline.fromHover
      ) {
        return linkText;
      }
      return `[${linkText}](${url})`;
    }
    case Elements.Math:
      return `$$${inline.katex ?? ''}$$`;
    case Elements.Issue: {
      if (inline.issueId) {
        const entity = lookups.entityLookup(inline.issueId);
        if (entity) {
          return `[${entity.number}](${entity.link})`;
        }
      }
      return '';
    }
    case Elements.Entity: {
      if (inline.entityId) {
        const entity = lookups.entityLookup(inline.entityId);
        if (entity) {
          return `[${entity.number}](${entity.link})`;
        }
      }
      return '';
    }
    case Elements.User: {
      const user = lookups.userLookup(inline.userId);
      if (!user) {
        return '';
      }
      return `@${user}`;
    }
    case Elements.Group: {
      return `@${inline.groupType}`;
    }
    case Elements.Comment: {
      return serializeChildren(inline, lookups);
    }
    case Elements.Insight: {
      return serializeChildren(inline, lookups);
    }
    case Elements.CustomEmoji: {
      return `:${inline.emojiId}:`;
    }
  }

  // if in doubt, serialize the children so at least we don't lose text data
  return serializeChildren(inline as any, lookups);
}

function serializeText(text: Text): string {
  let result = text.text ?? '';
  if (text.bold) {
    result = `**${result}**`;
  }
  if (text.italic) {
    result = `_${result}_`;
  }
  if (text.strikethrough) {
    result = `~~${result}~~`;
  }
  if (text.code) {
    result = `\`${result}\``;
  }
  return result;
}

function serializeNode(node: Node, lookups: Lookups): string {
  if (Text.isText(node)) {
    return serializeText(node);
  }
  if (KitemakerElement.isElement(node) && KitemakerElement.isInline(node)) {
    return serializeInline(node, lookups);
  }

  return '';
}

export function serializeToMarkdown(
  doc: DocumentLike,
  lookups: Lookups,
  voidPlaceholders = false
): string {
  return doc
    .map((node, index) =>
      serializeElement(node as Element, doc[index + 1] as Element, voidPlaceholders, lookups)
    )
    .join('\n');
}
