import { capitalize } from 'lodash';
import * as React from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { colorWithOpacity } from '../../../utils/color';
import { Icon, IconSize } from '../icon';
import { Tooltip } from '../tooltip';
import Pill from './pill';
import { MetadataSize } from './size';

export default function Impact({
  color,
  shortName,
  longName,
  size = MetadataSize.Small,
  forceDarkMode,
  disableTooltip,
  className,
}: {
  color: string;
  shortName: string;
  longName: string;
  size?: MetadataSize;
  forceDarkMode?: boolean;
  disableTooltip?: boolean;
  className?: string;
}) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  const { darkMode } = useDarkMode();

  const fill = colorWithOpacity(color, 9, forceDarkMode !== undefined ? forceDarkMode : darkMode);

  const content = (
    <Pill className={className} style={{ color: fill }} size={size}>
      <Icon style={{ fill }} size={iconSize} icon="impact" />
      <span>{shortName}</span>
    </Pill>
  );

  if (disableTooltip) {
    return content;
  }

  return (
    <Tooltip side="bottom" align="start" content={`${capitalize(longName)} impact`}>
      {content}
    </Tooltip>
  );
}
