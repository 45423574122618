import React from 'react';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { Tooltip } from './tooltip';

export function TooltipIfTruncated({
  children,
  disableTooltip,
}: {
  children: React.ReactNode;
  disableTooltip?: boolean;
}) {
  const [tooltipEnabled, setTooltipEnabled] = React.useState(false);
  const ref = React.useRef<HTMLSpanElement>(null);
  let wrapperContent = (
    <span className="ellipsis" ref={ref}>
      {children}
    </span>
  );

  useComponentDidMount(() => {
    if (!ref.current) {
      return;
    }
    if (!disableTooltip && ref.current.offsetWidth < ref.current.scrollWidth) {
      setTooltipEnabled(true);
    }
  });

  if (tooltipEnabled) {
    wrapperContent = (
      <Tooltip disableHoverableContent style={{ zIndex: 9999 }} content={children}>
        {wrapperContent}
      </Tooltip>
    );
  }

  return wrapperContent;
}
