import * as React from 'react';
import { writeToClipboard } from '../components/clipboardText';
import Modal from '../components/modal';
import { ButtonStyle, IconButton } from '../components/new/button';
import { Modals } from '../contexts/modalContext';
import { getDiagnostics } from '../diagnostics';
import styles from './diagnosticsModal.module.scss';

function DiagnosticsContent() {
  const content = getDiagnostics();

  return (
    <div className="relative">
      <div className={styles.content}>
        {content.map((line, index) => (
          <div key={index}>{JSON.stringify(line)}</div>
        ))}
      </div>
      <IconButton
        icon="copy"
        className={styles.copy}
        buttonStyle={ButtonStyle.SecondaryOverlay}
        onClick={() => {
          writeToClipboard(
            getDiagnostics()
              .map(d => JSON.stringify(d))
              .join('\n'),
            'Diagnostic information'
          );
        }}
      />
    </div>
  );
}

export default function DiagnosticsModal() {
  return (
    <>
      <Modal modalId={Modals.Diagnostics} title="Diagnostic Information">
        <DiagnosticsContent />
      </Modal>
    </>
  );
}
