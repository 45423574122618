import * as React from 'react';
import { updatePassword } from '../api/auth';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { TextInput } from '../components/new/textInput';
import { toast } from '../components/toast';
import { useModals, Modals } from '../contexts/modalContext';
import { trackerPageLoad } from '../tracker';

function ChangePasswordContent({ onClose }: { onClose: () => void }) {
  const [newPassword, setNewPassword] = React.useState('');
  const [repeatNewPassword, setRepeatNewPassword] = React.useState('');

  React.useEffect(() => trackerPageLoad('Change password'), []);

  const disabled = newPassword.length < 8 || newPassword !== repeatNewPassword;

  return (
    <form
      className="form grayed"
      onSubmit={async e => {
        e.preventDefault();
        try {
          const ok = await updatePassword(newPassword);
          if (!ok) {
            throw new Error('An unknown error occurred');
          }
          onClose();
          toast.info('Password successfully changed');
        } catch (err) {
          toast.error(err);
        }
        setNewPassword('');
        setRepeatNewPassword('');
      }}
    >
      <ModalContentWrapper>
        <label htmlFor="password">New password</label>
        <TextInput
          name="password"
          type="password"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          autoFocus={true}
          className="fullWidth mb16"
        />
        <label htmlFor="repeatPassword">Repeat password</label>
        <TextInput
          name="repeatPassword"
          type="password"
          value={repeatNewPassword}
          onChange={e => setRepeatNewPassword(e.target.value)}
          className="fullWidth mb16"
        />
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <Button type="button" buttonStyle={ButtonStyle.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button type="submit" buttonStyle={ButtonStyle.Primary} disabled={disabled}>
          Change password
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export default function ChangePasswordModal() {
  const modalManager = useModals();

  return (
    <>
      <Modal modalId={Modals.ChangePassword} title="Change Password">
        <ChangePasswordContent
          onClose={() => {
            modalManager.closeModal(Modals.ChangePassword);
          }}
        />
      </Modal>
    </>
  );
}
