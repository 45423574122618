import * as React from 'react';
import { Feedback } from '../../../sync/__generated/models';
import { ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
} from '../../components/new/copyAndPaste';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import {
  CompanyPersonPicker,
  CompanyPersonPickerOptions,
  feedbackToCompanyPersonPickerState,
} from '../../components/new/pickers/companyPersonPicker';
import { MemberPicker } from '../../components/new/pickers/memberPicker';
import { TagPicker, feedbackToTagPickerState } from '../../components/new/pickers/tagPicker';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import {
  useAddOwnersToFeedback,
  useAddTagToFeedback,
  useDeleteFeedbacks,
  useRemoveOwnersFromFeedback,
  useRemoveTagFromFeedbak,
  useUpdateFeedbacks,
} from '../../syncEngine/actions/feedback';
import {
  assignIssueKey,
  copyIssueNumberKey,
  customerKey,
  deleteKey,
  editTitleKey,
  labelIssueKey,
  toggleFeedbackProcessedKey,
} from '../../utils/config';

function Owners({ feedback, onDone }: { feedback: Feedback; onDone: () => void }) {
  const addOwnersToFeedback = useAddOwnersToFeedback();
  const removeOwnersFromFeedback = useRemoveOwnersFromFeedback();

  return (
    <Submenu>
      <SubmenuTrigger icon="member" shortcut={assignIssueKey}>
        Owners
      </SubmenuTrigger>
      <SubmenuContent className="menuPicker menuMedium">
        <MemberPicker
          term="owner"
          state={{ [feedback.id]: feedback.ownerIds }}
          onMemberAdded={(feedbackIds, userId) => {
            addOwnersToFeedback(feedbackIds, [userId]);
          }}
          onMemberRemoved={(feedbackIds, userId) => {
            removeOwnersFromFeedback(feedbackIds, [userId]);
          }}
          onDone={onDone}
        />
      </SubmenuContent>
    </Submenu>
  );
}

function Tags({ feedback, onDone }: { feedback: Feedback; onDone: () => void }) {
  const addTagsToFeedback = useAddTagToFeedback();
  const removeTagsFromFeedback = useRemoveTagFromFeedbak();

  return (
    <Submenu>
      <SubmenuTrigger icon="label" shortcut={labelIssueKey}>
        Tags
      </SubmenuTrigger>
      <SubmenuContent className="menuPicker menuMedium">
        <TagPicker
          state={feedbackToTagPickerState([feedback])}
          onTagAdded={(feedbackIds, userId) => {
            addTagsToFeedback(feedbackIds, [userId]);
          }}
          onTagRemoved={(feedbackIds, userId) => {
            removeTagsFromFeedback(feedbackIds, [userId]);
          }}
          onDone={onDone}
        />
      </SubmenuContent>
    </Submenu>
  );
}

function Customer({ feedback, onDone }: { feedback: Feedback; onDone: () => void }) {
  const updateFeedback = useUpdateFeedbacks();

  return (
    <Submenu>
      <SubmenuTrigger icon="org" shortcut={customerKey}>
        Customer
      </SubmenuTrigger>
      <SubmenuContent className="menuHuge menuPicker" style={{ maxHeight: 450 }}>
        <CompanyPersonPicker
          disableCreation
          state={feedbackToCompanyPersonPickerState([feedback])}
          onDone={onDone}
          onCompanyAdded={companyId => {
            updateFeedback([feedback.id], { companyId, personId: null });
            onDone();
          }}
          onCompanyRemoved={() => {
            updateFeedback([feedback.id], { companyId: null, personId: null });
            onDone();
          }}
          onPersonAdded={personId => {
            updateFeedback([feedback.id], { companyId: null, personId: personId });
            onDone();
          }}
          onPersonRemoved={() => {
            updateFeedback([feedback.id], { companyId: null, personId: null });
            onDone();
          }}
          options={CompanyPersonPickerOptions.All}
          currentId={feedback.companyId ?? feedback.personId}
        />
      </SubmenuContent>
    </Submenu>
  );
}

export function FeedbackMenu({
  feedback,
  closeMenu,
  onChangeTitle,
}: {
  feedback: Feedback;
  closeMenu: () => void;
  onChangeTitle?: () => void;
}) {
  const deleteFeedback = useDeleteFeedbacks();
  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const updateFeedback = useUpdateFeedbacks();
  const tinyScreen = useIsTinyScreen();

  return (
    <>
      {!tinyScreen && onChangeTitle && (
        <>
          <DropdownMenuItem icon="edit" shortcut={editTitleKey} onClick={onChangeTitle}>
            Change title
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </>
      )}
      <Owners feedback={feedback} onDone={closeMenu} />
      <Tags feedback={feedback} onDone={closeMenu} />
      <Customer feedback={feedback} onDone={closeMenu} />
      <DropdownMenuItem
        icon={!feedback.processed ? 'select_checkmark' : 'exit'}
        shortcut={toggleFeedbackProcessedKey}
        onClick={() => {
          updateFeedback([feedback.id], { processed: !feedback.processed });
        }}
      >
        {!feedback.processed ? 'Mark processed' : 'Mark unprocessed'}
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="copy"
        shortcut={copyIssueNumberKey}
        onClick={() => {
          copyEntityNumber([feedback.id]);
        }}
      >
        Copy number
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="link"
        shortcut={copyIssueNumberKey}
        onClick={() => {
          copyEntityLink([feedback.id]);
        }}
      >
        Copy link
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteFeedback([feedback.id]);
        }}
      >
        Delete
      </DropdownMenuItem>
    </>
  );
}

export function FeedbackMenuButton({
  feedback,
  size,
  onOpenChange,
  onChangeTitle,
}: {
  feedback: Feedback;
  size?: ButtonSize;
  onOpenChange?: (isOpen: boolean) => void;
  onChangeTitle?: () => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  React.useEffect(() => {
    onOpenChange?.(menuOpen);
  }, [menuOpen, onOpenChange]);

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton buttonStyle={ButtonStyle.BareSubtle} icon="more" size={size} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
      >
        <FeedbackMenu feedback={feedback} closeMenu={closeMenu} onChangeTitle={onChangeTitle} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
