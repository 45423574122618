import Timeline, {
  CustomMarker,
  TimelineHeaders,
  TimelineMarkers,
} from '@kitemaker/react-calendar-timeline';
import cn from 'classnames';
import { groupBy, sortBy } from 'lodash';
import moment from 'moment';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { atomFamily, useRecoilValue } from 'recoil';
import { between } from '../../../shared/utils/sorting';
import { Initiative } from '../../../sync/__generated/models';
import { CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { useDarkMode } from '../../hooks/useDarkMode';
import {
  useUpdateInitiativeSortInTimeline,
  useUpdateInitiatives,
} from '../../syncEngine/actions/intiatives';
import { localStorageEffect } from '../../syncEngine/effects';
import { useEntityPath } from '../../syncEngine/selectors/entities';
import {
  InitiativeWithDates,
  initiativesForTimelineSelector,
  itemsAndGroupsForTimelineSelector,
  useGetInitiativeForTimelineObject,
} from '../../syncEngine/selectors/intiatives';
import { colorWithOpacity } from '../../utils/color';
import {
  Item,
  findNearestDayBoundary,
  localToUtc,
  localToUtcStartDay,
  oneDay,
} from '../../utils/timelines';
import { MainHeader, SubHeader } from './headers';
import { CreateMarker, calculatePlaceholderPosition } from './markers';
import { AddToTimeline, CalenderItem, DragPlaceholder, TimelineItem } from './timelineItems';
import styles from './timelineViewScreen.module.scss';

export interface TimelineAdder {
  addToTimeline: () => void;
}

export type Group = {
  id: number;
  sort?: string;
};

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 1, // Monday is the first day of the year.
  },
});

export const RESIZE_INDCICATOR_ID = 'resize-date';
const VISIBLE_PERIODS = 5;

export const itemLineHeight = 50;
export const itemHeightRatio = 0.8;
export const workItemPadding = 4;

function RenderedItem({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
  group,
  interacting,
  edit,
  roadmapId,
  setPlaceHolder,
  setEdit,
  addToTimeline,
  handleMouseEnter,
  handleMouseLeave,
}: {
  item: Item;
  itemContext: any;
  getItemProps: any;
  getResizeProps: any;
  group: any;
  interacting: boolean;
  edit: string[];
  roadmapId: string;
  setPlaceHolder: Dispatch<
    SetStateAction<{ startTime: number; endTime: number; groupId: number } | null>
  >;
  setEdit: Dispatch<SetStateAction<string[]>>;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  addToTimeline: (item: Item, sort: string) => (title: string) => void;
}) {
  const { style, className, ...itemProps } = getItemProps();
  const { darkMode } = useDarkMode();

  delete style.background;
  delete style.border;
  delete style.color;
  delete style.fontSize;

  if (itemContext.dragging) {
    return <DragPlaceholder style={style} itemProps={itemProps}></DragPlaceholder>;
  }

  if (item.object === null) {
    return (
      <AddToTimeline
        item={item}
        getItemProps={getItemProps}
        addToTimeline={addToTimeline(item, group.sort)}
        onBlur={() => setPlaceHolder(null)}
      />
    );
  } else if (item.object.__typename === 'Initiative') {
    const initiative = item.object as Initiative;
    const margin = 2;
    style.borderColor = colorWithOpacity(initiative.color, 7, darkMode);
    style['--hoverColor'] = `var(--${initiative.color}2)`;

    return (
      <>
        <CalenderItem
          itemContext={itemContext}
          className={cn(styles.release)}
          getResizeProps={getResizeProps}
          itemProps={itemProps}
          style={style}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          margin={margin}
          interacting={interacting}
          edit={edit.includes(initiative.id)}
        >
          <TimelineItem
            roadmapId={roadmapId}
            initiative={initiative}
            itemContext={itemContext}
            onEdit={() => {
              setEdit([...edit, initiative.id]);
            }}
            onEditComplete={() => {
              setEdit([...edit.filter(id => id !== initiative.id)]);
            }}
            edit={edit.includes(initiative.id)}
          />
        </CalenderItem>
      </>
    );
  }
  return null;
}

export const timelineViewPeriod = atomFamily<'week' | 'month' | 'quarter', string>({
  key: 'timelineViewPeriod',
  default: 'week',
  effects: key => [localStorageEffect(`__timelineViewPeriod_${key}`)],
});

function TodayMarker({
  forPortalRef,
}: {
  forPortalRef: React.MutableRefObject<HTMLDivElement | null>;
}) {
  const date = moment().format('DD');
  return (
    <CustomMarker date={moment().startOf('day').valueOf()}>
      {({ styles: givenStyles }: { styles: CSSProperties }) => (
        <>
          <div
            style={{
              ...givenStyles,
              width: '0px',
              borderLeft: 'solid 1px var(--blueA9)',
              zIndex: 3,
            }}
          >
            {forPortalRef.current &&
              ReactDOM.createPortal(
                <>
                  <span style={{ left: givenStyles.left }} className={styles.todayMarker}>
                    {date}
                  </span>
                  <div className={styles.todayHeaderLine} style={{ left: givenStyles.left }} />
                </>,
                forPortalRef.current
              )}
          </div>
        </>
      )}
    </CustomMarker>
  );
}

function TimelineView(
  {
    roadmapId,
  }: {
    roadmapId: string;
  },
  ref: React.Ref<TimelineAdder>
): JSX.Element {
  const modals = useModals();

  const [interacting, setInteracting] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<string[]>([]);
  const [hoverOverItem, setHoverOverItem] = React.useState(false);
  const [placeHolder, setPlaceHolder] = React.useState<{
    startTime: number;
    endTime: number;
    groupId: number;
  } | null>(null);
  const history = useHistory();
  const entityPath = useEntityPath();

  const period = useRecoilValue(timelineViewPeriod(roadmapId));
  const [zoom, setZoom] = React.useState(moment.duration(VISIBLE_PERIODS, period).asMilliseconds());

  const cursorRef = React.useRef({ x: 0, y: 0 });
  const groupsRef = React.useRef<Group[]>([]);
  const itemsRef = React.useRef<Item[]>([]);
  const timelineRef = React.useRef<any>(null);
  const scrollRef = React.useRef<HTMLElement | null>(null);
  const forPortalRef = React.useRef<HTMLDivElement | null>(null);
  const initialRender = React.useRef(true);

  const initiatives = useRecoilValue(initiativesForTimelineSelector(roadmapId));
  const initiativesRef = React.useRef(initiatives);
  initiativesRef.current = initiatives;

  const updateInitiatives = useUpdateInitiatives();
  const updateInitiativeSortInTimeline = useUpdateInitiativeSortInTimeline();
  const getInitiativeObject = useGetInitiativeForTimelineObject();

  const [defaultTimeStart, defaultTimeEnd]: [moment.Moment, moment.Moment] = React.useMemo(() => {
    const start = moment().subtract('1', period);
    const end = moment().add(VISIBLE_PERIODS - 1, period);

    return [start, end];
  }, []);

  React.useImperativeHandle(ref, () => ({
    addToTimeline: () => {
      const groups = groupsRef.current;
      const initiatives = initiativesRef.current;
      const initiativesBySort = groupBy(initiatives, 'sort');

      const startTime = moment().startOf(period).valueOf();
      const endTime = moment().endOf(period).valueOf();

      const firstGroup = groups.find(group => {
        if (!group.sort) {
          return false;
        }
        const initiativesInGroup = initiativesBySort[group.sort];
        if (!initiativesInGroup) {
          return false;
        }

        const overlap = initiativesInGroup.reduce(
          (acc, r) => acc || Math.max(r.startDate, startTime) < Math.min(r.dueDate, endTime),
          false
        );

        return !overlap;
      });

      setPlaceHolder({
        startTime,
        endTime,
        groupId: firstGroup?.id ?? groupsRef.current[groupsRef.current.length - 1].id,
      });
    },
  }));

  React.useEffect(() => {
    if (!initialRender.current) {
      const from = moment().subtract(1, period).valueOf();
      const to = moment()
        .add(VISIBLE_PERIODS - 1, period)
        .valueOf();

      timelineRef.current?.updateScrollCanvas(from, to, true);
      setZoom(moment.duration(VISIBLE_PERIODS, period).asMilliseconds());
    } else {
      initialRender.current = false;
    }
  }, [period]);

  // this is used for the resize indictator (white box with date). We need it to render above
  // other releases. The best place to calculate it's position is inside the CalenderItem as
  // all the information required to position it is available there. If we do that, it ends up
  // as a sibling of the release getting resized and will get rendered underneath other releases.
  // z-index does not work to fix it, instead we need to use a React Portal to render it in a
  // different part of the DOM. Since it is positioned absolutely, it needs to be a child of the
  // main scroll element. This effect is responsible for creating that div.
  React.useEffect(() => {
    const div = document.createElement('div');
    div.setAttribute('id', RESIZE_INDCICATOR_ID);
    scrollRef.current?.appendChild(div);
    return () => {
      document.getElementById(RESIZE_INDCICATOR_ID)?.remove();
    };
  }, []);

  const addToTimeline = (_item: Item, _sort: string) => (_title: string) => {
    // createReleasePlan(space.id, title, item.start_time, item.end_time, item.color, sort);
    setPlaceHolder(null);
  };

  function handleMouseEnter() {
    setHoverOverItem(true);
  }
  function handleMouseLeave() {
    setHoverOverItem(false);
  }

  function finishInteracting() {
    // Safari doesn't fire a 'mouseup' event when you let go of an item after dragging an item.
    // fire our own event instead ;)
    const event = new MouseEvent('mouseup', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    scrollRef.current?.dispatchEvent(event);
    setHoverOverItem(false);
    setInteracting(false);
  }

  function itemRenderer({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
    group,
  }: {
    item: Item;
    itemContext: any;
    getItemProps: any;
    getResizeProps: any;
    group: any;
  }) {
    return (
      <RenderedItem
        item={item}
        itemContext={itemContext}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        addToTimeline={addToTimeline}
        setPlaceHolder={setPlaceHolder}
        setEdit={setEdit}
        edit={edit}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        interacting={interacting}
        group={group}
        roadmapId={roadmapId}
      />
    );
  }
  function handleItemClick(itemId: string, e: any) {
    // horrible hack to get around onClick events firing on the parent item despite stopping propagation.
    // this makes it so that the item doesn't "open"
    if (typeof e.target.className !== 'string' || e.target.className.includes('button')) {
      return;
    }

    const item = getInitiativeObject(itemId, roadmapId);
    if (!item) {
      return; // error
    }

    if (edit.includes(itemId)) {
      return;
    }

    const path = entityPath(itemId);
    history.push({
      pathname: path,
      state: {
        backUrl: history.location.pathname,
        backSearch: history.location.search,
      },
    });
  }

  function handleItemStartInteraction() {
    setInteracting(true);
    setHoverOverItem(true);
  }
  function handleItemResize(itemId: string, time: number, edge: string) {
    const item = getInitiativeObject(itemId, roadmapId);
    const initiatives = initiativesRef.current;
    if (!item) {
      return; // error
    }
    if (item.__typename === 'Initiative') {
      const initiative = item as InitiativeWithDates;
      const startDate = edge === 'left' ? time : initiative.startDate;
      const endDate = edge === 'left' ? initiative.dueDate : time;
      let sort = undefined;
      const sortedInitiatives = sortBy(initiatives, 'sort');

      const groups = groupsRef.current.filter(g => g.sort);

      for (let i = 0; i < sortedInitiatives.length; i++) {
        const r = sortedInitiatives[i];
        if (r.id === initiative.id || r.sort !== initiative.sort) {
          continue;
        }

        if (Math.max(r.startDate, startDate) < Math.min(r.dueDate, endDate)) {
          const previousGroup = groups[groups.findIndex(g => g.sort === r.sort) - 1];
          sort = between({ before: r.sort, after: previousGroup?.sort });
        }
      }

      if (edge === 'left') {
        updateInitiatives([itemId], {
          startDate: localToUtc(time),
        });
      } else {
        updateInitiatives([itemId], {
          dueDate: localToUtcStartDay(time - oneDay),
        });
      }
      if (sort) {
        updateInitiativeSortInTimeline(itemId, roadmapId, sort);
      }
    }
    finishInteracting();
  }

  function handleItemMove(itemId: string, dragTime: number, newGroupOrder: number, groups: any[]) {
    const obj = getInitiativeObject(itemId, roadmapId);
    const initiatives = initiativesRef.current;
    if (!obj) {
      return; // error
    }
    const newGroup = groups[newGroupOrder];
    if (obj.__typename === 'Initiative') {
      const initiative = obj as InitiativeWithDates;
      const startDate = dragTime;
      const diff = initiative.dueDate - initiative.startDate;
      const endDate = dragTime + diff;

      let sort = newGroup.sort;
      for (const r of initiatives) {
        if (r.id === initiative.id || r.sort !== newGroup.sort) {
          continue;
        }

        if (Math.max(r.startDate, startDate) < Math.min(r.dueDate, endDate)) {
          const originalGroup = groups.find(g => g.sort === initiative.sort);
          if (!originalGroup) {
            continue;
          }
          if (originalGroup && originalGroup.id != newGroup.id) {
            if (originalGroup.id > newGroup.id) {
              const previousGroup = groups[groups.findIndex(g => g.sort === r.sort) - 1];
              sort = between({ before: r.sort, after: previousGroup?.sort });
            } else {
              const nextGroup = groups[groups.findIndex(g => g.sort === r.sort) + 1];
              sort = between({ after: r.sort, before: nextGroup?.sort });
            }
          } else {
            if (initiative.startDate < startDate) {
              const previousGroup = groups[groups.findIndex(g => g.sort === r.sort) - 1];
              sort = between({ before: r.sort, after: previousGroup?.sort });
            } else {
              const nextGroup = groups[groups.findIndex(g => g.sort === r.sort) + 1];
              sort = between({ after: r.sort, before: nextGroup?.sort });
            }
          }
        }
      }

      updateInitiatives([itemId], {
        startDate: localToUtc(startDate),
        dueDate: localToUtcStartDay(endDate),
      });
      updateInitiativeSortInTimeline(itemId, roadmapId, sort);
    }
    finishInteracting();
  }

  function handleMouseMove(e: any) {
    cursorRef.current.x = e.clientX;
    cursorRef.current.y = e.clientY;
  }

  function handleCanvasClick(groupId: number, time: number) {
    if (placeHolder) {
      setPlaceHolder(null);
    } else {
      const group = groupsRef.current.find(g => g.id === groupId);
      if (!group) {
        return;
      }
      const { startTime, endTime } = calculatePlaceholderPosition(
        itemsRef.current,
        group,
        time,
        period
      );
      if (startTime && endTime && groupId % 1000 === 0) {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.AddToTimeline,
          context: {
            startDate: localToUtc(startTime),
            endDate: localToUtcStartDay(endTime),
            sort: group.sort,
            roadmapId,
          },
        });
      }
    }
  }

  function moveResizeValidator(
    action: 'move' | 'resize',
    item: Item,
    time: number,
    _resizeEdge: 'left' | 'right',
    index: number,
    delta: number
  ) {
    const newTime = findNearestDayBoundary(time);

    if (action === 'move') {
      const groups = groupsRef.current;
      const newGroupIndex = index + delta;
      const newGroup = groups[newGroupIndex];

      let newDelta = newGroup.sort ? delta : 0;
      if (newDelta > 0 && newGroupIndex == groups.length - 1) {
        const initiativesInLastRow = initiatives.filter(
          r => r.id !== item.object?.id && r.sort === groups[groups.length - 2]?.sort
        ).length;
        if (initiativesInLastRow === 0) {
          newDelta = 0;
        }
      }
      if (item.object?.__typename === 'Initiative') {
        if (newGroup.id % 1000 != 0) {
          const goodGroupIndex = groups.findIndex(g => g.id >= newGroup.id && g.id % 1000 === 0)!;
          newDelta = goodGroupIndex - index;
        }
      }
      return { dragTime: newTime, dragGroupDelta: newDelta };
    }
    return newTime;
  }

  const itemsAndGroups = useRecoilValue(itemsAndGroupsForTimelineSelector({ roadmapId, edit }));
  let { items, groups } = itemsAndGroups;

  // hack hack hack
  // need a way to get all groups/items in callback functions
  groupsRef.current = groups;
  itemsRef.current = items;

  items = [...items];
  groups = [...groups];

  if (placeHolder) {
    items.unshift({
      id: '999999',
      group: placeHolder.groupId,
      start_time: placeHolder.startTime,
      end_time: placeHolder.endTime,
      color: 'gray',
      title: '',
      object: null,
      canMove: false,
      canResize: false,
    });
  }

  return (
    <div key={roadmapId} className={styles.timelineView} onMouseMove={handleMouseMove}>
      <Timeline
        ref={timelineRef}
        scrollRef={(el: HTMLElement) => (scrollRef.current = el)}
        groups={groups}
        items={items}
        minZoom={zoom}
        maxZoom={zoom}
        stackItems
        useResizeHandle
        itemTouchSendsClick
        itemHeightRatio={itemHeightRatio}
        moveResizeValidator={moveResizeValidator}
        lineHeight={itemLineHeight}
        canMove
        stickyHeader={true}
        onItemMove={handleItemMove}
        onItemResize={handleItemResize}
        onItemStartInteraction={handleItemStartInteraction}
        onItemClick={handleItemClick}
        canChangeGroup
        canResize={'both'}
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
        showCursorLine
        sidebarWidth={0}
        placeholder
        onCanvasClick={handleCanvasClick}
        itemRenderer={itemRenderer}
        timeSteps={{
          second: 0,
          minute: 0,
          hour: 0,
          day: period === 'week',
          week: period === 'month',
          month: 1,
          year: 1,
        }}
      >
        <TimelineMarkers>
          <TodayMarker forPortalRef={forPortalRef} />
          {!hoverOverItem && !interacting && (
            <CreateMarker
              scrollRef={scrollRef}
              period={period}
              groups={groups}
              cursorRef={cursorRef}
              items={items}
            />
          )}
        </TimelineMarkers>
        <TimelineHeaders
          calendarHeaderClassName={styles.timeLineHeader}
          className={cn([styles.timeLineHeader, 'sticky'])}
        >
          <div id="todayMarker" className="relative" ref={forPortalRef}></div>
          <MainHeader period={period} />
          <SubHeader period={period} />
        </TimelineHeaders>
      </Timeline>
    </div>
  );
}

export default React.forwardRef<TimelineAdder, any>(TimelineView);
