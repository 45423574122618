import gql from 'graphql-tag';
import { allAppliedTransactionPayloadPropertiesFragment } from '../../sync/__generated/fragments';

// FIXME we have this one because to the backend, the current user (with a bunch of stuff like which tutorials they've completed, etc.)
// is the same object with the same ID as all other users. This means that for two different contexts, we want two different sets of
// properties. We should probably find a nicer way to reconcile that.
export const nonCurrentUserFragment = gql`
  fragment NonCurrentUserFragment on User {
    id
    username
    primaryEmail
    name
    avatar
    version
    deleted
    createdAt
    updatedAt
  }
`;

export const billingInfoFragment = gql`
  fragment BillingInfoFragment on BillingInformation {
    id
    availableProducts {
      id
      displayName
      description
      monthlyPlan {
        id
        price
        currency
      }
      yearlyPlan {
        id
        price
        currency
      }
    }
    currentPeriodEnd
    cancelAtPeriodEnd
    nextInvoice {
      amount
      currency
    }
    customerPortalURL
  }
`;

export const appliedCollectionTransactionPayloadFragment = gql`
  fragment AppliedCollectionTransactionPayloadFragment on AppliedCollectionTransactionPayload {
    type
    modelId
    modelType
    modelVersion
    modelUpdatedAt
    collection {
      collection
      modelIds
    }
  }
`;

export const appliedCollaborativeDocumentTransactionPayloadFragment = gql`
  fragment AppliedCollaborativeDocumentTransactionPayloadFragment on AppliedCollaborativeDocumentTransactionPayload {
    type
    modelId
    modelType
    currentVersion
    changes {
      version
      operations
      actorId
      clientId
    }
  }
`;

export const appliedTransactionPayloadFragment = gql`
  fragment AppliedTransactionPayloadFragment on AppliedTransactionPayload {
    type
    modelId
    modelType
    modelVersion
    modelUpdatedAt
    nullPropertiesToApply
    properties {
      ...AllAppliedTransactionPayloadPropertiesFragment
    }
  }
  ${allAppliedTransactionPayloadPropertiesFragment}
`;

export const tombstoneFragment = gql`
  fragment TombstoneFragment on Tombstone {
    id
    version
    deleted
    updatedAt
    createdAt
  }
`;
