import * as React from 'react';

export function useUnmounted() {
  const unmountedRef = React.useRef(false);
  React.useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return unmountedRef;
}
