import { isArray, isEqual } from 'lodash';
import Prism from 'prismjs';
// tried to use the babel plugin for this but no luck
import 'prismjs/components/prism-markup-templating.js';
import 'prismjs/components/prism-bash.js';
import 'prismjs/components/prism-c.js';
import 'prismjs/components/prism-cpp.js';
import 'prismjs/components/prism-csharp.js';
import 'prismjs/components/prism-css.js';
import 'prismjs/components/prism-d.js';
import 'prismjs/components/prism-dart.js';
import 'prismjs/components/prism-diff.js';
import 'prismjs/components/prism-go.js';
import 'prismjs/components/prism-gherkin.js';
import 'prismjs/components/prism-graphql.js';
import 'prismjs/components/prism-java.js';
import 'prismjs/components/prism-javascript.js';
import 'prismjs/components/prism-json.js';
import 'prismjs/components/prism-kotlin.js';
import 'prismjs/components/prism-markup.js';
import 'prismjs/components/prism-perl.js';
import 'prismjs/components/prism-php.js';
import 'prismjs/components/prism-protobuf.js';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-ruby.js';
import 'prismjs/components/prism-rust.js';
import 'prismjs/components/prism-scala.js';
import 'prismjs/components/prism-sql.js';
import 'prismjs/components/prism-swift.js';
import 'prismjs/components/prism-typescript.js';
import 'prismjs/components/prism-yaml.js';
import { Element, Node, NodeEntry, Range, Text } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';

function getContent(token: any) {
  if (typeof token === 'string') {
    return token;
  }
  if (typeof token.content === 'string') {
    return token.content;
  }
  return token.content.map(getContent).join('');
}

function flattenTokens(tokens: Array<string | Prism.Token>): Array<string | Prism.Token> {
  return tokens.flatMap(t => {
    if (typeof t === 'string' || !isArray(t.content)) {
      return t;
    }
    return flattenTokens(t.content);
  });
}

export function syntaxHighlightingDecorations(editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [node, path] = entry;
  if (!Text.isText(node) || !path.length) {
    return [];
  }

  const parent = KitemakerEditor.above(editor, {
    match: n => KitemakerElement.isElement(n) && n.type === Elements.Code,
    at: path,
  });

  if (!parent) {
    return [];
  }

  const [parentNode, parentPath] = parent;
  if (!Element.isElement(parentNode) || parentNode.type !== Elements.Code || !parentNode.language) {
    return [];
  }

  const language = parentNode.language as string;
  const texts = Array.from(Node.texts(editor, { from: parentPath, to: parentPath }));
  const string = KitemakerNode.safeString(parentNode);
  const grammar = Prism.languages[language];
  if (!grammar) {
    return [];
  }

  const tokens = flattenTokens(Prism.tokenize(string, grammar));
  const decorations: any[] = [];
  let startEntry = texts.shift();
  let endEntry = startEntry;
  let startOffset = 0;
  let endOffset = 0;
  let start = 0;

  for (const token of tokens) {
    startEntry = endEntry;
    startOffset = endOffset;

    if (!startEntry) {
      break;
    }

    const [startText, startPath] = startEntry;

    const content = getContent(token);
    const length = content.length;
    const end = start + length;

    let available = startText.text.length - startOffset;
    let remaining = length;

    endOffset = startOffset + remaining;

    while (available < remaining && texts.length > 0) {
      endEntry = texts.shift();
      if (!endEntry) {
        break;
      }
      const [endText2] = endEntry;
      remaining = length - available;
      available = endText2.text.length;
      endOffset = remaining;
    }

    if (!endEntry) {
      break;
    }
    const [, endPath] = endEntry;
    const zeroLength = isEqual(startPath, endPath) && startOffset === endOffset;

    if (typeof token !== 'string' && !zeroLength) {
      const dec = {
        anchor: {
          offset: startOffset,
          path: startPath,
        },
        focus: {
          offset: endOffset,
          path: endPath,
        },
        syntaxType: token.type,
      };
      decorations.push(dec);
    }
    start = end;
  }

  return decorations;
}

export function highlightElement(element: HTMLElement) {
  Prism.highlightElement(element);
}
