import * as React from 'react';
import { checkIfNewVersionAvailable, VersionCheckResult } from '../api/version';
import { ForceVersionScreen } from '../screens/errorScreens/forceVersionScreen';
import { isMac } from '../utils/config';
import { toast } from './toast';

export default function VersionCheck() {
  const [forceVersion, setForceVersion] = React.useState(false);

  React.useEffect(() => {
    const interval = setInterval(async () => {
      const newVersion = await checkIfNewVersionAvailable();
      switch (newVersion) {
        case VersionCheckResult.NewVersionAvailable:
          toast.warn(<div>A new version of Kitemaker is available</div>, {
            id: 'reload',
            action: {
              label: 'Reload',
              hotkey: isMac ? 'meta+r' : undefined,
              onClick: () => {
                window.location.reload();
              },
            },
            hideClose: true,
            duration: Number.POSITIVE_INFINITY,
          });
          break;
        case VersionCheckResult.ForceNewVersion:
          // Theoretically, if we programmatically reload the page, there's no reason to show a screen
          // telling users to reload, but we've seen that the programmatic reload doesn't always work.
          setForceVersion(true);
          window.location.reload();
          break;
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (forceVersion) {
    return <ForceVersionScreen />;
  }

  return <></>;
}
