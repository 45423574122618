import cn from 'classnames';
import { Dictionary, groupBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Descendant } from 'slate';
import { issueTerm } from '../../../shared/utils/terms';
import {
  Activity,
  CodeReviewRequest,
  CodeReviewRequestState,
  MemberRole,
  StatusChangedActivityDetails,
  TodoStatus,
} from '../../../sync/__generated/models';
import ExternalLink from '../../components/externalLink';
import { ButtonStyle, IconButton } from '../../components/new/button';
import { Count } from '../../components/new/count';
import { EntityCard, EntityCardMetadataContainer } from '../../components/new/entityCard';
import { extractFirstParagraph } from '../../components/new/entityMetadata';
import { Icon, IconSize } from '../../components/new/icon';
import {
  KeyNavigationProvider,
  useKeyNavigationColumn,
  useKeyNavigationElement,
} from '../../components/new/keyNavigation';
import { DropdownMenuSeparator } from '../../components/new/menu/dropdownMenu';
import AvatarGroup from '../../components/new/metadata/avatarGroup';
import { CodeReviewRequestDetail } from '../../components/new/metadata/codeReviewRequest';
import Pill from '../../components/new/metadata/pill';
import GenericPlaceholder from '../../components/new/placeholder';
import { StatusIcon } from '../../components/new/statusIcon';
import {
  DisableTooltips,
  MetadataTooltip,
  Tooltip,
  TooltipHeader,
} from '../../components/new/tooltip';
import { useOrganization } from '../../contexts/organizationContext';
import { useSpace } from '../../contexts/spaceContext';
import useSaveScroll from '../../hooks/useSaveScroll';
import { StaticSlateDocument } from '../../slate/staticSlate';
import { activitiesSelector, activitySelector } from '../../syncEngine/selectors/activities';
import { commentsSelector } from '../../syncEngine/selectors/comments';
import {
  entityKeySelector,
  entityPathSelector,
  entityTitleSelector,
} from '../../syncEngine/selectors/entities';
import { statusForIssueSelector, statusSelector } from '../../syncEngine/selectors/issues';
import { todosSelector } from '../../syncEngine/selectors/todos';
import { codeReviewRequestsSelector } from '../../syncEngine/selectors/updates';
import { usersAndMemberSelector } from '../../syncEngine/selectors/users';
import { EntityCycleSummary, cycleSummarySelector } from './data';
import styles from './summaryView.module.scss';

function Users({ userIds }: { userIds: string[] }) {
  const organization = useOrganization();

  const userMembers = useRecoilValue(
    usersAndMemberSelector({ organizationId: organization.id, userIds })
  );
  if (!userMembers.length) {
    return null;
  }

  return (
    <AvatarGroup
      max={5}
      title={'Active members'}
      avatarData={userMembers.map(user => ({
        name: user.name || user.username,
        img: user.avatar,
        id: user.id,
        deactivated: user.member?.deactivated,
        invited: user.member?.invited,
        guest: user.member?.role === MemberRole.Guest,
      }))}
    />
  );
}

function TodoTooltipContent({ todoData }: { todoData: { id: string; status?: TodoStatus }[] }) {
  const statusByTodoId = todoData.reduce((acc, { id, status }) => {
    acc[id] = status;
    return acc;
  }, {} as { [todoId: string]: TodoStatus | undefined });
  const todos = useRecoilValue(todosSelector(Object.keys(statusByTodoId)));

  const items = todos.map(todo => {
    const todoElement = {
      type: 'smartTodo',
      todoId: todo.id,
      children: extractFirstParagraph(todo.todoContents),
    } as Descendant;
    const status = statusByTodoId[todo.id];

    return (
      <div key={todo.id} className={cn({ [styles.grayedTodo]: !status })}>
        <StaticSlateDocument value={[todoElement]} />
      </div>
    );
  });

  return (
    <DisableTooltips>
      <TooltipHeader title="Todo activity during cycle" />
      <div className={styles.todos}>{items}</div>
    </DisableTooltips>
  );
}

function TodoPill({
  todoData,
  highlight,
}: {
  todoData: { id: string; status?: TodoStatus }[];
  highlight?: boolean;
}) {
  if (todoData.length === 0) {
    return null;
  }

  let completed = 0;
  let inProgress = 0;
  let notStarted = 0;

  for (const { status } of todoData) {
    switch (status) {
      case TodoStatus.Done:
        completed++;
        break;
      case TodoStatus.InProgress:
        inProgress++;
        break;
      case TodoStatus.NotStarted:
        notStarted++;
        break;
    }
  }

  return (
    <MetadataTooltip
      content={<TodoTooltipContent todoData={todoData} />}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Pill className={cn({ [styles.highlightTodo]: highlight })}>
        {completed > 0 && (
          <>
            <Icon icon="todo_checkmark" size={IconSize.Size16} />
            {completed}
          </>
        )}
        {inProgress > 0 && (
          <>
            <Icon icon="todo_half" size={IconSize.Size16} />
            {inProgress}
          </>
        )}
        {notStarted > 0 && (
          <>
            <Icon icon="todo_blank" size={IconSize.Size16} />
            {notStarted}
          </>
        )}
      </Pill>
    </MetadataTooltip>
  );
}

function CodeReviewRequestsTooltipContent({
  codeReviews,
  commitData,
}: {
  codeReviews: CodeReviewRequest[];
  commitData: { [branch: string]: number };
}) {
  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {codeReviews.map(cr => {
        return <CodeReviewRequestDetail key={cr.id} codeReview={cr} noTooltip />;
      })}
      {Object.keys(commitData).length > 0 && codeReviews.length > 0 && <DropdownMenuSeparator />}

      {Object.entries(commitData).map(([branch, count]) => {
        return (
          <div className={styles.codeReviewRow} key={branch}>
            <span className="semiBold">{count}</span> commit{count > 1 ? 's' : ''} in{' '}
            <span className="grayed semiBold">{branch}</span>
          </div>
        );
      })}
    </div>
  );
}

function CodeReviewRequests({
  codeReviewRequestIds,
  commitData,
}: {
  codeReviewRequestIds: string[];
  commitData: { [branch: string]: number };
}) {
  const codeReviews = useRecoilValue(codeReviewRequestsSelector(codeReviewRequestIds));
  const commits = Object.values(commitData).reduce((acc, val) => acc + val, 0);

  if (codeReviews.length === 0 && commits === 0) {
    return null;
  }

  const hasOpen = codeReviews.find(review => review.state === CodeReviewRequestState.Open);
  const hasMerged = codeReviews.find(review => review.state === CodeReviewRequestState.Merged);

  let state = CodeReviewRequestState.Closed;
  if (hasMerged) {
    state = CodeReviewRequestState.Merged;
  }
  if (hasOpen) {
    state = CodeReviewRequestState.Open;
  }

  let icon = '';

  switch (state) {
    case CodeReviewRequestState.Open:
      icon = 'git_pull_request_open';
      break;
    case CodeReviewRequestState.Merged:
      icon = 'git_merge';
      break;
    case CodeReviewRequestState.Closed:
      icon = 'git_pull_request_closed';
      break;
  }

  const content = (
    <Pill>
      {codeReviews.length > 0 && (
        <>
          <Icon icon={icon} size={IconSize.Size16} />
          {codeReviews.length}
        </>
      )}
      {commits > 0 && (
        <>
          <Icon icon="git_commit" size={IconSize.Size16} />
          {commits}
        </>
      )}
    </Pill>
  );

  return (
    <MetadataTooltip
      side="bottom"
      align="start"
      content={
        <CodeReviewRequestsTooltipContent codeReviews={codeReviews} commitData={commitData} />
      }
    >
      {content}
    </MetadataTooltip>
  );
}

function MentionCount({ count, name, icon }: { count: number; name: string; icon: string }) {
  if (count === 0) {
    return null;
  }
  return (
    <Tooltip
      content={
        <div>
          <span className="semiBold">{count}</span> mention
          {count > 1 ? 's' : ''} in {name} during this cycle
        </div>
      }
    >
      <Pill>
        <Icon icon={icon} size={IconSize.Size16} />
        {count}
      </Pill>
    </Tooltip>
  );
}

function StatusChangeSummary({ activity }: { activity: Activity }) {
  const details = activity.details as StatusChangedActivityDetails;
  const originalStatus = useRecoilValue(statusSelector(details.originalStatusId ?? ''));
  const newStatus = useRecoilValue(statusSelector(details.statusId ?? ''));
  const space = useSpace();

  if (!originalStatus || !newStatus) {
    return null;
  }
  return (
    <div className={styles.statusRow}>
      <StatusIcon
        isDefault={
          details.originalStatusId === space.defaultDoneStatusId ||
          details.originalStatusId === space.defaultNewStatusId
        }
        type={originalStatus.statusType}
        size={IconSize.Size16}
      />
      <span className={styles.statusName}>{originalStatus.name}</span>
      <span className="grayedLight">{' -> '}</span>
      <StatusIcon
        isDefault={
          details.statusId === space.defaultDoneStatusId ||
          details.statusId === space.defaultNewStatusId
        }
        type={newStatus.statusType}
        size={IconSize.Size16}
      />
      <span className={styles.statusName}>{newStatus.name}</span>
    </div>
  );
}

function StatusTooltipContent({ activityIds }: { activityIds: string[] }) {
  const activities = useRecoilValue(activitiesSelector(activityIds));

  if (!activities.length) {
    return <>This work item has not moved during the cycle</>;
  }

  const activitySummaries = activities.map(activity => {
    return <StatusChangeSummary key={activity.id} activity={activity} />;
  });

  return (
    <div>
      <TooltipHeader title="Status changes" />
      {activitySummaries}
    </div>
  );
}

function StatusPill({
  entityId,
  statusChangeIds,
}: {
  entityId: string;
  statusChangeIds: string[];
}) {
  const space = useSpace();
  const entityStatus = useRecoilValue(statusForIssueSelector(entityId));
  const firstStatusChange = useRecoilValue(activitySelector(statusChangeIds[0]));
  const lastStatusChange = useRecoilValue(
    activitySelector(statusChangeIds[statusChangeIds.length - 1])
  );

  const firstStatus = useRecoilValue(
    statusSelector(
      (firstStatusChange?.details as StatusChangedActivityDetails)?.originalStatusId ?? ''
    )
  );
  const lastStatus = useRecoilValue(
    statusSelector((lastStatusChange?.details as StatusChangedActivityDetails)?.statusId ?? '')
  );

  if (!entityStatus) {
    return null;
  }

  if (statusChangeIds.length === 0) {
    return (
      <Tooltip
        disableHoverableContent
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
        content={<StatusTooltipContent activityIds={[]} />}
      >
        <Pill className={styles.statusSummary}>
          <StatusIcon
            isDefault={
              space.defaultNewStatusId === entityStatus.id ||
              space.defaultDoneStatusId === entityStatus.id
            }
            type={entityStatus.statusType}
            size={IconSize.Size16}
          />
          <span className={styles.statusName}>{entityStatus.name}</span>
        </Pill>
      </Tooltip>
    );
  }

  if (!firstStatus || !lastStatus) {
    return null;
  }

  return (
    <Tooltip
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      content={<StatusTooltipContent activityIds={statusChangeIds} />}
    >
      <Pill className={styles.statusSummary}>
        <StatusIcon
          isDefault={
            space.defaultNewStatusId === firstStatus.id ||
            space.defaultDoneStatusId === firstStatus.id
          }
          type={firstStatus.statusType}
          size={IconSize.Size16}
        />
        <span className={styles.statusName}>{firstStatus.name}</span>
        {' -> '}
        <StatusIcon
          isDefault={
            space.defaultNewStatusId === lastStatus.id ||
            space.defaultDoneStatusId === lastStatus.id
          }
          type={lastStatus.statusType}
          size={IconSize.Size16}
        />
        <span className={styles.statusName}>{lastStatus.name}</span>
      </Pill>
    </Tooltip>
  );
}

function Comments({ commentIds }: { commentIds: string[] }) {
  const comments = useRecoilValue(commentsSelector(commentIds));

  if (!comments.length) {
    return null;
  }

  const commentsByThread = groupBy(comments, 'threadId');
  const tooltipContent = (
    <div>
      <span className="semiBold">{comments.length}</span> comment{comments.length > 1 ? 's' : ''} in
      {'  '}
      <span className="semiBold">{Object.keys(commentsByThread).length} </span>threads
    </div>
  );

  return (
    <Tooltip content={tooltipContent}>
      <Pill>
        <Icon icon="chat" size={IconSize.Size16} />
        {commentIds.length}
      </Pill>
    </Tooltip>
  );
}

function Placeholder({ type }: { type: 'planned' | 'unplanned' }) {
  const title = type === 'planned' ? 'No planned work yet' : 'No unplanned work yet';
  const subtitle =
    type === 'planned'
      ? `You'll see a summary of activity on all planned work items here`
      : 'You have no unplanned work in this cycle.  ';

  return (
    <div className={styles.placeholder}>
      <Icon icon="empty" size={IconSize.Size40} className="mb8" />
      <span className="headingS">{title}</span>
      <span className="grayed bodyM textCenter">{subtitle}</span>
    </div>
  );
}

export function SummaryCard({
  entityId,
  summary,
}: {
  entityId: string;
  summary: EntityCycleSummary;
}) {
  const title = useRecoilValue(entityTitleSelector(entityId));
  const key = useRecoilValue(entityKeySelector(entityId));
  const pathname = useRecoilValue(entityPathSelector(entityId));
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationElement(entityId, ref);

  if (!title) {
    return null;
  }

  const link = {
    pathname: pathname ?? '',
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: entityId,
    },
  };

  return (
    <Link to={link} className="fullWidth">
      <EntityCard className={styles.card} ref={ref}>
        <div className="row gap8">
          <div className="finePrint semiBold">{key}</div>
          <span
            className={cn('grow ellipsis headingS', {
              grayedLight: summary.ghost,
            })}
          >
            {title}
          </span>
        </div>
        <EntityCardMetadataContainer members={<Users userIds={summary.memberIds} />}>
          <StatusPill entityId={entityId} statusChangeIds={summary.statusChangeIds} />
          <TodoPill todoData={summary.todos} highlight={summary.ghost} />
          <CodeReviewRequests
            codeReviewRequestIds={summary.codeReviewRequestIds}
            commitData={summary.commitData}
          />
          <Comments commentIds={summary.commentIds} />
          <MentionCount count={summary.slackMentionIds.length} name="Slack" icon="slack" />
          <MentionCount count={summary.discordMentionIds.length} name="Discord" icon="discord" />
          <MentionCount count={summary.figmaMentionIds.length} name="Figma" icon="figma" />
        </EntityCardMetadataContainer>
      </EntityCard>
    </Link>
  );
}

function Column({
  id,
  cycleId,
  title,
  tooltip,
  data,
}: {
  id: string;
  cycleId: string;
  title: string;
  tooltip: string;
  data: Dictionary<EntityCycleSummary>;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  useKeyNavigationColumn(id, Object.keys(data));
  useSaveScroll(`${cycleId}-${id}`, ref);

  return (
    <div className={styles.col}>
      <div className={styles.colHeader}>
        <span className="headingS semiBold">{title}</span>
        <Count count={Object.keys(data).length} />
        <Tooltip content={tooltip}>
          <IconButton icon="info" buttonStyle={ButtonStyle.BareSubtleNoHover} />
        </Tooltip>
      </div>
      <div className={styles.summaryContainer} ref={ref}>
        {Object.entries(data).map(([entityId, summary]) => (
          <SummaryCard entityId={entityId} key={entityId} summary={summary} />
        ))}
        {Object.keys(data).length === 0 && <Placeholder type={id as 'planned' | 'unplanned'} />}
      </div>
    </div>
  );
}

export function SummaryView({ cycleId }: { cycleId: string }) {
  const { planned, unplanned } = useRecoilValue(cycleSummarySelector(cycleId));

  if (!planned || !unplanned) {
    return null;
  }

  const isEmpty = Object.keys(planned).length === 0 && Object.keys(unplanned).length === 0;

  return (
    <KeyNavigationProvider columnIds={['planned', 'unplanned']}>
      <div className={styles.container}>
        <div className={cn(styles.main)}>
          {isEmpty && (
            <GenericPlaceholder title="No activity yet" icon="activity">
              <span className="grayed textCenter">
                <p>
                  Get a glimpse of what is happening during the cycle.
                  <br />
                  Learn more in the{' '}
                  <ExternalLink
                    href="https://guide.kitemaker.co/overview/cycles"
                    className="link hoverOnly headingS"
                  >
                    Kitemaker Guide.
                  </ExternalLink>
                </p>
              </span>
            </GenericPlaceholder>
          )}

          {!isEmpty && (
            <>
              <Column
                cycleId={cycleId}
                id="planned"
                tooltip={`Shows ${issueTerm}s added to the cycle or that have to-dos added to the cycle.`}
                title={`Planned ${issueTerm}s`}
                data={planned}
              />
              <Column
                cycleId={cycleId}
                id="unplanned"
                title={`Unplanned ${issueTerm}s`}
                tooltip={`Any other ${issueTerm}s that had activity during the cycle.`}
                data={unplanned}
              />
            </>
          )}
        </div>
      </div>
    </KeyNavigationProvider>
  );
}
