import Fuse, { FuseOptionKey, FuseResult, IFuseOptions } from 'fuse.js';
import { first, isString, sortBy } from 'lodash';

const autocompleteOptions: IFuseOptions<any> = {
  threshold: 0.3,
  includeScore: true,
  findAllMatches: true,
  useExtendedSearch: true,
};

const autocompleteIgnoreLocationOptions: IFuseOptions<any> = {
  threshold: 0.3,
  includeScore: true,
  findAllMatches: true,
  useExtendedSearch: true,
  ignoreLocation: true,
};

const fullTextOptions: IFuseOptions<any> = {
  shouldSort: true,
  threshold: 0.15,
  useExtendedSearch: true,
  minMatchCharLength: 1,
  includeScore: true,
  findAllMatches: true,
  ignoreLocation: true,
};

export enum FuzzySearcherConfiguration {
  Autocomplete,
  AutocompleteIgnoreLocation,
  FullText,
}

const configToOptions = {
  [FuzzySearcherConfiguration.Autocomplete]: autocompleteOptions,
  [FuzzySearcherConfiguration.AutocompleteIgnoreLocation]: autocompleteIgnoreLocationOptions,
  [FuzzySearcherConfiguration.FullText]: fullTextOptions,
};

export class FuzzySearcher<T> {
  private fuse: Fuse<T>;
  private propertiesToSearch: FuseOptionKey<any>[];
  private fuzziness: FuzzySearcherConfiguration;

  constructor(
    fuzziness: FuzzySearcherConfiguration,
    propertiesToSearch: FuseOptionKey<any>[],
    options?: T[]
  ) {
    this.propertiesToSearch = propertiesToSearch;
    this.fuzziness = fuzziness;

    const fuseOptions = { ...configToOptions[fuzziness], keys: propertiesToSearch };
    this.fuse = new Fuse(options ?? [], fuseOptions);
  }

  setOptions(options: T[]) {
    this.fuse.setCollection(options);
  }

  reindexOptions(options: T[], equals: (a: T, b: T) => boolean) {
    for (const option of options) {
      this.fuse.remove(o => equals(o, option));
      this.fuse.add(option);
    }
  }

  search(searchTerm: string): FuseResult<T>[] {
    const results = this.fuse.search(searchTerm);
    if (
      this.fuzziness != FuzzySearcherConfiguration.Autocomplete ||
      !this.propertiesToSearch.length
    ) {
      return results;
    }

    const firstProperty = first(this.propertiesToSearch);
    if (!isString(firstProperty)) {
      return results;
    }

    // when autocompleting, break ties by choosing:
    // a) things that start with the search term and
    // b) the shorter options
    return sortBy(results, [
      'score',
      result => {
        const propValue = (result.item as any)[firstProperty];
        if (propValue && isString(propValue)) {
          return propValue.startsWith(searchTerm) ? 0 : 1;
        }
        return 1;
      },
      result => {
        const propValue = (result.item as any)[firstProperty];
        if (propValue && isString(propValue)) {
          return propValue.length;
        }
        return Number.MAX_SAFE_INTEGER;
      },
    ]);
  }
}
