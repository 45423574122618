import { Snippet } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import {
  SyncEngineCreate,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';

export function useCreateSnippet() {
  const modelManager = useModelManager();
  return (spaceId: string, name: string, contents: string) => {
    const snippet: SyncEngineCreate<Snippet> = {
      __typename: 'Snippet',
      spaceId,
      name,
      contents,
    };
    const result = modelManager.create<Snippet>(snippet);
    trackerEvent('Snippet Created', {
      name,
    });
    return result;
  };
}

export function useUpdateSnippets() {
  const modelManager = useModelManager();
  return (snippetIds: string[], update: SyncEngineUpdateWithoutDelete<Snippet>) => {
    modelManager.transaction((tx, { get }) => {
      for (const snippetId of snippetIds) {
        const snippet = get<Snippet>(snippetId);
        if (!snippet || snippet.deleted) {
          continue;
        }
        tx.update<Snippet>(snippetId, update);
        trackerEvent('Snippet Edited', {
          name: snippet.name,
        });
      }
    });
  };
}

export function useDeleteSnippets() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (snippetIds: string[]) => {
    const confirmed = await confirm(
      `Delete snippet${snippetIds.length > 1 ? 's' : ''}`,
      `Are you sure you want to delete ${
        snippetIds.length > 1 ? 'these snippets' : 'this snippet'
      }? There is no way to undo this operation`,
      { label: 'Delete', destructive: true }
    );

    if (!confirmed) {
      return [];
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get }) => {
      for (const snippetId of snippetIds) {
        const snippet = get<Snippet>(snippetId);
        if (!snippet || snippet.deleted) {
          continue;
        }
        tx.update<Snippet>(snippetId, {
          deleted: true,
        });

        deleted.push(snippetId);
        trackerEvent('Snippet Deleted', {
          id: snippet.id,
        });
      }
    });

    return deleted;
  };
}
