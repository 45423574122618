import { orderBy, partition } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  FeedbackTag as FeedbackTagModel,
  IntegrationType,
} from '../../../../sync/__generated/models';
import { writeToClipboard } from '../../../components/clipboardText';
import LinkButton from '../../../components/linkButton';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { ColorPicker } from '../../../components/new/colorPicker';
import { Icon } from '../../../components/new/icon';
import Pill, { PillStyle } from '../../../components/new/metadata/pill';
import { MetadataSize } from '../../../components/new/metadata/size';
import {
  Setting,
  SettingsListItem,
  SettingsPage,
  SettingsSection,
  SettingsSectionDivider,
} from '../../../components/new/settings';
import { TextInput } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import { useOrganization } from '../../../contexts/organizationContext';
import { useCreateTag, useDeleteTags, useUpdateTags } from '../../../syncEngine/actions/tags';
import {
  integrationsForOrganizationSelector,
  organizationPath,
} from '../../../syncEngine/selectors/organizations';
import { tagsForOrganizationSelector } from '../../../syncEngine/selectors/tags';
import { trackerPageLoad } from '../../../tracker';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import { Tag } from '../../feedbackScreen/feedbackTags';
import styles from './settingsScreen.module.scss';

function TagListItem({
  tag,
  editing,
  onEdit,
  isFirst,
  isLast,
}: {
  tag: FeedbackTagModel;
  editing: boolean;
  onEdit: (tag: FeedbackTagModel, action: 'edit' | 'save' | 'cancel') => void;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const deleteTags = useDeleteTags();
  const updateTags = useUpdateTags();

  const [update, setUpdate] = React.useState<{ name: string; color: string }>({
    name: tag.name,
    color: tag.color,
  });

  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);

  if (editing) {
    return (
      <SettingsListItem
        isFirst={isFirst}
        isLast={isLast}
        onClickOutside={() => {
          if (!colorPickerOpen) {
            onEdit(tag, 'cancel');
          }
        }}
      >
        <ColorPicker
          initialColor={update.color}
          onColorPicked={c => setUpdate({ ...update, color: c ?? 'gray' })}
          onOpenChanged={setColorPickerOpen}
          renderPreview={(c: string, forceDarkMode: boolean) => (
            <Tag
              forceDarkMode={forceDarkMode}
              color={c}
              name={'Preview Tag'}
              size={MetadataSize.Medium}
            />
          )}
        />
        <TextInput
          className="grow ml8 mr8"
          value={update.name}
          onChange={e => setUpdate({ ...update, name: e.currentTarget.value })}
          autoFocus={true}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
              e.stopPropagation();
              updateTags([tag.id], update);
              onEdit(tag, 'save');
              return;
            }
            if (e.key.toLowerCase() === 'escape') {
              onEdit(tag, 'cancel');
            }
          }}
        />
        <Button
          className="mr8"
          onClick={() => {
            onEdit(tag, 'cancel');
          }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle={ButtonStyle.Primary}
          onClick={() => {
            updateTags([tag.id], update);
            onEdit(tag, 'save');
          }}
        >
          Save
        </Button>
      </SettingsListItem>
    );
  }

  return (
    <SettingsListItem
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onEdit(tag, 'edit');
      }}
      isFirst={isFirst}
      isLast={isLast}
      meta={
        <>
          <Tooltip content="Edit">
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              icon="edit"
              onClick={() => onEdit(tag, 'edit')}
            />
          </Tooltip>
          <Tooltip content="Delete">
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              className={styles.deleteIcon}
              icon="delete"
              onClick={() => deleteTags([tag.id])}
            />
          </Tooltip>
        </>
      }
    >
      <Pill
        className={styles.label}
        color={tag.color}
        pillStyle={PillStyle.Primary}
        size={MetadataSize.Medium}
        textOnly
      >
        {tag.name}
      </Pill>
    </SettingsListItem>
  );
}

function newlyCreatedFirst(
  tags: FeedbackTagModel[],
  newlyCreatedId: string | null
): FeedbackTagModel[] {
  const [newlyCreated, rest] = partition(orderBy(tags, 'name'), l => l.id === newlyCreatedId);
  return [...newlyCreated, ...rest];
}

export function FeedbackSettings() {
  const organization = useOrganization();
  const history = useHistory();
  const [searchString, setSearchString] = React.useState('');

  const allTags = useRecoilValue(tagsForOrganizationSelector(organization.id));
  const [tags, setTags] = React.useState(allTags);

  const createTag = useCreateTag();
  const deleteTags = useDeleteTags();
  const [newlyCreated, setNewlyCreated] = React.useState<string | null>(null);
  const [currentlyEditing, setCurrentlyEditing] = React.useState<string | null>(null);

  const lookupKey = organization.slug.substring(0, organization.slug.indexOf('-'));
  const email = `${lookupKey}@feedback.kitemaker.io`;

  const search = React.useMemo(() => {
    return new FuzzySearcher(FuzzySearcherConfiguration.Autocomplete, ['name'], allTags);
  }, [allTags]);

  const installedIntegrations = useRecoilValue(
    integrationsForOrganizationSelector(organization.id)
  );

  React.useEffect(() => {
    trackerPageLoad('TagSettings');
  }, []);

  React.useEffect(() => {
    if (!searchString) {
      setTags(newlyCreatedFirst(allTags, newlyCreated));
      return;
    }

    setTags(
      newlyCreatedFirst(
        search.search(searchString).map(i => i.item),
        newlyCreated
      )
    );
  }, [search, allTags, searchString, newlyCreated]);

  return (
    <SettingsPage
      title="Feedback"
      description={
        <>
          <p>
            The feedback section is a shared inbox that allows you to link insights to work items
            and initiatives. Typically, these can be bug reports or support tickets, or they can be
            summaries of user interviews and sales meetings.
          </p>
          <p>
            It's a great way to directly connect feedback to the work, helping anyone on the team
            understand where the feedback comes from and understand the user context of their work.
          </p>
          <LinkButton
            to="https://guide.kitemaker.co/feedback-and-insights"
            className="row inlineFlex ml-4"
          >
            Read more in the Kitemaker Guide <Icon icon="external_link" className="ml4" />
          </LinkButton>
        </>
      }
    >
      <SettingsSectionDivider />
      <SettingsSection largeHeader title={'Import feedback'}>
        <Setting
          title="Create feedback from emails"
          description={`You can create feedback automatically from emails by forwarding them to ${email}`}
        >
          <Button icon="copy" onClick={() => writeToClipboard(email)}>
            Copy email
          </Button>
        </Setting>
        <Setting
          title="Create feedback from Intercom convesations"
          description="Channel feedback into Kitemaker directly from Intercom conversations."
        >
          <Button
            icon="intercom"
            onClick={() => {
              const integration = installedIntegrations.find(
                i => i.type === IntegrationType.Intercom
              );
              const link = integration
                ? organizationPath(organization, `settings/integration/${integration.id}`)
                : organizationPath(
                    organization,
                    `settings/integrations/new/${IntegrationType.Intercom.toLowerCase()}`
                  );
              history.push(link);
            }}
          >
            Intercom integration
          </Button>
        </Setting>

        <Setting
          title="Create feedback with Zapier integration"
          description="Build Zaps to create feedback in Kitemaker from range of tools."
        >
          <Button
            icon="zapier"
            onClick={() => {
              const integration = installedIntegrations.find(
                i => i.type === IntegrationType.Zapier
              );
              const link = integration
                ? organizationPath(organization, `settings/integration/${integration.id}`)
                : organizationPath(
                    organization,
                    `settings/integrations/new/${IntegrationType.Zapier.toLowerCase()}`
                  );
              history.push(link);
            }}
          >
            Zapier integration
          </Button>
        </Setting>
      </SettingsSection>
      <SettingsSectionDivider />
      <SettingsSection largeHeader title={'Manage tags'}>
        <div className="rowStretch mb16">
          <Button
            className="mr16"
            buttonStyle={ButtonStyle.Primary}
            icon="add"
            onClick={() => {
              const { id } = createTag(organization.id, '');
              setCurrentlyEditing(id);
              setNewlyCreated(id);
            }}
          >
            New tag
          </Button>
          <TextInput
            className={styles.searchInput}
            value={searchString}
            onChange={v => setSearchString(v.currentTarget.value)}
            placeholder="Filter tags"
          />
        </div>

        {tags.map((tag, index) => (
          <TagListItem
            key={tag.id}
            isFirst={index === 0}
            isLast={index === tags.length - 1}
            tag={tag}
            editing={currentlyEditing === tag.id}
            onEdit={(l, action) => {
              if (action === 'edit') {
                setCurrentlyEditing(l.id);
              } else {
                if (newlyCreated && action === 'cancel') {
                  deleteTags([newlyCreated], true);
                }
                setNewlyCreated(null);
                setCurrentlyEditing(null);
              }
            }}
          />
        ))}
      </SettingsSection>
    </SettingsPage>
  );
}
