import React from 'react';
import { useRecoilValue } from 'recoil';
import { useCurrentUser } from '../../contexts/userContext';
import { useToggleWatchersForEntities } from '../../syncEngine/actions/entities';
import {
  currentUserWatchingEntitySelector,
  entityTypeSelector,
} from '../../syncEngine/selectors/entities';
import { watchIssueKey } from '../../utils/config';
import { ButtonStyle, IconButton } from './button';
import { KeyboardShortcut } from './keyboardShortcut';
import { Tooltip } from './tooltip';

export default function Watchers({ entityId }: { entityId: string }) {
  const isWatcher = useRecoilValue(currentUserWatchingEntitySelector(entityId));
  const user = useCurrentUser();
  const toggleWatchers = useToggleWatchersForEntities();
  const entityType = useRecoilValue(entityTypeSelector(entityId));

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          {isWatcher ? `Stop watching ${entityType}` : `Watch ${entityType}`}{' '}
          <KeyboardShortcut shortcut={watchIssueKey} />
        </>
      }
    >
      <IconButton
        buttonStyle={ButtonStyle.BareSubtle}
        icon={isWatcher ? 'watch_filled' : 'watch'}
        onClick={e => {
          e.preventDefault();
          toggleWatchers([entityId], [user.id]);
        }}
      />
    </Tooltip>
  );
}
