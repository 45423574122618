import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  DueDateChangedActivityDetails,
} from '../../../../sync/__generated/models';
import { entityTypeSelector } from '../../../syncEngine/selectors/entities';
import { renderDate } from '../../../utils/datetime';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function DueDateChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const { dueDate, originalDueDate } = activity.details as DueDateChangedActivityDetails;
  const entityType = useRecoilValue(entityTypeSelector(activity.entityId));

  return (
    <Activity activity={activity} icon="due_date">
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} />{' '}
      {dueDate === null ? 'cleared' : 'set'} the due date for this {entityType}
      {dueDate && (
        <>
          {' '}
          to <span className={styles.activityHighlight}>{renderDate(dueDate)}</span>
        </>
      )}
      .
      {originalDueDate && (
        <>
          {' '}
          Used to be <span className={styles.activityHighlight}>{renderDate(originalDueDate)}</span>
          .
        </>
      )}
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const DueDateChangedActivity = React.memo(DueDateChangedActivityComponent);
