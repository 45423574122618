import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useOrganization } from '../../../contexts/organizationContext';
import { RoadmapIcon } from '../../../screens/new/newRoadmapScreen/roadmapIcon';
import {
  publicRoadmapsForOrganizationSelector,
  roadmapsForSpaceSelector,
} from '../../../syncEngine/selectors/roadmaps';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { IconSize } from '../icon';
import { ListViewItem } from '../listView';
import { Picker, PickerState } from '../picker';
import { TooltipIfTruncated } from '../tooltipIfTruncated';

function createRoadmapItem({
  contents,
  onSelected,
}: {
  contents: string | React.ReactNode;
  onSelected: (shift: boolean) => void;
}): ListViewItem {
  return {
    id: 'add',
    mouseDown: true,
    className: 'createItem',
    icon: 'add',
    onSelected,
    contents,
  };
}

export function RoadmapPicker({
  state,
  filterPlaceholder,
  filterClassName,
  additionalItems,
  multi = true,
  // PRIVATE-INITIATIVES-AND-ROADMAPS
  spaceId,
  noIcon,
  onDone,
  onRoadmapAdded,
  onRoadmapRemoved,
  onCreate,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  additionalItems?: ListViewItem[];
  multi?: boolean;
  spaceId?: string;
  noIcon?: boolean;
  onDone: () => void;
  onRoadmapAdded: (initiativeIds: string[], roadmapId: string) => void;
  onRoadmapRemoved: (initiativeIds: string[], roadmapId: string) => void;
  onCreate?: (name: string) => void;
}) {
  const organization = useOrganization();
  // PRIVATE-INITIATIVES-AND-ROADMAPS
  const publicRoadmaps = useRecoilValue(publicRoadmapsForOrganizationSelector(organization.id));
  const spaceRoadmaps = useRecoilValue(roadmapsForSpaceSelector(spaceId));
  const space = useRecoilValue(spaceSelector(spaceId));

  const isPrivate = space?.private ?? false;

  const roadmaps = isPrivate ? spaceRoadmaps : publicRoadmaps;

  const items: ListViewItem[] = roadmaps.map(roadmap => {
    const item: any = {
      ...roadmap,
      mouseDown: true,
      contents: () => (
        <>
          <RoadmapIcon color={roadmap.color} size={IconSize.Size20} />
          <TooltipIfTruncated>{roadmap.name}</TooltipIfTruncated>
        </>
      ),
    };
    if (noIcon) {
      item.icon = null;
    }
    return item;
  });

  if (onCreate) {
    items.unshift(
      createRoadmapItem({
        contents: 'Create new roadmap',
        onSelected: () => {
          onCreate?.('');
        },
      })
    );
  }

  return (
    <Picker
      filterLabel="Add roadmaps"
      filterPlaceholder={filterPlaceholder ?? 'Add roadmaps'}
      filterClassName={filterClassName}
      filter={(filterString, search) => {
        const results = search.search(filterString).map(r => r.item);
        if (!onCreate) {
          return results;
        }
        results.unshift(
          createRoadmapItem({
            contents: (
              <div className="ellipsis">Create a roadmap named &quot;{filterString}&quot;</div>
            ),
            onSelected: () => {
              onCreate?.(filterString);
            },
          })
        );
        return results;
      }}
      multi={multi}
      state={state}
      items={[...items, ...(additionalItems ?? [])]}
      propertiesToSearch={['name']}
      onAdd={onRoadmapAdded}
      onRemove={onRoadmapRemoved}
      onDone={onDone}
    />
  );
}
