import { parseInt } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { isNumeric } from 'validator';
import { issueTerm } from '../../shared/utils/terms';
import { capitalize } from '../../shared/utils/utils';
import Modal, { ModalButtonWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { TextInput } from '../components/new/textInput';
import { IssueLimitArgs, Modals, useModals } from '../contexts/modalContext';
import { useUpdateStatuses } from '../syncEngine/actions/issueStatuses';
import { statusSelector } from '../syncEngine/selectors/issues';
import styles from './issueLimitModal.module.scss';

function IssueLimitContent({ statusId }: { statusId?: string }) {
  const modals = useModals();
  const status = useRecoilValue(statusSelector(statusId));
  const updateStatuses = useUpdateStatuses();

  const [issueLimit, setIssueLimit] = React.useState(status?.issueLimit?.toString() ?? '');
  const [error, setError] = React.useState<string | null>(null);
  const [touched, setTouched] = React.useState(false);

  React.useEffect(() => {
    if (touched) {
      setError(
        isNumeric(issueLimit) && parseInt(issueLimit) > 0
          ? null
          : 'Limit must be a number greater than zero'
      );
    }
  }, [issueLimit, touched]);

  if (!status) {
    return null;
  }

  return (
    <form
      className="fullWidth"
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        updateStatuses([status.id], { issueLimit: parseInt(issueLimit, 10) });
        modals.closeModal(Modals.IssueLimit);
      }}
    >
      <div className={styles.content}>
        <p>Setting a {issueTerm} limit will give a visual indication when this limit is exceeded</p>
        <div className="rowBetween fullWidth">
          <span className="headingM">{capitalize(issueTerm)} limit</span>
          <TextInput
            className={styles.input}
            autoFocus
            value={issueLimit}
            onChange={e => {
              if (!touched) {
                setTouched(true);
              }
              setIssueLimit(e.currentTarget.value);
            }}
          />
        </div>
        <span className={styles.error}>{error}&nbsp;</span>
      </div>

      <ModalButtonWrapper>
        <Button
          type="button"
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => modals.closeModal(Modals.IssueLimit)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => {
            updateStatuses([status.id], { issueLimit: null });
            modals.closeModal(Modals.IssueLimit);
          }}
        >
          Clear
        </Button>
        <Button type="submit" buttonStyle={ButtonStyle.Primary} disabled={!!error || !touched}>
          Apply
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export default function IssueLimitModal() {
  const modals = useModals();
  const args = modals.currentArgs() ? (modals.currentArgs() as IssueLimitArgs) : null;

  return (
    <Modal modalId={Modals.IssueLimit} title={`Change ${issueTerm} limit`}>
      <IssueLimitContent statusId={args?.statusId} />
    </Modal>
  );
}
