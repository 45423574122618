import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useOrganization } from '../../contexts/organizationContext';
import { useSpace } from '../../contexts/spaceContext';
import { FetchedMarker } from '../../graphql/smartLoad';
import LoadingScreen from '../../screens/loadingScreen';
import {
  initiativePath,
  initiativesForOrganizationSelector,
  initiativesForSpaceSelector,
} from '../../syncEngine/selectors/intiatives';
import { roadmapsForSpaceSelector } from '../../syncEngine/selectors/roadmaps';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { spacePath } from '../../syncEngine/selectors/spaces';

export function OldThemeRedirect({ number }: { number?: string }) {
  const organization = useOrganization();
  const space = useSpace();
  const roadmaps = useRecoilValue(roadmapsForSpaceSelector(space.id));
  const orgInitiatives = useRecoilValue(initiativesForOrganizationSelector(organization.id));
  const spaceInitiatives = useRecoilValue(initiativesForSpaceSelector(space.id));
  const fetched = useRecoilValue(markerState(FetchedMarker.id));

  if (!number) {
    if (roadmaps.length) {
      return <Redirect to={spacePath(organization, space, `roadmap`)} />;
    }

    if (!fetched) {
      return <LoadingScreen />;
    }
    // if this space doesn't have a roadmap, there's not much we can do
    return <Redirect to={spacePath(organization, space, `initiatives`)} />;
  }

  // prefer to redirect inside the space
  const spaceInitiative = spaceInitiatives.find(
    i => i.oldTheme?.spaceId === space.id && i.oldTheme?.number === number
  );
  if (spaceInitiative) {
    return (
      <Redirect to={spacePath(organization, space, `initiatives/${spaceInitiative.number}`)} />
    );
  }

  // if we can't find it in the space, look in the org
  const orgInitiative = orgInitiatives.find(
    i => i.oldTheme?.spaceId === space.id && i.oldTheme?.number === number
  );
  if (orgInitiative) {
    return <Redirect to={initiativePath(organization, orgInitiative)} />;
  }

  if (!fetched) {
    return <LoadingScreen />;
  }

  // nothing else useful we can do
  return <Redirect to={spacePath(organization, space, `initiatives`)} />;
}
