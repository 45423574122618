import { atomFamily, useRecoilState } from 'recoil';
import { localStorageEffect } from '../syncEngine/effects';

export const temporarilySavedState = atomFamily<string | null, string>({
  key: 'TempSave',
  default: null,
  effects: key => [localStorageEffect(`__temp__${key}`)],
});

export default function useSaveTemporarily(objId: string) {
  const [tempState, setTempState] = useRecoilState(temporarilySavedState(objId));

  return {
    setTemp: (value: string) => setTempState(value),
    getTemp: () => {
      const value = tempState;
      setTempState(null);
      return value;
    },
  };
}
