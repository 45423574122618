import cn from 'classnames';
import React from 'react';
import { atom, useSetRecoilState } from 'recoil';
import {
  dragPreviewClassnameState,
  dragPreviewElementIdState,
  dragPreviewOffsetState,
  dragPreviewState,
} from '../../../slate/plugins/dragAndDrop/draggableLayer';
import { emptyImage } from '../../../slate/plugins/dragAndDrop/useDragAndDrop';
import styles from './columnHeader.module.scss';

export const columnDragLocationAtom = atom<{ x: number; y: number } | null>({
  key: 'columnDragLocation',
  default: null,
});

export const columnDragStatusIdAtom = atom<string | null>({
  key: 'columnDragStatus',
  default: null,
});

export const columnDragDestinationAtom = atom<{ id: string; after: boolean } | null>({
  key: 'columnDragDestination',
  default: null,
});

export const columnEditIdState = atom<string | null>({
  key: 'ColumnEditId',
  default: null,
});

export const COLUMN_DRAG_PREVIEW_ID = 'column_drag_preview';

export function BoardColumnHeader({
  id,
  disableEditing,
  onDragEnd,
  onDragStart,
  onClick,
  children,
}: {
  id: string;
  disableEditing?: boolean;
  onDragEnd?: () => void;
  onDragStart?: () => void;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  const [dragging, setDragging] = React.useState(false);
  const setClassname = useSetRecoilState(dragPreviewClassnameState);
  const setPreviewDestination = useSetRecoilState(dragPreviewElementIdState);
  const setColumnDragStatus = useSetRecoilState(columnDragStatusIdAtom);
  const setColumnDragLocation = useSetRecoilState(columnDragLocationAtom);
  const setNode = useSetRecoilState(dragPreviewState);
  const setOffset = useSetRecoilState(dragPreviewOffsetState);

  const ref = React.useRef<HTMLDivElement | null>(null);

  return (
    <div
      draggable={!disableEditing}
      onDragStart={e => {
        if (disableEditing) {
          return;
        }
        setTimeout(() => {
          setDragging(true);
        }, 10);
        onDragStart?.();
        setClassname(styles.dragPreview);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setDragImage(emptyImage, -1000, -1000);
        // we cannot set data properly here, it cannot be read from the column onDragOver callback because
        // the event is considered protected. But what we can do is set a custom data type and then read it
        // from e.dataTransfer.types, so we do that.
        e.dataTransfer.setData('column', '');

        setPreviewDestination(COLUMN_DRAG_PREVIEW_ID);
        setColumnDragStatus(id);
        setNode(ref.current);
        const clientRect = ref.current?.getClientRects()[0];
        setOffset({
          x: (clientRect?.left ?? e.currentTarget.offsetLeft) - e.pageX,
          y: (clientRect?.top ?? e.currentTarget.offsetTop) - e.clientY,
        });
      }}
      onDragEnd={() => {
        if (disableEditing) {
          return;
        }
        setDragging(false);
        setNode(null);
        setColumnDragStatus(null);
        setColumnDragLocation(null);
        onDragEnd?.();
      }}
      onClick={onClick}
      ref={ref}
      className={cn(styles.header, { [styles.dragging]: dragging, clickable: !disableEditing })}
    >
      {!dragging && children}
    </div>
  );
}
