import * as React from 'react';

const interactivtyDisabledContext = React.createContext(false);

export function useInteractivityDisabled() {
  return React.useContext(interactivtyDisabledContext);
}

export function InteractivityDisabled({ children }: { children: React.ReactNode }) {
  return (
    <interactivtyDisabledContext.Provider value={true}>
      {children}
    </interactivtyDisabledContext.Provider>
  );
}
