import { History } from 'history';
import { isArray, isUndefined } from 'lodash';
import { parse, stringify } from 'query-string';

export function setQueryParameter(history: History, name: string, value: string) {
  const query = parse(history.location.search);
  query[name] = encodeURIComponent(value);
  history.replace(`${history.location.pathname}?${stringify(query)}`, history.location.state);
}

export function getQueryParameter(history: History, name: string): string | null {
  const query = parse(history.location.search);
  if (query[name]) {
    const value = query[name];
    if (isArray(value)) {
      return decodeURIComponent(value[0]);
    }
    return value === null || isUndefined(value) ? null : decodeURIComponent(value);
  }
  return null;
}

export function removeQueryParameter(history: History, name: string | string[]) {
  const query = parse(history.location.search);
  const params = isArray(name) ? name : [name];
  for (const param of params) {
    delete query[param];
  }
  history.replace(`${history.location.pathname}?${stringify(query)}`, history.location.state);
}
