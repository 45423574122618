/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable no-var */
import Mousetrap from 'mousetrap';

(function(Mousetrap) {
  const _globalCallbacks: Record<string, any> = {};
  const _originalStopCallback = Mousetrap.prototype.stopCallback;

  Mousetrap.prototype.stopCallback = function(
    e: KeyboardEvent,
    element: HTMLElement,
    combo: string,
    sequence: string
  ) {
    var self = this;

    if (self.paused) {
      return true;
    }

    if (_globalCallbacks[combo] || _globalCallbacks[sequence]) {
      return false;
    }

    return _originalStopCallback.call(self, e, element, combo);
  };

  Mousetrap.prototype.bindGlobal = function(
    keys: string | string[],
    callback: any,
    action: string
  ) {
    var self = this;
    self.bind(keys, callback, action);

    if (keys instanceof Array) {
      for (var i = 0; i < keys.length; i++) {
        _globalCallbacks[keys[i]] = true;
      }
      return;
    }

    _globalCallbacks[keys] = true;
  };

  Mousetrap.prototype.unbindGlobal = function(keys: string | string[], action: string) {
    var self = this;
    self.unbind(keys, action);

    if (keys instanceof Array) {
      for (var i = 0; i < keys.length; i++) {
        _globalCallbacks[keys[i]] = false;
      }
      return;
    }

    _globalCallbacks[keys] = false;
  };

  Mousetrap.init();
})(Mousetrap);
