import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { notNull } from '../../shared/utils/convenience';
import { Organization } from '../../sync/__generated/models';
import { syncEngineState } from '../syncEngine/state';

export const organizationContext = React.createContext<string | null>(null);

export function OrganizationProvider({
  organizationId,
  children,
}: {
  organizationId: string;
  children: React.ReactNode;
}) {
  return (
    <organizationContext.Provider value={organizationId}>{children}</organizationContext.Provider>
  );
}

export function useOrganization(): Organization {
  const organizationId = React.useContext(organizationContext)!;
  const org = useRecoilValue(syncEngineState(organizationId)) as Organization | null;
  return notNull(org);
}

export function useMaybeOrganization(): Organization | null {
  const organizationId = React.useContext(organizationContext)!;
  const org = useRecoilValue(syncEngineState(organizationId ?? '')) as Organization | null;
  return org;
}
