import cn from 'classnames';
import { times, uniq } from 'lodash';
import * as React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import isEmail from 'validator/lib/isEmail';
import { MemberRole } from '../../../sync/__generated/models';
import linkButtonStyles from '../../components/linkButton.module.scss';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Hotkey } from '../../components/new/hotkey';
import { TextInputSize, TextValidationInput } from '../../components/new/textInput';
import { toast } from '../../components/toast';
import { useInviteUsers } from '../../syncEngine/actions/organizations';
import { trackerEvent, trackerPageLoad } from '../../tracker';
import styles from './onboardingScreen.module.scss';

const INITIAL_COUNT = 3;

function Input({
  index,
  email,
  disabled,
  last,
  onEmailChanged,
  onAdd,
  name,
}: {
  index: number;
  last: boolean;
  disabled?: boolean;
  email: string;
  onEmailChanged: (email: string) => void;
  onAdd: () => void;
  name: string;
}) {
  return (
    <TextValidationInput
      name={name}
      autoFocus={index === 0 || index >= INITIAL_COUNT}
      validate={value => (value && !isEmail(value) ? 'Invalid email' : null)}
      disabled={disabled}
      placeholder="teammate@email.com"
      className={cn('fullWidth', {
        mt16: index > 0,
      })}
      inputSize={TextInputSize.Large}
      value={email}
      onChange={e => onEmailChanged(e.currentTarget.value)}
      onKeyDown={e => {
        if (!last || e.key !== 'Tab') {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        onAdd();
      }}
      onFocus={e => {
        scrollIntoView(e.currentTarget, {
          block: 'nearest',
          scrollMode: 'if-needed',
          behavior: 'auto',
        });
      }}
    />
  );
}

export function InviteStep({ onNext }: { onNext: () => void }) {
  const inviteUsers = useInviteUsers();
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [invited, setInvited] = React.useState(false);
  const [emails, setEmails] = React.useState(times(INITIAL_COUNT, () => ''));
  const validEmails = React.useMemo(() => {
    return uniq(emails.filter(e => isEmail(e)));
  }, [emails]);

  React.useEffect(() => {
    trackerPageLoad('TutorialInvite');
  }, []);

  async function invite() {
    try {
      trackerEvent('Onboarding Users Invited', { count: validEmails.length });
      await inviteUsers(validEmails, MemberRole.Admin);
      setInvited(true);
      setTimeout(() => onNext(), 1000);
    } catch (e) {
      toast.error(e.message);
      setRequestInProgress(false);
    }
  }

  const inputs = emails.map((email, index) => (
    <Input
      name={`inpu${index}`}
      disabled={requestInProgress}
      key={index}
      index={index}
      last={index === emails.length - 1}
      email={email}
      onEmailChanged={e => {
        setEmails(previous => {
          const updated = [...previous];
          updated[index] = e;
          return updated;
        });
      }}
      onAdd={() => {
        setEmails(previous => [...previous, '']);
      }}
    />
  ));

  return (
    <div className={styles.standardStep}>
      <div className="headingXL oneLine">Invite your team</div>

      <p className="bodyXL slightlyGrayed mt24 mb24 textCenter">
        Kitemaker is built for team collaboration, invite your teammates to try it out with.
      </p>

      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          setRequestInProgress(true);
          invite();
        }}
        className={styles.stepContent}
      >
        <label htmlFor="email1" className="mb12">
          Invite by email
        </label>
        {inputs}
        <Button
          type="button"
          buttonStyle={ButtonStyle.BareSubtle}
          icon="add"
          className="mt12"
          onClick={() => {
            setEmails(previous => [...previous, '']);
          }}
        >
          Add another
        </Button>
        <div className="colAlignCenter">
          <Button
            buttonStyle={ButtonStyle.PrimaryBlack}
            size={ButtonSize.Large}
            type="submit"
            className={cn(styles.inviteButton, {
              [styles.sent]: invited,
            })}
            disabled={!validEmails.length || requestInProgress}
            autoFocus
            icon={invited ? 'success' : undefined}
          >
            {invited ? `Invitation${validEmails.length > 1 ? 's' : ''} sent` : 'Send invites'}
          </Button>

          <a
            className={cn('mt24', 'clickable', 'grayed', linkButtonStyles.medium)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onNext();
            }}
          >
            Skip for now
          </a>
        </div>

        <Hotkey
          hotkey="enter"
          global
          handler={e => {
            if (validEmails.length === 0) {
              e?.preventDefault();
              e?.stopPropagation();
              onNext();
            }
          }}
        />
      </form>
    </div>
  );
}
