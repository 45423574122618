import { isEqual, omit } from 'lodash';
import { KeyboardEvent } from 'react';
import { Editor, Element, Node, Path, Point, Range, Text, Transforms } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { Elements, ListElement } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import { MAX_LIST_INDENT, MAX_SMART_INDENT } from '../../utils/config';
import { metaKeyDown } from '../../utils/keyEvents';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { DropHighlightPosition } from '../plugins/dragAndDrop/dropHighlight';
import { HistoryEditor } from '../plugins/history';
import { EditorType } from '../types';

function todoHotkeys(editor: EditorType, e: KeyboardEvent): boolean {
  if (!(e.key === 'Enter' && metaKeyDown(e)) || editor.todosDisabled) {
    return false;
  }

  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;

  if (!KitemakerElement.isElement(node) || node.type !== Elements.Todo) {
    return false;
  }

  Transforms.setNodes(editor, { checked: !node.checked }, { at: path });

  return true;
}

function splitHeadlines(editor: EditorType, e: KeyboardEvent): boolean {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isHeadline(node)) {
    return false;
  }

  e.preventDefault();
  HistoryEditor.asBatch(editor, () => {
    if (KitemakerEditor.isAtStartOfBlock(editor, path) && KitemakerNode.safeString(node).length) {
      KitemakerTransforms.setBlockElement(editor, Elements.Paragraph);
      editor.insertBreak();
      KitemakerTransforms.setBlockElement(editor, node.type as Elements);
    } else if (KitemakerEditor.isAtEndOfBlock(editor, path)) {
      editor.insertBreak();
      KitemakerTransforms.setBlockElement(editor, Elements.Paragraph);
    } else {
      KitemakerTransforms.splitNodes(editor);
      KitemakerTransforms.setBlockElement(editor, Elements.Paragraph);
    }
  });

  return true;
}

function splitNonVoidInlines(editor: EditorType, e: KeyboardEvent) {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const inline = Editor.above(editor, {
    match: n =>
      KitemakerElement.isElement(n) && Editor.isInline(editor, n) && !Editor.isVoid(editor, n),
  });

  if (!inline) {
    return false;
  }

  const [, path] = inline;

  if (!Point.equals(Editor.end(editor, path), selection.focus)) {
    return false;
  }

  e.preventDefault();

  KitemakerTransforms.splitNodes(editor);
  KitemakerTransforms.move(editor, { distance: 1, unit: 'character' });
  KitemakerTransforms.unwrapNodes(editor, {
    match: n =>
      KitemakerElement.isElement(n) && Editor.isInline(editor, n) && !Editor.isVoid(editor, n),
  });
  return true;
}

function deleteOutOfHeadlines(editor: EditorType, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isHeadline(node)) {
    return false;
  }

  if (!KitemakerEditor.isAtStartOfBlock(editor, path)) {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();
  KitemakerTransforms.setBlockElement(editor, Elements.Paragraph);
  return true;
}

function breakOutOfFormattedTextBlocks(editor: EditorType, e: KeyboardEvent): boolean {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above<Element>(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isFormattedTextBlock(node)) {
    return false;
  }

  const texts = Array.from(Node.texts(editor, { from: path, to: path }));
  const [text] = texts[0];

  const line = KitemakerNode.lineOfTextNode(editor, text, selection.focus.offset);
  if (line.length) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerEditor.withoutNormalizing(editor, () => {
      if (selection.focus.offset > 0) {
        KitemakerTransforms.delete(editor, { reverse: true });
      }
      if (selection.focus.offset < text.text.length - 1) {
        KitemakerTransforms.delete(editor, { reverse: false });
      }
      KitemakerEditor.insertNode(editor, { type: Elements.Paragraph, children: [{ text: '' }] });
    });
  });

  return true;
}

function deleteOutOfFormattedTextBlocks(editor: EditorType, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above<Element>(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isFormattedTextBlock(node)) {
    return false;
  }

  const texts = Array.from(Node.texts(editor, { from: path, to: path }));
  const [text] = texts[0];

  const line = KitemakerNode.lineOfTextNode(editor, text, selection.focus.offset);
  if (line.length) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerEditor.withoutNormalizing(editor, () => {
      if (selection.focus.offset > 0) {
        KitemakerTransforms.delete(editor, { reverse: true });
      }
      if (selection.focus.offset < text.text.length - 1) {
        KitemakerTransforms.delete(editor, { reverse: false });
      }
      if (KitemakerNode.safeString(node) === '') {
        KitemakerTransforms.setBlockElement(editor, Elements.Paragraph, { at: path });
      } else {
        KitemakerEditor.insertNode(editor, { type: Elements.Paragraph, children: [{ text: '' }] });
      }
    });
  });

  return true;
}

function arrowOutOfTextBlocks(editor: EditorType, e: KeyboardEvent): boolean {
  if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
    return false;
  }

  const up = e.key === 'ArrowUp';

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above<Element>(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;

  if (node.type === Elements.Paragraph && isEqual(node.children, [{ text: '' }])) {
    return false;
  }

  // only handle pressing up on the annoying to break out of blocks like quotes and code
  if (up && !KitemakerElement.isFormattedTextBlock(node)) {
    return false;
  }

  // make sure we're on the first or last line of this block
  const texts = Array.from(Node.texts(editor, { from: path, to: path }));
  const [text] = texts[0];
  const lines = text.text.split('\n');
  const line = KitemakerNode.lineOfTextNode(editor, text, selection.focus.offset);

  const upOnFirst = line === lines[0] && up;
  const downOnLast = line === lines[lines.length - 1] && !up;

  if (!upOnFirst && !downOnLast) {
    return false;
  }

  // make sure the block is the first or last block of the whole document
  if (up && !KitemakerNode.isFirstElement(editor, path)) {
    return false;
  }
  if (!up && !KitemakerNode.isLastElement(editor, path)) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerEditor.withoutNormalizing(editor, () => {
      if (up) {
        const start = Editor.start(editor, path);
        if (KitemakerNode.safeString(node).length) {
          KitemakerTransforms.insertNodes(
            editor,
            { type: Elements.Paragraph, children: [{ text: '' }] },
            { at: start }
          );
        } else {
          KitemakerTransforms.insertNodes(
            editor,
            { type: Elements.Paragraph, children: [{ text: '' }] },
            { at: [0] }
          );
        }
        KitemakerTransforms.move(editor, { distance: 2, unit: 'line', reverse: true });
      } else {
        const end = Editor.end(editor, path);
        KitemakerTransforms.insertNodes(
          editor,
          { type: Elements.Paragraph, children: [{ text: '' }] },
          { at: end }
        );
        // Needs to be distance: 2 because the first line will just put us to
        // the end of the current line. But since we know there's only one new line
        // down there, moving down by 2 is all good.
        KitemakerTransforms.move(editor, { distance: 2, unit: 'line' });
      }
    });
  });

  return true;
}

function listTabbing(editor: EditorType, e: KeyboardEvent): boolean {
  if (e.key !== 'Tab') {
    return false;
  }

  // FIXME: handle when the selection is hanging
  const listItems = Array.from(
    Editor.nodes<ListElement>(editor, {
      match: n =>
        Element.isElement(n) &&
        (KitemakerElement.isListBlock(n) || KitemakerElement.isSmartTodo(n)),
      mode: 'lowest',
    })
  );
  if (!listItems.length) {
    return false;
  }

  e.preventDefault();
  if (e.shiftKey) {
    HistoryEditor.asBatch(editor, () => {
      KitemakerEditor.withoutNormalizing(editor, () => {
        for (const [listItem, path] of listItems) {
          if ((listItem.indent as number) <= 0) {
            continue;
          }

          KitemakerTransforms.setNodes(
            editor,
            { indent: (listItem.indent as number) - 1 },
            { at: path }
          );
        }
      });
    });
  } else {
    HistoryEditor.asBatch(editor, () => {
      KitemakerEditor.withoutNormalizing(editor, () => {
        for (const [listItem, path] of listItems) {
          const maxIndent = KitemakerElement.isSmartTodo(listItem)
            ? MAX_SMART_INDENT
            : MAX_LIST_INDENT;
          if ((listItem.indent as number) >= maxIndent) {
            continue;
          }
          KitemakerTransforms.setNodes(
            editor,
            { indent: (listItem.indent as number) + 1 },
            { at: path }
          );
        }
      });
    });
  }

  return true;
}

function breakOutOfList(editor: EditorType, e: KeyboardEvent): boolean {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isListBlock(node)) {
    return false;
  }

  // current text is empty?
  if (KitemakerNode.safeString(node).length || node.children.some(e => !Text.isText(e))) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.setBlockElement(editor, Elements.Paragraph, { at: path });
  });

  return true;
}

function deleteOutOfList(editor: EditorType, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isListBlock(node)) {
    return false;
  }

  // is our cursor at the start of the block?
  const nodes = Array.from(Editor.nodes(editor, { at: path, mode: 'lowest' }));
  if (!nodes.length) {
    return false;
  }

  if (!Path.equals(selection.focus.path, nodes[0][1]) || selection.focus.offset !== 0) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.setBlockElement(editor, Elements.Paragraph, { at: path });
  });

  return true;
}

function splitTodos(editor: EditorType, e: KeyboardEvent): boolean {
  if (!editor.isHardBreak(e)) {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node] = block;
  if (!KitemakerElement.isElement(node) || node.type !== Elements.Todo) {
    return false;
  }

  e.preventDefault();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.splitNodes(editor, { at: selection, always: true });
    KitemakerTransforms.setNodes(
      editor,
      {
        type: Elements.Todo,
        checked: false,
      },
      { hanging: true, mode: 'lowest' }
    );
  });

  return true;
}

function handleTab(editor: Editor, e: KeyboardEvent): boolean {
  if (e.key !== 'Tab') {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();
  editor.insertText('\t');
  return true;
}

function handleJumpingOverNonSelectableInlineVoids(editor: EditorType, e: KeyboardEvent) {
  if (e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
    return false;
  }

  const selection = safeSelection(editor);
  if (!selection) {
    return false;
  }

  const [node] = Editor.node(editor, selection.focus.path);
  if (!Text.isText(node)) {
    return false;
  }

  if (e.key === 'ArrowLeft' && selection.focus.offset !== 0) {
    return false;
  } else if (e.key === 'ArrowRight' && selection.focus.offset !== node.text.length) {
    return false;
  }

  const findAdjacent = () => {
    if (e.key === 'ArrowLeft') {
      return KitemakerEditor.previous(editor, { at: selection.focus.path });
    }
    return KitemakerEditor.next(editor, { at: selection.focus.path });
  };

  const parentEntry = Editor.parent(editor, selection.focus.path);
  if (!parentEntry) {
    return false;
  }

  const [parent] = parentEntry;

  const adjacentEntry = findAdjacent();
  if (!adjacentEntry) {
    return false;
  }
  const [adjacentNode] = adjacentEntry;
  if (
    KitemakerElement.isElement(adjacentNode) &&
    KitemakerElement.isNoneSelectableInlineVoid(adjacentNode) &&
    parent.children.includes(adjacentNode as any)
  ) {
    e.preventDefault();
    if (!Range.isCollapsed(selection) && !e.shiftKey) {
      KitemakerTransforms.collapse(editor, { edge: e.key === 'ArrowLeft' ? 'start' : 'end' });
      return true;
    }

    KitemakerTransforms.move(editor, {
      distance: 1,
      unit: 'character',
      edge: e.shiftKey ? 'focus' : undefined,
      reverse: e.key === 'ArrowLeft',
    });
    KitemakerTransforms.move(editor, {
      distance: 1,
      unit: 'offset',
      edge: e.shiftKey ? 'focus' : undefined,
      reverse: e.key === 'ArrowLeft',
    });

    return true;
  }

  return false;
}

function handleVoidInlines(editor: Editor, e: KeyboardEvent): boolean {
  const selection = safeSelection(editor);

  // pushing shift+left next to an inline void doesn't work
  if (e.key === 'ArrowLeft' && e.shiftKey && selection && selection.focus.offset === 0) {
    const path = selection.focus.path;
    const parentEntry = Editor.parent(editor, path);
    if (!parentEntry) {
      return false;
    }
    const [parent, parentPath] = parentEntry;
    // are we in an inline void now? If so, add the previous node to the selection
    if (KitemakerElement.isInlineVoid(parent)) {
      const previousEntry = KitemakerEditor.previous(editor, { at: parentPath });
      if (!previousEntry) {
        return false;
      }
      const [node, previousPath] = previousEntry;
      KitemakerTransforms.setSelection(editor, {
        focus: {
          path: previousPath,
          offset: Text.isText(node) ? node.text.length : 0,
        },
      });
      return true;
    }

    // check if the previous node is an inline void
    const predecessorEntry = Editor.previous(editor, { at: path });
    if (!predecessorEntry) {
      return false;
    }

    const [predecessor, predecessorPath] = predecessorEntry;
    if (!KitemakerElement.isInlineVoid(predecessor)) {
      return false;
    }
    KitemakerTransforms.setSelection(editor, { focus: { path: predecessorPath, offset: 0 } });
    return true;
  }

  if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
    return false;
  }

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const inline = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isInline(editor, n),
  });

  if (!inline) {
    return false;
  }

  const [node] = inline;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isVoid(node)) {
    return false;
  }

  // pushing up/down doesn't work so we move things a tiny bit and then let the normal
  // up/down behavior take over
  if (e.key === 'ArrowUp') {
    KitemakerTransforms.move(editor, { distance: 1, unit: 'offset', reverse: true });
  }
  if (e.key === 'ArrowDown') {
    KitemakerTransforms.move(editor, { distance: 1, unit: 'offset' });
  }

  return false;
}

function handleVoidBlocks(editor: Editor, e: KeyboardEvent): boolean {
  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isVoid(node)) {
    return false;
  }

  if (editor.isHardBreak(e)) {
    e.preventDefault();
    e.stopPropagation();
    editor.insertNode({ type: Elements.Paragraph, children: [{ text: '' }] });
    return true;
  }

  const prev = KitemakerNode.previousSibling(editor, path);
  if (e.key === 'ArrowUp' && !prev) {
    e.preventDefault();
    e.stopPropagation();
    HistoryEditor.asBatch(editor, () => {
      KitemakerTransforms.insertNodes(
        editor,
        { type: Elements.Paragraph, children: [{ text: '' }] },
        { at: [0], mode: 'highest' }
      );
      KitemakerTransforms.move(editor, { distance: 1, unit: 'line', reverse: true });
    });
    return true;
  }

  const next = KitemakerNode.nextSibling(editor, path);
  if (e.key === 'ArrowDown' && e.shiftKey && next) {
    const [nextNode, nextPath] = next;
    if (
      KitemakerElement.isElement(nextNode) &&
      KitemakerElement.isVoid(nextNode) &&
      !KitemakerElement.isInlineVoid(nextNode)
    ) {
      e.preventDefault();
      e.stopPropagation();
      KitemakerTransforms.expandSelectionToInclude(editor, nextPath);
      return true;
    }
  }

  if (e.key === 'ArrowDown' && !next) {
    e.preventDefault();
    e.stopPropagation();
    KitemakerTransforms.insertNodes(
      editor,
      [{ type: Elements.Paragraph, children: [{ text: '' }] }],
      {
        at: [editor.children.length],
      }
    );
    KitemakerTransforms.move(editor, { distance: 1, unit: 'line' });
    return true;
  }

  if (e.key === 'Backspace') {
    e.preventDefault();
    e.stopPropagation();
    KitemakerTransforms.removeNodes(editor, { at: path, mode: 'lowest', voids: true });
    return true;
  }

  return false;
}

function handleDeletingIntoVoidBlocks(editor: Editor, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;

  if (
    !KitemakerElement.isElement(node) ||
    !KitemakerElement.isTextBlock(node) ||
    !isEqual(node.children, [{ text: '' }])
  ) {
    return false;
  }

  const prev = KitemakerNode.previousSibling(editor, path);
  if (!prev) {
    return false;
  }

  const [prevNode] = prev;
  if (!KitemakerElement.isElement(prevNode) || !KitemakerElement.isVoid(prevNode)) {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.move(editor, { distance: 1, unit: 'line', reverse: true });
    KitemakerTransforms.removeNodes(editor, { at: path });
  });
  return true;
}

function handleDeletingIntoNonInteractiveBlocks(editor: Editor, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [, path] = block;

  // is our cursor at the start of the block?
  const nodes = Array.from(Editor.nodes(editor, { at: path, mode: 'lowest' }));
  if (!nodes.length) {
    return false;
  }

  if (!Path.equals(selection.focus.path, nodes[0][1]) || selection.focus.offset !== 0) {
    return false;
  }

  const prev = KitemakerNode.previousSibling(editor, path);
  if (!prev) {
    return false;
  }

  const [prevNode] = prev;
  if (!KitemakerElement.isElement(prevNode) || !KitemakerElement.isNonInteractive(prevNode)) {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();

  return true;
}

function handleEmptyFirstBlock(editor: Editor, e: KeyboardEvent): boolean {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;

  if (
    !KitemakerElement.isElement(node) ||
    !KitemakerElement.isTextBlock(node) ||
    node.children.length > 1 ||
    !Text.isText(node.children[0]) ||
    node.children[0].text !== ''
  ) {
    return false;
  }

  const prev = KitemakerNode.previousSibling(editor, path);
  const next = KitemakerNode.nextSibling(editor, path);

  if (prev) {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();

  if (!next) {
    if (node.type !== Elements.Paragraph) {
      KitemakerTransforms.setBlockElement(editor, Elements.Paragraph, { at: path });
    }
    return true;
  }

  KitemakerTransforms.removeNodes(editor, { at: path, mode: 'highest' });
  return true;
}

function handleSelectAllDelete(editor: EditorType, e: KeyboardEvent) {
  const selection = safeSelection(editor);

  if (e.key !== 'Backspace' || !selection) {
    return false;
  }

  const [start, end] = Editor.edges(editor, []);
  if (!start || !end) {
    return false;
  }

  if (!Point.equals(start, Range.start(selection)) || !Point.equals(end, Range.end(selection))) {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();

  HistoryEditor.asBatch(editor, () => {
    KitemakerTransforms.delete(editor);
    KitemakerTransforms.setBlockElement(editor, Elements.Paragraph);
  });

  return true;
}

function handleDeletingIntoEmptyBlock(editor: EditorType, e: KeyboardEvent) {
  if (e.key !== 'Backspace') {
    return false;
  }

  const { selection } = editor;

  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const block = Editor.above(editor, {
    match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
  });

  if (!block) {
    return false;
  }

  const [node, path] = block;
  if (!KitemakerElement.isElement(node) || !KitemakerElement.isTextBlock(node)) {
    return false;
  }
  const [start] = Editor.edges(editor, path);
  if (!Point.equals(start, selection.anchor)) {
    return false;
  }

  const prev = KitemakerNode.previousSibling(editor, path);
  if (!prev) {
    return false;
  }

  const [prevNode, prevPath] = prev;
  if (
    !KitemakerElement.isElement(prevNode) ||
    !KitemakerElement.isTextBlock(prevNode) ||
    prevNode.children.length > 1 ||
    !Text.isText(prevNode.children[0]) ||
    prevNode.children[0].text !== ''
  ) {
    return false;
  }

  e.preventDefault();
  e.stopPropagation();

  HistoryEditor.asBatch(editor, () => {
    Editor.withoutNormalizing(editor, () => {
      KitemakerTransforms.setBlockElement(editor, prevNode.type, {
        at: path,
        properties: omit(prevNode, 'children', 'type'),
      });
      KitemakerTransforms.removeNodes(editor, { at: prevPath });
    });
  });
  return true;
}

const keysToActions: { [index: string]: DropHighlightPosition } = {
  ArrowUp: 'top',
  k: 'top',
  ArrowDown: 'bottom',
  j: 'bottom',
};

function handleRearrangeBlock(editor: EditorType, e: KeyboardEvent) {
  const key = e.key;
  const position = keysToActions[key];
  if (e.altKey && position) {
    const { selection } = editor;

    if (!selection || !Range.isCollapsed(selection)) {
      return false;
    }

    const block = Editor.above(editor, {
      match: n => KitemakerElement.isElement(n) && Editor.isBlock(editor, n),
    });

    if (!block) {
      return false;
    }

    const [node, path] = block;

    if (!KitemakerElement.isElement(node)) {
      return false;
    }

    // don't try to move up when we are the first path, otherwise slate has a non-crashing error
    if (position === 'top' && !Path.hasPrevious(path)) {
      return false;
    }
    if (position === 'bottom' && path[0] === editor.children.length - 1) {
      return false;
    }

    e.preventDefault();
    e.stopPropagation();

    const range: Range = KitemakerElement.listViewChildrenRange(editor, path);
    const [start] = Range.start(range).path;
    const [end] = Range.end(range).path;
    const indent = KitemakerElement.calculateIndent(node);

    if (position === 'top') {
      let prev = start - 1;
      while (prev >= 0) {
        const prevElement = editor.children[prev];
        if (
          KitemakerElement.isElement(prevElement) &&
          KitemakerElement.calculateIndent(prevElement) <= indent
        ) {
          break;
        }
        prev--;
      }

      if (prev >= 0) {
        KitemakerTransforms.moveNodes(editor, { at: range, to: [prev] });
      }
    } else {
      let next = end + 1;
      while (next < editor.children.length) {
        const nextElement = editor.children[next];
        if (
          KitemakerElement.isElement(nextElement) &&
          KitemakerElement.calculateIndent(nextElement) <= indent
        ) {
          break;
        }
        next++;
      }

      if (next < editor.children.length) {
        const nextElement = editor.children[next];
        if (KitemakerElement.isElement(nextElement)) {
          // if the next thing isn't a list or we're the same type and our indent is large, move just passed it
          if (
            (!KitemakerElement.isListBlock(nextElement) &&
              nextElement.type !== Elements.SmartTodo) ||
            (nextElement.type === node.type &&
              KitemakerElement.calculateIndent(nextElement) < indent)
          ) {
            KitemakerTransforms.moveNodes(editor, { at: range, to: [next] });
          } else {
            // otherwise, jump over the list entirely
            const listRange: Range = KitemakerElement.listViewChildrenRange(editor, [next]);
            const [listRangeEnd] = Range.end(listRange).path;
            KitemakerTransforms.moveNodes(editor, { at: range, to: [listRangeEnd] });
          }
        }
      }
    }

    return true;
  }
  return false;
}

export function elementsOnKeyDown(editor: EditorType, e: KeyboardEvent): boolean {
  for (const handler of [
    todoHotkeys,
    splitHeadlines,
    deleteOutOfHeadlines,
    breakOutOfFormattedTextBlocks,
    deleteOutOfFormattedTextBlocks,
    arrowOutOfTextBlocks,
    listTabbing,
    breakOutOfList,
    deleteOutOfList,
    splitTodos,
    splitNonVoidInlines,
    handleVoidBlocks,
    handleDeletingIntoVoidBlocks,
    handleJumpingOverNonSelectableInlineVoids,
    handleVoidInlines,
    handleDeletingIntoNonInteractiveBlocks,
    handleEmptyFirstBlock,
    handleTab,
    handleSelectAllDelete,
    handleDeletingIntoEmptyBlock,
    handleRearrangeBlock,
  ]) {
    if (handler(editor, e)) {
      return true;
    }
  }

  return false;
}
