import * as React from 'react';
import { Descendant } from 'slate';
import { EntityProvenance, IntegrationType } from '../../sync/__generated/models';
import { trackerEvent } from '../tracker';

export enum Modals {
  CommandMenu = 'command_menu',
  HotkeyMenu = 'hotkey_menu',
  IntegrationsMenu = 'integrations_menu',
  Import = 'import',
  Image = 'image',
  ChangePassword = 'change_password',
  CreateOrganization = 'create_organization',
  CreateSpace = 'create_space',
  FigmaEmbed = 'figma_embed',
  ManageOrganizations = 'manage_organizations',
  NewEntity = 'new_entity',
  Diagnostics = 'diagnostics',
  InviteMember = 'invite_member',
  NewToken = 'new_token',
  SystemSettings = 'developer',
  IssueLimit = 'issue_limit',
  EnableCycles = 'enable_cycles',
  NewRoadmap = 'new_roadmap',
  NewCustomEmoji = 'new_custom_emoji',
  TextToCopy = 'text_to_copy',
  TodoDetails = 'todo_details',
  NewExternalIssue = 'new_external_issue',
  NewFolder = 'new_folder',
}

export enum CommandMenuView {
  Default,
  Labels,
  Members,
  Initiatives,
  Releases,
  RoadmapInitiatives,
  Roadmaps,
  SpaceRoadmap,
  Spaces,
  Tags,
  Owners,
  ChangeStatus,
  ChangeColumn,
  ChangeSpace,
  Dependencies,
  ChangeInitiativeIssues,
  ChangeReleaseIssues,
  ChangeCycleIssues,
  Impact,
  Effort,
  Snippets,
  Recent,
  GotoSpace,
  TodoMembers,
  TodoLabels,
  TodoImpact,
  TodoEffort,
  ListTodos,
  Starred,
  Snoozed,
  AddToTimeline,
  DueDate,
  MoveDocumentsAndFolders,
}

export interface TextToCopyArgs {
  dialogTitle?: string;
  content: string | Promise<string>;
}

export interface TodoDetailsArgs {
  todoId?: string;
}

export interface CommandMenuArgs {
  view: CommandMenuView;
  context: any;
}

export interface ImageArgs {
  url: string;
  onClose?: () => void;
}

export interface FigmaArgs {
  url: string;
  onClose?: () => void;
}

export interface NewFolderArgs {
  parentId?: string | null;
}

export type NewEntityType = 'Issue' | 'Initiative' | 'Feedback' | 'Doc';
export interface NewEntityArgs {
  type?: NewEntityType;
  spaceId?: string;
  title?: string;
  content?: Descendant[];
  assigneeIds?: string[];
  labelIds?: string[];
  spaceIds?: string[];
  roadmapIds?: string[];
  initiativeIds?: string[];
  releaseIds?: string[];
  impactId?: string | null;
  effortId?: string | null;
  cycleId?: string | null;
  statusId?: string;
  provenance?: EntityProvenance | null;
  personId?: string | null;
  companyId?: string | null;
  folderId?: string | null;
  onCreated?: (entityId: string | null) => void;
}

export type NewTokenType = 'Application' | 'PAT';
export interface NewTokenArgs {
  tokenType: NewTokenType;
}

export interface CreateSpaceArgs {
  favorite?: boolean;
}

export interface IssueLimitArgs {
  statusId: string;
}

export interface EnableCycleArgs {
  spaceId: string;
}

export interface ListTodosArgs {
  entityId: string;
  focusedTodoId?: string;
}

export interface NewRoadmapArgs {
  initialName?: string;
  spaceId?: string;
  onCreated?: (roadmapId: string | null) => void;
}

export interface NewExternalIssueArgs {
  type: IntegrationType;
  title?: string;
  description?: Descendant[];
  onCreated?: (externalIssueId: string | null) => void;
}

export interface AddToTimelineArgs {
  roadmapId?: string;
  startDate: number;
  endDate: number;
}

type ModalArgs =
  | CommandMenuArgs
  | ImageArgs
  | CreateSpaceArgs
  | NewEntityArgs
  | NewTokenArgs
  | EnableCycleArgs
  | IssueLimitArgs
  | NewRoadmapArgs
  | ListTodosArgs
  | TextToCopyArgs
  | TodoDetailsArgs
  | AddToTimelineArgs
  | NewExternalIssueArgs
  | NewFolderArgs;

export interface ModalContext {
  openModal(modal: Modals, args?: ModalArgs): void;
  closeModal(modal?: Modals): void;
  isModalOpen(modal?: Modals): boolean;
  currentArgs(): ModalArgs | null;
}

const modalContext = React.createContext<ModalContext | null>(null);

interface ModalState {
  modal: Modals;
  args: ModalArgs | null;
}

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [openModal, setOpenModal] = React.useState<ModalState | null>(null);
  const openModalRef = React.useRef(openModal);
  openModalRef.current = openModal;

  return (
    <modalContext.Provider
      value={{
        openModal(modal, newArgs) {
          trackerEvent('Modal opened', { modal });
          const state: ModalState = {
            modal,
            args: newArgs ?? null,
          };
          setOpenModal(state);
        },
        closeModal(modal) {
          if (!openModalRef.current) {
            return;
          }

          const state = openModalRef.current;
          if (!modal || state.modal === modal) {
            setOpenModal(null);
          }
        },
        isModalOpen(modal) {
          if (!openModalRef.current) {
            return false;
          }

          if (!modal) {
            return true;
          }

          const state = openModalRef.current;
          return state.modal === modal;
        },
        currentArgs() {
          if (!openModalRef.current) {
            return null;
          }

          const state = openModalRef.current;
          return state.args;
        },
      }}
    >
      {children}
    </modalContext.Provider>
  );
}

export function useModals(): ModalContext {
  return React.useContext(modalContext)!;
}
