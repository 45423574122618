import React from 'react';
import { useRecoilValue } from 'recoil';
import { Initiative } from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import {
  useAddSpacesToInitiatives,
  useRemoveSpacesFromInitiatives,
} from '../../syncEngine/actions/intiatives';
import { spacesIdsForInitiativeSelector } from '../../syncEngine/selectors/intiatives';
import {
  computedVisibilityBySpacesSelector,
  spaceNamesSelector,
  spacePath,
  spacesSelector,
} from '../../syncEngine/selectors/spaces';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import { MetadataSize } from './metadata/size';
import Spaces from './metadata/space';
import { PickerState } from './picker';
import { SpacePicker } from './pickers/spacePicker';
import { FollowTooltipContent, MetadataTooltip } from './tooltip';

function SpacesTooltipContent({ spaceIds }: { spaceIds: string[] }) {
  const organization = useOrganization();
  const spaces = useRecoilValue(spacesSelector(spaceIds));

  return (
    <>
      {spaces.map(space => {
        const link = {
          pathname: spacePath(organization, space),
          state: {
            backUrl: location.pathname,
            backSearch: location.search,
          },
        };
        return (
          <FollowTooltipContent link={link} key={space.id}>
            <span className="ml6 bodyM">{space.name}</span>
          </FollowTooltipContent>
        );
      })}
    </>
  );
}

export function SpacesMetadata({
  spaceIds,
  interactable,
  size,
  state,
  filterPlaceholder,
  className,
  showRoadmap,
  onSpaceAdded,
  onSpaceRemoved,
}: {
  spaceIds: string[];
  interactable?: boolean;
  size: MetadataSize;
  state: PickerState;
  filterPlaceholder?: string;
  className?: string;
  showRoadmap?: boolean;
  onSpaceAdded?: (ids: string[], spaceId: string) => void;
  onSpaceRemoved?: (ids: string[], spaceId: string) => void;
}) {
  const spaceNames = useRecoilValue(spaceNamesSelector(spaceIds));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const privateSpace = useRecoilValue(computedVisibilityBySpacesSelector(spaceIds)) === 'private';

  const [hoverOpen, setHoverOpen] = React.useState(false);
  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  if (!spaceIds.length) {
    return null;
  }

  let content = (
    <Spaces noHover={!interactable} names={spaceNames} size={size} privateSpace={privateSpace} />
  );
  if (!interactable) {
    return <div className={className}>{content}</div>;
  }

  if (state && onSpaceAdded && onSpaceRemoved) {
    content = (
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>{content}</DropdownMenuTrigger>
        <DropdownMenuContent
          onClick={e => {
            e.stopPropagation();
          }}
          side="bottom"
          align="start"
          className="menuPicker menuMedium"
        >
          <SpacePicker
            showRoadmap={showRoadmap}
            multi
            filterPlaceholder={filterPlaceholder}
            state={state}
            onSpaceAdded={onSpaceAdded}
            onSpaceRemoved={onSpaceRemoved}
            onDone={closeMenu}
          />
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <MetadataTooltip
      disabled={menuOpen}
      style={{ minWidth: 'unset' }}
      content={<SpacesTooltipContent spaceIds={spaceIds} />}
      side="bottom"
      align="start"
      open={hoverOpen}
      onOpenChange={setHoverOpen}
    >
      <div className={className}>{content}</div>
    </MetadataTooltip>
  );
}

export function InitiativeSpaces({
  initiative,
  interactable: interactableProp,
  size = MetadataSize.Small,
  className,
}: {
  initiative: Initiative;
  interactable?: boolean;
  size?: MetadataSize;
  className?: string;
}) {
  const spaceIds = useRecoilValue(spacesIdsForInitiativeSelector(initiative.id));
  const addSpacesToInitiatives = useAddSpacesToInitiatives();
  const removeSpacesFromInitiatives = useRemoveSpacesFromInitiatives();
  const maybeSpace = useMaybeSpace();
  const isPrivate = maybeSpace?.private ?? false;

  const interactable = interactableProp && !isPrivate;

  return (
    <SpacesMetadata
      className={className}
      spaceIds={spaceIds}
      interactable={interactable}
      size={size}
      state={{ [initiative.id]: spaceIds }}
      onSpaceAdded={(ids, spaceId) => addSpacesToInitiatives(ids, [spaceId])}
      onSpaceRemoved={(ids, spaceId) => removeSpacesFromInitiatives(ids, [spaceId])}
    />
  );
}
