import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  EffortChangedActivityDetails,
} from '../../../../sync/__generated/models';
import { impactSelector } from '../../../syncEngine/selectors/impactLevels';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function EffortChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as EffortChangedActivityDetails;
  const effort = useRecoilValue(impactSelector(details.effortId));

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> set the effort
      to{' '}
      <span className={styles.activityHighlight}>{effort?.name ?? details.fallbackEffortName}</span>
      .
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const EffortChangedActivity = React.memo(EffortChangedActivityComponent);
