import cn from 'classnames';
import debugModule from 'debug';
import * as React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { documentFromString, emptyDocument, stringifyDocument } from '../../../shared/slate/utils';
import { exportToCsv } from '../../../shared/utils/utils';
import { CommandGroup } from '../../commands';
import { CollaborativeDocEditor } from '../../components/collaborativeDocEditor';
import { DescriptionHistory } from '../../components/descriptionHistory';
import { DescriptionPlaceholder, PlaceholderType } from '../../components/descriptionPlaceholder';
import ExternalLink from '../../components/externalLink';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import { CommandContext } from '../../components/new/commandMenuContext';
import { CompanyPerson } from '../../components/new/companyPerson';
import { CustomCommand } from '../../components/new/customCommand';
import { EntityCard } from '../../components/new/entityCard';
import { FeedbackFilterMenu } from '../../components/new/feedbackFilters';
import { Filters } from '../../components/new/filters';
import { Hotkey } from '../../components/new/hotkey';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  FocusReason,
  KeyNavigationProvider,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useGetKeyNavigationState,
  useHasKeyNavigationFocus,
  useKeyNavigationWatcher,
  useSetKeyNavigationFocus,
} from '../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { AvatarSize } from '../../components/new/metadata/avatar';
import {
  CompanyPersonPicker,
  CompanyPersonPickerOptions,
  feedbackToCompanyPersonPickerState,
} from '../../components/new/pickers/companyPersonPicker';
import Placeholder from '../../components/new/placeholder';
import { ScreenHeader } from '../../components/new/screenHeader';
import { Tabs } from '../../components/new/tabs';
import { Tooltip } from '../../components/new/tooltip';
import { VirtualizedListView } from '../../components/new/virtualizedListView';
import { OrganizationBackCommand } from '../../components/organizationBackHotkey';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useUndo } from '../../contexts/undoContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { ResponsiveDesignSize, useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { useNewEntityModalArgs } from '../../modals/newEntityModal/newEntityModal';
import { useSerializeToMarkdown } from '../../slate/hooks/useSerializeToMarkdown';
import { TextArea, TextAreaHandle } from '../../slate/textArea';
import { useUpdateFeedbacks } from '../../syncEngine/actions/feedback';
import { actorNameSelector } from '../../syncEngine/selectors/actors';
import {
  collaborativeDocIdByEntitySelector,
  collaborativeDocsByEntitiesSelector,
} from '../../syncEngine/selectors/collaborativeDoc';
import { companySelector } from '../../syncEngine/selectors/companies';
import {
  FeedbackMode,
  feedbackForOrganizationSelector,
  feedbackScreenSelector,
  feedbackScreenTabState,
  feedbackSelector,
  openFeedbackState,
  personCompanyIdsForFeedbackSelector,
  useFeedbackNumberToId,
  useFeedbackPath,
} from '../../syncEngine/selectors/feedback';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { personSelector } from '../../syncEngine/selectors/people';
import { tagSelector } from '../../syncEngine/selectors/tags';
import { createNewEntityFromAnywhere, createNewIssueKey } from '../../utils/config';
import { filterPropertiesSelector } from '../../utils/filtering2';
import { silentlyUpdatePath } from '../../utils/history';
import { metaKeyDown } from '../../utils/keyEvents';
import { HistoryCheckpoint } from '../../utils/revisions';
import { FeedbackBlock } from './feedback';
import { FeedbackMenuButton } from './feedbackMenu';
import { FeedbackOwners } from './feedbackOwners';
import styles from './feedbackScreen.module.scss';
import { FeedbackTagsWithPlaceholder } from './feedbackTags';

const debug = debugModule('feedback');

enum SmallScreenMode {
  List,
  Details,
}

function CompanyBlock({
  feedbackId,
  setCompanyId,
  setPersonId,
}: {
  feedbackId: string;
  setCompanyId: (companyId: string | null) => void;
  setPersonId: (personId: string | null) => void;
}) {
  const feedback = useRecoilValue(feedbackSelector(feedbackId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const close = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);

  if (!feedback) {
    return null;
  }

  return (
    <DropdownMenu onOpenChange={setMenuOpen} open={menuOpen}>
      <DropdownMenuTrigger style={{ marginLeft: -6 }} asChild>
        <Button
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          icon={<CompanyPerson size={AvatarSize.Size20} noName feedbackId={feedbackId} />}
        >
          <div className="row metadataGap">
            <CompanyPerson noLogo feedbackId={feedbackId} />
            <Icon style={{ fill: 'var(--grayA8)' }} icon={'arrow_down'} />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        align="start"
        sideOffset={10}
        alignOffset={-2}
        className="menuHuge menuPicker"
        style={{ maxHeight: 450 }}
      >
        <CompanyPersonPicker
          state={feedbackToCompanyPersonPickerState([feedback])}
          onDone={close}
          onCompanyAdded={companyId => {
            setCompanyId(companyId);
            close();
          }}
          onCompanyRemoved={() => {
            setCompanyId(null);
            close();
          }}
          onPersonAdded={personId => {
            setPersonId(personId);
            close();
          }}
          onPersonRemoved={() => {
            setPersonId(null);
            close();
          }}
          options={CompanyPersonPickerOptions.All}
          currentId={feedback.companyId ?? feedback.personId}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function PlaceholderRow({ mode, className }: { mode: FeedbackMode; className?: string }) {
  const id = `placeholder-${mode}`;
  const focused = useHasKeyNavigationFocus(id);

  return (
    <div className={cn(styles.feedback, className)}>
      <EntityCard className={cn({ [styles.focused]: focused })}>Nothing here right now</EntityCard>
    </div>
  );
}

function FeedbackList({
  grid,
  className,
  onFeedbackClick,
}: {
  grid: Record<FeedbackMode, string[]>;
  className?: string;
  onFeedbackClick?: () => void;
}) {
  const [openFeedbackId, setOpenFeedbackId] = useRecoilState(openFeedbackState);
  const organization = useOrganization();
  const history = useHistory();
  const feedbackPath = useFeedbackPath();
  const feedbackNumberToId = useFeedbackNumberToId();
  const getKeyNavState = useGetKeyNavigationState();
  const setFocus = useSetKeyNavigationFocus();
  const [currentTab, setCurrentTab] = useRecoilState(feedbackScreenTabState);

  const openFeedbackIdRef = React.useRef(openFeedbackId);
  openFeedbackIdRef.current = openFeedbackId;

  useComponentDidMount(() => {
    const focusedId = getKeyNavState().focused;
    const params = history.location.search;
    if (focusedId && !focusedId.includes('-')) {
      debug(`Setting open feedback ID ${focusedId}`);
      setOpenFeedbackId(focusedId);
      const path = feedbackPath(focusedId);
      if (path) {
        silentlyUpdatePath(`${path}${params}`);
      }
    }

    const unregister = history.listen((location, action) => {
      if (action !== 'PUSH' && action !== 'POP') {
        return;
      }
      const pathComponents = location.pathname.split('/').filter(p => !!p);
      const [, feedback, feedbackNumber] = pathComponents;
      if (feedback !== 'feedback') {
        return;
      }
      const feedbackId = feedbackNumberToId(feedbackNumber);
      if (feedbackId) {
        debug(`Selected ${feedbackId} due to a history push`);
        setFocus(feedbackId);
      }
    });
    () => unregister();
  });

  useKeyNavigationWatcher(({ focused }) => {
    const params = history.location.search;
    if (focused && focused !== openFeedbackIdRef.current) {
      setOpenFeedbackId(focused.includes('-') ? '' : focused);
      if (!focused.includes('-')) {
        debug(`Selected ${focused} because of keynav`);
      } else {
        silentlyUpdatePath(`${organizationPath(organization, 'feedback')}${params}`);
      }
      const path = feedbackPath(focused);
      if (path) {
        silentlyUpdatePath(`${path}${params}`);
      }
    }
  });

  const tabs = React.useMemo(() => {
    return [
      {
        id: FeedbackMode.Unprocessed,
        name: 'Unprocessed',
        count: grid[FeedbackMode.Unprocessed].length,
      },
      {
        id: FeedbackMode.Processed,
        name: 'Processed',
        count: grid[FeedbackMode.Processed].length,
      },
    ];
  }, [grid]);

  const itemIds = React.useMemo(() => {
    return { items: grid[currentTab] };
  }, [currentTab, grid]);

  return (
    <div className={cn(styles.feedbackList, className)}>
      <div className={styles.tabWrapper}>
        <Tabs
          size={ButtonSize.Small}
          className={styles.tabs}
          tabs={tabs}
          currentTab={currentTab}
          onTabChanged={setCurrentTab as (tabId: string) => void}
        />
      </div>
      <VirtualizedListView
        className={styles.virtualizedList}
        id="FEEDBACK"
        resizableItems
        sectionIds={['items']}
        itemIds={itemIds}
        renderSectionHeader={() => null}
        renderItem={id => {
          return <FeedbackBlock onClick={onFeedbackClick} key={`feedback-${id}`} feedbackId={id} />;
        }}
        renderPlaceholder={setionId => {
          return <PlaceholderRow mode={setionId as FeedbackMode} />;
        }}
        sectionHeaderHeight={10}
        spacerHeight={20}
      />
      <div className={styles.footer}>
        <a href="https://clearbit.com">
          <span className="bodyS">Logos provided by</span>{' '}
          <span className="headingXS">Clearbit</span>
        </a>
      </div>
    </div>
  );
}

function FeedbackPlaceholder({ feedbackCount }: { feedbackCount: number }) {
  const modals = useModals();
  return (
    <Placeholder
      icon="feedback"
      title={feedbackCount === 0 ? 'No user feedback yet' : 'Select a feedback'}
    >
      <span className="grayed textCenter">
        <p>
          Collect user feedback and capture relevant insights here.
          <br />
          Learn more about feedback in the{' '}
          <ExternalLink
            href="https://guide.kitemaker.co/feedback-and-insights"
            className="link hoverOnly headingS"
          >
            Kitemaker Guide.
          </ExternalLink>
        </p>
        <Button
          icon="add"
          onClick={() => {
            modals.openModal(Modals.NewEntity);
          }}
        >
          Add new feedback
        </Button>
      </span>
    </Placeholder>
  );
}

function OpenFeedback({
  nextFeedbackId,
  feedbackCount,
  className,
}: {
  nextFeedbackId: (id: string) => string | null;
  feedbackCount: number;
  className?: string;
}) {
  const openFeedbackId = useRecoilValue(openFeedbackState);
  const documentId = useRecoilValue(collaborativeDocIdByEntitySelector(openFeedbackId));
  const openFeedbackIdRef = React.useRef(openFeedbackId);
  openFeedbackIdRef.current = openFeedbackId;

  const feedback = useRecoilValue(feedbackSelector(openFeedbackId));
  const [title, setTitle] = React.useState(documentFromString(feedback?.title ?? ''));

  const inputRef = React.useRef<TextAreaHandle>(null);
  const textAreaRef = React.useRef<TextAreaHandle>(null);
  const [historyContents, setHistoryContents] = React.useState<HistoryCheckpoint | null>(null);
  const [editorDisabled, setEditorDisabled] = React.useState(false);
  const setFocus = useSetKeyNavigationFocus();

  const creatorName = useRecoilValue(actorNameSelector(feedback?.actorId));
  const enableKeynav = useEnableKeyNavigation();
  const disableKeynav = useDisableKeyNavigation();

  const updateFeedback = useUpdateFeedbacks();
  const { setUndo } = useUndo();

  React.useEffect(() => {
    textAreaRef?.current?.moveSelectionToStart();
    inputRef.current?.replaceValue(documentFromString(feedback?.title ?? ''));

    return () => {
      enableKeynav('feedback');
    };
  }, [openFeedbackId]);

  function moveNext() {
    if (!feedback) {
      return;
    }

    const nextId = nextFeedbackId(openFeedbackIdRef.current);
    if (nextId) {
      setFocus(nextId);
    } else {
      setFocus(
        `placeholder-${feedback.processed ? FeedbackMode.Processed : FeedbackMode.Unprocessed}`
      );
    }
  }

  function toggleProcessed() {
    if (!feedback) {
      return;
    }

    updateFeedback([feedback.id], { processed: !feedback.processed });
    moveNext();

    const action = feedback.processed ? 'unprocessed' : 'processed';
    setUndo(`Feedback ${action}`, () => {
      updateFeedback([feedback.id], { processed: feedback.processed });
      setFocus(feedback.id);
    });
  }

  if (!feedback) {
    return <FeedbackPlaceholder feedbackCount={feedbackCount} />;
  }

  return (
    <div key={openFeedbackId} className={cn(styles.openFeedbackContainer, className)}>
      <CommandContext
        context={{
          group: CommandGroup.Entities,
          entityIds: [openFeedbackId],
          focusedEntityId: openFeedbackId,
        }}
      />
      <CustomCommand
        command={{
          id: 'edit-title',
          group: CommandGroup.Entities,
          description: 'Edit title',
          hotkey: 't',
          priority: 99,
          handler: () => {
            inputRef.current?.focus();
            inputRef.current?.moveSelectionToEnd();
          },
        }}
      />
      <CustomCommand
        command={{
          id: 'edit-description',
          group: CommandGroup.Entities,
          description: 'Edit content',
          hotkey: 'd',
          priority: 99,
          handler: () => {
            textAreaRef.current?.focus();
            textAreaRef.current?.moveSelectionToEnd();
            textAreaRef.current?.ensureBottomMarginVisible();
          },
        }}
      />
      <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
        <DescriptionHistory
          documentId={documentId ?? ''}
          onDescriptionChanged={d => {
            setHistoryContents(d);
          }}
          onRestore={d => {
            setHistoryContents(null);
            setTimeout(() => textAreaRef.current?.replaceValue(d.content));
          }}
          onOpen={() => {
            setEditorDisabled(true);
          }}
          onClose={() => {
            setEditorDisabled(false);
          }}
        />
      </HideIfSmallerThan>
      <div className={styles.gutter} />
      <div className={cn(styles.feedbackContent)}>
        <div className="rowBetween mb8">
          <CompanyBlock
            feedbackId={feedback.id}
            setCompanyId={companyId => {
              textAreaRef.current?.focus();
              textAreaRef.current?.moveSelectionToEnd();
              updateFeedback([feedback.id], { companyId, personId: null });
            }}
            setPersonId={personId => {
              updateFeedback([feedback.id], { personId, companyId: null });
            }}
          />
          <div className="rowEnd">
            <FeedbackOwners feedback={feedback} showPlaceholder />
            <div className="ml4">
              <FeedbackMenuButton feedback={feedback} />
            </div>
          </div>
        </div>
        <div className="rowAlignStart">
          <div className="col grow mr16">
            <TextArea
              ref={inputRef}
              richText={false}
              initialValue={title}
              onChange={value => {
                setTitle(value);
              }}
              onBlur={() => {
                updateFeedback([feedback.id], {
                  title: stringifyDocument(inputRef.current?.raw().children ?? emptyDocument()),
                });
              }}
              onKeyDown={e => {
                if (e.key.toLowerCase() === 'enter') {
                  e.preventDefault();
                  e.stopPropagation();
                  updateFeedback([feedback.id], {
                    title: stringifyDocument(inputRef.current?.raw().children ?? emptyDocument()),
                  });
                  textAreaRef.current?.focus();
                  return;
                }
                if (e.key.toLowerCase() === 'escape') {
                  inputRef.current?.blur();
                }
              }}
              placeholder="Give your feedback a title"
              className={'headingL noShrink fs-exclude'}
            />
          </div>
          <Tooltip
            content={
              <>
                {!feedback.processed ? 'Mark processed' : 'Mark unprocessed'}{' '}
                <KeyboardShortcut shortcut="p" />
              </>
            }
          >
            <Button
              size={ButtonSize.Small}
              icon={!feedback.processed ? 'select_checkmark' : undefined}
              onClick={() => {
                toggleProcessed();
              }}
            >
              {!feedback.processed ? 'Mark processed' : 'Mark unprocessed'}
            </Button>
          </Tooltip>
        </div>
        <div className={styles.labelRow}>
          <FeedbackTagsWithPlaceholder feedback={feedback} />
          <span className="grayedLight bodyS">
            Created by <span className="headingXS">{creatorName}</span>
          </span>
        </div>
        <div className="rowStretch grow">
          <CollaborativeDocEditor
            className={'fullWidth grow fs-exclude'}
            disabled={editorDisabled}
            historyDescription={historyContents}
            documentId={documentId ?? ''}
            textAreaRef={textAreaRef}
            richText
            placeholder={<DescriptionPlaceholder type={PlaceholderType.Feedback} />}
            onKeyDown={e => {
              if (e.key === 'Escape' || (e.key === 'Enter' && metaKeyDown(e))) {
                e.preventDefault();
                e.stopPropagation();
                textAreaRef?.current?.blur();
              }
            }}
            onFocus={() => {
              disableKeynav('feedback');
            }}
            onBlur={() => {
              enableKeynav('feedback');
            }}
            suggestions={[]}
            entityId={openFeedbackId}
            feedbackId={openFeedbackId}
            enableBlockDragging
          />
        </div>
      </div>
      <div className={styles.gutter} />
    </div>
  );
}

function LeftHeader({
  setSmallScreenMode,
}: {
  setSmallScreenMode?: React.Dispatch<React.SetStateAction<SmallScreenMode>>;
}) {
  const organization = useOrganization();
  const history = useHistory();

  return (
    <ScreenHeader
      className={styles.header}
      hideNotifications
      compensateForMacOSTrafficLights="auto"
      showSidebarOpener={!setSmallScreenMode}
    >
      {setSmallScreenMode && (
        <IconButton
          icon={'full_arrow_back'}
          onClick={() => setSmallScreenMode?.(SmallScreenMode.List)}
        />
      )}

      <div className="rowBetween fullWidth">
        <div className="row ml8">
          <Breadcrumbs breadcrumbs={[{ name: 'Feedback' }]} />
          <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
            <FeedbackFilterMenu id={'feedback'} />
          </HideIfSmallerThan>
        </div>

        <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
          <Tooltip content="Feedback settings">
            <IconButton
              icon="settings"
              buttonStyle={ButtonStyle.BareSubtle}
              onClick={() =>
                history.push(organizationPath(organization, 'settings/feedback'), {
                  backUrl: location.pathname,
                  backSearch: location.search,
                })
              }
            />
          </Tooltip>
        </HideIfSmallerThan>
      </div>
    </ScreenHeader>
  );
}

function RightHeader() {
  const modals = useModals();

  const rightSideContent = (
    <Tooltip
      content={
        <>
          Create feedback <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
        </>
      }
    >
      <Button
        className="mr16"
        icon="add"
        onClick={() => {
          modals.openModal(Modals.NewEntity);
        }}
      >
        New feedback
      </Button>
    </Tooltip>
  );
  return (
    <ScreenHeader rightSideContent={rightSideContent} className={styles.header}>
      <div />
    </ScreenHeader>
  );
}

function NewEntityProps({
  tab,
  setTab,
}: {
  tab: FeedbackMode;
  setTab: (tab: FeedbackMode) => void;
}) {
  const modals = useModals();
  const organization = useOrganization();
  const setFocus = useSetKeyNavigationFocus();
  const history = useHistory();

  const props = useRecoilValue(filterPropertiesSelector('feedback'));

  useNewEntityModalArgs({
    type: 'Feedback',
    ...props,
    onCreated: (createdId: string | null) => {
      if (!createdId) {
        return;
      }
      if (tab === FeedbackMode.Processed) {
        setTab(FeedbackMode.Unprocessed);
      }
      setFocus(createdId, FocusReason.Programmatic);
      history.push(organizationPath(organization, `feedback/${createdId}`));
    },
  });

  return (
    <Hotkey
      priority={-2}
      hotkey={createNewIssueKey}
      handler={(e?: KeyboardEvent | undefined) => {
        e?.preventDefault();
        e?.stopPropagation();
        modals.openModal(Modals.NewEntity);
      }}
    />
  );
}

function FeedbackScreenComponent() {
  const organization = useOrganization();
  const feedbackNumberToId = useFeedbackNumberToId();
  const matches = useRouteMatch<{ feedbackNumber?: string }>();
  const smallScreen = useIsSmallScreen();
  const feedbackPath = useFeedbackPath();
  const [tab, setTab] = useRecoilState(feedbackScreenTabState);

  const { all, processed, unprocessed } = useRecoilValue(feedbackScreenSelector(organization.id));

  const toMarkdown = useSerializeToMarkdown();

  const getFeedbackCallback = useRecoilCallback(({ snapshot }) => () => {
    const feedback = snapshot
      .getLoadable(feedbackForOrganizationSelector(organization.id))
      .getValue();
    const feedbackWithDesc = feedback.map(f => {
      const doc = snapshot.getLoadable(collaborativeDocsByEntitiesSelector([f.id])).getValue();
      const tags = f.tagIds.map(tagId => snapshot.getLoadable(tagSelector(tagId)).getValue()?.name);
      const { personId, companyId } = snapshot
        .getLoadable(personCompanyIdsForFeedbackSelector(f.id))
        .getValue();

      const person = snapshot.getLoadable(personSelector(personId)).getValue();
      const company = person
        ? snapshot.getLoadable(companySelector(person.companyId)).getValue()
        : snapshot.getLoadable(companySelector(companyId)).getValue();
      return {
        title: f.title,
        tags,
        processed: f.processed,
        company: company ? company.name : '',
        person: person ? person.name : '',
        content: toMarkdown(doc[0].content),
      };
    });
    return feedbackWithDesc;
  });

  const [smallScreenMode, setSmallScreenMode] = React.useState(() => {
    if (matches.params.feedbackNumber) {
      return SmallScreenMode.Details;
    }
    return SmallScreenMode.List;
  });

  const feedbackCount = all.length;
  const grid: Record<FeedbackMode, string[]> = React.useMemo(() => {
    return {
      [FeedbackMode.Unprocessed]: unprocessed,
      [FeedbackMode.Processed]: processed,
    };
  }, [all, processed, unprocessed]);

  const initialId = React.useMemo(() => {
    const id = feedbackNumberToId(matches.params.feedbackNumber ?? '');
    debug(`Initial ID: ${id} (feedback number: ${matches.params.feedbackNumber})`);
    if (id?.length) {
      if (tab === FeedbackMode.Processed && unprocessed.includes(id)) {
        setTab(FeedbackMode.Unprocessed);
      } else if (tab === FeedbackMode.Unprocessed && processed.includes(id)) {
        setTab(FeedbackMode.Processed);
      }
    }
    return id;
  }, [matches.params.feedbackNumber]);

  const onFeedbackClick = React.useCallback(() => {
    if (smallScreen) {
      setSmallScreenMode(SmallScreenMode.Details);
    }
  }, [smallScreen]);

  if (!smallScreen && !matches.params.feedbackNumber && unprocessed[0]) {
    const pathname = feedbackPath(unprocessed[0]);
    if (pathname) {
      return <Redirect to={{ pathname }} />;
    }
  }

  return (
    <Screen>
      <TitleSetter title={`${organization.name} · Feedback`} />
      <KeyNavigationProvider
        initiallyFocusedElementId={initialId}
        disableEnsureVisible
        disableLeftAndRight
        columnIds={['FEEDBACK']}
      >
        <NewEntityProps tab={tab} setTab={setTab} />
        <div className={cn(styles.feedbackScreen, 'fullScreenHeight', 'rowStretch')}>
          <div
            className={cn(styles.left, {
              hidden: smallScreen && smallScreenMode === SmallScreenMode.Details,
            })}
          >
            <LeftHeader />
            <Filters className="fullWidth" id={'feedback'} />
            <FeedbackList grid={grid} onFeedbackClick={onFeedbackClick} />
          </div>

          <div className={styles.right}>
            {!smallScreen && <RightHeader />}
            {smallScreen && <LeftHeader setSmallScreenMode={setSmallScreenMode} />}
            <OpenFeedback
              feedbackCount={feedbackCount}
              nextFeedbackId={openFeedbackId => {
                const allFeedbacks = [...unprocessed, ...processed];
                const index = allFeedbacks.indexOf(openFeedbackId);
                if (index === -1) {
                  return null;
                }

                const nextId = allFeedbacks[index + 1];
                const previousId = allFeedbacks[index - 1];

                return nextId ?? previousId ?? null;
              }}
              className={cn({
                [styles.hidden]: smallScreen && smallScreenMode === SmallScreenMode.List,
              })}
            />
          </div>
          <OrganizationBackCommand name="feedback" />
        </div>
      </KeyNavigationProvider>
      <CustomCommand
        command={{
          id: 'feedback-export',
          group: CommandGroup.Board,
          description: 'Export all feedback as CSV',
          handler: () => {
            const allFeedback = getFeedbackCallback();
            if (allFeedback) {
              exportToCsv('feedback.csv', allFeedback);
            }
          },
        }}
      />
    </Screen>
  );
}

export const FeedbackScreen = React.memo(FeedbackScreenComponent);
