import * as React from 'react';
import { isElectron, openLinkInBrowser } from '../electronIntegration';

export default function ExternalLink({
  href,
  onClick,
  ...rest
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) {
  return (
    <a
      {...rest}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      onMouseDown={e => {
        e.preventDefault();
      }}
      onClick={e => {
        onClick?.(e);
        if (isElectron()) {
          e.preventDefault();
          e.stopPropagation();
          if (href) {
            openLinkInBrowser(href);
          }
        }
      }}
    />
  );
}
