import * as React from 'react';
import { CycleStatus } from '../../../../sync/__generated/models';
import { cycleStatusToIcon } from '../../../syncEngine/selectors/cycles';
import { Icon, IconProps, IconSize } from '../icon';
import Pill, { PillStyle } from './pill';
import { MetadataSize } from './size';

export function CycleIcon({
  cycleStatus,
  size,
  ...rest
}: { cycleStatus: CycleStatus; size?: IconSize } & Omit<IconProps, 'icon'>) {
  return <Icon size={size} {...rest} icon={cycleStatusToIcon(cycleStatus)} />;
}

export default function Cycle({
  cycleStatus,
  name,
  size = MetadataSize.Small,
  ghost,
}: {
  cycleStatus: CycleStatus;
  name: string;
  size?: MetadataSize;
  ghost?: boolean;
}) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  return (
    <Pill pillStyle={ghost ? PillStyle.Subtle : PillStyle.Default} size={size}>
      <CycleIcon cycleStatus={cycleStatus} size={iconSize} />
      <span className="ellipsis">{name.replace('Cycle ', '')}</span>
    </Pill>
  );
}
