import cn from 'classnames';
import * as React from 'react';
import { NodeEntry, Node } from 'slate';
import { useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import {
  externalIssueLinkType,
  integrationTypeToExternalIssueType,
  storeExternalIssueByUrl,
} from '../../api/externalIssues';
import { Icon, IconSize } from '../../components/new/icon';
import { IntegrationIcon } from '../../components/new/integrationIcon';
import menuStyles from '../../components/new/menu/menu.module.scss';
import { useOrganization } from '../../contexts/organizationContext';
import Hover from '../hovers';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { HistoryEditor } from '../plugins/history';
import styles from './externalIssueLinkHover.module.scss';

enum EmbedBehavior {
  Mention = 0,
  Embed = 1,
  Link = 2,
}

export function ExternalIssueLinkHover() {
  const [visible, setVisible] = React.useState(true);
  const [embedBehavior, setEmbedBehavior] = React.useState<EmbedBehavior>(EmbedBehavior.Mention);

  const editor = useSlateStatic();
  const organization = useOrganization();

  const url = React.useMemo(() => {
    let parent: NodeEntry<Node> | undefined = KitemakerEditor.above(editor, {
      match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
    });

    if (!parent) {
      // Are we right at the beginning of a text node? Maybe the thing before us is a link
      const selection = safeSelection(editor);
      if (selection?.focus.offset === 0) {
        const previous = KitemakerEditor.previous(editor, {
          at: selection,
          mode: 'all',
          match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
        });
        if (previous) {
          parent = previous;
        }
      }
    }

    if (!parent) {
      return null;
    }

    const [parentNode] = parent;

    if (!KitemakerElement.isElement(parentNode) || parentNode.type !== Elements.Link) {
      return null;
    }

    return parentNode.url;
  }, [editor]);

  const type = React.useMemo(() => {
    if (!url) {
      return null;
    }
    return externalIssueLinkType(url);
  }, [url]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        setVisible(false);
        return true;
      }

      if (e.key === 'ArrowUp' && embedBehavior !== EmbedBehavior.Mention) {
        e.preventDefault();
        e.stopPropagation();
        setEmbedBehavior(embedBehavior - 1);
        return true;
      }

      if (e.key === 'ArrowDown' && embedBehavior !== EmbedBehavior.Link) {
        e.preventDefault();
        e.stopPropagation();
        setEmbedBehavior(embedBehavior + 1);
        return true;
      }

      if (editor.isAccept(e)) {
        e.preventDefault();
        e.stopPropagation();

        let parent: NodeEntry<Node> | undefined = KitemakerEditor.above(editor, {
          match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
        });

        if (!parent) {
          // Are we right at the beginning of a text node? Maybe the thing before us is a link
          const selection = safeSelection(editor);
          if (selection?.focus.offset === 0) {
            const previous = KitemakerEditor.previous(editor, {
              at: selection,
              mode: 'all',
              match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
            });
            if (previous) {
              parent = previous;
            }
          }
        }

        if (!parent) {
          return true;
        }

        const [parentNode, parentPath] = parent;

        if (!KitemakerElement.isElement(parentNode) || parentNode.type !== Elements.Link) {
          return true;
        }

        const url = parentNode.url;
        if (!url) {
          return true;
        }

        const urlType = externalIssueLinkType(url);
        if (!urlType) {
          return true;
        }

        const edges = KitemakerEditor.edges(editor, parentPath);
        HistoryEditor.asBatch(editor, async () => {
          if (embedBehavior === EmbedBehavior.Link) {
            KitemakerTransforms.setNodes(editor, { fromHover: true }, { at: parentPath });
            return;
          }

          const refId = uuid.v4();

          KitemakerTransforms.select(editor, { anchor: edges[0], focus: edges[1] });
          KitemakerTransforms.unwrapNodes(editor, {
            match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
          });

          const nodeType =
            embedBehavior === EmbedBehavior.Mention
              ? Elements.InlineExternalIssueLink
              : Elements.ExternalIssueLink;

          if (embedBehavior === EmbedBehavior.Mention) {
            KitemakerTransforms.insertNodes(editor, {
              type: nodeType,
              externalIssueType: integrationTypeToExternalIssueType(urlType),
              refId,
              children: [{ text: '' }],
            });
          } else if (embedBehavior === EmbedBehavior.Embed) {
            KitemakerTransforms.setBlockElementAndSplitIfNeeded(editor, nodeType, {
              properties: {
                externalIssueType: integrationTypeToExternalIssueType(urlType),
                refId,
              },
            });
          }

          HistoryEditor.asBatch(editor, async () => {
            const externalIssueId = await storeExternalIssueByUrl(organization.id, urlType, url);
            if (externalIssueId) {
              const nodes = Array.from(KitemakerNode.descendants(editor)).filter(
                ([node]) =>
                  KitemakerElement.isElement(node) && node.type === nodeType && node.refId === refId
              );

              for (const [, at] of nodes) {
                KitemakerTransforms.setNodes(editor, { externalIssueId }, { at });
              }
            }
          });
        });
        return true;
      }

      return false;
    },
    [editor, embedBehavior]
  );

  if (!url) {
    return null;
  }

  return (
    <Hover
      hoverId="link-external-issue"
      fixed
      open={visible}
      contentOptions={{
        side: 'top',
      }}
      onOpenChange={setVisible}
      onKeyDown={onKeyDown}
      content={
        <div className={cn(menuStyles.content, styles.externalIssueLinkHover)}>
          <div
            className={cn(menuStyles.item, {
              [styles.selected]: embedBehavior === EmbedBehavior.Mention,
            })}
          >
            {type && (
              <IntegrationIcon integrationType={type} className="mr4 mt2" size={IconSize.Size16} />
            )}
            Insert as mention
          </div>
          <div
            className={cn(menuStyles.item, {
              [styles.selected]: embedBehavior === EmbedBehavior.Embed,
            })}
          >
            {type && (
              <IntegrationIcon integrationType={type} className="mr4 mt2" size={IconSize.Size16} />
            )}
            Insert as embed
          </div>

          <div
            className={cn(menuStyles.item, {
              [styles.selected]: embedBehavior === EmbedBehavior.Link,
            })}
          >
            <Icon icon="link" className="mr4 mt2" size={IconSize.Size16} /> Keep as link
          </div>
        </div>
      }
    ></Hover>
  );
}
