import React from 'react';

const USER_IDLE_TIME = 5000;

export default function useIdleUser(idleRef: React.MutableRefObject<boolean>) {
  const idleTimeout = React.useRef(-1);

  const userInteracted = React.useCallback(() => {
    if (idleTimeout.current !== -1) {
      window.clearTimeout(idleTimeout.current);
    }
    idleRef.current = false;
    idleTimeout.current = window.setTimeout(() => (idleRef.current = true), USER_IDLE_TIME);
  }, []);

  React.useEffect(() => {
    userInteracted();
    window.addEventListener('keydown', userInteracted, { capture: true });
    window.addEventListener('mousemove', userInteracted, { capture: true });
    return () => {
      window.removeEventListener('keydown', userInteracted, { capture: true });
      window.removeEventListener('mousemove', userInteracted, { capture: true });
    };
  }, [userInteracted]);
}
