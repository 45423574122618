import { isEqual } from 'lodash';
import { Editor, Element, Node, NodeEntry, Path, Text, Transforms } from 'slate';
import { KitemakerElement, KitemakerNode } from '../kitemakerNode';
import { CodeElement, Elements, Marks } from '../types';
import { emptyDocument } from '../utils';

function mergeAdjacentFormattedTextBlock(editor: Editor, element: NodeEntry<Element>): boolean {
  const [node, path] = element;
  const previous = KitemakerNode.previousSibling(editor, path);
  if (previous) {
    const [previousNode] = previous;
    if (KitemakerElement.isElement(previousNode) && previousNode.type === node.type) {
      const start = Editor.start(editor, path);
      Editor.withoutNormalizing(editor, () => {
        Transforms.insertText(editor, '\n', { at: start });
        Transforms.mergeNodes(editor, { at: path });
      });
      return true;
    }
  }

  const next = KitemakerNode.nextSibling(editor, path);
  if (next) {
    const [nextNode, nextPath] = next;
    if (KitemakerElement.isElement(nextNode) && nextNode.type === node.type) {
      const end = Editor.end(editor, path);
      Editor.withoutNormalizing(editor, () => {
        Transforms.insertText(editor, '\n', { at: end });
        Transforms.mergeNodes(editor, { at: nextPath });
      });
      return true;
    }
  }

  return false;
}

function normalizeTextBlock(editor: Editor, element: NodeEntry<Element>): boolean {
  const [, path] = element;
  for (const [child, childPath] of Array.from(Node.children(editor, path))) {
    if (Element.isElement(child) && !editor.isInline(child)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }
  }

  return false;
}

function normalizeTopLevelBlocks(editor: Editor, path: Path) {
  const children = Array.from(KitemakerNode.children(editor, path));
  if (
    !children.length ||
    children.every(
      ([node]) => KitemakerElement.isElement(node) && KitemakerElement.isNonInteractive(node)
    )
  ) {
    Transforms.insertNodes(editor, emptyDocument(), {
      at: [children.length],
    });
    Transforms.select(editor, [children.length]);
    return true;
  }
  for (const [child, childPath] of children) {
    if (!KitemakerElement.isElement(child)) {
      continue;
    }
    if (!KitemakerElement.isTopLevelElement(child)) {
      Transforms.setNodes(editor, { type: Elements.Paragraph }, { at: childPath });
      return true;
    }
  }

  return false;
}

function normalizeCodeBlock(editor: Editor, element: NodeEntry<CodeElement>): boolean {
  const [, path] = element;
  for (const [child, childPath] of Array.from(Node.children(editor, path))) {
    if (KitemakerElement.isElement(child) && KitemakerElement.isInline(child)) {
      Transforms.unwrapNodes(editor, { at: childPath });
      return true;
    }

    if (!Text.isText(child)) {
      Transforms.removeNodes(editor, { at: childPath });
      return true;
    }

    const hasMarks = Object.values(Marks).some(m => child[m]);
    if (hasMarks) {
      Transforms.unsetNodes(editor, Object.values(Marks), { at: childPath });
    }
  }
  return false;
}

function normalizeTextNode(editor: Editor, text: NodeEntry<Text>): boolean {
  const [node, path] = text;

  if (node.code && (node.bold || node.italic || node.strikethrough || node.underline)) {
    Transforms.setNodes(
      editor,
      { bold: false, italic: false, underline: false, strikethrough: false },
      { at: path }
    );
    return true;
  }

  return false;
}

function normalizeSmartTodo(editor: Editor, entry: NodeEntry<Element>): boolean {
  const [node, path] = entry;
  if (KitemakerElement.isElement(node) && node.type === Elements.SmartTodo) {
    Transforms.setNodes(editor, { type: Elements.Todo, indent: node.indent }, { at: path });
    return true;
  }
  return false;
}

function normalizeComment(editor: Editor, entry: NodeEntry<Element>): boolean {
  const [node, path] = entry;
  if (KitemakerElement.isElement(node) && node.type === Elements.Comment) {
    if (editor.entityId !== node.entityId) {
      Transforms.unwrapNodes(editor, { at: path });
    }
    return true;
  }
  return false;
}

function normalizeVoidBlock(editor: Editor, entry: NodeEntry<Element>): boolean {
  const [node, path] = entry;
  if (!isEqual(node.children, [{ text: '' }])) {
    Editor.withoutNormalizing(editor, () => {
      const children = Array.from(Node.children(editor, path));
      for (const [, childPath] of children.reverse()) {
        Transforms.removeNodes(editor, { at: childPath, mode: 'lowest', voids: true });
      }
      Transforms.insertNodes(editor, { text: '' }, { at: [...path, 0] });
    });
    return true;
  }

  return false;
}

export function withSchema(editor: Editor) {
  const { normalizeNode, isInline, isVoid } = editor;

  editor.isInline = (element: Element) => {
    return KitemakerElement.isInline(element) || isInline(element);
  };

  editor.isVoid = (element: Element) => {
    return KitemakerElement.isVoid(element) || isVoid(element);
  };

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    const [node, path] = entry;

    if (Editor.isEditor(node) && normalizeTopLevelBlocks(editor, path)) {
      return;
    }

    if (
      KitemakerElement.isElement(node) &&
      node.type === Elements.Code &&
      normalizeCodeBlock(editor, [node, path])
    ) {
      return;
    }

    if (
      KitemakerElement.isElement(node) &&
      KitemakerElement.isFormattedTextBlock(node) &&
      mergeAdjacentFormattedTextBlock(editor, entry as NodeEntry<Element>)
    ) {
      return;
    }

    if (
      KitemakerElement.isElement(node) &&
      KitemakerElement.isTextBlock(node) &&
      normalizeTextBlock(editor, entry as NodeEntry<Element>)
    ) {
      return;
    }

    if (Text.isText(node) && normalizeTextNode(editor, entry as NodeEntry<Text>)) {
      return;
    }

    if (editor.removeSmartTodos && normalizeSmartTodo(editor, entry as NodeEntry<Element>)) {
      return;
    }

    if (editor.removeInvalidComments && normalizeComment(editor, entry as NodeEntry<Element>)) {
      return;
    }

    if (
      KitemakerElement.isElement(node) &&
      !KitemakerElement.isInline(node) &&
      KitemakerElement.isVoid(node) &&
      normalizeVoidBlock(editor, entry as NodeEntry<Element>)
    ) {
      return;
    }

    normalizeNode(entry);
  };

  return editor;
}
