import cn from 'classnames';
import { merge } from 'lodash';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DropResult,
  Droppable,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { useIsSmallScreen } from '../hooks/useResponsiveDesign';
import { Button, ButtonSize, ButtonStyle } from './new/button';
import buttonStyles from './new/button.module.scss';
import { Icon } from './new/icon';
import Popover from './new/popover';
import { Tab } from './new/tabs';
import tabStyles from './new/tabs.module.scss';
import { TooltipIfTruncated } from './new/tooltipIfTruncated';
import styles from './reorderableTabs.module.scss';

const TAB_PADDING = 12;

function AllTabsPopover({
  tabs,
  typeLabel,
  onTabClick,
  onNewClick,
  setPopoverOpen,
  onDragEnd,
}: {
  tabs: Tab[];
  typeLabel: string;
  onTabClick: (tab: Tab) => void;
  onNewClick: () => void;
  setPopoverOpen: (open: boolean) => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const isSmallScreen = useIsSmallScreen();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="reorderablePopover">
        {(provided, _snapshot) => (
          <div
            className={styles.list}
            {...provided.droppableProps}
            ref={r => {
              provided.innerRef(r);
              ref.current = r;
            }}
          >
            {tabs.map((tab, index) => {
              return (
                <Draggable key={tab.id} draggableId={tab.id} index={index}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        onClick={() => {
                          setPopoverOpen(false);
                          onTabClick(tab);
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={styles.popover}
                        style={merge(
                          provided.draggableProps.style,
                          // because the parent is transformed, we need to adjust the position
                          snapshot.isDragging && ref.current
                            ? {
                                left:
                                  (provided.draggableProps.style! as any).left -
                                  ref.current.getBoundingClientRect().left,
                                top:
                                  (provided.draggableProps.style! as any).top -
                                  ref.current.getBoundingClientRect().top,
                                background: 'var(--gray5)',
                              }
                            : {}
                        )}
                      >
                        <Icon className={styles.drag} icon="drag" />
                        {!isSmallScreen && tab.icon && typeof tab.icon === 'string' && (
                          <Icon icon={tab.icon} className="mr8" />
                        )}
                        {!isSmallScreen && tab.icon && typeof tab.icon !== 'string' && tab.icon}
                        <span className={cn({ ellipsis: tab.id != 'all' })}>{tab.name}</span>
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <div className={styles.listFooter}>
        <Button
          buttonStyle={ButtonStyle.BareSubtle}
          icon="add"
          size={ButtonSize.Small}
          onClick={() => {
            setPopoverOpen(false);
            onNewClick();
          }}
        >
          New {typeLabel}
        </Button>
      </div>
    </DragDropContext>
  );
}

function ReorderableTab({
  tab,
  currentTab,
  provided,
  snapshot,
  onTabClick,
}: {
  tab: Tab;
  currentTab: string;
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  onTabClick: (tab: Tab) => void;
}) {
  const smallScreen = useIsSmallScreen();

  return (
    <div className={styles.tabWrapper}>
      <div
        ref={provided?.innerRef ?? undefined}
        {...(provided?.draggableProps ?? {})}
        {...(provided?.dragHandleProps ?? {})}
        style={{
          ...(provided?.draggableProps?.style ?? {}),
          cursor: 'pointer',
          backgroundColor: snapshot?.isDragging ? 'var(--gray4)' : undefined,
          visibility: !snapshot ? 'hidden' : undefined,
        }}
        className={cn(
          'maxWidth',
          tabStyles.tab,
          tabStyles.subtle,
          buttonStyles.button,
          buttonStyles.medium,
          buttonStyles.bareSubtle,
          {
            [tabStyles.active]: tab.id === currentTab,
          }
        )}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onTabClick(tab);
        }}
      >
        {!smallScreen && tab.icon && typeof tab.icon === 'string' && (
          <Icon icon={tab.icon} className="mr8" />
        )}
        {!smallScreen && tab.icon && typeof tab.icon !== 'string' && tab.icon}
        {tab.id === 'all' && <span>{tab.name}</span>}
        {tab.id !== 'all' && <TooltipIfTruncated>{tab.name}</TooltipIfTruncated>}
      </div>
    </div>
  );
}

export function ReorderableTabs({
  currentTab,
  tabs,
  typeLabel,
  staticTabs,
  onTabClick,
  onNewClick,
  onDragEnd,
}: {
  currentTab: string;
  tabs: Tab[];
  typeLabel: string;
  staticTabs: React.ReactNode;
  onTabClick: (tab: Tab) => void;
  onNewClick: () => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
}) {
  const isSmallScreen = useIsSmallScreen();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [overflow, setOverflow] = React.useState<number | null>(null);

  const measure = React.useCallback(() => {
    if (ref.current) {
      for (const [index, node] of ref.current.childNodes.entries()) {
        // We need to figure out if the tabs overflow. We can figure this out by checking the offsetTop of every tab.
        // If they are in the first row, the offsetTop will be 12(ish?). All the wrapped tabs will have an offsetTop of 12 + tab height.
        if ((node as HTMLElement).offsetTop > TAB_PADDING + 1) {
          setOverflow(index);
          return;
        }
      }
      setOverflow(null);
    }
  }, []);

  React.useEffect(() => measure(), [JSON.stringify(tabs.map(tab => [tab.id, tab.name]))]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    measure();
    const resizeObserver = new ResizeObserver(measure);
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect();
  }, [measure]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="inlineReorderableTabs" direction="horizontal">
          {(provided, _snapshot) => (
            <div
              {...provided.droppableProps}
              className={cn(tabStyles.tabs, tabStyles.subtle, styles.reorderableTabs)}
              ref={r => {
                provided.innerRef(r);
                ref.current = r;
              }}
            >
              {staticTabs}
              {tabs.map((tab, index) => {
                if (overflow && index >= overflow) {
                  return (
                    <ReorderableTab
                      onTabClick={onTabClick}
                      currentTab={currentTab}
                      key={tab.id}
                      tab={tab}
                    />
                  );
                }

                return (
                  <Draggable key={tab.id} draggableId={tab.id} index={index}>
                    {(provided, snapshot) => {
                      return (
                        <ReorderableTab
                          onTabClick={onTabClick}
                          currentTab={currentTab}
                          provided={provided}
                          snapshot={snapshot}
                          tab={tab}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isSmallScreen && !overflow && (
        <div
          className={cn(
            'maxWidth',
            tabStyles.tab,
            tabStyles.subtle,
            buttonStyles.button,
            buttonStyles.buttonWithIcon,
            buttonStyles.medium,
            buttonStyles.bareSubtle
          )}
          onClick={() => {
            onNewClick();
          }}
        >
          <Icon icon="add" className={buttonStyles.icon} />
          New {typeLabel}
        </div>
      )}
      {overflow && (
        <Popover
          open={popoverOpen}
          onOpenChange={setPopoverOpen}
          asChild
          content={
            <AllTabsPopover
              typeLabel={typeLabel}
              tabs={tabs}
              onDragEnd={onDragEnd}
              setPopoverOpen={setPopoverOpen}
              onTabClick={onTabClick}
              onNewClick={onNewClick}
            />
          }
        >
          <Button className="mr16" buttonStyle={ButtonStyle.SecondarySubtle} icon="more_vertical">
            All {typeLabel}s
          </Button>
        </Popover>
      )}
    </>
  );
}
