import { isEqual } from 'lodash';
import { selectorFamily, useRecoilCallback, useRecoilValue } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import {
  applicationsByOrganization,
  integrationsByOrganization,
  organizationMembersByOrganization,
} from '../../../sync/__generated/indexes';
import {
  Application,
  Integration,
  MemberRole,
  Organization,
  OrganizationMember,
  Space,
  User,
} from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { equalSelectorFamily } from '../../utils/recoil';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { spaceSelector } from './spaces';
import { sortUsers, UserAndMember } from './users';

export function organizationPath(organization: Organization, additionalPath?: string) {
  const base = `/${organization.slug}`;
  return additionalPath ? `${base}/${additionalPath}` : base;
}

export function settingsPath(organization: Organization, subpath?: string) {
  return organizationPath(organization, `settings${subpath ? `/${subpath}` : ''}`);
}

export function spaceSettingsPath(organization: Organization, space: Space, subpath?: string) {
  return organizationPath(organization, `settings/${space.slug}${subpath ? `/${subpath}` : ''}`);
}

export const organizationsSelector = selectorFamily({
  key: 'Organizations',
  get:
    (organizationId: string | undefined) =>
    ({ get }) => {
      if (!organizationId) {
        return null;
      }
      return get(syncEngineState(organizationId)) as Organization;
    },
});

export const integrationsForOrganizationSelector = selectorFamily({
  key: 'IntegrationsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const integrationIds = get(
        indexKeyState(indexKey(integrationsByOrganization, organizationId))
      );
      return filterNotDeletedNotNull(
        integrationIds.map(
          integrationId => get(syncEngineState(integrationId)) as Integration | null
        )
      );
    },
});

export const applicationsForOrganizationSelector = selectorFamily({
  key: 'ApplicationsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const applicationIds = get(
        indexKeyState(indexKey(applicationsByOrganization, organizationId))
      );
      return filterNotDeletedNotNull(
        applicationIds.map(
          applicationId => get(syncEngineState(applicationId)) as Application | null
        )
      );
    },
});

export const usersWithMemberInfoForOrganizationSelector = selectorFamily({
  key: 'UsersWithMemberInfoForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const membersIds = get(
        indexKeyState(indexKey(organizationMembersByOrganization, organizationId))
      );
      const members = filterNotDeletedNotNull(
        membersIds.map(membersId => get(syncEngineState(membersId)) as OrganizationMember | null)
      );
      return members
        .map(member => {
          const user = get(syncEngineState(member.userId)) as User | null;
          return { member, user };
        })
        .filter(({ user }) => user && !user.deleted) as UserAndMember[];
    },
});

export const organizationMemberCountSelector = equalSelectorFamily({
  key: 'OrganizationMemberCount',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const membersIds = get(
        indexKeyState(indexKey(organizationMembersByOrganization, organizationId))
      );
      const members = filterNotDeletedNotNull(
        membersIds.map(membersId => get(syncEngineState(membersId)) as OrganizationMember | null)
      );

      const numberGuestUsers = members.filter(
        m => !m.invited && !m.deactivated && m.role === MemberRole.Guest
      ).length;
      const numberActiveUsers = members.filter(m => !m.invited && !m.deactivated).length;

      return { numberGuestUsers, numberActiveUsers };
    },
  equals: isEqual,
});

export const activeUsersForOrganizationSelector = selectorFamily({
  key: 'ActiveUsersForOrganization',
  get:
    ({
      organizationId,
      preferSpaceId,
    }: {
      organizationId: string;
      preferSpaceId?: string | null;
    }) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }
      const space = get(spaceSelector(preferSpaceId));
      const usersWithMemberInfo = get(usersWithMemberInfoForOrganizationSelector(organizationId));
      return sortUsers(
        usersWithMemberInfo.filter(({ member }) => !member.deactivated),
        space
      );
    },
});

export const allUsersForOrganizationSelector = selectorFamily({
  key: 'AllUsersForOrganization',
  get:
    ({
      organizationId,
      preferSpaceId,
    }: {
      organizationId: string;
      preferSpaceId?: string | null;
    }) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }

      const space = get(spaceSelector(preferSpaceId));
      const usersWithMemberInfo = get(usersWithMemberInfoForOrganizationSelector(organizationId));
      return sortUsers(usersWithMemberInfo, space);
    },
});

export type CustomEmoji = Organization['customEmojis'][keyof Organization['customEmojis']];

export const customEmojisSelector = selectorFamily({
  key: 'CustomEmojis',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const organization = get(organizationsSelector(organizationId));
      return organization?.customEmojis ?? {};
    },
});

export function useFindMemberByUserId() {
  const organization = useOrganization();
  return useRecoilCallback(({ transact_UNSTABLE }) => (userId: string) => {
    let member: OrganizationMember | null = null;

    transact_UNSTABLE(({ get }) => {
      member = get(syncEngineState(`${organization.id}:${userId}`)) as OrganizationMember | null;
    });

    return member as OrganizationMember | null;
  });
}

export function useCustomEmojis() {
  const organization = useOrganization();
  return useRecoilValue(customEmojisSelector(organization.id));
}
