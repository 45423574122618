import cn from 'classnames';
import * as React from 'react';
import { atomFamily, useRecoilState, useRecoilValue } from 'recoil';
import { useConfiguration } from '../contexts/configurationContext';
import { usePersistentSettings } from '../contexts/usePersistentSettings';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { useIsMediumScreen } from '../hooks/useResponsiveDesign';
import { localStorageEffect } from '../syncEngine/effects';
import { ButtonStyle, IconButton } from './new/button';
import { Tooltip } from './new/tooltip';
import styles from './rightBar.module.scss';

const minWidthStatic = 380;
const maxWidth = 820;

export enum RightSidebarGroup {
  Initiatives = 'initiatives',
  Documents = 'documents',
  Releases = 'releases',
  Default = 'default',
}

const DEFAULT_COLLAPSED = [RightSidebarGroup.Documents];

export const rightSidebarCollapsed = atomFamily<boolean, RightSidebarGroup | null>({
  key: 'RightSidebarCollapsed',
  default: key => DEFAULT_COLLAPSED.includes(key as RightSidebarGroup),
  effects: key => [localStorageEffect(`__rightSidebarCollapsed_${key}`)],
});

export function RightSidebarButton({
  group,
  className,
}: {
  group: RightSidebarGroup;
  className?: string;
}) {
  const [rightSidebarHidden, setRightSidebarHidden] = useRecoilState(rightSidebarCollapsed(group));

  return (
    <Tooltip content={`${rightSidebarHidden ? 'Show' : 'Hide'} sidebar`}>
      <IconButton
        icon="rightbar"
        buttonStyle={ButtonStyle.BareSubtle}
        onClick={() => setRightSidebarHidden(!rightSidebarHidden)}
        className={className}
      />
    </Tooltip>
  );
}

export function RightBar({
  children,
  className,
  collapsibleGroup,
}: {
  children: React.ReactNode;
  className?: string;
  collapsibleGroup?: RightSidebarGroup;
}) {
  const { featureFlags } = useConfiguration();
  const minWidth = featureFlags.FEATURE_TOGGLE_TODO_LIST ? 460 : minWidthStatic;
  const mediumScreen = useIsMediumScreen();

  const collapsed =
    useRecoilValue(rightSidebarCollapsed(collapsibleGroup ?? RightSidebarGroup.Default)) &&
    !mediumScreen;
  const { rightBarWidth, setRightBarWidth } = usePersistentSettings();
  const width = rightBarWidth || Math.max(Math.min(window.outerWidth * 0.35, maxWidth), minWidth);

  const draggingRef = React.useRef(false);

  useComponentDidMount(() => {
    function onMouseMove(e: MouseEvent) {
      if (!draggingRef.current) {
        return;
      }

      const newWidth = Math.max(Math.min(window.outerWidth - e.clientX, maxWidth), minWidth);
      setRightBarWidth(newWidth);
    }

    function onMouseUp() {
      draggingRef.current = false;
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  if (collapsed) {
    return null;
  }

  return (
    <div className={cn(styles.rightBar, className)} style={{ minWidth, width }}>
      <div
        className={styles.dragHandle}
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
          draggingRef.current = true;
        }}
      ></div>
      <div className={styles.contents}>{children}</div>
    </div>
  );
}
