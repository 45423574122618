import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Editor, Path } from 'slate';
import { KitemakerElement } from '../../shared/slate/kitemakerNode';
import { emptyDocument } from '../../shared/slate/utils';
import { CommandGroup } from '../commands';
import { useConfiguration } from '../contexts/configurationContext';
import { useOrganization } from '../contexts/organizationContext';
import { useSearchOnce } from '../contexts/searchContext';
import { useMaybeSpace } from '../contexts/spaceContext';
import { useCurrentUser } from '../contexts/userContext';
import { useModelManager } from '../graphql/modelManager';
import { useIsSmallScreen } from '../hooks/useResponsiveDesign';
import { hoverContext } from '../slate/hovers';
import { smartTodosOnKeyDown } from '../slate/onKeyDownHandlers/smartTodos';
import { entitySuggestionMatcher } from '../slate/plugins/suggestions/entitySuggestionMatcher';
import { useExternalIssueSuggestionMatchers } from '../slate/plugins/suggestions/externalIssueSuggestionMatcher';
import { labelSuggestionMatcher } from '../slate/plugins/suggestions/labelSuggestionMatcher';
import { userSuggestionMatcher } from '../slate/plugins/suggestions/userSuggestionMatcher';
import { withCollaboration } from '../slate/plugins/withCollaboration';
import { useWithSmartTodos } from '../slate/plugins/withSmartTodos';
import { TextArea, TextAreaHandle, TextAreaProps, TextAreaType } from '../slate/textArea';
import { DocumentLike, EditorType, Elements } from '../slate/types';
import { useCreateInitiative } from '../syncEngine/actions/intiatives';
import { useCreateIssue } from '../syncEngine/actions/issues';
import { useCreateLabel } from '../syncEngine/actions/labels';
import { useCreateOrganizationLabel } from '../syncEngine/actions/organizationLabels';
import { useMarkUpdatesReadByKey } from '../syncEngine/actions/updates';
import { collaborativeDocSelector } from '../syncEngine/selectors/collaborativeDoc';
import {
  useRecentEntitiesForOrganization,
  useRecentEntitiesForSpace,
} from '../syncEngine/selectors/entities';
import { useFilterInitiativesForSpace } from '../syncEngine/selectors/intiatives';
import { labelsForSpaceSelector } from '../syncEngine/selectors/labels';
import { orgLabelsForOrganizationSelector } from '../syncEngine/selectors/organizationLabels';
import {
  activeUsersForMaybeSpaceSelector,
  spacesForOrganizationSelector,
} from '../syncEngine/selectors/spaces';
import { useGetTodosForEntity } from '../syncEngine/selectors/todos';
import { getQueryParameter, removeQueryParameter } from '../utils/query';
import { HistoryCheckpoint } from '../utils/revisions';
import { TextAreaChaosMode } from './chaos/textAreaChaosMode';
import { CustomCommand } from './new/customCommand';
import { useDisableKeyNavigation, useEnableKeyNavigation } from './new/keyNavigation';

type CollaborativeDocEditorProps = {
  documentId: string;
  textAreaRef: React.RefObject<TextAreaHandle>;
  disabled?: boolean;
  className?: string;
  textAreaClassName?: string;
  textAreaType?: TextAreaType;
  onBlur?: () => void;
  bottomSpacing?: number;
} & Omit<TextAreaProps, 'initialValue'>;

function CollaborativeEditor({
  documentId,
  textAreaRef,
  disabled,
  className,
  textAreaClassName,
  textAreaType,
  onBlur,
  bottomSpacing,
  entityId,
  ...textAreaProps
}: CollaborativeDocEditorProps) {
  const { production } = useConfiguration();
  const organization = useOrganization();
  const collaborativeDoc = useRecoilValue(collaborativeDocSelector(documentId));

  const space = useMaybeSpace();
  const users = useRecoilValue(
    activeUsersForMaybeSpaceSelector({ organizationId: organization.id, spaceId: space?.id })
  );
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const labels = useRecoilValue(labelsForSpaceSelector(space?.id));
  const orgLabels = useRecoilValue(orgLabelsForOrganizationSelector(organization.id));
  const user = useCurrentUser();
  const search = useSearchOnce();
  const defaultOptionsForSpace = useRecentEntitiesForSpace();
  const defaultOptionsForOrganization = useRecentEntitiesForOrganization();
  const filterInitiatives = useFilterInitiativesForSpace();
  const getTodos = useGetTodosForEntity();

  const history = useHistory();
  const withSmartTodos = useWithSmartTodos(entityId ?? '');
  const modelManager = useModelManager();
  const hovers = React.useContext(hoverContext);
  const createLabel = useCreateLabel();
  const createOrgLabel = useCreateOrganizationLabel();
  const createIssue = useCreateIssue();
  const createInitiative = useCreateInitiative();
  const smallScreen = useIsSmallScreen();
  const markUpdatesRead = useMarkUpdatesReadByKey();
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  const [content, setContent] = React.useState<DocumentLike>(
    collaborativeDoc?.content ?? emptyDocument()
  );

  const labelsref = React.useRef(labels);
  labelsref.current = labels;

  const orgLabelsref = React.useRef(orgLabels);
  orgLabelsref.current = orgLabels;

  React.useEffect(() => {
    markUpdatesRead(documentId);

    if (textAreaRef.current && getQueryParameter(history, 'fromSnippet')) {
      removeQueryParameter(history, 'fromSnippet');
      Editor.normalize(textAreaRef.current.raw(), { force: true });
    }

    return () => {
      enableKeyNav('collaborativeDoc');
    };
  }, [documentId]);

  const autoFocus = React.useMemo(() => {
    const autoFocus = getQueryParameter(history, 'focusDescription');
    if (autoFocus) {
      removeQueryParameter(history, 'focusDescription');
      return true;
    }
    return false;
  }, []);

  const collaborationPlugin = React.useMemo(() => {
    return {
      plugin: withCollaboration(
        modelManager,
        documentId,
        'CollaborativeDoc',
        collaborativeDoc?.version ?? 0,
        {
          enableCursors: true,
        }
      ),
      setEditor: (editor: EditorType) => {
        modelManager.setCollaborativeDocumentEditor(documentId, editor);
        modelManager.checkForMissingChanges();
      },
      unsetEditor: () => modelManager.unsetCollaborativeDocumentEditor(documentId),
    };
  }, [documentId]);

  const autoFocusSmartTodo: Path | null = React.useMemo(() => {
    const focusSmartTodo = getQueryParameter(history, 'focusSmartTodo');
    if (focusSmartTodo) {
      removeQueryParameter(history, 'focusSmartTodo');
      const index = content.findIndex(
        n =>
          KitemakerElement.isElement(n) &&
          n.type === Elements.SmartTodo &&
          n.todoId === focusSmartTodo
      );
      if (index === -1) {
        return null;
      }
      return [index];
    }
    return null;
  }, []);

  const externalIssueMatchers = useExternalIssueSuggestionMatchers(organization.id);

  return (
    <div
      className={cn(className, 'colStretch', 'fs-exclude')}
      onDragOver={e => {
        if (e.dataTransfer?.files?.length && !hovers.isClaimed) {
          textAreaRef.current?.focus();
        }
      }}
    >
      <TextAreaChaosMode textAreaRef={textAreaRef} />
      {!production && (
        <CustomCommand
          command={{
            id: 'dump-doc-contents',
            description: 'Dump document to console',
            group: CommandGroup.Developer,
            handler: () => {
              if (!textAreaRef.current) {
                return;
              }
              // eslint-disable-next-line no-console
              console.log(JSON.stringify(textAreaRef.current?.raw().children, null, 2));
            },
          }}
        />
      )}
      <TextArea
        {...textAreaProps}
        type={textAreaType}
        className={cn('bodyL', 'grow', textAreaClassName)}
        entityId={entityId}
        autoFocus={autoFocus}
        autoFocusPath={autoFocusSmartTodo}
        enableBlockDragging={!smallScreen && !disabled}
        tabIndex={-1}
        richText={true}
        disabled={disabled}
        ref={textAreaRef}
        key={`${documentId}-editor`}
        initialValue={content}
        onChange={v => {
          setContent(v);
        }}
        onBlur={() => {
          enableKeyNav('collaborativeDoc');
          onBlur?.();
        }}
        onFocus={() => {
          disableKeyNav('collaborativeDoc');
        }}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            textAreaRef.current?.blur();
          }

          if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            textAreaRef.current?.blur();
          }
        }}
        bottomMargin={bottomSpacing}
        bottomOffset={bottomSpacing}
        suggestions={[
          ...entitySuggestionMatcher(
            organization,
            user,
            spaces,
            defaultOptionsForSpace,
            defaultOptionsForOrganization,
            search,
            createIssue,
            (title: string) =>
              createInitiative(title, { spaceIds: space ? [space.id] : [] }).initiative,
            initiatives => filterInitiatives(initiatives, space?.id),
            getTodos
          ),
          userSuggestionMatcher(user, users, {
            spaceId: space?.id,
            organizationId: organization.id,
            entityId,
          }),
          labelSuggestionMatcher(
            name => (space ? createLabel(space.id, name) : createOrgLabel(organization.id, name)),
            () => (space ? labelsref.current : orgLabelsref.current),
            { limitToElements: [Elements.SmartTodo] }
          ),
          ...externalIssueMatchers.matchers,
        ]}
        withCollaboration={collaborationPlugin}
        withSmartTodos={{
          plugin: withSmartTodos,
          onKeyDownHandler: (editor, e) => {
            return smartTodosOnKeyDown()(editor, e);
          },
        }}
        additionalInsertMenuChoices={externalIssueMatchers.insertMenuItems}
      />
    </div>
  );
}

export function CollaborativeDocEditor({
  historyDescription,
  textAreaClassName,
  className,
  bottomSpacing = 120,
  ...rest
}: CollaborativeDocEditorProps & { historyDescription: HistoryCheckpoint | null }) {
  if (historyDescription) {
    return (
      <div className={cn(className, 'fs-exclude')}>
        <TextArea
          {...rest}
          className={cn('bodyL', textAreaClassName)}
          tabIndex={-1}
          richText={true}
          disabled
          key={`${historyDescription.id}-history-editor`}
          initialValue={historyDescription.content}
          bottomOffset={bottomSpacing}
          bottomMargin={bottomSpacing}
        />
      </div>
    );
  }

  return (
    <CollaborativeEditor
      className={className}
      textAreaClassName={textAreaClassName}
      bottomSpacing={bottomSpacing}
      {...rest}
    />
  );
}
