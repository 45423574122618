import { Editor, Path, Range, Text, Transforms } from 'slate';
import { KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Marks } from '../../../../shared/slate/types';
import { isNonLetter } from '../../../utils/text';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { Matcher } from './withStringMatching';

function markdownMarkMatcher(editor: EditorType, pattern: string, mark: Marks): Matcher {
  return ({ text, texts, selection }) => {
    if (!text.endsWith(pattern)) {
      return false;
    }
    const prefixIndex = text.lastIndexOf(pattern, text.length - pattern.length - 1);
    if (prefixIndex === -1) {
      return false;
    }

    // we need to check the case where the user has type **foo* - we don't want to recognize this
    // as an italic, we want to wait to see if the user makes it into a bold. So let's see if the thing
    // before it is one of our breaking characters.
    if (prefixIndex > 0 && !isNonLetter(text[prefixIndex - 1], [pattern[0]])) {
      return false;
    }

    const anchor = KitemakerNode.offsetIntoTextNodes(editor, texts, prefixIndex);
    if (!anchor) {
      return false;
    }

    const range = {
      anchor,
      focus: {
        ...selection.focus,
        offset: selection.focus.offset,
      },
    };
    const start = Range.start(range);
    const end = Range.end(range);

    if (
      Path.equals(start.path, end.path) &&
      end.offset - pattern.length <= start.offset + pattern.length
    ) {
      return false;
    }

    const extractRangeStart = Range.start(range);
    const extractRangeEnd = Range.end(range);
    HistoryEditor.asBatch(editor, () => {
      const rangeRef = Editor.rangeRef(editor, range);

      Transforms.delete(editor, {
        at: {
          anchor: { ...extractRangeEnd, offset: extractRangeEnd.offset - pattern.length },
          focus: extractRangeEnd,
        },
      });
      Transforms.delete(editor, {
        at: {
          anchor: extractRangeStart,
          focus: { ...extractRangeStart, offset: extractRangeStart.offset + pattern.length },
        },
      });

      Transforms.setNodes(
        editor,
        { [mark]: true },
        {
          match: n => Text.isText(n),
          split: true,
          at: rangeRef.current!,
        }
      );
      editor.removeMark(mark);
    });
    return true;
  };
}

export function markdownMarkMatchers(editor: EditorType): Matcher[] {
  return [
    markdownMarkMatcher(editor, '**', Marks.Bold),
    markdownMarkMatcher(editor, '__', Marks.Bold),
    markdownMarkMatcher(editor, '*', Marks.Italic),
    markdownMarkMatcher(editor, '_', Marks.Italic),
    markdownMarkMatcher(editor, '~', Marks.Strikethrough),
    markdownMarkMatcher(editor, '`', Marks.Code),
  ];
}
