import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import {
  impactLevelsForOrganizationSelector,
  impactLevelsForSpaceSelector,
} from '../../../syncEngine/selectors/impactLevels';
import { DropdownMenuCheckboxItem } from './dropdownMenu';
import styles from './menu.module.scss';

export function ImpactMenu({
  impactId,
  onSelect,
  forceOrganizationLevel,
}: {
  impactId?: string | null;
  onSelect: (impactId: string | null) => void;
  forceOrganizationLevel?: boolean;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const spaceImpactLevels = useRecoilValue(impactLevelsForSpaceSelector(space?.id));
  const orgImpacLevels = useRecoilValue(
    impactLevelsForOrganizationSelector(organization.id)
  ).filter(i => !i.spaceId);
  const impactLevels = space && !forceOrganizationLevel ? spaceImpactLevels : orgImpacLevels;
  const twoCharacters = impactLevels.some(l => l.abbrevation.length === 2);

  return (
    <>
      <DropdownMenuCheckboxItem
        onSelect={() => onSelect(null)}
        checked={impactId === null}
        shortcut="0"
      >
        None
      </DropdownMenuCheckboxItem>
      {impactLevels.map((impact, index) => (
        <DropdownMenuCheckboxItem
          checked={impactId === impact.id}
          key={impact.id}
          shortcut={index < 9 ? `${index + 1}` : undefined}
          onSelect={() => onSelect(impact.id)}
        >
          <span
            className={cn(styles.abbreviation, { [styles.twoCharacters]: twoCharacters })}
            style={{ color: mapColor(impact.color) }}
          >
            {impact.abbrevation}
          </span>
          {impact.name}
        </DropdownMenuCheckboxItem>
      ))}
    </>
  );
}
