import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { notNull } from '../../shared/utils/convenience';
import { Space } from '../../sync/__generated/models';
import { spaceSelector } from '../syncEngine/selectors/spaces';

const spaceContext = React.createContext<string | null>(null);

export function SpaceProvider({
  spaceId,
  children,
}: {
  spaceId: string | null;
  children: React.ReactNode;
}) {
  return <spaceContext.Provider value={spaceId}>{children}</spaceContext.Provider>;
}

export function useSpace(): Space {
  const spaceId = React.useContext(spaceContext)!;
  const space = useRecoilValue(spaceSelector(spaceId));
  return notNull(space);
}

export function useMaybeSpace(): Space | null {
  const spaceId = React.useContext(spaceContext);
  const space = useRecoilValue(spaceSelector(spaceId));
  return space;
}

export function useMaybeCurrentSpaceId(): string | null {
  return React.useContext(spaceContext);
}
