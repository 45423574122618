import {
  CreateExternalIssueRequest,
  ExternalIssueCreationContext,
  LINEAR_LINK_REGEX,
} from '../../shared/externalIssues';
import { ExternalIssue, IntegrationType } from '../../sync/__generated/models';
import { toast } from '../components/toast';
import { ExternalIssueType } from '../slate/types';
import { stringifyIntegrationType } from '../utils/integrations';

export const EXTERNAL_ISSUE_INTEGRATION_TYPES = [IntegrationType.Linear];

export function integrationTypeToExternalIssueType(
  integrationType: IntegrationType
): ExternalIssueType {
  if (integrationType === IntegrationType.Linear) {
    return 'linear';
  }
  throw Error(`Unknown integration type: ${integrationType}`);
}

export function normalizeExternalIssueLink(maybeLinearLink: string): string | null {
  if (!maybeLinearLink.trim().match(LINEAR_LINK_REGEX)) {
    return null;
  }
  return maybeLinearLink.startsWith('https://') ? maybeLinearLink : `https://${maybeLinearLink}`;
}

export function externalIssueLinkType(link: string): IntegrationType | null {
  if (link.match(LINEAR_LINK_REGEX)) {
    return IntegrationType.Linear;
  }
  return null;
}

export async function storeExternalIssueByUrl(
  organizationId: string,
  type: IntegrationType,
  url: string
): Promise<string | null> {
  try {
    const resp = await fetch('/integrations/externalIssues/storeByUrl', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organizationId,
        type,
        url,
      }),
    });

    const respJson = await resp.json();
    if (respJson.errors || !respJson.data) {
      toast.error(
        respJson.errors?.length
          ? respJson.errors[0].message
          : `An unknown error occurred while importing the ${stringifyIntegrationType(type)} issue`
      );
      return null;
    }
    return respJson.data.id;
  } catch (e) {
    toast.error(
      `An unknown error occurred while importing the ${stringifyIntegrationType(type)} issue`
    );
    return null;
  }
}

export async function storeExternalIssueBExternalId(
  organizationId: string,
  type: IntegrationType,
  externalId: string
): Promise<string | null> {
  try {
    const resp = await fetch('/integrations/externalIssues/storeById', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organizationId,
        type,
        externalId,
      }),
    });

    const respJson = await resp.json();
    if (respJson.errors || !respJson.data) {
      toast.error(
        respJson.errors?.length
          ? respJson.errors[0].message
          : `An unknown error occurred while importing the ${stringifyIntegrationType(type)} issue`
      );
      return null;
    }
    return respJson.data.id;
  } catch (e) {
    toast.error(
      `An unknown error occurred while importing the ${stringifyIntegrationType(type)} issue`
    );
    return null;
  }
}

export async function searchExternalIssues(
  organizationId: string,
  type: IntegrationType,
  query: string
): Promise<Array<Partial<ExternalIssue>>> {
  try {
    const resp = await fetch(
      `/integrations/externalIssues/search?query=${encodeURIComponent(
        query
      )}&organizationId=${organizationId}&type=${type.toLowerCase()}`,
      {
        credentials: 'same-origin',
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }
    );

    const respJson = await resp.json();
    if (respJson.errors || !respJson.data) {
      toast.error(
        respJson.errors?.length
          ? respJson.errors[0].message
          : `An unknown error occurred while searching for the ${stringifyIntegrationType(
              type
            )} issue`
      );
      return [];
    }
    return respJson.data;
  } catch (e) {
    toast.error(
      `An unknown error occurred while searching for the ${stringifyIntegrationType(type)} issue`
    );
    return [];
  }
}

export async function fetchCreationContext(
  organizationId: string,
  type: IntegrationType
): Promise<ExternalIssueCreationContext | null> {
  try {
    const resp = await fetch(
      `/integrations/externalIssues/creationContext?organizationId=${organizationId}&type=${type.toLowerCase()}`,
      {
        credentials: 'same-origin',
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }
    );

    const respJson = await resp.json();
    if (respJson.errors || !respJson.data) {
      toast.error(
        respJson.errors?.length
          ? respJson.errors[0].message
          : `An unknown error occurred while fetching info for the ${stringifyIntegrationType(
              type
            )} integration`
      );
      return null;
    }
    return respJson.data;
  } catch (e) {
    toast.error(
      `An unknown error occurred while fetching info for the ${stringifyIntegrationType(
        type
      )} integration`
    );
    return null;
  }
}

export async function createExternalIssue(
  organizationId: string,
  type: IntegrationType,
  create: CreateExternalIssueRequest
): Promise<string | null> {
  try {
    const resp = await fetch('/integrations/externalIssues/create', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        organizationId,
        type,
        ...create,
      }),
    });

    const respJson = await resp.json();
    if (respJson.errors || !respJson.data) {
      toast.error(
        respJson.errors?.length
          ? respJson.errors[0].message
          : `An unknown error occurred while creating the ${stringifyIntegrationType(type)} issue`
      );
      return null;
    }
    return respJson.data.id;
  } catch (e) {
    toast.error(
      `An unknown error occurred while creating the ${stringifyIntegrationType(type)} issue`
    );
    return null;
  }
}
