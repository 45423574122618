import { chain } from 'lodash';
import { between } from '../../../shared/utils/sorting';
import { boardColumnsByBoard, boardsBySpace } from '../../../sync/__generated/indexes';
import { Board, BoardColumn, IssueStatus, IssueStatusType } from '../../../sync/__generated/models';
import {
  SyncEngineCreate,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { indexHelper } from './helpers';
import { createStatusHelper } from './issueStatuses';

export const issueStatusBoundaries: Record<IssueStatusType, { after: string; before: string }> = {
  [IssueStatusType.Backlog]: { after: 'a', before: 'f' },
  [IssueStatusType.Todo]: { after: 'f', before: 'l' },
  [IssueStatusType.InProgress]: { after: 'l', before: 'r' },
  [IssueStatusType.Done]: { after: 'r', before: 'x' },
  [IssueStatusType.Archived]: { after: 'x', before: 'zzzzzzzzzzzzzzzzzz' },
};

export function createColumnHelper(
  tx: SyncEngineTransaction,
  boardId: string,
  statusId: string,
  sort: string
) {
  const column: SyncEngineCreate<BoardColumn> = {
    __typename: 'BoardColumn',
    boardId,
    statusId,
    sort,
  };
  return tx.create(column);
}

export function createBoardHelper(
  tx: SyncEngineTransaction,
  spaceId: string,
  name: string,
  key: string,
  sort: string,
  defaultStatusType: IssueStatusType,
  statuses: IssueStatus[]
) {
  const board: SyncEngineCreate<Board> = {
    __typename: 'Board',
    spaceId,
    name,
    key,
    sort,
    shared: false,
    sharedMetadata: false,
    sharedWorkItems: false,
    defaultStatusType,
  };

  const boardResult = tx.create(board);

  chain(statuses)
    .groupBy(s => s.statusType)

    .forEach((statusesOfType, statusType) => {
      const before = issueStatusBoundaries[statusType as IssueStatusType].before;
      let columnSort = between(issueStatusBoundaries[statusType as IssueStatusType]);

      for (const status of statusesOfType) {
        createColumnHelper(tx, boardResult.id, status.id, columnSort);
        columnSort = between({ after: columnSort, before });
      }
    })
    .value();

  return boardResult;
}

export function useCreateColumn() {
  const modelManager = useModelManager();
  return (
    boardId: string,
    statusName: string,
    statusType: IssueStatusType,
    providedSort?: string,
    issueLimit?: number
  ) => {
    return modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const board = get<Board>(boardId);
      if (!board) {
        return null;
      }
      const status = createStatusHelper(tx, board.spaceId, statusName, statusType, issueLimit);
      let sort = providedSort;

      if (!sort) {
        const boardColumns = indexHelper<BoardColumn>(getters, boardColumnsByBoard, boardId);
        sort = between({ after: boardColumns.pop()?.sort });
      }

      const boardColumn = createColumnHelper(tx, boardId, status.id, sort);

      if (status.statusType === IssueStatusType.Todo) {
        const otherBoards = indexHelper<Board>(getters, boardsBySpace, board.spaceId);
        otherBoards
          .filter(b => b.id !== boardId)
          .forEach(b => {
            createColumnHelper(tx, b.id, status.id, sort!);
          });
      }
      return { status, boardColumn };
    });
  };
}

export function useUpdateBoards() {
  const modelManager = useModelManager();
  return (boardIds: string[], update: Omit<SyncEngineUpdateWithoutDelete<Board>, 'sort'>) => {
    modelManager.transaction(tx => {
      for (const boardId of boardIds) {
        tx.update<Board>(boardId, update);
      }
    });
  };
}

export function useUpdateColumn() {
  const modelManager = useModelManager();
  return (columnIds: string[], update: SyncEngineUpdateWithoutDelete<BoardColumn>) => {
    modelManager.transaction(tx => {
      for (const columnId of columnIds) {
        tx.update<BoardColumn>(columnId, update);
      }
    });
  };
}
