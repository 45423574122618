import { randomString } from '../../../shared/utils/random';
import { between } from '../../../shared/utils/sorting';
import { viewsByOrganization } from '../../../sync/__generated/indexes';
import { View, ViewType, ViewVisibility } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { SyncEngineCreate, SyncEngineUpdate, useModelManager } from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { FilterChain } from '../../utils/filtering2';
import { indexHelper, updateSortableSorts } from './helpers';

export function useCreateView() {
  const modelManager = useModelManager();
  const organization = useOrganization();
  const user = useCurrentUser();

  return (name: string, filters: FilterChain, color = 'gray'): View => {
    return modelManager.transaction((tx, getters) => {
      const views = indexHelper<View>(getters, viewsByOrganization, organization.id);
      const sort = between({ after: views[views.length - 1]?.sort });
      const view: SyncEngineCreate<View> = {
        __typename: 'View',
        name,
        filters,
        color,
        sort,
        organizationId: organization.id,
        actorId: user.id,
        viewType: ViewType.Feedback,
        visibility: ViewVisibility.Public,
      };

      return tx.create(view);
    });
  };
}

export function useUpdateViews() {
  const modelManager = useModelManager();
  return (viewIds: string[], update: SyncEngineUpdate<View>) => {
    modelManager.transaction(tx => {
      for (const viewId of viewIds) {
        tx.update<View>(viewId, update);
      }
    });
  };
}

export function useDeleteViews() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (viewIds: string[], force?: boolean) => {
    if (!force) {
      const confirmed = await confirm(
        `Delete view${viewIds.length > 1 ? 's' : ''}`,
        `Are you sure you want to delete ${
          viewIds.length > 1 ? 'these views' : 'this view'
        }? There is no way to undo this operation`,
        {
          label: 'Delete',
          destructive: true,
        }
      );

      if (!confirmed) {
        return [];
      }
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get }) => {
      for (const viewId of viewIds) {
        const view = get<View>(viewId);
        if (!view || view.deleted) {
          continue;
        }

        tx.update<View>(viewId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });

        deleted.push(viewId);
        trackerEvent('View Deleted', {
          id: view.id,
          name: view.name,
        });
      }
    });

    return deleted;
  };
}

export function useUpdateViewSorts() {
  const modelManager = useModelManager();

  return (viewId: string, afterViewId?: string, beforeViewId?: string) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;
      const view = get<View>(viewId);
      const organizationId = view?.organizationId;

      if (!organizationId || !view) {
        return;
      }

      const views = indexHelper<View>(getters, viewsByOrganization, organizationId);

      const updates = updateSortableSorts(views, [viewId], afterViewId, beforeViewId);
      for (const viewId in updates) {
        tx.update<View>(viewId, {
          sort: updates[viewId],
        });
      }
    });
  };
}
