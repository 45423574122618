import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Editor, Element, Range } from 'slate';
import { ReactEditor, useSelected, useSlateStatic } from 'slate-react';
import { safeSelection } from '../../../shared/slate/utils';
import { useConfirmation } from '../../contexts/confirmationContext';
import { InlineCommentThreadContents } from '../../slate/elements/comment';
import Hover from '../../slate/hovers';
import { CommentMode } from '../../slate/hovers/formatHover';
import { KitemakerEditor } from '../../slate/kitemakerEditor';
import { KitemakerTransforms } from '../../slate/kitemakerTransforms';
import { useOnKeyDownHandler } from '../../slate/onKeyDownHandlers/useKeyDownHandler';
import { VoidElement } from '../../slate/types';
import { commentSelector } from '../../syncEngine/selectors/comments';
import { useCurrentInlineComment, useFindSmallestCommentAtLocation } from '../../utils/comments';
import { mainComboKey } from '../../utils/config';
import { isCommentHotkey } from '../../utils/keyEvents';
import { ZIndexContext } from '../modal';
import { ButtonSize, ButtonStyle, IconButton } from './button';
import styles from './commentButton.module.scss';
import { KeyboardShortcut } from './keyboardShortcut';
import { Tooltip } from './tooltip';

export function CommentButton({
  element,
  className,
  style,
  buttonStyle,
}: {
  element: VoidElement;
  className?: string;
  style?: React.CSSProperties;
  buttonStyle?: ButtonStyle;
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const hasComments = !!element.annotations?.some(a => a.type === 'comment');
  const selected = useSelected();
  const editor = useSlateStatic();
  const [restoreSelection, setRestoreSelection] = React.useState<Range | null>(null);
  const [hoverOpen, setHoverOpen] = React.useState(false);
  const [commentId, setCommentId] = React.useState<string | null>(null);

  const findSmallestComment = useFindSmallestCommentAtLocation();
  const { isOpen: confirmationOpen } = useConfirmation();

  const storeFakeSelection = React.useCallback(() => {
    const selection = safeSelection(editor);
    editor.setFakeSelection(selection);
    setRestoreSelection(selection);
  }, []);

  const comment = useRecoilValue(commentSelector(commentId ?? ''));
  const onComment = React.useCallback(
    (x: number, y: number) => {
      if (!hasComments) {
        if (!selected) {
          const path = ReactEditor.findPath(editor, element as Element);
          if (KitemakerEditor.hasPath(editor, path)) {
            KitemakerTransforms.select(editor, path);
          }
        }
        storeFakeSelection();
        setCommentId(null);
        setHoverOpen(true);
      } else {
        const smallestCommentId = findSmallestComment(x, y);
        if (smallestCommentId) {
          setCommentId(smallestCommentId);
          setHoverOpen(true);
        }
      }
    },
    [editor, element, hasComments, selected, storeFakeSelection]
  );

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      const selection = safeSelection(editor);

      // if there's a text selection, we'll let the format hover handle the hotkey
      const hasTextSelection =
        selection && !Range.isCollapsed(selection) && !!Editor.string(editor, selection).length;
      if (hasTextSelection) {
        return false;
      }

      if (isCommentHotkey(e) && ref.current) {
        e.preventDefault();
        e.stopPropagation();
        const rect = ref.current.getBoundingClientRect();
        onComment(rect.x, rect.y);
        return true;
      }

      return false;
    },
    [onComment, editor]
  );

  useOnKeyDownHandler(onKeyDown);

  useCurrentInlineComment(commentId ?? '', hoverOpen);

  const content = (
    <Tooltip
      content={
        <>
          Comment <KeyboardShortcut shortcut={`${mainComboKey}+shift+m`} />
        </>
      }
    >
      <IconButton
        className={cn(
          {
            [styles.existingComment]: hasComments,
          },
          className
        )}
        style={style}
        size={ButtonSize.Small}
        buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onComment(e.clientX, e.clientY);
        }}
        icon="chat"
      />
    </Tooltip>
  );

  return (
    <div ref={ref}>
      <Hover
        fixed={!!restoreSelection}
        contentOptions={{
          side: 'top',
          onFocusOutside: e => {
            e.preventDefault();
          },
          onInteractOutside: e => {
            if (confirmationOpen) {
              e.preventDefault();
            }
          },
        }}
        content={
          commentId ? (
            <ZIndexContext.Provider value={300}>
              {comment && (
                <InlineCommentThreadContents comment={comment} onClose={() => setHoverOpen(true)} />
              )}
            </ZIndexContext.Provider>
          ) : (
            <div
              className={styles.hover}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <CommentMode
                onCommentCreated={commentId => {
                  if (restoreSelection) {
                    KitemakerTransforms.select(editor, restoreSelection);
                    if (commentId) {
                      KitemakerTransforms.addComemnt(editor, commentId);
                    }
                  }
                  ReactEditor.focus(editor);
                  setCommentId(null);
                  setHoverOpen(false);
                }}
              />
            </div>
          )
        }
        hoverId="void-comment"
        onOpenChange={setHoverOpen}
        open={hoverOpen}
      >
        <div>{content}</div>
      </Hover>
    </div>
  );
}
