import { integrationUserIntegrationusers } from '../../../sync/__generated/collections';
import { IntegrationUser } from '../../../sync/__generated/models';
import { useCurrentUser } from '../../contexts/userContext';
import { useModelManager } from '../../graphql/modelManager';

export function useDisconnectIntegrationUser() {
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (integrationUserId: string) => {
    return modelManager.transaction((tx, { get }) => {
      const integrationUser = get<IntegrationUser>(integrationUserId);
      if (!integrationUser || integrationUser.deleted) {
        return;
      }
      tx.removeFromCollection(integrationUserIntegrationusers, integrationUserId, [user.id]);
    });
  };
}
