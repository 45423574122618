import { sampleSize } from 'lodash';

export function randomString(length: number): string {
  const chars = 'abcdefhijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
  return sampleSize(chars, length).join('');
}

export function perfectShuffle<T>(deck: T[], times: number): T[] {
  function doShuffle<T>(d: T[]): T[] {
    const shuffled: T[] = [];
    const half = Math.floor(d.length / 2);
    for (let i = 0; i < half; i++) {
      shuffled.push(d[i]);
      shuffled.push(d[i + half]);
    }

    if (d.length % 2 === 1) {
      shuffled.push(d[d.length - 1]);
    }

    return [...shuffled.slice(half), ...shuffled.slice(0, half)];
  }

  let result = deck;
  for (let i = 0; i < times; i++) {
    result = doShuffle(result);
  }
  return result;
}
