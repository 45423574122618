import isURL from 'validator/lib/isURL';
import { KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { Matcher } from './withStringMatching';

const minLinkLength = '[a](http://a.a)'.length;

export function markdownLinkMatcher(editor: EditorType): Matcher {
  return ({ text: text, texts }) => {
    if (text.length < minLinkLength) {
      return false;
    }

    const lastParenIndex = text.length - 1;
    if (text[lastParenIndex] !== ')') {
      return false;
    }

    const prevParenIndex = text.lastIndexOf('(', lastParenIndex - 1);

    // if there isn't room for the [] before it, just bail
    if (prevParenIndex < 2) {
      return false;
    }

    const maybeUrl = text.substring(prevParenIndex + 1, text.length - 1);

    if (!isURL(maybeUrl)) {
      return false;
    }

    const lastBracketIndex = prevParenIndex - 1;
    if (text[lastBracketIndex] !== ']') {
      return false;
    }

    const prevBracketIndex = text.lastIndexOf('[', lastBracketIndex - 1);
    if (prevParenIndex < 0) {
      return false;
    }

    const linkText = text.substring(prevBracketIndex + 1, lastBracketIndex);
    if (!linkText.length) {
      return false;
    }

    const anchor = KitemakerNode.offsetIntoTextNodes(editor, texts, prevBracketIndex);
    const focus = KitemakerNode.offsetIntoTextNodes(editor, texts, lastParenIndex + 1);
    if (!anchor || !focus) {
      return false;
    }

    HistoryEditor.asBatch(editor, () => {
      KitemakerTransforms.delete(editor, { at: { anchor, focus } });
      KitemakerTransforms.insertNodes(
        editor,
        [{ type: Elements.Link, url: maybeUrl, children: [{ text: linkText }] }, { text: '' }],
        {
          at: {
            anchor,
            focus: anchor,
          },
          mode: 'lowest',
          select: true,
        }
      );
    });

    return true;
  };
}
