import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { CommandGroup } from '../../../commands';
import { boardMetadataConfig } from '../../../components/metadataConfig';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { Hotkey } from '../../../components/new/hotkey';
import {
  EditInitiativeListItem,
  InitiativeListItem,
} from '../../../components/new/initiativeListItem';
import {
  KeyNavigationProvider,
  useKeyNavigationState,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useNewEntityModalArgs } from '../../../modals/newEntityModal/newEntityModal';
import { initiativesSelector } from '../../../syncEngine/selectors/intiatives';
import { createNewIssueKey } from '../../../utils/config';
import { filterPropertiesSelector } from '../../../utils/filtering2';
import { Placeholder } from '../workItemBoardScreen/placeholder';
import styles from './roadmapsScreen.module.scss';

function Context() {
  const { focused, selected } = useKeyNavigationState();
  const initiativeIds = (selected ?? (focused ? [focused] : []))
    .map(id => id.replace('cycle-', ''))
    .filter(id => !id.includes('-'));

  return (
    <CommandContext
      context={{
        group: CommandGroup.Entities,
        entityIds: initiativeIds,
        focusedEntityId: focused,
      }}
    />
  );
}

function ListItem({
  id,
  className,
  boardId,
  siblingEntities,
  onEdit,
  onEditComplete,
  moveToTopBottom,
}: {
  id: string;
  className: string;
  boardId: string;
  siblingEntities: string[];
  onEdit?: () => void;
  onEditComplete?: () => void;
  moveToTopBottom: (direction: 'top' | 'bottom', ids: string[]) => void;
}) {
  const metadataConfig = useRecoilValue(boardMetadataConfig(boardId));

  if (onEditComplete) {
    return (
      <EditInitiativeListItem
        metadataConfig={metadataConfig}
        id={id}
        onDone={onEditComplete}
        className={className}
      />
    );
  }
  return (
    <InitiativeListItem
      metadataConfig={metadataConfig}
      onChangeTitle={onEdit}
      id={id}
      className={className}
      routingState={{ siblingEntities }}
      moveToTopBottom={moveToTopBottom}
    />
  );
}

function NewEntityProps({
  onCreated,
  filterId,
}: {
  filterId: string;
  onCreated?: (entityId: string) => void;
}) {
  const setFocus = useSetKeyNavigationFocus();
  const props = useRecoilValue(filterPropertiesSelector(filterId));

  useNewEntityModalArgs({
    ...props,
    type: 'Initiative',
    onCreated: entityId => {
      if (entityId) {
        onCreated?.(entityId);
        setFocus(entityId);
      }
    },
  });

  return null;
}

export function InitiativesList({
  initiativeIds,
  boardId,
  onMove,
  onCreated,
}: {
  initiativeIds: string[];
  boardId: string;
  onMove?: (ids: string[], index: number) => void;
  onCreated?: (entityId: string) => void;
}) {
  const modals = useModals();
  const initiatives = useRecoilValue(initiativesSelector(initiativeIds));
  const copyEntities = useCopyEntitiesToClipboard();

  const moveToTopBottom = React.useCallback(
    (direction: 'top' | 'bottom', ids: string[]) => {
      const index = direction === 'top' ? 0 : initiativeIds.length - 1;
      onMove?.(ids, index);
    },
    [initiativeIds, onMove]
  );

  const renderItem = React.useCallback(
    (
      id: string,
      _statusId: string,
      isFirst: boolean,
      isLast: boolean,
      edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => {
      return (
        <ListItem
          boardId={boardId}
          id={id}
          className={cn('listItem', {
            first: isFirst,
            last: isLast,
          })}
          onEdit={edit?.start}
          onEditComplete={edit?.end}
          moveToTopBottom={moveToTopBottom}
          siblingEntities={initiativeIds}
        />
      );
    },
    [boardId]
  );

  return (
    <KeyNavigationProvider
      columnIds={['all']}
      disableEnsureVisible
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <Context />
      <NewEntityProps filterId={boardId} onCreated={onCreated} />
      {initiativeIds.length > 0 && (
        <VirtualizedListView
          className={styles.list}
          id={'all'}
          sectionIds={['all']}
          itemIds={{ all: initiatives.map(i => i.id) }}
          sectionHeaderHeight={0}
          itemHeight={41}
          spacerHeight={32}
          renderSectionHeader={() => null}
          renderItem={renderItem}
          renderPlaceholder={columnId => {
            return <Placeholder type="initiative" statusId={columnId} list />;
          }}
          onCopy={copyEntities}
          onMoveItems={
            onMove
              ? (ids, _section, index) => {
                  onMove(ids, index);
                }
              : undefined
          }
        />
      )}

      <Hotkey
        hotkey={createNewIssueKey}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          modals.openModal(Modals.NewEntity);
        }}
      />
    </KeyNavigationProvider>
  );
}
