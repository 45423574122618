import { partition, uniq } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import { useRecoilValue } from 'recoil';
import { filterNotNull } from '../../../../shared/utils/convenience';
import { issueTerm } from '../../../../shared/utils/terms';
import LinkButton from '../../../components/linkButton';
import { Button, ButtonStyle } from '../../../components/new/button';
import { Icon } from '../../../components/new/icon';
import { ListViewItem } from '../../../components/new/listView';
import {
  Setting,
  SettingsPage,
  SettingsSection,
  SettingsTextInput,
} from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { Tooltip } from '../../../components/new/tooltip';
import SelectButton from '../../../components/selectButton';
import { useConfirmation } from '../../../contexts/confirmationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { useCurrentUser } from '../../../contexts/userContext';
import {
  useDeleteRoadmapInitiatives,
  useRemoveSpacesFromInitiatives,
} from '../../../syncEngine/actions/intiatives';
import { useUpdateOrganizationMembers } from '../../../syncEngine/actions/organizationMember';
import {
  useDeleteSpaces,
  useSetSpaceRoadmap,
  useUpdateSpaces,
} from '../../../syncEngine/actions/spaces';
import { useFindSharedInitiatives } from '../../../syncEngine/selectors/intiatives';
import {
  organizationPath,
  usersWithMemberInfoForOrganizationSelector,
} from '../../../syncEngine/selectors/organizations';
import {
  publicRoadmapsForOrganizationSelector,
  roadmapsForSpaceSelector,
  useFindRoadmapInitiativesFromOutsideSpace,
  useFindSharedRoadmaps,
} from '../../../syncEngine/selectors/roadmaps';
import { spacesForOrganizationSelector } from '../../../syncEngine/selectors/spaces';
import { RoadmapIcon } from '../newRoadmapScreen/roadmapIcon';

export function SpaceGeneralSettingsScreen() {
  const space = useSpace();
  const { confirm } = useConfirmation();

  const organization = useOrganization();
  const updateSpaces = useUpdateSpaces();
  const updateMember = useUpdateOrganizationMembers();
  const user = useCurrentUser();
  const findSharedRoadmaps = useFindSharedRoadmaps();
  const findSharedInitiatives = useFindSharedInitiatives();
  const findRoadmapInitiativesFromOutsideSpace = useFindRoadmapInitiativesFromOutsideSpace();
  const roadmaps = useRecoilValue(publicRoadmapsForOrganizationSelector(organization.id));
  const spaceRoadmap = useRecoilValue(roadmapsForSpaceSelector(space.id))[0];

  const setSpaceRoadmap = useSetSpaceRoadmap();
  const removeSpacesFromInitiatives = useRemoveSpacesFromInitiatives();
  const deleteRoadmapInitiatives = useDeleteRoadmapInitiatives();

  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const spaceKeys = spaces.map(s => s.key);

  const allMembers = useRecoilValue(usersWithMemberInfoForOrganizationSelector(organization.id));
  const paidPlan = !!organization.activeProductId;

  const deleteSpaces = useDeleteSpaces();
  const history = useHistory();

  const { options } = useTimezoneSelect({ timezones: allTimezones, labelStyle: 'abbrev' });

  const timezoneOptions = options.map(v => ({
    ...v,
    id: v.value,
    contents: v.label,
  }));

  const roadmapValues = roadmaps.map(r => ({
    ...r,
    contents: (
      <div className="row gap8">
        <RoadmapIcon color={r.color} />
        <span className="ellipsis">{r.name}</span>
      </div>
    ),
  })) as ListViewItem[];

  if (spaceRoadmap) {
    roadmapValues.unshift({ id: 'none', contents: 'No roadmap' });
  }

  const privateSwitch = (
    <Switch
      disabled={!paidPlan && !space.private}
      checked={space.private}
      onChange={async checked => {
        if (checked) {
          // PRIVATE-INITIATIVES-AND-ROADMAPS
          // if we're going private, remove shared roadmaps and initiatives
          const sharedRoadmaps = findSharedRoadmaps(space.id);
          const sharedInitiatives = findSharedInitiatives(space.id);
          const outsideInitiatives = findRoadmapInitiativesFromOutsideSpace(space.id);

          if (sharedRoadmaps.length || sharedInitiatives.length || outsideInitiatives.length) {
            const confirmed = await confirm(
              'Remove shared initiatives/roadmaps',
              `Roadmaps and initiatives that this space shares with other spaces will be removed from this space if you make it private. Additionally, initiatives from other spaces will be removed from the this space's roadmap. Are you sure you want to proceed?`,
              { destructive: true }
            );
            if (!confirmed) {
              return;
            }

            if (sharedRoadmaps.length) {
              // we know there can only be one roadmap per space
              setSpaceRoadmap(space.id, null);
            } else if (outsideInitiatives.length) {
              deleteRoadmapInitiatives(outsideInitiatives.map(i => i.id));
            }

            for (const initiative of sharedInitiatives) {
              removeSpacesFromInitiatives([initiative.id], [space.id], true);
            }
          }

          // if we're going private, make the current user, any guests and anyone who has it in their favorites a member
          const favoritedMembers = allMembers.filter(m =>
            m.member.favoriteSpaceIds?.includes(space.id)
          );
          const favoritedMemberIds = favoritedMembers.map(m => m.user.id);

          updateSpaces([space.id], {
            private: checked,
            members: uniq([user.id, ...favoritedMemberIds, ...space.members]),
          });

          const guestAndFavoritedMembers = space.members.filter(memberId => {
            const orgMember = allMembers.find(m => m.user.id === memberId);
            return (
              orgMember?.member.guest || orgMember?.member.favoriteSpaceIds?.includes(space.id)
            );
          });

          const updatedMembers = uniq([
            ...guestAndFavoritedMembers,
            ...(!checked ? [] : [user.id]),
          ]);

          updateSpaces([space.id], { private: true, members: updatedMembers });
        } else {
          // if we're going public, put the space into any non-guest's favorites and leave only the guests in the members
          const members = filterNotNull(
            space.members.map(memberId => allMembers.find(m => m.user.id === memberId))
          );
          const [guests, nonGuests] = partition(members, m => m.member.guest);
          updateSpaces([space.id], {
            private: false,
            members: guests.map(g => g.user.id),
          });
          for (const nonGuest of nonGuests) {
            updateMember([nonGuest.member.id], {
              favoriteSpaceIds: uniq([...(nonGuest.member.favoriteSpaceIds ?? []), space.id]),
            });
          }
        }
      }}
    />
  );

  return (
    <SettingsPage
      title="General space settings"
      description={
        <>
          <p>
            Space sections off work items and can represent cross-functional teams or products,
            components, and special projects
          </p>
          <LinkButton to="https://guide.kitemaker.co/spaces" className="row inlineFlex ml-4">
            Read more in the Kitemaker Guide <Icon icon="external_link" className="ml4" />
          </LinkButton>
        </>
      }
    >
      <SettingsSection>
        <Setting title={'Space name'} vertical>
          <SettingsTextInput
            initialValue={space.name}
            maxLength={254}
            onSave={v => updateSpaces([space.id], { name: v })}
          />
        </Setting>

        <Setting
          title={'Space key'}
          description={`The space key is used in ${issueTerm} identifiers (e.g. CP-123). It should be short and easy to recognize.`}
          vertical
        >
          <SettingsTextInput
            initialValue={space.key}
            maxLength={4}
            validate={v => {
              if (v.length < 2 || v.length > 4) {
                return 'Space key must be between two and four characters long';
              }

              if (spaceKeys.includes(v) && v !== space.key) {
                return `Key already used by another space`;
              }

              return null;
            }}
            onSave={v => updateSpaces([space.id], { key: v })}
          />
        </Setting>
      </SettingsSection>

      <SettingsSection>
        <Setting
          title="Space timezone"
          description={`The timezone setting is used to schedule automation`}
        >
          <SelectButton
            items={timezoneOptions}
            value={space.timezone}
            placeholder={'Select timezone'}
            propertiesToSearch={['contents', 'label', 'offset', ' altName', 'abbrev']}
            noSort
            onSelect={v => {
              updateSpaces([space.id], { timezone: v });
            }}
          />
        </Setting>
      </SettingsSection>

      {!space.private && organization.newRoadmapsEnabled && (
        <SettingsSection>
          <Setting title="Space roadmap" description="Every space can have one roadmap attached">
            <SelectButton
              items={roadmapValues}
              noSort
              value={spaceRoadmap?.id ?? ''}
              placeholder={'Select roadmap'}
              propertiesToSearch={['name']}
              onSelect={v => {
                if (v === 'none') {
                  setSpaceRoadmap(space.id, null, true);
                } else {
                  setSpaceRoadmap(space.id, v, true);
                }
              }}
            />
          </Setting>
        </SettingsSection>
      )}

      <SettingsSection>
        <Setting
          title="Make space private"
          description="Private spaces are hidden from other organization members until they are added to the space members. "
        >
          {!paidPlan && (
            <Tooltip content="Private spaces are a paid feature">
              <div>{privateSwitch}</div>
            </Tooltip>
          )}
          {paidPlan && privateSwitch}
        </Setting>
      </SettingsSection>

      <SettingsSection>
        <Button
          buttonStyle={ButtonStyle.Destructive}
          onClick={async () => {
            const deleted = await deleteSpaces([space.id]);
            if (deleted.length) {
              history.replace(organizationPath(organization, 'settings/organization'));
            }
          }}
        >
          Delete space
        </Button>
      </SettingsSection>
    </SettingsPage>
  );
}
