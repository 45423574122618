import cn from 'classnames';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './blockQuote.module.scss';

export function StaticBlockQuote({ children, attributes }: OptionalAttributesRenderElementProps) {
  return (
    <blockquote className="block" {...attributes}>
      <div className={styles.blockquote}>
        <div className={styles.border} contentEditable={false} />
        <div>{children}</div>
      </div>
    </blockquote>
  );
}

export function BlockQuote({ attributes, children }: RenderElementProps) {
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  return (
    <blockquote className={cn('block', dndClassName)} {...attributes} {...dndAttributes}>
      {dndComponents}
      <div className={styles.blockquote}>
        <div className={styles.border} contentEditable={false} />
        <div className="grow">{children}</div>
      </div>
    </blockquote>
  );
}
