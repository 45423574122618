import React from 'react';
import { ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { EffortMenu } from '../../components/new/menu/effortMenu';
import { ImpactMenu } from '../../components/new/menu/impactMenu';
import { LabelPicker } from '../../components/new/pickers/labelPicker';
import { MemberPicker } from '../../components/new/pickers/memberPicker';
import { SpacePicker } from '../../components/new/pickers/spacePicker';
import { StatusColumnPicker } from '../../components/new/pickers/statusColumnPicker';
import { TagPicker } from '../../components/new/pickers/tagPicker';
import { NewEntityType } from '../../contexts/modalContext';
import {
  assignIssueKey,
  labelIssueKey,
  moveIssueKey,
  setEffortKey,
  setImpactKey,
  spaceInitiativeKey,
} from '../../utils/config';

export function ActionMenu({
  statusId,
  setStatusId,
  assigneeIds,
  setAssigneeIds,
  labelIds,
  setLabelIds,
  spaceIds,
  setSpaceIds,
  effortId,
  setEffortId,
  impactId,
  setImpactId,

  type,
  closeMenu,
}: {
  statusId: string;
  setStatusId: (statusId: string) => void;
  assigneeIds: string[];
  setAssigneeIds: (assigneeIds: string[]) => void;
  labelIds: string[];
  setLabelIds: (labelIds: string[]) => void;
  spaceIds: string[];
  setSpaceIds: (spaceIds: string[]) => void;
  effortId: string | null;
  setEffortId: (effortId: string | null) => void;
  impactId: string | null;
  setImpactId: (impactId: string | null) => void;
  type: NewEntityType;
  closeMenu: () => void;
}) {
  return (
    <>
      {type === 'Issue' && (
        <Submenu>
          <SubmenuTrigger icon="status" shortcut={moveIssueKey}>
            Status
          </SubmenuTrigger>
          <SubmenuContent className="menuSmall">
            <StatusColumnPicker
              state={{ DUMMY_ID: [statusId] }}
              onPicked={(_issueIds, statusId) => {
                setStatusId(statusId);
              }}
              onDone={closeMenu}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <Submenu>
        <SubmenuTrigger icon="member" shortcut={assignIssueKey}>
          {type === 'Feedback' ? 'Owners' : 'Members'}
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <MemberPicker
            filterPlaceholder={type === 'Feedback' ? 'Add owners' : undefined}
            state={{ DUMMY_ID: assigneeIds }}
            onMemberAdded={(_issueIds, memberId) => {
              setAssigneeIds([...assigneeIds, memberId]);
            }}
            onMemberRemoved={(_issueIds, memberId) => {
              setAssigneeIds(assigneeIds.filter(id => id !== memberId));
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="label" shortcut={labelIssueKey}>
          {type === 'Feedback' ? 'Tags' : 'Labels'}
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          {type !== 'Feedback' && (
            <LabelPicker
              orgLevel={type === 'Initiative'}
              state={{ DUMMY_ID: labelIds }}
              onLabelAdded={(_issueIds, labelId) => {
                setLabelIds([...labelIds, labelId]);
              }}
              onLabelRemoved={(_issueIds, labelId) => {
                setLabelIds(labelIds.filter(id => id !== labelId));
              }}
              onDone={closeMenu}
            />
          )}
          {type === 'Feedback' && (
            <TagPicker
              state={{ DUMMY_ID: labelIds }}
              onTagAdded={(_issueIds, labelId) => {
                setLabelIds([...labelIds, labelId]);
              }}
              onTagRemoved={(_issueIds, labelId) => {
                setLabelIds(labelIds.filter(id => id !== labelId));
              }}
              onDone={closeMenu}
            />
          )}
        </SubmenuContent>
      </Submenu>
      {type === 'Initiative' && (
        <Submenu>
          <SubmenuTrigger icon="workspace" shortcut={spaceInitiativeKey}>
            Spaces
          </SubmenuTrigger>
          <SubmenuContent className="menuPicker menuMedium">
            <SpacePicker
              multi
              state={{ DUMMY_ID: spaceIds }}
              onSpaceAdded={(_issueIds, spaceId) => {
                setSpaceIds([...spaceIds, spaceId]);
              }}
              onSpaceRemoved={(_issueIds, spaceId) => {
                setSpaceIds(spaceIds.filter(id => id !== spaceId));
              }}
              onDone={closeMenu}
            />
          </SubmenuContent>
        </Submenu>
      )}
      {type === 'Issue' && (
        <>
          <Submenu>
            <SubmenuTrigger icon="impact" shortcut={setImpactKey}>
              Impact
            </SubmenuTrigger>
            <SubmenuContent className="menuTiny">
              <ImpactMenu onSelect={impactId => setImpactId(impactId)} impactId={impactId} />
            </SubmenuContent>
          </Submenu>
          <Submenu>
            <SubmenuTrigger icon="effort" shortcut={setEffortKey}>
              Effort
            </SubmenuTrigger>
            <SubmenuContent className="menuTiny">
              <EffortMenu onSelect={effortId => setEffortId(effortId)} effortId={effortId} />
            </SubmenuContent>
          </Submenu>
        </>
      )}
    </>
  );
}

export function ActionMenuButton({
  statusId,
  setStatusId,
  assigneeIds,
  setAssigneeIds,
  labelIds,
  setLabelIds,
  spaceIds,
  setSpaceIds,
  effortId,
  setEffortId,
  impactId,
  setImpactId,
  type,
}: {
  statusId: string;
  setStatusId: (statusId: string) => void;
  assigneeIds: string[];
  setAssigneeIds: (assigneeIds: string[]) => void;
  labelIds: string[];
  setLabelIds: (labelIds: string[]) => void;
  spaceIds: string[];
  setSpaceIds: (spaceIds: string[]) => void;
  effortId: string | null;
  setEffortId: (effortId: string | null) => void;
  impactId: string | null;
  setImpactId: (impactId: string | null) => void;
  type: NewEntityType;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton icon={'more'} size={ButtonSize.Small} buttonStyle={ButtonStyle.BareSubtle} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
      >
        <ActionMenu
          statusId={statusId}
          setStatusId={setStatusId}
          assigneeIds={assigneeIds}
          setAssigneeIds={setAssigneeIds}
          labelIds={labelIds}
          setLabelIds={setLabelIds}
          spaceIds={spaceIds}
          setSpaceIds={setSpaceIds}
          effortId={effortId}
          setEffortId={setEffortId}
          impactId={impactId}
          setImpactId={setImpactId}
          closeMenu={closeMenu}
          type={type}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
