import { cloneDeep, isUndefined } from 'lodash';
import { BaseRange, Descendant, Editor, Path, Point, Range, Text } from 'slate';
import { ReactEditor } from 'slate-react';
import { KitemakerElement } from '../../shared/slate/kitemakerNode';
import { Elements, Marks, VoidElement } from '../../shared/slate/types';
import { safeSelection } from '../../shared/slate/utils';
import { findScrollParent } from '../utils/dom';
import { scrollIntoView } from '../utils/scrolling';
import { CUSTOM_PROPERTIES } from './kitemakerTransforms';
import { EditorType } from './types';

const scrollParents: WeakMap<EditorType, HTMLElement | null> = new WeakMap();

export const KitemakerEditor = {
  ...Editor,
  toggleMark(editor: Editor, mark: Marks) {
    if (this.hasMark(editor, mark)) {
      this.removeMark(editor, mark);
    } else {
      this.addMark(editor, mark, true);
    }
  },
  hasMark(editor: Editor, mark: Marks): boolean {
    const [match] = Editor.nodes(editor, {
      match: n => (n as any)[mark] === true,
      mode: 'all',
    });
    return !!match;
  },
  findColor(editor: Editor): string | null {
    const [match] = Editor.nodes(editor, {
      match: n => (n as any)[Marks.Color],
      mode: 'all',
    });
    if (!match) {
      return null;
    }
    return (match[0] as any)[Marks.Color] ?? null;
  },
  isAtStartOfBlock(editor: EditorType, path: Path): boolean {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
      return false;
    }

    const start = Editor.start(editor, path);
    return Point.equals(selection.focus, start);
  },
  isAtEndOfBlock(editor: EditorType, path: Path): boolean {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
      return false;
    }

    const end = Editor.end(editor, path);
    return Point.equals(selection.focus, end);
  },
  isInCodeBlock(editor: EditorType, onlyCollapsed = false) {
    const { selection } = editor;
    if (!selection || (onlyCollapsed && !Range.isCollapsed(selection))) {
      return false;
    }

    const parent = this.above(editor, {
      match: n => KitemakerElement.isElement(n) && n.type === Elements.Code,
      at: selection.focus,
    });
    return !!parent;
  },
  ensureBottomOffset(editor: EditorType) {
    if (!editor.bottomOffset) {
      return;
    }

    const { selection } = editor;
    const domSelection = window.getSelection();

    if (
      !domSelection ||
      domSelection.rangeCount === 0 ||
      !selection ||
      !Range.isCollapsed(selection)
    ) {
      return;
    }

    const domRange = domSelection.getRangeAt(0);
    const rawClientRects = Array.from(domRange.getClientRects());
    if (!rawClientRects.length) {
      return;
    }

    const rect = rawClientRects[0];
    let scrollParent = scrollParents.get(editor);
    if (!scrollParent) {
      const node = ReactEditor.toDOMNode(editor, editor);
      // find a scroll parent that is NOT the editor itself in case it has overflow scrolling set on it
      scrollParent = findScrollParent(node, {
        match: element =>
          !element.getAttribute('data-slate-editor') &&
          !element.getAttribute('data-editor-container'),
      });
      if (!scrollParent) {
        return;
      }
      scrollParents.set(editor, scrollParent);
    }

    const scrollParentRect = scrollParent.getBoundingClientRect();
    const offset = window.innerHeight - rect.y + rect.height - scrollParentRect.top;
    if (offset < editor.bottomOffset) {
      scrollParent.scrollBy({ top: editor.bottomOffset - offset });
    }
  },
  pathIsInCollapsedFocus(editor: EditorType, path: Path) {
    const { selection } = editor;
    if (!selection || !Range.isCollapsed(selection)) {
      return false;
    }

    return Path.equals(selection?.anchor.path, path);
  },
  extractSelection(editor: EditorType, at?: BaseRange | null) {
    const selection = at ?? safeSelection(editor);
    if (!selection) {
      return null;
    }
    const fragment = cloneDeep(Editor.fragment(editor, selection));
    if (fragment.length === 1) {
      let first: Descendant | null = fragment[0];
      // if we've got a chat with only one message and that message has only one child, then we can coerce that to a paragraph
      if (KitemakerElement.isElement(first) && first.type === Elements.Chat) {
        const firstChatChild = first.children[0];
        if (
          first.children.length === 1 &&
          KitemakerElement.isElement(firstChatChild) &&
          firstChatChild.children.length === 1
        ) {
          first = firstChatChild.children[0];
        } else {
          first = null;
        }
      }
      if (
        first &&
        KitemakerElement.isElement(first) &&
        KitemakerElement.isTextBlock(first) &&
        first.type !== Elements.Paragraph
      ) {
        // clear out all the unneeded properties
        for (const prop of CUSTOM_PROPERTIES) {
          delete (first as any)[prop];
        }
        // force it to a paragraph
        (first as any).type = Elements.Paragraph;
      }
    }

    return fragment;
  },
  ensureFocusOnScreen(editor: Editor, delay?: number) {
    function ensureOnScreen() {
      const selection = safeSelection(editor);
      if (!selection || !ReactEditor.isFocused(editor)) {
        return;
      }
      const nodes = Editor.nodes(editor, {
        at: selection,
        mode: 'lowest',
        match: n => Text.isText(n),
      });
      const node = nodes.next().value;
      if (!node) {
        return;
      }

      const domNode = ReactEditor.toDOMNode(editor, node[0]);
      if (!domNode) {
        return;
      }
      scrollIntoView(domNode, {
        block: 'start',
        scrollMode: 'if-needed',
        behavior: 'auto',
      });
    }
    if (isUndefined(delay)) {
      ensureOnScreen();
    }
    // FIXME: battling the browser somehow here and without the delay it often doesn't work
    setTimeout(ensureOnScreen, delay);
  },
  commentNodes(editor: EditorType, commentId: string) {
    const [, startPath] = Editor.first(editor, []);
    const [, endPath] = Editor.last(editor, []);
    const anchor = Editor.start(editor, startPath);
    const focus = Editor.end(editor, endPath);
    const range = { anchor, focus };
    const nodes = Array.from(
      Editor.nodes(editor, {
        match: n => {
          if (!KitemakerElement.isElement(n)) {
            return false;
          }

          if (n.type === Elements.Comment && n.commentId === commentId) {
            return true;
          }

          if (KitemakerElement.isVoid(n) && !KitemakerElement.isInlineVoid(n)) {
            const annotations = (n as VoidElement).annotations ?? [];
            return !!annotations.find(a => a.type === 'comment' && a.id === commentId);
          }

          return false;
        },
        at: range,
      })
    );

    return nodes;
  },
};
