import { pick } from 'lodash';
import { syncEngineConfig } from '../../sync/config';
import { SyncEngineObject } from './types';

let stubCache: Record<string, string[]> | null = null;
let filterCache: Record<string, string[]> | null = null;

export function stubOutCollectionTypes<T extends SyncEngineObject>(
  object: Partial<T> & { __typename: string }
): T {
  if (!stubCache) {
    stubCache = syncEngineConfig.types.reduce((result, type) => {
      const collections = type.properties.filter(t => !!t.collection);
      if (collections) {
        result[type.name] = collections.map(p => p.name);
      }
      return result;
    }, {} as Record<string, string[]>);
  }

  if (!stubCache[object.__typename]) {
    return object as T;
  }

  const result = { ...object };
  for (const collection of stubCache[object.__typename]) {
    (result as any)[collection] = [];
  }
  return result as T;
}

export function filterOutgoingProperties<T extends SyncEngineObject>(
  typename: string,
  object: Partial<T>
): Partial<T> {
  if (!filterCache) {
    filterCache = {};
    for (const t of syncEngineConfig.types) {
      if (t.disallowClientCreation && t.disallowClientUpdate && t.disallowClientDeletion) {
        continue;
      }

      const properties: string[] = t.disallowClientCreation ? [] : ['id'];
      if (!t.disallowClientDeletion) {
        properties.push('deleted');
      }

      for (const p of t.properties) {
        if (!t.disallowClientCreation || !p.disallowClientUpdate) {
          properties.push(p.name);
        }
      }

      filterCache[t.name] = properties;
    }
  }

  return pick(object, filterCache[typename] ?? []);
}
