import { selectorFamily } from 'recoil';
import { filterNotNull } from '../../../shared/utils/convenience';
import { RenderedStarred, Starred, StarredType, renderStarred } from '../../utils/starred';
import { indexKey, syncEngineState } from '../state';
import { SyncEngineIndex, SyncEngineIndexValue, SyncEngineObject } from '../types';
import { organizationsSelector } from './organizations';
import { currentUserMembershipState } from './users';

export const starredSelector = selectorFamily({
  key: 'Starred',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const organizationMember = get(currentUserMembershipState(organizationId));
      if (!organizationMember) {
        return [];
      }

      const organization = get(organizationsSelector(organizationId));
      if (!organization) {
        return [];
      }

      return filterNotNull(
        (organizationMember.starred ?? []).map((f: Starred) =>
          renderStarred(
            <T extends SyncEngineObject>(id: string) => get(syncEngineState(id)) as T | null,
            (index: SyncEngineIndex, id: string) => {
              const indexValues = get(syncEngineState(indexKey(index, id))) as
                | SyncEngineIndexValue[]
                | null;
              return (indexValues ?? []).map(i => i.value);
            },
            f,
            organization
          )
        ) as RenderedStarred[]
      );
    },
});

export const isStarredSelector = selectorFamily({
  key: 'IsStarred',
  get:
    ({ organizationId, type, id }: { organizationId: string; type: StarredType; id: string }) =>
    ({ get }) => {
      const organizationMember = get(currentUserMembershipState(organizationId));
      if (!organizationMember) {
        return false;
      }
      return (organizationMember.starred ?? []).some(
        (f: Starred) => f.type === type && f.id === id
      );
    },
});
