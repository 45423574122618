import cn from 'classnames';
import * as React from 'react';
import { issueTerm } from '../../../../shared/utils/terms';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon } from '../../../components/new/icon';
import {
  useHasKeyNavigationFocus,
  useKeyNavigationElement,
} from '../../../components/new/keyNavigation';
import styles from './placeholder.module.scss';

export function Placeholder({
  statusId,
  type = issueTerm,
  onCreateNew,
  list,
}: {
  statusId: string;
  type?: string;
  onCreateNew?: () => void;
  list?: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const id = `placeholder-${statusId}`;
  useKeyNavigationElement(id, ref);
  const focused = useHasKeyNavigationFocus(id);
  return (
    <div
      className={cn(styles.placeholder, {
        [styles.list]: list,
      })}
      ref={ref}
    >
      <div className={styles.hoverInfo} onClick={onCreateNew}>
        <Icon icon="add_circle" className={styles.icon} /> Add {type}
      </div>

      {focused && onCreateNew && (
        <Hotkey
          hotkey="enter"
          handler={e => {
            e?.stopPropagation();
            e?.preventDefault();
            onCreateNew();
          }}
        />
      )}
    </div>
  );
}
