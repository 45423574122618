import * as React from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { sessionStorageEffect } from '../../syncEngine/effects';

const scrollPositionState = atomFamily<number, string>({
  key: 'RestoreScrollPosition',
  default: 0,
  effects: key => [sessionStorageEffect(`__scrollPositionRestore__${key}`)],
});

export function useRestoreScrollPosition(id: string, ref: React.RefObject<HTMLDivElement>) {
  const [scrollLeft, setScrollLeft] = useRecoilState(scrollPositionState(id));
  React.useLayoutEffect(() => {
    const node = ref.current;
    node?.scrollTo(scrollLeft, 0);
    return () => {
      if (node) {
        setScrollLeft(node.scrollLeft);
      }
    };
  }, []);
  return null;
}
