import React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { Entity } from '../../../../sync/__generated/models';
import { EntityPicker } from '../../../components/new/pickers/entityPicker';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  useAddEntitiesToCycle,
  useRemoveEntitiesFromCycle,
} from '../../../syncEngine/actions/cycles';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../../syncEngine/actions/intiatives';
import { useCreateIssue } from '../../../syncEngine/actions/issues';
import {
  useAddIssuesToRelease,
  useRemoveIssuesFromRelease,
} from '../../../syncEngine/actions/releases';
import { cycleEntitiesForCycleSelector, cycleSelector } from '../../../syncEngine/selectors/cycles';
import {
  isSpaceBoundEntity,
  useRecentEntitiesForOrganization,
  useRecentEntitiesForSpace,
} from '../../../syncEngine/selectors/entities';
import {
  initiativeVisibilitySelector,
  issuesForInitaitiveSelector,
  spacesIdsForInitiativeSelector,
} from '../../../syncEngine/selectors/intiatives';
import { isIssue } from '../../../syncEngine/selectors/issues';
import { firstTodoStatusIdSelector } from '../../../syncEngine/selectors/issueStatuses';
import { releaseSelector } from '../../../syncEngine/selectors/releases';
import { spacesForOrganizationSelector } from '../../../syncEngine/selectors/spaces';
import styles from '../commandMenu.module.scss';

export function ChangeInitiativeIssuesContent({
  onClose,
  initiativeId,
  spaceId,
}: {
  onClose: () => void;
  initiativeId: string;
  spaceId?: string;
}) {
  const organization = useOrganization();
  const initiativeVisibility = useRecoilValue(initiativeVisibilitySelector(initiativeId));
  const initiativeSpaceIds = useRecoilValue(spacesIdsForInitiativeSelector(initiativeId));

  const workItemIds = useRecoilValue(issuesForInitaitiveSelector(initiativeId));
  const addIssues = useAddIssuesToInitiatives();
  const removeIssues = useRemoveIssuesFromInitiatives();

  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const privateSpaces = spaces.filter(s => s.private).map(s => s.id);

  const defaultOptionsForSpace = useRecentEntitiesForSpace();
  const defaultOptionsForOrganization = useRecentEntitiesForOrganization({
    filter: (e: Entity) => {
      if (!isIssue(e)) {
        return false;
      }

      if (initiativeVisibility === 'private' && !initiativeSpaceIds.includes(e.spaceId)) {
        return false;
      }

      if (initiativeVisibility === 'public' && isSpaceBoundEntity(e)) {
        return !privateSpaces.includes(e.spaceId);
      }

      return true;
    },
  });

  const recentEntityIds = (
    spaceId ? defaultOptionsForSpace(spaceId) : defaultOptionsForOrganization(organization.id)
  )
    .filter(e => isIssue(e))
    .map(e => e.id);

  return (
    <div className="rowStretch fullWidth fullHeight">
      <EntityPicker
        spaceId={initiativeVisibility === 'private' ? initiativeSpaceIds[0] : undefined}
        filterClassName={styles.inputDiv}
        multi
        placeholder={`Add ${issueTerm}s to initiative`}
        entityTypes={['Issue']}
        defaultEntityIds={recentEntityIds}
        state={{ [initiativeId]: workItemIds.map(i => i.id) }}
        onDone={onClose}
        onAdd={(_, id) => addIssues([initiativeId], [id])}
        onRemove={(_, id) => removeIssues([initiativeId], [id])}
        mouseDown
      />
    </div>
  );
}

export function ChangeCycleIssuesContent({
  onClose,
  cycleId,
}: {
  onClose: () => void;
  cycleId: string;
}) {
  const cycleEntities = useRecoilValue(cycleEntitiesForCycleSelector(cycleId));
  const workItemIds = cycleEntities.map(e => e.entityId);
  const addIssues = useAddEntitiesToCycle();
  const removeIssues = useRemoveEntitiesFromCycle();
  const cycle = useRecoilValue(cycleSelector(cycleId));

  const statusId = useRecoilValue(firstTodoStatusIdSelector(cycle?.spaceId ?? ''));

  const createIssue = useCreateIssue();

  const recentEntityIds = useRecentEntitiesForSpace()(cycle?.spaceId ?? '')
    .filter(e => isIssue(e))
    .map(e => e.id);

  return (
    <div className="rowStretch fullWidth fullHeight">
      <EntityPicker
        createNewItem={({ onAdd, onDone, filter, setFilter }) => {
          return {
            id: 'create-new-dependency',
            className: 'createItem',
            icon: 'add',
            contents: (
              <div>
                Create {issueTerm} {`"${filter}"`}
              </div>
            ),
            onSelected: shift => {
              if (!cycle) {
                return;
              }
              const newIssue = createIssue(filter, cycle.spaceId, { statusId });
              if (newIssue) {
                onAdd(newIssue.id);
              }

              setFilter('');
              if (!shift) {
                onDone();
              }
            },
          };
        }}
        filterClassName={styles.inputDiv}
        spaceId={cycle?.spaceId}
        multi
        placeholder={`Add ${issueTerm}s to cycle`}
        entityTypes={['Issue']}
        defaultEntityIds={recentEntityIds}
        state={{ [cycleId]: workItemIds }}
        onDone={onClose}
        onAdd={(_, id) => addIssues([id], cycleId)}
        onRemove={(_, id) => removeIssues([id], cycleId)}
        mouseDown
      />
    </div>
  );
}

export function ChangeReleaseIssuesContent({
  onClose,
  releaseId,
}: {
  onClose: () => void;
  releaseId: string;
}) {
  const release = useRecoilValue(releaseSelector(releaseId));
  const addIssuesToRelease = useAddIssuesToRelease();
  const removeIssuesFromRelease = useRemoveIssuesFromRelease();

  const spaceId = release?.spaceIds?.[0];

  const recentOrgEntityIds = useRecentEntitiesForOrganization({ filter: isIssue })(
    release?.organizationId ?? ''
  ).map(e => e.id);
  const recentSpaceEntityIds = useRecentEntitiesForSpace()(spaceId ?? '')
    .filter(e => isIssue(e))
    .map(e => e.id);

  return (
    <div className="rowStretch fullWidth fullHeight">
      <EntityPicker
        filterClassName={styles.inputDiv}
        spaceId={release?.spaceIds?.[0]}
        multi
        placeholder={`Add ${issueTerm}s to release`}
        entityTypes={['Issue']}
        defaultEntityIds={spaceId ? recentSpaceEntityIds : recentOrgEntityIds}
        state={{ [releaseId]: release?.issueIds ?? [] }}
        onDone={onClose}
        onAdd={(_, id) => addIssuesToRelease([releaseId], [id])}
        onRemove={(_, id) => removeIssuesFromRelease([releaseId], [id])}
        mouseDown
      />
    </div>
  );
}
