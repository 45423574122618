import cn from 'classnames';
import { selectorFamily } from 'recoil';
import { DependencyType, IssueStatusType } from '../../../sync/__generated/models';
import { smallHeight, width } from '../../screens/dependencyGraphScreen/dependencyGraphScreen';
import styles from '../../screens/dependencyGraphScreen/dependencyGraphScreen.module.scss';
import { filterEntities } from '../../utils/filtering';
import { highlightedDependencyState } from '../state';
import {
  blockedSelector,
  dependsOnSelector,
  enablesSelector,
  issueArchivedSelector,
  issuePath,
  issueSelector,
  issuesForOrganizationSelector,
  statusSelector,
} from './issues';
import { organizationsSelector } from './organizations';
import { spaceSelector } from './spaces';

export const dependencyGraphForOrganizationSelector = selectorFamily({
  key: 'NewDependencyGraphForOrganization',
  get:
    ({ organizationId, filterId }: { organizationId: string; filterId: string }) =>
    ({ get }) => {
      const nodes: any[] = [];
      const edges: any[] = [];
      let numNetworks = 0;
      const highlightedSource = get(highlightedDependencyState);

      function recursiveAddNodes(issueId: string, networkId: number) {
        const issue = get(issueSelector(issueId));
        if (!issue) {
          return;
        }
        const space = get(spaceSelector(issue.spaceId));
        if (!space) {
          return;
        }
        const organization = get(organizationsSelector(space.organizationId));
        if (!organization) {
          return;
        }
        const status = get(statusSelector(issue.statusId));
        if (!status) {
          return;
        }

        const dependsOn = get(dependsOnSelector(issue.id));
        const enables = get(enablesSelector(issue.id));

        const isBlocked = get(blockedSelector(issue?.id));

        nodes.push({
          id: issue.id,
          height: smallHeight,
          width: width,
          className: cn(styles.node, {
            [styles.blocked]:
              dependsOn.findIndex(d => d.dependencyType === DependencyType.BlockedBy) !== -1,
          }),
          data: {
            issue: issue,
            space: space,
            statusName: status.name,
            title: `: ${issue.title}`,
            link: issuePath(organization, space, issue),
            network: networkId,
            highlighted: false,
            isArchived: status.statusType === IssueStatusType.Archived,
            isBlocked,
          },
        });

        dependsOn.forEach(d => {
          if (d.dependsOnId) {
            edges.push({
              id: `${d.enablesId}T${d.dependsOnId}`,
              to: d.enablesId,
              from: d.dependsOnId,
              disabled: true,
              dependencyType: d.dependencyType,
              className: cn(styles.edge, styles.base),
            });
          }
        });

        dependsOn.forEach(d => {
          if (d.enablesId) {
            if (nodes.findIndex(i => i.id === d.enablesId) === -1) {
              recursiveAddNodes(d.enablesId, networkId);
            }
          }
        });

        enables.forEach(d => {
          if (d.dependsOnId) {
            if (nodes.findIndex(i => i.id === d.dependsOnId) === -1) {
              recursiveAddNodes(d.dependsOnId, networkId);
            }
          }
        });
      }

      const issues = get(issuesForOrganizationSelector(organizationId));

      const filteredIssues = filterEntities(
        issues.map(i => i.id),
        filterId,
        get
      );
      filteredIssues.forEach(issueId => {
        const dependsOn = get(dependsOnSelector(issueId));
        const isArchived = get(issueArchivedSelector(issueId));
        if (dependsOn.length > 0 && !isArchived && nodes.findIndex(w => w.id === issueId) === -1) {
          recursiveAddNodes(issueId, numNetworks);
          ++numNetworks;
        }
      });

      function setHighlightForwards(id: string) {
        const n = nodes.find(n => n.id === id);

        if (n) {
          if (!n.data.visitedForward) {
            n.className = cn(styles.node, {
              [styles.blocked]: n.data.isBlocked,
            });

            n.data.highlighted = n.data.visitedForward = true;

            // Find all edges going out from this node
            edges.forEach(e => {
              if (e.from === id) {
                if (e.dependencyType === DependencyType.BlockedBy) {
                  e.icon = 'blocked';
                } else {
                  e.icon = 'enables';
                }
                e.className = cn(e.className, {
                  [styles.blocked]: e.dependencyType === DependencyType.BlockedBy,
                  [styles.depends]: e.dependencyType === DependencyType.DependsOn,
                });
                setHighlightForwards(e.to);
              }
            });
          }
        }
      }

      function setHighlightBackwards(id: string) {
        const n = nodes.find(n => n.id === id);

        if (n) {
          if (!n.data.visitedBackward) {
            n.className = cn(styles.node, {
              [styles.blocked]: n.data.isBlocked,
            });

            n.data.highlighted = n.data.visitedBackward = true;

            // Find all edges going out from this node
            edges.forEach(e => {
              if (e.to === id) {
                if (e.dependencyType === DependencyType.BlockedBy) {
                  e.icon = 'blocked';
                } else {
                  e.icon = 'enables';
                }
                e.className = cn(e.className, {
                  [styles.blocked]: e.dependencyType === DependencyType.BlockedBy,
                  [styles.depends]: e.dependencyType === DependencyType.DependsOn,
                });
                setHighlightBackwards(e.from);
              }
            });
          }
        }
      }

      if (highlightedSource) {
        setHighlightForwards(highlightedSource);
        setHighlightBackwards(highlightedSource);
      }
      return { nodes, edges };
    },
});

export const orgHasDependenciesSelector = selectorFamily({
  key: 'OrgHasDependencies',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const issues = get(issuesForOrganizationSelector(organizationId));
      for (const { id } of issues) {
        const dependsOn = get(dependsOnSelector(id));
        if (dependsOn.length > 0) {
          return true;
        }
      }

      return false;
    },
});
