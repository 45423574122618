import { ScrollMode } from 'scroll-into-view-if-needed/typings/types';
import { ensureVisible, hasMouseMoved, isMouseDown, scrollToTop } from './dom';

export type Coordinate = [number, number];

export enum FocusReason {
  Keyboard,
  Mouse,
  Programmatic,
}

export interface KeyNavigationState {
  id: string;
  columnIds: string[];
  columnIdsToElementIds: Record<string, string[]>;
  grid: string[][];
  elementIdsToGridCoordinates: Record<string, Coordinate>;
  focusedElementId: string | null;
  focusedElementGridCoordinates: Coordinate | null;
  focusedColumnId: string | null;
  focusedReason: FocusReason;
  selectedElementIds: string[] | null;
  disabled: Set<string>;
  locked: boolean;
  disableEnsureVisible: boolean;
  ensureVisibleOptions?: { blockMode?: string; inlineMode?: string; scrollMode?: ScrollMode };
  disableMissingElementDetection: boolean;
  multiSelect?: boolean;
  canMultiSelect: (elementId?: string | null, focusedColumnId?: string | null) => boolean;
}

export function createKeyNavigationState(
  id: string,
  columnIds: string[],
  focusedElementId?: string
): KeyNavigationState {
  return {
    id,
    columnIds,
    columnIdsToElementIds: {},
    grid: [],
    elementIdsToGridCoordinates: {},
    disabled: new Set(),
    focusedElementId: focusedElementId ?? null,
    focusedElementGridCoordinates: null,
    focusedColumnId: null,
    focusedReason: FocusReason.Programmatic,
    selectedElementIds: null,
    locked: false,
    disableEnsureVisible: false,
    disableMissingElementDetection: false,
    canMultiSelect: () => true,
  };
}

function isFullyInitialized(state: KeyNavigationState): boolean {
  return state.columnIds.every(columnId => columnId in state.columnIdsToElementIds);
}

function findClosestElement(grid: string[][], coordinate: [number, number]): string | null {
  const [x, y] = coordinate;

  // is there another element in the place of the missing element?
  if (grid[x] && grid[x][y]) {
    return grid[x][y];
  }

  // are there more elements in the same column?
  if (grid[x]?.length) {
    const col = grid[x];
    return grid[x][Math.min(y - 1, col.length - 1)];
  }

  // otherwise just find whichever column is closest and has stuff
  let closestX = -1;
  let i = 0;
  while (i < grid.length) {
    if (grid[i]?.length) {
      if (Math.abs(x - i) < Math.abs(x - closestX) || closestX === -1) {
        closestX = i;
      }
    }
    i++;
  }

  if (closestX >= 0 && closestX < grid.length) {
    const col = grid[closestX];
    return col[Math.min(y, col.length - 1)];
  }

  return null;
}

export function calculateGrid(state: KeyNavigationState): KeyNavigationState {
  state.elementIdsToGridCoordinates = {};
  state.grid = [];

  for (let columnIndex = 0; columnIndex < state.columnIds.length; columnIndex++) {
    state.grid[columnIndex] = [];

    const columnId = state.columnIds[columnIndex];
    if (state.columnIdsToElementIds[columnId]) {
      for (let rowIndex = 0; rowIndex < state.columnIdsToElementIds[columnId].length; rowIndex++) {
        const elementId = state.columnIdsToElementIds[columnId][rowIndex];
        state.grid[columnIndex][rowIndex] = elementId;
        state.elementIdsToGridCoordinates[elementId] = [columnIndex, rowIndex];
      }
    }
  }

  const originalFocusedId = state.focusedElementId;

  // ok, all of the columns have loaded, so we can mess with focus/selection if we need to
  if (isFullyInitialized(state)) {
    if (!state.disableMissingElementDetection) {
      // fix up focused element
      if (!state.focusedElementId) {
        state.focusedElementId = state.grid[0]?.[0] ?? null;
      }
      if (state.focusedElementId && !state.elementIdsToGridCoordinates[state.focusedElementId]) {
        state.focusedElementId = findClosestElement(
          state.grid,
          state.focusedElementGridCoordinates ?? [0, 0]
        );
      }

      // fix up selected elements
      if (state.selectedElementIds) {
        state.selectedElementIds = state.selectedElementIds.filter(
          id => !!state.elementIdsToGridCoordinates[id]
        );
        if (!state.selectedElementIds.length) {
          state.selectedElementIds = null;
        }
      }
    }

    // make sure the coordinates and columnId match the focus)
    if (state.focusedElementId && state.elementIdsToGridCoordinates[state.focusedElementId]) {
      state.focusedElementGridCoordinates =
        state.elementIdsToGridCoordinates[state.focusedElementId];
      state.focusedColumnId = state.columnIds[state.focusedElementGridCoordinates[0]];
    } else {
      state.focusedElementGridCoordinates = null;
      state.focusedColumnId = null;
    }
  }

  if (state.focusedElementId !== originalFocusedId) {
    state.focusedReason = FocusReason.Programmatic;
  }

  return state;
}

export function up(grid: string[][], coordinate: Coordinate | null): string | null {
  if (!coordinate) {
    return grid[0]?.[0] ?? null;
  }

  const [x, y] = coordinate;
  if (y === 0) {
    return grid[x][y];
  }

  return grid[x][y - 1];
}

export function down(grid: string[][], coordinate: Coordinate | null): string | null {
  if (!coordinate) {
    return grid[0]?.[0] ?? null;
  }

  const [x, y] = coordinate;

  const col = grid[x];
  if (y >= col.length - 1) {
    return grid[x][y];
  }

  return grid[x][y + 1];
}

export function left(grid: string[][], coordinate: Coordinate | null): string | null {
  if (!coordinate) {
    return grid[0]?.[0] ?? null;
  }

  const [x, y] = coordinate;

  // try to find the next non-empty column to the left
  let colIndex = x - 1;
  while (colIndex >= 0 && !grid[colIndex].length) {
    colIndex--;
  }

  // if we've run out of space, just return where we were
  if (colIndex < 0 || !grid[colIndex].length) {
    return grid[x][y];
  }

  const col = grid[colIndex];
  return col[Math.min(y, col.length - 1)];
}

export function right(grid: string[][], coordinate: Coordinate | null): string {
  if (!coordinate) {
    return grid[0]?.[0] ?? null;
  }

  const [x, y] = coordinate;

  // try to find the next non-empty column to the right
  let colIndex = x + 1;
  while (colIndex < grid.length && !grid[colIndex].length) {
    colIndex++;
  }

  // if we've run out of space, just return where we were
  if (colIndex > grid.length - 1 || !grid[colIndex].length) {
    return grid[x][y];
  }

  const col = grid[colIndex];
  return col[Math.min(y, col.length - 1)];
}

export function top(grid: string[][], coordinate: Coordinate | null): string {
  if (!coordinate) {
    return grid[0]?.[0] ?? null;
  }

  const [x] = coordinate;
  return grid[x][0];
}

export function bottom(grid: string[][], coordinate: Coordinate | null): string {
  if (!coordinate) {
    return grid[0]?.[0] ?? null;
  }

  const [x] = coordinate;
  const col = grid[x];
  return col[col.length - 1];
}

export function initialize(state: KeyNavigationState): KeyNavigationState {
  return calculateGrid(state);
}

export function updateColumn(
  state: KeyNavigationState,
  columnId: string,
  elementIds: string[] | null
): KeyNavigationState {
  const result: KeyNavigationState = {
    ...state,
    columnIdsToElementIds: { ...state.columnIdsToElementIds },
  };
  if (elementIds) {
    result.columnIdsToElementIds[columnId] = elementIds;
  } else {
    delete result.columnIdsToElementIds[columnId];
  }
  const s = calculateGrid(result);
  return s;
}

export function focus(
  state: KeyNavigationState,
  elementId: string | null,
  reason: FocusReason = FocusReason.Programmatic,
  options?: {
    ignoreMouseMove?: boolean;
  }
): KeyNavigationState {
  const isSameElement = elementId === state.focusedElementId;
  const isNonExistentElement =
    elementId &&
    !state.elementIdsToGridCoordinates[elementId] &&
    reason !== FocusReason.Programmatic;
  const isDisabled = (state.disabled.size || state.locked) && reason !== FocusReason.Programmatic;
  const mouseHasNotMoved =
    ((!hasMouseMoved && !options?.ignoreMouseMove) || isMouseDown) && reason === FocusReason.Mouse;

  if (isSameElement || isNonExistentElement || isDisabled || mouseHasNotMoved || !elementId) {
    return state;
  }

  const result = { ...state };
  result.focusedElementGridCoordinates = elementId
    ? state.elementIdsToGridCoordinates[elementId] ?? null
    : null;
  result.focusedElementId = elementId;
  result.focusedReason = reason;
  result.focusedColumnId =
    result.focusedElementId && result.focusedElementGridCoordinates
      ? result.columnIds[result.focusedElementGridCoordinates[0]]
      : null;

  if (elementId && reason !== FocusReason.Mouse && !state.disableEnsureVisible) {
    ensureVisible(elementId, state.id, result.ensureVisibleOptions);

    // if this is the first item of the column, automatically scroll to the top
    if (result.focusedElementGridCoordinates?.[1] === 0) {
      scrollToTop(elementId, state.id);
    }
  }

  return result;
}

export function toggleSelected(state: KeyNavigationState): KeyNavigationState {
  if (
    state.disabled.size ||
    state.locked ||
    !state.focusedElementId ||
    !state.canMultiSelect(state.focusedElementId, state.focusedColumnId)
  ) {
    return state;
  }
  const elementId = state.focusedElementId;
  const result: KeyNavigationState = {
    ...state,
    selectedElementIds: state?.selectedElementIds ? [...state.selectedElementIds] : null,
  };
  if (result.selectedElementIds?.includes(elementId)) {
    result.selectedElementIds = result.selectedElementIds.filter(id => id !== elementId);
    if (!result.selectedElementIds.length) {
      result.selectedElementIds = null;
    }
  } else {
    result.selectedElementIds = result.selectedElementIds || [];
    result.selectedElementIds.push(elementId);
  }

  return result;
}

export function select(state: KeyNavigationState, selectedIds: string[]): KeyNavigationState {
  if (
    state.disabled.size ||
    state.locked ||
    !selectedIds.every(id => state.canMultiSelect(state.focusedElementId, id))
  ) {
    return state;
  }
  const result: KeyNavigationState = {
    ...state,
    selectedElementIds: selectedIds,
  };

  return result;
}

export function clearSelection(state: KeyNavigationState): KeyNavigationState {
  if (!state.selectedElementIds) {
    return state;
  }
  return {
    ...state,
    selectedElementIds: null,
  };
}

export function selectAll(state: KeyNavigationState): KeyNavigationState {
  if (!state.focusedColumnId || !state.columnIdsToElementIds[state.focusedColumnId]) {
    return state;
  }

  // if the whole column is already selected, select everything
  const allInColumn = state.columnIdsToElementIds[state.focusedColumnId].filter(id =>
    state.canMultiSelect(id, state.focusedColumnId)
  );
  if (allInColumn.every(id => state.selectedElementIds?.includes(id))) {
    return {
      ...state,
      selectedElementIds: Object.entries(state.columnIdsToElementIds)
        .map(([columnId, elementIds]) =>
          elementIds.filter(id => state.canMultiSelect(id, columnId))
        )
        .flat(),
    };
  }

  return {
    ...state,
    selectedElementIds: state.columnIdsToElementIds[state.focusedColumnId].filter(id =>
      state.canMultiSelect(id, state.focusedColumnId)
    ),
  };
}

export function moveUp(
  state: KeyNavigationState,
  reason: FocusReason = FocusReason.Programmatic
): KeyNavigationState {
  const updatedFocused = up(state.grid, state.focusedElementGridCoordinates);
  return focus(state, updatedFocused, reason);
}

export function moveDown(
  state: KeyNavigationState,
  reason: FocusReason = FocusReason.Programmatic
): KeyNavigationState {
  const updatedFocused = down(state.grid, state.focusedElementGridCoordinates);
  return focus(state, updatedFocused, reason);
}

export function moveLeft(
  state: KeyNavigationState,
  reason: FocusReason = FocusReason.Programmatic
): KeyNavigationState {
  const updatedFocused = left(state.grid, state.focusedElementGridCoordinates);
  return focus(state, updatedFocused, reason);
}

export function moveRight(
  state: KeyNavigationState,
  reason: FocusReason = FocusReason.Programmatic
): KeyNavigationState {
  const updatedFocused = right(state.grid, state.focusedElementGridCoordinates);
  return focus(state, updatedFocused, reason);
}

export function moveToTop(
  state: KeyNavigationState,
  reason: FocusReason = FocusReason.Programmatic
): KeyNavigationState {
  const updatedFocused = top(state.grid, state.focusedElementGridCoordinates);
  return focus(state, updatedFocused, reason);
}

export function moveToBottom(
  state: KeyNavigationState,
  reason: FocusReason = FocusReason.Programmatic
): KeyNavigationState {
  const updatedFocused = bottom(state.grid, state.focusedElementGridCoordinates);
  return focus(state, updatedFocused, reason);
}

export function disable(state: KeyNavigationState, id: string): KeyNavigationState {
  const result = { ...state, focusedReason: FocusReason.Programmatic };
  result.disabled.add(id);
  return result;
}

export function enable(state: KeyNavigationState, id: string): KeyNavigationState {
  const result = { ...state, focusedReason: FocusReason.Programmatic };
  result.disabled.delete(id);
  return result;
}

export function disableMissingElementDetection(state: KeyNavigationState): KeyNavigationState {
  const result = { ...state, focusedReason: FocusReason.Programmatic };
  result.disableMissingElementDetection = true;
  return result;
}

export function enableMissingElementDetection(state: KeyNavigationState): KeyNavigationState {
  const result = { ...state, focusedReason: FocusReason.Programmatic };
  result.disableMissingElementDetection = false;
  return result;
}
