import cn from 'classnames';
import * as React from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { IntegrationType } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useHasAdminRights } from '../../contexts/userContext';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { stringifyIntegrationType } from '../../utils/integrations';
import BackButton from './backButton';
import { Button, ButtonProperties, ButtonSize, ButtonStyle, IconButton } from './button';
import { Icon, IconSize } from './icon';
import { IntegrationIcon } from './integrationIcon';
import Pill, { PillStyle } from './metadata/pill';
import { MetadataSize } from './metadata/size';
import styles from './settings.module.scss';
import { TextValidationInput } from './textInput';
import { Tooltip } from './tooltip';

export function AdminButton({
  children,
  icon,
  disabled,
  ...rest
}: {
  children: string | React.ReactNode;
  icon?: string | React.ReactNode;
  disabled?: boolean;
} & ButtonProperties) {
  const hasAdminRights = useHasAdminRights();

  return (
    <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
      <Button icon={icon} disabled={!hasAdminRights || disabled} {...(rest as any)}>
        {children}
      </Button>
    </Tooltip>
  );
}

export function SettingsSectionDivider() {
  return <div className={styles.sectionDivider} />;
}

export function SettingsSectionsHeader({
  children,
  large,
  className,
  nonInteractive,
}: {
  children: React.ReactNode;
  large?: boolean;
  className?: string;
  nonInteractive?: boolean;
}) {
  return (
    <div
      className={cn(styles.sectionHeader, className, {
        ['headingM']: !large,
        ['headingL']: large,
        [styles.disabled]: nonInteractive,
      })}
    >
      {children}
    </div>
  );
}

function IntegrationName({ integrationType }: { integrationType: IntegrationType }) {
  const { featureFlags } = useConfiguration();
  if (featureFlags.FEATURE_TOGGLE_NEW_SLACK && integrationType === IntegrationType.Slack) {
    return <>Slack (legacy)</>;
  }
  return <>{stringifyIntegrationType(integrationType)}</>;
}

export function IntegrationCard({
  integrationType,
  description,
  link,
  installed,
  className,
}: {
  integrationType: IntegrationType;
  description: string;
  link: string;
  installed: boolean;
  className?: string;
}) {
  const history = useHistory();

  return (
    <Link to={link} className={className}>
      <div className={styles.integrationCard}>
        <div className="col">
          <IntegrationIcon integrationType={integrationType} colored size={IconSize.Size40} />
        </div>
        <div>
          <div className="headingM mb4">
            <IntegrationName integrationType={integrationType} />
          </div>
          <div className={styles.description}>{description}</div>
          <div className="row metadataGapL">
            <Button
              size={ButtonSize.Small}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                history.push(link);
              }}
            >
              View
            </Button>
            {installed && (
              <Pill
                color={'grass'}
                pillStyle={PillStyle.Primary}
                size={MetadataSize.Medium}
                textOnly
              >
                Installed
              </Pill>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}

export function IntegrationSettingsPage({
  integrationType,
  description,
  children,
}: {
  integrationType: IntegrationType;
  description: React.ReactNode;
  children: React.ReactNode;
}) {
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'settings/integrations');

  return (
    <SettingsPage
      title={
        <>
          <BackButton className="mb24" defaultPath={defaultPath}>
            All integrations
          </BackButton>
          <div className="row">
            <IntegrationIcon
              integrationType={integrationType}
              size={IconSize.Size32}
              colored
              className="mr8"
            />
            <span>{stringifyIntegrationType(integrationType)}</span>
          </div>
        </>
      }
      description={description}
    >
      {children}
    </SettingsPage>
  );
}

export function SettingsListItem({
  children,
  meta,
  isFirst,
  isLast,
  link,
  menuOpen,
  onClick,
  onClickOutside,
}: {
  children: React.ReactNode;
  meta?: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  link?: string;
  menuOpen?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onClickOutside?: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    function onBodyClick(e: MouseEvent) {
      let parent: HTMLElement | null = e.target as HTMLElement | null;
      while (parent && parent !== ref.current) {
        parent = parent.parentElement;
      }

      if (parent !== ref.current) {
        onClickOutside?.();
      }
    }

    document.body.addEventListener('click', onBodyClick, true);

    return () => document.body.removeEventListener('click', onBodyClick, true);
  }, [onClickOutside]);

  const content = (
    <div
      ref={ref}
      onClick={onClick}
      className={cn(styles.settingsListItem, {
        [styles.first]: isFirst,
        [styles.last]: isLast,
        [styles.menuOpen]: menuOpen,
        [styles.clickable]: !!onClick,
      })}
    >
      <div className="row grow">{children}</div>
      <div className={styles.meta}>{meta}</div>
    </div>
  );

  return link ? <NavLink to={link}>{content}</NavLink> : content;
}

export function SettingsTextInput({
  initialValue,
  validate,
  onSave,
  onCancelled,
  alwaysShowButtons,
  hideErrorText,
  maxLength,
  className,
  autofocus,
  disabled,
}: {
  initialValue: string;
  validate?: (value: string) => string | null;
  onSave: (value: string) => void;
  onCancelled?: () => void;
  alwaysShowButtons?: boolean;
  hideErrorText?: boolean;
  maxLength?: number;
  className?: string;
  autofocus?: boolean;
  disabled?: boolean;
}) {
  const [value, setValue] = React.useState(initialValue);
  const [error, setError] = React.useState<string | undefined | null>(null);

  const saveButton = (
    <Button
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        const err = validate?.(value);
        if (err) {
          setError(err);
          return;
        }

        onSave(value);
      }}
      buttonStyle={ButtonStyle.Primary}
      disabled={!!error || initialValue === value || disabled}
    >
      Save
    </Button>
  );

  return (
    <>
      <div className={cn('rowAlignBaseline', className)}>
        <TextValidationInput
          className="fullWidth"
          error={hideErrorText ? null : error}
          disabled={disabled}
          value={value}
          autoFocus={autofocus}
          onBlur={() => {
            setError(validate?.(value));
          }}
          onChange={e => {
            setError(null);
            setValue(e.currentTarget.value.substring(0, maxLength));
          }}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
              e.stopPropagation();
              const err = validate?.(value);
              if (err) {
                setError(err);
                return;
              }

              onSave(value);
            }
            if (e.key.toLowerCase() === 'escape') {
              e.preventDefault();
              e.stopPropagation();
              setError(null);
              setValue(initialValue);
              onCancelled?.();
            }
          }}
        />
        <div className="row">
          {(initialValue !== value || alwaysShowButtons) && (
            <>
              <Button
                className="ml16 mr12"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setError(null);
                  setValue(initialValue);
                  onCancelled?.();
                }}
              >
                Cancel
              </Button>

              {(hideErrorText && error && <Tooltip content={error}>{saveButton}</Tooltip>) ||
                saveButton}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export function SettingsCard({
  handleIdentifier,
  draggable,
  editing,
  onEdit,
  onEditingComplete,
  onDelete,
  renderEdit,
  disableDelete,
  children,
  className,
  disabled,
  additionalMeta,
  onClick,
}: {
  handleIdentifier?: string | React.ReactNode;
  draggable?: boolean;
  editing?: boolean;
  onEdit?: () => void;
  onEditingComplete?: () => void;
  onDelete?: () => void;
  renderEdit: () => React.ReactNode;
  disableDelete?: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  additionalMeta?: React.ReactNode[];
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function onBodyClick(e: MouseEvent) {
      let parent: HTMLElement | null = e.target as HTMLElement | null;
      while (parent && parent !== ref.current) {
        parent = parent.parentElement;
      }

      if (parent !== ref.current) {
        onEditingComplete?.();
      }
    }

    const isEditing = editing;
    if (isEditing) {
      document.body.addEventListener('click', onBodyClick, true);
    }
    return () => {
      if (isEditing) {
        document.body.removeEventListener('click', onBodyClick, true);
      }
    };
  }, [onEditingComplete, editing]);

  return (
    <div
      ref={ref}
      onClick={disabled ? undefined : onClick}
      className={cn(styles.settingsCard, className, {
        [styles.disabled]: disabled,
        [styles.clickable]: !!onClick,
      })}
    >
      {draggable && (
        <div
          className={cn(styles.settingsCardHandle, {
            [styles.withHandleIdentifier]: handleIdentifier,
          })}
        >
          {disabled ? (
            <Icon icon="none" className={styles.handleIcon} />
          ) : (
            <Icon icon="drag" className={styles.handleIcon} />
          )}
          {handleIdentifier && handleIdentifier}
        </div>
      )}
      {!editing && (
        <>
          <div className={styles.settingsCardContent}>{children}</div>
          {!disabled && (
            <div className={styles.settingsCardMeta}>
              {additionalMeta}
              <Tooltip content="Edit">
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon="edit"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onEdit?.();
                  }}
                />
              </Tooltip>
              <Tooltip content={disableDelete ? disableDelete : 'Delete'}>
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  className={cn({
                    [styles.deleteIcon]: !disableDelete,
                  })}
                  icon="delete"
                  disabled={!!disableDelete}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDelete?.();
                  }}
                />
              </Tooltip>
            </div>
          )}
        </>
      )}
      {editing && renderEdit()}
    </div>
  );
}

export function Setting({
  title,
  description,
  vertical,
  centerAlign,
  smallSpacing,
  children,
  className,
}: {
  title?: string;
  description?: string | React.ReactNode;
  vertical?: boolean;
  smallSpacing?: boolean;
  centerAlign?: boolean;
  children?: React.ReactNode;
  className?: string;
}) {
  if (vertical) {
    return (
      <div
        className={cn('col', styles.setting, className, {
          [styles.vertical]: vertical,
          [styles.smallSpacing]: smallSpacing,
        })}
      >
        <div className={styles.settingTitle}>{title}</div>
        {children}
        {description && <div className={styles.settingDescription}>{description}</div>}
      </div>
    );
  }

  return (
    <div
      className={cn('rowBetween', className, styles.setting, {
        [styles.smallSpacing]: smallSpacing,
        rowAlignStart: !centerAlign,
      })}
    >
      <div className="col">
        <div className={styles.settingTitle}>{title}</div>
        {description && <div className={styles.settingDescription}>{description}</div>}
      </div>
      <div className="ml16">{children}</div>
    </div>
  );
}

export function SettingsSection({
  title,
  description,
  largeHeader,
  rightHeaderContent,
  children,
  nonInteractive,
}: {
  title?: string | React.ReactNode;
  description?: React.ReactNode;
  largeHeader?: boolean;
  rightHeaderContent?: React.ReactNode;
  children?: React.ReactNode;
  nonInteractive?: boolean;
}) {
  return (
    <div className={cn(styles.settingsSection, { [styles.disabled]: nonInteractive })}>
      {title && rightHeaderContent && (
        <div className="rowBetween">
          <SettingsSectionsHeader large={largeHeader}>
            {title} {largeHeader}
          </SettingsSectionsHeader>
          {rightHeaderContent}
        </div>
      )}
      {title && !rightHeaderContent && (
        <SettingsSectionsHeader nonInteractive={nonInteractive} large={largeHeader}>
          {title} {largeHeader}
        </SettingsSectionsHeader>
      )}
      {description && <div className={styles.settingsPageDescription}>{description}</div>}
      {children}
    </div>
  );
}

export function SettingsPage({
  title,
  description,
  logo,
  children,
}: {
  title: string | React.ReactNode;
  description?: React.ReactNode;
  logo?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <div className={styles.settingsPage}>
      <div className={styles.settingsHeader}>
        {logo}
        {title}
      </div>
      {description && (
        <SettingsSection>
          <div className={styles.settingsPageDescription}>{description}</div>
        </SettingsSection>
      )}
      {children}
    </div>
  );
}
