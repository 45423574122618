import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Breadcrumb } from '../../components/new/breadcrumbs';
import { useCopyEntitiesToClipboard } from '../../components/new/copyAndPaste';
import { EditFeedbackListItem, FeedbackListItem } from '../../components/new/feedbackListItem';
import { Hotkey } from '../../components/new/hotkey';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import { VirtualizedListView } from '../../components/new/virtualizedListView';
import { Modals, useModals } from '../../contexts/modalContext';
import { feedbacksSelector } from '../../syncEngine/selectors/feedback';
import { createNewIssueKey } from '../../utils/config';
import { Placeholder } from '../new/workItemBoardScreen/placeholder';

function ListItem({
  id,
  style,
  siblingEntities,
  className,
  breadcrumbs,
  onEdit,
  onEditComplete,
}: {
  id: string;
  siblingEntities: string[];
  style: React.CSSProperties;
  className?: string;
  breadcrumbs?: Breadcrumb[];
  onEdit?: () => void;
  onEditComplete?: () => void;
}) {
  const routingState = {
    siblingEntities,
    breadcrumbs,
  };

  return (
    <>
      {onEditComplete ? (
        <EditFeedbackListItem id={id} className={className} style={style} onDone={onEditComplete} />
      ) : (
        <FeedbackListItem
          id={id}
          fat
          className={className}
          style={style}
          onChangeTitle={onEdit}
          routingState={routingState}
        />
      )}
    </>
  );
}

export function FeedbackList({
  feedbackIds,
  breadcrumbs,
}: {
  feedbackIds: string[];
  breadcrumbs?: Breadcrumb[];
}) {
  const modals = useModals();
  const feedback = useRecoilValue(feedbacksSelector(feedbackIds));
  const boardId = 'feedback'; //TODO
  const copyEntities = useCopyEntitiesToClipboard();

  const renderItem = React.useCallback(
    (
      id: string,
      _statusId: string,
      isFirst: boolean,
      isLast: boolean,
      edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => {
      return (
        <ListItem
          id={id}
          className={cn('listItem', {
            ['first']: isFirst,
            ['last']: isLast,
          })}
          style={
            {
              //    '--number-column-width': `${numberColumnWidth}px`,
            } as React.CSSProperties
          }
          siblingEntities={feedbackIds}
          breadcrumbs={breadcrumbs}
          onEdit={edit?.start}
          onEditComplete={edit?.end}
        />
      );
    },
    [boardId]
  );

  return (
    <KeyNavigationProvider
      columnIds={['all']}
      disableEnsureVisible
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      {feedbackIds.length > 0 && (
        <VirtualizedListView
          style={{ flex: 1 }}
          id={'all'}
          sectionIds={['all']}
          itemIds={{ all: feedback.map(i => i.id) }}
          sectionHeaderHeight={20}
          spacerHeight={32}
          itemHeight={68}
          renderSectionHeader={() => null}
          renderItem={renderItem}
          renderPlaceholder={columnId => {
            return <Placeholder type="Feedback" statusId={columnId} list />;
          }}
          onCopy={copyEntities}
        />
      )}

      <Hotkey
        hotkey={createNewIssueKey}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          modals.openModal(Modals.NewEntity);
        }}
      />
    </KeyNavigationProvider>
  );
}
