import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../shared/utils/terms';
import { useStopCycle } from '../api/cycle';
import { writeToClipboard } from '../components/clipboardText';
import { useConfiguration } from '../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { cyclePath, cycleSelector } from '../syncEngine/selectors/cycles';
import { spaceSelector } from '../syncEngine/selectors/spaces';
import { addExistingKey } from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  CycleCommandGroupContext,
  useCommandMenuContext,
} from './state';

export function useCycleCommandGroup(): CommandDefinition[] {
  const cycleContext = useCommandMenuContext<CycleCommandGroupContext>(CommandGroup.Cycle);

  const focusedCycle = useRecoilValue(cycleSelector(cycleContext?.focusedCycleId));
  const space = useRecoilValue(spaceSelector(focusedCycle?.spaceId));
  const organization = useOrganization();
  const { host } = useConfiguration();
  const modals = useModals();
  const stopCycle = useStopCycle();
  const { production } = useConfiguration();

  if (!cycleContext || !focusedCycle || !space) {
    return [];
  }

  const commands: CommandDefinition[] = [
    {
      id: 'cycle-add-issues',
      description: `Add ${issueTerm} to cycle`,
      hotkey: addExistingKey,
      handler: async () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.ChangeCycleIssues,
          context: {
            cycleId: focusedCycle.id,
          },
        });
      },
    },
    {
      id: 'cycle-copy-link',
      description: 'Copy link to cycle',
      handler: async () => {
        writeToClipboard(`${host}${cyclePath(organization, space, focusedCycle)}`, 'link to cycle');
      },
    },
  ];

  if (!production) {
    commands.push({
      id: 'stop-cycle',
      description: 'Stop cycle',
      handler: async () => {
        stopCycle(focusedCycle.id);
      },
    });
  }

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Cycle }));
}
