import { isEqual, orderBy } from 'lodash';
import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { looksLikeId } from '../../../shared/utils/id';
import { documentsByFolder, documentsByOrganization } from '../../../sync/__generated/indexes';
import { Doc, Organization } from '../../../sync/__generated/models';
import { equalSelectorFamily } from '../../utils/recoil';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { organizationPath } from './organizations';

export function isDocument(doc: SyncEngineObject): doc is Doc {
  return doc.__typename === 'Doc';
}

export function documentPath(organization: Organization, doc: Doc) {
  return organizationPath(organization, `document/${doc.number}`);
}

export const documentSelector = selectorFamily({
  key: 'Doc',
  get:
    (docId: string | undefined | null) =>
    ({ get }) => {
      if (!docId) {
        return null;
      }
      return get(syncEngineState(docId)) as Doc | null;
    },
});

export const documentsSelector = selectorFamily({
  key: 'Docs',
  get:
    (docIds?: string[] | null) =>
    ({ get }) => {
      return filterNotDeletedNotNull((docIds ?? []).map(docId => get(documentSelector(docId))));
    },
});

export const documentIdsForOrganizationSelector = selectorFamily({
  key: 'DocIdsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const docIds = get(indexKeyState(indexKey(documentsByOrganization, organizationId)));
      return docIds;
    },
});

export const documentsForOrganizationSelector = selectorFamily({
  key: 'DocsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const docIds = get(documentIdsForOrganizationSelector(organizationId));
      return get(documentsSelector(docIds));
    },
});

export const documentIdsForFolderSelector = selectorFamily({
  key: 'DocIdsForFolder',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const docIds = get(indexKeyState(indexKey(documentsByFolder, organizationId)));
      return docIds;
    },
});

export const documentsForFolderSelector = selectorFamily({
  key: 'DocsForFolder',
  get:
    (folderId: string) =>
    ({ get }) => {
      const docIds = get(documentIdsForFolderSelector(folderId));
      return get(documentsSelector(docIds));
    },
});

export const unarchivedDocumentIdsForOrganizationSelector = selectorFamily({
  key: 'UnarchivedDocIdsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const docs = get(documentsForOrganizationSelector(organizationId));
      return docs.filter(d => d.archivedAt === null).map(d => d.id);
    },
});

export const unarchivedDocumentIdsForFolderSelector = selectorFamily({
  key: 'UnarchivedDocIdsForFolder',
  get:
    ({ organizationId, folderId }: { organizationId: string; folderId: string | null }) =>
    ({ get }) => {
      const docs = folderId
        ? get(documentsForFolderSelector(folderId))
        : get(documentsForOrganizationSelector(organizationId)).filter(d => !d.folderId);
      return docs.filter(d => d.archivedAt === null).map(d => d.id);
    },
});

export const documentByNumberSelector = selectorFamily({
  key: 'DocByNumber',
  get:
    ({ organizationId, docNumber }: { organizationId: string; docNumber: string }) =>
    ({ get }) => {
      const documents = get(documentsForOrganizationSelector(organizationId));
      return documents.find(d => d.number === docNumber || d.id === docNumber);
    },
});

export const documentIdByNumberSelector = selectorFamily({
  key: 'DocumentIdByNumber',
  get:
    ({ organizationId, docNumber: docNumber }: { organizationId: string; docNumber: string }) =>
    ({ get }) => {
      if (looksLikeId(docNumber)) {
        return docNumber;
      }

      return get(documentByNumberSelector({ organizationId, docNumber }))?.id;
    },
});

export const documentNumberSelector = selectorFamily({
  key: 'DocumentNumber',
  get:
    (docId: string | undefined) =>
    ({ get }) => {
      const doc = get(documentSelector(docId));
      if (!doc) {
        return null;
      }
      return doc.number;
    },
});

export const archivedDocumentIdsSelector = equalSelectorFamily({
  key: 'ArchivedDocs',
  get:
    ({ organizationId }: { organizationId: string }) =>
    ({ get }) => {
      const docs = get(documentsForOrganizationSelector(organizationId));
      const archivedInitiatives = orderBy(
        docs.filter(i => i.archivedAt !== null),
        ['archivedAt'],
        ['desc']
      );
      return archivedInitiatives.map(i => i.id);
    },
  equals: isEqual,
});
