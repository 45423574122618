import { round } from 'lodash';

export function prettyFileSize(size: number): string {
  if (size < 1024) {
    return `${size}b`;
  }

  if (size < 1024 * 1024) {
    return `${round(size / 1024, 1)}kb`;
  }

  return `${round(size / (1024 * 1024))}mb`;
}
