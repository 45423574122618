import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CommandGroup } from '../../../commands';
import { EntityCommandGroupContext, TodoCommandGroupContenxt } from '../../../commands/state';
import { Breadcrumbs } from '../../../components/new/breadcrumbs';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { EntityFilterMenu2 } from '../../../components/new/entityFilters2';
import { Filters } from '../../../components/new/filters2';
import {
  EditInitiativeListItem,
  InitiativeListItem,
} from '../../../components/new/initiativeListItem';
import {
  KeyNavigationProvider,
  useKeyNavigationState,
} from '../../../components/new/keyNavigation';
import Placeholder from '../../../components/new/placeholder';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { TodoListItem } from '../../../components/new/todoListItem';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { EditWorkItemListItem, WorkItemListItem } from '../../../components/new/workItemListItem';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useOrganization } from '../../../contexts/organizationContext';
import { SpaceProvider } from '../../../contexts/spaceContext';
import { OrganizationMarker } from '../../../graphql/smartLoad';
import {
  spaceIdForEntitySelector,
  spaceIdsForEntitiesSelector,
  useEntityNumberWidths,
} from '../../../syncEngine/selectors/entities';
import { myWorkSelector, objectTypeSelector } from '../../../syncEngine/selectors/myWork';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { todoSelector } from '../../../syncEngine/selectors/todos';
import { syncEngineState } from '../../../syncEngine/state';
import { SyncEngineObject } from '../../../syncEngine/types';
import { trackerPageLoad } from '../../../tracker';
import LoadingScreen from '../../loadingScreen';
import styles from './myWorkScreen.module.scss';

function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="listHeaderContainer">
      <div className="listHeader">{children}</div>
    </div>
  );
}

function MyWorkTodoListItem({
  id,
  keyNavId,
  className,
  style,
}: {
  id: string;
  keyNavId: string;
  className?: string;
  style?: React.CSSProperties;
}) {
  const todo = useRecoilValue(todoSelector(id));
  const spaceId = useRecoilValue(spaceIdForEntitySelector(todo?.entityId));

  return (
    <SpaceProvider spaceId={spaceId ?? ''}>
      <TodoListItem
        id={id}
        keyNavId={keyNavId}
        style={style}
        className={cn(styles.todo, className)}
      />
    </SpaceProvider>
  );
}

function ListItem({
  id: keyNavId,
  className,
  style,
  onEdit,
  onEditComplete,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onEdit?: () => void;
  onEditComplete?: () => void;
}) {
  const id = keyNavId;
  const spaceId = useRecoilValue(spaceIdForEntitySelector(id));
  const type = useRecoilValue(objectTypeSelector(id));

  switch (type) {
    case 'Issue':
      return (
        <SpaceProvider spaceId={spaceId ?? ''}>
          {onEditComplete ? (
            <EditWorkItemListItem
              id={id}
              onDone={onEditComplete}
              className={className}
              style={style}
            />
          ) : (
            <WorkItemListItem
              showStatus
              id={id}
              keyNavId={keyNavId}
              style={style}
              className={className}
              onChangeTitle={onEdit}
            />
          )}
        </SpaceProvider>
      );
    case 'Initiative':
      return (
        <>
          {onEditComplete ? (
            <EditInitiativeListItem
              id={id}
              onDone={onEditComplete}
              className={className}
              style={style}
            />
          ) : (
            <InitiativeListItem
              id={id}
              keyNavId={keyNavId}
              style={style}
              className={className}
              onChangeTitle={onEdit}
            />
          )}
        </>
      );
    case 'Todo':
      return <MyWorkTodoListItem id={id} keyNavId={keyNavId} style={style} className={className} />;
    default:
      return (
        <div>
          {type} - {id}
        </div>
      );
  }
}

function MyWorkPlaceholder() {
  return (
    <Placeholder icon={'my_work'} title={'You have nothing to do!'}>
      <span className="grayed">We'll collect all your work and tasks here.</span>
    </Placeholder>
  );
}

function MyWorkCommandContext() {
  const { focused, selected } = useKeyNavigationState();
  const ids = selected ? selected : focused ? [focused] : [];
  const focusedItem = useRecoilValue(syncEngineState(focused ?? '')) as SyncEngineObject | null;

  const context: TodoCommandGroupContenxt | EntityCommandGroupContext =
    focusedItem?.__typename === 'Todo'
      ? { group: CommandGroup.Todos, todoId: ids[0], focusedTodoId: focused }
      : { group: CommandGroup.Entities, entityIds: ids, focusedEntityId: focused };

  if (context.group === CommandGroup.Todos && !context.todoId) {
    return null;
  }

  return <CommandContext context={context} />;
}

export function MyWorkScreen() {
  const organization = useOrganization();
  const [visibleIds, setVisibleIds] = React.useState(new Set<string>());
  React.useEffect(() => trackerPageLoad('MyWork'), []);

  const { workItemsAndTodos, initiativesAndTodos } = useRecoilValue(
    myWorkSelector({
      organizationId: organization.id,
      filterId: 'mywork-filters',
      visibleIds,
    })
  );

  const items: Record<string, string[]> = React.useMemo(() => {
    return {
      ...(workItemsAndTodos.length ? { workItemsAndTodos } : {}),
      ...(initiativesAndTodos.length ? { initiativesAndTodos } : {}),
    };
  }, [workItemsAndTodos, initiativesAndTodos]);

  const allEntitiesAndTodos = [...workItemsAndTodos, ...initiativesAndTodos];

  const spaceIds = useRecoilValue(spaceIdsForEntitiesSelector(allEntitiesAndTodos));
  const numberColumnWidth = useEntityNumberWidths(spaceIds);

  React.useEffect(() => {
    setVisibleIds(oldSet => new Set([...oldSet, ...allEntitiesAndTodos]));
  }, [JSON.stringify(allEntitiesAndTodos)]);

  const initiallySelected = React.useMemo(() => {
    return items[Object.keys(items)?.[0]]?.[0];
  }, [items]);

  const organizationLoaded = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  if (!organizationLoaded) {
    return <LoadingScreen />;
  }

  return (
    <Screen>
      <TitleSetter title={`${organization.name} · My work`} />
      <ScreenHeader showSidebarOpener compensateForMacOSTrafficLights="auto">
        <Breadcrumbs breadcrumbs={[{ name: 'My work' }]} />
        <div className="row">
          <EntityFilterMenu2 id={`mywork-filters`} />
        </div>
      </ScreenHeader>
      <Filters id={`mywork-filters`} />
      {allEntitiesAndTodos.length === 0 && <MyWorkPlaceholder />}

      {allEntitiesAndTodos.length !== 0 && (
        <KeyNavigationProvider
          initiallyFocusedElementId={initiallySelected}
          columnIds={['my-work-entities']}
          disableEnsureVisible
        >
          <MyWorkCommandContext />
          <VirtualizedListView
            id={`my-work-entities`}
            className={'fullWidth grow'}
            style={
              {
                '--number-column-width': `${numberColumnWidth}px`,
              } as React.CSSProperties
            }
            sectionIds={Object.keys(items)}
            itemIds={items}
            sectionHeaderHeight={60}
            itemHeight={41}
            spacerHeight={32}
            renderSectionHeader={sectionId => {
              switch (sectionId) {
                case 'workItemsAndTodos':
                  return <SectionHeader>Work items</SectionHeader>;
                case 'initiativesAndTodos':
                  return <SectionHeader>Initiatives</SectionHeader>;
                default:
                  return null;
              }
            }}
            renderItem={(id, _sectionId, isFirst, isLast, edit) => {
              return (
                <ListItem
                  id={id}
                  className={cn('listItem', {
                    first: isFirst,
                    last: isLast,
                  })}
                  onEdit={edit?.start}
                  onEditComplete={edit?.end}
                />
              );
            }}
            renderPlaceholder={() => {
              return <div className="fullWidth row grayed">No entities found</div>;
            }}
          />
        </KeyNavigationProvider>
      )}
    </Screen>
  );
}
