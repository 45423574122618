import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { atomFamily, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { InitiativeStatus } from '../../../../shared/initiativeStatus';
import ExternalLink from '../../../components/externalLink';
import { FilterTabs } from '../../../components/filterTabs';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import {
  MetadataConfigurationButton,
  initiativeDefaultExcludeOptions,
} from '../../../components/metadataConfig';
import { Breadcrumbs, useSpaceBreadcrumb } from '../../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { Filters as Filters2 } from '../../../components/new/filters2';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
} from '../../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import Roadmap from '../../../components/new/metadata/roadmap';
import { MetadataSize } from '../../../components/new/metadata/size';
import { RoadmapPicker } from '../../../components/new/pickers/roadmapPicker';
import Placeholder from '../../../components/new/placeholder';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { Tooltip } from '../../../components/new/tooltip';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { ResponsiveDesignSize, useIsSmallScreen } from '../../../hooks/useResponsiveDesign';
import {
  useCreateInitiative,
  useUpdateInitiativeSpaceSorts,
} from '../../../syncEngine/actions/intiatives';
import { useSetSpaceRoadmap } from '../../../syncEngine/actions/spaces';
import { localStorageEffect } from '../../../syncEngine/effects';
import {
  filteredInitiativesForSpaceSelector,
  initiativePath,
  initiativeSpacesForSpaceSelector,
} from '../../../syncEngine/selectors/intiatives';
import { roadmapIdForSpaceSelector, roadmapSelector } from '../../../syncEngine/selectors/roadmaps';
import { spaceLoadedSelector } from '../../../syncEngine/selectors/smartLoader';
import { spacePath } from '../../../syncEngine/selectors/spaces';
import { trackerPageLoad } from '../../../tracker';
import {
  createNewEntityFromAnywhere,
  isMobileOS,
  roadmapAndInitiateivesGuideURL,
} from '../../../utils/config';
import { StarredType } from '../../../utils/starred';
import LoadingScreen from '../../loadingScreen';
import { InitiativesList } from './initiativesList';
import styles from './initiativesScreen.module.scss';

const initiativesScreenTab = atomFamily<string, string>({
  key: 'InitiativeScreenTab',
  default: 'all',
  effects: key => [localStorageEffect(`__initiativesScreenTab__${key}`)],
});

function filterId(spaceId: string) {
  return `initiatives-${spaceId}`;
}

function TopBar() {
  const space = useSpace();
  const [tab, setTab] = useRecoilState(initiativesScreenTab(space.id));

  return (
    <div className={styles.filterTabs}>
      <FilterTabs id={filterId(space.id)} tab={tab} setTab={setTab} />

      <MetadataConfigurationButton
        excludeOptions={initiativeDefaultExcludeOptions}
        boardId={filterId(space.id)}
      />
    </div>
  );
}

function RoadmapHeader() {
  const organization = useOrganization();
  const space = useSpace();
  const isSmallScreen = useIsSmallScreen();

  const [open, setOpen] = React.useState(false);
  const setSpaceRoadmap = useSetSpaceRoadmap();
  const roadmapId = useRecoilValue(roadmapIdForSpaceSelector(space.id));
  const roadmap = useRecoilValue(roadmapSelector(roadmapId ?? ''));
  const modals = useModals();
  const history = useHistory();

  const onPick = React.useCallback(
    (_: any, roadmapId: string) => {
      setSpaceRoadmap(space.id, roadmapId);
      setOpen(false);
      history.push(spacePath(organization, space, 'roadmap'));
    },
    [history, organization, setSpaceRoadmap, space]
  );

  if (!organization.newRoadmapsEnabled) {
    return null;
  }

  if (roadmap) {
    return (
      <Link to={spacePath(organization, space, 'roadmap')}>
        <Roadmap
          className="mr16"
          color={roadmap.color}
          name={roadmap.name}
          size={MetadataSize.Medium}
        />
      </Link>
    );
  }

  // PRIVATE-INITIATIVES-AND-ROADMAPS
  if (space.private) {
    const onClick = () => {
      modals.openModal(Modals.NewRoadmap, {
        spaceId: space.id,
        onCreated: () => {
          history.push(spacePath(organization, space, 'roadmap'));
        },
      });
    };

    return isSmallScreen ? (
      <Tooltip content="Add space roadmap">
        <IconButton
          className="mr8"
          icon="roadmap"
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={onClick}
        />
      </Tooltip>
    ) : (
      <Button className="mr8" icon="roadmap" buttonStyle={ButtonStyle.BareSubtle} onClick={onClick}>
        Add space roadmap
      </Button>
    );
  }

  const triggerButton = isSmallScreen ? (
    <IconButton className="mr8" icon="roadmap" buttonStyle={ButtonStyle.BareSubtle} />
  ) : (
    <Button className="mr8" icon="roadmap" buttonStyle={ButtonStyle.BareSubtle}>
      Add space roadmap
    </Button>
  );

  return (
    <DropdownMenu onOpenChange={setOpen} open={open}>
      <DropdownMenuTrigger asChild>{triggerButton}</DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
        className="menuPicker menuHuge"
      >
        <RoadmapPicker
          multi={false}
          noIcon
          filterPlaceholder={`Add roadmap to ${space.name}`}
          state={{}}
          onCreate={name => {
            setOpen(false);
            modals.openModal(Modals.NewRoadmap, {
              initialName: name,
              spaceId: space.id,
              onCreated: () => {
                history.push(spacePath(organization, space, 'roadmap'));
              },
            });
          }}
          onDone={() => {
            setOpen(false);
          }}
          onRoadmapAdded={onPick}
          onRoadmapRemoved={onPick}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function Header() {
  const organization = useOrganization();
  const history = useHistory();
  const space = useSpace();
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const isSmallScreen = useIsSmallScreen();

  const modals = useModals();
  const createInitiative = useCreateInitiative();

  const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isMobileOS) {
      e.preventDefault();
      e.stopPropagation();
      const initiative = createInitiative('New initiative', {
        spaceIds: [space.id],
      });
      history.push(initiativePath(organization, initiative.initiative));
      return;
    }

    modals.openModal(Modals.NewEntity);
  };

  return (
    <ScreenHeader
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={
        <>
          <RoadmapHeader />
          {isSmallScreen && (
            <Tooltip content="New initiative">
              <IconButton icon="add" onClick={onClick} />
            </Tooltip>
          )}
          {!isSmallScreen && (
            <>
              <Tooltip
                content={
                  <>
                    Create initiative <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
                  </>
                }
              >
                <Button icon="add" className="mr8" onClick={onClick}>
                  New initiative
                </Button>
              </Tooltip>
            </>
          )}
        </>
      }
    >
      <HideIfSmallerThan size={ResponsiveDesignSize.Tiny}>
        <Breadcrumbs
          breadcrumbs={[...spaceBreadcrumb, { name: 'Initiatives' }]}
          starred={{
            type: StarredType.SpaceInitiatives,
            id: space.id,
          }}
        />
      </HideIfSmallerThan>
    </ScreenHeader>
  );
}

export function InitiativesScreen() {
  const organization = useOrganization();
  const space = useSpace();
  const spaceLoaded = useRecoilValue(spaceLoadedSelector(space.id));
  const updateInitiativeSpaceSorts = useUpdateInitiativeSpaceSorts();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const history = useHistory();
  const createInitiative = useCreateInitiative();
  const modals = useModals();

  const tab = useRecoilValue(initiativesScreenTab(space.id));
  let status;

  if (tab === 'started') {
    status = InitiativeStatus.Started;
  } else if (tab === 'not started') {
    status = InitiativeStatus.NotStarted;
  } else if (tab === 'completed') {
    status = InitiativeStatus.Completed;
  }

  React.useEffect(() => {
    trackerPageLoad('InitiativeList');
  }, [space.id]);

  const initiativeIds = useRecoilValue(
    filteredInitiativesForSpaceSelector({ spaceId: space.id, filterId: filterId(space.id), status })
  );

  const move = useRecoilCallback(
    ({ snapshot }) =>
      async (initiativeIdsToMove: string[], index: number) => {
        const allInitiativeSpaces = [
          ...snapshot.getLoadable(initiativeSpacesForSpaceSelector(space.id)).getValue(),
        ];
        const initiativeSpaces = allInitiativeSpaces.filter(
          i => !initiativeIdsToMove.includes(i.initiativeId)
        );
        const filteredInitiativeSpaces = initiativeSpaces.filter(i =>
          initiativeIds.includes(i.initiativeId)
        );
        const initativeSpacesToMove = allInitiativeSpaces.filter(i =>
          initiativeIdsToMove.includes(i.initiativeId)
        );

        // we're inserting into the filtered list, but need to actually insert into the real list
        const filteredIndexToInsertAt = Math.min(index, filteredInitiativeSpaces.length);

        // if we're moving to the top, let's just put it at the top of the real list
        let indexToInsertAt = 0;

        // if we're not moving to the top, find the card we're going to place the card after
        if (filteredIndexToInsertAt !== 0) {
          const initiativeToInsertAfter = filteredInitiativeSpaces[filteredIndexToInsertAt - 1];
          indexToInsertAt = initiativeSpaces.indexOf(initiativeToInsertAfter) + 1;
        }

        initiativeSpaces.splice(indexToInsertAt, 0, ...initativeSpacesToMove);
        const previousInitiativeId: string | undefined = initiativeSpaces[indexToInsertAt - 1]?.id;
        const nextInitiativeId: string | undefined =
          initiativeSpaces[indexToInsertAt + initativeSpacesToMove.length]?.id;

        disableMissingElementDetection();
        updateInitiativeSpaceSorts(
          space.id,
          initativeSpacesToMove.map(i => i.id),
          previousInitiativeId,
          nextInitiativeId
        );
        enableMissingElementDetection();
      },
    [space.id, initiativeIds]
  );

  if (!spaceLoaded) {
    return <LoadingScreen />;
  }

  return (
    <Screen>
      <TitleSetter title={`${organization.name} · ${space.name} · Initiatives`} />
      <Header />
      <TopBar />
      <Filters2 id={filterId(space.id)} className={styles.filterTabs} />
      <InitiativesList boardId={filterId(space.id)} initiativeIds={initiativeIds} onMove={move} />
      {!initiativeIds.length && (
        <Placeholder icon={'initiative'} title={'No initiatives for this space yet'}>
          <span className="grayed textCenter">
            <p>
              Initiatives are larger chunks of work, bets, strategic goals, or projects.
              <br />
              Learn more in the{' '}
              <ExternalLink
                href={roadmapAndInitiateivesGuideURL}
                className="link hoverOnly headingS"
              >
                Kitemaker Guide.
              </ExternalLink>
            </p>
          </span>

          <Button
            icon="add"
            onClick={e => {
              if (isMobileOS) {
                e.preventDefault();
                e.stopPropagation();
                const initiative = createInitiative('New initiative');
                history.push(initiativePath(organization, initiative.initiative));
                return;
              }

              modals.openModal(Modals.NewEntity);
            }}
          >
            Create new initiative
          </Button>
        </Placeholder>
      )}
    </Screen>
  );
}
