import cn from 'classnames';
import { isString } from 'lodash';
import * as React from 'react';
import styles from './button.module.scss';
import { Icon, IconSize } from './icon';
import { DropdownMenuTrigger } from './menu/dropdownMenu';

export enum ButtonSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const sizeClasses = {
  [ButtonSize.ExtraSmall]: styles.extraSmall,
  [ButtonSize.Small]: styles.small,
  [ButtonSize.Medium]: styles.medium,
  [ButtonSize.Large]: styles.large,
};

export enum ButtonStyle {
  Primary = 'primary',
  PrimaryBlack = 'primaryBlack',
  Destructive = 'destructive',
  DestructiveSubtle = 'destructiveSubtle',
  Secondary = 'secondary',
  SecondarySubtle = 'secondarySubtle',
  SecondaryOverlay = 'secondaryOverlay',
  Bare = 'bare',
  BareSubtle = 'bareSubtle',
  BareSubtleNoHover = 'bareSubtleNoHover',
}

const styleClasses = {
  [ButtonStyle.Primary]: styles.primary,
  [ButtonStyle.PrimaryBlack]: styles.primaryBlack,
  [ButtonStyle.Destructive]: styles.destructive,
  [ButtonStyle.DestructiveSubtle]: styles.destructiveSubtle,
  [ButtonStyle.Secondary]: styles.secondary,
  [ButtonStyle.SecondarySubtle]: styles.secondarySubtle,
  [ButtonStyle.SecondaryOverlay]: styles.secondaryOverlay,
  [ButtonStyle.Bare]: styles.bare,
  [ButtonStyle.BareSubtle]: styles.bareSubtle,
  [ButtonStyle.BareSubtleNoHover]: styles.bareSubtleNoHover,
};

export type ButtonProperties = {
  size?: ButtonSize;
  buttonStyle?: ButtonStyle;
  fauxActive?: boolean;
  className?: string;
  style?: React.CSSProperties;
} & React.ComponentPropsWithoutRef<'button'>;

function BaseButtonComponent(
  {
    children,
    size = ButtonSize.Medium,
    buttonStyle = ButtonStyle.Secondary,
    fauxActive,
    className,
    style,
    onMouseDown,
    ...rest
  }: {
    children: React.ReactNode;
  } & ButtonProperties,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <button
      style={style}
      className={cn(
        styles.button,
        sizeClasses[size],
        styleClasses[buttonStyle],
        { [styles.fauxActive]: fauxActive },
        className
      )}
      ref={ref}
      {...rest}
      onMouseDown={e => {
        onMouseDown?.(e);
        e.preventDefault();
      }}
    >
      {children}
    </button>
  );
}

function IconButtonComponent(
  {
    icon,
    size = ButtonSize.Medium,
    className,
    ...rest
  }: {
    icon: string | React.ReactNode;
  } & ButtonProperties,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <BaseButton
      size={size}
      ref={ref}
      {...rest}
      className={cn(styles.iconButton, sizeClasses[size], className)}
    >
      {isString(icon) ? (
        <Icon
          className={cn(styles.icon)}
          icon={icon}
          size={size === ButtonSize.ExtraSmall ? IconSize.Size16 : IconSize.Size20}
        />
      ) : (
        icon
      )}
    </BaseButton>
  );
}

export function ButtonComponent(
  {
    children,
    icon,
    size,
    className,
    ...rest
  }: {
    children: string | React.ReactNode;
    icon?: string | React.ReactNode;
  } & ButtonProperties,
  ref?: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <BaseButton
      ref={ref}
      size={size}
      {...rest}
      className={cn(
        {
          [styles.buttonWithIcon]: !!icon,
        },
        className
      )}
    >
      {icon && typeof icon === 'string' && (
        <Icon
          icon={icon}
          className={styles.icon}
          size={size === ButtonSize.ExtraSmall ? IconSize.Size16 : IconSize.Size20}
        />
      )}
      {icon && typeof icon !== 'string' && <>{icon}</>}
      {children}
    </BaseButton>
  );
}

export function IconButtonTrigger({
  icon,
  size = ButtonSize.Medium,
  buttonStyle = ButtonStyle.Secondary,
  fauxActive,
  className,
  ...rest
}: {
  icon: React.ReactNode | string;
} & ButtonProperties) {
  return (
    <DropdownMenuTrigger
      className={cn(
        styles.button,
        styles.iconButton,
        sizeClasses[size],
        styleClasses[buttonStyle],
        { [styles.fauxActive]: fauxActive },
        className
      )}
      {...rest}
    >
      {icon && typeof icon === 'string' && (
        <Icon
          icon={icon}
          className={styles.icon}
          size={size === ButtonSize.ExtraSmall ? IconSize.Size16 : IconSize.Size20}
        />
      )}
      {icon && typeof icon !== 'string' && <>{icon}</>}
    </DropdownMenuTrigger>
  );
}

export const BaseButton = React.forwardRef(BaseButtonComponent);
export const IconButton = React.forwardRef(IconButtonComponent);
export const Button = React.forwardRef(ButtonComponent);
