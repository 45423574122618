import React from 'react';
import { useRecoilValue } from 'recoil';
import { Feedback } from '../../../../sync/__generated/models';
import { PickerState } from '../../../components/new/picker';
import { MemberPicker } from '../../../components/new/pickers/memberPicker';
import {
  useAddOwnersToFeedback,
  useRemoveOwnersFromFeedback,
} from '../../../syncEngine/actions/feedback';
import { feedbacksSelector } from '../../../syncEngine/selectors/feedback';
import styles from '../commandMenu.module.scss';

export function feedbackToMemberPickerState(feedback: Array<Feedback>): PickerState {
  return feedback.reduce((result, f) => {
    result[f.id] = f.ownerIds;
    return result;
  }, {} as PickerState);
}

export function ChangeOwnersContents({
  onClose,
  feedbackIds,
}: {
  onClose: () => void;
  feedbackIds: string[];
}) {
  const addOwnersToFeedback = useAddOwnersToFeedback();
  const removeOwnersFromFeedback = useRemoveOwnersFromFeedback();
  const feedbacks = useRecoilValue(feedbacksSelector(feedbackIds));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <MemberPicker
        filterClassName={styles.inputDiv}
        term="owner"
        state={feedbackToMemberPickerState(feedbacks)}
        onMemberAdded={(feedbackIds, userId) => {
          addOwnersToFeedback(feedbackIds, [userId]);
        }}
        onMemberRemoved={(feedbackIds, userId) => {
          removeOwnersFromFeedback(feedbackIds, [userId]);
        }}
        onDone={onClose}
      />
    </div>
  );
}
