import { isEqual } from 'lodash';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Breadcrumbs, useSpaceBreadcrumb } from '../../../components/new/breadcrumbs';
import { EntityFilterMenu } from '../../../components/new/entityFilters';
import { Filters } from '../../../components/new/filters';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { Tab, Tabs } from '../../../components/new/tabs';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { archivedDocumentIdsSelector } from '../../../syncEngine/selectors/documents';
import {
  archivedInitiativesSelector,
  filteredArchivedInitiativesForOrganizationSelector,
} from '../../../syncEngine/selectors/intiatives';
import { archivedIssuesSelector } from '../../../syncEngine/selectors/issues';
import { organizationPath } from '../../../syncEngine/selectors/organizations';
import {
  archivedReleasesSelector,
  filteredArchivedReleasesForOrganizationSelector,
} from '../../../syncEngine/selectors/releases';
import { orgLoadedSelector, spaceLoadedSelector } from '../../../syncEngine/selectors/smartLoader';
import { spacePath } from '../../../syncEngine/selectors/spaces';
import { equalSelectorFamily } from '../../../utils/recoil';
import { StarredType } from '../../../utils/starred';
import LoadingScreen from '../../loadingScreen';
import { DocumentArchive } from './documentArchive';
import { InitiativeArchive } from './initiativeArchive';
import { ReleaseArchive } from './releaseArchive';
import { WorkItemArchive } from './workItemArchive';

enum ArchiveTabs {
  WorkItems = 'workItems',
  Initiatives = 'initaitives',
  Documents = 'documents',
  Releases = 'releases',
}

const archivedEntityCounts = equalSelectorFamily({
  key: 'ArchivedEntityCounts',
  get:
    ({
      organizationId,
      spaceId,
      filterId,
    }: {
      organizationId: string;
      spaceId?: string;
      filterId: string;
    }) =>
    ({ get }) => {
      const issues = get(archivedIssuesSelector({ spaceId: spaceId ?? '', filterId }));
      const initiatives = get(
        spaceId
          ? archivedInitiativesSelector({ spaceId: spaceId ?? '', filterId })
          : filteredArchivedInitiativesForOrganizationSelector({
              organizationId,
              filterId,
            })
      );
      const releases = get(
        spaceId
          ? archivedReleasesSelector({ spaceId: spaceId ?? '', filterId })
          : filteredArchivedReleasesForOrganizationSelector({
              organizationId,
              filterId,
            })
      );
      const documents = get(archivedDocumentIdsSelector({ organizationId }));

      return {
        workItemCount: issues.length,
        initiativesCount: initiatives.length,
        documentsCount: documents.length,
        releasesCount: releases.length,
      };
    },
  equals: isEqual,
});

function ArchiveTabSwitcher({
  currentTab,
  onTabChanged,
}: {
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();

  const { workItemCount, initiativesCount, documentsCount, releasesCount } = useRecoilValue(
    archivedEntityCounts({
      organizationId: organization.id,
      spaceId: space?.id,
      filterId: `archive-${space?.id ?? organization.id}`,
    })
  );

  const tabs: Tab[] = React.useMemo(() => {
    if (space?.id) {
      return [
        {
          id: ArchiveTabs.WorkItems,
          name: 'Work items',
          count: workItemCount,
        },
        {
          id: ArchiveTabs.Initiatives,
          name: 'Initiatives',
          count: initiativesCount,
        },
        ...(organization.releasesEnabled
          ? [
              {
                id: ArchiveTabs.Releases,
                name: 'Releases',
                count: releasesCount,
              },
            ]
          : []),
      ];
    }

    const t = [
      {
        id: ArchiveTabs.Initiatives,
        name: 'Initiatives',
        count: initiativesCount,
      },
      ...(organization.releasesEnabled
        ? [
            {
              id: ArchiveTabs.Releases,
              name: 'Releases',
              count: releasesCount,
            },
          ]
        : []),
    ];

    if (organization.documentsEnabled) {
      t.push({
        id: ArchiveTabs.Documents,
        name: 'Documents',
        count: documentsCount,
      });
    }
    return t;
  }, [
    workItemCount,
    initiativesCount,
    documentsCount,
    space?.id,
    organization.documentsEnabled,
    releasesCount,
  ]);

  return (
    <div className="basePadding mt20 fullWidth">
      <Tabs tabs={tabs} ariaLabel="Archive" currentTab={currentTab} onTabChanged={onTabChanged} />
    </div>
  );
}
export function ArchiveScreen() {
  const history = useHistory();
  const organization = useOrganization();
  const space = useMaybeSpace();
  const spaceLoaded = useRecoilValue(spaceLoadedSelector(space?.id ?? ''));
  const orgLoaded = useRecoilValue(orgLoadedSelector(organization.id));
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const params = useRouteMatch<{ archive?: string }>().params;
  const currentTab = params.archive ?? (space ? ArchiveTabs.WorkItems : ArchiveTabs.Initiatives);

  if ((space && !spaceLoaded) || (!space && !orgLoaded)) {
    return <LoadingScreen />;
  }

  let content = null;
  switch (currentTab) {
    case ArchiveTabs.WorkItems:
      content = <WorkItemArchive />;
      break;
    case ArchiveTabs.Initiatives:
      content = <InitiativeArchive />;
      break;
    case ArchiveTabs.Documents:
      content = <DocumentArchive />;
      break;
    case ArchiveTabs.Releases:
      content = <ReleaseArchive />;
      break;
  }

  return (
    <Screen>
      <TitleSetter title={`${organization.name} ${space ? `· ${space.name}` : ``}`} />
      <ScreenHeader showSidebarOpener compensateForMacOSTrafficLights="auto">
        <Breadcrumbs
          breadcrumbs={[...spaceBreadcrumb, { name: 'Archive' }]}
          starred={{
            type: StarredType.Archive,
            id: space?.id ?? organization.id,
          }}
        />
        <div className="row">
          <EntityFilterMenu id={`archive-${space?.id ?? organization.id}`} entityType="Issue" />
        </div>
      </ScreenHeader>
      <Filters id={`archive-${space?.id} ?? ${organization.id}`} />
      <ArchiveTabSwitcher
        currentTab={currentTab}
        onTabChanged={tab => {
          history.push(
            space
              ? spacePath(
                  organization,
                  space,
                  `archive${tab === ArchiveTabs.WorkItems ? '' : `/${tab}`}`
                )
              : organizationPath(
                  organization,
                  `archive${tab === ArchiveTabs.Initiatives ? '' : `/${tab}`}`
                )
          );
        }}
      />

      {content}
    </Screen>
  );
}
