import cn from 'classnames';
import * as React from 'react';
import { Activity as ActivityModel } from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { useCurrentUser } from '../../../contexts/userContext';
import { useUpdateActivities } from '../../../syncEngine/actions/activities';
import { useActivityUrl } from '../../../syncEngine/selectors/activities';
import { copyActivityLinkKey, reactionKey } from '../../../utils/config';
import { writeToClipboard } from '../../clipboardText';
import { ActorAvatar } from '../actorAvatar';
import { ButtonSize, ButtonStyle, IconButton } from '../button';
import { CommandContext } from '../commandMenuContext';
import { CustomCommand } from '../customCommand';
import { EmojiPicker } from '../emojiPicker';
import { Icon, IconSize } from '../icon';
import { KeyboardShortcut } from '../keyboardShortcut';
import { KeyNavigationElement, useHasKeyNavigationFocus } from '../keyNavigation';
import { AvatarSize } from '../metadata/avatar';
import Popover from '../popover';
import { ReactionTooltip, Reactions, useToggleReactions } from '../reactions';
import { Timestamp } from '../timestamp';
import { Tooltip } from '../tooltip';
import styles from './activities.module.scss';

function ActivityActions({
  activity,
  additionalActions,
}: {
  activity: ActivityModel;
  additionalActions?: React.ReactNode;
}) {
  const focused = useHasKeyNavigationFocus(activity.id);
  const user = useCurrentUser();
  const updateActivities = useUpdateActivities();
  const activityUrl = useActivityUrl();
  const toggleReaction = useToggleReactions(user.id, activity.reactions, reactions =>
    updateActivities([activity.id], { reactions })
  );

  const [emojiPopoverOpen, setEmojiPopoverOpen] = React.useState(false);
  const closeEmojiPopover = React.useCallback(
    () => setEmojiPopoverOpen(false),
    [setEmojiPopoverOpen]
  );

  return (
    <div
      className={cn(styles.actions, {
        [styles.menuOpen]: emojiPopoverOpen,
      })}
    >
      {additionalActions}
      {focused && (
        <>
          <CommandContext context={{ group: CommandGroup.Activity, activityId: activity.id }} />
          <CustomCommand
            command={{
              id: 'react-to-activity',
              group: CommandGroup.Activity,
              hotkey: reactionKey,
              description: 'React to activity',
              handler: () => setEmojiPopoverOpen(true),
            }}
          />
        </>
      )}
      <Tooltip
        disabled={emojiPopoverOpen}
        content={
          <>
            Add reaction <KeyboardShortcut shortcut={reactionKey} />
          </>
        }
      >
        <div className="noLineHeight">
          <Popover
            open={emojiPopoverOpen}
            onOpenChange={setEmojiPopoverOpen}
            asChild
            content={
              <EmojiPicker
                onPicked={reaction => {
                  closeEmojiPopover();
                  toggleReaction(reaction);
                }}
              />
            }
            contentOptions={{
              align: 'end',
              alignOffset: -52,
            }}
          >
            <IconButton
              icon="emoji"
              buttonStyle={ButtonStyle.BareSubtle}
              size={ButtonSize.Small}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setEmojiPopoverOpen(true);
              }}
            />
          </Popover>
        </div>
      </Tooltip>
      <Tooltip
        content={
          <>
            Copy link <KeyboardShortcut shortcut={copyActivityLinkKey} />
          </>
        }
      >
        <IconButton
          icon="link"
          buttonStyle={ButtonStyle.BareSubtle}
          size={ButtonSize.Small}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            const url = activityUrl(activity);
            writeToClipboard(url, 'Activity link');
          }}
        />
      </Tooltip>
    </div>
  );
}

export function Activity({
  actorId,
  icon,
  activity,
  additionalActions,
  children,
}: {
  actorId?: string;
  icon?: string | React.ReactNode;
  activity: ActivityModel;
  additionalActions?: React.ReactNode;
  children: React.ReactNode;
}) {
  const user = useCurrentUser();
  const updateActivities = useUpdateActivities();

  return (
    <KeyNavigationElement id={activity.id} className={styles.activity}>
      <ActivityActions activity={activity} additionalActions={additionalActions} />
      {!actorId && (typeof icon === 'string' || icon === undefined) && (
        <Icon
          size={IconSize.Size24}
          icon={icon ? icon : 'activity_item_default'}
          className="mr12"
        />
      )}

      {icon !== undefined && typeof icon !== 'string' && <div className="mr12">{icon}</div>}

      {actorId && (
        <ActorAvatar
          size={AvatarSize.Size24}
          actorId={actorId}
          className={cn(styles.actorAvatar, 'mr12')}
        />
      )}
      <div className={styles.activityContent}>
        {children}
        {activity.reactions.length > 0 && (
          <Reactions
            className="mt6"
            currentUserId={user.id}
            reactions={activity.reactions}
            onReactionsChanged={reactions => {
              updateActivities([activity.id], { reactions });
            }}
            tooltipContents={(reaction, actorIds) => (
              <ReactionTooltip reaction={reaction} actorIds={actorIds} />
            )}
          />
        )}
      </div>
    </KeyNavigationElement>
  );
}

export function EmptyActivity({
  keynavId,
  actorId,
  icon,
  children,
}: {
  keynavId: string;
  actorId?: string;
  icon?: string | React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <KeyNavigationElement id={keynavId} className={styles.activity}>
      {!actorId && (typeof icon === 'string' || icon === undefined) && (
        <Icon
          size={IconSize.Size24}
          icon={icon ? icon : 'activity_item_default'}
          className="mr12"
        />
      )}

      {icon !== undefined && typeof icon !== 'string' && <div className="mr12">{icon}</div>}

      {actorId && (
        <ActorAvatar
          size={AvatarSize.Size24}
          actorId={actorId}
          className={cn(styles.actorAvatar, 'mr12')}
        />
      )}
      <div className={styles.activityContent}>{children}</div>
    </KeyNavigationElement>
  );
}

export function ActivityTimestamp({ timestamp }: { timestamp: number }) {
  return <Timestamp className="oneLine grayed ml8" timestamp={timestamp} />;
}
