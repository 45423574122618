import * as React from 'react';
import { OptionalAttributesRenderElementProps } from '../types';

export function DummyNode<T extends React.ElementType>({
  as,
  children,
  ...props
}: React.ComponentPropsWithoutRef<T> & { as?: T } & OptionalAttributesRenderElementProps) {
  const Component = as ?? 'div';
  return <Component {...props}>{children}</Component>;
}
