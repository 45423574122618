import * as React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { RoadmapType } from '../../sync/__generated/models';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle, IconButtonTrigger } from '../components/new/button';
import { ColorPickerContent } from '../components/new/colorPicker';
import { Hotkey } from '../components/new/hotkey';
import { KeyboardShortcut } from '../components/new/keyboardShortcut';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../components/new/menu/dropdownMenu';
import { MetadataSize } from '../components/new/metadata/size';
import Spaces from '../components/new/metadata/space';
import { InitiativePicker } from '../components/new/pickers/initiativePicker';
import { SpacePicker } from '../components/new/pickers/spacePicker';
import { Select, SelectSize } from '../components/new/select';
import { TextInput, TextInputSize, TextInputType } from '../components/new/textInput';
import { Tooltip } from '../components/new/tooltip';
import { useConfiguration } from '../contexts/configurationContext';
import { Modals, NewRoadmapArgs, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { RoadmapIcon } from '../screens/new/newRoadmapScreen/roadmapIcon';
import { useCreateRoadmap } from '../syncEngine/actions/roadmaps';
import { roadmapPath, roadmapsForOrganizationSelector } from '../syncEngine/selectors/roadmaps';
import { spaceNamesSelector, spaceSelector } from '../syncEngine/selectors/spaces';
import { labelColors, mainComboKey } from '../utils/config';
import styles from './newRoadmapModal.module.scss';

function SpacesButton({
  spaceIds,
  setSpaceIds,
}: {
  spaceIds: string[];
  setSpaceIds: (spaceIds: string[]) => void;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const spaceNames = useRecoilValue(spaceNamesSelector(spaceIds));

  const content = (
    <Spaces size={MetadataSize.Medium} names={spaceNames.length ? spaceNames : ['Add to space']} />
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <div>{content}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
        className="menuPicker menuMedium"
      >
        <SpacePicker
          filterPlaceholder="Add to space"
          multi
          state={{ DUMMY_ID: spaceIds }}
          onSpaceAdded={(_issueIds, spaceId) => {
            setSpaceIds([...spaceIds, spaceId]);
          }}
          onSpaceRemoved={(_issueIds, spaceId) => {
            setSpaceIds(spaceIds.filter(l => l !== spaceId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function NewRoadmapContents({
  initialName,
  spaceId,
  onCreated,
}: {
  initialName?: string;
  spaceId?: string;
  onCreated?: (roadmapId: string) => void;
}) {
  const organization = useOrganization();
  const { featureFlags } = useConfiguration();
  const roadmaps = useRecoilValue(roadmapsForOrganizationSelector(organization.id));
  const modals = useModals();
  const [type, setType] = React.useState(RoadmapType.Column);
  const createRoadmap = useCreateRoadmap();
  const ref = React.useRef<HTMLInputElement | null>(null);
  const [color, setColor] = React.useState(labelColors[roadmaps.length % labelColors.length]);
  const [open, setOpen] = React.useState(false);
  const [selectedInitiatives, setSelectedInitiatives] = React.useState<string[]>([]);
  const history = useHistory();
  const space = useRecoilValue(spaceSelector(spaceId));
  const [spaceIds, setSpacesIds] = React.useState<string[]>(spaceId ? [spaceId] : []);

  useComponentDidMount(() => {
    setTimeout(() => {
      ref.current?.focus();
      if (ref.current && initialName) {
        ref.current.value = initialName;
      }
    }, 5);
  });

  const submit = React.useCallback(() => {
    modals.closeModal(Modals.NewRoadmap);
    const roadmap = createRoadmap(
      ref.current?.value || 'Untitled roadmap',
      color,
      selectedInitiatives,
      spaceIds,
      type
    );
    if (onCreated) {
      onCreated(roadmap.id);
    } else {
      history.push(roadmapPath(organization, roadmap.id));
    }
  }, [
    color,
    createRoadmap,
    history,
    modals,
    onCreated,
    organization,
    selectedInitiatives,
    spaceIds,
    type,
  ]);

  return (
    <div className={styles.modal}>
      <ModalContentWrapper className={styles.header}>
        <div className="row fullWidth">
          <DropdownMenu open={open} onOpenChange={setOpen}>
            <IconButtonTrigger icon={<RoadmapIcon color={color} />} />
            <DropdownMenuContent>
              <ColorPickerContent
                color={color}
                onColorPicked={(color: string | null) => {
                  setColor(color ?? 'gray');
                  setOpen(false);
                  setTimeout(() => ref.current?.focus());
                }}
              />
            </DropdownMenuContent>
          </DropdownMenu>
          <TextInput
            ref={ref}
            autoFocus
            className="grow"
            style={{ fontSize: 20 }}
            placeholder="Roadmap title"
            inputSize={TextInputSize.Large}
            inputType={TextInputType.Bare}
          />
          {featureFlags.FEATURE_TOGGLE_TIMELINE && <TypeButton type={type} setType={setType} />}
          <SpacesButton spaceIds={spaceIds} setSpaceIds={setSpacesIds} />
        </div>
      </ModalContentWrapper>
      <div className={styles.pickerWrapper}>
        <InitiativePicker
          inputType={TextInputType.Input}
          space={space}
          showMetadata
          disableAutoFocus
          placeholder="No initiatives found"
          className={styles.picker}
          filterClassName={styles.filter}
          state={{ DUMMY: selectedInitiatives }}
          hideFooter
          onInitiativeAdded={(_ids, initiativeId) => {
            setSelectedInitiatives(old => [...old, initiativeId]);
          }}
          onInitiativeRemoved={(_ids, initiativeId) => {
            setSelectedInitiatives(old => old.filter(id => id !== initiativeId));
          }}
        />
      </div>
      <ModalButtonWrapper>
        <Button
          onClick={() => {
            modals.closeModal(Modals.NewRoadmap);
          }}
        >
          Cancel
        </Button>
        <Tooltip
          content={
            <>
              Create roadmap <KeyboardShortcut shortcut={`${mainComboKey}+enter`} />
            </>
          }
        >
          <Button onClick={submit} buttonStyle={ButtonStyle.Primary}>
            Create roadmap
          </Button>
        </Tooltip>
      </ModalButtonWrapper>
      <Hotkey
        hotkey={`${mainComboKey}+enter`}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          submit();
        }}
      />
    </div>
  );
}

function TypeButton({ type, setType }: { type: string; setType: (type: RoadmapType) => void }) {
  const items = [
    { label: 'Column', value: RoadmapType.Column },
    { label: 'Timeline', value: RoadmapType.Timeline },
  ];
  return (
    <Select
      size={SelectSize.Small}
      className="mr8"
      values={items}
      value={type}
      onChange={setType as (value: string) => void}
    />
  );
}

export default function NewRoadmapModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as NewRoadmapArgs) : null;
  return (
    <Modal topAligned modalId={Modals.NewRoadmap} hideHeader={true}>
      <NewRoadmapContents
        initialName={args?.initialName}
        spaceId={args?.spaceId}
        onCreated={args?.onCreated}
      />
    </Modal>
  );
}
