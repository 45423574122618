import { Dictionary, chain, groupBy, isNil, keyBy, omitBy, pickBy, sortBy } from 'lodash';
import { GetRecoilValue, atomFamily, selectorFamily } from 'recoil';
import { filterNotNull } from '../../../shared/utils/convenience';
import {
  Activity,
  ActivityType,
  CodeReviewRequestAddedActivityDetails,
  Cycle,
  CycleEntity,
  IntegrationType,
  Issue,
  IssueStatusType,
  MentionedInIntegrationActivityDetails,
  StatusChangedActivityDetails,
  Todo,
  TodoChangedActivityDetails,
  TodoStatus,
} from '../../../sync/__generated/models';
import { localStorageEffect } from '../../syncEngine/effects';
import {
  activitiesSelector,
  activitySelector,
  isActivity,
} from '../../syncEngine/selectors/activities';
import { actorsSelector } from '../../syncEngine/selectors/actors';
import { codeReviewsForIssueSelector } from '../../syncEngine/selectors/codeReviewRequests';
import { commentsSelector } from '../../syncEngine/selectors/comments';
import {
  cycleEntitiesForCycleSelector,
  cycleSelector,
  cycleTodosForCycleSelector,
} from '../../syncEngine/selectors/cycles';
import { hideSnoozedAtom, issueSelector } from '../../syncEngine/selectors/issues';
import { statusesForSpaceSelector } from '../../syncEngine/selectors/issueStatuses';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import {
  todoSelector,
  todosForEntitySelector,
  todosSelector,
} from '../../syncEngine/selectors/todos';
import { codeReviewRequestSelector } from '../../syncEngine/selectors/updates';
import { currentUserMembershipState } from '../../syncEngine/selectors/users';

function generateIssueTodoList(
  get: GetRecoilValue,
  cycle: Cycle,
  issueIds: string[],
  todoIds: string[],
  hideCompleted?: boolean,
  hideSnoozed?: boolean
) {
  const allIssues = filterNotNull(
    issueIds.map(id => {
      return get(issueSelector(id));
    })
  );
  const allTodos = filterNotNull(
    todoIds.map(id => {
      return get(todoSelector(id));
    })
  ).filter(todo => {
    return !todo.orphaned;
  });

  const doneStatuses = get(statusesForSpaceSelector(cycle.spaceId))
    .filter(s => s.statusType === IssueStatusType.Done || s.statusType === IssueStatusType.Archived)
    .map(s => s.id);

  let issues = hideCompleted
    ? allIssues.filter(i => !doneStatuses.includes(i.statusId))
    : allIssues;

  let snoozedById = {} as Record<string, { id: string; snoozedUntil: number }>;

  if (hideSnoozed) {
    const organizationId = get(spaceSelector(cycle.spaceId))?.organizationId;

    const orgMembership = get(currentUserMembershipState(organizationId));

    if (orgMembership?.snoozed?.length) {
      snoozedById = keyBy(orgMembership.snoozed, 'id');
      issues = issues.filter((e: Issue) => {
        const snoozed = snoozedById[e.id];
        if (!snoozed) {
          return true;
        }
        return snoozed.snoozedUntil < Date.now().valueOf();
      });
    }
  }

  const todos = hideCompleted ? allTodos.filter(t => t.status !== TodoStatus.Done) : allTodos;

  const issueIdsSet = new Set(issues.map(i => i.id));
  const todoIdsSet = new Set(todos.map(t => t.id));

  const missingCompletedIssues = new Set<string>();
  todos.forEach(todo => {
    if (!issueIdsSet.has(todo.entityId)) {
      if (hideSnoozed) {
        const snoozed = snoozedById[todo.entityId];
        if (snoozed?.snoozedUntil > Date.now().valueOf()) {
          return;
        }
      }
      missingCompletedIssues.add(todo.entityId);
    }
  });

  missingCompletedIssues.forEach(id => {
    const issue = get(issueSelector(id));
    if (issue) {
      issues.push(issue);
    }
  });

  const cycleEntityIndex = keyBy(
    get(cycleEntitiesForCycleSelector(cycle.id)),
    (ce: CycleEntity) => ce.entityId
  );
  const sortedIssues = sortBy(issues, i => cycleEntityIndex[i.id]?.sort);

  const all = sortedIssues.reduce((acc, issue) => {
    acc.push(issue);
    const todos = get(todosForEntitySelector(issue.id));
    const allTodosWithParents = new Set<string>();
    // walk them in reverse so we see the children before the parent
    for (const todo of [...todos].reverse()) {
      if (todoIdsSet.has(todo.id)) {
        allTodosWithParents.add(todo.id);
      }
      if (allTodosWithParents.has(todo.id) && todo.parentId) {
        allTodosWithParents.add(todo.parentId);
      }
    }
    const todosToAdd = todos.filter(todo => allTodosWithParents.has(todo.id));
    acc.push(...todosToAdd);
    return acc;
  }, [] as (Issue | Todo)[]);

  return all;
}

export const completedCycleSelector = selectorFamily({
  key: 'CompletedCycleScreen',
  get:
    (cycleId: string) =>
    ({ get }) => {
      // FIXME: probably think about how to do this effiecently instead of brute forcing it
      const cycle = get(cycleSelector(cycleId));
      if (!cycle || !cycle.history) {
        return { completed: [] as string[], incomplete: [] as string[] };
      }

      const completed = generateIssueTodoList(
        get,
        cycle,
        cycle.history.completedEntities,
        cycle.history.completedTodos
      ).map(o => `complete-${o.id}`);
      const incomplete = generateIssueTodoList(
        get,
        cycle,
        cycle.history.incompleteEntities,
        cycle.history.incompleteTodos
      ).map(o => `incomplete-${o.id}`);
      return { completed, incomplete };
    },
});

export const hideCompletedInCycleAtom = atomFamily<boolean, string>({
  key: 'HideCompletedInCycle',
  default: false,
  effects: key => [localStorageEffect(`__hideCompletedInCycle_${key}`)],
});

export const cycleScreenSelector = selectorFamily({
  key: 'CycleScreen',
  get:
    (cycleId: string) =>
    ({ get }) => {
      // FIXME: probably think about how to do this effiecently instead of brute forcing it
      const cycle = get(cycleSelector(cycleId));
      if (!cycle) {
        return {};
      }

      const cycleEntities = get(cycleEntitiesForCycleSelector(cycleId));
      const cycleTodos = get(cycleTodosForCycleSelector(cycleId));
      const hideCompleted = get(hideCompletedInCycleAtom(cycle.spaceId));
      const hideSnoozed = get(hideSnoozedAtom('cycle'));

      const all = generateIssueTodoList(
        get,
        cycle,
        cycleEntities.filter(ce => !ce.ghost).map(ce => ce.entityId),
        cycleTodos.map(ct => ct.todoId),
        hideCompleted,
        hideSnoozed
      );

      return all.reduce((acc, o) => {
        if (o.__typename === 'Issue') {
          acc[o.id] = [];
        } else {
          acc[o.entityId].push(o.id);
        }
        return acc;
      }, {} as Record<string, string[]>);
    },
});

export type EntityCycleSummary = {
  statusChangeIds: string[];
  todos: { id: string; status?: TodoStatus }[];
  memberIds: string[];
  commentIds: string[];
  slackMentionIds: string[];
  discordMentionIds: string[];
  figmaMentionIds: string[];
  codeReviewRequestIds: string[];
  commitData: {
    [branch: string]: number;
  };
  ghost: boolean;
};

export const cycleSummarySelector = selectorFamily({
  key: 'CycleSummary',
  get:
    (cycleId: string) =>
    ({ get }) => {
      const cycle = get(cycleSelector(cycleId));
      if (!cycle) {
        return { planned: {}, unplanned: {} };
      }
      const activities = get(activitiesSelector(cycle.activityIds));
      const comemnts = get(commentsSelector(cycle.commentIds));
      const cycleEntities = get(cycleEntitiesForCycleSelector(cycleId));
      const statuses = get(statusesForSpaceSelector(cycle.spaceId));
      const statusesById = keyBy(statuses, s => s.id);

      const cycleEntitiesByEntityId = keyBy(cycleEntities, 'entityId');
      const entitiesInCycle = cycleEntities.map(ce => ce.entityId);
      const entitiesInCycleSet = new Set(cycleEntities.map(ce => ce.entityId));

      const cycleTodosInCycle = get(cycleTodosForCycleSelector(cycleId));
      const todosInCycle = get(todosSelector(cycleTodosInCycle.map(ct => ct.todoId)));
      const todosInCycleById = keyBy(todosInCycle, 'id');

      // creates a map of entity id to everything that happened related to that entity in the cycle
      const activitiesByEntity = chain([...activities, ...comemnts])
        .groupBy(a => a.entityId) // group up the activities and comments by entity
        .mapValues(v => {
          // for every entity...

          const entity = get(issueSelector(v[0].entityId));
          if (!entity) {
            return null;
          }

          // get all the members that worked on the entity
          const memberIds = chain(v)
            .map(a => a.actorId)
            .uniq()
            .map(id => get(actorsSelector(id)))
            .filter(a => a !== null && a.__typename === 'User')
            .map(a => a!.id)
            .value();

          // get all the comments on the entity
          const commentIds = chain(v)
            .filter(a => a.__typename === 'Comment')
            .map(a => a.id)
            .value();

          // group the activities up by type
          const activitiesByType = chain(v)
            .filter(a => isActivity(a))
            .sortBy(a => a.createdAt)
            .groupBy(a => (a as Activity).activityType)
            .value() as Dictionary<Activity[]>;

          // get all the integration mention activities
          const mentionedInIntegrationActivities =
            activitiesByType[ActivityType.MentionedInIntegration];
          // group them by integration type
          const mentionsByType = groupBy(
            mentionedInIntegrationActivities,
            a => (a.details as MentionedInIntegrationActivityDetails).type
          );

          // create list of ids for each integration type
          const slackMentionIds = [
            ...(mentionsByType[IntegrationType.Slack]?.map(a => a.id) ?? []),
            ...(mentionsByType[IntegrationType.Slack2]?.map(a => a.id) ?? []),
          ];
          const figmaMentionIds = mentionsByType[IntegrationType.Figma]?.map(a => a.id) ?? [];
          const discordMentionIds = mentionsByType[IntegrationType.Discord]?.map(a => a.id) ?? [];
          const githubMentions = mentionsByType[IntegrationType.Github] ?? [];
          const gitlabMentions = mentionsByType[IntegrationType.Gitlab] ?? [];
          const gitMentions = [...githubMentions, ...gitlabMentions];

          // get all the code review request activities
          const codeReviewActivities = activitiesByType[ActivityType.CodeReviewRequestAdded];
          const codeReviewRequests = filterNotNull(
            codeReviewActivities?.map(cr =>
              get(
                codeReviewRequestSelector(
                  (cr.details as CodeReviewRequestAddedActivityDetails).codeReviewRequestId
                )
              )
            ) ?? []
          );

          const entityCodeReviews = get(codeReviewsForIssueSelector(entity.id));
          const cycleCodeReviewBranches = new Set(
            filterNotNull(codeReviewRequests.map(cr => cr.details.fromBranch))
          );
          const allEntityCodeReviewsByBranch = keyBy(entityCodeReviews, 'details.fromBranch');
          const missingCodeReviewRequestIds: string[] = [];

          // TODO remove regex garbage when we have a proper way to get the number of commits
          const commitData = chain(gitMentions)
            .groupBy(
              a => (a.details as MentionedInIntegrationActivityDetails).publicMetadata?.branch
            )
            .omitBy((_, k) => {
              const codeReview = allEntityCodeReviewsByBranch[k];
              if (codeReview && !cycleCodeReviewBranches.has(k)) {
                missingCodeReviewRequestIds.push(codeReview.id);
              }
              return !!codeReview;
            })
            .mapValues(v => {
              return (v ?? []).reduce((acc, a) => {
                const description = (a.details as MentionedInIntegrationActivityDetails)
                  .description;
                const number = description.match(/pushed (\d+) commits?/)?.[1] ?? '0';
                acc += parseInt(number, 10);
                return acc;
              }, 0);
            })
            .omitBy(v => v === 0)
            .value();

          const codeReviewRequestIds = [
            ...codeReviewRequests.map(cr => cr.id),
            ...missingCodeReviewRequestIds,
          ];

          // get all the todo changed activities
          const todoActivities = activitiesByType[ActivityType.TodoChanged];

          // create mapping of todo id -> last status of the todo whilst filtering out any that ended up in a not started state
          const todoStatusByTodoId = chain(todoActivities)
            .groupBy(a => (a.details as TodoChangedActivityDetails).todoId)
            .pickBy(a => {
              const lastActivityDetails = a[a.length - 1].details as TodoChangedActivityDetails;
              return lastActivityDetails.newStatus !== TodoStatus.NotStarted;
            })
            .mapValues(a => (a![a!.length - 1].details as TodoChangedActivityDetails).newStatus)
            .value();

          const allTodosInEntity = get(todosForEntitySelector(entity.id));
          const todoIdsInCycle = todosInCycle.map(ct => ct.id);

          const allTodosWithParents = new Set<string>();

          const allActiveTodos = new Set([...Object.keys(todoStatusByTodoId), ...todoIdsInCycle]);

          // walk them in reverse so we see the children before the parent
          for (const todo of [...allTodosInEntity].reverse()) {
            if (allActiveTodos.has(todo.id)) {
              allTodosWithParents.add(todo.id);
            }
            if (allTodosWithParents.has(todo.id) && todo.parentId) {
              allTodosWithParents.add(todo.parentId);
            }
          }

          const relevantTodos = allTodosInEntity
            .filter(todo => allTodosWithParents.has(todo.id))
            .map(t => ({
              id: t.id,
              status: todoStatusByTodoId[t.id] ?? todosInCycleById[t.id]?.status,
            }));

          // get a list of status changed activity ids
          const moveActivities = activitiesByType[ActivityType.StatusChanged]?.map(a => a.id) ?? [];

          return {
            statusChangeIds: moveActivities,
            todos: relevantTodos,
            memberIds,
            commentIds,
            slackMentionIds,
            figmaMentionIds,
            discordMentionIds,
            codeReviewRequestIds,
            commitData,
            ghost: cycleEntitiesByEntityId[entity.id]?.ghost ?? false,
          } as EntityCycleSummary;
        })
        .omitBy(isNil)
        .value() as { [x: string]: EntityCycleSummary };

      // split entities into planned and unplanned
      // make sure planned entities are in cycle order and have a summary even if nothing happened
      const planned = omitBy(
        entitiesInCycle.reduce((acc, id) => {
          const summary = activitiesByEntity[id];
          if (summary) {
            acc[id] = summary;
          } else {
            const issue = get(issueSelector(id));
            if (!issue) {
              return acc;
            }
            acc[id] = {
              statusChangeIds: [],
              todos: todosInCycle
                .filter(t => t.entityId === id)
                .map(t => ({ id: t.id, status: t.status })),
              memberIds: [],
              commentIds: [],
              slackMentionIds: [],
              figmaMentionIds: [],
              discordMentionIds: [],
              codeReviewRequestIds: [],
              commitData: {},
              ghost: cycleEntitiesByEntityId[id]?.ghost ?? false,
            };
          }
          return acc;
        }, {} as { [x: string]: EntityCycleSummary }),
        v => Object.keys(v).length === 0
      );

      const unplanned = pickBy(activitiesByEntity, (v, k) => {
        if (entitiesInCycleSet.has(k)) {
          return false;
        }

        // if there are any code reviews or commit, we want to show it
        if (v.codeReviewRequestIds.length || Object.keys(v.commitData).length) {
          return true;
        }

        // if it hasn't moved, it's not work
        if (v.statusChangeIds.length === 0) {
          return false;
        }

        for (const statusChangeId of v.statusChangeIds) {
          const statusChange = get(activitySelector(statusChangeId));
          const originalStatus =
            statusesById[
              (statusChange?.details as StatusChangedActivityDetails)?.originalStatusId ?? ''
            ];

          const movedStatus =
            statusesById[(statusChange?.details as StatusChangedActivityDetails)?.statusId ?? ''];
          if (!originalStatus || !movedStatus) {
            continue;
          }

          // if it's moved to or from in progress, it's work
          if (
            originalStatus.statusType === IssueStatusType.InProgress ||
            movedStatus.statusType === IssueStatusType.InProgress
          ) {
            return true;
          }

          // if it's moved to done from a not done column, it's work
          if (
            originalStatus.statusType !== IssueStatusType.Done &&
            movedStatus.statusType === IssueStatusType.Done
          ) {
            return true;
          }
        }

        // otherwise, it's not work
        return false;
      });

      return { planned, unplanned };
    },
});
