import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useOrganization } from '../../contexts/organizationContext';
import { useMarkUpdatesRead } from '../../syncEngine/actions/updates';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import {
  unreadUpdatesCountForCurrentUserSelector,
  unreadUpdatesForCurrentUserSelector,
} from '../../syncEngine/selectors/updates';
import { DefaultFocusElement } from '../defaultFocusElement';
import { Button, ButtonStyle, IconButton } from './button';
import styles from './notifications.module.scss';
import Placeholder from './placeholder';
import Popover from './popover';
import { Tooltip } from './tooltip';
import { Update } from './update';

export function NotificationsContent({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const organization = useOrganization();
  const updates = useRecoilValue(unreadUpdatesForCurrentUserSelector(organization.id));

  const markUpdatesRead = useMarkUpdatesRead();
  const history = useHistory();

  let notifications: JSX.Element | JSX.Element[];
  if (updates.length) {
    notifications = updates.map(update => (
      <Update
        key={update.id}
        className={styles.notification}
        update={update}
        onClick={() => {
          setOpen(false);
        }}
        onMarkRead={() => {
          if (updates.length === 1) {
            setOpen(false);
          }
        }}
        slateVoidBlockPlaceholders
        clampContent
      />
    ));
  } else {
    notifications = (
      <Placeholder title={`You're all set!`} icon="notifications" className={styles.placeholder}>
        <span className="bodyM grayed">
          We'll notify you about all mentions and watched activities.
        </span>
      </Placeholder>
    );
  }

  return (
    <div className={styles.content}>
      <DefaultFocusElement />
      <div className={styles.header}>
        <span className={styles.heading}>Notifications</span>
        {!!updates.length && (
          <Button
            buttonStyle={ButtonStyle.BareSubtle}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              markUpdatesRead(
                updates.map(update => update.id),
                true
              );
              setOpen(false);
            }}
          >
            Mark all as read
          </Button>
        )}

        <Button
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push(organizationPath(organization, 'updates'));
          }}
        >
          See all updates
        </Button>
      </div>
      <div className={styles.notificationsList}>{notifications}</div>
    </div>
  );
}

export function NotificationDotCompnent(
  { children, show }: { children: React.ReactNode; show: boolean },
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={styles.unreadIcon} ref={ref}>
      {show && <div className={styles.indicator} />}
      {children}
    </div>
  );
}

export const NotificationDot = React.forwardRef(NotificationDotCompnent);

export function NotificationsIndicator() {
  const [open, setOpen] = React.useState(false);
  const content = <NotificationsContent setOpen={setOpen} />;
  const organization = useOrganization();
  const unreadCount = useRecoilValue(unreadUpdatesCountForCurrentUserSelector(organization.id));

  return (
    <Popover
      content={content}
      open={open}
      onOpenChange={setOpen}
      contentOptions={{
        align: 'end',
      }}
      asChild
    >
      <div>
        <NotificationDot show={unreadCount > 0}>
          <Tooltip content="Unread notifications">
            <IconButton icon="notifications" buttonStyle={ButtonStyle.BareSubtle} />
          </Tooltip>
        </NotificationDot>
      </div>
    </Popover>
  );
}
