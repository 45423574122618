import { isFunction } from 'lodash';
import React from 'react';
import { Button, ButtonSize, ButtonStyle } from './new/button';
import { Icon } from './new/icon';
import { ListViewItem } from './new/listView';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './new/menu/dropdownMenu';
import { SimplifiedPicker } from './new/picker';

export default function SelectButton({
  items,
  value,
  placeholder,
  propertiesToSearch,
  buttonClassName,
  noSort,
  size,
  onSelect,
}: {
  items: ListViewItem[];
  value: string;
  placeholder: string | React.ReactNode;
  propertiesToSearch?: string[];
  buttonClassName?: string;
  noSort?: boolean;
  size?: ButtonSize;
  onSelect: (value: string) => void;
}) {
  const [open, setOpen] = React.useState(false);

  let valueContent = items.find(i => i.id === value)?.contents ?? placeholder;
  if (isFunction(valueContent)) {
    valueContent = valueContent(false);
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger>
        <Button size={size} buttonStyle={ButtonStyle.SecondaryOverlay} className={buttonClassName}>
          <>
            <div className="grow">{valueContent}</div>
            <div className="noShrink flexAlignEnd ml6">
              <Icon icon="expand_more" />
            </div>
          </>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="menuHuge menuPicker ">
        <SimplifiedPicker
          state={[value]}
          onDone={() => {
            setOpen(false);
          }}
          propertiesToSearch={propertiesToSearch}
          onAdd={v => {
            onSelect(v);
            setOpen(false);
          }}
          onRemove={v => {
            onSelect(v);
            setOpen(false);
          }}
          items={items}
          noSort={noSort}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
