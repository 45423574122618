import { History } from 'history';
import * as React from 'react';
import { Organization } from '../../sync/__generated/models';
import { toast } from '../components/toast';
import { organizationPath } from '../syncEngine/selectors/organizations';

export function checkTierExceededAndShowToast(
  organization: Organization,
  history: History
): boolean {
  if (!organization.productTierExceeded) {
    return false;
  }

  const freePlan = !organization.activeProductId;
  const hideToast = toast.error(
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        hideToast();
        history.push(organizationPath(organization, 'settings/billing'));
      }}
    >
      You're now exceeding {freePlan ? 'the free plan' : 'your current plan'}. Click here to upgrade
    </div>,
    {
      id: 'tier-exceeded',
      duration: Infinity,
    }
  );

  return true;
}
