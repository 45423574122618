import * as React from 'react';
import uuid from 'uuid';
import { KeyHold, useHotkeys } from '../../contexts/hotkeyContext';

interface HotkeyProperties {
  global?: boolean;
  unscoped?: boolean;
  hotkey: string;
  handler: (e?: KeyboardEvent, released?: KeyHold) => void;
  priority?: number;
  hold?: boolean;
}

export function Hotkey({ global, unscoped, hotkey, handler, priority, hold }: HotkeyProperties) {
  const id = React.useMemo(() => uuid.v4(), []);
  const hotkeyContext = useHotkeys();
  React.useEffect(() => {
    const hotkeyDefinition = { id, global, unscoped, hotkey, handler, priority, hold };
    hotkeyContext.registerHotkeyCommand(hotkeyDefinition);
    return () => {
      hotkeyContext.unregisterHotkeyCommand(hotkeyDefinition);
    };
  }, [id, global, unscoped, hotkey, handler, priority, hold, hotkeyContext]);

  return null;
}
