import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'urql';
import {
  EnableCyclesMutation,
  EnableCyclesMutationVariables,
  RescheduleCycleMutation,
  RescheduleCycleMutationVariables,
  StopCycleMutation,
  StopCycleMutationVariables,
} from '../../../graphql__generated__/graphql';
import { cycleFragment } from '../../sync/__generated/fragments';
import { Space } from '../../sync/__generated/models';
import { useStateTransaction } from '../syncEngine/state';
import { SyncEngineObject } from '../syncEngine/types';
import { trackerEvent } from '../tracker';

export function useEnableCycles() {
  const stateTransaction = useStateTransaction();

  const [, enableCyclesMutation] = useMutation<
    EnableCyclesMutation,
    EnableCyclesMutationVariables
  >(gql`
    mutation EnableCycles($spaceId: ID!) {
      enableCycles(input: { spaceId: $spaceId }) {
        cycle {
          ...CycleFragment
        }
      }
    }
    ${cycleFragment}
  `);

  return React.useCallback(
    async (spaceId: string) => {
      const result = await enableCyclesMutation({ spaceId });
      if (result.error) {
        throw result.error;
      }

      if (!result.data) {
        throw Error('Error enabling cycles');
      }
      const cycle = result.data?.enableCycles.cycle as SyncEngineObject;

      stateTransaction(({ set, get }) => {
        set([cycle]);

        const space = get<Space>(spaceId);
        trackerEvent('Cycles Enabled', {
          id: spaceId,
          startDay: space?.cycleStartDay,
          duration: space?.cycleDuration,
          cooldown: space?.cycleCooldown,
          automaticallyAdd: space?.addToCurrentCycle,
          timezone: space?.timezone,
        });
      });
    },
    [enableCyclesMutation, stateTransaction]
  );
}

export function useRescheduleCycle() {
  const [, rescheduleCycleMutation] = useMutation<
    RescheduleCycleMutation,
    RescheduleCycleMutationVariables
  >(gql`
    mutation RescheduleCycle($id: ID!, $startDate: Date, $endDate: Date) {
      rescheduleCycle(input: { id: $id, startDate: $startDate, endDate: $endDate })
    }
  `);

  return React.useCallback(
    async ({ id, startDate, endDate }: { id: string; startDate?: Date; endDate?: Date }) => {
      const result = await rescheduleCycleMutation({ id, startDate, endDate });
      if (result.error) {
        throw result.error;
      }

      trackerEvent('Cycle Updated', {
        id,
        type: 'Reschedule',
      });
    },
    [rescheduleCycleMutation]
  );
}

export function useStopCycle() {
  const [, stopCycleMutation] = useMutation<StopCycleMutation, StopCycleMutationVariables>(gql`
    mutation StopCycle($id: ID!) {
      stopCycle(input: { id: $id })
    }
  `);

  return async (id: string) => {
    const result = await stopCycleMutation({ id });
    if (result.error) {
      throw result.error;
    }
  };
}
