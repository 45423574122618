import EventEmitter from 'eventemitter3';
import { isEqual } from 'lodash';
import * as React from 'react';
import { Range } from 'slate';
import { useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { safeSelection } from '../../shared/slate/utils';
import Popover from '../components/new/popover';
import { PopoverProps, Side } from '../components/new/popover/popover.types';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { calculateBoundingRect, nativeSelection, Rect } from '../utils/dom';

export class HoverContextEmitter extends EventEmitter<'claimed' | 'released'> {
  private claimedBy: string | null = null;
  private keyDownHandler: ((e: React.KeyboardEvent<HTMLDivElement>) => boolean) | null = null;

  public claim(
    id: string | null = null,
    keyDownHandler: ((e: React.KeyboardEvent<HTMLDivElement>) => boolean) | null = null
  ) {
    this.claimedBy = id;
    this.keyDownHandler = keyDownHandler;
    this.emit('claimed', id);
  }

  public release(id: string) {
    if (id === this.claimedBy) {
      this.claimedBy = null;
      this.keyDownHandler = null;
      this.emit('released');
    }
  }

  public onKeyDown(e: React.KeyboardEvent<HTMLDivElement>): boolean {
    if (!this.keyDownHandler) {
      return false;
    }
    return this.keyDownHandler(e);
  }

  public get isClaimed() {
    return this.claimedBy !== null;
  }

  public get claimedById() {
    return this.claimedBy;
  }
}

export const hoverContext = React.createContext(new HoverContextEmitter());

export function HoverProvider({ children }: { children: React.ReactNode }) {
  return (
    <hoverContext.Provider value={new HoverContextEmitter()}>{children}</hoverContext.Provider>
  );
}

function FocusFollowingHoverPlacementComponent(
  {
    open,
    shown,
    fixed,
    onSideChanged,
  }: {
    open?: boolean;
    shown: boolean;
    fixed?: boolean;
    onSideChanged: (s: Side) => void;
  },
  ref?: any
) {
  const editor = useSlateStatic();
  const placementRef = React.useRef<HTMLSpanElement | null>(null);
  const boundingRectRef = React.useRef<Rect | null>(null);

  React.useEffect(() => {
    if (!placementRef.current) {
      return;
    }

    function findPositionedParent() {
      let parent = placementRef.current?.parentElement;
      while (parent) {
        const style = window.getComputedStyle(parent);
        if (style.position === 'relative' || style.position === 'absolute') {
          return parent;
        }
        parent = parent.parentElement;
      }

      throw Error(
        'Hover must have a relatively/absolutely positioned parent to be accurately positioned on the screen'
      );
    }

    if (open) {
      if (fixed && shown) {
        return;
      }

      const selection = safeSelection(editor);
      if (!selection) {
        return;
      }

      const clientRects = nativeSelection();
      if (!clientRects.length) {
        return;
      }

      const boundingRect = calculateBoundingRect(clientRects);
      if (isEqual(boundingRect, boundingRectRef.current)) {
        return;
      }

      boundingRectRef.current = boundingRect;

      const firstRect = Range.isForward(selection)
        ? clientRects[clientRects.length - 1]
        : clientRects[0];
      const alignedRects = clientRects.filter(
        r => r.top === firstRect.top && r.height === firstRect.height
      );

      // rough heuristic to see if we're on a single line or not
      const singleLine = alignedRects.length === clientRects.length;
      onSideChanged(singleLine || Range.isBackward(selection) ? 'top' : 'bottom');

      // detect when we're in the "we've selected a bunch of images" case
      const largeRects = clientRects.filter(r => r.width > 25).every(r => r.height > 50);
      const rect = largeRects ? boundingRect : calculateBoundingRect(alignedRects);

      const relativeParent = findPositionedParent();
      const parentRect = relativeParent.getBoundingClientRect();

      const offsetY = parentRect.top;
      const offsetX = parentRect.left;

      placementRef.current.style.top = `${rect.top - offsetY}px`;
      placementRef.current.style.left = `${rect.left - offsetX + rect.width / 2}px`;
    } else {
      placementRef.current.style.top = '-10000px';
      placementRef.current.style.left = '-10000px';
      boundingRectRef.current = null;
    }
  });

  return (
    <span
      contentEditable={false}
      ref={r => {
        placementRef.current = r;
        ref?.(r);
      }}
      style={{
        position: 'absolute',
        top: -10000,
        left: -10000,
        height: 15,
        minHeight: 15,
        width: 1,
        minWidth: 1,
      }}
    ></span>
  );
}

const FocusFollowingHoverPlacement = React.forwardRef(FocusFollowingHoverPlacementComponent);

export default function Hover(
  props: PopoverProps & {
    children?: JSX.Element;
    hoverId: string;
    fixed?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => boolean;
  }
) {
  const {
    children,
    hoverId: providedHoverId,
    fixed,
    onKeyDown,
    open,
    onOpenChange,
    contentOptions,
    nonInteractive,
    ...rest
  } = props;
  const context = React.useContext(hoverContext);
  const [side, setSide] = React.useState<Side>('top');
  const [shown, setShown] = React.useState(false);
  const id = React.useRef(uuid.v4());
  const hoverId = `${providedHoverId}-${id.current}`;

  useComponentDidMount(() => {
    function closeWhenClaimed(id: string) {
      if (id !== hoverId) {
        onOpenChange?.(false);
      }
    }
    context.on('claimed', closeWhenClaimed);
    return () => {
      context.off('claimed', closeWhenClaimed);
      context.release(hoverId);
    };
  });

  React.useEffect(() => {
    setShown(!!open);
    if (open && onKeyDown) {
      context.claim(hoverId, onKeyDown);
    }
    if (!open) {
      context.release(hoverId);
    }
  }, [hoverId, context, open, onKeyDown]);

  const childElement = children ? (
    children
  ) : (
    <FocusFollowingHoverPlacement open={open} fixed={fixed} shown={shown} onSideChanged={setSide} />
  );

  const {
    onOpenAutoFocus,
    side: forceSide,
    sideOffset,
    ...restOfContentOptions
  } = contentOptions ?? {};
  return (
    <Popover
      asChild
      open={open}
      nonInteractive={nonInteractive}
      onOpenChange={onOpenChange}
      contentOptions={{
        onOpenAutoFocus: e => {
          e.preventDefault();
          onOpenAutoFocus?.(e);
        },
        side: forceSide ?? side,
        sideOffset: sideOffset ?? 4,
        style: { ...contentOptions?.style, pointerEvents: nonInteractive ? undefined : 'all' },
        collisionPadding: {
          top: 50,
        },
        ...restOfContentOptions,
      }}
      {...rest}
    >
      {childElement}
    </Popover>
  );
}
