import cn from 'classnames';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { LineElement, OptionalAttributesRenderElementProps } from '../types';
import styles from './line.module.scss';

export function StaticLine({ attributes, children }: OptionalAttributesRenderElementProps) {
  return (
    <div {...attributes} className={cn('block', styles.lineContainer)}>
      <div className={styles.line}>{children}</div>
    </div>
  );
}

export function Line({ attributes, children }: RenderElementProps & { element: LineElement }) {
  const selected = useFocusedAndSelected();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <div className={styles.lineContainer} contentEditable={false}>
        <div
          className={cn(styles.line, {
            [styles.selected]: selected,
          })}
        ></div>
      </div>
      {children}
    </div>
  );
}
