import cn from 'classnames';
import * as H from 'history';
import React from 'react';
import { Link } from 'react-router-dom';
import { useConfiguration } from '../contexts/configurationContext';
import ExternalLink from './externalLink';
import styles from './linkButton.module.scss';
import { ButtonSize } from './new/button';

export default function LinkButton({
  to,
  size = ButtonSize.Medium,
  className,
  children,
  onClick,
}: {
  to: string | H.LocationDescriptor<any>;
  size?: ButtonSize.Medium | ButtonSize.Small;
  className?: string;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) {
  const { host } = useConfiguration();

  const url = to.toString();
  if (url.startsWith('http') && !url.startsWith(host)) {
    return (
      <ExternalLink
        className={cn(className, {
          [styles.small]: size === ButtonSize.Small,
          [styles.medium]: size === ButtonSize.Medium,
        })}
        href={url}
      >
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link
      className={cn(className, {
        [styles.small]: size === ButtonSize.Small,
        [styles.medium]: size === ButtonSize.Medium,
      })}
      to={to}
      onClick={e => {
        onClick?.(e);
      }}
    >
      {children}
    </Link>
  );
}
