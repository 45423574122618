import cn from 'classnames';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  EffortFilter as EffortFilterType,
  Filter,
  FilterType,
  FreeTextFilter as FreeTextFilterType,
  ImpactFilter as ImpactFilterType,
} from '../../../shared/filtering';
import { mapColor } from '../../../shared/utils/colors';
import { capitalize } from '../../../shared/utils/utils';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace, useSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { companySelector } from '../../syncEngine/selectors/companies';
import { cycleSelector } from '../../syncEngine/selectors/cycles';
import {
  effortLevelsForSpaceSelector,
  effortSelector,
  uniqueEffortLevelsForOrganizationSelector,
} from '../../syncEngine/selectors/effortLevels';
import { entityTypeString } from '../../syncEngine/selectors/entities';
import {
  impactLevelsForSpaceSelector,
  impactSelector,
  uniqueImpactLevelsForOrganizationSelector,
} from '../../syncEngine/selectors/impactLevels';
import {
  activeInitiativesForSpaceSelector,
  initiativeSelector,
} from '../../syncEngine/selectors/intiatives';
import { labelSelector } from '../../syncEngine/selectors/labels';
import { personSelector } from '../../syncEngine/selectors/people';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { tagSelector } from '../../syncEngine/selectors/tags';
import { userMembershipSelector, userSelector } from '../../syncEngine/selectors/users';
import {
  addToCurrentCycleKey,
  addToUpcomingCycleKey,
  cycleFilterHotkey,
  filterHotKey,
  selfFilterHotKey,
} from '../../utils/config';
import {
  FILTER_ANY_ID,
  FILTER_NONE_ID,
  FilterableEntityType,
  encodeFilterUrlValue,
  filterState,
  getFilterUrlParam,
} from '../../utils/filtering';
import { CycleStatusLabel } from '../cycle';
import { Button, ButtonSize, ButtonStyle, IconButton } from './button';
import { CustomCommand } from './customCommand';
import { EntityCycleTooltip } from './entityMetadata';
import { FilteredListView } from './filteredListView';
import styles from './filters.module.scss';
import { Icon } from './icon';
import { KeyboardShortcut } from './keyboardShortcut';
import { KeyNavigationProvider } from './keyNavigation';
import { LISTVIEW_ID, ListView } from './listView';
import menuStyles from './menu/menu.module.scss';
import { CycleIcon } from './metadata/cycle';
import { InitiativeIcon } from './metadata/initiative';
import Pill from './metadata/pill';
import { MetadataSize } from './metadata/size';
import { PickerState } from './picker';
import { CompanyPersonPicker, CompanyPersonPickerOptions } from './pickers/companyPersonPicker';
import { InitiativePicker } from './pickers/initiativePicker';
import { LabelPicker } from './pickers/labelPicker';
import { MemberPicker } from './pickers/memberPicker';
import { SpacePicker } from './pickers/spacePicker';
import { TagPicker } from './pickers/tagPicker';
import Popover from './popover';
import { TextInput } from './textInput';
import { MetadataTooltip, Tooltip } from './tooltip';
import { User } from './user';
import { WorkItemInitiativeTooltip } from './workItemMetadata';

export enum MenuMode {
  Default,
  Users,
  Labels,
  Impact,
  Effort,
  Initiatives,
  Space,
  FreeText,
  RelativeDate,
  People,
  Companies,
  Tags,
  Cycles,
}

export interface MenuArgs {
  userFilterType?: FilterType.Member | FilterType.CreatedBy | FilterType.TodosAssigned;
  placeholder?: string;
  dateFilterType?: FilterType.CreatedAt | FilterType.UpdatedAt;
  dateFilterDirection?: 'before' | 'after';
}

export interface ContentProps {
  id: string;
  entityType?: FilterableEntityType;
  args: MenuArgs | null;
  onClose: () => void;
  onModeChanged: (mode: MenuMode, args?: MenuArgs) => void;
  className?: string;
  options?: {
    disableSpaces?: boolean;
    orgLevel?: boolean;
    freeTextTitleOnly?: boolean;
  };
}

export function subfilters(filter: Filter | null) {
  if (filter?.type === FilterType.And) {
    return filter.filters;
  }
  return [];
}

export function addFilter(previousFilter: Filter | null, filterToAdd: Filter): Filter {
  let newFilter = cloneDeep(previousFilter);
  if (!previousFilter) {
    newFilter = { type: FilterType.And, filters: [] };
  }
  if (newFilter?.type === FilterType.And) {
    newFilter.filters.push(filterToAdd);
  }
  return newFilter!;
}

export function removeFilter(
  previousFilter: Filter | null,
  match: (filter: Filter) => boolean
): Filter | null {
  const newFilter = cloneDeep(previousFilter);
  if (newFilter?.type === FilterType.And) {
    newFilter.filters = newFilter.filters.filter(f => !match(f));
    if (!newFilter.filters.length) {
      return null;
    }
  }

  return newFilter;
}

export function findFilter<T extends Filter>(
  filter: Filter | null,
  match: (filter: Filter) => boolean
): T | null {
  if (filter?.type === FilterType.And) {
    return (filter.filters.find(f => match(f)) ?? null) as T | null;
  }

  return null;
}

export function filterPickerState(filter: Filter | null, match: (filter: Filter) => string | null) {
  return subfilters(filter).reduce(
    (result, filter) => {
      const id = match(filter);
      if (id) {
        result.__filter__.push(id);
      }
      return result;
    },
    { __filter__: [] } as PickerState
  );
}

function UserContents({ onClose, id, args }: ContentProps) {
  const filterType = args?.userFilterType ?? FilterType.Member;
  const [filter, setFilter] = useRecoilState(filterState(id));
  const memberFilterState = filterPickerState(filter, f => (f.type === filterType ? f.id : null));

  return (
    <MemberPicker
      filterPlaceholder={args?.placeholder ?? 'Members'}
      state={memberFilterState}
      additionalItems={
        filterType === FilterType.Member
          ? [
              {
                id: FILTER_ANY_ID,
                name: 'any',
                contents: (
                  <div className="row">
                    <Icon icon="member" className="mr8" /> Any member
                  </div>
                ),
              },
              {
                id: FILTER_NONE_ID,
                name: 'none',
                contents: (
                  <div className="row">
                    <Icon icon="member" className="mr8" /> No members
                  </div>
                ),
              },
            ]
          : []
      }
      onDone={onClose}
      onMemberAdded={(_, memberId) => {
        setFilter(previous => addFilter(previous, { type: filterType, id: memberId }));
      }}
      onMemberRemoved={(_, memberId) => {
        setFilter(previous =>
          removeFilter(previous, f => f.type === filterType && f.id === memberId)
        );
      }}
    />
  );
}

function LabelContents({ onClose, id, options }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const labelFilterState = filterPickerState(filter, f =>
    f.type === FilterType.Label ? f.id : null
  );

  return (
    <LabelPicker
      orgLevel={options?.orgLevel}
      filterPlaceholder="Labels"
      state={labelFilterState}
      additionalItems={[
        {
          id: FILTER_ANY_ID,
          name: 'any',
          contents: (
            <div className="row">
              <Icon icon="label" className="mr8" /> Any label
            </div>
          ),
        },
        {
          id: FILTER_NONE_ID,
          name: 'none',
          contents: (
            <div className="row">
              <Icon icon="label" className="mr8" /> No labels
            </div>
          ),
        },
      ]}
      onDone={onClose}
      onLabelAdded={(_, labelId) => {
        setFilter(previous =>
          addFilter(previous, { type: FilterType.Label, id: labelId, orgLevel: options?.orgLevel })
        );
      }}
      onLabelRemoved={(_, labelId) => {
        setFilter(previous =>
          removeFilter(previous, f => f.type === FilterType.Label && f.id === labelId)
        );
      }}
    />
  );
}

function InitiativeContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const initiativeFilterState = filterPickerState(filter, f =>
    f.type === FilterType.Initiative ? f.id : null
  );

  return (
    <InitiativePicker
      filterPlaceholder="Initiatives"
      state={initiativeFilterState}
      additionalItems={[
        {
          id: FILTER_ANY_ID,
          title: 'any',
          contents: (
            <div className="row">
              <Icon icon="initiative" className="mr8" /> Any initiative
            </div>
          ),
        },
        {
          id: FILTER_NONE_ID,
          title: 'none',
          contents: (
            <div className="row">
              <Icon icon="initiative" className="mr8" /> No initiatives
            </div>
          ),
        },
      ]}
      onDone={onClose}
      onInitiativeAdded={(_, initiativeId) => {
        setFilter(previous =>
          addFilter(previous, { type: FilterType.Initiative, id: initiativeId })
        );
      }}
      onInitiativeRemoved={(_, initiativeId) => {
        setFilter(previous =>
          removeFilter(previous, f => f.type === FilterType.Initiative && f.id === initiativeId)
        );
      }}
    />
  );
}

function ImpactContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const selectedImpactId = findFilter<ImpactFilterType>(
    filter,
    f => f.type === FilterType.Impact
  )?.id;
  const organization = useOrganization();
  const space = useMaybeSpace();
  const spaceImpactLevels = useRecoilValue(impactLevelsForSpaceSelector(space?.id));
  const organizationImpactLevels = useRecoilValue(
    uniqueImpactLevelsForOrganizationSelector(organization.id)
  );
  const impactLevels = space ? spaceImpactLevels : organizationImpactLevels;

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView
        itemClassName={menuStyles.item}
        items={[
          {
            id: 'none',
            icon: selectedImpactId === null ? 'select_checkmark' : 'none',
            contents: 'None',
            hotkey: '0',
            onSelected: () => {
              setFilter(previous =>
                addFilter(
                  removeFilter(previous, f => f.type === FilterType.Impact),
                  { type: FilterType.Impact, id: null }
                )
              );
            },
          },
          ...impactLevels.map((impact, index) => ({
            id: impact.id,
            icon: selectedImpactId === impact.id ? 'select_checkmark' : 'none',
            contents: impact.name,
            hotkey: `${index + 1}`,
            onSelected: () => {
              setFilter(previous =>
                addFilter(
                  removeFilter(previous, f => f.type === FilterType.Impact),
                  { type: FilterType.Impact, id: impact.id }
                )
              );
              onClose();
            },
          })),
        ]}
      />
    </KeyNavigationProvider>
  );
}

function EffortContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const selectedEffortId = findFilter<EffortFilterType>(
    filter,
    f => f.type === FilterType.Effort
  )?.id;
  const organization = useOrganization();
  const space = useMaybeSpace();
  const spaceEffortLevels = useRecoilValue(effortLevelsForSpaceSelector(space?.id));
  const organizationEffortLevels = useRecoilValue(
    uniqueEffortLevelsForOrganizationSelector(organization.id)
  );
  const effortLevels = space ? spaceEffortLevels : organizationEffortLevels;

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView
        itemClassName={menuStyles.item}
        items={[
          {
            id: 'none',
            icon: selectedEffortId === null ? 'select_checkmark' : 'none',
            contents: 'None',
            hotkey: '0',
            onSelected: () => {
              setFilter(previous =>
                addFilter(
                  removeFilter(previous, f => f.type === FilterType.Effort),
                  { type: FilterType.Effort, id: null }
                )
              );
            },
          },
          ...effortLevels.map((effort, index) => ({
            id: effort.id,
            icon: selectedEffortId === effort.id ? 'select_checkmark' : 'none',
            contents: effort.name,
            hotkey: `${index + 1}`,
            onSelected: () => {
              setFilter(previous =>
                addFilter(
                  removeFilter(previous, f => f.type === FilterType.Effort),
                  { type: FilterType.Effort, id: effort.id }
                )
              );
              onClose();
            },
          })),
        ]}
      />
    </KeyNavigationProvider>
  );
}

function SpacesContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const spaceFilterState = filterPickerState(filter, f =>
    f.type === FilterType.Space ? f.id : null
  );

  return (
    <SpacePicker
      showAll
      multi
      filterPlaceholder="Space"
      state={spaceFilterState}
      onDone={onClose}
      onSpaceAdded={(_, spaceId) => {
        setFilter(previous => addFilter(previous, { type: FilterType.Space, id: spaceId }));
      }}
      onSpaceRemoved={(_, spaceId) => {
        setFilter(previous =>
          removeFilter(previous, f => f.type === FilterType.Space && f.id === spaceId)
        );
      }}
    />
  );
}

function FreeTextContents({ id, onClose, options }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const [value, setValue] = React.useState(
    findFilter<FreeTextFilterType>(filter, f => f.type === FilterType.FreeText)?.text ?? ''
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        setFilter(previous =>
          addFilter(
            removeFilter(previous, f => f.type === FilterType.FreeText),
            { type: FilterType.FreeText, text: value, titleOnly: options?.freeTextTitleOnly }
          )
        );
        onClose();
      }}
    >
      <div className={styles.freeTextFilter}>
        <div className={cn('bodyM', 'oneLine', styles.label)}>Filter by</div>
        <div className="fullWidth mt12"></div>
        <TextInput
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          className="fullWidth"
          autoFocus
        />
        <div className="rowEnd mt8">
          <Button
            size={ButtonSize.Small}
            type="button"
            onClick={onClose}
            buttonStyle={ButtonStyle.Bare}
          >
            Cancel
          </Button>
          <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Primary}>
            Apply
          </Button>
        </div>
      </div>
    </form>
  );
}

const defaultItems = [
  { id: '0', contents: <>Today</>, name: 'Today', aliases: ['0'], value: 0 },
  { id: '1', contents: <>Yesterday</>, name: 'Yesterday', aliases: ['1'], value: 1 },
  { id: '7', contents: <>7 days</>, aliases: ['week'], value: 7 },
  { id: '14', contents: <>14 days</>, aliases: ['fortnight'], value: 14 },
  { id: '30', contents: <>30 days</>, aliases: ['month'], value: 30 },
];

function RelativeDateContents({ id, onClose, args }: ContentProps) {
  const setFilter = useSetRecoilState(filterState(id));

  const [filterText, setFilterText] = React.useState('');
  const filterType = args!.dateFilterType!;
  const direction = args!.dateFilterDirection!;

  const [items, setItems] = React.useState(defaultItems);

  React.useEffect(() => {
    if (filterText === '') {
      setItems(defaultItems);
      return;
    }

    const options = [...defaultItems];

    const [firstComponent] = filterText.split(' ');
    const filterAsNumber = Number(firstComponent);
    if (filterAsNumber && filterAsNumber > 1) {
      options.push({
        id: `${filterAsNumber}`,
        value: filterAsNumber,
        contents: <>{`${filterAsNumber} days ago`}</>,
        name: `${filterAsNumber} days ago`,
        aliases: [],
      });
      options.push({
        id: `${filterAsNumber * 7}`,
        value: filterAsNumber * 7,
        contents: <>{`${filterAsNumber} weeks ago`}</>,
        aliases: [],
      });
      options.push({
        id: `${filterAsNumber * 30}`,
        value: filterAsNumber * 30,
        contents: <>{`${filterAsNumber} months ago`}</>,
        aliases: [],
      });
    }
    setItems(options);
  }, [filterText]);

  const placeholder = `${direction === 'before' ? 'not ' : ''}${
    filterType === FilterType.CreatedAt ? 'created' : 'updated'
  } since "x" days ago`;

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <FilteredListView
        filterPlaceholder={capitalize(placeholder)}
        onFilterChanged={setFilterText}
        itemClassName={menuStyles.item}
        propertiesToSearch={['name', 'aliases']}
        items={items.map(i => ({
          ...i,
          onSelected: () => {
            setFilter(previous =>
              addFilter(
                removeFilter(previous, f => f.type === filterType && f.direction === direction),
                { type: filterType, direction, offset: i.value as number }
              )
            );
            onClose();
          },
        }))}
      />
    </KeyNavigationProvider>
  );
}

function TagContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const tagFilterState = filterPickerState(filter, f => (f.type === FilterType.Tag ? f.id : null));

  return (
    <TagPicker
      filterPlaceholder="Tags"
      state={tagFilterState}
      onDone={onClose}
      onTagAdded={(_, tagId) => {
        setFilter(previous => addFilter(previous, { type: FilterType.Tag, id: tagId }));
      }}
      onTagRemoved={(_, tagId) => {
        setFilter(previous =>
          removeFilter(previous, f => f.type === FilterType.Tag && f.id === tagId)
        );
      }}
    />
  );
}

function PeopleContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const peopleFilterState = filterPickerState(filter, f =>
    f.type === FilterType.Person ? f.id : null
  );

  return (
    <CompanyPersonPicker
      disableCreation
      noEdit
      state={peopleFilterState}
      onDone={onClose}
      onPersonAdded={personId => {
        if (personId) {
          setFilter(previous =>
            addFilter(
              removeFilter(previous, f => f.type === FilterType.Person),
              { type: FilterType.Person, id: personId }
            )
          );
        }
      }}
      onPersonRemoved={personId => {
        if (personId) {
          setFilter(previous => removeFilter(previous, f => f.type === FilterType.Person));
        }
      }}
      onCompanyAdded={() => {
        throw new Error('Function not implemented.');
      }}
      onCompanyRemoved={() => {
        throw new Error('Function not implemented.');
      }}
      options={CompanyPersonPickerOptions.People}
    />
  );
}

function CompanyContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  const peopleFilterState = filterPickerState(filter, f =>
    f.type === FilterType.Person ? f.id : null
  );

  return (
    <CompanyPersonPicker
      disableCreation
      noEdit
      state={peopleFilterState}
      onDone={onClose}
      onCompanyAdded={companyId => {
        if (companyId) {
          setFilter(previous =>
            addFilter(
              removeFilter(previous, f => f.type === FilterType.Company),
              { type: FilterType.Company, id: companyId }
            )
          );
        }
      }}
      onCompanyRemoved={companyId => {
        if (companyId) {
          setFilter(previous => removeFilter(previous, f => f.type === FilterType.Company));
        }
      }}
      onPersonAdded={() => {
        throw new Error('Function not implemented.');
      }}
      onPersonRemoved={() => {
        throw new Error('Function not implemented.');
      }}
      options={CompanyPersonPickerOptions.Companies}
    />
  );
}

function CycleContents({ onClose, id }: ContentProps) {
  const [filter, setFilter] = useRecoilState(filterState(id));

  const selectedCycleId = findFilter<ImpactFilterType>(
    filter,
    f => f.type === FilterType.Cycle
  )?.id;

  const space = useSpace();
  const activeCycle = useRecoilValue(cycleSelector(space?.activeCycleId));
  const upcomingCycle = useRecoilValue(cycleSelector(space?.upcomingCycleId));

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <ListView
        itemClassName={cn(menuStyles.item, 'defaultCursor')}
        items={[
          {
            id: 'none',
            icon: selectedCycleId === null ? 'select_checkmark' : 'none',
            contents: 'None',
            onSelected: () => {
              setFilter(previous =>
                addFilter(
                  removeFilter(previous, f => f.type === FilterType.Cycle),
                  { type: FilterType.Cycle, id: null }
                )
              );
              onClose();
            },
          },
          ...(activeCycle
            ? [
                {
                  id: 'active_cycle',
                  icon: selectedCycleId === activeCycle.id ? 'select_checkmark' : 'none',
                  hotkey: addToCurrentCycleKey,
                  contents: (
                    <div className="row metadataGap">
                      <span className="ellipsis">{activeCycle.title}</span>
                      <CycleStatusLabel cycleId={activeCycle.id} />
                    </div>
                  ),
                  onSelected: () => {
                    setFilter(previous =>
                      addFilter(
                        removeFilter(previous, f => f.type === FilterType.Cycle),
                        { type: FilterType.Cycle, id: activeCycle.id }
                      )
                    );
                    onClose();
                  },
                },
              ]
            : []),
          ...(upcomingCycle
            ? [
                {
                  id: 'upcoming_cycle',
                  icon: selectedCycleId === upcomingCycle.id ? 'select_checkmark' : 'none',
                  hotkey: addToUpcomingCycleKey,
                  contents: (
                    <div className="row metadataGap">
                      <span className="ellipsis">{upcomingCycle.title}</span>
                      <CycleStatusLabel cycleId={upcomingCycle.id} />
                    </div>
                  ),
                  onSelected: () => {
                    setFilter(previous =>
                      addFilter(
                        removeFilter(previous, f => f.type === FilterType.Cycle),
                        { type: FilterType.Cycle, id: upcomingCycle.id }
                      )
                    );
                    onClose();
                  },
                },
              ]
            : []),
        ]}
      />
    </KeyNavigationProvider>
  );
}

export function MenuContents({
  mode,
  DefaultContents,
  ...rest
}: { mode: MenuMode; DefaultContents: React.ComponentType<ContentProps> } & ContentProps) {
  switch (mode) {
    case MenuMode.Users:
      return <UserContents {...rest} />;
    case MenuMode.Labels:
      return <LabelContents {...rest} />;
    case MenuMode.Impact:
      return <ImpactContents {...rest} />;
    case MenuMode.Effort:
      return <EffortContents {...rest} />;
    case MenuMode.Initiatives:
      return <InitiativeContents {...rest} />;
    case MenuMode.FreeText:
      return <FreeTextContents {...rest} />;
    case MenuMode.RelativeDate:
      return <RelativeDateContents {...rest} />;
    case MenuMode.Space:
      return <SpacesContents {...rest} />;
    case MenuMode.Tags:
      return <TagContents {...rest} />;
    case MenuMode.Companies:
      return <CompanyContents {...rest} />;
    case MenuMode.People:
      return <PeopleContents {...rest} />;
    case MenuMode.Cycles:
      return <CycleContents {...rest} />;
    case MenuMode.Default:
      return <DefaultContents {...rest} />;
  }
}

export function FilterMenu({
  entityType,
  id,
  DefaultContents,
  buttonStyle,
  compact,
  className,
  style,
  options,
}: {
  entityType?: FilterableEntityType;
  buttonStyle?: ButtonStyle;
  id: string;
  DefaultContents: React.ComponentType<ContentProps>;
  compact?: boolean;
  style?: React.CSSProperties;
  className?: string;
  options?: {
    disableSpaces?: boolean;
    freeTextTitleOnly?: boolean;
    orgLevel?: boolean;
  };
}) {
  const user = useCurrentUser();
  const setFilters = useSetRecoilState(filterState(id));
  const [mode, setMode] = React.useState(MenuMode.Default);
  const [args, setArgs] = React.useState<MenuArgs | null>(null);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const space = useMaybeSpace();

  React.useEffect(() => {
    if (!menuOpen) {
      setTimeout(() => {
        setMode(MenuMode.Default);
        setArgs(null);
      }, 300);
    }
  }, [menuOpen]);

  const popoverContent = (
    <div
      className={cn('menuMedium', styles.popover, {
        menuPicker: [
          MenuMode.Labels,
          MenuMode.Users,
          MenuMode.Companies,
          MenuMode.People,
          MenuMode.Tags,
          MenuMode.Effort,
          MenuMode.Impact,
          MenuMode.Cycles,
        ].includes(mode),
      })}
    >
      <MenuContents
        mode={mode}
        entityType={entityType}
        id={id}
        args={args}
        onModeChanged={(mode, args) => {
          setArgs(args ?? null);
          setMode(mode);
        }}
        onClose={closeMenu}
        DefaultContents={DefaultContents}
        options={options}
      />
    </div>
  );

  return (
    <>
      <Popover
        content={popoverContent}
        asChild
        contentOptions={{
          onClick: e => {
            e.stopPropagation();
          },
          side: 'bottom',
          align: 'start',
        }}
        open={menuOpen}
        onOpenChange={setMenuOpen}
      >
        <div>
          {!compact && (
            <Tooltip
              asChild
              content={
                <div>
                  Set filters <KeyboardShortcut shortcut={filterHotKey} />
                </div>
              }
            >
              <Button
                icon="filter"
                className={className}
                style={style}
                buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle}
                fauxActive={menuOpen}
                onClick={() => setMenuOpen(true)}
              >
                Filter
              </Button>
            </Tooltip>
          )}
          {compact && (
            <IconButton
              icon="filter"
              className={className}
              style={style}
              buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle}
              fauxActive={menuOpen}
              onClick={() => setMenuOpen(true)}
            />
          )}
        </div>
      </Popover>
      <CustomCommand
        command={{
          id: `filter-board-${id}`,
          icon: 'filter',
          group: CommandGroup.Board,
          description: 'Filter',
          hotkey: filterHotKey,
          handler: () => {
            setMenuOpen(true);
          },
        }}
      />
      <CustomCommand
        command={{
          id: `filter-board-my-${id}`,
          group: CommandGroup.Board,
          description: `Filter my work`,
          icon: 'my_work',
          hotkey: selfFilterHotKey,
          handler: () => {
            setFilters(previous => {
              if (findFilter(previous, f => f.type === FilterType.Member && f.id === user.id)) {
                return removeFilter(
                  previous,
                  f => f.type === FilterType.Member && f.id === user.id
                );
              }
              return addFilter(previous, { type: FilterType.Member, id: user.id });
            });
          },
        }}
      />
      {space?.cyclesEnabled && space?.activeCycleId && (
        <CustomCommand
          command={{
            id: `filter-board-cycle-${id}`,
            group: CommandGroup.Board,
            description: entityType
              ? `Show cycle ${entityTypeString(entityType)}s`
              : 'Show cycle items',
            icon: 'cycle_current',
            hotkey: cycleFilterHotkey,
            handler: () => {
              setFilters(previous => {
                if (
                  findFilter(
                    previous,
                    f => f.type === FilterType.Cycle && f.id === space.activeCycleId
                  )
                ) {
                  return removeFilter(
                    previous,
                    f => f.type === FilterType.Cycle && f.id === space.activeCycleId
                  );
                }
                return addFilter(previous, { type: FilterType.Cycle, id: space.activeCycleId });
              });
            },
          }}
        />
      )}
    </>
  );
}

function SimpleFilter({ name, color, icon }: { name: string; color: string; icon: string }) {
  return (
    <div className={styles.simpleFilter}>
      <Icon icon={icon} className="mr4" style={{ fill: color }} />
      <span className="ellipsis">{name}</span>
    </div>
  );
}

function UserFilter({ id, prefix }: { id: string; prefix?: string }) {
  const organization = useOrganization();
  const user = useRecoilValue(userSelector(id));
  const member = useRecoilValue(
    userMembershipSelector({ organizationId: organization.id, userId: id })
  );

  if (id === FILTER_ANY_ID) {
    return <SimpleFilter icon="member" color="" name="Any member" />;
  }

  if (id === FILTER_NONE_ID) {
    return <SimpleFilter icon="member" color="" name="No members" />;
  }

  if (!user || !member) {
    return null;
  }
  return (
    <div className={styles.userFilter}>
      {prefix && <div className={styles.userFilterPrefix}>{prefix}</div>}
      <User member={member} className="bodyM oneLine ellipsis ml4" user={user} />
    </div>
  );
}

function LabelFilter({ id }: { id: string }) {
  const label = useRecoilValue(labelSelector(id));

  if (id === FILTER_ANY_ID) {
    return <SimpleFilter icon="label" color="" name="Any label" />;
  }

  if (id === FILTER_NONE_ID) {
    return <SimpleFilter icon="label" color="" name="No labels" />;
  }

  if (!label) {
    return null;
  }
  const color = mapColor(label.color);

  return <SimpleFilter icon="label" color={color} name={label.name} />;
}

function TagFilter({ id }: { id: string }) {
  const tag = useRecoilValue(tagSelector(id));
  if (!tag) {
    return null;
  }

  return <SimpleFilter icon="label" color={`#${tag.color}`} name={tag.name} />;
}

function CompanyFilter({ id }: { id: string }) {
  const company = useRecoilValue(companySelector(id));
  if (!company) {
    return null;
  }

  return <SimpleFilter icon="org" color={''} name={company.name} />;
}

function PersonFilter({ id }: { id: string }) {
  const person = useRecoilValue(personSelector(id));
  if (!person) {
    return null;
  }

  return <SimpleFilter name={person.name} color={''} icon={'none'} />;
}

function InitiativeFilter({ id }: { id: string }) {
  const initiative = useRecoilValue(initiativeSelector(id));

  if (id === FILTER_ANY_ID) {
    return <SimpleFilter icon="initiative" color="" name="Any initiative" />;
  }

  if (id === FILTER_NONE_ID) {
    return <SimpleFilter icon="initiative" color="" name="No initiatives" />;
  }

  if (!initiative) {
    return null;
  }

  const color = mapColor(initiative.color);

  return (
    <MetadataTooltip
      sideOffset={11}
      alignOffset={-4}
      side="bottom"
      align="start"
      className="noMinWidth p4"
      content={<WorkItemInitiativeTooltip initiative={initiative} />}
    >
      <div className={styles.simpleFilter}>
        <Icon icon="initiative" className="mr4" style={{ fill: color }} />
        <span className="ellipsis">{initiative.title}</span>
      </div>
    </MetadataTooltip>
  );
}

function ImpactFilter({ id }: { id: string | null }) {
  const impact = useRecoilValue(impactSelector(id));

  if (!id) {
    return <SimpleFilter icon="impact" color={`var(--grayA10)`} name="No impact" />;
  }

  if (!impact) {
    return null;
  }

  const color = mapColor(impact.color);
  return <SimpleFilter icon="impact" color={color} name={impact.abbrevation} />;
}

function EffortFilter({ id }: { id: string | null }) {
  const effort = useRecoilValue(effortSelector(id));

  if (!id) {
    return <SimpleFilter icon="effort" color={`var(--grayA10)`} name="No effort" />;
  }

  if (!effort) {
    return null;
  }

  const color = mapColor(effort.color);
  return <SimpleFilter icon="effort" color={color} name={effort.abbrevation} />;
}

function SpaceFilter({ id }: { id: string }) {
  const space = useRecoilValue(spaceSelector(id));

  return <SimpleFilter icon="workspace" name={space?.name ?? ''} color="" />;
}

function CycleFilter({ id }: { id: string | null }) {
  const cycle = useRecoilValue(cycleSelector(id));

  if (id === null) {
    return <SimpleFilter icon="cycle_completed" color="" name="Not part of a cycle" />;
  }

  if (!cycle) {
    return null;
  }

  return (
    <MetadataTooltip
      sideOffset={11}
      alignOffset={-4}
      side="bottom"
      align="start"
      className="noMinWidth p4"
      content={<EntityCycleTooltip cycle={cycle} />}
    >
      <div className={styles.simpleFilter}>
        <CycleIcon
          className="mr4"
          style={{ fill: 'var(--grayA10)' }}
          cycleStatus={cycle.cycleStatus}
        />
        <span className="ellipsis">{cycle.title}</span>
      </div>
    </MetadataTooltip>
  );
}

function DateFilter({
  offset,
  direction,
  type,
}: {
  offset: number;
  direction: 'before' | 'after';
  type: FilterType.CreatedAt | FilterType.UpdatedAt;
}) {
  let adjustedOffset = offset;
  let timeString;

  if (adjustedOffset === 0) {
    timeString = 'today';
  } else if (adjustedOffset === 1) {
    timeString = 'yesterday';
  } else if (adjustedOffset % 30 === 0) {
    adjustedOffset = adjustedOffset / 30;
    timeString = `${adjustedOffset} month${adjustedOffset > 0 ? 's' : ''} ago`;
  } else if (adjustedOffset % 7 === 0) {
    adjustedOffset = adjustedOffset / 7;
    timeString = `${adjustedOffset} week${adjustedOffset > 0 ? 's' : ''} ago`;
  } else {
    timeString = `${adjustedOffset} day${adjustedOffset > 0 ? 's' : ''} ago`;
  }

  const filterString = `${direction === 'after' ? '' : 'not '}${
    type === FilterType.CreatedAt ? 'created' : 'updated'
  } since: `;
  return (
    <div className={styles.simpleFilter}>
      <span className={styles.prefix}>{capitalize(filterString)}</span> {timeString}
    </div>
  );
}

export function FilterComponent({ filter }: { filter: Filter }) {
  switch (filter.type) {
    case FilterType.Member:
      return <UserFilter id={filter.id} />;
    case FilterType.Label:
      return <LabelFilter id={filter.id} />;
    case FilterType.Initiative:
      return <InitiativeFilter id={filter.id} />;
    case FilterType.CreatedBy:
      return <UserFilter id={filter.id} prefix="Created by" />;
    case FilterType.Impact:
      return <ImpactFilter id={filter.id} />;
    case FilterType.Effort:
      return <EffortFilter id={filter.id} />;
    case FilterType.Space:
      return <SpaceFilter id={filter.id} />;
    case FilterType.Tag:
      return <TagFilter id={filter.id} />;
    case FilterType.Person:
      return <PersonFilter id={filter.id} />;
    case FilterType.Company:
      return <CompanyFilter id={filter.id} />;
    case FilterType.Cycle:
      return <CycleFilter id={filter.id} />;
    case FilterType.Watching:
      return <UserFilter id={filter.id} prefix="Watched by" />;
    case FilterType.CreatedAt:
      return <DateFilter {...filter} />;
    case FilterType.UpdatedAt:
      return <DateFilter {...filter} />;
    case FilterType.FreeText:
      return (
        <div className="bodyM row">
          <div className="bodyM oneLine mr4">Title{!filter.titleOnly && ' or ID'} contains</div>
          {filter.text}
        </div>
      );
    case FilterType.TodosAssigned:
      return <UserFilter id={filter.id} prefix="Todos assigned" />;
    default:
      return <>Unknown filter</>;
  }
}

function FilterButton({ children, onRemove }: { children: React.ReactNode; onRemove: () => void }) {
  return (
    <div className={styles.filterButton}>
      {children}
      <IconButton
        icon="exit"
        buttonStyle={ButtonStyle.Bare}
        className={cn('ml4', styles.filterButtonIcon)}
        size={ButtonSize.Small}
        onClick={onRemove}
      />
    </div>
  );
}

export function Filters({ id, className }: { id: string; className?: string }) {
  const [filter, setFilter] = useRecoilState(filterState(id));
  // for now we assume everything is a single And filter
  const filters = filter?.type === FilterType.And ? filter.filters : [];

  // we need this to run on every render if we actually do want to keep things in sync
  // shouldn't be too expensive though. Could maybe stick it in a requestAnimationFrame
  // callback if it does show up in the profiler
  React.useEffect(() => {
    const filterParam = getFilterUrlParam(id);
    if (!filterParam && filter) {
      const encoded = encodeFilterUrlValue(filter);

      const url = new URL(window.location.toString());
      const searchParams = url.searchParams;
      searchParams.set(`filter_${id}`, encoded);
      window.history.replaceState({}, '', url);
    }
  });

  if (!filters.length) {
    return null;
  }

  return (
    <div className={cn(styles.filters, className ?? '')}>
      {filters.map((filter, index) => (
        <FilterButton
          key={`filter-${index}`}
          onRemove={() => {
            setFilter(previous => {
              const newFilter = cloneDeep(previous);
              if (newFilter?.type === FilterType.And) {
                newFilter.filters.splice(index, 1);
                if (!newFilter.filters.length) {
                  return null;
                }
              }
              return newFilter;
            });
          }}
        >
          <FilterComponent filter={filter} />
        </FilterButton>
      ))}
      <CustomCommand
        command={{
          // FIXME: group for the board?
          id: `clear-filter-board-${id}`,
          group: CommandGroup.Entities,
          description: 'Clear all filters',
          hotkey: 'backspace',
          handler: () => {
            setFilter(null);
          },
        }}
      />
    </div>
  );
}

export function QuickFilters({ id, className }: { id: string; className?: string }) {
  const space = useSpace();
  const [filter, setFilter] = useRecoilState(filterState(id));
  const smallScreen = useIsSmallScreen();

  const initiatives = useRecoilValue(activeInitiativesForSpaceSelector(space.id));
  const currentCycle = useRecoilValue(cycleSelector(space.activeCycleId));
  const filters = filter?.type === FilterType.And ? filter.filters : [];

  if (!initiatives.length || filters.length || smallScreen) {
    return null;
  }

  return (
    <div className={cn(styles.filters, className ?? '')}>
      {space.cyclesEnabled && currentCycle && (
        <MetadataTooltip
          key={currentCycle.id}
          side="bottom"
          align="start"
          className="followTooltip"
          content={<EntityCycleTooltip cycle={currentCycle} />}
        >
          <Pill
            className="clickable"
            size={MetadataSize.Medium}
            onClick={() => {
              setFilter(previous => {
                return addFilter(previous, {
                  type: FilterType.Cycle,
                  id: currentCycle.id,
                });
              });
            }}
          >
            <CycleIcon cycleStatus={currentCycle.cycleStatus} />
            <span>{currentCycle.title}</span>
          </Pill>
        </MetadataTooltip>
      )}
      {initiatives.map(initiative => (
        <MetadataTooltip
          key={initiative.id}
          className="followTooltip"
          side="bottom"
          align="start"
          content={<WorkItemInitiativeTooltip initiative={initiative} />}
        >
          <Pill
            className="clickable"
            size={MetadataSize.Medium}
            onClick={() => {
              setFilter(previous => {
                return addFilter(previous, {
                  type: FilterType.Initiative,
                  id: initiative.id,
                });
              });
            }}
          >
            <InitiativeIcon color={initiative.color} />
            <span>{initiative.title}</span>
          </Pill>
        </MetadataTooltip>
      ))}
    </div>
  );
}
