import cn from 'classnames';
import { keys, values } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { capitalize } from '../../../shared/utils/utils';
import { CommandGroup, StatusCommandGroupContext } from '../../commands/state';
import { Button, ButtonStyle, IconButton } from '../../components/new/button';
import { CommandContext } from '../../components/new/commandMenuContext';
import { Count } from '../../components/new/count';
import { Hotkey } from '../../components/new/hotkey';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  useColumnHasKeyNavigationFocus,
  useHasKeyNavigationFocus,
  useKeyNavigationElement,
} from '../../components/new/keyNavigation';
import Placeholder from '../../components/new/placeholder';
import { StatusIcon } from '../../components/new/statusIcon';
import { Tooltip } from '../../components/new/tooltip';
import { VirtualizedListView } from '../../components/new/virtualizedListView';
import { WorkItemListItem } from '../../components/new/workItemListItem';
import { CommandMenuView, Modals, NewEntityArgs, useModals } from '../../contexts/modalContext';
import {
  spaceIdsForEntitiesSelector,
  useEntityNumberWidths,
} from '../../syncEngine/selectors/entities';
import { statusSelector } from '../../syncEngine/selectors/issues';
import { releaseIssuesByStatusSelector } from '../../syncEngine/selectors/releases';
import { createNewIssueKey } from '../../utils/config';
import { Placeholder as ListItemPlaceholder } from '../new/workItemBoardScreen/placeholder';

function SectionHeader({
  releaseId,
  statusId,
  collapsed,
  onToggleCollapsed,
  count,
}: {
  releaseId: string;
  statusId: string;
  collapsed: boolean;
  onToggleCollapsed: () => void;
  count?: number;
}) {
  const id = `header-${statusId}`;
  const ref = React.useRef<HTMLDivElement>(null);
  const columnHasFocus = useColumnHasKeyNavigationFocus(statusId);
  const focused = useHasKeyNavigationFocus(id);
  const status = useRecoilValue(statusSelector(statusId));
  const modals = useModals();

  useKeyNavigationElement(id, ref);

  if (!status) {
    return null;
  }

  return (
    <div className="listHeaderContainer">
      <div className="listHeader" onClick={onToggleCollapsed} ref={ref}>
        <div className="row grow ellipsis">
          <Icon
            icon={collapsed ? 'arrow_forward' : 'arrow_down'}
            className="mr8 clickable grayIcon"
          />
          {status && <StatusIcon type={status.statusType} className="mr8" />}
          <div className="ellipsis mr8">{status?.name}</div>
          {count !== undefined && <Count count={count} />}
        </div>
        <div className="row">
          <Tooltip
            content={
              <>
                {`Create ${issueTerm}`} <KeyboardShortcut shortcut={createNewIssueKey} />
              </>
            }
          >
            <IconButton
              icon="add"
              buttonStyle={ButtonStyle.BareSubtle}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                const args: NewEntityArgs = {
                  spaceId: status.spaceId,
                  statusId,
                  releaseIds: [releaseId],
                };

                modals.openModal(Modals.NewEntity, args);
              }}
            />
          </Tooltip>
        </div>
        {status && columnHasFocus && (
          <CommandContext<StatusCommandGroupContext>
            context={{ group: CommandGroup.Status, statusId: status.id }}
          />
        )}
        {focused && (
          <>
            <Hotkey
              hotkey="space"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
            <Hotkey
              hotkey="enter"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export function ReleaseIssuesList({ releaseId }: { releaseId: string }) {
  const modals = useModals();
  const issueIdsByStatus = useRecoilValue(releaseIssuesByStatusSelector(releaseId));
  const items = React.useMemo(() => values(issueIdsByStatus).flat(), [issueIdsByStatus]);
  const statuses = React.useMemo(() => keys(issueIdsByStatus), [issueIdsByStatus]);
  const spaceIds = useRecoilValue(spaceIdsForEntitiesSelector(items));
  const numberColumnWidth = useEntityNumberWidths(spaceIds);

  if (!statuses.length || items.length === 0) {
    return (
      <Placeholder icon="release" title={`No ${issueTerm}s added to the release yet`}>
        <span className="grayed textCenter">
          <p>{capitalize(issueTerm)}s connected to this release will appear here</p>
        </span>
        <div className="row mr12 headerGap">
          <Button
            onClick={() => {
              modals.openModal(Modals.CommandMenu, {
                view: CommandMenuView.ChangeReleaseIssues,
                context: {
                  releaseId,
                },
              });
            }}
          >
            Select {issueTerm}
          </Button>
          <Button
            icon="add"
            onClick={() => {
              modals.openModal(Modals.NewEntity, { releaseIds: [releaseId] });
            }}
          >
            New {issueTerm}
          </Button>
        </div>
      </Placeholder>
    );
  }

  return (
    <VirtualizedListView
      id={`my-work-entities`}
      className={'fullWidth grow'}
      style={
        {
          '--number-column-width': `${numberColumnWidth}px`,
          backgroundColor: 'var(--gray2)',
        } as React.CSSProperties
      }
      sectionIds={statuses}
      itemIds={issueIdsByStatus}
      sectionHeaderHeight={60}
      itemHeight={41}
      spacerHeight={32}
      renderSectionHeader={(id, collapsed, toggleCollapsed) => {
        if (id === 'input') {
          return null;
        }
        return (
          <SectionHeader
            releaseId={releaseId}
            statusId={id}
            collapsed={collapsed}
            onToggleCollapsed={toggleCollapsed}
            count={issueIdsByStatus[id].length}
          />
        );
      }}
      renderItem={(id, _sectionId, isFirst, isLast, _edit) => {
        return (
          <WorkItemListItem
            id={id}
            className={cn('listItem', {
              first: isFirst,
              last: isLast,
            })}
          />
        );
      }}
      renderPlaceholder={s => {
        return <ListItemPlaceholder type="initiative" statusId={s} list />;
      }}
    />
  );
}
