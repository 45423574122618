import cn from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { EntityCommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { DocumentListItem } from '../../../components/new/documentListItem';
import { KeyNavigationProvider } from '../../../components/new/keyNavigation';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { useOrganization } from '../../../contexts/organizationContext';
import { archivedDocumentIdsSelector } from '../../../syncEngine/selectors/documents';
import { useEntityNumberWidths } from '../../../syncEngine/selectors/entities';
import { LocationState, silentlyUpdateHistoryState } from '../../../utils/history';
import { ArchivePlaceholder } from './placeholder';

export function DocumentArchive() {
  const organization = useOrganization();
  const location = useLocation<LocationState>();
  const docs = useRecoilValue(archivedDocumentIdsSelector({ organizationId: organization.id }));
  const numberColumnWidth = useEntityNumberWidths([]);
  const copyEntities = useCopyEntitiesToClipboard();

  const focusedElementId = React.useMemo(() => {
    if (location.state?.entity) {
      const { entity, ...rest } = location.state;
      silentlyUpdateHistoryState(rest);
      return entity;
    }

    return docs[0];
  }, []);

  if (!docs.length) {
    return <ArchivePlaceholder type="document" />;
  }

  return (
    <KeyNavigationProvider
      initiallyFocusedElementId={focusedElementId}
      columnIds={['archive-documents']}
      disableEnsureVisible
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <EntityCommandContext />
      {docs.length > 0 && (
        <VirtualizedListView
          id={`archive-documents`}
          className={'fullWidth grow mt16'}
          sectionIds={['archived']}
          itemIds={{ archived: docs }}
          sectionHeaderHeight={0}
          itemHeight={41}
          spacerHeight={32}
          renderSectionHeader={() => <></>}
          renderItem={(id, _sectionId, isFirst, isLast, _edit) => {
            return (
              <DocumentListItem
                id={id}
                className={cn('listItem', {
                  first: isFirst,
                  last: isLast,
                })}
                style={
                  {
                    '--number-column-width': `${numberColumnWidth}px`,
                  } as React.CSSProperties
                }
              />
            );
          }}
          renderPlaceholder={() => {
            return <></>;
          }}
          renderAccessories={grid => {
            return <ResizeItemsOnStateChange ids={grid} />;
          }}
          onCopy={copyEntities}
        />
      )}
    </KeyNavigationProvider>
  );
}
