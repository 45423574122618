import cn from 'classnames';
import * as React from 'react';
import { issueTerm } from '../../../../shared/utils/terms';
import { TodoStatus } from '../../../../sync/__generated/models';
import { Icon, IconSize } from '../../../components/new/icon';
import { Tooltip } from '../../../components/new/tooltip';
import { toast } from '../../../components/toast';
import { todoStatusToIcon } from '../../../syncEngine/selectors/todos';
import styles from './smartTodo.module.scss';

function CheckboxIconComponent({
  status,
  linkedToEntity,
  linkedToExternalIssue,
  onClick,
}: {
  status: TodoStatus;
  linkedToEntity?: boolean;
  linkedToExternalIssue?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}) {
  const content = (
    <div
      onClick={e => {
        if (linkedToEntity) {
          e.preventDefault();
          e.stopPropagation();
          toast.info(
            `This todo reflects the state of the linked ${issueTerm} and can't be updated manually.`
          );
          return;
        }
        if (linkedToExternalIssue) {
          e.preventDefault();
          e.stopPropagation();
          toast.info(
            `This todo reflects the state of the linked external issue and can't be updated manually.`
          );
          return;
        }

        onClick?.(e);
      }}
      onMouseDown={e => e.preventDefault()}
      className="noLineHeight"
      role="checkbox"
    >
      <Icon
        className={cn(styles.checkbox, {
          [styles.started]: status !== TodoStatus.NotStarted,
          [styles.disabled]: linkedToEntity || linkedToExternalIssue,
        })}
        icon={todoStatusToIcon(status, linkedToEntity || linkedToExternalIssue)}
        size={IconSize.Size20}
      />
    </div>
  );

  if (linkedToEntity || linkedToExternalIssue) {
    return (
      <Tooltip
        content={
          <>This todo is linked to the mentioned {linkedToEntity ? issueTerm : 'external issue'}</>
        }
      >
        {content}
      </Tooltip>
    );
  }

  return content;
}

export const CheckboxIcon = React.memo(CheckboxIconComponent);
