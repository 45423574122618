import React from 'react';
import { IssueStatusSortMode, RoadmapColumn } from '../../../../sync/__generated/models';
import { SortIndicator } from '../../../components/sortIndicator';
import { inverseSortMode } from '../../../syncEngine/actions/issueStatuses';
import { useUpdateRoadmapColumnSortMode } from '../../../syncEngine/actions/roadmaps';

export function RoadmapColumnSortIndicator({
  roadmapColumn,
  disableEditing,
  className,
}: {
  roadmapColumn: RoadmapColumn;
  disableEditing?: boolean;
  className?: string;
}) {
  const updateSortMode = useUpdateRoadmapColumnSortMode();
  const changeOrder = React.useCallback(
    (originalSortMode: IssueStatusSortMode) => {
      return (e: React.MouseEvent<HTMLButtonElement>) => {
        if (disableEditing) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();

        const sortMode = inverseSortMode(originalSortMode);
        updateSortMode(roadmapColumn.id, sortMode);
      };
    },
    [disableEditing, roadmapColumn.id, updateSortMode]
  );

  if (!roadmapColumn.sortMode || roadmapColumn.sortMode === IssueStatusSortMode.Manual) {
    return null;
  }

  return (
    <SortIndicator
      sortMode={roadmapColumn.sortMode}
      className={className}
      changeOrder={changeOrder}
    />
  );
}
