import * as React from 'react';

export interface RowHeightContext {
  recomputeRowHeights(index?: number): void;
}

const rowHeightContext = React.createContext<RowHeightContext | null>(null);

export function RowHeightProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value: RowHeightContext;
}) {
  return <rowHeightContext.Provider value={value}>{children}</rowHeightContext.Provider>;
}

export function useRowHeights() {
  return React.useContext(rowHeightContext)!;
}
