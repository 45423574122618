import { isUndefined } from 'lodash';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { emptyDocument } from '../../../shared/slate/utils';
import { feedbackOwners, feedbackTags } from '../../../sync/__generated/collections';
import { insightsByFeedback } from '../../../sync/__generated/indexes';
import { Feedback } from '../../../sync/__generated/models';
import NewEntityToastContents from '../../components/newEntityToastContents';
import { toast } from '../../components/toast';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { DocumentLike } from '../../slate/types';
import { trackerEvent } from '../../tracker';
import { nextAvailableNumber } from '../../utils/entities';
import { openFeedbackState } from '../selectors/feedback';
import { createCollaborativeDocHelper } from './collaborativeDoc';
import { NO_COMPANY_ID } from './companies';
import { deleteInsightHelper } from './insights';

function createFeedbackHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  title: string,
  organizationId: string,
  actorId: string,
  options?: {
    companyId?: string | null;
    personId?: string | null;
    ownerIds?: string[];
    tagIds?: string[];
    content?: DocumentLike | null;
  }
) {
  const { companyId: originalCompanyId, personId, ownerIds, tagIds, content } = options ?? {};

  const companyId = originalCompanyId === NO_COMPANY_ID ? null : originalCompanyId;

  const feedback: SyncEngineCreate<Feedback> = {
    __typename: 'Feedback',
    organizationId,
    title,
    number: nextAvailableNumber(getters, organizationId, 'feedback'),
    processed: false,
    companyId: companyId ?? null,
    personId: personId ?? null,
    processedAt: null,
    actorId,
    ownerIds: [],
    tagIds: [],
    watcherIds: [],
  };

  const result = tx.create<Feedback>(feedback);

  createCollaborativeDocHelper(tx, organizationId, content ?? emptyDocument(), result.id);

  if (ownerIds?.length) {
    tx.addToCollection(feedbackOwners, result.id, ownerIds);
  }
  if (tagIds?.length) {
    tx.addToCollection(feedbackTags, result.id, tagIds);
  }

  return result;
}

export function useCreateFeedback() {
  const organization = useOrganization();
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (
    title: string,
    options?: {
      companyId?: string | null;
      personId?: string | null;
      ownerIds?: string[];
      tagIds?: string[];
      content?: DocumentLike | null;
    }
  ): Feedback => {
    return modelManager.transaction((tx, getters) => {
      const result = createFeedbackHelper(tx, getters, title, organization.id, user.id, options);
      if (result) {
        trackerEvent('Feedback Created', {
          id: result.id,
        });
      }
      toast.success(<NewEntityToastContents entityId={result.id} />);
      return result;
    });
  };
}

export function useUpdateFeedbacks() {
  const modelManager = useModelManager();
  return (feedbackIds: string[], update: SyncEngineUpdateWithoutDelete<Feedback>) => {
    modelManager.transaction((tx, { get }) => {
      for (const feedbackId of feedbackIds) {
        const feedback = get<Feedback>(feedbackId);
        if (!feedback || feedback.deleted) {
          continue;
        }
        if (update.personId && feedback.companyId) {
          update.companyId = null;
        }
        if (update.companyId === NO_COMPANY_ID) {
          update.companyId = null;
        }
        tx.update<Feedback>(feedbackId, update);

        if (update.title) {
          trackerEvent('Feedback Updated', {
            id: feedback.id,
            type: 'Title',
          });
        }
        if (!isUndefined(update.processed)) {
          trackerEvent('Feedback Updated', {
            id: feedback.id,
            type: 'Processed',
          });
        }
      }
    });
  };
}

export function useDeleteFeedbacks() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const [openFeedbackId, setOpenFeedbackId] = useRecoilState(openFeedbackState);

  return async (feedbackIds: string[]) => {
    const confirmed = await confirm(
      `Delete feedback${feedbackIds.length > 1 ? 's' : ''}`,
      `Are you sure you want to delete ${
        feedbackIds.length > 1 ? 'these feedbacks' : 'this feedback'
      }? There is no way to undo this operation`,
      {
        label: 'Delete',
        destructive: true,
      }
    );

    if (!confirmed) {
      return [];
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const feedbackId of feedbackIds) {
        const feedback = get<Feedback>(feedbackId);
        if (!feedback || feedback.deleted) {
          continue;
        }

        if (feedbackId === openFeedbackId) {
          setOpenFeedbackId('deleted');
        }

        const insightIds = getIndex(insightsByFeedback, feedbackId);
        for (const insightId of insightIds) {
          deleteInsightHelper(tx, { get, getIndex }, insightId);
        }

        tx.update<Feedback>(feedbackId, {
          deleted: true,
        });
        deleted.push(feedbackId);

        trackerEvent('Feedback Deleted', {
          id: feedback.id,
        });
      }
    });

    return deleted;
  };
}

export function useAddOwnersToFeedback() {
  const modelManager = useModelManager();
  return (feedbackIds: string[], assigneeIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const feedbackId of feedbackIds) {
        const feedback = get<Feedback>(feedbackId);
        if (!feedback) {
          continue;
        }
        tx.addToCollection(feedbackOwners, feedbackId, assigneeIds);
      }
    });
  };
}

export function useRemoveOwnersFromFeedback() {
  const modelManager = useModelManager();
  return (feedbackIds: string[], assigneeIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const feedbackId of feedbackIds) {
        const feedback = get<Feedback>(feedbackId);
        if (!feedback) {
          continue;
        }
        tx.removeFromCollection(feedbackOwners, feedbackId, assigneeIds);
      }
    });
  };
}

export function useAddTagToFeedback() {
  const modelManager = useModelManager();
  return (feedbackIds: string[], tabIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of feedbackIds) {
        const entity = get<Feedback>(entityId);
        if (!entity) {
          continue;
        }
        tx.addToCollection(feedbackTags, entityId, tabIds);
      }
    });
  };
}

export function useRemoveTagFromFeedbak() {
  const modelManager = useModelManager();
  return (entityIds: string[], labelIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Feedback>(entityId);
        if (!entity) {
          continue;
        }
        tx.removeFromCollection(feedbackTags, entityId, labelIds);
      }
    });
  };
}
