import React from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { capitalize } from '../../shared/utils/utils';
import { useConfiguration } from '../contexts/configurationContext';
import { localStorageEffect } from '../syncEngine/effects';
import { hideSnoozedAtom } from '../syncEngine/selectors/issues';
import styles from './metadataConfig.module.scss';
import { ButtonStyle, IconButton } from './new/button';
import { Hotkey } from './new/hotkey';
import { KeyboardShortcut } from './new/keyboardShortcut';
import Pill, { PillStyle } from './new/metadata/pill';
import { NotificationDot } from './new/notifications';
import Popover from './new/popover';
import { Setting } from './new/settings';
import { Switch } from './new/switch';
import { Tooltip } from './new/tooltip';

export type MetadataConfig = {
  initiatives: boolean;
  labels: boolean;
  impact: boolean;
  effort: boolean;
  todos: boolean;
  members: boolean;
  insights: boolean;
  codeReview: boolean;
  dependencies: boolean;
  cycles: boolean;
  status: boolean;
  spaces: boolean;
  roadmaps: boolean;
  dueDates: boolean;
  releases: boolean;
};

const defaultMetadataConfig: MetadataConfig = {
  initiatives: true,
  labels: true,
  impact: true,
  effort: true,
  todos: true,
  members: true,
  insights: true,
  codeReview: true,
  dependencies: true,
  cycles: true,
  status: true,
  spaces: true,
  roadmaps: true,
  dueDates: true,
  releases: true,
};

export const initiativeDefaultExcludeOptions: Array<keyof MetadataConfig> = [
  'initiatives',
  'cycles',
  'codeReview',
  'dependencies',
];

export const workItemDefaultExcludeOptions = ['status', 'spaces', 'roadmaps'] as Array<
  keyof MetadataConfig
>;

const metdataNames: { [shortName: string]: string } = {
  codeReview: 'Code Review Requests',
  dueDates: 'Due dates',
};

export const boardMetadataConfig = atomFamily<MetadataConfig, string>({
  key: 'BoardMetadataConfig',
  default: defaultMetadataConfig,
  effects: key => [localStorageEffect(`__boardMetadata_${key}`)],
});

export function MetadataConfigOption({
  title,
  checked,
  onChange,
  hotkey,
}: {
  title: string;
  checked: boolean;
  hotkey?: { key: string; hint: string };
  onChange: (checked: boolean) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  const hideTooltip = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setOpen(false);
  }, []);

  const showTooltip = React.useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setOpen(true);
    }, 500);
  }, []);

  let content = (
    <Setting centerAlign title={title}>
      <Switch checked={checked} onChange={onChange} />
    </Setting>
  );

  if (hotkey) {
    content = (
      <Tooltip
        disableHoverableContent
        align="end"
        open={open}
        content={
          <div onMouseEnter={hideTooltip} className="row gap8">
            <span>{hotkey.hint}</span> <KeyboardShortcut shortcut={hotkey.key} />{' '}
          </div>
        }
      >
        <div className="fullWidth">
          {content}
          <Hotkey
            hotkey={hotkey.key}
            handler={() => {
              onChange(!checked);
            }}
          />
        </div>
      </Tooltip>
    );
  }

  return (
    <div onMouseEnter={showTooltip} onMouseLeave={hideTooltip} className={styles.header}>
      {content}
    </div>
  );
}

function MetadataConfigurationContent({
  boardId,
  header,
  excludeOptions,
  children,
}: {
  boardId: string;
  header?: React.ReactNode;
  excludeOptions?: Array<keyof MetadataConfig>;
  children?: React.ReactNode;
}) {
  const { featureFlags } = useConfiguration();
  const [config, setConfig] = useRecoilState(boardMetadataConfig(boardId));
  const [hideSnoozed, setHideSnoozed] = useRecoilState(hideSnoozedAtom(boardId));
  const options = (Object.keys(defaultMetadataConfig) as Array<keyof MetadataConfig>).filter(
    v => !excludeOptions?.includes(v)
  );

  return (
    <div className={styles.popover}>
      <div className={styles.header}>
        {!header && <span className="headingS semiBold">View options</span>}
        {header && header}
      </div>
      {children}
      {featureFlags.FEATURE_TOGGLE_SNOOZE && (
        <MetadataConfigOption
          title="Hide snoozed items"
          onChange={setHideSnoozed}
          checked={hideSnoozed}
        />
      )}
      <div className={styles.content}>
        <span className="headingS mb12 mb8">Display metadata</span>
        <div className={styles.options}>
          {options.map(option => {
            return (
              <Pill
                className="unselectable"
                textOnly
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setConfig(oldConfig => {
                    const newConfig = { ...oldConfig };
                    newConfig[option] = !config[option];
                    return newConfig;
                  });
                }}
                pillStyle={config[option] ? PillStyle.Primary : PillStyle.Default}
                key={option}
              >
                {metdataNames[option] ?? capitalize(option)}
              </Pill>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export function MetadataConfigurationButton({
  boardId,
  header,
  extraSettings,
  excludeOptions,
  className,
  showDot,
}: {
  boardId: string;
  header?: React.ReactNode;
  extraSettings?: React.ReactNode;
  excludeOptions?: Array<keyof MetadataConfig>;
  className?: string;
  showDot?: boolean;
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <Popover
      content={
        <MetadataConfigurationContent
          header={header}
          excludeOptions={excludeOptions}
          boardId={boardId}
        >
          {extraSettings}
        </MetadataConfigurationContent>
      }
      open={open}
      onOpenChange={setOpen}
      contentOptions={{
        align: 'end',
      }}
      asChild
    >
      <div className={className}>
        <NotificationDot show={!!showDot}>
          <Tooltip content="View options">
            <IconButton icon="view_settings" buttonStyle={ButtonStyle.BareSubtle} />
          </Tooltip>
        </NotificationDot>
      </div>
    </Popover>
  );
}
