import cn from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Transforms } from 'slate';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { documentFromString, stringifyDocument } from '../../../shared/slate/utils';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { emojiSuggestionMatcher } from '../../slate/plugins/suggestions/emojiSuggestionMatcher';
import { initiativeSuggestionMatcher } from '../../slate/plugins/suggestions/initiativeSuggestionMatcher';
import { labelSuggestionMatcher } from '../../slate/plugins/suggestions/labelSuggestionMatcher';
import { userSuggestionMatcher } from '../../slate/plugins/suggestions/userSuggestionMatcher';
import { TextArea, TextAreaHandle } from '../../slate/textArea';
import { DocumentLike, Elements } from '../../slate/types';
import { useCreateInitiative } from '../../syncEngine/actions/intiatives';
import { useCreateLabel } from '../../syncEngine/actions/labels';
import { useCreateOrganizationLabel } from '../../syncEngine/actions/organizationLabels';
import { initiativeSuggestionsForOrgSelector } from '../../syncEngine/selectors/intiatives';
import { labelsForSpaceSelector } from '../../syncEngine/selectors/labels';
import { orgLabelsForOrganizationSelector } from '../../syncEngine/selectors/organizationLabels';
import { activeUsersForMaybeSpaceSelector } from '../../syncEngine/selectors/spaces';
import { metaKeyDown, modifierKeyDown } from '../../utils/keyEvents';

interface SupportedMetadata {
  labels?: boolean;
  orgLabels?: boolean;
  users?: boolean;
  initiatives?: boolean;
}

type MetadataType = 'label' | 'user' | 'initiative';

function extractMetadata(content: DocumentLike): { type: MetadataType; id: string } | null {
  if (!content.length || !KitemakerElement.isElement(content[0])) {
    return null;
  }

  for (const node of content[0].children) {
    if (!KitemakerElement.isElement(node)) {
      continue;
    }

    switch (node.type) {
      case Elements.User:
        return { type: 'user', id: node.userId };
      case Elements.Label:
        return { type: 'label', id: node.labelId };
      case Elements.Entity:
        // TODO: fix this if we ever allow mentioning other entities
        return { type: 'initiative', id: node.entityId };
    }
  }

  return null;
}

function deleteMetadata(ref: React.RefObject<TextAreaHandle>) {
  if (!ref.current) {
    return;
  }

  const editor = ref.current.raw();
  const content = editor.children;

  if (!content.length || !KitemakerElement.isElement(content[0])) {
    return;
  }

  for (let index = 0; index < content[0].children.length; index++) {
    const node = content[0].children[index];
    if (!KitemakerElement.isElement(node)) {
      continue;
    }

    if ([Elements.User, Elements.Label, Elements.Entity].includes(node.type)) {
      Transforms.removeNodes(editor, { at: [0, index] });
      Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true });
      return;
    }
  }
}

function EntityTitleEditorComponent(
  {
    initialTitle,
    supportedMetadata,
    onChange,
    onSubmit,
    onReset,
    onBlur,
    onFocus,
    onMetadataAdded,
    disabled,
    autoFocus,
    placeholder,
    oneLine,
    className,
    style,
  }: {
    initialTitle: string;
    onChange?: (title: string) => void;
    onSubmit?: (e: React.KeyboardEvent) => void;
    onReset?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    supportedMetadata?: SupportedMetadata;
    onMetadataAdded?: (type: MetadataType, id: string) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    placeholder?: React.ReactNode;
    oneLine?: boolean;
    className?: string;
    style?: React.CSSProperties;
  },
  ref?: React.ForwardedRef<TextAreaHandle>
) {
  const organization = useOrganization();
  const editorRef = React.useRef<TextAreaHandle | null>(null);
  const user = useCurrentUser();
  const space = useMaybeSpace();
  const labels = useRecoilValue(labelsForSpaceSelector(space?.id));
  const orgLabels = useRecoilValue(orgLabelsForOrganizationSelector(organization.id));
  const initiatives = useRecoilValue(
    initiativeSuggestionsForOrgSelector({ organizationId: organization?.id, spaceId: space?.id })
  );

  const users = useRecoilValue(
    activeUsersForMaybeSpaceSelector({ organizationId: organization.id, spaceId: space?.id })
  );
  const createInitiative = useCreateInitiative();
  const createLabel = useCreateLabel();
  const createOrgLabel = useCreateOrganizationLabel();
  const onBlurRef = React.useRef(onBlur);
  onBlurRef.current = onBlur;
  const touchedRef = React.useRef(false);

  const labelsRef = React.useRef(labels);
  labelsRef.current = labels;
  const orgLabelsRef = React.useRef(orgLabels);
  orgLabelsRef.current = orgLabels;
  const usersRef = React.useRef(users);
  usersRef.current = users;
  const initiativesRef = React.useRef(initiatives);
  initiativesRef.current = initiatives;

  useComponentDidMount(() => {
    return () => {
      if (touchedRef.current) {
        onBlurRef.current?.();
      }
    };
  });

  const suggestions = React.useMemo(() => {
    const matchers = [emojiSuggestionMatcher()];
    if (supportedMetadata?.orgLabels) {
      matchers.push(
        labelSuggestionMatcher(
          name => createOrgLabel(organization.id, name),
          () => orgLabelsRef.current
        )
      );
    }
    if (supportedMetadata?.labels && space) {
      matchers.push(
        labelSuggestionMatcher(
          name => createLabel(space.id, name),
          () => labelsRef.current
        )
      );
    }

    if (supportedMetadata?.initiatives) {
      matchers.push(
        initiativeSuggestionMatcher(
          title => {
            const { initiative } = createInitiative(title, {
              spaceIds: space ? [space.id] : undefined,
            });
            return initiative;
          },
          () => initiativesRef.current
        )
      );
    }
    if (supportedMetadata?.users) {
      matchers.push(userSuggestionMatcher(user, usersRef.current));
    }

    return matchers;
  }, []);

  return (
    <TextArea
      ref={r => {
        editorRef.current = r;
        if (ref) {
          if (isFunction(ref)) {
            ref(r);
          } else {
            ref.current = r;
          }
        }
      }}
      richText={false}
      initialValue={documentFromString(initialTitle)}
      onChange={value => {
        const metadata = extractMetadata(value);
        if (metadata) {
          onMetadataAdded?.(metadata.type, metadata.id);
        }
        if (!touchedRef.current) {
          touchedRef.current = true;
        }
        onChange?.(stringifyDocument(value));
        deleteMetadata(editorRef);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={e => {
        if (e.key.toLowerCase() === 'enter' && !metaKeyDown(e) && !modifierKeyDown(e)) {
          e.preventDefault();
          onSubmit?.(e);
          return;
        }
        if (e.key.toLowerCase() === 'escape') {
          e.preventDefault();
          e.stopPropagation();
          onReset?.();
          editorRef.current?.blur();
        }
      }}
      disabled={disabled}
      autoFocus={autoFocus}
      placeholder={placeholder}
      className={cn(className, 'fs-exclude')}
      style={style}
      suggestions={suggestions}
      oneLine={oneLine}
    />
  );
}

export const EntityTitleEditor = React.forwardRef(EntityTitleEditorComponent);
