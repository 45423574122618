import { LocationDescriptor } from 'history';
import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  MentionedActivityDetails,
} from '../../../../sync/__generated/models';
import { StaticSlateDocument } from '../../../slate/staticSlate';
import {
  entityKeySelector,
  entityTypeSelector,
  isDeletedSelector,
  useEntityPath,
} from '../../../syncEngine/selectors/entities';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import ActorName from '../../actorName';
import { Hotkey } from '../hotkey';
import { useHasKeyNavigationFocus } from '../keyNavigation';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function MentionedActivityComponent({ activity }: { activity: ActivityModel }) {
  const focused = useHasKeyNavigationFocus(activity.id);
  const history = useHistory();
  const location = useLocation();

  const details = activity.details as MentionedActivityDetails;
  const isDeleted = useRecoilValue(isDeletedSelector(details.entityId));
  const space = useRecoilValue(spaceSelector(details.entityId));
  const entityPathFunc = useEntityPath();
  const entityPath = entityPathFunc(details.entityId);
  const type = useRecoilValue(entityTypeSelector(activity.entityId));
  let key =
    useRecoilValue(entityKeySelector(details.entityId)) ??
    `${details.fallbackSpaceKey}-${details.fallbackEntityNumber}`;

  if (isDeleted) {
    key = `${key} (DELETED)`;
  }

  function calculateLink() {
    if (!space || !entityPath) {
      return null;
    }

    if (!isDeleted && details.commentId && details.commentThreadId) {
      return {
        pathname: entityPath,
        search: details.commentReply
          ? `?commentThreadId=${details.commentThreadId}&commentId=${details.commentId}`
          : `?commentId=${details.commentId}`,
        state: {
          backUrl: location.pathname,
        },
      };
    } else if (!isDeleted) {
      return {
        pathname: entityPath,
        state: {
          backUrl: location.pathname,
        },
      };
    }

    return null;
  }

  const link: LocationDescriptor<any> | null = calculateLink();
  const issueNumberComponent = link ? <Link to={link}>{key}</Link> : <span>{key}</span>;
  const commentComponent = details.commentId && details.commentThreadId ? 'a comment in ' : null;

  return (
    <Link to={location => link ?? location}>
      <Activity activity={activity}>
        <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> mentioned this{' '}
        {type} in {commentComponent}
        <span className={styles.activityHighlight}>{issueNumberComponent}</span>.
        <ActivityTimestamp timestamp={activity.createdAt} />
        <div className={styles.activityContext}>
          <StaticSlateDocument value={JSON.parse(details.context)} className="quote" />
        </div>
        {focused && link && (
          <Hotkey
            hotkey="enter"
            handler={() => {
              history.push(link);
            }}
          />
        )}
      </Activity>
    </Link>
  );
}

export const MentionedActivity = React.memo(MentionedActivityComponent);
