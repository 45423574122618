import * as React from 'react';
import Placeholder from '../../../components/new/placeholder';

export function ArchivePlaceholder({ type }: { type: string }) {
  return (
    <Placeholder icon="archive" title={'Nothing archived'}>
      <span className="grayed textCenter">
        <p>We'll collect all your archived {type}s here</p>
      </span>
    </Placeholder>
  );
}
