import { difference } from 'lodash';
import {
  defaultLargeEffort,
  defaultLargeImpact,
  defaultMediumEffort,
  defaultMediumImpact,
  defaultSmallEffort,
  defaultSmallImpact,
} from '../../../shared/utils/impactAndEffortLevels';
import { randomString } from '../../../shared/utils/random';
import {
  effortLevelsBySpace,
  impactLevelsBySpace,
  issuesByEffort,
  issuesByImpact,
  todosByEffort,
  todosByImpact,
} from '../../../sync/__generated/indexes';
import { Effort, Impact, Issue, Todo } from '../../../sync/__generated/models';
import {
  SyncEngineCreate,
  SyncEngineTransaction,
  useModelManager,
} from '../../graphql/modelManager';
import { SyncEngineIndex, SyncEngineObject } from '../types';

function createDeleteTransactions(
  tx: SyncEngineTransaction,
  get: <T extends SyncEngineObject>(id: string) => T | null,
  getIndex: (index: SyncEngineIndex, id: string) => string[],
  oldIds: string[],
  newIds: string[]
) {
  const idsToDelete = difference(oldIds, newIds);
  for (const id of idsToDelete) {
    const level = get<Impact | Effort>(id);
    if (!level || level.deleted) {
      continue;
    }

    // clean up issues
    if (level.__typename === 'Impact') {
      const issueIds = getIndex(issuesByImpact, level.id);
      for (const issueId of issueIds) {
        tx.update<Issue>(issueId, { impactId: null });
      }
    } else {
      const issueIds = getIndex(issuesByEffort, level.id);
      for (const issueId of issueIds) {
        tx.update<Issue>(issueId, { effortId: null });
      }
    }

    // clean up todos
    if (level.__typename === 'Impact') {
      const todoIds = getIndex(todosByImpact, level.id);
      for (const todoId of todoIds) {
        tx.update<Todo>(todoId, { impactId: null });
      }
    } else {
      const todoIds = getIndex(todosByEffort, level.id);
      for (const todoId of todoIds) {
        tx.update<Todo>(todoId, { effortId: null });
      }
    }

    tx.update<Impact | Effort>(id, {
      deleted: true,
      name: `__deleted__${randomString(8)}`,
      abbrevation: `_d_${randomString(5)}`,
    });
  }
}

function createUpdateTransactions<T extends Impact | Effort>(
  tx: SyncEngineTransaction,
  oldIds: string[],
  newLevels: T[]
): void {
  const levelsToUpdate = newLevels.filter(obj => oldIds.includes(obj.id));
  levelsToUpdate.forEach(level => {
    const { createdAt, updatedAt, id, version, deleted, ...update } = level;
    tx.update<Impact | Effort>(id, update);
  });
}

function createCreateTransactions<T extends Impact | Effort>(
  tx: SyncEngineTransaction,
  oldIds: string[],
  newLevels: T[]
): void {
  const levelsToCreate = newLevels.filter(obj => !oldIds.includes(obj.id));
  levelsToCreate.forEach(level => {
    const { createdAt, updatedAt, id, version, deleted, ...create } = level;
    tx.create<Impact | Effort>(create);
  });
}

export function useSetImpactEffortLevels() {
  const modelManager = useModelManager();
  return (newImpactLevels: Impact[], newEffortLevels: Effort[], spaceId: string): void => {
    const numberedImpactLevels = newImpactLevels.map((impact, index) => ({
      ...impact,
      level: index + 1,
    }));

    const numberedEffortLevels = newEffortLevels.map((effort, index) => ({
      ...effort,
      level: index + 1,
    }));

    return modelManager.transaction((tx, { getIndex, get }) => {
      const impactLevelIds = getIndex(impactLevelsBySpace, spaceId);
      const newImpactLevelIds = newImpactLevels.map(l => l.id);
      const effortLevelIds = getIndex(effortLevelsBySpace, spaceId);
      const newEffortLevelIds = newEffortLevels.map(l => l.id);

      createDeleteTransactions(tx, get, getIndex, impactLevelIds, newImpactLevelIds);
      createDeleteTransactions(tx, get, getIndex, effortLevelIds, newEffortLevelIds);

      createUpdateTransactions(tx, impactLevelIds, numberedImpactLevels);
      createUpdateTransactions(tx, effortLevelIds, numberedEffortLevels);

      createCreateTransactions(tx, impactLevelIds, numberedImpactLevels);
      createCreateTransactions(tx, effortLevelIds, numberedEffortLevels);
    });
  };
}

export function createDefaultImpactEffortLevels(
  tx: SyncEngineTransaction,
  organizationId: string,
  spaceId: string
): (Effort | Impact)[] {
  const defaults: (Effort | Impact)[] = [];

  const smallEffort: SyncEngineCreate<Effort> = {
    __typename: 'Effort',
    organizationId,
    spaceId,
    ...defaultSmallEffort,
  };
  defaults.push(tx.create(smallEffort));

  const mediumEffort: SyncEngineCreate<Effort> = {
    __typename: 'Effort',
    organizationId,
    spaceId,
    ...defaultMediumEffort,
  };
  defaults.push(tx.create(mediumEffort));

  const largeEffort: SyncEngineCreate<Effort> = {
    __typename: 'Effort',
    organizationId,
    spaceId,
    ...defaultLargeEffort,
  };
  defaults.push(tx.create(largeEffort));

  const smallImpact: SyncEngineCreate<Impact> = {
    __typename: 'Impact',
    organizationId,
    spaceId,
    ...defaultSmallImpact,
  };
  defaults.push(tx.create(smallImpact));

  const mediumImpact: SyncEngineCreate<Impact> = {
    __typename: 'Impact',
    organizationId,
    spaceId,
    ...defaultMediumImpact,
  };
  defaults.push(tx.create(mediumImpact));

  const largeImpact: SyncEngineCreate<Impact> = {
    __typename: 'Impact',
    organizationId,
    spaceId,
    ...defaultLargeImpact,
  };
  defaults.push(tx.create(largeImpact));

  return defaults;
}
