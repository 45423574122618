import * as React from 'react';
import { NodeEntry, Node } from 'slate';
import { useSlateStatic } from 'slate-react';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import Hover from '../hovers';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { HistoryEditor } from '../plugins/history';

export function LoomLinkHover() {
  const [visible, setVisible] = React.useState(true);
  const editor = useSlateStatic();

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        setVisible(false);
        return true;
      }

      if (editor.isAccept(e)) {
        e.preventDefault();
        e.stopPropagation();

        let parent: NodeEntry<Node> | undefined = KitemakerEditor.above(editor, {
          match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
        });

        if (!parent) {
          // Are we right at the beginning of a text node? Maybe the thing before us is a link
          const selection = safeSelection(editor);
          if (selection?.focus.offset === 0) {
            const previous = KitemakerEditor.previous(editor, {
              at: selection,
              mode: 'all',
              match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
            });
            if (previous) {
              parent = previous;
            }
          }
        }

        if (!parent) {
          return true;
        }

        const [parentNode, parentPath] = parent;
        if (!KitemakerElement.isElement(parentNode) || parentNode.type !== Elements.Link) {
          return true;
        }

        const url = parentNode.url;
        if (!url) {
          return true;
        }

        const edges = KitemakerEditor.edges(editor, parentPath);
        HistoryEditor.asBatch(editor, () => {
          KitemakerTransforms.select(editor, { anchor: edges[0], focus: edges[1] });
          KitemakerTransforms.unwrapNodes(editor, {
            match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
          });
          KitemakerTransforms.setBlockElementAndSplitIfNeeded(editor, Elements.Loom, {
            properties: { url },
          });
        });
        return true;
      }

      return false;
    },
    [editor]
  );

  return (
    <Hover
      hoverId="link-loom"
      fixed
      open={visible}
      onOpenChange={setVisible}
      contentOptions={{
        side: 'top',
      }}
      onKeyDown={onKeyDown}
      content={
        <div className="mr8 ml8 mt4 mb4 headingS noWrap row">
          <div className="mr8">
            <KeyboardShortcut shortcut="enter" />
          </div>
          to embed Loom video
        </div>
      }
    ></Hover>
  );
}
