import { atomFamily, useRecoilState } from 'recoil';
import { useClient } from 'urql';
import { BillingInformation, fetchBillingInfo } from '../api/billing';
import { useOrganization } from '../contexts/organizationContext';
import { sessionStorageEffect } from '../syncEngine/effects';

export const billingInfoState = atomFamily<BillingInformation | null, string>({
  key: 'BillingInfo',
  default: null,
  effects: key => [sessionStorageEffect(`__billing__${key}`)],
});

export default function useBillingInfo() {
  const organization = useOrganization();
  const client = useClient();
  const [billingInfo, setBillingInfo] = useRecoilState(billingInfoState(organization.id));

  async function loadBillingInfo(redirectURL: string) {
    const info = await fetchBillingInfo(client, organization.id, redirectURL);
    setBillingInfo(info);
    return info;
  }

  return {
    billingInfo,
    loadBillingInfo,
  };
}
