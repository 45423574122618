import * as React from 'react';
import { Redirect } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useOrganization } from '../contexts/organizationContext';
import { useSpace } from '../contexts/spaceContext';
import { FetchedMarker, OrganizationMarker } from '../graphql/smartLoad';
import { NotFoundScreen } from '../screens/errorScreens';
import LoadingScreen from '../screens/loadingScreen';
import { entityTypeByNumberSelector } from '../syncEngine/selectors/entities';
import { markerState } from '../syncEngine/selectors/smartLoader';
import { spacePath } from '../syncEngine/selectors/spaces';
import { Screen } from './screen';

export function EntityRedirect({ entityNumber }: { entityNumber: string }) {
  const organization = useOrganization();
  const space = useSpace();
  const entityType = useRecoilValue(
    entityTypeByNumberSelector({ spaceId: space.id, entityNumber })
  );
  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(space.organizationId, false))
  );
  const fetched = useRecoilValue(markerState(FetchedMarker.id));

  if (entityType === 'Issue') {
    return <Redirect to={`${spacePath(organization, space, `items/${entityNumber}`)}`} />;
  }

  if (entityType || (fetched && currentOrgFullyFetched)) {
    return (
      <Screen>
        <NotFoundScreen />
      </Screen>
    );
  }

  return <LoadingScreen />;
}
