import cn from 'classnames';
import * as React from 'react';
import { ButtonProperties } from './button';
import styles from './buttonGroup.module.scss';

function ButtonGroupComponent(
  {
    className,
    children,
  }: {
    children: React.ReactElement[];
    className?: string;
  } & ButtonProperties,
  ref?: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref} className={cn('row', styles.group, className ?? '')}>
      {children}
    </div>
  );
}

export const ButtonGroup = React.forwardRef(ButtonGroupComponent);
