import cn from 'classnames';
import * as React from 'react';
import styles from './voidActions.module.scss';

export function VoidActions({
  children,
  floating,
  className,
}: {
  children: React.ReactNode;
  floating?: boolean;
  className?: string;
}) {
  return (
    <div
      className={cn(
        styles.actions,
        {
          [styles.floating]: floating,
        },
        className
      )}
    >
      {children}
    </div>
  );
}
