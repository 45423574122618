import { capitalize, values } from 'lodash';
import * as React from 'react';
import { ReleaseStatus } from '../../../../sync/__generated/models';
import { releaseStatusName } from '../../../screens/releaseScreen';
import { FilteredListViewHandle } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import { Picker, PickerState } from '../picker';

export function iconForRelease(state: string) {
  switch (state) {
    case ReleaseStatus.Released:
      return 'released';
    default:
      return 'release';
  }
}

export function ReleaseStatusPicker({
  state,
  filterPlaceholder,
  filterClassName,
  additionalItems,
  additionalItemsFirst,
  onDone,
  onReleaseStatusAdded,
  onReleaseStatusRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  onDone: () => void;
  onReleaseStatusAdded: (issueIds: string[], impactId: string) => void;
  onReleaseStatusRemoved: (issueIds: string[], impactId: string) => void;
}) {
  const states = values(ReleaseStatus);

  const items: ListViewItem[] = states.map(state => {
    return {
      id: state,
      mouseDown: true,
      contents: () => (
        <>
          <Icon icon={iconForRelease(state)} size={IconSize.Size20} />
          <span className="ellipsis">{capitalize(releaseStatusName(state))}</span>
        </>
      ),
    };
  });

  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      multi
      filterLabel="Choose release status"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Choose state'}
      placeholder={'No state found'}
      ref={filterRef}
      state={state}
      items={items}
      propertiesToSearch={['id']}
      onAdd={onReleaseStatusAdded}
      onRemove={onReleaseStatusRemoved}
      onDone={onDone}
    />
  );
}
