import { allEmojisById, emojiShortcuts } from '../../../utils/emoji';
import { EditorType } from '../../types';
import { simpleReplacementMatcher } from './simpleReplacementMatcher';
import { Matcher } from './withStringMatching';

const emojiShortcutReplacements = Object.entries(emojiShortcuts).map(([key, emoji]) => ({
  id: key,
  value: key,
  emoji,
}));

export function emojiShortcutMatcher(editor: EditorType): Matcher {
  return simpleReplacementMatcher(editor, emojiShortcutReplacements, shortCut => {
    return [{ text: `${allEmojisById.get(shortCut.emoji)?.unicode ?? ''}` }];
  });
}
