import cn from 'classnames';
import { uniq } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useEntityPath } from '../../syncEngine/selectors/entities';
import { insightsForEntity } from '../../syncEngine/selectors/insights';
import styles from './feedbackWorkItems.module.scss';
import { Icon } from './icon';
import menuStyles from './menu/menu.module.scss';
import Pill from './metadata/pill';
import { MetadataSize } from './metadata/size';
import { EntityItem } from './pickers/entityPicker';
import { MetadataTooltip } from './tooltip';

function TooltipContent({ issueIds }: { issueIds: string[] }) {
  const issuePath = useEntityPath();

  return (
    <div
      className={styles.container}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <span className={styles.header}>Linked work items</span>
      {issueIds.map(issueId => {
        const link = {
          pathname: issuePath(issueId) ?? '',
          state: {
            backUrl: location.pathname,
            backSearch: location.search,
            entity: issueId,
          },
        };
        return (
          <Link className={cn('row', menuStyles.item, styles.item)} key={issueId} to={link}>
            <EntityItem disableTooltip entityId={issueId} />
          </Link>
        );
      })}
    </div>
  );
}

export function FeedbackWorkItems({
  initiativeId,
  size,
}: {
  initiativeId: string;
  size?: MetadataSize;
}) {
  const insights = useRecoilValue(insightsForEntity(initiativeId));
  const issueIds = uniq(insights.flatMap(i => i.entityIds));

  if (issueIds.length === 0) {
    return null;
  }

  const content = (
    <Pill size={size}>
      <Icon icon="work_item" />
      {issueIds.length} linked work items
    </Pill>
  );

  return (
    <MetadataTooltip side="bottom" align="start" content={<TooltipContent issueIds={issueIds} />}>
      {content}
    </MetadataTooltip>
  );
}
