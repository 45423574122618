import { Application } from '../../../sync/__generated/models';
import { SyncEngineUpdateWithoutDelete, useModelManager } from '../../graphql/modelManager';

export function useUpdateApplications() {
  const modelManager = useModelManager();
  return (applicationIds: string[], update: SyncEngineUpdateWithoutDelete<Application>) => {
    modelManager.transaction(tx => {
      for (const applicationId of applicationIds) {
        tx.update<Application>(applicationId, update);
      }
    });
  };
}
