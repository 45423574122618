import * as React from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { colorWithOpacity } from '../../../utils/color';
import { Icon, IconSize } from '../icon';
import styles from './initiative.module.scss';
import Pill, { PillStyle } from './pill';

export default function Initiative({
  color,
  title,
  iconOnly,
  forceDarkMode,
}: {
  color: string;
  title: string;
  iconOnly?: boolean;
  forceDarkMode?: boolean;
}) {
  return (
    <Pill
      className={styles.initiative}
      forceDarkMode={forceDarkMode}
      color={color}
      iconOnly={iconOnly}
      pillStyle={PillStyle.Primary}
    >
      <InitiativeIcon size={IconSize.Size16} color={color} />
      {!iconOnly && <span className="ellipsis">{title}</span>}
    </Pill>
  );
}

export function InitiativeIcon({
  color,
  className,
  style,
  size = IconSize.Size20,
}: {
  color: string;
  className?: string;
  style?: React.CSSProperties;
  size?: IconSize;
}) {
  const { darkMode } = useDarkMode();
  return (
    <Icon
      size={size}
      icon="initiative"
      className={className}
      style={{ ...style, fill: colorWithOpacity(color, 10, darkMode, true) }}
    />
  );
}
