import cn from 'classnames';
import debugModule from 'debug';
import { pick } from 'lodash';
import * as React from 'react';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { UploadResult, fileUploader, prepareUpload } from '../../utils/fileUploader';
import { attachmentsPath } from '../../utils/paths';
import { toast } from '../toast';
import { Button, ButtonStyle } from './button';
import styles from './changeableAvatar.module.scss';
import { LoadingSpinner } from './loadingSpinner';
import Avatar, { AvatarSize } from './metadata/avatar';

export function ChangeableAvatar({
  name,
  img,
  onUploadCompleted,
  onRemove,
  showButtons,
  maxSize,
  AvatarComponent,
}: {
  name: string;
  img?: string | null;
  onUploadCompleted?: (result: UploadResult[]) => void;
  onRemove?: () => void;
  showButtons?: boolean;
  maxSize?: { width: number; height: number };
  AvatarComponent?: React.ComponentType<{ name: string; img?: string | null; size: AvatarSize }>;
}) {
  const AvatarRenderer = AvatarComponent ?? Avatar;
  const organization = useOrganization();
  const maybeSpace = useMaybeSpace();

  const [uploading, setUploading] = React.useState(false);
  const [dragOver, setDragOver] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const uploadPath = attachmentsPath(organization, maybeSpace);
  const debug = debugModule('upload');
  const acceptedTypes = ['image/png', 'image/jpeg'];

  const uploader = React.useRef(
    fileUploader(uploadPath, {
      onBeforeUpload: () => setUploading(true),
      multi: false,
      maxSize,
    })
  );

  async function upload(dataTransfer: DataTransfer) {
    if (!dataTransfer.files?.length) {
      return;
    }

    for (const file of dataTransfer.files) {
      if (!acceptedTypes.includes(file.type)) {
        const niceName = file.type.split('/')[1]?.split('+')[0];
        toast.error(`Invalid filetype: ${niceName ?? file.type}`);
        return;
      }
    }

    try {
      setUploading(true);
      const uploadResults = prepareUpload(
        uploader.current,
        uploadPath,
        Array.from(dataTransfer.files)
      );
      const uploadResultsCleaned = uploadResults.map(
        res => pick(res, ['url', 'file', 'size', 'name']) as UploadResult
      );

      await uploader.current.upload();
      onUploadCompleted?.(uploadResultsCleaned);
    } catch (err) {
      toast.error(`There was an error uploading your file${err.message ? `: ${err.message}` : ''}`);
      debug('There was an error uploading your file', err);
    } finally {
      setUploading(false);
      uploader.current.reset();
    }
  }

  return (
    <div className="rowStretch">
      <div
        className={cn(styles.dropTarget, { [styles.dragOver]: dragOver })}
        onDragLeave={e => {
          e.preventDefault();
          e.stopPropagation();
          setDragOver(false);
        }}
        onDragOver={e => {
          if (e.dataTransfer.types.some(t => t === 'application/json')) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          setDragOver(true);
        }}
        onDrop={async e => {
          e.preventDefault();
          e.stopPropagation();
          if (dragOver) {
            setDragOver(false);
          }

          upload(e.dataTransfer);
        }}
      >
        <input
          type="file"
          className={styles.input}
          ref={fileInputRef}
          accept={acceptedTypes.join(', ')}
          onChange={e => {
            upload(e.currentTarget as unknown as DataTransfer);
          }}
        />
        {uploading && <LoadingSpinner className={styles.spinner} />}
        {!uploading && (
          <AvatarRenderer name={name} img={img} size={AvatarSize.Size96} key={img ?? name} />
        )}
      </div>

      {showButtons && (
        <div className="col ml32">
          <Button onClick={() => fileInputRef.current?.click()} className="mb16">
            {img && <>Change image</>}
            {!img && <>Upload image</>}
          </Button>
          {img && (
            <Button buttonStyle={ButtonStyle.DestructiveSubtle} onClick={() => onRemove?.()}>
              Remove image
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
