import * as React from 'react';
import Hotkey from '../../components/hotkey';
import { Modals, useModals } from '../../contexts/modalContext';
import { trackerPageLoad } from '../../tracker';
import { ErrorScreen } from './errorScreen';
import styles from './errorScreen.module.scss';

export function NoSpacesScreen() {
  const modals = useModals();
  React.useEffect(() => {
    trackerPageLoad('No spaces');
  }, []);

  return (
    <ErrorScreen>
      <div className={styles.errorHeading}>No spaces</div>
      <span>Your organization does not currently have any spaces</span>
      <span>
        <a
          href="#"
          className="link"
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            modals.openModal(Modals.CreateSpace);
          }}
        >
          Create a new space
        </a>
      </span>
      <Hotkey
        command={{
          id: 'new-space',
          hotkey: 'enter',
          handler: () => {
            modals.openModal(Modals.CreateSpace);
          },
        }}
      />
    </ErrorScreen>
  );
}
