import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { RenderElementProps } from 'slate-react';
import { EntityElement, IssueElement } from '../../../shared/slate/types';
import { Icon, IconSize } from '../../components/new/icon';
import { InitiativeIcon } from '../../components/new/metadata/initiative';
import { statusToIcon } from '../../components/new/statusIcon';
import { isCycle } from '../../syncEngine/selectors/cycles';
import { isDocument } from '../../syncEngine/selectors/documents';
import {
  entityArchivedSelector,
  entitySelector,
  isSpaceBoundEntity,
  useEntityPath,
} from '../../syncEngine/selectors/entities';
import { isFeedback } from '../../syncEngine/selectors/feedback';
import { isInitiative } from '../../syncEngine/selectors/intiatives';
import { issueIdByNumberSelector, statusSelector } from '../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../syncEngine/selectors/issueStatuses';
import { isRelease } from '../../syncEngine/selectors/releases';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import styles from './entityMention.module.scss';
import { VoidInline } from './voidBlock';

function isEntityElement(element: EntityElement | IssueElement): element is EntityElement {
  return (element as EntityElement).entityId !== undefined;
}

function useFindEntityId(element: EntityElement | IssueElement): string {
  const maybeIssueId = useRecoilValue(
    issueIdByNumberSelector({
      spaceId: !isEntityElement(element) ? element.spaceId : '',
      issueNumber: !isEntityElement(element) ? element.issueNumber ?? '' : '',
    })
  );
  return isEntityElement(element) ? element.entityId : element.issueId ?? maybeIssueId ?? '';
}

export function StaticEntityMention({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: EntityElement | IssueElement }) {
  const history = useHistory();
  const entityPath = useEntityPath();

  const entityId = useFindEntityId(element);
  const entity = useRecoilValue(entitySelector(entityId));
  const status = useRecoilValue(
    statusSelector(entity?.__typename === 'Issue' ? entity.statusId : '')
  );
  const isDefault = useRecoilValue(isDefaultStatusSelector(status?.id));
  const space = useRecoilValue(
    spaceSelector(entity && isSpaceBoundEntity(entity) ? entity.spaceId : '')
  );
  const archived = useRecoilValue(entityArchivedSelector(entityId));

  if (!entity) {
    return (
      <DummyNode as="span" attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  return (
    <span
      className={cn(styles.entityMention, 'clickable', {
        [styles.archived]: archived,
      })}
      {...attributes}
      onClick={() => {
        const path = entityPath(entityId ?? '');
        if (path) {
          const link = {
            pathname: path,
            state: {
              backUrl: location.pathname,
              backSearch: location.search,
              entity: entityId,
            },
          };

          history.push(link);
        }
      }}
    >
      {status && (
        <Icon
          size={IconSize.Size20}
          icon={statusToIcon(status.statusType, isDefault)}
          className={cn('mr4', styles.icon, 'inlineIcon')}
        />
      )}
      {isInitiative(entity) && (
        <>
          <InitiativeIcon
            size={IconSize.Size20}
            color={entity.color}
            className={cn('mr4', 'inlineIcon')}
          />
          I-{entity.number}
        </>
      )}
      {isFeedback(entity) && (
        <>
          <Icon
            size={IconSize.Size20}
            icon={isCycle(entity) ? 'cycle_current' : 'feedback'}
            className={cn('mr4', styles.icon, 'inlineIcon')}
          />
          F-{entity.number}
        </>
      )}
      {isCycle(entity) && (
        <Icon
          size={IconSize.Size20}
          icon={'cycle_current'}
          className={cn('mr4', styles.icon, 'inlineIcon')}
        />
      )}
      {isDocument(entity) && (
        <Icon
          size={IconSize.Size20}
          icon="document"
          className={cn('mr4', styles.icon, 'inlineIcon')}
        />
      )}
      {isRelease(entity) && (
        <>
          <Icon
            size={IconSize.Size20}
            icon="release"
            className={cn('mr4', styles.icon, 'inlineIcon')}
          />
          R-{entity.number}
        </>
      )}
      {space && (
        <>
          {space.key}-{isCycle(entity) ? 'C' : ''}
          {entity.number}
        </>
      )}
      <span className="semiBold ml2 ml4">{entity.title}</span>
      {children}
    </span>
  );
}

export function EntityMention({
  attributes,
  element,
  children,
}: RenderElementProps & { element: EntityElement | IssueElement }) {
  const history = useHistory();
  const entityPath = useEntityPath();

  const entityId = useFindEntityId(element);
  const entity = useRecoilValue(entitySelector(entityId));
  const status = useRecoilValue(
    statusSelector(entity?.__typename === 'Issue' ? entity.statusId : '')
  );
  const isDefault = useRecoilValue(isDefaultStatusSelector(status?.id));
  const space = useRecoilValue(
    spaceSelector(entity && isSpaceBoundEntity(entity) ? entity.spaceId : '')
  );
  const archived = useRecoilValue(entityArchivedSelector(entityId));

  if (!entity) {
    return (
      <DummyNode as="span" attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  return (
    <VoidInline
      attributes={attributes}
      element={element}
      className={cn(styles.entityMention, styles.slate, {
        [styles.archived]: archived,
      })}
      onClick={() => {
        const path = entityPath(entityId ?? '');
        if (path) {
          const link = {
            pathname: path,
            state: {
              backUrl: location.pathname,
              backSearch: location.search,
              entity: entityId,
            },
          };
          history.push(link);
        }
      }}
    >
      {isInitiative(entity) && (
        <>
          <InitiativeIcon
            size={IconSize.Size20}
            color={entity.color}
            className={cn('mr4', 'inlineIcon')}
          />
          I-{entity.number}
        </>
      )}
      {isFeedback(entity) && (
        <>
          <Icon
            size={IconSize.Size20}
            icon={isCycle(entity) ? 'cycle_current' : 'feedback'}
            className={cn('mr4', styles.icon, 'inlineIcon')}
          />
          F-{entity.number}
        </>
      )}
      {isDocument(entity) && (
        <>
          <Icon
            size={IconSize.Size20}
            icon="document"
            className={cn('mr4', styles.icon, 'inlineIcon')}
          />
          D-{entity.number}
        </>
      )}
      {isRelease(entity) && (
        <>
          <Icon
            size={IconSize.Size20}
            icon="release"
            className={cn('mr4', styles.icon, 'inlineIcon')}
          />
          R-{entity.number}
        </>
      )}
      {status && (
        <Icon
          size={IconSize.Size20}
          icon={statusToIcon(status.statusType, isDefault)}
          className={cn('mr4', styles.icon, 'inlineIcon')}
        />
      )}
      {isCycle(entity) && (
        <Icon
          size={IconSize.Size20}
          icon={'cycle_current'}
          className={cn('mr4', styles.icon, 'inlineIcon')}
        />
      )}
      {space && (
        <>
          {space.key}-{isCycle(entity) ? 'C' : ''}
          {entity.number}
        </>
      )}
      <span className="semiBold mr2 ml4">{entity.title}</span>
      {children}
    </VoidInline>
  );
}
