import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { peopleByOrganization } from '../../../sync/__generated/indexes';
import { Person } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { feedbackSelector } from './feedback';

export const personSelector = selectorFamily({
  key: 'person',
  get:
    (personId: string | undefined | null) =>
    ({ get }) => {
      if (!personId) {
        return null;
      }
      return get(syncEngineState(personId)) as Person;
    },
});

export const peopleSelector = selectorFamily({
  key: 'people',
  get:
    (peopleIds: string[]) =>
    ({ get }) => {
      return filterNotNull(peopleIds.map(personId => get(personSelector(personId))));
    },
});

export const personForFeedbackSelector = selectorFamily({
  key: 'personForFeedback',
  get:
    (feedbackId: string | undefined | null) =>
    ({ get }) => {
      if (!feedbackId) {
        return null;
      }
      const feedback = get(feedbackSelector(feedbackId));
      if (!feedback) {
        return null;
      }

      return get(personSelector(feedback.personId));
    },
});

export const peopleForOrganizationSelector = selectorFamily({
  key: 'peopleForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const personIds = get(indexKeyState(indexKey(peopleByOrganization, organizationId)));
      return filterNotDeletedNotNull(personIds.map(personId => get(personSelector(personId))));
    },
});
