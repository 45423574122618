import debugModule from 'debug';
import { Client } from 'urql';
import {
  IssueStatusFilter,
  IssueStatusFilterType,
  IssueStatusType,
} from '../../../graphql__generated__/graphql';
import { Space } from '../../sync/__generated/models';
import { SyncEngineObject } from '../syncEngine/types';
import { RequestCycles, RequestIssueByNumber, RequestIssues } from './api';
import { BoardType } from './modelManager';

const debug = debugModule('modelManager');

export interface CurrentUser extends SyncEngineObject {
  currentUserId?: string;
}

const defaultProps = {
  __typename: 'Marker',
  version: 0,
  deleted: false,
  createdAt: new Date().getTime(),
  updatedAt: new Date().getTime(),
};

export const OrganizationMarker = {
  id: (organizationId: string, partial = false) => `organization_${organizationId}_${partial}`,
  create: (organizationId: string, partial = false) => {
    return { id: OrganizationMarker.id(organizationId, partial), ...defaultProps };
  },
};

export const CurrentUserMarker = {
  id: 'currentUser',
  create: (currentUserId?: string) => {
    return { id: CurrentUserMarker.id, currentUserId, ...defaultProps };
  },
};

export const BoardMarker = {
  id: (spaceId: string, boardName: BoardType) => `board_${spaceId}_${boardName}`,
  create: (spaceId: string, boardName: BoardType) => {
    return { id: BoardMarker.id(spaceId, boardName), ...defaultProps };
  },
};

export const FetchedMarker = {
  id: '__fetched',
  create: () => ({ id: FetchedMarker.id, ...defaultProps }),
};

export class SmartLoad {
  private client: Client;
  private requestIssues: RequestIssues;
  private requestCycles: RequestCycles;
  private requestIssueByNumber: RequestIssueByNumber;

  constructor(
    client: Client,
    requestIssues: RequestIssues,
    requestCycles: RequestCycles,
    requestIssueByNumber: RequestIssueByNumber
  ) {
    this.client = client;
    this.requestIssues = requestIssues;
    this.requestCycles = requestCycles;
    this.requestIssueByNumber = requestIssueByNumber;
  }

  async issueFirst(
    workItemNumber: number,
    currentSpace: Space,
    remainingSpaces: Space[],
    lastFetched: number | null,
    dataLoaded: (o: SyncEngineObject[]) => void
  ) {
    const primaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];
    const secondaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];

    debug('Loading primary data');
    primaryLoad.push(this.requestIssueByNumber(this.client, currentSpace.id, workItemNumber));

    const primaryData = await Promise.all(primaryLoad);
    dataLoaded([...primaryData.map(d => d.data).flat()]);
    debug('Primary data loaded');

    debug('Loading secondary data');
    secondaryLoad.push(this.requestIssues(this.client, currentSpace.id, { lastFetched }));
    secondaryLoad.push(this.requestCycles(this.client, currentSpace.id, { lastFetched }));

    for (const space of remainingSpaces) {
      secondaryLoad.push(
        this.requestIssues(this.client, space.id, {
          lastFetched,
        })
      );
      secondaryLoad.push(this.requestCycles(this.client, space.id, { lastFetched }));
    }

    const secondaryData = await Promise.all(secondaryLoad);
    dataLoaded([
      ...secondaryData.map(d => d.data).flat(),
      ...remainingSpaces
        .map(s => [
          BoardMarker.create(s.id, 'current'),
          BoardMarker.create(s.id, 'backlog'),
          BoardMarker.create(s.id, 'archive'),
        ])
        .flat(),
      ...primaryData.map(d => d.data).flat(),
      BoardMarker.create(currentSpace.id, 'current'),
      BoardMarker.create(currentSpace.id, 'backlog'),
      BoardMarker.create(currentSpace.id, 'archive'),
    ]);
    debug('Secondary data loaded');
  }

  async spaceFirst(
    boardType: BoardType | undefined,
    currentSpace: Space,
    remainingSpaces: Space[],
    lastFetched: number | null,
    dataLoaded: (o: SyncEngineObject[]) => void
  ) {
    const primaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];
    const secondaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];

    let statusFilter: IssueStatusType[] | undefined = undefined;
    switch (boardType) {
      case 'archive':
        statusFilter = [IssueStatusType.ARCHIVED];
        break;
      case 'backlog':
        statusFilter = [IssueStatusType.BACKLOG, IssueStatusType.TODO];
        break;
      case 'current':
        statusFilter = [IssueStatusType.TODO, IssueStatusType.IN_PROGRESS, IssueStatusType.DONE];
        break;
    }

    debug('Loading primary data');
    primaryLoad.push(
      this.requestIssues(this.client, currentSpace.id, {
        lastFetched,
        statusFilter: statusFilter
          ? {
              type: IssueStatusFilterType.INCLUDE,
              filter: statusFilter,
            }
          : undefined,
      })
    );
    primaryLoad.push(this.requestCycles(this.client, currentSpace.id, { lastFetched }));

    const primaryData = await Promise.all(primaryLoad);
    dataLoaded([
      ...primaryData.map(d => d.data).flat(),
      ...(boardType
        ? [BoardMarker.create(currentSpace.id, boardType)]
        : [
            BoardMarker.create(currentSpace.id, 'archive'),
            BoardMarker.create(currentSpace.id, 'backlog'),
            BoardMarker.create(currentSpace.id, 'current'),
          ]),
    ]);
    debug('Primary data loaded');

    debug('Loading secondary data');
    if (statusFilter) {
      secondaryLoad.push(
        this.requestIssues(this.client, currentSpace.id, {
          lastFetched,
          statusFilter: statusFilter
            ? {
                type: IssueStatusFilterType.EXCLUDE,
                filter: statusFilter,
              }
            : undefined,
        })
      );
    }

    for (const space of remainingSpaces) {
      secondaryLoad.push(
        this.requestIssues(this.client, space.id, {
          lastFetched,
        })
      );
      secondaryLoad.push(this.requestCycles(this.client, space.id, { lastFetched }));
    }

    const secondaryData = await Promise.all(secondaryLoad);
    const remainingBoards: BoardType[] = boardType
      ? (['current', 'archive', 'backlog'].filter(b => b !== boardType) as BoardType[])
      : [];

    dataLoaded([
      ...secondaryData.map(d => d.data).flat(),
      ...remainingBoards.map(b => BoardMarker.create(currentSpace.id, b)),
      ...remainingSpaces
        .map(s => [
          BoardMarker.create(s.id, 'current'),
          BoardMarker.create(s.id, 'backlog'),
          BoardMarker.create(s.id, 'archive'),
        ])
        .flat(),
    ]);
    debug('Secondary data loaded');
  }

  async loadEverything(
    spaces: Space[],
    lastFetched: number | null,
    dataLoaded: (o: SyncEngineObject[]) => void
  ) {
    const primaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];

    for (const space of spaces) {
      primaryLoad.push(
        this.requestIssues(this.client, space.id, {
          lastFetched,
        })
      );
      primaryLoad.push(this.requestCycles(this.client, space.id, { lastFetched }));
    }

    debug('Loading primary data');
    const primaryData = await Promise.all(primaryLoad);
    dataLoaded([
      ...primaryData.map(d => d.data).flat(),
      ...spaces
        .map(s => [
          BoardMarker.create(s.id, 'current'),
          BoardMarker.create(s.id, 'backlog'),
          BoardMarker.create(s.id, 'archive'),
        ])
        .flat(),
    ]);
    debug('Primary data loaded');
  }

  async nonArchivedFirst(
    spaces: Space[],
    lastFetched: number | null,
    dataLoaded: (o: SyncEngineObject[]) => void
  ) {
    const primaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];
    const secondaryLoad: Promise<{ data: SyncEngineObject[] }>[] = [];

    const nonArchivedFilter: IssueStatusFilter = {
      type: IssueStatusFilterType.EXCLUDE,
      filter: [IssueStatusType.ARCHIVED],
    };

    debug('Loading primary data');
    for (const space of spaces) {
      primaryLoad.push(
        this.requestIssues(this.client, space.id, {
          lastFetched,
          statusFilter: nonArchivedFilter,
        })
      );
      primaryLoad.push(this.requestCycles(this.client, space.id, { lastFetched }));
    }

    const primaryData = await Promise.all(primaryLoad);
    dataLoaded([
      ...primaryData.flatMap(d => d.data),
      ...spaces.flatMap(s => [
        BoardMarker.create(s.id, 'current'),
        BoardMarker.create(s.id, 'backlog'),
      ]),
    ]);
    debug('Primary data loaded');

    const archivedFilter: IssueStatusFilter = {
      type: IssueStatusFilterType.INCLUDE,
      filter: [IssueStatusType.ARCHIVED],
    };

    debug('Loading secondary data');
    for (const space of spaces) {
      secondaryLoad.push(
        this.requestIssues(this.client, space.id, {
          lastFetched,
          statusFilter: archivedFilter,
        })
      );
    }

    const secondaryData = await Promise.all(secondaryLoad);
    dataLoaded([
      ...secondaryData.flatMap(d => d.data),
      ...spaces.map(s => BoardMarker.create(s.id, 'archive')),
    ]);
    debug('Secondary data loaded');
  }
}
