import gql from 'graphql-tag';
import * as React from 'react';
import { CombinedError, useMutation } from 'urql';
import {
  InviteUsersMutation,
  InviteUsersMutationVariables,
  MemberRole as MemberRoleGQL,
  ResendInviteMutation,
  ResendInviteMutationVariables,
} from '../../../../graphql__generated__/graphql';
import { organizationMemberFragment, userFragment } from '../../../sync/__generated/fragments';
import { roadmapsByOrganization } from '../../../sync/__generated/indexes';
import { MemberRole, Organization } from '../../../sync/__generated/models';
import { toast } from '../../components/toast';
import { useClient } from '../../contexts/clientContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { messageFromGraphQLError } from '../../graphql/errors';
import { SyncEngineUpdateWithoutDelete, useModelManager } from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { labelColors } from '../../utils/config';
import { useStateTransaction } from '../state';
import { indexHelper } from './helpers';
import { createRoadmapHelper } from './roadmaps';

function onError(error?: CombinedError) {
  const message =
    messageFromGraphQLError(error?.graphQLErrors ?? []) ?? 'An unknown error occurred';
  toast.error(message);
}

function syncToGqlMemberRole(role: MemberRole): MemberRoleGQL {
  switch (role) {
    case MemberRole.Admin:
      return MemberRoleGQL.ADMIN;
    case MemberRole.Member:
      return MemberRoleGQL.MEMBER;
    case MemberRole.Guest:
      return MemberRoleGQL.GUEST;
  }
}

export function useInviteUsers() {
  const organization = useOrganization();
  const stateTransaction = useStateTransaction();
  const client = useClient();

  const [, inviteMutation] = useMutation<InviteUsersMutation, InviteUsersMutationVariables>(
    gql`
      mutation InviteUsers(
        $organization: ID!
        $emails: [String!]!
        $role: MemberRole!
        $client: String!
      ) {
        inviteUsers(
          input: { organizationId: $organization, emails: $emails, role: $role, clientId: $client }
        ) {
          invitedUsers {
            ...UserFragment
          }
          members {
            ...OrganizationMemberFragment
          }
        }
      }
      ${userFragment}
      ${organizationMemberFragment}
    `
  );

  return async (emails: string[], role: MemberRole): Promise<string[]> => {
    const result = await inviteMutation({
      organization: organization.id,
      emails,
      role: syncToGqlMemberRole(role),
      client,
    });

    if (result.error || !result.data?.inviteUsers.invitedUsers) {
      onError(result.error);
      return [];
    }

    toast.info(
      <>
        <span className="semiBold">
          {result.data.inviteUsers.invitedUsers.length === 1
            ? result.data.inviteUsers.invitedUsers[0].primaryEmail
            : `${result.data.inviteUsers.invitedUsers.length} users `}
        </span>{' '}
        invited
      </>
    );

    stateTransaction(tx => {
      tx.set([...result.data!.inviteUsers.invitedUsers, ...result.data!.inviteUsers.members]);
    });

    result.data.inviteUsers.invitedUsers.forEach(_ => {
      trackerEvent('User Invited');
    });

    return result.data.inviteUsers.invitedUsers.map(u => u.id);
  };
}

export function useResendInvite() {
  const organization = useOrganization();
  const stateTransaction = useStateTransaction();
  const client = useClient();

  const [, resendInviteMutation] = useMutation<ResendInviteMutation, ResendInviteMutationVariables>(
    gql`
      mutation ResendInvite($organization: ID!, $user: ID!, $client: String!) {
        resendInvite(input: { organizationId: $organization, userId: $user, clientId: $client }) {
          invitedUser {
            ...UserFragment
          }
          member {
            ...OrganizationMemberFragment
          }
        }
      }
      ${userFragment}
      ${organizationMemberFragment}
    `
  );

  return async (userId: string): Promise<boolean> => {
    const result = await resendInviteMutation({
      client,
      user: userId,
      organization: organization.id,
    });

    if (result.error) {
      onError(result.error);
      return false;
    }

    stateTransaction(tx => {
      if (result.data?.resendInvite?.invitedUser) {
        toast.info(
          <>
            Resent invite to{' '}
            <span className="semiBold">{result.data.resendInvite.invitedUser.primaryEmail}</span>
          </>
        );
        tx.set([result.data.resendInvite.invitedUser]);
      }
      if (result.data?.resendInvite?.member) {
        tx.set([result.data?.resendInvite?.member]);
      }
    });

    const resent = !!result.data?.resendInvite.invitedUser;
    if (resent) {
      trackerEvent('Invite Resent');
    }
    return resent;
  };
}

export function useUpdateOrganization() {
  const modelManager = useModelManager();
  const organization = useOrganization();
  const user = useCurrentUser();

  return (update: SyncEngineUpdateWithoutDelete<Organization>) => {
    modelManager.transaction((tx, getters) => {
      const roadmaps = indexHelper(getters, roadmapsByOrganization, organization.id, true);
      if (update.newRoadmapsEnabled && !roadmaps.length) {
        createRoadmapHelper(tx, getters, `${organization.name} Roadmap`, organization.id, user.id, {
          color: labelColors[0],
        });
      }
      if (update.aiEnabled !== undefined) {
        trackerEvent('AI Toggled', { enabled: update.aiEnabled });
      }
      tx.update(organization.id, update);
    });
  };
}
