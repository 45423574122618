import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { DefaultFocusElement } from '../../../components/defaultFocusElement';
import { ListView, NO_KEYNAV } from '../../../components/new/listView';
import styles from '../../../components/new/menu/menu.module.scss';
import { useOrganization } from '../../../contexts/organizationContext';
import { useUpdateEntities } from '../../../syncEngine/actions/entities';
import { useUpdateTodos } from '../../../syncEngine/actions/todos';
import {
  impactLevelsForOrganizationSelector,
  impactLevelsForSpaceSelector,
} from '../../../syncEngine/selectors/impactLevels';

function SetImpact({
  onClose,
  onSetImpact,
  spaceId,
}: {
  onClose: () => void;
  onSetImpact: (impactId: string | null) => void;
  spaceId?: string;
}) {
  const organization = useOrganization();
  const spaceImpacts = useRecoilValue(impactLevelsForSpaceSelector(spaceId));
  const orgImpacts = useRecoilValue(impactLevelsForOrganizationSelector(organization.id)).filter(
    i => !i.spaceId
  );
  const impacts = spaceId ? spaceImpacts : orgImpacts;

  const twoCharacters = impacts.some(l => l.abbrevation.length === 2);

  return (
    <div className="rowStretch fullWidth fullHeight">
      <DefaultFocusElement />
      <ListView
        className={styles.content}
        itemClassName={styles.item}
        items={[
          {
            id: NO_KEYNAV,
            key: 'header',
            contents: <div className={styles.listItemHeader}>Set impact</div>,
          },
          {
            id: 'none',
            contents: 'None',
            hotkey: '0',
            onSelected: () => {
              onSetImpact(null);
              onClose();
            },
          },
          ...impacts.map((impact, index) => ({
            id: impact.id,
            contents: impact.name,
            hotkey: `${index + 1}`,
            icon: (
              <span
                className={cn(styles.abbreviation, { [styles.twoCharacters]: twoCharacters })}
                style={{ color: mapColor(impact.color) }}
              >
                {impact.abbrevation}
              </span>
            ),
            onSelected: () => {
              onSetImpact(impact.id);
              onClose();
            },
          })),
        ]}
      />
    </div>
  );
}

export function SetImpactContents({
  onClose,
  entityIds,
  spaceId,
}: {
  onClose: () => void;
  entityIds: string[];
  spaceId?: string;
}) {
  const updateEntities = useUpdateEntities();
  return (
    <SetImpact
      onClose={onClose}
      spaceId={spaceId}
      onSetImpact={impactId => updateEntities(entityIds, { impactId })}
    />
  );
}

export function SetTodoImpactContents({
  onClose,
  todoId,
  spaceId,
}: {
  onClose: () => void;
  todoId: string;
  spaceId: string;
}) {
  const updateTodos = useUpdateTodos();

  return (
    <SetImpact
      onClose={onClose}
      spaceId={spaceId}
      onSetImpact={impactId => updateTodos([todoId], { impactId })}
    />
  );
}
