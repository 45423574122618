import { IntegrationType } from '../../sync/__generated/models';

export function stringifyIntegrationType(type: IntegrationType) {
  switch (type) {
    case IntegrationType.Github:
      return 'GitHub';
    case IntegrationType.Gitlab:
      return 'GitLab';
    case IntegrationType.Figma:
      return 'Figma';
    case IntegrationType.Trello:
      return 'Trello';
    case IntegrationType.Slack:
      return 'Slack';
    case IntegrationType.Slack2:
      return 'Slack';
    case IntegrationType.Discord:
      return 'Discord';
    case IntegrationType.Zapier:
      return 'Zapier';
    case IntegrationType.Email:
      return 'Email';
    case IntegrationType.Intercom:
      return 'Intercom';
    case IntegrationType.Vanta:
      return 'Vanta';
    case IntegrationType.Sentry:
      return 'Sentry';
    case IntegrationType.Linear:
      return 'Linear';
    case IntegrationType.Hubspot:
      return 'Hubspot';
    default:
      return 'Integration';
  }
}
