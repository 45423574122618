import cn from 'classnames';
import { groupBy, lowerCase } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { Activity as ActivityModel } from '../../../../sync/__generated/models';
import { entitiesChangedForActivitySelector } from '../../../syncEngine/selectors/activities';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function stringifyChangesByType(
  changesByType: Record<string, Array<{ title: string; number: string; type: string }>>
): React.ReactNode | null {
  if (!Object.keys(changesByType).length) {
    return null;
  }

  return Object.keys(changesByType)
    .map(type => {
      const changes = changesByType[type];
      let entityType = type === 'Issue' ? issueTerm : type;
      if (changes.length > 1) {
        entityType += 's';
      }

      return (
        <>
          {lowerCase(entityType)}{' '}
          <span className={cn(styles.activityHighlight, 'fs-exclude')}>
            {changes.map(change => `${change.number} ${change.title}`).join(', ')}
          </span>
        </>
      );
    })
    .reduce((result: React.ReactNode, element) => {
      if (!result) {
        return element;
      }
      return (
        <>
          ' '{result},{element}
        </>
      );
    }, null);
}

function EntitiesChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const { added, removed } = useRecoilValue(entitiesChangedForActivitySelector(activity.id));

  const addedByType = groupBy(added, a => a.type);
  const removedByType = groupBy(removed, r => r.type);

  const stringifiedAdded = stringifyChangesByType(addedByType);
  const stringifiedRemoved = stringifyChangesByType(removedByType);

  const and = stringifiedAdded && stringifiedRemoved ? ' and ' : '';

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} />{' '}
      {!!stringifiedAdded && (
        <>
          added {stringifiedAdded}
          {and}
        </>
      )}
      {!!stringifiedRemoved && <>removed {stringifiedRemoved}</>}.
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const EntitiesChangedActivity = React.memo(EntitiesChangedActivityComponent);
