import { uniqBy } from 'lodash';
import { selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { effortLevelsByOrganization, effortLevelsBySpace } from '../../../sync/__generated/indexes';
import { Effort } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';

export const effortSelector = selectorFamily({
  key: 'EffortLevels',
  get:
    (effortId: string | null | undefined) =>
    ({ get }) => {
      if (!effortId) {
        return null;
      }
      return notDeleted(get(syncEngineState(effortId)) as Effort | null);
    },
});

export const effortsSelector = selectorFamily({
  key: 'EffortsLevels',
  get:
    (effortIds: string[]) =>
    ({ get }) => {
      return filterNotNull(effortIds.map(effortId => get(effortSelector(effortId))));
    },
});

export const effortLevelsForSpaceSelector = selectorFamily({
  key: 'EffortLevelsForSpace',
  get:
    (spaceId: string | null | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }
      const effortIds = get(indexKeyState(indexKey(effortLevelsBySpace, spaceId)));
      return filterNotDeletedNotNull(
        effortIds.map(effortId => get(syncEngineState(effortId)) as Effort | null)
      );
    },
});

export const effortLevelsForOrganizationSelector = selectorFamily({
  key: 'EffortLevelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const effortIds = get(indexKeyState(indexKey(effortLevelsByOrganization, organizationId)));
      return filterNotDeletedNotNull(
        effortIds.map(effortId => get(syncEngineState(effortId)) as Effort | null)
      );
    },
});

export const globalEffortLevelsForOrganizationSelector = selectorFamily({
  key: 'GlobalEffortLevelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const efforts = get(effortLevelsForOrganizationSelector(organizationId));
      return efforts.filter(effort => !effort.spaceId);
    },
});

export const uniqueEffortLevelsForOrganizationSelector = selectorFamily({
  key: 'UniqueEffortLevelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const effortLevels = get(effortLevelsForOrganizationSelector(organizationId));
      return uniqBy(effortLevels, 'name');
    },
});
