import * as Sentry from '@sentry/browser';
import debugModule from 'debug';
import {
  Cancelable,
  camelCase,
  cloneDeep,
  debounce,
  isEqual,
  isUndefined,
  lowerFirst,
  merge,
  omitBy,
  partition,
  uniq,
} from 'lodash';
import * as React from 'react';
import { Descendant, Editor, Operation, Transforms, createEditor } from 'slate';
import { Client, useClient } from 'urql';
import uuid from 'uuid';
import { TransactionPayload, TransactionType } from '../../../graphql__generated__/graphql';
import { transformAll } from '../../shared/slate/operationalTransforms';
import { filterNotNull } from '../../shared/utils/convenience';
import { generateId } from '../../shared/utils/id';
import { cleanOutUndefineds } from '../../shared/utils/utils';
import { CollaborativeDoc, Entity, Space } from '../../sync/__generated/models';
import { showRollbackToast } from '../components/collaborativeEditRollbackToast';
import { appendDiagnostic } from '../diagnostics';
import useIdleUser from '../hooks/useIdleUser';
import { calculateCursor, transformCursors } from '../slate/cursors';
import { HistoryEditor } from '../slate/plugins/history';
import { withSchema } from '../slate/plugins/withSchema';
import { DocumentLike, EditorType } from '../slate/types';
import { filterOutgoingProperties, stubOutCollectionTypes } from '../syncEngine/helpers';
import { collectionMap, useStateTransaction } from '../syncEngine/state';
import {
  SyncEngineCollection,
  SyncEngineIndex,
  SyncEngineObject,
  isSyncEngineObject,
} from '../syncEngine/types';
import { safeLocalStorageGet } from '../utils/localStorage';
import {
  AppliedCollaborativeDocumentTransactionPayload,
  AppliedCollectionTransactionPayload,
  AppliedPayload,
  AppliedTransactionPayload,
  RequestCurrentUser,
  RequestDocument,
  RequestDocumentChanges,
  RequestInitiatives,
  RequestIssue,
  RequestOrganization,
  RequestRoadmaps,
  SendTransaction,
  Transaction,
  isFetchError,
  requestCurrentUser,
  requestCycles,
  requestDocument,
  requestDocumentChanges,
  requestInitiatives,
  requestIssue,
  requestIssueByNumber,
  requestIssues,
  requestOrganization,
  requestRoadmaps,
  sendTransaction,
} from './api';
import { codeFromGraphQLError, statusFromGraphQLError } from './errors';
import { clearCache, fetchCache, updateCache } from './offlineCache';
import { CurrentUserMarker, FetchedMarker, OrganizationMarker, SmartLoad } from './smartLoad';

// polyfill for Safari
import 'requestidlecallback-polyfill';

const debug = debugModule('modelManager');
const transactionQueueKey = '__transactionQueue';
const pauseTransactionQueueKey = '__pauseTransactionQueue';
const FLUSH_CACHE_TIMEOUT = 300000;
const STUCK_TRANSITION_TIMEOUT = 15000;
const STUCK_TRANISITION_RECENTLY_REFETCHED = 60000;

const sentMetadataKey = 'sent';
const notBeforeMetadataKey = 'notBefore';
function contentMetadataKey(id: string) {
  return `${id}-content`;
}
function contentVersionMetadataKey(id: string) {
  return `${id}-contentVersion`;
}

export type BoardType = 'current' | 'archive' | 'backlog';

export type IdentifiedOperation = Operation & { actorId?: string; clientId?: string };

export type SyncEngineCreate<T> = Omit<
  T,
  'id' | 'version' | 'deleted' | 'createdAt' | 'updatedAt'
> & { id?: string };
export type SyncEngineUpdate<T> = Partial<Omit<T, 'id' | 'version' | 'createdAt' | 'updatedAt'>>;
export type SyncEngineUpdateWithoutDelete<T> = Omit<SyncEngineUpdate<T>, 'deleted'>;

export interface SyncEngineTransaction {
  create<T extends SyncEngineObject>(object: SyncEngineCreate<T>): T;
  update<T extends SyncEngineObject>(id: string, update: SyncEngineUpdate<T>): void;
  addToCollection(collection: SyncEngineCollection, modelId: string, toAdd: string[]): void;
  removeFromCollection(collection: SyncEngineCollection, modelId: string, toRemove: string[]): void;
}

export interface SyncEngineGetters {
  get<T extends SyncEngineObject>(id: string): T | null;
  getIndex(index: SyncEngineIndex, id: string): string[];
}

export interface ModelManager extends SyncEngineTransaction {
  loadFromCache(organizationSlug?: string): Promise<void>;
  fetchData(options?: {
    organizationSlug?: string;
    spaceSlug?: string;
    board?: BoardType;
    workItemNumber?: string;
    fullRefetch?: boolean;
    orgScreen?: 'search' | 'my';
  }): Promise<void>;
  fetchIssue(id: string): Promise<void>;
  fetchRoadmaps(organizationId: string): Promise<void>;
  fetchInitiatives(
    organizationId: string,
    options?: { spaceIds?: string[]; initiativeSpaceIds?: string[] }
  ): Promise<void>;
  handleRemoteTransaction(payloads: AppliedPayload[]): void;
  setCollaborativeDocumentEditor(id: string, editor: Editor): void;
  unsetCollaborativeDocumentEditor(id: string): void;
  getCollaborativeDocumentEditor(id: string): Editor | null;
  applyCollaborativeDocumentOperations(
    id: string,
    operations: Operation[],
    content: Descendant[],
    modelType: string,
    version: number,
    options?: {
      forceFlush?: boolean;
    }
  ): void;
  checkForMissingChanges(): void;
  transactionQueuePaused(): boolean;
  pauseTransactionQueue(): void;
  resumeTransactionQueue(): void;
  transaction<T>(callback: (tx: SyncEngineTransaction, getters: SyncEngineGetters) => T): T;
  unload(): Promise<void>;
  flushOfflineCache(force: boolean): Promise<void>;
  flushCollaborativeDocs(): void;
  isCheckingForMissingChanges(): boolean;
}

const modelManagerContext = React.createContext<ModelManager | null>(null);
const ignoreExceptions = ['DUPLICATE_TRANSACTION'];

const DEFAULT_RETRY_TIMEOUT = 5000;
const MAX_RETRY_TIMEOUT = 60000;
const MAX_RETRIES = 10;
// NOTE: hack to make it overridable for demo purposes only
const collaborativeTransactionDelay = parseInt(
  safeLocalStorageGet('__collaborativeTransactionDelay', '1000'),
  10
);

export interface QueuedTransaction {
  transaction: Transaction;
  metadata: Record<string, unknown>;
}

export interface TransactionQueueStorage {
  loadTransactionQueue(): QueuedTransaction[];
  persistTransactionQueue(transactions: QueuedTransaction[]): void;
}

type StateTransaction = ReturnType<typeof useStateTransaction>;

export class ModelManagerValue implements ModelManager {
  private clientId: string;
  private client: Client;
  private stateTransaction: StateTransaction;
  private requestCurrentUser: RequestCurrentUser;
  private requestOrganization: RequestOrganization;
  private requestIssue: RequestIssue;
  private requestRoadmaps: RequestRoadmaps;
  private requestInitiatives: RequestInitiatives;
  private requestDocument: RequestDocument;
  private requestDocumentChanges: RequestDocumentChanges;
  private sendTransaction: SendTransaction;

  private transactionQueue: QueuedTransaction[];
  private pendingPayloads: AppliedPayload[] = [];
  private pendingCollaborativePayloads: AppliedCollaborativeDocumentTransactionPayload[] = [];
  private transactionRetryLoop = -1;
  private fetchRetryLoop = -1;
  private flushCacheTimeout = -1;
  private stuckTransactionTimeout = -1;
  private lastFetched: number | null = null;
  private lastFetchStarted: number | null = null;
  private lastOrganizationSlug: string | null = null;

  private smartLoad: SmartLoad;

  private collaborativeEditors: { [index: string]: Editor } = {};

  private userIdleRef: React.MutableRefObject<boolean> | null;
  private paused: boolean;
  private sending = false;
  private checkingForMissingChanges = false;
  private resultPayloadsToTransactions: Map<AppliedPayload, Transaction> = new Map();
  private persistTransactionQueue: (() => void) & Cancelable;

  private updateDocumentInCache = debounce(
    (id: string, content: Descendant[], version?: number) => {
      this.stateTransaction(({ get, set }) => {
        const document = get<CollaborativeDoc>(id);
        if (!document) {
          return;
        }
        debug('%s - updating docuemnt in cache for %s %j', this.clientId, id, content);

        const updated = {
          ...document,
          content: content,
        } as CollaborativeDoc;

        if (!isUndefined(version)) {
          updated.version = version;
        }

        // we neet to set the associated entity if there is one as well to trigger search reindexing
        const results: SyncEngineObject[] = [updated];
        const entity = get<Entity>(document.entityId);
        if (entity) {
          results.push(entity);
        }
        set(results);
      });
    },
    1000
  );

  constructor(options: {
    clientId: string;
    client: Client;
    stateTransaction: StateTransaction;
    storage: TransactionQueueStorage;
    smartLoad: SmartLoad;
    requestCurrentUser: RequestCurrentUser;
    requestOrganization: RequestOrganization;
    requestIssue: RequestIssue;
    requestRoadmaps: RequestRoadmaps;
    requestInitiatives: RequestInitiatives;
    requestDocument: RequestDocument;
    requestDocumentChanges: RequestDocumentChanges;
    sendTransaction: SendTransaction;
    userIdleRef?: React.MutableRefObject<boolean>;
    offlineCache?: boolean;
    cursors?: boolean;
  }) {
    this.clientId = options.clientId;
    this.client = options.client;
    this.stateTransaction = options.stateTransaction;
    this.persistTransactionQueue = debounce(
      () => options.storage.persistTransactionQueue(this.transactionQueue),
      1000
    );
    this.requestCurrentUser = options.requestCurrentUser;
    this.requestOrganization = options.requestOrganization;
    this.requestIssue = options.requestIssue;
    this.requestRoadmaps = options.requestRoadmaps;
    this.requestInitiatives = options.requestInitiatives;
    this.requestDocument = options.requestDocument;
    this.requestDocumentChanges = options.requestDocumentChanges;
    this.sendTransaction = options.sendTransaction;
    this.userIdleRef = options.userIdleRef ?? null;

    this.transactionQueue = options.storage.loadTransactionQueue();
    this.smartLoad = options.smartLoad;
    this.paused = !!localStorage.getItem(pauseTransactionQueueKey);
  }

  enqueueTransaction(
    transaction: Transaction,
    overridingMetadata: Record<string, unknown> = {},
    nonOverridingMetadata = {}
  ): boolean {
    debug(
      '%s - enqueueing transaction (transaction = %j, overriding metadata = %j, non-overriding metadata = %j)',
      this.clientId,
      transaction,
      overridingMetadata,
      nonOverridingMetadata
    );

    // get all the transactions that we haven't attempted to send yet, and attack them
    // back to front to ensure ordering
    const usableTransactions = this.transactionQueue
      .slice(
        this.transactionQueue.length && this.transactionQueue[0].metadata[sentMetadataKey] ? 1 : 0
      )
      .reverse();

    const uncompressedPayloads: TransactionPayload[] = [];

    for (const payload of transaction.payload) {
      let compressed = false;
      // no way to compress down CREATE transactions
      if (payload.type === TransactionType.CREATE) {
        uncompressedPayloads.push(payload);
        continue;
      }

      // let's see if we can find a payload we can append this thing to
      for (const pendingTransaction of usableTransactions) {
        if (compressed) {
          break;
        }

        for (let i = 0; i < pendingTransaction.transaction.payload.length; i++) {
          const pendingPayload = pendingTransaction.transaction.payload[i];
          if (
            pendingPayload.type !== payload.type ||
            pendingPayload.modelId !== payload.modelId ||
            pendingPayload.modelType !== payload.modelType ||
            pendingPayload.collection?.collection !== payload.collection?.collection
          ) {
            continue;
          }

          switch (payload.type) {
            case TransactionType.UPDATE:
              pendingTransaction.transaction.payload[i] = merge(cloneDeep(pendingPayload), payload);
              break;
            case TransactionType.ADD:
              pendingPayload.collection!.modelIds = uniq([
                ...pendingPayload.collection!.modelIds,
                ...payload.collection!.modelIds,
              ]);
              break;
            case TransactionType.REMOVE:
              pendingPayload.collection!.modelIds = uniq([
                ...pendingPayload.collection!.modelIds,
                ...payload.collection!.modelIds,
              ]);
              break;
            case TransactionType.COLLABORATIVE_DOCUMENT:
              pendingPayload.collaborativeDocument!.operations = [
                ...pendingPayload.collaborativeDocument!.operations,
                ...payload.collaborativeDocument!.operations,
              ];
              break;
          }

          compressed = true;
          pendingTransaction.metadata = {
            ...nonOverridingMetadata,
            ...pendingTransaction.metadata,
            ...overridingMetadata,
          };

          debug('%s - payload compressed %j', this.clientId, pendingTransaction);

          break;
        }
      }

      if (!compressed) {
        uncompressedPayloads.push(payload);
      }
    }

    if (uncompressedPayloads.length) {
      transaction.payload = uncompressedPayloads;
      this.transactionQueue.push({
        transaction,
        metadata: { ...nonOverridingMetadata, ...overridingMetadata },
      });
    } else {
      debug('%s - fully compressed transaction', this.clientId);
    }

    const queueLength = this.transactionQueue.length;
    debug(
      '%s - enqueued transaction (transaction queue length = %s, retry loop active = %s)',
      this.clientId,
      queueLength,
      this.transactionRetryLoop !== -1
    );

    setTimeout(async () => {
      this.persistTransactionQueue();
      if (queueLength === 1 && this.transactionRetryLoop === -1) {
        this.tryToSendTransaction();
      }
    });

    return uncompressedPayloads.length === 0;
  }

  sendQueuedTransactionIfNeeded() {
    if (this.transactionQueue.length) {
      this.tryToSendTransaction();
    }
  }

  setCollaborativeDocumentEditor(id: string, editor: Editor) {
    debug('%s - setting collaborative editor for %s', this.clientId, id);
    this.collaborativeEditors[id] = editor;
  }

  unsetCollaborativeDocumentEditor(id: string, noOfflineCacheFlush = false) {
    debug('%s - unsetting collaborative editor for %s', this.clientId, id);
    const editor = this.collaborativeEditors[id];

    if (!editor) {
      debug(
        '%s - unsetCollaborativeDocumentEditor for %s when there is no editor',
        this.clientId,
        id
      );
      return;
    }

    if (editor.operations.length) {
      debug(
        '%s - collaborative editor still has unflushed operations %j',
        this.clientId,
        editor.operations
      );
    }

    editor.shutdown?.();
    delete this.collaborativeEditors[id];
    this.updateDocumentInCache(id, editor.children, editor.version?.());
    this.updateDocumentInCache.flush();
    if (!noOfflineCacheFlush) {
      this.flushOfflineCache();
    }
  }

  getCollaborativeDocumentEditor(id: string): Editor | null {
    return this.collaborativeEditors[id] ?? null;
  }

  isCheckingForMissingChanges(): boolean {
    return this.checkingForMissingChanges;
  }

  applyCollaborativeDocumentOperations(
    id: string,
    operations: Operation[],
    content: Descendant[],
    modelType: string,
    version: number,
    options?: {
      forceFlush?: boolean;
    }
  ) {
    debug(
      '%s - applying collaborative document operations for %s %j %j',
      this.clientId,
      id,
      operations,
      options
    );

    const updatedVersion = version + 1;
    const transaction: Transaction = {
      id: uuid.v4(),
      clientId: this.clientId,
      payload: [
        {
          type: TransactionType.COLLABORATIVE_DOCUMENT,
          modelId: id,
          modelType,
          collaborativeDocument: {
            version: updatedVersion,
            operations: operations,
          },
        },
      ],
    };

    const compressed = this.enqueueTransaction(
      transaction,
      {
        [contentMetadataKey(id)]: content,
        [contentVersionMetadataKey(id)]: updatedVersion,
      },
      {
        [notBeforeMetadataKey]: Date.now() + collaborativeTransactionDelay,
      }
    );

    // check if we got compressed into the previous version or if we got a new one
    if (!compressed) {
      this.collaborativeEditors[id]?.updateVersion?.(updatedVersion);
    }

    if (options?.forceFlush) {
      this.persistTransactionQueue.flush();
    }

    // if there are out of order ops waiting to apply on this doc, we should drop them since we'll get them back as missed
    // changes anyway
    this.pendingCollaborativePayloads = this.pendingCollaborativePayloads.filter(
      p => p.modelId !== id
    );
  }

  handleRemoteTransaction(payloads: AppliedPayload[]): void {
    const collaborativePayloads = payloads.filter(
      p => p.type === TransactionType.COLLABORATIVE_DOCUMENT
    );
    const otherPayloads = payloads.filter(p => p.type !== TransactionType.COLLABORATIVE_DOCUMENT);

    const pending = [...this.pendingCollaborativePayloads];
    this.pendingCollaborativePayloads = [];

    for (const collaborativePayload of [...collaborativePayloads, ...pending]) {
      const editor = this.collaborativeEditors[collaborativePayload.modelId];
      if (editor) {
        this.handleRemoteCollaborativeEdit(
          collaborativePayload as AppliedCollaborativeDocumentTransactionPayload,
          editor
        );
      } else {
        debug(
          '%s no active editor for %s, discarding collaborative edit',
          this.clientId,
          collaborativePayload.modelId
        );
      }
    }

    if (!otherPayloads.length) {
      return;
    }

    for (const payload of otherPayloads) {
      if (payload.type === TransactionType.CREATE && payload.modelType === 'Issue') {
        const createPayload = payload as AppliedTransactionPayload;
        if (createPayload.properties) {
          appendDiagnostic({
            type: 'entityCreated',
            entityType: payload.modelType,
            id: createPayload.properties.id,
            number: (createPayload.properties as any).number,
            spaceId: (createPayload.properties as any).spaceId,
          });
        }
      }
    }
    this.applyRemoteTransaction(otherPayloads);
  }

  private applyRemoteTransaction(payloads: AppliedPayload[], forceOwnTransaction?: boolean) {
    debug('%s applying remote transaction %j', this.clientId, payloads);

    this.stateTransaction(({ get, set }) => {
      const toLoad: { [index: string]: SyncEngineObject } = {};

      function existingObject(id: string): SyncEngineObject | null {
        const toLoadObject = toLoad[id];
        if (toLoadObject) {
          return toLoadObject;
        }

        const fromStore = get<any>(id);
        if (fromStore) {
          return fromStore;
        }

        return null;
      }

      const hadPendingPayloads = !!this.pendingPayloads.length;
      let payloadsToApply = [...payloads, ...this.pendingPayloads];
      this.pendingPayloads = [];

      let applied = true;

      while (applied && payloadsToApply.length) {
        applied = false;
        const notApplied: AppliedPayload[] = [];
        for (const payload of payloadsToApply) {
          const originalTransaction = this.resultPayloadsToTransactions.get(payload) ?? null;
          const ownTransaction = !!originalTransaction || !!forceOwnTransaction;

          switch (payload.type) {
            case TransactionType.CREATE:
              {
                const createPayload = payload as AppliedTransactionPayload;
                if (!createPayload.properties) {
                  applied = true;
                  continue;
                }

                debug('%s - applying "create" transaction %j', this.clientId, payload);
                toLoad[payload.modelId] = {
                  ...existingObject(payload.modelId),
                  ...this.mapIncomingPropertiesAndNullableValues(
                    payload.modelId,
                    stubOutCollectionTypes<any>({
                      ...createPayload.properties,
                      __typename: payload.modelType,
                    }),
                    createPayload.nullPropertiesToApply,
                    ownTransaction,
                    false
                  ),
                  version: createPayload.modelVersion,
                  updatedAt: createPayload.modelUpdatedAt,
                  id: payload.modelId,
                } as SyncEngineObject;
              }
              break;
            case TransactionType.UPDATE:
              {
                const updatePayload = payload as AppliedTransactionPayload;
                if (!updatePayload.properties) {
                  applied = true;
                  continue;
                }
                const existing: any = existingObject(payload.modelId);
                if (!existing) {
                  debug(
                    '%s - received update for non existant object (model type = %s, model ID = %s)',
                    this.clientId,
                    payload.modelType,
                    payload.modelId
                  );
                  appendDiagnostic({
                    type: 'pendingPayload',
                    reason: 'Update model that does not exist',
                    modelId: payload.modelId,
                    modelType: payload.modelType,
                  });
                  notApplied.push(payload);
                  continue;
                }

                if (
                  existing.version >= updatePayload.modelVersion &&
                  updatePayload.modelVersion !== -1
                ) {
                  debug(
                    '%s - old object has higher version than new object in update. Skipping (new = %j, old = %j)',
                    this.clientId,
                    payload,
                    existing
                  );
                  applied = true;
                  continue;
                }

                const isCorrectVersion =
                  updatePayload.modelVersion === -1 ||
                  existing.version + 1 === updatePayload.modelVersion ||
                  (existing.version < updatePayload.modelVersion &&
                    updatePayload.modelType === 'CollaborativeDoc');

                if (!isCorrectVersion) {
                  debug(
                    '%s - new object has wrong version in update. Queueing (new = %j, old = %j)',
                    this.clientId,
                    payload,
                    existing
                  );
                  appendDiagnostic({
                    type: 'pendingPayload',
                    reason: 'Update model that has wrong version',
                    modelId: payload.modelId,
                    modelType: payload.modelType,
                    oldVersion: existing.version,
                    newVersion: updatePayload.modelVersion,
                  });
                  notApplied.push(payload);
                  continue;
                }

                debug('%s - applying update %j', this.clientId, payload);
                toLoad[payload.modelId] = {
                  ...existing,
                  ...this.mapIncomingPropertiesAndNullableValues(
                    updatePayload.modelId,
                    updatePayload.properties as unknown as Record<string, unknown>,
                    updatePayload.nullPropertiesToApply,
                    ownTransaction,
                    true
                  ),
                  version:
                    updatePayload.modelVersion !== -1 ? updatePayload.modelVersion : undefined,
                  updatedAt: updatePayload.modelUpdatedAt,
                  id: payload.modelId,
                };
              }
              break;
            case TransactionType.ADD:
              {
                const collectionPayload = payload as AppliedCollectionTransactionPayload;
                const model: any = { ...existingObject(payload.modelId) } as any;
                if (!model) {
                  debug(
                    '%s - received collection add for non existant object (model type = %s, model ID = %s)',
                    this.clientId,
                    payload.modelType,
                    payload.modelId
                  );
                  appendDiagnostic({
                    type: 'pendingPayload',
                    reason: 'Add to collection for model that does not exist',
                    modelId: payload.modelId,
                    modelType: payload.modelType,
                    collection: collectionPayload.collection.collection,
                    oldVersion: null,
                    newVersion: collectionPayload.modelVersion,
                  });
                  notApplied.push(payload);
                  continue;
                }

                if (model.version >= collectionPayload.modelVersion) {
                  debug(
                    '%s - old object has higher version than new object in add. Skipping (new = %j, old = %j)',
                    this.client,
                    payload,
                    model
                  );
                  applied = true;
                  continue;
                }

                if (
                  model.version != collectionPayload.modelVersion - 1 &&
                  collectionPayload.modelVersion !== -1
                ) {
                  debug(
                    '%s - new object has wrong version in add. Queueing (new = %j, old = %j)',
                    this.clientId,
                    payload,
                    model
                  );
                  appendDiagnostic({
                    type: 'pendingPayload',
                    reason: 'Add to collection for model that has wrong version',
                    modelId: payload.modelId,
                    modelType: payload.modelType,
                    collection: collectionPayload.collection.collection,
                    oldVersion: model.version,
                    newVersion: collectionPayload.modelVersion,
                  });
                  notApplied.push(payload);
                  continue;
                }

                debug('%s - applying add %j', this.clientId, payload);
                const collection =
                  collectionMap[collectionPayload.modelType]?.[
                    collectionPayload.collection.collection
                  ];

                if (collection) {
                  for (const toAdd of collectionPayload.collection.modelIds) {
                    if (!model[collection.property]) {
                      continue;
                    }
                    if (!model[collection.property].includes(toAdd)) {
                      model[collection.property] = [...model[collection.property], toAdd];
                    }
                  }
                }

                model.version = collectionPayload.modelVersion;
                model.updatedAt = collectionPayload.modelUpdatedAt;
                toLoad[payload.modelId] = { ...model };
              }
              break;
            case TransactionType.REMOVE:
              {
                const collectionPayload = payload as AppliedCollectionTransactionPayload;
                const model: any = { ...existingObject(payload.modelId) } as any;
                if (!model) {
                  debug(
                    '%s - received collection remove for non existant object (model type = %s, model ID = %s)',
                    this.clientId,
                    payload.modelType,
                    payload.modelId
                  );
                  appendDiagnostic({
                    type: 'pendingPayload',
                    reason: 'Remove from collection for model that does not exist',
                    modelId: payload.modelId,
                    modelType: payload.modelType,
                    collection: collectionPayload.collection.collection,
                    oldVersion: null,
                    newVersion: collectionPayload.modelVersion,
                  });
                  notApplied.push(payload);
                  continue;
                }

                if (model.version >= collectionPayload.modelVersion) {
                  debug(
                    '%s - old object has higher version than new object in remove. Skipping (new = %j, old = %j)',
                    this.clientId,
                    payload,
                    model
                  );
                  applied = true;
                  continue;
                }

                if (
                  model.version != collectionPayload.modelVersion - 1 &&
                  collectionPayload.modelVersion !== -1
                ) {
                  debug(
                    '%s - new object has wrong version in remove. Queueing (new = %j, old = %j)',
                    this.clientId,
                    payload,
                    model
                  );
                  appendDiagnostic({
                    type: 'pendingPayload',
                    reason: 'Remove from collection for model that has wrong version',
                    modelId: payload.modelId,
                    modelType: payload.modelType,
                    collection: collectionPayload.collection.collection,
                    oldVersion: model.version,
                    newVersion: collectionPayload.modelVersion,
                  });
                  notApplied.push(payload);
                  continue;
                }

                debug('%s - applying remove transaction %j', this.clientId, payload);
                const collection =
                  collectionMap[collectionPayload.modelType]?.[
                    collectionPayload.collection.collection
                  ];

                if (collection) {
                  for (const toRemove of collectionPayload.collection.modelIds) {
                    if (!model[collection.property]) {
                      continue;
                    }
                    model[collection.property] = model[collection.property].filter(
                      (c: string) => c !== toRemove
                    );
                  }
                }

                model.version = collectionPayload.modelVersion;
                model.updatedAt = collectionPayload.modelUpdatedAt;
                toLoad[payload.modelId] = { ...model };
              }
              break;
          }
        }

        payloadsToApply = notApplied;
      }

      this.pendingPayloads = payloadsToApply;

      if (hadPendingPayloads && !this.pendingPayloads.length) {
        appendDiagnostic({
          type: 'pendingPayloadsCleared',
        });
      }

      if (this.pendingPayloads.length) {
        appendDiagnostic({
          type: 'pendingPayloads',
          count: this.pendingPayloads.length,
        });

        const recentlyStartedFetching =
          this.lastFetchStarted &&
          Date.now() - this.lastFetchStarted < STUCK_TRANISITION_RECENTLY_REFETCHED;

        if (this.stuckTransactionTimeout === -1 && !recentlyStartedFetching) {
          this.stuckTransactionTimeout = window.setTimeout(() => {
            this.stuckTransactionTimeout = -1;
            if (!this.pendingPayloads.length) {
              return;
            }
            try {
              const payloadInfo = this.pendingPayloads.map(p => {
                const result: Record<string, unknown> = {
                  modelId: p.modelId,
                  modelType: p.modelType,
                  type: p.type,
                };

                if (p.type === TransactionType.ADD || p.type === TransactionType.REMOVE) {
                  result.collection = (p as AppliedCollectionTransactionPayload).collection;
                }
                if (p.type === TransactionType.UPDATE) {
                  const updatePayload = p as AppliedTransactionPayload;
                  const properties = omitBy(
                    updatePayload.properties ?? {},
                    (value, key) =>
                      value !== null || updatePayload.nullPropertiesToApply.includes(key)
                  );
                  result.properties = JSON.stringify(Object.keys(properties));
                }

                return result;
              });
              Sentry.captureMessage(`Transactions stalled`, {
                extra: {
                  clientId: this.clientId,
                  payloads: payloadInfo,
                },
              });
            } catch (e) {
              //  just don't crash trying to gather sentry data
            }
            debug('%s - Stalled transactions (%j)', this.clientId, this.pendingPayloads);
            this.fetchData();
          }, STUCK_TRANSITION_TIMEOUT);
        }
      }

      for (const payload of this.resultPayloadsToTransactions.keys()) {
        if (!this.pendingPayloads.includes(payload)) {
          this.resultPayloadsToTransactions.delete(payload);
        }
      }

      if (!Object.keys(toLoad).length) {
        return;
      }

      const toLoadWithLocalChanges = Object.fromEntries(
        Object.entries(toLoad).map(([key, value]) => [key, this.applyLocalChanges(value, true)])
      );

      set(Object.values(toLoadWithLocalChanges));
      this.flushOfflineCache();
    });
  }

  pendingTransactions(): QueuedTransaction[] {
    return this.transactionQueue;
  }

  pendingRemotePayloads(): AppliedPayload[] {
    return this.pendingPayloads;
  }

  async loadFromCache(organizationSlug?: string) {
    if (this.lastFetched) {
      return;
    }

    try {
      const { data, slug } = await fetchCache(organizationSlug);

      // apply local edits to cached data
      const pendingObjectIds = uniq(
        this.transactionQueue.flatMap(t => t.transaction.payload.map(p => p.modelId))
      );
      for (const id of pendingObjectIds) {
        const value = data[id];
        if (value && isSyncEngineObject(value)) {
          data[id] = this.applyLocalChanges(value);
        }
      }
      if (slug) {
        this.stateTransaction(({ load, set }) => {
          // Handle local inserts. Need to set() them, not load() them so they get indexed
          const inserts = this.localInserts();
          if (inserts.length) {
            set(inserts.map(insert => this.applyLocalChanges(insert)));
          }

          if (Object.keys(data).length) {
            load(data);
          }
        });
        this.lastOrganizationSlug = slug;
      }
    } catch (e) {
      debug('%s - error loading from cache %j', e);
    }
  }

  async fetchData(options?: {
    organizationSlug?: string;
    spaceSlug?: string;
    board?: BoardType;
    workItemNumber?: string;
    fullRefetch?: boolean;
    orgScreen?: 'search' | 'my';
  }) {
    const {
      organizationSlug,
      spaceSlug,
      board: specifiedBoard,
      workItemNumber,
      fullRefetch,
      orgScreen,
    } = options ?? {};

    debug('%s - fetchData %j', this.clientId, options);
    const now = Date.now();
    this.lastFetchStarted = now;

    appendDiagnostic({
      type: 'dataFetchStart',
    });
    let retryTimeout = DEFAULT_RETRY_TIMEOUT;
    let retryCount = MAX_RETRIES;

    const fetch = async () => {
      try {
        const { data: userData, user } = await this.requestCurrentUser(this.client);
        if (!user) {
          this.clearTransactionQueue();
          this.loadData([CurrentUserMarker.create()]);
          await clearCache();
          return;
        }

        const specifiedOrg = userData.find(
          o =>
            o.__typename === 'Organization' &&
            (o as any).slug.split('-')[0] === (organizationSlug ?? '').split('-')[0]
        );
        const specifiedOrgOrLastForUser = specifiedOrg
          ? specifiedOrg
          : userData.find(o => o.id === user.lastOrganizationId);
        const organization =
          specifiedOrgOrLastForUser ?? userData.find(o => o.__typename === 'Organization');

        if (!organization) {
          this.loadData([...userData, CurrentUserMarker.create(user?.id)]);
          return;
        }

        const orgSlug = (organization as any).slug;

        // if we just loaded from the cache, but we loaded the wrong thing, forget the stuff we loaded
        if (
          !this.lastFetched &&
          this.lastOrganizationSlug?.split('-')[0] !== orgSlug.split('-')[0]
        ) {
          this.stateTransaction(({ clear }) => {
            clear();
          });
        }

        const lastFetchTime = fullRefetch ? null : this.lastFetched;
        this.lastOrganizationSlug = orgSlug;

        this.loadData([...userData, CurrentUserMarker.create(user?.id)]);

        const { data: orgData } = await this.requestOrganization(this.client, organization.id);
        if (!orgData.length) {
          return;
        }

        this.loadData([...orgData, OrganizationMarker.create(organization.id, true)]);

        const spaces = orgData.filter(d => d.__typename === 'Space') as Space[];

        const organizationMembership: any = orgData.find(
          (membership: any) =>
            membership.__typename === 'OrganizationMember' &&
            membership.organizationId === organization.id &&
            membership.userId === user.id
        );

        const [[specifiedSpace], remainingSpaces] = partition(spaces, s => s.slug === spaceSlug);
        let currentSpace = specifiedSpace;

        if (!currentSpace) {
          currentSpace = (spaces.find(s => s.id === organizationMembership?.lastSpaceId) ??
            spaces[0]) as Space;
        }

        if (currentSpace && workItemNumber) {
          await this.smartLoad.issueFirst(
            parseInt(workItemNumber),
            currentSpace,
            remainingSpaces,
            lastFetchTime,
            o => this.loadData(o)
          );
        } else if (orgScreen) {
          await this.smartLoad.nonArchivedFirst(spaces, lastFetchTime, o => this.loadData(o));
        } else if (currentSpace) {
          await this.smartLoad.spaceFirst(
            specifiedBoard ?? 'current', // We default to current
            currentSpace,
            remainingSpaces,
            lastFetchTime,
            o => this.loadData(o)
          );
        } else {
          await this.smartLoad.loadEverything(spaces, lastFetchTime, o => this.loadData(o));
        }

        this.loadData([OrganizationMarker.create(organization.id)]);
        const firstFetch = !this.lastFetched;
        this.lastFetched = now;
        appendDiagnostic({
          type: 'dataFetchComplete',
        });
        this.fetchRetryLoop = -1;

        this.flushOfflineCache();

        this.stateTransaction(async ({ set }) => {
          set([FetchedMarker.create()]);
        });

        if (firstFetch) {
          // we can start sending any queue transactions if there are any and we just loaded
          this.sendQueuedTransactionIfNeeded();
        }

        // attempt to apply any pending transactions
        this.applyRemoteTransaction([]);
      } catch (e) {
        appendDiagnostic({
          type: 'dataFetchError',
          error: JSON.stringify(e),
          retryCount,
        });

        if (isFetchError(e)) {
          if (retryCount > 0) {
            retryTimeout = Math.min(retryTimeout * 2, MAX_RETRY_TIMEOUT);
            retryCount--;
            this.fetchRetryLoop = window.setTimeout(fetch, retryTimeout);
          }
          return;
        }
        throw e;
      }
    };

    if (this.fetchRetryLoop !== -1) {
      window.clearTimeout(this.fetchRetryLoop);
      this.fetchRetryLoop = -1;
    }

    await fetch();
  }

  transactionQueuePaused() {
    return this.paused;
  }

  pauseTransactionQueue() {
    this.paused = true;
    localStorage.setItem(pauseTransactionQueueKey, 'true');
  }

  resumeTransactionQueue() {
    this.paused = false;
    localStorage.removeItem(pauseTransactionQueueKey);
    this.sendQueuedTransactionIfNeeded();
  }

  async unload(): Promise<void> {
    for (const id of Object.keys(this.collaborativeEditors)) {
      this.unsetCollaborativeDocumentEditor(id, true);
    }
    this.persistTransactionQueue();
    this.persistTransactionQueue.flush();
    await this.flushOfflineCache(true);
  }

  async fetchIssue(issueId: string) {
    const { data } = await this.requestIssue(this.client, issueId);
    this.loadData(data);
  }

  async fetchRoadmaps(organizationId: string): Promise<void> {
    const { data } = await this.requestRoadmaps(this.client, organizationId);
    this.loadData(data);
  }

  async fetchInitiatives(
    organizationId: string,
    options?: { spaceIds?: string[]; initiativeSpaceIds?: string[] }
  ): Promise<void> {
    const { data } = await this.requestInitiatives(this.client, organizationId, options);
    this.loadData(data);
  }

  private loadData(data: SyncEngineObject[]) {
    this.stateTransaction(({ get, set }) => {
      const result = data.map(object => {
        const existingObject = get<any>(object.id);
        const hasLocalChanges = !!this.pendingPayloadsByType(object.id).length;

        // don't mess with collaborative docs if we have local edits or an open editor
        if (
          object.__typename === 'CollaborativeDoc' &&
          ((hasLocalChanges && !!existingObject) || this.collaborativeEditors[object.id])
        ) {
          return null;
        }

        const newVersion = (object as any)?.version;
        const oldVersion = existingObject?.version;
        const newPartial = (object as any)?.partial;
        const oldPartial = existingObject?.partial;

        // if we've got the exact same object locally, don't bother writing it
        if (
          object.__typename !== 'Marker' &&
          newVersion !== null &&
          oldVersion !== null &&
          oldVersion === newVersion &&
          newPartial === oldPartial &&
          object?.deleted === existingObject?.deleted &&
          !hasLocalChanges
        ) {
          return null;
        }

        if (newVersion && oldVersion && oldVersion > newVersion) {
          debug(
            '%s - fetched version is older than local object when loading data. Ignoring (old = %j, new = %j)',
            this.clientId,
            existingObject,
            object
          );
          return this.applyLocalChanges({ ...existingObject });
        }

        return this.applyLocalChanges({ ...existingObject, ...object });
      });

      set(filterNotNull(result));
    });
  }

  private clearTransactionQueue() {
    this.transactionQueue = [];
    this.persistTransactionQueue();
  }

  private async tryToSendTransaction() {
    debug('%s - trying to send transactions', this.clientId);

    if (this.sending || !this.transactionQueue.length || this.paused) {
      debug(
        '%s - bailing out of sending transaction (sending = %s, queue length = %s, paused = %s)',
        this.clientId,
        this.sending,
        this.transactionQueue.length,
        this.paused
      );
      return;
    }

    const now = Date.now();
    if (
      this.transactionQueue[0].metadata[notBeforeMetadataKey] &&
      now < (this.transactionQueue[0].metadata[notBeforeMetadataKey] as number)
    ) {
      debug(
        '%s - transaction is too new. Need to wait %s',
        this.clientId,
        this.transactionQueue[0].metadata[notBeforeMetadataKey]
      );
      setTimeout(() => {
        this.tryToSendTransaction();
      }, (this.transactionQueue[0].metadata[notBeforeMetadataKey] as number) - now + 50);
      return;
    }

    try {
      this.sending = true;
      this.transactionQueue[0].metadata[sentMetadataKey] = true;
      this.persistTransactionQueue();

      debug('%s - sending transaction %j', this.clientId, this.transactionQueue[0].transaction);
      const result = await this.sendTransaction(
        this.client,
        this.clientId,
        this.transactionQueue[0].transaction
      );
      const sent = this.transactionQueue.shift()!;
      this.persistTransactionQueue();
      if (!sent) {
        return;
      }

      debug(
        '%s - transaction %s successfully sent, processing response %j',
        this.clientId,
        sent.transaction.id,
        result
      );

      const collaborativePayloads = result.filter(
        p => p.type === TransactionType.COLLABORATIVE_DOCUMENT
      );
      for (const collaborativePayload of collaborativePayloads) {
        const editor: EditorType | undefined =
          this.collaborativeEditors[collaborativePayload.modelId];
        const sentPayloads = sent.transaction.payload.filter(
          payload =>
            payload.modelId === collaborativePayload.modelId &&
            payload.type === TransactionType.COLLABORATIVE_DOCUMENT
        );
        this.handleCollaborativeEditResponse(
          collaborativePayload as AppliedCollaborativeDocumentTransactionPayload,
          sentPayloads,
          editor
        );
      }

      const otherPayloads = result.filter(p => p.type !== TransactionType.COLLABORATIVE_DOCUMENT);
      if (otherPayloads.length) {
        this.applyRemoteTransaction(otherPayloads, true);
      }

      // stop the retry loop if we've successfully delivered one
      if (this.transactionRetryLoop !== -1) {
        clearInterval(this.transactionRetryLoop);
        this.transactionRetryLoop = -1;
      }

      if (this.transactionQueue.length) {
        setTimeout(() => this.tryToSendTransaction());
      }
    } catch (e) {
      // Ignorable error. Pop the transaction and move on
      if (
        e.graphQLErrors?.length &&
        ignoreExceptions.includes(e.graphQLErrors[0].extensions?.code)
      ) {
        debug('%s - received ignorable error', this.clientId, e);
        this.transactionQueue.shift();
        this.persistTransactionQueue();

        if (this.transactionQueue.length) {
          setTimeout(() => this.tryToSendTransaction());
        }
        return;
      }

      const graphqlStatus = statusFromGraphQLError(e.graphQLErrors ?? []);
      const graphqlCode = codeFromGraphQLError(e.graphQLErrors ?? []);
      const hasFatalGraphQLStatus = graphqlStatus && graphqlStatus >= 400 && graphqlStatus < 500;
      const hasFatalNetworkStatus =
        !!e.networkError?.statusCode && e.networkError.statusCode === 400;

      // Fatal error, roll back this transaction
      if (hasFatalGraphQLStatus || hasFatalNetworkStatus) {
        debug('%s - received fatal error', this.clientId, e);

        const toRollback = this.transactionQueue.shift();
        if (!toRollback) {
          Sentry.captureMessage('Unable to find transaction to rollback', {
            extra: {
              graphqlStatus,
              networkError: JSON.stringify(e.networkError ?? null),
              graphqlErrors: JSON.stringify(e.graphQLErrors ?? null),
              clientId: this.clientId,
            },
          });
          return;
        }

        if (graphqlCode !== 'AUTHENTICATION_REQUIRED') {
          Sentry.captureMessage('Rolling back transaction', {
            extra: {
              graphqlStatus,
              networkError: JSON.stringify(e.networkError ?? null),
              graphqlErrors: JSON.stringify(e.graphQLErrors ?? null),
              clientId: this.clientId,
              transactionPayload: JSON.stringify(toRollback),
            },
          });
        }

        this.persistTransactionQueue();
        this.rollbackTransaction(toRollback.transaction, toRollback.metadata);

        if (this.transactionQueue.length) {
          setTimeout(() => this.tryToSendTransaction());
        }
        return;
      }

      debug('%s - received non-fatal error', this.clientId, e);

      // Not fatal error, start the retry loop if it's not already started
      if (this.transactionRetryLoop === -1) {
        this.transactionRetryLoop = window.setInterval(
          () => this.tryToSendTransaction(),
          DEFAULT_RETRY_TIMEOUT
        );
      }
    } finally {
      this.sending = false;
    }
  }

  private rollbackTransaction(transaction: Transaction, _metadata: Record<string, unknown>) {
    debug('%s - rolling back transaction', this.clientId, transaction);

    this.stateTransaction(({ get, set, del }) => {
      const collaborativeEntitiesToRollback = new Set<string>();

      for (const payload of transaction.payload.reverse()) {
        const object = get<any>(payload.modelId);
        if (!object) {
          continue;
        }
        const rawObject = object;
        switch (payload.type) {
          case TransactionType.CREATE:
            del(payload.modelId);
            break;
          case TransactionType.UPDATE:
            {
              const rollbackValue = transaction.rollBackData?.find(
                o => o.__typename === payload.modelType && o.id === payload.modelId
              );
              if (rollbackValue) {
                set([this.applyLocalChanges({ ...rawObject, ...rollbackValue })]);
              }
            }
            break;
          case TransactionType.ADD:
            {
              const collectionPayload = payload.collection!;

              const collection = collectionMap[payload.modelType][collectionPayload.collection];
              const existingModelIds: string[] = (rawObject as any)[collection.property];
              const updatedModelIds = existingModelIds.filter(
                modelId => !collectionPayload.modelIds.includes(modelId)
              );

              if (isEqual(existingModelIds, updatedModelIds)) {
                continue;
              }

              set([
                this.applyLocalChanges({
                  ...rawObject,
                  [collection.property]: updatedModelIds,
                }),
              ]);
            }
            break;
          case TransactionType.REMOVE:
            {
              const collectionPayload = payload.collection!;
              const collection = collectionMap[payload.modelType][collectionPayload.collection];
              const existingModelIds: string[] = [...(rawObject as any)[collection.property]];

              let addedBack = false;
              for (const modelId of collectionPayload.modelIds) {
                if (!existingModelIds.includes(modelId)) {
                  addedBack = true;
                  existingModelIds.push(modelId);
                }
              }
              if (!addedBack) {
                continue;
              }
              set([
                this.applyLocalChanges({
                  ...rawObject,
                  [collection.property]: existingModelIds,
                }),
              ]);
            }
            break;
          case TransactionType.COLLABORATIVE_DOCUMENT: {
            collaborativeEntitiesToRollback.add(payload.modelId);
          }
        }
      }

      if (collaborativeEntitiesToRollback.size) {
        this.rollbackCollaborativeEdits(
          transaction,
          Array.from(collaborativeEntitiesToRollback),
          get,
          set
        );
      }
    });
  }

  private async rollbackCollaborativeEdits(
    transaction: Transaction,
    ids: string[],
    get: <T extends SyncEngineObject>(id: string) => T | null,
    set: (values: SyncEngineObject[]) => void
  ) {
    const hideToast = showRollbackToast();
    let persistQueue = false;

    for (const id of ids) {
      // disable any active editors
      if (this.collaborativeEditors[id]) {
        this.collaborativeEditors[id].disabled = true;
      }

      // throw away any further operations for this entity because they will also fail
      for (const transaction of this.transactionQueue) {
        const lengthBefore = transaction.transaction.payload.length;
        transaction.transaction.payload = transaction.transaction.payload.filter(
          p => p.type !== TransactionType.COLLABORATIVE_DOCUMENT || p.modelId !== id
        );
        if (transaction.transaction.payload.length !== lengthBefore) {
          persistQueue = true;
        }
      }

      const model = get<CollaborativeDoc>(id);
      if (!model) {
        continue;
      }

      const payloadInRolledBackTransaction = transaction.payload.filter(p => p.modelId === id);

      Sentry.captureMessage('Rolling back collaborative edit', {
        extra: {
          modelId: id,
          clientId: transaction.clientId,
          content: JSON.stringify(model.content),
          contentVersion: model.version,
          transactionPayload: JSON.stringify(payloadInRolledBackTransaction),
        },
      });

      // fetch the latest version from the servers
      const rollbackInfo = await this.requestDocument(this.client, id);
      if (!rollbackInfo.data) {
        continue;
      }

      const { content, version } = rollbackInfo.data;

      // update the version in the local cache
      const updatedModel = get<CollaborativeDoc>(id);
      if (updatedModel) {
        const updatedDoc: CollaborativeDoc = {
          ...updatedModel,
          content,
          version,
        };
        set([updatedDoc]);
      }

      // update the active editor
      if (this.collaborativeEditors[id]) {
        this.collaborativeEditors[id].resetCollaboriativeEditor?.(content, version);
        this.collaborativeEditors[id].disabled = false;
      }
    }

    // flush the transaction queue if needed
    if (persistQueue) {
      this.transactionQueue = this.transactionQueue.filter(t => t.transaction.payload.length);
      this.persistTransactionQueue();
    }

    // flush the offline cache to ensure the right version is cached
    await this.flushOfflineCache(true);

    // actually jarring if it's too quick, so snooze a little
    setTimeout(() => {
      hideToast?.();
    }, 1500);
  }

  private applyLocalChanges(
    object: SyncEngineObject,
    ignoreLocalCollaborativeEdits = false
  ): SyncEngineObject {
    let objectWithLocalChanges = { ...object };
    let applied = false;

    debug(
      '%s - applying local changes to %j (transaction queue = %j)',
      this.clientId,
      object,
      this.transactionQueue
    );

    for (const transaction of this.transactionQueue) {
      for (const payload of transaction.transaction.payload) {
        if (object.id !== payload.modelId || object.__typename !== payload.modelType) {
          continue;
        }

        switch (payload.type) {
          case TransactionType.UPDATE:
            {
              applied = true;
              objectWithLocalChanges = {
                ...objectWithLocalChanges,
                ...(payload as any)[`${camelCase(payload.modelType)}Properties`],
              };
            }
            break;
          case TransactionType.ADD:
            {
              applied = true;
              const collectionPayload = payload.collection!;
              const collection = collectionMap[payload.modelType][collectionPayload.collection];
              const existingModelIds: string[] = (objectWithLocalChanges as any)[
                collection.property
              ];
              for (const modelId of collectionPayload.modelIds) {
                if (!existingModelIds.includes(modelId)) {
                  existingModelIds.push(modelId);
                }
              }
              objectWithLocalChanges = {
                ...objectWithLocalChanges,
                [collection.property]: existingModelIds,
              };
            }
            break;
          case TransactionType.REMOVE:
            {
              applied = true;
              const collectionPayload = payload.collection!;
              const collection = collectionMap[payload.modelType][collectionPayload.collection];
              const existingModelIds: string[] = (objectWithLocalChanges as any)[
                collection.property
              ];
              objectWithLocalChanges = {
                ...objectWithLocalChanges,
                [collection.property]: existingModelIds.filter(
                  modelId => !collectionPayload.modelIds.includes(modelId)
                ),
              };
            }
            break;
          case TransactionType.COLLABORATIVE_DOCUMENT:
            if (ignoreLocalCollaborativeEdits) {
              continue;
            }
            applied = true;
            if (
              transaction.metadata[contentMetadataKey(object.id)] &&
              transaction.metadata[contentVersionMetadataKey(object.id)]
            ) {
              const content = transaction.metadata[contentMetadataKey(object.id)] as Descendant[];
              const contentVersion = transaction.metadata[contentVersionMetadataKey(object.id)];

              const document = objectWithLocalChanges as CollaborativeDoc;
              debug(
                '%s taking the content from a local collaborative edit for a rebase (local content = %j, local version = %s, incoming content = %j, incoming version)',
                this.clientId,
                content,
                contentVersion,
                document.content,
                document.version
              );

              objectWithLocalChanges = {
                ...objectWithLocalChanges,
                content,
                version: contentVersion,
              } as any;
            }
        }
      }
    }

    if (applied) {
      debug(
        '%s - rebased local changes (new = %j, old = %j)',
        this.clientId,
        objectWithLocalChanges,
        object
      );
    }

    return objectWithLocalChanges;
  }

  private localInserts(): SyncEngineObject[] {
    const toLoad: SyncEngineObject[] = [];

    for (const transaction of this.transactionQueue) {
      for (const payload of transaction.transaction.payload) {
        if (payload.type !== TransactionType.CREATE) {
          continue;
        }

        const rawPayload = payload as any;
        const property = Object.getOwnPropertyNames(payload).find(
          propname => propname.endsWith('Properties') && !!rawPayload[propname]
        );

        if (!property) {
          continue;
        }

        toLoad.push({
          id: payload.modelId,
          __typename: payload.modelType,
          ...rawPayload[property],
        });
      }
    }

    return toLoad;
  }

  private mapIncomingPropertiesAndNullableValues(
    modelId: string,
    properties: Record<string, unknown>,
    nullPropertiesToApply: string[],
    ownTransaction: boolean,
    updateTransaction: boolean
  ): Record<string, unknown> {
    const result: Record<any, unknown> = {};

    for (const prop in properties) {
      if (properties[prop] === null && !nullPropertiesToApply.includes(prop)) {
        continue;
      }

      const pendingCollabEdits = this.pendingPayloadsByType(
        modelId,
        TransactionType.COLLABORATIVE_DOCUMENT
      );
      const canReceiveUpdate = !this.collaborativeEditors[modelId] && !pendingCollabEdits.length;
      const ignoreIncomingContent = ownTransaction || (updateTransaction && !canReceiveUpdate);
      if (
        ignoreIncomingContent &&
        (prop === 'content' || prop === 'version') &&
        ((properties.__typename as string)?.replace('AppliedTransactionPayload', '') ?? '') ===
          'CollaborativeDoc'
      ) {
        continue;
      }

      if (prop === '__typename') {
        result[prop] = (properties[prop] as string).replace('AppliedTransactionPayload', '');
        continue;
      }
      result[prop] = properties[prop];
    }

    return result;
  }

  private pendingPayloadsByType(modelId: string, type?: TransactionType): TransactionPayload[] {
    return this.transactionQueue
      .flatMap(transaction => transaction.transaction.payload)
      .filter(payload => payload.modelId === modelId && (!type || payload.type === type));
  }

  async checkForMissingChanges(): Promise<void> {
    try {
      this.checkingForMissingChanges = true;
      for (const id in this.collaborativeEditors) {
        let editor = this.collaborativeEditors[id];
        if (!editor || !editor.version) {
          continue;
        }

        const hasPendingOutgoingPayloads = !!this.pendingPayloadsByType(
          id,
          TransactionType.COLLABORATIVE_DOCUMENT
        ).length;

        if (hasPendingOutgoingPayloads) {
          debug(`%s - Has pending payload, returning`, this.clientId);
          continue;
        }

        const version = editor.version();
        const missingChanges = await this.requestDocumentChanges(this.client, id, version);

        if (!missingChanges.data || !missingChanges.data.missedChanges.length) {
          continue;
        }

        editor = this.collaborativeEditors[id];
        if (!editor) {
          continue;
        }

        const hasMadeLocalChanges = !!this.pendingPayloadsByType(
          id,
          TransactionType.COLLABORATIVE_DOCUMENT
        ).length;

        if (hasMadeLocalChanges) {
          debug(`%s - Has pending local changes, returning`, this.clientId);
          continue;
        }

        const operations = missingChanges.data.missedChanges.flatMap(
          rev => rev.operations
        ) as Operation[];

        debug(
          `%s - Applying ${operations.length} operations from ${missingChanges.data.missedChanges.length} changes`,
          this.clientId
        );

        const latestVersion =
          missingChanges.data.missedChanges[missingChanges.data.missedChanges.length - 1].version;

        if (editor.version?.() !== version) {
          continue;
        }

        HistoryEditor.withoutSaving(editor, () => {
          Editor.withoutNormalizing(editor, () => {
            for (const operation of operations) {
              editor.applyLocal(operation);
            }
          });
        });

        editor.updateVersion?.(latestVersion);
      }
    } catch (e) {
      debug(`%s - Error fetching missing changes: %s`, this.clientId, e.message);
    } finally {
      this.checkingForMissingChanges = false;
    }
  }

  private handleCollaborativeEditResponse(
    payload: AppliedCollaborativeDocumentTransactionPayload,
    sentPayloads: TransactionPayload[],
    editor?: EditorType
  ) {
    const pendingPayloads = this.pendingPayloadsByType(
      payload.modelId,
      TransactionType.COLLABORATIVE_DOCUMENT
    );

    const changes = payload.changes;
    let currentVersion = payload.currentVersion;

    const originalServerOperations = changes.flatMap(r =>
      r.operations.map((o: Operation) => ({
        ...o,
        actorId: r.actorId,
        clientId: r.clientId,
      }))
    );
    let serverOperations: IdentifiedOperation[] = [...originalServerOperations];
    if (serverOperations.length) {
      debug('%s - received missed operations %j', this.clientId, serverOperations);
      const allPayloads = [...sentPayloads, ...pendingPayloads];
      const transformByOperations = allPayloads.flatMap(
        payload => payload.collaborativeDocument!.operations
      ) as Operation[];
      serverOperations = transformAll(serverOperations, transformByOperations, true);
      debug(
        '%s - transformed incoming operations (transformed = %j, transformed by = %j',
        this.clientId,
        serverOperations,
        transformByOperations
      );
    }

    // we need to keep track of the operations that have been sent before each outgoing pending payload
    // so that we can transform the transforms by them.
    const previousOperations = sentPayloads.flatMap(
      payload => payload.collaborativeDocument!.operations
    );

    for (const pendingPayload of pendingPayloads) {
      currentVersion += 1;

      debug(
        '%s - updating version of queued operation (new = %s, old = %s)',
        this.clientId,
        currentVersion,
        pendingPayload.collaborativeDocument!.version
      );
      pendingPayload.collaborativeDocument!.version = currentVersion;

      if (originalServerOperations.length) {
        const transformByOperations = transformAll(
          originalServerOperations,
          previousOperations,
          true
        );
        const operations = pendingPayload.collaborativeDocument!.operations;

        pendingPayload.collaborativeDocument!.operations = transformAll(
          operations,
          transformByOperations,
          false
        );

        debug(
          '%s - transformed outgoing operations (new = %s, old = %j, tranformed by %j)',
          this.clientId,
          pendingPayload.collaborativeDocument!.operations,
          operations,
          transformByOperations
        );

        previousOperations.push(...operations);
      }

      debug(
        '%s - transaction queue after transforming based on remote transaction %j',
        this.clientId,
        this.transactionQueue
      );
    }

    editor?.updateVersion?.(currentVersion);

    if (serverOperations.length) {
      if (editor) {
        const content: DocumentLike = this.applyCollaborativeDocumentOperationsToEditor(
          payload.modelId,
          serverOperations
        );

        this.updateQueuedCollaborativeEdits(payload.modelId, currentVersion, content);
      } else {
        // if we don't have an editor, we just need to update the contents behind the scenes which
        // is surprisingly annoying
        const disposableEditor = withSchema(createEditor());
        this.stateTransaction(({ get, set }) => {
          const model = get<CollaborativeDoc>(payload.modelId);
          if (!model) {
            return;
          }

          let content = model.content;
          Transforms.insertNodes(disposableEditor, content);
          Editor.withoutNormalizing(disposableEditor, () => {
            for (const operation of serverOperations) {
              disposableEditor.apply(operation);
            }
          });
          content = disposableEditor.children;

          const updatedCollabDoc: CollaborativeDoc = {
            ...model,
            content: content,
            version: currentVersion,
          };
          set([updatedCollabDoc]);
          this.updateQueuedCollaborativeEdits(payload.modelId, currentVersion, content);
        });
      }
    } else {
      this.updateQueuedCollaborativeEdits(payload.modelId, currentVersion);
    }
  }

  private updateQueuedCollaborativeEdits(modelId: string, version: number, content?: DocumentLike) {
    for (const transaction of this.transactionQueue.filter(t => !t.metadata[sentMetadataKey])) {
      if (transaction.metadata[`${contentVersionMetadataKey(modelId)}`]) {
        transaction.metadata[`${contentVersionMetadataKey(modelId)}`] = version;
      }
      if (content) {
        if (transaction.metadata[`${contentMetadataKey(modelId)}`]) {
          transaction.metadata[`${contentMetadataKey(modelId)}`] = content;
        }
      }
    }
  }

  private handleRemoteCollaborativeEdit(
    payload: AppliedCollaborativeDocumentTransactionPayload,
    editor: EditorType
  ) {
    const pendingPayloads = this.pendingPayloadsByType(
      payload.modelId,
      TransactionType.COLLABORATIVE_DOCUMENT
    );

    // if we have pending outbound things, we should just ignore the inbound ops because
    // we'll get them as missing change when we send in our changes
    const havePendingTransactions = !!pendingPayloads.length;
    if (havePendingTransactions) {
      return;
    }

    const changes = payload.changes;
    const currentVersion = payload.currentVersion;

    if (editor.version && editor.version() >= changes[0].version) {
      return;
    }

    if (editor.version && editor.version() != changes[0].version - 1) {
      this.pendingCollaborativePayloads.push(payload);
      return;
    }

    const serverOperations = changes.flatMap(r =>
      r.operations.map((o: Operation) => ({
        ...o,
        actorId: r.actorId,
        clientId: r.clientId,
      }))
    );

    const content: DocumentLike = this.applyCollaborativeDocumentOperationsToEditor(
      payload.modelId,
      serverOperations
    );

    editor.updateVersion?.(currentVersion);
    this.updateQueuedCollaborativeEdits(payload.modelId, currentVersion, content);
  }

  private applyCollaborativeDocumentOperationsToEditor(
    modelId: string,
    operations: IdentifiedOperation[]
  ): Descendant[] {
    debug('%s - applying remote transaction with active editor %j', this.clientId, operations);
    const editor = this.collaborativeEditors[modelId];
    let updatedCursors = { ...editor.cursors() };

    HistoryEditor.withoutSaving(editor, () => {
      Editor.withoutNormalizing(editor, () => {
        for (const operation of operations) {
          const maybeUpdatedCursor = calculateCursor(editor, operation);
          updatedCursors = transformCursors(updatedCursors, [operation]);
          if (maybeUpdatedCursor && operation.clientId) {
            updatedCursors[operation.clientId] = maybeUpdatedCursor;
          }

          editor.applyLocal(operation);
        }
      });
    });

    editor.updateCursors(updatedCursors);
    return editor.children;
  }

  transaction<T>(callback: (tx: SyncEngineTransaction, getter: SyncEngineGetters) => T): T {
    let result: T;

    this.stateTransaction(({ get, getIndex, set }) => {
      const transaction: Transaction = {
        id: uuid.v4(),
        clientId: this.clientId,
        payload: [],
      };

      result = callback(
        {
          create<T extends SyncEngineObject>(object: SyncEngineCreate<T>): T {
            const now = Date.now();

            cleanOutUndefineds(object);

            const populatedObject = {
              ...object,
              id: object.id ?? generateId(),
              version: 0,
              deleted: false,
              createdAt: now,
              updatedAt: now,
            } as T;

            transaction.payload.push({
              type: TransactionType.CREATE,
              modelId: populatedObject.id,
              modelType: populatedObject.__typename,
              [`${lowerFirst(populatedObject.__typename)}Properties`]: filterOutgoingProperties(
                populatedObject.__typename,
                populatedObject
              ),
            });

            set([populatedObject]);
            return populatedObject;
          },
          update<T extends SyncEngineObject>(id: string, update: SyncEngineUpdate<T>): void {
            const now = Date.now();

            const existingObject = get<T>(id);
            if (!existingObject) {
              // FIXME log to sentry?
              return;
            }

            if (existingObject.__typename === 'Tombstone') {
              Sentry.captureMessage('Attempt to update Tombstone', {
                extra: {
                  modelId: id,
                },
              });
              return;
            }

            transaction.rollBackData = transaction.rollBackData ?? [];
            if (!transaction.rollBackData.find(o => o.id === id)) {
              transaction.rollBackData.push(existingObject);
            }

            cleanOutUndefineds(update);

            const updatedObject = {
              ...existingObject,
              ...update,
              updatedAt: now,
            } as T;

            transaction.payload.push({
              type: TransactionType.UPDATE,
              modelId: id,
              modelType: updatedObject.__typename,
              [`${lowerFirst(updatedObject.__typename)}Properties`]: filterOutgoingProperties(
                updatedObject.__typename,
                update
              ),
            });

            set([updatedObject]);
          },
          addToCollection(
            collection: SyncEngineCollection,
            modelId: string,
            toAdd: string[]
          ): void {
            const existingObject = get<any>(modelId);
            if (
              !existingObject ||
              existingObject.__typename !== collection.type ||
              !existingObject[collection.property]
            ) {
              // FIXME log to sentry?
              return;
            }

            const added = toAdd.filter(id => !existingObject[collection.property].includes(id));
            if (!added.length) {
              return;
            }

            transaction.payload.push({
              type: TransactionType.ADD,
              modelId,
              modelType: existingObject.__typename,
              collection: {
                collection: collection.name,
                modelIds: toAdd,
              },
            });

            const updatedObject = {
              ...existingObject,
              [collection.property]: [...existingObject[collection.property], ...added],
            };
            set([updatedObject]);
          },
          removeFromCollection(
            collection: SyncEngineCollection,
            modelId: string,
            toRemove: string[]
          ): void {
            const existingObject = get<any>(modelId);
            if (
              !existingObject ||
              existingObject.__typename !== collection.type ||
              !existingObject[collection.property]
            ) {
              // FIXME log to sentry?
              return;
            }

            const removed = toRemove.filter(id => existingObject[collection.property].includes(id));
            if (!removed.length) {
              return;
            }

            transaction.payload.push({
              type: TransactionType.REMOVE,
              modelId,
              modelType: existingObject.__typename,
              collection: {
                collection: collection.name,
                modelIds: removed,
              },
            });

            const updatedObject = {
              ...existingObject,
              [collection.property]: existingObject[collection.property].filter(
                (id: string) => !removed.includes(id)
              ),
            };
            set([updatedObject]);
          },
        },
        { get, getIndex }
      );

      if (transaction.payload.length) {
        this.enqueueTransaction(transaction);
      }
    });

    return result!;
  }

  async flushOfflineCache(force = false) {
    if (!this.lastOrganizationSlug || (this.flushCacheTimeout !== -1 && !force)) {
      return;
    }

    if (force) {
      let flushPromise: Promise<void> | null = null;
      this.stateTransaction(async ({ dumpState }) => {
        const state = dumpState();

        flushPromise = updateCache(this.lastOrganizationSlug!, state);
      });
      await flushPromise;
      return;
    }

    this.flushCacheTimeout = window.requestIdleCallback(
      () => {
        this.flushCacheTimeout = -1;
        if (this.userIdleRef !== null && !this.userIdleRef.current) {
          this.flushOfflineCache();
          return;
        }
        this.stateTransaction(({ dumpState }) => {
          updateCache(this.lastOrganizationSlug!, dumpState());
        });
      },
      { timeout: FLUSH_CACHE_TIMEOUT }
    );
  }

  async flushCollaborativeDocs() {
    for (const [id, editor] of Object.entries(this.collaborativeEditors)) {
      this.updateDocumentInCache(id, editor.children, editor.version?.());
    }
    this.updateDocumentInCache.flush();
  }

  // convenience
  create<T extends SyncEngineObject>(
    object: Omit<T, 'id' | 'version' | 'deleted' | 'createdAt' | 'updatedAt'>
  ): T {
    return this.transaction(tx => tx.create(object));
  }

  update<T extends SyncEngineObject>(
    id: string,
    update: Partial<Omit<T, 'id' | 'version' | 'createdAt' | 'updatedAt'>>
  ): void {
    this.transaction(tx => tx.update(id, update));
  }
  addToCollection(collection: SyncEngineCollection, modelId: string, toAdd: string[]): void {
    this.transaction(tx => tx.addToCollection(collection, modelId, toAdd));
  }
  removeFromCollection(
    collection: SyncEngineCollection,
    modelId: string,
    toRemove: string[]
  ): void {
    this.transaction(tx => tx.removeFromCollection(collection, modelId, toRemove));
  }
}

function loadTransactionQueue(): QueuedTransaction[] {
  const persisted = localStorage.getItem(transactionQueueKey);
  if (persisted) {
    return JSON.parse(persisted);
  }
  return [];
}

function persistTransactionQueue(queue: QueuedTransaction[]) {
  if (queue.length) {
    localStorage.setItem(transactionQueueKey, JSON.stringify(queue));
    return;
  }
  localStorage.removeItem(transactionQueueKey);
}

export function ModelManagerProvider({
  children,
  clientId,
}: {
  children: React.ReactNode;
  clientId: string;
}) {
  const client = useClient();
  const stateTransaction = useStateTransaction();
  const userIdleRef = React.useRef(false);
  useIdleUser(userIdleRef);

  const modelManager = React.useRef(
    new ModelManagerValue({
      clientId,
      client,
      storage: {
        loadTransactionQueue,
        persistTransactionQueue,
      },
      smartLoad: new SmartLoad(client, requestIssues, requestCycles, requestIssueByNumber),
      stateTransaction,
      requestCurrentUser,
      requestOrganization,
      requestIssue,
      requestRoadmaps,
      requestInitiatives,
      requestDocument,
      requestDocumentChanges: requestDocumentChanges,
      sendTransaction,
      userIdleRef,
    })
  );

  React.useEffect(() => {
    async function onUnload() {
      await modelManager.current.unload();
    }

    window.addEventListener('beforeunload', onUnload);
    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, []);

  return (
    <modelManagerContext.Provider value={modelManager.current}>
      {children}
    </modelManagerContext.Provider>
  );
}

export function useModelManager() {
  const context = React.useContext(modelManagerContext)!;
  return context;
}
