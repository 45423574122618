import { isEqual } from 'lodash';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DocumentLike } from '../../../../shared/slate/types';
import { emptyDocument } from '../../../../shared/slate/utils';
import ExternalLink from '../../../components/externalLink';
import { Button, ButtonStyle } from '../../../components/new/button';
import { Setting, SettingsPage } from '../../../components/new/settings';
import { TextInput } from '../../../components/new/textInput';
import { useConfirmation } from '../../../contexts/confirmationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { TextArea, TextAreaHandle, TextAreaType } from '../../../slate/textArea';
import { useCreateSnippet, useUpdateSnippets } from '../../../syncEngine/actions/snippets';
import { snippetsSelector } from '../../../syncEngine/selectors/snippets';
import { useSpaceSettingsPath } from '../../../syncEngine/selectors/spaces';

export function EditSnippetsScreen() {
  const space = useSpace();
  const match = useRouteMatch<{ snippetId: string }>();
  const { snippetId } = match.params;
  const snippet = useRecoilValue(snippetsSelector(snippetId));
  const [contents, setContents] = React.useState<DocumentLike>(
    snippet?.contents ? JSON.parse(snippet.contents) : emptyDocument()
  );
  const { confirm } = useConfirmation();

  const createNew = !snippet;

  const createSnippet = useCreateSnippet();
  const updateSnippets = useUpdateSnippets();
  const spaceSettingsPath = useSpaceSettingsPath();
  const history = useHistory();

  const [snippetName, setSnippetName] = React.useState(snippet ? snippet.name : '');

  const editorRef = React.useRef<TextAreaHandle | null>();

  function save() {
    if (!snippet) {
      createSnippet(space.id, snippetName, JSON.stringify(contents));
    } else {
      updateSnippets([snippet.id], { name: snippetName, contents: JSON.stringify(contents) });
    }
    history.push(spaceSettingsPath(space.id, 'snippets'));
  }
  async function cancel() {
    const existingChanged =
      snippet && (snippet.name !== snippetName || !isEqual(JSON.parse(snippet.contents), contents));
    const newChanged = !snippet && (snippetName.length || !isEqual(emptyDocument(), contents));

    if (existingChanged || newChanged) {
      const confirmed = await confirm(
        'Discard changes?',
        'You have unsaved changes. Are you sure you want to discard them?',
        { label: 'Discard', destructive: true }
      );
      if (!confirmed) {
        return;
      }
    }
    history.push(spaceSettingsPath(space.id, 'snippets'));
  }

  return (
    <SettingsPage
      title={`${createNew ? 'New' : 'Edit'} snippet`}
      description={
        <>
          <p>
            You can use all editor components when creating snippets. Use them for templates as well
            as often used sections of text.
          </p>
          <p>
            <ExternalLink className="link" href="https://guide.kitemaker.co/snippets">
              Read more in the guide
            </ExternalLink>
          </p>
        </>
      }
    >
      <Setting vertical title="Snippet name">
        <TextInput
          value={snippetName}
          autoFocus={createNew}
          onChange={e => setSnippetName(e.currentTarget.value)}
          placeholder={'Snippet name'}
        />
      </Setting>
      <TextArea
        type={TextAreaType.InputLarge}
        initialValue={contents}
        ref={r => {
          editorRef.current = r;
        }}
        richText={true}
        isHardBreak={e => e.key === 'Enter' && e.shiftKey && !e.metaKey && !e.ctrlKey}
        isSoftBreak={() => false}
        onChange={v => setContents(v)}
      />
      <div className="rowEnd mt16">
        <Button
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            cancel();
          }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle={ButtonStyle.Primary}
          className="ml8"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            save();
          }}
        >
          Save
        </Button>
      </div>
    </SettingsPage>
  );
}
