import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../../sync/__generated/models';
import BackButton from '../../../components/new/backButton';
import { IntegrationSettingsPage, SettingsPage } from '../../../components/new/settings';
import { toast } from '../../../components/toast';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { integrationsSelector } from '../../../syncEngine/selectors/integrations';
import { organizationPath } from '../../../syncEngine/selectors/organizations';
import { stringifyIntegrationType } from '../../../utils/integrations';
import { getQueryParameter, removeQueryParameter } from '../../../utils/query';
import { EditDiscordIntegration, NewDiscordIntegration } from './integrations/discord';
import { EditFigmaIntegration, NewFigmaIntegration } from './integrations/figma';
import { EditGitHubIntegration, NewGitHubIntegration } from './integrations/github';
import { EditGitLabIntegration, NewGitLabIntegration } from './integrations/gitlab';
import { EditHubspotIntegration, NewHubspotIntegration } from './integrations/hubspot';
import { EditIntercomIntegration, NewIntercomIntegration } from './integrations/intercom';
import { EditLinearIntegration, NewLinearIntegration } from './integrations/linear';
import { EditSentryIntegration, NewSentryIntegration } from './integrations/sentry';
import {
  EditSlackIntegration,
  ManageSlackIntegrations,
  NewSlackIntegration,
} from './integrations/slack';
import { EditVantaIntegration, NewVantaIntegration } from './integrations/vanta';
import { EditZapierIntegration, NewZapierIntegration } from './integrations/zapier';

export function IntegrationNotFound() {
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'settings/integrations');

  return (
    <SettingsPage
      title={
        <>
          <BackButton className="mb24" defaultPath={defaultPath}>
            All integrations
          </BackButton>
          <div>Integration not found</div>
        </>
      }
      description={`We were unable to locate the integration`}
    />
  );
}

export function ManageIntegrationScreen({ integrationId }: { integrationId: string }) {
  const integration = useRecoilValue(integrationsSelector(integrationId));

  if (!integration) {
    return <IntegrationNotFound />;
  }

  switch (integration.type) {
    case IntegrationType.Discord:
      return <EditDiscordIntegration id={integration.id} />;
    case IntegrationType.Figma:
      return <EditFigmaIntegration id={integration.id} />;
    case IntegrationType.Github:
      return <EditGitHubIntegration id={integration.id} />;
    case IntegrationType.Gitlab:
      return <EditGitLabIntegration id={integration.id} />;
    case IntegrationType.Intercom:
      return <EditIntercomIntegration id={integration.id} />;
    case IntegrationType.Sentry:
      return <EditSentryIntegration id={integration.id} />;
    case IntegrationType.Slack:
      return <EditSlackIntegration id={integration.id} />;
    case IntegrationType.Vanta:
      return <EditVantaIntegration id={integration.id} />;
    case IntegrationType.Zapier:
      return <EditZapierIntegration id={integration.id} />;
    case IntegrationType.Linear:
      return <EditLinearIntegration id={integration.id} />;
    case IntegrationType.Hubspot:
      return <EditHubspotIntegration id={integration.id} />;
    default:
      return <SettingsPage title={`Not implemented`}></SettingsPage>;
  }
}

export function NewIntegrationScreen({ integrationType }: { integrationType: IntegrationType }) {
  const organization = useOrganization();
  const { host } = useConfiguration();

  switch (integrationType) {
    case IntegrationType.Github:
      return (
        <NewGitHubIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Gitlab:
      return (
        <NewGitLabIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Figma:
      return (
        <NewFigmaIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Discord:
      return (
        <NewDiscordIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Sentry:
      return (
        <NewSentryIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Slack:
      return (
        <NewSlackIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Vanta:
      return (
        <NewVantaIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Zapier:
      return (
        <NewZapierIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Intercom:
      return (
        <NewIntercomIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Linear:
      return (
        <NewLinearIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    case IntegrationType.Hubspot:
      return (
        <NewHubspotIntegration
          redirectUrl={`${host}${organizationPath(organization, 'settings/integrations')}`}
        />
      );
    default:
      return (
        <IntegrationSettingsPage
          integrationType={integrationType}
          description="Unknown integration type"
        >
          <p>
            The current integration is not known to us. Do you think it should exist? Please contact{' '}
            <a href="mailto:hi@kitemaker.co">support</a>
          </p>
        </IntegrationSettingsPage>
      );
  }
}

export function ManageIntegrationsScreen({
  integrationType,
}: {
  integrationType: IntegrationType;
}) {
  const history = useHistory();
  const pending = getQueryParameter(history, 'pendingIntegration');
  if (pending) {
    removeQueryParameter(history, 'pendingIntegration');
    toast.success(
      `The ${stringifyIntegrationType(
        pending as IntegrationType
      )} integration was successfully installed`
    );
  }

  switch (integrationType) {
    case IntegrationType.Slack2:
      return <ManageSlackIntegrations />;
  }

  return null;
}
