import * as Sentry from '@sentry/browser';
import React from 'react';
import { Descendant } from 'slate';
import { emptyDocument, isDocumentEmpty } from '../../shared/slate/utils';
import { useComponentDidMount } from './useComponentDidMount';

export function useEditableComment(
  objectId: string
): [Descendant[], (comment: Descendant[]) => void, (comment: Descendant[]) => void] {
  const commentId = `__comment-${objectId}`;
  const comment = localStorage.getItem(commentId);

  let initialValue: Descendant[];
  try {
    initialValue = comment !== null ? JSON.parse(comment) : emptyDocument();
  } catch (error) {
    Sentry.captureException(error);
    initialValue = emptyDocument();
    localStorage.removeItem(commentId);
  }
  const [newComment, setNewComment] = React.useState(initialValue);

  const commentRef = React.useRef(newComment);

  const setComment = (comment: Descendant[]) => {
    setNewComment(comment);
    commentRef.current = comment;
  };

  const persistComment = (comment: Descendant[]) => {
    if (isDocumentEmpty(comment)) {
      localStorage.removeItem(commentId);
    } else {
      const localStorageItem = JSON.stringify(comment);
      localStorage.setItem(commentId, localStorageItem);
    }
  };

  // save comment on componentUnmount
  useComponentDidMount(() => {
    return () => {
      persistComment(commentRef.current);
    };
  });

  return [newComment, setComment, persistComment];
}
