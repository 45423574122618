import { trim } from 'lodash';
import * as React from 'react';
import { Editor } from 'slate';
import { OrganizationLabel } from '../../../../../graphql__generated__/graphql';
import { KitemakerElement } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { mapColor } from '../../../../shared/utils/colors';
import { IssueLabel as IssueLabelModel } from '../../../../sync/__generated/models';
import { Icon, IconSize } from '../../../components/new/icon';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { SuggestionMatcher, SuggestionOption } from './withSuggestions';

const newLabelRegex = new RegExp(
  `^([\\w_\\-\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]+|"[\\s\\w_\\-\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]+")$`
);

export function labelSuggestionMatcher(
  createLabel: ((name: string) => IssueLabelModel | OrganizationLabel) | null,
  labels: () => IssueLabelModel[] | OrganizationLabel[],
  options?: {
    limitToElements?: Elements[];
  }
): SuggestionMatcher {
  return {
    id: 'label',
    prefix: '#',
    options: (partialMatch, editor) => {
      if (!partialMatch) {
        return [];
      }

      if (options?.limitToElements) {
        const nodes = Editor.nodes(editor, {
          mode: 'lowest',
          match: n => KitemakerElement.isElement(n) && options.limitToElements!.includes(n.type),
        });
        const node = nodes.next().value;
        if (!node) {
          return [];
        }
      }

      const labelOptions: SuggestionOption[] = labels().map(label => ({
        ...label,
        value: label.name,
        render: function RenderLabelSuggestion() {
          const color = mapColor(label.color);
          return (
            <div className="row">
              <Icon icon="label" size={IconSize.Size20} style={{ fill: color }} />
              <span className="ml8 ellipsis">{label.name}</span>
            </div>
          );
        },
      }));

      const validNewLabelSuggestion = partialMatch.match(newLabelRegex);
      if (validNewLabelSuggestion && createLabel) {
        const value = trim(partialMatch, '"');
        labelOptions.push({
          id: `create-label-${value}`,
          value,
          render: function RenderNewLabel() {
            return <div className="row">Create label &quot;{value}&quot;</div>;
          },
          footer: true,
          suggestionAutoCompleteOnly: true,
        });
      }

      return labelOptions;
    },
    onMatch: (editor, option, range, autocomplete) => {
      let labelId = option.id;
      if (labelId.startsWith('create-label-')) {
        const label = createLabel!(option.value);
        labelId = label.id;
      }
      KitemakerTransforms.insertMention(
        editor,
        {
          type: Elements.Label,
          labelId,
          children: [{ text: '' }],
        },
        range,
        autocomplete
      );
    },
    handleTrailingSpace: true,
  };
}
