import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import {
  Board,
  PreparedTrelloImport,
  checkToken,
  fetchBoards,
  importFromTrello,
  prepareImport,
} from '../../../../api/trello';
import BackButton from '../../../../components/new/backButton';
import { Button, ButtonStyle } from '../../../../components/new/button';
import { Icon, IconSize } from '../../../../components/new/icon';
import { LoadingSpinner } from '../../../../components/new/loadingSpinner';
import { Setting, SettingsPage, SettingsSection } from '../../../../components/new/settings';
import { Switch } from '../../../../components/new/switch';
import { TextValidationInput } from '../../../../components/new/textInput';
import SelectButton from '../../../../components/selectButton';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { organizationPath } from '../../../../syncEngine/selectors/organizations';
import { spacesForOrganizationSelector } from '../../../../syncEngine/selectors/spaces';
import { stringifyIntegrationType } from '../../../../utils/integrations';
import styles from './import.module.scss';
import { ImportAssigneePicker } from './importAssigneePicker';
import { ImportType } from '.';

function Import({ onImportCompleted }: { onImportCompleted: () => void }) {
  const organization = useOrganization();
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const defaultPath = organizationPath(organization, 'settings/import');

  const [allBoards, setAllBoards] = React.useState<Board[]>([]);
  const [boardsFetched, setBoardsFetched] = React.useState(false);
  const [board, setBoard] = React.useState<Board | null>(null);
  const [preparing, setPreparing] = React.useState(false);
  const [preparedImport, setPreparedImport] = React.useState<PreparedTrelloImport | null>(null);
  const [importInProgress, setImportInProgress] = React.useState(false);

  const [importArchived, setImportArchived] = React.useState(true);
  const [importLabels, setImportLabels] = React.useState(true);
  const [mapAssignees, setMapAssignees] = React.useState<{ [index: string]: string }>({});
  const [newSpaceName, setNewSpaceName] = React.useState('');

  const [importCount, setImportCount] = React.useState(0);

  const newSpaceNameAlreadyExists = !!spaces.find(
    s => s.name.toLowerCase() === newSpaceName.toLowerCase()
  );

  React.useEffect(() => {
    if (!preparedImport) {
      setImportCount(0);
      return;
    }
    const importCount = importArchived
      ? preparedImport.archivedCardCount + preparedImport.openCardCount
      : preparedImport.openCardCount;

    setImportCount(importCount);
  }, [importArchived, preparedImport]);

  React.useEffect(() => {
    (async () => {
      const fetchedBoards = await fetchBoards();
      setAllBoards(fetchedBoards);
      setBoardsFetched(true);
    })();
  }, []);

  React.useEffect(() => {
    setPreparedImport(null);
    setMapAssignees({});

    if (!board) {
      return;
    }

    setPreparing(true);
    (async () => {
      const preparedImportResult = await prepareImport(organization.id, board.id);
      setPreparing(false);
      setPreparedImport(preparedImportResult);

      let count = 1;
      let newName = board.name;

      while (spaces.find(s => s.name === newName)) {
        newName = `${board.name}${count}`;
        count += 1;
      }
      setNewSpaceName(newName);
    })();
  }, [board]);

  async function startImport() {
    if (importInProgress) {
      return;
    }
    setImportInProgress(true);

    await importFromTrello({
      id: preparedImport!.id,
      importArchivedCards: importArchived,
      spaceName: newSpaceName,
      importLabels,
      mapAssignees,
    });

    setImportInProgress(false);
    onImportCompleted();
  }

  if (!boardsFetched) {
    return (
      <SettingsPage title="Import from Trello">
        <div className="colCenter mt32">
          <LoadingSpinner />
          <p className="mt16">Fetching Trello boards</p>
        </div>
      </SettingsPage>
    );
  }

  return (
    <SettingsPage title="Import from Trello">
      <SettingsSection title="Create a new space from a Trello board">
        <Setting title="Source repository">
          <SelectButton
            placeholder="Please select a board"
            propertiesToSearch={['name']}
            value={board?.id ?? ''}
            onSelect={boardId => setBoard(allBoards.find(b => b.id === boardId) ?? null)}
            items={allBoards.map(board => ({
              id: board.id,
              name: board.name,
              contents: <>{board.name}</>,
            }))}
          />
        </Setting>

        {preparing && (
          <div className="colCenter mt32">
            <LoadingSpinner />
            <p className="mt16">Preparing import</p>
          </div>
        )}
      </SettingsSection>

      {preparedImport && (
        <>
          <SettingsSection>
            <Setting title={`Importing ${importCount} cards`} />

            <Setting title="Space name" description="Give your new space a name">
              <TextValidationInput
                value={newSpaceName}
                error={
                  newSpaceNameAlreadyExists ? 'A space with that name already exists' : undefined
                }
                onChange={e => setNewSpaceName(e.currentTarget.value)}
              />
            </Setting>

            <Setting
              title="Import archived cards"
              description={`Archived Trello cards will imported as archived Kitemaker ${issueTerm}s`}
            >
              <Switch checked={importArchived} onChange={setImportArchived} />
            </Setting>
            <Setting title="Import labels">
              <Switch checked={importLabels} onChange={setImportLabels} />
            </Setting>
          </SettingsSection>

          <SettingsSection
            title={`Import assignees (${Object.keys(mapAssignees).length}/${
              preparedImport.assignees.length
            })`}
            description={`Choose existing Kitemaker users or invite your teammates to Kitemaker to automatically import ${stringifyIntegrationType(
              IntegrationType.Trello
            )} assignees`}
          >
            <ImportAssigneePicker
              assignees={preparedImport.assignees}
              mapAssignees={mapAssignees}
              onMapAssigneesChanged={setMapAssignees}
            />
          </SettingsSection>
        </>
      )}

      <SettingsSection>
        <div className="rowBetween">
          <BackButton defaultPath={defaultPath} hideIcon>
            Back
          </BackButton>
          <Button
            buttonStyle={ButtonStyle.Primary}
            disabled={!preparedImport || !newSpaceName || importInProgress}
            onClick={() => startImport()}
          >
            {!importInProgress && <span>Import</span>}
            {importInProgress && (
              <>
                Importing&nbsp;
                <LoadingSpinner />
              </>
            )}
          </Button>
        </div>
      </SettingsSection>
    </SettingsPage>
  );
}

function Auth({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();
  const defaultPath = organizationPath(organization, 'settings/import');

  async function startAuthFlow() {
    await externalAuthFlow(`${host}/import/trello/auth`, electronScheme, {
      exchange: true,
      redirectQueryParams: {
        pendingImport: ImportType.ImportTrelloBoardToNewSpace,
        forceRefresh: 'true',
      },
      redirectBaseUrl: redirectUrl,
    });
  }

  return (
    <SettingsPage
      title="Import from Trello"
      description="Create a new space in Kitemaker from a Trello board"
    >
      <p>The process of importing is just a few easy steps:</p>
      <ul className="mb32">
        <li>We'll need to authenticate you with Trello so we can access your data</li>
        <li>You'll choose which board you want to import from and give your new space a name</li>
        <li>You'll choose whether or not you want to import archived cards or not</li>
        <li>You'll choose whether to import labels from Trello or not</li>
        <li>
          You'll optionally map members from your Trello cards to Kitemaker users so that people
          were assigned to the Trello cards will automatically be members of the Kitemaker work
          items
        </li>
      </ul>
      <SettingsSection>
        <div className="rowBetween">
          <BackButton hideIcon defaultPath={defaultPath}>
            Back
          </BackButton>
          <Button buttonStyle={ButtonStyle.Primary} onClick={startAuthFlow}>
            Authenticate
          </Button>
        </div>
      </SettingsSection>
    </SettingsPage>
  );
}

export function TrelloImportScreen({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const [tokenChecked, setTokenChecked] = React.useState(false);
  const [hasToken, setHasToken] = React.useState(false);
  const [importCompleted, setImportCompleted] = React.useState(false);
  const defaultPath = organizationPath(organization, 'settings/import');

  React.useEffect(() => {
    (async () => {
      const result = await checkToken();
      setTokenChecked(true);
      setHasToken(result);
    })();
  }, []);

  if (importCompleted) {
    return (
      <SettingsPage title="Import from Trello">
        <div className="colCenter mt32">
          <Icon icon="success" size={IconSize.Size40} className={styles.successIcon} />
          <p className="mt16">Import started</p>
        </div>

        <SettingsSection>
          <BackButton defaultPath={defaultPath} hideIcon>
            Back
          </BackButton>
        </SettingsSection>
      </SettingsPage>
    );
  }

  return (
    <>
      {!tokenChecked && (
        <SettingsPage title="Import from Trello">
          <div className="colCenter mt32">
            <LoadingSpinner />
            <p className="mt16">Verifying Trello token</p>
          </div>
        </SettingsPage>
      )}
      {tokenChecked && !hasToken && <Auth redirectUrl={redirectUrl} />}
      {tokenChecked && hasToken && <Import onImportCompleted={() => setImportCompleted(true)} />}
    </>
  );
}
