import debugModule from 'debug';
import { random, sample } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { IssueStatusType } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import {
  useToggleAssigneesForEntities,
  useToggleLabelsForEntities,
} from '../../syncEngine/actions/entities';
import { useUpdateIssueSorts } from '../../syncEngine/actions/issues';
import { statusesForSpaceSelector } from '../../syncEngine/selectors/issueStatuses';
import { labelsForSpaceSelector } from '../../syncEngine/selectors/labels';
import { activeUsersForSpaceSelector } from '../../syncEngine/selectors/spaces';
import { CustomCommand } from '../new/customCommand';
import { toast } from '../toast';

const debug = debugModule('issueChaosMode');

function ChaosMode({ issues, spaceId }: { issues: Record<string, string[]>; spaceId: string }) {
  const statuses = useRecoilValue(statusesForSpaceSelector(spaceId)).filter(
    s => s.statusType !== IssueStatusType.Archived
  );
  const labels = useRecoilValue(labelsForSpaceSelector(spaceId));
  const users = useRecoilValue(activeUsersForSpaceSelector(spaceId));

  const updateSort = useUpdateIssueSorts();
  const toggleLabel = useToggleLabelsForEntities();
  const toggleAssignees = useToggleAssigneesForEntities();

  function applyRandomOperations(options?: { iterations?: number; interval?: number }) {
    toast.info('Board chaos mode started');
    const interval = options?.interval ?? 0;
    const iterations = options?.iterations ?? 1;
    debug('applyRandomOperations - Running', iterations, 'iterations');

    let i = 0;
    const intervalRef = window.setInterval(() => {
      i++;
      if (i >= iterations) {
        debug('applyRandomOperations - Completed running', iterations, 'iterations');
        toast.info('Board chaos mode completed');
        window.clearInterval(intervalRef);
        return;
      }

      const issueId = sample(Object.values(issues).flat());
      if (!issueId) {
        return;
      }

      const randomValue = Math.random();
      if (randomValue < 0.4) {
        //  move it
        const status = sample(statuses);
        if (!status) {
          return;
        }
        const issuesForStatus = issues[status.id] ?? [];
        const index = random(0, issuesForStatus.length - 1);
        updateSort(status.id, [issueId], issuesForStatus[index], issuesForStatus[index + 1]);
      } else if (randomValue >= 0.4 && randomValue < 0.7) {
        //  label it
        const label = sample(labels);
        if (!label) {
          return;
        }
        toggleLabel([issueId], [label.id]);
      } else if (randomValue >= 0.7) {
        // assign it
        const user = sample(users);
        if (!user) {
          return;
        }
        toggleAssignees([issueId], [user.user.id]);
      }
    }, interval);
  }

  return (
    <CustomCommand
      command={{
        id: 'board-chaos-mode',
        description: 'Board chaos mode',
        handler: () => applyRandomOperations({ iterations: 100, interval: 500 }),
      }}
    />
  );
}
export function IssueBoardChaosMode({
  issues,
  spaceId,
}: {
  issues: Record<string, string[]>;
  spaceId: string;
}) {
  const { production } = useConfiguration();
  if (production) {
    return null;
  }

  return <ChaosMode issues={issues} spaceId={spaceId} />;
}
