import * as React from 'react';
import {
  Activity as ActivityModel,
  TitleChangedActivityDetails,
} from '../../../../sync/__generated/models';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function TitleChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as TitleChangedActivityDetails;
  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> changed title
      from <span className="fs-exclude">&quot;{details.originalTitle}&quot;</span> to{' '}
      <span className="fs-exclude">
        &quot;
        {details.newTitle}&quot;
      </span>
      .
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const TitleChangedActivity = React.memo(TitleChangedActivityComponent);
