import React from 'react';
import { List } from 'react-virtualized';

export function useVirtualizedEnsureVisible(
  idsRef: React.MutableRefObject<string[]>,
  listRef: React.MutableRefObject<List | null | undefined>,
  visibleRangeRef: React.MutableRefObject<{ startIndex: number; stopIndex: number } | null>,
  afterEnsureVisible?: () => void
) {
  const ensureVisible = React.useCallback(
    (id: string | null) => {
      if (!id) {
        return;
      }

      const index = idsRef.current.indexOf(id);
      if (index === -1) {
        return;
      }

      if (
        visibleRangeRef.current &&
        (index < visibleRangeRef.current.startIndex + 1 ||
          index > visibleRangeRef.current.stopIndex - 1)
      ) {
        listRef.current?.scrollToRow(index);
        // for some reason when jumping to the bottom, it doesn't quite manage to get there
        // so for now we double scroll it :shrug:
        if (index === idsRef.current.length - 1) {
          setTimeout(() => listRef.current?.scrollToRow(index), 5);
        }
      }
      afterEnsureVisible?.();
    },
    [afterEnsureVisible]
  );

  return ensureVisible;
}
