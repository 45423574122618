import * as React from 'react';

export interface SizeCacheContext {
  invalidateSizeCache(index: number): void;
}

const sizeCacheContext = React.createContext<SizeCacheContext | null>(null);

export function SizeCacheProvider({
  children,
  value,
}: {
  children: React.ReactNode;
  value: SizeCacheContext;
}) {
  return <sizeCacheContext.Provider value={value}>{children}</sizeCacheContext.Provider>;
}

export function useSizeCache() {
  return React.useContext(sizeCacheContext)!;
}
