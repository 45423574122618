import debugModule from 'debug';
import React from 'react';
import { FIGMA_LINK_REGEX } from '../../shared/figma';
import { toast } from '../components/toast';

const debug = debugModule('figma');

export function normalizeFigmaLink(maybeFigmaLink: string): string | null {
  if (!maybeFigmaLink.trim().match(FIGMA_LINK_REGEX)) {
    return null;
  }
  return maybeFigmaLink.startsWith('https://') ? maybeFigmaLink : `https://${maybeFigmaLink}`;
}

export async function createFigmaThumbnail(
  organizationId: string,
  url: string,
  thumbnailId: string
): Promise<{ needsAuth: boolean }> {
  const resp = await fetch('/integrations/figma/thumbnails', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      organizationId,
      thumbnailId,
      url,
    }),
  });

  const respJson = await resp.json();
  if (respJson.errors) {
    toast.error(
      respJson.errors.length
        ? respJson.errors[0].message
        : 'An unknown error occurred while importing the Figma file'
    );
    throw Error('Unable to fetch Figma thumbnail');
  }

  if (!respJson.data) {
    throw Error('Unable to fetch Figma thumbnail');
  }
  const { result } = respJson.data as { result: string };
  return { needsAuth: result === 'FIGMA_THUMBNAIL_CREATION_PENDING' };
}

export async function fetchFigmaThumbnail(thumbnailId: string) {
  try {
    const resp = await fetch(`/integrations/figma/thumbnails/${thumbnailId}`, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    });
    if (resp.status === 404) {
      return null;
    }
    const respJson = await resp.json();
    if (respJson.errors) {
      throw new Error(
        respJson.errors.length
          ? respJson.errors[0].message
          : 'An unknown error occurred while fetching the Figma thumbnail'
      );
    }

    if (!respJson.data) {
      throw new Error('An unknown error occurred while fetching the Figma thumbnail');
    }

    return respJson.data;
  } catch (e) {
    debug('Error fetching Figma thumbnail', e);
    throw e;
  }
}

export function refreshFigmaThumbnail(thumbnailId: string, refreshComplete: () => void) {
  async function performRefresh() {
    try {
      const resp = await fetch(`/integrations/figma/thumbnails/refresh/${thumbnailId}`, {
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
        },
      });
      const respJson = await resp.json();
      if (respJson.errors) {
        throw new Error(
          respJson.errors.length
            ? respJson.errors[0].message
            : 'An unknown error occurred while fetching the Figma thumbnail'
        );
      }

      if (!respJson.data) {
        throw new Error('An unknown error occurred while fetching the Figma thumbnail');
      }

      if (respJson.data.state === 'failed') {
        throw new Error('Unable to refresh Figma thumbnail');
      }

      if (respJson.data.state === 'completed') {
        refreshComplete();
        return;
      }

      setTimeout(performRefresh, 1500);
    } catch (e) {
      debug('Error refreshing Figma thumbnail', e);
      throw e;
    }
  }

  performRefresh();
}

export async function installFigmaWebhooks(orgId: string, tId: string) {
  const res = await fetch(`/integrations/figma/hooks?organization_id=${orgId}&team_id=${tId}`, {
    method: 'POST',
  });

  if (res.status == 200) {
    toast.success(
      <>
        <span className="semiBold">The Figma integration</span> was successfully installed!
      </>
    );
  } else if (res.status == 401) {
    toast.error(
      <>
        An error occurred when adding <span className="semiBold">Figma integration</span>. Do you
        have admin rights on the specified team?
      </>
    );
  } else {
    toast.error(
      <>
        An unknown error occurred when adding <span className="semiBold">Figma integration</span>.
        Code {res.status}
      </>
    );
  }
}

export async function uninstallFigmaWebhooks(integrationId: string) {
  const res = await fetch(`/integrations/figma/hooks?integration_id=${integrationId}`, {
    method: 'DELETE',
  });

  if (res.status == 200) {
    toast.success(
      <>
        Successfully uninstalled <span className="semiBold">Figma integration</span>
      </>
    );
  } else {
    toast.error(
      <>
        Failed to uninstall <span className="semiBold">Figma integration</span>. Code {res.status}
      </>
    );
  }
}
