import gql from 'graphql-tag';
import { uniq } from 'lodash';
import { useMutation } from 'urql';
import { User } from '../../../sync/__generated/models';
import { toast } from '../../components/toast';
import { useClient } from '../../contexts/clientContext';
import { useCurrentUser } from '../../contexts/userContext';
import { messageFromGraphQLError } from '../../graphql/errors';
import { SyncEngineUpdateWithoutDelete, useModelManager } from '../../graphql/modelManager';
import { useStateTransaction } from '../state';

function onError(error: any) {
  const message = messageFromGraphQLError(error.graphQLErrors) ?? 'An unknown error occurred';
  toast.error(message);
}

export function useMarkTutorialCompleted(userId?: string) {
  const modelManager = useModelManager();
  const currentUser = useCurrentUser();

  return (tutorial: string) => {
    modelManager.transaction((tx, { get }) => {
      const user = get<User>(userId ?? currentUser?.id);
      if (!user) {
        return;
      }
      if (user.completedTutorials.includes(tutorial)) {
        return;
      }
      tx.update<User>(user.id, {
        completedTutorials: uniq([...user.completedTutorials, tutorial]),
      });
    });
  };
}

export function useUpdateUsers() {
  const modelManager = useModelManager();
  return (userIds: string[], update: SyncEngineUpdateWithoutDelete<User>) => {
    modelManager.transaction(tx => {
      for (const userId of userIds) {
        tx.update<User>(userId, update);
      }
    });
  };
}

export function useUpdatePreferences() {
  const modelManager = useModelManager();
  const currentUser = useCurrentUser();

  return (update: Record<string, unknown>) => {
    modelManager.transaction((tx, { get }) => {
      const user = get<User>(currentUser?.id);
      const preferences = { ...(user?.preferences ?? {}), ...update };
      tx.update<User>(currentUser?.id, { preferences });
    });
  };
}

export function useChangeEmail() {
  const stateTransaction = useStateTransaction();
  const client = useClient();
  const user = useCurrentUser();

  const [, changeEmailMutation] = useMutation(
    gql`
      mutation ChangeEmail($email: String!, $client: String!) {
        changeEmail(input: { email: $email, clientId: $client }) {
          changed
        }
      }
    `
  );

  return async (email: string): Promise<boolean> => {
    const result = await changeEmailMutation({
      email,
      client,
    });

    if (result.error) {
      onError(result.error);
      return false;
    }

    if (!result?.data?.changeEmail.changed) {
      return false;
    }

    if (result.data.changeEmail.changed) {
      stateTransaction(tx => {
        tx.set([{ ...user, primaryEmail: email } as User]);
      });
    }

    return result.data.changeEmail.changed;
  };
}
