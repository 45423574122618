import { uniqBy } from 'lodash';
import { selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { issueLabelsBySpace } from '../../../sync/__generated/indexes';
import { IssueLabel } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { spacesForOrganizationSelector } from './spaces';

export const labelSelector = selectorFamily({
  key: 'Label',
  get:
    (labelId: string) =>
    ({ get }) => {
      return notDeleted(get(syncEngineState(labelId)) as IssueLabel | null);
    },
});

export const labelsSelector = selectorFamily({
  key: 'Labels',
  get:
    (labelIds: string[]) =>
    ({ get }) => {
      return filterNotNull(labelIds.map(id => get(labelSelector(id))));
    },
});

export const labelsForSpaceSelector = selectorFamily({
  key: 'LabelsForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }
      const labelIds = get(indexKeyState(indexKey(issueLabelsBySpace, spaceId)));
      return filterNotDeletedNotNull(
        labelIds.map(labelId => get(syncEngineState(labelId)) as IssueLabel | null)
      );
    },
});

export const uniqueLabelsForOrganizationSelector = selectorFamily({
  key: 'UniqueLabelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const spaces = get(spacesForOrganizationSelector(organizationId));
      const labels = spaces.flatMap(s => get(labelsForSpaceSelector(s.id)));
      return uniqBy(labels, 'name');
    },
});
