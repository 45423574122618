import { maxBy } from 'lodash';
import { randomString } from '../../../shared/utils/random';
import {
  impactLevelsByOrganization,
  impactLevelsBySpace,
  issuesByImpact,
} from '../../../sync/__generated/indexes';
import { Impact, Issue } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { SyncEngineCreate, SyncEngineUpdate, useModelManager } from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';

export function useCreateImpact() {
  const organization = useOrganization();
  const modelManager = useModelManager();
  return (spaceId: string | null, name: string, abbrevation: string): Impact => {
    return modelManager.transaction((tx, { get, getIndex }) => {
      let impactLevels;

      if (spaceId) {
        const impactLevelIds = getIndex(impactLevelsBySpace, spaceId);
        impactLevels = impactLevelIds
          .map(id => get(id) as Impact)
          .filter(e => e !== null && !e.deleted);
      } else {
        const impactLevelIds = getIndex(impactLevelsByOrganization, organization.id);
        impactLevels = impactLevelIds
          .map(id => get(id) as Impact)
          .filter(e => e !== null && !e.deleted && !e.spaceId);
      }

      const maxLevel = maxBy(impactLevels, 'level')?.level ?? 1;
      const impact: SyncEngineCreate<Impact> = {
        __typename: 'Impact',
        name,
        color: 'gray',
        level: maxLevel + 1,
        organizationId: organization.id,
        spaceId,
        abbrevation,
      };

      return tx.create(impact);
    });
  };
}

export function useUpdateImpacts() {
  const modelManager = useModelManager();
  return (impactIds: string[], update: SyncEngineUpdate<Impact>) => {
    modelManager.transaction(tx => {
      for (const impactId of impactIds) {
        tx.update<Impact>(impactId, update);
      }
    });
  };
}

export function useReorderImpacts() {
  const modelManager = useModelManager();
  return (impactIds: string[]) => {
    modelManager.transaction(tx => {
      for (const [index, impactId] of impactIds.entries()) {
        tx.update<Impact>(impactId, { level: index + 1 });
      }
    });
  };
}

export function useDeleteImpacts() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (impactIds: string[], force?: boolean) => {
    if (!force) {
      const confirmed = await confirm(
        `Delete impact${impactIds.length > 1 ? 's' : ''}`,
        `Are you sure you want to delete ${
          impactIds.length > 1 ? 'these impacts' : 'this impact'
        }? There is no way to undo this operation`,
        {
          label: 'Delete',
          destructive: true,
        }
      );

      if (!confirmed) {
        return [];
      }
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const impactId of impactIds) {
        const impact = get<Impact>(impactId);
        if (!impact || impact.deleted) {
          continue;
        }

        // clean up issues
        const issueIds = getIndex(issuesByImpact, impact.id);
        for (const issueId of issueIds) {
          tx.update<Issue>(issueId, { impactId: null });
        }

        tx.update<Impact>(impactId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });

        deleted.push(impactId);
        trackerEvent('g Deleted', {
          id: impact.id,
          name: impact.name,
        });
      }
    });

    return deleted;
  };
}
