import cn from 'classnames';
import React from 'react';
import { Icon, IconSize } from './icon';
import styles from './placeholder.module.scss';

export default function Placeholder({
  icon,
  title,
  children,
  className,
}: {
  icon: string;
  title: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn(styles.placeholder, className)}>
      <Icon style={{ fill: 'var(--gray8)' }} size={IconSize.Size40} icon={icon} />
      <span className="headingS">{title}</span>
      {children}
    </div>
  );
}
