import * as React from 'react';
import { IssueStatusType } from '../../../sync/__generated/models';
import { Icon, IconSize } from './icon';

export function statusToIcon(type: IssueStatusType, isDefault?: boolean) {
  switch (type) {
    case IssueStatusType.Backlog: {
      if (isDefault) {
        return 'status_inbox';
      }
      return 'status_shortlist';
    }
    case IssueStatusType.Todo: {
      if (isDefault) {
        return 'status_inbox';
      }
      return 'status';
    }
    case IssueStatusType.InProgress:
      return 'status_in_progress';
    case IssueStatusType.Done:
      if (isDefault) {
        return 'status_done_default';
      }
      return 'status_done';
    case IssueStatusType.Archived:
      return 'archive';
  }
}

export function statusToString(type: IssueStatusType) {
  switch (type) {
    case IssueStatusType.Backlog:
      return 'Backlog';
    case IssueStatusType.Todo:
      return 'Not started';
    case IssueStatusType.InProgress:
      return 'Started';
    case IssueStatusType.Done:
      return 'Done';
    case IssueStatusType.Archived:
      return 'Archived';
  }
}

export function StatusIcon({
  type,
  isDefault,
  size,
  className,
  style,
}: {
  type: IssueStatusType;
  isDefault?: boolean;
  size?: IconSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <Icon size={size} icon={statusToIcon(type, isDefault)} className={className} style={style} />
  );
}
