import cn from 'classnames';
import * as React from 'react';
import { Text } from 'slate';
import { RenderElementProps, useSlateStatic } from 'slate-react';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { isAndroid } from '../../utils/config';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import {
  CustomElement,
  Elements,
  OptionalAttributesRenderElementProps,
  ParagraphElement,
} from '../types';
import styles from './paragraph.module.scss';

function useBigCustomEmoji(element: CustomElement) {
  return React.useMemo(() => {
    if (element.children.length !== 3) {
      return false;
    }
    if (
      !KitemakerElement.isElement(element.children[1]) ||
      element.children[1].type !== Elements.CustomEmoji
    ) {
      return false;
    }
    if (
      !Text.isText(element.children[0]) ||
      !Text.isText(element.children[2]) ||
      element.children[0].text !== '' ||
      element.children[2].text !== ''
    ) {
      return false;
    }
    return true;
  }, [element]);
}

export function StaticParagraph({
  children,
  attributes,
  element,
}: OptionalAttributesRenderElementProps) {
  const bigCustomEmoji = useBigCustomEmoji(element);

  return (
    <div
      className={cn('block', {
        [styles.bigCustomEmoji]: bigCustomEmoji,
      })}
      {...attributes}
    >
      {children}
    </div>
  );
}

function Placeholder({ element }: { element: ParagraphElement }) {
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const slate = useSlateStatic();

  if (!selected || !selectionCollapsed) {
    return null;
  }

  if (
    element.children.length > 1 ||
    !Text.isText(element.children[0]) ||
    element.children[0].text !== '' ||
    (slate.showPlaceholderOnFirstParagraph && slate.children.length === 1)
  ) {
    return null;
  }

  return (
    <span contentEditable={false} className={styles.placeholder}>
      Type '/' to insert a new block
    </span>
  );
}

export function Paragraph({
  attributes,
  children,
  element,
}: RenderElementProps & { element: ParagraphElement }) {
  const editor = useSlateStatic();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();
  const bigCustomEmoji = useBigCustomEmoji(element);

  return (
    <div
      {...attributes}
      {...dndAttributes}
      className={cn(
        'block',
        styles.paragraph,
        { [styles.bigCustomEmoji]: bigCustomEmoji },
        dndClassName
      )}
    >
      {editor.richText && !isAndroid && <Placeholder element={element} />}
      {dndComponents}
      {children}
    </div>
  );
}
