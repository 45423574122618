import { EventEmitter } from 'eventemitter3';
import { isArray, isEqual, sortBy, sortedIndexBy, sum, trim } from 'lodash';
import {
  atom,
  atomFamily,
  GetRecoilValue,
  selectorFamily,
  Snapshot,
  useRecoilCallback,
} from 'recoil';
import { allCollections } from '../../sync/__generated/collections';
import { allIndexes } from '../../sync/__generated/indexes';
import {
  isSyncEngineObject,
  SyncEngineCollection,
  SyncEngineCollectionMap,
  SyncEngineIndex,
  SyncEngineIndexMap,
  SyncEngineIndexValue,
  SyncEngineObject,
  SyncEngineStateUpdate,
  SyncEngineValue,
} from './types';

const emitter = new EventEmitter();

export enum StateEvents {
  Set = 'set',
  Load = 'load',
}

export interface StateSetEvent {
  objects: SyncEngineObject[];
  get: GetRecoilValue;
}

export type StateEvent = StateSetEvent;

export const highlightedDependencyState = atom<null | string>({
  key: 'highlightedDependency',
  default: null,
});

export const syncEngineState = atomFamily<SyncEngineValue | null, string>({
  key: 'syncEngine',
  default: null,
});

const INDEX_PREFIX = 'index';

export function indexesByTypeAndProperty(indexes: SyncEngineIndex[]): SyncEngineIndexMap {
  const result: SyncEngineIndexMap = { byTypeAndProperty: {}, byTypeAndSort: {} };
  for (const index of indexes) {
    result.byTypeAndProperty[index.type] = result.byTypeAndProperty[index.type] ?? {};
    result.byTypeAndProperty[index.type][index.property] = index;

    if (index.sortedBy && index.type) {
      result.byTypeAndSort[index.type] = result.byTypeAndSort[index.type] ?? {};
      result.byTypeAndSort[index.type][index.sortedBy] =
        result.byTypeAndSort[index.type][index.sortedBy] ?? [];
      result.byTypeAndSort[index.type][index.sortedBy].push(index);
    }
  }
  return result;
}

function collectionsByTypeAndName(collections: SyncEngineCollection[]): SyncEngineCollectionMap {
  const result: SyncEngineCollectionMap = {};
  for (const collection of collections) {
    result[collection.type] = result[collection.type] ?? {};
    result[collection.type][collection.name] = collection;
  }
  return result;
}

export const indexMap = indexesByTypeAndProperty(allIndexes);
export const collectionMap = collectionsByTypeAndName(allCollections);

function deindexObject(
  index: SyncEngineIndex,
  object: SyncEngineObject,
  get: (id: string) => SyncEngineValue | null
): SyncEngineStateUpdate {
  const propertyValue: string | string[] | undefined | null = (object as any)[index.property];
  if (!propertyValue) {
    return {};
  }

  const updates: SyncEngineStateUpdate = {};
  function updateIndex(id: string) {
    const indexKey = `${INDEX_PREFIX}:${index.name}:${id}`;
    const existing =
      updates[indexKey] ?? get(indexKey)
        ? ((updates[indexKey] ?? get(indexKey)) as SyncEngineIndexValue[])
        : [];
    updates[indexKey] = existing.filter(v => v.value !== object.id);
  }

  if (isArray(propertyValue)) {
    for (const id of propertyValue) {
      updateIndex(id);
    }
  } else {
    updateIndex(propertyValue);
  }

  return updates;
}

function indexObject(
  index: SyncEngineIndex,
  object: SyncEngineObject,
  get: (id: string) => SyncEngineValue | null
): SyncEngineStateUpdate {
  const propertyValue: string | string[] | undefined | null = (object as any)[index.property];
  if (!propertyValue) {
    return {};
  }

  const sortValue = index.sortedBy ? (object as any)[index.sortedBy] : null;
  const updates: SyncEngineStateUpdate = {};

  function updateIndex(id: string) {
    const indexKey = `${INDEX_PREFIX}:${index.name}:${id}`;
    const existing =
      updates[indexKey] ?? get(indexKey)
        ? ((updates[indexKey] ?? get(indexKey)) as SyncEngineIndexValue[])
        : [];
    const existingFiltered = existing.filter(v => v.value !== object.id);

    if (sortValue) {
      const toInsert = { value: object.id, sortKey: sortValue };
      const indexToInsertAt = sortedIndexBy(existingFiltered, toInsert, v =>
        index.sortReversed ? -v.sortKey! : v.sortKey
      );
      const updated = [...existingFiltered];
      updated.splice(indexToInsertAt, 0, toInsert);

      updates[indexKey] = updated;
    } else {
      updates[indexKey] = [...existingFiltered, { value: object.id }];
    }
  }
  if (isArray(propertyValue)) {
    for (const id of propertyValue) {
      updateIndex(id);
    }
  } else {
    updateIndex(propertyValue);
  }

  return updates;
}

function updateIndexSort(
  index: SyncEngineIndex,
  object: SyncEngineObject,
  get: (id: string) => SyncEngineValue | null
): SyncEngineStateUpdate {
  const propertyValue: string | string[] | undefined | null = (object as any)[index.property];
  if (!propertyValue) {
    return {};
  }

  const sortValue = (object as any)[index.sortedBy!];
  const updates: SyncEngineStateUpdate = {};

  function updateIndex(id: string) {
    const indexKey = `${INDEX_PREFIX}:${index.name}:${id}`;
    const existing =
      updates[indexKey] ?? get(indexKey)
        ? ((updates[indexKey] ?? get(indexKey)) as SyncEngineIndexValue[])
        : [];
    const existingFiltered = existing.filter(v => v.value !== object.id);
    const toInsert = { value: object.id, sortKey: sortValue };
    const indexToInsertAt = sortedIndexBy(existingFiltered, toInsert, v =>
      index.sortReversed ? -v.sortKey! : v.sortKey
    );
    const updated = [...existingFiltered];
    updated.splice(indexToInsertAt, 0, toInsert);
    updates[indexKey] = updated;
  }
  if (isArray(propertyValue)) {
    for (const id of propertyValue) {
      updateIndex(id);
    }
  } else {
    updateIndex(propertyValue);
  }

  return updates;
}

export function setData(
  objects: SyncEngineObject[],
  indexes: SyncEngineIndexMap,
  get: (id: string) => SyncEngineValue | null,
  set: (id: string, value: SyncEngineValue | null) => void
) {
  for (const obj of objects) {
    // FIXME any here is super gross
    const previouseObj = get(obj.id) as any | null;
    const indexesByType = indexes.byTypeAndProperty[obj.__typename] ?? {};
    const sortIndexesToUpdate = indexes.byTypeAndSort[obj.__typename] ?? {};
    const deindexedIndexes = new Set<string>();

    if (previouseObj) {
      // if a property on which this object is indexed has changed, remove it from the index to
      // be added back later

      for (const property in indexesByType) {
        if (isEqual(previouseObj[property], (obj as any)[property])) {
          continue;
        }
        const index = indexesByType[property];
        const updatesFromDeindexing = deindexObject(index, previouseObj, get);
        for (const id in updatesFromDeindexing) {
          set(id, updatesFromDeindexing[id]);
        }
        deindexedIndexes.add(index.name);
      }

      // if a property on which this object is sorted in an index has changed, update the sorting of
      // the index
      for (const property in sortIndexesToUpdate) {
        if (isEqual(previouseObj[property], (obj as any)[property])) {
          continue;
        }

        for (const index of sortIndexesToUpdate[property]) {
          // if this object has been deindexed for the time being, no reason to update the sort. It'll get
          // fixed when we index it below.
          if (deindexedIndexes.has(index.name)) {
            continue;
          }
          const updatesFromReindexing = updateIndexSort(index, obj, get);
          for (const id in updatesFromReindexing) {
            set(id, updatesFromReindexing[id]);
          }
        }
      }
    }

    // index this object
    for (const index of Object.values(indexesByType)) {
      if (previouseObj && !deindexedIndexes.has(index.name)) {
        continue;
      }
      const updatesFromIdexing = indexObject(index, obj, get);
      for (const id in updatesFromIdexing) {
        set(id, updatesFromIdexing[id]);
      }
    }
    set(obj.id, obj);
  }
}

function allKeys(snapshot: Snapshot) {
  const nodes = Array.from(snapshot.getNodes_UNSTABLE());
  return nodes
    .map(n => n.key)
    .filter(key => key.startsWith('syncEngine__'))
    .map(key => trim(key.replace('syncEngine__', ''), '"'));
}

export interface StateTransaction {
  get: <T extends SyncEngineObject>(id: string) => T | null;
  getIndex: (index: SyncEngineIndex, id: string) => string[];
  set: (objects: SyncEngineObject[]) => void;
  load: (data: Record<string, SyncEngineValue>) => void;
  del: (id: string) => void;
  clear: () => void;
  dumpState: () => Record<string, SyncEngineValue>;
}

export function useStateTransaction() {
  return useRecoilCallback(
    ({ transact_UNSTABLE, snapshot }) =>
      (callback: (state: StateTransaction) => void) => {
        transact_UNSTABLE(({ get, set, reset }) => {
          callback({
            get: <T extends SyncEngineObject>(id: string) => {
              const value = get(syncEngineState(id));
              if (!value || !isSyncEngineObject(value)) {
                return null;
              }
              return value as T;
            },
            getIndex: (index: SyncEngineIndex, id: string) => {
              const indexValues = get(syncEngineState(indexKey(index, id))) as
                | SyncEngineIndexValue[]
                | null;
              return (indexValues ?? []).map(i => i.value);
            },
            set: objects => {
              setData(
                objects,
                indexMap,
                id => get(syncEngineState(id)),
                (id, value) => set(syncEngineState(id), value)
              );
              emitter.emit(StateEvents.Set, { objects, get });
            },
            load: data => {
              for (const id in data) {
                set(syncEngineState(id), data[id]);
              }
              const objects = Object.values(data).filter(o => isSyncEngineObject(o));
              emitter.emit(StateEvents.Load, {
                objects,
              });
            },
            del: id => {
              reset(syncEngineState(id));
            },
            clear: () => {
              const keys = allKeys(snapshot);
              for (const key of keys) {
                reset(syncEngineState(key));
              }
            },
            dumpState: () => {
              const keys = allKeys(snapshot);
              const result: Record<string, SyncEngineValue> = {};
              for (const key of keys) {
                const value = get(syncEngineState(key));
                if (value) {
                  result[key] = value;
                }
              }
              return result;
            },
          });
        });
      }
  );
}

export function indexKey(index: SyncEngineIndex, id: string) {
  return `${INDEX_PREFIX}:${index.name}:${id}`;
}

export const indexKeyState = selectorFamily({
  key: 'Indexes',
  get:
    (key: string) =>
    ({ get }) => {
      const indexValues = get(syncEngineState(key)) as SyncEngineIndexValue[] | null;
      return (indexValues ?? []).map(i => i.value);
    },
});

export function onStateEvent(event: StateEvents, callback: (e: StateEvent) => void) {
  emitter.on(event, callback);
}

export function offStateEvent(event: StateEvents, callback: (e: StateEvent) => void) {
  emitter.off(event, callback);
}

export function calculateRecoilStats(state: Record<string, SyncEngineValue>) {
  const indexes: Record<string, number> = {};
  const objects: Record<string, number> = {};

  for (const key in state) {
    if (key.startsWith(INDEX_PREFIX)) {
      const [, type] = key.split(':');
      indexes[type] = indexes[type] ?? 0;
      indexes[type] += 1;
    } else {
      const object = state[key] as SyncEngineObject;
      const type = object.__typename ?? 'UNKNOWN';
      objects[type] = objects[type] ?? 0;
      objects[type] += 1;
    }
  }

  return {
    indexes: {
      total: sum(Object.values(indexes)),
      byCount: sortBy(Object.entries(indexes), ([_key, count]) => -count),
    },
    objects: {
      total: sum(Object.values(objects)),
      byCount: sortBy(Object.entries(objects), ([_key, count]) => -count),
    },
  };
}
