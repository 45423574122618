export const LINEAR_LINK_REGEX =
  /^(?:https:\/\/)?linear\.app\/([\w-]+)\/issue\/(\w+)-(\d+)(?:\/.*)$/;

// info we feed the frontend to be able to create an external issue
export interface ExternalIssueCreationContext {
  integrationTerm: string;
  assigneeTerm: string;
  projectTerm: string;
  statusTerm?: string;

  integrations: Array<{
    id: string;
    name: string;

    users: Array<{
      id: string;
      displayName: string;
      avatarUrl?: string;
    }>;

    // projects are like teams in Linear, repos in GitHub, projects in Jira
    projects: Array<{
      id: string;
      name: string;

      statuses?: Array<{
        id: string;
        name: string;
        color?: string;
        icon?: string;
      }>;
    }>;
  }>;
}

export interface CreateExternalIssueRequest {
  integrationId: string;
  title: string;
  description: string;
  projectId: string;
  statusId?: string | null;
  assigneeId?: string | null;
}
