import cn from 'classnames';
import { findLast } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Update as UpdateModel } from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { Breadcrumbs } from '../../../components/new/breadcrumbs';
import { Button, ButtonStyle } from '../../../components/new/button';
import { CustomCommand } from '../../../components/new/customCommand';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  KeyNavigationProvider,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useKeyNavigationElement,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import Placeholder from '../../../components/new/placeholder';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { ScrollToLoadMore } from '../../../components/new/scrollToLoadMore';
import { Tooltip } from '../../../components/new/tooltip';
import { Update } from '../../../components/new/update';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useOrganization } from '../../../contexts/organizationContext';
import { OrganizationMarker } from '../../../graphql/smartLoad';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useIsTinyScreen } from '../../../hooks/useResponsiveDesign';
import { useMarkUpdatesRead } from '../../../syncEngine/actions/updates';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import {
  unreadUpdatesForCurrentUserSelector,
  updatesForCurrentUserSelector,
} from '../../../syncEngine/selectors/updates';
import { markAllNotificationsReadKey } from '../../../utils/config';
import { LocationState, silentlyUpdateHistoryState } from '../../../utils/history';
import LoadingScreen from '../../loadingScreen';
import styles from './updatesScreen.module.scss';

enum Timespan {
  Today = 'Today',
  ThisWeek = 'This week',
  Earlier = 'Earlier',
}

function isUpdate(update: UpdateModel | Timespan): update is UpdateModel {
  return (update as UpdateModel)?.__typename === 'Update';
}

function sortUpdates(updates: UpdateModel[]): Array<UpdateModel | Timespan> {
  const result: Array<UpdateModel | Timespan> = [];
  let lastTimespan: Timespan | null = null;

  const now = moment();

  for (const update of updates) {
    const then = moment(update.createdAt);
    const daysDiff = now.diff(then, 'days');
    const isToday = daysDiff === 0 && now.dayOfYear() === then.dayOfYear();
    const isThisWeek = daysDiff < 7 && now.week() === then.week();

    if (isToday) {
      if (lastTimespan !== Timespan.Today) {
        result.push(Timespan.Today);
        lastTimespan = Timespan.Today;
      }
      result.push(update);
    } else if (isThisWeek) {
      if (lastTimespan !== Timespan.ThisWeek) {
        result.push(Timespan.ThisWeek);
        lastTimespan = Timespan.ThisWeek;
      }
      result.push(update);
    } else {
      if (lastTimespan !== Timespan.Earlier) {
        result.push(Timespan.Earlier);
        lastTimespan = Timespan.Earlier;
      }
      result.push(update);
    }
  }

  return result;
}

function SectionHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="listHeaderContainer">
      <div className={cn('listHeader', styles.updateListItemHeader)}>{children}</div>
    </div>
  );
}

function UpdatesPlaceholder() {
  return (
    <Placeholder icon={'updates'} title={'No updates available'}>
      <span className="grayed">We'll show you relevent updates and notifications here.</span>
    </Placeholder>
  );
}

function UpdateListItem({
  update,
  isFirst,
  isLast,
}: {
  update: UpdateModel;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const focused = useHasKeyNavigationFocus(update.id);
  useKeyNavigationElement(update.id, ref);

  return (
    <>
      <Update
        update={update}
        ref={ref}
        hotkeys={focused}
        className={cn(styles.updateListItem, {
          first: isFirst,
          last: isLast,
        })}
        contentClassName={cn({
          [styles.unread]: update.notification && !update.read,
        })}
        showUnreadIndicator
      />
    </>
  );
}

function UpdatesScreenContent({ focusedElementId }: { focusedElementId?: string }) {
  const organization = useOrganization();
  const updates = useRecoilValue(updatesForCurrentUserSelector(organization.id));
  const unreadUpdates = useRecoilValue(unreadUpdatesForCurrentUserSelector(organization.id));
  const markUpdatesRead = useMarkUpdatesRead();
  const setFocus = useSetKeyNavigationFocus();
  const tinyScreen = useIsTinyScreen();
  const ref = React.useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    ref.current?.focus();
  });

  const sortedUpdates: Array<UpdateModel | Timespan> = React.useMemo(
    () => sortUpdates(updates),
    [updates]
  );

  const [offset, setOffset] = React.useState(() => {
    const focusedIdIndex = focusedElementId
      ? sortedUpdates.findIndex(u => isUpdate(u) && u.id === focusedElementId)
      : -1;

    if (focusedIdIndex !== -1) {
      return Math.max(20, focusedIdIndex + 10);
    }

    return 20;
  });

  const [updatesToRender, setUpdatesToRender] = React.useState(sortedUpdates.slice(0, offset));
  const deferredUpdates = React.useDeferredValue(updatesToRender);
  useKeyNavigationColumn(
    'updates',
    updates.map(u => u.id),
    ref
  );

  React.useEffect(() => {
    setUpdatesToRender(sortedUpdates.slice(0, offset));
  }, [sortedUpdates, offset]);

  const renderedUpdates = deferredUpdates.map((u, index) => {
    if (isUpdate(u)) {
      return (
        <UpdateListItem
          key={u.id}
          update={u}
          isFirst={index > 0 && !isUpdate(sortedUpdates[index - 1])}
          isLast={index === sortedUpdates.length - 1 || !isUpdate(sortedUpdates[index + 1])}
        />
      );
    }

    return <SectionHeader key={u}>{u}</SectionHeader>;
  });

  const maybeLastUpdate: UpdateModel | undefined = findLast(
    deferredUpdates,
    (u: UpdateModel | Timespan) => isUpdate(u)
  ) as UpdateModel | undefined;

  return (
    <>
      <ScreenHeader
        showSidebarOpener
        compensateForMacOSTrafficLights="auto"
        rightSideContent={
          <Tooltip content={<KeyboardShortcut shortcut={markAllNotificationsReadKey} />}>
            <Button
              disabled={unreadUpdates.length === 0}
              className="mr8"
              buttonStyle={ButtonStyle.Secondary}
              icon={'select_checkmark'}
              onClick={() =>
                markUpdatesRead(
                  updates.map(update => update.id),
                  true
                )
              }
            >
              {tinyScreen ? 'Mark all read' : 'Mark all updates as read'}
            </Button>
          </Tooltip>
        }
      >
        <Breadcrumbs breadcrumbs={[{ name: 'Updates' }]} />
      </ScreenHeader>

      <CustomCommand
        command={{
          id: 'mark-all-updates-read',
          hotkey: markAllNotificationsReadKey,
          // TODO: Find good gruop for this
          group: CommandGroup.Other,
          description: `Mark all updates as read`,
          priority: 100,
          handler: () => {
            markUpdatesRead(
              updates.map(u => u.id),
              true
            );
          },
        }}
      />
      {updates.length === 0 && <UpdatesPlaceholder />}

      {updates.length !== 0 && (
        <div className="overflowScrollY grow" ref={ref} tabIndex={-1}>
          {renderedUpdates}
          {offset < sortedUpdates.length && (
            <ScrollToLoadMore
              watchForId={maybeLastUpdate?.id ?? ''}
              onLoadMore={() => {
                if (maybeLastUpdate) {
                  setFocus(maybeLastUpdate.id);
                }
                setOffset(previous => Math.min(previous + 20, sortedUpdates.length));
              }}
              key={`loadMore-${deferredUpdates.length}`}
            />
          )}
        </div>
      )}
    </>
  );
}

export function UpdatesScreen() {
  const location = useLocation<LocationState>();
  const organization = useOrganization();
  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  const focusedElementId = React.useMemo(() => {
    if (location.state?.update) {
      const { update, ...rest } = location.state;
      silentlyUpdateHistoryState(rest);
      return update;
    }

    return undefined;
  }, [location.state]);

  if (!currentOrgFullyFetched) {
    return <LoadingScreen />;
  }

  return (
    <KeyNavigationProvider
      initiallyFocusedElementId={focusedElementId}
      columnIds={['updates']}
      ensureVisibleOptions={{ blockMode: 'center' }}
    >
      <Screen>
        <TitleSetter title={`${organization.name} · Updates`} />
        <UpdatesScreenContent focusedElementId={focusedElementId} />
      </Screen>
    </KeyNavigationProvider>
  );
}
