import React from 'react';
import { useRecoilValue } from 'recoil';
import { Issue, IssueStatusType, Space } from '../../../../sync/__generated/models';
import { useSpace } from '../../../contexts/spaceContext';
import { statusesInBoardOrderSelector } from '../../../syncEngine/selectors/boards';
import { isDefaultStatus } from '../../../syncEngine/selectors/issueStatuses';
import { archiveIssueKey, closeIssueKey } from '../../../utils/config';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import styles from '../menu/menu.module.scss';
import { Picker, PickerState } from '../picker';
import { statusToIcon } from '../statusIcon';

export function entitiesToStatusColumnPickerState(entities: Array<Issue>): PickerState {
  return entities.reduce((result, issue) => {
    result[issue.id] = [issue.statusId];
    return result;
  }, {} as PickerState);
}

export function StatusColumnPicker({
  state,
  onDone,
  onPicked,
  filterClassName,
  space: propSpace,
}: {
  state: PickerState;
  onPicked: (issueIds: string[], statusId: string) => void;
  onDone: () => void;
  filterClassName?: string;
  space?: Space | null;
}) {
  const contextSpace = useSpace();
  const space = propSpace ?? contextSpace;
  const sortedStatuses = useRecoilValue(statusesInBoardOrderSelector({ spaceId: space.id }));
  const items: ListViewItem[] = sortedStatuses.map(status => {
    let hotkey;
    const def = isDefaultStatus(space, status);
    const icon = statusToIcon(status.statusType, def);

    if (status.statusType === IssueStatusType.Archived) {
      hotkey = archiveIssueKey;
    } else if (status.statusType === IssueStatusType.Done && def) {
      hotkey = closeIssueKey;
    }
    return {
      ...status,
      contents: () => (
        <>
          <Icon size={IconSize.Size20} icon={icon} />
          <span className={styles.text}>{status.name}</span>
        </>
      ),
      hotkey,
    };
  });

  const onAdd = React.useCallback(
    (issueIds: string[], statusId: string) => {
      onPicked(issueIds, statusId);
      onDone();
    },
    [onDone, onPicked]
  );

  return (
    <Picker
      noSort
      filterLabel="Change status"
      filterPlaceholder="Change status"
      state={state}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onAdd}
      onRemove={onDone}
      onDone={onDone}
      filterClassName={filterClassName}
    />
  );
}
