import cn from 'classnames';
import { range } from 'lodash';
import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { NumberedElement } from '../../../shared/slate/types';
import { isSafari } from '../../utils/config';
import { useInteractivityDisabled } from '../hooks/useInteractivityDisabled';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './numbered.module.scss';

export function StaticNumbered({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: NumberedElement }) {
  const childNode = range(element.indent).reduce(
    result => <ol start={(element.value ?? 0) + 1}>{result}</ol>,
    <li>
      <div>{children}</div>
    </li>
  );

  return (
    <ol
      start={(element.value ?? 0) + 1}
      className={cn('block', 'listBlock', styles.numbered, {
        [styles.safari]: isSafari,
        [styles.indent0]: !element.indent,
        [styles.indent1]: element.indent === 1,
        [styles.indent2]: element.indent === 2,
        [styles.indent3]: element.indent === 3,
        [styles.indent4]: element.indent === 4,
        [styles.indent5]: element.indent === 5,
        [styles.indent6]: element.indent === 6,
      })}
      {...attributes}
    >
      {childNode}
    </ol>
  );
}

export function Numbered({
  element,
  attributes,
  children,
}: RenderElementProps & { element: NumberedElement }) {
  const interactivityDisabled = useInteractivityDisabled();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop({ smallPadding: true });

  if (interactivityDisabled) {
    return (
      <StaticNumbered {...attributes} element={element}>
        {children}
      </StaticNumbered>
    );
  }
  const childNode = range(element.indent).reduce(
    result => <ol>{result}</ol>,
    <li>
      <div className="grow">{children}</div>
    </li>
  );

  return (
    <ol
      {...attributes}
      {...dndAttributes}
      className={cn(
        'block',
        'listBlock',
        styles.numbered,
        {
          [styles.safari]: isSafari,
          [styles.indent0]: !element.indent,
          [styles.indent1]: element.indent === 1,
          [styles.indent2]: element.indent === 2,
          [styles.indent3]: element.indent === 3,
          [styles.indent4]: element.indent === 4,
          [styles.indent5]: element.indent === 5,
          [styles.indent6]: element.indent === 6,
        },
        dndClassName
      )}
    >
      {dndComponents}
      {childNode}
    </ol>
  );
}
