export interface GithubLink {
  type: IssueOrPullRequest;
  owner: string;
  repo: string;
  number: number;
}

// TODO: This could take the base URL from the GH integration config
export function parseGithubLink(link?: string): GithubLink | null {
  const regex = '^((?:http|https)://(?:[^/]+))/([^/]+)/([^/]+)/(issues|pull)/([0-9]+)(?:/?)$';
  if (!link) {
    return null;
  }

  const groups = link.match(regex);
  if (groups?.length != 6) {
    return null;
  }

  let type: IssueOrPullRequest | null = null;
  if (groups[4].toLowerCase() === 'issues') {
    type = 'issue';
  } else if (groups[4].toLowerCase() === 'pull') {
    type = 'pull';
  }

  if (!type) {
    return null;
  }

  return {
    owner: groups[2],
    repo: groups[3],
    number: Number(groups[5]),
    type,
  };
}

export type IssueOrPullRequest = 'issue' | 'pull';
export interface GithubEntityInfo {
  type: IssueOrPullRequest;
  title: string;
  state: string;
  number: number;
  url: string;

  createdAt: string; // created_at
  updatedAt: string; // updated_at

  // .user
  user: {
    login: string;
    url: string;
    avatarUrl: string; // avatar_url
  };
}

export interface GithubIssueInfo extends GithubEntityInfo {
  type: 'issue';
}

export function isGithubIssue(info: GithubEntityInfo): info is GithubIssueInfo {
  return info.type === 'issue';
}

export interface GithubPullRequestInfo extends GithubEntityInfo {
  type: 'pull';
  merged: boolean;
  mergedAt?: string; // merged_at
}

export function isGithubPullRequest(info: GithubEntityInfo): info is GithubPullRequestInfo {
  return info.type === 'pull';
}
