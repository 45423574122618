import { kebabCase, trimEnd } from 'lodash';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../sync/__generated/models';
import { useOrganization } from '../contexts/organizationContext';
import { useCurrentUser } from '../contexts/userContext';
import { spaceForEntitySelector } from '../syncEngine/selectors/entities';
import { issueNumberSelector, issueTitleSelector } from '../syncEngine/selectors/issues';
import { integrationsForOrganizationSelector } from '../syncEngine/selectors/organizations';

export function useGitBranchName(issueId?: string) {
  const organization = useOrganization();
  const integrations = useRecoilValue(integrationsForOrganizationSelector(organization.id));
  const integration = integrations.find(
    i => i.type === IntegrationType.Github || i.type === IntegrationType.Gitlab
  );
  const space = useRecoilValue(spaceForEntitySelector(issueId));
  const issueTitle = useRecoilValue(issueTitleSelector(issueId));
  const issueNumber = useRecoilValue(issueNumberSelector(issueId));
  const user = useCurrentUser();

  if (!space || !issueTitle || !issueNumber || !user || !integration) {
    return null;
  }

  const title = kebabCase(issueTitle);
  const lastHyphen = title.indexOf('-', 50);

  return trimEnd(
    `${user.username}/${space?.key}-${issueNumber}-${title}`.substring(
      0,
      lastHyphen > 0 ? lastHyphen : undefined
    ),
    '-'
  ).toLowerCase();
}
