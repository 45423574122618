import React from 'react';
import { Icon, IconSize } from '../icon';
import Pill from './pill';
import { MetadataSize } from './size';

export default function Spaces({
  names,
  size = MetadataSize.Small,
  noHover,
  privateSpace,
  className,
}: {
  names: string[];
  size?: MetadataSize;
  noHover?: boolean;
  privateSpace?: boolean;
  className?: string;
}) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;

  return (
    <Pill noHover={noHover} className={className} size={size}>
      <Icon size={iconSize} icon={privateSpace ? 'private' : 'workspace'} />
      <span>{names.join(', ')}</span>
    </Pill>
  );
}
