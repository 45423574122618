import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { RenderElementProps } from 'slate-react';
import { ExternalIssueLinkElement } from '../../../shared/slate/types';
import { ExternalIssue, IntegrationType } from '../../../sync/__generated/models';
import { BlinkingDots } from '../../components/blinkingDots';
import { IntegrationIcon } from '../../components/new/integrationIcon';
import AvatarGroup from '../../components/new/metadata/avatarGroup';
import { Tooltip } from '../../components/new/tooltip';
import { externalIssueSelector } from '../../syncEngine/selectors/externalIssues';
import { renderDate } from '../../utils/datetime';
import { stringifyIntegrationType } from '../../utils/integrations';
import { openExternalUrl } from '../../utils/urls';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './externalIssueLink.module.scss';
import { VoidBlock } from './voidBlock';

function RenderExternalIssue({ externalIssue }: { externalIssue: ExternalIssue | null }) {
  if (!externalIssue) {
    return <div className="grayed">Issue not found</div>;
  }

  return (
    <div className="rowBetween grow overflowHidden">
      <div className="row grow mr8 gap8 overflowHidden">
        <div className="finePrint mt1">{externalIssue.number}</div>
        {!externalIssue.fauxDeleted && (
          <>
            {externalIssue.statusDetails?.icon && (
              <Tooltip content={externalIssue.statusDetails.name}>
                <span
                  className={styles.statusIcon}
                  style={{
                    stroke: externalIssue.statusDetails.color ?? `var(--foreground-color)`,
                  }}
                  dangerouslySetInnerHTML={{ __html: externalIssue.statusDetails.icon }}
                ></span>
              </Tooltip>
            )}
            <div className="grow ellipsis oneLine noMinWidth bodyM">{externalIssue.title}</div>
          </>
        )}
        {externalIssue.fauxDeleted && (
          <div className="grayed">
            This issue has been deleted in {stringifyIntegrationType(externalIssue.type)}
          </div>
        )}
      </div>
      {!externalIssue.fauxDeleted && (
        <div className="row gap8">
          {externalIssue.assignees.length > 0 && (
            <AvatarGroup
              max={2}
              avatarData={externalIssue.assignees.map(assignee => ({
                ...assignee,
                img: assignee.avatarUrl,
              }))}
            />
          )}
          <div className="finePrint mt1 noShrink">
            {capitalize(
              renderDate(externalIssue.updatedAt, {
                compact: true,
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function StaticExternalIssueLink({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: ExternalIssueLinkElement }) {
  const { externalIssueId, externalIssueType } = element;
  const type = externalIssueType.toUpperCase() as IntegrationType;
  const externalIssue = useRecoilValue(externalIssueSelector(externalIssueId));
  const loading = !externalIssueId || !externalIssue;

  return (
    <div
      className="block row fullWidth unselectable overflowHidden"
      {...attributes}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (externalIssue) {
          openExternalUrl(externalIssue.url);
        }
      }}
    >
      <div className={styles.externalIssueLink}>
        <IntegrationIcon integrationType={type} className="mr8" />
        {loading && (
          <>
            <span className="grayed mr8">Loading issue from {stringifyIntegrationType(type)}</span>
            <BlinkingDots className={styles.loading} />
          </>
        )}
        {!loading && <RenderExternalIssue externalIssue={externalIssue} />}
      </div>
      {children}
    </div>
  );
}

export function ExternalIssueLink({
  attributes,
  element,
  children,
}: RenderElementProps & { element: ExternalIssueLinkElement }) {
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();
  const ref = React.useRef<HTMLDivElement>(null);
  const { externalIssueId, externalIssueType } = element;
  const type = externalIssueType.toUpperCase() as IntegrationType;
  const externalIssue = useRecoilValue(externalIssueSelector(externalIssueId));

  React.useEffect(() => {
    if (selected && selectionCollapsed) {
      setTimeout(() => {
        if (!ref.current) {
          return;
        }
        scrollIntoView(ref.current, {
          block: 'center',
          scrollMode: 'if-needed',
          behavior: 'auto',
        });
      });
    }
  }, [selected, selectionCollapsed]);

  const loading = !externalIssueId || !externalIssue;

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <VoidBlock
        element={element}
        className="row fullWidth unselectable overflowHidden"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (externalIssue) {
            openExternalUrl(externalIssue.url);
          }
        }}
      >
        <div className={styles.externalIssueLink}>
          <IntegrationIcon integrationType={type} className="mr8" />
          {loading && (
            <>
              <span className="grayed mr8">
                Loading issue from {stringifyIntegrationType(type)}
              </span>
              <BlinkingDots className={styles.loading} />
            </>
          )}
          {!loading && <RenderExternalIssue externalIssue={externalIssue} />}
        </div>
      </VoidBlock>
    </div>
  );
}
