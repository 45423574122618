import type EventEmitter from 'eventemitter3';
import type { BaseEditor, Operation, Point } from 'slate';
import { BaseRange, Descendant } from 'slate';
import type { ReactEditor } from 'slate-react';
import { IntegrationType } from '../../sync/__generated/models';
import { GithubEntityInfo, GithubLink } from '../utils/github';
import { HistoryEditor } from './plugins/withHistory';

export type DocumentLike = Descendant[];

export enum Marks {
  Bold = 'bold',
  Underline = 'underline',
  Italic = 'italic',
  Strikethrough = 'strikethrough',
  Code = 'code',
  Color = 'color',
}

export enum Elements {
  Paragraph = 'paragraph',
  Headline1 = 'headline1',
  Headline2 = 'headline2',
  Headline3 = 'headline3',
  BlockQuote = 'blockQuote',
  Bulleted = 'bulleted',
  Numbered = 'numbered',
  Todo = 'todo',
  SmartTodo = 'smartTodo',
  Code = 'code',
  Image = 'image',
  Video = 'video',
  File = 'file',
  Figma = 'figma',
  Github = 'github',
  Loom = 'loom',
  MathExpression = 'mathExpression',
  Line = 'line',
  Giphy = 'giphy',
  Chat = 'chat',
  ChatMessage = 'chatMessage',
  AIPlaceholder = 'aiPlaceholder',
  ExternalIssueLink = 'externalIssueLink',

  // inlines
  Link = 'link',
  User = 'user',
  Group = 'group',
  Issue = 'issue',
  Entity = 'entity',
  TodoMention = 'todoMention',
  Label = 'label',
  Math = 'math',
  Insight = 'insight',
  Comment = 'comment',
  CustomEmoji = 'customEmoji',
  InlineExternalIssueLink = 'inlineExternalIssueLink',
}

export type VoidElement = {
  insightId?: string;
  // FIXME: we should deprecate the old insightIds and use this new structure once we're
  // happy with how inline comments works.
  annotations?: Array<{ type: 'insight' | 'comment'; id: string }>;
};

export enum GroupType {
  Members = 'members',
  Space = 'space',
  Organization = 'organization',
}

export function isGroupType(input: string): input is GroupType {
  return Object.values(GroupType).includes(input as GroupType);
}

export enum FormatHoverMode {
  Normal,
  Link,
  Insight,
  Comment,
  AISuggestion,
  Color,
}

export type FormattedText = {
  text: string;
  [Marks.Bold]?: boolean;
  [Marks.Italic]?: boolean;
  [Marks.Underline]?: boolean;
  [Marks.Strikethrough]?: boolean;
  [Marks.Code]?: boolean;
  [Marks.Color]?: string;
  emojiFont?: boolean;
  bigEmoji?: boolean;
  syntaxType?: string;
  colorPreview?: string;
  loomLink?: boolean;
  figmaLink?: boolean;
  githubLink?: boolean;
  externalIssueLink?: boolean;
  cursorLocation?: {
    enabled: boolean;
    actorId: string;
    time: number;
    empty: boolean;
    clientId: string;
  };
  fakeSelection?: boolean;
};

export type EmptyText = {
  text: string;
};

export type CustomText = FormattedText;

export interface ParagraphElement {
  type: Elements.Paragraph;
  children: CustomText[];
}

export interface Headline1Element {
  type: Elements.Headline1;
  children: CustomText[];
}

export interface Headline2Element {
  type: Elements.Headline2;
  children: CustomText[];
}

export interface Headline3Element {
  type: Elements.Headline3;
  children: CustomText[];
}

export interface BlockQuoteElement {
  type: Elements.BlockQuote;
  children: CustomText[];
}

export interface BulletedElement {
  type: Elements.Bulleted;
  indent: number;
  children: CustomText[];
}

export interface TodoElement {
  type: Elements.Todo;
  indent: number;
  checked: boolean;
  children: CustomText[];
}

export interface SmartTodoElement {
  type: Elements.SmartTodo;
  todoId: string;
  indent: number;
  children: CustomText[];
}

export interface NumberedElement {
  type: Elements.Numbered;
  indent: number;
  value?: number;
  children: CustomText[];
}

export interface CodeElement {
  type: Elements.Code;
  language: string | null;
  children: CustomText[];
}

export interface ChatMessageElement {
  type: Elements.ChatMessage;
  direction: 'incoming' | 'outgoing';
  children: CustomElement[];
  sender: string;
  timestamp: number;
  chatMessageId: string;
}

export interface ChatElement {
  type: Elements.Chat;
  chatId: string;
  url?: string;
  integrationType?: IntegrationType;
  undeletable?: boolean;
  children: ChatMessageElement[];
}

export interface ImageElement extends VoidElement {
  type: Elements.Image;
  url?: string;
  width?: number;
  name?: string;
  size?: number;
  children: EmptyText[];
}

export interface VideoElement extends VoidElement {
  type: Elements.Video;
  url?: string;
  name?: string;
  size?: number;
  children: EmptyText[];
}

export interface FileElement extends VoidElement {
  type: Elements.File;
  url?: string;
  size?: number;
  name?: string;
  children: EmptyText[];
}

export interface FigmaElement extends VoidElement {
  type: Elements.Figma;
  url?: string;
  thumbnailId?: string;
  refId?: string;
  children: EmptyText[];
}

export interface GithubElement extends VoidElement {
  type: Elements.Github;
  url?: string;
  refId?: string;
  prepopulatedData?: GithubLink & GithubEntityInfo;
  children: EmptyText[];
}

export interface LoomElement extends VoidElement {
  type: Elements.Loom;
  url?: string;
  children: EmptyText[];
}

export interface MathExpressionElement extends VoidElement {
  type: Elements.MathExpression;
  katex: string | null;
  children: EmptyText[];
}

export interface LineElement extends VoidElement {
  type: Elements.Line;
  children: EmptyText[];
}

export interface AIPlaceholderElement extends VoidElement {
  type: Elements.AIPlaceholder;
  clientId: string;
  operation: 'todo';
  context: {
    content: string;
    url: string;
  };
  children: EmptyText[];
}

export interface LinkElement {
  type: Elements.Link;
  children: FormattedText[];
  url?: string;
  fromHover?: boolean;
}

export interface UserElement extends VoidElement {
  type: Elements.User;
  userId: string;
  mentionId: string;
  actorId?: string;
  children: EmptyText[];
}

export interface GroupElement extends VoidElement {
  type: Elements.Group;
  groupType: GroupType; // TODO: Possible extensions: Assignees, Watchers
  groupId: string;
  mentionId: string;
  actorId?: string;
  children: EmptyText[];
}

export interface IssueElement extends VoidElement {
  type: Elements.Issue;
  issueId: string;
  issueNumber?: string;
  spaceId: string;
  mentionId: string;
  actorId?: string;
  children: EmptyText[];
}

export interface EntityElement extends VoidElement {
  type: Elements.Entity;
  entityId: string;
  mentionId: string;
  actorId?: string;
  children: EmptyText[];
}

export interface TodoMentionElement extends VoidElement {
  type: Elements.TodoMention;
  todoId: string;
  mentionId: string;
  actorId?: string;
  children: EmptyText[];
}

export interface LabelElement extends VoidElement {
  type: Elements.Label;
  labelId: string;
  children: EmptyText[];
}

export interface MathElement extends VoidElement {
  type: Elements.Math;
  katex: string | null;
  children: EmptyText[];
}

export interface GiphyElement extends VoidElement {
  type: Elements.Giphy;
  searchTerm: string;
  url: string;
  contributor: string;
  contributorUrl: string;
  children: EmptyText[];
}

export interface InsightElement {
  type: Elements.Insight;
  insightId: string;
  children: EmptyText[];
}

export interface CommentElement {
  type: Elements.Comment;
  commentId: string;
  entityId: string;
  children: EmptyText[];
}

export interface CustomEmojiElement extends VoidElement {
  type: Elements.CustomEmoji;
  emojiId: string;
  children: EmptyText[];
}

export type ExternalIssueType = 'linear';

export interface ExternalIssueLinkElement extends VoidElement {
  type: Elements.ExternalIssueLink;
  // wish this was a real enum but we don't have shared integration types between FE and BE
  externalIssueType: ExternalIssueType;
  externalIssueId?: string;
  refId?: string;
  children: EmptyText[];
}

export interface InlineExternalIssueLinkElement extends VoidElement {
  type: Elements.InlineExternalIssueLink;
  externalIssueType: ExternalIssueType;
  externalIssueId?: string;
  refId?: string;
  children: EmptyText[];
}

export type ListElement = BulletedElement | NumberedElement | TodoElement;
export type TextfulElement =
  | ParagraphElement
  | Headline1Element
  | Headline2Element
  | Headline3Element
  | BlockQuoteElement
  | BulletedElement
  | TodoElement
  | SmartTodoElement
  | NumberedElement
  | CodeElement;

export type CustomElement =
  | TextfulElement
  | ImageElement
  | VideoElement
  | FileElement
  | FigmaElement
  | GithubElement
  | LoomElement
  | MathExpressionElement
  | LineElement
  | AIPlaceholderElement
  | ChatMessageElement
  | ChatElement
  | LinkElement
  | UserElement
  | GroupElement
  | IssueElement
  | EntityElement
  | LabelElement
  | MathElement
  | GiphyElement
  | InsightElement
  | CommentElement
  | CustomEmojiElement
  | TodoMentionElement
  | ExternalIssueLinkElement
  | InlineExternalIssueLinkElement;

export type MentionElement =
  | UserElement
  | GroupElement
  | IssueElement
  | EntityElement
  | LabelElement
  | TodoMentionElement;

export interface BigEmojiDecorationRange extends BaseRange {
  bigEmoji: boolean;
  emojiFont: boolean;
}

export interface LoomLinkRange extends BaseRange {
  loomLink: boolean;
}

export interface GithubLinkRange extends BaseRange {
  githubLink: boolean;
}

export interface FigmaLinkRange extends BaseRange {
  figmaLink: boolean;
}

export interface ExternalIssueLinkRange extends BaseRange {
  externalIssueLink: boolean;
}

export interface CursorLocationRange extends BaseRange {
  cursorLocation: {
    enabled: boolean;
    actorId: string;
    time: number;
    empty: boolean;
    clientId: string;
  };
}

export interface FakeSelectionRange extends BaseRange {
  fakeSelection: boolean;
}

export type CustomRange =
  | BaseRange
  | BigEmojiDecorationRange
  | LoomLinkRange
  | GithubLinkRange
  | CursorLocationRange
  | FigmaLinkRange
  | ExternalIssueLinkRange
  | FakeSelectionRange;

export type EditorEvents = {
  openComment: (commentElement: CommentElement) => void;
  forceFormatHoverMode: (mode: FormatHoverMode) => void;
};

interface ConfiguredEditor {
  isHardBreak(e: React.KeyboardEvent): boolean;
  isSoftBreak(e: React.KeyboardEvent): boolean;
  isAccept(e: React.KeyboardEvent): boolean;
  applyLocal(operation: Operation): void;
  pauseOperationCollection(): void;
  resumeOperationCollection(): void;
  shutdown?(): void;
  bottomOffset?: number;
  updateCursors: (cursors: CursorLocations) => void;
  cursors(): CursorLocations;
  getColor: (actorId: string) => string;
  colorMap: { [actorId: string]: string };
  colors: string[];
  fakeSelection(): BaseRange | null;
  setFakeSelection(selection: BaseRange | null): void;
  disabled?: boolean;
  resetCollaboriativeEditor?: (contents: DocumentLike, version: number) => void;
  updateVersion?: (version: number) => void;
  version?: () => number;
  findSuggestions?: () => void;
  attachmentUploadPath?: string;
  forceFocus: () => void;
  todosDisabled?: boolean;
  smartTodosEnabled?: boolean;
  removeSmartTodos?: boolean;
  removeInvalidComments?: boolean;
  showPlaceholderOnFirstParagraph?: boolean;
  snippetsEnabled?: boolean;
  richText?: boolean;
  githubMentionsEnabled?: boolean;
  keyPressEvent?: (e: React.KeyboardEvent) => boolean;
  isFocusing?: boolean;
  entityId?: string;
  feedbackId?: string;
  inlineComments?: boolean;
  on<T extends EventEmitter.EventNames<EditorEvents>>(
    event: T,
    fn: EventEmitter.EventListener<EditorEvents, T>
  ): this;
  off<T extends EventEmitter.EventNames<EditorEvents>>(
    event: T,
    fn?: EventEmitter.EventListener<EditorEvents, T>
  ): this;
  emit<T extends EventEmitter.EventNames<EditorEvents>>(
    event: T,
    ...args: EventEmitter.EventArgs<EditorEvents, T>
  ): boolean;
  disableModals?: boolean;
}

export interface DragEditor extends BaseEditor {
  blockDragAndDropEnabled?: boolean;
  fileDragAndDropEnabled?: boolean;
  isDocumentEmpty?: boolean;
}

export type CursorLocation = Point & { time: number; clientId: string; actorId: string };
export type CursorLocations = { [clientId: string]: CursorLocation };
export type EditorType = BaseEditor & ReactEditor & HistoryEditor & ConfiguredEditor & DragEditor;

declare module 'slate' {
  interface CustomTypes {
    Editor: EditorType;
    Element: CustomElement;
    Text: CustomText;
    Range: CustomRange;
  }
}
