import React from 'react';
import { useRecoilValue } from 'recoil';
import { Issue } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { RoadmapIcon } from '../../../screens/new/newRoadmapScreen/roadmapIcon';
import { roadmapsForSpaceSelector } from '../../../syncEngine/selectors/roadmaps';
import { spacesForOrganizationSelector } from '../../../syncEngine/selectors/spaces';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import styles from '../menu/menu.module.scss';
import { Picker, PickerState } from '../picker';

export function entitiesToSpacePickerState(entities: Array<Issue>): PickerState {
  return entities.reduce((result, issue) => {
    result[issue.id] = [issue.spaceId];
    return result;
  }, {} as PickerState);
}

function RoadmapIconWrapper({ spaceId }: { spaceId: string }) {
  const roadmap = useRecoilValue(roadmapsForSpaceSelector(spaceId))[0];
  if (!roadmap) {
    return null;
  }

  return <RoadmapIcon color={roadmap.color} size={IconSize.Size16} />;
}

export function SpacePicker({
  state,
  onSpaceAdded,
  onSpaceRemoved,
  onDone,
  multi,
  showAll,
  showRoadmap,
  filterClassName,
  filterPlaceholder,
  disabledIds,
  additionalItems,
  additionalItemsFirst,
}: {
  state: PickerState;
  onSpaceAdded: (ids: string[], spaceId: string) => void;
  onSpaceRemoved: (ids: string[], spaceId: string) => void;
  onDone: () => void;
  multi?: boolean;
  showAll?: boolean;
  showRoadmap?: boolean;
  filterClassName?: string;
  filterPlaceholder?: string;
  disabledIds?: string[];
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
}) {
  const organization = useOrganization();
  const sortedSpaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const publicSpaces = sortedSpaces.filter(space => !space.private);

  const maybeSpace = useMaybeSpace();
  const isPrivate = maybeSpace?.private ?? false;

  const spacesForItems = showAll ? sortedSpaces : isPrivate ? [maybeSpace!] : publicSpaces;

  const items: ListViewItem[] = spacesForItems.map(space => {
    return {
      ...space,
      disabled: disabledIds?.includes(space.id),
      contents: () => (
        <>
          <span className={styles.text}>{space.name}</span>
          {space.private && <Icon size={IconSize.Size16} icon="private" />}
          {showRoadmap && <RoadmapIconWrapper spaceId={space.id} />}
        </>
      ),
    };
  });
  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  return (
    <Picker
      multi={multi}
      filterLabel="Change space"
      filterPlaceholder={filterPlaceholder ?? 'Change space'}
      placeholder="No spaces found"
      state={state}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onSpaceAdded}
      onRemove={onSpaceRemoved}
      onDone={onDone}
      filterClassName={filterClassName}
    />
  );
}
