import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';
import { Node, Text } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../shared/slate/kitemakerNode';
import { Icon, IconSize } from '../components/new/icon';
import { iconForIntegrationType } from '../components/new/integrationIcon';
import Pill, { PillStyle } from '../components/new/metadata/pill';
import { isEmoji } from '../utils/emoji';
import { stringifyIntegrationType } from '../utils/integrations';
import { hexColorRegex, rgbaColorRegex } from './decorators/colorPreview';
import { reEmoji } from './decorators/emojiDecoration';
import { StaticAIPlaceholder } from './elements/aiPlaceholder';
import { StaticBlockQuote } from './elements/blockQuote';
import { StaticBulleted } from './elements/bulleted';
import { StaticChat } from './elements/chat';
import { StaticChatMessage } from './elements/chatMessage';
import { StaticCode } from './elements/code';
import { StaticCustomEmoji } from './elements/customEmoji';
import { DummyNode } from './elements/dummyNode';
import { StaticEntityMention } from './elements/entityMention';
import { StaticExternalIssueLink } from './elements/externalIssueLink';
import { StaticFigma } from './elements/figma';
import { StaticFile } from './elements/file';
import { StaticGiphy } from './elements/giphy';
import { StaticGithub } from './elements/github';
import { StaticGroupMention } from './elements/groupMention';
import { StaticHeadline1 } from './elements/headline1';
import { StaticHeadline2 } from './elements/headline2';
import { StaticHeadline3 } from './elements/headline3';
import { StaticImage } from './elements/image';
import { StaticInlineExternalIssueLink } from './elements/inlineExternalIssueLink';
import { StaticLabelMention } from './elements/labelMention';
import { StaticLine } from './elements/line';
import { StaticLink } from './elements/link';
import { StaticLoom } from './elements/loom';
import { StaticMath } from './elements/math';
import { StaticMathExpression } from './elements/mathExpression';
import { StaticNumbered } from './elements/numbered';
import { StaticParagraph } from './elements/paragraph';
import { StaticSmartTodo } from './elements/smartTodo';
import { StaticTodo } from './elements/todo';
import { StaticTodoMention } from './elements/todoMention';
import { StaticUserMention } from './elements/userMention';
import { StaticVideo } from './elements/video';
import { StaticLeaf } from './renderLeaf';
import styles from './textArea.module.scss';
import {
  AIPlaceholderElement,
  BulletedElement,
  ChatElement,
  ChatMessageElement,
  CodeElement,
  CustomEmojiElement,
  DocumentLike,
  Elements,
  EntityElement,
  ExternalIssueLinkElement,
  FigmaElement,
  FileElement,
  FormattedText,
  GiphyElement,
  GithubElement,
  GroupElement,
  ImageElement,
  InlineExternalIssueLinkElement,
  IssueElement,
  LabelElement,
  LineElement,
  LinkElement,
  LoomElement,
  MathElement,
  MathExpressionElement,
  NumberedElement,
  SmartTodoElement,
  TodoElement,
  TodoMentionElement,
  UserElement,
  VideoElement,
} from './types';

function colorOffsetsToLeaves(
  colorOffsets: number[][],
  leaf: FormattedText,
  text: string
): FormattedText[] {
  if (!colorOffsets.length) {
    return [leaf];
  }

  const result: FormattedText[] = [];
  let offset = 0;
  for (const colorOffset of colorOffsets) {
    if (offset !== colorOffset[0]) {
      result.push({
        ...leaf,
        text: text.substring(offset, colorOffset[0]),
      });
    }
    const color = text.substring(colorOffset[0], colorOffset[1]);
    result.push({
      ...leaf,
      colorPreview: color,
      text: color,
    });
    offset = colorOffset[1];
  }

  if (offset !== text.length - 1) {
    result.push({
      ...leaf,
      text: text.substring(offset),
    });
  }
  return result;
}

function emojiOffsets(
  matches: IterableIterator<RegExpMatchArray>,
  leaf: FormattedText,
  text: string
): FormattedText[] {
  const result: FormattedText[] = [];
  let offset = 0;
  for (const match of matches) {
    if (match.index !== undefined) {
      if (offset !== match.index) {
        result.push({
          ...leaf,
          text: text.substring(offset, match.index),
        });
      }
      const emojiString = text.substring(match.index, match.index + match[0].length);
      result.push({
        ...leaf,
        text: emojiString,
        emojiFont: true,
      });
      offset = match.index + match[0].length;
    }
  }

  if (offset <= text.length - 1) {
    result.push({
      ...leaf,
      text: text.substring(offset),
    });
  }
  return result;
}

function decorateLeaf(leaf: FormattedText, parent?: Node): FormattedText[] {
  if (!parent || !KitemakerElement.isElement(parent)) {
    return [leaf];
  }

  let result = [{ ...leaf }];
  const text = KitemakerNode.safeString(leaf);

  // Check for big emoji
  if (parent.type === Elements.Paragraph && parent.children.length === 1) {
    if (isEmoji(text.trim())) {
      result[0].bigEmoji = true;
      result[0].emojiFont = true;
    }
  }

  if (
    KitemakerElement.isTextBlock(parent) &&
    (text.includes('#') || text.includes('rgba(')) &&
    (text.match(hexColorRegex) || text.match(rgbaColorRegex))
  ) {
    // look for # colors
    result = result.flatMap(textNode => {
      const colorOffsets: number[][] = [];
      const text = KitemakerNode.safeString(textNode);

      let hashIndex = text.indexOf('#');
      while (hashIndex !== -1) {
        const maybeColor = text.substring(hashIndex, hashIndex + 7);
        if (maybeColor.match(hexColorRegex)) {
          colorOffsets.push([hashIndex, hashIndex + 7]);
        }
        hashIndex = text.indexOf('#', hashIndex + 1);
      }
      return colorOffsetsToLeaves(colorOffsets, textNode, text);
    });
    // look for rgba colors
    result = result.flatMap(textNode => {
      const colorOffsets: number[][] = [];
      const text = KitemakerNode.safeString(textNode);
      let rgbIndex = text.indexOf('rgb');
      while (rgbIndex !== -1) {
        const closedParenIndex = text.indexOf(')', rgbIndex + 1);
        if (closedParenIndex !== -1) {
          const maybeColor = text.substring(rgbIndex, closedParenIndex + 1);
          if (maybeColor.match(rgbaColorRegex)) {
            colorOffsets.push([rgbIndex, closedParenIndex + 1]);
          }
        }
        rgbIndex = text.indexOf('rgb', rgbIndex + 1);
      }
      return colorOffsetsToLeaves(colorOffsets, textNode, text);
    });
  }

  result = result.flatMap(textNode => {
    const text = KitemakerNode.safeString(textNode);
    const matches = text.matchAll(reEmoji);

    return emojiOffsets(matches, textNode, text);
  });

  return result;
}

export function StaticSlateNode({ node, decorate }: { node: Node; decorate?: boolean }) {
  if (Text.isText(node)) {
    return (
      <StaticLeaf text={node} leaf={node}>
        {node.text}
      </StaticLeaf>
    );
  }

  if (KitemakerElement.isElement(node)) {
    const element = node;
    const children = node.children.flatMap(child => {
      if (!Text.isText(child) || !decorate) {
        return child;
      }
      return decorateLeaf(child, node);
    });
    const recursiveChildren = children.map((child, index) => (
      <StaticSlateNode key={index} node={child} />
    ));

    switch (node.type) {
      // blocks
      case Elements.Paragraph:
        return <StaticParagraph element={element}>{recursiveChildren}</StaticParagraph>;
      case Elements.Headline1:
        return <StaticHeadline1 element={element}>{recursiveChildren}</StaticHeadline1>;
      case Elements.Headline2:
        return <StaticHeadline2 element={element}>{recursiveChildren}</StaticHeadline2>;
      case Elements.Headline3:
        return <StaticHeadline3 element={element}>{recursiveChildren}</StaticHeadline3>;
      case Elements.BlockQuote:
        return <StaticBlockQuote element={element}>{recursiveChildren}</StaticBlockQuote>;
      case Elements.Bulleted:
        return (
          <StaticBulleted element={element as BulletedElement}>{recursiveChildren}</StaticBulleted>
        );
      case Elements.Numbered:
        return (
          <StaticNumbered element={element as NumberedElement}>{recursiveChildren}</StaticNumbered>
        );
      case Elements.Todo:
        return <StaticTodo element={element as TodoElement}>{recursiveChildren}</StaticTodo>;
      case Elements.SmartTodo:
        return (
          <StaticSmartTodo element={element as SmartTodoElement}>
            {recursiveChildren}
          </StaticSmartTodo>
        );
      case Elements.Code:
        return <StaticCode element={element as CodeElement}>{recursiveChildren}</StaticCode>;
      case Elements.Line:
        return <StaticLine element={element as LineElement}>{recursiveChildren}</StaticLine>;
      case Elements.Image:
        return <StaticImage element={element as ImageElement}>{recursiveChildren}</StaticImage>;
      case Elements.MathExpression:
        return (
          <StaticMathExpression element={element as MathExpressionElement}>
            {recursiveChildren}
          </StaticMathExpression>
        );
      case Elements.Video:
        return <StaticVideo element={node as VideoElement}>{recursiveChildren}</StaticVideo>;
      case Elements.File:
        return <StaticFile element={node as FileElement}>{recursiveChildren}</StaticFile>;
      case Elements.Loom:
        return <StaticLoom element={element as LoomElement}>{recursiveChildren}</StaticLoom>;
      case Elements.Figma:
        return <StaticFigma element={element as FigmaElement}>{recursiveChildren}</StaticFigma>;
      case Elements.Github:
        return <StaticGithub element={element as GithubElement}>{recursiveChildren}</StaticGithub>;
      case Elements.Giphy:
        return <StaticGiphy element={element as GiphyElement}>{recursiveChildren}</StaticGiphy>;
      case Elements.Chat:
        return <StaticChat element={element as ChatElement}>{recursiveChildren}</StaticChat>;
      case Elements.ChatMessage:
        return (
          <StaticChatMessage element={element as ChatMessageElement}>
            {recursiveChildren}
          </StaticChatMessage>
        );
      case Elements.AIPlaceholder:
        return (
          <StaticAIPlaceholder element={element as AIPlaceholderElement}>
            {recursiveChildren}
          </StaticAIPlaceholder>
        );
      case Elements.ExternalIssueLink:
        return (
          <StaticExternalIssueLink element={element as ExternalIssueLinkElement}>
            {recursiveChildren}
          </StaticExternalIssueLink>
        );

      // inlines
      case Elements.Link:
        return <StaticLink element={element as LinkElement}>{recursiveChildren}</StaticLink>;
      case Elements.User:
        return (
          <StaticUserMention element={element as UserElement}>
            {recursiveChildren}
          </StaticUserMention>
        );
      case Elements.Group:
        return (
          <StaticGroupMention element={element as GroupElement}>
            {recursiveChildren}
          </StaticGroupMention>
        );
      case Elements.Entity:
        return (
          <StaticEntityMention element={element as EntityElement}>
            {recursiveChildren}
          </StaticEntityMention>
        );
      case Elements.TodoMention:
        return (
          <StaticTodoMention element={element as TodoMentionElement}>
            {recursiveChildren}
          </StaticTodoMention>
        );
      case Elements.Issue:
        // FIXME: legacy - remove once we write a DB migration to convert all Issue mentions to Entity mentions
        return (
          <StaticEntityMention element={element as IssueElement}>
            {recursiveChildren}
          </StaticEntityMention>
        );
      case Elements.Label:
        return (
          <StaticLabelMention element={element as LabelElement}>
            {recursiveChildren}
          </StaticLabelMention>
        );
      case Elements.Math:
        return <StaticMath element={element as MathElement}>{recursiveChildren}</StaticMath>;
      case Elements.CustomEmoji:
        return (
          <StaticCustomEmoji element={element as CustomEmojiElement}>
            {recursiveChildren}
          </StaticCustomEmoji>
        );
      case Elements.InlineExternalIssueLink:
        return (
          <StaticInlineExternalIssueLink element={element as InlineExternalIssueLinkElement}>
            {recursiveChildren}
          </StaticInlineExternalIssueLink>
        );

      // slate gets sad if an element is altogether omitted, so just return a dump div/span if we don't know what
      // else to do
      default:
        return (
          <DummyNode as={KitemakerElement.isInline(node) ? 'span' : 'div'} element={node}>
            {recursiveChildren}
          </DummyNode>
        );
    }
  }

  return null;
}

function StaticSlateDocumentComponent(
  {
    value,
    decorate,
    voidBlockPlaceholders,
    className,
  }: {
    value: DocumentLike;
    decorate?: boolean;
    voidBlockPlaceholders?: boolean;
    className?: string;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div ref={ref} className={cn(styles.textArea, styles.richText, styles.static, className)}>
      {value.map((descendant, index) => {
        const isVoid =
          KitemakerElement.isElement(descendant) && KitemakerElement.isVoid(descendant);
        const isChat = KitemakerElement.isElement(descendant) && descendant.type === Elements.Chat;

        if (!voidBlockPlaceholders || (!isVoid && !isChat)) {
          return <StaticSlateNode key={index} node={descendant} decorate={decorate} />;
        }

        let icon = 'attachment';
        let name = capitalize(descendant.type);
        if (isChat && descendant.integrationType) {
          icon = iconForIntegrationType(descendant.integrationType) ?? icon;
          name = `${stringifyIntegrationType(descendant.integrationType)} chat`;
        }

        return (
          <div key={index} className="block">
            <Pill noHover pillStyle={PillStyle.Secondary}>
              <Icon size={IconSize.Size16} icon={icon} />
              {name}
            </Pill>
          </div>
        );
      })}
    </div>
  );
}

export const StaticSlateDocument = React.forwardRef(StaticSlateDocumentComponent);
