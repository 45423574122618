import * as React from 'react';
import { Icon, IconSize } from '../icon';
import Pill from './pill';
import { MetadataSize } from './size';

function TodoCountComponent(
  {
    completed,
    total,
    size = MetadataSize.Small,
    ...props
  }: { completed: number; total: number; size?: MetadataSize } & Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'ref'
  >,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const filled = completed === total;
  return (
    <Pill ref={ref} size={size} {...props}>
      <Icon size={IconSize.Size16} icon={filled ? 'tasks_filled' : 'tasks'} />
      <span>
        {completed}/{total}
      </span>
    </Pill>
  );
}

const TodoCount = React.forwardRef(TodoCountComponent);
export default TodoCount;
