import * as React from 'react';
import { Icon, IconSize } from '../../../components/new/icon';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { colorWithOpacity } from '../../../utils/color';

export function RoadmapIcon({
  color,
  className,
  style,
  size = IconSize.Size20,
}: {
  color: string;
  className?: string;
  style?: React.CSSProperties;
  size?: IconSize;
}) {
  const { darkMode } = useDarkMode();
  return (
    <Icon
      size={size}
      icon="roadmap"
      className={className}
      style={{ ...style, fill: colorWithOpacity(color, 9, darkMode, true) }}
    />
  );
}
