import * as Sentry from '@sentry/browser';
import { appMenu } from '@todesktop/client-core';
import {
  getVersion,
  setInternalURLs,
  setBadgeCount as setToDesktopBadgeCount,
} from '@todesktop/client-core/app';
import { isMaximized, maximize, unmaximize } from '@todesktop/client-core/nativeWindow';
import { openURL } from '@todesktop/client-core/platform/os';
import { getBackendVersion } from '@todesktop/client-core/platform/todesktop';
import { getZoomLevel, setZoomLevel, setWindowOpenRules } from '@todesktop/client-core/webContents';
import debugModule from 'debug';
import { parse } from 'query-string';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Organization } from '../sync/__generated/models';
import { useOrganization } from './contexts/organizationContext';
import { sanitizeUrl } from './utils/urls';

const debug = debugModule('electronIntegration');
const addedOrganizations = new Set<string>();

const toDesktopVersion = isElectron() ? Number(getBackendVersion().replace('.', '')) : -1;
const toDesktopVersionCheck = 1284;

/**
 * There are several ways one can check for whether the page is running in Electron. The solution
 * below is selected simply because it was written at the same time as the badge function below was
 * created. And since this 'ipcRenderer' is *one* way to check Electron presence, 'tis the chosen
 * way for now.
 */
export function isElectron(): boolean {
  return !!window.todesktop;
}

/*
 * Preliminary test function to play around with notifications. The string 'badge-increase' is not
 * magic, there just needs to be a event handler on the electron side that listens for it.
 */
export function setBadgeCount(value: number): void {
  if (!isElectron()) {
    return;
  }
  setToDesktopBadgeCount(value);
}

/*
 * Open the specified URL in the default system browser rather than in the app
 */
export async function openLinkInBrowser(link: string) {
  try {
    if (link.startsWith('mailto:')) {
      window.location.href = link;
    } else {
      await openURL(sanitizeUrl(link));
    }
  } catch (e) {
    debug('Error opening link in browser', e);
    Sentry.captureException(e, {
      extra: {
        url: link,
      },
    });
  }
}

/*
 * Set the organizations so they show up in the Window menu
 */
export async function configureElectronApp(organizations: Organization[]) {
  try {
    for (const { id, name, slug } of organizations) {
      if (addedOrganizations.has(id)) {
        continue;
      }

      addedOrganizations.add(id);
      await appMenu.add(
        'Organizations',
        name,
        () => {
          window.location.pathname = slug;
        },
        {
          accelerator: `CommandOrControl+${addedOrganizations.size}`,
        }
      );
    }

    await appMenu.refresh();
    await setInternalURLs('^https://kitemaker-attachments\\.s3\\..*\\.amazonaws\\.com/.*$');

    await setWindowOpenRules({
      ref: undefined as any, // web contents ref is optional, defaults to the current window.
      rules: [
        {
          regex: '^https:\\/\\/(www\\.)?figma\\.com.*$',
          options: {
            action: 'allow',
            overrideBrowserWindowOptions: {
              titleBarStyle: 'default',
            },
          },
        },
      ],
    });
  } catch (e) {
    debug('Error configuring app menu in electron', e);
  }
}

/*
 * Temporary thing needed to support cmd+= on electron for zooming
 */
export async function zoomIn() {
  if (isElectron() && toDesktopVersion >= toDesktopVersionCheck) {
    const currentZoom = await getZoomLevel();
    await setZoomLevel(currentZoom + 0.5);
  }
}

export function getElectronVersion(): string | undefined {
  if (!isElectron()) {
    return;
  }
  return getVersion();
}

export function checkElectronVersion() {
  if (!isElectron()) {
    return;
  }
  Sentry.setTag('electronAppVersion', getVersion());
  Sentry.setTag('toDesktop', true);
}

export async function toggleMaximized() {
  if (!isElectron()) {
    return;
  }
  const maximized = await isMaximized();
  if (maximized) {
    await unmaximize();
  } else {
    await maximize();
  }
}

export function DeepLinkInOrganization() {
  const history = useHistory();
  const organization = useOrganization();
  const slug = organization.slug;

  React.useEffect(() => {
    if (!isElectron()) {
      return;
    }
    window.todesktop.on('open-protocol-url', (_: never, event: Event & { url?: string }) => {
      try {
        const url = new URL(event.url ?? '');
        const query = parse(url.search);
        if (query.forceRefresh) {
          return;
        }
        const path = url.pathname.replace(`/${url.hostname}`, '');

        if (path.startsWith(`/${slug}`)) {
          event.preventDefault();
          history.push({
            pathname: path,
            search: url.search,
            state: {
              backUrl: history.location.pathname,
              backSearch: history.location.search,
            },
          });
        }
      } catch (e) {
        // no worries, we just will let electron do the handling
      }
    });
  }, [slug]);

  return null;
}
