import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { installZapierIntegration, uninstallZapierIntegration } from '../../../../api/zapier';
import { writeToClipboard } from '../../../../components/clipboardText';
import { ButtonStyle, IconButton } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  Setting,
  SettingsSection,
} from '../../../../components/new/settings';
import { TextInput } from '../../../../components/new/textInput';
import { Tooltip } from '../../../../components/new/tooltip';
import { toast } from '../../../../components/toast';
import { useOrganization } from '../../../../contexts/organizationContext';
import useSaveTemporarily from '../../../../hooks/useSaveTemporarily';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { organizationPath } from '../../../../syncEngine/selectors/organizations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <p>Build Zaps that connect to Kitemaker and automate tasks across a wide range of tools</p>
);

export function EditZapierIntegration({ id }: { id: string }) {
  const history = useHistory();
  const integration = useRecoilValue(integrationsSelector(id));
  const organization = useOrganization();
  const { getTemp } = useSaveTemporarily('zapier_token');
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const token = React.useMemo(() => getTemp(), []);

  async function uninstall() {
    if (requestInProgress) {
      return;
    }
    setRequestInProgress(true);
    await uninstallZapierIntegration(id);
    setRequestInProgress(false);

    toast.success('Successfully uninstalled the Zapier integration');

    history.replace({
      pathname: organizationPath(organization, 'settings/integrations'),
      state: {
        backUrl: organizationPath(organization),
      },
    });
  }

  if (!integration) {
    return <IntegrationNotFound />;
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Zapier} description={description}>
      {token && (
        <SettingsSection>
          <p>
            Here is the token you should set in Zapier. Copy it now because it won't be displayed
            again.
          </p>

          <Setting vertical title="Token">
            <div className="row">
              <TextInput value={token} disabled />
              <Tooltip content={<>Copy token to clipboard</>}>
                <IconButton
                  icon="copy"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    writeToClipboard(token, 'token');
                  }}
                />
              </Tooltip>
            </div>
          </Setting>
        </SettingsSection>
      )}

      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Destructive} onClick={uninstall}>
          Uninstall
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewZapierIntegration({ redirectUrl: _redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const history = useHistory();
  const { setTemp } = useSaveTemporarily('zapier_token');

  const [requestInProgress, setRequestInProgress] = React.useState(false);

  async function install() {
    if (requestInProgress) {
      return;
    }

    setRequestInProgress(true);
    const zapierInstallation = await installZapierIntegration(organization.id);
    if (zapierInstallation) {
      setTemp(zapierInstallation.token);
    }
    setRequestInProgress(false);

    history.replace({
      pathname: organizationPath(
        organization,
        `settings/integration/${zapierInstallation?.integrationId}`
      ),
      state: {
        backUrl: organizationPath(organization),
      },
    });
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Zapier}
      description={
        <>
          {description}
          <p>
            To set up the integration, we'll provide you with an API key that you can then plug into
            Zapier when configuring your Zaps.
          </p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={install}>
          Install Zapier integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
