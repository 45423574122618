import defaultScrollIntoView from 'scroll-into-view-if-needed';
import { findScrollParent } from './dom';

export function scrollIntoView(el: HTMLElement, options: any) {
  const scrollParent = findScrollParent(el);
  if (scrollParent && getComputedStyle(scrollParent).flexDirection === 'column-reverse') {
    const { top, bottom } = el.getBoundingClientRect();
    const needsToScroll =
      top < scrollParent.getBoundingClientRect().top || scrollParent.clientHeight < bottom;
    if (needsToScroll) {
      el.scrollIntoView(options);
    }
    return;
  }

  defaultScrollIntoView(el, options);
}
