import cn from 'classnames';
import { isArray } from 'lodash';
import { lookup } from 'mime-types';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as ReactLink, useHistory, useLocation } from 'react-router-dom';
import { useConfiguration } from '../contexts/configurationContext';
import { Modals, useModals } from '../contexts/modalContext';
import { openExternalUrl } from '../utils/urls';
import ExternalLink from './externalLink';
import styles from './markdown.module.scss';
import { Breadcrumb } from './new/breadcrumbs';
import { ButtonStyle } from './new/button';
import { DownloadButton } from './new/downloadButton';
import { Icon } from './new/icon';
import { RetryingImage } from './retryingImage';
import { RetryingVideo } from './retryingVideo';

// filthy work around for this bug https://github.com/remarkjs/react-markdown/issues/339
window.process = { env: {}, cwd: () => '' };

function Link(props: any) {
  const history = useHistory();
  const location = useLocation();
  const { host } = useConfiguration();
  if (props.href.startsWith(host)) {
    try {
      const { href, className, ...rest } = props;
      const url = new URL(props.href);
      const query = parse(url.search);

      let update: string | undefined = undefined;
      let siblingEntities: string[] | undefined = undefined;
      let breadcrumbs: Breadcrumb[] | undefined = undefined;

      if (query.update) {
        update = isArray(query.update) ? query.update[0] : query.update;
        delete query.update;
      }
      if (query.siblingEntities) {
        siblingEntities = (
          isArray(query.siblingEntities) ? query.siblingEntities[0] : query.siblingEntities
        ).split(',');
        delete query.siblingEntities;
      }
      if (query.breadcrumbs) {
        breadcrumbs = (isArray(query.breadcrumbs) ? query.breadcrumbs[0] : query.breadcrumbs)
          .split(',')
          .map(name => ({ name }));
        delete query.breadcrumbs;
      }

      return (
        <ReactLink
          className={cn('clickable', className)}
          to={{
            pathname: `${url.pathname}${Object.keys(query).length ? `?${stringify(query)}` : ''}`,
            state: {
              backUrl: location.pathname,
              backSearch: history.location.search,
              update,
              siblingEntities,
              breadcrumbs,
            },
          }}
          {...rest}
        />
      );
    } catch (e) {
      // invalid URL, no biggie
    }
  }

  return <ExternalLink className={cn('clickable', props.className)} {...props} />;
}

function Image(props: any) {
  const modals = useModals();
  const { host } = useConfiguration();

  const url: string = props.src;

  const fullUrl = url.startsWith('/') ? `${host}${url}` : url;
  const downloadUrl = url.startsWith('/') ? `${fullUrl}?forceDownload=true` : fullUrl;

  const mimeType = lookup(url);

  const isVideo = mimeType && mimeType.startsWith('video/');
  const isFile = mimeType && !isVideo && !mimeType.startsWith('image/');
  const isEmoji = props.alt?.startsWith('emoji');

  let content = (
    <RetryingImage
      {...props}
      className={isEmoji ? styles.emoji : undefined}
      onClick={() => {
        modals.openModal(Modals.Image, { url });
      }}
    />
  );

  if (isEmoji) {
    return content;
  }

  if (isFile) {
    content = (
      <div
        className={styles.file}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (!fullUrl) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();
          openExternalUrl(fullUrl);
        }}
      >
        <Icon icon="attachment" />
        <div className={styles.name}>{decodeURI(url.substr(url.lastIndexOf('/') + 1))}</div>
      </div>
    );
  }

  if (isVideo) {
    content = <RetryingVideo {...props} controls />;
  }

  return (
    <div className="rowCenter">
      <div className={cn(styles.container, { [styles.fileContainer]: isFile })}>
        {content}
        <DownloadButton
          url={downloadUrl}
          className={styles.downloadContainer}
          buttonStyle={ButtonStyle.SecondaryOverlay}
        />
      </div>
    </div>
  );
}

export default function Markdown({
  children,
  inline,
  className,
}: {
  children: string;
  inline?: boolean;
  className?: string;
}) {
  return (
    <div
      className={cn(
        styles.markdown,
        {
          [styles.inline]: inline,
        },
        className
      )}
    >
      <ReactMarkdown
        renderers={{
          link: Link,
          image: Image,
        }}
        source={children}
      />
    </div>
  );
}
