import cn from 'classnames';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import { spacePath } from '../../syncEngine/selectors/spaces';
import { Starred } from '../../utils/starred';
import styles from './breadcrumbs.module.scss';
import { Icon, IconSize } from './icon';
import { StarredIcon } from './starredIcon';

export interface Breadcrumb {
  name: React.ReactNode | string;
  link?: string;
}

export function TitleBreadcrumb({ number, title }: { number: string; title: string }) {
  return (
    <>
      <div className={styles.number}>{number}</div>
      <div className={styles.title}>{title}</div>
    </>
  );
}

export function Breadcrumbs({
  breadcrumbs,
  starred,
  className,
}: {
  breadcrumbs: Breadcrumb[];
  starred?: Starred;
  className?: string;
}) {
  const renderedBreadcrumbs = breadcrumbs.map((breadcrumb, index) => {
    const Component = breadcrumb.link ? NavLink : 'div';

    return (
      <Component
        key={`breadcrumb-${index}`}
        className={cn(styles.breadcrumb, {
          [styles.last]: index === breadcrumbs.length - 1,
          [styles.link]: !!breadcrumb.link,
        })}
        to={(breadcrumb.link ? breadcrumb.link : undefined) as any}
      >
        {breadcrumb.name}
      </Component>
    );
  });

  const breadcrumbsWithSeparators = renderedBreadcrumbs.reduce((result: React.ReactNode, child) => {
    if (!result) {
      return child;
    }
    return (
      <>
        {result}
        <div className={styles.separator}>›</div>
        {child}
      </>
    );
  }, null);

  return (
    <div className={cn(styles.breadcrumbs, className)}>
      {breadcrumbsWithSeparators}
      {starred && <StarredIcon starred={starred} />}
    </div>
  );
}

export function useSpaceBreadcrumb(): Breadcrumb[] {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const isTinyScreen = useIsTinyScreen();

  if (!space) {
    return [];
  }

  return [
    {
      name: (
        <div className="row">
          <div className="ellipsis">{space.name}</div>
          {space.private && !isTinyScreen && (
            <Icon icon="private" className="ml4" size={IconSize.Size16} />
          )}
        </div>
      ),
      link: spacePath(organization, space),
    },
  ];
}
