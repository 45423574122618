import cn from 'classnames';
import * as React from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { Breadcrumbs } from '../../../components/new/breadcrumbs';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { Tab } from '../../../components/new/tabs';
import tabStyles from '../../../components/new/tabs.module.scss';
import { Tooltip } from '../../../components/new/tooltip';
import { ReorderableTabs } from '../../../components/reorderableTabs';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { OrganizationMarker } from '../../../graphql/smartLoad';
import { useIsSmallScreen } from '../../../hooks/useResponsiveDesign';
import { useCreateInitiative } from '../../../syncEngine/actions/intiatives';
import { useUpdateRoadmapSorts } from '../../../syncEngine/actions/roadmaps';
import { localStorageEffect } from '../../../syncEngine/effects';
import { entityDeletedSelector } from '../../../syncEngine/selectors/entities';
import { initiativePath } from '../../../syncEngine/selectors/intiatives';
import {
  publicRoadmapsForOrganizationSelector,
  roadmapPath,
  roadmapSelector,
} from '../../../syncEngine/selectors/roadmaps';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { createNewEntityFromAnywhere, isMobileOS } from '../../../utils/config';
import { DeletedScreen } from '../../errorScreens/deletedScreen';
import LoadingScreen from '../../loadingScreen';
import { AllInitiatives } from './allInitiatives';
import { Roadmap } from './roadmap';
import { RoadmapIcon } from './roadmapIcon';
import styles from './roadmapsScreen.module.scss';

const roadmapTabState = atom<string | null>({
  key: 'roadmapTab',
  default: null,
  effects: [localStorageEffect('__roadmapTab')],
});

function useRoadmapTabState(): string {
  const state = useRecoilValue(roadmapTabState);
  const organization = useOrganization();
  // PRIVATE-INITIATIVES-AND-ROADMAPS
  const roadmaps = useRecoilValue(publicRoadmapsForOrganizationSelector(organization.id)).map(
    r => r.id
  );

  if (!state || (!roadmaps.includes(state) && state !== 'all')) {
    return roadmaps[0] ?? 'all';
  }

  return state!;
}

function NewRoadmapButton({ roadmapId }: { roadmapId: string }) {
  const organization = useOrganization();
  const history = useHistory();
  const modals = useModals();
  const isSmallScreen = useIsSmallScreen();
  const createInitiative = useCreateInitiative();

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isMobileOS) {
      e.preventDefault();
      e.stopPropagation();
      const initiative = createInitiative('New initiative', {
        roadmaps: [
          {
            roadmapId,
          },
        ],
      });
      history.push(initiativePath(organization, initiative.initiative));
      return;
    }

    modals.openModal(Modals.NewEntity);
  };

  if (isSmallScreen) {
    return (
      <>
        <Tooltip content="All initiatives">
          <IconButton
            className="mr8"
            icon="list_view"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              history.push(roadmapPath(organization, 'all'));
            }}
          />
        </Tooltip>
        <Tooltip content="New initiative">
          <IconButton icon="add" onClick={onClick} />
        </Tooltip>
      </>
    );
  }

  return (
    <Tooltip
      content={
        <>
          Create initiative <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
        </>
      }
    >
      <Button className="mr8" onClick={onClick} icon="add">
        New initiative
      </Button>
    </Tooltip>
  );
}

function RoadmapTabs() {
  const organization = useOrganization();
  // PRIVATE-INITIATIVES-AND-ROADMAPS
  const roadmaps = useRecoilValue(publicRoadmapsForOrganizationSelector(organization.id));
  const currentTab = useRoadmapTabState();
  const history = useHistory();
  const updateRoadmapSorts = useUpdateRoadmapSorts();
  const isSmallScreen = useIsSmallScreen();
  const modals = useModals();

  const staticTabs = (
    <>
      {!isSmallScreen && (
        <Button
          key={'all'}
          value={'all'}
          buttonStyle={ButtonStyle.BareSubtle}
          className={cn(tabStyles.tab, tabStyles.subtle, 'mr1', {
            [tabStyles.active]: 'all' === currentTab,
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            history.push(roadmapPath(organization, 'all'));
          }}
        >
          <span>All initiatives</span>
        </Button>
      )}
    </>
  );

  const onNewClick = () => {
    modals.openModal(Modals.NewRoadmap);
  };

  const tabs = roadmaps.map(roadmap => {
    return {
      id: roadmap.id,
      icon: <RoadmapIcon color={roadmap.color} className="mr6" />,
      name: roadmap.name,
    };
  }) as Tab[];

  const onDragEnd = async ({ source, destination }: DropResult) => {
    const from = source.index;
    const to = destination?.index;

    if (to === undefined || to === null) {
      return;
    }
    const toMove = roadmaps[from];

    const tempRoadmaps = [...roadmaps].filter(s => s.id !== toMove.id);
    tempRoadmaps.splice(to, 0, toMove);
    const previousRoadmap = tempRoadmaps[to - 1];
    const nextRoadmap = tempRoadmaps[to + 1];
    updateRoadmapSorts(toMove.id, previousRoadmap?.id, nextRoadmap?.id);
  };

  const onTabClick = (tab: Tab) => {
    history.push(roadmapPath(organization, tab.id));
  };

  return (
    <ReorderableTabs
      currentTab={currentTab}
      tabs={tabs}
      typeLabel={'roadmap'}
      staticTabs={staticTabs}
      onTabClick={onTabClick}
      onNewClick={onNewClick}
      onDragEnd={onDragEnd}
    />
  );
}

function Header({ roadmapId }: { roadmapId: string }) {
  const isSmallScreen = useIsSmallScreen();

  return (
    <ScreenHeader
      className={styles.header}
      showSidebarOpener
      compensateForMacOSTrafficLights="auto"
      rightSideContent={<NewRoadmapButton roadmapId={roadmapId} />}
    >
      {!isSmallScreen && <Breadcrumbs breadcrumbs={[{ name: 'Roadmaps' }]} className="noShrink" />}
      <RoadmapTabs />
    </ScreenHeader>
  );
}

function RoadmapTitleSetter({ roadmapId }: { roadmapId?: string }) {
  const organization = useOrganization();
  const roadmap = useRecoilValue(roadmapSelector(roadmapId));
  let title = `${organization.name} · Roadmaps`;
  if (roadmap) {
    title = `${title} · ${roadmap.name}`;
  }
  return <TitleSetter title={title} />;
}

export function RoadmapsScreen() {
  const organization = useOrganization();
  const matches = useRouteMatch<{ roadmapId?: string }>();
  const roadmapId = matches.params.roadmapId;
  const setSavedTab = useSetRecoilState(roadmapTabState);
  const savedTab = useRoadmapTabState();
  const roadmapDeleted = useRecoilValue(entityDeletedSelector(roadmapId));

  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  React.useEffect(() => {
    if (roadmapId && roadmapId.length > 0) {
      setSavedTab(roadmapId);
    }
  }, [roadmapId, savedTab, setSavedTab]);

  if (!currentOrgFullyFetched) {
    return <LoadingScreen />;
  }

  if (!roadmapId || roadmapId === '') {
    const pathname = roadmapPath(organization, savedTab);
    return <Redirect to={{ pathname }} />;
  }

  if (roadmapDeleted) {
    setSavedTab('all');
    return (
      <Screen>
        <DeletedScreen type={'roadmap'} />
      </Screen>
    );
  }

  return (
    <Screen>
      <RoadmapTitleSetter roadmapId={roadmapId} />
      <Header roadmapId={roadmapId} />
      {!roadmapId?.length || (roadmapId === 'all' && <AllInitiatives />)}
      {roadmapId && roadmapId !== 'all' && <Roadmap roadmapId={roadmapId} />}
    </Screen>
  );
}
