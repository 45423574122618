import cn from 'classnames';
import * as React from 'react';
import { issueTerm } from '../../../shared/utils/terms';
import styles from './count.module.scss';
import { Tooltip } from './tooltip';

export function Count({
  count,
  limit,
  className,
  style,
}: {
  count: number;
  limit?: number | null;
  className?: string;
  style?: React.CSSProperties;
}) {
  const multiDigit = React.useMemo(() => `${count}`.length > 1, [count]);
  const maximumExceeded = limit && count > limit;

  const content = (
    <div
      className={cn(
        styles.count,
        {
          [styles.multiDigit]: multiDigit || limit,
          [styles.limitExdeeded]: maximumExceeded,
        },
        className
      )}
      style={style}
    >
      {count}
      {limit && `/${limit}`}
    </div>
  );

  if (maximumExceeded) {
    return (
      <Tooltip content={`The number of ${issueTerm}s has exceeded this column's limit`}>
        {content}
      </Tooltip>
    );
  }

  return content;
}
