import { partition } from 'lodash';
import React from 'react';
import { IssueStatusType } from '../../../../sync/__generated/models';
import { statusToIcon, statusToString } from '../statusIcon';
import { DropdownMenuCheckboxItem, DropdownMenuLabel, DropdownMenuSeparator } from './dropdownMenu';

export function StatusTypeMenu({
  statusType,
  values,
  boardName,
  onSelect,
}: {
  statusType: IssueStatusType;
  values: IssueStatusType[];
  boardName?: string;
  onSelect: (statusType: IssueStatusType) => void;
}) {
  let top: IssueStatusType[] = values;
  let bottom: IssueStatusType[] = [];

  if (boardName === 'Current') {
    [top, bottom] = partition(values, st => st === IssueStatusType.Backlog);
  } else if (boardName === 'Backlog') {
    [top, bottom] = partition(
      values,
      st => st === IssueStatusType.Backlog || st === IssueStatusType.Todo
    );
  }

  return (
    <>
      <DropdownMenuLabel>Status type</DropdownMenuLabel>
      {top.map(st => (
        <DropdownMenuCheckboxItem
          checked={statusType === st}
          key={st}
          icon={statusToIcon(st)}
          onSelect={() => onSelect(st)}
        >
          {statusToString(st)}
        </DropdownMenuCheckboxItem>
      ))}
      {!!top.length && !!bottom.length && <DropdownMenuSeparator />}
      {bottom.map(st => (
        <DropdownMenuCheckboxItem
          checked={statusType === st}
          key={st}
          icon={statusToIcon(st)}
          onSelect={() => onSelect(st)}
        >
          {statusToString(st)}
        </DropdownMenuCheckboxItem>
      ))}
    </>
  );
}
