import cn from 'classnames';
import * as React from 'react';
import { writeToClipboard } from '../components/clipboardText';
import Markdown from '../components/markdown';
import Modal from '../components/modal';
import { ButtonStyle, IconButton } from '../components/new/button';
import { useModals, Modals, TextToCopyArgs } from '../contexts/modalContext';
import styles from './textToCopyModal.module.scss';

function TextToCopyContent() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() as TextToCopyArgs | null;
  const [content, setContent] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (args?.content instanceof Promise) {
      args.content.then(setContent);
    } else {
      setContent(args?.content ?? null);
    }
  }, [args?.content]);

  return (
    <div className="relative">
      <div className={cn('rowBetween', 'headingL', 'mt32', 'mb12', 'ml32', 'mr32')}>
        <span>{args?.dialogTitle ? args.dialogTitle : `Text`}</span>
        <IconButton
          disabled={content === null}
          icon="copy"
          buttonStyle={ButtonStyle.SecondaryOverlay}
          onClick={() => {
            writeToClipboard(content!, args?.dialogTitle ?? 'Text');
          }}
        />
      </div>
      <div className={styles.content}>
        <Markdown>{content ? content : 'Content is loading...'}</Markdown>
      </div>
    </div>
  );
}

export default function TextToCopyModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() as TextToCopyArgs | null;
  return (
    <>
      <Modal
        modalId={Modals.TextToCopy}
        title={args?.dialogTitle ? args.dialogTitle : `Text`}
        hideHeader={true}
      >
        <TextToCopyContent />
      </Modal>
    </>
  );
}
