import * as React from 'react';
import { CommandGroup, useAllCommandGroups, useCommandPriorities } from '../commands';
import { Hotkey } from '../components/new/hotkey';

export function CommandHotkeys() {
  const allCommandGroups = useAllCommandGroups();
  const priorities = useCommandPriorities();

  const allHotkeys = allCommandGroups
    .filter(c => !!c.hotkey && !c.hotkeyHintOnly)
    .map(command => (
      <Hotkey
        key={command.id}
        {...command}
        hotkey={command.hotkey!}
        priority={command.priority ?? priorities[command.group ?? CommandGroup.Other] * -1} // Need to reverse the priority for the hotkeys
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          command.handler();
        }}
      />
    ));

  return <>{allHotkeys}</>;
}
