import cn from 'classnames';
import { groupBy, keys, uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Entity } from '../../../sync/__generated/models';
import {
  CommandGroup,
  useAllCommandGroups,
  useCommandGroupTitles,
  useSortCommandGroups,
} from '../../commands';
import Hotkey from '../../components/hotkey';
import Modal from '../../components/modal';
import { FilteredListView } from '../../components/new/filteredListView';
import { IconSize } from '../../components/new/icon';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import { LISTVIEW_ID, ListViewItem, NO_KEYNAV } from '../../components/new/listView';
import menuStyles from '../../components/new/menu/menu.module.scss';
import { EntityItem } from '../../components/new/pickers/entityPicker';
import { CommandMenuArgs, CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { useSearch } from '../../contexts/searchContext';
import { useEntityPath } from '../../syncEngine/selectors/entities';
import { trackerEvent } from '../../tracker';
import { commandMenuKey } from '../../utils/config';
import { FuzzySearcherConfiguration } from '../../utils/search';
import styles from './commandMenu.module.scss';
import { AddToTimelineContents } from './views/addInitiativeToTimeline';
import { ChangeColumnContents } from './views/changeColumn';
import { ChangeDependenciesContents } from './views/changeDependencies';
import { ChangeDueDateContents } from './views/changeDueDate';
import { ChangeInitiativesContents } from './views/changeInitiatives';
import { ChangeLabelsContents, ChangeTodoLabelsContents } from './views/changeLabels';
import { ChangeMembersContents, ChangeTodoMembersContents } from './views/changeMembers';
import { ChangeOwnersContents } from './views/changeOwners';
import { ChangeReleasesContents } from './views/changeReleases';
import { ChangeRoadmapInitiativesContents } from './views/changeRoadmapInitiatives';
import { ChangeRoadmapsContents, ChangeSpaceRoadmapContents } from './views/changeRoadmaps';
import { ChangeSpaceContents } from './views/changeSpace';
import { ChangeSpacesContents } from './views/changeSpaces';
import { ChangeStatusContents } from './views/changeStatus';
import { ChangeTagsContents } from './views/changeTags';
import {
  ChangeCycleIssuesContent,
  ChangeInitiativeIssuesContent,
  ChangeReleaseIssuesContent,
} from './views/changeWorkItems';
import { GotoSpaceContents } from './views/gotoSpace';
import { ListTodosContents } from './views/listTodos';
import { MoveDocumentsAndFoldersContents } from './views/moveDocumentsAndFolders';
import { RecentContents } from './views/recent';
import { SetEffortContents, SetTodoEffortContents } from './views/setEffort';
import { SetImpactContents, SetTodoImpactContents } from './views/setImpact';
import { SnippetsContents } from './views/snippets';
import { SnoozedContents } from './views/snoozed';
import { StarredContents } from './views/starred';

const MAX_ENTITY_SEARCH_RESULTS = 10;

export function populate(
  items: ListViewItem[],
  alwaysShownItems: ListViewItem[],
  filter: string,
  sortGroups: (groups: CommandGroup[]) => CommandGroup[],
  commandGroupTitles: Record<CommandGroup, string>
) {
  const allItems = [...items, ...alwaysShownItems] as GroupedListViewItem[];
  const groupedResults = groupBy(allItems, 'group');
  const sortedGroups = filter
    ? uniq(allItems.map(item => item.group).filter(g => !!g))
    : sortGroups(keys(groupedResults) as CommandGroup[]);

  const shownItems: ListViewItem[] = [];
  const sortedGroupsLength = sortedGroups.length;
  for (let i = 0; i < sortedGroupsLength; i++) {
    const group = sortedGroups[i];
    shownItems.push({
      id: NO_KEYNAV,
      key: group.toString(),
      contents: <div className={styles.listItemHeader}>{commandGroupTitles[group]}</div>,
    });
    shownItems.push(...groupedResults[group.toString()]);
    if (i !== sortedGroupsLength - 1) {
      shownItems.push({
        id: NO_KEYNAV,
        key: `${group.toString()}-sep`,
        contents: () => <div className={menuStyles.separator} />,
      });
    }
  }

  shownItems.push({
    id: NO_KEYNAV,
    key: 'command-menu-spacer',
    contents: () => <div style={{ height: 8 }} />,
  });

  return shownItems;
}
export type GroupedListViewItem = ListViewItem & {
  group: CommandGroup;
};

function DefaultContents({ onClose }: { onClose: () => void }) {
  const [searchResults, setSearchResults] = React.useState<Entity[]>([]);
  const entityPath = useEntityPath();
  const history = useHistory();
  const commandGroups = useAllCommandGroups();
  const sortGroups = useSortCommandGroups();
  const commandGroupTitles = useCommandGroupTitles();

  const search = useSearch(resultEntities => {
    setSearchResults(resultEntities.slice(0, MAX_ENTITY_SEARCH_RESULTS));
  });

  const items: GroupedListViewItem[] = commandGroups.map(result => ({
    group: result.group ?? CommandGroup.Other,
    ...result,
    contents: result.description ?? '',
    aliases: [...(result.aliases ?? [])],
    hotkey: result.hotkey,
    icon: result.icon !== 'none' ? result.icon : 'command',
    onSelected: () => {
      trackerEvent('Command Executed', {
        id: result.id,
        description: result.description,
      });

      onClose();
      result.handler();
    },
  }));

  return (
    <FilteredListView
      fuzzySearchConfiguration={FuzzySearcherConfiguration.AutocompleteIgnoreLocation}
      onFilterChanged={filterString => {
        const startsWithSearch =
          filterString.startsWith('search') && filterString.trim().length > 'search'.length;
        const trimmedSearch = startsWithSearch
          ? filterString.slice('search'.length).trim()
          : filterString;
        search(trimmedSearch);
      }}
      iconSize={IconSize.Size20}
      filterClassName={styles.inputDiv}
      populate={(items, alwaysShownItems, filter) => {
        return populate(items, alwaysShownItems, filter, sortGroups, commandGroupTitles);
      }}
      items={items}
      className={styles.commands}
      itemClassName={menuStyles.item}
      selectFirstOnItemsChanged={true}
      filterPlaceholder="Command and search..."
      filterLabel="Command"
      propertiesToSearch={[
        { name: 'description', weight: 2 },
        { name: 'aliases', weight: 1 },
      ]}
      filter={(filterString, search) => {
        const startsWithSearch =
          filterString.startsWith('search') && filterString.trim().length > 'search'.length;
        const trimmedSearch = startsWithSearch
          ? filterString.slice('search'.length).trim()
          : filterString;
        const prioritizeEntitySearch = startsWithSearch && trimmedSearch.length > 2;

        const commandResults = search.search(trimmedSearch).map(r => r.item);
        const entitySearchResults = trimmedSearch
          ? searchResults.map(entity => ({
              group: CommandGroup.EntitySearch,
              id: entity.id,
              contents: () => <EntityItem entityId={entity.id} />,
              onSelected: () => {
                const path = entityPath(entity.id);
                if (path) {
                  history.push(path, {
                    backUrl: location.pathname,
                    backSearch: location.search,
                    entity: entity.id,
                  });
                }
                onClose();
              },
            }))
          : [];

        return prioritizeEntitySearch
          ? [...entitySearchResults, ...commandResults]
          : [...commandResults, ...entitySearchResults];
      }}
    />
  );
}

function CommandMenuContents({
  onClose,
  view = CommandMenuView.Default,
  context,
}: {
  onClose: () => void;
  view?: CommandMenuView;
  context?: any;
}) {
  let contents;

  switch (view) {
    case CommandMenuView.Members: {
      contents = (
        <ChangeMembersContents
          onClose={onClose}
          entityIds={context.entityIds}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.Labels: {
      contents = (
        <ChangeLabelsContents
          onClose={onClose}
          entityIds={context.entityIds}
          spaceId={context.spaceId}
          orgLevel={context.orgLevel}
        />
      );
      break;
    }
    case CommandMenuView.Roadmaps: {
      contents = <ChangeRoadmapsContents onClose={onClose} initiativeIds={context.initiativeIds} />;
      break;
    }
    case CommandMenuView.SpaceRoadmap: {
      contents = <ChangeSpaceRoadmapContents onClose={onClose} spaceId={context.spaceId} />;
      break;
    }
    case CommandMenuView.Spaces: {
      contents = <ChangeSpacesContents onClose={onClose} initiativeIds={context.initiativeIds} />;
      break;
    }

    case CommandMenuView.Initiatives: {
      contents = (
        <ChangeInitiativesContents
          onClose={onClose}
          entityIds={context.entityIds}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.Releases: {
      contents = <ChangeReleasesContents onClose={onClose} entityIds={context.entityIds} />;
      break;
    }
    case CommandMenuView.RoadmapInitiatives: {
      contents = (
        <ChangeRoadmapInitiativesContents
          onClose={onClose}
          roadmapId={context.roadmapId}
          columnId={context.columnId}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.Impact: {
      contents = (
        <SetImpactContents
          onClose={onClose}
          entityIds={context.entityIds}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.Effort: {
      contents = (
        <SetEffortContents
          onClose={onClose}
          entityIds={context.entityIds}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.ChangeStatus: {
      contents = (
        <ChangeStatusContents
          onClose={onClose}
          entityIds={context.entityIds}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.ChangeColumn: {
      contents = (
        <ChangeColumnContents
          onClose={onClose}
          roadmapInitiativeIds={context.roadmapInitiativeIds}
          roadmapId={context.roadmapId}
        />
      );
      break;
    }
    case CommandMenuView.ChangeSpace: {
      contents = <ChangeSpaceContents onClose={onClose} entityIds={context.entityIds} />;
      break;
    }
    case CommandMenuView.ChangeInitiativeIssues: {
      contents = (
        <ChangeInitiativeIssuesContent
          onClose={onClose}
          initiativeId={context.initiativeId}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.ChangeReleaseIssues: {
      contents = <ChangeReleaseIssuesContent onClose={onClose} releaseId={context.releaseId} />;
      break;
    }
    case CommandMenuView.ChangeCycleIssues: {
      contents = <ChangeCycleIssuesContent onClose={onClose} cycleId={context.cycleId} />;
      break;
    }
    case CommandMenuView.Dependencies: {
      contents = (
        <ChangeDependenciesContents
          onClose={onClose}
          entityIds={context.entityIds}
          type={context.type}
          property={context.property}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.Tags: {
      contents = <ChangeTagsContents onClose={onClose} feedbackIds={context.feedbackIds} />;
      break;
    }
    case CommandMenuView.Owners: {
      contents = <ChangeOwnersContents onClose={onClose} feedbackIds={context.feedbackIds} />;
      break;
    }
    case CommandMenuView.AddToTimeline: {
      contents = (
        <AddToTimelineContents
          onClose={onClose}
          roadmapId={context.roadmapId}
          startDate={context.startDate}
          endDate={context.endDate}
          sort={context.sort}
        />
      );
      break;
    }
    case CommandMenuView.Snippets: {
      contents = (
        <SnippetsContents
          onClose={onClose}
          spaceId={context.spaceId}
          onSnippetPicked={context.onSnippetPicked}
          trackingContext={context.trackingContext}
        />
      );
      break;
    }
    case CommandMenuView.Recent: {
      contents = <RecentContents onClose={onClose} />;
      break;
    }
    case CommandMenuView.Starred: {
      contents = <StarredContents onClose={onClose} />;
      break;
    }
    case CommandMenuView.Snoozed: {
      contents = <SnoozedContents onClose={onClose} entityIds={context.entityIds} />;
      break;
    }
    case CommandMenuView.GotoSpace: {
      contents = <GotoSpaceContents onClose={onClose} />;
      break;
    }
    case CommandMenuView.DueDate: {
      contents = (
        <ChangeDueDateContents
          type={context.entityIds.length > 0 ? 'Issue' : 'Todo'}
          onClose={onClose}
          itemIds={context.entityIds?.length > 0 ? context.entityIds : context.todoIds}
        />
      );
      break;
    }

    // FIXME: this duplication for todos is seriously gross, but we need some serious refactoring
    // to avoid it, so this is what we're going with for now

    case CommandMenuView.TodoMembers: {
      contents = (
        <ChangeTodoMembersContents
          onClose={onClose}
          todoId={context.todoId}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.TodoLabels: {
      contents = (
        <ChangeTodoLabelsContents
          onClose={onClose}
          todoId={context.todoId}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.TodoImpact: {
      contents = (
        <SetTodoImpactContents
          onClose={onClose}
          todoId={context.todoId}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.TodoEffort: {
      contents = (
        <SetTodoEffortContents
          onClose={onClose}
          todoId={context.todoId}
          spaceId={context.spaceId}
        />
      );
      break;
    }
    case CommandMenuView.ListTodos: {
      contents = <ListTodosContents onClose={onClose} entityId={context.entityId} />;
      break;
    }
    case CommandMenuView.MoveDocumentsAndFolders: {
      contents = (
        <MoveDocumentsAndFoldersContents
          onClose={onClose}
          documentIds={context.documentIds}
          folderIds={context.folderIds}
        />
      );
      break;
    }

    default: {
      contents = <DefaultContents onClose={onClose} />;
      break;
    }
  }

  return (
    <div className={cn(styles.commandMenu, { [styles.wide]: view === CommandMenuView.ListTodos })}>
      {contents}
    </div>
  );
}

export function CommandMenu() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() as CommandMenuArgs | null;

  return (
    <>
      <Hotkey
        command={{
          id: 'command-menu',
          hotkey: commandMenuKey,
          global: true,
          unscoped: true,
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            modalManager.openModal(Modals.CommandMenu);
          },
        }}
      />
      <Modal modalId={Modals.CommandMenu} hideHeader={true} topAligned>
        <KeyNavigationProvider
          columnIds={[LISTVIEW_ID]}
          ensureVisibleOptions={{ blockMode: 'nearest' }}
        >
          <CommandMenuContents
            onClose={() => modalManager.closeModal(Modals.CommandMenu)}
            view={args?.view}
            context={args?.context}
          />
        </KeyNavigationProvider>
      </Modal>
    </>
  );
}
