import { uniq } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Descendant } from 'slate';
import { KitemakerElement } from '../../../../shared/slate/kitemakerNode';
import { issueTerm } from '../../../../shared/utils/terms';
import { IntegrationType, Todo, TodoStatus } from '../../../../sync/__generated/models';
import { writeToClipboard } from '../../../components/clipboardText';
import { DueDatePicker } from '../../../components/new/dueDateMetadata';
import { iconForIntegrationType } from '../../../components/new/integrationIcon';
import { CycleMenu } from '../../../components/new/menu/cycleMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import { EffortMenu } from '../../../components/new/menu/effortMenu';
import { ImpactMenu } from '../../../components/new/menu/impactMenu';
import { LabelPicker } from '../../../components/new/pickers/labelPicker';
import { MemberPicker } from '../../../components/new/pickers/memberPicker';
import { useConfiguration } from '../../../contexts/configurationContext';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useAddTodosToCycle, useRemoveTodosFromCycle } from '../../../syncEngine/actions/cycles';
import { useToggleTodos, useUpdateTodos } from '../../../syncEngine/actions/todos';
import { todoInCycleSelector } from '../../../syncEngine/selectors/cycles';
import { useEntityPath } from '../../../syncEngine/selectors/entities';
import { externalIssueIntegrationsForOrganizationSelector } from '../../../syncEngine/selectors/externalIssues';
import { todoKeySelector } from '../../../syncEngine/selectors/todos';
import {
  addTodoToCurrentCycleKey,
  addTodoToUpcomingCycleKey,
  commentKey,
  copyTodoBranch,
  copyTodoNumber,
  createNewEntityFromAnywhere,
  markTodoCompleted,
  markTodoInProgress,
  todoDetailsKey,
} from '../../../utils/config';
import { stringifyIntegrationType } from '../../../utils/integrations';
import { Elements } from '../../types';
import { copyNumberToClipboard } from './smartTodoHelpers';
import { useGitTodoBranchName } from './useSmartTodoGitBranchName';
import {
  useOpenNewEntityModalFromTodo,
  useOpenNewExternalIssueModalFromTodo,
} from './useSmartTodoHotkeys';

export function MenuContents({
  todo,
  onMemberAdded,
  onMemberRemoved,
  onLabelAdded,
  onLabelRemoved,
  onWorkItemCreated,
  onCommented,
  onNewExternalIssue,
  closeMenu,
}: {
  todo: Todo;
  onMemberAdded: (memberId: string) => void;
  onMemberRemoved: (memberId: string) => void;
  onLabelAdded: (labelId: string) => void;
  onLabelRemoved: (labelId: string) => void;
  onWorkItemCreated?: (entityId: string | null) => void;
  onCommented?: () => void;
  onNewExternalIssue?: (integrationType: IntegrationType, externalIssueId: string | null) => void;
  closeMenu: () => void;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const updateTodos = useUpdateTodos();
  const toggleTodos = useToggleTodos();
  const key = useRecoilValue(todoKeySelector(todo.id));
  const branchName = useGitTodoBranchName(todo.id);
  const openNewEntityModalFromTodo = useOpenNewEntityModalFromTodo(todo, onWorkItemCreated);
  const openNewExternalIssueModalFromTodo = useOpenNewExternalIssueModalFromTodo(
    todo,
    onNewExternalIssue
  );

  const todoInUpcomingCycle = useRecoilValue(
    todoInCycleSelector({ cycleId: space?.upcomingCycleId, todoId: todo.id })
  );
  const todoInCurrentCycle = useRecoilValue(
    todoInCycleSelector({ cycleId: space?.activeCycleId, todoId: todo.id })
  );
  const addTodosToCycle = useAddTodosToCycle();
  const removeTodosFromCycle = useRemoveTodosFromCycle();
  const { host, featureFlags } = useConfiguration();
  const entityPath = useEntityPath();
  const modals = useModals();

  const externalIssueIntegrations = useRecoilValue(
    externalIssueIntegrationsForOrganizationSelector(organization.id)
  );
  const externalIssueIntegrationTypes = uniq(externalIssueIntegrations.map(i => i.type));
  const linkableMentions = todo.todoContents.flatMap(block =>
    KitemakerElement.isElement(block)
      ? (block.children as any).filter(
          (child: Descendant) =>
            KitemakerElement.isElement(child) &&
            (child.type === Elements.Entity || child.type === Elements.InlineExternalIssueLink)
        )
      : []
  );
  return (
    <>
      {!todo.connectedEntityId && !todo.connectedExternalIssueId && (
        <>
          <DropdownMenuItem
            icon={todo.status === TodoStatus.InProgress ? 'todo_blank' : 'todo_half'}
            onClick={() => {
              toggleTodos([todo.id], true);
              closeMenu();
            }}
            shortcut={markTodoInProgress}
          >
            Mark as {todo.status === TodoStatus.InProgress ? 'not in progress' : 'in progress'}
          </DropdownMenuItem>
          <DropdownMenuItem
            icon={todo.status === TodoStatus.Done ? 'todo_blank' : 'todo_checkmark'}
            onClick={() => {
              toggleTodos([todo.id]);
              closeMenu();
            }}
            shortcut={markTodoCompleted}
          >
            Mark as {todo.status === TodoStatus.Done ? 'not started' : 'completed'}
          </DropdownMenuItem>
          {linkableMentions.length > 0 && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                icon="link"
                onClick={() => {
                  const firstMention = linkableMentions[0];
                  let connectedEntityId: string | null = null;
                  let connectedExternalIssueId: string | null = null;

                  if (firstMention.type === Elements.Entity) {
                    connectedEntityId = firstMention.entityId;
                  } else if (
                    firstMention.type === Elements.InlineExternalIssueLink &&
                    firstMention.externalIssueId
                  ) {
                    connectedExternalIssueId = firstMention.externalIssueId;
                  }

                  if (connectedEntityId || connectedExternalIssueId) {
                    updateTodos([todo.id], {
                      connectedEntityId,
                      connectedExternalIssueId,
                      explicitLinkStatus: true,
                      status: TodoStatus.NotStarted,
                    });
                  }
                }}
              >
                Link to mentioned{' '}
                {linkableMentions[0].type === Elements.Entity ? issueTerm : 'external issue'}
              </DropdownMenuItem>
            </>
          )}
        </>
      )}
      {(todo.connectedEntityId || todo.connectedExternalIssueId) && (
        <DropdownMenuItem
          icon="link"
          onClick={() => {
            updateTodos([todo.id], {
              connectedEntityId: null,
              connectedExternalIssueId: null,
              explicitLinkStatus: true,
              status: TodoStatus.NotStarted,
            });
          }}
        >
          Unlink connected {todo.connectedEntityId ? issueTerm : 'external issue'}
        </DropdownMenuItem>
      )}
      <DropdownMenuSeparator />
      <Submenu>
        <SubmenuTrigger icon="member">Members</SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <MemberPicker
            state={{ todo: todo.memberIds }}
            onMemberAdded={(_, memberId) => {
              onMemberAdded(memberId);
            }}
            onMemberRemoved={(_, memberId) => {
              onMemberRemoved(memberId);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="label">Labels</SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <LabelPicker
            state={{ todo: todo.labelIds }}
            onLabelAdded={(_, labelId) => {
              onLabelAdded(labelId);
            }}
            onLabelRemoved={(_, labelId) => {
              onLabelRemoved(labelId);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="impact">Impact</SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          <ImpactMenu
            onSelect={impactId => updateTodos([todo.id], { impactId })}
            impactId={todo.impactId}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="effort">Effort</SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          <EffortMenu
            onSelect={effortId => updateTodos([todo.id], { effortId })}
            effortId={todo.effortId}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="status">Due date</SubmenuTrigger>
        <SubmenuContent>
          <DueDatePicker
            date={todo.dueDate}
            onSave={dueDate => {
              updateTodos([todo.id], { dueDate: dueDate?.getTime() ?? null });
              closeMenu?.();
            }}
            onCancel={() => closeMenu?.()}
          />
        </SubmenuContent>
      </Submenu>
      {space?.cyclesEnabled && (
        <Submenu>
          <SubmenuTrigger icon="cycle_current">Cycles</SubmenuTrigger>
          <SubmenuContent>
            <CycleMenu
              inCurrentCycle={todoInCurrentCycle}
              inUpcomingCycle={todoInUpcomingCycle}
              spaceId={space.id}
              onSelect={(cycleId: string | null) => {
                if (cycleId === null) {
                  if (todoInUpcomingCycle && space.upcomingCycleId) {
                    removeTodosFromCycle([todo.id], space.upcomingCycleId);
                  }
                  if (todoInCurrentCycle && space.activeCycleId) {
                    removeTodosFromCycle([todo.id], space.activeCycleId);
                  }
                  return;
                }
                if (cycleId === space.activeCycleId && !todoInCurrentCycle) {
                  addTodosToCycle([todo.id], cycleId);
                }
                if (cycleId === space.upcomingCycleId && !todoInUpcomingCycle) {
                  addTodosToCycle([todo.id], cycleId);
                }
              }}
              hotkeys={{
                addToCurrentCycleKey: addTodoToCurrentCycleKey,
                addToUpcomingCycleKey: addTodoToUpcomingCycleKey,
              }}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="copy"
        onClick={() => {
          copyNumberToClipboard(key ?? '');
          closeMenu();
        }}
        shortcut={copyTodoNumber}
      >
        Copy todo number
      </DropdownMenuItem>

      <DropdownMenuItem
        icon="link"
        onClick={() => {
          const link = `${host}${entityPath(todo.entityId)}?focusDescription=true&focusSmartTodo=${
            todo.id
          }`;
          writeToClipboard(link, 'Todo link');
          closeMenu();
        }}
      >
        Copy link
      </DropdownMenuItem>

      <DropdownMenuItem
        icon="branch"
        onClick={() => {
          writeToClipboard(branchName ?? '', 'Todo git branch name');
          closeMenu();
        }}
        shortcut={copyTodoBranch}
      >
        Copy git branch name
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="add"
        shortcut={createNewEntityFromAnywhere}
        onClick={() => {
          openNewEntityModalFromTodo();
        }}
      >
        Create {issueTerm} from todo
      </DropdownMenuItem>

      {onNewExternalIssue &&
        externalIssueIntegrationTypes.map(integrationType => (
          <DropdownMenuItem
            key={integrationType}
            icon={iconForIntegrationType(integrationType)}
            onClick={() => openNewExternalIssueModalFromTodo(integrationType)}
          >
            Create {stringifyIntegrationType(integrationType)} issue from todo
          </DropdownMenuItem>
        ))}
      {onCommented && (
        <DropdownMenuItem icon="chat" shortcut={commentKey} onClick={() => onCommented()}>
          Comment
        </DropdownMenuItem>
      )}
      {featureFlags.FEATURE_TOGGLE_TODO_ACTIVITIES && (
        <DropdownMenuItem
          icon="activity"
          shortcut={todoDetailsKey}
          onClick={() => modals.openModal(Modals.TodoDetails, { todoId: todo.id })}
        >
          Show details
        </DropdownMenuItem>
      )}
    </>
  );
}

export function SmartTodoMenu({
  children,
  todo,
  onMemberAdded,
  onMemberRemoved,
  onLabelAdded,
  onLabelRemoved,
  onWorkItemCreated,
  onCommented,
  onNewExternalIssue,
  onShow,
  onHidden,
}: {
  children: React.ReactNode;
  todo: Todo;
  onMemberAdded: (memberId: string) => void;
  onMemberRemoved: (memberId: string) => void;
  onLabelAdded: (labelId: string) => void;
  onLabelRemoved: (labelId: string) => void;
  onWorkItemCreated?: (entityId: string | null) => void;
  onCommented?: () => void;
  onNewExternalIssue?: (integrationType: IntegrationType, externalIssueId: string | null) => void;
  onShow?: () => void;
  onHidden?: () => void;
}) {
  const [menuOpen, _setMenuOpen] = React.useState(false);
  const setMenuOpen = React.useCallback(
    (open: boolean) => {
      _setMenuOpen(previous => {
        if (open === previous) {
          return open;
        }
        if (!previous) {
          onShow?.();
        } else {
          onHidden?.();
        }
        return !previous;
      });
    },
    [onShow, onHidden, _setMenuOpen]
  );

  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuHuge"
        onClick={e => {
          e.stopPropagation();
        }}
        side="right"
        align="start"
      >
        <MenuContents
          todo={todo}
          closeMenu={closeMenu}
          onMemberAdded={onMemberAdded}
          onMemberRemoved={onMemberRemoved}
          onLabelAdded={onLabelAdded}
          onLabelRemoved={onLabelRemoved}
          onCommented={onCommented}
          onWorkItemCreated={onWorkItemCreated}
          onNewExternalIssue={onNewExternalIssue}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
