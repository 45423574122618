import { PersonalAccessToken } from '../../../sync/__generated/models';
import { SyncEngineUpdateWithoutDelete, useModelManager } from '../../graphql/modelManager';

export function useUpdatePersonalAccessTokens() {
  const modelManager = useModelManager();
  return (patIds: string[], update: SyncEngineUpdateWithoutDelete<PersonalAccessToken>) => {
    modelManager.transaction(tx => {
      for (const patId of patIds) {
        tx.update<PersonalAccessToken>(patId, update);
      }
    });
  };
}
