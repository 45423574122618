import cn from 'classnames';
import NodeCache from 'node-cache';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { FigmaElement } from '../../../shared/slate/types';
import { generateId } from '../../../shared/utils/id';
import { externalAuthFlow } from '../../api/auth';
import {
  createFigmaThumbnail,
  fetchFigmaThumbnail,
  normalizeFigmaLink,
  refreshFigmaThumbnail,
} from '../../api/figma';
import { ButtonStyle, IconButton } from '../../components/new/button';
import { CommentButton } from '../../components/new/commentButton';
import { Icon, IconSize } from '../../components/new/icon';
import { useEnsureFocusedElementIsVisible } from '../../components/new/keyNavigation';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import { Tooltip } from '../../components/new/tooltip';
import { VoidActions } from '../../components/new/voidActions';
import { RetryingImage } from '../../components/retryingImage';
import { toast } from '../../components/toast';
import { useConfiguration } from '../../contexts/configurationContext';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useUnmounted } from '../../hooks/useUnmounted';
import UploadPlaceholder from '../../slate/components/uploadPlaceholder';
import { renderDate } from '../../utils/datetime';
import { openExternalUrl } from '../../utils/urls';
import { collapsedSelector } from '../collapsed';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { debug } from '../plugins/withDebug';
import { useResizeObserver } from '../staticSlateHelpers';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import { ErrorElement } from './error';
import styles from './figma.module.scss';
import { VoidBlock } from './voidBlock';

const thumbnailCache = new NodeCache({
  stdTTL: 300,
});

interface Thumbnail {
  thumbnailUrl?: string;
  fileName?: string;
  frameName?: string;
  figmaUrl: string;
  updatedAt: string;
  backgroundColor: string;
}

function openFigmaEmbed(e: React.MouseEvent, url?: string) {
  if (e.button !== 0 || !url) {
    return;
  }

  e.preventDefault();
  e.stopPropagation();

  openExternalUrl(url);
}

export function StaticFigma({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: FigmaElement }) {
  const unmounted = useUnmounted();
  const modals = useModals();
  const refreshRef = React.useRef(-1);
  const ref = React.useRef<HTMLDivElement>(null);
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const [refreshing, setRefreshing] = React.useState(false);
  const [collapsed, setCollapsed] = useRecoilState(collapsedSelector(element.refId ?? ''));

  const { url, thumbnailId } = element;
  // we should also have both the url and thumbnailId set, but we had a bug where sometimes
  // the thumbnailId wasn't set properly, so let's catch those cases
  const validElementProperties = (!url && !thumbnailId) || (url && thumbnailId);

  const [thumbnailData, setThumbnailData] = React.useState<Thumbnail | null>(
    thumbnailId && thumbnailCache.has(thumbnailId)
      ? (thumbnailCache.get(thumbnailId) as Thumbnail)
      : null
  );

  const [thumbnailLoaded, setThumbnailLoaded] = React.useState(
    thumbnailId && thumbnailCache.has(thumbnailId)
  );
  const [error, setError] = React.useState(
    !validElementProperties ? 'There was an error loading the Figma design' : ''
  );

  useComponentDidMount(() => {
    return () => {
      unmounted.current = true;
    };
  });

  useResizeObserver(ref, url);

  const refetch = React.useCallback(async (thumbnailId: string) => {
    try {
      const thumbUrl = await fetchFigmaThumbnail(thumbnailId);
      if (!thumbUrl) {
        if (!unmounted.current) {
          setTimeout(() => refetch(thumbnailId), 2000);
        }
        return;
      }
      thumbnailCache.set(thumbnailId, thumbUrl);
      setThumbnailData(thumbUrl);
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  React.useEffect(() => {
    if (!thumbnailId) {
      return;
    }
    refetch(thumbnailId);
  }, [thumbnailId]);

  React.useEffect(() => {
    if (!thumbnailId || !thumbnailData) {
      return;
    }

    refreshRef.current = window.setInterval(
      () => {
        refetch(thumbnailId);
      },
      thumbnailData.thumbnailUrl ? 300000 : 1000
    );

    return () => {
      if (refreshRef.current !== -1) {
        window.clearInterval(refreshRef.current);
        refreshRef.current = -1;
      }
    };
  }, [thumbnailData]);

  if (!thumbnailId && !url) {
    return <DummyNode element={element}>{children}</DummyNode>;
  }

  const loading = !thumbnailData?.thumbnailUrl || !thumbnailLoaded || refreshing;

  return (
    <div className="block rowCenter fullWidth" {...attributes}>
      <div
        className={cn(styles.figmaThumbnail, {
          [styles.loading]: !thumbnailLoaded && !error,
        })}
        ref={ref}
      >
        <div
          className={cn(styles.header, { [styles.collapsed]: collapsed })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            openFigmaEmbed(e, url);
          }}
        >
          <div className="row">
            <Icon icon="figma" size={IconSize.Size20} className="mr12" />
            <span className="headingS oneLine mr8 ellipsis">
              {thumbnailData?.fileName && (
                <>
                  {thumbnailData.fileName}
                  {thumbnailData.fileName && thumbnailData.frameName ? ' · ' : ''}
                  {thumbnailData.frameName}
                </>
              )}
            </span>
            {thumbnailData?.updatedAt && (
              <span className="oneLine bodyM grayed mr8">
                Updated{' '}
                {renderDate(new Date(thumbnailData.updatedAt).getTime(), {
                  hoursAndMinutesAgo: true,
                  daysAgo: true,
                })}
              </span>
            )}
          </div>
          {!loading && (
            <VoidActions className={styles.actions}>
              <Tooltip content={collapsed ? 'Expand Figma preview' : 'Minimize Figma preview'}>
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon={collapsed ? 'unfold_more' : 'unfold_less'}
                  className={cn(styles.refresh, 'ml4')}
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (element.refId) {
                      setCollapsed(old => !old);
                    }
                  }}
                />
              </Tooltip>
              <Tooltip content="Refresh Figma thumbnial">
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon="refresh"
                  className={styles.refresh}
                  onClick={async e => {
                    if (refreshing) {
                      return;
                    }
                    e.preventDefault();
                    e.stopPropagation();
                    if (thumbnailId) {
                      setRefreshing(true);
                      refreshFigmaThumbnail(thumbnailId, () => {
                        refetch(thumbnailId);
                        setRefreshing(false);
                      });
                    }
                  }}
                />
              </Tooltip>
            </VoidActions>
          )}
        </div>
        {error && (
          <div className={styles.thumbnail}>
            <ErrorElement error={error} />
          </div>
        )}
        {thumbnailData?.thumbnailUrl && !refreshing && !error && (
          <div className={cn({ maxHeightZero: collapsed })}>
            <RetryingImage
              className={styles.thumbnailImage}
              src={thumbnailData.thumbnailUrl}
              style={{
                ...(thumbnailData.backgroundColor
                  ? { backgroundColor: thumbnailData.backgroundColor }
                  : {}),
              }}
              onLoad={() => {
                setThumbnailLoaded(true);
                ensureVisible();
              }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                modals.openModal(Modals.FigmaEmbed, {
                  url: thumbnailData!.figmaUrl,
                });
              }}
              onError={() => {
                setError('Unable to load Figma thumbnail');
              }}
            />
          </div>
        )}
        {loading && !error && (
          <div className="loadingContainer">
            <LoadingSpinner />
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export function Figma({
  attributes,
  element,
  children,
}: RenderElementProps & { element: FigmaElement }) {
  const unmounted = React.useRef(false);
  const modals = useModals();
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();
  const [collapsed, setCollapsed] = useRecoilState(collapsedSelector(element.refId ?? ''));

  const editor = useSlateStatic();
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  const selectionCollapsedRef = React.useRef(selected && selectionCollapsed);
  selectionCollapsedRef.current = selected && selectionCollapsed;
  const refreshRef = React.useRef(-1);

  const ref = React.useRef<HTMLDivElement>(null);

  const { url, thumbnailId } = element;
  // we should also have both the url and thumbnailId set, but we had a bug where sometimes
  // the thumbnailId wasn't set properly, so let's catch those cases
  const validElementProperties = (!url && !thumbnailId) || (url && thumbnailId);

  const [thumbnailData, setThumbnailData] = React.useState<Thumbnail | null>(
    thumbnailId && thumbnailCache.has(thumbnailId)
      ? (thumbnailCache.get(thumbnailId) as Thumbnail)
      : null
  );

  const [thumbnailLoaded, setThumbnailLoaded] = React.useState(
    thumbnailId && thumbnailCache.has(thumbnailId)
  );
  const [error, setError] = React.useState(
    !validElementProperties ? 'There was an error loading the Figma design' : ''
  );

  const [refreshing, setRefreshing] = React.useState(false);

  const sizeObserver = React.useRef(
    window.ResizeObserver
      ? new window.ResizeObserver(() => {
          if (selectionCollapsedRef.current && ref.current) {
            scrollIntoView(ref.current, {
              block: 'center',
              behavior: 'auto',
              scrollMode: 'if-needed',
            });
          }
          ensureVisible();
          KitemakerEditor.ensureFocusOnScreen(editor, 0);
        })
      : null
  );

  React.useEffect(() => {
    if (!ref.current || !sizeObserver.current) {
      return;
    }

    const observer = sizeObserver.current;
    const observed = ref.current;
    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, [sizeObserver, url]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const refetch = React.useCallback(async (thumbnailId: string) => {
    try {
      const thumbUrl = await fetchFigmaThumbnail(thumbnailId);
      if (!thumbUrl) {
        if (!unmounted.current) {
          setTimeout(() => refetch(thumbnailId), 2000);
        }
        return;
      }
      thumbnailCache.set(thumbnailId, thumbUrl);
      setThumbnailData(thumbUrl);
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  React.useEffect(() => {
    if (!thumbnailId) {
      return;
    }
    refetch(thumbnailId);
  }, [thumbnailId]);

  React.useEffect(() => {
    if (!thumbnailId || !thumbnailData) {
      return;
    }

    refreshRef.current = window.setInterval(
      () => {
        refetch(thumbnailId);
      },
      thumbnailData.thumbnailUrl ? 300000 : 1000
    );

    return () => {
      if (refreshRef.current !== -1) {
        window.clearInterval(refreshRef.current);
        refreshRef.current = -1;
      }
    };
  }, [thumbnailData]);

  React.useEffect(() => {
    if (selectionCollapsedRef.current) {
      setTimeout(() => {
        if (!ref.current) {
          return;
        }
        scrollIntoView(ref.current, {
          block: 'center',
          scrollMode: 'if-needed',
          behavior: 'auto',
        });
      });
    }
  }, [selectionCollapsedRef.current]);

  if (!thumbnailId && !url) {
    return (
      <UploadPlaceholder
        requireUrl
        attributes={attributes}
        element={element}
        icon="figma"
        placeholder="Add a Figma file"
        focusedPlaceholder="Enter a Figma URL"
        validateInput={maybeFigmaUrl => {
          const normalizedLink = normalizeFigmaLink(maybeFigmaUrl);
          if (!normalizedLink) {
            throw Error(`That doesn't appear to be a Figma link`);
          }
          return normalizedLink;
        }}
        onSubmit={async result => {
          ReactEditor.focus(editor);
          const path = ReactEditor.findPath(editor, element);
          const thumbnailId = generateId();
          KitemakerTransforms.setNodes(editor, { url: result, thumbnailId }, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);

          try {
            const { needsAuth } = await createFigmaThumbnail(organization!.id, result, thumbnailId);
            if (unmounted.current) {
              return;
            }
            if (needsAuth) {
              toast.info('You will need to authenticate with Figma first');
              ReactEditor.blur(editor);
              setTimeout(() => {
                externalAuthFlow(
                  `${host}/integrations/figma/auth?organizationId=${
                    organization!.id
                  }&pendingThumbnails=${thumbnailId}`,
                  electronScheme,
                  { exchange: true }
                );
              }, 3000);
            }
          } catch (e) {
            debug(e);
          }
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }

  const loading = !thumbnailData?.thumbnailUrl || !thumbnailLoaded || refreshing;

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <VoidBlock
        element={element}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (!editor.disableModals && thumbnailData?.figmaUrl) {
            modals.openModal(Modals.FigmaEmbed, {
              url: thumbnailData.figmaUrl,
              onClose: () => {
                ReactEditor.focus(editor);
                Transforms.select(editor, ReactEditor.findPath(editor, element));
              },
            });
          }
        }}
      >
        <div
          className={cn(styles.figmaThumbnail, {
            [styles.loading]: loading && !refreshing && !error,
            [styles.hasCollapsedSelection]: selected && selectionCollapsed,
          })}
          ref={ref}
        >
          <div
            className={cn(styles.header, { [styles.collapsed]: collapsed })}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              openFigmaEmbed(e, url);
            }}
          >
            <div className="row">
              <Icon icon="figma" size={IconSize.Size20} className="mr12" />
              <div className="headingS oneLine">
                {thumbnailData?.fileName && (
                  <>
                    {thumbnailData.fileName}
                    {thumbnailData.fileName && thumbnailData.frameName ? ' · ' : ''}
                    {thumbnailData.frameName}
                  </>
                )}
              </div>
            </div>
            {!loading && (
              <VoidActions className={styles.actions}>
                <Tooltip content={collapsed ? 'Expand Figma preview' : 'Minimize Figma preview'}>
                  <IconButton
                    buttonStyle={ButtonStyle.BareSubtle}
                    icon={collapsed ? 'unfold_more' : 'unfold_less'}
                    className={cn(styles.refresh, 'ml4')}
                    onClick={async e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (element.refId) {
                        setCollapsed(old => !old);
                      }
                    }}
                  />
                </Tooltip>
                {editor.inlineComments && editor.entityId && <CommentButton element={element} />}
                <Tooltip content="Refresh Figma thumbnail">
                  <IconButton
                    buttonStyle={ButtonStyle.BareSubtle}
                    icon="refresh"
                    className={styles.refresh}
                    onClick={async e => {
                      if (refreshing) {
                        return;
                      }
                      e.preventDefault();
                      e.stopPropagation();
                      if (thumbnailId) {
                        setRefreshing(true);
                        refreshFigmaThumbnail(thumbnailId, () => {
                          refetch(thumbnailId);
                          setRefreshing(false);
                        });
                      }
                    }}
                  />
                </Tooltip>
              </VoidActions>
            )}
          </div>
          {error && (
            <div className={styles.thumbnail}>
              <ErrorElement error={error} />
            </div>
          )}
          {thumbnailData?.thumbnailUrl && !refreshing && !error && (
            <div className={cn({ maxHeightZero: collapsed })}>
              <RetryingImage
                className={styles.thumbnailImage}
                src={thumbnailData.thumbnailUrl}
                style={{
                  ...(thumbnailData.backgroundColor
                    ? { backgroundColor: thumbnailData.backgroundColor }
                    : {}),
                }}
                onLoad={() => {
                  setThumbnailLoaded(true);
                  ensureVisible();
                  KitemakerEditor.ensureFocusOnScreen(editor, 0);
                }}
                onError={() => {
                  setError('Unable to load Figma thumbnail');
                }}
              />
            </div>
          )}
          {loading && !error && (
            <div className="loadingContainer">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </VoidBlock>
    </div>
  );
}
