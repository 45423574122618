import { forEach } from 'lodash';
import * as React from 'react';
import { isEmail } from 'validator';
import { MemberRole } from '../../sync/__generated/models';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { Select } from '../components/new/select';
import { Setting } from '../components/new/settings';
import { TextValidationInput } from '../components/new/textInput';
import { Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useInviteUsers } from '../syncEngine/actions/organizations';
import styles from './inviteMemberModal.module.scss';

function validateEmails(emails: string[]): string | null {
  const nonValidEmails: string[] = [];

  forEach(emails, e => {
    if (!isEmail(e)) {
      nonValidEmails.push(e);
    }
  });

  if (nonValidEmails.length) {
    return `Found invalid email${nonValidEmails.length > 1 ? 's' : ''}: ${nonValidEmails}`;
  }

  return null;
}

function InviteMemberModalContent() {
  const organization = useOrganization();
  const modalManager = useModals();
  const inviteUsers = useInviteUsers();

  const [memberType, setMemberType] = React.useState<MemberRole>(MemberRole.Admin);
  const [emails, setEmails] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);

  function splitEmails(emailString: string) {
    if (emailString === '') {
      return [];
    }
    return emailString.split(/[ ,;]+/);
  }

  const description = React.useMemo(() => {
    switch (memberType) {
      case MemberRole.Admin:
        return 'Admins can manage all organization settings';
      case MemberRole.Member:
        return '';
      case MemberRole.Guest:
        return 'Guests can only see spaces they are invited to';
    }
  }, [memberType]);

  const submit = React.useCallback(async () => {
    if (error) {
      return;
    }
    const emailList = splitEmails(emails);

    const err = validateEmails(emailList);
    if (err) {
      setError(err);
      return;
    }

    await inviteUsers(emailList, memberType);
    modalManager.closeModal(Modals.InviteMember);
  }, [emails, memberType, inviteUsers, modalManager, error]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        submit();
      }}
    >
      <ModalContentWrapper>
        <Setting vertical title="Email">
          <TextValidationInput
            placeholder="Type one or more emails"
            autoFocus
            value={emails}
            onChange={e => {
              setError(null);
              setEmails(e.currentTarget.value);
            }}
            className={styles.input}
            error={error}
            onBlur={e => {
              setError(validateEmails(splitEmails(e.currentTarget.value)));
            }}
            onIdle={e => {
              setError(validateEmails(splitEmails(e)));
            }}
          />
        </Setting>

        <Setting vertical title="Invite as" description={description}>
          <Select
            values={[
              { value: MemberRole.Admin, label: 'Admin' },
              {
                value: MemberRole.Member,
                label: 'Member',
                disabled: !organization.activeProductId,
              },
              {
                value: MemberRole.Guest,
                label: 'Guest',
                disabled: !organization.activeProductId,
              },
            ]}
            value={memberType}
            onChange={v => setMemberType(v as MemberRole)}
          />
        </Setting>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <Button
          type="button"
          className="mr12"
          onClick={() => modalManager.closeModal(Modals.InviteMember)}
        >
          Cancel
        </Button>
        <Button buttonStyle={ButtonStyle.Primary} disabled={!!error}>
          Invite
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export default function InviteMemberModal() {
  return (
    <Modal modalId={Modals.InviteMember} title="Invite new members">
      <InviteMemberModalContent />
    </Modal>
  );
}
