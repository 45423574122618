import cn from 'classnames';
import { filter, sortBy } from 'lodash';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Canvas, CanvasRef, Edge, ElkRoot, MarkerArrow, Node, NodeChildProps } from 'reaflow';
import { CenterCoords } from 'reaflow/dist/symbols/Edge/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { issueTerm } from '../../../shared/utils/terms';
import { Issue, Space } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import ExternalLink from '../../components/externalLink';
import Hotkey from '../../components/hotkey';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import { CommandContext } from '../../components/new/commandMenuContext';
import { CustomCommand } from '../../components/new/customCommand';
import {
  EntityCard,
  EntityCardHeader,
  EntityCardMetadataContainer,
  EntityMetadataRow,
} from '../../components/new/entityCard';
import { EntityFilterMenu } from '../../components/new/entityFilters';
import { EntityListItemTitle } from '../../components/new/entityListItem';
import {
  EntityEffort,
  EntityImpact,
  EntityLabels,
  EntityMembers,
} from '../../components/new/entityMetadata';
import { Filters } from '../../components/new/filters';
import { IconSize } from '../../components/new/icon';
import { DependencyIcon, DependencyState } from '../../components/new/metadata/dependency';
import { MetadataSize } from '../../components/new/metadata/size';
import Placeholder from '../../components/new/placeholder';
import { ScreenHeader } from '../../components/new/screenHeader';
import { WorkItemMenu } from '../../components/new/workItemMenu';
import { WorkItemStatus } from '../../components/new/workItemMetadata';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useOrganization } from '../../contexts/organizationContext';
import { SpaceProvider } from '../../contexts/spaceContext';
import { OrganizationMarker } from '../../graphql/smartLoad';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { dependencyGraphForOrganizationSelector } from '../../syncEngine/selectors/dependencies';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { highlightedDependencyState } from '../../syncEngine/state';
import { trackerPageLoad } from '../../tracker';
import { filterState } from '../../utils/filtering';
import { LocationState } from '../../utils/history';
import LoadingScreen from '../loadingScreen';
import styles from './dependencyGraphScreen.module.scss';

export const smallHeight = 79;
const halfHeight = 105;
const fullHeight = 131;
export const width = 300;
export const padding = 100;
const FILTER_ID = 'dependency-graph';

function DependencyTypeBadge({
  edge,
  center,
  pathRef,
}: {
  edge: any;
  pathRef: React.MutableRefObject<SVGPathElement> | null;
  center: CenterCoords | null;
}) {
  if (!center) {
    return <></>;
  }

  const size = 24;
  let icon;
  let fill = 'var(--gray2)';
  let stroke = 'var(--gray9)';

  if (edge.icon === 'depends') {
    icon = <DependencyIcon state={DependencyState.DEPENDS_ON} style={{ fill: 'var(--gray9' }} />;
    pathRef?.current.setAttribute('marker-end', 'url(#end-depends-arrow)');
  } else if (edge.icon === 'enables') {
    icon = <DependencyIcon state={DependencyState.ENABLES} style={{ fill: 'var(--gray9' }} />;
    pathRef?.current.setAttribute('marker-end', 'url(#end-enables-arrow)');
  } else if (edge.icon === 'blocks') {
    icon = <DependencyIcon state={DependencyState.BLOCKS} style={{ fill: 'white' }} />;
    stroke = fill = 'var(--orange9)';
    pathRef?.current.setAttribute('marker-end', 'url(#end-blocks-arrow)');
  } else if (edge.icon === 'blocked') {
    icon = <DependencyIcon state={DependencyState.BLOCKED} style={{ fill: 'white' }} />;
    stroke = fill = 'var(--red9)';
    // HACK: since blocked edges are always red, need a way to make the arrow end red as well.
    // Easiest way is to check if the blocked class is applied to the edge.
    pathRef?.current.setAttribute('marker-end', 'url(#end-blocked-arrow)');
  } else {
    pathRef?.current.setAttribute('marker-end', 'url(#end-arrow)');
  }

  const half = size / 2;
  const translateX = center.x - half;
  const translateY = center.y - half;

  if (icon) {
    icon = (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="11" fill={fill} stroke={stroke} strokeWidth={1} />
        <g style={{ transform: `translateX(2px) translateY(2px)` }}>{icon}</g>
      </svg>
    );
  }
  return (
    <g style={{ transform: `translateX(${translateX}px) translateY(${translateY}px)` }}>{icon}</g>
  );
}

function calculateHeight(issue: Issue): number {
  const hasMeta = issue.assigneeIds.length || issue.impactId || issue.effortId;
  const hasLabels = issue.labelIds.length > 0;
  if (hasLabels && hasMeta) {
    return fullHeight;
  } else if (hasLabels || hasMeta) {
    return halfHeight;
  }
  return smallHeight;
}

function WorkItemNode({
  event,
  highlightedSource,
  setHighlightedSource,
}: {
  event: NodeChildProps;
  highlightedSource: string | null;
  setHighlightedSource: any;
}) {
  const history = useHistory();
  const hovered = highlightedSource === event.node.id;
  const item = event.node.data.issue as Issue;
  const space = event.node.data.space as Space;

  const linkTo = {
    pathname: event.node.data.link,
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      node: item.id,
    },
  };

  return (
    <foreignObject
      height={hovered ? calculateHeight(item) : smallHeight}
      width={event.node.width}
      x={0}
      y={0}
      onMouseEnter={() => {
        setHighlightedSource(event.node.id);
      }}
      onClick={() => {
        history.push(linkTo);
      }}
    >
      <EntityCard
        className={cn({
          [styles.blocked]: event.node.data.isBlocked,
          [styles.highlighted]: event.node.data.highlighted,
          [styles.highlightedSource]: hovered,
        })}
      >
        <EntityCardHeader
          entityNumber={`${space?.key}-${item.number}`}
          date={item.updatedAt}
          menuContents={closeMenu => <WorkItemMenu closeMenu={closeMenu} item={item} />}
        >
          <WorkItemStatus id={item.id} className="mr8 grayIcon" size={IconSize.Size16} />
        </EntityCardHeader>
        {/* use the list item version to get some sweet truncation */}
        <EntityListItemTitle type={issueTerm}>{item.title}</EntityListItemTitle>
        {hovered && (
          <>
            <EntityMetadataRow className={styles.labels}>
              <EntityLabels interactable={hovered} entity={item} size={MetadataSize.Small} />
            </EntityMetadataRow>
            <EntityCardMetadataContainer
              members={<EntityMembers interactable={hovered} entity={item} />}
            >
              <EntityImpact interactable={hovered} entity={item} />
              <EntityEffort interactable={hovered} entity={item} />
            </EntityCardMetadataContainer>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(linkTo);
                },
              }}
            />
            <CustomCommand
              command={{
                id: 'open-and-focus-description',
                hotkey: 'd',
                group: CommandGroup.Entities,
                description: `Edit description`,
                priority: 99,
                handler: () => {
                  history.push({
                    ...linkTo,
                    pathname: linkTo.pathname,
                    search: `focusDescription=true`,
                  });
                },
              }}
            />
            <CommandContext
              context={{
                group: CommandGroup.Entities,
                entityIds: [item.id],
                focusedEntityId: item.id,
              }}
            />
          </>
        )}
      </EntityCard>
    </foreignObject>
  );
}

function DependencyGraphContents() {
  const organization = useOrganization();
  const ref = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<CanvasRef>(null);
  const location = useLocation<LocationState>();
  const history = useHistory();
  const currentFilters = useRecoilValue(filterState(FILTER_ID));

  const nodeLookupRef = React.useRef<{ [id: string]: { x: number; y: number; id: string } } | null>(
    null
  );

  const { nodes, edges } = useRecoilValue(
    dependencyGraphForOrganizationSelector({
      organizationId: organization.id,
      filterId: FILTER_ID,
    })
  );

  const [highlightedSource, setHighlightedSource] = useRecoilState(highlightedDependencyState);

  useComponentDidMount(() => {
    let result: string | null = null;
    // if we come back to the modal, force the selection to where it was before
    if (location.state?.node) {
      const { node, ...rest } = location.state;
      history.replace({ pathname: location.pathname, search: location.search, state: rest });
      result = node;
    }
    if (!result) {
      return;
    }
    const idExists = !!nodes.find(n => n.id === result);
    if (!idExists) {
      return;
    }

    setHighlightedSource(result);

    const targetNode = ref.current!;
    const config = { attributes: false, childList: true, subtree: true };

    const callback: MutationCallback = (mutationList, _observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          const node = mutation.addedNodes[0] as any;
          if (node && node.id.split('-')[3] === result) {
            setTimeout(() => {
              scrollIntoView(node, { block: 'center', inline: 'center' });
              observer.disconnect();
            }, 100);
          }
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };
  });

  const [maxWidth, setMaxWidth] = React.useState<number | undefined>(undefined);
  const [maxHeight, setMaxHeight] = React.useState<number | undefined>(undefined);

  const mouseMovedRef = React.useRef<boolean>(false);
  const mouseDownRef = React.useRef<boolean>(false);
  const scrollRef = React.useRef({ top: 0, left: 0, x: 0, y: 0 });

  React.useEffect(() => {
    function handleMouseMove(e: any) {
      if (!mouseMovedRef.current) {
        mouseMovedRef.current = true;
      }
      if (!canvasRef.current || !canvasRef.current.containerRef.current) {
        return;
      }

      if (mouseDownRef.current) {
        const dx = e.clientX - scrollRef.current.x;
        const dy = e.clientY - scrollRef.current.y;

        canvasRef.current.containerRef.current.scrollTop = scrollRef.current.top - dy;
        canvasRef.current.containerRef.current.scrollLeft = scrollRef.current.left - dx;
      }
    }
    function handleMouseDown(e: any) {
      if (!canvasRef.current || !canvasRef.current.containerRef.current) {
        return;
      }

      scrollRef.current = {
        left: canvasRef.current!.containerRef.current!.scrollLeft,
        top: canvasRef.current!.containerRef.current!.scrollTop,
        x: e.clientX,
        y: e.clientY,
      };
      mouseDownRef.current = true;
      canvasRef.current.containerRef.current.style.cursor = 'grabbing';
      canvasRef.current.containerRef.current.style.userSelect = 'none';
    }
    function handleMouseUp() {
      mouseDownRef.current = false;
      if (!canvasRef.current || !canvasRef.current.containerRef.current) {
        return;
      }
      canvasRef.current.containerRef.current.style.cursor = 'grab';
      canvasRef.current.containerRef.current.style.removeProperty('user-select');
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousedown', handleMouseDown);
    };
  });

  React.useLayoutEffect(() => {
    // Hacky code to add new arrow styles, so that the arrows are the same color as the edge.
    // Not supported by the library natively, so HACK HACK HACK...
    if (!ref.current) {
      return;
    }
    const svgs = ref.current.getElementsByTagName('svg');
    if (!svgs.length) {
      return;
    }
    const svg = svgs.item(0)!;
    const defs = Array.from(svg.children).find(e => e.tagName === 'defs');
    if (!defs) {
      return;
    }

    for (const name of ['depends', 'enables', 'blocks', 'blocked']) {
      const newMarker = defs.children.item(0)!.cloneNode(true)! as SVGElement;
      newMarker.setAttribute('id', `end-${name}-arrow`);
      const path = newMarker.children.item(0) as SVGPathElement;
      path.setAttribute('class', styles[`${name}-arrow`]);
      defs.appendChild(newMarker);
    }
  }, []);

  function handleLayoutChange(layout: ElkRoot) {
    setMaxHeight((layout?.height ?? 0) + padding);
    setMaxWidth((layout?.width ?? 0) + padding);
    const grouped = layout.children?.reduce((acc, child) => {
      acc[child.id] = { x: child.x, y: child.y, id: child.id };
      return acc;
    }, Object.create(null));
    nodeLookupRef.current = grouped;
    let idToFocus = highlightedSource;
    if (!highlightedSource || !grouped[highlightedSource]) {
      const newHighlight = sortBy(grouped, [n => n.x, n => n.y])[0]?.id;
      if (newHighlight) {
        idToFocus = newHighlight;
        setHighlightedSource(newHighlight);
      }
    }
    const node = document.querySelectorAll(`[id$='${idToFocus}']`)[0];
    if (node) {
      const parent = node.parentElement;
      if (parent) {
        node.remove();
        parent.appendChild(node);
      }
      if (!mouseMovedRef.current) {
        scrollIntoView(node, { block: 'center', inline: 'center', scrollMode: 'if-needed' });
      }
    }
  }

  // FIXME: loads of copy pasted code, inefficient, stupid code
  function moveDown() {
    const domNodes = nodeLookupRef.current;
    if (!domNodes || !highlightedSource) {
      return;
    }

    const highlightedCords = domNodes[highlightedSource];
    const sameX = sortBy(
      filter(domNodes, v => v.x === highlightedCords.x),
      'y'
    );

    const index = sameX.findIndex(n => n.id === highlightedSource);
    const previous = sameX[index + 1];
    if (previous) {
      setHighlightedSource(previous.id);
      mouseMovedRef.current = false;
    }
  }

  function moveLeft() {
    const domNodes = nodeLookupRef.current;
    if (!domNodes || !highlightedSource) {
      return;
    }
    const filteredEdges = edges.filter(e => e.to === highlightedSource);
    const fromNodes = filteredEdges.map(e => e.from);

    const newHighlight = sortBy(
      fromNodes.filter(n => domNodes[n].x <= domNodes[highlightedSource].x),
      n => domNodes[n].x
    ).reverse()[0];

    if (newHighlight) {
      setHighlightedSource(newHighlight);
      mouseMovedRef.current = false;
    }
  }

  function moveRight() {
    const domNodes = nodeLookupRef.current;
    if (!domNodes || !highlightedSource) {
      return;
    }
    const filteredEdges = edges.filter(e => e.from === highlightedSource);
    const toNodes = filteredEdges.map(e => e.to);

    const sorted = sortBy(
      toNodes.filter(n => domNodes[n].x >= domNodes[highlightedSource].x),
      n => domNodes[n].x
    );

    const newHighlight = sorted[0];

    if (newHighlight) {
      setHighlightedSource(newHighlight);
      mouseMovedRef.current = false;
    }
  }

  function moveUp() {
    const domNodes = nodeLookupRef.current;
    if (!domNodes || !highlightedSource) {
      return;
    }

    const highlightedCords = domNodes[highlightedSource];
    const sameX = sortBy(
      filter(domNodes, v => v.x === highlightedCords.x),
      'y'
    );

    const index = sameX.findIndex(n => n.id === highlightedSource);
    const previous = sameX[index - 1];
    if (previous) {
      setHighlightedSource(previous.id);
      mouseMovedRef.current = false;
    }
  }

  return (
    <div className={styles.dependencyScreen}>
      <TitleSetter title={`${organization.name} · Dependency graph`} />
      <DependencyGraphBreadcrumbs />
      <Filters id={FILTER_ID} />
      <Hotkey
        command={{
          id: 'graph-down',
          hotkey: 'down',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveDown();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-up',
          hotkey: 'up',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveUp();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-left',
          hotkey: 'left',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveLeft();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-right',
          hotkey: 'right',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveRight();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-down-vim',
          hotkey: 'j',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveDown();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-up-vim',
          hotkey: 'k',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveUp();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-left-vim',
          hotkey: 'h',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveLeft();
          },
        }}
      />
      <Hotkey
        command={{
          id: 'graph-right-vim',
          hotkey: 'l',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
            moveRight();
          },
        }}
      />
      <div className={styles.graph} ref={ref}>
        {nodes.length === 0 && (
          <div className={styles.emptyState}>
            <Placeholder icon="dependency_enables" title={'No dependencies'}>
              <span className="grayed textCenter">
                <p>
                  Add dependencies between work items and you'll find an overview of all of them
                  here.
                  <br />
                  Learn more in the{' '}
                  <ExternalLink
                    href="https://guide.kitemaker.co/overview/dependencies-and-dependency-graph"
                    className="link hoverOnly headingS"
                  >
                    Kitemaker Guide.
                  </ExternalLink>
                </p>
              </span>
            </Placeholder>
          </div>
        )}
        {nodes.length > 0 && (
          <Canvas
            // NOTE: if we change the nodes/elements while the graph is on the screen, we get weird orphaned edges.
            // By setting the key in this way, we force react to remount the whole thing when the filters
            // change.
            key={JSON.stringify(currentFilters)}
            onLayoutChange={handleLayoutChange}
            maxZoom={0}
            nodes={nodes}
            edges={edges}
            ref={canvasRef}
            direction={'RIGHT'}
            readonly
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            arrow={<MarkerArrow className={cn(styles.arrow, styles.base)} />}
            edge={<Edge>{props => <DependencyTypeBadge {...props} />}</Edge>}
            node={
              <Node rx={4} ry={4}>
                {event => (
                  <SpaceProvider key={event.node.id} spaceId={event.node.data.space.id}>
                    <WorkItemNode
                      event={event}
                      setHighlightedSource={(id: string) => {
                        if (mouseMovedRef.current) {
                          setHighlightedSource(id);
                        }
                      }}
                      highlightedSource={highlightedSource}
                    />
                  </SpaceProvider>
                )}
              </Node>
            }
          />
        )}
      </div>
    </div>
  );
}

function DependencyGraphBreadcrumbs() {
  return (
    <ScreenHeader showSidebarOpener compensateForMacOSTrafficLights="auto">
      <Breadcrumbs breadcrumbs={[{ name: 'Dependency graph' }]} />
      <EntityFilterMenu id={FILTER_ID} entityType="Issue" />
    </ScreenHeader>
  );
}

export function DependencyGraphScreen() {
  const organization = useOrganization();

  React.useEffect(() => trackerPageLoad('DependencyGraph'), []);
  const organizationLoaded = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  if (!organizationLoaded) {
    return <LoadingScreen />;
  }
  return (
    <Screen>
      <DependencyGraphContents />
    </Screen>
  );
}
