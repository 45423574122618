import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useClient } from 'urql';
import { AuthResult, createOrganization, organizationNameAvailable } from '../../api/auth';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { TextInputSize, TextInput } from '../../components/new/textInput';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { toast } from '../../components/toast';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerPageLoad } from '../../tracker';
import LoadingScreen from '../loadingScreen';

const ALREADY_EXISTS_MESSAGE = `An organization with that name already exists. If you want to join an existing organization, have one of your teammates invite you to Kitemaker.`;

export function NewOrganizationScreenContents({
  onSubmit,
}: {
  onSubmit: (data: { name: string; onError: () => void }) => void;
}) {
  const client = useClient();
  const [name, setName] = React.useState('');
  const nameRef = React.useRef(name);
  nameRef.current = name;
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [available, setAvailable] = React.useState(true);
  const toastRef = React.useRef<(() => void) | null>(null);

  useComponentDidMount(() => {
    return () => toastRef.current?.();
  });

  const valid = React.useMemo(() => {
    return !!name;
  }, [name]);

  const checkAvailable = React.useCallback(
    async (recheck?: boolean) => {
      if (requestInProgress && !recheck) {
        return true;
      }

      const isAvailable = await organizationNameAvailable(client, nameRef.current);
      setAvailable(isAvailable);

      if (!isAvailable && !toastRef.current) {
        toastRef.current = toast.warn(ALREADY_EXISTS_MESSAGE, { duration: Infinity });
      }
      return isAvailable;
    },
    [name, requestInProgress]
  );

  if (requestInProgress) {
    return <LoadingScreen />;
  }

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL oneLine mb16">Create organization</div>
      <div className="bodyM grayed mb32">Create a new Kitemaker organization for your team.</div>
      <form
        onSubmit={async e => {
          e.preventDefault();
          e.stopPropagation();
          if (!valid || requestInProgress) {
            return;
          }

          setRequestInProgress(true);
          const recheckAvailable = await checkAvailable(true);
          if (!recheckAvailable) {
            setRequestInProgress(false);
            return;
          }
          onSubmit({ name, onError: () => setRequestInProgress(false) });
        }}
      >
        <label htmlFor="name">Organization name</label>
        <TextInput
          name="name"
          inputSize={TextInputSize.Large}
          className="fullWidth"
          autoFocus
          disabled={requestInProgress}
          placeholder={`E.g. "Your company" `}
          value={name}
          minLength={2}
          onChange={e => {
            toastRef.current?.();
            toastRef.current = null;
            setAvailable(true);
            setName(e.currentTarget.value);
          }}
          onIdle={() => {
            checkAvailable();
          }}
        />
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          type="submit"
          className="fullWidth mt24"
          disabled={!valid || !available || requestInProgress}
        >
          <div className="row">Continue</div>
        </Button>
      </form>
    </div>
  );
}

export function NewOrganizationScreen({
  onCompleted,
}: {
  onCompleted: (options: AuthResult) => void;
}) {
  const history = useHistory();

  React.useEffect(() => {
    trackerPageLoad('New organization');
  }, []);

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Create organization" />
      <NewOrganizationScreenContents
        onSubmit={async ({ name, onError }) => {
          try {
            await createOrganization(
              history,
              result => {
                setTimeout(() => onCompleted(result), 500);
              },
              name.trim()
            );
          } catch (e) {
            toast.error(e.message);
            onError();
          }
        }}
      />
    </AuxiliaryScreen>
  );
}
