import cn from 'classnames';
import { last, uniq } from 'lodash';
import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { useOrganization } from '../../contexts/organizationContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { localStorageEffect } from '../../syncEngine/effects';
import { vimDown, vimLeft, vimRight, vimUp } from '../../utils/config';
import { allEmojis, allEmojisByCategory, emojiCategories } from '../../utils/emoji';
import { scrollIntoView } from '../../utils/scrolling';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../utils/search';
import { EmojiSize, Emoji as RenderedEmoji } from './emoji';
import styles from './emojiPicker.module.scss';
import { Hotkey } from './hotkey';
import { hasMouseMoved } from './keyNavigation/dom';
import { TextInput, TextInputSize } from './textInput';

const DEFAULT_COLUMN_COUNT = 9;
const SMALL_SCREEN_COLUMN_COUNT = 6;

const emojiSearcher = new FuzzySearcher<{ id: string; name?: string }>(
  FuzzySearcherConfiguration.Autocomplete,
  ['id', 'name'],
  [...allEmojis]
);

type GridElementBase = {
  type: 'category' | 'emoji';
};

interface CategoryElement extends GridElementBase {
  type: 'category';
  category: string;
}

interface EmojiElement extends GridElementBase {
  type: 'emoji';
  id: string;
  name?: string;
}
type GridElement = CategoryElement | EmojiElement;

const popular: GridElement[] = [
  { type: 'category', category: 'Popular' },
  // the _ prefix is deliberate to avoid duplicate keynav IDs
  {
    type: 'emoji',
    name: 'thumbsup',
    id: '_thumbsup',
  },
  {
    type: 'emoji',
    name: 'thumbsdown',
    id: '_thumbsdown',
  },
  {
    type: 'emoji',
    name: 'slightly_smiling_face',
    id: '_slightly_smiling_face',
  },
  {
    type: 'emoji',
    name: 'tada',
    id: '_tada',
  },
  {
    type: 'emoji',
    name: 'confused',
    id: '_confused',
  },
  {
    type: 'emoji',
    name: 'heart',
    id: '_heart',
  },
  {
    type: 'emoji',
    name: 'rocket',
    id: '_rocket',
  },
  {
    type: 'emoji',
    name: 'eyes',
    id: '_eyes',
  },
  {
    type: 'emoji',
    name: 'thinking_face',
    id: '_thinking_face',
  },
];

const baseDefaultGrid = emojiCategories.flatMap(category => {
  return [
    { type: 'category', category },
    ...(allEmojisByCategory[category] ?? []).map(e => ({ type: 'emoji', name: e.name, id: e.id })),
  ];
}) as GridElement[];

const recent = atom<string[]>({
  key: 'recentEmojis',
  default: [],
  effects: [localStorageEffect('__recentEmojis')],
});

function trimEmojiId(id: string): string {
  return id.startsWith('_') ? id.substring(1) : id;
}

function EmojiComponent({
  emoji,
  onPicked,
  onHover,
  isCurrent,
}: {
  emoji: string;
  onPicked: (emoji: string) => void;
  onHover: (emoji: string) => void;
  isCurrent: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (isCurrent && ref.current && !hasMouseMoved) {
      scrollIntoView(ref.current, { block: 'center', scrollMode: 'if-needed' });
    }
  }, [isCurrent]);

  return (
    <div
      className={cn(styles.emoji, {
        [styles.current]: isCurrent,
      })}
      onClick={() => onPicked(emoji)}
      onMouseEnter={() => {
        if (hasMouseMoved) {
          onHover(emoji);
        }
      }}
      ref={ref}
    >
      <RenderedEmoji emoji={trimEmojiId(emoji)} />
    </div>
  );
}

const Emoji = React.memo(EmojiComponent);

function FilterComponent(
  {
    onResults,
    defaultGrid,
  }: {
    onResults: (results: GridElement[]) => void;
    defaultGrid: GridElement[];
  },
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const [filter, setFilter] = React.useState('');
  const onIdle = React.useCallback(
    (filter: string) => {
      if (!filter) {
        onResults(defaultGrid);
        return;
      }
      const results = [
        { type: 'category', category: 'Search results' },
        ...emojiSearcher
          .search(filter)
          .map(({ item }) => ({ type: 'emoji', name: item.name, id: item.id })),
      ] as GridElement[];
      onResults(results);
    },
    [filter, onResults]
  );

  return (
    <div className={styles.filterContainer}>
      <TextInput
        inputSize={TextInputSize.Small}
        ref={ref}
        autoFocus
        value={filter}
        onChange={e => setFilter(e.currentTarget.value)}
        className="fullWidth"
        placeholder="Search emojis"
        idleTimeoutLength={250}
        onIdle={onIdle}
        onKeyDown={e => {
          if (e.key.toLowerCase() === 'arrowdown' || e.key.toLowerCase() === 'tab') {
            e.preventDefault();
            e.stopPropagation();
            e.currentTarget.blur();
          }
        }}
      />
    </div>
  );
}

const Filter = React.forwardRef(FilterComponent);

function gridToKeyNavGrid(columns: number, grid: GridElement[]) {
  const keyNavGrid: string[][] = [];
  const keyNavLookup: Record<string, [number, number]> = {};
  let row = 0;
  let col = 0;

  for (const element of grid) {
    keyNavGrid[row] = keyNavGrid[row] ?? [];

    // if we're done, pad out the row
    if (element.type === 'category') {
      if (col > 0) {
        const padOutWith = last(keyNavGrid[row])!;
        while (col < columns) {
          keyNavGrid[row][col] = padOutWith;
          col++;
        }
        row++;
        col = 0;
      }
      continue;
    }

    keyNavGrid[row][col] = element.id;
    keyNavLookup[element.id] = [row, col];
    col++;

    if (col === columns) {
      row++;
      col = 0;
    }
  }

  return { keyNavGrid, keyNavLookup };
}

function Footer({ currentEmoji }: { currentEmoji?: string }) {
  return (
    <div className={styles.footer}>
      {currentEmoji && (
        <div className={styles.currentEmojiContainer}>
          <div className={styles.currentEmoji}>
            <RenderedEmoji emoji={trimEmojiId(currentEmoji)} size={EmojiSize.Emoji28} />
          </div>
          <div className="ellipsis headingS">:{trimEmojiId(currentEmoji)}:</div>
        </div>
      )}
    </div>
  );
}

function KeyNavHotkeys({ move }: { move: (direction: 'left' | 'right' | 'up' | 'down') => void }) {
  return (
    <>
      <Hotkey hotkey="up" global handler={() => move('up')} />
      <Hotkey hotkey={vimUp} handler={() => move('up')} />
      <Hotkey hotkey="down" global handler={() => move('down')} />
      <Hotkey hotkey={vimDown} handler={() => move('down')} />
      <Hotkey hotkey="left" handler={() => move('left')} />
      <Hotkey hotkey={vimLeft} handler={() => move('left')} />{' '}
      <Hotkey hotkey="right" handler={() => move('right')} />
      <Hotkey hotkey={vimRight} handler={() => move('right')} />
    </>
  );
}

export function EmojiPicker({ onPicked }: { onPicked: (emoji: string) => void }) {
  const organization = useOrganization();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const smallScreen = useIsSmallScreen();
  const columns = smallScreen ? SMALL_SCREEN_COLUMN_COUNT : DEFAULT_COLUMN_COUNT;
  const [recentEmojis, setRecentEmojis] = useRecoilState(recent);

  const customEmojis = React.useMemo(() => {
    if (!Object.keys(organization.customEmojis ?? {}).length) {
      return [];
    }

    return [
      { type: 'category', category: 'Custom' },
      ...Object.keys(organization.customEmojis).map(e => ({
        type: 'emoji',
        id: e,
        name: e,
      })),
    ] as GridElement[];
  }, [organization.customEmojis]);

  const defaultGrid = React.useMemo<GridElement[]>(() => {
    if (recentEmojis.length) {
      return [
        { type: 'category', category: 'Recent' },
        ...recentEmojis.map(e => ({ type: 'emoji', id: `_${e}`, name: e })),
        ...baseDefaultGrid,
        ...customEmojis,
      ] as GridElement[];
    }

    return [...popular, ...baseDefaultGrid, ...customEmojis];
  }, [recentEmojis, customEmojis]);

  React.useEffect(() => {
    emojiSearcher.reindexOptions(
      Object.keys(organization.customEmojis).map(id => ({ id })),
      (a, b) => a.id === b.id
    );
  }, [organization.customEmojis]);

  const [grid, setGrid] = React.useState(defaultGrid.slice(0, columns * 8));
  const deferredGrid = React.useDeferredValue(grid);

  const { keyNavGrid, keyNavLookup } = React.useMemo(
    () => gridToKeyNavGrid(columns, deferredGrid),
    [columns, deferredGrid]
  );

  React.useLayoutEffect(() => {
    setCurrentEmoji(keyNavGrid[0]?.[0]);
  }, [keyNavGrid]);

  const [currentEmoji, setCurrentEmoji] = React.useState<string | undefined>(keyNavGrid[0]?.[0]);

  useComponentDidMount(() => {
    setTimeout(() => setGrid(defaultGrid));
  });

  const pick = React.useCallback(
    (emoji: string) => {
      setRecentEmojis(previous =>
        uniq([trimEmojiId(emoji), ...previous.filter(p => p !== emoji)]).slice(0, columns)
      );
      onPicked(trimEmojiId(emoji));
    },
    [columns, setRecentEmojis, onPicked]
  );

  const hover = React.useCallback(
    (emoji: string) => {
      setCurrentEmoji(emoji);
    },
    [setCurrentEmoji]
  );

  const move = React.useCallback(
    (direction: 'left' | 'right' | 'up' | 'down') => {
      setCurrentEmoji(currentEmoji => {
        const position = keyNavLookup[currentEmoji ?? ''];
        if (!position) {
          return currentEmoji;
        }

        const [row, col] = position;

        const currentRow = keyNavGrid[row];
        switch (direction) {
          case 'left':
            if (col === 0) {
              return currentEmoji;
            }
            return keyNavGrid[row][col - 1];
          case 'right':
            if (col === currentRow.length - 1 || currentRow[col + 1] === currentRow[col]) {
              return currentEmoji;
            }
            return keyNavGrid[row][col + 1];
          case 'up':
            if (row === 0) {
              inputRef.current?.focus();
              return currentEmoji;
            }
            return keyNavGrid[row - 1][col];
          case 'down':
            if (row === keyNavGrid.length - 1) {
              return currentEmoji;
            }
            return keyNavGrid[row + 1][col];
        }
      });
    },
    [keyNavGrid, keyNavLookup, setCurrentEmoji]
  );

  // just in case we need to make it responsive, we won't count on the # of columns
  const renderedGridElements = deferredGrid.map(g => {
    if (g.type === 'category') {
      return (
        <div
          key={g.category}
          className={styles.header}
          style={{ gridColumn: `1 / ${columns + 1}` }}
        >
          {g.category}
        </div>
      );
    }

    return (
      <Emoji
        key={g.id}
        emoji={g.id}
        onPicked={pick}
        isCurrent={currentEmoji === g.id}
        onHover={hover}
      />
    );
  });

  return (
    <div className={styles.picker}>
      <Filter onResults={setGrid} defaultGrid={defaultGrid} ref={inputRef} />
      <div
        className={styles.grid}
        style={
          {
            '--column-count': columns,
          } as React.CSSProperties
        }
      >
        {renderedGridElements}
      </div>
      <Footer currentEmoji={currentEmoji} />
      <Hotkey
        hotkey="enter"
        global
        handler={() => {
          if (currentEmoji) {
            pick(currentEmoji);
          }
        }}
      />
      <KeyNavHotkeys move={move} />
    </div>
  );
}
