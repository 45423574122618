import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import debugModule from 'debug';
import { range } from 'lodash';
import NodeCache from 'node-cache';
import { Path } from 'slate';
import ImageCompressor from 'uppy-plugin-image-compressor';
import uuid from 'uuid';
import { Elements } from '../../shared/slate/types';
import { isDocumentEmpty } from '../../shared/slate/utils';
import { toast } from '../components/toast';
import { KitemakerEditor } from '../slate/kitemakerEditor';
import { KitemakerTransforms } from '../slate/kitemakerTransforms';
import { EditorType } from '../slate/types';
import { LocalPreparePlugin } from './localPreparePlugin';
import { prettyFileSize } from './units';

const debug = debugModule('fileUploader');

const { contentStorage } = JSON.parse(
  document.getElementById('initial-data')?.innerText ?? '{}'
) as {
  contentStorage: string;
};

const uploadCache = new NodeCache({
  stdTTL: 300,
});
export const pendingUploads = new NodeCache({
  stdTTL: 300,
});

type ElementTypes = Elements.Image | Elements.Video | Elements.File;
const defaultElementType = Elements.File;
const elementTypeMap: { [index: string]: ElementTypes } = {
  image: Elements.Image,
  video: Elements.Video,
};

export interface FileUploaderOptions {
  multi?: boolean;
  path?: string;
  onBeforeUpload?: () => void;
  autoProceed?: boolean;
  maxSize?: { width: number; height: number };
}

export interface UploadResult {
  url: string;
  size: number;
  name: string;
}

function getMaxFileSize() {
  const initialData = document.getElementById('initial-data');
  if (!initialData) {
    return 1024 * 1024 * 10;
  }

  return (
    JSON.parse(initialData.innerText) as {
      maxFileSize: number;
    }
  ).maxFileSize;
}

const maxFileSize = getMaxFileSize();

export function fileUploader(path: string, options?: FileUploaderOptions) {
  const { multi, onBeforeUpload, autoProceed, maxSize } = options ?? {};
  const uploader = Uppy({
    meta: { path },
    autoProceed,
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: multi ? null : 1,
      maxFileSize,
      allowedFileTypes: null,
    },
    onBeforeUpload: files => {
      if (Object.values(files).every(f => f.size === 0)) {
        return {};
      }
      onBeforeUpload?.();
      return files;
    },
  });
  if (maxSize) {
    uploader.use(ImageCompressor, {
      maxWidth: maxSize.width,
      maxHeight: maxSize.height,
    });
  }
  if (contentStorage === 'cloud') {
    uploader.use(AwsS3 as any, {
      getUploadParameters(file: any) {
        return fetch('/content/prepare', {
          method: 'post',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            name: file.name,
            type: file.type,
            meta: file.meta,
            path,
          }),
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            const { uploadUrl, url, name, headers } = data.data;
            uploadCache.set(uploadUrl, { url, name });

            return {
              method: 'put',
              url: uploadUrl,
              fields: [],
              headers,
            };
          });
      },
    });
  } else {
    uploader.use(LocalPreparePlugin, { path });
    uploader.use(XHRUpload, {
      endpoint: '/content/local',
      formData: true,
    });
  }

  return uploader;
}

export function parseUploadResult(uploadResult: Uppy.UploadResult): UploadResult[] {
  if (uploadResult.failed.length) {
    throw Error('Upload error');
  }

  if (contentStorage === 'cloud') {
    const results = uploadResult.successful.map((s: any) => {
      const cacheKey = s.xhrUpload.endpoint;
      const cached = uploadCache.get<{ name: string; url: string }>(cacheKey);
      if (!cached) {
        throw Error('Upload error');
      }
      uploadCache.del(cacheKey);
      return {
        url: cached.url,
        name: cached.name,
        size: s.size,
      };
    });

    for (const r of results) {
      pendingUploads.del(r.url);
    }

    return results;
  }

  const results = uploadResult.successful.map((s: any) => ({
    url: s.response.body.data.url,
    size: s.response.body.data.size,
    name: s.response.body.data.name,
  }));

  for (const r of results) {
    pendingUploads.del(r.url);
  }

  return results;
}

function blockType(fileType: string): ElementTypes {
  const type = fileType.split('/')[0];
  return elementTypeMap[type] ?? defaultElementType;
}

export async function uploadFilesFromTransfer(
  editor: EditorType,
  uploader: Uppy.Uppy,
  files: any,
  path: string
) {
  if (files.every((f: any) => f.size === 0)) {
    return;
  }

  const uploadResults = prepareUpload(uploader, path, files);
  const blocks = uploadResults.map(ul =>
    KitemakerTransforms.firstAvailableElementOfType(editor, blockType(ul.type), {
      url: ul.url,
      name: ul.name,
      size: ul.size,
    })
  );

  try {
    const result = await uploader.upload();
    parseUploadResult(result);
  } catch (e) {
    debug(`Error uploading files ${e.message}`);
    for (const path of blocks.reverse()) {
      KitemakerTransforms.removeNodes(editor, { at: path });
    }
    if (e.isRestriction) {
      toast.error(
        `Please ensure that your file does not exceed the maximum allowable size (${prettyFileSize(
          maxFileSize
        )})`
      );
    } else {
      toast.error('There was an error uploading your file');
    }
  } finally {
    uploader.reset();
  }
}

export async function uploadFilesFromDragAndDrop(
  editor: EditorType,
  uploader: Uppy.Uppy,
  files: any,
  at: Path,
  path: string
) {
  if (files.every((f: any) => f.size === 0)) {
    return;
  }

  const empty = isDocumentEmpty(editor.children);
  const uploadResults = prepareUpload(uploader, path, files);
  KitemakerEditor.withoutNormalizing(editor, () => {
    if (empty) {
      KitemakerTransforms.removeNodes(editor, { at: [0] });
      at = [0];
    }

    KitemakerTransforms.insertNodes(
      editor,
      uploadResults.map(ul => ({
        type: blockType(ul.type),
        url: ul.url,
        name: ul.name,
        size: ul.size,
        children: [{ text: '' }],
      })),
      { at, select: true }
    );
  });

  const blocks = range(0, uploadResults.length).map(i => {
    const newPath = [...at];
    newPath[0] += i;
    return newPath;
  });

  try {
    const result = await uploader.upload();
    parseUploadResult(result);
  } catch (e) {
    debug(`Error uploading files ${e.message}`);
    for (const p of blocks.reverse()) {
      KitemakerTransforms.removeNodes(editor, { at: p });
    }
    if (e.isRestriction) {
      toast.error(
        `Please ensure that your file does not exceed the maximum allowable size (${prettyFileSize(
          maxFileSize
        )})`
      );
    } else {
      toast.error('There was an error uploading your file');
    }
  } finally {
    uploader.reset();
  }
}

export function prepareFileUrl(path: string, fileId: string, fileName: string): string {
  return `/content/${path}/${fileId}/${encodeURI(fileName.normalize())}`;
}

export function prepareUpload(
  uploader: Uppy.Uppy,
  path: string,
  files: Array<File>
): { fileId: string; type: string; url: string; size: number; name: string }[] {
  return files.map(file => {
    const fileId = uuid.v4();
    const url = prepareFileUrl(path, fileId, file.name);
    pendingUploads.set(url, true);

    uploader.addFile({
      name: file.name,
      type: file.type,
      data: file,
      isRemote: false,
      meta: {
        fileId,
      },
    });

    return { fileId, type: file.type, url, name: file.name, size: file.size };
  });
}
