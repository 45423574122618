import { uniqBy } from 'lodash';
import { selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { impactLevelsByOrganization, impactLevelsBySpace } from '../../../sync/__generated/indexes';
import { Impact } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';

export const impactSelector = selectorFamily({
  key: 'ImpactLevels',
  get:
    (impactId: string | null | undefined) =>
    ({ get }) => {
      if (!impactId) {
        return null;
      }
      return notDeleted(get(syncEngineState(impactId)) as Impact | null);
    },
});

export const impactsSelector = selectorFamily({
  key: 'ImpactsLevels',
  get:
    (impactIds: string[]) =>
    ({ get }) => {
      return filterNotNull(impactIds.map(impactId => get(impactSelector(impactId))));
    },
});

export const impactLevelsForSpaceSelector = selectorFamily({
  key: 'ImpactLevelsForSpace',
  get:
    (spaceId: string | null | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }
      const impactIds = get(indexKeyState(indexKey(impactLevelsBySpace, spaceId)));
      return filterNotDeletedNotNull(
        impactIds.map(impactId => get(syncEngineState(impactId)) as Impact | null)
      );
    },
});

export const impactLevelsForOrganizationSelector = selectorFamily({
  key: 'ImpactLevelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const impactIds = get(indexKeyState(indexKey(impactLevelsByOrganization, organizationId)));
      return filterNotDeletedNotNull(
        impactIds.map(impactId => get(syncEngineState(impactId)) as Impact | null)
      );
    },
});

export const globalImpactLevelsForOrganizationSelector = selectorFamily({
  key: 'GlobalImpactLevelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const impacts = get(impactLevelsForOrganizationSelector(organizationId));
      return impacts.filter(impact => !impact.spaceId);
    },
});

export const uniqueImpactLevelsForOrganizationSelector = selectorFamily({
  key: 'UniqueImpactLevelsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const impactLevels = get(impactLevelsForOrganizationSelector(organizationId));
      return uniqBy(impactLevels, 'name');
    },
});
