import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  FEEDBACK_EMAIL_SCOPES,
  FULL_SYNC_SCOPES,
  FULL_SYNC_USER_SCOPES,
  SlackConfig,
  SlackConfig2,
  slackScopes,
} from '../../../../../shared/slack';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { useReauthorize } from '../../../../api/slack';
import BackButton from '../../../../components/new/backButton';
import { ButtonStyle } from '../../../../components/new/button';
import { IntegrationIcon } from '../../../../components/new/integrationIcon';
import {
  AdminButton,
  IntegrationSettingsPage,
  Setting,
  SettingsSection,
} from '../../../../components/new/settings';
import { Switch } from '../../../../components/new/switch';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { isElectron, openLinkInBrowser } from '../../../../electronIntegration';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import {
  integrationsForOrganizationSelector,
  organizationPath,
} from '../../../../syncEngine/selectors/organizations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

function ExistingSlackInstallation({ integrationId }: { integrationId: string }) {
  const { host } = useConfiguration();
  const integration = useRecoilValue(integrationsSelector(integrationId));
  const config = React.useMemo(
    () => JSON.parse(integration?.config || '{}') as SlackConfig2,
    [integration]
  );

  function manageOnSlack() {
    const url = `${host}/integrations/slack/manage/${integrationId}`;
    if (isElectron()) {
      openLinkInBrowser(url);
    } else {
      window.open(url);
    }
  }

  return (
    <div className="rowBetween mb4">
      <div className="row grow ellipsis">
        <IntegrationIcon integrationType={IntegrationType.Slack} colored className="mr8" />
        {config.teamName}
      </div>

      <AdminButton buttonStyle={ButtonStyle.Secondary} onClick={manageOnSlack}>
        Manage in Slack
      </AdminButton>
    </div>
  );
}

export function ManageSlackIntegrations() {
  const organization = useOrganization();
  const integrations = useRecoilValue(integrationsForOrganizationSelector(organization.id)).filter(
    i => i.type === IntegrationType.Slack2
  );
  const { host, electronScheme } = useConfiguration();
  const [fullSync, setFullSync] = React.useState(true);
  const [emailPermission, setEmailPermission] = React.useState(true);

  function startInstallation() {
    const additionalScopes = [
      ...(fullSync ? FULL_SYNC_SCOPES : []),
      ...(emailPermission ? FEEDBACK_EMAIL_SCOPES : []),
    ];
    const additionalUserScopes = fullSync ? FULL_SYNC_USER_SCOPES : [];

    externalAuthFlow(
      `${host}/integrations/slack/install?organizationId=${
        organization.id
      }&additionalScopes=${encodeURIComponent(
        additionalScopes.join(',')
      )}&additionalUserScopes=${encodeURIComponent(additionalUserScopes.join(','))}`,
      electronScheme,
      {
        redirectBaseUrl: organizationPath(organization, 'settings/integrations/slack2'),
        redirectQueryParams: {
          pendingIntegration: 'slack2',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Slack2}
      description="Manage your installations of the Kitemaker Slack app"
    >
      {integrations.length > 0 && (
        <SettingsSection title="Existing installations">
          {integrations.map(i => (
            <ExistingSlackInstallation key={i.id} integrationId={i.id} />
          ))}
        </SettingsSection>
      )}
      <SettingsSection title="New installation">
        <p>The slack integration allows you to:</p>
        <p>
          <ul>
            <li>Get information about mentioned {issueTerm}s in Slack</li>
            <li>Create work items from Slack</li>
            <li>Create feedback from Slack</li>
            <li>Sync emojis from Slack to Kitemaker</li>
          </ul>
        </p>

        <Setting
          title="Full message access"
          description={`In order to sync Slack threads to feedback and to watch for mentions of work items, initiatives and more, Kitemaker will need access to read messages for channels in which the Kitemaker bot is present.`}
        >
          <Switch checked={fullSync} onChange={setFullSync} />
        </Setting>
        <Setting
          title="User email access"
          description={`In order to associate users' email address with feedback created from Slack, Kitemaker's Slack app will need access to users' email addresses.`}
        >
          <Switch checked={emailPermission} onChange={setEmailPermission} />
        </Setting>
        <p>
          To set up the integration, we'll need to install the Kitemaker app in your Slack
          organization.
        </p>
      </SettingsSection>
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Slack integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function EditSlackIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'settings/integrations');
  const reauthorize = useReauthorize();

  const config = React.useMemo(
    () => JSON.parse(integration?.config || '{}') as SlackConfig,
    [integration]
  );

  const scopesUpToDate = React.useMemo(() => {
    return slackScopes.every(scope => (config.scopes ?? []).includes(scope));
  }, [config]);

  if (!integration) {
    return <IntegrationNotFound />;
  }

  function manageOnSlack() {
    if (!config.url) {
      return;
    }
    if (isElectron()) {
      openLinkInBrowser(config.url);
    } else {
      window.open(config.url);
    }
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Slack}
      description={
        <>
          <p>
            Get information about mentioned {issueTerm}s in Slack and link Slack conversations to
            Kitemaker {issueTerm}s
          </p>
          <p>
            The integration is connected to the Slack team &quot;{config.teamName}&quot; and you can
            now invite the Kitemaker bot to any channel. It will look for Kitemaker links and
            mentions of {issueTerm}s
          </p>
          {!scopesUpToDate && (
            <>
              <h3>Action required:</h3>
              <p>
                The Slack bot has new features which require additional permissions. To enable
                these, we need to reauthorize the application.
              </p>
            </>
          )}
        </>
      }
    >
      <SettingsSection>
        <div className="rowBetween">
          <BackButton defaultPath={defaultPath}>Back</BackButton>
          <div className="row">
            {!scopesUpToDate && (
              <AdminButton
                className="mr8"
                buttonStyle={ButtonStyle.Secondary}
                onClick={reauthorize}
              >
                Reauthorize
              </AdminButton>
            )}
            {config.url && (
              <AdminButton buttonStyle={ButtonStyle.Primary} onClick={manageOnSlack}>
                Manage in Slack
              </AdminButton>
            )}
          </div>
        </div>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewSlackIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(
      `${host}/integrations/slack-legacy/install?organizationId=${organization.id}`,
      electronScheme,
      {
        redirectBaseUrl: redirectUrl,
        redirectQueryParams: {
          pendingIntegration: 'slack',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Slack}
      description={
        <>
          <p>
            Get information about mentioned {issueTerm}s in Slack and link Slack conversations to
            Kitemaker {issueTerm}s
          </p>
          <p>
            To set up the integration, we'll need to install the Kitemaker app in your Slack
            organization.
          </p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Slack integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
