import { KeyboardEvent } from 'react';
import { Editor, Range } from 'slate';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { safeSelection } from '../../../shared/slate/utils';
import { KitemakerEditor } from '../kitemakerEditor';

export function insertSoftBreak(editor: Editor, e: KeyboardEvent): boolean {
  const selection = safeSelection(editor);

  if (editor.isSoftBreak(e) && selection && Range.isCollapsed(selection)) {
    const nodes = Array.from(
      KitemakerEditor.nodes(editor, {
        mode: 'lowest',
        match: o => KitemakerElement.isElement(o) && KitemakerElement.isTextBlock(o),
      })
    );
    if (nodes.length === 1) {
      e.preventDefault();
      editor.insertText('\n');
      return true;
    }
  }

  return false;
}

export function clearMarksOnCustomHardbreaks(editor: Editor, e: KeyboardEvent): boolean {
  if (editor.isHardBreak(e)) {
    for (const mark in Editor.marks(editor)) {
      Editor.removeMark(editor, mark);
    }
  }

  return false;
}
