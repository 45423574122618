import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { GroupType } from '../../slate/types';
import { membersForEntitySelector } from './entities';
import { activeUsersForOrganizationSelector } from './organizations';
import { spaceSelector } from './spaces';
import { userSelector } from './users';

export const groupMembersSelector = selectorFamily({
  key: 'GroupMembersSelector',
  get:
    ({ groupType, groupId }: { groupType: GroupType; groupId: string }) =>
    ({ get }) => {
      switch (groupType) {
        case 'organization': {
          const orgMembers = get(activeUsersForOrganizationSelector({ organizationId: groupId }));
          return orgMembers.map(m => m.user);
        }
        case 'space': {
          const space = get(spaceSelector(groupId));
          if (!space) {
            break;
          }
          const orgMembers = get(
            activeUsersForOrganizationSelector({ organizationId: space.organizationId })
          );
          return orgMembers
            .filter(m => m.member.favoriteSpaceIds?.includes(space.id))
            .map(m => m.user);
        }
        case 'members': {
          const memberIds = get(membersForEntitySelector(groupId));
          const users = memberIds.map(m => get(userSelector(m)));
          return filterNotDeletedNotNull(users);
        }
      }

      return [];
    },
});

export const spaceMemberScreenSelector = selectorFamily({
  key: 'SpaceMembersSelector',
  get:
    ({ spaceId }: { spaceId: string }) =>
    ({ get }) => {
      const space = get(spaceSelector(spaceId));
      if (!space) {
        return [];
      }
      const orgMembers = get(
        activeUsersForOrganizationSelector({ organizationId: space.organizationId })
      );
      return orgMembers.filter(
        m => m.member.favoriteSpaceIds?.includes(space.id) || space.members.includes(m.user.id)
      );
    },
});
