import { selectorFamily, useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull, notDeleted } from '../../../shared/utils/convenience';
import {
  roadmapColumnsByRoadmap,
  roadmapInitiativesByInitiative,
  roadmapsByOrganization,
  spaceRoadmapsByRoadmap,
  spaceRoadmapsBySpace,
} from '../../../sync/__generated/indexes';
import {
  Organization,
  Roadmap,
  RoadmapColumn,
  RoadmapColumnStatusType,
  RoadmapInitiative,
  SpaceRoadmap,
} from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineIndexValue } from '../types';
import { roadmapInitiativesForRoadmapSelector, spacesIdsForInitiativeSelector } from './intiatives';
import { spacesSelector } from './spaces';

export function roadmapPath(organization: Organization, roadmap: Roadmap | string) {
  return `/${organization.slug}/roadmaps/${typeof roadmap === 'string' ? roadmap : roadmap.id}`;
}

export const roadmapSelector = selectorFamily({
  key: 'Roadmap',
  get:
    (roadmapId: string | undefined) =>
    ({ get }) => {
      if (!roadmapId) {
        return null;
      }
      return notDeleted(get(syncEngineState(roadmapId)) as Roadmap | null);
    },
});

export const roadmapsSelector = selectorFamily({
  key: 'Roadmaps',
  get:
    (roadmapIds: string[]) =>
    ({ get }) => {
      return filterNotDeletedNotNull(roadmapIds.map(id => get(roadmapSelector(id))));
    },
});

export const roadmapsForOrganizationSelector = selectorFamily({
  key: 'RoadmapsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const roadmapsIds = get(indexKeyState(indexKey(roadmapsByOrganization, organizationId)));
      return filterNotDeletedNotNull(roadmapsIds.map(roadmapId => get(roadmapSelector(roadmapId))));
    },
});

export const publicRoadmapsForOrganizationSelector = selectorFamily({
  key: 'PublicRoadmapsForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const roadmaps = get(roadmapsForOrganizationSelector(organizationId));
      return roadmaps.filter(r => get(privateSpaceForRoadmapSelector(r.id)) === null);
    },
});

export const roadmapColumnSelector = selectorFamily({
  key: 'RoadmapColumn',
  get:
    (roadmapColumnId: string | undefined) =>
    ({ get }) => {
      if (!roadmapColumnId) {
        return null;
      }
      return notDeleted(get(syncEngineState(roadmapColumnId)) as RoadmapColumn | null);
    },
});

export const roadmapColumnsForRoadmapSelector = selectorFamily({
  key: 'RoadmapColumnsForRoadmap',
  get:
    (roadmapId: string | undefined) =>
    ({ get }) => {
      if (!roadmapId) {
        return [];
      }
      const columnIds = get(indexKeyState(indexKey(roadmapColumnsByRoadmap, roadmapId)));
      return filterNotDeletedNotNull(columnIds.map(id => get(roadmapColumnSelector(id))));
    },
});

export const roadmapColumnIdsForRoadmapSelector = selectorFamily({
  key: 'RoadmapColumnIdsForRoadmap',
  get:
    (roadmapId: string) =>
    ({ get }) => {
      const columns = get(roadmapColumnsForRoadmapSelector(roadmapId));
      return columns.map(column => column.id);
    },
});

export const roadmapColumnSiblingIdsSelector = selectorFamily({
  key: 'RoadmapColumnSiblingIds',
  get:
    (columnId: string | undefined) =>
    ({ get }) => {
      if (!columnId) {
        return [];
      }
      const column = get(roadmapColumnSelector(columnId));
      if (!column) {
        return [];
      }
      const columns = get(roadmapColumnsForRoadmapSelector(column.roadmapId));
      return columns.map(column => column.id);
    },
});

export const roadmapIdForColumnSelector = selectorFamily({
  key: 'RoadmapIdForColumn',
  get:
    (columnId: string | undefined) =>
    ({ get }) => {
      if (!columnId) {
        return null;
      }
      const column = get(roadmapColumnSelector(columnId));
      if (!column) {
        return null;
      }
      return column.roadmapId;
    },
});

export function roadmapColumnTypeToIcon(columnType: RoadmapColumnStatusType) {
  switch (columnType) {
    case RoadmapColumnStatusType.Past:
      return 'roadmap_past';
    case RoadmapColumnStatusType.Present:
      return 'roadmap_present';
    case RoadmapColumnStatusType.Future:
      return 'roadmap_future';
  }
}

export const roadmapIdForSpaceSelector = selectorFamily({
  key: 'RoadmapIdForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return null;
      }

      const spaceRoadmapIds = get(indexKeyState(indexKey(spaceRoadmapsBySpace, spaceId)));
      const spaceRoadmaps = filterNotDeletedNotNull(
        spaceRoadmapIds.map(id => get(syncEngineState(id)) as SpaceRoadmap)
      );
      return spaceRoadmaps[0]?.roadmapId;
    },
});

export const spaceRoadmapsForSpaceSelector = selectorFamily({
  key: 'SpaceRoadmapsForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }

      const spaceRoadmapIds = get(indexKeyState(indexKey(spaceRoadmapsBySpace, spaceId)));
      const spaceRoadmaps = spaceRoadmapIds.map(id => get(syncEngineState(id)) as SpaceRoadmap);
      return filterNotDeletedNotNull(spaceRoadmaps);
    },
});

export const roadmapsForSpaceSelector = selectorFamily({
  key: 'RoadmapsForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }
      const spaceRoadmaps = get(spaceRoadmapsForSpaceSelector(spaceId));
      return filterNotDeletedNotNull(spaceRoadmaps.map(r => get(roadmapSelector(r.roadmapId))));
    },
});

export const spaceRoadmapsForRoadmapSelector = selectorFamily({
  key: 'SpaceRoadmapsForRoadmap',
  get:
    (roadmapId: string | undefined) =>
    ({ get }) => {
      if (!roadmapId) {
        return [];
      }

      const spaceRoadmapIds = get(indexKeyState(indexKey(spaceRoadmapsByRoadmap, roadmapId)));
      return filterNotDeletedNotNull(
        spaceRoadmapIds.map(id => get(syncEngineState(id)) as SpaceRoadmap)
      );
    },
});

export const privateSpaceForRoadmapSelector = selectorFamily({
  key: 'PrivateSpaceForRoadmap',
  get:
    (roadmapId: string | undefined) =>
    ({ get }) => {
      if (!roadmapId) {
        return null;
      }
      const spaceRoadmaps = get(spaceRoadmapsForRoadmapSelector(roadmapId));
      const spaces = get(spacesSelector(spaceRoadmaps.map(r => r.spaceId)));
      return spaces.find(s => s.private)?.id ?? null;
    },
});

export function useFindSharedRoadmaps() {
  return useRecoilCallback(({ snapshot }) => (spaceId: string) => {
    const roadmapSpaces = snapshot.getLoadable(spaceRoadmapsForSpaceSelector(spaceId)).getValue();
    const roadmaps = snapshot
      .getLoadable(roadmapsSelector(roadmapSpaces.map(r => r.roadmapId)))
      .getValue();
    return roadmaps.filter(r => {
      const spaces = snapshot.getLoadable(spaceRoadmapsForRoadmapSelector(r.id)).getValue();
      return spaces.length > 1;
    });
  });
}

export function useFindRoadmapInitiativesFromOutsideSpace() {
  return useRecoilCallback(({ snapshot }) => (spaceId: string) => {
    const roadmapSpaces = snapshot.getLoadable(spaceRoadmapsForSpaceSelector(spaceId)).getValue();
    const roadmaps = snapshot
      .getLoadable(roadmapsSelector(roadmapSpaces.map(r => r.roadmapId)))
      .getValue();
    const roadmapInitiatives = roadmaps.flatMap(r =>
      snapshot.getLoadable(roadmapInitiativesForRoadmapSelector(r.id)).getValue()
    );
    return roadmapInitiatives.filter(i => {
      const spaceIds = snapshot
        .getLoadable(spacesIdsForInitiativeSelector(i.initiativeId))
        .getValue();
      return spaceIds.length !== 1 || spaceIds[0] !== spaceId;
    });
  });
}

export function useFindRoadmapInitiativeForInitiative() {
  // can't afford stale data here so using the transaction hack
  return useRecoilCallback(({ transact_UNSTABLE }) => (roadmapId: string, initiativeId: string) => {
    let roadmapInitiative: RoadmapInitiative | null = null;

    transact_UNSTABLE(({ get }) => {
      const roadmapInitiativeIndex = get(
        syncEngineState(indexKey(roadmapInitiativesByInitiative, initiativeId))
      ) as SyncEngineIndexValue[] | null;
      const roadmapInitiativeIds = (roadmapInitiativeIndex ?? []).map(i => i.value);

      const roadmapInitiatives = filterNotDeletedNotNull(
        roadmapInitiativeIds.map(
          roadmapInitiativeId =>
            get(syncEngineState(roadmapInitiativeId)) as RoadmapInitiative | null
        )
      );
      roadmapInitiative =
        roadmapInitiatives.find(ri => {
          const roadmapColumn = get(syncEngineState(ri.columnId)) as RoadmapColumn | null;
          return roadmapColumn?.roadmapId === roadmapId;
        }) ?? null;
    });

    return roadmapInitiative as RoadmapInitiative | null;
  });
}
