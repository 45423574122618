import { randomString } from '../../../shared/utils/random';
import { feedbackTags } from '../../../sync/__generated/collections';
import { feedbackByTag } from '../../../sync/__generated/indexes';
import { FeedbackTag } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { SyncEngineCreate, SyncEngineUpdate, useModelManager } from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';

export function useCreateTag() {
  const modelManager = useModelManager();
  return (organizationId: string, name: string): FeedbackTag => {
    return modelManager.transaction(tx => {
      const color = 'gray';
      const tag: SyncEngineCreate<FeedbackTag> = {
        __typename: 'FeedbackTag',
        name,
        color,
        organizationId,
      };

      return tx.create(tag);
    });
  };
}

export function useUpdateTags() {
  const modelManager = useModelManager();
  return (tagIds: string[], update: SyncEngineUpdate<FeedbackTag>) => {
    modelManager.transaction(tx => {
      for (const tagId of tagIds) {
        tx.update<FeedbackTag>(tagId, update);
      }
    });
  };
}

export function useDeleteTags() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (tagIds: string[], force?: boolean) => {
    if (!force) {
      const confirmed = await confirm(
        `Delete tag${tagIds.length > 1 ? 's' : ''}`,
        `Are you sure you want to delete ${
          tagIds.length > 1 ? 'these tags' : 'this tag'
        }? There is no way to undo this operation`,
        {
          label: 'Delete',
          destructive: true,
        }
      );

      if (!confirmed) {
        return [];
      }
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const tagId of tagIds) {
        const tag = get<FeedbackTag>(tagId);
        if (!tag || tag.deleted) {
          continue;
        }

        // clean up feedbacks
        const feedbackIds = getIndex(feedbackByTag, tag.id);
        for (const feedbackId of feedbackIds) {
          tx.removeFromCollection(feedbackTags, feedbackId, [tag.id]);
        }

        tx.update<FeedbackTag>(tagId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });

        deleted.push(tagId);
        trackerEvent('Tag Deleted', {
          id: tag.id,
          name: tag.name,
        });
      }
    });

    return deleted;
  };
}
