import cn from 'classnames';
import { uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { between } from '../../../../shared/utils/sorting';
import { MemberRole } from '../../../../sync/__generated/models';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../../components/new/button';
import cardStyles from '../../../components/new/entityCard.module.scss';
import listItemStyles from '../../../components/new/entityListItem.module.scss';
import { EntityTitleEditor } from '../../../components/new/entityTitleEditor';
import { Icon, IconSize } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  FocusReason,
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import AvatarGroup from '../../../components/new/metadata/avatarGroup';
import Initiative from '../../../components/new/metadata/initiative';
import Label from '../../../components/new/metadata/label';
import Pill, { PillStyle } from '../../../components/new/metadata/pill';
import { MetadataSize } from '../../../components/new/metadata/size';
import { InitiativePicker } from '../../../components/new/pickers/initiativePicker';
import { LabelPicker } from '../../../components/new/pickers/labelPicker';
import { MemberPicker } from '../../../components/new/pickers/memberPicker';
import { HorizontalScrollArea } from '../../../components/new/scrollArea';
import { Tooltip } from '../../../components/new/tooltip';
import { SubmitHotkeyTooltip } from '../../../components/submitHotkeyTooltip';
import { useOrganization } from '../../../contexts/organizationContext';
import { useSpace } from '../../../contexts/spaceContext';
import { TextAreaHandle } from '../../../slate/textArea';
import { useCreateIssue } from '../../../syncEngine/actions/issues';
import { initiativesSelector } from '../../../syncEngine/selectors/intiatives';
import {
  filteredIssuesForStatusSelector,
  issuePath,
  issueSelector,
} from '../../../syncEngine/selectors/issues';
import { labelsSelector } from '../../../syncEngine/selectors/labels';
import { usersAndMemberSelector } from '../../../syncEngine/selectors/users';
import { assignIssueKey } from '../../../utils/config';
import { useNextAvailableNumber } from '../../../utils/entities';
import { filterPropertiesSelector } from '../../../utils/filtering2';
import { metaKeyDown } from '../../../utils/keyEvents';
import { scrollIntoView } from '../../../utils/scrolling';

enum CreateMode {
  Done,
  Open,
  Additional,
}

export function Labels({
  labelIds,
  onLabelsChanged,
  className,
  orgLevel,
}: {
  labelIds: string[];
  onLabelsChanged: (labelIds: string[]) => void;
  className?: string;
  orgLevel?: boolean;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const labels = useRecoilValue(labelsSelector(labelIds));

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        asChild
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={cn('row', 'flexWrap', 'metadataGap', className)}>
          {labelIds.length === 0 && (
            <Pill size={MetadataSize.Small} pillStyle={PillStyle.Empty}>
              <Icon icon="label" size={IconSize.Size16} />
              Label
            </Pill>
          )}
          {labels.map(label => (
            <Label key={label.id} size={MetadataSize.Small} color={label.color} name={label.name} />
          ))}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuPicker menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
      >
        <LabelPicker
          orgLevel={orgLevel}
          state={{ __new__: labelIds }}
          onLabelAdded={(_, labelId) => {
            onLabelsChanged([...labelIds, labelId]);
          }}
          onLabelRemoved={(_, labelId) => {
            onLabelsChanged(labelIds.filter(l => l !== labelId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function Members({
  users,
  onUsersChanged,
}: {
  users: string[];
  onUsersChanged: (userIds: string[]) => void;
}) {
  const organization = useOrganization();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const userMembers = useRecoilValue(
    usersAndMemberSelector({ organizationId: organization.id, userIds: users })
  );

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        asChild
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={cn('row', 'flexWrap', 'metadataGap')}>
          {users.length === 0 && (
            <Tooltip
              content={
                <>
                  Set assignees <KeyboardShortcut shortcut={assignIssueKey} />
                </>
              }
            >
              <IconButton
                buttonStyle={ButtonStyle.Bare}
                size={ButtonSize.ExtraSmall}
                icon="member"
              />
            </Tooltip>
          )}
          {users.length > 0 && (
            <AvatarGroup
              avatarData={userMembers.map(user => ({
                name: user.name || user.username,
                img: user.avatar,
                id: user.id,
                deactivated: user.member?.deactivated,
                invited: user.member?.invited,
                guest: user.member?.role === MemberRole.Guest,
              }))}
            />
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
        className="menuPicker menuMedium"
      >
        <MemberPicker
          state={{ __new__: users }}
          onMemberAdded={(_, userId) => {
            onUsersChanged([...users, userId]);
          }}
          onMemberRemoved={(_, userId) => {
            onUsersChanged(users.filter(u => u !== userId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function Initiatives({
  initiativeIds,
  onInitiativesChanged,
  className,
}: {
  initiativeIds: string[];
  onInitiativesChanged: (initiativeIds: string[]) => void;
  className?: string;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const initiativeObjects = useRecoilValue(initiativesSelector(initiativeIds));

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        asChild
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={cn('row', 'flexWrap', 'metadataGap', className)}>
          {initiativeIds.length === 0 && (
            <Pill pillStyle={PillStyle.Empty} size={MetadataSize.Small}>
              <Icon icon={'initiative'} size={IconSize.Size16} />
              Initiative
            </Pill>
          )}
          {initiativeObjects.map(initiative => (
            <Initiative title={initiative.title} key={initiative.id} color={initiative.color} />
          ))}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuPicker menuHuge"
      >
        <InitiativePicker
          state={{ __new__: initiativeIds }}
          onInitiativeAdded={(_, initiativeId) => {
            onInitiativesChanged([...initiativeIds, initiativeId]);
          }}
          onInitiativeRemoved={(_, initiativeId) => {
            onInitiativesChanged(initiativeIds.filter(l => l !== initiativeId));
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function NewWorkItem({
  boardId,
  statusId,
  index,
  onDone,
  onCreate,
  list,
  className,
  style,
}: {
  boardId: string;
  statusId: string;
  index: number;
  onDone: () => void;
  onCreate?: (id: string) => void;
  list?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) {
  const createIssue = useCreateIssue();
  const setFocus = useSetKeyNavigationFocus();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const history = useHistory();
  const space = useSpace();
  const organization = useOrganization();
  const workItemNumber = useNextAvailableNumber(space.id);
  const ref = React.useRef<HTMLDivElement>(null);
  const editorRef = React.useRef<TextAreaHandle>(null);

  const props = useRecoilValue(filterPropertiesSelector(boardId));

  const impactId = props.impactId;
  const effortId = props.effortId;
  const [labelIds, setLabelIds] = React.useState<string[]>(props.labelIds);
  const [memberIds, setMemberIds] = React.useState<string[]>(props.assigneeIds);
  const [initiativeIds, setInitiativeIds] = React.useState<string[]>(props.initiativeIds);
  const [title, setTitle] = React.useState('');

  const create = useRecoilCallback(
    ({ snapshot }) =>
      (mode: CreateMode) => {
        if (!title.length) {
          return;
        }

        const items = snapshot
          .getLoadable(
            filteredIssuesForStatusSelector({ spaceId: space.id, filterId: boardId, statusId })
          )
          .getValue();
        const afterId = items[index - 1];
        const beforeId = items[index];

        const afterItem = snapshot.getLoadable(issueSelector(afterId)).getValue();
        const beforeItem = snapshot.getLoadable(issueSelector(beforeId)).getValue();
        const sort = between({ after: afterItem?.sort, before: beforeItem?.sort });

        const item = createIssue(title, space.id, {
          statusId,
          sort,
          assigneeIds: memberIds,
          labelIds,
          initiativeIds,
          impactId,
          effortId,
        });

        if (!item) {
          return;
        }
        onCreate?.(item.id);
        if (mode === CreateMode.Open) {
          history.push({
            pathname: issuePath(organization, space, item),
            search: 'focusDescription=true',
            state: {
              backUrl: location.pathname,
              backSearch: location.search,
              entity: item.id,
            },
          });
          return;
        }

        disableMissingElementDetection();
        setFocus(item.id, FocusReason.Programmatic);
        enableMissingElementDetection();

        if (mode === CreateMode.Additional) {
          setTitle('');
          setLabelIds(props.labelIds);
          setMemberIds(props.assigneeIds);
          setInitiativeIds(props.initiativeIds);

          editorRef.current?.clear();
          return;
        }
        onDone();
      },
    [title, statusId, index, labelIds, memberIds]
  );

  const titleEditor = (
    <EntityTitleEditor
      className={cn('headingS', {
        mt4: !list,
        [listItemStyles.titleEditor]: list,
        [cardStyles.minTitleHeight]: !list && !title,
      })}
      initialTitle=""
      onChange={setTitle}
      onSubmit={e => {
        let mode = CreateMode.Done;
        if (e.shiftKey && metaKeyDown(e)) {
          mode = CreateMode.Additional;
        } else if (metaKeyDown(e) && e.altKey) {
          mode = CreateMode.Open;
        } else if (e.shiftKey) {
          // backwards compat to keep things working the same even though we're changing the hotkeys
          mode = CreateMode.Open;
        } else if (metaKeyDown(e)) {
          // backwards compat to keep things working the same even though we're changing the hotkeys
          mode = CreateMode.Additional;
        }

        create(mode);
      }}
      onFocus={() => {
        setTimeout(() => {
          if (ref.current) {
            scrollIntoView(ref.current, { block: 'center', scrollMode: 'if-needed' });
          }
        });
      }}
      onReset={onDone}
      autoFocus
      supportedMetadata={{
        initiatives: true,
        labels: true,
        users: true,
      }}
      onMetadataAdded={(type, id) => {
        switch (type) {
          case 'label':
            setLabelIds(previous => uniq([...previous, id]));
            break;
          case 'user':
            setMemberIds(previous => uniq([...previous, id]));
            break;
          case 'initiative':
            setInitiativeIds(previous => uniq([...previous, id]));
            break;
        }
      }}
      placeholder={
        <span className="bodyM">
          Enter a title, use @ for members, # for labels and ! for initiatives
        </span>
      }
      ref={editorRef}
      oneLine={list}
    />
  );

  return (
    <div
      className={cn(
        'relative',
        {
          [cardStyles.card]: !list,
          [listItemStyles.listItem]: list,
        },
        className
      )}
      style={style}
      ref={ref}
    >
      <div className="row metadataHeight">
        <div
          className={cn('finePrint', {
            mr8: !list,
            [listItemStyles.number]: list,
          })}
        >
          {space.key}-{workItemNumber.replace('T', '')}
        </div>
      </div>
      {list ? (
        <HorizontalScrollArea className="mr8 grow">{titleEditor}</HorizontalScrollArea>
      ) : (
        titleEditor
      )}
      <div
        className={cn('row', 'metadataGap', {
          mt4: !list,
          flexWrap: !list,
        })}
      >
        <Initiatives
          initiativeIds={initiativeIds}
          onInitiativesChanged={setInitiativeIds}
          className={cn('mr4', {
            mt4: !list,
          })}
        />
        <span className="grow">
          <Labels
            labelIds={labelIds}
            onLabelsChanged={setLabelIds}
            className={cn({
              mt4: !list,
            })}
          />
        </span>
        <Members users={memberIds} onUsersChanged={setMemberIds} />
      </div>
      <div className={cn('rowEnd', { mt12: !list, ml24: list })}>
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <SubmitHotkeyTooltip entityType="Issue">
          <Button
            size={ButtonSize.Small}
            onClick={e => {
              let mode = CreateMode.Done;
              if (metaKeyDown(e)) {
                mode = CreateMode.Additional;
              } else if (e.shiftKey) {
                mode = CreateMode.Open;
              }
              create(mode);
            }}
            buttonStyle={ButtonStyle.Primary}
          >
            Create
          </Button>
        </SubmitHotkeyTooltip>
      </div>
    </div>
  );
}
