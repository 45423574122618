import { peopleByOrganization } from '../../../sync/__generated/indexes';
import { Person } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import {
  SyncEngineCreate,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { TEMP_COMPANY_ID } from '../selectors/companies';
import { NO_COMPANY_ID } from './companies';
import { indexHelper } from './helpers';

export function useCreatePerson() {
  const modelManager = useModelManager();
  return (
    organizationId: string,
    originalCompanyId: string | null,
    name: string,
    email: string | null,
    externalId?: string | null
  ): Person => {
    return modelManager.transaction((tx, getters) => {
      const existingPeople = indexHelper<Person>(getters, peopleByOrganization, organizationId);
      const personWithEmail = email
        ? existingPeople.find(c => c.email?.toLowerCase() === email.toLowerCase())
        : null;

      if (personWithEmail) {
        throw Error(`There is already a person with the email "${email}"`);
      }

      if (originalCompanyId === TEMP_COMPANY_ID) {
        throw Error('Trying to create person with non-existant company');
      }

      const companyId = originalCompanyId === NO_COMPANY_ID ? null : originalCompanyId;

      const person: SyncEngineCreate<Person> = {
        __typename: 'Person',
        organizationId,
        name,
        companyId,
        email,
        externalId: externalId ?? null,
      };
      return tx.create<Person>(person);
    });
  };
}

export function useUpdatePeople() {
  const modelManager = useModelManager();
  return (personIds: string[], update: SyncEngineUpdateWithoutDelete<Person>) => {
    modelManager.transaction((tx, { get }) => {
      for (const personId of personIds) {
        const person = get<Person>(personId);
        if (!person || person.deleted) {
          continue;
        }
        if (update.companyId === NO_COMPANY_ID) {
          update.companyId = null;
        }
        tx.update<Person>(personId, update);
        trackerEvent('Person Edited', {
          name: person.name,
        });
      }
    });
  };
}

export function useDeletePeople() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (personIds: string[]) => {
    const confirmed = await confirm(
      `Delete person${personIds.length > 1 ? 's' : ''}`,
      `Are you sure you want to delete ${
        personIds.length > 1 ? 'these people' : 'this person'
      }? There is no way to undo this operation`,
      { label: 'Delete', destructive: true }
    );

    if (!confirmed) {
      return [];
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get }) => {
      for (const personId of personIds) {
        const person = get<Person>(personId);
        if (!person || person.deleted) {
          continue;
        }
        tx.update<Person>(personId, {
          deleted: true,
        });

        deleted.push(personId);
        trackerEvent('Person Deleted', {
          id: person.id,
        });
      }
    });

    return deleted;
  };
}
