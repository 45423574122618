import { toast } from '../components/toast';

interface ZapierInstallation {
  token: string;
  integrationId: string;
}

export async function installZapierIntegration(
  organizationId: string
): Promise<ZapierInstallation | null> {
  const res = await fetch(`/integrations/zapier/install?organizationId=${organizationId}`, {
    method: 'POST',
  });

  if (!res.ok || res.status !== 200) {
    toast.error(`An unknown error occurred when adding Zapier integration. Code ${res.status}`);
    return null;
  }

  const jsonResult = await res.json();
  return jsonResult.data;
}

export async function uninstallZapierIntegration(integrationId: string): Promise<void> {
  const res = await fetch(`/integrations/zapier/uninstall?integrationId=${integrationId}`, {
    method: 'POST',
  });

  if (!res.ok || res.status !== 200) {
    toast.error(`An unknown error occurred when removing Zapier integration. Code ${res.status}`);
  }
}
