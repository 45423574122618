import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { uninstallVantaIntegration } from '../../../../api/vanta';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  SettingsSection,
} from '../../../../components/new/settings';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = (
  <>
    <p>
      Kitemaker will synchronize all of your user accounts and security tasks to Vanta. Once setup,
      Kitemaker will automatically register security tasks from Kitemaker in Vanta to better ensure
      your security compliance
    </p>
    <ul>
      <li>
        To identify a work item as a security task, apply a label to it that contains
        &quot;Vanta&quot; in the name.
      </li>
      <li>
        To control the priority of tasks in Vanta (from P1 to P4) also include the priority in the
        label name (e.g. &quot;Vanta P2&quot;).
      </li>
    </ul>
  </>
);

export function EditVantaIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));

  if (!integration) {
    return <IntegrationNotFound />;
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Vanta} description={description}>
      <SettingsSection>
        <AdminButton
          buttonStyle={ButtonStyle.Destructive}
          onClick={async () => await uninstallVantaIntegration(integration.id)}
        >
          Uninstall
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewVantaIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(
      `${host}/integrations/vanta/auth?organizationId=${organization.id}`,
      electronScheme,
      {
        redirectBaseUrl: redirectUrl,
        redirectQueryParams: {
          pendingIntegration: 'vanta',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Vanta} description={description}>
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Vanta integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
