import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu';
import cn from 'classnames';
import { LocationDescriptor } from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { hotkeyContext } from '../../../contexts/hotkeyContext';
import { HotkeyScope } from '../../hotkeys';
import { ZIndexContext } from '../../modal';
import { Hotkey } from '../hotkey';
import { Icon, IconSize } from '../icon';
import { KeyboardShortcut } from '../keyboardShortcut';
import styles from './menu.module.scss';

export function DropdownMenu({ children, ...props }: DropdownMenuProps) {
  return <DropdownMenuPrimitive.Root {...props}>{children}</DropdownMenuPrimitive.Root>;
}

export const DropdownMenuTrigger = React.forwardRef(function DropdownMenuTrigger(
  { children, className, ...props }: DropdownMenuPrimitive.DropdownMenuTriggerProps,
  forwardedRef: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <DropdownMenuPrimitive.Trigger
      className={cn(className ?? '', styles.trigger)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
});

export const DropdownMenuContent = React.forwardRef(function DropdownMenuContent(
  { children, className, ...props }: DropdownMenuPrimitive.DropdownMenuContentProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  const { depth: hotkeyDepth } = React.useContext(hotkeyContext)!;
  const zIndex = React.useContext(ZIndexContext) ?? 0;

  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        onCloseAutoFocus={e => {
          e.stopPropagation();
          e.stopImmediatePropagation();
          e.preventDefault();
        }}
        className={cn(styles.content, className ?? '')}
        style={{ zIndex: zIndex + 200 }}
        {...props}
        ref={forwardedRef}
      >
        <HotkeyScope depth={hotkeyDepth + 5}>
          {children}
          <Hotkey
            hotkey="`"
            handler={() => {
              // this is just a dummy to ensure the scope grabs all hotkeys
            }}
          />
        </HotkeyScope>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Portal>
  );
});

export const DropdownMenuLabel = React.forwardRef(function DropdownMenuLabel(
  { children, ...props }: DropdownMenuPrimitive.DropdownMenuLabelProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <DropdownMenuPrimitive.Label
      className={cn(styles.label, styles.item)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenuPrimitive.Label>
  );
});

export const DropdownMenuItem = React.forwardRef(function DropdownMenuItem(
  {
    children,
    icon,
    iconPlaceholder,
    shortcut,
    standardWidth,
    destructive,
    to,
    onClick,
    ...props
  }: DropdownMenuPrimitive.DropdownMenuItemProps & {
    icon?: string | React.ReactNode;
    iconPlaceholder?: boolean;
    shortcut?: string;
    standardWidth?: boolean;
    destructive?: boolean;
    to?: LocationDescriptor<unknown>;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  const ref = React.useRef<HTMLDivElement | null>();
  const history = useHistory();

  return (
    <DropdownMenuPrimitive.Item
      className={cn(styles.item, {
        [styles.standardWidth]: standardWidth,
        [styles.destructive]: destructive,
        [styles.disabled]: props.disabled,
      })}
      {...props}
      onClick={e => {
        if (props.disabled) {
          return;
        }
        onClick?.(e);
        if (to && e.button === 0) {
          setTimeout(() => history.push(to));
        }
      }}
      ref={r => {
        if (typeof forwardedRef === 'function') {
          forwardedRef(r);
        } else if (forwardedRef) {
          forwardedRef.current = r;
        }
        ref.current = r;
      }}
    >
      {icon && typeof icon === 'string' && (
        <Icon className={styles.icon} size={IconSize.Size20} icon={icon} />
      )}
      {icon && typeof icon !== 'string' && <>{icon}</>}
      {iconPlaceholder && <div style={{ width: '20px', height: '20px' }} />}
      <span className={styles.text}>{children}</span>
      {shortcut && <KeyboardShortcut shortcut={shortcut} />}
      {shortcut && (
        <Hotkey
          hotkey={shortcut}
          handler={e => {
            ref.current?.click();
            e?.preventDefault();
            e?.stopPropagation();
            e?.stopImmediatePropagation();
          }}
        />
      )}
    </DropdownMenuPrimitive.Item>
  );
});

export const DropdownMenuCheckboxItem = React.forwardRef(function DropdownMenuCheckboxItem(
  {
    children,
    icon,
    iconPlaceholder,
    shortcut,
    multi,
    ...props
  }: DropdownMenuPrimitive.MenuCheckboxItemProps & {
    icon?: string;
    iconPlaceholder?: boolean;
    shortcut?: string;
    multi?: boolean;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  const ref = React.useRef<HTMLDivElement | null>();
  return (
    <DropdownMenuPrimitive.CheckboxItem
      className={styles.item}
      {...props}
      ref={r => {
        if (typeof forwardedRef === 'function') {
          forwardedRef(r);
        } else if (forwardedRef) {
          forwardedRef.current = r;
        }
        ref.current = r;
      }}
    >
      <div className={cn(styles.checkbox, { [styles.multi]: multi })}>
        <DropdownMenuPrimitive.ItemIndicator>
          <Icon size={IconSize.Size18} icon="select_checkmark" />
        </DropdownMenuPrimitive.ItemIndicator>
      </div>
      {icon && <Icon className={styles.icon} size={IconSize.Size20} icon={icon} />}
      {iconPlaceholder && <div style={{ width: '20px', height: '20px' }} />}
      <span className={styles.text}>{children}</span>
      {shortcut && <KeyboardShortcut shortcut={shortcut} />}
      {shortcut && (
        <Hotkey
          hotkey={shortcut}
          handler={e => {
            ref.current?.click();
            e?.preventDefault();
            e?.stopPropagation();
            e?.stopImmediatePropagation();
          }}
        />
      )}
    </DropdownMenuPrimitive.CheckboxItem>
  );
});

export const DropdownMenuSeparator = React.forwardRef(function DropdownMenuSeparator(
  { ...props },
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <DropdownMenuPrimitive.Separator className={styles.separator} {...props} ref={forwardedRef} />
  );
});

export const Submenu = DropdownMenuPrimitive.Sub;

export const SubmenuTrigger = React.forwardRef(function SubmenuTrigger(
  {
    children,
    icon,
    iconPlaceholder,
    shortcut,
    ...props
  }: DropdownMenuPrimitive.DropdownMenuSubTriggerProps & {
    icon?: string;
    iconPlaceholder?: boolean;
    shortcut?: string;
  },
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  const ref = React.useRef<HTMLDivElement | null>();
  return (
    <DropdownMenuPrimitive.SubTrigger
      className={styles.item}
      {...props}
      ref={r => {
        if (typeof forwardedRef === 'function') {
          forwardedRef(r);
        } else if (forwardedRef) {
          forwardedRef.current = r;
        }
        ref.current = r;
      }}
    >
      {shortcut && (
        <Hotkey
          hotkey={shortcut}
          handler={e => {
            ref.current?.click();
            ref.current?.click();
            e?.preventDefault();
            e?.stopPropagation();
            e?.stopImmediatePropagation();
          }}
        />
      )}
      {icon && <Icon className={styles.icon} size={IconSize.Size20} icon={icon} />}
      {iconPlaceholder && <div style={{ width: '20px', height: '20px' }} />}
      <span className={styles.text}>{children}</span>
      {shortcut && <KeyboardShortcut shortcut={shortcut} />}
      <Icon size={IconSize.Size18} icon="chevron_right" />
    </DropdownMenuPrimitive.SubTrigger>
  );
});

export const SubmenuContent = React.forwardRef(function SubmenuContent(
  { children, className, ...props }: DropdownMenuPrimitive.DropdownMenuSubContentProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
  const zIndex = React.useContext(ZIndexContext) ?? 0;

  return (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.SubContent
        className={cn(styles.content, className ?? '')}
        style={{ zIndex: zIndex + 200 }}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </DropdownMenuPrimitive.SubContent>
    </DropdownMenuPrimitive.Portal>
  );
});
