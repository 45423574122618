import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { atom, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { InitiativeStatus } from '../../../../shared/initiativeStatus';
import { FilterTabs } from '../../../components/filterTabs';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import {
  MetadataConfigurationButton,
  initiativeDefaultExcludeOptions,
} from '../../../components/metadataConfig';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { Filters as Filters2 } from '../../../components/new/filters2';
import {
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
} from '../../../components/new/keyNavigation';
import Placeholder from '../../../components/new/placeholder';
import { Tooltip } from '../../../components/new/tooltip';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { ResponsiveDesignSize, useIsSmallScreen } from '../../../hooks/useResponsiveDesign';
import {
  useCreateInitiative,
  useUpdateInitiativeSorts,
} from '../../../syncEngine/actions/intiatives';
import {
  filteredInitiativesForOrganizationSelector,
  initiativePath,
  initiativesForOrganizationSelector,
} from '../../../syncEngine/selectors/intiatives';
import { organizationPath } from '../../../syncEngine/selectors/organizations';
import { isMobileOS } from '../../../utils/config';
import { InitiativesList } from './initiativesList';
import styles from './roadmapsScreen.module.scss';

export const ALL_INITIATIVES_FILTER_ID = 'allInitiatives';

const allInitiativesTabState = atom({
  key: 'allInitiativesTab',
  default: 'all',
});

function TopBar() {
  const [tab, setTab] = useRecoilState(allInitiativesTabState);
  const organization = useOrganization();
  const smallScreen = useIsSmallScreen();
  const history = useHistory();

  return (
    <div className={styles.topbar}>
      <FilterTabs id={ALL_INITIATIVES_FILTER_ID} tab={tab} setTab={setTab} />
      <div className="row">
        <Link
          to={{
            pathname: organizationPath(organization, 'archive'),
            state: {
              backUrl: location.pathname,
              backSearch: history.location.search,
            },
          }}
        >
          {smallScreen && (
            <Tooltip content="Archived initiatives">
              <IconButton icon="archive" buttonStyle={ButtonStyle.BareSubtle} />
            </Tooltip>
          )}
          {!smallScreen && (
            <Button icon="archive" buttonStyle={ButtonStyle.BareSubtle}>
              Archive
            </Button>
          )}
        </Link>
        <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
          <MetadataConfigurationButton
            excludeOptions={initiativeDefaultExcludeOptions}
            boardId={ALL_INITIATIVES_FILTER_ID}
          />
        </HideIfSmallerThan>
      </div>
    </div>
  );
}

function InitiativesPlaceholder() {
  const tab = useRecoilValue(allInitiativesTabState);
  const organization = useOrganization();
  const history = useHistory();
  const modals = useModals();
  const createInitiative = useCreateInitiative();

  let title = 'No initiatives yet';
  let content =
    'Initiatives are a way to track larger pieces of work that spans multiple work items.';

  if (tab !== 'all') {
    title = `No ${tab} initiatives yet`;
    content = `There are no ${tab} initiatives yet.`;
  }

  return (
    <Placeholder icon={'initiative'} title={title}>
      <div>{content}</div>
      {tab === 'all' && (
        <Button
          className="mt12"
          icon="add"
          onClick={e => {
            if (isMobileOS) {
              e.preventDefault();
              e.stopPropagation();
              const initiative = createInitiative('New initiative');
              history.push(initiativePath(organization, initiative.initiative));
              return;
            }

            modals.openModal(Modals.NewEntity);
          }}
        >
          Create new initiative
        </Button>
      )}
    </Placeholder>
  );
}

export function AllInitiatives() {
  const organization = useOrganization();
  const updateInitiativeSorts = useUpdateInitiativeSorts();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();

  const [tab, setTab] = useRecoilState(allInitiativesTabState);
  let status;

  if (tab === 'started') {
    status = InitiativeStatus.Started;
  } else if (tab === 'not started') {
    status = InitiativeStatus.NotStarted;
  } else if (tab === 'completed') {
    status = InitiativeStatus.Completed;
  }

  const initiativeIds = useRecoilValue(
    filteredInitiativesForOrganizationSelector({
      organizationId: organization.id,
      filterId: ALL_INITIATIVES_FILTER_ID,
      status,
    })
  );

  const move = useRecoilCallback(
    ({ snapshot }) =>
      async (initiativeIdsToMove: string[], index: number) => {
        const allInitiatives = [
          ...snapshot.getLoadable(initiativesForOrganizationSelector(organization.id)).getValue(),
        ];
        const initiatives = allInitiatives.filter(i => !initiativeIdsToMove.includes(i.id));
        const filteredInitiatives = initiatives.filter(i => initiativeIds.includes(i.id));
        const initativesToMove = allInitiatives.filter(i => initiativeIdsToMove.includes(i.id));

        // we're inserting into the filtered list, but need to actually insert into the real list
        const filteredIndexToInsertAt = Math.min(index, filteredInitiatives.length);

        // if we're moving to the top, let's just put it at the top of the real list
        let indexToInsertAt = 0;

        // if we're not moving to the top, find the card we're going to place the card after
        if (filteredIndexToInsertAt !== 0) {
          const initiativeToInsertAfter = filteredInitiatives[filteredIndexToInsertAt - 1];
          indexToInsertAt = initiatives.indexOf(initiativeToInsertAfter) + 1;
        }

        initiatives.splice(indexToInsertAt, 0, ...initativesToMove);
        const previousInitiativeId: string | undefined = initiatives[indexToInsertAt - 1]?.id;
        const nextInitiativeId: string | undefined =
          initiatives[indexToInsertAt + initativesToMove.length]?.id;

        disableMissingElementDetection();
        updateInitiativeSorts(
          initativesToMove.map(i => i.id),
          previousInitiativeId,
          nextInitiativeId
        );
        enableMissingElementDetection();
      },
    [status, initiativeIds]
  );

  return (
    <>
      <TopBar />
      <Filters2 id={ALL_INITIATIVES_FILTER_ID} />
      <InitiativesList
        boardId={ALL_INITIATIVES_FILTER_ID}
        initiativeIds={initiativeIds}
        onMove={move}
        onCreated={() => {
          // when you create a new initiative and you're not in the 'all' tab, you won't see it
          // it's not possible to create an initiative in a specific state, because it's calculated
          // so just move to all.
          setTab('all');
        }}
      />
      {initiativeIds.length === 0 && <InitiativesPlaceholder />}
    </>
  );
}
