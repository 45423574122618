import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  CodeReviewRequestAddedActivityDetails,
  IntegrationType,
} from '../../../../sync/__generated/models';
import { codeReviewRequestSelector } from '../../../syncEngine/selectors/updates';
import { openExternalUrl } from '../../../utils/urls';
import ActorName from '../../actorName';
import { Hotkey } from '../hotkey';
import { useHasKeyNavigationFocus } from '../keyNavigation';
import { CodeReviewRequestDetail } from '../metadata/codeReviewRequest';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function CodeReviewRequestAddedActivityComponent({ activity }: { activity: ActivityModel }) {
  const focused = useHasKeyNavigationFocus(activity.id);
  const codeReviewRequestDetails = activity.details as CodeReviewRequestAddedActivityDetails;
  const request = useRecoilValue(
    codeReviewRequestSelector(codeReviewRequestDetails.codeReviewRequestId)
  );
  if (!request) {
    return null;
  }
  const reviewTerm =
    request.codeReviewType === IntegrationType.Github ? 'pull request' : 'merge request';

  return (
    <Activity activity={activity} actorId={activity.actorId}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> added a{' '}
      {reviewTerm}.
      <ActivityTimestamp timestamp={activity.createdAt} />
      <div className={styles.activityContext}>
        <CodeReviewRequestDetail codeReview={request} className={styles.codeReviewRequest} />
      </div>
      {focused && (
        <Hotkey
          hotkey={'enter'}
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            openExternalUrl(request.details.url);
          }}
        />
      )}
    </Activity>
  );
}

export const CodeReviewRequestAddedActivity = React.memo(CodeReviewRequestAddedActivityComponent);
