import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  StatusChangedActivityDetails,
} from '../../../../sync/__generated/models';
import { useSpace } from '../../../contexts/spaceContext';
import { statusSelector } from '../../../syncEngine/selectors/issues';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import { openExternalUrl } from '../../../utils/urls';
import ActorName from '../../actorName';
import Markdown from '../../markdown';
import { Hotkey } from '../hotkey';
import { statusToIcon } from '../statusIcon';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function StatusChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const statusChangedDetails = activity.details as StatusChangedActivityDetails;
  const originalStatus = useRecoilValue(
    statusSelector(statusChangedDetails.originalStatusId ?? '')
  );
  const status = useRecoilValue(statusSelector(statusChangedDetails.statusId));
  const space = useRecoilValue(spaceSelector(statusChangedDetails?.spaceId));
  const originalSpace = useRecoilValue(spaceSelector(originalStatus?.spaceId));
  const currentSpace = useSpace();

  const statusName = status?.name ?? statusChangedDetails.fallbackStatus;

  const interactive = !!statusChangedDetails.statusChangedMetadata;

  function metadata() {
    if (!statusChangedDetails.statusChangedMetadata) {
      return null;
    }

    return (
      <>
        with&nbsp;
        <Markdown inline>{statusChangedDetails.statusChangedMetadata.description}</Markdown>
        &nbsp;
      </>
    );
  }

  function message() {
    if (!statusChangedDetails.statusChangedMetadata) {
      return null;
    }

    return statusChangedDetails.statusChangedMetadata.context ? (
      <div>
        <Markdown>{statusChangedDetails.statusChangedMetadata.context}</Markdown>
      </div>
    ) : null;
  }

  if (statusChangedDetails.statusChangedMetadata?.autoArchived) {
    return (
      <Activity activity={activity}>
        {statusChangedDetails.statusChangedMetadata.description}
        <ActivityTimestamp timestamp={activity.createdAt} />
      </Activity>
    );
  }

  return (
    <Activity
      activity={activity}
      actorId={statusChangedDetails.statusChangedMetadata?.type ? activity.actorId : undefined}
      icon={
        status?.statusType
          ? statusToIcon(
              status.statusType,
              currentSpace?.defaultDoneStatusId === status.id ||
                currentSpace?.defaultNewStatusId === status.id
            )
          : undefined
      }
    >
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> changed{' '}
      {space ? 'space' : 'status'}
      {metadata()}.
      <ActivityTimestamp timestamp={activity.createdAt} />
      {message()}
      <div className={cn(styles.activityContext, styles.activityHighlight)}>
        <span className={styles.activityHighlight}>
          {space && (
            <>
              {originalSpace?.name} ({originalStatus?.name}) {`->`} {space.name} ({statusName})
            </>
          )}
          {!space && (
            <>
              {originalStatus ? `${originalStatus?.name} ->` : ``} {statusName}
            </>
          )}
        </span>
      </div>
      {interactive && (
        <Hotkey
          hotkey="enter"
          handler={() => {
            openExternalUrl(statusChangedDetails.statusChangedMetadata!.link);
          }}
        />
      )}
    </Activity>
  );
}

export const StatusChangedActivity = React.memo(StatusChangedActivityComponent);
