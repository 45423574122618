import { Location } from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useComponentDidMount } from './useComponentDidMount';

export default function useNoDuplicateHistory() {
  const history = useHistory();
  const prevLocationRef = React.useRef<Location>(history.location);

  /**
   * FIXME: Here be dragons
   *
   * This is done to fix a bug where 'back' doesn't work after using the bell
   * indicator popover to open a notification and ending up in the same work
   * item that was already open.
   *
   * First, you must appreciate that there are two ways to go back. The browser
   * back button and 'esc'. They work differently. The back button will read
   * from the browser history whereas esc will read from the location state.
   * Both need to work.
   *
   * history.listen still gets called if history.block returns true.  We rely on
   * this behaviour to replace a 'PUSH' with a 'REPLACE'. First block will stop
   * the push going through, and then listen will make a new call to replace.
   *
   * The notification push request has the same pathname as the current
   * location, but a different search. So they are not strictly duplicates and
   * do not get blocked by the 'no duplicates' code.
   *
   * The issueScreen will then strip out the search and replace the history
   * location again without the search in it. At that point, we truely have two
   * duplicate entries in our history, this breaks the back button and the esc
   * button.
   *
   * By replacing the the push with a replace, we fix the browser back button,
   * but we also need to fix the esc button which reads from location.state.
   *
   * The location.state contains the back location for esc to read from. It
   * correctly gets filled with the page you are currently on when you open a
   * notification. The problem is, in the broken case, the location to go back
   * to and the location you are at, are the same.
   *
   * To work around this, we keep around the previousLocation and use it's state
   * when we replace the push with a replace. This fixes the esc button.
   *
   */
  useComponentDidMount(() => {
    history.listen((location, action) => {
      const prevLocation = prevLocationRef.current;
      if (action === 'PUSH' && prevLocation.pathname === location.pathname) {
        prevLocationRef.current = location;
        history.replace({ ...location, state: prevLocation.state });
      }
      prevLocationRef.current = location;
    });

    history.block(
      // @ts-expect-error types are wrong here, expects a static value but actually accepts a function
      (location, action) => {
        if (
          location.pathname.endsWith('/feedback') &&
          location.pathname === window.location.pathname &&
          window.location.search !== '' &&
          location.search === ''
        ) {
          return false;
        }

        return (
          action !== 'PUSH' ||
          !equalLocations(location, history.location) ||
          (action === 'PUSH' && history.location.pathname === location.pathname)
        );
      }
    );
  });
}

function equalLocations(loc1: Location, loc2: Location<any>): boolean {
  return loc1.pathname === loc2.pathname && loc1.search === loc2.search && loc1.hash === loc2.hash;
}
