import { Node } from 'slate';
import { KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { Matcher } from './withStringMatching';

interface Replacement {
  id: string;
  value: string;
}

const breakingCharacters = [' '];

export function simpleReplacementMatcher<T extends Replacement>(
  editor: EditorType,
  replacements: T[],
  onMatch: (replacement: T) => Node[],
  select?: boolean
): Matcher {
  return ({ textSinceLastInline, textsSinceLastInline, selection, newLine }) => {
    const offsetAdjustment = newLine ? 0 : 1;

    if (
      textSinceLastInline.length < 2 ||
      (!breakingCharacters.includes(textSinceLastInline.substr(-1)) && !newLine)
    ) {
      return false;
    }

    let offset = textSinceLastInline.length - 1 - offsetAdjustment;
    while (offset > 0 && !breakingCharacters.includes(textSinceLastInline[offset])) {
      offset--;
    }

    if (breakingCharacters.includes(textSinceLastInline[offset])) {
      offset++;
    }
    const possibleMatch = textSinceLastInline.substring(
      offset,
      textSinceLastInline.length - offsetAdjustment
    );

    const replacement = replacements.find(r => r.value === possibleMatch);
    if (!replacement) {
      return false;
    }

    const anchor = KitemakerNode.offsetIntoTextNodes(editor, textsSinceLastInline, offset);
    if (!anchor) {
      return false;
    }

    const nodes = onMatch(replacement);
    if (nodes.length) {
      HistoryEditor.asBatch(editor, () => {
        KitemakerTransforms.insertNodes(editor, nodes, {
          at: {
            anchor,
            focus: {
              path: selection.focus.path,
              offset: selection.focus.offset - offsetAdjustment,
            },
          },
          mode: 'lowest',
          select: select ?? true,
        });
        KitemakerTransforms.move(editor, { distance: 1, unit: 'character' });
      });
    }

    return true;
  };
}
