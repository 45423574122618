import { selectorFamily } from 'recoil';
import { BoardMarker, OrganizationMarker } from '../../graphql/smartLoad';
import { syncEngineState } from '../state';
import { spacesForOrganizationSelector } from './spaces';

export const markerState = selectorFamily({
  key: 'MarkerState',
  get:
    (markerId: string) =>
    ({ get }) => {
      return !!get(syncEngineState(markerId));
    },
});

export const spaceLoadedSelector = selectorFamily({
  key: 'SpaceLoaded',
  get:
    (spaceId: string) =>
    ({ get }) => {
      return (
        get(markerState(BoardMarker.id(spaceId, 'archive'))) &&
        get(markerState(BoardMarker.id(spaceId, 'backlog'))) &&
        get(markerState(BoardMarker.id(spaceId, 'current')))
      );
    },
});

export const spacesHaveLoadedNonArchivedItemsSelector = selectorFamily({
  key: 'SpacesHaveLoadedNonArchivedItems',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const spaces = get(spacesForOrganizationSelector(organizationId));
      return spaces.every(
        space =>
          get(markerState(BoardMarker.id(space.id, 'backlog'))) &&
          get(markerState(BoardMarker.id(space.id, 'current')))
      );
    },
});

export const orgLoadedSelector = selectorFamily({
  key: 'OrgLoaded',
  get:
    (organizationId: string) =>
    ({ get }) => {
      return get(markerState(OrganizationMarker.id(organizationId, false)));
    },
});
