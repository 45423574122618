import * as RadixPopover from '@radix-ui/react-popover';
import cn from 'classnames';
import { isNumber } from 'lodash';
import * as React from 'react';
import uuid from 'uuid';
import { HotkeyScope } from '../../hotkeys';
import { ZIndexContext } from '../../modal';
import { Hotkey } from '../hotkey';
import { KeyNavigationDisablingElement } from '../keyNavigation';
import styles from './popover.module.scss';
import { PopoverProps } from './popover.types';

const POPOVER_OPEN_CLASS = 'popoverOpen';

export default function Popover({
  children,
  open,
  onOpenChange,
  defaultOpen,
  content,
  contentOptions,
  asChild,
  nonInteractive,
}: PopoverProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const id = React.useRef(uuid.v4());
  const zIndex = React.useContext(ZIndexContext) ?? 0;

  React.useEffect(() => {
    if (nonInteractive === undefined || !ref.current) {
      return;
    }

    let popoverRoot: HTMLElement | null = ref.current;
    while (popoverRoot && !popoverRoot.hasAttribute('data-radix-popper-content-wrapper')) {
      popoverRoot = popoverRoot.parentElement;
    }

    if (!popoverRoot) {
      return;
    }

    if (nonInteractive) {
      popoverRoot.style.pointerEvents = 'none';
    } else {
      popoverRoot.style.pointerEvents = 'unset';
    }
  });

  return (
    <RadixPopover.Root
      open={open}
      onOpenChange={open => {
        if (open) {
          if (!document.body.className.includes(POPOVER_OPEN_CLASS)) {
            document.body.className = [
              ...document.body.className.split(' '),
              POPOVER_OPEN_CLASS,
            ].join(' ');
          }
        } else {
          if (document.body.className.includes(POPOVER_OPEN_CLASS)) {
            document.body.className = document.body.className
              .split(' ')
              .filter(s => s !== POPOVER_OPEN_CLASS)
              .join(' ');
          }
        }
        onOpenChange?.(open);
      }}
      defaultOpen={defaultOpen}
    >
      <RadixPopover.Trigger asChild={asChild} className={styles.trigger}>
        {children}
      </RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          hideWhenDetached
          onCloseAutoFocus={e => e.preventDefault()}
          {...contentOptions}
          style={{
            ...contentOptions?.style,
            zIndex: zIndex + 300,
          }}
          collisionPadding={
            isNumber(contentOptions?.collisionPadding)
              ? contentOptions?.collisionPadding
              : {
                  left: 5,
                  right: 5,
                  ...(contentOptions?.collisionPadding ?? {}),
                }
          }
          className={cn(styles.content, contentOptions?.className)}
        >
          <KeyNavigationDisablingElement disableKey={id.current}>
            <HotkeyScope depth={100}>
              <div ref={ref}>{content}</div>
              <Hotkey
                hotkey="escape"
                priority={0}
                handler={e => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  onOpenChange?.(false);
                }}
              />
            </HotkeyScope>
          </KeyNavigationDisablingElement>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
