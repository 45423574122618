import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import {
  effortLevelsForSpaceSelector,
  uniqueEffortLevelsForOrganizationSelector,
} from '../../../syncEngine/selectors/effortLevels';
import { FilteredListViewHandle } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import { Picker, PickerState } from '../picker';

export function EffortPicker({
  state,
  filterPlaceholder,
  filterClassName,
  additionalItems,
  additionalItemsFirst,
  onDone,
  onEffortAdded,
  onEffortRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  onDone: () => void;
  onEffortAdded: (issueIds: string[], effortId: string) => void;
  onEffortRemoved: (issueIds: string[], effortId: string) => void;
}) {
  const organization = useOrganization();
  const space = useMaybeSpace();

  const spaceEffortLevels = useRecoilValue(effortLevelsForSpaceSelector(space?.id));
  const organizationEffortLevels = useRecoilValue(
    uniqueEffortLevelsForOrganizationSelector(organization.id)
  );
  const effortLevels = space ? spaceEffortLevels : organizationEffortLevels;

  const items: ListViewItem[] = effortLevels.map(tag => {
    const color = mapColor(tag.color);
    return {
      ...tag,
      mouseDown: true,
      contents: () => (
        <>
          <Icon icon="effort" size={IconSize.Size20} style={{ fill: color }} />
          <span className="ellipsis">{tag.name}</span>
        </>
      ),
    };
  });

  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterLabel="Pick effort"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Pick effort'}
      placeholder="No effort found"
      ref={filterRef}
      multi
      state={state}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onEffortAdded}
      onRemove={onEffortRemoved}
      onDone={onDone}
    />
  );
}
