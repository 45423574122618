import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../shared/utils/terms';
import { MemberRole } from '../../sync/__generated/models';
import { useRemoveInactiveUserAssignees } from '../syncEngine/actions/entities';
import { useUpdateOrganizationMembers } from '../syncEngine/actions/organizationMember';
import { useAddBacklog, useDeleteSpaces } from '../syncEngine/actions/spaces';
import { boardsForSpaceSelector } from '../syncEngine/selectors/boards';
import { cycleSelector } from '../syncEngine/selectors/cycles';
import { organizationPath, organizationsSelector } from '../syncEngine/selectors/organizations';
import { spacePath, spaceSelector, useSpaceSettingsPath } from '../syncEngine/selectors/spaces';
import { currentUserMembershipState } from '../syncEngine/selectors/users';
import {
  showActiveCycleKey,
  showArchiveKey,
  showBacklogBoardKey,
  showInitiativesKey,
  showIssueBoardKey,
  showSpaceRoadmapKey,
  showUpcomingCycleKey,
} from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  SpaceCommandGroupContext,
  useCommandMenuContext,
} from './state';

export function useSpaceCommandGroup(): CommandDefinition[] {
  const context = useCommandMenuContext<SpaceCommandGroupContext>(CommandGroup.Space);
  const space = useRecoilValue(spaceSelector(context?.spaceId));
  const boards = useRecoilValue(boardsForSpaceSelector(space?.id));
  const organization = useRecoilValue(organizationsSelector(space?.organizationId));
  const orgMembership = useRecoilValue(currentUserMembershipState(organization?.id));
  const history = useHistory();

  const addBacklog = useAddBacklog();
  const deleteSpaces = useDeleteSpaces();
  const updateMembers = useUpdateOrganizationMembers();
  const removeInactiveUserAssignees = useRemoveInactiveUserAssignees();

  const spaceSettingsPath = useSpaceSettingsPath();

  const activeCycle = useRecoilValue(cycleSelector(space?.activeCycleId));
  const upcomingCycle = useRecoilValue(cycleSelector(space?.upcomingCycleId));

  const hasBacklog = !!boards.find(b => b.key === 'backlog');

  if (!organization || !space || !orgMembership) {
    return [];
  }

  const isFavoriteSpace = orgMembership.favoriteSpaceIds?.includes(space.id) ?? false;

  const commands: CommandDefinition[] = [
    {
      id: 'view-issues',
      description: `Go to current ${issueTerm}s`,
      hotkey: showIssueBoardKey,
      group: CommandGroup.Navigation,
      icon: 'current',
      handler: () => history.push(spacePath(organization, space, 'boards')),
    },
    {
      id: 'view-space-archive',
      description: `View space archive`,
      hotkey: showArchiveKey,
      group: CommandGroup.Navigation,
      icon: 'archive',
      handler: () =>
        history.push({
          pathname: spacePath(organization, space, 'archive'),
          state: {
            backUrl: location.pathname,
            backSearch: history.location.search,
          },
        }),
    },
    {
      id: `favorite-${space.id}`,
      description: isFavoriteSpace ? `Leave space ${space.name}` : `Join space ${space.name}`,
      icon: 'workspace',
      handler: () => {
        const favoriteSpaceIds = [...(orgMembership.favoriteSpaceIds ?? [])];
        updateMembers([orgMembership.id], {
          favoriteSpaceIds: isFavoriteSpace
            ? favoriteSpaceIds.filter(spaceId => spaceId !== space.id)
            : [...favoriteSpaceIds, space.id],
        });
      },
    },
  ];

  if (orgMembership && orgMembership.role === MemberRole.Guest) {
    commands.push(
      ...[
        {
          id: 'deleteSpace',
          description: 'Delete space',
          icon: 'workspace',
          handler: async () => {
            await deleteSpaces([space.id]);
            history.push(organizationPath(organization));
          },
        },
        {
          id: 'nuke-inactive-members',
          description: `Remove inactive users from active ${issueTerm}s in ${space.name}`,
          icon: 'workspace',
          handler: async () => {
            removeInactiveUserAssignees(space.id);
          },
        },
      ]
    );
  }

  if (hasBacklog) {
    commands.push({
      id: 'view-backlog',
      description: `Go to planning board`,
      hotkey: showBacklogBoardKey,
      aliases: ['backlog', 'plan', 'scrum'],
      icon: 'backlog',
      group: CommandGroup.Navigation,
      handler: () =>
        history.push({
          pathname: spacePath(organization, space, 'boards/backlog'),
          state: {
            backUrl: location.pathname,
            backSearch: history.location.search,
          },
        }),
    });
  } else {
    commands.push({
      id: 'add-backlog',
      description: `Enable the planning board for space ${space.name}`,
      aliases: ['backlog', 'plan', 'scrum', 'add backlog'],
      icon: 'backlog',
      group: CommandGroup.Space,
      handler: () => {
        addBacklog(space.id);
        history.push(spacePath(organization, space, 'boards/backlog'));
      },
    });
  }

  commands.push({
    id: 'view-initiatives',
    description: 'Go to initiatives',
    hotkey: showInitiativesKey,
    group: CommandGroup.Navigation,
    icon: 'initiative',
    handler: () =>
      history.push({
        pathname: spacePath(organization, space, 'initiatives'),
        state: {
          backUrl: location.pathname,
          backSearch: history.location.search,
        },
      }),
  });

  if (organization.newRoadmapsEnabled) {
    commands.push({
      id: 'go-to-space-roadmap',
      description: 'Go to space roadmap',
      hotkey: showSpaceRoadmapKey,
      group: CommandGroup.Navigation,
      icon: 'roadmap',
      handler: () =>
        history.push({
          pathname: spacePath(organization, space, 'roadmap'),
          state: {
            backUrl: location.pathname,
            backSearch: history.location.search,
          },
        }),
    });
  }

  if (space.cyclesEnabled) {
    commands.push({
      id: 'list-cycles',
      description: 'Go to all cycles',
      group: CommandGroup.Navigation,

      handler: () =>
        history.push({
          pathname: spacePath(organization, space, 'cycles'),
          state: {
            backUrl: location.pathname,
            backSearch: history.location.search,
          },
        }),
    });

    if (activeCycle) {
      commands.push(
        ...[
          {
            id: 'view-current-cycle',
            description: 'Go to current cycle',
            hotkey: showActiveCycleKey,
            group: CommandGroup.Navigation,
            handler: () =>
              history.push({
                pathname: spacePath(organization, space, `cycles/${activeCycle.number}`),
                state: {
                  backUrl: location.pathname,
                  backSearch: history.location.search,
                },
              }),
          },
        ]
      );
    }

    if (upcomingCycle) {
      commands.push(
        ...[
          {
            id: 'view-upcoming-cycle',
            description: 'Go to upcoming cycle',
            hotkey: showUpcomingCycleKey,
            group: CommandGroup.Navigation,
            handler: () =>
              history.push({
                pathname: spacePath(organization, space, `cycles/${upcomingCycle.number}`),
                state: {
                  backUrl: location.pathname,
                  backSearch: history.location.search,
                },
              }),
          },
        ]
      );
    }
  }

  commands.push(
    {
      id: 'space-settings-general',
      description: 'Space settings',
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id));
      },
    },
    {
      id: 'space-settings-members',
      description: 'Space members',
      aliases: ['invite', 'add', 'space member settings'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'members'));
      },
    },
    {
      id: 'space-settings-statuses',
      description: 'Workflow settings',
      aliases: ['statuses', 'columns'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'statuses'));
      },
    },
    {
      id: 'space-settings-labels',
      description: 'Manage labels',
      aliases: ['tags', 'label settings'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'labels'));
      },
    },
    {
      id: 'space-settings-snippets',
      description: 'Manage snippets',
      aliases: ['templates', 'snippet settings'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'snippets'));
      },
    },
    {
      id: 'space-settings-effort-impact',
      description: 'Manage impact and effort',
      aliases: ['estimations', 'story points', 'ICE', 'impact effort settings'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'impact-effort'));
      },
    },
    {
      id: 'space-settings-cycles',
      description: 'Cycle settings',
      aliases: ['scrum', 'sprint', 'agile'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'cycle'));
      },
    },
    {
      id: 'space-settings-automation',
      description: 'Automation settings',
      aliases: ['git'],
      icon: 'settings',
      group: CommandGroup.SpaceSettingsNavigation,
      handler: () => {
        history.push(spaceSettingsPath(space.id, 'automation'));
      },
    }
  );

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Space }));
}
