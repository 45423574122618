import cn from 'classnames';
import * as React from 'react';
import Modal from '../components/modal';
import { ImageArgs, Modals, useModals } from '../contexts/modalContext';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import styles from './imageModal.module.scss';

function ImageModalContents({ url, onClose }: { url?: string; onClose?: () => void }) {
  const modals = useModals();
  const ref = React.useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    ref.current?.focus();
  });

  return (
    <div
      className={cn(styles.imageModalContents, 'fs-exclude')}
      tabIndex={-1}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        modals.closeModal(Modals.Image);
        onClose?.();
      }}
      ref={ref}
    >
      <img src={url} />
    </div>
  );
}

export default function ImageModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as ImageArgs) : null;

  return (
    <Modal
      modalId={Modals.Image}
      hideHeader={true}
      className={styles.imageModal}
      onClose={args?.onClose}
    >
      <ImageModalContents url={args?.url} onClose={args?.onClose} />
    </Modal>
  );
}
