import * as React from 'react';
import { HotkeyCommand, useHotkeys } from '../contexts/hotkeyContext';

export default function Hotkey({ command }: { command: HotkeyCommand }) {
  const hotkeyContext = useHotkeys();

  React.useEffect(() => {
    hotkeyContext.registerHotkeyCommand(command);

    return () => {
      hotkeyContext.unregisterHotkeyCommand(command);
    };
  });
  return null;
}
