import * as React from 'react';
import { isElectron } from '../../electronIntegration';
import { ButtonSize, ButtonStyle, IconButton } from './button';
import { Tooltip } from './tooltip';

export function DownloadButton({
  url,
  className,
  style,
  buttonStyle,
}: {
  url: string;
  className?: string;
  style?: React.CSSProperties;
  buttonStyle?: ButtonStyle;
}) {
  return (
    <Tooltip content="Download">
      <IconButton
        className={className}
        style={style}
        size={ButtonSize.Small}
        buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          if (isElectron()) {
            window.location.href = url;
          } else {
            window.open(url);
          }
        }}
        icon="download"
      />
    </Tooltip>
  );
}
