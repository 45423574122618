import { uniq } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { DependencyType, Issue } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { useConfirmation } from '../../contexts/confirmationContext';
import { CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import { useAddEntitiesToCycle, useRemoveEntitiesFromCycle } from '../../syncEngine/actions/cycles';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
  useRemoveAssigneesFromEntities,
  useRemoveLabelsFromEntities,
  useToggleAssigneesForEntities,
  useToggleWatchersForEntities,
  useUpdateEntities,
} from '../../syncEngine/actions/entities';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../syncEngine/actions/intiatives';
import {
  useAddDependenciesToIssues,
  useDeleteIssues,
  useDuplicateIssues,
  useMoveIssues,
  useRemoveDependenciesFromIssues,
  useUnarchiveIssues,
  useUpdateIssues,
} from '../../syncEngine/actions/issues';
import { useSnoozeIds, useUnsnoozeIds } from '../../syncEngine/actions/organizationMember';
import {
  useAddIssuesToRelease,
  useRemoveIssuesFromRelease,
} from '../../syncEngine/actions/releases';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import { entitiesInCycleSelector } from '../../syncEngine/selectors/cycles';
import { spaceForEntitySelector, useEntityPath } from '../../syncEngine/selectors/entities';
import { initiativesForOrganizationSelector } from '../../syncEngine/selectors/intiatives';
import {
  isIssue,
  issuesSelector,
  useCopyPublicIssueLink,
  useGetFilterdIssuesForStatus,
} from '../../syncEngine/selectors/issues';
import {
  archivedStatusForSpaceSelector,
  openStatusForSpaceSelector,
} from '../../syncEngine/selectors/issueStatuses';
import { idsSnoozedSelector } from '../../syncEngine/selectors/organizationMember';
import { releasesForOrganizationSelector } from '../../syncEngine/selectors/releases';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import {
  addToInitiativeKey,
  alternateComboKey,
  archiveIssueKey,
  assignIssueKey,
  copyIssueGitBranchKey,
  copyIssueLinkKey,
  copyIssueNumberKey,
  deleteKey,
  duplicateKey,
  editTitleKey,
  labelIssueKey,
  mainComboKey,
  moveIssueKey,
  moveIssueToNewSpaceKey,
  openHistoryKey,
  releaseIssueKey,
  removeItemKey,
  selfAssignIssueKey,
  setEffortKey,
  setImpactKey,
  watchIssueKey,
} from '../../utils/config';
import { StarredType } from '../../utils/starred';
import { descriptionHistoryOpenAtom } from '../descriptionHistory';
import { useGitBranchName } from '../gitBranch';
import { ButtonStyle, IconButton } from './button';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
  useCopyGitBranchToClipboard,
  writeItemsToClipboard,
} from './copyAndPaste';
import { DueDatePicker } from './dueDateMetadata';
import { useClearSelection, useKeyNavigationState } from './keyNavigation';
import { CycleMenu } from './menu/cycleMenu';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from './menu/dropdownMenu';
import { EffortMenu } from './menu/effortMenu';
import { ImpactMenu } from './menu/impactMenu';
import DependencyPickerWrapper from './pickers/dependencyPicker';
import { InitiativePicker, workItemsToInitiativePickerState } from './pickers/initiativePicker';
import { LabelPicker, entitiesToLabelPickerState } from './pickers/labelPicker';
import { MemberPicker, entitiesToMemberPickerState } from './pickers/memberPicker';
import { ReleasePicker, issuesToReleasePickerState } from './pickers/releasePicker';
import {
  StatusColumnPicker,
  entitiesToStatusColumnPickerState,
} from './pickers/statusColumnPicker';
import { TimePicker } from './timePicker';
import { Tooltip } from './tooltip';

export function WorkItemMenu({
  item,
  showHistory,
  closeMenu,
  onChangeTitle,
  moveToTopBottom,
  disableChangeStatus,
  initiativeId,
}: {
  item: Issue;
  showHistory?: boolean;
  closeMenu: () => void;
  onChangeTitle?: () => void;
  disableChangeStatus?: boolean;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
  initiativeId?: string;
}) {
  const { host, featureFlags } = useConfiguration();
  const organization = useOrganization();
  const user = useCurrentUser();
  const space = useRecoilValue(spaceForEntitySelector(item.id));
  const modals = useModals();
  const initiatives = useRecoilValue(initiativesForOrganizationSelector(organization.id));
  const releases = useRecoilValue(releasesForOrganizationSelector(organization.id));
  const updateEntities = useUpdateEntities();
  const updateIssues = useUpdateIssues();
  const addLabelsToEntities = useAddLabelsToEntities();
  const removeLabelsFromEntities = useRemoveLabelsFromEntities();
  const addAssignees = useAddAssigneesToEntities();
  const removeAssignees = useRemoveAssigneesFromEntities();
  const addIssueToInitiative = useAddIssuesToInitiatives();
  const removeIssuesFromInitiative = useRemoveIssuesFromInitiatives();
  const addIssuesToRelease = useAddIssuesToRelease();
  const removeIssuesFromRelease = useRemoveIssuesFromRelease();
  const moveIssues = useMoveIssues();
  const deleteIssues = useDeleteIssues();
  const addDepenencies = useAddDependenciesToIssues();
  const removeDependencies = useRemoveDependenciesFromIssues();
  const toggleAssigneesForEntities = useToggleAssigneesForEntities();
  const toggleWatching = useToggleWatchersForEntities();
  const duplicateWorkItem = useDuplicateIssues();
  const getIssuesForStatus = useGetFilterdIssuesForStatus(space?.id ?? '', item.statusId, '');
  const copyPublicLink = useCopyPublicIssueLink();
  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const copyGitBranch = useCopyGitBranchToClipboard(item.id);

  const entityPath = useEntityPath();
  const branchName = useGitBranchName(item.id);
  const addEntitiesToCycle = useAddEntitiesToCycle();
  const removeEntitiesFromCycle = useRemoveEntitiesFromCycle();
  const unarchive = useUnarchiveIssues();
  const snoozeIds = useSnoozeIds();
  const unsnoozeIds = useUnsnoozeIds();

  const { confirm } = useConfirmation();
  const archivedStatus = useRecoilValue(archivedStatusForSpaceSelector(space?.id));
  const defaultstatus = useRecoilValue(openStatusForSpaceSelector(space?.id));
  const [openHistory, setOpenHistory] = useRecoilState(descriptionHistoryOpenAtom);
  const tinyScreen = useIsTinyScreen();
  const { selected } = useKeyNavigationState();
  const clearSelection = useClearSelection();

  const selectedItems = useRecoilValue(issuesSelector(selected));

  const items = (selected?.includes(item.id) ? selectedItems! : [item]).filter(i => isIssue(i));
  const itemIds = items.map(i => i.id);

  const { snoozed, unsnoozed } = useRecoilValue(
    idsSnoozedSelector({ ids: itemIds, organizationId: organization.id })
  );

  const isSnoozable = !!unsnoozed.length;
  const isUnsnoozable = !!snoozed.length;

  const watching = items.every(item => item.watcherIds.includes(user.id));
  const sameStatus = items.every(item => item.statusId === items[0].statusId);
  const isMember = items.every(item => item.assigneeIds.includes(user.id));
  const archived = items.every(item => item.statusId === archivedStatus?.id);
  const paidPlan = !!organization.activeProductId;
  const impactIds = uniq(items.map(item => item.impactId));
  const effortIds = uniq(items.map(item => item.effortId));

  const { inCycle: inUpcomingCycleEntities, notInCycle: notInUpcomingCycle } = useRecoilValue(
    entitiesInCycleSelector({ entityIds: itemIds, cycleId: space?.upcomingCycleId })
  );
  const inUpcomingCycle = notInUpcomingCycle.length === 0;

  const { inCycle: inCurrentCycleEntities, notInCycle: notInCurrentCycle } = useRecoilValue(
    entitiesInCycleSelector({ entityIds: itemIds, cycleId: space?.activeCycleId })
  );
  const inCurrentCycle = notInCurrentCycle.length === 0;

  const inNoCycles = inCurrentCycleEntities.length === 0 && inUpcomingCycleEntities.length === 0;

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.WorkItem,
      id: item.id,
    })
  );
  const toggleStarred = useToggleStarred();

  useComponentDidMount(() => {
    if (selected?.length && !selected.includes(item.id)) {
      clearSelection();
    }
  });

  let shareTooltip;
  if (!paidPlan) {
    shareTooltip = (
      <div>
        <div>Publicly shared work items are a paid feature.</div>
        <div className="semiBold">Please upgrade your plan.</div>
      </div>
    );
  } else {
    if (item.public) {
      shareTooltip = (
        <div style={{ maxWidth: 372 }}>
          Stop sharing this work item publicly. Only members of your organization will be able to
          access it.
        </div>
      );
    } else {
      shareTooltip = (
        <div style={{ maxWidth: 372 }}>
          Share this work item publicly, so anyone with a link can view it.
        </div>
      );
    }
  }

  return (
    <>
      {!disableChangeStatus && (
        <Submenu>
          <SubmenuTrigger icon="status" shortcut={moveIssueKey}>
            Status
          </SubmenuTrigger>
          <SubmenuContent className="menuSmall">
            <StatusColumnPicker
              state={entitiesToStatusColumnPickerState(items)}
              onPicked={(issueIds, statusId) => {
                // without this, the z to undo hotkey doesn't seem to work
                closeMenu?.();
                moveIssues(issueIds, statusId);
              }}
              onDone={closeMenu}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <Submenu>
        <SubmenuTrigger icon="member" shortcut={assignIssueKey}>
          Members
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <MemberPicker
            state={entitiesToMemberPickerState(items)}
            onMemberAdded={(issueIds, memberId) => {
              addAssignees(issueIds, [memberId]);
            }}
            onMemberRemoved={(issueIds, memberId) => {
              removeAssignees(issueIds, [memberId]);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="label" shortcut={labelIssueKey}>
          Labels
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <LabelPicker
            state={entitiesToLabelPickerState(items)}
            onLabelAdded={(issueIds, labelId) => {
              addLabelsToEntities(issueIds, [labelId]);
            }}
            onLabelRemoved={(issueIds, labelId) => {
              removeLabelsFromEntities(issueIds, [labelId]);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="impact" shortcut={setImpactKey}>
          Impact
        </SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          <ImpactMenu
            onSelect={impactId => updateEntities(itemIds, { impactId })}
            impactId={impactIds.length === 1 ? impactIds[0] : undefined}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="effort" shortcut={setEffortKey}>
          Effort
        </SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          <EffortMenu
            onSelect={effortId => updateEntities(itemIds, { effortId })}
            effortId={effortIds.length === 1 ? effortIds[0] : undefined}
          />
        </SubmenuContent>
      </Submenu>
      {organization.releasesEnabled && (
        <Submenu>
          <SubmenuTrigger icon="release" shortcut={releaseIssueKey}>
            Release
          </SubmenuTrigger>
          <SubmenuContent className="menuPicker menuMedium">
            <ReleasePicker
              state={issuesToReleasePickerState(items, releases)}
              onReleaseAdded={(issueIds, releaseId) => {
                addIssuesToRelease([releaseId], issueIds);
              }}
              onReleaseRemoved={(issueIds, releaseId) => {
                removeIssuesFromRelease([releaseId], issueIds);
              }}
              onDone={closeMenu}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <Submenu>
        <SubmenuTrigger icon="due_date">Due date</SubmenuTrigger>
        <SubmenuContent>
          <DueDatePicker
            date={items.find(i => !!i.dueDate)?.dueDate ?? null}
            onSave={dueDate => {
              updateIssues(itemIds, { dueDate: dueDate?.getTime() ?? null });
              closeMenu?.();
            }}
            onCancel={() => closeMenu?.()}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="initiative" shortcut={addToInitiativeKey}>
          Initiatives
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuHuge">
          <InitiativePicker
            state={workItemsToInitiativePickerState(items, initiatives)}
            onInitiativeAdded={(issueIds, initiativeId) => {
              addIssueToInitiative([initiativeId], issueIds);
            }}
            onInitiativeRemoved={(issueIds, initiativeId) => {
              removeIssuesFromInitiative([initiativeId], issueIds);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      {space?.cyclesEnabled && (
        <Submenu>
          <SubmenuTrigger icon="cycle_current">Cycles</SubmenuTrigger>
          <SubmenuContent className="menuHuge">
            <CycleMenu
              inCurrentCycle={inCurrentCycle}
              inUpcomingCycle={inUpcomingCycle}
              inNoCycles={inNoCycles}
              spaceId={space.id}
              onSelect={(cycleId: string | null) => {
                if (cycleId === null) {
                  if (inUpcomingCycle && space.upcomingCycleId) {
                    removeEntitiesFromCycle(itemIds, space.upcomingCycleId);
                  }
                  if (inCurrentCycle && space.activeCycleId) {
                    removeEntitiesFromCycle(itemIds, space.activeCycleId);
                  }
                  return;
                }
                if (cycleId === space.activeCycleId && !inCurrentCycle) {
                  addEntitiesToCycle(itemIds, cycleId);
                }
                if (cycleId === space.upcomingCycleId && !inUpcomingCycle) {
                  addEntitiesToCycle(itemIds, cycleId);
                }
              }}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <DropdownMenuSeparator />
      {!tinyScreen && items.length === 1 && onChangeTitle && (
        <DropdownMenuItem icon="edit" shortcut={editTitleKey} onClick={onChangeTitle}>
          Change title
        </DropdownMenuItem>
      )}
      {moveToTopBottom && sameStatus && (
        <Submenu>
          <SubmenuTrigger icon="move">Move</SubmenuTrigger>
          <SubmenuContent className="menuLarge">
            <DropdownMenuItem
              onClick={() => moveToTopBottom('top', itemIds, items[0].statusId)}
              icon="move_top"
              shortcut={`${mainComboKey}+${alternateComboKey}+up`}
            >
              Move to top
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => moveToTopBottom('bottom', itemIds, items[0].statusId)}
              icon="move_bottom"
              shortcut={`${mainComboKey}+${alternateComboKey}+down`}
            >
              Move to bottom
            </DropdownMenuItem>
          </SubmenuContent>
        </Submenu>
      )}
      {sameStatus && (
        <DropdownMenuItem
          icon="copy"
          shortcut={duplicateKey}
          onClick={() => {
            const issues = getIssuesForStatus();
            const itemIndex = issues.findIndex(id => item.id === id);
            const itemAfter = issues[itemIndex + 1];
            duplicateWorkItem([item.id], item.statusId, item.id, itemAfter);
          }}
        >
          Duplicate {issueTerm}
          {items.length > 1 ? 's' : ''}
        </DropdownMenuItem>
      )}
      {items.length > 1 && (
        <DropdownMenuItem
          shortcut={`${mainComboKey}+c`}
          icon="copy"
          onClick={() => {
            writeItemsToClipboard(
              [
                {
                  id: item.id,
                  shortName: `${space?.key}-${item.number}`,
                  name: item.title,
                  url: entityPath(item.id),
                  type: 'work item',
                },
              ],
              host
            );
          }}
        >
          Copy work items
        </DropdownMenuItem>
      )}
      {items.length === 1 && (
        <>
          <Submenu>
            <SubmenuTrigger icon="copy">Copy</SubmenuTrigger>
            <SubmenuContent className="menuMedium">
              <DropdownMenuItem
                shortcut={copyIssueNumberKey}
                onClick={() => {
                  copyEntityNumber([item.id]);
                }}
              >
                Copy work item number
              </DropdownMenuItem>
              <DropdownMenuItem
                shortcut={copyIssueLinkKey}
                onClick={() => {
                  copyEntityLink([item.id]);
                }}
              >
                Copy link to work item
              </DropdownMenuItem>
              <DropdownMenuItem
                shortcut={`${mainComboKey}+c`}
                onClick={() => {
                  writeItemsToClipboard(
                    [
                      {
                        id: item.id,
                        shortName: `${space?.key}-${item.number}`,
                        name: item.title,
                        url: entityPath(item.id),
                        type: 'work item',
                      },
                    ],
                    host
                  );
                }}
              >
                Copy work item
              </DropdownMenuItem>
              {branchName && (
                <DropdownMenuItem
                  shortcut={copyIssueGitBranchKey}
                  onClick={() => {
                    copyGitBranch();
                  }}
                >
                  Copy git branch name
                </DropdownMenuItem>
              )}
            </SubmenuContent>
          </Submenu>
        </>
      )}
      <DropdownMenuSeparator />

      {featureFlags.FEATURE_TOGGLE_SNOOZE && isSnoozable && (
        <Submenu>
          <SubmenuTrigger icon="snooze">Snooze</SubmenuTrigger>
          <SubmenuContent className="menuSmall">
            <TimePicker
              onPicked={(days: number) => {
                snoozeIds(itemIds, moment().add(days, 'days').valueOf());
              }}
            />
          </SubmenuContent>
        </Submenu>
      )}
      {featureFlags.FEATURE_TOGGLE_SNOOZE && isUnsnoozable && (
        <DropdownMenuItem
          icon="snooze"
          onClick={() => {
            unsnoozeIds(itemIds);
          }}
        >
          Unsnooze
        </DropdownMenuItem>
      )}
      <DropdownMenuItem
        icon="member"
        shortcut={selfAssignIssueKey}
        onClick={() => {
          toggleAssigneesForEntities(itemIds, [user.id]);
        }}
      >
        {isMember ? 'Remove' : 'Add'} myself
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="watch"
        shortcut={watchIssueKey}
        onClick={() => {
          toggleWatching(itemIds, [user.id]);
        }}
      >
        {watching ? 'Stop watching' : 'Watch'}
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        onClick={() => {
          toggleStarred({ type: StarredType.WorkItem, id: item.id });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      {showHistory && (
        <DropdownMenuItem
          icon="history"
          shortcut={openHistoryKey}
          onClick={() => setOpenHistory(v => !v)}
        >
          {openHistory ? 'Close' : 'Open'} history
        </DropdownMenuItem>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="workspace"
        shortcut={moveIssueToNewSpaceKey}
        onClick={() => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.ChangeSpace,
            context: {
              entityIds: itemIds,
            },
          });
        }}
      >
        Move to space
      </DropdownMenuItem>
      <Submenu>
        <SubmenuTrigger icon="dependency">Dependency</SubmenuTrigger>
        <SubmenuContent>
          <Submenu>
            <SubmenuTrigger>Enables</SubmenuTrigger>
            <SubmenuContent className="menuPicker menuHuge">
              <DependencyPickerWrapper
                issues={items}
                dependencyType="enables"
                onDependenciesAdded={(issueIds, toAdd) => {
                  addDepenencies(issueIds, 'dependsOnId', DependencyType.DependsOn, [toAdd]);
                }}
                onDependenciesRemoved={(issueIds, toRemove) => {
                  removeDependencies(issueIds, 'dependsOnId', DependencyType.DependsOn, [toRemove]);
                }}
                onDone={closeMenu}
              />
            </SubmenuContent>
          </Submenu>
          <Submenu>
            <SubmenuTrigger>Depends on</SubmenuTrigger>
            <SubmenuContent className="menuPicker menuHuge">
              <DependencyPickerWrapper
                issues={items}
                dependencyType="dependsOn"
                onDependenciesAdded={(issueIds, toAdd) => {
                  addDepenencies(issueIds, 'enablesId', DependencyType.DependsOn, [toAdd]);
                }}
                onDependenciesRemoved={(issueIds, toRemove) => {
                  removeDependencies(issueIds, 'enablesId', DependencyType.DependsOn, [toRemove]);
                }}
                onDone={closeMenu}
              />
            </SubmenuContent>
          </Submenu>
          <Submenu>
            <SubmenuTrigger>Blocks</SubmenuTrigger>
            <SubmenuContent className="menuPicker menuHuge">
              <DependencyPickerWrapper
                issues={items}
                dependencyType="blocks"
                onDependenciesAdded={(issueIds, toAdd) => {
                  addDepenencies(issueIds, 'dependsOnId', DependencyType.BlockedBy, [toAdd]);
                }}
                onDependenciesRemoved={(issueIds, toRemove) => {
                  removeDependencies(issueIds, 'dependsOnId', DependencyType.BlockedBy, [toRemove]);
                }}
                onDone={closeMenu}
              />
            </SubmenuContent>
          </Submenu>
          <Submenu>
            <SubmenuTrigger>Blocked by</SubmenuTrigger>
            <SubmenuContent className="menuPicker menuHuge">
              <DependencyPickerWrapper
                issues={items}
                dependencyType="blockedBy"
                onDependenciesAdded={(issueIds, toAdd) => {
                  addDepenencies(issueIds, 'enablesId', DependencyType.BlockedBy, [toAdd]);
                }}
                onDependenciesRemoved={(issueIds, toRemove) => {
                  removeDependencies(issueIds, 'enablesId', DependencyType.BlockedBy, [toRemove]);
                }}
                onDone={closeMenu}
              />
            </SubmenuContent>
          </Submenu>
        </SubmenuContent>
      </Submenu>
      {items.length === 1 && (
        <DropdownMenuItem
          disabled={!paidPlan}
          icon="public"
          onClick={async () => {
            if (!paidPlan) {
              return;
            }
            if (item.public) {
              const confirmed = await confirm(
                `Are you sure you want to make this ${issueTerm} private?`,
                `Once made private, users outside of your organization will no longer be able to acceess this ${issueTerm}`,
                {
                  label: 'Make private',
                  destructive: true,
                }
              );
              if (!confirmed) {
                return;
              }
            }
            updateIssues([item.id], { public: !item.public });
            if (!item.public) {
              copyPublicLink(item.id);
            }
          }}
        >
          <Tooltip side="left" sideOffset={50} align="center" content={shareTooltip}>
            <span>Make {item.public ? 'private' : 'public'}</span>
          </Tooltip>
        </DropdownMenuItem>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="archive"
        shortcut={archiveIssueKey}
        onClick={() => {
          if (!archivedStatus || !defaultstatus) {
            return;
          }
          if (archived) {
            unarchive(itemIds);
          } else {
            moveIssues(itemIds, archivedStatus.id);
          }
        }}
      >
        {item.statusId === archivedStatus?.id ? `Unarchive` : `Archive`}
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteIssues(itemIds);
        }}
      >
        Delete
      </DropdownMenuItem>
      {initiativeId && (
        <DropdownMenuItem
          icon="delete"
          shortcut={removeItemKey}
          onClick={() => {
            removeIssuesFromInitiative([initiativeId], itemIds);
          }}
        >
          Remove from initiative
        </DropdownMenuItem>
      )}
    </>
  );
}

export function WorkItemMenuButton({
  item,
  buttonStyle,
}: {
  item: Issue;
  buttonStyle?: ButtonStyle;
}) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton buttonStyle={buttonStyle ?? ButtonStyle.BareSubtle} icon="more" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="menuMedium"
        onClick={e => {
          e.stopPropagation();
        }}
        side="right"
        align="start"
      >
        <WorkItemMenu showHistory item={item} closeMenu={closeMenu} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
