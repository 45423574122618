import debugModule from 'debug';
import gql from 'graphql-tag';
import { isArray, maxBy, omit, range } from 'lodash';
import { Client } from 'urql';
import {
  AppliedCollaborativeDocumentTransactionPayload,
  AppliedCollectionTransactionPayload,
  AppliedTransactionPayload,
  CollaborativeDocumentQuery,
  CollaborativeDocumentQueryVariables,
  CurrentOrganizationQuery,
  CurrentOrganizationQueryVariables,
  CurrentUserQuery,
  CyclesQuery,
  CyclesQueryVariables,
  ExternalIssuesQuery,
  ExternalIssuesQueryVariables,
  DocumentsQuery,
  DocumentsQueryVariables,
  InitiativesQuery,
  InitiativesQueryVariables,
  Issue,
  IssueByNumberQuery,
  IssueByNumberQueryVariables,
  IssueQuery,
  IssueQueryVariables,
  IssueStatusFilter,
  IssuesQuery,
  IssuesQueryVariables,
  MissedChangesQuery,
  MissedChangesQueryVariables,
  ReleasesQuery,
  ReleasesQueryVariables,
  RoadmapsQuery,
  RoadmapsQueryVariables,
  TransactionPayload,
  FeedbackQuery,
  FeedbackQueryVariables,
} from '../../../graphql__generated__/graphql';
import { Doc, Feedback } from '../../sync/__generated/models';
import { appendDiagnostic } from '../diagnostics';
import { SyncEngineObject } from '../syncEngine/types';
import {
  appliedCollaborativeDocumentTransactionPayloadFragment,
  appliedCollectionTransactionPayloadFragment,
  appliedTransactionPayloadFragment,
} from './fragments';
import {
  collaborativeDocumentQuery,
  currentOrganizationQuery,
  currentUserQuery,
  cyclesQuery,
  externalIssuesQuery,
  documentsQuery,
  initiativesQuery,
  issueByNumberQuery,
  issueQuery,
  issuesQuery,
  missingChangesQuery,
  releasesQuery,
  roadmapsQuery,
  feedbackQuery,
} from './queries';
export type {
  AppliedCollaborativeDocumentTransactionPayload,
  AppliedCollectionTransactionPayload,
  AppliedTransactionPayload,
} from '../../../graphql__generated__/graphql';
export { TransactionType } from '../../../graphql__generated__/graphql';

const debug = debugModule('modelManager');
const FETCH_SIZE = 200;
const FETCH_ERROR = 'Error fetching data';

export function isFetchError(e: Error): boolean {
  return e.message === FETCH_ERROR;
}

export type AppliedPayload =
  | AppliedTransactionPayload
  | AppliedCollectionTransactionPayload
  | AppliedCollaborativeDocumentTransactionPayload;
export type DocumentChange = AppliedCollaborativeDocumentTransactionPayload['changes'][0];
export type RequestCurrentUser = typeof requestCurrentUser;
export type RequestOrganization = typeof requestOrganization;
export type RequestIssues = typeof requestIssues;
export type RequestCycles = typeof requestCycles;
export type RequestDocuents = typeof requestDocuments;
export type RequestIssue = typeof requestIssue;
export type RequestIssueByNumber = typeof requestIssueByNumber;
export type RequestReleases = typeof requestReleases;
export type RequestRoadmaps = typeof requestRoadmaps;
export type RequestInitiatives = typeof requestInitiatives;
export type RequestExternalIssues = typeof requestExternalIssues;
export type RequestDocument = typeof requestDocument;
export type RequestDocumentChanges = typeof requestDocumentChanges;
export type SendTransaction = typeof sendTransaction;

export interface Transaction {
  id: string;
  clientId: string;
  payload: TransactionPayload[];
  rollBackData?: SyncEngineObject[];
}

function flattenResults(
  result: Record<string, SyncEngineObject | SyncEngineObject[] | null>
): SyncEngineObject[] {
  return Object.values(result).reduce((result: SyncEngineObject[], value) => {
    if (!value) {
      return result;
    }
    if (isArray(value)) {
      return [...result, ...value];
    }
    return [...result, value];
  }, []);
}

// FIXME: not really sure this is needed anymore since the urql client doesn't seem to throw
// in the case of errors, it just sets result.error
async function fetchWrapper<T>(fetchFunc: () => Promise<T>) {
  try {
    const result = await fetchFunc();
    return result;
  } catch (e) {
    debug('Error fetching', e);
    throw Error(FETCH_ERROR);
  }
}

export async function requestCurrentUser(client: Client): Promise<{
  data: SyncEngineObject[];
  user?: CurrentUserQuery['currentUser']['user'] | null;
}> {
  debug('Requesting current user');
  const result = await fetchWrapper(() =>
    client.query<CurrentUserQuery>(currentUserQuery, {}).toPromise()
  );

  const error = !!result.error || !result.data?.currentUser;
  if (result.error) {
    debug('Error requesting current user', result.error.message);
  }

  if (error) {
    throw Error(FETCH_ERROR);
  }

  if (!result.data?.currentUser?.user) {
    return {
      data: [],
    };
  }

  const data = result.data.currentUser;
  return {
    data: flattenResults(omit(data, ['__typename'])),
    user: data.user,
  };
}

export async function requestOrganization(
  client: Client,
  organizationId: string,
  options?: {
    lastFetched?: number | null;
  }
): Promise<{ data: SyncEngineObject[] }> {
  debug(`Request organization (organizationId=${organizationId})`);
  const result = await fetchWrapper(() =>
    client
      .query<CurrentOrganizationQuery, CurrentOrganizationQueryVariables>(
        currentOrganizationQuery,
        {
          id: organizationId,
        }
      )
      .toPromise()
  );

  const error = !!result.error || !result.data?.organization;
  if (result.error) {
    debug('Error requesting organization', result.error.message);
  }

  if (error || !result.data) {
    throw Error(FETCH_ERROR);
  }

  const data = result.data.organization;
  const [
    roadmapResult,
    initiativesResult,
    externalIssuesResult,
    documentsResults,
    feedbackResults,
    releasesResults,
  ] = await Promise.all([
    requestRoadmaps(client, organizationId),
    requestInitiatives(client, organizationId, options),
    requestExternalIssues(client, organizationId, options),
    requestDocuments(client, organizationId, options),
    requestFeedback(client, organizationId, options),
    requestReleases(client, organizationId),
  ]);

  return {
    data: [
      ...flattenResults(omit(data, ['__typename', 'serverTime'])),
      ...roadmapResult.data,
      ...initiativesResult.data,
      ...externalIssuesResult.data,
      ...documentsResults.data,
      ...feedbackResults.data,
      ...releasesResults.data,
    ],
  };
}

export async function requestIssues(
  client: Client,
  spaceId: string,
  options?: {
    lastFetched?: number | null;
    statusFilter?: IssueStatusFilter;
  }
): Promise<{ data: SyncEngineObject[] }> {
  const { lastFetched, statusFilter } = options ?? {};
  debug(
    `Request issues (spaceId=${spaceId}${lastFetched ? `, lastFetched=${lastFetched}` : ''}${
      statusFilter ? `, statusFilter=${JSON.stringify(statusFilter)}` : ''
    })`
  );

  const firstIssuesResult = await fetchWrapper(() =>
    client
      .query<IssuesQuery, IssuesQueryVariables>(issuesQuery, {
        space: spaceId,
        offset: 0,
        count: FETCH_SIZE,
        updatedSince: lastFetched,
        statusFilter,
      })
      .toPromise()
  );

  // if we didn't manage to fetch the first batch, we can't go on
  if (!firstIssuesResult.data?.issues) {
    throw Error(FETCH_ERROR);
  }

  const remainingIssues =
    firstIssuesResult.data.issues.total - firstIssuesResult.data.issues.issues.length;
  const numberOfIssueFetches = Math.ceil(remainingIssues / FETCH_SIZE);
  const issuesResults = await Promise.all(
    range(1, numberOfIssueFetches + 1).map(
      async index =>
        await fetchWrapper(() =>
          client
            .query<IssuesQuery, IssuesQueryVariables>(issuesQuery, {
              space: spaceId,
              offset: index * FETCH_SIZE,
              count: index === numberOfIssueFetches ? FETCH_SIZE + 10 : FETCH_SIZE,
              updatedSince: lastFetched,
              statusFilter,
            })
            .toPromise()
        )
    )
  );

  issuesResults.unshift(firstIssuesResult);

  let result: SyncEngineObject[] = [];
  for (const issuesResult of issuesResults) {
    const error = !!issuesResult.error || !issuesResult.data?.issues;
    if (issuesResult.error) {
      debug('Error requesting issues', issuesResult.error.message);
    }
    if (error || !issuesResult.data) {
      appendDiagnostic({
        type: 'workItemFetchError',
        spaceId,
        errorMessage: issuesResult.error?.message ?? 'Unknown',
      });
      throw Error(FETCH_ERROR);
    }

    result = [
      ...result,
      ...flattenResults(
        omit(issuesResult.data.issues, ['__typename', 'cursor', 'hasMore', 'total'])
      ),
    ];
  }

  const issues = result.filter(r => r.__typename === 'Issue') as Issue[];
  appendDiagnostic({
    type: 'workItemFetch',
    spaceId,
    maxNumber: `${maxBy(issues, i => parseInt(i.number, 10))?.number ?? 'none'}`,
  });
  return { data: result };
}

export async function requestDocuments(
  client: Client,
  organizationId: string,
  options?: {
    lastFetched?: number | null;
  }
): Promise<{ data: SyncEngineObject[] }> {
  const { lastFetched } = options ?? {};
  debug(
    `Request documents (organizationId=${organizationId}${
      lastFetched ? `, lastFetched=${lastFetched}` : ''
    })`
  );
  const firstDocumentsResult = await fetchWrapper(() =>
    client
      .query<DocumentsQuery, DocumentsQueryVariables>(
        documentsQuery,

        {
          organization: organizationId,
          offset: 0,
          count: FETCH_SIZE,
          updatedSince: lastFetched,
        }
      )
      .toPromise()
  );

  // if we didn't manage to fetch the first batch, we can't go on
  if (!firstDocumentsResult.data?.documents) {
    throw Error(FETCH_ERROR);
  }

  const remainingDocuments =
    firstDocumentsResult.data.documents.total -
    firstDocumentsResult.data.documents.documents.length;
  const numberOfDocumentsFetches = Math.ceil(remainingDocuments / FETCH_SIZE);
  const documentsResults = await Promise.all(
    range(1, numberOfDocumentsFetches + 1).map(
      async index =>
        await fetchWrapper(() =>
          client
            .query<DocumentsQuery, DocumentsQueryVariables>(
              documentsQuery,

              {
                organization: organizationId,
                offset: index * FETCH_SIZE,
                count: index === numberOfDocumentsFetches ? FETCH_SIZE + 10 : FETCH_SIZE,
                updatedSince: lastFetched,
              }
            )
            .toPromise()
        )
    )
  );
  documentsResults.unshift(firstDocumentsResult);

  let result: SyncEngineObject[] = [];
  for (const documentResult of documentsResults) {
    const error = !!documentResult.error || !documentResult.data?.documents;
    if (documentResult.error) {
      debug('Error requesting documents', documentResult.error.message);
    }
    if (error || !documentResult.data) {
      appendDiagnostic({
        type: 'documentFetchError',
        organizationId,
        errorMessage: documentResult.error?.message ?? 'Unknown',
      });
      throw Error(FETCH_ERROR);
    }

    result = [
      ...result,
      ...flattenResults(
        omit(documentResult.data.documents, ['__typename', 'cursor', 'hasMore', 'total'])
      ),
    ];
  }

  const documents = result.filter(r => r.__typename === 'Doc') as Doc[];
  appendDiagnostic({
    type: 'documentFetch',
    organizationId,
    maxNumber: `${maxBy(documents, t => parseInt(t.number, 10))?.number ?? 'none'}`,
  });
  return { data: result };
}

export async function requestFeedback(
  client: Client,
  organizationId: string,
  options?: {
    lastFetched?: number | null;
  }
): Promise<{ data: SyncEngineObject[] }> {
  const { lastFetched } = options ?? {};
  debug(
    `Request feedback (organizationId=${organizationId}${
      lastFetched ? `, lastFetched=${lastFetched}` : ''
    })`
  );
  const firstFeedbackResult = await fetchWrapper(() =>
    client
      .query<FeedbackQuery, FeedbackQueryVariables>(
        feedbackQuery,

        {
          organization: organizationId,
          offset: 0,
          count: FETCH_SIZE,
          updatedSince: lastFetched,
        }
      )
      .toPromise()
  );

  // if we didn't manage to fetch the first batch, we can't go on
  if (!firstFeedbackResult.data?.feedback) {
    throw Error(FETCH_ERROR);
  }

  const remainingFeedback =
    firstFeedbackResult.data.feedback.total - firstFeedbackResult.data.feedback.feedback.length;
  const numberOfFeedbackFetches = Math.ceil(remainingFeedback / FETCH_SIZE);
  const feedbackResults = await Promise.all(
    range(1, numberOfFeedbackFetches + 1).map(
      async index =>
        await fetchWrapper(() =>
          client
            .query<FeedbackQuery, FeedbackQueryVariables>(
              feedbackQuery,

              {
                organization: organizationId,
                offset: index * FETCH_SIZE,
                count: index === numberOfFeedbackFetches ? FETCH_SIZE + 10 : FETCH_SIZE,
                updatedSince: lastFetched,
              }
            )
            .toPromise()
        )
    )
  );
  feedbackResults.unshift(firstFeedbackResult);

  let result: SyncEngineObject[] = [];
  for (const feedbackResult of feedbackResults) {
    const error = !!feedbackResult.error || !feedbackResult.data?.feedback;
    if (feedbackResult.error) {
      debug('Error requesting feedback', feedbackResult.error.message);
    }
    if (error || !feedbackResult.data) {
      appendDiagnostic({
        type: 'feedbackFetchError',
        organizationId,
        errorMessage: feedbackResult.error?.message ?? 'Unknown',
      });
      throw Error(FETCH_ERROR);
    }

    result = [
      ...result,
      ...flattenResults(
        omit(feedbackResult.data.feedback, ['__typename', 'cursor', 'hasMore', 'total'])
      ),
    ];
  }

  const feedback = result.filter(r => r.__typename === 'Feedback') as Feedback[];
  appendDiagnostic({
    type: 'feedbackFetch',
    organizationId,
    maxNumber: `${maxBy(feedback, t => parseInt(t.number, 10))?.number ?? 'none'}`,
  });
  return { data: result };
}

export async function requestReleases(
  client: Client,
  organizationId: string
): Promise<{ data: SyncEngineObject[] }> {
  debug(`Request releases (organizationId=${organizationId})`);
  const result = await fetchWrapper(() =>
    client
      .query<ReleasesQuery, ReleasesQueryVariables>(releasesQuery, {
        organizationId,
      })
      .toPromise()
  );

  const error = !!result.error || !result.data?.releases;
  if (result.error) {
    debug('Error requesting releases', result.error.message);
  }
  if (error || !result.data) {
    throw Error(FETCH_ERROR);
  }

  const data = result.data.releases;
  return {
    data: flattenResults(omit(data, ['__typename'])),
  };
}

export async function requestRoadmaps(
  client: Client,
  organizationId: string
): Promise<{ data: SyncEngineObject[] }> {
  debug(`Request roadmaps (organizationId=${organizationId})`);
  const result = await fetchWrapper(() =>
    client
      .query<RoadmapsQuery, RoadmapsQueryVariables>(roadmapsQuery, {
        organizationId,
      })
      .toPromise()
  );

  const error = !!result.error || !result.data?.roadmaps;
  if (result.error) {
    debug('Error requesting roadmaps', result.error.message);
  }
  if (error || !result.data) {
    throw Error(FETCH_ERROR);
  }

  const data = result.data.roadmaps;
  return {
    data: flattenResults(omit(data, ['__typename'])),
  };
}

export async function requestInitiatives(
  client: Client,
  organizationId: string,
  options?: {
    spaceIds?: string[];
    initiativeSpaceIds?: string[];
    lastFetched?: number | null;
  }
): Promise<{ data: SyncEngineObject[] }> {
  const { lastFetched, spaceIds, initiativeSpaceIds } = options ?? {};
  debug(
    `Request initiatives (organizationId=${organizationId}${
      lastFetched ? `, lastFetched=${lastFetched}` : ''
    }`
  );

  const firstResult = await fetchWrapper(() =>
    client
      .query<InitiativesQuery, InitiativesQueryVariables>(initiativesQuery, {
        organizationId,
        spaceIds,
        initiativeSpaceIds,
        offset: 0,
        count: FETCH_SIZE,
        updatedSince: lastFetched,
      })
      .toPromise()
  );

  // if we didn't manage to fetch the first batch, we can't go on
  if (!firstResult.data?.initiatives) {
    throw Error(FETCH_ERROR);
  }

  const remaining =
    firstResult.data.initiatives.total - firstResult.data.initiatives.initiatives.length;
  const numberOfFetches = Math.ceil(remaining / FETCH_SIZE);
  const results = await Promise.all(
    range(1, numberOfFetches + 1).map(
      async index =>
        await fetchWrapper(() =>
          client
            .query<InitiativesQuery, InitiativesQueryVariables>(initiativesQuery, {
              organizationId,
              offset: index * FETCH_SIZE,
              count: index === numberOfFetches ? FETCH_SIZE + 10 : FETCH_SIZE,
              updatedSince: lastFetched,
            })
            .toPromise()
        )
    )
  );

  results.unshift(firstResult);

  let result: SyncEngineObject[] = [];
  for (const initiativesResult of results) {
    const error = !!initiativesResult.error || !initiativesResult.data?.initiatives;
    if (initiativesResult.error) {
      debug('Error requesting initiatives', initiativesResult.error.message);
    }
    if (error || !initiativesResult.data) {
      appendDiagnostic({
        type: 'initiativesFetchError',
        organizationId,
        errorMessage: initiativesResult.error?.message ?? 'Unknown',
      });
      throw Error(FETCH_ERROR);
    }

    result = [
      ...result,
      ...flattenResults(
        omit(initiativesResult.data.initiatives, ['__typename', 'cursor', 'hasMore', 'total'])
      ),
    ];
  }

  const initiatives = result.filter(r => r.__typename === 'Issue') as Issue[];
  appendDiagnostic({
    type: 'initiativesFetch',
    organizationId,
    maxNumber: `${maxBy(initiatives, i => parseInt(i.number, 10))?.number ?? 'none'}`,
  });
  return { data: result };
}

export async function requestExternalIssues(
  client: Client,
  organizationId: string,
  options?: {
    lastFetched?: number | null;
  }
): Promise<{ data: SyncEngineObject[] }> {
  const { lastFetched } = options ?? {};
  debug(
    `Request external issues (organizationId=${organizationId}${
      lastFetched ? `, lastFetched=${lastFetched}` : ''
    }`
  );

  const firstResult = await fetchWrapper(() =>
    client
      .query<ExternalIssuesQuery, ExternalIssuesQueryVariables>(externalIssuesQuery, {
        organizationId,
        offset: 0,
        count: FETCH_SIZE,
        updatedSince: lastFetched,
      })
      .toPromise()
  );

  // if we didn't manage to fetch the first batch, we can't go on
  if (!firstResult.data?.externalIssues) {
    throw Error(FETCH_ERROR);
  }

  const remaining =
    firstResult.data.externalIssues.total - firstResult.data.externalIssues.externalIssues.length;
  const numberOfFetches = Math.ceil(remaining / FETCH_SIZE);
  const results = await Promise.all(
    range(1, numberOfFetches + 1).map(
      async index =>
        await fetchWrapper(() =>
          client
            .query<ExternalIssuesQuery, ExternalIssuesQueryVariables>(externalIssuesQuery, {
              organizationId,
              offset: index * FETCH_SIZE,
              count: index === numberOfFetches ? FETCH_SIZE + 10 : FETCH_SIZE,
              updatedSince: lastFetched,
            })
            .toPromise()
        )
    )
  );

  results.unshift(firstResult);

  let result: SyncEngineObject[] = [];
  for (const externalIssuesResult of results) {
    const error = !!externalIssuesResult.error || !externalIssuesResult.data?.externalIssues;
    if (externalIssuesResult.error) {
      debug('Error requesting external issues', externalIssuesResult.error.message);
    }
    if (error || !externalIssuesResult.data) {
      appendDiagnostic({
        type: 'externalIssuessFetchError',
        organizationId,
        errorMessage: externalIssuesResult.error?.message ?? 'Unknown',
      });
      throw Error(FETCH_ERROR);
    }

    result = [
      ...result,
      ...flattenResults(
        omit(externalIssuesResult.data.externalIssues, ['__typename', 'hasMore', 'total'])
      ),
    ];
  }

  return { data: result };
}

export async function requestIssue(
  client: Client,
  issueId: string
): Promise<{ data: SyncEngineObject[] }> {
  debug(`Request issue (issueId=${issueId})`);
  const result = await fetchWrapper(() =>
    client
      .query<IssueQuery, IssueQueryVariables>(issueQuery, {
        id: issueId,
      })
      .toPromise()
  );

  const error = !!result.error || !result.data?.issue;
  if (result.error) {
    debug('Error requesting issue', result.error.message);
  }
  if (error || !result.data) {
    throw Error(FETCH_ERROR);
  }

  const data = result.data.issue;
  return {
    data: flattenResults(omit(data, ['__typename'])),
  };
}

export async function requestIssueByNumber(
  client: Client,
  spaceId: string,
  issueNumber: number
): Promise<{ data: SyncEngineObject[] }> {
  debug(`request issue by number (spaceId=${spaceId}, issueNumber=${issueNumber})`);
  const result = await fetchWrapper(() =>
    client
      .query<IssueByNumberQuery, IssueByNumberQueryVariables>(issueByNumberQuery, {
        spaceId,
        issueNumber: `${issueNumber}`,
      })
      .toPromise()
  );
  const error = !!result.error || !result.data?.issueByNumber;
  if (result.error) {
    debug('Error requesting issue by number', result.error.message);
  }
  if (error) {
    // don't treat 404s as real errors. We'll handle them further up in the UI
    if (result.error?.graphQLErrors[0]?.extensions?.code === 'ISSUE_NOT_FOUND') {
      return { data: [] };
    }
    throw Error(FETCH_ERROR);
  }

  if (!result.data?.issueByNumber) {
    return { data: [] };
  }
  const data = result.data.issueByNumber;
  return {
    data: flattenResults(omit(data, ['__typename'])),
  };
}

export async function requestCycles(
  client: Client,
  spaceId: string,
  options?: {
    lastFetched?: number | null;
  }
): Promise<{ data: SyncEngineObject[] }> {
  const { lastFetched } = options ?? {};
  debug(`Request cycles (spaceId=${spaceId}${lastFetched ? `, lastFetched=${lastFetched}` : ''})`);
  const firstCyclesResult = await fetchWrapper(() =>
    client
      .query<CyclesQuery, CyclesQueryVariables>(cyclesQuery, {
        space: spaceId,
        offset: 0,
        count: FETCH_SIZE,
        updatedSince: lastFetched,
      })
      .toPromise()
  );

  // if we didn't manage to fetch the first batch, we can't go on
  if (!firstCyclesResult.data?.cycles) {
    throw Error(FETCH_ERROR);
  }

  const remainingCycles =
    firstCyclesResult.data.cycles.total - firstCyclesResult.data.cycles.cycles.length;
  const numberOfCyclesFetches = Math.ceil(remainingCycles / FETCH_SIZE);
  const cyclesResults = await Promise.all(
    range(1, numberOfCyclesFetches + 1).map(
      async index =>
        await fetchWrapper(() =>
          client
            .query<CyclesQuery, CyclesQueryVariables>(
              cyclesQuery,

              {
                space: spaceId,
                offset: index * FETCH_SIZE,
                count: index === numberOfCyclesFetches ? FETCH_SIZE + 10 : FETCH_SIZE,
                updatedSince: lastFetched,
              }
            )
            .toPromise()
        )
    )
  );
  cyclesResults.unshift(firstCyclesResult);

  let result: SyncEngineObject[] = [];
  for (const cyclesResult of cyclesResults) {
    const error = !!cyclesResult.error || !cyclesResult.data?.cycles;
    if (cyclesResult.error) {
      debug('Error requesting cycles', cyclesResult.error.message);
    }
    if (error || !cyclesResult.data) {
      throw Error(FETCH_ERROR);
    }

    result = [
      ...result,
      ...flattenResults(omit(cyclesResult.data.cycles, ['__typename', 'hasMore', 'total'])),
    ];
  }

  return { data: result };
}

export async function requestDocument(
  client: Client,
  id: string
): Promise<{ data: CollaborativeDocumentQuery['collaborativeDocument'] | null }> {
  debug(`requestDocument(id=${id}`);
  const result = await fetchWrapper(() =>
    client
      .query<CollaborativeDocumentQuery, CollaborativeDocumentQueryVariables>(
        collaborativeDocumentQuery,
        {
          id,
        }
      )
      .toPromise()
  );

  if (result.error) {
    // don't treat 404s as real errors since it might just mean something was freshly created
    if (result.error.graphQLErrors[0]?.extensions?.status === 404) {
      return { data: null };
    }
    throw result.error;
  }

  if (!result.data) {
    return { data: null };
  }

  return {
    data: result.data.collaborativeDocument,
  };
}

export async function requestDocumentChanges(
  client: Client,
  entityId: string,
  sinceVersion: number
): Promise<{ data: MissedChangesQuery['missedDocumentChanges'] | null }> {
  debug(`requestDocumentChanges(entityId=${entityId}, sinceVersion=${sinceVersion})`);
  const result = await fetchWrapper(() =>
    client
      .query<MissedChangesQuery, MissedChangesQueryVariables>(missingChangesQuery, {
        id: entityId,
        sinceVersion,
      })
      .toPromise()
  );

  if (result.error) {
    // don't treat 404s as real errors since it might just mean something was freshly created
    if (result.error.graphQLErrors[0]?.extensions?.status === 404) {
      return { data: null };
    }
    throw result.error;
  }

  if (!result.data) {
    return { data: null };
  }

  return {
    data: result.data.missedDocumentChanges,
  };
}

export async function sendTransaction(
  client: Client,
  clientId: string,
  transaction: Transaction
): Promise<AppliedPayload[]> {
  debug('Attempting to send transaction', transaction);
  const result = await client
    .mutation(
      gql`
        mutation SendTransaction($id: ID!, $clientId: String!, $payload: [TransactionPayload!]!) {
          sendTransaction(input: { id: $id, clientId: $clientId, payload: $payload }) {
            id
            payload {
              ... on AppliedTransactionPayload {
                ...AppliedTransactionPayloadFragment
              }
              ... on AppliedCollectionTransactionPayload {
                ...AppliedCollectionTransactionPayloadFragment
              }
              ... on AppliedCollaborativeDocumentTransactionPayload {
                ...AppliedCollaborativeDocumentTransactionPayloadFragment
              }
            }
          }
        }
        ${appliedTransactionPayloadFragment}
        ${appliedCollectionTransactionPayloadFragment}
        ${appliedCollaborativeDocumentTransactionPayloadFragment}
      `,
      {
        id: transaction.id,
        payload: transaction.payload,
        clientId,
      }
    )
    .toPromise();

  if (result.error) {
    throw result.error;
  }

  return result.data!.sendTransaction.payload;
}
