import { values } from 'lodash';
import { useHistory } from 'react-router-dom';
import { IntegrationType } from '../../sync/__generated/models';
import { useOrganization } from '../contexts/organizationContext';
import { organizationPath } from '../syncEngine/selectors/organizations';
import { stringifyIntegrationType } from '../utils/integrations';
import { CommandDefinition, CommandGroup } from './state';

export function useSettingsNavigationCommandGroup(): CommandDefinition[] {
  const organization = useOrganization();
  const history = useHistory();

  const commands: CommandDefinition[] = [
    {
      id: 'browse-preferences',
      description: 'Go to settings',
      aliases: ['settings'],
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/organization'));
      },
    },
    {
      id: 'settings-members',
      description: 'Manage team',
      aliases: ['users', 'members', 'team settings', 'user settings'],
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/members'));
      },
    },
    {
      id: 'settings-feedback',
      description: 'Feedback settings',
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/feedback'));
      },
    },
    {
      id: 'settings-roadmap',
      description: 'Roadmap and initiative settings',
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/roadmaps'));
      },
    },
    {
      id: 'settings-developer',
      description: 'Developer settings',
      aliases: ['webhooks', 'hooks', 'access tokens', 'api'],
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/developer'));
      },
    },
    {
      id: 'settings-integrations',
      description: 'Integration settings',
      aliases: values(IntegrationType)
        .filter(t => ![IntegrationType.Custom, IntegrationType.Email].includes(t))
        .map(t => stringifyIntegrationType(t)),
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/integrations'));
      },
    },
    {
      id: 'settings-import',
      description: 'Import',
      aliases: ['trello', 'github'],
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/import'));
      },
    },

    // Account settings
    {
      id: 'settings-profile',
      description: 'Edit profile',
      aliases: ['account', 'account settings', 'profile settings'],
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/profile'));
      },
    },
    {
      id: 'settings-user-preferences',
      description: 'Account preferences',
      aliases: ['settings', 'user', 'account settings', 'user settings'],
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/preferences'));
      },
    },
    {
      id: 'settings-notifications',
      description: 'Notification settings',
      icon: 'settings',
      handler: () => {
        history.push(organizationPath(organization, 'settings/notifications'));
      },
    },
  ];

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.SettingsNavigation }));
}
