import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Icon } from '../../../components/new/icon';
import { ListViewItem } from '../../../components/new/listView';
import { Picker } from '../../../components/new/picker';
import { useUpdateIssues } from '../../../syncEngine/actions/issues';
import { useUpdateTodos } from '../../../syncEngine/actions/todos';
import styles from '../commandMenu.module.scss';

enum RelativeDueDate {
  None = 'none',
  Today = 'today',
  Tomorrow = 'tomorrow',
  NextWeek = 'next week',
}

function DueDatePicker({
  onDone,
  onPicked,
  filterClassName,
}: {
  onPicked: (itemIds: string[], relativeDueDate: RelativeDueDate) => void;
  onDone: () => void;
  filterClassName?: string;
}) {
  const items: ListViewItem[] = Object.values(RelativeDueDate).map(relativeDueDate => {
    return {
      id: relativeDueDate,
      text: relativeDueDate,
      contents: () => (
        <>
          <Icon icon="due_date" />
          <span className={styles.text}>{capitalize(relativeDueDate)}</span>
        </>
      ),
    };
  });

  const onAdd = React.useCallback(
    (issueIds: string[], relativeDueDate: string) => {
      onPicked(issueIds, relativeDueDate as RelativeDueDate);
      onDone();
    },
    [onDone, onPicked]
  );

  return (
    <Picker
      noSort
      filterLabel="Change due date"
      filterPlaceholder="Change due date"
      state={{}}
      items={items}
      propertiesToSearch={['name']}
      onAdd={onAdd}
      onRemove={ids => onAdd(ids, RelativeDueDate.None)}
      onDone={onDone}
      filterClassName={filterClassName}
    />
  );
}

export function ChangeDueDateContents({
  onClose,
  itemIds,
  type,
}: {
  onClose: () => void;
  itemIds: string[];
  type: 'Todo' | 'Issue';
}) {
  const updateIssues = useUpdateIssues();
  const updateTodos = useUpdateTodos();

  function update(itemIds: string[], relativeDueDate: RelativeDueDate) {
    let dueDate: number | null = null;
    switch (relativeDueDate) {
      case RelativeDueDate.Today:
        dueDate = moment().utc().startOf('day').toDate().getTime();
        break;
      case RelativeDueDate.Tomorrow:
        dueDate = moment().utc().add(1, 'day').startOf('day').toDate().getTime();
        break;
      case RelativeDueDate.NextWeek:
        dueDate = moment().utc().add(1, 'week').startOf('isoWeek').toDate().getTime();
        break;
    }

    if (type === 'Issue') {
      updateIssues(itemIds, { dueDate });
    } else {
      updateTodos(itemIds, { dueDate });
    }
  }

  return (
    <div className="rowStretch fullWidth fullHeight">
      <DueDatePicker
        filterClassName={styles.inputDiv}
        onPicked={(_ids, dueDate) => {
          update(itemIds, dueDate);
        }}
        onDone={onClose}
      />
    </div>
  );
}
