import { EventEmitter } from 'eventemitter3';
import { History } from 'history';
import notificationIcon from '../assets/notifications/notification.png';
import { isElectron } from '../electronIntegration';

export const notificationsSupported = 'Notification' in window;
const havePermission = 'haveNotificationPermissions';
const permissionChanged = 'notificationPermissionChanged';

const eventEmitter = new EventEmitter();

function requestPermission(): Promise<NotificationPermission> {
  return new Promise(resolve => {
    Notification.requestPermission(permission => resolve(permission));
  });
}
export function haveNotificationPermission(): boolean {
  if (!notificationsSupported) {
    return false;
  }

  return Notification.permission === 'granted' && localStorage.getItem(havePermission) === 'true';
}

export async function enableNotifications(): Promise<boolean> {
  if (!notificationsSupported) {
    return false;
  }

  if (Notification.permission === 'denied') {
    return false;
  }

  if (Notification.permission !== 'granted') {
    const permission = await requestPermission();
    if (permission !== 'granted') {
      return false;
    }
  }

  localStorage.setItem(havePermission, 'true');
  eventEmitter.emit(permissionChanged, true);
  return true;
}

export function disableNotifications() {
  if (!notificationsSupported) {
    return;
  }

  if (Notification.permission !== 'granted') {
    return;
  }

  localStorage.removeItem(havePermission);
  eventEmitter.emit(permissionChanged, false);
}

export async function toggleNotifications() {
  if (!haveNotificationPermission()) {
    return enableNotifications();
  } else {
    disableNotifications();
    return false;
  }
}

export function addNotificationPermissionChangedListener(listener: (enabled: boolean) => void) {
  eventEmitter.on(permissionChanged, listener);
}

export function removeNotificationPermissionChangedListener(listener: (enabled: boolean) => void) {
  eventEmitter.off(permissionChanged, listener);
}

export async function showNotification(
  history: History,
  onClick: () => void,
  url: string,
  title: string,
  body?: string
) {
  if (!haveNotificationPermission()) {
    const enabled = await enableNotifications();
    if (!enabled) {
      return;
    }
  }

  const notification = new Notification(title, {
    body,
    icon: isElectron() ? undefined : notificationIcon,
  });

  notification.onclick = () => {
    notification.close();
    parent.focus();
    onClick();
    const parsed = new URL(url);
    history.push({ pathname: parsed.pathname, search: parsed.search });
  };
}
