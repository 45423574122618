import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  CreatedFromActivityDetails,
} from '../../../../sync/__generated/models';
import {
  entityTypeSelector,
  isIssueSelector,
  useEntityPath,
} from '../../../syncEngine/selectors/entities';
import { spaceKeyForIssueSelector } from '../../../syncEngine/selectors/issues';
import ActorName from '../../actorName';
import { Hotkey } from '../hotkey';
import { useHasKeyNavigationFocus } from '../keyNavigation';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function CreatedFromActivityComponent({ activity }: { activity: ActivityModel }) {
  const focused = useHasKeyNavigationFocus(activity.id);
  const history = useHistory();

  const details = activity.details as CreatedFromActivityDetails;
  const entityId = details.entityId;

  const entityType = useRecoilValue(entityTypeSelector(activity.entityId));

  const spaceKey = useRecoilValue(spaceKeyForIssueSelector(entityId));
  const entityPath = useEntityPath();
  const isIssue = useRecoilValue(isIssueSelector(entityId));

  let issueNumber = spaceKey
    ? `${spaceKey}-${details.fallbackEntityNumber}`
    : `${details.fallbackSpaceKey}-${details.fallbackEntityNumber}`;

  if (!isIssue) {
    issueNumber = `${issueNumber} (DELETED)`;
  }

  const link = entityPath(entityId);
  const entityNumberComponent = link ? (
    <Link to={link}>{issueNumber}</Link>
  ) : (
    <span>{issueNumber}</span>
  );

  let where = '';
  if (!details.duplicated) {
    if (details.createdFromCommentId) {
      where = ' a comment in';
    } else if (details.createdFromTodoId) {
      where = ' a todo in';
    } else {
      where = ' the description of';
    }
  }

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> created{' '}
      <span className={styles.activityHighlight}>{entityNumberComponent}</span>{' '}
      {details.duplicated ? 'by duplicating' : 'from'}
      {where} this {entityType}.
      <ActivityTimestamp timestamp={activity.createdAt} />
      {focused && link && (
        <Hotkey
          hotkey={'enter'}
          handler={() => {
            history.push(link);
          }}
        />
      )}
    </Activity>
  );
}

export const CreatedFromActivity = React.memo(CreatedFromActivityComponent);
