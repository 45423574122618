import cn from 'classnames';
import * as React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Update as UpdateModel } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import { StaticSlateDocument } from '../../slate/staticSlate';
import { useMarkUpdatesRead } from '../../syncEngine/actions/updates';
import { entityKeySelector, entitySelector } from '../../syncEngine/selectors/entities';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { isMobileOS, markNotificationReadKey } from '../../utils/config';
import Markdown from '../markdown';
import { ActorAvatar } from './actorAvatar';
import { Button, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { KeyboardShortcut } from './keyboardShortcut';
import { AvatarSize } from './metadata/avatar';
import { Timestamp } from './timestamp';
import styles from './update.module.scss';

function UpdateComponent(
  {
    update,
    onClick,
    onMarkRead,
    hotkeys,
    className,
    contentClassName,
    slateVoidBlockPlaceholders,
    clampContent,
    showUnreadIndicator,
  }: {
    update: UpdateModel;
    onClick?: () => void;
    onMarkRead?: () => void;
    className?: string;
    contentClassName?: string;
    hotkeys?: boolean;
    slateVoidBlockPlaceholders?: boolean;
    clampContent?: boolean;
    showUnreadIndicator?: boolean;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const organization = useOrganization();
  const entity = useRecoilValue(entitySelector(update?.entityId));
  const entityKey = useRecoilValue(entityKeySelector(entity?.id));
  const markUpdatesRead = useMarkUpdatesRead();
  const history = useHistory();

  if (!update) {
    return null;
  }

  const title = entity ? `${entityKey} ${entity.title}` : update.fallbackTitle;

  const parsedUrl = new URL(update.link);

  const linkTo = {
    hostname: parsedUrl.hostname,
    pathname: parsedUrl.pathname,
    search: parsedUrl.search,
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      update: update.id,
      siblingEntities: update.siblingEntityIds ?? (entity ? [entity.id] : []),
      breadcrumbs: update.breadcrumbs
        ? update.breadcrumbs.map(name => ({ name }))
        : [
            {
              name: 'Updates',
              link: organizationPath(organization, 'updates'),
            },
          ],
    },
  };

  return (
    <div
      className={cn(
        styles.updateListItem,
        {
          [styles.nonNotification]: !update.notification,
        },
        className
      )}
    >
      <NavLink
        to={linkTo}
        href={update.link}
        onClick={() => {
          markUpdatesRead([update.id], true);
          onClick?.();
        }}
      >
        <div className={cn(styles.contents, contentClassName)} ref={ref}>
          <div className={styles.avatar}>
            {update.notification && (
              <>
                <ActorAvatar size={AvatarSize.Size32} actorId={update.actorIds[0]} />
                {update.notification && !update.read && showUnreadIndicator && (
                  <div className={styles.unreadIndicator} />
                )}
              </>
            )}
          </div>
          <div className="grow overflowHidden">
            <Markdown className={cn('mt4', styles.action)} inline>
              {update.markdownAction ?? ''}
            </Markdown>
            {!update.notification && ' on '}
            <div
              className={cn('headingS', 'mt4', 'mb4', 'fs-exclude', styles.title, {
                inline: !update.notification,
              })}
            >
              {title}
            </div>
            {update.inlineCommentContext && (
              <StaticSlateDocument
                className={cn(styles.commentContext, 'fs-exclude')}
                value={update.inlineCommentContext}
                voidBlockPlaceholders
              />
            )}

            {!update.richContent && (
              <Markdown className={cn(styles.context, 'fs-exclude')}>
                {update.context ?? ''}
              </Markdown>
            )}
            {update.richContent && (
              <StaticSlateDocument
                className={cn('fs-exclude', {
                  [styles.clampContent]: clampContent,
                })}
                value={update.richContent}
                voidBlockPlaceholders={slateVoidBlockPlaceholders}
              />
            )}
            <Timestamp timestamp={update.createdAt} className="grayedLight bodyS inline mt2" />
          </div>
          {!isMobileOS && update.notification && (
            <Button
              buttonStyle={ButtonStyle.SecondaryOverlay}
              className={styles.markRead}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                markUpdatesRead([update.id], !update.read);
                onMarkRead?.();
              }}
            >
              <span>Mark as {update.read ? 'unread' : 'read'}</span>
              {hotkeys && (
                <KeyboardShortcut
                  className={styles.shortcut}
                  shortcut={`${markNotificationReadKey}`}
                />
              )}
            </Button>
          )}
          {hotkeys && (
            <>
              <CustomCommand
                command={{
                  id: 'open-update',
                  hotkey: 'enter',
                  group: CommandGroup.Navigation,
                  description: `Go to update`,
                  priority: 100,
                  handler: () => {
                    markUpdatesRead([update.id], true);
                    history.push(linkTo);
                  },
                }}
              />
              {update.notification && !update.read && (
                <CustomCommand
                  command={{
                    id: 'mark-update-read',
                    hotkey: markNotificationReadKey,
                    group: CommandGroup.Update,
                    description: `Mark update as read`,
                    priority: 100,
                    handler: () => {
                      markUpdatesRead([update.id], true);
                      onMarkRead?.();
                    },
                  }}
                />
              )}
              {update.notification && update.read && (
                <CustomCommand
                  command={{
                    id: 'mark-update-unread',
                    hotkey: markNotificationReadKey,
                    group: CommandGroup.Update,
                    description: `Mark update as unread`,
                    priority: 100,
                    handler: () => {
                      markUpdatesRead([update.id], false);
                    },
                  }}
                />
              )}
            </>
          )}
        </div>
      </NavLink>
    </div>
  );
}

export const Update = React.forwardRef(UpdateComponent);
