import cn from 'classnames';
import React from 'react';
import { Icon } from '../icon';
import { MetadataTooltip, TooltipHeader } from '../tooltip';
import Avatar, { AvatarCricle, AvatarSize } from './avatar';
import styles from './avatarGroup.module.scss';

export type AvatarData = {
  name: string;
  img?: string | null;
  id: string;
  invited?: boolean;
  deactivated?: boolean;
  guest?: boolean;
};

export function AvatarClipPath() {
  return (
    <div style={{ position: 'absolute', left: '-100000', top: '-10000' }}>
      <svg width="0" height="0">
        <clipPath id="avatarClip" clipPathUnits="objectBoundingBox">
          <path d="M0.11116 0.814354C0.1675 0.722937 0.200002 0.615264 0.200002 0.5C0.200002 0.384736 0.1675 0.277062 0.11116 0.185646C0.202825 0.0724013 0.342959 0 0.500002 0C0.776145 0 1 0.223858 1 0.5C1 0.776142 0.776145 1 0.500002 1C0.342959 1 0.202825 0.927599 0.11116 0.814354Z " />
        </clipPath>
      </svg>
    </div>
  );
}

function AvatarHover({ title, avatarData }: { title?: string; avatarData: AvatarData[] }) {
  return (
    <>
      {title && <TooltipHeader title={title} />}
      {avatarData.map(a => {
        return (
          <div className={styles.hoverRow} key={a.id}>
            {!a.deactivated && <Avatar name={a.name} img={a.img} />}
            {a.deactivated && (
              <AvatarCricle content={<Icon style={{ fill: 'var(--grayA9)' }} icon="disabled" />} />
            )}
            <span>{a.name}</span>
            {a.invited && <span className={styles.info}>(invited)</span>}
            {a.deactivated && <span className={styles.info}>(deactivated)</span>}
            {a.guest && <span className={styles.info}>(guest)</span>}
          </div>
        );
      })}
    </>
  );
}

function AvatarGroupComponent(
  {
    avatarData,
    max = 2,
    className,
    size,
    title,
    disableTooltip,
    ...props
  }: {
    avatarData: AvatarData[];
    max?: number | null;
    size?: AvatarSize;
    title?: string;
    disableTooltip?: boolean;
  } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  let slicedData = avatarData;
  if (max && avatarData.length > max) {
    slicedData = avatarData.slice(0, max - 1);
  }

  const avatars = slicedData.map(d =>
    d.deactivated ? (
      <AvatarCricle
        size={size}
        content={<Icon style={{ fill: 'var(--grayA9)' }} icon="disabled" />}
      />
    ) : (
      <Avatar size={size} key={d.id} name={d.name} img={d.img} />
    )
  );
  if (slicedData.length !== avatarData.length) {
    avatars.push(
      <AvatarCricle
        size={size}
        content={`${avatarData.length - slicedData.length}`}
        key="avatar-counter"
      />
    );
  }

  const content = (
    <div {...props} ref={ref} className={cn(styles.avatarGroup, className)}>
      {avatars}
    </div>
  );

  if (disableTooltip) {
    return content;
  }

  return (
    <MetadataTooltip
      align="start"
      side="bottom"
      content={<AvatarHover title={title} avatarData={avatarData} />}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {content}
    </MetadataTooltip>
  );
}

const AvatarGroup = React.forwardRef(AvatarGroupComponent);
export default AvatarGroup;
