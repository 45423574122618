import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { AutomationEvents } from '../../../../sync/__generated/models';
import { Select } from '../../../components/new/select';
import { Setting, SettingsPage, SettingsSection } from '../../../components/new/settings';
import { useSpace } from '../../../contexts/spaceContext';
import { useUpdateSpaces } from '../../../syncEngine/actions/spaces';
import { statusesForSpaceInBoardOrderSelector } from '../../../syncEngine/selectors/boards';

function cloneAutomationEvents(events: AutomationEvents): AutomationEvents {
  return {
    git_closed: events.git_closed ? { statusId: events.git_closed.statusId } : null,
    git_in_progress: events.git_in_progress ? { statusId: events.git_in_progress.statusId } : null,
    git_pull_request: events.git_pull_request
      ? { statusId: events.git_pull_request.statusId }
      : null,
  };
}

export function AutomationSettingsScreen() {
  const space = useSpace();

  const statuses = useRecoilValue(statusesForSpaceInBoardOrderSelector(space.id));
  const updateSpaces = useUpdateSpaces();

  return (
    <SettingsPage title="Automation">
      <SettingsSection
        title="Git integration events"
        largeHeader
        description="When you mention a work item in a commit or pull request with keywords “Fixes” or “Closes”
          (e.g. “Fixes KM-123”) we move it to a provided status."
      >
        <Setting
          title="When a pull request or commit that is merged to the main (default) branch"
          description={`Move ${issueTerm} to a status`}
        >
          <Select
            placeholder="Select interval"
            value={space.automationEvents['git_closed']?.statusId ?? 'none'}
            values={[
              { value: 'none', label: `Don't move` },
              ...statuses.map(status => ({ value: status.id, label: status.name })),
            ]}
            onChange={statusId => {
              updateSpaces([space.id], {
                automationEvents: {
                  ...cloneAutomationEvents(space.automationEvents),
                  git_closed: statusId === 'none' ? null : { statusId },
                },
              });
            }}
          />
        </Setting>

        <Setting
          title="When a commit pushed to a branch"
          description={`Move ${issueTerm} to a status`}
        >
          <Select
            placeholder="Select interval"
            value={space.automationEvents['git_in_progress']?.statusId ?? 'none'}
            values={[
              { value: 'none', label: `Don't move` },
              ...statuses.map(status => ({ value: status.id, label: status.name })),
            ]}
            onChange={statusId => {
              updateSpaces([space.id], {
                automationEvents: {
                  ...cloneAutomationEvents(space.automationEvents),
                  git_in_progress: statusId === 'none' ? null : { statusId },
                },
              });
            }}
          />
        </Setting>

        <Setting
          title="When a new pull request is created"
          description={`Move ${issueTerm} to a status`}
        >
          <Select
            placeholder="Select interval"
            value={space.automationEvents['git_pull_request']?.statusId ?? 'none'}
            values={[
              { value: 'none', label: `Don't move` },
              ...statuses.map(status => ({ value: status.id, label: status.name })),
            ]}
            onChange={statusId => {
              updateSpaces([space.id], {
                automationEvents: {
                  ...cloneAutomationEvents(space.automationEvents),
                  git_pull_request: statusId === 'none' ? null : { statusId },
                },
              });
            }}
          />
        </Setting>
      </SettingsSection>
    </SettingsPage>
  );
}
