import { RecoilValueReadOnly, selectorFamily, useRecoilCallback } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { spaceRoadmapsByRoadmap, spacesByOrganization } from '../../../sync/__generated/indexes';
import { MemberRole, Organization, Space, SpaceRoadmap } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import {
  activeUsersForOrganizationSelector,
  allUsersForOrganizationSelector,
  organizationsSelector,
  usersWithMemberInfoForOrganizationSelector,
} from './organizations';
import { roadmapsForOrganizationSelector } from './roadmaps';
import { sortUsers } from './users';

export function spacePath(organization: Organization, space: Space, additionalPath?: string) {
  const base = `/${organization.slug}/${space.slug}`;
  return additionalPath ? `${base}/${additionalPath}` : base;
}

export function useSpacePath() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (spaceId: string, additionalPath?: string) => {
        const space = snapshot.getLoadable(spaceSelector(spaceId)).getValue();
        const organization = snapshot
          .getLoadable(organizationsSelector(space?.organizationId))
          .getValue();

        if (!space || !organization) {
          return '';
        }
        return spacePath(organization, space, additionalPath);
      },
    []
  );
}

export function spaceSettingsPath(
  organization: Organization,
  space: Space,
  additionalPath?: string
) {
  const base = `/${organization.slug}/settings/${space.slug}`;
  return additionalPath ? `${base}/${additionalPath}` : base;
}

export function useSpaceSettingsPath() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (spaceId: string, additionalPath?: string) => {
        const space = snapshot.getLoadable(spaceSelector(spaceId)).getValue();
        const organization = snapshot
          .getLoadable(organizationsSelector(space?.organizationId))
          .getValue();

        if (!space || !organization) {
          return '';
        }
        return spaceSettingsPath(organization, space, additionalPath);
      },
    []
  );
}

export const spacesForOrganizationSelector = selectorFamily({
  key: 'SpacesForOrganization',
  get:
    (organizationId: string | null | undefined) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }

      const spaceIds = get(indexKeyState(indexKey(spacesByOrganization, organizationId)));
      return filterNotDeletedNotNull(
        spaceIds.map(spaceId => get(syncEngineState(spaceId)) as Space | null)
      );
    },
});

export const allSpacesForOrganizationSelector = selectorFamily({
  key: 'SpacesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const spaceIds = get(indexKeyState(indexKey(spacesByOrganization, organizationId)));
      return filterNotNull(spaceIds.map(spaceId => get(syncEngineState(spaceId)) as Space | null));
    },
});

export const spaceSelector = selectorFamily({
  key: 'Space',
  get:
    (spaceId: string | undefined | null) =>
    ({ get }) => {
      if (!spaceId) {
        return null;
      }
      return notDeleted(get(syncEngineState(spaceId)) as Space | null);
    },
});

export const spacesSelector = selectorFamily({
  key: 'Spaces',
  get:
    (spaceIds: string[] | undefined | null) =>
    ({ get }) => {
      if (!spaceIds) {
        return [];
      }

      const spaces = spaceIds.map(spaceId => get(spaceSelector(spaceId)) as Space | null);
      return filterNotNull(spaces);
    },
});

export const activeUsersForSpaceSelector = selectorFamily({
  key: 'ActiveUsersForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }

      const space = get(syncEngineState(spaceId)) as Space;
      const members = get(usersWithMemberInfoForOrganizationSelector(space.organizationId));
      return sortUsers(
        members.filter(({ member, user }) => {
          if (member.deactivated) {
            return false;
          }
          if (space.private || member.role === MemberRole.Guest) {
            return space.members.includes(user.id);
          }
          return true;
        }),
        space
      );
    },
});

export const activeUsersForMaybeSpaceSelector = selectorFamily({
  key: 'ActiveUsersForMaybeSpace',
  get:
    ({ organizationId, spaceId }: { organizationId: string; spaceId?: string | null }) =>
    ({ get }) => {
      if (spaceId) {
        return get(activeUsersForSpaceSelector(spaceId));
      }
      return get(activeUsersForOrganizationSelector({ organizationId }));
    },
});

export const allUsersForSpaceSelector = selectorFamily({
  key: 'AllUsersForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const space = get(syncEngineState(spaceId)) as Space;
      const members = get(usersWithMemberInfoForOrganizationSelector(space.organizationId));
      return sortUsers(
        members.filter(({ member, user }) => {
          if (space.private || member.role === MemberRole.Guest) {
            return space.members.includes(user.id);
          }
          return true;
        }),
        space
      );
    },
});

export const allUsersForMaybeSpaceSelector = selectorFamily({
  key: 'AllUsersForMaybeSpace',
  get:
    ({ organizationId, spaceId }: { organizationId: string; spaceId?: string | null }) =>
    ({ get }) => {
      if (spaceId) {
        return get(allUsersForSpaceSelector(spaceId));
      }
      return get(allUsersForOrganizationSelector({ organizationId }));
    },
});

export const spaceNamesSelector = selectorFamily({
  key: 'SpaceNames',
  get:
    (spaceIds: string[]) =>
    ({ get }) => {
      const spaces = filterNotNull(spaceIds.map(spaceId => get(spaceSelector(spaceId))));
      return spaces.map(space => space.name);
    },
});

export const spacesForRoadmapSelector = selectorFamily({
  key: 'SpacesForRoadmapSelector',
  get:
    (roadmapId: string | null) =>
    ({ get }) => {
      if (!roadmapId) {
        return [];
      }
      const spaceRoadmapIds = get(indexKeyState(indexKey(spaceRoadmapsByRoadmap, roadmapId)));
      const spaceRoadmaps = filterNotDeletedNotNull(
        spaceRoadmapIds.map(
          spaceRoadmapId => get(syncEngineState(spaceRoadmapId)) as SpaceRoadmap | null
        )
      );
      const spaces = filterNotNull(spaceRoadmaps.map(sr => get(spaceSelector(sr.spaceId))));
      return spaces;
    },
});
export const spaceIdsForRoadmapSelector = selectorFamily({
  key: 'SpaceIdsForRoadmapSelector',
  get:
    (roadmapId: string | null) =>
    ({ get }) => {
      if (!roadmapId) {
        return [];
      }
      const spaces = get(spacesForRoadmapSelector(roadmapId));
      return spaces.map(space => space.id);
    },
});

export const spaceIdssWithRoadmapSelector = selectorFamily({
  key: 'SpaceIdsWithRoadmaps',
  get:
    (organizationId: string | null) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }
      const roadmaps = get(roadmapsForOrganizationSelector(organizationId));

      const spaceRoadmapIds = roadmaps.flatMap(r =>
        get(indexKeyState(indexKey(spaceRoadmapsByRoadmap, r.id)))
      );

      const spaceRoadmaps = filterNotDeletedNotNull(
        spaceRoadmapIds.map(
          spaceRoadmapId => get(syncEngineState(spaceRoadmapId)) as SpaceRoadmap | null
        )
      );
      const spaces = filterNotNull(spaceRoadmaps.map(sr => get(spaceSelector(sr.spaceId))));
      return spaces.map(space => space.id);
    },
});

export type SpaceVisibility = 'public' | 'private';
export const computedVisibilityBySpacesSelector: (
  param: string[] | null
) => RecoilValueReadOnly<SpaceVisibility> = selectorFamily({
  key: 'ComputedVisibilityBySpaces',
  get:
    (spaceIds: string[] | null) =>
    ({ get }) => {
      const spaces = get(spacesSelector(spaceIds));
      if (spaces.length === 0) {
        return 'public';
      }

      return spaces.some(s => !s.private) ? 'public' : 'private';
    },
});
