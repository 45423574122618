import { isEqual, last, omit } from 'lodash';
import {
  BaseRange,
  Editor,
  Element,
  Location,
  Node,
  NodeMatch,
  Path,
  Point,
  Range,
  Transforms,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { KitemakerElement, KitemakerNode } from '../../shared/slate/kitemakerNode';
import {
  CustomElement,
  DocumentLike,
  Elements,
  LinkElement,
  ListElement,
  SmartTodoElement,
} from '../../shared/slate/types';
import { safeSelection } from '../../shared/slate/utils';
import { MAX_SMART_INDENT } from '../utils/config';
import { KitemakerEditor } from './kitemakerEditor';
import { HistoryEditor } from './plugins/history';
import { EditorType, VoidElement } from './types';

export const CUSTOM_PROPERTIES = [
  'indent',
  'language',
  'checked',
  'url',
  'size',
  'name',
  'thumbnailId',
  'insightId',
  'katex',
  'todoId',
];

const clearCustomProperties = CUSTOM_PROPERTIES.reduce((result, prop) => {
  result[prop] = null;
  return result;
}, {} as Record<string, unknown>);

export const KitemakerTransforms = {
  ...Transforms,
  splitNodes<T extends Node>(
    editor: Editor,
    options?: {
      at?: Location;
      match?: NodeMatch<T>;
      mode?: 'highest' | 'lowest';
      always?: boolean;
      height?: number;
      voids?: boolean;
    }
  ): void {
    Transforms.splitNodes(editor, options);
    for (const mark in Editor.marks(editor)) {
      Editor.removeMark(editor, mark);
    }
  },
  setBlockElement(
    editor: Editor,
    element: Elements,
    options?: { properties?: Partial<CustomElement>; at?: Location }
  ) {
    const { properties, ...otherOptions } = options ?? {};

    if (!safeSelection(editor) && !otherOptions?.at) {
      return;
    }

    if (
      [Elements.Numbered, Elements.Bulleted, Elements.Todo, Elements.SmartTodo].includes(element)
    ) {
      KitemakerEditor.withoutNormalizing(editor, () => {
        this.setNodes<ListElement>(
          editor,
          { ...properties, type: element, indent: 0 } as ListElement,
          otherOptions
        );
      });

      return;
    }

    // make sure we don't have an properties lingering when we change types
    this.setNodes<Element>(
      editor,
      { ...clearCustomProperties, ...properties, type: element } as Element,
      otherOptions
    );
  },
  changeTextBlockType(editor: Editor, element: Elements, options?: { at?: Location }) {
    const { at } = options ?? {};
    if (!safeSelection(editor) && !at) {
      return;
    }

    const preserveIndent =
      KitemakerElement.isListBlock(element) || KitemakerElement.isSmartTodo(element);
    HistoryEditor.asBatch(editor, () => {
      KitemakerEditor.withoutNormalizing(editor, () => {
        const nodes = Editor.nodes(editor, {
          at,
          match: n => KitemakerElement.isElement(n) && KitemakerElement.isTextBlock(n),
        });
        for (const [node, path] of nodes) {
          if (KitemakerElement.isElement(node) && node.type === element) {
            continue;
          }
          let indent = (node as { indent?: number }).indent ?? 0;
          if (KitemakerElement.isSmartTodo(element)) {
            indent = Math.min(indent, MAX_SMART_INDENT);
          }
          this.setNodes<Element>(
            editor,
            {
              ...clearCustomProperties,
              indent: preserveIndent ? indent : undefined,
              type: element,
            } as Element,
            { at: path }
          );
        }
      });
    });
  },
  setBlockElementAndSplitIfNeeded(
    editor: Editor,
    element: Elements,
    options?: { at?: BaseRange; properties?: Partial<CustomElement> }
  ) {
    const range = options?.at ?? safeSelection(editor);
    if (!range) {
      return;
    }
    KitemakerEditor.withoutNormalizing(editor, () => {
      const [start, end] = KitemakerEditor.edges(editor, range.anchor.path.slice(0, -1));
      const isAtStart = Point.equals(start, range.anchor);
      const isAtEnd = Point.equals(end, range.focus);

      KitemakerTransforms.select(editor, range);
      if (!isAtEnd) {
        KitemakerTransforms.splitNodes(editor, { at: range.focus });
      }
      if (!isAtStart) {
        KitemakerTransforms.splitNodes(editor, { at: range.anchor });
      }
      KitemakerTransforms.delete(editor);
      KitemakerTransforms.setBlockElement(editor, element, {
        properties: options?.properties,
      });
    });
  },

  firstAvailableElementOfType(
    editor: Editor,
    element: Elements,
    elementProperties?: Partial<CustomElement>
  ): Path {
    const elements = Array.from(
      Editor.nodes(editor, {
        match: n => KitemakerElement.isElement(n),
      })
    );

    if (!elements.length) {
      KitemakerTransforms.insertNodes(
        editor,
        { ...elementProperties, type: element, children: [{ text: '' }] } as any,
        {
          select: true,
        }
      );

      const newPath = safeSelection(editor)?.focus.path;
      const [, resultPath] = Editor.above(editor, { at: newPath })!;
      return resultPath;
    }

    const [[node, path]] = elements;

    // if it's an empty paragraph, we can just steal it
    if (
      KitemakerElement.isElement(node) &&
      node.type === Elements.Paragraph &&
      node.children.length === 1 &&
      KitemakerNode.safeString(node) === ''
    ) {
      this.setBlockElement(editor, element, { at: path, properties: elementProperties });
      return path;
    }

    const [, end] = Editor.edges(editor, path);
    KitemakerTransforms.insertNodes(
      editor,
      { ...elementProperties, type: element, children: [{ text: '' }] } as any,
      {
        at: end,
        select: true,
      }
    );

    const newPath = safeSelection(editor)?.focus.path;
    const [, resultPath] = Editor.above(editor, { at: newPath })!;
    return resultPath;
  },
  expandSelectionToInclude(editor: EditorType, path: Path) {
    const selection = safeSelection(editor);
    if (!selection) {
      return;
    }

    const startOfSelection = Range.start(selection);
    const endOfSelection = Range.end(selection);
    const startOfElement = Editor.start(editor, path);
    const endOfElement = Editor.end(editor, path);

    if (Path.isBefore(endOfElement.path, startOfSelection.path)) {
      this.select(editor, {
        anchor: startOfElement,
        focus: endOfSelection,
      });
    } else {
      this.select(editor, {
        anchor: startOfSelection,
        focus: endOfElement,
      });
    }
  },
  insertMention(editor: Editor, node: Element, range: Range, autocomplete?: boolean) {
    HistoryEditor.asBatch(editor, () => {
      let atEndOfLine = false;
      const selection = safeSelection(editor);
      if (selection?.focus) {
        const [, end] = Editor.edges(editor, selection.focus.path);
        if (Point.equals(end, selection.focus)) {
          atEndOfLine = true;
        }
      }

      KitemakerTransforms.insertNodes(
        editor,
        [
          node,
          {
            text: '',
          },
        ],
        {
          at: range,
          mode: 'lowest',
          select: autocomplete,
        }
      );
      if (!atEndOfLine) {
        KitemakerTransforms.move(editor, { distance: 1, unit: 'offset' });
      }
      if (autocomplete) {
        KitemakerTransforms.insertText(editor, ' ');
      }
    });
  },
  addLink(editor: Editor, url: string, fromHover?: boolean) {
    Editor.withoutNormalizing(editor, () => {
      const selection = safeSelection(editor);
      if (!selection) {
        return;
      }

      const selectionRef = Editor.rangeRef(editor, selection);
      const links = Array.from(
        Editor.nodes(editor, {
          match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
        })
      );
      if (links.length) {
        // Slate has a terrible bug. Namely that passing split: true doesn't really work when unwrapping
        // nodes. So we manually go through the existing links here, unwrap the entire thing, and then
        // rewrap the part that shouldn't have been unwrapped in the first place.
        for (const [node, path] of links) {
          const anchor = Editor.start(editor, path);
          const focus = Editor.end(editor, path);

          const intersection = Range.intersection(selection, { anchor, focus });
          if (!intersection) {
            continue;
          }

          const existingUrl = (node as LinkElement).url ?? KitemakerNode.safeString(node);
          const existingFromHover = (node as LinkElement).fromHover;
          const rangeBefore = { anchor, focus: intersection.anchor };
          const rangeBeforeRef = Editor.rangeRef(editor, rangeBefore);

          const rangeAfter = { anchor: intersection.focus, focus };
          const rangeAfterRef = Editor.rangeRef(editor, rangeAfter);

          KitemakerTransforms.unwrapNodes(editor, {
            at: path,
            match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
          });

          if (rangeBeforeRef.current && !Point.equals(rangeBefore.anchor, rangeBefore.focus)) {
            KitemakerTransforms.wrapNodes(
              editor,
              { type: Elements.Link, url: existingUrl, fromHover: existingFromHover, children: [] },
              { at: rangeBeforeRef.current, split: true }
            );
          }

          if (rangeAfterRef.current && !Point.equals(rangeAfter.anchor, rangeAfter.focus)) {
            KitemakerTransforms.wrapNodes(
              editor,
              { type: Elements.Link, url: existingUrl, fromHover: existingFromHover, children: [] },
              { at: rangeAfterRef.current, split: true }
            );
          }
        }
      }
      if (selectionRef.current && url) {
        KitemakerTransforms.wrapNodes(
          editor,
          { type: Elements.Link, url, fromHover, children: [] },
          { at: selectionRef.current, split: true }
        );
      }
      KitemakerTransforms.collapse(editor, { edge: 'end' });
    });
  },
  removeInsight(editor: Editor, insightId: string) {
    const [, startPath] = Editor.first(editor, []);
    const [, endPath] = Editor.last(editor, []);

    const anchor = Editor.start(editor, startPath);
    const focus = Editor.end(editor, endPath);
    const range = { anchor, focus };

    const nodes = Editor.nodes(editor, {
      match: n =>
        KitemakerElement.isElement(n) &&
        KitemakerElement.isVoid(n) &&
        !KitemakerElement.isInlineVoid(n) &&
        (n as VoidElement).insightId === insightId,
      at: range,
      mode: 'all',
    });

    for (const [, path] of Array.from(nodes)) {
      KitemakerTransforms.setNodes(editor, { insightId: null } as any, { at: path });
    }

    KitemakerTransforms.unwrapNodes(editor, {
      at: range,
      mode: 'lowest',
      split: true,
      match: n =>
        KitemakerElement.isElement(n) && n.type === Elements.Insight && n.insightId === insightId,
    });
  },
  addInsight(editor: Editor, insightId: string) {
    Editor.withoutNormalizing(editor, () => {
      const maybeSelection = safeSelection(editor);
      if (!maybeSelection) {
        return;
      }

      const selection = Editor.unhangRange(editor, maybeSelection);
      const selectionRef = Editor.rangeRef(editor, selection);
      const insights = Array.from(
        Editor.nodes(editor, {
          match: n => KitemakerElement.isElement(n) && n.type === Elements.Insight,
        })
      );
      if (insights.length) {
        // Slate has a terrible bug. Namely that passing split: true doesn't really work when unwrapping
        // nodes. So we manually go through the existing links here, unwrap the entire thing, and then
        // rewrap the part that shouldn't have been unwrapped in the first place.
        for (const [, path] of insights) {
          const anchor = Editor.start(editor, path);
          const focus = Editor.end(editor, path);

          const intersection = Range.intersection(selection, { anchor, focus });
          if (!intersection) {
            continue;
          }

          const rangeBefore = { anchor, focus: intersection.anchor };
          const rangeBeforeRef = Editor.rangeRef(editor, rangeBefore);

          const rangeAfter = { anchor: intersection.focus, focus };
          const rangeAfterRef = Editor.rangeRef(editor, rangeAfter);

          KitemakerTransforms.unwrapNodes(editor, {
            at: path,
            match: n => KitemakerElement.isElement(n) && n.type === Elements.Insight,
          });

          if (rangeBeforeRef.current && !Point.equals(rangeBefore.anchor, rangeBefore.focus)) {
            KitemakerTransforms.wrapNodes(
              editor,
              { type: Elements.Insight, insightId, children: [] },
              { at: rangeBeforeRef.current, split: true }
            );
          }

          if (rangeAfterRef.current && !Point.equals(rangeAfter.anchor, rangeAfter.focus)) {
            KitemakerTransforms.wrapNodes(
              editor,
              { type: Elements.Insight, insightId, children: [] },
              { at: rangeAfterRef.current, split: true }
            );
          }
        }
      }
      if (selectionRef.current) {
        KitemakerTransforms.wrapNodes(
          editor,
          { type: Elements.Insight, insightId, children: [] },
          { at: selectionRef.current, split: true }
        );

        const nodes = Editor.nodes(editor, {
          at: selectionRef.current,
          match: n => KitemakerElement.isElement(n) && KitemakerElement.isVoid(n),
        });
        for (const [node, path] of nodes) {
          this.setNodes<Element>(
            editor,
            {
              ...node,
              insightId,
            } as VoidElement,
            { at: path }
          );
        }
      }

      KitemakerTransforms.collapse(editor, { edge: 'end' });
    });
  },
  addComemnt: (editor: Editor, commentId: string) => {
    const selection = safeSelection(editor);
    if (!selection) {
      return;
    }

    Editor.withoutNormalizing(editor, () => {
      KitemakerTransforms.wrapNodes(
        editor,
        {
          type: Elements.Comment,
          commentId,
          entityId: editor.entityId!,
          children: [],
        },
        { at: selection, split: true }
      );
      const nodes = Editor.nodes(editor, {
        at: selection,
        match: n => KitemakerElement.isElement(n) && KitemakerElement.isVoid(n),
      });
      for (const [node, path] of nodes) {
        KitemakerTransforms.setNodes<Element>(
          editor,
          {
            ...node,
            annotations: [
              ...((node as VoidElement).annotations ?? []),
              { type: 'comment', id: commentId },
            ],
          } as VoidElement,
          { at: path }
        );
      }
      KitemakerTransforms.collapse(editor, { edge: 'end' });
    });
  },
  removeComment(editor: Editor, commentId: string) {
    const [, startPath] = Editor.first(editor, []);
    const [, endPath] = Editor.last(editor, []);

    const anchor = Editor.start(editor, startPath);
    const focus = Editor.end(editor, endPath);
    const range = { anchor, focus };

    const nodes = Editor.nodes(editor, {
      match: n =>
        KitemakerElement.isElement(n) &&
        KitemakerElement.isVoid(n) &&
        !KitemakerElement.isInlineVoid(n) &&
        !!(n as VoidElement).annotations?.find(
          annotation => annotation.type === 'comment' && annotation.id === commentId
        ),
      at: range,
      mode: 'all',
    });

    for (const [node, path] of Array.from(nodes)) {
      const voidNode = node as VoidElement;
      const annotations = voidNode.annotations?.filter(
        annotation => annotation.type !== 'comment' || annotation.id !== commentId
      );
      KitemakerTransforms.setNodes(editor, { annotations } as any, { at: path });
    }

    KitemakerTransforms.unwrapNodes(editor, {
      at: range,
      mode: 'lowest',
      split: true,
      match: n =>
        KitemakerElement.isElement(n) && n.type === Elements.Comment && n.commentId === commentId,
    });
  },
  intelligentlyInsertFragment(editor: EditorType, fragment: DocumentLike, atRange?: Range) {
    const selection = safeSelection(editor);
    if (!fragment.length || !selection) {
      return;
    }

    const range = atRange ?? selection;
    if (!range) {
      return;
    }

    HistoryEditor.asBatch(editor, () => {
      KitemakerTransforms.select(editor, range);

      if (!Range.isCollapsed(range)) {
        Transforms.delete(editor);
      }

      const [[maybeVoidElement]] = Array.from(
        Editor.nodes(editor, {
          match: n => KitemakerElement.isElement(n),
        })
      );

      if (!maybeVoidElement) {
        return;
      }

      // are we in a void node? If so, let's give ourselves a fresh paragraph to work with
      if (KitemakerElement.isVoid(maybeVoidElement as Element)) {
        this.insertNodes(editor, [{ type: Elements.Paragraph, children: [{ text: '' }] }]);
      }

      const [[currentNode]] = Array.from(
        Editor.nodes(editor, {
          match: n => KitemakerElement.isElement(n),
        })
      );

      if (!currentNode) {
        return;
      }

      const currentElement = currentNode as Element;
      const currentElementEmpty = isEqual(currentElement.children, [{ text: '' }]);
      const preserveType = KitemakerElement.isTypePreservingBlock(currentElement);
      const fragmentStartsWithText =
        KitemakerElement.isElement(fragment[0]) && KitemakerElement.isTextBlock(fragment[0]);
      const fragmentIsSingleTextElement = fragment.length === 1 && fragmentStartsWithText;
      const fragmentStartsWithVoid =
        KitemakerElement.isElement(fragment[0]) && KitemakerElement.isVoid(fragment[0]);

      // if we just have a single text element, that's easy to handle
      if (fragmentIsSingleTextElement) {
        // figure out whether to take stuff from the current node or the thing we're inserting
        if (preserveType) {
          Object.assign(fragment[0], omit(currentElement, 'children'));
        }
        KitemakerTransforms.insertFragment(editor, fragment);
        return;
      }

      // if the thing we're inserting starts with a void, we need to decide to either blow away the current
      // text block (if it's empty) or split it
      if (fragmentStartsWithVoid) {
        if (!currentElementEmpty) {
          KitemakerTransforms.splitNodes(editor);
        }

        KitemakerTransforms.insertFragment(editor, fragment);
        return;
      }

      // if we're inserting text into text, we need to decide whether to keep the formatting from the thing
      // being inserted or the thing being inserted into, and if we need to split or not
      const [firstFragmenNode, ...restOfFragment] = fragment;
      if (preserveType) {
        Object.assign(firstFragmenNode, omit(currentElement, 'children'));
      }
      KitemakerTransforms.insertFragment(editor, [firstFragmenNode]);

      // split the element, ensure the first element gets the right type, then just let the fragment rip
      if (restOfFragment.length) {
        if (preserveType) {
          for (const node of restOfFragment) {
            if (KitemakerElement.isElement(node) && KitemakerElement.isTextBlock(node)) {
              Object.assign(node, omit(currentElement, 'children'));
            }
          }
        }

        KitemakerTransforms.splitNodes(editor, { always: true });
        KitemakerTransforms.insertFragment(editor, restOfFragment);
      }
    });
  },
  selectAll(editor: EditorType) {
    const [, startPath] = Editor.first(editor, []);
    const [, endPath] = Editor.last(editor, []);

    const anchor = Editor.start(editor, startPath);
    const focus = Editor.end(editor, endPath);
    Transforms.select(editor, { anchor, focus });
  },
  moveSelectionToStart(editor: EditorType) {
    const [, path] = Editor.first(editor, []);
    const anchor = Editor.start(editor, path);
    Transforms.select(editor, { anchor, focus: anchor });
  },
  moveSelectionToEnd(editor: EditorType) {
    const [, path] = Editor.last(editor, []);
    const anchor = Editor.end(editor, path);
    Transforms.select(editor, { anchor, focus: anchor });
  },
  moveSelectionToPath(editor: EditorType, path: Path) {
    if (!Editor.hasPath(editor, path)) {
      this.moveSelectionToEnd(editor);
      return;
    }
    const anchor = Editor.start(editor, path);
    Transforms.select(editor, { anchor, focus: anchor });
  },
  clear(editor: EditorType) {
    this.selectAll(editor);
    Transforms.insertText(editor, ' ');
    for (const mark in Editor.marks(editor)) {
      Editor.removeMark(editor, mark);
    }
    this.selectAll(editor);
    Transforms.delete(editor);
    KitemakerTransforms.setBlockElement(editor, Elements.Paragraph);
  },
  ensureBlankLastLine(editor: EditorType) {
    if (editor.children.length) {
      const lastChild = last(editor.children);
      if (
        KitemakerElement.isElement(lastChild) &&
        lastChild.type === Elements.Paragraph &&
        isEqual(lastChild.children, [{ text: '' }])
      ) {
        return;
      }
    }

    KitemakerTransforms.insertNodes(
      editor,
      [{ type: Elements.Paragraph, children: [{ text: '' }] }],
      { at: [editor.children.length] }
    );
  },
  selectSmartTodo(editor: EditorType, element: SmartTodoElement) {
    const path = ReactEditor.findPath(editor, element);
    const [anchor, focus] = Editor.edges(editor, path);
    Transforms.select(editor, { anchor, focus });
  },
  selectElement(editor: EditorType, element: Element) {
    ReactEditor.focus(editor);
    const path = ReactEditor.findPath(editor, element);
    const start = Editor.start(editor, path);
    const end = Editor.end(editor, path);
    KitemakerTransforms.select(editor, { anchor: start, focus: end });
  },
};
