import { first, last, uniq } from 'lodash';
import * as React from 'react';
import { filterNotNull, notNull } from '../../../../shared/utils/convenience';
import { CommandGroup } from '../../../commands';
import {
  alternateComboKey,
  createNewCardKey,
  mainComboKey,
  vimDown,
  vimUp,
} from '../../../utils/config';
import { CopyAndPasteHotkeys } from '../copyAndPaste';
import { CustomCommand } from '../customCommand';
import { Hotkey } from '../hotkey';
import { useClearSelection, useKeyNavigationState } from '../keyNavigation';

export interface CopyAndPasteProperties {
  onCopy?: (ids: string[]) => Array<{ id: string; name: string; url: string }>;
  onPaste?: (columnId: string, index: number, pastedIds: string[]) => void;
}

export function Hotkeys({
  columnsRef,
  commandGroup,
  getAllColumns,
  onNewCard,
  onMoveCards,
  onCopy,
  onPaste,
}: {
  columnsRef: React.RefObject<string[]>;
  commandGroup?: CommandGroup;
  getAllColumns: () => {
    [k: string]: string[];
  };
  onNewCard?: (newCardPosition: { columnId: string; index: number }) => void;
  onMoveCards?: (ids: string[], toColumn: string, toIndex: number) => void;
} & CopyAndPasteProperties) {
  const { focused, focusedColumn, selected } = useKeyNavigationState();
  const clearSelection = useClearSelection();

  const create = React.useCallback(
    (after: boolean) => {
      const cards = getAllColumns();

      if (!focused || !focusedColumn || !cards) {
        return;
      }

      const index = cards[focusedColumn]?.indexOf(focused) ?? 0;
      const colLength = cards[focusedColumn]?.length ?? 0;

      clearSelection();
      onNewCard?.({
        columnId: focusedColumn,
        index: Math.min(after ? index + 1 : index, colLength),
      });
    },
    [getAllColumns, focused, focusedColumn, clearSelection, onNewCard]
  );

  const move = React.useCallback(
    (direction: 'up' | 'down' | 'left' | 'right', moveToTopOrBottom = false) => {
      const cards = getAllColumns();
      if ((!selected && !focused) || !columnsRef.current || !cards) {
        return;
      }
      const cardIds = filterNotNull(selected ?? [focused]);
      if (!cardIds.length) {
        return;
      }

      let columnId = focusedColumn;

      if (cardIds.length > 1) {
        // build a reverse lookup of cardIds to columnId. Pretty slow and gross so
        // we only do it when multiselcted
        const columnIdByCardId: Record<string, string> = {};
        for (const columnId of columnsRef.current) {
          for (const cardId of cards[columnId] ?? []) {
            columnIdByCardId[cardId] = columnId;
          }
        }

        const columnIds = uniq(cardIds.map(cardId => columnIdByCardId[cardId]));
        // if the selected things aren't in the same column, just give up
        if (columnIds.length !== 1) {
          return;
        }

        columnId = columnIds[0];
      }

      if (!columnId || !cards[columnId]) {
        return;
      }

      const columnIndex = columnsRef.current.indexOf(columnId);
      const isFirstColumn = columnIndex === 0;
      const isLastColumn = columnIndex === columnsRef.current.length - 1;
      const rowIndexes = cardIds.map(cardId => cards![notNull(columnId)].indexOf(cardId));
      const firstRowIndex = first(rowIndexes) ?? -1;
      const lastRowIndex = last(rowIndexes) ?? -1;

      if (columnIndex === -1 || firstRowIndex === -1 || lastRowIndex === -1) {
        return;
      }

      if (direction === 'up') {
        onMoveCards?.(cardIds, columnId, Math.max(moveToTopOrBottom ? 0 : firstRowIndex - 1, 0));
      } else if (direction === 'down') {
        onMoveCards?.(
          cardIds,
          columnId,
          Math.min(
            moveToTopOrBottom ? cards[columnId].length - 1 : lastRowIndex + 1,
            cards[columnId].length - 1
          )
        );
      } else if (direction === 'left' && !isFirstColumn) {
        const newColumnId = columnsRef.current[columnIndex - 1];
        onMoveCards?.(
          cardIds,
          newColumnId,
          Math.min(firstRowIndex, cards[newColumnId]?.length - 1 ?? 0)
        );
      } else if (direction === 'right' && !isLastColumn) {
        const newColumnId = columnsRef.current[columnIndex + 1];
        onMoveCards?.(
          cardIds,
          newColumnId,
          Math.min(firstRowIndex, cards[newColumnId]?.length - 1 ?? 0)
        );
      }
    },
    [getAllColumns, selected, focused, columnsRef, focusedColumn, onMoveCards]
  );

  const paste = React.useCallback(
    (ids: string[]) => {
      const cards = getAllColumns();
      if (!focused || !focusedColumn || !cards || !columnsRef.current?.includes(focusedColumn)) {
        return;
      }

      // headers, placeholders
      if (focused.includes('-')) {
        onPaste?.(focusedColumn, 0, ids);
        return;
      }

      const index = cards[focusedColumn]?.indexOf(focused) ?? -1;
      if (index !== -1) {
        onPaste?.(focusedColumn, index, ids);
      }
    },
    [getAllColumns, focused, focusedColumn, columnsRef, onPaste]
  );

  return (
    <>
      {onNewCard && (
        <>
          <CustomCommand
            command={{
              id: 'create-new-card',
              hotkey: createNewCardKey,
              group: CommandGroup.Board,
              description: `Create new`,
              priority: 9,
              handler: () => {
                create(false);
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'create-new-card-below',
              hotkey: `shift+${createNewCardKey}`,
              group: CommandGroup.Board,
              description: `Create new below`,
              priority: 9,
              handler: () => {
                create(true);
              },
            }}
          />
        </>
      )}
      {onMoveCards && (
        <>
          <Hotkey
            hotkey={`${alternateComboKey}+${vimDown}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('down');
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+${alternateComboKey}+${vimDown}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('down', true);
            }}
          />
          <Hotkey
            hotkey={`${alternateComboKey}+${vimUp}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('up');
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+${alternateComboKey}+${vimUp}`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('up', true);
            }}
          />
          <Hotkey
            hotkey={`${alternateComboKey}+h`}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              move('left');
            }}
          />
          <Hotkey
            hotkey={`${alternateComboKey}+l`}
            handler={e => {
              /* FIXME: Gross hack because option+l is @ on german keyboards. Works for Sigurd locallz when he changes kezboard to German */
              if (e?.key !== '@') {
                e?.preventDefault();
                e?.stopPropagation();
                move('right');
              }
            }}
          />
          {['up', 'down', 'left', 'right'].map(direction => (
            <CustomCommand
              key={direction}
              command={{
                id: `move-${direction}`,
                group: commandGroup ?? CommandGroup.Other,
                description: `Move ${direction}`,
                hotkey: `${alternateComboKey}+${direction}`,
                handler: () => {
                  move(direction as 'up' | 'down' | 'left' | 'right');
                },
              }}
            />
          ))}
          <CustomCommand
            command={{
              id: 'move-top',
              group: commandGroup ?? CommandGroup.Other,
              description: 'Move to top',
              hotkey: `${mainComboKey}+${alternateComboKey}+up`,
              handler: () => {
                move('up', true);
              },
            }}
          />
          <CustomCommand
            command={{
              id: 'move-bottom',
              group: commandGroup ?? CommandGroup.Other,
              description: 'Move to bottom',
              hotkey: `${mainComboKey}+${alternateComboKey}+down`,
              handler: () => {
                move('down', true);
              },
            }}
          />
        </>
      )}
      {onCopy && onPaste && <CopyAndPasteHotkeys onCopy={onCopy} onPaste={paste} />}
    </>
  );
}
