import { customColorOpacities, legacyColorMap, paletteColors } from '../../shared/utils/colors';

const LIGHTMODE_THRESHOLD = 0.3;
const DARKMODE_THRESHOLD = 1 - LIGHTMODE_THRESHOLD;
const colorLookup = new Map();

export function hexToRgb(hex: string): [number, number, number] | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : null;
}

function getPerceivedLightness(rgb: [number, number, number]): number {
  const [r, g, b] = rgb;
  [];
  const perceivedLightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return perceivedLightness / 255; // Normalize to the range [0, 1]
}

function rgbToHsl(rgb: [number, number, number]): [number, number, number] {
  const [r, g, b] = rgb.map(val => val / 255);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const l = (max + min) / 2;

  let h = 0;
  let s = 0;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
}

function hslToRgb(hsl: [number, number, number]): [number, number, number] {
  const [h, s, l] = hsl;

  if (s === 0) {
    const value = Math.round(l * 255);
    return [value, value, value];
  }

  const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  const p = 2 * l - q;

  const r = Math.round(hueToRgb(p, q, h + 1 / 3) * 255);
  const g = Math.round(hueToRgb(p, q, h) * 255);
  const b = Math.round(hueToRgb(p, q, h - 1 / 3) * 255);

  return [r, g, b];
}

function getAdjustedCustomColor(
  color: string,
  opacityLevel: number,
  darkMode: boolean,
  skipAdjustment: boolean,
  transparent: boolean
): string {
  const key = JSON.stringify({ color, opacityLevel, darkMode, skipAdjustment });
  const adjustedColor = colorLookup.get(key);

  if (adjustedColor) {
    return adjustedColor;
  }
  const rgb = hexToRgb(color);
  if (!rgb) {
    const calculatedColor = `var(--gray${transparent ? 'A' : ''}${opacityLevel})`;
    colorLookup.set({ color, opacityLevel, darkMode }, calculatedColor);
    return calculatedColor;
  }

  let calculatedColor = rgb;

  if (!skipAdjustment) {
    const precievedBrightness = getPerceivedLightness(rgb);
    if (
      (darkMode && precievedBrightness < DARKMODE_THRESHOLD) ||
      (!darkMode && precievedBrightness > LIGHTMODE_THRESHOLD)
    ) {
      const hsl = rgbToHsl(rgb);
      hsl[2] = darkMode ? DARKMODE_THRESHOLD : LIGHTMODE_THRESHOLD;
      calculatedColor = hslToRgb(hsl);
    }
  }

  const calculatedColorString = `rgba(${calculatedColor.join(',')}, ${
    transparent ? customColorOpacities[opacityLevel] ?? 0.92 : 1
  })`;

  colorLookup.set(key, calculatedColorString);
  return calculatedColorString;
}

const DEFAULT_BACKGROUND = 'var(--background-color-avatar)';

export function hashStringToPaletteColors(input?: string | null): string {
  if (!input) {
    return DEFAULT_BACKGROUND;
  }

  // This is a super cheap "hash". Basically take the last letter of the string,
  // convert it to a number and look it up in the palette colors. We use base36
  // to handle a-zA-Z0-9. If the string ends in something else, it'll get the first
  // color. No biggie because we're not looking for extreme randomness.
  const value = parseInt(input[input.length - 1].toLowerCase(), 36) || 0;
  return paletteColors[value % paletteColors.length];
}

export function colorWithOpacity(
  baseColor: string,
  opacityLevel: number,
  darkMode: boolean,
  skipAdjustment = false,
  alpha = true
): string {
  const color = legacyColorMap[baseColor] ?? baseColor;
  if (paletteColors.includes(color)) {
    return `var(--${color}${alpha ? 'A' : ''}${opacityLevel})`;
  }
  return getAdjustedCustomColor(color, opacityLevel, darkMode, skipAdjustment, alpha);
}
