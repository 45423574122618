export interface SyncEngineObject {
  id: string;
  __typename: string;
  version: number;
  deleted: boolean;
  createdAt: number;
  updatedAt: number;
}

export interface SyncEngineIndex {
  name: string;
  type: string;
  property: string;
  sortedBy?: string;
  sortReversed?: boolean;
}

export interface SyncEngineCollection {
  name: string;
  type: string;
  property: string;
}

// objects and index values keyed by id
export type SyncEngineIndexValue = { value: string; sortKey?: string | number };
export type SyncEngineStateUpdate = Record<string, SyncEngineValue>;
export type SyncEngineValue = SyncEngineObject | SyncEngineIndexValue[];

export interface SyncEngineIndexMap {
  byTypeAndProperty: Record<string, Record<string, SyncEngineIndex>>;
  byTypeAndSort: Record<string, Record<string, SyncEngineIndex[]>>;
}

export type SyncEngineCollectionMap = Record<string, Record<string, SyncEngineCollection>>;

export function isSyncEngineObject(
  obj: SyncEngineObject | SyncEngineIndexValue[]
): obj is SyncEngineObject {
  return (obj as SyncEngineObject).__typename !== undefined;
}
