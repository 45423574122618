import { fileUploader, uploadFilesFromTransfer } from '../../utils/fileUploader';
import { EditorType } from '../types';

export function withLegacyFileDragAndDrop(editor: EditorType, attachmentUploadPath: string) {
  const { insertData } = editor;
  editor.insertData = data => {
    if (data.files.length) {
      editor.forceFocus();
      const uploader = fileUploader(attachmentUploadPath, { multi: true });
      uploadFilesFromTransfer(editor, uploader, Array.from(data.files), attachmentUploadPath);
      return;
    }

    insertData(data);
  };
  editor.attachmentUploadPath = attachmentUploadPath;

  return editor;
}
