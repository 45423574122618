import { isMac, isSafari } from './config';

const SCROLLABLE = ['auto', 'scroll', 'overlay'];

export function findScrollParent(
  element: HTMLElement,
  options?: { match?: (element: HTMLElement) => boolean; horizontal?: boolean }
): HTMLElement | null {
  let parent: HTMLElement | null = element;
  while (parent) {
    const style = getComputedStyle(parent);
    const isScrollignStyle = options?.horizontal
      ? SCROLLABLE.includes(style.overflowX)
      : SCROLLABLE.includes(style.overflowY);
    if ((!options?.match || options?.match(parent)) && isScrollignStyle) {
      return parent;
    }
    parent = parent.parentElement;
  }

  return null;
}

export function findFocusParent(element: HTMLElement): HTMLElement | null {
  let parent: HTMLElement | null = element;
  while (parent) {
    if (parent.hasAttribute('tabIndex')) {
      return parent;
    }
    parent = parent.parentElement;
  }

  return null;
}

export function isDescendant(maybeAncestor: Element, maybeDescendant: Element): boolean {
  let current: Element | null = maybeDescendant;
  while (current) {
    if (current === maybeAncestor) {
      return true;
    }
    current = current.parentElement;
  }
  return false;
}

export interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

export function calculateBoundingRect(rects: DOMRect[]): Rect {
  if (rects.length === 0) {
    return { top: -1, left: -1, width: -1, height: -1 };
  }

  const result = {
    left: rects[0].left,
    top: rects[0].top,
    width: rects[0].width,
    height: rects[0].height,
  };
  for (const rect of rects.slice(1)) {
    if (rect.left < result.left) {
      result.left = rect.left;
    }
    if (rect.top < result.top) {
      result.top = rect.top;
    }
    if (result.left + result.width < rect.left + rect.width) {
      result.width = rect.left + rect.width - result.left;
    }
    if (result.top + result.height < rect.top + rect.height) {
      result.height = rect.top + rect.height - result.top;
    }
  }
  return result;
}

export function translateFix() {
  // https://github.com/facebook/react/issues/11538#issuecomment-417504600
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function <T extends Node>(child: T): T {
      if (child.parentNode !== this) {
        if (console) {
          // eslint-disable-next-line no-console
          console.warn('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments as any) as T;
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function <T extends Node>(
      newNode: T,
      referenceNode: Node | null
    ): T {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          // eslint-disable-next-line no-console
          console.warn(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this
          );
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments as any) as T;
    };
  }
}

export function isSimpleTextDocument(doc: Document) {
  const childNodes = Array.from(doc.body.childNodes);
  if (
    doc.body.childElementCount === 0 &&
    childNodes.every(n => isText(n) || (isElement(n) && n.tagName.toLowerCase() === 'br'))
  ) {
    return true;
  }

  if (
    childNodes.every(n => {
      if (isText(n)) {
        return true;
      }

      if (!isElement(n) || n.tagName.toLowerCase() !== 'p') {
        return false;
      }

      const nestedChildNodes = Array.from(n.childNodes);
      return nestedChildNodes.every(
        n => isText(n) || (isElement(n) && n.tagName.toLowerCase() === 'br')
      );
    })
  ) {
    if (!isSafari) {
      return true;
    }
    // For some reason, Safari often removes the line breaks from the document when converting it to
    // text, so if there are multiple paragraphs, we're better off leaving it as HTML
    const paragraphCount = Array.from(doc.body.childNodes).filter(
      n => isElement(n) && n.tagName.toLowerCase()
    ).length;
    return paragraphCount <= 1;
  }

  return false;
}

export function isText(node: ChildNode | Node): node is Text {
  return node.nodeType === Node.TEXT_NODE;
}

export function isElement(node: ChildNode | Node): node is Element {
  return node.nodeType === Node.ELEMENT_NODE;
}

export function nativeSelection() {
  const domSelection = window.getSelection();
  if (!domSelection || domSelection.rangeCount < 1) {
    return [];
  }

  const domRange = domSelection.getRangeAt(0);
  const rawClientRects = Array.from(domRange.getClientRects());
  const rectsWithWidth = rawClientRects.filter(r => r.width > 0);
  return rectsWithWidth.length ? rectsWithWidth : rawClientRects;
}

export function checkForAlwaysOnScrollbars() {
  if (!isMac) {
    return false;
  }
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = 'scroll';

  const inner = document.createElement('div');
  inner.style.width = '100%';
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  outer.parentNode?.removeChild(outer);
  return widthNoScroll - widthWithScroll > 0;
}

export function getElementsInRange(range: Range): HTMLElement[] {
  const startNode = range.startContainer.parentElement;
  const endNode = range.endContainer.parentElement;
  const commonAncestorNode = range.commonAncestorContainer;

  if (commonAncestorNode.nodeType !== Node.ELEMENT_NODE || !startNode || !endNode) {
    return [];
  }

  const commonAncestor = commonAncestorNode as HTMLElement;
  const children = [...commonAncestor.children];

  let start: HTMLElement | null = startNode;
  while (start && !children.includes(start)) {
    start = start.parentElement;
  }

  let end: HTMLElement | null = endNode;
  while (end && !children.includes(end)) {
    end = end.parentElement;
  }

  if (!start || !end) {
    return [];
  }

  const result: HTMLElement[] = [];
  let collecting = false;
  for (const child of commonAncestor.children) {
    if (child === start) {
      collecting = true;
    }

    if (collecting) {
      result.push(child as HTMLElement);
    }

    if (child === end) {
      break;
    }
  }

  return result;
}

// These guys are lifted from slate-react
const getDefaultView = (value: any): Window | null => {
  return (value && value.ownerDocument && value.ownerDocument.defaultView) || null;
};
export const isDOMNode = (value: any): value is Node => {
  const window = getDefaultView(value);
  return !!window && value instanceof window.Node;
};
export const isDOMText = (value: any): value is Text => {
  return isDOMNode(value) && value.nodeType === 3;
};
export const isDOMElement = (value: any): value is Element => {
  return isDOMNode(value) && value.nodeType === 1;
};
export const getPlainText = (domNode: Node) => {
  let text = '';

  if (isDOMText(domNode) && domNode.nodeValue) {
    return domNode.nodeValue;
  }

  if (isDOMElement(domNode)) {
    for (const childNode of Array.from(domNode.childNodes)) {
      text += getPlainText(childNode);
    }

    const display = getComputedStyle(domNode).getPropertyValue('display');

    if (display === 'block' || display === 'list' || domNode.tagName === 'BR') {
      text += '\n';
    }
  }

  return text;
};
