import cn from 'classnames';
import gql from 'graphql-tag';
import { capitalize } from 'lodash';
import Slider, { Handle, SliderTooltip } from 'rc-slider';
import * as React from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useQuery } from 'urql';
import { CheckpointsQuery, CheckpointsQueryVariables } from '../../../graphql__generated__/graphql';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { actorNameSelector } from '../syncEngine/selectors/actors';
import { openHistoryKey, vimLeft, vimRight } from '../utils/config';
import { renderDate } from '../utils/datetime';
import { HistoryCheckpoint } from '../utils/revisions';
import styles from './descriptionHistory.module.scss';
import { HotkeyScope } from './hotkeys';
import { ActorAvatar } from './new/actorAvatar';
import { Button, ButtonStyle, IconButton } from './new/button';
import { CustomCommand } from './new/customCommand';
import { Hotkey } from './new/hotkey';
import { KeyboardShortcut } from './new/keyboardShortcut';
import { useDisableKeyNavigation, useEnableKeyNavigation } from './new/keyNavigation';
import { LoadingSpinner } from './new/loadingSpinner';
import { Tooltip } from './new/tooltip';

interface HandleProps {
  className: string;
  prefixCls?: string;
  vertical?: boolean;
  offset: number;
  value: number;
  dragging?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  reverse?: boolean;
  index: number;
  tabIndex?: number;
  ariaLabel: string;
  ariaLabelledBy: string;
  ariaValueTextFormatter?: (value: number) => string;
  style?: React.CSSProperties;
  ref?: React.Ref<any>;
}

type CheckpointResult = CheckpointsQuery['documentCheckpoints']['checkpoints'][0];

export const descriptionHistoryOpenAtom = atom({
  key: 'DescriptionHistoryOpen',
  default: false,
});

function CheckpointsSliderHandle({
  checkpoint,
  onRestore,
  value,
  dragging,
  index,
  ...rest
}: HandleProps & {
  checkpoint: CheckpointResult;
  onRestore: (description: HistoryCheckpoint) => void;
}) {
  const actorId = checkpoint.actorIds[0] ?? '';
  const actorName = useRecoilValue(actorNameSelector(actorId));

  return (
    <SliderTooltip
      showArrow={false}
      prefixCls="rc-slider-tooltip"
      overlay={
        <div className={styles.checkpointTooltip}>
          <div key={actorId} className="mb12">
            <ActorAvatar actorId={actorId} />
            <span className="ml8">{actorName}</span>
          </div>
          <div className="mb12 grayed">
            {capitalize(renderDate(checkpoint.updatedAt, { showTime: true }))}
          </div>
          <Button
            buttonStyle={ButtonStyle.Primary}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onRestore(checkpoint);
            }}
          >
            Restore
          </Button>
        </div>
      }
      visible={true}
      placement="top"
      align={{
        offset: [0, -4],
      }}
      key={index}
      overlayClassName={styles.checkpointTooltipContainer}
      overlayInnerStyle={{
        height: 'unset',
        backgroundColor: 'var(--white)',
        color: 'unset',
        boxShadow: 'none',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 10,
        paddingBottom: 10,
      }}
      zIndex={5}
    >
      <Handle value={value} {...(rest as any)} />
    </SliderTooltip>
  );
}

function CheckpointSlider({
  checkpoints,
  onRestore,
  onDescriptionChanged,
}: {
  checkpoints: CheckpointResult[];
  onRestore: (description: HistoryCheckpoint) => void;
  onDescriptionChanged: (description: HistoryCheckpoint) => void;
}) {
  useComponentDidMount(() => {
    onDescriptionChanged(checkpoints[checkpoints.length - 1]);
  });

  const [value, setValue] = React.useState(checkpoints.length - 1);
  const previousDisabled = value === 0;
  const nextDisabled = value === checkpoints.length - 1;

  React.useEffect(() => {
    onDescriptionChanged(checkpoints[value]);
  }, [value]);

  return (
    <>
      <Hotkey
        hotkey="right"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (value < checkpoints.length - 1) {
            setValue(value + 1);
          }
        }}
      />
      <Hotkey
        hotkey="left"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (value > 0) {
            setValue(value - 1);
          }
        }}
      />
      <Hotkey
        hotkey={vimRight}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (value < checkpoints.length - 1) {
            setValue(value + 1);
          }
        }}
      />
      <Hotkey
        hotkey={vimLeft}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (value > 0) {
            setValue(value - 1);
          }
        }}
      />
      <Hotkey
        hotkey="enter"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onRestore(checkpoints[value]);
        }}
      />

      <IconButton
        icon="arrow_back"
        buttonStyle={ButtonStyle.Bare}
        disabled={previousDisabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setValue(v => (v === 0 ? v : v - 1));
        }}
      />

      <IconButton
        className="mr8"
        buttonStyle={ButtonStyle.Bare}
        icon="arrow_forward"
        disabled={nextDisabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setValue(v => (v === checkpoints.length - 1 ? v : v + 1));
        }}
      />
      <Slider
        handle={({ ref, ...handleProps }: HandleProps) => (
          <CheckpointsSliderHandle
            {...handleProps}
            value={value}
            checkpoint={checkpoints[value]}
            onRestore={onRestore}
          />
        )}
        className="fullWidth"
        min={0}
        max={checkpoints.length - 1}
        value={value}
        step={1}
        dots={true}
        railStyle={{
          backgroundColor: 'var(--grayA5)',
        }}
        trackStyle={{
          backgroundColor: 'var(--grayA5)',
        }}
        dotStyle={{
          backgroundColor: 'var(--gray9)',
          border: 0,
          height: 4,
          width: 4,
          bottom: 0,
          margin: 0,
        }}
        handleStyle={{
          border: 0,
          backgroundColor: 'var(--blue10)',
        }}
        onChange={v => {
          setValue(v);
        }}
      />
    </>
  );
}

function DescriptionCheckpoints({
  entityId,
  onRestore,
  onDescriptionChanged,
}: {
  entityId: string;
  onRestore: (description: HistoryCheckpoint) => void;
  onDescriptionChanged: (description: HistoryCheckpoint) => void;
}) {
  const [{ data, error, fetching }] = useQuery<CheckpointsQuery, CheckpointsQueryVariables>({
    query: gql`
      query Checkpoints($id: ID!) {
        documentCheckpoints(id: $id) {
          checkpoints {
            id
            actorIds
            updatedAt
            content
          }
        }
      }
    `,
    variables: {
      id: entityId,
    },
  });

  return (
    <div className={styles.descriptionCheckpoints}>
      {fetching && <LoadingSpinner className={styles.loading} />}
      {error && 'Unable to fetch description history'}
      {data && data.documentCheckpoints.checkpoints.length > 1 && (
        <CheckpointSlider
          checkpoints={data.documentCheckpoints.checkpoints}
          onDescriptionChanged={onDescriptionChanged}
          onRestore={onRestore}
        />
      )}
      {data && data.documentCheckpoints.checkpoints.length < 2 && (
        <div>The history of description edits will appear here</div>
      )}
    </div>
  );
}

export function DescriptionHistory({
  documentId,
  onRestore,
  onDescriptionChanged,
  onOpen,
  onClose,
}: {
  documentId: string;
  onRestore: (description: HistoryCheckpoint) => void;
  onDescriptionChanged: (description: HistoryCheckpoint | null) => void;
  onOpen: () => void;
  onClose: () => void;
}) {
  const [open, setOpen] = useRecoilState(descriptionHistoryOpenAtom);

  const disable = useDisableKeyNavigation();
  const enable = useEnableKeyNavigation();

  React.useEffect(() => {
    if (!open) {
      enable('description-history');
      onDescriptionChanged(null);
      onClose();
    } else {
      disable('description-history');
      onOpen();
    }
  }, [open]);

  React.useEffect(() => {
    setOpen(false);
  }, [documentId]);

  React.useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <div
      className={cn(styles.descriptionHistoryContainer, {
        [styles.open]: open,
      })}
      onClick={e => {
        if (open) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <CustomCommand
        command={{
          id: 'open-history',
          hotkey: openHistoryKey,
          description: open ? 'Close description history' : 'Open description history',
          handler: () => {
            setOpen(true);
          },
        }}
      />
      <div className="row fullWidth">
        <Tooltip
          content={
            <>
              {open ? 'Close history' : 'Open history'}
              <KeyboardShortcut shortcut={openHistoryKey} />
            </>
          }
        >
          <IconButton
            icon={open ? 'exit' : 'history'}
            buttonStyle={ButtonStyle.SecondaryOverlay}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(v => !v);
            }}
          />
        </Tooltip>

        {open && (
          <HotkeyScope depth={10}>
            <Hotkey
              hotkey="escape"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                setOpen(false);
              }}
            />
            <DescriptionCheckpoints
              entityId={documentId}
              onRestore={description => {
                onRestore(description);
                setTimeout(() => {
                  setOpen(false);
                });
              }}
              onDescriptionChanged={onDescriptionChanged}
            />
          </HotkeyScope>
        )}
      </div>
    </div>
  );
}
