import React from 'react';
import ReactDOM from 'react-dom';
import { atom, useRecoilValue } from 'recoil';

export const DRAG_PREVIEW_ID = 'draggable-preview';
export const DRAG_PREVIEW_OFSET = 10;

export const dragPreviewState = atom<Node | null>({
  key: 'DragPreview',
  default: null,
});

export const dragPreviewClassnameState = atom<string>({
  key: 'DragPreviewClassname',
  default: '',
});

export const dragPreviewElementIdState = atom<string>({
  key: 'DragPreviewElementId',
  default: DRAG_PREVIEW_ID,
});

export const dragPreviewOffsetState = atom<{ x: number; y: number }>({
  key: 'DragPreviewOffset',
  default: { x: DRAG_PREVIEW_OFSET, y: DRAG_PREVIEW_OFSET },
});

function DraggableLayerComponent() {
  const nodeRef = useRecoilValue(dragPreviewState);
  const className = useRecoilValue(dragPreviewClassnameState);
  const destinationElementId = useRecoilValue(dragPreviewElementIdState);
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const offset = useRecoilValue(dragPreviewOffsetState);

  const onMouseMove = (e: MouseEvent) => {
    setY(e.pageY);
    setX(e.pageX);
  };

  React.useEffect(() => {
    const div = document.createElement('div');
    div.setAttribute('id', DRAG_PREVIEW_ID);
    document.body.appendChild(div);
    document.addEventListener('dragover', onMouseMove);
    return () => {
      document.getElementById(DRAG_PREVIEW_ID)?.remove();
      document.removeEventListener('dragover', onMouseMove);
    };
  }, []);

  React.useEffect(() => {
    const clone = nodeRef?.cloneNode(true);
    if (clone) {
      ref.current?.appendChild(clone);
    } else {
      const children = Array.from(ref.current?.children ?? []);
      for (const child of children) {
        child.remove();
      }
    }
  }, [nodeRef]);

  if (!nodeRef) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={className}
      style={{ top: y + offset.y, left: x + offset.x, position: 'fixed' }}
      ref={ref}
    />,
    document.getElementById(destinationElementId) ?? document.getElementById(DRAG_PREVIEW_ID)!
  );
}

export const DraggableLayer = React.memo(DraggableLayerComponent);
