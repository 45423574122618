import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../syncEngine/actions/entities';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../syncEngine/actions/intiatives';
import { useUpdateIssues } from '../../syncEngine/actions/issues';
import { spaceForEntitySelector } from '../../syncEngine/selectors/entities';
import {
  dependencyIdsForIssueSelector,
  issuePath,
  issueSelector,
  issuesResolvedSelector,
} from '../../syncEngine/selectors/issues';
import { moveCycleIndicatorSelector } from '../../syncEngine/selectors/users';
import { removeItemKey } from '../../utils/config';
import { MetadataConfig } from '../metadataConfig';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CustomCommand } from './customCommand';
import { EntityDueDate } from './dueDateMetadata';
import {
  EntityCard,
  EntityCardHeader,
  EntityCardMetadataContainer,
  EntityCardTitle,
  EntityMetadataRow,
} from './entityCard';
import {
  EntityCycleIndicator,
  EntityEffort,
  EntityImpact,
  EntityInsights,
  EntityLabels,
  EntityMembers,
  EntitySnoozed,
  EntityTodos,
  EntityWatching,
} from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import { IconSize } from './icon';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import { MetadataSize } from './metadata/size';
import { WorkItemDependencies } from './workItemDependencies';
import { WorkItemMenu } from './workItemMenu';
import {
  WorkItemCodeReviewRequests,
  WorkItemInitiatives,
  WorkItemPublic,
  WorkItemReleases,
} from './workItemMetadata';

export function WorkItemCard({
  id,
  routingState,
  className,
  style,
  metadataConfig,
  onChangeTitle,
  moveToTopBottom,
  initiativeId,
}: {
  id: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onChangeTitle?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], statusId: string) => void;
  initiativeId?: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const item = useRecoilValue(issueSelector(id));
  const space = useRecoilValue(spaceForEntitySelector(id));
  const currentUser = useCurrentUser();
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(id);
  const history = useHistory();
  const { blockedByIssueIds } = useRecoilValue(dependencyIdsForIssueSelector(id));
  const blockedByResolved = useRecoilValue(issuesResolvedSelector(blockedByIssueIds));
  const moveCycleIndicator = useRecoilValue(moveCycleIndicatorSelector);
  const removeIssuesFromInitiatives = useRemoveIssuesFromInitiatives();

  useKeyNavigationElement(id, ref);

  if (!item || !space) {
    return null;
  }

  const link = {
    pathname: issuePath(organization, space, item),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: id,
      ...routingState,
    },
  };

  return (
    <Link to={link}>
      <EntityCard
        ref={ref}
        className={className}
        style={style}
        blocked={blockedByIssueIds.length > 0 && !blockedByResolved}
        focused={focused}
        highlight={item.assigneeIds.includes(currentUser.id)}
      >
        <EntityCardHeader
          entityNumber={`${space?.key}-${item.number}`}
          date={item.displayedUpdatedAt}
          leftContent={
            moveCycleIndicator && metadataConfig?.cycles !== false ? (
              <EntityCycleIndicator
                minimal
                entity={item}
                interactable={focused}
                size={MetadataSize.Small}
              />
            ) : null
          }
          menuContents={closeMenu => (
            <WorkItemMenu
              closeMenu={closeMenu}
              item={item}
              onChangeTitle={onChangeTitle}
              initiativeId={initiativeId}
              moveToTopBottom={moveToTopBottom}
            />
          )}
        >
          <EntityWatching entity={item} noBorder />
          <EntitySnoozed entity={item} />
          <WorkItemPublic
            id={item.id}
            size={IconSize.Size16}
            style={{ marginBottom: '1.5px', fill: 'var(--grayA8)' }}
          />
        </EntityCardHeader>
        <EntityCardTitle type={issueTerm}>{item.title}</EntityCardTitle>
        <EntityMetadataRow>
          {metadataConfig?.initiatives !== false && (
            <WorkItemInitiatives interactable={focused} id={id} />
          )}
          {metadataConfig?.labels !== false && (
            <EntityLabels interactable={focused} entity={item} size={MetadataSize.Small} />
          )}
          {metadataConfig?.releases !== false && (
            <WorkItemReleases interactable={focused} id={id} />
          )}
        </EntityMetadataRow>
        <EntityCardMetadataContainer
          members={
            metadataConfig?.members !== false ? <EntityMembers interactable entity={item} /> : null
          }
        >
          {metadataConfig?.impact !== false && (
            <EntityImpact interactable={focused} entity={item} />
          )}
          {metadataConfig?.effort !== false && (
            <EntityEffort interactable={focused} entity={item} />
          )}
          {metadataConfig?.codeReview !== false && (
            <WorkItemCodeReviewRequests id={id} interactable={focused} />
          )}
          {metadataConfig?.todos !== false && <EntityTodos entity={item} interactable={focused} />}
          {metadataConfig?.dependencies !== false && (
            <WorkItemDependencies id={id} interactable={focused} />
          )}
          {metadataConfig?.insights !== false && (
            <EntityInsights entity={item} interactable={focused} />
          )}
          {!moveCycleIndicator && metadataConfig?.cycles !== false && (
            <EntityCycleIndicator entity={item} interactable={focused} />
          )}
          {metadataConfig?.dueDates !== false && (
            <EntityDueDate item={item} interactable={focused} />
          )}
        </EntityCardMetadataContainer>
        {focused && (
          <>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
            <CustomCommand
              command={{
                id: 'edit-title',
                hotkey: 't',
                group: CommandGroup.Entities,
                description: `Edit title`,
                priority: 99,
                handler: () => {
                  onChangeTitle?.();
                },
              }}
            />
            <CustomCommand
              command={{
                id: 'open-and-focus-description',
                hotkey: 'd',
                group: CommandGroup.Entities,
                description: `Edit description`,
                priority: 99,
                handler: () => {
                  history.push({
                    ...link,
                    pathname: link.pathname,
                    search: 'focusDescription=true',
                  });
                },
              }}
            />

            {initiativeId && (
              <CustomCommand
                command={{
                  id: 'remove-issue-from-initiative',
                  hotkey: removeItemKey,
                  group: CommandGroup.Entities,
                  description: `Remove ${issueTerm} from initiative`,
                  priority: 99,
                  handler: () => {
                    removeIssuesFromInitiatives([initiativeId], [id]);
                  },
                }}
              />
            )}
          </>
        )}
      </EntityCard>
    </Link>
  );
}

export function EditWorkItemCard({
  id,
  className,
  style,
  metadataConfig,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const item = useRecoilValue(issueSelector(id));
  const space = useRecoilValue(spaceForEntitySelector(id));
  const [title, setTitle] = React.useState(item?.title ?? '');
  const focused = useHasKeyNavigationFocus(id);
  const currentUser = useCurrentUser();

  useKeyNavigationElement(id, ref);

  const updateIssues = useUpdateIssues();
  const addLabels = useAddLabelsToEntities();
  const addMembers = useAddAssigneesToEntities();
  const addInitiatives = useAddIssuesToInitiatives();

  if (!item || !space) {
    return null;
  }

  function save() {
    if (item?.title !== title) {
      updateIssues([id], { title });
    }
    onDone();
  }

  return (
    <EntityCard
      focused={focused}
      ref={ref}
      className={className}
      style={style}
      highlight={item.assigneeIds.includes(currentUser.id)}
    >
      <EntityCardHeader
        entityNumber={`${space?.key}-${item.number}`}
        date={item.displayedUpdatedAt}
        menuContents={closeMenu => (
          <WorkItemMenu closeMenu={closeMenu} item={item} disableChangeStatus />
        )}
      >
        <EntityWatching entity={item} noBorder />
        <EntitySnoozed entity={item} />
        <WorkItemPublic
          id={item.id}
          size={IconSize.Size16}
          style={{ marginBottom: '1.5px', fill: 'var(--grayA8)' }}
        />
      </EntityCardHeader>
      <EntityTitleEditor
        className="headingS mt4"
        initialTitle={title}
        onChange={setTitle}
        onSubmit={save}
        onReset={onDone}
        autoFocus
        supportedMetadata={{
          initiatives: true,
          labels: true,
          users: true,
        }}
        onMetadataAdded={(type, metadataId) => {
          switch (type) {
            case 'label':
              addLabels([id], [metadataId]);
              break;
            case 'user':
              addMembers([id], [metadataId]);
              break;
            case 'initiative':
              addInitiatives([metadataId], [id]);
              break;
          }
        }}
        placeholder={
          <span className="bodyM">
            Enter a title, use @ for members, # for labels and ! for initiatives
          </span>
        }
      />
      <EntityMetadataRow>
        {metadataConfig?.initiatives !== false && <WorkItemInitiatives interactable id={id} />}
        {metadataConfig?.labels !== false && (
          <EntityLabels interactable entity={item} size={MetadataSize.Small} />
        )}
      </EntityMetadataRow>
      <EntityCardMetadataContainer
        members={
          metadataConfig?.members !== false ? <EntityMembers interactable entity={item} /> : null
        }
      >
        {metadataConfig?.impact !== false && <EntityImpact interactable entity={item} />}
        {metadataConfig?.effort !== false && <EntityEffort interactable entity={item} />}
        {metadataConfig?.codeReview !== false && (
          <WorkItemCodeReviewRequests interactable id={id} />
        )}
        {metadataConfig?.todos !== false && <EntityTodos interactable entity={item} />}
        {metadataConfig?.dependencies !== false && <WorkItemDependencies interactable id={id} />}
        {metadataConfig?.insights !== false && <EntityInsights interactable entity={item} />}
        {metadataConfig?.cycles !== false && <EntityCycleIndicator interactable entity={item} />}
        {metadataConfig?.dueDates !== false && <EntityDueDate interactable item={item} />}
      </EntityCardMetadataContainer>
      <div className="rowEnd mt12">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityCard>
  );
}
