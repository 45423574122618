import { partition, uniqBy } from 'lodash';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../utils/search';

const ctx: Worker = self as any;
const EXACT_MATCH_THRESHOLD = 0.001;
const ARCHIVED_EXACT_MATCH_THRESHOLD = 0.000000001;

export interface EntityLike {
  id: string;
  number: string;
  numberWithSpaceKey: string;
  title: string;
  description: string | null;
  spaceSort: string;
  inFavoriteSpace: boolean;
  statusScore: number;
  statusType: 'open' | 'closed' | 'archived';
}

export function indexEntities(worker: Worker, entities: EntityLike[]) {
  worker.postMessage({
    type: 'INDEX',
    entities,
  });
}

export function search(
  worker: Worker,
  id: string,
  search: string | null,
  searchDescription: boolean
) {
  worker.postMessage({
    type: 'SEARCH',
    id,
    search,
    searchDescription,
  });
}

let queuedSearches: Array<{
  id: string;
  search: string | null;
  searchDescription: boolean;
}> = [];
let indexed = false;

const numberAndTitleSearch = new FuzzySearcher<EntityLike>(FuzzySearcherConfiguration.FullText, [
  'title',
  'number',
  'numberWithSpaceKey',
]);

const numberTitleAndDescriptionSearch = new FuzzySearcher<EntityLike>(
  FuzzySearcherConfiguration.FullText,
  [
    { name: 'title', weight: 4 },
    { name: 'number', weight: 4 },
    { name: 'numberWithSpaceKey', weight: 4 },
    'description',
  ]
);

function searchNumberAndTitleWorker(
  search: string | null
): Array<{ item: EntityLike; score?: number }> {
  if (!search) {
    return [];
  }

  return numberAndTitleSearch.search(search);
}

function searchNumberTitleAndDescriptionWorker(
  search: string | null
): Array<{ item: EntityLike; score?: number }> {
  if (!search) {
    return [];
  }

  return numberTitleAndDescriptionSearch.search(search);
}

function indexEntitiesWorker(entities: EntityLike[]) {
  if (!indexed) {
    numberAndTitleSearch.setOptions([...entities]);
    numberTitleAndDescriptionSearch.setOptions([...entities]);
    indexed = true;
    ctx.postMessage({ type: 'INDEXED' });
    return;
  }

  numberAndTitleSearch.reindexOptions(entities, (a, b) => a.id === b.id);
  numberTitleAndDescriptionSearch.reindexOptions(entities, (a, b) => a.id === b.id);
}

function execute() {
  queuedSearches = uniqBy(queuedSearches, s => s.id);
  const s = queuedSearches.shift()!;
  if (!indexed) {
    return;
  }

  const rawResults = s.searchDescription
    ? searchNumberTitleAndDescriptionWorker(s.search)
    : searchNumberAndTitleWorker(s.search);

  const [exact, inexact] = partition(
    rawResults,
    r =>
      r.score !== undefined &&
      ((r.score < EXACT_MATCH_THRESHOLD && r.item.statusType !== 'archived') ||
        (r.score < ARCHIVED_EXACT_MATCH_THRESHOLD && r.item.statusType === 'archived'))
  );

  const open = inexact.filter(r => r.item.statusType === 'open');
  const closed = inexact.filter(r => r.item.statusType === 'closed');
  const archived = inexact.filter(r => r.item.statusType === 'archived');
  const results = [...exact, ...open, ...closed, ...archived].map(r => r.item.id);

  ctx.postMessage({ type: 'RESULTS', results, id: s.id, search: s.search });

  if (queuedSearches.length) {
    execute();
  }
}

// Respond to message from parent thread
ctx.addEventListener('message', event => {
  if (!event.data) {
    return;
  }

  switch (event.data.type) {
    case 'INDEX':
      indexEntitiesWorker(event.data.entities);
      break;
    case 'SEARCH':
      {
        queuedSearches.push(event.data);
        if (queuedSearches.length === 1) {
          execute();
        }
      }
      break;
  }
});
