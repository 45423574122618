import * as Sentry from '@sentry/browser';
import * as React from 'react';
import ErrorText from './errorText';

export class ErrorBoundary extends React.Component<
  {
    children: React.ReactNode;
    className?: string;
  },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode; className?: string }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={this.props.className}>
          <ErrorText error="It looks like something has gone wrong. Please reload and try again 😞" />
        </div>
      );
    }

    return this.props.children;
  }
}
