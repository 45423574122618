import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../../shared/utils/terms';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  SettingsSection,
} from '../../../../components/new/settings';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { isElectron, openLinkInBrowser } from '../../../../electronIntegration';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

const description = <p>Easily create and link {issueTerm}s from Sentry issues.</p>;

export function EditSentryIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));

  if (!integration) {
    return <IntegrationNotFound />;
  }

  const config = JSON.parse(integration.config);
  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Sentry}
      description={
        <>
          {description}
          <p>Your Sentry integration is installed. You can uninstall it from Sentry.</p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton
          onClick={() => {
            if (isElectron()) {
              openLinkInBrowser(config.url);
            } else {
              window.open(config.url);
            }
          }}
        >
          Sentry settings
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
export function NewSentryIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(`${host}/integrations/sentry/install`, electronScheme, {
      redirectBaseUrl: redirectUrl,
      redirectQueryParams: {
        pendingIntegration: 'sentry',
      },
      exchange: true,
    });
  }

  return (
    <IntegrationSettingsPage integrationType={IntegrationType.Sentry} description={description}>
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Sentry integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
