import React from 'react';
import { useRecoilValue } from 'recoil';
import { TagPicker, feedbackToTagPickerState } from '../../../components/new/pickers/tagPicker';
import { useAddTagToFeedback, useRemoveTagFromFeedbak } from '../../../syncEngine/actions/feedback';
import { feedbacksSelector } from '../../../syncEngine/selectors/feedback';
import styles from '../commandMenu.module.scss';

export function ChangeTagsContents({
  onClose,
  feedbackIds,
}: {
  onClose: () => void;
  feedbackIds: string[];
}) {
  const addTagsToFeedback = useAddTagToFeedback();
  const removeTagsFromFeedback = useRemoveTagFromFeedbak();
  const feedbacks = useRecoilValue(feedbacksSelector(feedbackIds));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <TagPicker
        filterClassName={styles.inputDiv}
        state={feedbackToTagPickerState(feedbacks)}
        onTagAdded={(feedbackIds, userId) => {
          addTagsToFeedback(feedbackIds, [userId]);
        }}
        onTagRemoved={(feedbackIds, userId) => {
          removeTagsFromFeedback(feedbackIds, [userId]);
        }}
        onDone={onClose}
      />
    </div>
  );
}
