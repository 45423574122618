import * as React from 'react';
import {
  MemberRole,
  OrganizationMember,
  User as UserModel,
} from '../../../sync/__generated/models';
import Avatar from './metadata/avatar';
import styles from './user.module.scss';

export function User({
  user,
  member,
  className,
  style,
}: {
  user: UserModel;
  member: OrganizationMember;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <>
      <Avatar name={user.name || user.username} img={user.avatar} />
      <span className={className} style={style}>
        {user.name || user.username}
        {member.invited && <span className={styles.info}> (invited)</span>}
        {member.deactivated && <span className={styles.info}> (deactivated)</span>}
        {member.role === MemberRole.Guest && <span className={styles.info}> (guest)</span>}
      </span>
    </>
  );
}
