import { EventEmitter } from 'eventemitter3';
import * as React from 'react';
import { Snapshot, useRecoilCallback } from 'recoil';
import uuid from 'uuid';
import { InitiativeStatus } from '../../shared/initiativeStatus';
import { filterNotDeletedNotNull, filterNotNull } from '../../shared/utils/convenience';
import {
  CollaborativeDoc,
  Doc,
  Entity,
  Feedback,
  Initiative,
  Issue,
  IssueStatusType,
  ReleaseStatus,
} from '../../sync/__generated/models';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { collaborativeDocsByEntitiesSelector } from '../syncEngine/selectors/collaborativeDoc';
import { documentsForOrganizationSelector, isDocument } from '../syncEngine/selectors/documents';
import {
  entitiesSelector,
  entitySelector,
  orgEntityKey,
  useFindRelevantEntities,
} from '../syncEngine/selectors/entities';
import { feedbackForOrganizationSelector, isFeedback } from '../syncEngine/selectors/feedback';
import {
  initiativeStatusSelector,
  initiativesForOrganizationSelector,
  isInitiative,
  scoreInitiative as scoreInitiativeStatus,
  spacesIdsForInitiativeSelector,
} from '../syncEngine/selectors/intiatives';
import {
  isIssue,
  issuesForSpaceSelector,
  scoreStatus,
  statusSelector,
} from '../syncEngine/selectors/issues';
import { isRelease, releasesForOrganizationSelector } from '../syncEngine/selectors/releases';
import { spacesForOrganizationSelector } from '../syncEngine/selectors/spaces';
import { spacesForCurrentUserMembershipSelector } from '../syncEngine/selectors/users';
import { StateEvents, offStateEvent, onStateEvent } from '../syncEngine/state';
import { SyncEngineObject } from '../syncEngine/types';
// eslint-disable-next-line import/no-duplicates
import { EntityLike, indexEntities, search } from '../workers/search.worker';
// eslint-disable-next-line import/no-duplicates
import Worker from '../workers/search.worker?worker';
import { useOrganization } from './organizationContext';

const MAX_RESULTS = 50;
const REINDEXING_TIMEOUT = 10000;

const emitter = new EventEmitter();

const worker = new Worker();
worker.onmessage = e => {
  if (e.data.type === 'RESULTS') {
    emitter.emit('results', e.data);
  }
  if (e.data.type === 'INDEXED') {
    emitter.emit('indexed');
  }
};

interface SearchOptions {
  searchDescription?: boolean;
}

interface SearchContext {
  isIndexed: boolean;
  search(id: string, searchQuery: string | null, options?: SearchOptions): void;
  pauseIndexing(): void;
  resumeIndexing(): void;
}

const searchContext = React.createContext<SearchContext | null>(null);

function getStatusType(snapshot: Snapshot, entity: Entity): 'open' | 'closed' | 'archived' {
  if (isIssue(entity)) {
    const status = snapshot.getLoadable(statusSelector(entity.statusId)).getValue();
    if (!status) {
      return 'open';
    }
    if (
      [IssueStatusType.Backlog, IssueStatusType.Todo, IssueStatusType.InProgress].includes(
        status.statusType
      )
    ) {
      return 'open';
    } else if (status?.statusType === IssueStatusType.Done) {
      return 'closed';
    }
  }

  if (isInitiative(entity)) {
    const status = snapshot.getLoadable(initiativeStatusSelector(entity.id)).getValue();
    switch (status) {
      case InitiativeStatus.NotStarted:
      case InitiativeStatus.Started:
        return 'open';
      case InitiativeStatus.Completed:
        return 'closed';
    }
  }

  if (isRelease(entity)) {
    if (entity.archivedAt) {
      return 'archived';
    }

    switch (entity.releaseStatus) {
      case ReleaseStatus.Planned:
      case ReleaseStatus.InDevelopment:
        return 'open';
      case ReleaseStatus.Released:
        return 'closed';
    }
  }

  return 'archived';
}

function getStatusScore(snapshot: Snapshot, entity: Entity): number {
  if (isIssue(entity)) {
    const status = snapshot.getLoadable(statusSelector(entity.statusId)).getValue();
    if (!status) {
      return 100;
    }
    return scoreStatus(status.statusType);
  }

  if (isInitiative(entity)) {
    const status = snapshot.getLoadable(initiativeStatusSelector(entity.id)).getValue();
    if (!status) {
      return 100;
    }
    return scoreInitiativeStatus(status);
  }

  if (isRelease(entity)) {
    const status = snapshot.getLoadable(initiativeStatusSelector(entity.id)).getValue();
    if (!status) {
      return 100;
    }
    return scoreInitiativeStatus(status);
  }

  // lower is better, so return something big since we can't figure it out
  return 100;
}

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const organization = useOrganization();
  const timeoutRef = React.useRef(-1);
  const entitiesToReindex = React.useRef<string[]>([]);
  const indexingPaused = React.useRef(false);
  const findRelevantEntities = useFindRelevantEntities();
  const [indexed, setIndexed] = React.useState(false);
  const indexedRef = React.useRef(indexed);
  indexedRef.current = indexed;

  const index = useRecoilCallback(({ snapshot }) => (entityIds?: string[]) => {
    const spaces = snapshot.getLoadable(spacesForOrganizationSelector(organization.id)).getValue();
    const { favoriteSpaces } = snapshot
      .getLoadable(spacesForCurrentUserMembershipSelector(organization.id))
      .getValue();
    const favoriteSpaceIds = favoriteSpaces.map(s => s.id);

    const entityInput =
      indexedRef.current && entityIds
        ? (snapshot
            .getLoadable(entitiesSelector(entityIds))
            .getValue()

            .filter(
              e =>
                e.__typename === 'Issue' ||
                e.__typename === 'Initiative' ||
                e.__typename === 'Feedback' ||
                e.__typename === 'Doc'
            ) as (Issue | Initiative | Feedback | Doc)[])
        : [
            ...spaces.flatMap(space => [
              ...snapshot.getLoadable(issuesForSpaceSelector(space.id)).getValue(),
            ]),
            ...snapshot.getLoadable(initiativesForOrganizationSelector(organization.id)).getValue(),
            ...snapshot.getLoadable(feedbackForOrganizationSelector(organization.id)).getValue(),
            ...snapshot.getLoadable(documentsForOrganizationSelector(organization.id)).getValue(),
            ...snapshot.getLoadable(releasesForOrganizationSelector(organization.id)).getValue(),
          ];

    const docs = snapshot
      .getLoadable(collaborativeDocsByEntitiesSelector(entityInput.map(e => e.id)))
      .getValue() as CollaborativeDoc[];

    const entities: EntityLike[] = filterNotNull(
      entityInput.map(entity => {
        const doc = docs.find(d => d.entityId === entity.id);
        if (isInitiative(entity)) {
          const spaceIds = snapshot
            .getLoadable(spacesIdsForInitiativeSelector(entity.id))
            .getValue();
          const inFavoriteSpace = spaceIds.some(id => favoriteSpaceIds.includes(id));
          return {
            ...entity,
            description: doc ? JSON.stringify(doc) : null,
            numberWithSpaceKey: orgEntityKey(entity),
            spaceSort: 'a', // All initiatives are treated equal
            inFavoriteSpace,
            statusScore: getStatusScore(snapshot, entity),
            statusType: getStatusType(snapshot, entity),
          };
        }
        if (isFeedback(entity)) {
          return {
            ...entity,
            description: doc ? JSON.stringify(doc) : null,
            numberWithSpaceKey: orgEntityKey(entity),
            spaceSort: 'a', // All feedback treated equal
            inFavoriteSpace: false,
            statusScore: 100,
            statusType: entity.processed ? 'closed' : 'open',
          };
        }
        if (isDocument(entity)) {
          return {
            ...entity,
            description: doc ? JSON.stringify(doc) : null,
            numberWithSpaceKey: orgEntityKey(entity),
            spaceSort: 'a', // All docs treated equal
            inFavoriteSpace: false,
            statusScore: 100,
            statusType: entity.archivedAt ? 'archived' : 'open',
          };
        }
        if (isRelease(entity)) {
          return {
            ...entity,
            description: doc ? JSON.stringify(doc) : null,
            numberWithSpaceKey: orgEntityKey(entity),
            spaceSort: 'a', // All releases treated equal
            inFavoriteSpace: false,
            statusScore: getStatusScore(snapshot, entity),
            statusType: getStatusType(snapshot, entity),
          };
        }
        const space = spaces.find(s => s.id === entity.spaceId);
        if (!space) {
          return null;
        }
        return {
          ...entity,
          description: doc ? JSON.stringify(doc) : null,
          numberWithSpaceKey: `${space.key}-${isDocument(entity) ? 'D' : ''}${entity.number}`,
          spaceSort: space.sort,
          inFavoriteSpace: favoriteSpaceIds.includes(space.id),
          statusScore: getStatusScore(snapshot, entity),
          statusType: getStatusType(snapshot, entity),
        };
      })
    );
    if (entities.length) {
      indexEntities(worker, entities);
    }
    setIndexed(true);
  });

  useComponentDidMount(() => {
    function handleSet({ objects }: { objects: SyncEngineObject[] }) {
      const relevantForEntities = findRelevantEntities(objects);
      if (indexingPaused.current) {
        entitiesToReindex.current.push(...relevantForEntities);
        return;
      }

      const reindex =
        (!indexedRef.current || relevantForEntities.length) && !indexingPaused.current;

      if (reindex) {
        if (timeoutRef.current !== -1) {
          window.clearTimeout(timeoutRef.current);
          timeoutRef.current = -1;
        }
        timeoutRef.current = window.setTimeout(
          () => index(relevantForEntities),
          indexed ? REINDEXING_TIMEOUT : 500
        );
      }
    }

    onStateEvent(StateEvents.Set, handleSet);
    return () => offStateEvent(StateEvents.Set, handleSet);
  });

  const value = {
    search(id: string, searchQuery: string | null, options?: SearchOptions) {
      if (!indexedRef.current) {
        index();
      }
      search(worker, id, searchQuery, !!options?.searchDescription);
    },
    index,
    pauseIndexing() {
      indexingPaused.current = true;
    },
    resumeIndexing() {
      indexingPaused.current = false;
      if (entitiesToReindex.current.length) {
        timeoutRef.current = window.setTimeout(() => {
          index(entitiesToReindex.current);
          entitiesToReindex.current = [];
        }, REINDEXING_TIMEOUT);
      }
    },
    isIndexed: indexed,
  };
  return <searchContext.Provider value={value}>{children}</searchContext.Provider>;
}

export function useSearch(
  callback: (results: Entity[], searchString: string | null) => void,
  options?: { searchDescription?: boolean; issuesOnly?: boolean }
) {
  const { search } = React.useContext(searchContext)!;
  const searchId = React.useRef(uuid.v4());
  const queryRef = React.useRef<string>('');
  const mapResults = useRecoilCallback(({ snapshot }) => (entityIds: string[]) => {
    const entities = snapshot.getLoadable(entitiesSelector(entityIds)).getValue();
    return filterNotDeletedNotNull(entities).filter(
      entity => !options?.issuesOnly || isIssue(entity)
    );
  });

  useComponentDidMount(() => {
    function onResults({
      results,
      id,
      search,
    }: {
      results: string[];
      id: string;
      search: string | null;
    }) {
      if (searchId.current !== id) {
        return;
      }
      callback(mapResults(results), search);
    }

    function onIndexed() {
      search(searchId.current, queryRef.current, { searchDescription: options?.searchDescription });
    }

    emitter.on('results', onResults);
    emitter.on('indexed', onIndexed);

    return () => {
      emitter.off('results', onResults);
      emitter.off('indexed', onIndexed);
    };
  });

  return (query: string) => {
    queryRef.current = query;
    search(searchId.current, query, { searchDescription: options?.searchDescription });
  };
}

export function useSearchOnce() {
  const { search } = React.useContext(searchContext)!;
  return useRecoilCallback(
    ({ snapshot }) =>
      (searchQuery: string | null, options?: SearchOptions): Promise<Entity[]> => {
        const searchOnceId = uuid.v4();
        return new Promise<Entity[]>(resolve => {
          function onOnceResults({ results, id }: { results: string[]; id: string }) {
            if (searchOnceId !== id) {
              return;
            }

            const entities = results.map(id => snapshot.getLoadable(entitySelector(id)).getValue());
            resolve(filterNotDeletedNotNull(entities).slice(0, MAX_RESULTS));
            emitter.off('results', onOnceResults);
          }

          emitter.on('results', onOnceResults);
          search(searchOnceId, searchQuery, options);
        });
      }
  );
}

export function usePauseSearchIndexing() {
  const context = React.useContext(searchContext);
  useComponentDidMount(() => {
    context?.pauseIndexing();
    return () => context?.resumeIndexing();
  });
  return null;
}

export function useIsSearchIndexed() {
  const context = React.useContext(searchContext);
  return context?.isIndexed;
}
