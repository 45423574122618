import { orderBy, partition } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { OrganizationLabel } from '../../../../sync/__generated/models';
import LinkButton from '../../../components/linkButton';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../../components/new/button';
import { ColorPicker } from '../../../components/new/colorPicker';
import { Icon } from '../../../components/new/icon';
import Label from '../../../components/new/metadata/label';
import { MetadataSize } from '../../../components/new/metadata/size';
import {
  Setting,
  SettingsListItem,
  SettingsPage,
  SettingsSection,
} from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { TextInput, TextInputSize } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  useCreateEffort,
  useDeleteEfforts,
  useReorderEfforts,
  useUpdateEfforts,
} from '../../../syncEngine/actions/effort';
import {
  useCreateImpact,
  useDeleteImpacts,
  useReorderImpacts,
  useUpdateImpacts,
} from '../../../syncEngine/actions/impact';
import {
  useCreateOrganizationLabel,
  useDeleteOrganizationLabels,
  useUpdateOrganizationLabels,
} from '../../../syncEngine/actions/organizationLabels';
import { useUpdateOrganization } from '../../../syncEngine/actions/organizations';
import { globalEffortLevelsForOrganizationSelector } from '../../../syncEngine/selectors/effortLevels';
import { globalImpactLevelsForOrganizationSelector } from '../../../syncEngine/selectors/impactLevels';
import { orgLabelsForOrganizationSelector } from '../../../syncEngine/selectors/organizationLabels';
import { roadmapAndInitiateivesGuideURL } from '../../../utils/config';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import { LevelsSection } from './impactEffortSettings';
import styles from './settingsScreen.module.scss';

function LabelListItem({
  label,
  editing,
  onEdit,
  isFirst,
  isLast,
}: {
  label: OrganizationLabel;
  editing: boolean;
  onEdit: (label: OrganizationLabel, action: 'edit' | 'save' | 'cancel') => void;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const deleteLabels = useDeleteOrganizationLabels();
  const updateLabels = useUpdateOrganizationLabels();

  const [update, setUpdate] = React.useState<{ name: string; color: string }>({
    name: label.name,
    color: label.color,
  });
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);

  if (editing) {
    return (
      <SettingsListItem
        isFirst={isFirst}
        isLast={isLast}
        onClickOutside={() => {
          if (!colorPickerOpen) {
            onEdit(label, 'cancel');
          }
        }}
      >
        <ColorPicker
          initialColor={update.color}
          onColorPicked={c => setUpdate({ ...update, color: c ?? 'gray' })}
          onOpenChanged={setColorPickerOpen}
          renderPreview={(c, forceDarkMode) => (
            <Label
              color={c}
              name={'Preview Label'}
              size={MetadataSize.Medium}
              forceDarkMode={forceDarkMode}
            />
          )}
        />
        <TextInput
          inputSize={TextInputSize.Small}
          className="grow mr8 ml8"
          value={update.name}
          autoFocus={true}
          onChange={e => setUpdate({ ...update, name: e.currentTarget.value })}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
              e.stopPropagation();
              updateLabels([label.id], update);
              onEdit(label, 'save');
              return;
            }
            if (e.key.toLowerCase() === 'escape') {
              onEdit(label, 'cancel');
            }
          }}
        />
        <Button
          className="mr8"
          size={ButtonSize.Small}
          onClick={() => {
            onEdit(label, 'cancel');
          }}
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.Primary}
          onClick={() => {
            updateLabels([label.id], update);
            onEdit(label, 'save');
          }}
        >
          Save
        </Button>
      </SettingsListItem>
    );
  }

  return (
    <SettingsListItem
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onEdit(label, 'edit');
      }}
      isFirst={isFirst}
      isLast={isLast}
      meta={
        <>
          <Tooltip content="Edit">
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              icon="edit"
              onClick={() => onEdit(label, 'edit')}
            />
          </Tooltip>
          <Tooltip content="Delete">
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              className={styles.deleteIcon}
              icon="delete"
              onClick={() => deleteLabels([label.id])}
            />
          </Tooltip>
        </>
      }
    >
      <Label
        className={styles.label}
        color={label.color}
        name={label.name}
        size={MetadataSize.Medium}
      />
    </SettingsListItem>
  );
}

function newlyCreatedFirst(
  labels: OrganizationLabel[],
  newlyCreatedId: string | null
): OrganizationLabel[] {
  const [newlyCreated, rest] = partition(orderBy(labels, 'name'), l => l.id === newlyCreatedId);
  return [...newlyCreated, ...rest];
}

function LabelSettings() {
  const organization = useOrganization();
  const createLabel = useCreateOrganizationLabel();
  const deleteLabels = useDeleteOrganizationLabels();

  const allLabels = useRecoilValue(orgLabelsForOrganizationSelector(organization.id));
  const [labels, setLabels] = React.useState(allLabels);
  const [searchString, setSearchString] = React.useState('');
  const [newlyCreated, setNewlyCreated] = React.useState<string | null>(null);
  const [currentlyEditing, setCurrentlyEditing] = React.useState<string | null>(null);

  const search = React.useMemo(() => {
    return new FuzzySearcher(FuzzySearcherConfiguration.Autocomplete, ['name'], allLabels);
  }, [allLabels]);

  React.useEffect(() => {
    if (!searchString) {
      setLabels(newlyCreatedFirst(allLabels, newlyCreated));
      return;
    }

    setLabels(
      newlyCreatedFirst(
        search.search(searchString).map(i => i.item),
        newlyCreated
      )
    );
  }, [search, allLabels, searchString, newlyCreated]);

  return (
    <SettingsSection largeHeader title="Initiative labels">
      <div className="rowStretch mb16">
        <Button
          className="mr16"
          buttonStyle={ButtonStyle.Primary}
          icon="add"
          onClick={() => {
            const { id } = createLabel(organization.id, '');
            setCurrentlyEditing(id);
            setNewlyCreated(id);
          }}
        >
          New label
        </Button>
        <TextInput
          className={styles.searchInput}
          value={searchString}
          onChange={v => setSearchString(v.currentTarget.value)}
          placeholder="Filter labels"
        />
      </div>

      {labels.map((label, index) => (
        <LabelListItem
          key={label.id}
          isFirst={index === 0}
          isLast={index === labels.length - 1}
          label={label}
          editing={currentlyEditing === label.id}
          onEdit={(l, action) => {
            if (action === 'edit') {
              setCurrentlyEditing(l.id);
            } else {
              if (newlyCreated && action === 'cancel') {
                deleteLabels([newlyCreated], true);
              }
              setNewlyCreated(null);
              setCurrentlyEditing(null);
            }
          }}
        />
      ))}
    </SettingsSection>
  );
}

function ImpactSection({
  currentlyEditing,
  setCurrentlyEditing,
  newlyCreated,
  setNewlyCreated,
}: {
  currentlyEditing: string | null;
  setCurrentlyEditing: (val: string | null) => void;
  newlyCreated: string | null;
  setNewlyCreated: (val: string | null) => void;
}) {
  const organization = useOrganization();
  const levels = useRecoilValue(globalImpactLevelsForOrganizationSelector(organization.id));

  const create = useCreateImpact();
  const update = useUpdateImpacts();
  const deleteLevel = useDeleteImpacts();
  const reorderLevels = useReorderImpacts();

  return (
    <LevelsSection
      deleteLevel={deleteLevel}
      updateLevel={update}
      mode={'impact'}
      levels={levels}
      create={create}
      reorderLevels={reorderLevels}
      currentlyEditing={currentlyEditing}
      setCurrentlyEditing={setCurrentlyEditing}
      newlyCreated={newlyCreated}
      setNewlyCreated={setNewlyCreated}
    />
  );
}
function EffortSection({
  currentlyEditing,
  setCurrentlyEditing,
  newlyCreated,
  setNewlyCreated,
}: {
  currentlyEditing: string | null;
  setCurrentlyEditing: (val: string | null) => void;
  newlyCreated: string | null;
  setNewlyCreated: (val: string | null) => void;
}) {
  const organization = useOrganization();
  const levels = useRecoilValue(globalEffortLevelsForOrganizationSelector(organization.id));
  const create = useCreateEffort();
  const reorderLevels = useReorderEfforts();
  const deleteLevel = useDeleteEfforts();
  const update = useUpdateEfforts();
  return (
    <LevelsSection
      deleteLevel={deleteLevel}
      updateLevel={update}
      mode={'effort'}
      levels={levels}
      create={create}
      reorderLevels={reorderLevels}
      currentlyEditing={currentlyEditing}
      setCurrentlyEditing={setCurrentlyEditing}
      newlyCreated={newlyCreated}
      setNewlyCreated={setNewlyCreated}
    />
  );
}

function ImpactEffortSettings() {
  const [currentlyEditing, setCurrentlyEditing] = React.useState<string | null>(null);
  const [newlyCreated, setNewlyCreated] = React.useState<string | null>(null);
  return (
    <SettingsPage
      title="Impact & Effort"
      description={
        <>
          <p>
            Use impact and/or to communicate and share rough estimations on initiatives. You can
            customize the levels to your teams' needs here.
          </p>
        </>
      }
    >
      <ImpactSection
        currentlyEditing={currentlyEditing}
        setCurrentlyEditing={setCurrentlyEditing}
        newlyCreated={newlyCreated}
        setNewlyCreated={setNewlyCreated}
      />
      <EffortSection
        currentlyEditing={currentlyEditing}
        setCurrentlyEditing={setCurrentlyEditing}
        newlyCreated={newlyCreated}
        setNewlyCreated={setNewlyCreated}
      />
    </SettingsPage>
  );
}

export function RoadmapSettingsScreen() {
  const organization = useOrganization();
  const updateOrganization = useUpdateOrganization();
  return (
    <SettingsPage
      title="Roadmaps and initiatives"
      description={
        <>
          <p>
            Initiatives are a great method to connect large features that will consist of multiple
            deliveries or require cross-team collaboration. To organize initiatives over time, you
            can create roadmaps. Roadmaps are boards where you can prioritize initiatives in columns
            for past, present, and future horizons.
          </p>
          <p>
            <LinkButton to={roadmapAndInitiateivesGuideURL} className="row inlineFlex ml-4">
              Read more in the Kitemaker Guide <Icon icon="external_link" className="ml4" />
            </LinkButton>
          </p>
        </>
      }
    >
      <SettingsSection>
        <Setting
          title="Enable roadmaps"
          description="Roadmaps let you group and manage initiatives on a board."
        >
          <Switch
            checked={organization.newRoadmapsEnabled}
            onChange={v => updateOrganization({ newRoadmapsEnabled: v })}
          />
        </Setting>
      </SettingsSection>
      <ImpactEffortSettings />
      <LabelSettings />
    </SettingsPage>
  );
}
