import { maxBy } from 'lodash';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { stringifyDocument } from '../../../shared/slate/utils';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { indexArray } from '../../../shared/utils/utils';
import { todosByEntity, todosByMember } from '../../../sync/__generated/indexes';
import { Todo, TodoStatus } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { entityKeySelector, entitySelector, isSpaceBoundEntity } from './entities';
import { issueSelector } from './issues';
import { labelsForSpaceSelector } from './labels';
import { activeUsersForOrganizationSelector } from './organizations';
import { spaceSelector } from './spaces';
import { currentUserState } from './users';

export function isTodo(todo: SyncEngineObject): todo is Todo {
  return todo.__typename === 'Todo';
}

export const todoSelector = selectorFamily({
  key: 'Todo',
  get:
    (todoId: string | null | undefined) =>
    ({ get }) => {
      if (!todoId) {
        return null;
      }
      return notDeleted(get(syncEngineState(todoId)) as Todo | null);
    },
});

export const todosSelector = selectorFamily({
  key: 'Todos',
  get:
    (todoIds: string[]) =>
    ({ get }) => {
      return filterNotNull(todoIds.map(todoId => get(todoSelector(todoId))));
    },
});

export const todosForEntitySelector = selectorFamily({
  key: 'TodosForEntity',
  get:
    (entityId: string | undefined, opts?: { includeOrphans?: boolean }) =>
    ({ get }) => {
      if (!entityId) {
        return [];
      }
      const todos = get(indexKeyState(indexKey(todosByEntity, entityId)));
      return filterNotDeletedNotNull(
        todos.map(todoId => get(syncEngineState(todoId)) as Todo | null)
      ).filter(t => !t.orphaned || opts?.includeOrphans);
    },
});

export function useGetTodosForEntity() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (entityId: string) => {
        return snapshot.getLoadable(todosForEntitySelector(entityId)).getValue();
      },
    []
  );
}

export const todoSearchSelector = selectorFamily({
  key: 'TodoSearch',
  get:
    (issueId: string | undefined) =>
    ({ get }) => {
      const issue = get(issueSelector(issueId));

      const labels = get(labelsForSpaceSelector(issue?.spaceId));
      const labelIndex = indexArray(labels, l => l.id);

      const todos = get(todosForEntitySelector(issueId));

      const space = get(spaceSelector(issue?.spaceId));
      const organizationId = space?.organizationId;
      const orgUsers = organizationId
        ? get(activeUsersForOrganizationSelector({ organizationId }))
        : [];
      const userIndex = indexArray(
        orgUsers.map(({ user }) => ({
          id: user.id,
          name: user.name ?? user.username,
        })),
        i => i.id
      );

      return todos.map(todo => ({
        ...todo,
        labelNames: todo.labelIds.map(l => `#${labelIndex[l]?.name}` ?? null),
        userNames: todo.memberIds.map(m => `@${userIndex[m]?.name}` ?? null),
        textContent: stringifyDocument(todo.todoContents),
      }));
    },
});

export function useFillTodoParents() {
  return useRecoilCallback(({ snapshot }) => (todos: Todo[]) => {
    const allTodosWithParents: Todo[] = [];

    for (const todo of todos) {
      if (todo.parentId && !allTodosWithParents.find(t => t.id === todo.parentId)) {
        const parent = snapshot.getLoadable(todoSelector(todo.parentId)).getValue();
        if (parent) {
          if (parent.parentId && !allTodosWithParents.find(t => t.id === parent.parentId)) {
            const grandParent = snapshot.getLoadable(todoSelector(parent.parentId)).getValue();
            if (grandParent) {
              allTodosWithParents.push(grandParent);
            }
          }

          allTodosWithParents.push(parent);
        }
      }
      allTodosWithParents.push(todo);
    }

    return allTodosWithParents;
  });
}

export const currentUserTodosForEntitySelector = selectorFamily({
  key: 'CurrentUserTodosForEntity',
  get:
    (entityId: string | undefined) =>
    ({ get }) => {
      const user = get(currentUserState);
      if (!entityId || !user) {
        return [];
      }

      const todos = get(todosForEntitySelector(entityId));

      const allTodosWithParents = new Set<string>();

      // walk them in reverse so we see the children before the parent
      for (const todo of [...todos].reverse()) {
        if (todo.memberIds.includes(user.id)) {
          allTodosWithParents.add(todo.id);
        }
        if (allTodosWithParents.has(todo.id) && todo.parentId) {
          allTodosWithParents.add(todo.parentId);
        }
      }

      return todos.filter(todo => allTodosWithParents.has(todo.id));
    },
});

export const todosForMemberSelector = selectorFamily({
  key: 'TodosForMember',
  get:
    (memberId: string | undefined, opts?: { includeOrphans?: boolean }) =>
    ({ get }) => {
      if (!memberId) {
        return [];
      }
      const todos = get(indexKeyState(indexKey(todosByMember, memberId)));
      return filterNotDeletedNotNull(
        todos.map(todoId => get(syncEngineState(todoId)) as Todo | null)
      ).filter(t => !t.orphaned || opts?.includeOrphans);
    },
});

export const todoCountForEntitySelector = selectorFamily({
  key: 'TodosForEntity',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return { completed: 0, total: 0 };
      }

      const todos = get(todosForEntitySelector(entityId));
      return {
        completed: todos.filter(todo => todo.status === TodoStatus.Done).length,
        total: todos.length,
      };
    },
});

export const todoKeySelector = selectorFamily({
  key: 'TodoKeys',
  get:
    (todoId: string | null | undefined) =>
    ({ get }) => {
      if (!todoId) {
        return null;
      }
      const todo = get(todoSelector(todoId));
      const entity = get(entitySelector(todo?.entityId));

      if (!entity || !todo) {
        return null;
      }

      const entityKey = get(entityKeySelector(entity.id));
      return `${entityKey}${todo.key.toLocaleLowerCase()}`;
    },
});

export const todoKeyWidthSelector = selectorFamily({
  key: 'TodoKeyWidths',
  get:
    (todoId: string | null | undefined) =>
    ({ get }) => {
      if (!todoId) {
        return null;
      }
      const todo = get(todoSelector(todoId));

      if (!todo) {
        return null;
      }
      const todos = get(todosForEntitySelector(todo.entityId));
      const maxTodoLength = maxBy(todos, t => t?.key.length);

      return maxTodoLength?.key?.length ?? 1;
    },
});

export const currentUserTodoKeyWidth = selectorFamily({
  key: 'CurrentUserTodoKeyWidths',
  get:
    (todoId: string | null | undefined) =>
    ({ get }) => {
      if (!todoId) {
        return null;
      }
      const todo = get(todoSelector(todoId));
      const entity = get(entitySelector(todo?.entityId));
      if (!entity || !isSpaceBoundEntity(entity)) {
        return null;
      }

      const space = get(spaceSelector(entity?.spaceId));

      if (!todo || !space) {
        return null;
      }

      const todos = get(currentUserTodosForEntitySelector(todo.entityId));
      const maxTodoLength = maxBy(todos, t => t.key?.length);

      return maxTodoLength?.key.length ?? 1;
    },
});

export function todoStatusToIcon(status: TodoStatus, linked = false): string {
  switch (status) {
    case TodoStatus.NotStarted:
      return linked ? 'todo_blank_linked' : 'todo_blank';
    case TodoStatus.InProgress:
      return linked ? 'todo_half_linked' : 'todo_half';
    case TodoStatus.Done:
      return linked ? 'todo_checkmark_linked' : 'todo_checkmark';
  }
}
