import { isEqual, partition } from 'lodash';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { COMMENT_ID_QUERY_PARAM, COMMENT_THREAD_ID_QUERY_PARAM } from '../../../shared/comments';
import { filterNotNull } from '../../../shared/utils/convenience';
import { commentsByThread } from '../../../sync/__generated/indexes';
import { Comment } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { equalSelectorFamily } from '../../utils/recoil';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { useEntityPath } from './entities';

export const commentSelector = selectorFamily({
  key: 'Comments',
  get:
    (commentId: string | undefined | null) =>
    ({ get }) => {
      if (!commentId) {
        return null;
      }

      return get(syncEngineState(commentId)) as Comment | null;
    },
});

export const commentsSelector = selectorFamily({
  key: 'Comments',
  get:
    (commentIds: string[]) =>
    ({ get }) => {
      return filterNotNull(commentIds.map(commentId => get(commentSelector(commentId))));
    },
});

export function useFindComment() {
  return useRecoilCallback(({ snapshot }) => (commentId?: string | null) => {
    return snapshot.getLoadable(commentSelector(commentId)).getValue();
  });
}

export const commentsForThreadSelector = selectorFamily({
  key: 'CommentsForThread',
  get:
    (commentThreadId: string | undefined | null) =>
    ({ get }) => {
      if (!commentThreadId) {
        return null;
      }

      const commentIds = get(indexKeyState(indexKey(commentsByThread, commentThreadId)));
      const comments = filterNotNull(commentIds.map(commentId => get(commentSelector(commentId))));
      const [nonReplies, replies] = partition(comments, comment => !comment.reply);
      return [...nonReplies, ...replies];
    },
});

export const allCommentsForThreadDeletedSelector = selectorFamily({
  key: 'AllCommentsForThreadDeleted',
  get:
    (commentThreadId: string | undefined | null) =>
    ({ get }) => {
      return get(commentsForThreadSelector(commentThreadId))?.every(comment => comment.deleted);
    },
});

export const threadIdForCommentSelector = selectorFamily({
  key: 'ThreadIdForComment',
  get:
    (commentId: string | undefined | null) =>
    ({ get }) => {
      if (!commentId) {
        return;
      }
      const comment = get(commentSelector(commentId));
      return comment?.threadId;
    },
});

export const threadIdsForCommentsSelector = equalSelectorFamily({
  key: 'ThreadIdsForComments',
  get:
    (commentIds: string[]) =>
    ({ get }) => {
      return filterNotNull(commentIds.map(commentId => get(threadIdForCommentSelector(commentId))));
    },
  equals: isEqual,
});

export const entityIdForCommentThreadSelector = selectorFamily({
  key: 'EntityIdForCommentThread',
  get:
    (commentThreadId: string | undefined | null) =>
    ({ get }) => {
      if (!commentThreadId) {
        return null;
      }

      const comments = get(commentsForThreadSelector(commentThreadId));
      if (!comments) {
        return null;
      }
      return comments[0]?.entityId;
    },
});

export function useCommentUrl() {
  const entityPath = useEntityPath();
  const { host } = useConfiguration();

  return (comment: Comment, forceThread = false) => {
    const path = entityPath(comment.entityId);
    const thread =
      comment.reply || forceThread ? `&${COMMENT_THREAD_ID_QUERY_PARAM}=${comment.threadId}` : '';
    return `${host}${path}?${COMMENT_ID_QUERY_PARAM}=${comment.id}${thread}`;
  };
}
