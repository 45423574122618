import * as React from 'react';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { TextInput } from '../components/new/textInput';
import { Modals, NewFolderArgs, useModals } from '../contexts/modalContext';
import { useCreateFolder } from '../syncEngine/actions/folders';
import styles from './newFolderModal.module.scss';

function NewFolderModalContents({
  parentId,
  onClose,
}: {
  parentId?: string | null;
  onClose: () => void;
}) {
  const createFolder = useCreateFolder();
  const [name, setName] = React.useState('');

  function submit() {
    createFolder(name, parentId);
    onClose();
  }

  return (
    <form
      className={styles.form}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        if (name !== '') {
          submit();
        }
      }}
    >
      <ModalContentWrapper>
        <div className={styles.inputGroup}>
          <span className="oneLine headingS">Folder name</span>
          <TextInput
            autoFocus
            maxLength={254}
            placeholder="e.g. Notes"
            onKeyDown={e => {
              if (e.key === '/') {
                e.preventDefault();
              }
            }}
            onChange={e => {
              const val = e.currentTarget.value;
              setName(val.replace(/\//g, ''));
            }}
          />
        </div>
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <Button type="button" buttonStyle={ButtonStyle.Secondary} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button type="submit" buttonStyle={ButtonStyle.Primary} disabled={!name}>
          Create folder
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export function NewFolderModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as NewFolderArgs) : null;

  return (
    <Modal modalId={Modals.NewFolder} title="Create folder">
      <NewFolderModalContents {...args} onClose={() => modalManager.closeModal(Modals.NewFolder)} />
    </Modal>
  );
}
