import { emptyDocument } from '../../../shared/slate/utils';
import { insightEntities } from '../../../sync/__generated/collections';
import { Insight } from '../../../sync/__generated/models';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';

export function useCreateInsight() {
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (feedbackId: string, contents?: string): Insight => {
    return modelManager.transaction(tx => {
      const insight: SyncEngineCreate<Insight> = {
        __typename: 'Insight',
        entityIds: [],
        feedbackId,
        contents: contents ?? JSON.stringify(emptyDocument()),
        orphaned: false,
        actorId: user.id,
      };
      const result = tx.create<Insight>(insight);
      trackerEvent('Insight Created', {
        id: result.id,
      });
      return result;
    });
  };
}

export function useUpdateInsights() {
  const modelManager = useModelManager();
  return (insightIds: string[], update: SyncEngineUpdateWithoutDelete<Insight>) => {
    modelManager.transaction((tx, { get }) => {
      for (const insightId of insightIds) {
        const insight = get<Insight>(insightId);
        if (!insight || insight.deleted) {
          continue;
        }
        tx.update<Insight>(insightId, update);
      }
    });
  };
}

export function deleteInsightHelper(
  tx: SyncEngineTransaction,
  { get }: SyncEngineGetters,
  insightId: string
) {
  const insight = get<Insight>(insightId);
  if (!insight || insight.deleted) {
    return false;
  }

  tx.removeFromCollection(insightEntities, insightId, insight.entityIds);
  tx.update<Insight>(insightId, {
    deleted: true,
  });
  return true;
}

export function useDeleteInsightIfEmpty() {
  const modelManager = useModelManager();
  return (insightId: string) => {
    return modelManager.transaction((tx, { get }) => {
      const insight = get<Insight>(insightId);
      if (!insight || insight.deleted) {
        return true;
      }

      if (insight.entityIds.length) {
        return false;
      }

      tx.update<Insight>(insightId, {
        deleted: true,
      });

      trackerEvent('Insight Deleted', {
        id: insight.id,
      });

      return true;
    });
  };
}

export function useAddEntitiesToInsights() {
  const modelManager = useModelManager();
  return (insightIds: string[], entityIds: string[]) => {
    for (const insightId of insightIds) {
      modelManager.addToCollection(insightEntities, insightId, entityIds);
      trackerEvent('Insight Updated', {
        id: insightId,
        entityIds,
        type: 'Entity Added',
      });
    }
  };
}

export function useRemoveEntitiesFromInsights() {
  const modelManager = useModelManager();
  return (insightIds: string[], entityIds: string[]) => {
    for (const insightId of insightIds) {
      modelManager.removeFromCollection(insightEntities, insightId, entityIds);
      trackerEvent('Insight Updated', {
        id: insightId,
        entityIds,
        type: 'Entity Removed',
      });
    }
  };
}
