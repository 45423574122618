import { orderBy, partition } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { IssueLabel } from '../../../../sync/__generated/models';
import ExternalLink from '../../../components/externalLink';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../../components/new/button';
import { ColorPicker } from '../../../components/new/colorPicker';
import Label from '../../../components/new/metadata/label';
import { MetadataSize } from '../../../components/new/metadata/size';
import { SettingsListItem, SettingsPage, SettingsSection } from '../../../components/new/settings';
import { TextInput, TextInputSize } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import { useSpace } from '../../../contexts/spaceContext';
import {
  useCreateLabel,
  useDeleteLabels,
  useUpdateLabels,
} from '../../../syncEngine/actions/labels';
import { labelsForSpaceSelector } from '../../../syncEngine/selectors/labels';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import styles from './settingsScreen.module.scss';

function LabelListItem({
  label,
  editing,
  onEdit,
  isFirst,
  isLast,
}: {
  label: IssueLabel;
  editing: boolean;
  onEdit: (label: IssueLabel, action: 'edit' | 'save' | 'cancel') => void;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const deleteLabels = useDeleteLabels();
  const updateLabels = useUpdateLabels();

  const [update, setUpdate] = React.useState<{ name: string; color: string }>({
    name: label.name,
    color: label.color,
  });
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);

  if (editing) {
    return (
      <SettingsListItem
        isFirst={isFirst}
        isLast={isLast}
        onClickOutside={() => {
          if (!colorPickerOpen) {
            onEdit(label, 'cancel');
          }
        }}
      >
        <ColorPicker
          initialColor={update.color}
          onColorPicked={c => setUpdate({ ...update, color: c ?? 'gray' })}
          onOpenChanged={setColorPickerOpen}
          renderPreview={(c, forceDarkMode) => (
            <Label
              color={c}
              name={'Preview Label'}
              size={MetadataSize.Medium}
              forceDarkMode={forceDarkMode}
            />
          )}
        />
        <TextInput
          inputSize={TextInputSize.Small}
          className="grow mr8 ml8"
          value={update.name}
          autoFocus={true}
          onChange={e => setUpdate({ ...update, name: e.currentTarget.value })}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
              e.stopPropagation();
              updateLabels([label.id], update);
              onEdit(label, 'save');
              return;
            }
            if (e.key.toLowerCase() === 'escape') {
              onEdit(label, 'cancel');
            }
          }}
        />
        <Button
          className="mr8"
          size={ButtonSize.Small}
          onClick={() => {
            onEdit(label, 'cancel');
          }}
        >
          Cancel
        </Button>
        <Button
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.Primary}
          onClick={() => {
            updateLabels([label.id], update);
            onEdit(label, 'save');
          }}
        >
          Save
        </Button>
      </SettingsListItem>
    );
  }

  return (
    <SettingsListItem
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onEdit(label, 'edit');
      }}
      isFirst={isFirst}
      isLast={isLast}
      meta={
        <>
          <Tooltip content="Edit">
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              icon="edit"
              onClick={() => onEdit(label, 'edit')}
            />
          </Tooltip>
          <Tooltip content="Delete">
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              className={styles.deleteIcon}
              icon="delete"
              onClick={() => deleteLabels([label.id])}
            />
          </Tooltip>
        </>
      }
    >
      <Label
        className={styles.label}
        color={label.color}
        name={label.name}
        size={MetadataSize.Medium}
      />
    </SettingsListItem>
  );
}

function newlyCreatedFirst(labels: IssueLabel[], newlyCreatedId: string | null): IssueLabel[] {
  const [newlyCreated, rest] = partition(orderBy(labels, 'name'), l => l.id === newlyCreatedId);
  return [...newlyCreated, ...rest];
}

export function LabelSettingsScreen() {
  const space = useSpace();
  const createLabel = useCreateLabel();
  const deleteLabels = useDeleteLabels();

  const allLabels = useRecoilValue(labelsForSpaceSelector(space.id));
  const [labels, setLabels] = React.useState(allLabels);
  const [searchString, setSearchString] = React.useState('');
  const [newlyCreated, setNewlyCreated] = React.useState<string | null>(null);
  const [currentlyEditing, setCurrentlyEditing] = React.useState<string | null>(null);

  const search = React.useMemo(() => {
    return new FuzzySearcher(FuzzySearcherConfiguration.Autocomplete, ['name'], allLabels);
  }, [allLabels]);

  React.useEffect(() => {
    if (!searchString) {
      setLabels(newlyCreatedFirst(allLabels, newlyCreated));
      return;
    }

    setLabels(
      newlyCreatedFirst(
        search.search(searchString).map(i => i.item),
        newlyCreated
      )
    );
  }, [search, allLabels, searchString, newlyCreated]);

  return (
    <SettingsPage
      title="Labels"
      description={
        <>
          <p>
            Labels allow you to categorize work items in a freeform fashion. Typically teams will
            classify the type of work item (say, “Bug”, “Experiment”, “Customer request”, etc.), or
            categorize initiatives by the objective they address.
          </p>
          <p>
            <ExternalLink className="link" href="https://guide.kitemaker.co/overview/labels">
              Read more in the guide
            </ExternalLink>
          </p>
        </>
      }
    >
      <SettingsSection>
        <div className="rowStretch mb16">
          <Button
            className="mr16"
            buttonStyle={ButtonStyle.Primary}
            icon="add"
            onClick={() => {
              const { id } = createLabel(space.id, '');
              setCurrentlyEditing(id);
              setNewlyCreated(id);
            }}
          >
            New label
          </Button>
          <TextInput
            className={styles.searchInput}
            value={searchString}
            onChange={v => setSearchString(v.currentTarget.value)}
            placeholder="Filter labels"
          />
        </div>

        {labels.map((label, index) => (
          <LabelListItem
            key={label.id}
            isFirst={index === 0}
            isLast={index === labels.length - 1}
            label={label}
            editing={currentlyEditing === label.id}
            onEdit={(l, action) => {
              if (action === 'edit') {
                setCurrentlyEditing(l.id);
              } else {
                if (newlyCreated && action === 'cancel') {
                  deleteLabels([newlyCreated], true);
                }
                setNewlyCreated(null);
                setCurrentlyEditing(null);
              }
            }}
          />
        ))}
      </SettingsSection>
    </SettingsPage>
  );
}
