import React from 'react';
import { useRecoilValue } from 'recoil';
import { Issue } from '../../../../sync/__generated/models';
import {
  StatusColumnPicker,
  entitiesToStatusColumnPickerState,
} from '../../../components/new/pickers/statusColumnPicker';
import { useMoveIssues } from '../../../syncEngine/actions/issues';
import { entitiesSelector } from '../../../syncEngine/selectors/entities';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import styles from '../commandMenu.module.scss';

export function ChangeStatusContents({
  onClose,
  entityIds,
  spaceId,
}: {
  onClose: () => void;
  entityIds: string[];
  spaceId?: string | null;
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const moveIssues = useMoveIssues();

  const entities = useRecoilValue(entitiesSelector(entityIds));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <StatusColumnPicker
        filterClassName={styles.inputDiv}
        space={space}
        state={entitiesToStatusColumnPickerState(entities as Issue[])}
        onPicked={(issueIds, statusId) => {
          moveIssues(issueIds, statusId);
        }}
        onDone={onClose}
      />
    </div>
  );
}
