import React from 'react';
import { useRecoilValue } from 'recoil';
import { DependencyType, Issue } from '../../../../sync/__generated/models';
import { ListView, NO_KEYNAV } from '../../../components/new/listView';
import styles from '../../../components/new/menu/menu.module.scss';
import DependencyPickerWrapper from '../../../components/new/pickers/dependencyPicker';
import {
  useAddDependenciesToIssues,
  useRemoveDependenciesFromIssues,
} from '../../../syncEngine/actions/issues';
import { entitiesSelector } from '../../../syncEngine/selectors/entities';
import { spaceSelector } from '../../../syncEngine/selectors/spaces';
import commandMenuStyles from '../commandMenu.module.scss';

function calculateDependencyType(
  type: DependencyType,
  property: 'dependsOnId' | 'enablesId'
): 'enables' | 'dependsOn' | 'blocks' | 'blockedBy' {
  if (type === DependencyType.DependsOn) {
    if (property === 'enablesId') {
      return 'dependsOn';
    }
    return 'enables';
  }

  if (property === 'enablesId') {
    return 'blockedBy';
  }
  return 'blocks';
}

export function ChangeDependenciesContents({
  onClose,
  entityIds,
  type: providedType,
  property: providedProperty,
  spaceId,
}: {
  onClose: () => void;
  entityIds: string[];
  spaceId: string;
  type?: DependencyType;
  property?: 'dependsOnId' | 'enablesId';
}) {
  const space = useRecoilValue(spaceSelector(spaceId));
  const [type, setType] = React.useState(providedType ?? null);
  const [property, setProperty] = React.useState(providedProperty ?? null);
  const issues = useRecoilValue(entitiesSelector(entityIds)) as Issue[];
  const addDepenencies = useAddDependenciesToIssues();
  const removeDependencies = useRemoveDependenciesFromIssues();

  if (type && property) {
    return (
      <DependencyPickerWrapper
        filterClassName={commandMenuStyles.inputDiv}
        space={space ?? undefined}
        issues={issues}
        dependencyType={calculateDependencyType(type, property)}
        onDependenciesAdded={(issueIds, toAdd) => {
          addDepenencies(issueIds, property, type, [toAdd]);
        }}
        onDependenciesRemoved={(issueIds, toRemove) => {
          removeDependencies(issueIds, property, type, [toRemove]);
        }}
        onDone={onClose}
      />
    );
  }

  return (
    <div className="rowStretch fullWidth fullHeight" key="status">
      <ListView
        selectFirstOnItemsChanged
        className={styles.content}
        itemClassName={styles.item}
        items={[
          {
            id: NO_KEYNAV,
            key: 'header',
            contents: <div className={styles.listItemHeader}>Add dependencies</div>,
          },
          {
            id: 'enables',
            contents: 'Enables',
            hotkey: 'e',
            icon: 'dependency_enables',
            onSelected: () => {
              setType(DependencyType.DependsOn);
              setProperty('dependsOnId');
            },
          },
          {
            id: 'dependsOn',
            contents: 'Depends on',
            hotkey: 'd',
            icon: 'dependency_depends',
            onSelected: () => {
              setType(DependencyType.DependsOn);
              setProperty('enablesId');
            },
          },
          {
            id: 'blocks',
            contents: 'Blocks',
            hotkey: 'b',
            icon: 'dependency_blocking',
            onSelected: () => {
              setType(DependencyType.BlockedBy);
              setProperty('dependsOnId');
            },
          },
          {
            id: 'blockedBy',
            contents: 'Blocked by',
            hotkey: 'x',
            icon: 'dependency_blocked',
            onSelected: () => {
              setType(DependencyType.BlockedBy);
              setProperty('enablesId');
            },
          },
        ]}
      />
    </div>
  );
}
