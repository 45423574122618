import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { KITEMAKER_ACTOR_ID, ONBOARDING_ACTOR_ID } from '../../../shared/actors';
import { IntegrationType } from '../../../sync/__generated/models';
import sigurdAvatar from '../../assets/images/new/avatars/sigurd.png?url';
import logoDark from '../../assets/images/new/logo-on_dark.svg?url';
import logoLight from '../../assets/images/new/logo-on_white.svg?url';
import { useDarkMode } from '../../hooks/useDarkMode';
import { actorsSelector } from '../../syncEngine/selectors/actors';
import { stringifyIntegrationType } from '../../utils/integrations';
import { Icon } from './icon';
import { iconForIntegrationType } from './integrationIcon';
import Avatar, { AvatarSize } from './metadata/avatar';

function IntegrationAvatar({
  size,
  type,
  className,
  style,
}: {
  size?: AvatarSize;
  type: IntegrationType;
  className?: string;
  style?: React.CSSProperties;
}) {
  const icon = iconForIntegrationType(type);
  if (icon) {
    return (
      <div className={className} style={style}>
        <Icon icon={icon} width={size} height={size} />
      </div>
    );
  }

  return (
    <Avatar size={size} name={stringifyIntegrationType(type)} className={className} style={style} />
  );
}

export function KitemakerActorAvatar({
  size,
  className,
  style,
}: {
  size?: AvatarSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  const { darkMode } = useDarkMode();
  return (
    <div className={className} style={style}>
      <img width={size} height={size} src={darkMode ? logoDark : logoLight} />
    </div>
  );
}

export function ActorAvatar({
  actorId,
  size = AvatarSize.Size20,
  className,
  style,
}: {
  actorId: string;
  size?: AvatarSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  const actor = useRecoilValue(actorsSelector(actorId));
  if (actorId === KITEMAKER_ACTOR_ID) {
    return <KitemakerActorAvatar size={size} className={className} style={style} />;
  }
  if (actorId === ONBOARDING_ACTOR_ID) {
    return (
      <Avatar
        size={size}
        name={'Sigurd from Kitemaker'}
        img={sigurdAvatar}
        className={className}
        style={style}
      />
    );
  }

  if (!actor) {
    return null;
  }

  switch (actor.__typename) {
    case 'User':
      return (
        <Avatar
          size={size}
          name={actor.name ?? actor.username}
          img={actor.avatar}
          className={className}
          style={style}
        />
      );
    case 'Integration':
      return (
        <IntegrationAvatar size={size} type={actor.type} className={className} style={style} />
      );
    case 'IntegrationUser':
      return (
        <IntegrationAvatar size={size} type={actor.type} className={className} style={style} />
      );
    case 'Application':
      return (
        <Avatar
          size={size}
          name={actor.name}
          img={actor.avatar}
          className={className}
          style={style}
        />
      );
  }
}
