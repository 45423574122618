import { uniq } from 'lodash';
import { atom, selectorFamily } from 'recoil';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { companiesByOrganization } from '../../../sync/__generated/indexes';
import { Company, Insight } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { feedbackSelector } from './feedback';
import { insightsForEntity } from './insights';
import { personSelector } from './people';

export const TEMP_COMPANY_ID = 'tempCompany';

export const tempCompanyState = atom<Company | null>({
  key: 'TempCompany',
  default: null,
  effects: [],
});

export const companySelector = selectorFamily({
  key: 'company',
  get:
    (companyId: string | undefined | null) =>
    ({ get }) => {
      if (!companyId) {
        return null;
      } else if (companyId === TEMP_COMPANY_ID) {
        return get(tempCompanyState) as Company;
      }
      return get(syncEngineState(companyId)) as Company;
    },
});

export const companiesSelector = selectorFamily({
  key: 'companies',
  get:
    (companyIds: string[]) =>
    ({ get }) => {
      return filterNotNull(companyIds.map(companyId => get(companySelector(companyId))));
    },
});

export const companyForFeedbackSelector = selectorFamily({
  key: 'companyForFeedback',
  get:
    (feedbackId: string | undefined | null) =>
    ({ get }) => {
      if (!feedbackId) {
        return null;
      }
      const feedback = get(feedbackSelector(feedbackId));
      if (!feedback) {
        return null;
      }

      if (feedback.personId) {
        const person = get(personSelector(feedback.personId));
        return get(companySelector(person?.companyId));
      }
      return get(companySelector(feedback.companyId));
    },
});

export const companiesForOrganizationSelector = selectorFamily({
  key: 'companyForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const companyIds = get(indexKeyState(indexKey(companiesByOrganization, organizationId)));
      return filterNotDeletedNotNull(companyIds.map(companyId => get(companySelector(companyId))));
    },
});

export const companyCountForOrganization = selectorFamily({
  key: 'companyCountForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const company = get(companiesForOrganizationSelector(organizationId));
      return company.length;
    },
});

export const companyIdsForEntitySelector = selectorFamily({
  key: 'CompanyIdsForEntity',
  get:
    (entityId: string | undefined | null) =>
    ({ get }) => {
      if (!entityId) {
        return null;
      }
      const insights = get(insightsForEntity(entityId)).filter(i => !!i) as Insight[];
      const feedback = insights.map(i => get(feedbackSelector(i.feedbackId)));
      return uniq(filterNotNull(feedback.map(f => f?.companyId)));
    },
});
