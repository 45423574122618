import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Text } from 'slate';
import { RenderLeafProps, useSlate } from 'slate-react';
import { notNull } from '../../shared/utils/convenience';
import { useClient } from '../contexts/clientContext';
import { userSelector } from '../syncEngine/selectors/users';
import { cursorDelay } from '../utils/config';
import { useFocusedAndSelected } from './hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from './hooks/useSelectionCollapsed';
import { ExternalIssueLinkHover } from './hovers/externalIssueLinkHover';
import { FigmaLinkHover } from './hovers/figmaLinkHover';
import { GithubLinkHover } from './hovers/githubLinkHover';
import { LoomLinkHover } from './hovers/loomLinkHover';
import styles from './renderLeaf.module.scss';
import { OptionalAttributesRenderLeafProps } from './types';

function LoomLink({ children, attributes }: { children: any; attributes: any }) {
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();

  if (!selected || !selectionCollapsed) {
    return <span {...attributes}>{children}</span>;
  }

  return (
    <>
      <LoomLinkHover />
      <span {...attributes}>{children}</span>
    </>
  );
}

function GithubLink({ children, attributes }: { children: any; attributes: any }) {
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();

  if (!selected || !selectionCollapsed) {
    return <span {...attributes}>{children}</span>;
  }

  return (
    <>
      <GithubLinkHover />
      <span {...attributes}>{children}</span>
    </>
  );
}

function FigmaLink({ children, attributes }: { children: any; attributes: any }) {
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();

  if (!selected || !selectionCollapsed) {
    return <span {...attributes}>{children}</span>;
  }

  return (
    <>
      <FigmaLinkHover />
      <span {...attributes}>{children}</span>
    </>
  );
}
function ExternalIssueLink({ children, attributes }: { children: any; attributes: any }) {
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();

  if (!selected || !selectionCollapsed) {
    return <span {...attributes}>{children}</span>;
  }

  return (
    <>
      <ExternalIssueLinkHover />
      <span {...attributes}>{children}</span>
    </>
  );
}

function Cursor({ children, leaf, attributes }: { children: any; leaf: Text; attributes: any }) {
  const cursorLocation = notNull(leaf.cursorLocation);
  const actorId = cursorLocation!.actorId;
  const editor = useSlate();
  const user = useRecoilValue(userSelector(actorId));
  const client = useClient();
  const cursorTimeoutId = React.useRef<any>(null);

  const [showCursor, setShowCursor] = React.useState(false);

  // Hacky piece of code to stop cursors resetting timers when nodes get split/merged
  React.useEffect(() => {
    if (Date.now() - cursorLocation.time < cursorDelay) {
      clearTimeout(cursorTimeoutId.current);
      setShowCursor(true);
      cursorTimeoutId.current = setTimeout(() => setShowCursor(false), cursorDelay);
      return () => {
        clearTimeout(cursorTimeoutId.current);
      };
    }
    return;
  }, [cursorLocation.time]);

  if (
    cursorLocation.clientId === client ||
    cursorLocation.time !== editor.cursors()[cursorLocation.clientId]?.time
  ) {
    return <span {...attributes}>{children}</span>;
  }

  const color = editor.getColor(actorId);
  return (
    <>
      <span {...attributes}>
        {children}
        {showCursor && (
          <div
            className={cn(styles.cursor, {
              [styles.empty]: cursorLocation.empty,
            })}
            contentEditable={false}
          >
            <div
              className={styles.text}
              style={{
                background: `var(--${color}11)`,
              }}
            >
              {user && user.username}
            </div>
            <div className={styles.body} style={{ borderColor: `var(--${color}11)` }} />
          </div>
        )}
      </span>
    </>
  );
}

export function StaticLeaf({ leaf, children, attributes }: OptionalAttributesRenderLeafProps) {
  if (leaf.colorPreview) {
    return (
      <span {...attributes}>
        {children}
        <span
          className={styles.colorPreview}
          contentEditable={false}
          style={{
            backgroundColor: leaf.colorPreview,
          }}
        />
      </span>
    );
  }

  let content = children;

  if (leaf.bigEmoji) {
    return (
      <span {...attributes} className={styles.bigEmoji}>
        {content}
      </span>
    );
  }

  if (leaf.emojiFont) {
    content = (
      <span className={styles.emojiWrapper}>
        <span className={styles.emoji}>{content}</span>
      </span>
    );
  }

  if (leaf.bold) {
    content = <strong className={styles.bold}>{content}</strong>;
  }
  if (leaf.italic) {
    content = <em className={styles.italic}>{content}</em>;
  }
  if (leaf.underline) {
    content = <u className={styles.underline}>{content}</u>;
  }
  if (leaf.strikethrough) {
    content = <s className={styles.strikethrough}>{content}</s>;
  }
  if (leaf.code) {
    content = <span className={styles.code}>{content}</span>;
  }
  if (leaf.fakeSelection) {
    content = <span className={styles.fakeSelection}>{content}</span>;
  }
  if (leaf.color) {
    const color = `var(--${leaf.color}11)`;
    content = <span style={{ color, textDecorationColor: color }}>{content}</span>;
  }

  return <span {...attributes}>{content}</span>;
}

export function RenderLeaf({ attributes, children, leaf, text }: RenderLeafProps) {
  if (leaf.loomLink) {
    return <LoomLink attributes={attributes}>{children}</LoomLink>;
  }

  if (leaf.figmaLink) {
    return <FigmaLink attributes={attributes}>{children}</FigmaLink>;
  }

  if (leaf.githubLink) {
    return <GithubLink attributes={attributes}>{children}</GithubLink>;
  }

  if (leaf.externalIssueLink) {
    return <ExternalIssueLink attributes={attributes}>{children}</ExternalIssueLink>;
  }

  if (leaf.syntaxType) {
    return (
      <span {...attributes} className={`token ${leaf.syntaxType}`}>
        {children}
      </span>
    );
  }

  if (leaf.cursorLocation?.enabled) {
    return (
      <Cursor leaf={leaf} attributes={attributes}>
        {children}
      </Cursor>
    );
  }

  return (
    <StaticLeaf leaf={leaf} attributes={attributes} text={text}>
      {children}
    </StaticLeaf>
  );
}
