import { selectorFamily } from 'recoil';
import { filterNotDeleted } from '../../../shared/utils/convenience';
import {
  organizationMembersByUser,
  personalAccessTokensByOrganizationMember,
} from '../../../sync/__generated/indexes';
import { OrganizationMember, PersonalAccessToken } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { currentUserState } from './users';

export const patsForCurrentUserSelector = selectorFamily({
  key: 'CurrentUserPersonalAccessTokens',
  get: (organizationId: string) => ({ get }) => {
    const user = get(currentUserState);
    if (!user) {
      return [];
    }
    const organizationMemberIds = get(indexKeyState(indexKey(organizationMembersByUser, user.id)));

    const organizationMemberId = organizationMemberIds.find(id => {
      const organizationMember = get(syncEngineState(id)) as OrganizationMember;
      return organizationMember.organizationId === organizationId;
    });

    if (!organizationMemberId) {
      return [];
    }

    const patIds = get(
      indexKeyState(indexKey(personalAccessTokensByOrganizationMember, organizationMemberId))
    ) as string[];
    return filterNotDeleted(
      patIds.map(patId => get(syncEngineState(patId)) as PersonalAccessToken)
    );
  },
});

export const patSelector = selectorFamily({
  key: 'PersonalAccessTokens',
  get: (patId: string) => ({ get }) => {
    return get(syncEngineState(patId)) as PersonalAccessToken;
  },
});
