import { Node, NodeEntry, Range, Path, Text } from 'slate';
import { CustomRange } from '../../../shared/slate/types';
import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';

export function cursorLocationDecorations(editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [node, path] = entry;
  if (!path.length) {
    return [];
  }
  const cursors: CustomRange[] = [];

  for (const point of Object.values(editor.cursors())) {
    if (Path.equals(path, point.path)) {
      let empty = Text.isText(node) && node.text.length === 0;
      if (empty) {
        const parent = KitemakerEditor.parent(editor, path);
        if (parent) {
          const [parentNode] = parent;
          if (parentNode.children.length > 1) {
            empty = false;
          }
        } else {
          empty = false;
        }
      }
      cursors.push({
        anchor: point,
        focus: { ...point, offset: point.offset + 1 },
        cursorLocation: {
          enabled: true,
          actorId: point.actorId,
          time: point.time,
          empty: empty,
          clientId: point.clientId,
        },
      });
    }
  }

  return cursors;
}
