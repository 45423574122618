import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Screen } from '../../../components/screen';
import { useOrganization } from '../../../contexts/organizationContext';
import { FetchedMarker, OrganizationMarker } from '../../../graphql/smartLoad';
import {
  entityDeletedSelector,
  entityExistsSelector,
} from '../../../syncEngine/selectors/entities';
import { feedbackIdByNumberSelector } from '../../../syncEngine/selectors/feedback';
import { markerState } from '../../../syncEngine/selectors/smartLoader';
import { NotFoundScreen } from '../../errorScreens';
import { DeletedScreen } from '../../errorScreens/deletedScreen';
import LoadingScreen from '../../loadingScreen';
import FeedbackDetailScreen from './feedbackDetailScreen';

interface RouteParams {
  feedbackNumber: string;
}

export default function FeedbackDetailScreenWrapper(props: RouteComponentProps<RouteParams>) {
  const { match } = props;
  const organization = useOrganization();

  const { feedbackNumber: feedbackNumberOrId } = match.params;

  const feedbackId = useRecoilValue(
    feedbackIdByNumberSelector({
      organizationId: organization.id,
      feedbackNumber: feedbackNumberOrId,
    })
  );
  const feedbackIdRef = React.useRef(feedbackId);

  const lastKnownGoodId = feedbackId ?? feedbackIdRef.current;

  const entityDeleted = useRecoilValue(entityDeletedSelector(lastKnownGoodId));
  const entityExists = useRecoilValue(entityExistsSelector(lastKnownGoodId));

  const currentOrgPartiallyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, true))
  );
  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );
  const fetched = useRecoilValue(markerState(FetchedMarker.id));

  if (lastKnownGoodId && entityDeleted) {
    return (
      <Screen>
        <DeletedScreen type={'Feedback'} />
      </Screen>
    );
  }

  if (!lastKnownGoodId || !entityExists || !currentOrgPartiallyFetched) {
    if (!currentOrgPartiallyFetched || !currentOrgFullyFetched || !fetched) {
      return <LoadingScreen />;
    }

    return (
      <Screen>
        <NotFoundScreen />
      </Screen>
    );
  }

  return <FeedbackDetailScreen feedbackId={lastKnownGoodId} />;
}
