import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Activity as ActivityModel } from '../../../../sync/__generated/models';
import { roadmapsChangedForActivitySelector } from '../../../syncEngine/selectors/activities';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function RoadmapsChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const { added, removed } = useRecoilValue(roadmapsChangedForActivitySelector(activity.id));

  let addedRoadmaps = added.length > 0 ? 'added to roadmap' : '';
  if (added.length > 1) {
    addedRoadmaps += 's';
  }

  let removedRoadmaps = removed.length > 0 ? 'removed from roadmap' : '';
  if (removed.length > 1) {
    removedRoadmaps += 's';
  }

  const and = added.length && removed.length ? ' and ' : '';

  return (
    <Activity activity={activity}>
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} />{' '}
      {added.length > 0 && (
        <>
          {addedRoadmaps} <span className={styles.activityHighlight}>{added.join(', ')}</span>
          {and}
        </>
      )}
      {removed.length > 0 && (
        <>
          {removedRoadmaps} <span className={styles.activityHighlight}>{removed.join(', ')}</span>
        </>
      )}
      .<ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const RoadmapsChangedActivity = React.memo(RoadmapsChangedActivityComponent);
