import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { CommandGroup, CommandGroupContext, useRegisterContext } from '../../commands/state';
import { areEntitiesSelector, isEntitySelector } from '../../syncEngine/selectors/entities';
import { useKeyNavigationState } from './keyNavigation';

export function CommandContext<T extends CommandGroupContext>({ context }: { context: T }) {
  const { registerContext, unregisterContext } = useRegisterContext();

  React.useEffect(() => {
    registerContext(context);
    return () => unregisterContext(context);
  });
  return null;
}

export function EntityCommandContext() {
  const { focused, selected } = useKeyNavigationState();
  const rawIds = selected ?? (focused ? [focused] : []);
  const entityIds = rawIds.map(id => id.replace('cycle-', '')).filter(id => !id.includes('-'));

  const filterEntityIds = useRecoilValue(areEntitiesSelector(entityIds));
  const nonEntityIds = rawIds.filter(id => !filterEntityIds.includes(id));
  const focusedIsEntity = useRecoilValue(isEntitySelector(focused));

  return (
    <CommandContext
      context={{
        group: CommandGroup.Entities,
        entityIds: filterEntityIds,
        focusedEntityId: focusedIsEntity ? focused : undefined,
        nonEntityIds,
      }}
    />
  );
}
