import * as Sentry from '@sentry/browser';
import { max, sortBy } from 'lodash';
import Mousetrap from 'mousetrap';
import * as React from 'react';
import { atom, useRecoilState } from 'recoil';
import { HotkeyCommand, hotkeyContext, KeyHold } from '../contexts/hotkeyContext';
import { trackerEvent } from '../tracker';
import { isMobileOS } from '../utils/config';
import '../utils/mousetrapGlobal';

export const SEQUENCE_TIMEOUT = 1000;
export const hotkeysInitiallyDisabledAtom = atom({
  key: 'HotKeysInitiallyDisabled',
  default: isMobileOS,
});

interface HotkeyCommandWithScopeDepth extends HotkeyCommand {
  depth: number;
}

type HotkeyMap = { [index: string]: HotkeyCommandWithScopeDepth[] };
const hotkeys: HotkeyMap = {};
const mousetrapBindings: Record<string, HotkeyCommandWithScopeDepth[]> = {};

const heldKeys = new Set<string>();
let heldKeyScopeDepth: number | null = null;

const heldHanders: { [index: string]: HotkeyCommand } = {};

function clearHeldKeys() {
  heldKeys.clear();
  Object.values(heldHanders).forEach(handler => {
    delete heldHanders[handler.id];
    handler.handler(undefined, KeyHold.Release);
  });
}

document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    clearHeldKeys();
  }
});

window.addEventListener('blur', () => {
  clearHeldKeys();
});

function filterIfInInput(e: KeyboardEvent, command: HotkeyCommand) {
  if (command.global) {
    return false;
  }

  const element = e.target as HTMLElement;
  return (
    element.tagName == 'INPUT' ||
    element.tagName == 'SELECT' ||
    element.tagName == 'TEXTAREA' ||
    (element.contentEditable && element.contentEditable == 'true')
  );
}

function trackHotkey(command: HotkeyCommand) {
  trackerEvent('Hotkey', {
    hotkey_id: command.id,
    keys: command.hotkey,
    description: command.description,
  });
}

let ongoingSequence: string[] | null = null;
let sequenceTimeout = -1;

function findViableSequence(key: string): string[] | null {
  const allSequences = Object.keys(hotkeys).filter(hotkey => hotkey.includes(' '));
  let maybeSequence = [...(ongoingSequence ?? []), key];

  while (maybeSequence.length) {
    const sequence = maybeSequence.join(' ');
    if (allSequences.find(s => s.startsWith(sequence))) {
      return maybeSequence;
    }
    maybeSequence = maybeSequence.slice(1);
  }
  return null;
}

function handlePress(e: KeyboardEvent, rawHotkey: string) {
  let hotkey = rawHotkey;
  if (sequenceTimeout) {
    window.clearTimeout(sequenceTimeout);
    sequenceTimeout = -1;
  }

  ongoingSequence = findViableSequence(rawHotkey);
  if (ongoingSequence?.length) {
    hotkey = ongoingSequence.join(' ');
    // if we have a viable sequence but no hotkeys, it means we need to wait for more
    // keystrokes to see if a valid sequence finishes
    if (!hotkeys[hotkey]) {
      sequenceTimeout = window.setTimeout(() => {
        ongoingSequence = null;
      }, SEQUENCE_TIMEOUT);
      return;
    }
  }

  const maxDepth = max(Object.values(hotkeys).flatMap(keys => keys.map(key => key.depth))) ?? 0;

  let track = false;
  if (!heldKeys.has(hotkey)) {
    track = true;
  }
  heldKeys.add(hotkey);
  heldKeyScopeDepth = maxDepth;

  const hotkeyCommands = hotkeys[hotkey] ?? [];
  const scopedCommands = hotkeyCommands.filter(c => c.depth === maxDepth || c.unscoped);

  if (!scopedCommands.length) {
    return;
  }

  const sortedCommands = sortBy(scopedCommands, c => -1 * (c.priority ?? Number.MAX_SAFE_INTEGER));
  const hotkeyCommand = sortedCommands[sortedCommands.length - 1];

  if (filterIfInInput(e, hotkeyCommand)) {
    return;
  }

  if (track) {
    trackHotkey(hotkeyCommand);
  }

  hotkeyCommand.handler(e, KeyHold.Press);
}

function handleUp(e: KeyboardEvent, hotkey: string) {
  const hotkeyComponents = new Set(hotkey.split('+'));
  for (const heldKey of heldKeys) {
    const heldKeyComponents = heldKey.split('+');
    if (heldKeyComponents.every(c => hotkeyComponents.has(c))) {
      heldKeys.delete(heldKey);
      Object.values(heldHanders).forEach(handler => {
        if (handler.hotkey !== heldKey) {
          return;
        }
        delete heldHanders[handler.id];
        handler.handler(e, KeyHold.Release);
      });
    }
  }
}

export function HotkeyScope({ depth, children }: { depth: number; children: React.ReactNode }) {
  const [hotkeysInitiallyDisabled, setHotkeysInitiallyDisabled] = useRecoilState(
    hotkeysInitiallyDisabledAtom
  );

  return (
    <hotkeyContext.Provider
      value={{
        depth,
        registerHotkeyCommand(command: HotkeyCommand): void {
          if (!hotkeys[command.hotkey]) {
            hotkeys[command.hotkey] = [];
          }
          hotkeys[command.hotkey].push({ ...command, depth });

          const keySequence = command.hotkey.split(' ');
          const isSequence = keySequence.length > 1;

          // register each key of a sequence separately with mousetrap since their sequence handling is wonky
          for (const key of keySequence) {
            mousetrapBindings[key] = mousetrapBindings[key] ?? [];
            mousetrapBindings[key].push({ ...command, depth });
            if (mousetrapBindings[key].length === 1) {
              Mousetrap.bindGlobal(key, (e: KeyboardEvent, rawHotkey: string) => {
                if (hotkeysInitiallyDisabled && hotkeys[rawHotkey]) {
                  setHotkeysInitiallyDisabled(false);
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
                handlePress(e, rawHotkey);
              });
              if (!isSequence) {
                Mousetrap.bindGlobal(key, handleUp, 'keyup');
              }
            }
          }

          if (isSequence) {
            return;
          }

          // handle when a held key handler is added when the key is already held
          if (command.hold && heldKeys.has(command.hotkey) && depth === heldKeyScopeDepth) {
            heldHanders[command.id] = command;
            command.handler(undefined, KeyHold.Press);
          }
        },
        unregisterHotkeyCommand(command: HotkeyCommand): void {
          if (!hotkeys[command.hotkey]) {
            return;
          }

          hotkeys[command.hotkey] = hotkeys[command.hotkey].filter(
            hotkeyCommand => hotkeyCommand.id !== command.id || hotkeyCommand.depth !== depth
          );

          if (!hotkeys[command.hotkey].length) {
            delete hotkeys[command.hotkey];
          }

          const keySequence = command.hotkey.split(' ');
          const isSequence = keySequence.length > 1;

          for (const key of keySequence) {
            if (!mousetrapBindings[key]) {
              Sentry.captureMessage('Unbinding but cannot find registered mousetrap handler', {
                extra: {
                  key,
                  keySequence,
                },
              });
              continue;
            }

            mousetrapBindings[key] = mousetrapBindings[key].filter(
              hotkeyCommand => hotkeyCommand.id !== command.id || hotkeyCommand.depth !== depth
            );
            if (!mousetrapBindings[key].length) {
              delete mousetrapBindings[key];
              Mousetrap.unbindGlobal(key);
              if (!isSequence) {
                Mousetrap.unbindGlobal(key, 'keyup');
              }
            }
          }

          if (isSequence) {
            return;
          }

          if (heldHanders[command.id]) {
            // Add a delay here because we often get the same hotkey immediately remounted and then
            // the key might still be held down
            setTimeout(() => {
              if (hotkeys[command.hotkey]?.find(c => c.id === command.id)) {
                return;
              }
              delete heldHanders[command.id];
              command.handler(undefined, KeyHold.Release);
            });
          }
        },
      }}
    >
      {children}
    </hotkeyContext.Provider>
  );
}
