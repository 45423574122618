import { useRecoilValue } from 'recoil';
import { writeToClipboard } from '../components/clipboardText';
import { useCurrentUser } from '../contexts/userContext';
import { useDeleteComments, useUpdateComments } from '../syncEngine/actions/comments';
import { commentSelector, useCommentUrl } from '../syncEngine/selectors/comments';
import { copyActivityLinkKey, resolveCommentKey } from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  CommentGroupContext,
  useCommandMenuContext,
} from './state';

export function useCommentCommandGroup(): CommandDefinition[] {
  const user = useCurrentUser();
  const updateComments = useUpdateComments();
  const deleteComments = useDeleteComments();
  const commentUrl = useCommentUrl();
  const commentContext = useCommandMenuContext<CommentGroupContext>(CommandGroup.Comment);

  const comment = useRecoilValue(commentSelector(commentContext?.commentId ?? ''));

  if (!comment) {
    return [];
  }

  const commands: CommandDefinition[] = [
    {
      id: 'copy-comment-link',
      hotkey: copyActivityLinkKey,
      description: 'Copy link to comment',
      icon: 'link',
      handler: () => {
        const url = commentUrl(comment);
        writeToClipboard(url, 'Comment link');
      },
    },
  ];

  if (comment.actorId === user.id) {
    commands.push({
      id: 'delete-comment',
      hotkey: 'backspace',
      description: 'Delete comment',
      icon: 'delete',
      handler: () => {
        deleteComments([comment.id]);
      },
    });
  }

  if (comment.inline && !comment.reply) {
    commands.push({
      id: 'resolve-comment',
      hotkey: resolveCommentKey,
      description: comment.resolved ? 'Unresolve comment' : 'Resolve comment',
      icon: comment.resolved ? 'refresh' : 'tasks',
      handler: () => {
        updateComments([comment.id], {
          resolved: !comment.resolved,
        });
      },
    });
  }

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Comment }));
}
