import moment from 'moment';
import React from 'react';
import { TimePicker } from '../../../components/new/timePicker';
import { useSnoozeIds } from '../../../syncEngine/actions/organizationMember';
import styles from '../commandMenu.module.scss';

export function SnoozedContents({
  entityIds,
  onClose,
}: {
  entityIds: string[];
  onClose: () => void;
}) {
  const snoozeIds = useSnoozeIds();

  return (
    <div className="rowStretch fullWidth fullHeight">
      <div className="fullWidth">
        <TimePicker
          filterPlaceholder="Snooze for ... (e.g 4 days)"
          filterClassName={styles.inputDiv}
          onPicked={(days: number) => {
            snoozeIds(entityIds, moment().add(days, 'days').valueOf());
            onClose();
          }}
        />
      </div>
    </div>
  );
}
