import * as React from 'react';
import { trackerPageLoad } from '../../tracker';
import { ErrorScreen } from './errorScreen';
import styles from './errorScreen.module.scss';

export function MaintenanceModeScreen() {
  React.useEffect(() => {
    trackerPageLoad('Maintenance mode');
  }, []);
  return (
    <ErrorScreen>
      <div className={styles.errorHeading}>Kitemaker is currently undergoing maintenance</div>
      <div>Don't worry! It will be back up and running in a jiffy</div>
    </ErrorScreen>
  );
}
