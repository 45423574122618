import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import { Emoji } from '../../components/new/emoji';
import { CustomEmojiElement, OptionalAttributesRenderElementProps } from '../types';
import styles from './customEmoji.module.scss';
import { UnselectableVoidInline } from './voidBlock';

export function StaticCustomEmoji({
  attributes,
  children,
  element,
}: OptionalAttributesRenderElementProps & { element: CustomEmojiElement }) {
  return (
    <span {...attributes} className={styles.emojiWrapper}>
      <span className={styles.emoji}>
        <Emoji emoji={element.emojiId} />
      </span>
      {children}
    </span>
  );
}

export function CustomEmoji({
  attributes,
  children,
  element,
}: RenderElementProps & { element: CustomEmojiElement }) {
  return (
    <UnselectableVoidInline attributes={attributes} element={element}>
      <span className={styles.emojiWrapper}>
        <span className={styles.emoji}>
          <Emoji emoji={element.emojiId} />
          {children}
        </span>
      </span>
    </UnselectableVoidInline>
  );
}
