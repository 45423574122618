import { TooltipProps } from '@radix-ui/react-tooltip';
import * as React from 'react';
import { NewEntityType } from '../contexts/modalContext';
import { entityTypeString } from '../syncEngine/selectors/entities';
import { alternateComboKey, mainComboKey } from '../utils/config';
import { KeyboardShortcut } from './new/keyboardShortcut';
import { Tooltip } from './new/tooltip';

export function SubmitHotkeyTooltip({
  entityType,
  needsMainComboKey,
  children,
  ...rest
}: { entityType: NewEntityType; needsMainComboKey?: boolean } & Omit<TooltipProps, 'content'>) {
  return (
    <Tooltip
      {...rest}
      content={
        <div>
          <div className="rowBetween mb8">
            Create {entityTypeString(entityType)}{' '}
            <KeyboardShortcut shortcut={`${needsMainComboKey ? `${mainComboKey}+` : ''}enter`} />
          </div>
          <div className="rowBetween mb8">
            Create and open {entityTypeString(entityType)}
            <KeyboardShortcut shortcut={`${mainComboKey}+${alternateComboKey}+enter`} />
          </div>
          <div className="rowBetween">
            Create {entityTypeString(entityType)} and start another{' '}
            <KeyboardShortcut shortcut={`${mainComboKey}+shift+enter`} className="ml8" />{' '}
          </div>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
