import * as React from 'react';
import { Icon, IconSize } from '../icon';
import Pill from './pill';
import { MetadataSize } from './size';

function InsightComponent(
  {
    size = MetadataSize.Small,
    ...props
  }: { size?: MetadataSize } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  return (
    <Pill {...props} ref={ref} size={size} iconOnly>
      <Icon size={iconSize} icon="insights" />
    </Pill>
  );
}

const Insight = React.forwardRef(InsightComponent);
export default Insight;
