import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  RoadmapColumnPicker,
  initiativesToRoadmapColumnPickerState,
} from '../../../components/new/pickers/roadmapColumnPicker';
import { useMoveInitiatives } from '../../../syncEngine/actions/intiatives';
import { roadmapInitiativesSelector } from '../../../syncEngine/selectors/intiatives';
import styles from '../commandMenu.module.scss';

export function ChangeColumnContents({
  onClose,
  roadmapInitiativeIds,
  roadmapId,
}: {
  onClose: () => void;
  roadmapInitiativeIds: string[];
  roadmapId: string;
}) {
  const moveInitiatives = useMoveInitiatives();
  const roadmapInitiatives = useRecoilValue(roadmapInitiativesSelector(roadmapInitiativeIds));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <RoadmapColumnPicker
        filterClassName={styles.inputDiv}
        roadmapId={roadmapId}
        state={initiativesToRoadmapColumnPickerState(roadmapInitiatives)}
        onPicked={(initiativeIds, columnId) => {
          moveInitiatives(initiativeIds, columnId);
        }}
        onDone={onClose}
      />
    </div>
  );
}
