import * as React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Space } from '../../../../sync/__generated/models';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { snippetsForSpaceSelector } from '../../../syncEngine/selectors/snippets';
import { useSpaceSettingsPath } from '../../../syncEngine/selectors/spaces';
import { FilteredListView } from '../filteredListView';
import { KeyNavigationProvider } from '../keyNavigation';
import { LISTVIEW_ID, ListViewItem, NO_KEYNAV } from '../listView';
import menuStyles from '../menu/menu.module.scss';

export function SnippetPicker({
  filterPlaceholder,
  filterClassName,
  space: propSpace,
  onPicked,
}: {
  filterPlaceholder?: string;
  filterClassName?: string;
  space?: Space | null;
  onPicked: (snippetId: string) => void;
}) {
  const contextSpace = useMaybeSpace();
  const space = propSpace ?? contextSpace;
  const history = useHistory();
  const spaceSettingsPath = useSpaceSettingsPath();

  const snippets = useRecoilValue(snippetsForSpaceSelector(space!.id));
  const snippetItems: ListViewItem[] = snippets.map(snippet => ({
    id: snippet.id,
    name: snippet.name,
    contents: snippet?.name ?? 'Untitled snippet',
    onSelected: () => {
      onPicked(snippet.id);
    },
  }));

  if (!space) {
    return null;
  }

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <FilteredListView
        itemClassName={menuStyles.item}
        filterClassName={filterClassName}
        filterPlaceholder="Create work item from snippet"
        maxItems={10}
        propertiesToSearch={['name']}
        placeholder={filterPlaceholder}
        items={snippetItems}
        populate={(items, _alwaysShownItems) => {
          const shownItems = [...items];
          if (items.length) {
            shownItems.push({
              id: NO_KEYNAV,
              key: 'sep',
              contents: () => <div className={menuStyles.separator} />,
            });
          }
          shownItems.push({
            id: 'manage',
            contents: 'Manage snippets',
            onSelected: () => {
              history.push(spaceSettingsPath(space.id, 'snippets'));
            },
          });

          return shownItems;
        }}
      />
    </KeyNavigationProvider>
  );
}
