import { selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { feedbackTagsByOrganization } from '../../../sync/__generated/indexes';
import { FeedbackTag } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';

export const tagSelector = selectorFamily({
  key: 'Tag',
  get:
    (tagId: string) =>
    ({ get }) => {
      return notDeleted(get(syncEngineState(tagId)) as FeedbackTag | null);
    },
});

export const tagsSelector = selectorFamily({
  key: 'Tags',
  get:
    (tagIds: string[]) =>
    ({ get }) => {
      return filterNotNull(tagIds.map(tagId => get(tagSelector(tagId))));
    },
});

export const tagsForOrganizationSelector = selectorFamily({
  key: 'TagsForOrganization',
  get:
    (organizationId: string | undefined) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }
      const tagIds = get(indexKeyState(indexKey(feedbackTagsByOrganization, organizationId)));
      return filterNotDeletedNotNull(tagIds.map(tagId => get(tagSelector(tagId))));
    },
});
