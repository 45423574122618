import { flatten, uniqBy } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Initiative, Issue, Space } from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useCreateInitiative } from '../../../syncEngine/actions/intiatives';
import {
  initiativesForSpaceSelector,
  initiativesSelector,
  publicInitiativesForOrganizationSelector,
} from '../../../syncEngine/selectors/intiatives';
import { FilteredListViewHandle } from '../filteredListView';
import { InitiativeSpaces } from '../initativeSpaces';
import { InitiativeStatusIcon } from '../initiativeStatus';
import { ListViewItem, NO_KEYNAV } from '../listView';
import { InitiativeIcon } from '../metadata/initiative';
import { Picker, PickerState } from '../picker';
import { TextInputType } from '../textInput';
import { TooltipIfTruncated } from '../tooltipIfTruncated';

export function workItemsToInitiativePickerState(
  issues: Array<Issue>,
  initiatives: Array<Initiative>
): PickerState {
  const initiativeIdsByIssueId = issues.reduce((result, issue) => {
    result[issue.id] = [];
    return result;
  }, {} as Record<string, string[]>);

  for (const initiative of initiatives) {
    for (const issueId of initiative.issueIds) {
      if (initiativeIdsByIssueId[issueId]) {
        initiativeIdsByIssueId[issueId].push(initiative.id);
      }
    }
  }
  return initiativeIdsByIssueId;
}

function createInitiativeItem({
  newInitiativeName,
  onSelected,
}: {
  newInitiativeName: string;
  onSelected: (shift: boolean) => void;
}): ListViewItem {
  return {
    id: 'add',
    mouseDown: true,
    className: 'createItem',
    icon: 'add',
    onSelected,
    contents: (
      <div className="ellipsis">Create an initiative titled &quot;{newInitiativeName}&quot;</div>
    ),
  };
}

export function InitiativePicker({
  state,
  onInitiativeAdded,
  onInitiativeRemoved,
  onDone,
  multi = true,
  excluseInitiativeIds,
  additionalItems,
  additionalItemsFirst,
  filterClassName,
  filterPlaceholder,
  className,
  placeholder,
  hideFooter,
  inputType,
  showMetadata,
  noCheckbox,
  space: propSpace,
  disableAutoFocus,
  disableCreation,
}: {
  state: PickerState;
  onInitiativeAdded: (ids: string[], initiativeId: string) => void;
  onInitiativeRemoved: (ids: string[], initiativeId: string) => void;
  onDone?: () => void;
  multi?: boolean;
  excluseInitiativeIds?: string[];
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  filterClassName?: string;
  className?: string;
  filterPlaceholder?: string;
  placeholder?: string;
  hideFooter?: boolean;
  inputType?: TextInputType;
  showMetadata?: boolean;
  noCheckbox?: boolean;
  createProps?: { roadmapId?: string };
  space?: Space | null;
  disableAutoFocus?: boolean;
  disableCreation?: boolean;
}) {
  const organization = useOrganization();
  const contextSpace = useMaybeSpace();
  const space = propSpace ?? contextSpace;
  const createInitiative = useCreateInitiative();
  const orgInitiatives = useRecoilValue(
    publicInitiativesForOrganizationSelector(organization.id)
  ).filter(i => !i.archivedAt);
  const stateInitiatives = useRecoilValue(initiativesSelector(flatten(Object.values(state))));
  const spaceInitiatives = useRecoilValue(initiativesForSpaceSelector(space?.id));
  const isPrivate = space?.private ?? false;

  const initiatives = uniqBy(
    isPrivate
      ? [...stateInitiatives, ...spaceInitiatives]
      : [...stateInitiatives, ...orgInitiatives],
    'id'
  ).filter(i => !excluseInitiativeIds?.includes(i.id));

  const defaultInitiatives = space?.id ? spaceInitiatives : orgInitiatives;
  const filterRef = React.useRef<FilteredListViewHandle>(null);

  const items: ListViewItem[] = initiatives.map(initiative => {
    const item = {
      ...initiative,
      contents: () => {
        if (showMetadata) {
          return (
            <div className="rowBetween fullWidth noMinWidth">
              <div className="row gap8 noMinWidth">
                <InitiativeIcon color={initiative.color} />
                <TooltipIfTruncated>{initiative.title}</TooltipIfTruncated>
              </div>
              <div className="row gap8">
                <InitiativeSpaces initiative={initiative} />
                <InitiativeStatusIcon initiativeId={initiative.id} />
              </div>
            </div>
          );
        }
        return (
          <>
            <InitiativeIcon color={initiative.color} />
            <TooltipIfTruncated>{initiative.title}</TooltipIfTruncated>
          </>
        );
      },
    };
    if (noCheckbox) {
      (item as any).icon = null;
    }
    return item;
  });

  const defaultItems = defaultInitiatives.map(i => i.id);
  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
      defaultItems.unshift(...additionalItems.map(i => i.id));
    } else {
      items.push(...additionalItems);
      defaultItems.push(...additionalItems.map(i => i.id));
    }
  }

  if (items.length === 0) {
    items.push({
      id: NO_KEYNAV,
      contents: placeholder ?? 'Type to create a new initiative',
    });
  }

  return (
    <Picker
      ref={filterRef}
      disableAutoFocus={disableAutoFocus}
      filter={(filterString, search) => {
        const results = search.search(filterString).map(r => r.item);
        if (disableCreation) {
          return results;
        }
        const exactMatch = !!initiatives.find(
          l => l.title.toLowerCase() === filterString.toLowerCase()
        );

        if (!exactMatch) {
          results.push(
            createInitiativeItem({
              newInitiativeName: filterString,
              onSelected: () => {
                const initiative = createInitiative(filterString, {
                  spaceIds: space ? [space.id] : [],
                });

                const initiativeIds = Object.keys(state);
                onInitiativeAdded(initiativeIds, initiative.initiative.id);
                filterRef.current?.clear();
                onDone?.();
              },
            })
          );
        }

        return results;
      }}
      noSort
      multi={multi}
      inputType={inputType}
      hideFooter={hideFooter}
      filterLabel="Filter initiatives"
      filterPlaceholder={filterPlaceholder ?? 'Filter initiatives'}
      placeholder={placeholder ?? 'No initiatives found'}
      state={state}
      items={items}
      defaultItems={defaultItems}
      propertiesToSearch={['title']}
      onAdd={onInitiativeAdded}
      onRemove={onInitiativeRemoved}
      onDone={onDone}
      filterClassName={filterClassName}
      className={className}
    />
  );
}
