export enum ImportType {
  ImportGitHubIssueToSpace = 'github_issues_into_space',
  ImportTrelloBoardToNewSpace = 'trello_board',
  ImportTestSpace = 'test_space',
  ImportTutorialSpace = 'tutorial_space',
}

export interface ImportedAssignee {
  id: number;
  name?: string;
  login: string;
}
