import { randomString } from '../../../shared/utils/random';
import { initiativeLabels } from '../../../sync/__generated/collections';
import { initiativesByLabel } from '../../../sync/__generated/indexes';
import { OrganizationLabel } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { SyncEngineCreate, SyncEngineUpdate, useModelManager } from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';

export function useCreateOrganizationLabel() {
  const modelManager = useModelManager();
  return (organizationId: string, name: string, color = 'gray'): OrganizationLabel => {
    return modelManager.transaction(tx => {
      const label: SyncEngineCreate<OrganizationLabel> = {
        __typename: 'OrganizationLabel',
        name,
        color,
        organizationId,
      };

      return tx.create(label);
    });
  };
}

export function useUpdateOrganizationLabels() {
  const modelManager = useModelManager();
  return (labelIds: string[], update: SyncEngineUpdate<OrganizationLabel>) => {
    modelManager.transaction(tx => {
      for (const labelId of labelIds) {
        tx.update<OrganizationLabel>(labelId, update);
      }
    });
  };
}

export function useDeleteOrganizationLabels() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (labelIds: string[], force?: boolean) => {
    if (!force) {
      const confirmed = await confirm(
        `Delete organization label${labelIds.length > 1 ? 's' : ''}`,
        `Are you sure you want to delete ${
          labelIds.length > 1 ? 'these organization labels' : 'this organization label'
        }? There is no way to undo this operation`,
        {
          label: 'Delete',
          destructive: true,
        }
      );

      if (!confirmed) {
        return [];
      }
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const labelId of labelIds) {
        const label = get<OrganizationLabel>(labelId);
        if (!label || label.deleted) {
          continue;
        }

        // clean up initiatives
        const initiativeIds = getIndex(initiativesByLabel, label.id);
        for (const initiativeId of initiativeIds) {
          tx.removeFromCollection(initiativeLabels, initiativeId, [label.id]);
        }

        tx.update<OrganizationLabel>(labelId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });

        deleted.push(labelId);
        trackerEvent('Organization Label Deleted', {
          id: label.id,
          name: label.name,
        });
      }
    });

    return deleted;
  };
}
