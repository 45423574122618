import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import { toggleStarredKey } from '../../utils/config';
import { Starred } from '../../utils/starred';
import { ButtonStyle, IconButton } from './button';
import { CustomCommand } from './customCommand';
import { KeyboardShortcut } from './keyboardShortcut';
import styles from './starredIcon.module.scss';
import { Tooltip } from './tooltip';

export function StarredIcon({ starred, className }: { starred: Starred; className?: string }) {
  const organization = useOrganization();
  const isStarred = useRecoilValue(
    isStarredSelector({ organizationId: organization.id, ...starred })
  );
  const toggleStarred = useToggleStarred();

  return (
    <>
      <Tooltip
        content={
          <>
            {isStarred ? 'Remove from Starred' : 'Add to Starred'}{' '}
            <KeyboardShortcut shortcut={toggleStarredKey} />
          </>
        }
      >
        <IconButton
          icon="starred"
          buttonStyle={ButtonStyle.BareSubtle}
          className={cn(className, {
            [styles.starred]: isStarred,
          })}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            toggleStarred(starred);
          }}
        />
      </Tooltip>
      <CustomCommand
        command={{
          id: 'toggle-starred',
          description: isStarred ? 'Remove screen from Starred' : 'Add screen to Starred',
          icon: 'starred',
          hotkey: toggleStarredKey,
          group: CommandGroup.Navigation,
          handler: () => toggleStarred(starred),
        }}
      />
    </>
  );
}
