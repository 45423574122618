export const slackScopes = [
  'links:read',
  'links:write',
  'channels:history',
  'channels:read',
  'groups:read',
  'groups:history',
  'chat:write',
  'files:read',
  'users:read',
  'users:read.email',
  'team:read',
  'commands',
  'emoji:read',
  'channels:join',
] as const;

export type SlackScope = typeof slackScopes[number];

export interface SlackConfig {
  teamId: string;
  teamName: string;
  teamDomain: string;
  enterpriseId?: string;
  enterpriseName?: string;
  url: string;
  scopes: SlackScope[];
}

export const BASIC_SLACK_SCOPES = [
  'links:read',
  'links:write',
  'channels:read',
  'channels:join',
  'chat:write',
  'groups:read',
  'files:read',
  'im:read',
  'im:write',
  'mpim:read',
  'mpim:write',
  'emoji:read',
  'team:read',
  'users:read',
  'commands',
] as const;
export const FEEDBACK_EMAIL_SCOPES = ['users:read.email'] as const;
export const FULL_SYNC_SCOPES = [
  'channels:history',
  'groups:history',
  'im:history',
  'mpim:history',
  'reactions:read',
  'reactions:write',
  'files:write',
] as const;

export const BASIC_USER_SCOPES = [];
export const FULL_SYNC_USER_SCOPES = ['chat:write'] as const;

export const ALL_SLACK_SCOPES = [
  ...BASIC_SLACK_SCOPES,
  ...FULL_SYNC_SCOPES,
  ...FEEDBACK_EMAIL_SCOPES,
] as const;

export interface SlackConfig2 {
  teamId: string;
  teamName: string;
  isEnterprise?: boolean;
  scopes: typeof ALL_SLACK_SCOPES[number][];
}
