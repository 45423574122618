import cn from 'classnames';
import * as React from 'react';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Hotkey } from '../../components/new/hotkey';
import { Icon, IconSize } from '../../components/new/icon';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerPageLoad } from '../../tracker';
import styles from './onboardingScreen.module.scss';

function Integration({
  icon,
  name,
  description,
}: {
  name: string;
  icon: string;
  description: string;
}) {
  return (
    <div className={styles.integration}>
      <div className="mr12">
        <Icon icon={icon} size={IconSize.Size40} />
      </div>
      <div>
        <div className="headingM mb4">{name}</div>
        <div className="bodyM grayed">{description}</div>
      </div>
    </div>
  );
}

export function IntegrationStep({ onNext }: { onNext: () => void }) {
  useComponentDidMount(() => {
    trackerPageLoad('TutorialIntegrations');
  });

  return (
    <div className="colAlignCenter">
      <div className={cn('headingXL', styles.heading)}>You are all set.</div>
      <p className={cn('bodyXL slightlyGrayed mt24 mb32 textCenter', styles.heading)}>
        After you poke around Kitemaker, don’t forget to try the GitHub, Slack and Figma
        integrations.
      </p>

      <div className={styles.integrationRow}>
        <Integration
          key="github"
          icon="github"
          name="GitHub and GitLab"
          description="Automate workflows based on commits & PRs and link mentions of work items back to Kitemaker."
        />
        <Integration
          key="slack"
          icon="slack_color"
          name="Slack and Discord"
          description="Get information about mentioned work items in Slack or Discord and link conversations to Kitemaker work items."
        />
        <Integration
          key="figma"
          icon="figma_color"
          name="Figma"
          description="Automatically updated Figma thumbnails in work items & mentions from Figma comments."
        />
      </div>

      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onNext();
        }}
      >
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          className="fullWidth"
        >
          Continue
        </Button>
      </form>
      <Hotkey
        hotkey="enter"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onNext();
        }}
      />
    </div>
  );
}
