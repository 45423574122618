import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useClient } from 'urql';
import {
  SharedRoadmapQuery,
  SharedRoadmapQueryVariables,
} from '../../../../graphql__generated__/graphql';
import { generateId } from '../../../shared/utils/id';
import { Breadcrumbs } from '../../components/new/breadcrumbs';
import { Count } from '../../components/new/count';
import {
  EntityCard,
  EntityCardHeader,
  EntityCardMetadataContainer,
  EntityCardTitle,
  EntityMetadataRow,
} from '../../components/new/entityCard';
import { EntityLabels, EntityMembers } from '../../components/new/entityMetadata';
import { Icon } from '../../components/new/icon';
import { InitiativeSpaces } from '../../components/new/initativeSpaces';
import { InitiativeStatus } from '../../components/new/initiativeStatus';
import { Logo } from '../../components/new/logo';
import { InitiativeIcon } from '../../components/new/metadata/initiative';
import { MetadataSize } from '../../components/new/metadata/size';
import { ScreenHeader } from '../../components/new/screenHeader';
import { VirtualizedBoardView } from '../../components/new/virtualizedBoardView';
import TitleSetter from '../../components/titleSetter';
import { DummyConfirmationProvider } from '../../contexts/confirmationContext';
import { OrganizationProvider } from '../../contexts/organizationContext';
import { messageFromGraphQLError } from '../../graphql/errors';
import { sharedRoadmapQuery } from '../../graphql/queries';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { orgEntityKey } from '../../syncEngine/selectors/entities';
import {
  filteredRoadmapInitiativeIdsForColumnSelector,
  initiativeCountForColumn,
  initiativeIdForRoadmapInitiativeSelector,
  initiativeSelector,
} from '../../syncEngine/selectors/intiatives';
import { organizationsSelector } from '../../syncEngine/selectors/organizations';
import {
  roadmapColumnIdsForRoadmapSelector,
  roadmapColumnSelector,
  roadmapColumnTypeToIcon,
  roadmapSelector,
} from '../../syncEngine/selectors/roadmaps';
import { useStateTransaction } from '../../syncEngine/state';
import { SyncEngineObject } from '../../syncEngine/types';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from '../errorScreens';
import LoadingScreen from '../loadingScreen';
import styles from './sharedRoadmapScreen.module.scss';

function InitiativeCard({ id, metadata }: { id: string; metadata: boolean }) {
  const initiativeId = useRecoilValue(initiativeIdForRoadmapInitiativeSelector(id));
  const initiative = useRecoilValue(initiativeSelector(initiativeId ?? ''));

  if (!initiative) {
    return null;
  }

  return (
    <EntityCard>
      <EntityCardHeader entityNumber={orgEntityKey(initiative)} />
      <div className="row">
        <InitiativeIcon className="mt6 mr8" color={initiative.color} />
        <EntityCardTitle type={'Initiative'}>{initiative.title}</EntityCardTitle>
      </div>
      {metadata && (
        <>
          <EntityMetadataRow>
            <InitiativeSpaces initiative={initiative} />
            <EntityLabels entity={initiative} orgLevel size={MetadataSize.Small} />
          </EntityMetadataRow>
          <EntityCardMetadataContainer members={<EntityMembers entity={initiative} />}>
            <InitiativeStatus initiativeId={initiative.id} />
          </EntityCardMetadataContainer>
        </>
      )}
    </EntityCard>
  );
}

function Header({ columnId }: { columnId: string }) {
  const column = useRecoilValue(roadmapColumnSelector(columnId));
  const count = useRecoilValue(
    initiativeCountForColumn({ columnId, filterId: column?.roadmapId ?? '' })
  );

  if (!column) {
    return null;
  }

  return (
    <div className={styles.header}>
      <div className="row grow ellipsis">
        <Icon className="mr8" icon={roadmapColumnTypeToIcon(column.columnType)} />
        <div className="ellipsis mr8">{column.name}</div>
        <Count count={count} />
      </div>
    </div>
  );
}

function SharedRoadmapScreenContents({
  roadmapId,
  metadata,
}: {
  roadmapId: string;
  metadata: boolean;
}) {
  const roadmap = useRecoilValue(roadmapSelector(roadmapId));
  const organization = useRecoilValue(organizationsSelector(roadmap?.organizationId ?? ''));
  const columnIds = useRecoilValue(roadmapColumnIdsForRoadmapSelector(roadmapId));

  // TODO: better implementation at some point
  const getAllColumns = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const ret = Object.fromEntries(
          columnIds.map(id => [
            id,
            snapshot
              .getLoadable(
                filteredRoadmapInitiativeIdsForColumnSelector({ columnId: id, filterId: roadmapId })
              )
              .getValue(),
          ])
        );
        return ret;
      },
    [columnIds]
  );

  const getSelector = React.useCallback(
    (columnId: string) =>
      filteredRoadmapInitiativeIdsForColumnSelector({ columnId, filterId: roadmapId }),
    [roadmapId]
  );

  if (!organization) {
    return null;
  }

  return (
    <DummyConfirmationProvider>
      <OrganizationProvider organizationId={organization.id}>
        <div className={styles.sharedRoadmapScreen}>
          <TitleSetter title={`${organization.name}  · Roadmap by Kitemaker`} />
          <ScreenHeader hideNotifications>
            <Breadcrumbs breadcrumbs={[{ name: organization.name }, { name: 'Roadmap' }]} />
          </ScreenHeader>
          <VirtualizedBoardView
            id={roadmapId}
            getAllColumns={getAllColumns}
            className={styles.board}
            columnIds={columnIds}
            getSelector={getSelector}
            spacerHeight={8}
            columnHeaderHeight={32}
            renderColumnHeader={columnId => {
              return <Header columnId={columnId} />;
            }}
            renderCard={id => {
              return <InitiativeCard id={id} metadata={metadata} />;
            }}
            renderPlaceholder={() => {
              return <div></div>;
            }}
          />
          <Logo className={styles.logo} link />
        </div>
      </OrganizationProvider>
    </DummyConfirmationProvider>
  );
}

export function SharedRoadmapScreen() {
  const { params } = useRouteMatch<{ roadmapId: string }>();
  const client = useClient();
  const [loaded, setLoaded] = React.useState(false);
  const [renderMetadata, setRenderMetadata] = React.useState(false);
  const [error, setError] = React.useState('');
  const stateTransaction = useStateTransaction();

  trackerPageLoad('Shared Roadmap', {
    roadmapId: params.roadmapId,
  });

  useComponentDidMount(() => {
    (async () => {
      const result = await client
        .query<SharedRoadmapQuery, SharedRoadmapQueryVariables>(sharedRoadmapQuery, {
          id: params.roadmapId,
        })
        .toPromise();

      if (result.error) {
        setError(
          messageFromGraphQLError(result.error.graphQLErrors) ?? 'An unknown error has occurred'
        );
        return;
      }

      if (!result.data?.sharedRoadmap) {
        setError('An unknown error has ocurred');
        return;
      }

      const {
        organization,
        metadata,
        labels,
        members,
        roadmap,
        roadmapInitiatives,
        initiatives,
        initiativeSpaces,
        columns,
        spaces,
        issues,
        statuses,
      } = result.data.sharedRoadmap;

      const now = Date.now();
      const defaultProps = {
        updatedAt: now,
        createdAt: now,
        deleted: false,
        version: 0,
      };

      const labelsToLoad: SyncEngineObject[] = (labels ?? []).map(label => ({
        ...label,
        ...defaultProps,
        __typename: 'OrganizationLabel',
      }));

      const spacesToLoad: SyncEngineObject[] = (spaces ?? []).map(space => ({
        ...space,
        ...defaultProps,
        __typename: 'Space',
      }));

      const issuesToLoad: SyncEngineObject[] = (issues ?? []).map(issue => ({
        ...issue,
        ...defaultProps,
        __typename: 'Issue',
      }));

      const statusesToLoad: SyncEngineObject[] = (statuses ?? []).map(status => ({
        ...status,
        ...defaultProps,
        __typename: 'IssueStatus',
      }));

      const usersToLoad: SyncEngineObject[] = (members ?? []).map(user => ({
        ...user,
        ...defaultProps,
        __typename: 'User',
      }));

      const organizationToLoad: SyncEngineObject = {
        ...organization,
        ...defaultProps,
        __typename: 'Organization',
      };

      const roadmapToLoad: SyncEngineObject = {
        ...roadmap,
        ...defaultProps,
        __typename: 'Roadmap',
      };

      const roadmapInitiativesToLoad: SyncEngineObject[] = (roadmapInitiatives ?? []).map(ri => ({
        ...ri,
        ...defaultProps,
        __typename: 'RoadmapInitiative',
      }));

      const initiativesToLoad: SyncEngineObject[] = (initiatives ?? []).map(ri => ({
        ...ri,
        ...defaultProps,
        __typename: 'Initiative',
      }));

      const initiativeSpacesToLoad: SyncEngineObject[] = (initiativeSpaces ?? []).map(is => ({
        ...is,
        ...defaultProps,
        __typename: 'InitiativeSpace',
      }));

      const columnsToLoad: SyncEngineObject[] = (columns ?? []).map(col => ({
        ...col,
        ...defaultProps,
        __typename: 'RoadmapColumn',
      }));

      const organizationMembersToLoad: SyncEngineObject[] = usersToLoad.map(user => ({
        id: generateId(),
        userId: user.id,
        organizationId: organizationToLoad.id,
        ...defaultProps,
        __typename: 'OrganizationMember',
      }));

      const allObjects = [
        ...usersToLoad,
        ...labelsToLoad,
        ...spacesToLoad,
        ...issuesToLoad,
        ...statusesToLoad,
        ...organizationMembersToLoad,
        ...roadmapInitiativesToLoad,
        ...initiativesToLoad,
        ...initiativeSpacesToLoad,
        ...columnsToLoad,
        roadmapToLoad,
        organizationToLoad,
      ];

      stateTransaction(({ set }) => {
        set(allObjects);
      });

      setRenderMetadata(metadata);
      setLoaded(true);
    })();
  });

  if (!loaded) {
    return <LoadingScreen />;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{error}</BasicErrorScreen>;
  }

  return <SharedRoadmapScreenContents roadmapId={params.roadmapId} metadata={renderMetadata} />;
}
