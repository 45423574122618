import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { generateKey, validKey } from '../../shared/utils/id';
import ErrorText from '../components/errorText';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { useSetSidebarSpaceCollapsed } from '../components/new/sidebar/sidebarComponents';
import { TextInput, TextValidationInput } from '../components/new/textInput';
import { CreateSpaceArgs, Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useUpdateOrganizationMembers } from '../syncEngine/actions/organizationMember';
import { useCreateSpace } from '../syncEngine/actions/spaces';
import { spacePath, spacesForOrganizationSelector } from '../syncEngine/selectors/spaces';
import { currentUserMembershipState } from '../syncEngine/selectors/users';
import styles from './newSpaceModal.module.scss';

function NewSpaceModalContents() {
  const history = useHistory();
  const createSpace = useCreateSpace();
  const [name, setName] = React.useState('');
  const [key, setKey] = React.useState('');
  const [keyChanged, setKeyChanged] = React.useState(false);
  const modals = useModals();

  const [error, setError] = React.useState('');
  const organization = useOrganization();
  const orgMembership = useRecoilValue(currentUserMembershipState(organization.id));
  const setSpaceCollapsed = useSetSidebarSpaceCollapsed();
  const updateOrganizationMembers = useUpdateOrganizationMembers();

  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const spaceKeys = spaces.map(s => s.key);

  const modalManager = useModals();

  const args = modalManager.currentArgs() ? (modalManager.currentArgs() as CreateSpaceArgs) : null;
  const favorite = args?.favorite;

  function submit() {
    const newSpace = createSpace(name, favorite, key);
    setSpaceCollapsed(newSpace.id, false);
    if (orgMembership) {
      // FIXME: This is a hack to circumvent a bug in the sync engine compression
      updateOrganizationMembers([orgMembership.id], { lastSpaceId: newSpace.id });
    }
    modalManager.closeModal(Modals.CreateSpace);
    history.push(spacePath(organization, newSpace));
  }

  return (
    <form
      className={styles.form}
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();
        if (name !== '') {
          submit();
        }
      }}
    >
      <ModalContentWrapper>
        <span>
          Space sections off work items and can represent cross-functional teams or products,
          components, and special projects
        </span>
        <div className={styles.inputGroup}>
          <span className="oneLine headingS">Space name</span>
          <TextInput
            autoFocus
            maxLength={254}
            placeholder="e.g. Core Product"
            onChange={e => {
              const val = e.currentTarget.value;
              setError('');
              setName(val);
              if (!keyChanged) {
                if (val !== '') {
                  setKey(generateKey(val, spaceKeys));
                } else {
                  setKey('');
                }
              }
            }}
          />
        </div>
        <div className={styles.inputGroup}>
          <span className="oneLine headingS">Space ID</span>
          <TextValidationInput
            value={key}
            style={{ width: 110 }}
            placeholder="e.g. CP"
            onChange={e => {
              setKeyChanged(true);
              setError('');
              setKey(e.currentTarget.value.toUpperCase());
            }}
            validate={v => {
              if (!v.match(validKey)) {
                return 'ID may only contain letters and numbers';
              }
              return null;
            }}
          />
          <span>
            Space ID is used in work item identifiers (e.g. CP-123). It should be short and easy to
            recognize.
          </span>
        </div>
        {error && <ErrorText error={error} />}
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <Button
          type="button"
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => modals.closeModal(Modals.CreateSpace)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          buttonStyle={ButtonStyle.Primary}
          disabled={!name || !key.match(validKey)}
        >
          Create space
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export function NewSpaceModal() {
  return (
    <Modal modalId={Modals.CreateSpace} title="Create space">
      <NewSpaceModalContents />
    </Modal>
  );
}
