import { capitalize } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ExternalAuthProviderType } from '../../../shared/auth';
import { MAX_PASSWORD_LENGTH } from '../../../shared/utils/constants';
import { AuthResult, fetchPendingLinkdAccount, linkExternalAccount } from '../../api/auth';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { TextInputSize, TextValidationInput } from '../../components/new/textInput';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerPageLoad } from '../../tracker';
import LoadingScreen from '../loadingScreen';

function LinkAccountScreenContents({
  email,
  externalAccountType,
  onSubmit,
}: {
  email: string;
  externalAccountType: ExternalAuthProviderType;
  onSubmit: (data: { password: string; onError: (error: string) => void }) => void;
}) {
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL">Link existing account</div>
      <p className="grayed bodyM mt16">
        An existing account was found for <span className="semiBold">{email}</span>. Please verify
        your password in order to link this {capitalize(externalAccountType)} account to your
        Kitemaker account.
      </p>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          if (!password) {
            return;
          }
          setRequestInProgress(true);
          onSubmit({
            password,
            onError: e => {
              setError(e);
              setRequestInProgress(false);
            },
          });
        }}
      >
        <div className="fullWidth mb8">
          <label htmlFor="password">Password</label>
          <TextValidationInput
            name="password"
            type="password"
            inputSize={TextInputSize.Large}
            className="fullWidth"
            disabled={requestInProgress}
            placeholder="Your password"
            value={password}
            onChange={e => {
              setError('');
              setPassword(e.currentTarget.value);
            }}
            error={error}
            validate={pw => {
              if (pw.length > MAX_PASSWORD_LENGTH) {
                return `Password length cannot exceed ${MAX_PASSWORD_LENGTH} characters.`;
              }

              return null;
            }}
          />
        </div>
        <div className="mb24">
          <Link
            className="headingS link subtle hoverOnly grayed"
            to={{
              pathname: '/forgotPassword',
              state: { backLabel: 'Go back', backPath: '/linkExternalAccount' },
            }}
          >
            Forgot password?
          </Link>
        </div>
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          type="submit"
          className="fullWidth mt24"
          disabled={requestInProgress || !password}
          autoFocus
        >
          <div className="row">Continue</div>
        </Button>
      </form>
    </div>
  );
}

export function LinkAccountScreen({ onCompleted }: { onCompleted: (result: AuthResult) => void }) {
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [externalAccountType, setExternalAccountType] =
    React.useState<ExternalAuthProviderType | null>(null);

  React.useEffect(() => {
    trackerPageLoad('Link External Account');
  }, []);

  useComponentDidMount(() => {
    (async () => {
      const result = await fetchPendingLinkdAccount();
      if (result) {
        setEmail(result.email);
        setExternalAccountType(result.type);
      }
    })();
  });

  if (!email || !externalAccountType) {
    return <LoadingScreen />;
  }

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Link external account" />
      <LinkAccountScreenContents
        email={email}
        externalAccountType={externalAccountType}
        onSubmit={async ({ password, onError }) => {
          try {
            await linkExternalAccount(history, onCompleted, password);
          } catch (e) {
            onError(e.message);
          }
        }}
      />
    </AuxiliaryScreen>
  );
}
