import { capitalize } from 'lodash';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useClient } from 'urql';
import isEmail from 'validator/lib/isEmail';
import { ExternalAuthProviderType, LoginMethod } from '../../../shared/auth';
import { MAX_PASSWORD_LENGTH } from '../../../shared/utils/constants';
import { InvitedUser, findInvitedUser, login } from '../../api/auth';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { ExternalAuthButton } from '../../components/new/externalAuthButton';
import { Hotkey } from '../../components/new/hotkey';
import { Logo } from '../../components/new/logo';
import { TextInputSize, TextValidationInput } from '../../components/new/textInput';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { Configuration } from '../../contexts/configurationContext';
import { trackerPageLoad } from '../../tracker';
import { getQueryParameter } from '../../utils/query';
import { BasicErrorScreen } from '../errorScreens';

export function LoginScreenContents({
  onSubmit,
  invitedUser,
  token,
}: {
  onSubmit: (data: { onError: (error: string) => void; email: string; password: string }) => void;
  invitedUser?: InvitedUser | null;
  token?: string;
}) {
  const history = useHistory();
  const [email, setEmail] = React.useState(invitedUser?.user.primaryEmail ?? '');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  const valid = React.useMemo(() => {
    return email && password && isEmail(email) && password.length <= MAX_PASSWORD_LENGTH;
  }, [email, password]);

  const requiredLogin = React.useMemo(() => {
    const login = getQueryParameter(history, 'requiredLoginMethod');
    return login ? (login as LoginMethod) : null;
  }, [history]);

  const submit = React.useCallback(() => {
    setError('');
    setRequestInProgress(true);
    onSubmit({
      email,
      password,
      onError: e => {
        setRequestInProgress(false);
        if (e === 'Rate limit exceeded') {
          setError('Too many login attempts. Please try again later.');
        } else {
          setError(e);
        }
        passwordRef.current?.focus();
      },
    });
  }, [email, password, onSubmit]);

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL oneLine mb4">
        {requiredLogin
          ? `Log in with ${capitalize(requiredLogin)} required`
          : 'Log in to Kitemaker'}
      </div>
      <div className="bodyM grayed mb32">
        {!invitedUser && !requiredLogin && (
          <>
            Welcome! Don't have an account?{' '}
            <Link className="headingS link subtle hoverOnly" to="/signup">
              Sign up.
            </Link>
          </>
        )}
        {!!invitedUser && !requiredLogin && <>Join {invitedUser.organization.name}</>}
        {requiredLogin && (
          <>Your organization requires that you log in with {capitalize(requiredLogin)}</>
        )}
      </div>
      {(!requiredLogin || requiredLogin === ExternalAuthProviderType.Google) && (
        <ExternalAuthButton token={token} provider={ExternalAuthProviderType.Google} />
      )}
      {(!requiredLogin || requiredLogin === ExternalAuthProviderType.Microsoft) && (
        <ExternalAuthButton
          token={token}
          provider={ExternalAuthProviderType.Microsoft}
          className="mt24"
        />
      )}
      {!requiredLogin && (
        <>
          <div className="mt24 mb24 rowCenter fullWidth grayedLight">OR</div>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              submit();
            }}
          >
            <div className="fullWidth mb24">
              <label htmlFor="email">Email</label>
              <TextValidationInput
                name="email"
                inputSize={TextInputSize.Large}
                className="fullWidth emailInput"
                autoFocus={!invitedUser}
                disabled={requestInProgress || !!invitedUser}
                placeholder="yourname@company.com"
                value={email}
                onChange={e => {
                  setError('');
                  setEmail(e.currentTarget.value);
                }}
              />
            </div>
            <div className="fullWidth mb8">
              <label htmlFor="password">Password</label>
              <TextValidationInput
                name="password"
                type="password"
                inputSize={TextInputSize.Large}
                className="fullWidth"
                autoFocus={!!invitedUser}
                disabled={requestInProgress}
                placeholder="Your password"
                value={password}
                onChange={e => {
                  setError('');
                  setPassword(e.currentTarget.value);
                }}
                error={error}
                validate={pw => {
                  if (pw.length > MAX_PASSWORD_LENGTH) {
                    return `Password length cannot exceed ${MAX_PASSWORD_LENGTH} characters.`;
                  }

                  return null;
                }}
              />
            </div>
            <div className="mb24">
              <Link className="headingS link subtle hoverOnly grayed" to="/forgotPassword">
                Forgot password?
              </Link>
            </div>
            <Button
              buttonStyle={ButtonStyle.PrimaryBlack}
              size={ButtonSize.Large}
              type="submit"
              className="fullWidth"
              disabled={!valid || requestInProgress}
            >
              <div className="row">Log in with email</div>
            </Button>
            <Hotkey
              hotkey="enter"
              handler={e => {
                // NOTE: this hotkey exists because Safari breaks the focus on the form when
                // populating strong passwords
                e?.preventDefault();
                e?.stopPropagation();
                if (!valid || requestInProgress) {
                  return;
                }
                submit();
              }}
            />
          </form>
        </>
      )}
    </div>
  );
}

export function LoginScreen({
  onCompleted,
}: {
  onCompleted: (options: { redirect?: string | null; config: Partial<Configuration> }) => void;
}) {
  const client = useClient();
  const history = useHistory();
  const match = useRouteMatch<{ token?: string }>();
  const { token } = match.params;
  const [tokenValidated, setTokenValidated] = React.useState(!token);
  const [invitedUser, setInvitedUser] = React.useState<InvitedUser | null>(null);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    trackerPageLoad('Login');
  }, []);

  React.useEffect(() => {
    if (!token) {
      return;
    }

    (async () => {
      try {
        const user = await findInvitedUser(client, token);
        setInvitedUser(user);
      } catch (e) {
        setError(e.message);
      }
      setTokenValidated(true);
    })();
  }, [token, client]);

  const onSubmit = React.useCallback(
    async ({
      email,
      password,
      onError,
    }: {
      email: string;
      password: string;
      onError: (e: string) => void;
    }) => {
      if (!email) {
        onError('Email is required');
        return;
      }
      if (!password) {
        onError('Password is required');
        return;
      }

      try {
        await login(history, onCompleted, email, password, token);
      } catch (e) {
        onError(e.message);
      }
    },
    [token, onCompleted, history]
  );

  if (!tokenValidated) {
    return null;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{error}</BasicErrorScreen>;
  }

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Log in" />
      <LoginScreenContents token={token} invitedUser={invitedUser} onSubmit={onSubmit} />
    </AuxiliaryScreen>
  );
}
