export function filterNotNull<T>(values: (T | null | undefined)[]): T[] {
  return values.filter(v => !!v) as T[];
}

export function filterNotDeleted<T extends { deleted: boolean }>(values: T[]): T[] {
  return values.filter(v => !v.deleted);
}

export function filterNotDeletedNotNull<T extends { deleted: boolean }>(values: (T | null)[]): T[] {
  return values.filter(v => !!v && v.deleted === false) as T[];
}

export function notNull<T>(value: T | null): NonNullable<T> {
  return value!;
}

export function notDeleted<T extends { deleted: boolean }>(value: T | null): T | null {
  return value?.deleted ? null : value ?? null;
}

export function mapNotNull<T, U>(values: T[], map: (value: T) => U): NonNullable<U>[] {
  return filterNotNull(values.map(map)) as NonNullable<U>[];
}

export function mapNotDeleted<T extends { deleted: boolean }, U>(
  values: T[],
  func: (value: T) => U
): U[] {
  return values.reduce<U[]>((acc, current) => {
    if (!current.deleted) {
      acc.push(func(current));
    }
    return acc;
  }, []);
}

export function mapNotDeletedNotNull<T extends { deleted: boolean }, U>(
  values: (T | null)[],
  func: (value: T) => U
): U[] {
  return values.reduce<U[]>((acc, current) => {
    if (!!current && !current.deleted) {
      acc.push(func(current));
    }
    return acc;
  }, []);
}
