import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { EditorType } from '../types';

export function withNonInteractiveElements(editor: EditorType) {
  const {
    insertBreak,
    insertSoftBreak,
    insertData,
    insertText,
    insertTextData,
    insertFragment,
    insertNode,
    deleteForward,
    deleteBackward,
    deleteFragment,
  } = editor;

  editor.insertBreak = () => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    insertBreak();
  };

  editor.insertSoftBreak = () => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    insertSoftBreak();
  };

  editor.insertData = data => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    insertData(data);
  };

  editor.insertText = text => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    insertText(text);
  };

  editor.insertTextData = data => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return false;
    }
    return insertTextData(data);
  };

  editor.insertFragment = fragment => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    insertFragment(fragment);
  };

  editor.insertNode = node => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    insertNode(node);
  };

  editor.deleteForward = unit => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    deleteForward(unit);
  };

  editor.deleteBackward = unit => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    deleteBackward(unit);
  };

  editor.deleteFragment = direction => {
    if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
      return;
    }
    deleteFragment(direction);
  };

  return editor;
}
