import cn from 'classnames';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { stringifyDocument } from '../../../shared/slate/utils';
import { Feedback } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { FeedbackMenu } from '../../screens/feedbackScreen/feedbackMenu';
import { FeedbackTags } from '../../screens/feedbackScreen/feedbackTags';
import { useUpdateFeedbacks } from '../../syncEngine/actions/feedback';
import { collaborativeDocByEntitySelector } from '../../syncEngine/selectors/collaborativeDoc';
import { orgEntityKey } from '../../syncEngine/selectors/entities';
import { feedbackPath, feedbackSelector } from '../../syncEngine/selectors/feedback';
import { Button, ButtonSize, ButtonStyle } from './button';
import { CompanyPerson } from './companyPerson';
import { CustomCommand } from './customCommand';
import {
  EntityListItem,
  EntityListItemMainContents,
  EntityListItemMembers,
  EntityListItemMenu,
  EntityListItemMetadata,
  EntityListItemTitle,
} from './entityListItem';
import listItemStyles from './entityListItem.module.scss';
import { EntityInsights, EntityMembers } from './entityMetadata';
import { EntityTitleEditor } from './entityTitleEditor';
import styles from './feedbackListItem.module.scss';
import { useHasKeyNavigationFocus, useKeyNavigationElement } from './keyNavigation';
import { AvatarSize } from './metadata/avatar';
import Label from './metadata/label';
import { MetadataSize } from './metadata/size';

function MetadataLeft({ feedback, focused }: { feedback: Feedback; focused: boolean }) {
  return (
    <EntityListItemMetadata className="ml8 overlappableMetadataContainer">
      <div>
        <CompanyPerson noLogo feedbackId={feedback.id} />
      </div>
      <div
        className={cn('row', 'metadataGap', 'overflowHidden', 'overlappableMetadata', {
          focused,
        })}
      >
        <FeedbackTags size={MetadataSize.Small} feedback={feedback} className="overlappable" />
      </div>
    </EntityListItemMetadata>
  );
}

function MetadataRight({
  feedback,
  focused,
  editMode,
}: {
  feedback: Feedback;
  focused: boolean;
  editMode?: boolean;
}) {
  return (
    <EntityListItemMetadata
      className={cn('ml4', {
        overlappableMetadataContainer: editMode,
        noShrink: !editMode,
      })}
    >
      <div
        className={cn('row', 'metadataGap', 'overflowHidden', {
          focused,
          overlappableMetadata: editMode,
        })}
      >
        <EntityInsights interactable entity={feedback} />
        {feedback.processed && (
          <div
            className={cn({
              overlappable: editMode,
            })}
          >
            <Label noHover color={'gray'} name={'Processed'} size={MetadataSize.Small} />
          </div>
        )}
      </div>
    </EntityListItemMetadata>
  );
}

export function FeedbackListItem({
  id,
  routingState,
  className,
  style,
  keyNavId,
  fat,
  onChangeTitle,
}: {
  id: string;
  routingState?: any;
  className?: string;
  style?: React.CSSProperties;
  keyNavId?: string;
  fat?: boolean;
  onChangeTitle?: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const feedback = useRecoilValue(feedbackSelector(id));
  const organization = useOrganization();
  const focused = useHasKeyNavigationFocus(keyNavId ?? id);
  const history = useHistory();
  const document = useRecoilValue(collaborativeDocByEntitySelector(id));
  const currentUser = useCurrentUser();

  useKeyNavigationElement(keyNavId ?? id, ref);

  if (!feedback) {
    return null;
  }

  const link = {
    pathname: feedbackPath(organization, feedback),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: id,
      ...routingState,
    },
  };

  let mainContent = (
    <>
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <EntityListItemTitle type={'feedback'}>{feedback.title}</EntityListItemTitle>
          <MetadataLeft feedback={feedback} focused={focused} />
        </div>
        <MetadataRight feedback={feedback} focused={focused} />
      </EntityListItemMainContents>
      <EntityListItemMembers>
        <EntityMembers interactable entity={feedback} />
      </EntityListItemMembers>
      <EntityListItemMenu
        date={feedback.updatedAt}
        menuContents={closeMenu => (
          <FeedbackMenu feedback={feedback} closeMenu={closeMenu} onChangeTitle={onChangeTitle} />
        )}
      />
    </>
  );

  if (fat) {
    mainContent = (
      <div className="fullWidth noMinWidth">
        <div className={styles.contentRow}>{mainContent}</div>
        <div className={styles.previewRow}>{stringifyDocument(document?.content ?? [])}</div>
      </div>
    );
  }

  return (
    <Link to={link}>
      <EntityListItem
        fat={fat}
        ref={ref}
        entityNumber={orgEntityKey(feedback)}
        className={cn(className, { [styles.fat]: fat })}
        style={style}
        statusIcon={<CompanyPerson noName size={AvatarSize.Size20} feedbackId={feedback.id} />}
        highlight={feedback.ownerIds.includes(currentUser.id)}
      >
        {mainContent}
        {focused && (
          <>
            <CustomCommand
              command={{
                id: 'open',
                hotkey: 'enter',
                group: CommandGroup.Entities,
                description: `Open`,
                priority: 100,
                handler: () => {
                  history.push(link);
                },
              }}
            />
            {onChangeTitle && (
              <CustomCommand
                command={{
                  id: 'edit-title',
                  hotkey: 't',
                  group: CommandGroup.Entities,
                  description: `Edit title`,
                  priority: 99,
                  handler: () => {
                    onChangeTitle();
                  },
                }}
              />
            )}
          </>
        )}
      </EntityListItem>
    </Link>
  );
}

export function EditFeedbackListItem({
  id,
  className,
  style,
  keyNavId,
  onDone,
}: {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  keyNavId?: string;
  onDone: () => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const feedback = useRecoilValue(feedbackSelector(id));
  const updateFeedbacks = useUpdateFeedbacks();
  const currentUser = useCurrentUser();

  const [title, setTitle] = React.useState(feedback?.title ?? '');

  useKeyNavigationElement(keyNavId ?? id, ref);

  function save() {
    if (feedback?.title !== title && feedback) {
      updateFeedbacks([feedback.id], { title });
    }
    onDone();
  }

  if (!feedback) {
    return null;
  }

  return (
    <EntityListItem
      ref={ref}
      entityNumber={orgEntityKey(feedback)}
      className={className}
      style={style}
      statusIcon={<CompanyPerson noName size={AvatarSize.Size20} feedbackId={feedback.id} />}
      highlight={feedback.ownerIds.includes(currentUser.id)}
    >
      <EntityListItemMainContents>
        <div className="row ellipsis">
          <EntityTitleEditor
            className={cn('headingS', listItemStyles.titleEditor)}
            initialTitle={title}
            onChange={setTitle}
            onSubmit={save}
            onReset={onDone}
            autoFocus
            oneLine
            placeholder={<span className="bodyM">Enter a title</span>}
          />
        </div>
      </EntityListItemMainContents>
      <MetadataRight feedback={feedback} focused editMode />
      <EntityListItemMembers>
        <EntityMembers interactable entity={feedback} />
      </EntityListItemMembers>

      <div className="rowEnd ml24">
        <Button onClick={onDone} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button size={ButtonSize.Small} onClick={save} buttonStyle={ButtonStyle.Primary}>
          Save
        </Button>
      </div>
    </EntityListItem>
  );
}
