import cn from 'classnames';
import { range } from 'lodash';
import * as React from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useReadOnly, useSlateStatic } from 'slate-react';
import { TodoElement } from '../../../shared/slate/types';
import { TodoStatus } from '../../../sync/__generated/models';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import { CheckboxIcon } from './smartTodo';
import styles from './todo.module.scss';

export function StaticTodo({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: TodoElement }) {
  const childNode = range(element.indent).reduce(
    result => (
      <ul data-todo="true" data-checked={`${element.checked}`}>
        {result}
      </ul>
    ),
    <li className="rowAlignStart">
      <div className="row noGrow">
        <div className={cn(styles.controlContainer, 'mr8')}>
          <CheckboxIcon
            status={element.checked ? TodoStatus.Done : TodoStatus.NotStarted}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </div>
      </div>
      <div className={styles.todoText}>{children}</div>
    </li>
  );

  return (
    <ul
      {...attributes}
      className={cn('block', 'listBlock', styles.todo, {
        [styles.todoChecked]: element.checked,
      })}
      data-todo="true"
      data-checked={`${element.checked}`}
    >
      {childNode}
    </ul>
  );
}

export function Todo({
  element,
  attributes,
  children,
}: RenderElementProps & { element: TodoElement }) {
  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop({ smallPadding: true });

  const childNode = range(element.indent).reduce(
    result => (
      <ul data-todo="true" data-checked={`${element.checked}`}>
        {result}
      </ul>
    ),
    <li className="rowAlignStart">
      <div className="row noGrow">
        <div className={cn(styles.controlContainer, 'mr8')}>
          <CheckboxIcon
            status={element.checked ? TodoStatus.Done : TodoStatus.NotStarted}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              // let users uncheck todos even if the todosDisabled flag is set
              // in case they managed to check them before this code was added
              if (readonly || (editor.todosDisabled && !element.checked)) {
                return;
              }
              const path = ReactEditor.findPath(editor, element);
              Transforms.setNodes(editor, { checked: !element.checked }, { at: path });
            }}
          />
        </div>
      </div>
      <div className={styles.todoText}>{children}</div>
    </li>
  );

  return (
    <ul
      {...attributes}
      {...dndAttributes}
      className={cn('block', 'listBlock', dndClassName, styles.todo, {
        [styles.todoChecked]: element.checked,
      })}
      data-todo="true"
      data-checked={`${element.checked}`}
    >
      {dndComponents}
      {childNode}
    </ul>
  );
}
