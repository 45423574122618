import cn from 'classnames';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { LoomElement } from '../../../shared/slate/types';
import { normalizeLoomLink } from '../../api/loom';
import { ButtonStyle, IconButton } from '../../components/new/button';
import { CommentButton } from '../../components/new/commentButton';
import { useEnsureFocusedElementIsVisible } from '../../components/new/keyNavigation';
import { Tooltip } from '../../components/new/tooltip';
import { VoidActions } from '../../components/new/voidActions';
import UploadPlaceholder from '../../slate/components/uploadPlaceholder';
import { collapsedSelector } from '../collapsed';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { useResizeObserver } from '../staticSlateHelpers';
import { OptionalAttributesRenderElementProps } from '../types';
import { CollapsedInnerVoidBlock } from './collapsed';
import { DummyNode } from './dummyNode';
import styles from './loom.module.scss';
import { VoidBlock } from './voidBlock';

export function StaticLoom({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: LoomElement }) {
  const { url } = element;
  const ref = React.useRef<HTMLDivElement>(null);
  useResizeObserver(ref, url);
  const [collapsed, setCollapsed] = useRecoilState(collapsedSelector(element.url ?? ''));

  if (!url) {
    return <DummyNode element={element}>{children}</DummyNode>;
  }

  return (
    <div className="block fullWidth" {...attributes}>
      {collapsed && (
        <CollapsedInnerVoidBlock
          element={element}
          name="Loom video"
          icon="loom"
          setCollapsed={setCollapsed}
        />
      )}
      <div
        className={cn(styles.loom, styles.hover, {
          maxHeightZero: collapsed,
          invisibleWithWidth: collapsed,
        })}
        ref={ref}
      >
        <div className={styles.iframeContainer}>
          <iframe src={url.replace('/share/', '/embed/')} frameBorder={0} allowFullScreen></iframe>
        </div>
        <VoidActions floating className={styles.actions}>
          <Tooltip content={collapsed ? 'Expand' : 'Minimize'}>
            <IconButton
              buttonStyle={ButtonStyle.BareSubtle}
              icon={collapsed ? 'unfold_more' : 'unfold_less'}
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();
                if (element.url) {
                  setCollapsed(old => !old);
                }
              }}
            />
          </Tooltip>
        </VoidActions>
      </div>
      {children}
    </div>
  );
}

export function Loom({
  attributes,
  element,
  children,
}: RenderElementProps & { element: LoomElement }) {
  const editor = useSlateStatic();
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const ref = React.useRef<HTMLDivElement>(null);
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();
  const [collapsed, setCollapsed] = useRecoilState(collapsedSelector(element.url ?? ''));

  const { url } = element;

  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();

  const selectionCollapsedRef = React.useRef(selected && selectionCollapsed);
  selectionCollapsedRef.current = selected && selectionCollapsed;

  const sizeObserver = React.useRef(
    window.ResizeObserver
      ? new window.ResizeObserver(() => {
          if (selectionCollapsedRef.current && ref.current) {
            scrollIntoView(ref.current, {
              block: 'center',
              behavior: 'auto',
              scrollMode: 'if-needed',
            });
            ensureVisible();
            KitemakerEditor.ensureFocusOnScreen(editor, 0);
          }
        })
      : null
  );

  React.useEffect(() => {
    if (!ref.current || !sizeObserver.current) {
      return;
    }
    const observer = sizeObserver.current;
    const observed = ref.current;
    observer.observe(observed);
    return () => {
      observer.unobserve(observed);
    };
  }, [sizeObserver, url]);

  if (!url) {
    return (
      <UploadPlaceholder
        requireUrl
        attributes={attributes}
        element={element}
        icon="loom"
        placeholder="Add a Loom video"
        focusedPlaceholder="Enter a Loom URL"
        validateInput={maybeLoomUrl => {
          const normalizedLink = normalizeLoomLink(maybeLoomUrl);
          if (!normalizedLink) {
            throw Error(`That doesn't appear to be a Loom link`);
          }
          return normalizedLink;
        }}
        onSubmit={result => {
          ReactEditor.focus(editor);

          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { url: result }, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }

  return (
    <div
      {...attributes}
      {...dndAttributes}
      className={cn('block', 'relative', styles.hover, dndClassName)}
    >
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <VoidBlock element={element} className="fullWidth" iframe>
        {collapsed && (
          <CollapsedInnerVoidBlock
            element={element}
            name="Loom video"
            icon="loom"
            setCollapsed={setCollapsed}
          />
        )}
        <div
          ref={ref}
          className={cn(styles.loom, { maxHeightZero: collapsed, invisibleWithWidth: collapsed })}
        >
          <div className={styles.iframeContainer}>
            <iframe
              src={url.replace('/share/', '/embed/')}
              frameBorder={0}
              allowFullScreen
            ></iframe>
          </div>
          {editor.inlineComments && editor.entityId && (
            <VoidActions floating className={styles.actions}>
              <Tooltip content={collapsed ? 'Expand' : 'Minimize'}>
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon={collapsed ? 'unfold_more' : 'unfold_less'}
                  className="ml4"
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (element.url) {
                      setCollapsed(old => !old);
                    }
                  }}
                />
              </Tooltip>
              <CommentButton element={element} />
            </VoidActions>
          )}
        </div>
      </VoidBlock>
    </div>
  );
}
