import cn from 'classnames';
import * as React from 'react';
import { Editor, Transforms } from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import { languages } from '../../../shared/slate/syntaxHighlightingLanguages';
import { CodeElement } from '../../../shared/slate/types';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { FilteredListView } from '../../components/new/filteredListView';
import { Icon } from '../../components/new/icon';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import { ListViewItem } from '../../components/new/listView';
import menuStyles from '../../components/new/menu/menu.module.scss';
import Popover from '../../components/new/popover';
import { highlightElement } from '../decorators/syntaxHighlighting';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './code.module.scss';

export function StaticCode({
  attributes,
  children,
  element,
}: OptionalAttributesRenderElementProps & { element: CodeElement }) {
  const { language } = element;
  const ref = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!ref.current || !language) {
      return;
    }
    highlightElement(ref.current);
  }, [language]);

  return (
    <pre className={cn('block', styles.code, styles.static)}>
      <div className={styles.codeScroller}>
        <code
          ref={ref}
          className={cn(language ? `language-${language}` : '', 'bodyMonospaceM')}
          {...attributes}
        >
          {children}
        </code>
      </div>
    </pre>
  );
}

function LanguageDropdown({
  language,
  onLanguageChanged,
}: {
  language: string;
  onLanguageChanged: (languge: string) => void;
}) {
  const items: ListViewItem[] = Object.keys(languages)
    .sort()
    .map(l => ({
      id: l,
      contents: languages[l],
      onSelected: () => {
        onLanguageChanged(l);
      },
    }));

  items.unshift({
    id: 'none',
    contents: 'None',
    onSelected: () => {
      onLanguageChanged('');
    },
  });

  return (
    <div className={styles.languageDropDown} contentEditable={false}>
      <Popover
        content={
          <KeyNavigationProvider columnIds={['languages']}>
            <FilteredListView
              keyNavId="languages"
              items={items}
              className={styles.languageDropDownContent}
              itemClassName={menuStyles.item}
              propertiesToSearch={['id', 'contents']}
            />
          </KeyNavigationProvider>
        }
        asChild
      >
        <div>
          <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.SecondaryOverlay}>
            {languages[language] ?? 'None'}
            <Icon style={{ fill: 'var(--grayA8)' }} icon={'arrow_down'} className="ml4" />
          </Button>
        </div>
      </Popover>
    </div>
  );
}

export function Code({
  element,
  attributes,
  children,
}: RenderElementProps & { element: CodeElement }) {
  const editor = useSlateStatic();
  const selected = useSelected();
  const currentLanguage = element.language ?? '';
  const readonly = useReadOnly();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  if (readonly) {
    return (
      <StaticCode element={element} attributes={attributes}>
        {children}
      </StaticCode>
    );
  }

  return (
    <div
      className={cn('block', styles.codeContainer, dndClassName)}
      {...attributes}
      {...dndAttributes}
    >
      <div className={styles.code}>
        {dndComponents}
        {selected && (
          <LanguageDropdown
            language={currentLanguage}
            onLanguageChanged={language => {
              const path = ReactEditor.findPath(editor, element);
              Transforms.setNodes(editor, { language }, { at: path });
              setTimeout(() => {
                ReactEditor.focus(editor);
                const range = Editor.end(editor, path);
                Transforms.select(editor, range);
              }, 300);
            }}
          />
        )}
        <pre spellCheck={false}>
          <div className={styles.codeScroller}>
            <code className="bodyMonospaceM">{children}</code>
          </div>
        </pre>
      </div>
    </div>
  );
}
