import { toast } from '../components/toast';
import { useConfiguration } from '../contexts/configurationContext';
import { useOrganization } from '../contexts/organizationContext';
import { externalAuthFlow } from './auth';

export async function importEmojis(integrationId: string, legacy = false) {
  const res = await fetch(`/integrations/slack${legacy ? '-legacy' : ''}/emojis`, {
    method: 'POST',
    body: JSON.stringify({ integrationId }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  const jsonResult = await res.json();
  const error = jsonResult.errors?.[0]?.message;

  if (!res.ok || res.status !== 200) {
    toast.error(error ?? `Failed to import emojis`);
    return;
  }

  toast.success('Emojis imported successfully');
}

export function useReauthorize() {
  const organization = useOrganization();
  const { electronScheme, host } = useConfiguration();

  return () => {
    externalAuthFlow(
      `${host}/integrations/slack/install?organizationId=${organization.id}&reinstall=true`,
      electronScheme,
      {
        redirectQueryParams: {
          pendingIntegration: 'slack',
        },
        exchange: true,
      }
    );
  };
}
