import cn from 'classnames';
import * as React from 'react';
import logoDark from '../../assets/images/new/logo-on_dark.svg?url';
import logoLight from '../../assets/images/new/logo-on_white.svg?url';
import { useDarkMode } from '../../hooks/useDarkMode';
import ExternalLink from '../externalLink';
import styles from './logo.module.scss';

export enum LogoSize {
  LogoSize72 = 72,
  LogoSize90 = 90,
}

const sizesToStyles = {
  [LogoSize.LogoSize72]: styles.size72,
  [LogoSize.LogoSize90]: styles.size90,
};

export function Logo({
  size = LogoSize.LogoSize72,
  className,
  style,
  link,
}: {
  size?: LogoSize;
  className?: string;
  style?: React.CSSProperties;
  link?: boolean;
}) {
  const { darkMode } = useDarkMode();
  const logo = (
    <img
      className={cn(styles.logo, sizesToStyles[size], className)}
      style={style}
      src={darkMode ? logoDark : logoLight}
    />
  );

  if (!link) {
    return logo;
  }

  return <ExternalLink href="https://kitemaker.co">{logo}</ExternalLink>;
}
