import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Reaction } from '../../../sync/__generated/models';
import { actorNamesSelector } from '../../syncEngine/selectors/actors';
import { Emoji, EmojiSize } from './emoji';
import { EmojiPicker } from './emojiPicker';
import { Icon, IconSize } from './icon';
import Popover from './popover';
import styles from './reactions.module.scss';
import { Tooltip } from './tooltip';

function ReactionButtonComponent(
  {
    own,
    children,
    onClick,
  }: {
    own?: boolean;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      className={cn(styles.reactionButton, {
        [styles.own]: own,
      })}
      ref={ref}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

const ReactionButton = React.forwardRef(ReactionButtonComponent);

export function useToggleReactions(
  currentUserId: string,
  reactions: Reaction[],
  onReactionsChanged: (reactions: Reaction[]) => void
) {
  const toggleReaction = React.useCallback(
    (reaction: string) => {
      const ownReaction = reactions.find(r => r.actorId === currentUserId);
      if (ownReaction?.reactions.includes(reaction)) {
        const newReactions = reactions
          .filter(r => r.actorId !== currentUserId)
          .map(r => ({ actorId: r.actorId, reactions: r.reactions }));
        if (ownReaction.reactions.length > 1) {
          newReactions.push({
            actorId: currentUserId,
            reactions: ownReaction.reactions.filter(r => r !== reaction),
          });
        }
        onReactionsChanged(newReactions);
        return;
      }

      const newOwnReaction = {
        actorId: currentUserId,
        reactions: [...(ownReaction?.reactions ?? []), reaction],
      };
      const newReactions = [
        ...reactions
          .filter(r => r.actorId !== currentUserId)
          .map(r => ({ actorId: r.actorId, reactions: r.reactions })),
        newOwnReaction,
      ];
      onReactionsChanged(newReactions);
    },
    [reactions, onReactionsChanged, currentUserId]
  );

  return toggleReaction;
}

export function Reactions({
  reactions,
  onReactionsChanged,
  currentUserId,
  tooltipContents,
  className,
  style,
}: {
  reactions: Reaction[];
  onReactionsChanged: (reactions: Reaction[]) => void;
  currentUserId: string;
  tooltipContents: (reaction: string, actorIds: string[]) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const close = React.useCallback(() => setPopoverOpen(false), [setPopoverOpen]);
  const toggleReaction = useToggleReactions(currentUserId, reactions, onReactionsChanged);

  const reactionCounts: Record<string, { own: boolean; actorIds: string[] }> = reactions.reduce(
    (result, reaction) => {
      const res = { ...result };
      for (const r of reaction.reactions) {
        if (!res[r]) {
          res[r] = { own: false, actorIds: [] };
        }

        res[r].actorIds.push(reaction.actorId);
        if (reaction.actorId === currentUserId) {
          res[r].own = true;
        }
      }
      return res;
    },
    {} as Record<string, { own: boolean; actorIds: string[] }>
  );

  const renderedReactions = Object.entries(reactionCounts)
    .sort()
    .map(([reaction, reactors]) => (
      <Tooltip key={reaction} content={tooltipContents(reaction, reactors.actorIds)}>
        <div>
          <ReactionButton
            own={reactors.own}
            onClick={() => {
              toggleReaction(reaction);
            }}
          >
            <div className="row">
              <span className={styles.emoji}>
                <Emoji emoji={reaction} />
              </span>
              <span className={styles.count}>{reactors.actorIds.length}</span>
            </div>
          </ReactionButton>
        </div>
      </Tooltip>
    ));

  return (
    <div className={cn(styles.reactions, className)} style={style}>
      {renderedReactions}
      <Popover
        onOpenChange={setPopoverOpen}
        open={popoverOpen}
        asChild
        content={
          <EmojiPicker
            onPicked={reaction => {
              close();
              toggleReaction(reaction);
            }}
          />
        }
      >
        <ReactionButton onClick={() => setPopoverOpen(true)}>
          <Icon icon="emoji" size={IconSize.Size16} />
        </ReactionButton>
      </Popover>
    </div>
  );
}

export function ReactionTooltipContents({
  reaction,
  actorNames,
}: {
  reaction: string;
  actorNames: string;
}) {
  return (
    <div className={styles.hover}>
      <div className={styles.bigEmoji}>
        <Emoji emoji={reaction} size={EmojiSize.Emoji50} />
      </div>
      <div className="textCenter mb4">
        <span className="headingXS">{actorNames}</span> reacted with
        <br />:{reaction}:
      </div>
    </div>
  );
}

export function ReactionTooltip({ reaction, actorIds }: { reaction: string; actorIds: string[] }) {
  const actorNames = useRecoilValue(actorNamesSelector(actorIds));
  return <ReactionTooltipContents reaction={reaction} actorNames={actorNames} />;
}
