import * as React from 'react';
import { Editor, Node, Path, Point, Text, Transforms } from 'slate';
import { useConfiguration } from '../../contexts/configurationContext';
import { debug } from '../../slate/plugins/withDebug';
import { TextAreaHandle } from '../../slate/textArea';
import { CustomCommand } from '../new/customCommand';

function randomValue(s: number, e: number): number {
  return s + Math.floor((e - s + 1) * Math.random());
}

function randomChar(): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789             ';
  return characters.charAt(randomValue(0, characters.length - 2));
}

function applyRandomOperations(
  editor: any,
  options?: {
    iterations?: number;
    interval?: number;
    disableSplits?: boolean;
    disableMerge?: boolean;
    disableMove?: boolean;
  }
) {
  const interval = options?.interval ?? 0;
  const iterations = options?.iterations ?? 1;
  debug('applyRandomOperations - Running', iterations, 'iterations');

  let i = 0;
  const intervalRef = window.setInterval(() => {
    i++;
    if (i >= iterations) {
      debug('applyRandomOperations - Completed running', iterations, 'iterations');
      window.clearInterval(intervalRef);
      return;
    }

    let textNodes = Node.texts(editor);

    // count elements
    let n = textNodes.next();
    let numElements = 0;
    while (!n.done) {
      ++numElements;
      n = textNodes.next();
    }

    const nodeIdx = randomValue(0, numElements - 1); // Node index to add operation

    textNodes = Node.texts(editor);
    n = textNodes.next();
    let idx = 0;
    while (!n.done && idx <= nodeIdx) {
      if (typeof n.value === 'object' && idx === nodeIdx) {
        const do_insert = (n.value[0] as Text).text.length > 0 ? Math.random() > 0.5 : true;

        if (do_insert) {
          const do_split =
            (!options?.disableSplits && Math.random() > 0.5 && numElements < 10) ||
            n.value[0].text.length > 500; // Always split if the size of the text node is over 500

          if (do_split) {
            const at: Point = {
              path: n.value[1],
              offset: randomValue(0, (n.value[0].text as string).length),
            };

            Transforms.splitNodes(editor, { at });
          } else {
            const do_move = !options?.disableMove && Math.random() > 0.5 && numElements > 1;
            if (do_move) {
              let newPath = [randomValue(0, numElements - 1)];
              while (Path.equals(newPath, [n.value[1][0]])) {
                newPath = [randomValue(0, numElements - 1)];
              }
              Transforms.moveNodes(editor, { at: [n.value[1][0]], to: newPath });
            } else {
              const numChars = randomValue(1, 5);

              let s = '';

              for (let j = 0; j < numChars; ++j) {
                s = s + `${randomChar()}`;
              }

              const at: Point = {
                path: n.value[1],
                offset: randomValue(0, (n.value[0].text as string).length),
              };
              Transforms.insertText(editor, s, { at });
            }
          }
        } else {
          const do_merge =
            (!options?.disableMerge && numElements >= 2 && Math.random() > 0.5) || numElements > 10;
          if (do_merge && n.value[1][0] > 0) {
            if (Path.isPath(n.value[1])) {
              Transforms.mergeNodes(editor, { at: n.value[1] });
            }
          } else {
            const index = randomValue(
              Path.equals(n.value[1], [0, 0]) ? 1 : 0,
              (n.value[0].text as string).length - 1
            );

            if (index >= (n.value[0].text as string).length - 1) {
              return;
            } // Can't delete anything, make a nop

            let distance = randomValue(1, 3);

            if (distance >= (n.value[0].text as string).length - index) {
              distance = (n.value[0].text as string).length - index - 1;
            }
            const at: Point = {
              path: n.value[1],
              offset: randomValue(0, (n.value[0].text as string).length),
            };
            Transforms.delete(editor, { at, distance });
          }
        }
        Editor.normalize(editor);
      }

      n = textNodes.next();
      ++idx;
    }
  }, interval);
}

export function TextAreaChaosMode({
  textAreaRef,
}: {
  textAreaRef: React.RefObject<TextAreaHandle>;
}) {
  const { production } = useConfiguration();
  if (production) {
    return null;
  }

  return (
    <CustomCommand
      command={{
        id: 'text-area chaos-mode',
        description: 'Editor chaos mode',
        handler: () =>
          applyRandomOperations(textAreaRef.current?.raw(), {
            iterations: 500,
            interval: 100,
            disableMove: true,
          }),
      }}
    />
  );
}
