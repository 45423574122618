import { capitalize } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { renderDate } from '../../utils/datetime';
import { Tooltip } from './tooltip';

export function Timestamp({
  timestamp,
  className,
  style,
}: {
  timestamp: number;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <Tooltip content={<>{moment(timestamp).format('MMMM Do YYYY, h:mma')}</>}>
      <span className={className} style={style}>
        {capitalize(renderDate(timestamp, { daysAgo: true, hoursAndMinutesAgo: true }))}
      </span>
    </Tooltip>
  );
}
