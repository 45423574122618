import { Range } from 'slate';
import { useSlateSelector } from 'slate-react';
import { safeSelection } from '../../../shared/slate/utils';

export function useSelectionCollapsed() {
  return useSlateSelector(editor => {
    const selection = safeSelection(editor);
    return !!selection && Range.isCollapsed(selection);
  });
}
