import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { handleLogout, resendVerification } from '../api/auth';
import { useOrganization } from '../contexts/organizationContext';
import { useCurrentUser } from '../contexts/userContext';
import { isElectron } from '../electronIntegration';
import { useDarkMode } from '../hooks/useDarkMode';
import { spellCheckDisabledState } from '../slate/textArea';
import { useUpdatePreferences } from '../syncEngine/actions/users';
import { currentUserMembershipState } from '../syncEngine/selectors/users';
import {
  electronAppleSiliconDownloadUrl,
  electronDownloadUrl,
  isMac,
  isWindows,
} from '../utils/config';
import {
  addNotificationPermissionChangedListener,
  haveNotificationPermission,
  removeNotificationPermissionChangedListener,
  toggleNotifications,
} from '../utils/notifications';
import { CommandDefinition, CommandGroup } from './state';

export function useSettingsCommandGroup(): CommandDefinition[] {
  const organization = useOrganization();
  const { darkMode, forceDarkMode, forceLightMode, resetDarkModePreference, forced } =
    useDarkMode();
  const [browserNotificationsEnabled, setBrowserNotificationsEnabled] = React.useState(
    haveNotificationPermission()
  );
  const member = useRecoilValue(currentUserMembershipState(organization.id));
  const user = useCurrentUser();
  const updatePreferences = useUpdatePreferences();
  const [spellCheckDisabled, setDisableSpellCheck] = useRecoilState(spellCheckDisabledState);
  const animationsDisabled = user.preferences?.animationsDisabled ?? false;

  React.useEffect(() => {
    function notificationsChanged(enabled: boolean) {
      setBrowserNotificationsEnabled(enabled);
    }

    addNotificationPermissionChangedListener(notificationsChanged);
    return () => removeNotificationPermissionChangedListener(notificationsChanged);
  }, []);

  if (!member) {
    return [];
  }

  const commands: CommandDefinition[] = [
    {
      id: 'dark-mode-swtich',
      description: darkMode ? 'Switch to light mode' : 'Switch to dark mode',
      icon: darkMode ? 'light' : 'dark',
      aliases: ['dark mode', 'light mode'],
      handler: () => {
        if (darkMode) {
          forceLightMode();
        } else {
          forceDarkMode();
        }
      },
    },
    {
      id: 'disable-animations',
      description: animationsDisabled ? 'Enable animations' : 'Disable animations',
      icon: 'settings',
      aliases: ['reduced motion'],
      handler: () => {
        updatePreferences({ animationsDisabled: !animationsDisabled });
      },
    },
    {
      id: 'sendFeedback',
      description: 'Send Feedback (e-mail)',
      handler: () => {
        const x = window.open('mailto:hi@kitemaker.com?subject=Kitemaker Feedback');
        x?.close();
      },
      aliases: ['contact'],
      group: CommandGroup.Other,
    },
    {
      id: 'toggleNotifications',
      icon: 'notifications',
      aliases: ['notification settings'],
      description: `${!browserNotificationsEnabled ? 'Enable' : 'Disable'} desktop notifications`,
      handler: () => toggleNotifications(),
    },

    {
      id: 'disableSpellcheck',
      icon: 'settings',
      aliases: ['spellcheck settings'],
      description: `${spellCheckDisabled ? 'Enable' : 'Disable'} spellcheck`,
      handler: () => {
        setDisableSpellCheck(current => !current);
      },
    },
    {
      id: 'logOut',
      description: 'Log out',
      aliases: ['signout'],
      icon: 'signout',
      handler: () => handleLogout(),
    },
    {
      id: 'resendVerification',
      description: `Resend verification to ${user.primaryEmail}`,
      icon: 'settings',
      handler: async () => await resendVerification(user),
    },
  ];

  if (forced) {
    commands.push({
      id: 'dark-mode-reset',
      description: 'Restore default dark mode preference',
      icon: 'refresh',
      handler: () => {
        resetDarkModePreference();
      },
    });
  }

  if (isElectron()) {
    if (isMac) {
      commands.push({
        id: `download-client`,
        description: `Download macOS desktop client`,
        handler: () => {
          window.location.href = electronDownloadUrl;
        },
        priority: 0,
      });
      commands.push({
        id: `download-client-apple-silicon`,
        description: `Download macOS desktop client (Apple Silicon)`,
        handler: () => {
          window.location.href = electronAppleSiliconDownloadUrl;
        },
        priority: 0,
      });
    } else {
      commands.push({
        id: `download-client`,
        description: `Download ${isWindows ? 'Windows' : 'Linux'} desktop client`,
        handler: () => {
          window.location.href = electronDownloadUrl;
        },
        priority: 0,
      });
    }
  }

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Settings }));
}
