import cn from 'classnames';
import * as React from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { IntegrationIcon } from '../../components/new/integrationIcon';
import { stringifyIntegrationType } from '../../utils/integrations';
import { openExternalUrl } from '../../utils/urls';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { InteractivityDisabled } from '../hooks/useInteractivityDisabled';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { ChatElement, OptionalAttributesRenderElementProps } from '../types';
import styles from './chat.module.scss';

export function StaticChat({
  attributes,
  children,
  element,
}: OptionalAttributesRenderElementProps & { element: ChatElement }) {
  return (
    <div {...attributes} className={cn('block', 'colAlignCenter')}>
      <div className={styles.chat}>
        <div
          className={cn(styles.header, {
            [styles.linked]: element.url,
          })}
          contentEditable={false}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            if (!element.url) {
              return;
            }

            openExternalUrl(element.url);
          }}
        >
          {element.integrationType && (
            <>
              <div className="mr8">
                <IntegrationIcon integrationType={element.integrationType} />
              </div>
              {stringifyIntegrationType(element.integrationType)} chat
            </>
          )}
          {!element.integrationType && <>Chat</>}
        </div>
        <div className={styles.messages}>{children}</div>
      </div>
    </div>
  );
}

export function Chat({
  attributes,
  children,
  element,
}: RenderElementProps & { element: ChatElement }) {
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();
  const selected = useFocusedAndSelected();
  const collapsed = useSelectionCollapsed();
  const editor = useSlateStatic();

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', 'colAlignCenter', dndClassName)}>
      {dndComponents}
      <div
        className={cn(styles.chatContainer, {
          [styles.selected]: selected,
        })}
      >
        <div className={styles.chat}>
          <div
            className={cn(styles.header, {
              [styles.linked]: element.url,
            })}
            contentEditable={false}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              if (!collapsed || !selected) {
                e.preventDefault();
                e.stopPropagation();
                ReactEditor.focus(editor);
                Transforms.select(editor, [...ReactEditor.findPath(editor, element)]);
                Transforms.collapse(editor, { edge: 'start' });
                return;
              }

              if (editor.isFocusing || !element.url) {
                return;
              }

              openExternalUrl(element.url);
            }}
          >
            {element.integrationType && (
              <>
                <div className="mr8">
                  <IntegrationIcon integrationType={element.integrationType} />
                </div>
                {stringifyIntegrationType(element.integrationType)} chat
              </>
            )}
            {!element.integrationType && <>Chat</>}
          </div>

          <InteractivityDisabled>
            <div className={styles.messages}>{children}</div>
          </InteractivityDisabled>
        </div>
      </div>
    </div>
  );
}
