import { documentFromString } from '../../../shared/slate/utils';
import { generateId } from '../../../shared/utils/id';
import { collaborativeDocsByEntity, commentsByThread } from '../../../sync/__generated/indexes';
import { Comment, Entity } from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { KitemakerTransforms } from '../../slate/kitemakerTransforms';
import { DocumentLike } from '../../slate/types';
import { trackerEvent } from '../../tracker';
import { isInitiative } from '../selectors/intiatives';
import { isIssue } from '../selectors/issues';
import { indexHelper } from './helpers';

export function useCreateComment() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  return (
    entityId: string,
    body: string,
    options?: { threadId?: string; inline?: boolean; context?: DocumentLike | null }
  ) => {
    const comment: SyncEngineCreate<Comment> = {
      __typename: 'Comment',
      entityId,
      actorId: user.id,
      body,
      threadId: options?.threadId ?? generateId(),
      reply: !!options?.threadId,
      inline: options?.inline ?? false,
      resolved: false,
      reactions: [],
      commentContext: options?.context ?? null,
    };

    return modelManager.transaction((tx, { get }) => {
      const result = tx.create<Comment>(comment);

      const entity = get<Entity>(entityId);
      if (!entity) {
        return;
      }

      trackerEvent('Comment Created', {
        id: result.id,
        itemId: result.entityId,
        itemType: entity.__typename,
        reply: result.reply,
      });

      if (isIssue(entity)) {
        trackerEvent('Work Item Updated', {
          id: entityId,
          type: 'Activity',
          activityType: 'Comment',
        });
      } else if (isInitiative(entity)) {
        trackerEvent('Initiative Updated', {
          id: entityId,
          type: 'Activity',
          activityType: 'Comment',
        });
      }

      return result;
    });
  };
}

export function useUpdateComments() {
  const modelManager = useModelManager();
  return (commentIds: string[], update: SyncEngineUpdateWithoutDelete<Comment>) => {
    modelManager.transaction((tx, { get }) => {
      for (const commentId of commentIds) {
        tx.update<Comment>(commentId, update);
        const comment = get<Comment>(commentId);
        if (!comment) {
          return;
        }
        const entity = get<Entity>(comment.entityId);
        if (!entity) {
          return;
        }
        trackerEvent('Comment Updated', {
          id: comment.id,
          itemId: entity.id,
          itemType: entity.__typename,
          reply: comment.reply,
        });
      }
    });
  };
}

export function useDeleteComments() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return async (commentIds: string[]) => {
    const confirmed = await confirm(
      `Delete comment${commentIds.length > 1 ? 's' : ''}`,
      `Are you sure you want to delete ${
        commentIds.length > 1 ? 'these comments' : 'this comment'
      }? You cannot undo this.`,
      { label: 'Delete', destructive: true }
    );

    if (!confirmed) {
      return [];
    }

    const deleted: string[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const commentId of commentIds) {
        const comment = get<Comment>(commentId);
        if (!comment || comment.deleted) {
          continue;
        }

        const entity = get<Entity>(comment.entityId);
        if (!entity) {
          continue;
        }

        let commentToUnwrap: Comment | null = null;

        // if all the comment in the thread have been deleted, we should remove the comment
        // from any open collaborative editors
        if (comment.inline) {
          const allCommentsForThread = indexHelper<Comment>(
            { get, getIndex },
            commentsByThread,
            comment.threadId,
            true
          );

          if (allCommentsForThread.every(c => c.deleted || c.id === commentId)) {
            commentToUnwrap = allCommentsForThread.find(c => !c.reply) ?? null;
          }
        }

        tx.update<Comment>(commentId, {
          deleted: true,
          body: JSON.stringify(documentFromString('[Comment deleted]')),
        });

        deleted.push(commentId);
        trackerEvent('Comment Deleted', {
          id: comment.id,
          itemId: entity.id,
          itemType: entity.__typename,
          reply: comment.reply,
        });

        if (commentToUnwrap) {
          const docIds = getIndex(collaborativeDocsByEntity, commentToUnwrap.entityId);
          for (const docId of docIds) {
            const editor = modelManager.getCollaborativeDocumentEditor(docId);
            if (editor) {
              KitemakerTransforms.removeComment(editor, commentToUnwrap.id);
            }
          }
        }
      }
    });

    return deleted;
  };
}
