import { sortBy } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { mapColor } from '../../../../shared/utils/colors';
import {
  Initiative,
  Issue,
  IssueLabel as IssueLabelModel,
  OrganizationLabel,
  Space,
} from '../../../../sync/__generated/models';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useCreateLabel } from '../../../syncEngine/actions/labels';
import { useCreateOrganizationLabel } from '../../../syncEngine/actions/organizationLabels';
import {
  labelsForSpaceSelector,
  uniqueLabelsForOrganizationSelector,
} from '../../../syncEngine/selectors/labels';
import { orgLabelsForOrganizationSelector } from '../../../syncEngine/selectors/organizationLabels';
import { ColorPickerContent } from '../colorPicker';
import { FilteredListViewHandle } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { ListViewItem, NO_KEYNAV } from '../listView';
import { Picker, PickerState } from '../picker';
import { ColorListPicker } from './colorListPicker';

export function entitiesToLabelPickerState(entities: Array<Issue | Initiative>): PickerState {
  return entities.reduce((result, issue) => {
    result[issue.id] = issue.labelIds;
    return result;
  }, {} as PickerState);
}

enum Mode {
  Picker,
  Color,
}

function createLabelItem({
  newLabelName,
  onSelected,
}: {
  newLabelName: string;
  onSelected: (shift: boolean) => void;
}): ListViewItem {
  return {
    id: 'add',
    mouseDown: true,
    className: 'createItem',
    icon: 'add',
    onSelected,
    contents: <div className="ellipsis">Create a label named &quot;{newLabelName}&quot;</div>,
  };
}

export function LabelPicker({
  state,
  filterPlaceholder,
  placeholder,
  space: propSpace,
  orgLevel,
  filterClassName,
  listMode,
  disableCreation,
  additionalItems,
  additionalItemsFirst,
  onDone,
  onLabelAdded,
  onLabelRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  placeholder?: string;
  listMode?: boolean;
  space?: Space;
  orgLevel?: boolean;
  disableCreation?: boolean;
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  onDone: () => void;
  onLabelAdded: (issueIds: string[], labelId: string) => void;
  onLabelRemoved: (issueIds: string[], labelId: string) => void;
}) {
  const organization = useOrganization();
  const createSpaceLabel = useCreateLabel();
  const createOrgLabel = useCreateOrganizationLabel();

  const createLabel = orgLevel ? createOrgLabel : createSpaceLabel;

  const contextSpace = useMaybeSpace();
  const space = propSpace ?? contextSpace;
  const requestInProgress = React.useRef(false);
  const spaceLabels = useRecoilValue(labelsForSpaceSelector(space?.id));
  const allSpaceLabels = useRecoilValue(uniqueLabelsForOrganizationSelector(organization.id));
  const organizationLabels = useRecoilValue(orgLabelsForOrganizationSelector(organization.id));

  const labels = (orgLevel ? organizationLabels : space ? spaceLabels : allSpaceLabels) as Array<
    IssueLabelModel | OrganizationLabel
  >;
  const [mode, setMode] = React.useState(Mode.Picker);
  const [shift, setShift] = React.useState(false);
  const [name, setName] = React.useState('');

  const items: ListViewItem[] = sortBy(labels, label => label.name).map(label => {
    const color = mapColor(label.color);
    return {
      ...label,
      mouseDown: true,
      contents: () => (
        <>
          <Icon icon="label" size={IconSize.Size20} style={{ fill: color }} />
          <span className="ellipsis">{label.name}</span>
        </>
      ),
    };
  });

  const hideFooter = labels.length === 0;

  if (hideFooter) {
    items.push({
      id: NO_KEYNAV,
      contents: space ? 'Type to create a new label' : 'No labels found',
    });
  }

  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...(additionalItems ?? []));
    } else {
      items.push(...(additionalItems ?? []));
    }
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  if (mode === Mode.Color) {
    if (listMode) {
      return (
        <ColorListPicker
          state={[]}
          filterClassName={filterClassName}
          filterPlaceholder="Choose a color of your label"
          onPicked={async function (color: string): Promise<void> {
            if (requestInProgress.current || (!orgLevel && !space)) {
              return;
            }
            const issueIds = Object.keys(state);

            requestInProgress.current = true;
            const label = await createLabel(orgLevel ? organization.id : space!.id, name, color);
            requestInProgress.current = false;
            onLabelAdded(issueIds, label.id);
            filterRef.current?.clear();
            if (!shift) {
              onDone();
            }
            setMode(Mode.Picker);
            setShift(false);
            setName('');
          }}
          onDone={onDone}
        />
      );
    }
    return (
      <ColorPickerContent
        onColorPicked={async function (color: string | null): Promise<void> {
          if (requestInProgress.current || (!orgLevel && !space)) {
            return;
          }
          const issueIds = Object.keys(state);

          requestInProgress.current = true;
          const label = await createLabel(
            orgLevel ? organization.id : space!.id,
            name,
            color ?? 'gray'
          );
          requestInProgress.current = false;
          onLabelAdded(issueIds, label.id);
          filterRef.current?.clear();
          if (!shift) {
            onDone();
          }
          setMode(Mode.Picker);
          setShift(false);
          setName('');
        }}
      />
    );
  }

  return (
    <Picker
      filterLabel="Add labels"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Add labels'}
      placeholder={placeholder ?? 'No labels found'}
      ref={filterRef}
      multi
      hideFooter={hideFooter}
      state={state}
      items={items}
      propertiesToSearch={['name']}
      filter={(filterString, search) => {
        const results = search.search(filterString).map(r => r.item);
        if (disableCreation) {
          return results;
        }
        const exactMatch = !!labels.find(l => l.name.toLowerCase() === filterString.toLowerCase());
        if (!exactMatch && (space || orgLevel)) {
          results.push(
            createLabelItem({
              newLabelName: filterString,
              onSelected: async shift => {
                setMode(Mode.Color);
                setShift(shift);
                setName(filterString);
              },
            })
          );
        }
        return results;
      }}
      onAdd={onLabelAdded}
      onRemove={onLabelRemoved}
      onDone={onDone}
    />
  );
}
