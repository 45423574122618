import * as React from 'react';
import {
  FocusReason,
  useKeyNavigationColumn,
  useKeyNavigationWatcher,
  useMultiMove,
} from './keyNavigation';

const ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_LEFT = 'extraColumnLeft';
const ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_RIGHT = 'extraColumnRight';
export const ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS = [
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_LEFT,
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_RIGHT,
];

function EntityScreenKeyNavHackColumn({ id }: { id: string }) {
  useKeyNavigationColumn(id, [`${id}Item`]);
  return null;
}

export function EntityScreenKeyNavHacks() {
  const move = useMultiMove();
  useKeyNavigationWatcher(({ focusedColumnId }) => {
    if (focusedColumnId === ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_LEFT) {
      move(m => {
        m('right');
        m('right');
        m('bottom');
      }, FocusReason.Keyboard);
    }
    if (focusedColumnId === ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_RIGHT) {
      move(m => {
        m('left');
        m('left');
        m('top');
      }, FocusReason.Keyboard);
    }
  });
  return (
    <>
      <EntityScreenKeyNavHackColumn id={ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_LEFT} />
      <EntityScreenKeyNavHackColumn id={ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_ID_RIGHT} />
    </>
  );
}
