import cn from 'classnames';
import NodeCache from 'node-cache';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { GithubElement } from '../../../shared/slate/types';
import { GithubEntityInfo, GithubLink, parseGithubLink } from '../../../shared/utils/github';
import { IntegrationType } from '../../../sync/__generated/models';
import { externalAuthFlow } from '../../api/auth';
import { fetchGitHubEntity } from '../../api/github';
import ExternalLink from '../../components/externalLink';
import { GitStatus, GitStatusType } from '../../components/gitStatus';
import { Icon } from '../../components/new/icon';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import { useConfiguration } from '../../contexts/configurationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { integrationOfTypeSelector } from '../../syncEngine/selectors/integrations';
import { renderDateLongForm } from '../../utils/datetime';
import UploadPlaceholder from '../components/uploadPlaceholder';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import { ErrorElement } from './error';
import styles from './github.module.scss';
import { VoidBlock } from './voidBlock';

const linkCache = new NodeCache({
  stdTTL: 300,
});

function EntityType({ type }: { type: string }) {
  switch (type) {
    case 'issue':
      return <>Issue</>;
    case 'pull':
      return <>Pull request</>;
    default:
      return <></>;
  }
}

function GithubEntity({ info }: { info: GithubEntityInfo & GithubLink }) {
  const createdAt = React.useMemo(() => new Date(info.createdAt).getTime(), [info]);

  return (
    <>
      <div className="noGrow">
        <Icon icon="github" />
      </div>
      <div className="ml8 colStretch noMinWidth grow">
        <div className="row overflowHidden noMinWidth maxWidth">
          <ExternalLink href={info.url} className="link hoverOnly headingS mr10 oneLine ellipsis">
            {info.title}
          </ExternalLink>
          <GitStatus state={info.state as GitStatusType} mergeRequest={info.type === 'pull'} />
        </div>
        <div className="bodyM grayed ellipsis">
          <ExternalLink
            href={`https://github.com/${info.owner}/${info.repo}`}
            className="link hoverOnly semiBold mr4"
          >
            {info.owner}/{info.repo}
          </ExternalLink>
          <EntityType type={info.type} />
          <ExternalLink href={info.url} className="link hoverOnly ml4 mr4">
            #{info.number}
          </ExternalLink>
          opened by
          <ExternalLink href={info.user.url} className="link hoverOnly semiBold ml4 mr4">
            {info.user.login}
          </ExternalLink>
          on {renderDateLongForm(createdAt)}
        </div>
      </div>
    </>
  );
}

export function StaticGithub({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: GithubElement }) {
  const organization = useOrganization();
  const integration = useRecoilValue(
    integrationOfTypeSelector({
      organizationId: organization.id,
      integrationType: IntegrationType.Github,
    })
  );

  const hasGitHubIntegration = !!integration;
  const refreshRef = React.useRef(-1);

  const { url } = element;
  const [linkData, setLinkData] = React.useState<(GithubEntityInfo & GithubLink) | null>(
    url && linkCache.has(url) ? (linkCache.get(url) as GithubEntityInfo & GithubLink) : null
  );
  const [error, setError] = React.useState('');

  const refetch = React.useCallback(
    async (link: string) => {
      if (!hasGitHubIntegration) {
        setError('No Github integration set up');
        return;
      }

      try {
        const entityInfo = parseGithubLink(link);
        if (!entityInfo) {
          setError('This Github URL appears to be invalid');
          return;
        }

        const entity = await fetchGitHubEntity({
          ...entityInfo,
          organization: organization.id,
        });

        if (entity) {
          linkCache.set(link, { ...entity, ...entityInfo });
          setLinkData({ ...entity, ...entityInfo });
        }
      } catch (e) {
        setError(e.message);
      }
    },
    [setError, setLinkData, hasGitHubIntegration, organization.id]
  );

  React.useEffect(() => {
    if (!url) {
      return;
    }
    refetch(url);
  }, [url, refetch]);

  React.useEffect(() => {
    if (!url || !linkData) {
      return;
    }

    refreshRef.current = window.setInterval(() => {
      refetch(url);
    }, 60000);

    return () => {
      if (refreshRef.current !== -1) {
        window.clearInterval(refreshRef.current);
        refreshRef.current = -1;
      }
    };
  }, [linkData, url, refetch]);

  if (!url) {
    return <DummyNode element={element}>{children}</DummyNode>;
  }

  const loading = !linkData;

  return (
    <div className="block row fullWidth unselectable" {...attributes}>
      <div
        className={cn(styles.github, {
          [styles.loaded]: !error && !loading,
        })}
      >
        {error && <ErrorElement error={error} />}
        {!error && (
          <>
            {loading && <LoadingSpinner className={styles.loading} />}
            {!loading && linkData && <GithubEntity info={linkData} />}
          </>
        )}
      </div>
      {children}
    </div>
  );
}

export function Github({
  attributes,
  element,
  children,
}: RenderElementProps & { element: GithubElement }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();
  const prepopulated = !!element.prepopulatedData;
  const editor = useSlateStatic();
  const selected = useFocusedAndSelected();
  const selectionCollapsed = useSelectionCollapsed();

  const integration = useRecoilValue(
    integrationOfTypeSelector({
      organizationId: organization.id,
      integrationType: IntegrationType.Github,
    })
  );

  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  const hasGitHubIntegration = !!integration;
  const refreshRef = React.useRef(-1);

  const ref = React.useRef<HTMLDivElement>(null);

  const { url } = element;
  const [linkData, setLinkData] = React.useState<(GithubEntityInfo & GithubLink) | null>(
    url && linkCache.has(url)
      ? (linkCache.get(url) as GithubEntityInfo & GithubLink)
      : element.prepopulatedData ?? null
  );
  const [error, setError] = React.useState('');

  const refetch = React.useCallback(
    async (link: string) => {
      if (prepopulated) {
        return;
      }
      if (!hasGitHubIntegration) {
        setError('No Github integration set up');
        return;
      }

      try {
        const entityInfo = parseGithubLink(link);
        if (!entityInfo) {
          setError('This Github URL appears to be invalid');
          return;
        }

        const entity = await fetchGitHubEntity({
          ...entityInfo,
          organization: organization.id,
        });

        if (entity) {
          linkCache.set(link, { ...entity, ...entityInfo });
          setLinkData({ ...entity, ...entityInfo });
        }
      } catch (e) {
        setError(e.message);
      }
    },
    [setLinkData, setError, hasGitHubIntegration, organization.id]
  );

  React.useEffect(() => {
    if (!url) {
      return;
    }
    refetch(url);
  }, [url, refetch]);

  React.useEffect(() => {
    if (!url || !linkData) {
      return;
    }

    refreshRef.current = window.setInterval(() => {
      refetch(url);
    }, 60000);

    return () => {
      if (refreshRef.current !== -1) {
        window.clearInterval(refreshRef.current);
        refreshRef.current = -1;
      }
    };
  }, [linkData, url, refetch]);

  React.useEffect(() => {
    if (selected && selectionCollapsed) {
      setTimeout(() => {
        if (!ref.current) {
          return;
        }
        scrollIntoView(ref.current, {
          block: 'center',
          scrollMode: 'if-needed',
          behavior: 'auto',
        });
      });
    }
  }, [selected, selectionCollapsed]);

  if (!url) {
    return (
      <UploadPlaceholder
        requireUrl
        icon="github"
        attributes={attributes}
        element={element}
        placeholder="Add a Github issue or pull request, or paste in a URL"
        focusedPlaceholder="Enter a Github issue or pull request URL"
        validateInput={maybeGithubLink => {
          const normalizedLink = parseGithubLink(maybeGithubLink);
          if (!normalizedLink) {
            throw Error(`That doesn't appear to be a Github link`);
          }
          return maybeGithubLink;
        }}
        onSubmit={async result => {
          ReactEditor.focus(editor);
          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { url: result }, { at: path });
          KitemakerTransforms.moveSelectionToPath(editor, path);

          if (!hasGitHubIntegration) {
            ReactEditor.blur(editor);
            externalAuthFlow(
              `${host}/integrations/github/install?organizationId=${organization!.id}`,
              electronScheme,
              { exchange: true }
            );
          }
        }}
      >
        {children}
      </UploadPlaceholder>
    );
  }

  const loading = !linkData;

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <div className="rowCenter">{children}</div>
      <VoidBlock element={element} className="row fullWidth unselectable">
        <div
          className={cn(styles.github, {
            [styles.loaded]: !error && !loading,
          })}
        >
          {error && <ErrorElement error={error} />}
          {!error && (
            <>
              {loading && <LoadingSpinner className={styles.loading} />}
              {!loading && linkData && <GithubEntity info={linkData} />}
            </>
          )}
        </div>
      </VoidBlock>
    </div>
  );
}
