import * as RadixAvatar from '@radix-ui/react-avatar';
import cn from 'classnames';
import * as React from 'react';
import { hashStringToPaletteColors } from '../../../utils/color';
import { AvatarSize } from './avatar';
import styles from './organizationAvatar.module.scss';

const sizesToStyles = {
  [AvatarSize.Size20]: styles.size20,
  [AvatarSize.Size24]: styles.size24,
  [AvatarSize.Size32]: styles.size30,
  [AvatarSize.Size40]: styles.size40,
  [AvatarSize.Size96]: styles.size96,
};

export default function OrganizationAvatar({
  img,
  name,
  size = AvatarSize.Size20,
  color: propColor,
  className,
}: {
  img?: string | null;
  name: string;
  color?: string;
  size?: AvatarSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  const [loadingState, setLoadingState] = React.useState('');
  const initials = name.includes(' ')
    ? name
        .split(' ')
        .splice(0, 2)
        .map(n => n[0])
    : name[0];

  const color = loadingState !== 'loaded' ? propColor ?? hashStringToPaletteColors(name) : '';
  return (
    <RadixAvatar.Root
      className={cn(styles.root, sizesToStyles[size], 'fs-exclude', className)}
      style={{ ...styles, '--orgColor': `var(--${color}A10)` }}
    >
      {img && (
        <RadixAvatar.Image
          onLoadingStatusChange={setLoadingState}
          className={styles.image}
          src={img}
        />
      )}
      <RadixAvatar.Fallback className={styles.fallback} delayMs={img ? 600 : undefined}>
        {initials}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}
