import { partition } from 'lodash';
import { Organization } from '../../../../graphql__generated__/graphql';
import {
  docWatchers,
  feedbackOwners,
  feedbackWatchers,
  initiativeLabels,
  initiativeMembers,
  initiativeWatchers,
  issueAssignees,
  issueLabels,
  issueWatchers,
  releaseMembers,
  releaseWatchers,
} from '../../../sync/__generated/collections';
import {
  issuesByAssignee,
  organizationMembersByOrganization,
} from '../../../sync/__generated/indexes';
import {
  Entity,
  Feedback,
  Initiative,
  Issue,
  IssueStatus,
  IssueStatusType,
  MemberRole,
  OrganizationMember,
  Release,
  Space,
} from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import { SyncEngineUpdateWithoutDelete, useModelManager } from '../../graphql/modelManager';
import { isDocument } from '../selectors/documents';
import { isFeedback } from '../selectors/feedback';
import { isInitiative } from '../selectors/intiatives';
import { isIssue } from '../selectors/issues';
import { isRelease } from '../selectors/releases';

export enum SortChange {
  MoveUpOne,
  MoveDownOne,
  MoveToTop,
  MoveToBottom,
}

export function useUpdateEntities() {
  const modelManager = useModelManager();
  return (
    entityIds: string[],
    update: Omit<SyncEngineUpdateWithoutDelete<Issue | Initiative>, 'sort'>
  ) => {
    modelManager.transaction(tx => {
      for (const entityId of entityIds) {
        tx.update<Entity>(entityId, update);
      }
    });
  };
}

export function useAddLabelsToEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], labelIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Entity>(entityId);
        if (!entity) {
          continue;
        }
        if (isIssue(entity)) {
          tx.addToCollection(issueLabels, entityId, labelIds);
        } else if (isInitiative(entity)) {
          tx.addToCollection(initiativeLabels, entityId, labelIds);
        }
      }
    });
  };
}

export function useRemoveLabelsFromEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], labelIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Entity>(entityId);
        if (!entity) {
          continue;
        }
        if (isIssue(entity)) {
          tx.removeFromCollection(issueLabels, entityId, labelIds);
        } else if (isInitiative(entity)) {
          tx.removeFromCollection(initiativeLabels, entityId, labelIds);
        }
      }
    });
  };
}

export function useToggleLabelsForEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], labelIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Issue>(entityId);
        if (!entity) {
          continue;
        }

        const [toRemove, toAdd] = partition(labelIds, labelId => entity.labelIds.includes(labelId));

        if (isIssue(entity)) {
          tx.removeFromCollection(issueLabels, entityId, toRemove);
          tx.addToCollection(issueLabels, entityId, toAdd);
        }
      }
    });
  };
}

export function useAddAssigneesToEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], assigneeIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Entity>(entityId);
        if (!entity) {
          continue;
        }
        if (isIssue(entity)) {
          tx.addToCollection(issueAssignees, entityId, assigneeIds);
        } else if (isInitiative(entity)) {
          tx.addToCollection(initiativeMembers, entityId, assigneeIds);
        } else if (isRelease(entity)) {
          tx.addToCollection(releaseMembers, entityId, assigneeIds);
        }
      }
    });
  };
}

export function useRemoveAssigneesFromEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], assigneeIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Entity>(entityId);
        if (!entity) {
          continue;
        }
        if (isIssue(entity)) {
          tx.removeFromCollection(issueAssignees, entityId, assigneeIds);
        } else if (isFeedback(entity)) {
          tx.removeFromCollection(feedbackOwners, entityId, assigneeIds);
        } else if (isInitiative(entity)) {
          tx.removeFromCollection(initiativeMembers, entityId, assigneeIds);
        } else if (isRelease(entity)) {
          tx.removeFromCollection(releaseMembers, entityId, assigneeIds);
        }
      }
    });
  };
}

export function useToggleAssigneesForEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], assigneeIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      const isMember = entityIds.every(entityId => {
        const entity = get<Issue | Feedback | Initiative | Release>(entityId);
        if (!entity) {
          return false;
        }
        let existingIds: string[] = [];
        if (isIssue(entity)) {
          existingIds = entity.assigneeIds;
        } else if (isInitiative(entity) || isRelease(entity)) {
          existingIds = entity.memberIds;
        } else {
          existingIds = entity.ownerIds;
        }
        return assigneeIds.every(assigneeId => existingIds.includes(assigneeId));
      });

      for (const entityId of entityIds) {
        const entity = get<Issue | Initiative>(entityId);
        if (!entity) {
          continue;
        }

        if (isMember) {
          if (isIssue(entity)) {
            tx.removeFromCollection(issueAssignees, entityId, assigneeIds);
          } else if (isFeedback(entity)) {
            tx.removeFromCollection(feedbackOwners, entityId, assigneeIds);
          } else if (isInitiative(entity)) {
            tx.removeFromCollection(initiativeMembers, entityId, assigneeIds);
          } else if (isRelease(entity)) {
            tx.removeFromCollection(releaseMembers, entityId, assigneeIds);
          }
        } else {
          if (isIssue(entity)) {
            tx.addToCollection(issueAssignees, entityId, assigneeIds);
          } else if (isFeedback(entity)) {
            tx.addToCollection(feedbackOwners, entityId, assigneeIds);
          } else if (isInitiative(entity)) {
            tx.addToCollection(initiativeMembers, entityId, assigneeIds);
          } else if (isRelease(entity)) {
            tx.addToCollection(releaseMembers, entityId, assigneeIds);
          }
        }
      }
    });
  };
}

export function useReplaceAssigneesForEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], userId: string) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Issue | Feedback | Initiative | Release>(entityId);
        if (!entity) {
          continue;
        }

        if (isIssue(entity)) {
          tx.removeFromCollection(issueAssignees, entityId, entity.assigneeIds);
          tx.addToCollection(issueAssignees, entityId, [userId]);
        } else if (isInitiative(entity)) {
          tx.removeFromCollection(initiativeMembers, entityId, entity.memberIds);
          tx.addToCollection(initiativeMembers, entityId, [userId]);
        } else if (isFeedback(entity)) {
          tx.removeFromCollection(feedbackOwners, entityId, entity.ownerIds);
          tx.addToCollection(feedbackOwners, entityId, [userId]);
        } else if (isRelease(entity)) {
          tx.removeFromCollection(releaseMembers, entityId, entity.memberIds);
          tx.addToCollection(releaseMembers, entityId, [userId]);
        }
      }
    });
  };
}

export function useRemoveInactiveUserAssignees() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return (spaceId: string) => {
    modelManager.transaction(async (tx, { get, getIndex }) => {
      const space = get<Space>(spaceId)!;

      const confirmed = await confirm(
        `Sure?`,
        `Sure you want to remove all inactive users from work items in ${space.name}? You can not undo this action.`
      );
      if (!confirmed) {
        return;
      }
      const organization = get<Organization>(space?.organizationId)!;
      const organizationMembersIds = getIndex(organizationMembersByOrganization, organization.id);

      for (const membershipId of organizationMembersIds) {
        const member = get<OrganizationMember>(membershipId)!;

        const issueIds = getIndex(issuesByAssignee, member.userId!);
        for (const issueId of issueIds) {
          const issue = get<Issue>(issueId)!;
          const status = get<IssueStatus>(issue?.statusId)!;
          if (
            issue.spaceId === space.id &&
            [IssueStatusType.InProgress, IssueStatusType.Todo, IssueStatusType.Backlog].includes(
              status?.statusType
            )
          ) {
            if (
              member?.deactivated ||
              (member?.role === MemberRole.Guest && !space?.members.includes(member?.userId)) ||
              (space.private && !space?.members.includes(member?.userId))
            ) {
              tx.removeFromCollection(issueAssignees, issueId, [member?.userId]);
            }
          }
        }
      }
    });
  };
}

export function useToggleWatchersForEntities() {
  const modelManager = useModelManager();
  return (entityIds: string[], watcherIds: string[]) => {
    modelManager.transaction((tx, { get }) => {
      for (const entityId of entityIds) {
        const entity = get<Issue | Initiative | Release>(entityId);
        if (!entity) {
          continue;
        }

        const [toRemove, toAdd] = partition(watcherIds, watcherId =>
          entity.watcherIds?.includes(watcherId)
        );

        if (isIssue(entity)) {
          tx.removeFromCollection(issueWatchers, entityId, toRemove);
          tx.addToCollection(issueWatchers, entityId, toAdd);
        } else if (isInitiative(entity)) {
          tx.removeFromCollection(initiativeWatchers, entityId, toRemove);
          tx.addToCollection(initiativeWatchers, entityId, toAdd);
        } else if (isDocument(entity)) {
          tx.removeFromCollection(docWatchers, entityId, toRemove);
          tx.addToCollection(docWatchers, entityId, toAdd);
        } else if (isFeedback(entity)) {
          tx.removeFromCollection(feedbackWatchers, entityId, toRemove);
          tx.addToCollection(feedbackWatchers, entityId, toAdd);
        } else if (isRelease(entity)) {
          tx.removeFromCollection(releaseWatchers, entityId, toRemove);
          tx.addToCollection(releaseWatchers, entityId, toAdd);
        }
      }
    });
  };
}
