import { isEqual } from 'lodash';
import { Editor, NodeEntry, Range, Text } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../../../shared/slate/kitemakerNode';
import { Elements } from '../../../../shared/slate/types';
import { safeSelection } from '../../../../shared/slate/utils';
import { FuzzySearcher, FuzzySearcherConfiguration } from '../../../utils/search';
import { isNonLetter } from '../../../utils/text';
import { EditorType } from '../../types';

const maxSuggestions = 50;
export const trailingSpace = new WeakMap<EditorType, boolean>();

export interface Suggestion {
  id: string;
  render: () => React.ReactNode;
  accept: () => void;
  expand?: () => void;
}

export interface SuggestionState {
  suggestions: Suggestion[] | null;
  className?: string;
  header?: () => React.ReactNode;
}

export interface SuggestionOption {
  id: string;
  value: string;
  render: () => React.ReactNode;
  suggestionAutoCompleteOnly?: boolean;
  footer?: boolean;
  expandable?: boolean;
}

export interface SuggestionMatcher {
  id: string;
  options: (partialMatch: string, editor: EditorType) => SuggestionOption[];
  search?: (
    partialMatch: string,
    asyncResults: (results: SuggestionOption[]) => void
  ) => Promise<SuggestionOption[]>;
  onMatch: (
    editor: EditorType,
    option: SuggestionOption,
    range: Range,
    autoComplete?: boolean
  ) => void;
  propertiesToSearch?: string[];
  prefix: string;
  suffix?: string;
  handleTrailingSpace?: boolean;
  searchOnEmptyString?: boolean;
  className?: string;
  header?: () => React.ReactNode;
  expandedOptions?: (option: SuggestionOption) => SuggestionOption[];
}

interface MatcherState {
  options: SuggestionOption[];
  optionsByValue: Record<string, SuggestionOption>;
  searcher: FuzzySearcher<SuggestionOption>;
}

function updateMatcherState(
  editor: EditorType,
  matcher: SuggestionMatcher,
  partialMatch: string,
  currentState?: MatcherState
): MatcherState {
  const options = matcher.options(partialMatch, editor);
  if (
    currentState &&
    isEqual(
      options.map(o => o.id),
      currentState.options.map(o => o.id)
    )
  ) {
    return currentState;
  }

  return {
    options,
    optionsByValue: options.reduce((result, option) => {
      result[option.value.toLowerCase()] = option;
      return result;
    }, {} as Record<string, SuggestionOption>),
    searcher: new FuzzySearcher<SuggestionOption>(
      FuzzySearcherConfiguration.Autocomplete,
      ['value', ...(matcher.propertiesToSearch ?? [])],
      options.filter(o => !o.footer)
    ),
  };
}

function findTextToSearch(editor: EditorType): NodeEntry<Text> | null {
  const selection = editor.selection;
  if (!selection || !Range.isCollapsed(selection)) {
    return null;
  }

  const nodeEntry = Editor.node(editor, selection);
  const [node] = nodeEntry;
  if (!Text.isText(node)) {
    return null;
  }

  const codeParent = Editor.above(editor, {
    at: selection.focus,
    match: n => KitemakerElement.isElement(n) && n.type === Elements.Code,
  });
  if (codeParent) {
    return null;
  }
  return nodeEntry as NodeEntry<Text>;
}

function stringContainsBreakingChars(toCheck: string, includeSpace?: boolean): boolean {
  const match = isNonLetter(toCheck);
  return !!match || (!!includeSpace && toCheck.includes(' '));
}

export function withSuggestions(
  editor: EditorType,
  matchers: SuggestionMatcher[],
  onSuggestions: (suggestions: SuggestionState) => void
) {
  const state: Record<string, MatcherState> = {};

  function findSuggestions() {
    if (!matchers.length) {
      return;
    }

    const nodeToSearch = findTextToSearch(editor);
    if (!nodeToSearch) {
      onSuggestions({ suggestions: null });
      return;
    }

    const [node, path] = nodeToSearch;
    const toSearch = KitemakerNode.safeString(node);
    const toSearchLowerCase = toSearch.toLowerCase();

    const selection = safeSelection(editor);
    if (!selection) {
      onSuggestions({ suggestions: null });
      return;
    }
    const offset = selection.focus.offset;

    // do we have a matcher where the suffix matches at the end?
    const suffixMatcher = matchers.find(m => {
      if (
        m.suffix &&
        offset - m.suffix.length > 0 &&
        toSearchLowerCase.slice(offset - m.suffix.length, offset + 1) === m.suffix.toLowerCase()
      ) {
        // ok, we found the suffix. Let's see if the prefix is also here
        const prefixIndex = toSearchLowerCase.lastIndexOf(
          m.prefix.toLowerCase(),
          offset - m.suffix.length
        );
        if (prefixIndex >= 0) {
          return true;
        }
      }

      return false;
    });

    // a suffix match means we can search for an exact match in the options
    if (suffixMatcher) {
      const suffixIndex = toSearchLowerCase.lastIndexOf(
        suffixMatcher.suffix!.toLowerCase(),
        offset
      );
      const prefixIndex = toSearchLowerCase.lastIndexOf(
        suffixMatcher.prefix.toLowerCase(),
        suffixIndex - 1
      );
      if (suffixIndex !== -1 && prefixIndex !== -1) {
        const possibleMatch = toSearch.substring(
          prefixIndex + suffixMatcher.prefix.length,
          suffixIndex
        );
        const possibleMatchLowerCase = possibleMatch.toLowerCase();

        if (!stringContainsBreakingChars(possibleMatch)) {
          state[suffixMatcher.id] = updateMatcherState(
            editor,
            suffixMatcher,
            possibleMatch,
            state[suffixMatcher.id]
          );

          const match = state[suffixMatcher.id].optionsByValue[possibleMatchLowerCase];
          if (match && !match.suggestionAutoCompleteOnly) {
            const range: Range = {
              anchor: {
                path,
                offset: prefixIndex,
              },
              focus: {
                path,
                offset: suffixIndex + suffixMatcher.suffix!.length,
              },
            };
            suffixMatcher.onMatch(editor, match, range);
            return;
          }
        }
      }
    }

    // Now we're just matching prefixes. Sort the matchers by whichever one's prefix comes last.
    // In the case that one matcher's prefix is a substring of the other's, prefer the longer one
    const offsets: Record<string, number> = matchers.reduce((result, matcher) => {
      result[matcher.id] = toSearchLowerCase.lastIndexOf(matcher.prefix.toLowerCase(), offset);
      return result;
    }, {} as Record<string, number>);

    const sortedMatchers = matchers.sort((a, b) => {
      if (offsets[a.id] !== -1 && offsets[b.id] == -1) {
        return 1;
      }
      if (offsets[a.id] === -1 && offsets[b.id] !== -1) {
        return -1;
      }

      const aOffsetAndLength = offsets[a.id] + a.prefix.length;
      const bOffsetAndLength = offsets[b.id] + b.prefix.length;

      if (aOffsetAndLength > bOffsetAndLength) {
        return 1;
      }
      if (aOffsetAndLength < bOffsetAndLength) {
        return -1;
      }

      if (a.prefix.length > b.prefix.length) {
        return 1;
      }
      if (a.prefix.length < b.prefix.length) {
        return -1;
      }

      return 0;
    });

    const prefixMatcher = sortedMatchers[sortedMatchers.length - 1];
    const prefixOffset = toSearchLowerCase.lastIndexOf(prefixMatcher.prefix.toLowerCase(), offset);
    if (prefixOffset < 0) {
      onSuggestions({ suggestions: null });
      return;
    }

    const possibleMatch = toSearch.substring(prefixOffset + prefixMatcher.prefix.length, offset);
    const possibleMatchLowerCase = possibleMatch.toLowerCase();

    state[prefixMatcher.id] = updateMatcherState(
      editor,
      prefixMatcher,
      possibleMatch,
      state[prefixMatcher.id]
    );

    // do we have an exact match?
    const endsInBreakingChar = stringContainsBreakingChars(
      possibleMatchLowerCase.substring(possibleMatchLowerCase.length - 1),
      true
    );

    if (endsInBreakingChar) {
      const toMatch = possibleMatchLowerCase.substring(0, possibleMatchLowerCase.length - 1);
      const exactMatch = state[prefixMatcher.id].optionsByValue[toMatch];
      if (exactMatch && !prefixMatcher.suffix && !exactMatch.suggestionAutoCompleteOnly) {
        onSuggestions({ suggestions: null });
        const range: Range = {
          anchor: {
            path,
            offset: prefixOffset,
          },
          focus: {
            path,
            offset: offset - 1,
          },
        };
        prefixMatcher.onMatch(editor, exactMatch, range);
        return;
      }

      if (stringContainsBreakingChars(possibleMatchLowerCase)) {
        onSuggestions({ suggestions: null });
        return;
      }
    }

    if (
      prefixOffset !== 0 &&
      !stringContainsBreakingChars(toSearchLowerCase.substr(prefixOffset - 1, 1), true)
    ) {
      onSuggestions({ suggestions: null });
      return;
    }

    const range: Range = {
      anchor: {
        path,
        offset: prefixOffset,
      },
      focus: {
        path,
        offset,
      },
    };

    (async () => {
      function onResults(results: SuggestionOption[]) {
        onSuggestions({
          suggestions: results.slice(0, maxSuggestions).map(suggestion => ({
            id: suggestion.id,
            render: suggestion.render,
            accept: () => {
              prefixMatcher.onMatch(editor, suggestion, range, true);
              if (prefixMatcher.handleTrailingSpace) {
                trailingSpace.set(editor, true);
              }
            },
            ...(suggestion.expandable
              ? {
                  expand: () => {
                    const expanded = prefixMatcher.expandedOptions?.(suggestion) ?? [];

                    onSuggestions({
                      suggestions: expanded.map(opt => ({
                        id: opt.id,
                        render: opt.render,
                        accept: () => {
                          prefixMatcher.onMatch(editor, opt, range, true);
                          if (prefixMatcher.handleTrailingSpace) {
                            trailingSpace.set(editor, true);
                          }
                        },
                      })),
                    });
                  },
                }
              : {}),
          })),
          className: prefixMatcher.className,
          header: prefixMatcher.header,
        });
      }

      // if there's nothing to search for, just return all the options
      let results = [...state[prefixMatcher.id].options];
      if (possibleMatch.length || (prefixMatcher.search && prefixMatcher.searchOnEmptyString)) {
        if (prefixMatcher.search) {
          // if a matcher provides its own custom search function, use that
          results = await prefixMatcher.search(possibleMatch, onResults);
        } else {
          // otherwise use the generic fuzzy searcher
          results = state[prefixMatcher.id].searcher
            .search(possibleMatch)
            .map(searchResult => searchResult.item);
        }
        results = [...results, ...state[prefixMatcher.id].options.filter(o => o.footer)];
      }

      onResults(results);
    })();
  }

  const { insertText, deleteBackward, insertBreak, apply } = editor;
  editor.apply = op => {
    trailingSpace.delete(editor);
    if (op.type !== 'insert_text' && op.type !== 'remove_text') {
      // FIXME-SLATE: causes a crash when typing links if we don't have this setTimeout here
      setTimeout(() => onSuggestions({ suggestions: null }));
    }
    apply(op);
  };

  editor.insertText = text => {
    if (trailingSpace.get(editor) && ['.', ',', ':', '?', '!', '\t', ')'].includes(text)) {
      editor.deleteBackward('character');
    }
    insertText(text);
    findSuggestions();
  };

  editor.insertBreak = () => {
    if (trailingSpace.get(editor)) {
      editor.deleteBackward('character');
    }
    insertBreak();
  };

  editor.deleteBackward = unit => {
    deleteBackward(unit);
    findSuggestions();
  };

  editor.findSuggestions = findSuggestions;

  return editor;
}
