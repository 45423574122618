export interface GiphyData {
  searchTerm: string;
  url: string;
  contributor: string;
  contributorUrl: string;
}

export async function randomGif(searchTerm: string): Promise<GiphyData> {
  const response = await fetch('/integrations/giphy/gifs/random', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      searchTerm,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.errors) {
    throw new Error('Unable to search for Giphy');
  }

  const result = responseJson.data as GiphyData;

  return result;
}
