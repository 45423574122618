import { uniq } from 'lodash';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { filterNotNull } from '../../../shared/utils/convenience';
import { issueTerm } from '../../../shared/utils/terms';
import { Initiative } from '../../../sync/__generated/models';
import { useConfiguration } from '../../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useIsTinyScreen } from '../../hooks/useResponsiveDesign';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
  useRemoveAssigneesFromEntities,
  useRemoveLabelsFromEntities,
  useToggleAssigneesForEntities,
  useToggleWatchersForEntities,
  useUpdateEntities,
} from '../../syncEngine/actions/entities';
import {
  useAddInitiativesToRoadmaps,
  useAddSpacesToInitiatives,
  useArchiveInitatives,
  useDeleteInitiatives,
  useRemoveInitiativesFromRoadmaps,
  useRemoveSpacesFromInitiatives,
  useUnarchiveInitatives,
  useUpdateInitiatives,
} from '../../syncEngine/actions/intiatives';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import {
  initiativesSelector,
  initiativesToRoadmapStateSelector,
  isInitiative,
  roadmapInitiativeForInitiativeAndRoadmap,
  roadmapInitiativesSelector,
  spacesIdsForInitiativesSelector,
} from '../../syncEngine/selectors/intiatives';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import {
  addExistingKey,
  alternateComboKey,
  archiveIssueKey,
  assignIssueKey,
  deleteKey,
  editTitleKey,
  labelIssueKey,
  mainComboKey,
  openHistoryKey,
  removeItemKey,
  roadmapInitiativeKey,
  selfAssignIssueKey,
  setEffortKey,
  setImpactKey,
  spaceInitiativeKey,
  toggleStarredKey,
  watchIssueKey,
} from '../../utils/config';
import { StarredType } from '../../utils/starred';
import { descriptionHistoryOpenAtom } from '../descriptionHistory';
import { ColorPickerContent } from './colorPicker';
import { DueDatePicker } from './dueDateMetadata';
import { useClearSelection, useKeyNavigationState } from './keyNavigation';
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from './menu/dropdownMenu';
import { EffortMenu } from './menu/effortMenu';
import { ImpactMenu } from './menu/impactMenu';
import { LabelPicker, entitiesToLabelPickerState } from './pickers/labelPicker';
import { MemberPicker, entitiesToMemberPickerState } from './pickers/memberPicker';
import { RoadmapPicker } from './pickers/roadmapPicker';
import { SpacePicker } from './pickers/spacePicker';

export function InitiativeMenu({
  initiative,
  closeMenu,
  onChangeTitle,
  moveToTopBottom,
  roadmapId,
  showHistory,
}: {
  initiative: Initiative;
  closeMenu: () => void;
  onChangeTitle?: () => void;
  moveToTopBottom?: (direction: 'top' | 'bottom', ids: string[], columnId: string) => void;
  roadmapId?: string;
  showHistory?: boolean;
}) {
  const user = useCurrentUser();
  const organization = useOrganization();
  const maybeSpace = useMaybeSpace();
  const { featureFlags } = useConfiguration();

  const tinyScreen = useIsTinyScreen();
  const updateInitiatives = useUpdateInitiatives();
  const deleteInitiatives = useDeleteInitiatives();
  const addSpacesToInitiatives = useAddSpacesToInitiatives();
  const removeSpacesFromInitiatives = useRemoveSpacesFromInitiatives();
  const addInitiativesToRoadmaps = useAddInitiativesToRoadmaps();
  const removeInitiativesFromRoadmaps = useRemoveInitiativesFromRoadmaps();
  const addAssignees = useAddAssigneesToEntities();
  const removeAssignees = useRemoveAssigneesFromEntities();
  const toggleAssigneesForEntities = useToggleAssigneesForEntities();
  const addLabelsToEntities = useAddLabelsToEntities();
  const removeLabelsFromEntities = useRemoveLabelsFromEntities();
  const archive = useArchiveInitatives();
  const unarchive = useUnarchiveInitatives();
  const updateEntities = useUpdateEntities();
  const toggleWatching = useToggleWatchersForEntities();
  const modals = useModals();

  const roadmapInitiative = useRecoilValue(
    roadmapInitiativeForInitiativeAndRoadmap({
      initiativeId: initiative.id,
      roadmapId: roadmapId ?? '',
    })
  );

  const { selected } = useKeyNavigationState();
  const clearSelection = useClearSelection();
  const selectedRoadmapInitiatives = useRecoilValue(roadmapInitiativesSelector(selected));

  const roadmapInitiatives = filterNotNull(
    selectedRoadmapInitiatives.length ? selectedRoadmapInitiatives : [roadmapInitiative]
  );

  const sameColumn = roadmapInitiatives.every(ri => ri?.columnId === roadmapInitiative?.columnId);

  const selectedInitiatives = useRecoilValue(
    initiativesSelector(filterNotNull(roadmapInitiatives.map(i => i?.initiativeId)))
  );
  const selectedInitiativeIds = selectedInitiatives.map(i => i.id);

  const initiatives = (
    selectedInitiativeIds.includes(initiative.id) ? selectedInitiatives! : [initiative]
  ).filter(i => isInitiative(i));
  const initiativeIds = initiatives.map(i => i.id);

  const watching = initiatives.every(i => i.watcherIds?.includes(user.id));

  const roadmapPickerState = useRecoilValue(initiativesToRoadmapStateSelector(initiativeIds));
  const spacePickerState = useRecoilValue(spacesIdsForInitiativesSelector(initiativeIds));

  const archived = !!initiatives[0]?.archivedAt;
  const isPrivate = maybeSpace?.private ?? false;

  const isMember = initiative.memberIds.includes(user.id);

  const impactIds = uniq(initiatives.map(initiative => initiative.impactId));
  const effortIds = uniq(initiatives.map(initiative => initiative.effortId));

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.Initiative,
      id: initiative.id,
    })
  );
  const toggleStarred = useToggleStarred();

  const [openHistory, setOpenHistory] = useRecoilState(descriptionHistoryOpenAtom);

  useComponentDidMount(() => {
    if (selectedInitiativeIds?.length && !selectedInitiativeIds.includes(initiative.id)) {
      clearSelection();
    }
  });

  return (
    <>
      <DropdownMenuItem
        icon="add"
        shortcut={addExistingKey}
        onClick={() => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.ChangeInitiativeIssues,
            context: {
              initiativeId: initiative.id,
            },
          });
        }}
      >
        Add existing {issueTerm}s
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <Submenu>
        {!isPrivate && (
          <Submenu>
            <SubmenuTrigger icon="workspace" shortcut={spaceInitiativeKey}>
              Spaces
            </SubmenuTrigger>
            <SubmenuContent className="menuPicker menuMedium">
              <SpacePicker
                multi
                state={spacePickerState}
                onSpaceAdded={(ids, spaceId) => {
                  addSpacesToInitiatives(ids, [spaceId]);
                }}
                onSpaceRemoved={(ids, spaceId) => {
                  removeSpacesFromInitiatives(ids, [spaceId]);
                }}
                onDone={closeMenu}
              />
            </SubmenuContent>
          </Submenu>
        )}
        <SubmenuTrigger icon="member" shortcut={assignIssueKey}>
          Members
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <MemberPicker
            state={entitiesToMemberPickerState(initiatives)}
            onMemberAdded={(issueIds, memberId) => {
              addAssignees(issueIds, [memberId]);
            }}
            onMemberRemoved={(issueIds, memberId) => {
              removeAssignees(issueIds, [memberId]);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="label" shortcut={labelIssueKey}>
          Labels
        </SubmenuTrigger>
        <SubmenuContent className="menuPicker menuMedium">
          <LabelPicker
            orgLevel
            state={entitiesToLabelPickerState(initiatives)}
            onLabelAdded={(initiativeIds, labelId) => {
              addLabelsToEntities(initiativeIds, [labelId]);
            }}
            onLabelRemoved={(intiativeIds, labelId) => {
              removeLabelsFromEntities(intiativeIds, [labelId]);
            }}
            onDone={closeMenu}
          />
        </SubmenuContent>
      </Submenu>
      {organization.newRoadmapsEnabled && (
        <Submenu>
          <SubmenuTrigger icon="roadmap" shortcut={roadmapInitiativeKey}>
            Roadmaps
          </SubmenuTrigger>
          <SubmenuContent className="menuPicker menuMedium">
            <RoadmapPicker
              multi
              spaceId={maybeSpace?.id}
              state={roadmapPickerState}
              onRoadmapAdded={(initiativeIds, roadmapId) => {
                addInitiativesToRoadmaps(initiativeIds, [roadmapId]);
              }}
              onRoadmapRemoved={(intiativeIds, roadmapId) => {
                removeInitiativesFromRoadmaps(intiativeIds, [roadmapId]);
              }}
              onDone={closeMenu}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <Submenu>
        <SubmenuTrigger icon="impact" shortcut={setImpactKey}>
          Impact
        </SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          <ImpactMenu
            forceOrganizationLevel
            onSelect={impactId => updateEntities(initiativeIds, { impactId })}
            impactId={impactIds.length === 1 ? impactIds[0] : undefined}
          />
        </SubmenuContent>
      </Submenu>
      <Submenu>
        <SubmenuTrigger icon="effort" shortcut={setEffortKey}>
          Effort
        </SubmenuTrigger>
        <SubmenuContent className="menuTiny">
          <EffortMenu
            forceOrganizationLevel
            onSelect={effortId => updateEntities(initiativeIds, { effortId })}
            effortId={effortIds.length === 1 ? effortIds[0] : undefined}
          />
        </SubmenuContent>
      </Submenu>
      {featureFlags.FEATURE_TOGGLE_TIMELINE && (
        <Submenu>
          <SubmenuTrigger icon="due_date">Start date</SubmenuTrigger>
          <SubmenuContent>
            <DueDatePicker
              label="start"
              date={initiatives.find(i => !!i.startDate)?.startDate ?? null}
              onSave={startDate => {
                updateInitiatives(initiativeIds, {
                  startDate: startDate?.getTime() ?? null,
                });
                closeMenu?.();
              }}
              onCancel={() => closeMenu?.()}
            />
          </SubmenuContent>
        </Submenu>
      )}
      <Submenu>
        <SubmenuTrigger icon="due_date">Due date</SubmenuTrigger>
        <SubmenuContent>
          <DueDatePicker
            date={initiatives.find(i => !!i.dueDate)?.dueDate ?? null}
            onSave={dueDate => {
              updateInitiatives(initiativeIds, {
                dueDate: dueDate?.getTime() ?? null,
              });
              closeMenu?.();
            }}
            onCancel={() => closeMenu?.()}
          />
        </SubmenuContent>
      </Submenu>
      <DropdownMenuSeparator />
      {!tinyScreen && initiatives.length === 1 && onChangeTitle && (
        <DropdownMenuItem icon="edit" shortcut={editTitleKey} onClick={onChangeTitle}>
          Change title
        </DropdownMenuItem>
      )}
      <Submenu>
        <SubmenuTrigger icon="status">Color</SubmenuTrigger>
        <SubmenuContent>
          <ColorPickerContent
            onColorPicked={color => {
              updateInitiatives([initiative.id], { color: color ?? 'gray' });
              closeMenu();
            }}
            color={initiative.color}
          />
        </SubmenuContent>
      </Submenu>
      {moveToTopBottom && sameColumn && (
        <Submenu>
          <SubmenuTrigger icon="move">Move</SubmenuTrigger>
          <SubmenuContent className="menuLarge">
            <DropdownMenuItem
              onClick={() => {
                if (roadmapId && roadmapInitiative) {
                  moveToTopBottom(
                    'top',
                    roadmapInitiatives.map(ri => ri.id),
                    roadmapInitiative?.columnId ?? ''
                  );
                } else {
                  moveToTopBottom('top', initiativeIds, '');
                }
              }}
              icon="move_top"
              shortcut={`${mainComboKey}+${alternateComboKey}+up`}
            >
              Move to top
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                if (roadmapId && roadmapInitiative) {
                  moveToTopBottom(
                    'bottom',
                    roadmapInitiatives.map(ri => ri.id),
                    roadmapInitiative?.columnId ?? ''
                  );
                } else {
                  moveToTopBottom('bottom', initiativeIds, '');
                }
              }}
              icon="move_bottom"
              shortcut={`${mainComboKey}+${alternateComboKey}+down`}
            >
              Move to bottom
            </DropdownMenuItem>
          </SubmenuContent>
        </Submenu>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="member"
        shortcut={selfAssignIssueKey}
        onClick={() => {
          toggleAssigneesForEntities(initiativeIds, [user.id]);
        }}
      >
        {isMember ? 'Remove' : 'Add'} myself
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="watch"
        shortcut={watchIssueKey}
        onClick={() => {
          toggleWatching(initiativeIds, [user.id]);
        }}
      >
        {watching ? 'Stop watching' : 'Watch'}
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        shortcut={toggleStarredKey}
        onClick={() => {
          toggleStarred({ type: StarredType.Initiative, id: initiative.id });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      {showHistory && (
        <DropdownMenuItem
          icon="history"
          shortcut={openHistoryKey}
          onClick={() => setOpenHistory(v => !v)}
        >
          {openHistory ? 'Close' : 'Open'} history
        </DropdownMenuItem>
      )}
      <DropdownMenuSeparator />
      <DropdownMenuItem
        icon="archive"
        shortcut={archiveIssueKey}
        onClick={() => {
          if (archived) {
            unarchive(initiativeIds);
          } else {
            archive(initiativeIds);
          }
        }}
      >
        {archived ? 'Unarchive' : 'Archive'}
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="delete"
        shortcut={deleteKey}
        onClick={() => {
          deleteInitiatives(initiativeIds);
        }}
      >
        Delete
      </DropdownMenuItem>
      {roadmapId && (
        <DropdownMenuItem
          icon="delete"
          shortcut={removeItemKey}
          onClick={() => {
            removeInitiativesFromRoadmaps(initiativeIds, [roadmapId]);
          }}
        >
          Remove from roadmap
        </DropdownMenuItem>
      )}
    </>
  );
}
