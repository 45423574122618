import React from 'react';
import { useRecoilValue } from 'recoil';
import { RoadmapPicker } from '../../../components/new/pickers/roadmapPicker';
import {
  useAddInitiativesToRoadmaps,
  useRemoveInitiativesFromRoadmaps,
} from '../../../syncEngine/actions/intiatives';
import { useSetSpaceRoadmap } from '../../../syncEngine/actions/spaces';
import {
  initiativesToRoadmapStateSelector,
  privateSpaceForInitiativeSelector,
} from '../../../syncEngine/selectors/intiatives';
import { roadmapsForSpaceSelector } from '../../../syncEngine/selectors/roadmaps';
import styles from '../commandMenu.module.scss';

export function ChangeRoadmapsContents({
  onClose,
  initiativeIds: initiativeIds,
}: {
  onClose: () => void;
  initiativeIds: string[];
}) {
  const roadmapPickerState = useRecoilValue(initiativesToRoadmapStateSelector(initiativeIds));
  const addInitiativesToRoadmaps = useAddInitiativesToRoadmaps();
  const removeInitiativesFromRoadmaps = useRemoveInitiativesFromRoadmaps();
  const spaceId = useRecoilValue(privateSpaceForInitiativeSelector(initiativeIds[0]));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <RoadmapPicker
        spaceId={spaceId ?? undefined}
        filterClassName={styles.inputDiv}
        state={roadmapPickerState}
        onRoadmapAdded={(initiativeIds, roadmapId) => {
          addInitiativesToRoadmaps(initiativeIds, [roadmapId]);
        }}
        onRoadmapRemoved={(intiativeIds, roadmapId) => {
          removeInitiativesFromRoadmaps(intiativeIds, [roadmapId]);
        }}
        onDone={onClose}
      />
    </div>
  );
}

export function ChangeSpaceRoadmapContents({
  onClose,
  spaceId,
}: {
  onClose: () => void;
  spaceId: string;
}) {
  const setSpaceRoadmap = useSetSpaceRoadmap();
  const roadmap = useRecoilValue(roadmapsForSpaceSelector(spaceId))[0];

  return (
    <div className="rowStretch fullWidth fullHeight">
      <RoadmapPicker
        filterPlaceholder="Change space roadmap"
        multi={false}
        spaceId={spaceId ?? undefined}
        filterClassName={styles.inputDiv}
        state={{ [spaceId]: roadmap ? [roadmap.id] : [] }}
        onRoadmapAdded={(_, roadmapId) => {
          setSpaceRoadmap(spaceId, roadmapId, true);
        }}
        onRoadmapRemoved={() => {
          setSpaceRoadmap(spaceId, null, true);
        }}
        onDone={onClose}
      />
    </div>
  );
}
