import cn from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { Company as CompanyModel, Person as PersonModel } from '../../../sync/__generated/models';
import { companySelector } from '../../syncEngine/selectors/companies';
import { personCompanyIdsForFeedbackSelector } from '../../syncEngine/selectors/feedback';
import { personSelector } from '../../syncEngine/selectors/people';
import { hashStringToPaletteColors } from '../../utils/color';
import styles from './companyPerson.module.scss';
import Avatar, { AvatarSize } from './metadata/avatar';
import OrganizationAvatar from './metadata/organizationAvatar';

export function CompanyAvatar({
  company,
  size,
}: {
  company: CompanyModel | null;
  size?: AvatarSize;
}) {
  if (!company) {
    return <OrganizationAvatar size={size} name={'?'} />;
  }
  return (
    <OrganizationAvatar
      size={size}
      name={company.name}
      img={`https://logo.clearbit.com/${company?.domain}`}
    />
  );
}

export function PersonAvatar({ person, size }: { person: PersonModel; size?: AvatarSize }) {
  const backgroundColor = `var(--${hashStringToPaletteColors(person.name)}A10)`;
  return (
    <Avatar
      size={size}
      style={{
        backgroundColor,
      }}
      className={cn(styles.person, 'fs-exclude')}
      name={person.name}
    />
  );
}

export function Company({
  companyId,
  noLogo,
  noName,
  size = AvatarSize.Size24,
}: {
  companyId?: string | null;
  noLogo?: boolean;
  noName?: boolean;
  size?: AvatarSize;
}) {
  const company = useRecoilValue(companySelector(companyId));
  return (
    <>
      {!noLogo && <CompanyAvatar size={size} company={company} />}
      {!noName && (
        <span className="grow ellipsis semiBold fs-exclude">{company?.name ?? 'Anonymous'}</span>
      )}
    </>
  );
}

export function Person({
  personId,
  noLogo,
  noName,
  size = AvatarSize.Size24,
}: {
  personId?: string | null;
  noLogo?: boolean;
  noName?: boolean;
  size?: AvatarSize;
}) {
  const person = useRecoilValue(personSelector(personId));
  const company = useRecoilValue(companySelector(person?.companyId));

  if (!person) {
    return null;
  }

  return (
    <>
      {company && (
        <>
          {!noLogo && <CompanyAvatar size={size} company={company} />}
          {!noName && (
            <>
              <span className="grow  ellipsis semiBold fs-exclude contentMaxWidth">
                {company.name}
              </span>
              <span className={styles.seperator}>-</span>
            </>
          )}
        </>
      )}
      {!company && !noLogo && <PersonAvatar size={size} person={person} />}
      {!noName && <span className="grow ellipsis fs-exclude contentMaxWidth">{person.name}</span>}
    </>
  );
}

export function CompanyPerson({
  feedbackId,
  noName,
  noLogo,
  size = AvatarSize.Size24,
}: {
  feedbackId: string;
  noLogo?: boolean;
  noName?: boolean;
  size?: AvatarSize;
}) {
  const { personId, companyId } = useRecoilValue(personCompanyIdsForFeedbackSelector(feedbackId));
  if (personId) {
    return <Person size={size} personId={personId} noName={noName} noLogo={noLogo} />;
  } else {
    return <Company size={size} companyId={companyId} noName={noName} noLogo={noLogo} />;
  }
}
