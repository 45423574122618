import * as React from 'react';
import { useDarkMode } from '../hooks/useDarkMode';
import { colorWithOpacity } from '../utils/color';
import { Icon, IconSize } from './new/icon';

export function ViewIcon({
  color,
  className,
  style,
  size = IconSize.Size20,
}: {
  color: string;
  className?: string;
  style?: React.CSSProperties;
  size?: IconSize;
}) {
  const { darkMode } = useDarkMode();
  return (
    <Icon
      size={size}
      icon="view"
      className={className}
      style={{ ...style, fill: colorWithOpacity(color, 9, darkMode, true) }}
    />
  );
}
