import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { InitiativeStatus as InitiativeStatusType } from '../../../shared/initiativeStatus';
import {
  initiativeIssuesStatusCountsSelector,
  initiativeStatusSelector,
  initiativeStatusToIcon,
} from '../../syncEngine/selectors/intiatives';
import { Icon, IconSize } from './icon';
import Pill from './metadata/pill';
import { MetadataSize } from './metadata/size';
import { Tooltip } from './tooltip';

function statusToInfo(status: InitiativeStatusType) {
  switch (status) {
    case InitiativeStatusType.Completed:
      return 'Completed: This initiative has only completed or archived work items.';
    case InitiativeStatusType.Archived:
      return 'Archived';
    case InitiativeStatusType.NotStarted:
      return 'Not started: This initiative has planned work items.';
    case InitiativeStatusType.Started:
      return 'Started: This initiative has in-progress work items.';
  }
}

export function InitiativeStatus({
  initiativeId,
  size = MetadataSize.Small,
}: {
  initiativeId: string;
  size?: MetadataSize;
}) {
  const counts = useRecoilValue(initiativeIssuesStatusCountsSelector(initiativeId));
  const totalCount = Object.values(counts).reduce((a, b) => a + b, 0);
  const status = useRecoilValue(initiativeStatusSelector(initiativeId));

  if (totalCount === 0) {
    return null;
  }
  const completedCount = counts.ARCHIVED + counts.DONE;

  return (
    <Tooltip content={statusToInfo(status)}>
      <Pill size={size} noHover>
        <InitiativeStatusIcon size={size} initiativeId={initiativeId} />
        {completedCount}/{totalCount}
      </Pill>
    </Tooltip>
  );
}

export function InitiativeStatusIcon({
  size = MetadataSize.Small,
  initiativeId,
}: {
  size?: MetadataSize;
  initiativeId: string;
}) {
  const status = useRecoilValue(initiativeStatusSelector(initiativeId));
  return (
    <Icon
      size={size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20}
      icon={initiativeStatusToIcon(status)}
    />
  );
}
