import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { useOrganization } from '../../contexts/organizationContext';
import { OrganizationMarker } from '../../graphql/smartLoad';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { feedbackTabState } from '../../syncEngine/selectors/feedback';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { trackerPageLoad } from '../../tracker';
import LoadingScreen from '../loadingScreen';
import { FeedbackScreen } from './feedbackScreen';

export default function FeedbackScreenWrapper() {
  const organization = useOrganization();
  React.useEffect(() => trackerPageLoad('FeedbackScreen open'), []);

  const history = useHistory();
  const setFeedbackTab = useSetRecoilState(feedbackTabState);
  const getFeedbackCallback = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot.getLoadable(feedbackTabState).getValue();
      },
    []
  );

  history.listen((location, action) => {
    if (action === 'REPLACE' && location.search.includes('?feedbackTab')) {
      const params = new URLSearchParams(location.search);
      const urlTab = params.get('feedbackTab');
      if (urlTab) {
        setFeedbackTab(urlTab);
      }
    }
  });

  useComponentDidMount(() => {
    setTimeout(() => {
      const params = new URLSearchParams(window.location.search);
      const urlParam = params.get('feedbackTab');
      if (!urlParam) {
        const newValue = getFeedbackCallback();
        params.set('feedbackTab', newValue);
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
      }
    }, 10);
  });

  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );

  if (!currentOrgFullyFetched) {
    return <LoadingScreen />;
  }

  return <FeedbackScreen />;
}
