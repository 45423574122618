export interface UserConfig {
  websocketToken?: string | null;
  featureFlags: Record<string, boolean>;
  ssoOrganization?: string | null;
  defaultOrganizationId?: string;
  europeanDataResidency?: boolean;
  isDeveloper?: boolean;
}

export enum FlowStep {
  Terms = 'terms',
  CreateOrganization = 'createOrganization',
  PendingInvitation = 'pendingInvitation',
  LinkExternalAccount = 'linkExternalAccount',
}

export enum FlowResultStatus {
  Complete = 'complete',
  Next = 'next',
  Redirect = 'redirect',
}

export interface FlowCompleteResult {
  status: FlowResultStatus.Complete;
  redirect?: string | null;
  userId?: string;
  email?: string;
  config: UserConfig;
}

export interface FlowNextStepResult {
  status: FlowResultStatus.Next;
  step: FlowStep;
  context?: Record<string, unknown>;
}

export interface FlowRedirectResult {
  status: FlowResultStatus.Redirect;
  redirect: string;
}

export type FlowStepResult = FlowCompleteResult | FlowNextStepResult | FlowRedirectResult;

export enum ExternalAuthProviderType {
  Google = 'google',
  Microsoft = 'microsoft',
}

export type LoginMethod = 'password' | ExternalAuthProviderType;

export type LoginDetails = {
  method: LoginMethod;
  [index: string]: unknown;
};

export enum AutomaticConectionType {
  MicrosoftEntraId = 'microsoftEntraId',
}

interface MicrosoftEntraConnection {
  type: AutomaticConectionType.MicrosoftEntraId;
  tenantId: string;
}

export type AutomaticConnection = MicrosoftEntraConnection;
