import { Node, NodeEntry, Range, Text } from 'slate';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { isEmoji } from '../../utils/emoji';
import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';

export function bigEmojiDecorations(editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [node, path] = entry;
  if (!Text.isText(node) || !path.length) {
    return [];
  }

  const [parent, parentPath] = KitemakerEditor.parent(editor, path);
  if (!KitemakerElement.isElement(parent) || parent.type !== Elements.Paragraph) {
    return [];
  }

  const nodes = Array.from(KitemakerEditor.nodes(editor, { at: parentPath, mode: 'lowest' }));
  if (nodes.length > 1) {
    return [];
  }

  if (!isEmoji(KitemakerNode.safeString(node).trim())) {
    return [];
  }

  const edges = KitemakerEditor.edges(editor, path);

  return [
    {
      anchor: edges[0],
      focus: edges[1],
      bigEmoji: true,
      emojiFont: true,
    },
  ];
}
