import { useRecoilValue } from 'recoil';
import { Modals, useModals } from '../contexts/modalContext';
import {
  useSortStatusByDate,
  useSortStatusByImpactAndEffort,
  useWatchStatuses,
} from '../syncEngine/actions/issueStatuses';
import { statusSelector } from '../syncEngine/selectors/issues';
import { currentUserWatchingStatusSelector } from '../syncEngine/selectors/issueStatuses';
import { EntitySortField, ImpactAndEffortScoringMode } from '../utils/entities';
import {
  CommandDefinition,
  CommandGroup,
  StatusCommandGroupContext,
  useCommandMenuContext,
} from './state';

export function useStatusColumnCommandGroup(): CommandDefinition[] {
  const context = useCommandMenuContext<StatusCommandGroupContext>(CommandGroup.Status);
  const status = useRecoilValue(statusSelector(context?.statusId));
  const modals = useModals();

  const sortStatusByImpactAndEffort = useSortStatusByImpactAndEffort();
  const sortStatusByDate = useSortStatusByDate();
  const isWatchingStatus = useRecoilValue(currentUserWatchingStatusSelector(status?.id));
  const watchStatuses = useWatchStatuses();

  if (!status) {
    return [];
  }

  const commands: CommandDefinition[] = [
    {
      id: `sort-status-impacteffort-${status.id}`,
      description: `Sort ${status.name} by impact & effort`,
      aliases: ['Order'],
      handler: () => {
        sortStatusByImpactAndEffort(status.id);
      },
    },
    {
      id: `sort-status-impact-${status.id}`,
      description: `Sort ${status.name} by impact`,
      aliases: ['Order'],
      handler: () => {
        sortStatusByImpactAndEffort(status.id, ImpactAndEffortScoringMode.IMPACT_ONLY);
      },
    },
    {
      id: `sort-status-effort-${status.id}`,
      description: `Sort ${status.name} by effort`,
      aliases: ['Order'],
      handler: () => {
        sortStatusByImpactAndEffort(status.id, ImpactAndEffortScoringMode.EFFORT_ONLY);
      },
    },
    {
      id: `sort-status-updated-at-${status.id}`,
      description: `Sort ${status.name} by recently updated`,
      aliases: ['Order'],
      handler: () => {
        sortStatusByDate(status.id, EntitySortField.RECENTLY_UPDATED);
      },
    },
    {
      id: `sort-status-created-at-${status.id}`,
      description: `Sort ${status.name} by recently created`,
      aliases: ['Order'],
      handler: () => {
        sortStatusByDate(status.id, EntitySortField.RECENTLY_CREATED);
      },
    },
    {
      id: `set-column-limit-${status.id}`,
      description: `${status.issueLimit ? 'Change' : 'Set'} work item limit for ${status.name}`,
      handler: () => {
        modals.openModal(Modals.IssueLimit, { statusId: status.id });
      },
    },
    {
      id: `watch-status-${status.id}`,
      description: isWatchingStatus ? 'Stop watching status column' : 'Watch status column',
      handler: () => {
        watchStatuses([status.id], !isWatchingStatus);
      },
    },
  ];

  return commands.map(c => ({ ...c, group: c.group ?? CommandGroup.Status }));
}
