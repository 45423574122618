import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';

export function withBottomOffset(editor: EditorType) {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    insertBreak();
    setTimeout(() => KitemakerEditor.ensureBottomOffset(editor));
  };

  return editor;
}
