import React from 'react';
import { FolderPicker } from '../../../components/new/pickers/folderPicker';
import { useMoveDocumentsAndFoldersToFolder } from '../../../syncEngine/actions/documents';
import styles from '../commandMenu.module.scss';

export function MoveDocumentsAndFoldersContents({
  onClose,
  documentIds,
  folderIds,
}: {
  onClose: () => void;
  documentIds: string[];
  folderIds: string[];
}) {
  const moveDocumentsAndFolders = useMoveDocumentsAndFoldersToFolder();

  return (
    <div className="rowStretch fullWidth fullHeight">
      <FolderPicker
        omitFolders={folderIds}
        onPicked={folderId => {
          moveDocumentsAndFolders(documentIds, folderIds, folderId);
          onClose();
        }}
        filterClassName={styles.inputDiv}
      />
    </div>
  );
}
