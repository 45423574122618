import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { FilteredListView } from '../../../components/new/filteredListView';
import styles from '../../../components/new/menu/menu.module.scss';
import { useOrganization } from '../../../contexts/organizationContext';
import { spacePath } from '../../../syncEngine/selectors/spaces';
import { spacesForCurrentUserMembershipSelector } from '../../../syncEngine/selectors/users';
import commandMenuStyles from '../commandMenu.module.scss';

export function GotoSpaceContents({ onClose }: { onClose: () => void }) {
  const history = useHistory();
  const organization = useOrganization();
  const { favoriteSpaces, nonFavoriteSpaces } = useRecoilValue(
    spacesForCurrentUserMembershipSelector(organization.id)
  );
  return (
    <div className="colStretch fullWidth fullHeight" key="space">
      <FilteredListView
        className="grow"
        itemClassName={styles.item}
        filterPlaceholder="Select space"
        filterClassName={commandMenuStyles.inputDiv}
        propertiesToSearch={['name']}
        items={[...favoriteSpaces, ...nonFavoriteSpaces].map(space => ({
          id: space.id,
          contents: space.name,
          name: space.name,
          onSelected: () => {
            history.push(spacePath(organization, space));
            onClose();
          },
        }))}
      />
    </div>
  );
}
