import { randomString } from '../../shared/utils/random';

const NOT_FOUND_ERROR = 'Attachment not found';

export async function checkUrl(url: string): Promise<boolean | null> {
  try {
    const resp = await fetch(`${url}?check=true&rand=${randomString(8)}`, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (resp.status === 404) {
      return false;
    }

    if (resp.ok) {
      return true;
    }
    return null;
  } catch (e) {
    if (e.message === NOT_FOUND_ERROR) {
      throw e;
    }
    return null;
  }
}
