import cn from 'classnames';
import { LocationState } from 'history';
import { keys } from 'lodash';
import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { atomFamily, useRecoilState, useRecoilValue } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { capitalize } from '../../../../shared/utils/utils';
import { Initiative as InitiativeModel } from '../../../../sync/__generated/models';
import { CommandGroup } from '../../../commands';
import { ArchivedNotice } from '../../../components/archivedNotice';
import { writeToClipboard } from '../../../components/clipboardText';
import { CollaborativeDocEditor } from '../../../components/collaborativeDocEditor';
import { DescriptionHistory } from '../../../components/descriptionHistory';
import {
  DescriptionPlaceholder,
  PlaceholderType,
} from '../../../components/descriptionPlaceholder';
import { useGitBranchName } from '../../../components/gitBranch';
import { HideIfSmallerThan } from '../../../components/hideIfSmallerThan';
import {
  MetadataConfigurationButton,
  workItemDefaultExcludeOptions,
} from '../../../components/metadataConfig';
import { ActivitiesTab } from '../../../components/new/activities/activities';
import BackButton from '../../../components/new/backButton';
import {
  Breadcrumbs,
  TitleBreadcrumb,
  useSpaceBreadcrumb,
} from '../../../components/new/breadcrumbs';
import { ButtonSize, ButtonStyle, IconButton } from '../../../components/new/button';
import { ButtonGroup } from '../../../components/new/buttonGroup';
import { ColorPicker } from '../../../components/new/colorPicker';
import { CommandContext } from '../../../components/new/commandMenuContext';
import { CustomCommand } from '../../../components/new/customCommand';
import { EntityDueDate } from '../../../components/new/dueDateMetadata';
import {
  EntityEffort,
  EntityImpact,
  EntityLabels,
  EntityMembers,
  InsightsTab,
} from '../../../components/new/entityMetadata';
import {
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS,
  EntityScreenKeyNavHacks,
} from '../../../components/new/entityScreenKeyNavHacks';
import {
  QueryParameterManager,
  useEntityTabs,
  useInitialTab,
} from '../../../components/new/entityTabs';
import { EntityTitleEditor } from '../../../components/new/entityTitleEditor';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon, IconSize } from '../../../components/new/icon';
import { InitiativeSpaces } from '../../../components/new/initativeSpaces';
import { InitiativeMenu } from '../../../components/new/initiativeMenu';
import { InitiativeRoadmaps } from '../../../components/new/initiativeRoadmaps';
import { InitiativeStatus } from '../../../components/new/initiativeStatus';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  KeyNavigationProvider,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
} from '../../../components/new/keyNavigation';
import { LoadingSpinner } from '../../../components/new/loadingSpinner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../../components/new/menu/dropdownMenu';
import { AvatarSize } from '../../../components/new/metadata/avatar';
import Initiative from '../../../components/new/metadata/initiative';
import { MetadataSize } from '../../../components/new/metadata/size';
import { NotificationsIndicator } from '../../../components/new/notifications';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { SegmentedControl } from '../../../components/new/segmentedControl';
import { Setting } from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { Tab, TabStyle, Tabs } from '../../../components/new/tabs';
import { TodoList } from '../../../components/new/todoList';
import { Tooltip } from '../../../components/new/tooltip';
import {
  RightBar,
  RightSidebarButton,
  RightSidebarGroup,
  rightSidebarCollapsed,
} from '../../../components/rightBar';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useDarkMode } from '../../../hooks/useDarkMode';
import {
  ResponsiveDesignSize,
  useIsMediumScreen,
  useIsSmallScreen,
} from '../../../hooks/useResponsiveDesign';
import useSaveScroll from '../../../hooks/useSaveScroll';
import { useNextPreviousSiblings } from '../../../hooks/useSiblingEntities';
import { TextAreaHandle } from '../../../slate/textArea';
import {
  useAddAssigneesToEntities,
  useAddLabelsToEntities,
} from '../../../syncEngine/actions/entities';
import {
  useDeleteInitiativesContext,
  useUpdateInitiatives,
} from '../../../syncEngine/actions/intiatives';
import { localStorageEffect } from '../../../syncEngine/effects';
import { collaborativeDocIdByEntitySelector } from '../../../syncEngine/selectors/collaborativeDoc';
import {
  breadcrumbsForEntitySelector,
  isPartialSelector,
  useEntityPath,
  useUpdateRecents,
} from '../../../syncEngine/selectors/entities';
import {
  initiativeSelector,
  issueIdsByStatusForInitiativeSelector,
  spacesIdsForInitiativeSelector,
} from '../../../syncEngine/selectors/intiatives';
import { roadmapPath } from '../../../syncEngine/selectors/roadmaps';
import { spacePath, spacesSelector } from '../../../syncEngine/selectors/spaces';
import { wideInitiativeHeadersSelector } from '../../../syncEngine/selectors/users';
import { colorWithOpacity } from '../../../utils/color';
import {
  copyIssueGitBranchKey,
  copyIssueLinkKey,
  copyIssueNumberKey,
  gotoActivitiesTabKey,
  gotoDescriptionTabKey,
  gotoInsightsTabKey,
  gotoIssuesTabKey,
  gotoTabKey,
  gotoTodoListTabKey,
  toggleListViewKey,
  vimDown,
  vimUp,
} from '../../../utils/config';
import { HistoryCheckpoint } from '../../../utils/revisions';
import { StarredType } from '../../../utils/starred';
import styles from './initiativeScreen.module.scss';
import { InitiativeWorkItemView, getFilterId } from './initiativeWorkItemList';

export const initiativeScreenView = atomFamily<string, string>({
  key: 'InitiativeScreenView',
  default: 'description',
  effects: key => [localStorageEffect(`__initiativeScreenView_${key}`)],
});

export enum Mode {
  Board = 'board_view',
  List = 'list_view',
}

export const initiativeScreenSpaceMode = atomFamily<Mode, string>({
  key: 'InitiativeScreenSpaceMode',
  default: Mode.List,
  effects: key => [localStorageEffect(`__initiativeScreenSpaceMode__${key}`)],
});

export const initiativeScreenShowEmpty = atomFamily<boolean, string>({
  key: 'InitiativeScreenShowEmpty',
  default: false,
  effects: key => [localStorageEffect(`__initiativeScreenShowEmpty__${key}`)],
});

function Header({ initiative }: { initiative: InitiativeModel }) {
  const location = useLocation<LocationState>();
  // PRIVATE-INITIATIVES-AND-ROADMAPS
  const spaceBreadcrumb = useSpaceBreadcrumb();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(initiative.id));

  const organization = useOrganization();
  const defaultPath = roadmapPath(organization, 'all');
  const entityPath = useEntityPath();
  const { host } = useConfiguration();
  const branchName = useGitBranchName(initiative.id);
  const isMediumScreen = useIsMediumScreen();

  const sidebarCollapsed = useRecoilValue(rightSidebarCollapsed(RightSidebarGroup.Initiatives));

  const { previousEntity, nextEntity, totalCount, position } = useNextPreviousSiblings(
    initiative.id
  );

  if (!entityBreadcrumbs) {
    return null;
  }

  const rightSideContent = (
    <div className="row metadataGapL">
      <Tooltip
        content={
          <>
            Copy initiative number
            <KeyboardShortcut shortcut={copyIssueNumberKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="copy"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            writeToClipboard(`I-${initiative.number}`, `initiative number`);
          }}
        />
      </Tooltip>
      <Tooltip
        content={
          <>
            Copy initiative link
            <KeyboardShortcut shortcut={copyIssueLinkKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="link"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            writeToClipboard(`${host}${entityPath(initiative.id)}`, `link to initiative`);
          }}
        />
      </Tooltip>
      {branchName && (
        <Tooltip
          content={
            <>
              {`Copy git branch name`}
              <KeyboardShortcut shortcut={copyIssueGitBranchKey} />
            </>
          }
        >
          <IconButton
            buttonStyle={ButtonStyle.BareSubtle}
            icon="branch"
            onClick={e => {
              e?.preventDefault();
              e?.stopPropagation();
              if (branchName) {
                writeToClipboard(branchName, 'git branch name');
              }
            }}
          />
        </Tooltip>
      )}

      {sidebarCollapsed && (
        <>
          <RightSidebarButton group={RightSidebarGroup.Initiatives} />
          <NotificationsIndicator />
        </>
      )}
    </div>
  );

  return (
    <ScreenHeader
      className={styles.header}
      hideNotifications
      compensateForMacOSTrafficLights="always"
      rightSideContent={isMediumScreen ? null : rightSideContent}
    >
      <div className="row headerGap ellipsis">
        <BackButton defaultPath={defaultPath} />
        <div className="row metadataGapL">
          <ButtonGroup>
            <Tooltip
              content={
                <>
                  Previous initiative <KeyboardShortcut shortcut={vimUp} />
                </>
              }
            >
              <IconButton disabled={position === 0} icon={'arrow_up'} onClick={previousEntity} />
            </Tooltip>
            <Tooltip
              content={
                <>
                  Next initiative <KeyboardShortcut shortcut={vimDown} />
                </>
              }
            >
              <IconButton
                disabled={position === totalCount - 1}
                icon={'arrow_down'}
                onClick={nextEntity}
              />
            </Tooltip>
          </ButtonGroup>

          <CustomCommand
            command={{
              id: 'next-initiative',
              icon: 'arrow_down',
              description: `Move to the next initiative`,
              handler: () => nextEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimDown,
              priority: 20,
            }}
          />
          <CustomCommand
            command={{
              id: 'previous-initiative',
              icon: 'arrow_up',
              description: `Move to the previous initiative`,
              handler: () => previousEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimUp,
              priority: 20,
            }}
          />
          <div className={styles.count}>
            {position + 1}
            <span className="mr2 ml2">/</span>
            {totalCount}
          </div>
        </div>
        {isMediumScreen && <Breadcrumbs breadcrumbs={[{ name: `I-${initiative.number}` }]} />}
        {!isMediumScreen && (
          <Breadcrumbs
            breadcrumbs={[
              ...(location.state?.breadcrumbs ?? [...spaceBreadcrumb, ...entityBreadcrumbs]),
              {
                name: (
                  <TitleBreadcrumb number={`I-${initiative.number}`} title={initiative.title} />
                ),
              },
            ]}
            starred={{
              type: StarredType.Initiative,
              id: initiative.id,
            }}
          />
        )}
      </div>
    </ScreenHeader>
  );
}

function InitiativeColorPicker({
  initiativeId,
  className,
}: {
  initiativeId: string;
  className?: string;
}) {
  const updateInitiatives = useUpdateInitiatives();
  const initiative = useRecoilValue(initiativeSelector(initiativeId));
  const { darkMode } = useDarkMode();

  if (!initiative) {
    return null;
  }

  return (
    <ColorPicker
      renderPreview={(c, forceDarkMode) => (
        <Initiative color={c} forceDarkMode={forceDarkMode} title={'Preview'} />
      )}
      initialColor={initiative.color}
      onColorPicked={color => {
        updateInitiatives([initiative.id], { color: color ?? 'gray' });
      }}
    >
      <IconButton
        className={className}
        icon={
          <Icon
            size={IconSize.Size30}
            icon="initiative"
            style={{ fill: colorWithOpacity(initiative.color, 10, darkMode, true) }}
          />
        }
        size={ButtonSize.Medium}
        buttonStyle={ButtonStyle.Bare}
      />
    </ColorPicker>
  );
}

function Title({ initiative }: { initiative: InitiativeModel }) {
  const updateInitiatives = useUpdateInitiatives();
  const addMembers = useAddAssigneesToEntities();
  const addLabels = useAddLabelsToEntities();
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState('');

  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  const editTitle = React.useCallback(() => {
    updateInitiatives([initiative.id], { title });
    setTitle('');
    setEditing(false);
  }, [updateInitiatives, initiative.id, title]);

  const ref = React.useRef<TextAreaHandle>(null);

  if (!editing) {
    return (
      <div
        onMouseDown={e => {
          /* mouseDown needs to be used so that the click location travels down into slate
             but we don't want to capture it if you click on the color picker, so we do some
             serious jank.

             You've either clicked the button or the svg, in which case the first part of the if
             is true and we can return.
             If you've clicked a diff, it could be you choosing a color, or clicking the title,
             so check the classname
          */
          if (
            !(e.target instanceof HTMLDivElement) ||
            (e.target instanceof HTMLDivElement && e.target.className.includes('colorIndicator'))
          ) {
            return;
          }
          setTitle(initiative.title);
          setEditing(true);
        }}
        className="rowAlignStart gap16 heading2XL bold wrap fs-exclude fullWidth breakWord"
      >
        <CustomCommand
          command={{
            id: 'edit-title',
            group: CommandGroup.Entities,
            description: 'Edit title',
            hotkey: 't',
            priority: 99,
            handler: () => {
              setTitle(initiative.title);
              setEditing(true);
              ref.current?.focus();
            },
          }}
        />
        <InitiativeColorPicker
          key={`initiative-color-${initiative.id}`}
          initiativeId={initiative.id}
          className="mt2"
        />
        {initiative.title || 'Untitled initiative'}
      </div>
    );
  }

  return (
    <div className="rowAlignStart gap16 heading2XL bold wrap fullWidth fs-exclude">
      <InitiativeColorPicker
        className="mt2 noShrink"
        key={`initiative-color-${initiative.id}`}
        initiativeId={initiative.id}
      />
      <EntityTitleEditor
        ref={ref}
        className="grow"
        initialTitle={initiative.title}
        onChange={v => {
          setTitle(v);
        }}
        autoFocus
        onSubmit={editTitle}
        onFocus={() => {
          disableKeyNav('title');
        }}
        onBlur={() => {
          enableKeyNav('title');
          editTitle();
        }}
        supportedMetadata={{
          users: true,
          orgLabels: true,
        }}
        onMetadataAdded={(type, id) => {
          switch (type) {
            case 'user':
              addMembers([initiative.id], [id]);
              break;
            case 'label':
              addLabels([initiative.id], [id]);
              break;
          }
        }}
        placeholder="Give your initiative a title"
      />
    </div>
  );
}

function TitleHeader({ initiative }: { initiative: InitiativeModel }) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const isSmallScreen = useIsSmallScreen();
  const wide = useRecoilValue(wideInitiativeHeadersSelector);

  const dropdownMenu = (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <IconButton icon="more" buttonStyle={ButtonStyle.Bare} />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        <InitiativeMenu showHistory initiative={initiative} closeMenu={() => setMenuOpen(false)} />
      </DropdownMenuContent>
    </DropdownMenu>
  );

  return (
    <div
      className={cn(styles.titleHeaderContainer, {
        [styles.wide]: wide,
      })}
    >
      <div
        className={cn(styles.gutter, {
          [styles.wide]: wide,
        })}
      />
      <div
        className={cn(styles.titleHeader, {
          [styles.wide]: wide,
        })}
      >
        <div className="col gap16 grow">
          {isSmallScreen && (
            <div className="rowBetween fullWidth">
              <div className="row gap8 flexWrap">
                <InitiativeRoadmaps
                  initiativeId={initiative.id}
                  placeholder
                  size={MetadataSize.Medium}
                />
                <InitiativeStatus initiativeId={initiative.id} size={MetadataSize.Medium} />
              </div>
              <div className="row">
                <EntityMembers interactable max={2} entity={initiative} size={AvatarSize.Size24} />
                {isSmallScreen && dropdownMenu}
              </div>
            </div>
          )}
          <Title key={initiative.id} initiative={initiative} />
          <div className="row gap8 flexWrap">
            {!isSmallScreen && (
              <>
                <InitiativeRoadmaps
                  initiativeId={initiative.id}
                  placeholder
                  size={MetadataSize.Medium}
                />
                <InitiativeStatus initiativeId={initiative.id} size={MetadataSize.Medium} />
              </>
            )}
            <InitiativeSpaces interactable initiative={initiative} size={MetadataSize.Medium} />
            <EntityImpact interactable entity={initiative} size={MetadataSize.Medium} />
            <EntityEffort interactable entity={initiative} size={MetadataSize.Medium} />
            <EntityLabels interactable entity={initiative} orgLevel size={MetadataSize.Medium} />
            <EntityDueDate interactable item={initiative} size={MetadataSize.Medium} />
          </div>
        </div>

        {!isSmallScreen && (
          <div className="row flexAlignStart metadataGapL ml8">
            <EntityMembers interactable max={2} entity={initiative} size={AvatarSize.Size24} />
            {dropdownMenu}
          </div>
        )}
      </div>
      <div
        className={cn(styles.gutter, {
          [styles.wide]: wide,
        })}
      />
    </div>
  );
}

function ShowEmptyColumnsSwitch({ id }: { id: string }) {
  const [showEmpty, setShowEmpty] = useRecoilState(initiativeScreenShowEmpty(id));
  return (
    <Setting title={'Show empty statuses'}>
      <Switch checked={showEmpty} onChange={setShowEmpty} />
    </Setting>
  );
}

function useGetSpaceTabs(initiativeId: string): Tab[] {
  const spaceIds = useRecoilValue(spacesIdsForInitiativeSelector(initiativeId));
  const spaces = useRecoilValue(spacesSelector(spaceIds));

  return React.useMemo(() => {
    if (spaces.length === 1) {
      return [
        {
          id: spaces[0].id,
          icon: 'work_items',
          name: capitalize(`${issueTerm}s`),
        },
      ];
    }

    return [
      {
        id: 'all',
        icon: 'work_items',
        name: `All ${issueTerm}s`,
      },
      ...spaces.map(space => ({
        id: space.id,
        icon: 'work_items',
        name: space.name,
      })),
    ];
  }, [spaces]);
}

function ViewHeader({
  initiative,
  currentView,
  onViewChanged,
}: {
  initiative: InitiativeModel;
  currentView: string;
  onViewChanged: (tab: string) => void;
}) {
  const spaceTabs = useGetSpaceTabs(initiative.id);
  const tabs = React.useMemo<Tab[]>(() => {
    return [
      {
        id: 'description',
        icon: 'notes',
        name: 'Description',
      },
      ...spaceTabs,
    ];
  }, [spaceTabs]);
  const wide = useRecoilValue(wideInitiativeHeadersSelector);

  const [mode, setMode] = useRecoilState(
    initiativeScreenSpaceMode(`${initiative.id}-${currentView}`)
  );

  return (
    <div className={styles.tabHeaderContainer}>
      <div
        className={cn(styles.gutter, {
          [styles.wide]: wide,
        })}
      />
      <div
        className={cn(styles.tabHeader, {
          [styles.wide]: wide,
        })}
      >
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentView}
          onTabChanged={onViewChanged}
        />
        {currentView !== 'description' && (
          <div className="row gap8">
            <MetadataConfigurationButton
              header={
                currentView !== 'all' ? (
                  <ShowEmptyColumnsSwitch id={getFilterId(currentView, initiative.id)} />
                ) : undefined
              }
              excludeOptions={workItemDefaultExcludeOptions}
              boardId={getFilterId(currentView, initiative.id)}
            />
            <SegmentedControl
              className="mr8"
              label="board and list toggle"
              value={mode}
              onValueChanged={m => setMode(m as Mode)}
              options={[
                {
                  id: Mode.Board,
                  icon: Mode.Board,
                  label: `Change to mode ${Mode.Board}`,
                  tooltip:
                    mode === Mode.Board ? undefined : (
                      <>
                        Board view <KeyboardShortcut shortcut={toggleListViewKey} />
                      </>
                    ),
                },
                {
                  id: Mode.List,
                  icon: Mode.List,
                  label: `Change to mode ${Mode.List}`,
                  tooltip:
                    mode === Mode.List ? undefined : (
                      <>
                        List view <KeyboardShortcut shortcut={toggleListViewKey} />
                      </>
                    ),
                },
              ]}
            />
          </div>
        )}
      </div>
      <div
        className={cn(styles.gutter, {
          [styles.wide]: wide,
        })}
      />
    </div>
  );
}

function MainScrollArea({
  children,
  initiativeId,
}: {
  children: React.ReactNode;
  initiativeId: string;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationColumn(`main-${initiativeId}`, [`main-${initiativeId}`]);
  const selected = useHasKeyNavigationFocus(`main-${initiativeId}`);

  React.useEffect(() => {
    if (selected && document.activeElement === document.body) {
      ref.current?.focus();
    }
  }, [selected]);

  return (
    <div className="colStretch grow overflowScrollY fullWidth" ref={ref} tabIndex={-1}>
      {children}
    </div>
  );
}

function Description({ initiativeId }: { initiativeId: string }) {
  const initiative = useRecoilValue(initiativeSelector(initiativeId));
  const partialEntity = useRecoilValue(isPartialSelector(initiativeId));
  const [descriptionDisabled, setDescriptionDisabled] = React.useState(false);
  const [historyDescription, setHistoryDescription] = React.useState<HistoryCheckpoint | null>(
    null
  );
  const ref = React.useRef<TextAreaHandle>(null);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const docId = useRecoilValue(collaborativeDocIdByEntitySelector(initiativeId));

  useSaveScroll(initiativeId, scrollRef);

  return (
    <>
      {!partialEntity && (
        <div className={styles.descriptionContainer} ref={scrollRef}>
          <div className={styles.gutter} />
          <CollaborativeDocEditor
            key={`description-${initiativeId}`}
            className={cn(styles.description, 'fs-exclude')}
            documentId={docId ?? ''}
            entityId={initiativeId}
            inlineComments
            historyDescription={historyDescription}
            textAreaRef={ref}
            disabled={descriptionDisabled || !!initiative?.archivedAt}
            bottomSpacing={24}
            placeholder={<DescriptionPlaceholder type={PlaceholderType.Initiative} />}
          />
          <div className={styles.gutter} />
        </div>
      )}
      {partialEntity && (
        <div className={styles.loadingContainer}>
          <LoadingSpinner />
        </div>
      )}
      <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
        <DescriptionHistory
          documentId={docId ?? ''}
          onDescriptionChanged={d => {
            setHistoryDescription(d);
          }}
          onRestore={d => {
            setHistoryDescription(null);
            setTimeout(() => ref.current?.replaceValue(d.content));
          }}
          onOpen={() => {
            setDescriptionDisabled(true);
          }}
          onClose={() => {
            setDescriptionDisabled(false);
          }}
        />
      </HideIfSmallerThan>
    </>
  );
}

function RightHeader({
  tabs,
  currentTab,
  onTabChanged,
}: {
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  return (
    <ScreenHeader className={styles.header} collapsibleGroup={RightSidebarGroup.Initiatives}>
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function Context({ initiativeId }: { initiativeId: string }) {
  const organization = useOrganization();
  const defaultPath = roadmapPath(organization, 'all');
  const history = useHistory();
  const { previousEntity, nextEntity } = useNextPreviousSiblings(initiativeId);

  useDeleteInitiativesContext({
    onDelete: () => {
      if (previousEntity()) {
        return;
      }

      if (nextEntity()) {
        return;
      }

      history.push(defaultPath);
    },
  });

  return (
    <CommandContext
      context={{
        group: CommandGroup.Entities,
        entityIds: [initiativeId],
        focusedEntityId: initiativeId,
      }}
    />
  );
}

function SupersetTabs({
  initiativeId,
  currentTab,
  onTabChanged,
}: {
  initiativeId: string;
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  const spaceTabs = useGetSpaceTabs(initiativeId);
  const tabs = useEntityTabs(initiativeId, spaceTabs);

  return (
    <ScreenHeader
      className={cn(styles.header, 'overflowScrollX')}
      collapsibleGroup={RightSidebarGroup.Initiatives}
    >
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function InitiativeScreen({ initiativeId }: { initiativeId: string }) {
  const organization = useOrganization();
  const initiative = useRecoilValue(initiativeSelector(initiativeId));
  const spaceIds = useRecoilValue(spacesIdsForInitiativeSelector(initiativeId));
  const updateInitiatives = useUpdateInitiatives();
  const spaces = useRecoilValue(spacesSelector(spaceIds));
  const isPrivate = spaces.some(s => s.private);
  const maybeSpace = useMaybeSpace();
  const history = useHistory();

  const mediumScreen = useIsMediumScreen();

  const [openCommentThreadId, setOpenCommentThreadId] = React.useState<string | null>(null);

  const [currentView, setCurrentView] = useRecoilState(initiativeScreenView(initiativeId));
  const initialTab = useInitialTab();
  const [currentTab, setCurrentTab] = React.useState(initialTab);
  const tabs = useEntityTabs(initiativeId);
  const subMode = useRecoilValue(initiativeScreenSpaceMode(`${initiativeId}-${currentView}`));
  const [supersetTab, setSupersetTab] = React.useState(currentView);

  React.useEffect(() => {
    setSupersetTab(currentView);
  }, [currentView]);

  const issueIdsByStatus = useRecoilValue(
    issueIdsByStatusForInitiativeSelector({ initiativeId, spaceId: currentView })
  );

  const statusIds = React.useMemo(() => keys(issueIdsByStatus), [issueIdsByStatus]);
  const rightBarCollapsed = useRecoilValue(rightSidebarCollapsed(RightSidebarGroup.Initiatives));

  useUpdateRecents(initiativeId);

  React.useEffect(() => {
    if (currentView !== 'description' && currentView !== 'all' && !spaceIds.includes(currentView)) {
      setCurrentView('all');
    }
  }, [currentView, setCurrentView, spaceIds]);

  if (isPrivate && !maybeSpace) {
    return (
      <Redirect
        to={{
          pathname: spacePath(organization, spaces[0], `initiatives/${initiative?.number}`),
          state: history.location.state,
        }}
      />
    );
  }

  if (!initiative) {
    return null;
  }

  let columnIds = [] as string[];

  if (currentView === 'description') {
    columnIds = [`main-${initiativeId}`];
  } else if (currentView === 'all') {
    columnIds = [`${initiativeId}`];
  } else {
    if (subMode === Mode.List) {
      columnIds = [`${getFilterId(currentView, initiativeId)}`];
    } else {
      columnIds = [...statusIds];
    }
  }
  if (!rightBarCollapsed) {
    columnIds.push(...[...ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS, `right-${initiativeId}`]);
  }

  return (
    <KeyNavigationProvider
      columnIds={columnIds}
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <Screen hideSidebar row={!mediumScreen} className={styles.initiativeScreen}>
        <TitleSetter title={`${organization.name} · Initiatives · ${initiative.title}`} />
        <Context initiativeId={initiativeId} />

        {!rightBarCollapsed && <EntityScreenKeyNavHacks />}
        <div className={cn(styles.left)}>
          <Header initiative={initiative} />
          <MainScrollArea initiativeId={initiative.id}>
            {initiative.archivedAt && (
              <ArchivedNotice
                type={'initiative'}
                onUnarchive={() =>
                  updateInitiatives([initiativeId], { archivedAt: null, title: 'Hey' })
                }
              />
            )}

            {mediumScreen && (
              <>
                <SupersetTabs
                  initiativeId={initiative.id}
                  currentTab={supersetTab}
                  onTabChanged={setSupersetTab}
                />

                {supersetTab === 'description' && (
                  <>
                    <TitleHeader initiative={initiative} />
                    <Description initiativeId={initiativeId} />
                  </>
                )}
                {!['description', 'activities', 'insights', 'todos'].includes(supersetTab) && (
                  <InitiativeWorkItemView initiativeId={initiativeId} currentTab={supersetTab} />
                )}

                {(supersetTab === 'activities' ||
                  supersetTab === 'insights' ||
                  supersetTab === 'todos') && (
                  <RightBar
                    className={styles.right}
                    collapsibleGroup={RightSidebarGroup.Initiatives}
                  >
                    {supersetTab === 'activities' && (
                      <ActivitiesTab
                        entityId={initiativeId}
                        openCommentThreadId={openCommentThreadId}
                      />
                    )}
                    {supersetTab === 'insights' && (
                      <InsightsTab entityId={initiativeId} entityType={'initiative'} />
                    )}
                    {supersetTab === 'todos' && (
                      <TodoList
                        entityId={initiativeId}
                        className={styles.todoTab}
                        keyNavColumnId={`right-${initiativeId}`}
                      />
                    )}
                  </RightBar>
                )}
              </>
            )}

            {!mediumScreen && (
              <>
                <TitleHeader initiative={initiative} />
                <ViewHeader
                  initiative={initiative}
                  currentView={currentView}
                  onViewChanged={setCurrentView}
                />

                {currentView === 'description' && <Description initiativeId={initiativeId} />}
                {currentView !== 'description' && (
                  <InitiativeWorkItemView initiativeId={initiativeId} currentTab={currentView} />
                )}
              </>
            )}
          </MainScrollArea>
        </div>

        {!mediumScreen &&
          (currentTab === 'activities' || currentTab === 'insights' || currentTab === 'todos') && (
            <RightBar className={styles.right} collapsibleGroup={RightSidebarGroup.Initiatives}>
              <RightHeader tabs={tabs} currentTab={currentTab} onTabChanged={setCurrentTab} />

              {currentTab === 'activities' && (
                <ActivitiesTab entityId={initiativeId} openCommentThreadId={openCommentThreadId} />
              )}
              {currentTab === 'insights' && (
                <InsightsTab entityId={initiativeId} entityType={'initiative'} />
              )}
              {currentTab === 'todos' && (
                <TodoList
                  entityId={initiativeId}
                  className={styles.todoTab}
                  keyNavColumnId={`right-${initiativeId}`}
                />
              )}
            </RightBar>
          )}
        <QueryParameterManager
          onOpenThreadIdChanged={threadId => {
            if (threadId && threadId !== openCommentThreadId) {
              setCurrentTab('activities');
              setSupersetTab('activities');
            }
            setOpenCommentThreadId(threadId);
          }}
          onCommentOrActivityIdChanged={commentOrActivityId => {
            if (commentOrActivityId) {
              setCurrentTab('activities');
              setSupersetTab('activities');
            }
          }}
          onInsightIdChanged={insightId => {
            if (insightId) {
              setCurrentTab('insights');
              setSupersetTab('insights');
            }
          }}
        />
      </Screen>

      <Hotkey
        priority={0}
        hotkey={gotoActivitiesTabKey}
        handler={(e?: KeyboardEvent | undefined) => {
          e?.preventDefault();
          e?.stopPropagation();
          setCurrentTab('activities');
          setSupersetTab('activities');
        }}
      />

      <Hotkey
        priority={0}
        hotkey={gotoInsightsTabKey}
        handler={(e?: KeyboardEvent | undefined) => {
          e?.preventDefault();
          e?.stopPropagation();
          setCurrentTab('insights');
          setSupersetTab('insights');
        }}
      />

      <Hotkey
        priority={0}
        hotkey={gotoTodoListTabKey}
        handler={(e?: KeyboardEvent | undefined) => {
          e?.preventDefault();
          e?.stopPropagation();
          setCurrentTab('todos');
          setSupersetTab('todos');
        }}
      />

      <Hotkey
        priority={0}
        hotkey={gotoIssuesTabKey}
        handler={(e?: KeyboardEvent | undefined) => {
          e?.preventDefault();
          e?.stopPropagation();
          setCurrentView('all');
          setSupersetTab('all');
        }}
      />

      <Hotkey
        priority={0}
        hotkey={gotoDescriptionTabKey}
        handler={(e?: KeyboardEvent | undefined) => {
          e?.preventDefault();
          e?.stopPropagation();
          setCurrentView('description');
          setSupersetTab('description');
        }}
      />

      {spaceIds.map((spaceId, index) => (
        <Hotkey
          key={spaceId}
          priority={0}
          hotkey={`${gotoTabKey} ${index + 1}`}
          handler={(e?: KeyboardEvent | undefined) => {
            e?.preventDefault();
            e?.stopPropagation();
            setCurrentView(spaceId);
            setSupersetTab('space');
          }}
        />
      ))}
    </KeyNavigationProvider>
  );
}

export default React.memo(InitiativeScreen);
