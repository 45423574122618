import { Location } from 'history';
import { spaceRoadmapsBySpace } from '../../sync/__generated/indexes';
import {
  Board,
  Cycle,
  Doc,
  Folder,
  Initiative,
  Issue,
  IssueStatus,
  Organization,
  Release,
  Roadmap,
  Space,
  View,
} from '../../sync/__generated/models';
import { statusToIcon } from '../components/new/statusIcon';
import { indexHelper } from '../syncEngine/actions/helpers';
import { cyclePath } from '../syncEngine/selectors/cycles';
import { documentPath } from '../syncEngine/selectors/documents';
import { entityPath } from '../syncEngine/selectors/entities';
import { getFolderPath } from '../syncEngine/selectors/folders';
import { organizationPath } from '../syncEngine/selectors/organizations';
import { roadmapPath } from '../syncEngine/selectors/roadmaps';
import { spacePath } from '../syncEngine/selectors/spaces';
import { SyncEngineIndex, SyncEngineObject } from '../syncEngine/types';

export enum StarredType {
  Board = 'board',
  WorkItem = 'workItem',
  Initiative = 'initiative',
  Roadmap = 'roadmap',
  SpaceRoadmap = 'spaceRoadmap',
  Archive = 'archive',
  SpaceInitiatives = 'spaceInitiatives',
  Cycles = 'cycles',
  Cycle = 'cycle',
  FeedbackView = 'feedbackView',
  Document = 'document',
  Folder = 'folder',
  Release = 'release',
}

export interface Starred {
  id: string;
  type: StarredType;
}

export interface RenderedStarred extends Starred {
  name: string;
  icon: string;
  iconColor?: string;
  link: string;
  isActive?: (match: unknown | null, location: Location) => boolean;
}

export function renderStarred(
  get: <T extends SyncEngineObject>(id: string) => T | null,
  getIndex: (index: SyncEngineIndex, id: string) => string[],
  starred: Starred,
  organization: Organization
): RenderedStarred | null {
  function getNotDeleted<T extends SyncEngineObject>(id: string): T | null {
    const item = get<T>(id);
    return item !== null && !item.deleted ? item : null;
  }

  switch (starred.type) {
    case StarredType.WorkItem: {
      const workItem = getNotDeleted<Issue>(starred.id);
      if (!workItem) {
        return null;
      }
      const space = getNotDeleted<Space>(workItem.spaceId);
      if (!space) {
        return null;
      }
      const status = getNotDeleted<IssueStatus>(workItem.statusId);
      if (!status) {
        return null;
      }
      return {
        ...starred,
        name: workItem.title,
        icon: statusToIcon(status.statusType),
        link: entityPath(organization, space, workItem),
      };
    }
    case StarredType.Board: {
      const board = getNotDeleted<Board>(starred.id);
      if (!board) {
        return null;
      }

      const space = getNotDeleted<Space>(board.spaceId);
      if (!space) {
        return null;
      }

      return {
        ...starred,
        name: `${space.name} - ${board.name}`,
        icon: board.key === 'backlog' ? 'backlog' : 'current',
        link: spacePath(organization, space, `boards/${board.key}`),
      };
    }
    case StarredType.Initiative: {
      const initiative = getNotDeleted<Initiative>(starred.id);
      if (!initiative) {
        return null;
      }
      return {
        ...starred,
        name: initiative.title,
        icon: 'initiative',
        iconColor: initiative.color,
        link: entityPath(organization, null, initiative),
      };
    }
    case StarredType.SpaceInitiatives: {
      const space = getNotDeleted<Space>(starred.id);
      if (!space) {
        return null;
      }

      return {
        ...starred,
        name: `${space.name} - Initiatives`,
        icon: 'initiative',
        link: spacePath(organization, space, 'initiatives'),
      };
    }
    case StarredType.Roadmap: {
      const roadmap = getNotDeleted<Roadmap>(starred.id);
      if (!roadmap) {
        return null;
      }
      return {
        ...starred,
        name: roadmap.name,
        icon: 'initiative',
        iconColor: roadmap.color,
        link: roadmapPath(organization, roadmap),
      };
    }
    case StarredType.SpaceRoadmap: {
      const space = getNotDeleted<Space>(starred.id);
      if (!space) {
        return null;
      }
      const spaceRoadmap = indexHelper(
        { get: getNotDeleted, getIndex },
        spaceRoadmapsBySpace,
        space.id
      )[0];
      if (!spaceRoadmap) {
        return null;
      }

      return {
        ...starred,
        name: `${space.name} - Roadmap`,
        icon: 'roadmap',
        link: spacePath(organization, space, 'roadmap'),
      };
    }
    case StarredType.Cycles: {
      const space = getNotDeleted<Space>(starred.id);
      if (!space) {
        return null;
      }

      return {
        ...starred,
        name: `${space.name} - Cycles`,
        icon: 'cycle_current',
        link: spacePath(organization, space, 'cycles'),
      };
    }
    case StarredType.Cycle: {
      if (starred.id.startsWith('current-')) {
        const [, spaceId] = starred.id.split('-');
        const space = getNotDeleted<Space>(spaceId);
        if (!space || !space.activeCycleId) {
          return null;
        }

        const cycle = getNotDeleted<Cycle>(space.activeCycleId);
        if (!cycle) {
          return null;
        }

        return {
          ...starred,
          name: `${space.name} - Current Cycle`,
          icon: 'cycle_current',
          link: cyclePath(organization, space, cycle),
        };
      }
      if (starred.id.startsWith('upcoming-')) {
        const [, spaceId] = starred.id.split('-');
        const space = getNotDeleted<Space>(spaceId);
        if (!space || !space.upcomingCycleId) {
          return null;
        }

        const cycle = getNotDeleted<Cycle>(space.upcomingCycleId);
        if (!cycle) {
          return null;
        }

        return {
          ...starred,
          name: `${space.name} - Upcoming Cycle`,
          icon: 'cycle_upcoming',
          link: cyclePath(organization, space, cycle),
        };
      }

      const cycle = getNotDeleted<Cycle>(starred.id);
      if (!cycle) {
        return null;
      }
      const space = getNotDeleted<Space>(cycle.spaceId);
      if (!space) {
        return null;
      }

      let icon = 'cycle_completed';
      if (space.activeCycleId === cycle.id) {
        icon = 'cycle_current';
      } else if (space.upcomingCycleId === cycle.id) {
        icon = 'cycle_upcoming';
      }

      return {
        ...starred,
        name: `${space.name} - ${cycle.title}`,
        icon,
        link: cyclePath(organization, space, cycle),
      };
    }
    case StarredType.Archive: {
      const space = getNotDeleted<Space>(starred.id);
      if (!space) {
        return null;
      }

      return {
        ...starred,
        name: `${space.name} - Archive`,
        icon: 'archive',
        link: spacePath(organization, space, 'archive'),
      };
    }
    case StarredType.Document: {
      const doc = getNotDeleted<Doc>(starred.id);
      if (!doc) {
        return null;
      }
      return {
        ...starred,
        name: doc.title,
        icon: 'document',
        link: documentPath(organization, doc),
      };
    }
    case StarredType.Folder: {
      if (starred.id === 'root-folder') {
        return {
          ...starred,
          name: 'Documents',
          icon: 'folder',
          link: organizationPath(organization, 'documents'),
        };
      }
      const folder = getNotDeleted<Folder>(starred.id);

      if (!folder) {
        return null;
      }

      const path = getFolderPath(get, folder.id);
      return {
        ...starred,
        name: folder.name,
        icon: 'folder',
        link: organizationPath(organization, `documents/${path}`),
      };
    }
    case StarredType.Release: {
      const release = getNotDeleted<Release>(starred.id);
      if (!release) {
        return null;
      }
      return {
        ...starred,
        name: release.title,
        icon: 'release',
        link: entityPath(organization, null, release),
      };
    }
    case StarredType.FeedbackView: {
      let view;

      if (starred.id !== 'inbox' && starred.id !== 'processed') {
        view = getNotDeleted<View>(starred.id);
        if (!view) {
          return null;
        }
      }

      return {
        ...starred,
        name: view?.name ?? starred.id,
        icon: 'view',
        iconColor: view?.color,
        link: organizationPath(organization, `feedback?feedbackTab=${starred.id}`),
        isActive: (_, location: Location) => {
          return location.search.includes(`?feedbackTab=${starred.id}`);
        },
      };
    }
    default:
      return null;
  }
}
