import { capitalize } from 'lodash';
import * as React from 'react';
import { FilteredListViewHandle } from '../filteredListView';
import { Icon, IconSize } from '../icon';
import { ListViewItem } from '../listView';
import { Picker, PickerState } from '../picker';

export function iconForState(state: string) {
  switch (state) {
    case 'active':
      return 'status_todo';
    default:
      return 'status_done';
  }
}

export function StatePicker({
  state,
  filterPlaceholder,
  filterClassName,
  additionalItems,
  additionalItemsFirst,
  labels,
  onDone,
  onStateAdded,
  onStateRemoved,
}: {
  state: PickerState;
  filterPlaceholder?: string;
  filterClassName?: string;
  additionalItems?: ListViewItem[];
  additionalItemsFirst?: boolean;
  labels?: {
    active: string;
    closed: string;
  };
  onDone: () => void;
  onStateAdded: (issueIds: string[], impactId: string) => void;
  onStateRemoved: (issueIds: string[], impactId: string) => void;
}) {
  const states = ['active', 'closed'];

  const items: ListViewItem[] = states.map(state => {
    return {
      id: state,
      mouseDown: true,
      contents: () => (
        <>
          <Icon icon={iconForState(state)} size={IconSize.Size20} />
          <span className="ellipsis">
            {capitalize(labels?.[state as 'active' | 'closed'] ?? state)}
          </span>
        </>
      ),
    };
  });

  if (additionalItems) {
    if (additionalItemsFirst) {
      items.unshift(...additionalItems);
    } else {
      items.push(...additionalItems);
    }
  }

  const filterRef = React.useRef<FilteredListViewHandle>(null);

  return (
    <Picker
      filterLabel="Choose state"
      filterClassName={filterClassName}
      filterPlaceholder={filterPlaceholder ?? 'Choose state'}
      placeholder={'No state found'}
      ref={filterRef}
      multi
      state={state}
      items={items}
      propertiesToSearch={['id']}
      onAdd={onStateAdded}
      onRemove={onStateRemoved}
      onDone={onDone}
    />
  );
}
