import debugModule from 'debug';
import gql from 'graphql-tag';
import { random } from 'lodash';
import NodeCache from 'node-cache';
import * as React from 'react';
import { useSubscription } from 'urql';
import {
  TransactionType,
  TransactionSubscriptionSubscriptionVariables,
  TransactionSubscriptionSubscription,
  AppliedTransactionPayload,
  UpdateAppliedTransactionPayload,
  SpaceAppliedTransactionPayload,
  InitiativeSpaceAppliedTransactionPayload,
  SpaceRoadmapAppliedTransactionPayload,
} from '../../../graphql__generated__/graphql';
import { useClient } from '../contexts/clientContext';
import { useSubscriptionClient } from '../contexts/graphqlContext';
import { useOrganization } from '../contexts/organizationContext';
import { appendDiagnostic } from '../diagnostics';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { useCleanupAfterSpaceDeletion } from '../syncEngine/actions/spaces';
import { useShowNotification } from '../syncEngine/selectors/updates';
import {
  appliedCollaborativeDocumentTransactionPayloadFragment,
  appliedCollectionTransactionPayloadFragment,
  appliedTransactionPayloadFragment,
} from './fragments';
import { useModelManager } from './modelManager';

const debug = debugModule('subscriptions');

const fetchCache = new NodeCache({
  stdTTL: 150,
});

const NUM_RETRIES = 3;

function flushExpiredCacheKeys() {
  // The NodeCache doesn't evict stuff from the cache until it's fetched.
  // Since we're relaying on the length of keys() elsewhere, we need a way to
  // remove the dead stuff so that keys().length is accurate.
  for (const key of fetchCache.keys()) {
    fetchCache.get(key);
  }
}

export function Subscriptions() {
  const organization = useOrganization();
  const clientId = useClient();
  const modelManager = useModelManager();
  const showNotification = useShowNotification();
  const cleanupSpace = useCleanupAfterSpaceDeletion();

  const { subscriptionClient } = useSubscriptionClient();

  const pingTimeoutRef = React.useRef(-1);

  useComponentDidMount(() => {
    subscriptionClient?.on('closed', () => {
      debug('Web socket connection disconnected');
      appendDiagnostic({
        type: 'webSocketTrace',
        event: 'close',
      });
    });
    subscriptionClient?.on('error', e => {
      debug('Web socket connection error');
      appendDiagnostic({
        type: 'webSocketTrace',
        event: 'error',
        error: JSON.stringify(e),
      });
    });
    subscriptionClient?.on('pong', () => {
      if (pingTimeoutRef.current !== -1) {
        window.clearTimeout(pingTimeoutRef.current);
      }
      pingTimeoutRef.current = window.setTimeout(() => {
        appendDiagnostic({
          type: 'webSocketTrace',
          event: 'noPingPong',
        });
      }, 60000);
    });
    subscriptionClient?.on('connected', async () => {
      appendDiagnostic({
        type: 'webSocketTrace',
        event: 'connect',
      });

      modelManager.checkForMissingChanges();

      debug('Web socket connection reconnected, will refresh queries');
      flushExpiredCacheKeys();
      if (fetchCache.keys().length >= NUM_RETRIES) {
        appendDiagnostic({
          type: 'webSocketTrace',
          event: 'refetch',
          throttled: true,
        });
        debug('Too many refetches, throttling');
        return;
      }
      fetchCache.set(`fetch-${Date.now()}`, true);
      setTimeout(async () => {
        appendDiagnostic({
          type: 'webSocketTrace',
          event: 'refetch',
          throttled: false,
        });
        await modelManager.fetchData({ organizationSlug: organization.slug });
      }, random(3000, 15000));
    });
  });

  useSubscription<
    TransactionSubscriptionSubscription,
    null | undefined,
    TransactionSubscriptionSubscriptionVariables
  >(
    {
      query: gql`
        subscription TransactionSubscription($organization: ID!, $clientId: String!) {
          transaction(input: { id: $organization, clientId: $clientId }) {
            id
            payload {
              ... on AppliedTransactionPayload {
                ...AppliedTransactionPayloadFragment
              }
              ... on AppliedCollectionTransactionPayload {
                ...AppliedCollectionTransactionPayloadFragment
              }
              ... on AppliedCollaborativeDocumentTransactionPayload {
                ...AppliedCollaborativeDocumentTransactionPayloadFragment
              }
            }
          }
        }
        ${appliedTransactionPayloadFragment}
        ${appliedCollectionTransactionPayloadFragment}
        ${appliedCollaborativeDocumentTransactionPayloadFragment}
      `,
      variables: {
        clientId: clientId,
        organization: organization.id,
      },
    },
    (_prev, data) => {
      const subscription = data?.transaction;
      if (!subscription) {
        return;
      }

      debug('onSubscriptionData', subscription);
      modelManager.handleRemoteTransaction(subscription.payload);

      for (const payload of subscription.payload) {
        // handle push notifications
        if (payload.type === TransactionType.CREATE && payload.modelType === 'Update') {
          const transactionPayload = payload as AppliedTransactionPayload;
          const update = transactionPayload.properties as UpdateAppliedTransactionPayload;
          if (update.notification) {
            setTimeout(async () => {
              showNotification(payload.modelId);
            }, 5000);
          }
        }

        // When new spaces are created, refetch data. For a true new space, this will just have the effect of creating the
        // markers and for spaces where we've been granted access, it will fetch the issues, etc.
        if (payload.type === TransactionType.CREATE && payload.modelType === 'Space') {
          const transactionPayload = payload as AppliedTransactionPayload;
          const space = transactionPayload.properties as SpaceAppliedTransactionPayload;
          modelManager.fetchData({
            organizationSlug: organization.slug,
            spaceSlug: space.slug ?? undefined,
            fullRefetch: true,
          });
        }

        // When a space is deleted (or access is removed), let's try to clean up the mess
        if (payload.type === TransactionType.UPDATE && payload.modelType === 'Space') {
          const transactionPayload = payload as AppliedTransactionPayload;
          const space = transactionPayload.properties as SpaceAppliedTransactionPayload;
          if (space.deleted) {
            cleanupSpace(transactionPayload.modelId);
            modelManager.flushOfflineCache(true);

            // refetch roadmaps and initiatives for the space in case it's some stuff going private that triggered this
            modelManager.fetchRoadmaps(organization.id);
            modelManager.fetchInitiatives(organization.id, { spaceIds: [payload.modelId] });
          }
        }

        // When spaces are added/removed from intiaitives, we need to refetch the initiatives to see
        // if any access has been granted/revoked
        if (payload.type === TransactionType.CREATE && payload.modelType === 'InitiativeSpace') {
          modelManager.fetchInitiatives(organization.id, {
            initiativeSpaceIds: [payload.modelId],
          });
        }
        if (payload.type === TransactionType.UPDATE && payload.modelType === 'InitiativeSpace') {
          const transactionPayload = payload as AppliedTransactionPayload;
          const properties =
            transactionPayload.properties as InitiativeSpaceAppliedTransactionPayload;

          if (properties.deleted) {
            modelManager.fetchInitiatives(organization.id, {
              initiativeSpaceIds: [payload.modelId],
            });
          }
        }

        // When spaces are added/removed from roadmaps, we need to refetch the roadmaps to see
        // if any access has been granted/revoked
        if (payload.type === TransactionType.CREATE && payload.modelType === 'SpaceRoadmap') {
          const transactionPayload = payload as AppliedTransactionPayload;
          const properties = transactionPayload.properties as SpaceRoadmapAppliedTransactionPayload;

          if (properties.spaceId) {
            modelManager.fetchRoadmaps(organization.id);
          }
        }
        if (payload.type === TransactionType.UPDATE && payload.modelType === 'SpaceRoadmap') {
          const transactionPayload = payload as AppliedTransactionPayload;
          const properties = transactionPayload.properties as SpaceRoadmapAppliedTransactionPayload;

          if (properties.deleted) {
            modelManager.fetchRoadmaps(organization.id);
          }
        }
      }

      // we're not consuming the data from the hook so we don't care what this function returns
      return null;
    }
  );

  return null;
}
