import { isEqual } from 'lodash';
import { selectorFamily } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { insightsByEntity, insightsByFeedback } from '../../../sync/__generated/indexes';
import { Insight } from '../../../sync/__generated/models';
import { equalSelectorFamily } from '../../utils/recoil';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { entitySelector } from './entities';

export const insightSelector = selectorFamily({
  key: 'Insights',
  get:
    (insightId: string) =>
    ({ get }) => {
      return get(syncEngineState(insightId)) as Insight | null;
    },
});

export const insightIdsForEntity = equalSelectorFamily({
  key: 'InsightIdsForEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      return get(insightsForEntity(entityId)).map(i => i.id);
    },
  equals: isEqual,
});

export const insightsForEntity = selectorFamily({
  key: 'InsightsForEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const entity = get(entitySelector(entityId));
      if (!entity) {
        return [];
      }
      let insightIds;
      if (entity.__typename === 'Feedback') {
        insightIds = get(indexKeyState(indexKey(insightsByFeedback, entityId)));
      } else {
        insightIds = get(indexKeyState(indexKey(insightsByEntity, entityId)));
      }
      return filterNotDeletedNotNull(insightIds.map(id => get(insightSelector(id)))).filter(
        i => !i.orphaned
      );
    },
});

export const insightCountForFeedbackSelector = selectorFamily({
  key: 'InsightCountForFeedback',
  get:
    (feedbackId: string) =>
    ({ get }) => {
      const insightIds = get(indexKeyState(indexKey(insightsByFeedback, feedbackId)));
      return insightIds.length;
    },
});
