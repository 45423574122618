import cn from 'classnames';
import * as React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { RecoilValueReadOnly, atom, useSetRecoilState } from 'recoil';
import { CommandGroup } from '../../../commands';
import { useRestoreScrollPosition } from '../../../hooks/new/useRestoreScrollPosition';
import { ColumnDragHandle } from '../../../screens/new/workItemBoardScreen/columnDragHandle';
import { sessionStorageEffect } from '../../../syncEngine/effects';
import { autoSortedMessage, hideAutoSortedMessage } from '../autoSortedToast';
import { useClearSelection, useGetKeyNavigationState } from '../keyNavigation';
import { Column, RenderColumnProperties } from './column';
import {
  COLUMN_DRAG_PREVIEW_ID,
  columnDragDestinationAtom,
  columnDragLocationAtom,
} from './columnHeader';
import { CopyAndPasteProperties, Hotkeys } from './hotkeys';
import styles from './virtualizedBoardView.module.scss';

export const boardScrollPositionState = atom<number>({
  key: 'BoardScrollPosition',
  default: 0,
  effects: [sessionStorageEffect(`__scrollBoard`)],
});

export function VirtualizedBoardView({
  id,
  columnIds,
  disabledColumnDndMessages,
  dragEnabled,
  canCreate,
  getAllColumns,
  onMoveCards,
  onCopy,
  onPaste,
  renderNewColumn,
  className,
  style,
  columnHeaderHeight,
  commandGroup,
  dragDisabledColumns,
  ...rest
}: {
  id: string;
  columnIds: string[];
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  dragEnabled?: boolean;
  canCreate?: () => boolean;
  getAllColumns: () => {
    [k: string]: string[];
  };
  renderNewColumn?: () => React.ReactNode;
  getSelector: (columnId: string) => RecoilValueReadOnly<string[]>;
  onMoveCards?: (ids: string[], toColumn: string, toIndex: number) => void;
  className?: string;
  style?: React.CSSProperties;
  columnHeaderHeight: number;
  commandGroup?: CommandGroup;
  dragDisabledColumns?: string[];
} & RenderColumnProperties &
  CopyAndPasteProperties) {
  const columnsRef = React.useRef(columnIds);
  columnsRef.current = columnIds;

  const [newCard, setNewCard] = React.useState<{ columnId: string; index: number } | null>(null);
  const [editCard, setEditCard] = React.useState<{ columnId: string; index: number } | null>(null);
  const [draggingId, setDraggingId] = React.useState<string | null>(null);
  const [dragCount, setDragCount] = React.useState<number | null>(null);

  const setColumnDragLocation = useSetRecoilState(columnDragLocationAtom);
  const setColumnDragDestination = useSetRecoilState(columnDragDestinationAtom);

  const getKeyNavState = useGetKeyNavigationState();
  const clearSelection = useClearSelection();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  useRestoreScrollPosition(id, scrollRef);

  const onNewCard = React.useCallback(
    (position: { columnId: string; index: number } | null) => {
      if (canCreate && !canCreate()) {
        return;
      }
      setEditCard(null);
      setNewCard(position);
    },
    [canCreate]
  );

  const onEditCard = React.useCallback(
    (position: { columnId: string; index: number } | null) => {
      setNewCard(null);
      setEditCard(position);
    },
    [setNewCard, setEditCard]
  );

  const [height, setHeight] = React.useState(0);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      // 20 is the padding in the board
      setHeight((scrollRef.current?.offsetHeight ?? 0) - (columnHeaderHeight + 20));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDragOver = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>, id: string) => (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      // we can't read the dataTransfer data properly, but we can detect a custom type, and that's enough
      // to identify that we are dragging a column and not something else (like a card)
      if (e.dataTransfer.types[0] !== 'column') {
        return;
      }

      const left = ref.current?.offsetLeft ?? 0;
      const width = ref.current?.offsetWidth ?? 0;
      const top = ref.current?.offsetTop ?? 0;

      const parent = e.currentTarget.parentElement;
      if (!parent) {
        return;
      }

      const mouseX = parent.scrollLeft + e.clientX - parent.offsetLeft;

      if (left + width / 2 < mouseX) {
        setColumnDragLocation({ x: left + width, y: top });
        setColumnDragDestination({ id, after: true });
      } else {
        setColumnDragLocation({ x: left, y: top });
        setColumnDragDestination({ id, after: false });
      }
    },

    []
  );

  const renderedColumns = columnIds.map(columnId => (
    <Column
      boardId={id}
      disabledColumnDndMessages={disabledColumnDndMessages}
      columnHeight={height}
      key={columnId}
      id={columnId}
      draggingId={draggingId}
      dragCount={dragCount}
      newCardIndex={newCard?.columnId === columnId ? newCard.index : null}
      onNewCard={onNewCard}
      editCardIndex={editCard?.columnId === columnId ? editCard.index : null}
      onEditCard={onEditCard}
      dragAndDropDisabled={
        !!newCard || !!editCard || !onMoveCards || !!dragDisabledColumns?.includes(columnId)
      }
      onDragOver={dragEnabled ? onDragOver : undefined}
      {...rest}
    />
  ));

  return (
    <DragDropContext
      onBeforeDragStart={drag => {
        const { selected } = getKeyNavState();
        if (selected && selected.length && !selected.includes(drag.draggableId)) {
          clearSelection();
        }
      }}
      onDragStart={drag => {
        setDraggingId(drag.draggableId);
        const { selected } = getKeyNavState();
        setDragCount(selected?.length ?? 1);
        const disabledMessage = disabledColumnDndMessages?.[drag.source.droppableId ?? ''];
        if (!disabledMessage) {
          return;
        }
        autoSortedMessage(disabledMessage, true);
      }}
      onDragUpdate={initial => {
        const destination = initial.destination;
        const source = initial.source;
        if (!destination) {
          return;
        }
        const disabledMessage = disabledColumnDndMessages?.[initial.destination?.droppableId ?? ''];
        if (disabledMessage) {
          autoSortedMessage(disabledMessage, source.droppableId === destination.droppableId);
        } else {
          hideAutoSortedMessage();
        }
      }}
      onDragEnd={async ({ source, destination }) => {
        setTimeout(hideAutoSortedMessage, 100);
        setDraggingId(null);
        if (!destination) {
          return;
        }
        const cards = getAllColumns();
        const cardId = cards[source.droppableId]?.[source.index];
        if (!cardId) {
          return;
        }
        const { selected } = getKeyNavState();
        const cardIds =
          selected && selected.length && selected.includes(cardId) ? selected : [cardId];
        onMoveCards?.(cardIds, destination.droppableId, destination.index);
      }}
    >
      {/* FIXME: wanted to use ScrollArea here but it struggles when things have no intrinsic height */}
      <div
        onDragOver={e => {
          const clientRect = scrollRef.current?.getClientRects();
          const x = e.clientX;
          if (x - 200 < (clientRect?.[0].left ?? 0)) {
            scrollRef.current?.scrollBy({ left: -10 });
          } else if (x + 200 > (clientRect?.[0].right ?? 0)) {
            scrollRef.current?.scrollBy({ left: 10 });
          }
        }}
        className={cn(styles.board, className)}
        style={style}
        ref={scrollRef}
      >
        {renderedColumns}
        {renderNewColumn && renderNewColumn()}
        <Hotkeys
          onMoveCards={onMoveCards}
          onNewCard={onNewCard}
          getAllColumns={getAllColumns}
          columnsRef={columnsRef}
          onCopy={onCopy}
          onPaste={onPaste}
          commandGroup={commandGroup}
        />
        <div style={{ overflow: 'hidden' }} id={COLUMN_DRAG_PREVIEW_ID} />
        <ColumnDragHandle />
      </div>
    </DragDropContext>
  );
}
