import debugModule from 'debug';
import { History } from 'history';
import { parse, stringify } from 'query-string';
import React from 'react';
import { Client } from 'urql';
import {
  InvitedUserQuery,
  InvitedUserQueryVariables,
  OrganizationNameAvailableQuery,
  OrganizationNameAvailableQueryVariables,
  ValidateResetPasswordTokenQuery,
  ValidateResetPasswordTokenQueryVariables,
} from '../../../graphql__generated__/graphql';
import {
  ExternalAuthProviderType,
  FlowCompleteResult,
  FlowResultStatus,
  FlowStep,
  FlowStepResult,
} from '../../shared/auth';
import domainList from '../../shared/email/domains.json';
import { User } from '../../sync/__generated/models';
import { toast } from '../components/toast';
import { Configuration } from '../contexts/configurationContext';
import { isElectron, openLinkInBrowser } from '../electronIntegration';
import { clearCache } from '../graphql/offlineCache';
import {
  invitedUserQuery,
  organizationNameAvailableQuery,
  validateResetPasswordTokenQuery,
} from '../graphql/queries';
import { trackerClearUser, trackerEvent, trackerSimpleIdentify } from '../tracker';

const debug = debugModule('auth');
export type InvitedUser = InvitedUserQuery['invitedUser'];

export interface AuthResult {
  config: Partial<Configuration>;
  redirect?: string | null;
}

async function authFlowRequest(
  history: History,
  route: string,
  params: Record<string, unknown>,
  onCompleted?: (options: FlowCompleteResult) => void
) {
  try {
    const resp = await fetch(`/auth/${route}`, {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const respJson = await resp.json();

    if (resp.ok) {
      const data = respJson.data as FlowStepResult;
      if (data.status === FlowResultStatus.Complete) {
        onCompleted?.(data);
        return;
      }

      if (data.status === FlowResultStatus.Next) {
        switch (data.step) {
          case FlowStep.PendingInvitation:
            history.push({
              pathname: '/pendinginvite',
              state: data.context,
            });
            break;
          case FlowStep.Terms:
            history.push({
              pathname: '/terms',
              state: data.context,
            });
            break;
          case FlowStep.CreateOrganization:
            history.push({
              pathname: '/create',
              state: data.context,
            });
            break;
        }
      }

      if (data.status === FlowResultStatus.Redirect) {
        window.location.href = data.redirect;
      }

      return;
    }

    if (respJson.errors) {
      debug('Error logging in', respJson.errors[0]?.message);
      throw Error(respJson.errors[0]?.message ?? 'An unknown error occurred');
    }
  } catch (e) {
    debug('Error logging in', e);
    throw new Error(e.message);
  }
  throw Error('An unknown error occurred');
}

export async function login(
  history: History,
  onCompleted: (result: AuthResult) => void,
  email: string,
  password: string,
  token?: string
) {
  await authFlowRequest(
    history,
    'login',
    {
      email,
      password,
      token,
    },
    result => {
      if (result.email && result.userId) {
        trackerSimpleIdentify(result.userId, result.email);
      }
      trackerEvent('Login', { method: 'password' });
      onCompleted(result);
    }
  );
}

export async function signup(
  history: History,
  email: string,
  password: string,
  options: { token?: string }
) {
  await authFlowRequest(history, 'signup', { email, password, ...options });
}

export async function acceptTerms(history: History, onCompleted: (result: AuthResult) => void) {
  await authFlowRequest(history, 'terms', {}, result => {
    if (result.email && result.userId) {
      trackerSimpleIdentify(result.userId, result.email);
      trackerEvent('Signup', { invited: true });
    }
    onCompleted(result);
  });
}

export async function linkExternalAccount(
  history: History,
  onCompleted: (result: AuthResult) => void,
  password: string
) {
  await authFlowRequest(history, 'link', { password }, result => {
    if (result.email && result.userId) {
      trackerSimpleIdentify(result.userId, result.email);
      trackerEvent('Login');
    }
    onCompleted(result);
  });
}

export async function createOrganization(
  history: History,
  onCompleted: (result: AuthResult) => void,
  organization: string
) {
  await authFlowRequest(history, 'create', { organization }, result => {
    trackerEvent('Organization Registered', { name: organization });
    trackerEvent('Signup', { invited: false });
    const { email } = result;
    if (email) {
      const emailDomain = email.split('@')[1];
      if (!domainList.includes(emailDomain)) {
        trackerEvent('Signup - Company domain', { invited: false, email });
      }
    }
    onCompleted(result);
  });
}

export async function logout(): Promise<boolean> {
  try {
    await fetch('/auth/logout', {
      credentials: 'same-origin',
      method: 'POST',
    });
    return true;
  } catch (e) {
    debug('Error fetching exchange token', e);
  }

  return false;
}

export async function resendVerification(user: User): Promise<void> {
  try {
    await fetch('/auth/resendverification', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    toast.info(
      <>
        Verification link sent to <span className="semiBold">{user.primaryEmail}</span>
      </>
    );
    return;
  } catch (e) {
    debug('Error resending verification', e);
  }

  toast.error(
    <>
      Failed to send verification to <span className="semiBold">{user.primaryEmail}</span>
    </>
  );
}

export async function resendPendingInvite(): Promise<void> {
  try {
    const result = await fetch('/auth/resendinvite', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (result.ok) {
      toast.info(<>Invitation re-sent</>);
      return;
    }
  } catch (e) {
    debug('Error resending invite', e);
  }

  toast.error(<>Failed to resend invitation</>);
}

export async function sendForgotPasswordLink(email: string): Promise<void> {
  try {
    const result = await fetch('/auth/forgotpassword', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });

    if (result.ok) {
      return;
    }

    const errorJson = await result.json();
    debug('Error sending forgot password link', errorJson.errors[0].message);
  } catch (e) {
    debug('Error sending forgot password link', e);
  }

  throw new Error('There was a problem handling your request. Please try again.');
}

export async function resetPassword(token: string, password: string): Promise<void> {
  try {
    const result = await fetch('/auth/resetpassword', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        token,
      }),
    });

    if (result.ok) {
      return;
    }

    const errorJson = await result.json();
    debug('Error resetting password', errorJson.errors[0].message);
  } catch (e) {
    debug('Error resetting password', e);
  }

  throw new Error('Something went wrong resetting your password. Please try again');
}

export async function updatePassword(password: string): Promise<boolean> {
  try {
    const result = await fetch('/auth/updatepassword', {
      credentials: 'same-origin',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
      }),
    });

    if (result.ok) {
      return true;
    }

    const errorJson = await result.json();
    debug('Error updating password', errorJson.errors[0].message);
  } catch (e) {
    debug('Error updating password', e);
  }

  return false;
}

export async function fetchPendingLinkdAccount(): Promise<{
  email: string;
  type: ExternalAuthProviderType;
} | null> {
  try {
    const result = await fetch('/auth/link', {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
      },
    });

    if (result.ok) {
      const data = await result.json();
      return data.data;
    }

    const errorJson = await result.json();
    toast.error(errorJson.errors[0].message);
  } catch (e) {
    debug('Error updating password', e);
    toast.error('An unknown error occurred');
  }
  return null;
}

export async function externalAuthFlow(
  flowUrl: string,
  electronScheme: string,
  options?: {
    redirectBaseUrl?: string;
    redirectQueryParams?: Record<string, unknown>;
    exchange?: boolean;
  }
): Promise<void> {
  const redirectBaseUrl = options?.redirectBaseUrl ?? window.location.href;
  const existingQueryParams = parse(window.location.search);
  const redirectQueryParams = {
    ...existingQueryParams,
    ...(options?.redirectQueryParams ?? {}),
  };
  const redirectUrl = `${redirectBaseUrl}?${stringify(redirectQueryParams)}`;

  const hasQueryParams = flowUrl.includes('?');

  if (isElectron()) {
    const exchangeToken = options?.exchange ? await getExchangeToken() : undefined;
    const isHttps = redirectUrl.startsWith('https://');
    const electronUrl = redirectUrl.replace(
      isHttps ? 'https://' : 'http://',
      `${electronScheme}://`
    );
    const redirect = `redirect=${encodeURIComponent(electronUrl)}`;
    const exchange = exchangeToken ? `&exchange=${encodeURIComponent(exchangeToken)}` : '';
    const url = `${flowUrl}${hasQueryParams ? '&' : '?'}${redirect}${exchange}`;
    openLinkInBrowser(url);
  } else {
    const redirect = `redirect=${encodeURIComponent(redirectUrl)}`;
    const url = `${flowUrl}${hasQueryParams ? '&' : '?'}${redirect}`;
    window.location.href = url;
  }
}

async function getExchangeToken(): Promise<string> {
  try {
    const exchangeResult = await fetch('/auth/exchange', {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const exchangeJson = await exchangeResult.json();
    return exchangeJson.data;
  } catch (e) {
    debug('Error fetching exchange token', e);
  }

  // FIXME: probably better to have a real error propagate
  return '';
}

export async function handleLogout() {
  await clearCache(true);
  localStorage.clear();
  const loggedOut = await logout();

  if (loggedOut) {
    setTimeout(async () => {
      // needed or else it'll redirect the user back to whatever page the
      // previous user was on
      window.location.href = '/';
    }, 0);

    trackerClearUser();
    window.Intercom?.('shutdown'); // clear the intercom id
  }
}

export async function findInvitedUser(client: Client, token: string): Promise<null | InvitedUser> {
  const { error, data } = await client
    .query<InvitedUserQuery, InvitedUserQueryVariables>(invitedUserQuery, { token })
    .toPromise();
  if (error || !data) {
    return null;
  }

  return data.invitedUser;
}

export async function organizationNameAvailable(client: Client, name: string): Promise<boolean> {
  const result = await client
    .query<OrganizationNameAvailableQuery, OrganizationNameAvailableQueryVariables>(
      organizationNameAvailableQuery,
      { name }
    )
    .toPromise();
  return !!(result.data && result.data.organizationNameAvailable);
}

export async function validateResetPasswordToken(client: Client, token: string): Promise<boolean> {
  const result = await client
    .query<ValidateResetPasswordTokenQuery, ValidateResetPasswordTokenQueryVariables>(
      validateResetPasswordTokenQuery,
      { token }
    )
    .toPromise();

  return !!(result.data && result.data.passwordResetTokenValid);
}
