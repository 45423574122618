import * as React from 'react';
import { randomString } from '../../shared/utils/random';
import { checkUrl } from '../api/attachments';
import { pendingUploads } from '../utils/fileUploader';

const DEFAULT_MAX_RETRIES = 10;
const RETRY_TIMEOUT = 2000;

function RetryingVideoComponent(
  {
    maxRetries,
    checkForUploadInProgress,
    timeOffset,
    onLoadError,
    src,
    ...rest
  }: {
    maxRetries?: number;
    checkForUploadInProgress?: boolean;
    timeOffset?: number;
    onLoadError?: (error: string) => void;
  } & React.ComponentPropsWithRef<'video'>,
  ref: any
) {
  const retries = React.useRef(0);
  const [url, setUrl] = React.useState(src);
  const numRetries = maxRetries ?? DEFAULT_MAX_RETRIES;

  React.useEffect(() => {
    setUrl(src);
  }, [src]);

  return (
    <video
      ref={ref}
      {...rest}
      src={`${url}${timeOffset ? `#t=${timeOffset}` : ''}`}
      onError={async () => {
        if (!src) {
          return;
        }

        if (retries.current >= numRetries) {
          onLoadError?.('Error loading video');
          return;
        }

        const response = await checkUrl(src);

        // if the server answered and doesn't know about this file, error out
        if (response === false && !pendingUploads.has(src)) {
          onLoadError?.('Video not found');
          return;
        }

        // if the server didn't answer at all, count it as a retry, otherwise don't
        if (response === null) {
          retries.current += 1;
        }
        setTimeout(() => {
          setUrl(`${src}?attempt=${randomString(8)}`);
        }, RETRY_TIMEOUT);
      }}
    />
  );
}

export const RetryingVideo = React.forwardRef(RetryingVideoComponent);
