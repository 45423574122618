import { useHistory, useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { siblingEntitySelector, useEntityPath } from '../syncEngine/selectors/entities';
import { LocationState } from '../utils/history';

export function useNextPreviousSiblings(entityId: string) {
  const location = useLocation<LocationState>();
  const entityPath = useEntityPath();
  const history = useHistory();

  const fixedSiblingIds = location.state?.siblingEntities;
  const siblingEntities = useRecoilValue(
    siblingEntitySelector({
      entityId,
      filterId: location.state?.filterId,
      fixedSiblingIds,
    })
  );

  // if we didn't get a fixed list of siblings passed in, save the default list in the state so
  // we get a nice fixed list even in the default case

  if (!fixedSiblingIds) {
    history.replace({
      pathname: location.pathname,
      search: location.search,
      state: {
        ...(location.state ?? {}),
        siblingEntities,
      },
    });
  }

  const currentEntityIndex = siblingEntities.findIndex(sibling => sibling === entityId);

  function nextEntity() {
    const next = siblingEntities[currentEntityIndex + 1];
    if (next) {
      const ep = entityPath(next);
      if (!ep) {
        return false;
      }
      history.push(ep, { ...location.state, entity: next });
      return true;
    } else {
      return false;
    }
  }

  function previousEntity() {
    const previous = siblingEntities[currentEntityIndex - 1];
    if (previous) {
      const ep = entityPath(previous);
      if (!ep) {
        return false;
      }
      history.push(ep, { ...location.state, entity: previous });
      return true;
    } else {
      return false;
    }
  }

  return {
    previousEntity,
    nextEntity,
    totalCount: siblingEntities.length,
    position: currentEntityIndex,
  };
}
