import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/new/button';
import { Setting, SettingsPage, SettingsSection } from '../../../components/new/settings';
import { Tooltip } from '../../../components/new/tooltip';
import { useOrganization } from '../../../contexts/organizationContext';
import { useHasAdminRights } from '../../../contexts/userContext';
import { organizationPath } from '../../../syncEngine/selectors/organizations';

export function ImportSettingsScreen() {
  const organization = useOrganization();
  const history = useHistory();
  const hasAdminRights = useHasAdminRights();

  return (
    <SettingsPage
      title="Import"
      description="You can choose to import data into Kitemaker from other products like Trello or GitHub"
    >
      <SettingsSection>
        <Setting
          title="Import issues from a GitHub repository into Kitemaker"
          description="Each issue from GitHub will become a work item in Kitemaker. The import will preserve the
          assignees and labels from the GitHub issues."
        >
          <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
            <Button
              disabled={!hasAdminRights}
              onClick={() => {
                history.push(organizationPath(organization, 'settings/import/github'));
              }}
            >
              Import from Github
            </Button>
          </Tooltip>
        </Setting>
      </SettingsSection>

      <SettingsSection>
        <Setting
          title="Import a Trello board into Kitemaker"
          description="You can create a space in Kitemaker based on a board in Trello. The import will set up the
          space with the same columns as the Trello board and each card will become a work item. The
          import will preserve the members and labels from the Trello cards."
        >
          <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
            <Button
              disabled={!hasAdminRights}
              onClick={() => {
                history.push(organizationPath(organization, 'settings/import/trello'));
              }}
            >
              Import from Trello
            </Button>
          </Tooltip>
        </Setting>
      </SettingsSection>
    </SettingsPage>
  );
}
