import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import { CommandGroup } from '../commands';
import { useOrganization } from '../contexts/organizationContext';
import { spacePath, spacesForOrganizationSelector } from '../syncEngine/selectors/spaces';
import { LocationState } from '../utils/history';
import { CustomCommand } from './new/customCommand';

export function useBackBehavior() {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const organization = useOrganization();
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));

  const { backUrl, backSearch, backBreadcrumbs } = location.state || {};
  const firstSpace = spaces[0];
  return () => {
    if (!backUrl && !firstSpace) {
      return;
    }
    const path = backUrl ? backUrl : spacePath(organization, firstSpace);
    history.push({
      pathname: path,
      search: backSearch,
      state: { breadcrumbs: backBreadcrumbs },
    });
  };
}

export function OrganizationBackCommand({ name }: { name: string }) {
  const goBack = useBackBehavior();
  return (
    <CustomCommand
      command={{
        id: `back-${name}`,
        group: CommandGroup.Navigation,
        hotkey: 'escape',
        description: 'Go back',
        priority: 10,
        handler: goBack,
      }}
    />
  );
}
