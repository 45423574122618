import { partition, sortBy, uniq, uniqBy } from 'lodash';
import { atom, selectorFamily, useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { between } from '../../../shared/utils/sorting';
import { boardColumnsByBoard, boardsBySpace } from '../../../sync/__generated/indexes';
import { Board, BoardColumn, IssueStatus, IssueStatusType } from '../../../sync/__generated/models';
import { issueStatusBoundaries } from '../actions/boards';
import { localStorageEffect } from '../effects';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { statusSelector } from './issues';
import { statusesForSpaceSelector } from './issueStatuses';

export const statusForColumnSelector = selectorFamily({
  key: 'StatusForColumn',
  get:
    (columnId: string) =>
    ({ get }) => {
      const column = get(syncEngineState(columnId)) as BoardColumn;
      return get(syncEngineState(column.statusId)) as IssueStatus;
    },
});

export const columnForStatusSelector = selectorFamily({
  key: 'ColumnForStatus',
  get:
    ({ statusId, boardId }: { statusId: string; boardId: string }) =>
    ({ get }) => {
      const columns = get(boardColumnsForBoardSelector(boardId));
      return columns.find(column => column.statusId === statusId) ?? null;
    },
});

export const boardColumnsForBoardSelector = selectorFamily({
  key: 'BoardColumnsForBoard',
  get:
    (boardId: string | undefined) =>
    ({ get }) => {
      const boardColumnIds = get(indexKeyState(indexKey(boardColumnsByBoard, boardId ?? '')));
      return filterNotDeletedNotNull(
        boardColumnIds.map(boardId => get(syncEngineState(boardId)) as BoardColumn | null)
      );
    },
});

export const boardColumnSelector = selectorFamily({
  key: 'BoardColumn',
  get:
    (boardColumnId: string) =>
    ({ get }) => {
      return get(syncEngineState(boardColumnId)) as BoardColumn | null;
    },
});

export const boardColumnsWithStatusTypeSelector = selectorFamily({
  key: 'BoardColumnsWithStatusType',
  get:
    ({ boardId, statusType }: { boardId: string; statusType: IssueStatusType }) =>
    ({ get }) => {
      const boardColumns = get(boardColumnsForBoardSelector(boardId));
      return boardColumns
        .map(column => ({ column, status: get(statusSelector(column.statusId)) }))
        .filter(i => i.status && i.status.statusType === statusType && !i.status.deleted)
        .map((i, index) => ({ ...i.column, index }));
    },
});

export function useGetNewColumnSort() {
  return useRecoilCallback(
    ({ snapshot }) =>
      (boardId: string, statusType: IssueStatusType) => {
        const firstColumn = snapshot
          .getLoadable(boardColumnsForBoardSelector(boardId))
          .getValue()
          .filter(
            column =>
              snapshot.getLoadable(statusForColumnSelector(column.id)).getValue()?.statusType ===
              statusType
          )[0];

        const boundaries = issueStatusBoundaries[statusType];
        return between({
          after: boundaries.after,
          before: firstColumn?.sort ?? boundaries.before,
        });
      },
    []
  );
}

export const boardForStatusSelector = selectorFamily({
  key: 'BoardForStatus',
  get:
    ({
      statusId,
      spaceId,
    }: {
      statusId: string | null | undefined;
      spaceId: string | null | undefined;
    }) =>
    ({ get }) => {
      if (!statusId || !spaceId) {
        return null;
      }

      const status = get(statusSelector(statusId));
      const boards = get(boardsForSpaceSelector(spaceId));
      const backlogBoard = boards.find(b => b.defaultStatusType === IssueStatusType.Backlog);

      if (status?.statusType === IssueStatusType.Archived) {
        return null;
      }

      if (status?.statusType === IssueStatusType.Backlog && backlogBoard) {
        return backlogBoard;
      }

      return boards.find(b => b.key === 'current');
    },
});

// FIXME-SYNC this feels slow
export const statusesInBoardOrderSelector = selectorFamily({
  key: 'StatusesInBoardOrder',
  get:
    ({ spaceId, boardId }: { spaceId: string; boardId?: string }) =>
    ({ get }) => {
      const statuses = get(statusesForSpaceSelector(spaceId));

      if (boardId) {
        const columns = get(boardColumnsForBoardSelector(boardId));
        return filterNotNull(
          columns.map(column => statuses.find(status => status.id === column.statusId) ?? null)
        );
      }

      const boards = get(boardsForSpaceSelector(spaceId));
      const columns: BoardColumn[] = [];

      for (const board of boards) {
        const columnsForBoard = get(boardColumnsForBoardSelector(board.id));
        for (const column of columnsForBoard) {
          columns.push(column);
        }
      }

      return [
        ...uniqBy(
          filterNotNull(
            columns.map(column => statuses.find(status => status.id === column.statusId) ?? null)
          ),
          status => status.id
        ),
        statuses.find(status => status.statusType === IssueStatusType.Archived)!,
      ];
    },
});

export const listViewSettings = atom<{ [boardId: string]: boolean }>({
  key: 'ListViewSettings',
  default: {},
  effects: [localStorageEffect('__listViewSettings')],
});

export const statusesForSpaceInBoardOrderSelector = selectorFamily({
  key: 'StatusesBySpaceInBoardOrder',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }
      const issueStatuses = get(statusesForSpaceSelector(spaceId));
      const [archived, unarchived] = partition(
        issueStatuses,
        s => s.statusType === IssueStatusType.Archived
      );
      const boards = get(boardsForSpaceSelector(spaceId));
      const columns = uniq(
        sortBy(boards, b => b.sort)
          .flatMap(b => {
            const boardColumns = get(boardColumnsForBoardSelector(b.id));
            return sortBy(boardColumns, c => c.sort).map(c => c.statusId);
          })
          .reverse()
      ).reverse();

      const result = columns
        .map(c => unarchived.find(s => s.id === c))
        .filter(s => !!s) as IssueStatus[];
      return [...result, ...archived];
    },
});

export const boardSelector = selectorFamily({
  key: 'Board',
  get:
    (boardId: string | null | undefined) =>
    ({ get }) => {
      if (!boardId) {
        return null;
      }
      return get(syncEngineState(boardId)) as Board | null;
    },
});
export const boardsForSpaceSelector = selectorFamily({
  key: 'BoardsForSpace',
  get:
    (spaceId: string | null | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }

      const boardIds = get(indexKeyState(indexKey(boardsBySpace, spaceId)));
      return filterNotDeletedNotNull(
        boardIds.map(boardId => get(syncEngineState(boardId)) as Board | null)
      );
    },
});

export const allBoardsForSpaceSelector = selectorFamily({
  key: 'AllBoardsForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const boardIds = get(indexKeyState(indexKey(boardsBySpace, spaceId)));
      return filterNotNull(boardIds.map(boardId => get(syncEngineState(boardId)) as Board | null));
    },
});

export const boardsAreInOrderForSpaceSelector = selectorFamily({
  key: 'AllBoardsForSpace',
  get: (spaceId: string) => getters => {
    const { get } = getters;
    const boardIds = get(indexKeyState(indexKey(boardsBySpace, spaceId)));

    for (const boardId of boardIds) {
      const columnIds = get(indexKeyState(indexKey(boardColumnsByBoard, boardId)));
      const columns = filterNotDeletedNotNull(
        columnIds.map(id => get(syncEngineState(id)) as BoardColumn | null)
      );

      const columnsAndStatuses = columns
        .map(column => ({
          column,
          status: get(syncEngineState(column.statusId)) as IssueStatus | null,
        }))
        .filter(({ status }) => !!status) as {
        column: BoardColumn;
        status: IssueStatus;
      }[];

      const statuseTypes = Object.values(IssueStatusType);
      let lastStatusIndex = 0;
      for (const { status } of sortBy(columnsAndStatuses, 'column.sort')) {
        const index = statuseTypes.indexOf(status.statusType);
        if (index < lastStatusIndex) {
          return false;
        }

        lastStatusIndex = index;
      }
    }

    return true;
  },
});
