import React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import { Cycle } from '../../../sync/__generated/models';
import { writeToClipboard } from '../../components/clipboardText';
import { ButtonStyle, IconButtonTrigger } from '../../components/new/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from '../../components/new/menu/dropdownMenu';
import { useConfiguration } from '../../contexts/configurationContext';
import { CommandMenuView, Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useToggleStarred } from '../../syncEngine/actions/starred';
import {
  cyclePath,
  cycleStarredIdSelector,
  cycleSelector,
} from '../../syncEngine/selectors/cycles';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { isStarredSelector } from '../../syncEngine/selectors/starred';
import { addExistingKey } from '../../utils/config';
import { StarredType } from '../../utils/starred';

export function CycleMenuContents({
  cycle,
  onEditTitle,
  onEditSummary,
  onEditDates,
  ignoreCurrentAndUpcomingWhenStarring,
}: {
  cycle: Cycle;
  onEditTitle: React.MouseEventHandler<HTMLDivElement>;
  onEditSummary: React.MouseEventHandler<HTMLDivElement>;
  onEditDates: React.MouseEventHandler<HTMLDivElement>;
  ignoreCurrentAndUpcomingWhenStarring?: boolean;
}) {
  const space = useRecoilValue(spaceSelector(cycle.spaceId));
  const organization = useOrganization();
  const { host } = useConfiguration();
  const modals = useModals();
  const starredId = useRecoilValue(
    cycleStarredIdSelector({
      cycleId: cycle.id,
      ignoreCurrentAndUpcoming: ignoreCurrentAndUpcomingWhenStarring,
    })
  );

  const isStarred = useRecoilValue(
    isStarredSelector({
      organizationId: organization.id,
      type: StarredType.Cycle,
      id: starredId,
    })
  );
  const toggleStarred = useToggleStarred();

  return (
    <>
      <DropdownMenuItem onClick={onEditTitle} icon="edit">
        Edit title
      </DropdownMenuItem>
      <DropdownMenuItem onClick={onEditSummary} icon="desc">
        Edit description
      </DropdownMenuItem>
      <DropdownMenuItem onClick={onEditDates} icon="date">
        Change cycle dates
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.ChangeCycleIssues,
            context: {
              cycleId: cycle.id,
            },
          });
        }}
        icon="add"
        shortcut={addExistingKey}
      >
        Add {issueTerm}s
      </DropdownMenuItem>
      <DropdownMenuItem
        icon="starred"
        onClick={() => {
          toggleStarred({ type: StarredType.Cycle, id: starredId });
        }}
      >
        {isStarred ? 'Unstar' : 'Star'}
      </DropdownMenuItem>
      {space && cycle && (
        <DropdownMenuItem
          onClick={() => {
            writeToClipboard(`${host}${cyclePath(organization, space, cycle)}`, 'cycle link');
          }}
          icon="copy"
        >
          Copy link
        </DropdownMenuItem>
      )}
    </>
  );
}

export function CycleMenuButton({
  className,
  cycleId,
  onEditTitle,
  onEditSummary,
  onEditDates,
}: {
  className?: string;
  cycleId: string;
  onEditTitle: () => void;
  onEditSummary: () => void;
  onEditDates: () => void;
}) {
  const cycle = useRecoilValue(cycleSelector(cycleId));

  if (!cycle) {
    return null;
  }

  return (
    <DropdownMenu>
      <IconButtonTrigger className={className} buttonStyle={ButtonStyle.BareSubtle} icon="more" />
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="end"
      >
        <CycleMenuContents
          cycle={cycle}
          onEditTitle={onEditTitle}
          onEditSummary={onEditSummary}
          onEditDates={onEditDates}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
