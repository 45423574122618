import React from 'react';
import { useRecoilValue } from 'recoil';
import { Issue } from '../../../../sync/__generated/models';
import { issuesToReleasePickerState, ReleasePicker } from '../../../components/new/pickers/releasePicker';
import { useOrganization } from '../../../contexts/organizationContext';
import {
  useAddIssuesToRelease,
  useRemoveIssuesFromRelease,
} from '../../../syncEngine/actions/releases';
import { entitiesSelector } from '../../../syncEngine/selectors/entities';
import { releasesForOrganizationSelector } from '../../../syncEngine/selectors/releases';
import styles from '../commandMenu.module.scss';

export function ChangeReleasesContents({
  onClose,
  entityIds,
}: {
  onClose: () => void;
  entityIds: string[];
}) {
  const organization = useOrganization();
  const entities = useRecoilValue(entitiesSelector(entityIds)).filter(
    e => e.__typename === 'Issue'
  ) as Issue[];
  const releases = useRecoilValue(releasesForOrganizationSelector(organization.id));

  const addIssuesToRelease = useAddIssuesToRelease();
  const removeIssuesFromRelease = useRemoveIssuesFromRelease();

  return (
    <div className="rowStretch fullWidth fullHeight">
      <ReleasePicker
        filterClassName={styles.inputDiv}
        state={issuesToReleasePickerState(entities, releases)}
        onReleaseAdded={(issueIds, initiativeId) => {
          addIssuesToRelease([initiativeId], issueIds);
        }}
        onReleaseRemoved={(issueIds, initiativeId) => {
          removeIssuesFromRelease([initiativeId], issueIds);
        }}
        onDone={onClose}
      />
    </div>
  );
}
