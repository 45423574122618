import * as React from 'react';
import { ExternalIssueCreationContext } from '../../shared/externalIssues';
import {
  documentFromString,
  emptyDocument,
  isDocumentEmpty,
  stringifyDocument,
} from '../../shared/slate/utils';
import { IntegrationType } from '../../sync/__generated/models';
import { createExternalIssue, fetchCreationContext } from '../api/externalIssues';
import Modal, { ModalButtonWrapper, ModalContentWrapper } from '../components/modal';
import { Button, ButtonSize, ButtonStyle } from '../components/new/button';
import { Hotkey } from '../components/new/hotkey';
import { IconSize } from '../components/new/icon';
import { IntegrationIcon } from '../components/new/integrationIcon';
import { KeyboardShortcut } from '../components/new/keyboardShortcut';
import { LoadingSpinner } from '../components/new/loadingSpinner';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../components/new/menu/dropdownMenu';
import { Tooltip } from '../components/new/tooltip';
import { Modals, NewExternalIssueArgs, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useComponentDidMount } from '../hooks/useComponentDidMount';
import { useSerializeToMarkdown } from '../slate/hooks/useSerializeToMarkdown';
import { TextArea, TextAreaHandle } from '../slate/textArea';
import { DocumentLike } from '../slate/types';
import { mainComboKey } from '../utils/config';
import { stringifyIntegrationType } from '../utils/integrations';
import { metaKeyDown, modifierKeyDown } from '../utils/keyEvents';
import styles from './newExternalIssueModal.module.scss';

export function Description({
  description,
  onDescriptionChanged,
  descriptionRef,
  disabled,
}: {
  description: DocumentLike;
  onDescriptionChanged: (description: DocumentLike) => void;
  descriptionRef: React.RefObject<TextAreaHandle>;
  disabled?: boolean;
}) {
  return (
    <div className={styles.description}>
      <Hotkey
        hotkey="d"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          descriptionRef.current?.focus();
          descriptionRef.current?.moveSelectionToEnd();
        }}
      />
      <TextArea
        ref={descriptionRef}
        className={styles.descriptionEditor}
        initialValue={description}
        onChange={v => {
          onDescriptionChanged(v);
        }}
        richText
        disableModals
        disabled={disabled}
        placeholder="Enter a description"
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            descriptionRef?.current?.blur();
          }
        }}
      />
    </div>
  );
}

function IntegrationDropDown({
  integrationTerm,
  integrations,
  integrationId,
  onIntegrationIdChanged,
}: {
  integrationTerm: string;
  integrations: ExternalIssueCreationContext['integrations'];
  integrationId: string | null;
  onIntegrationIdChanged: (id: string) => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Secondary}>
          {integrations.find(i => i.id === integrationId)?.name ?? `Select ${integrationTerm}`}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        {integrations.map(integration => (
          <DropdownMenuItem
            key={integration.id}
            onSelect={() => onIntegrationIdChanged(integration.id)}
          >
            {integration.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function ProjectDropDown({
  projectTerm,
  projects,
  projectId,
  onProjectIdChanged,
}: {
  projectTerm: string;
  projects: ExternalIssueCreationContext['integrations'][0]['projects'];
  projectId: string | null;
  onProjectIdChanged: (id: string) => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Secondary}>
          {projects.find(p => p.id === projectId)?.name ?? `Select ${projectTerm}`}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        {projects.map(project => (
          <DropdownMenuItem key={project.id} onSelect={() => onProjectIdChanged(project.id)}>
            {project.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function StatusDropDown({
  statusTerm,
  statuses,
  statusId,
  onStatusIdChanged,
}: {
  statusTerm?: string;
  statuses?: ExternalIssueCreationContext['integrations'][0]['projects'][0]['statuses'];
  statusId: string | null;
  onStatusIdChanged: (id: string) => void;
}) {
  if (!statuses) {
    return null;
  }

  const current = statuses.find(s => s.id === statusId);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Secondary}>
          {current && (
            <div className="row">
              {current.icon && (
                <span
                  className={styles.statusIcon}
                  style={{
                    stroke: current.color,
                  }}
                  dangerouslySetInnerHTML={{ __html: current.icon }}
                ></span>
              )}
              {current.name}
            </div>
          )}
          {!current && <div>Select ${statusTerm}</div>}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        {statuses.map(status => (
          <DropdownMenuItem key={status.id} onSelect={() => onStatusIdChanged(status.id)}>
            <div className="row fullWidth">
              {status.icon && (
                <span
                  className={styles.statusIcon}
                  style={{
                    stroke: status.color,
                  }}
                  dangerouslySetInnerHTML={{ __html: status.icon }}
                ></span>
              )}
              {status.name}
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function AssigneeDropDown({
  userTerm,
  users,
  assigneeId,
  onAssigneeIdChanged,
}: {
  userTerm: string;
  users: ExternalIssueCreationContext['integrations'][0]['users'];
  assigneeId: string | null;
  onAssigneeIdChanged: (id: string | null) => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        asChild
      >
        <Button size={ButtonSize.Small} buttonStyle={ButtonStyle.Secondary}>
          {users.find(u => u.id === assigneeId)?.displayName ?? `Select ${userTerm}`}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuSmall"
      >
        <DropdownMenuItem onSelect={() => onAssigneeIdChanged(null)}>
          <span className="grayed">Unassigned</span>
        </DropdownMenuItem>
        {users.map(user => (
          <DropdownMenuItem key={user.id} onSelect={() => onAssigneeIdChanged(user.id)}>
            {user.displayName}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function NewEntityModalContents({
  onCreated,
  onClose,
  initialTitle,
  initialDescription,
  type,
}: {
  onCreated?: (id: string | null) => void;
  onClose: () => void;
  initialTitle: string;
  initialDescription?: DocumentLike;
  type?: IntegrationType;
}) {
  const organization = useOrganization();
  const toMarkdown = useSerializeToMarkdown();

  const [context, setContext] = React.useState<ExternalIssueCreationContext | null>(null);
  const [integrationId, setIntegrationId] = React.useState<string | null>(null);
  const [projectId, setProjectId] = React.useState<string | null>(null);
  const [statusId, setStatusId] = React.useState<string | null>(null);
  const [assigneeId, setAssigneeId] = React.useState<string | null>(null);

  const [title, setTitle] = React.useState(initialTitle);
  const [description, setDescription] = React.useState(initialDescription ?? emptyDocument());

  const [requestInProgress, setRequestInProgress] = React.useState(false);
  const titleRef = React.useRef<TextAreaHandle>(null);
  const descriptionRef = React.useRef<TextAreaHandle>(null);

  React.useEffect(() => {
    setIntegrationId(context?.integrations[0]?.id ?? null);
  }, [context]);

  React.useEffect(() => {
    const integration = context?.integrations.find(i => i.id === integrationId);
    setProjectId(integration?.projects[0]?.id ?? null);
    setAssigneeId(null);
  }, [context, integrationId]);

  React.useEffect(() => {
    const integration = context?.integrations.find(i => i.id === integrationId);
    const project = integration?.projects.find(p => p.id === projectId);
    setStatusId(project?.statuses?.[0]?.id ?? null);
  }, [context, integrationId, projectId]);

  useComponentDidMount(() => {
    titleRef?.current?.moveSelectionToEnd();
    titleRef?.current?.focus();

    (async () => {
      const context = await fetchCreationContext(organization.id, type!);
      setContext(context);
    })();
  });

  if (!context?.integrations.length) {
    return (
      <div className={styles.newExternalIssueModal}>
        <ModalContentWrapper>
          <div className="fullWidth colCenter">
            {!context && <LoadingSpinner />}
            {context && (
              <>
                <div className="headingS mb16">No supported integrations found</div>

                <Button buttonStyle={ButtonStyle.Primary} onClick={onClose}>
                  Close
                </Button>
              </>
            )}
          </div>
        </ModalContentWrapper>
      </div>
    );
  }

  async function submit() {
    if (!integrationId || !projectId || !title) {
      return;
    }
    setRequestInProgress(true);
    try {
      const id = await createExternalIssue(organization.id, type!, {
        integrationId,
        projectId,
        statusId,
        assigneeId,
        title,
        description: toMarkdown(description),
      });
      onCreated?.(id);
      onClose();
    } finally {
      setRequestInProgress(false);
    }
  }

  return (
    <div className={styles.newExternalIssueModal}>
      <ModalContentWrapper>
        <div className="rowBetween fullWidth">
          <div className="row headerButtonGap">
            <IntegrationIcon integrationType={type!} size={IconSize.Size24} />
            {context.integrations.length > 1 && (
              <IntegrationDropDown
                integrationTerm={context.integrationTerm}
                integrations={context.integrations}
                integrationId={integrationId}
                onIntegrationIdChanged={setIntegrationId}
              />
            )}
            <ProjectDropDown
              projectTerm={context.projectTerm}
              projects={context.integrations.find(i => i.id === integrationId)?.projects ?? []}
              projectId={projectId}
              onProjectIdChanged={setProjectId}
            />
            <StatusDropDown
              statusTerm={context.statusTerm}
              statuses={
                context.integrations
                  .find(i => i.id === integrationId)
                  ?.projects.find(p => p.id === projectId)?.statuses
              }
              statusId={statusId}
              onStatusIdChanged={setStatusId}
            />
          </div>
          <div className="row headerButtonGap">
            <AssigneeDropDown
              userTerm={context.assigneeTerm}
              users={context.integrations.find(i => i.id === integrationId)?.users ?? []}
              assigneeId={assigneeId}
              onAssigneeIdChanged={setAssigneeId}
            />
          </div>
        </div>
        <div className="rowAlignStart relative fullWidth mt12 mb8">
          <TextArea
            autoFocus
            disableModals
            disabled={requestInProgress}
            ref={titleRef}
            placeholder="Issue title"
            className={styles.title}
            initialValue={documentFromString(title)}
            onChange={v => {
              setTitle(stringifyDocument(v));
            }}
            onKeyDown={e => {
              if (e.key.toLowerCase() === 'enter' && !metaKeyDown(e) && !modifierKeyDown(e)) {
                e.preventDefault();
                descriptionRef.current?.focus();
                descriptionRef.current?.moveSelectionToEnd();
                return;
              }

              if (e.key.toLowerCase() === 'escape') {
                e.preventDefault();
                e.stopPropagation();
                titleRef.current?.blur();
                if (title === '' && isDocumentEmpty(description)) {
                  onClose();
                }
              }
            }}
          />
        </div>
        <div className={styles.description}>
          <Description
            descriptionRef={descriptionRef}
            description={description}
            onDescriptionChanged={setDescription}
            disabled={requestInProgress}
          />
        </div>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <div className={styles.buttonRow}>
          <Tooltip
            content={
              <>
                <KeyboardShortcut shortcut={'esc'} /> to cancel
              </>
            }
          >
            <Button onClick={onClose} buttonStyle={ButtonStyle.Secondary}>
              Cancel
            </Button>
          </Tooltip>
          <Tooltip
            content={
              <>
                <KeyboardShortcut shortcut={`${mainComboKey}+enter`} /> to create
              </>
            }
          >
            <Button
              onClick={submit}
              buttonStyle={ButtonStyle.Primary}
              disabled={!integrationId || !projectId || requestInProgress}
            >
              Create {stringifyIntegrationType(type!)} issue
            </Button>
          </Tooltip>
        </div>
      </ModalButtonWrapper>
      <Hotkey
        hotkey={`${mainComboKey}+enter`}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          submit();
        }}
      />
    </div>
  );
}

export function NewExternalIssueModal() {
  const modalManager = useModals();
  const args = modalManager.currentArgs()
    ? (modalManager.currentArgs() as NewExternalIssueArgs)
    : null;

  return (
    <Modal
      modalId={Modals.NewExternalIssue}
      hideHeader
      onClose={() => {
        args?.onCreated?.(null);
      }}
    >
      <NewEntityModalContents
        onCreated={args?.onCreated}
        onClose={() => modalManager.closeModal(Modals.NewExternalIssue)}
        initialTitle={args?.title?.trim() ?? ''}
        initialDescription={args?.description}
        type={args?.type}
      />
    </Modal>
  );
}
