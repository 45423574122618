import cn from 'classnames';
import { keys, pickBy, values } from 'lodash';
import React from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { issueTerm } from '../../../../shared/utils/terms';
import { capitalize } from '../../../../shared/utils/utils';
import { IssueStatusSortMode, IssueStatusType } from '../../../../sync/__generated/models';
import { CommandGroup, StatusCommandGroupContext } from '../../../commands/state';
import { MetadataConfig, boardMetadataConfig } from '../../../components/metadataConfig';
import { autoSortedMessage, hideAutoSortedMessage } from '../../../components/new/autoSortedToast';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { CommandContext, EntityCommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { Count } from '../../../components/new/count';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import {
  KeyNavigationWatcher,
  useColumnHasKeyNavigationFocus,
  useDisableMissingKeyNavigationElementDetection,
  useEnableMissingKeyNavigationElementDetection,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useKeyNavigationElement,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import Placeholder from '../../../components/new/placeholder';
import { StatusIcon } from '../../../components/new/statusIcon';
import { Tooltip } from '../../../components/new/tooltip';
import { VirtualizedBoardView } from '../../../components/new/virtualizedBoardView';
import { BoardColumnHeader } from '../../../components/new/virtualizedBoardView/columnHeader';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { WorkItemCard } from '../../../components/new/workItemCard';
import { EditWorkItemListItem, WorkItemListItem } from '../../../components/new/workItemListItem';
import { CommandMenuView, Modals, NewEntityArgs, useModals } from '../../../contexts/modalContext';
import { SpaceProvider, useSpace } from '../../../contexts/spaceContext';
import { useNewEntityModalArgs } from '../../../modals/newEntityModal/newEntityModal';
import { useDuplicateIssues, useUpdateIssueSorts } from '../../../syncEngine/actions/issues';
import {
  spaceIdForEntitySelector,
  useEntityNumberWidths,
} from '../../../syncEngine/selectors/entities';
import {
  issueIdsByStatusForInitiativeSelector,
  issueIdsForStatusAndInitiativeSelector,
  spacesIdsForInitiativeSelector,
} from '../../../syncEngine/selectors/intiatives';
import {
  filteredIssuesForStatusSelector,
  issuesByStatusSelector,
  sortModeToString,
  statusSelector,
  statusesSelector,
  useSortIssuesInBoardOrder,
} from '../../../syncEngine/selectors/issues';
import {
  archivedStatusForSpaceSelector,
  isDefaultStatusSelector,
} from '../../../syncEngine/selectors/issueStatuses';
import { createNewIssueKey } from '../../../utils/config';
import { filterState } from '../../../utils/filtering';
import { filterChainState, FilterType as FilterTypeNew } from '../../../utils/filtering2';
import {
  WorkItemBoardView,
  WorkItemContext,
  WorkItemListView,
} from '../workItemBoardScreen/workItemBoardScreen';
import { Mode, initiativeScreenShowEmpty, initiativeScreenSpaceMode } from './initiativeScreen';
import styles from './initiativeWorkItemList.module.scss';

export function SectionHeader({
  statusId,
  collapsed,
  onToggleCollapsed,
  count,
}: {
  statusId: string;
  collapsed: boolean;
  onToggleCollapsed: () => void;
  count?: number;
}) {
  const id = `header-${statusId}`;
  const ref = React.useRef<HTMLDivElement>(null);
  const columnHasFocus = useColumnHasKeyNavigationFocus(statusId);
  const focused = useHasKeyNavigationFocus(id);
  const status = useRecoilValue(statusSelector(statusId));
  const modals = useModals();

  useKeyNavigationElement(id, ref);

  if (!status) {
    return null;
  }

  return (
    <div className="listHeaderContainer">
      <div className="listHeader" onClick={onToggleCollapsed} ref={ref}>
        <div className="row grow ellipsis">
          <Icon
            icon={collapsed ? 'arrow_forward' : 'arrow_down'}
            className="mr8 clickable grayIcon"
          />
          {status && <StatusIcon type={status.statusType} className="mr8" />}
          <div className="ellipsis mr8">{status?.name}</div>
          {count !== undefined && <Count count={count} />}
        </div>
        <div className="row">
          <Tooltip
            content={
              <>
                {`Create ${issueTerm}`} <KeyboardShortcut shortcut={createNewIssueKey} />
              </>
            }
          >
            <IconButton
              icon="add"
              buttonStyle={ButtonStyle.BareSubtle}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                const args: NewEntityArgs = {
                  spaceId: status.spaceId,
                  statusId,
                };

                modals.openModal(Modals.NewEntity, args);
              }}
            />
          </Tooltip>
        </div>
        {status && columnHasFocus && (
          <CommandContext<StatusCommandGroupContext>
            context={{ group: CommandGroup.Status, statusId: status.id }}
          />
        )}
        {focused && (
          <>
            <Hotkey
              hotkey="space"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
            <Hotkey
              hotkey="enter"
              handler={e => {
                e?.preventDefault();
                e?.stopPropagation();
                onToggleCollapsed();
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

function ListItem({
  id,
  initiativeId,
  className,
  style,
  metadataConfig,
  onEdit,
  onEditComplete,
}: {
  id: string;
  initiativeId: string;
  className?: string;
  style?: React.CSSProperties;
  metadataConfig?: MetadataConfig;
  onEdit?: () => void;
  onEditComplete?: () => void;
}) {
  const spaceId = useRecoilValue(spaceIdForEntitySelector(id));

  return (
    <SpaceProvider spaceId={spaceId ?? ''}>
      {onEditComplete ? (
        <EditWorkItemListItem
          id={id}
          onDone={onEditComplete}
          className={className}
          style={style}
          metadataConfig={metadataConfig}
        />
      ) : (
        <WorkItemListItem
          id={id}
          style={style}
          className={className}
          onChangeTitle={onEdit}
          metadataConfig={metadataConfig}
          initiativeId={initiativeId}
        />
      )}
    </SpaceProvider>
  );
}

function RowHeader({
  statusId,
  collapsed,
  onToggleCollapsed,
  count,
}: {
  statusId: string;
  collapsed: boolean;
  onToggleCollapsed: () => void;
  count: number;
}) {
  return (
    <SectionHeader
      statusId={statusId}
      collapsed={collapsed}
      onToggleCollapsed={onToggleCollapsed}
      count={count}
    />
  );
}

function ColumnHeader({ statusId, count }: { statusId: string; count: number }) {
  const status = useRecoilValue(statusSelector(statusId));
  const isDefault = useRecoilValue(isDefaultStatusSelector(statusId));
  const modals = useModals();

  return (
    <BoardColumnHeader id={statusId}>
      <div className="row grow ellipsis">
        {status && <StatusIcon type={status.statusType} isDefault={isDefault} className="mr8" />}
        <div className="ellipsis mr8">{status?.name}</div>
        <Count count={count} />
      </div>
      <div className="row">
        <Tooltip
          content={
            <>
              {`Create ${issueTerm}`} <KeyboardShortcut shortcut={createNewIssueKey} />
            </>
          }
        >
          <IconButton
            icon="add"
            buttonStyle={ButtonStyle.BareSubtle}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              if (!status) {
                return;
              }

              const args: NewEntityArgs = {
                spaceId: status.spaceId,
                statusId,
              };

              modals.openModal(Modals.NewEntity, args);
            }}
          />
        </Tooltip>
      </div>
    </BoardColumnHeader>
  );
}

function InitiativeWorkItemList({
  initiativeId,
  numberColumnWidth,
  className,
}: {
  initiativeId: string;
  numberColumnWidth?: number;
  className?: string;
}) {
  const issueIdsByStatus = useRecoilValue(issueIdsByStatusForInitiativeSelector({ initiativeId }));
  const statuses = React.useMemo(() => keys(issueIdsByStatus), [issueIdsByStatus]);
  const modals = useModals();

  const keyNavIds = Object.entries(issueIdsByStatus).reduce((acc, [key, values]) => {
    const keyNavValues = values.length > 0 ? values : [`placeholder-${key}`];
    return acc.concat([`header-${key}`, ...keyNavValues]);
  }, [] as string[]);
  useKeyNavigationColumn(`workItems-${initiativeId}`, keyNavIds);

  const mode = useRecoilValue(initiativeScreenSpaceMode(`${initiativeId}-all`));

  const metadataConfig = useRecoilValue(boardMetadataConfig(getFilterId('all', initiativeId)));

  const findArchivedStatus = useRecoilCallback(
    ({ snapshot }) =>
      (statusIds: string[]) => {
        const statuses = statusIds.map(s => snapshot.getLoadable(statusSelector(s)).getValue());
        return statuses.find(s => s?.statusType === IssueStatusType.Archived);
      },
    []
  );

  const defaultCollapsed = React.useMemo(() => {
    const emptyStatuses = Object.keys(issueIdsByStatus).reduce(
      (acc, curr) => (issueIdsByStatus[curr].length > 0 ? acc : [curr, ...acc]),
      [] as string[]
    );

    const archived = findArchivedStatus(statuses);
    return [...emptyStatuses, ...(archived ? [archived.id] : [])];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCard = React.useCallback(
    (
      id: string,
      _statusId: string,
      _edit:
        | {
            start?: (() => void) | undefined;
            end?: (() => void) | undefined;
          }
        | undefined
    ) => <WorkItemCard id={id} initiativeId={initiativeId} />,
    [initiativeId]
  );

  const getSelector = React.useCallback(
    (columnId: string) =>
      issueIdsForStatusAndInitiativeSelector({
        initiativeId,
        statusId: columnId,
      }),
    []
  );

  const getAllColumns = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return snapshot
          .getLoadable(issueIdsByStatusForInitiativeSelector({ initiativeId }))
          .getValue();
      },
    [initiativeId]
  );

  const renderColumnHeader = React.useCallback(
    (statusId: string) => {
      return <ColumnHeader statusId={statusId} count={issueIdsByStatus[statusId].length} />;
    },
    [initiativeId, issueIdsByStatus]
  );

  if (!statuses.length || values(issueIdsByStatus).flat().length === 0) {
    return (
      <div className={styles.noContent}>
        <Placeholder icon="insights" title={`No ${issueTerm}s`}>
          <span className="grayed textCenter">
            <p>{capitalize(issueTerm)}s connected to this initiative will appear here</p>
          </span>
          <div className="row mr12 headerGap">
            <Button
              onClick={() => {
                modals.openModal(Modals.CommandMenu, {
                  view: CommandMenuView.ChangeInitiativeIssues,
                  context: {
                    initiativeId,
                  },
                });
              }}
            >
              Select {issueTerm}
            </Button>
            <Button
              icon="add"
              onClick={() => {
                modals.openModal(Modals.NewEntity);
              }}
            >
              New {issueTerm}
            </Button>
          </div>
        </Placeholder>
      </div>
    );
  }

  if (mode === Mode.Board) {
    return (
      <VirtualizedBoardView
        className={styles.board}
        id={initiativeId}
        columnIds={statuses}
        getAllColumns={getAllColumns}
        getSelector={getSelector}
        columnHeaderHeight={32}
        renderColumnHeader={renderColumnHeader}
        renderCard={renderCard}
        renderPlaceholder={() => null}
      />
    );
  }

  return (
    <VirtualizedListView
      id={initiativeId}
      className={className}
      defaultCollapsed={defaultCollapsed}
      sectionIds={statuses}
      interactiveHeaders
      itemIds={issueIdsByStatus}
      sectionHeaderHeight={id => {
        if (id === 'input') {
          return 24;
        }
        return 60;
      }}
      itemHeight={41}
      spacerHeight={32}
      canCreate={() => false}
      renderSectionHeader={(id, collapsed, toggleCollapsed) => {
        if (id === 'input') {
          return null;
        }
        return (
          <RowHeader
            statusId={id}
            collapsed={collapsed}
            onToggleCollapsed={toggleCollapsed}
            count={issueIdsByStatus[id].length}
          />
        );
      }}
      renderItem={(id, _sectionId, isFirst, isLast, edit) => {
        return (
          <ListItem
            id={id}
            metadataConfig={metadataConfig}
            initiativeId={initiativeId}
            className={cn('listItem', {
              first: isFirst,
              last: isLast,
            })}
            onEdit={edit?.start}
            onEditComplete={edit?.end}
            style={
              {
                '--number-column-width': `${numberColumnWidth}px`,
              } as React.CSSProperties
            }
          />
        );
      }}
      renderAccessories={grid => {
        return <ResizeItemsOnStateChange ids={grid} />;
      }}
      renderPlaceholder={() => null}
    />
  );
}

export function getFilterId(spaceId: string, initiativeId: string) {
  return `initiative-${spaceId}-${initiativeId}`;
}

function WorksItems({
  initiativeId,
  numberColumnWidth,
  keyNavFocus,
}: {
  initiativeId: string;
  numberColumnWidth?: number;
  keyNavFocus: React.RefObject<string | null>;
}) {
  const modals = useModals();
  const space = useSpace();
  const copyEntities = useCopyEntitiesToClipboard();
  const mode = useRecoilValue(initiativeScreenSpaceMode(`${initiativeId}-${space.id}`));

  const setFocus = useSetKeyNavigationFocus();
  const updateIssueSorts = useUpdateIssueSorts();
  const disableMissingElementDetection = useDisableMissingKeyNavigationElementDetection();
  const enableMissingElementDetection = useEnableMissingKeyNavigationElementDetection();
  const sortIssues = useSortIssuesInBoardOrder();
  const duplicateIssues = useDuplicateIssues();
  const setFilter = useSetRecoilState(filterState(getFilterId(space.id, initiativeId)));
  const setFilterNew = useSetRecoilState(filterChainState(getFilterId(space.id, initiativeId)));
  const showEmpty = useRecoilValue(initiativeScreenShowEmpty(getFilterId(space.id, initiativeId)));
  const archivedStatus = useRecoilValue(archivedStatusForSpaceSelector(space.id));

  const issueIdsByStatus = useRecoilValue(
    issueIdsByStatusForInitiativeSelector({ initiativeId, spaceId: space.id })
  );

  // FIXME this is just a quick hack to make sure the new work items get the initiatve. We need a better
  // way though.
  React.useEffect(() => {
    setFilterNew({
      filters: [
        {
          type: FilterTypeNew.Initiative,
          ids: [initiativeId],
          modifier: 'all-of',
        },
      ],
      operation: 'and',
    });
  }, [initiativeId, setFilter]);

  const statusIds = React.useMemo(() => {
    if (showEmpty) {
      return keys(issueIdsByStatus);
    } else {
      return keys(pickBy(issueIdsByStatus, value => value.length > 0));
    }
  }, [issueIdsByStatus, showEmpty]);
  const statuses = useRecoilValue(statusesSelector(statusIds));

  const disabledColumnDndMessages = React.useMemo(
    () =>
      statuses?.reduce((ret, status) => {
        if (status.sortMode !== IssueStatusSortMode.Manual) {
          ret[status.id] = (
            <>
              <span className="semiBold">{status.name}</span> is ordered by{' '}
              <span className="semiBold">{sortModeToString(status.sortMode)}</span>.
            </>
          );
        }
        if (status.statusType === IssueStatusType.Archived) {
          ret[status.id] = (
            <>
              <span className="semiBold">Archived</span> cannot be reordered. It is always sorted by
              archived date.
            </>
          );
        }
        return ret;
      }, {} as { [columnId: string]: React.ReactNode }),
    [statuses]
  );
  const defaultCollapsed = React.useMemo(() => {
    const emptyStatuses = Object.keys(issueIdsByStatus).reduce(
      (acc, curr) => (issueIdsByStatus[curr].length > 0 ? acc : [curr, ...acc]),
      [] as string[]
    );
    return [...emptyStatuses, ...(archivedStatus ? [archivedStatus.id] : [])];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const move = useRecoilCallback(
    ({ snapshot }) =>
      async (rawIssueIdsToMove: string[], statusId: string, index: number) => {
        const { filteredIssueIds, issueIdsByStatus } = snapshot
          .getLoadable(
            issuesByStatusSelector({
              spaceId: space.id,
            })
          )
          .getValue();

        let issueIdsToMove = rawIssueIdsToMove;
        // need to sort the ids in board order in the multiselect case
        if (issueIdsToMove.length > 1) {
          issueIdsToMove = sortIssues(rawIssueIdsToMove);
        }
        const issuesForStatus = [...(issueIdsByStatus[statusId] ?? [])].filter(
          id => !issueIdsToMove.includes(id)
        );

        // if this is true, it means we are moving up/down
        if (issuesForStatus.length !== (issueIdsByStatus[statusId]?.length ?? 0)) {
          const disabledMessage = disabledColumnDndMessages?.[statusId];
          if (disabledMessage) {
            autoSortedMessage(disabledMessage, true);
            return;
          }
        } else {
          hideAutoSortedMessage();
        }

        const filteredIssuesForStatus = [...(filteredIssueIds[statusId] ?? [])].filter(
          id => !issueIdsToMove.includes(id)
        );

        // we're inserting into the filtered list, but need to actually insert into the real list
        const filteredIndexToInsertAt = Math.min(index, filteredIssuesForStatus.length);

        // if we're moving to the top, let's just put it at the top of the real list
        let indexToInsertAt = 0;

        // if we're not moving to the top, find the card we're going to place the card after
        if (filteredIndexToInsertAt !== 0) {
          const issueToInsertAfter = filteredIssuesForStatus[filteredIndexToInsertAt - 1];
          indexToInsertAt = issuesForStatus.indexOf(issueToInsertAfter) + 1;
        }

        issuesForStatus.splice(indexToInsertAt, 0, ...issueIdsToMove);
        const previousIssueId: string | undefined = issuesForStatus[indexToInsertAt - 1];
        const nextIssueId: string | undefined =
          issuesForStatus[indexToInsertAt + issueIdsToMove.length];

        disableMissingElementDetection();
        updateIssueSorts(statusId, issueIdsToMove, previousIssueId, nextIssueId);
        enableMissingElementDetection();
      },
    [space.id, disabledColumnDndMessages]
  );

  const onPaste = useRecoilCallback(
    ({ snapshot }) =>
      function RecoilColumnAccessories(statusId: string, index: number, ids: string[]) {
        const items = snapshot
          .getLoadable(
            filteredIssuesForStatusSelector({ spaceId: space.id, statusId, filterId: '' })
          )
          .getValue();
        const current = items[index];
        const previous = items[index - 1];
        const results = duplicateIssues(ids, statusId, previous, current);
        if (results.length) {
          setTimeout(() => {
            setFocus(results[0]);
          });
        }
      },
    [space.id, setFocus, duplicateIssues]
  );

  if (!statusIds.length || values(issueIdsByStatus).flat().length === 0) {
    return (
      <div className={styles.noContent}>
        <Placeholder icon="insights" title={`No ${issueTerm}s`}>
          <span className="grayed textCenter">
            <p>{capitalize(issueTerm)}s connected to this initiative will appear here</p>
          </span>
          <div className="row mr12 headerGap">
            <Button
              onClick={() => {
                modals.openModal(Modals.CommandMenu, {
                  view: CommandMenuView.ChangeInitiativeIssues,
                  context: {
                    initiativeId,
                    spaceId: space.id,
                  },
                });
              }}
            >
              Select {issueTerm}
            </Button>
            <Button
              icon="add"
              onClick={() => {
                modals.openModal(Modals.NewEntity);
              }}
            >
              New {issueTerm}
            </Button>
          </div>
        </Placeholder>
      </div>
    );
  }

  return (
    <>
      <WorkItemContext id={getFilterId(space.id, initiativeId)} keyNavFocus={keyNavFocus} />
      {mode === Mode.Board && (
        <WorkItemBoardView
          includeArchived
          boardId={getFilterId(space.id, initiativeId)}
          statusIds={statusIds}
          disabledColumnDndMessages={disabledColumnDndMessages ?? {}}
          move={move}
          copyEntities={copyEntities}
          onPaste={onPaste}
          disableColumnEditing
          initiativeId={initiativeId}
        />
      )}
      {mode === Mode.List && (
        <WorkItemListView
          numberColumnWidth={numberColumnWidth}
          defaultCollapsed={defaultCollapsed}
          disableColumnEditing
          includeArchived
          boardId={getFilterId(space.id, initiativeId)}
          statusIds={statusIds}
          disabledColumnDndMessages={disabledColumnDndMessages ?? {}}
          move={move}
          copyEntities={copyEntities}
          onPaste={onPaste}
          initiativeId={initiativeId}
        />
      )}
    </>
  );
}

export function InitiativeWorkItemView({
  initiativeId,
  currentTab,
}: {
  initiativeId: string;
  currentTab: string;
}) {
  let content;
  const spaceIds = useRecoilValue(spacesIdsForInitiativeSelector(initiativeId));
  const numberColumnWidth = useEntityNumberWidths(spaceIds);
  const keynavFocusRef = React.useRef<string | null>(null);

  useNewEntityModalArgs({
    type: 'Issue',
    initiativeIds: [initiativeId],
  });

  if (currentTab === 'all') {
    content = (
      <InitiativeWorkItemList
        numberColumnWidth={numberColumnWidth}
        className="fullWidth"
        initiativeId={initiativeId}
      />
    );
  } else {
    content = (
      <SpaceProvider spaceId={currentTab}>
        <WorksItems
          numberColumnWidth={numberColumnWidth}
          initiativeId={initiativeId}
          keyNavFocus={keynavFocusRef}
        />
        <EntityCommandContext />
        <KeyNavigationWatcher focusedRef={keynavFocusRef} />
      </SpaceProvider>
    );
  }

  return <div className={styles.workItemList}>{content}</div>;
}
