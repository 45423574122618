import Uppy from '@uppy/core';

export class LocalPreparePlugin extends Uppy.Plugin {
  private path: string;

  constructor(uppy: Uppy.Uppy, opts?: Uppy.PluginOptions & { path?: string }) {
    super(uppy, opts);
    this.id = opts?.id || 'local-prepare';
    this.type = 'prepare';
    this.prepareUpload = this.prepareUpload.bind(this);
    this.path = opts?.path ?? '';
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstal() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }

  async prepareUpload(fileIds: string[]) {
    return Promise.all(
      fileIds.map(async fileId => {
        const file = this.uppy.getFile(fileId);
        await fetch('/content/prepare', {
          method: 'post',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            name: file.name,
            type: file.type,
            meta: file.meta,
            path: this.path,
          }),
        });
      })
    );
  }
}
