import { last } from 'lodash';
import { Editor, Node, Text, Path } from 'slate';
import { ReactEditor } from 'slate-react';
import uuid from 'uuid';
import { KitemakerElement } from '../../../../shared/slate/kitemakerNode';
import { writeToClipboard } from '../../../components/clipboardText';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType, Elements, SmartTodoElement } from '../../types';

export function copyNumberToClipboard(key: string) {
  writeToClipboard(key, 'todo number');
}

export function onMemberAdded(
  editor: EditorType,
  element: SmartTodoElement,
  actorId: string,
  memberId: string
) {
  const path = ReactEditor.findPath(editor, element);
  const [, end] = Editor.edges(editor, path);
  const lastChild = last(element.children);
  const endsInSpace = lastChild && Text.isText(lastChild) && lastChild.text.endsWith(' ');
  KitemakerTransforms.insertNodes(
    editor,
    [
      {
        text: endsInSpace ? '' : ' ',
      },
      {
        type: Elements.User,
        userId: memberId,
        mentionId: uuid.v4(),
        actorId,
        children: [{ text: '' }],
      },
      {
        text: '',
      },
    ],
    { at: end, select: true }
  );
}

export function onMemberRemoved(editor: EditorType, element: SmartTodoElement, memberId: string) {
  const path = ReactEditor.findPath(editor, element);
  const nodes = Node.children(editor, path);

  let previousNode: Node | null = null;
  let previousPath: Path | null = null;

  for (const [node, path] of nodes) {
    if (
      KitemakerElement.isElement(node) &&
      node.type === Elements.User &&
      node.userId === memberId
    ) {
      if (Text.isText(previousNode) && previousNode.text.endsWith(' ')) {
        const point = { path: previousPath!, offset: previousNode.text.length - 1 };
        KitemakerTransforms.delete(editor, { at: point });
      }
    }
    previousNode = node;
    previousPath = path;
  }

  KitemakerTransforms.removeNodes(editor, {
    at: path,
    match: n => KitemakerElement.isElement(n) && n.type === Elements.User && n.userId === memberId,
  });
}

export function onLabelAdded(editor: EditorType, element: SmartTodoElement, labelId: string) {
  const path = ReactEditor.findPath(editor, element);
  const [, end] = Editor.edges(editor, path);
  const lastChild = last(element.children);
  const endsInSpace = lastChild && Text.isText(lastChild) && lastChild.text.endsWith(' ');
  KitemakerTransforms.insertNodes(
    editor,
    [
      {
        text: endsInSpace ? '' : ' ',
      },
      {
        type: Elements.Label,
        labelId: labelId,
        children: [{ text: '' }],
      },
      {
        text: '',
      },
    ],
    { at: end, select: true }
  );
}

export function onLabelRemoved(editor: EditorType, element: SmartTodoElement, labelId: string) {
  if (!element || !editor) {
    return;
  }
  const path = ReactEditor.findPath(editor, element);
  KitemakerTransforms.removeNodes(editor, {
    at: path,
    match: n => KitemakerElement.isElement(n) && n.type === Elements.Label && n.labelId === labelId,
  });
}
