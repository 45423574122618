import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { MemberRole } from '../../sync/__generated/models';
import { Hotkey } from '../components/new/hotkey';
import { useOrganization } from '../contexts/organizationContext';
import { useCurrentUser } from '../contexts/userContext';
import ChangePasswordModal from '../modals/changePasswordModal';
import DeveloperModal from '../modals/developerModal';
import EnableCyclesModal from '../modals/enableCyclesModal';
import HotkeyMenu from '../modals/hotkeyMenu';
import InviteMemberModal from '../modals/inviteMemberModal';
import NewCustomEmojiModal from '../modals/newCustomEmojiModal';
import { NewEntityModal } from '../modals/newEntityModal';
import { NewExternalIssueModal } from '../modals/newExternalIssueModal';
import { NewFolderModal } from '../modals/newFolderModal';
import NewRoadmapModal from '../modals/newRoadmapModal';
import { NewSpaceModal } from '../modals/newSpaceModal';
import NewTokenModal from '../modals/newTokenModal';
import TodoDetailsModal from '../modals/todoDetailsModal';
import { ONBOARDING_TUTORIAL_ID } from '../screens/onboardingScreen';
import { starredSelector } from '../syncEngine/selectors/starred';
import { currentUserMembershipState } from '../syncEngine/selectors/users';
import { gotoStarredKey } from '../utils/config';

export function OrganizationCommandsAndModals() {
  const organization = useOrganization();
  const user = useCurrentUser();
  const history = useHistory();
  const onboardingComplete = user.completedTutorials.includes(ONBOARDING_TUTORIAL_ID);
  const member = useRecoilValue(currentUserMembershipState(organization.id));
  const starred = useRecoilValue(starredSelector(organization.id));

  if (!member) {
    return null;
  }

  const starredHotkeys = starred.slice(0, 9).map((f, index) => (
    <Hotkey
      key={index}
      hotkey={`${gotoStarredKey}${index + 1}`}
      handler={e => {
        e?.preventDefault();
        e?.stopPropagation();
        history.push({
          pathname: f.link,
          state: {
            backUrl: location.pathname,
            backSearch: location.search,
          },
        });
      }}
    />
  ));

  return (
    <>
      <ChangePasswordModal />
      <HotkeyMenu />
      <NewEntityModal />
      <NewExternalIssueModal />
      <InviteMemberModal />
      <NewTokenModal />
      <EnableCyclesModal />
      <DeveloperModal />
      <NewRoadmapModal />
      <NewCustomEmojiModal />
      <TodoDetailsModal />
      <NewFolderModal />
      {starredHotkeys}
      {onboardingComplete && (
        <>
          {member.role !== MemberRole.Guest && (
            <>
              <NewSpaceModal />
            </>
          )}
        </>
      )}
    </>
  );
}
