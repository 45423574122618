import * as React from 'react';
import { InlineMath } from 'react-katex';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { MathElement } from '../../../shared/slate/types';
import { MathHover } from '../hovers/mathHover';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './math.module.scss';
import { VoidInline } from './voidBlock';

export function StaticMath({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: MathElement }) {
  const { katex } = element;
  const content = (
    <>
      {katex && <InlineMath math={katex} />}
      {!katex && <span className={styles.mathPlaceholder}>Add a TeX expression</span>}
    </>
  );

  return (
    <span {...attributes} className={styles.math}>
      {content}
      {children}
    </span>
  );
}

export function Math({
  attributes,
  element,
  children,
}: RenderElementProps & { element: MathElement }) {
  const { katex } = element;
  const editor = useSlateStatic();
  const [showHover, setShowHover] = React.useState(false);
  const content = (
    <>
      {katex && <InlineMath math={katex} />}
      {!katex && <span className={styles.mathPlaceholder}>Add a TeX expression</span>}
    </>
  );

  function focus() {
    ReactEditor.focus(editor);
    setTimeout(() => {
      const path = ReactEditor.findPath(editor, element);
      KitemakerTransforms.moveSelectionToPath(editor, path);
    }, 100);
  }

  if (!showHover) {
    return (
      <VoidInline
        attributes={attributes}
        element={element}
        className={styles.math}
        onMouseDown={e => {
          e.preventDefault();
        }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setShowHover(true);
        }}
      >
        {content}
        {children}
      </VoidInline>
    );
  }

  return (
    <VoidInline
      attributes={attributes}
      element={element}
      className={styles.math}
      onMouseDown={e => {
        e.preventDefault();
      }}
      onClick={e => {
        if (showHover) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        setShowHover(true);
      }}
    >
      <MathHover
        katex={katex ?? ''}
        onChange={value => {
          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { katex: value }, { at: path });
          focus();
        }}
        onHide={escape => {
          setShowHover(false);
          if (escape) {
            focus();
          }
        }}
      >
        <span>{content}</span>
      </MathHover>
      {children}
    </VoidInline>
  );
}
