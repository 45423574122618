import { EventEmitter } from 'eventemitter3';
import * as React from 'react';
import { ScrollMode } from 'scroll-into-view-if-needed/typings/types';
import uuid from 'uuid';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import {
  mainComboKey,
  toggleSelectionKey,
  vimDown,
  vimLeft,
  vimRight,
  vimUp,
} from '../../../utils/config';
import { Hotkey } from '../hotkey';
import {
  KEY_NAVIGATION_DATA_ATTRIBUTE,
  ensureVisible,
  isMouseDown,
  mouseX,
  mouseY,
  scrolled,
} from './dom';
import {
  Coordinate,
  FocusReason,
  KeyNavigationState,
  clearSelection,
  createKeyNavigationState,
  disable,
  disableMissingElementDetection,
  enable,
  enableMissingElementDetection,
  focus,
  initialize,
  moveDown,
  moveLeft,
  moveRight,
  moveToBottom,
  moveToTop,
  moveUp,
  select,
  selectAll,
  toggleSelected,
  updateColumn,
} from './state';

export { FocusReason } from './state';
export { useMouseMoveTracker, KEY_NAVIGATION_DATA_ATTRIBUTE } from './dom';
export { KeyNavigationDisablingElement } from './keyNavigationDisablingElement';
export { KeyNavigationElement, useKeyNavigationElement } from './keyNavigationElement';

const emitter = new EventEmitter<'updated'>();
const keyNavContext = React.createContext<string | null>(null);
const keyNavState: Record<string, KeyNavigationState> = {};

function ClearMultiSelectHotkey({ handler }: { handler: (e?: KeyboardEvent) => void }) {
  const { selected } = useKeyNavigationState();
  if (selected?.length) {
    return <Hotkey hotkey={'escape'} priority={0} handler={handler} />;
  }

  return null;
}

function useSetKeyNavState(id: string) {
  return (update: (previous: KeyNavigationState) => KeyNavigationState) => {
    const state = keyNavState[id] ?? createKeyNavigationState(id, []);
    const updated = update(state);
    if (updated !== state) {
      keyNavState[id] = updated;
      emitter.emit('updated', { id });
    }
  };
}

export function KeyNavigationProvider({
  columnIds,
  initiallyFocusedElementId,
  disableEnsureVisible,
  ensureVisibleOptions,
  disableLeftAndRight,
  multiSelect,
  isMultiSelectable,
  onSelectAll,
  id,
  children,
}: {
  columnIds: string[];
  id?: string;
  initiallyFocusedElementId?: string;
  disableEnsureVisible?: boolean;
  ensureVisibleOptions?: { blockMode?: string; inlineMode?: string; scrollMode?: ScrollMode };
  disableLeftAndRight?: boolean;
  multiSelect?: boolean;
  isMultiSelectable?: (id: string, columnId: string) => boolean;
  onSelectAll?: (
    focusedElementId: string,
    focusedColumnId: string,
    selectedElementIds: string[]
  ) => string[];
  children: React.ReactNode;
}) {
  const keyNavId = React.useRef(id ?? uuid.v4());

  const setKeyNavState = useSetKeyNavState(keyNavId.current);
  const [initialized, setInitialized] = React.useState(false);

  function canMultiSelect(id?: string | null, columnId?: string | null) {
    if (!id || !columnId) {
      return false;
    }
    if (!isMultiSelectable) {
      return true;
    }

    return isMultiSelectable(id, columnId);
  }

  useComponentDidMount(() => {
    function onBodyClick(e: MouseEvent) {
      if (!e.shiftKey) {
        // FIXME: hack to deal with radix menus
        let parent = e.target as HTMLElement;
        while (parent) {
          if ((parent as any).role?.includes('menu')) {
            return;
          }
          parent = parent.parentElement as HTMLElement;
        }
        setKeyNavState(state => clearSelection(state));
      }
    }

    document.body.addEventListener('click', onBodyClick);
    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  });

  React.useLayoutEffect(() => {
    setKeyNavState(original =>
      initialize({
        ...original,
        columnIds,
        focusedElementId: original.focusedElementId ?? initiallyFocusedElementId ?? null,
        disableEnsureVisible: disableEnsureVisible ?? false,
        ensureVisibleOptions,
        multiSelect,
        canMultiSelect,
      })
    );
    setInitialized(true);
  }, [JSON.stringify(columnIds)]);

  return (
    <keyNavContext.Provider value={keyNavId.current}>
      <Hotkey
        hotkey="up"
        global
        handler={e => {
          setKeyNavState(original => {
            const updated = moveUp(original, FocusReason.Keyboard);
            if (updated !== original) {
              e?.preventDefault();
              e?.stopPropagation();
            }
            return updated;
          });
        }}
      />
      <Hotkey
        hotkey="down"
        global
        handler={e => {
          setKeyNavState(original => {
            const updated = moveDown(original, FocusReason.Keyboard);
            if (updated !== original) {
              e?.preventDefault();
              e?.stopPropagation();
            }
            return updated;
          });
        }}
      />
      <Hotkey
        hotkey={vimUp}
        handler={e => {
          setKeyNavState(original => {
            const updated = moveUp(original, FocusReason.Keyboard);
            if (updated !== original) {
              e?.preventDefault();
              e?.stopPropagation();
            }
            return updated;
          });
        }}
      />
      <Hotkey
        hotkey={vimDown}
        handler={e => {
          setKeyNavState(original => {
            const updated = moveDown(original, FocusReason.Keyboard);
            if (updated !== original) {
              e?.preventDefault();
              e?.stopPropagation();
            }
            return updated;
          });
        }}
      />
      <Hotkey
        hotkey={`${mainComboKey}+up`}
        handler={e => {
          setKeyNavState(original => {
            const updated = moveToTop(original, FocusReason.Keyboard);
            if (updated !== original) {
              e?.preventDefault();
              e?.stopPropagation();
            }
            return updated;
          });
        }}
      />
      <Hotkey
        hotkey={`${mainComboKey}+down`}
        handler={e => {
          setKeyNavState(original => {
            const updated = moveToBottom(original, FocusReason.Keyboard);
            if (updated !== original) {
              e?.preventDefault();
              e?.stopPropagation();
            }
            return updated;
          });
        }}
      />
      {multiSelect && (
        <>
          <Hotkey
            hotkey={toggleSelectionKey}
            handler={e => {
              setKeyNavState(original => {
                const updated = toggleSelected(original);
                if (updated !== original) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                return updated;
              });
            }}
          />
          <ClearMultiSelectHotkey
            handler={e => {
              setKeyNavState(original => {
                const updated = clearSelection(original);
                if (updated !== original) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                return updated;
              });
            }}
          />
          <Hotkey
            hotkey={`${mainComboKey}+a`}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              setKeyNavState(original => {
                if (onSelectAll && original.focusedElementId && original.focusedColumnId) {
                  return {
                    ...original,
                    selectedElementIds: onSelectAll(
                      original.focusedElementId,
                      original.focusedColumnId,
                      original.selectedElementIds ?? []
                    ).filter(id => canMultiSelect(id, original.focusedColumnId)),
                  };
                }
                return selectAll(original);
              });
            }}
          />
          <Hotkey
            hotkey={`shift+up`}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              setKeyNavState(original => {
                if (!original.focusedElementId) {
                  return original;
                }
                if (!original.selectedElementIds?.includes(original.focusedElementId)) {
                  return toggleSelected(original);
                }

                const updated = moveUp(original, FocusReason.Keyboard);
                if (updated.focusedElementId === original.focusedElementId) {
                  return original;
                }

                // if the thing we're heading towards is selected, we should unselect this thing. Otherwise
                // we should select the thing we're heading for.
                if (
                  updated.focusedElementId &&
                  original.selectedElementIds.includes(updated.focusedElementId)
                ) {
                  return moveUp(toggleSelected(original));
                }

                return toggleSelected(updated);
              });
            }}
          />
          <Hotkey
            hotkey={`shift+${vimUp}`}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              setKeyNavState(original => {
                if (!original.focusedElementId) {
                  return original;
                }
                if (!original.selectedElementIds?.includes(original.focusedElementId)) {
                  return toggleSelected(original);
                }

                const updated = moveUp(original, FocusReason.Keyboard);
                if (updated.focusedElementId === original.focusedElementId) {
                  return original;
                }

                // if the thing we're heading towards is selected, we should unselect this thing. Otherwise
                // we should select the thing we're heading for.
                if (
                  updated.focusedElementId &&
                  original.selectedElementIds.includes(updated.focusedElementId)
                ) {
                  return moveUp(toggleSelected(original));
                }

                return toggleSelected(updated);
              });
            }}
          />
          <Hotkey
            hotkey={`shift+down`}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              setKeyNavState(original => {
                if (!original.focusedElementId) {
                  return original;
                }
                if (!original.selectedElementIds?.includes(original.focusedElementId)) {
                  return toggleSelected(original);
                }

                const updated = moveDown(original, FocusReason.Keyboard);
                if (updated.focusedElementId === original.focusedElementId) {
                  return original;
                }

                // if the thing we're heading towards is selected, we should unselect this thing. Otherwise
                // we should select the thing we're heading for.
                if (
                  updated.focusedElementId &&
                  original.selectedElementIds.includes(updated.focusedElementId)
                ) {
                  return moveDown(toggleSelected(original));
                }

                return toggleSelected(updated);
              });
            }}
          />
          <Hotkey
            hotkey={`shift+${vimDown}`}
            priority={0}
            handler={e => {
              e?.preventDefault();
              e?.stopPropagation();
              setKeyNavState(original => {
                if (!original.focusedElementId) {
                  return original;
                }
                if (!original.selectedElementIds?.includes(original.focusedElementId)) {
                  return toggleSelected(original);
                }

                const updated = moveDown(original, FocusReason.Keyboard);
                if (updated.focusedElementId === original.focusedElementId) {
                  return original;
                }

                // if the thing we're heading towards is selected, we should unselect this thing. Otherwise
                // we should select the thing we're heading for.
                if (
                  updated.focusedElementId &&
                  original.selectedElementIds.includes(updated.focusedElementId)
                ) {
                  return moveDown(toggleSelected(original));
                }

                return toggleSelected(updated);
              });
            }}
          />
        </>
      )}
      {!disableLeftAndRight && (
        <>
          <Hotkey
            hotkey="left"
            handler={e => {
              setKeyNavState(original => {
                const updated = moveLeft(original, FocusReason.Keyboard);
                if (updated !== original) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                return updated;
              });
            }}
          />
          <Hotkey
            hotkey={'right'}
            handler={e => {
              setKeyNavState(original => {
                const updated = moveRight(original, FocusReason.Keyboard);
                if (updated !== original) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                return updated;
              });
            }}
          />
          <Hotkey
            hotkey={vimLeft}
            handler={e => {
              setKeyNavState(original => {
                const updated = moveLeft(original, FocusReason.Keyboard);
                if (updated !== original) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                return updated;
              });
            }}
          />
          <Hotkey
            hotkey={vimRight}
            handler={e => {
              setKeyNavState(original => {
                const updated = moveRight(original, FocusReason.Keyboard);
                if (updated !== original) {
                  e?.preventDefault();
                  e?.stopPropagation();
                }
                return updated;
              });
            }}
          />
        </>
      )}
      {initialized && children}
    </keyNavContext.Provider>
  );
}

export function useKeyNavigationProviderId() {
  return React.useContext(keyNavContext);
}

export function useSetKeyNavigationFocus() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return (elementId: string | null, reason?: FocusReason) =>
    setKeyNavState(original => {
      return focus(original, elementId, reason);
    });
}

export function useSetKeyNavigationSelection() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return (elementIds: string[]) =>
    setKeyNavState(original => {
      return select(original, elementIds);
    });
}

export function useToggleKeyNavigationSelection() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return () =>
    setKeyNavState(original => {
      return toggleSelected(original);
    });
}

export function useDisableKeyNavigation() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return (key: string) =>
    setKeyNavState(original => {
      return disable(original, key);
    });
}

export function useEnableKeyNavigation() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return (key: string) =>
    setKeyNavState(original => {
      return enable(original, key);
    });
}

export function useDisableMissingKeyNavigationElementDetection() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return () =>
    setKeyNavState(original => {
      return disableMissingElementDetection(original);
    });
}

export function useEnableMissingKeyNavigationElementDetection() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return () =>
    // NOTE: hack here but because we usually do the following:
    // - disable missing element detection
    // - move stuff
    // - enable missing element detection
    // we need to wait for the columns to update after a move, so we use a set timeout
    setTimeout(() => {
      setKeyNavState(original => {
        return enableMissingElementDetection(original);
      });
    });
}

export function useClearSelection() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return () =>
    setKeyNavState(original => {
      return clearSelection(original);
    });
}

export function useMove() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return (direction: 'up' | 'down' | 'left' | 'right' | 'top' | 'bottom', reason?: FocusReason) => {
    setKeyNavState(original => {
      switch (direction) {
        case 'up':
          return moveUp(original, reason);
        case 'down':
          return moveDown(original, reason);
        case 'left':
          return moveLeft(original, reason);
        case 'right':
          return moveRight(original, reason);
        case 'top':
          return moveToTop(original, reason);
        case 'bottom':
          return moveToBottom(original, reason);
      }
    });
  };
}
export function useMultiMove() {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  return (
    callback: (
      move: (direction: 'up' | 'down' | 'left' | 'right' | 'top' | 'bottom') => void
    ) => void,
    reason?: FocusReason
  ) => {
    setKeyNavState(original => {
      let result = original;
      const move = (direction: 'up' | 'down' | 'left' | 'right' | 'top' | 'bottom') => {
        switch (direction) {
          case 'up':
            result = moveUp(result, reason);
            break;
          case 'down':
            result = moveDown(result, reason);
            break;
          case 'left':
            result = moveLeft(result, reason);
            break;
          case 'right':
            result = moveRight(result, reason);
            break;
          case 'top':
            result = moveToTop(result, reason);
            break;
          case 'bottom':
            result = moveToBottom(result, reason);
            break;
        }
      };
      callback(move);
      return result;
    });
  };
}

export function useLockKeyNavigation() {
  const id = React.useContext(keyNavContext);
  const state = keyNavState[id ?? ''];

  React.useEffect(() => {
    if (state) {
      state.locked = true;
    }

    return () => {
      if (state) {
        state.locked = false;
      }
    };
  });
}

export function useHasKeyNavigationFocus(elementId: string) {
  const id = React.useContext(keyNavContext);
  const [state, setState] = React.useState(() => {
    const s = keyNavState[id ?? ''];
    return s?.focusedElementId === elementId;
  });

  React.useEffect(() => {
    const s = keyNavState[id ?? ''];
    setState(s?.focusedElementId === elementId);

    function onUpdated({ id: updatedId }: { id: string }) {
      if (updatedId !== id) {
        return;
      }
      const updated = keyNavState[id];
      if (!updated || updated.disabled.size) {
        setState(false);
        return;
      }

      setState(updated.focusedElementId === elementId);
    }

    emitter.on('updated', onUpdated);
    return () => {
      emitter.off('updated', onUpdated);
    };
  }, [elementId]);

  return state;
}

export function useHasKeyNavigationSelection(elementId: string) {
  const id = React.useContext(keyNavContext);
  const [state, setState] = React.useState(() => {
    const s = keyNavState[id ?? ''];
    return s?.selectedElementIds?.includes(elementId) ?? false;
  });

  useComponentDidMount(() => {
    const s = keyNavState[id ?? ''];
    setState(s?.selectedElementIds?.includes(elementId) ?? false);

    function onUpdated({ id: updatedId }: { id: string }) {
      if (updatedId !== id) {
        return;
      }
      const updated = keyNavState[id];
      if (!updated || updated.disabled.size) {
        setState(false);
        return;
      }

      setState(updated.selectedElementIds?.includes(elementId) ?? false);
    }

    emitter.on('updated', onUpdated);
    return () => emitter.off('updated', onUpdated);
  });

  return state;
}

export function useColumnHasKeyNavigationFocus(columnId: string) {
  const id = React.useContext(keyNavContext);
  const [state, setState] = React.useState(() => {
    const s = keyNavState[id ?? ''];
    return s?.focusedColumnId === columnId;
  });

  useComponentDidMount(() => {
    const s = keyNavState[id ?? ''];
    setState(s?.focusedColumnId === columnId ?? false);

    function onUpdated({ id: updatedId }: { id: string }) {
      if (updatedId !== id) {
        return;
      }
      const updated = keyNavState[id];
      if (!updated || updated.disabled.size) {
        setState(false);
        return;
      }

      setState(updated.focusedColumnId === columnId);
    }

    emitter.on('updated', onUpdated);
    return () => emitter.off('updated', onUpdated);
  });

  return state;
}

export function useIsMultiSelectable(elementId: string) {
  const id = React.useContext(keyNavContext);
  const isMultiSelectable = React.useMemo(() => {
    const state = keyNavState[id ?? ''];
    if (!state) {
      return false;
    }
    return state.multiSelect && state.canMultiSelect(elementId, state.focusedColumnId);
  }, [id, elementId]);

  return isMultiSelectable;
}

export function useEnsureFocusedElementIsVisible() {
  const id = React.useContext(keyNavContext);

  return (scrollMode?: ScrollMode) => {
    const state = keyNavState[id ?? ''];
    const focusedId = state?.focusedElementId;
    if (!state?.disableEnsureVisible && focusedId) {
      ensureVisible(focusedId, state.id, { scrollMode });
    }
  };
}

function extractKeyNavState(id?: string | null): {
  focused: string | null;
  selected: string[] | null;
  focusedColumn: string | null;
  focusedReason: FocusReason | null;
  focusedElementGridCoordinates: Coordinate | null;
} {
  const s = keyNavState[id ?? ''];
  if (!s || s.disabled.size) {
    return {
      focused: null,
      selected: null,
      focusedColumn: null,
      focusedReason: null,
      focusedElementGridCoordinates: null,
    };
  }
  return {
    focused: s.focusedElementId,
    selected: s.selectedElementIds,
    focusedColumn: s.focusedColumnId,
    focusedReason: s.focusedReason,
    focusedElementGridCoordinates: s.focusedElementGridCoordinates,
  };
}

export function useKeyNavigationState() {
  const id = React.useContext(keyNavContext);
  const [state, setState] = React.useState(() => extractKeyNavState(id));

  useComponentDidMount(() => {
    setState(extractKeyNavState(id));

    function onUpdated({ id: updatedId }: { id: string }) {
      if (updatedId !== id) {
        return;
      }
      setState(extractKeyNavState(id));
    }

    emitter.on('updated', onUpdated);
    return () => emitter.off('updated', onUpdated);
  });

  return state;
}

export function useKeyNavigationColumn(
  columnId: string,
  elementIds: string[],
  scrollParent?: React.RefObject<HTMLDivElement>
) {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');
  useSelectKeyNavigationElementAfterScroll(columnId, scrollParent);

  React.useLayoutEffect(() => {
    setKeyNavState(original => {
      const result = updateColumn({ ...original }, columnId, elementIds);
      return result;
    });
  }, [columnId, JSON.stringify(elementIds)]);

  // FIXME: we should clean up old columns, I think but it's a bit problematic
  // since sometimes we remove and re-add things quickly
}

export function useKeyNavigationWatcher(
  callback: (state: {
    focused: string | null;
    focusedReason: FocusReason | null;
    focusedElementGridCoordinates: Coordinate | null;
    focusedColumnId: string | null;
  }) => void
) {
  const id = React.useContext(keyNavContext);

  useComponentDidMount(() => {
    function onUpdated({ id: updatedId }: { id: string }) {
      if (updatedId !== id) {
        return;
      }
      const updated = keyNavState[id];

      callback({
        focused: updated.focusedElementId,
        focusedReason: updated.focusedReason,
        focusedElementGridCoordinates: updated.focusedElementGridCoordinates,
        focusedColumnId: updated.focusedColumnId,
      });
    }

    emitter.on('updated', onUpdated);
    return () => emitter.off('updated', onUpdated);
  });

  return null;
}

export function KeyNavigationWatcher({
  focusedRef,
}: {
  focusedRef: React.MutableRefObject<string | null>;
}) {
  useKeyNavigationWatcher(({ focused }) => {
    focusedRef.current = focused;
  });

  return null;
}

export function useGetKeyNavigationState() {
  const id = React.useContext(keyNavContext);
  return () => extractKeyNavState(id);
}

const timeouts: Record<string, number> = {};

export function useSelectKeyNavigationElementAfterScroll(
  columnId: string,
  scrollParent?: React.RefObject<HTMLDivElement>
) {
  const id = React.useContext(keyNavContext);
  const setKeyNavState = useSetKeyNavState(id ?? '');

  const scroll = React.useCallback(() => {
    function calculate() {
      if (!id) {
        return;
      }

      const elements = document.elementsFromPoint(mouseX, mouseY);
      const element = elements.find(e => e.getAttribute(KEY_NAVIGATION_DATA_ATTRIBUTE));
      if (!element) {
        return;
      }

      const elementId = element.getAttribute(KEY_NAVIGATION_DATA_ATTRIBUTE);
      if (!elementId) {
        return;
      }

      setKeyNavState(state => {
        if (!state || !state.columnIdsToElementIds[columnId]?.includes(elementId)) {
          return state;
        }
        return focus(state, elementId, FocusReason.Mouse, { ignoreMouseMove: true });
      });
    }

    if (timeouts[columnId]) {
      window.clearTimeout(timeouts[columnId]);
    }

    timeouts[columnId] = window.setTimeout(() => {
      delete timeouts[columnId];
      calculate();
    }, 200);
  }, [columnId, id]);

  useComponentDidMount(() => {
    function onScroll() {
      if (!isMouseDown) {
        return;
      }
      scrolled();
      scroll();
    }

    function onWheel() {
      scrolled();
      scroll();
    }

    const scroller = scrollParent?.current;

    if (scroller) {
      scroller.addEventListener('scroll', onScroll);
      scroller.addEventListener('wheel', onWheel);
    }

    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', onScroll);
        scroller.removeEventListener('wheel', onWheel);
      }
    };
  });
}
