import { uniq } from 'lodash';
import { selectorFamily } from 'recoil';
import { KITEMAKER_ACTOR_ID, ONBOARDING_ACTOR_ID } from '../../../shared/actors';
import { Actor } from '../../../sync/__generated/models';
import { stringifyIntegrationType } from '../../utils/integrations';
import { syncEngineState } from '../state';
import { isSyncEngineObject } from '../types';
import { currentUserState } from './users';

export function isNonUserActor(actor: Actor): boolean {
  return actor.__typename !== 'User';
}

export const actorsSelector = selectorFamily({
  key: 'Actors',
  get:
    (actorId: string | null | undefined) =>
    ({ get }) => {
      if (!actorId) {
        return null;
      }
      const actor = get(syncEngineState(actorId));
      if (!actor || (isSyncEngineObject(actor) && actor.deleted)) {
        return null;
      }
      return get(syncEngineState(actorId)) as Actor;
    },
});

export function actorName(actor: Actor): string {
  switch (actor.__typename) {
    case 'User': {
      return actor.username;
    }
    case 'IntegrationUser': {
      return `${stringifyIntegrationType(actor.type)} user ${
        actor.externalName.includes('@') ? '' : '@'
      }${actor.externalName}`;
    }
    case 'Integration': {
      if (actor) {
        return `${stringifyIntegrationType(actor.type)} integration`;
      }
      break;
    }
    case 'Application':
      {
        if (actor) {
          return `Application "${actor.name}"`;
        }
      }
      break;
  }

  return 'Unknown';
}

export const actorNameSelector = selectorFamily({
  key: 'ActorName',
  get:
    (actorId: string | null | undefined) =>
    ({ get }) => {
      if (!actorId) {
        return 'Unknown';
      }
      if (actorId === KITEMAKER_ACTOR_ID) {
        return 'Kitemaker';
      }
      if (actorId === ONBOARDING_ACTOR_ID) {
        return 'Sigurd from Kitemaker';
      }
      const actor = get(actorsSelector(actorId));
      if (!actor) {
        return 'Unknown';
      }

      return actorName(actor);
    },
});

export const actorNamesSelector = selectorFamily({
  key: 'ActorNames',
  get:
    (actorIds: string[]) =>
    ({ get }) => {
      const user = get(currentUserState);
      const uniqueActors = uniq(actorIds);
      if (!uniqueActors.length) {
        return 'An unknown user';
      }

      const includesYou = actorIds.includes(user?.id ?? '');
      const otherActors = actorIds.filter(actorId => actorId !== user?.id);
      const names = otherActors.map(actorId => get(actorNameSelector(actorId)));
      if (includesYou) {
        names.push('you');
      }

      if (names.length === 1) {
        return names[0] === 'you' ? 'You' : names[0];
      }

      return `${names.slice(0, -1).join(', ')} and ${names[names.length - 1]}`;
    },
});
