import * as React from 'react';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useFindRelevantEntities } from '../../syncEngine/selectors/entities';
import { StateEvents, offStateEvent, onStateEvent } from '../../syncEngine/state';
import { SyncEngineObject } from '../../syncEngine/types';
import { useRowHeights } from './virtualizedListView/rowHeightContext';

export function ResizeItemsOnStateChange({ ids }: { ids: string[] }) {
  const idsRef = React.useRef(ids);
  idsRef.current = ids;
  const findRelevantEntities = useFindRelevantEntities();
  const { recomputeRowHeights } = useRowHeights();

  useComponentDidMount(() => {
    function handleSet({ objects }: { objects: SyncEngineObject[] }) {
      // If an object is modified it might have moved to another section.
      // In that case, we need to recalculate the heights of the rows around it.
      // This is a cheap operation because it's not inspecting the DOM.
      const relevantForEntities = findRelevantEntities(objects);
      for (const entityId of relevantForEntities) {
        const itemIndex = idsRef.current.indexOf(entityId);
        if (itemIndex === -1) {
          continue;
        }

        // FIXME: tried to make this more selective, but got a weird jumping.
        recomputeRowHeights();
      }
    }

    onStateEvent(StateEvents.Set, handleSet);
    return () => {
      offStateEvent(StateEvents.Set, handleSet);
    };
  });
  return null;
}
