import { groupBy } from 'lodash';
import { useCustomEmojis } from '../syncEngine/selectors/organizations';
import emojiData from './allEmojis.json';
import { codePoints } from './text';
// couple of emojis look terrible so we filter 'em out
const toSkip = ['relaxed', 'white_frowning_face'];

export const allEmojis = emojiData.filter(e => !toSkip.includes(e.id));
export const allEmojisByCategory = groupBy(
  allEmojis.filter(e => e.category !== 'Component'),
  'category'
);

export const emojiCategories = [
  'Smileys & People',
  'Animals & Nature',
  'Food & Drink',
  'Travel & Places',
  'Activities',
  'Objects',
  'Symbols',
  'Flags',
];

export interface Emoji {
  id: string;
  value: string;
  unicode: string;
  codePoints: number[];
  name?: string;
}

export const allEmojisById: Map<string, Emoji> = new Map();
for (const emoji of allEmojis) {
  allEmojisById.set(emoji.id, emoji);
}

const byCodePoints: Map<string, Emoji> = new Map();
for (const emoji of allEmojis) {
  byCodePoints.set(emoji.codePoints.join(','), emoji);
}

export function isEmoji(maybeEmoji: string): boolean {
  const points = codePoints(maybeEmoji);
  return byCodePoints.has(points.join(','));
}

export function emojify(toEmojify: string): string {
  let result = toEmojify;

  const emojiRegex = /:([a-z0-9_\-+])+:/g;
  const matches = toEmojify.match(emojiRegex) ?? [];

  for (const match of matches) {
    const emojiId = match.replace(/:/g, '');
    const emoji = allEmojisById.get(emojiId);

    if (emoji) {
      result = result.replace(match, emoji.unicode);
    }
  }

  return result;
}

export function useEmojifyMarkdown() {
  const customEmojis = useCustomEmojis();
  return (toEmojify: string): string => {
    let result = toEmojify;

    const emojiRegex = /:([a-z0-9_\-+])+:/g;
    const matches = toEmojify.match(emojiRegex) ?? [];

    for (const match of matches) {
      const emojiId = match.replace(/:/g, '');
      const emoji = allEmojisById.get(emojiId);

      if (emoji) {
        result = result.replace(match, emoji.unicode);
      } else if (customEmojis[emojiId]) {
        result = result.replace(match, `![emoji:${emojiId}](${customEmojis[emojiId].url})`);
      }
    }

    return result;
  };
}

export const emojiShortcuts: { [index: string]: string } = {
  '<3': 'heart',
  '</3': 'broken_heart',
  '8)': 'sunglasses',
  'D:': 'anguished',
  ':|': 'neutral_face',
  ':o)': 'monkey_face',
  '=)': 'smiley',
  '=-)': 'smiley',
  ':D': 'smile',
  ':-D': 'smile',
  ';)': 'wink',
  ';-)': 'wink',
  ':>': 'laughing',
  ':->': 'laughing',
  ':o': 'open_mouth',
  ':-o': 'open_mouth',
  '>:(': 'angry',
  '>:-(': 'angry',
  ':)': 'slightly_smiling_face',
  '(:': 'slightly_smiling_face',
  ':-)': 'slightly_smiling_face',
  ':(': 'disappointed',
  '):': 'disappointed',
  ':-(': 'disappointed',
  ':/': 'confused',
  ':-/': 'confused',
  ':\\': 'confused',
  ':-\\': 'confused',
  ':p': 'stuck_out_tongue',
  ':-p': 'stuck_out_tongue',
  ':-b': 'stuck_out_tongue',
  ':b': 'stuck_out_tongue',
  ';p': 'stuck_out_tongue_winking_eye',
  ';-p': 'stuck_out_tongue_winking_eye',
  ';-b': 'stuck_out_tongue_winking_eye',
  ';b': 'stuck_out_tongue_winking_eye',
  ':*': 'kissing_heart',
  ':-*': 'kissing_heart',
};
