import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../shared/utils/terms';
import { Issue } from '../../sync/__generated/models';
import { useConfiguration } from '../contexts/configurationContext';
import { useConfirmation } from '../contexts/confirmationContext';
import { useOrganization } from '../contexts/organizationContext';
import { useSpace } from '../contexts/spaceContext';
import { useUpdateBoards } from '../syncEngine/actions/boards';
import { useUpdateIssues } from '../syncEngine/actions/issues';
import { useUpdateRoadmaps } from '../syncEngine/actions/roadmaps';
import { useUpdateSpaces } from '../syncEngine/actions/spaces';
import { boardSelector } from '../syncEngine/selectors/boards';
import { useCopyPublicIssueLink } from '../syncEngine/selectors/issues';
import { organizationPath } from '../syncEngine/selectors/organizations';
import { roadmapSelector } from '../syncEngine/selectors/roadmaps';
import { writeToClipboard } from './clipboardText';
import { Button, ButtonStyle } from './new/button';
import Pill, { PillStyle } from './new/metadata/pill';
import { MetadataSize } from './new/metadata/size';
import { Setting } from './new/settings';
import { Switch } from './new/switch';
import styles from './sharing.module.scss';

export function WorkItemSharingPopover({ issue }: { issue: Issue }) {
  const updateIssues = useUpdateIssues();
  const copyPublicLink = useCopyPublicIssueLink();
  const { confirm } = useConfirmation();

  return (
    <div>
      <div className={styles.header}>
        <span className="headingS semiBold">Public sharing</span>
        <Pill color="blue" pillStyle={PillStyle.Primary} size={MetadataSize.Small} noHover textOnly>
          Shared
        </Pill>
      </div>
      <div className={styles.content}>
        <div className="grayed mb12">
          This {issueTerm} is <span className="semiBold">public</span> and anyone with a link can
          view it.
        </div>
        <div className="row mb24">
          <Button
            onClick={() => copyPublicLink(issue.id)}
            buttonStyle={ButtonStyle.Secondary}
            className="mr12"
            icon="link"
          >
            Copy public link
          </Button>
          <Button
            onClick={async () => {
              const confirmed = await confirm(
                `Are you sure you want to make this ${issueTerm} private?`,
                `Once made private, users outside of your organization will no longer be able to acceess this ${issueTerm}`,
                { label: 'Make private', destructive: true }
              );
              if (!confirmed) {
                return;
              }
              updateIssues([issue.id], { public: false });
            }}
            buttonStyle={ButtonStyle.Secondary}
            style={{ color: 'var(--red10)' }}
          >
            Make private
          </Button>
        </div>
        <Setting
          title={'Share metadata'}
          description="Make metadata visible (e.g. status, labels and members)"
        >
          <Switch
            checked={issue.publicMetadata}
            onChange={() => updateIssues([issue.id], { publicMetadata: !issue.publicMetadata })}
          />
        </Setting>
      </div>
    </div>
  );
}

export function RoadmapSharingPopover() {
  const organization = useOrganization();
  const space = useSpace();
  const updateSpaces = useUpdateSpaces();
  const { confirm } = useConfirmation();
  const { host } = useConfiguration();
  const paidPlan = !!organization.activeProductId;
  const history = useHistory();

  const copyPublicLink = React.useCallback(
    () => writeToClipboard(`${host}/sharing/roadmap/${space.id}`, 'public link to roadmap'),
    [host, space.id]
  );

  if (!space.publicRoadmap && paidPlan) {
    return (
      <div>
        <div className={styles.header}>
          <span className="headingS semiBold">Public sharing</span>
        </div>
        <div className={styles.content}>
          <div className="grayed mb12">
            Share this roadmap publicly, so anyone with a link can view it.
          </div>
          <Button
            onClick={() => {
              updateSpaces([space.id], { publicRoadmap: true });
              copyPublicLink();
            }}
            buttonStyle={ButtonStyle.Primary}
          >
            Make public
          </Button>
        </div>
      </div>
    );
  }

  if (!paidPlan) {
    return (
      <div>
        <div className={styles.header}>
          <span className="headingS semiBold">Public sharing</span>
        </div>
        <div className={styles.content}>
          <div className="grayed mb12">Publicly shared roadmaps are a paid feature.</div>
          <Button
            onClick={() => {
              history.push(organizationPath(organization, 'settings/billing'));
            }}
            buttonStyle={ButtonStyle.Secondary}
            icon="upgrade"
          >
            Upgrade your plan
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <span className="headingS semiBold">Public sharing</span>
        <Pill color="blue" pillStyle={PillStyle.Primary} size={MetadataSize.Small} noHover textOnly>
          Shared
        </Pill>
      </div>
      <div className={styles.content}>
        <div className="grayed mb12">
          This roadmap is <span className="semiBold">public</span> and anyone with a link can view
          it.
        </div>
        <div className="row mb24">
          <Button
            onClick={copyPublicLink}
            buttonStyle={ButtonStyle.Secondary}
            className="mr12"
            icon="link"
          >
            Copy public link
          </Button>
          <Button
            onClick={async () => {
              const confirmed = await confirm(
                `Are you sure you want to make this roadmap private?`,
                `Once made private, users outside of your organization will no longer be able to acceess this roadmap`,
                {
                  label: 'Make private',
                  destructive: true,
                }
              );
              if (!confirmed) {
                return;
              }
              updateSpaces([space.id], { publicRoadmap: false });
            }}
            buttonStyle={ButtonStyle.Secondary}
            style={{ color: 'var(--red10)' }}
          >
            Make private
          </Button>
        </div>
        <Setting
          title={'Share metadata'}
          description="Make metadata visible (e.g. labels and members)"
        >
          <Switch
            checked={space.publicRoadmapMetadata}
            onChange={() =>
              updateSpaces([space.id], { publicRoadmapMetadata: !space.publicRoadmapMetadata })
            }
          />
        </Setting>
      </div>
    </div>
  );
}

export function NewRoadmapSharingPopover({ roadmapId }: { roadmapId: string }) {
  const organization = useOrganization();
  const { confirm } = useConfirmation();
  const { host } = useConfiguration();
  const paidPlan = !!organization.activeProductId;
  const history = useHistory();
  const roadmap = useRecoilValue(roadmapSelector(roadmapId));
  const updateRoadmaps = useUpdateRoadmaps();

  const copyPublicLink = React.useCallback(
    () => writeToClipboard(`${host}/sharing/roadmaps/${roadmap?.id}`, 'public link to roadmap'),
    [host, roadmap?.id]
  );

  if (!roadmap) {
    return null;
  }

  if (!roadmap.shared && paidPlan) {
    return (
      <div>
        <div className={styles.header}>
          <span className="headingS semiBold">Public sharing</span>
        </div>
        <div className={styles.content}>
          <div className="grayed mb12">
            Share this roadmap publicly, so anyone with a link can view it.
          </div>
          <Button
            onClick={() => {
              updateRoadmaps([roadmap.id], { shared: true });
              copyPublicLink();
            }}
            buttonStyle={ButtonStyle.Primary}
          >
            Make public
          </Button>
        </div>
      </div>
    );
  }

  if (!paidPlan) {
    return (
      <div>
        <div className={styles.header}>
          <span className="headingS semiBold">Public sharing</span>
        </div>
        <div className={styles.content}>
          <div className="grayed mb12">Publicly shared roadmaps is a paid feature.</div>
          <Button
            onClick={() => {
              history.push(organizationPath(organization, 'settings/billing'));
            }}
            buttonStyle={ButtonStyle.Secondary}
            icon="upgrade"
          >
            Upgrade your plan
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <span className="headingS semiBold">Public sharing</span>
        <Pill color="blue" pillStyle={PillStyle.Primary} size={MetadataSize.Small} noHover textOnly>
          Shared
        </Pill>
      </div>
      <div className={styles.content}>
        <div className="grayed mb12">
          This roadmap is <span className="semiBold">public</span> and anyone with a link can view
          it.
        </div>
        <div className="row mb24">
          <Button
            onClick={copyPublicLink}
            buttonStyle={ButtonStyle.Secondary}
            className="mr12"
            icon="link"
          >
            Copy public link
          </Button>
          <Button
            onClick={async () => {
              const confirmed = await confirm(
                `Are you sure you want to make this roadmap non-public?`,
                `Once made non-public, users outside of your organization will no longer be able to acceess this roadmap`,
                {
                  label: 'Make non-public',
                  destructive: true,
                }
              );
              if (!confirmed) {
                return;
              }
              updateRoadmaps([roadmap.id], { shared: false });
            }}
            buttonStyle={ButtonStyle.Secondary}
            style={{ color: 'var(--red10)' }}
          >
            Make private
          </Button>
        </div>
        <Setting
          title={'Share metadata'}
          description="Make metadata visible (e.g. labels and members)"
        >
          <Switch
            checked={roadmap.sharedMetadata}
            onChange={() =>
              updateRoadmaps([roadmap.id], { sharedMetadata: !roadmap.sharedMetadata })
            }
          />
        </Setting>
      </div>
    </div>
  );
}

export function BoardSharingPopover({ boardId }: { boardId: string }) {
  const organization = useOrganization();
  const board = useRecoilValue(boardSelector(boardId));
  const updateBoards = useUpdateBoards();
  const { confirm } = useConfirmation();
  const { host } = useConfiguration();
  const paidPlan = !!organization.activeProductId;
  const history = useHistory();

  const copyPublicLink = React.useCallback(
    () => writeToClipboard(`${host}/sharing/board/${board?.id}`, 'public link to board'),
    [host, board?.id]
  );

  if (!board) {
    return null;
  }

  if (!board.shared && paidPlan) {
    return (
      <div>
        <div className={styles.header}>
          <span className="headingS semiBold">Public sharing</span>
        </div>
        <div className={styles.content}>
          <div className="grayed mb12">
            Share this board publicly, so anyone with a link can view it.
          </div>
          <Button
            onClick={() => {
              updateBoards([board.id], { shared: true });
              copyPublicLink();
            }}
            buttonStyle={ButtonStyle.Primary}
          >
            Make public
          </Button>
        </div>
      </div>
    );
  }

  if (!paidPlan) {
    return (
      <div>
        <div className={styles.header}>
          <span className="headingS semiBold">Public sharing</span>
        </div>
        <div className={styles.content}>
          <div className="grayed mb12">Publicly shared boards are a paid feature.</div>
          <Button
            onClick={() => {
              history.push(organizationPath(organization, 'settings/billing'));
            }}
            buttonStyle={ButtonStyle.Secondary}
            icon="upgrade"
          >
            Upgrade your plan
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.header}>
        <span className="headingS semiBold">Public sharing</span>
        <Pill color="blue" pillStyle={PillStyle.Primary} size={MetadataSize.Small} noHover textOnly>
          Shared
        </Pill>
      </div>
      <div className={styles.content}>
        <div className="grayed mb12">
          This board is <span className="semiBold">public</span> and anyone with a link can view it.
        </div>
        <div className="row mb24">
          <Button
            onClick={copyPublicLink}
            buttonStyle={ButtonStyle.Secondary}
            className="mr12"
            icon="link"
          >
            Copy public link
          </Button>
          <Button
            onClick={async () => {
              const confirmed = await confirm(
                `Are you sure you want to make this board private?`,
                `Once made private, users outside of your organization will no longer be able to acceess this board`,
                {
                  label: 'Make private',
                  destructive: true,
                }
              );
              if (!confirmed) {
                return;
              }
              updateBoards([board.id], { shared: false });
            }}
            buttonStyle={ButtonStyle.Secondary}
            style={{ color: 'var(--red10)' }}
          >
            Make private
          </Button>
        </div>
        <Setting
          title={'Share metadata'}
          description="Make metadata visible (e.g. labels and members)"
          smallSpacing
        >
          {/* FIXME: why do i need to include everything here? */}
          <Switch
            checked={board.sharedMetadata}
            onChange={() =>
              updateBoards([board.id], {
                sharedMetadata: !board.sharedMetadata,
                shared: true,
                sharedWorkItems: board.sharedWorkItems,
              })
            }
          />
        </Setting>
        <Setting
          title={'Share all work items'}
          description="Make all work items on the board publicly accessible"
        >
          {/* FIXME: why do i need to include everything here? */}
          <Switch
            checked={board.sharedWorkItems}
            onChange={() => {
              updateBoards([board.id], {
                sharedWorkItems: !board.sharedWorkItems,
                shared: true,
                sharedMetadata: board.sharedMetadata,
              });
            }}
          />
        </Setting>
      </div>
    </div>
  );
}
