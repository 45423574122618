import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { addExistingKey } from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  RoadmapCommandGroupContext,
  useCommandMenuContext,
} from './state';

export function useRoadmapCommandGroup(): CommandDefinition[] {
  const modals = useModals();

  const commandContext = useCommandMenuContext<RoadmapCommandGroupContext>(CommandGroup.Roadmap);

  if (!commandContext) {
    return [];
  }

  const { roadmapId, columnId, spaceId } = commandContext;

  const commands: CommandDefinition[] = [
    {
      id: 'roadmap-add-initiative',
      hotkey: addExistingKey,
      description: `Add initiatives to roadmap`,
      icon: 'add',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.RoadmapInitiatives,
          context: { roadmapId, columnId, spaceId },
        });
      },
    },
  ];

  return commands.map(c => ({
    ...c,
    group: c.group ?? CommandGroup.Roadmap,
  }));
}
