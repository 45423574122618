import * as React from 'react';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useConfiguration } from '../../contexts/configurationContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerEvent } from '../../tracker';

export function OpenInAppScreen({ onOverride }: { onOverride: () => void }) {
  const { electronScheme } = useConfiguration();

  useComponentDidMount(() => {
    trackerEvent('Universal Link Opened');
    window.location.href = window.location.href.replace('https://', `${electronScheme}://`);
  });

  return (
    <AuxiliaryScreen verticalCenter>
      <TitleSetter title="Kitemaker · Open in app" />
      <div className="form textCenter">
        <Logo className="mb32" />
        <div className="grayed bodyM oneLine">
          The link was opened in the Kitemaker desktop app.
        </div>
        <Button
          buttonStyle={ButtonStyle.Secondary}
          size={ButtonSize.Large}
          className="mt16 mb32"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            trackerEvent('Universal Link Overriden');
            onOverride();
          }}
        >
          Open here instead
        </Button>
        <Button
          buttonStyle={ButtonStyle.BareSubtle}
          icon="settings"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            const orgSlug = window.location.pathname.split('/').filter(s => !!s);
            if (orgSlug) {
              onOverride();
              window.location.href = `/${orgSlug}/settings/preferences`;
            }
          }}
        >
          Configure link opening preferences
        </Button>
      </div>
    </AuxiliaryScreen>
  );
}
