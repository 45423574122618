import debugModule from 'debug';
import { cloneDeep } from 'lodash';
import {
  InsertNodeOperation,
  MergeNodeOperation,
  MoveNodeOperation,
  Operation,
  Path,
  Point,
  RemoveNodeOperation,
  SetNodeOperation,
  SplitNodeOperation,
  TextOperation,
} from 'slate';

const debug = debugModule('operationalTransforms');

function PathTransformWrapper(p: Path, t: Operation): Path | null {
  return Path.transform(p, t);
}

function overlapLeft(
  a_start: number,
  a_length: number,
  b_start: number,
  b_length: number
): boolean {
  if (
    a_start < b_start &&
    a_start + a_length > b_start &&
    a_start + a_length < b_start + b_length
  ) {
    return true;
  }

  return false;
}

function overlapRight(
  a_start: number,
  a_length: number,
  b_start: number,
  b_length: number
): boolean {
  if (
    a_start > b_start &&
    a_start < b_start + b_length &&
    a_start + a_length >= b_start + b_length
  ) {
    return true; // a overapls right part of b
  }
  return false;
}

function contains(a_start: number, a_length: number, b_start: number, b_length: number): boolean {
  if (a_start >= b_start && a_start + a_length <= b_start + b_length) {
    return true;
  } // a contained in b

  return false;
}

function isOverlapping(
  a_start: number,
  a_length: number,
  b_start: number,
  b_length: number
): boolean {
  if (overlapLeft(a_start, a_length, b_start, b_length)) {
    return true; // a overlaps left part of b
  } else if (overlapRight(a_start, a_length, b_start, b_length)) {
    return true; // a overapls right part of b
  } else if (contains(a_start, a_length, b_start, b_length)) {
    return true; // a contained in b
  } else if (contains(b_start, b_length, a_start, a_length)) {
    return true; // b contained in a
  } else {
    return false;
  }
}

export function textTransform(
  op: TextOperation,
  trans: Operation,
  winTiebreakers: boolean
): Operation[] {
  const opt = cloneDeep(op);

  if (Operation.isTextOperation(trans)) {
    if (Operation.isTextOperation(op) && Path.equals(op.path, trans.path)) {
      if (trans.type === 'insert_text') {
        if (op.type === 'insert_text' || op.type === 'remove_text') {
          if (op.offset > trans.offset) {
            opt.offset = op.offset + trans.text.length;
          } else if (op.offset === trans.offset && op.type === 'insert_text' && !winTiebreakers) {
            // If we insert at the same point, the client with the lowest id has presedence.
            opt.offset = op.offset + trans.text.length;
          } else if (
            op.type === 'remove_text' &&
            op.offset !== trans.offset &&
            trans.offset < op.offset + op.text.length
          ) {
            opt.text =
              op.text.slice(0, trans.offset - op.offset) +
              trans.text +
              op.text.slice(trans.offset - op.offset);
          } else if (op.offset === trans.offset && op.type === 'remove_text') {
            opt.offset = op.offset + trans.text.length;
          }
        }
      } else if (trans.type === 'remove_text') {
        if (
          op.type === 'remove_text' &&
          isOverlapping(op.offset, op.text.length, trans.offset, trans.text.length)
        ) {
          // two overlapping remove_text nodes

          if (overlapLeft(op.offset, op.text.length, trans.offset, trans.text.length)) {
            opt.text = op.text.slice(0, trans.offset - op.offset);
          } else if (overlapRight(op.offset, op.text.length, trans.offset, trans.text.length)) {
            opt.offset = trans.offset;
            opt.text = op.text.slice(trans.offset - op.offset + trans.text.length);
          } else if (contains(op.offset, op.text.length, trans.offset, trans.text.length)) {
            return [];
          } else if (contains(trans.offset, trans.text.length, op.offset, op.text.length)) {
            // trans contained in op then remove trans part
            opt.text =
              op.text.slice(0, trans.offset - op.offset) +
              op.text.slice(trans.offset - op.offset + trans.text.length);
          } else {
            // Should not be possible to get here..
            debug('OperationalTransforms.textTransform: This should never happen...', {
              op,
              trans,
              winTiebreakers,
            });
          }
        } else if (
          (op.type === 'insert_text' || op.type === 'remove_text') &&
          op.offset > trans.offset
        ) {
          if (op.offset > trans.offset) {
            if (op.offset > trans.offset + trans.text.length - 1) {
              opt.offset = op.offset - trans.text.length;
            } else {
              return [];
            }
          }
        }
      }
    }
  } else if (Operation.isNodeOperation(trans)) {
    if (trans.type === 'split_node' && Path.equals(trans.path, op.path)) {
      if (
        op.type === 'remove_text' &&
        op.offset < trans.position &&
        trans.position < op.offset + op.text.length
      ) {
        const ret: Operation[] = [];

        // Split in the middle of a remove text
        const opt1 = cloneDeep(op);
        opt1.text = op.text.slice(0, trans.position - op.offset);
        ret.push(opt1);

        opt.offset = 0;
        opt.text = op.text.slice(trans.position - op.offset);
        opt.path = PathTransformWrapper(op.path, trans)!; // split_node doesn't remove nodes

        ret.push(opt);

        return ret;
      } else if (trans.position > op.offset) {
        // Split is after insertion or deletion
        return [opt];
      } else {
        opt.path = Path.transform(op.path, trans)!;
        opt.offset -= trans.position;
      }
    } else if (trans.type === 'merge_node' && Path.equals(trans.path, op.path)) {
      opt.path = Path.transform(op.path, trans)!;
      opt.offset += trans.position;
    } else {
      const path = Path.transform(op.path, trans);
      if (path === null) {
        // Node was deleted
        return [];
      } else {
        // Node was transformed
        opt.path = path;
      }
    }
  }

  return [opt];
}

export function splitNode(op: SplitNodeOperation, trans: Operation): Operation[] {
  const opt = cloneDeep(op);

  if (Operation.isTextOperation(trans) && Path.equals(op.path, trans.path)) {
    if (trans.type === 'insert_text' && op.position > trans.offset) {
      opt.position = op.position + trans.text.length;
    } else if (trans.type === 'remove_text') {
      if (op.position > trans.offset && op.position >= trans.offset + trans.text.length) {
        opt.position = op.position - trans.text.length;
      } else if (op.position > trans.offset) {
        opt.position = trans.offset;
      }
    }
  } else if (Operation.isNodeOperation(trans)) {
    // If same operation, we do notiong.
    if (
      op.type === 'split_node' &&
      trans.type === 'split_node' &&
      Path.equals(op.path, trans.path) &&
      op.position === trans.position
    ) {
      return [];
    } else if (
      trans.type === 'split_node' &&
      Path.isAncestor(op.path, trans.path) &&
      op.path.length === trans.path.length - 1 &&
      op.position > trans.path[op.path.length]
    ) {
      opt.position = op.position + 1;
    } else if (
      trans.type === 'merge_node' &&
      Path.isParent(op.path, trans.path) &&
      trans.path[op.path.length] < op.position
    ) {
      opt.position = op.position - 1;
    } else if (
      trans.type === 'move_node' &&
      Path.isParent(op.path, trans.path) &&
      trans.path[op.path.length] < op.position
    ) {
      opt.position = op.position - 1;
    } else if (Path.equals(op.path, trans.path)) {
      const p: Point = { path: op.path, offset: op.position };

      const res = Point.transform(p, trans);

      if (res === null) {
        return [];
      }

      opt.path = res.path;
      opt.position = res.offset;
    } else {
      const p = Path.transform(op.path, trans);
      if (p === null) {
        // Node was deleted
        return [];
      } else {
        opt.path = p;
      }
    }
  }

  return [opt];
}

export function mergeNode(op: MergeNodeOperation, trans: Operation): Operation[] {
  const opt: Operation = cloneDeep(op);

  if (Operation.isTextOperation(trans) && Path.equals(Path.previous(op.path), trans.path)) {
    // Merge node has a reference to the node being "merged into", so we need to check if that changes.
    const p: Point = { path: Path.previous(op.path), offset: op.position }; // This only works for text nodes...
    const res = Point.transform(p, trans)!;

    opt.position = res.offset;
  } else if (trans.type === 'merge_node' && Path.equals(op.path, trans.path)) {
    // Same operation exists, do nothing.
    return [];
  } else if (trans.type === 'merge_node' && Path.equals(Path.previous(op.path), trans.path)) {
    opt.path = Path.transform(op.path, trans)!;
    opt.position = op.position + 1;
  } else if (trans.type === 'split_node' && Path.equals(Path.previous(op.path), trans.path)) {
    opt.path = Path.transform(op.path, trans)!;
    opt.position = op.position - trans.position;
  } else if (Operation.isNodeOperation(trans)) {
    // Fix the positon op opt.merge (NOTE: This in untested!!!)
    const transParent = Path.parent(trans.path);
    if (Path.equals(Path.previous(op.path), transParent)) {
      if (trans.type === 'insert_node' || trans.type === 'split_node') {
        opt.position += 1;
      }

      if (
        trans.type === 'remove_node' ||
        trans.type === 'merge_node' ||
        trans.type === 'move_node'
      ) {
        opt.position -= 1;
      }
    }

    if (trans.type === 'move_node') {
      const transNewPathParent = Path.parent(trans.newPath);
      if (Path.equals(Path.previous(op.path), transNewPathParent)) {
        opt.position += 1;
      }
    }
    // Handle transform
    if (trans.type !== 'split_node' || !Path.equals(op.path, trans.path)) {
      // Note, merge path is special in that is always merges the "leftover" node for s plit.

      const p = PathTransformWrapper(op.path, trans);

      if (Path.isPath(p)) {
        opt.path = p;

        // Merge requires the previous sibling to be the same, so check if that changes address. If it does, move the node before merging.
        const newSiblingPath = PathTransformWrapper(Path.previous(op.path), trans);

        if (!newSiblingPath) {
          if (trans.type === 'remove_node') {
            const ret: Operation[] = [];
            // The previous node was deleted 🤯
            // Merge trumps remove, so we'll insert the node again
            const insertOperation: InsertNodeOperation = {
              type: 'insert_node',
              path: Path.previous(op.path),
              node: trans.node,
            };
            ret.push(insertOperation);
            opt.path = op.path;
            ret.push(opt);
            return ret;
          } else {
            // This should be impossible, but let's handle it anyway
            debug('OperationalTransforms.mergeNode: This should never happen...', {
              op,
              trans,
            });
            return [];
          }
        } else if (
          opt.path[opt.path.length - 1] === 0 ||
          !Path.equals(newSiblingPath, Path.previous(opt.path))
        ) {
          // The node we're merging into has moved, and we need to move the node before merging.
          const ret: Operation[] = [];

          let moveOperation: MoveNodeOperation;

          if (Path.isSibling(opt.path, newSiblingPath) && Path.isBefore(opt.path, newSiblingPath)) {
            moveOperation = {
              type: 'move_node',
              path: opt.path,
              newPath: newSiblingPath,
            };
          } else {
            moveOperation = {
              type: 'move_node',
              path: opt.path,
              newPath: Path.next(newSiblingPath),
            };
          }

          opt.path = PathTransformWrapper(opt.path, moveOperation)!;

          if (!Path.equals(moveOperation.path, moveOperation.newPath)) {
            ret.push(moveOperation);
          } else {
            debug('OperationalTransforms.mergeNode: Sigurd said 🤔', {
              op,
              trans,
              opt,
            });
          }

          ret.push(opt);

          if (Path.isPath(opt.path) && opt.path[opt.path.length - 1] === 0) {
            debug('OperationalTransforms.mergeNode: Invalid merge', {
              op,
              trans,
            });
          }

          return ret;
        }
      } else {
        debug('OperationalTransforms.mergeNode: Couldnt transform merge_node', {
          op,
          trans,
          opt,
        });

        // The merging node was deleted 🤯
        if (trans.type === 'remove_node') {
          // The previous node was deleted 🤯
          const ret: Operation[] = [];
          // Merge trumps remove, so we'll insert the node again
          const insertOperation: InsertNodeOperation = {
            type: 'insert_node',
            path: op.path,
            node: trans.node,
          };
          ret.push(insertOperation);

          opt.path = op.path;
          ret.push(opt);
          return ret;
        } else {
          // This should be impossible, but let's handle it anyway
          debug('OperationalTransforms.mergeNode: Sibling removed, but no remove_node', {
            op,
            trans,
            opt,
          });

          return [];
        }
      }
    }
  }

  return [opt];
}

export function moveNode(
  op: MoveNodeOperation,
  trans: Operation,
  winTiebreakers: boolean
): Operation[] {
  if (trans.type === 'split_node' && Path.equals(op.path, trans.path)) {
    const opt1 = cloneDeep(op);
    opt1.newPath = Path.transform(op.newPath, trans)!;

    const opt2 = cloneDeep(op);
    opt2.path = Path.transform(Path.next(opt1.path), opt1)!;
    opt2.newPath = Path.transform(Path.next(opt1.newPath), opt1)!; // Note: double check this

    return [opt1, opt2];
  }

  if (trans.type === 'split_node' && Path.equals(op.newPath, trans.path)) {
    const opt = cloneDeep(op);
    if (op.path.length === op.newPath.length && Path.isBefore(op.path, op.newPath)) {
      opt.newPath = Path.next(op.newPath);
    } else {
      opt.path = Path.transform(op.path, trans)!;
    }

    return [opt];
  }

  //if( trans.type === 'merge_node' && (Path.equals(op.path, trans.path) || (Path.isBefore(op.path, op.newPath) && Path.equals(trans.path, op.newPath))) ) {
  if (
    trans.type === 'merge_node' &&
    (Path.equals(op.path, trans.path) ||
      (op.newPath[op.newPath.length - 1] !== 0 &&
        Path.equals(op.path, Path.previous(op.newPath)) &&
        Path.equals(trans.path, op.newPath)))
  ) {
    //merge_node always forces to have the merging node next to the correct sibling.
    debug('OperationalTransforms.moveNode: Cancellation', {
      op,
      trans,
    });
    return [];
  }

  function isSwap(operation: Operation): boolean {
    if (operation.type === 'move_node') {
      if (
        operation.path[operation.path.length - 1] !== 0 &&
        Path.equals(Path.previous(operation.path), operation.newPath)
      ) {
        return true;
      }
      if (
        operation.newPath[operation.newPath.length - 1] !== 0 &&
        Path.equals(operation.path, Path.previous(operation.newPath))
      ) {
        return true;
      }
    }

    return false;
  }

  function isIntersectingSwaps(op1: Operation, op2: Operation): boolean {
    if (isSwap(op1) && isSwap(op2) && op1.type === 'move_node' && op2.type === 'move_node') {
      if (
        Path.equals(op1.path, op2.path) ||
        Path.equals(op1.path, op2.newPath) ||
        Path.equals(op1.newPath, op2.path) ||
        Path.equals(op1.newPath, op.newPath)
      ) {
        return true;
      }
    }
    return false;
  }

  if (trans.type === 'move_node' && isIntersectingSwaps(op, trans)) {
    const isFirst = Path.isBefore(op.path, trans.path) || Path.isBefore(op.path, trans.newPath);
    if (isFirst) {
      debug('OperationalTransforms.moveNode: First', op);
      const opt = cloneDeep(op);

      opt.path = Path.isBefore(trans.path, trans.newPath) ? trans.newPath : trans.path;
      opt.newPath = Path.isBefore(op.path, op.newPath) ? op.path : op.newPath;

      return [opt];
    } else {
      debug('OperationalTransforms.moveNode: Last', op);
      const opt = cloneDeep(op);

      opt.path = Path.isBefore(op.path, op.newPath) ? op.path : op.newPath;
      opt.newPath = Path.isBefore(trans.path, trans.newPath) ? trans.newPath : trans.path;

      return [opt];
    }
  }

  if (trans.type === 'move_node' && Path.equals(trans.newPath, op.newPath)) {
    if (Path.equals(trans.path, op.path)) {
      // Same operation, do notion.
      debug('OperationalTransforms.moveNode: Same operation, do nothing', { op, trans });
      return [];
    }

    if (winTiebreakers) {
      // op client has presedence and can insert into newPath
      const opt = cloneDeep(op);
      opt.path = PathTransformWrapper(op.path, trans)!;
      return [opt];
    }
  }

  /* if( trans.type === 'move_node' && op.path[op.path.length-1] !== 0 && Path.equals(Path.previous(op.path), op.newPath) && Path.equals(trans.path, op.newPath))  {
        return [];
    } */

  if (trans.type === 'move_node' && Path.equals(trans.path, op.path)) {
    // Notice that same operations are handled above.
    if (winTiebreakers) {
      const opt = cloneDeep(op);
      opt.path = PathTransformWrapper(op.path, trans)!;
      return [opt];
    } else {
      debug('OperationalTransforms.moveNode: P0');
      return [];
    }
  }

  if (Operation.isNodeOperation(trans) && Path.equals(trans.path, op.newPath)) {
    // If trans affects the newPath node, we don't care. The destination is still valid, so convert only path
    const opt = cloneDeep(op);
    const path = PathTransformWrapper(op.path, trans);

    if (path && !Path.equals(path, opt.newPath)) {
      opt.path = path;
      return [opt];
    } else {
      debug('OperationalTransforms.moveNode: P1');
      return [];
    }
  }

  // convert path and newPath since both can have new paths
  const path = PathTransformWrapper(op.path, trans);
  if (path) {
    const opt = cloneDeep(op);

    opt.path = path;
    const newPath = PathTransformWrapper(op.newPath, trans);

    if (!newPath) {
      // This should be impossible due to we handle operations at newPath above.
      debug('OperationalTransforms.moveNode: New path not valid', opt);

      return [];
    }

    opt.newPath = newPath;

    if (Path.equals(opt.path, opt.newPath)) {
      // The move operation doesn't move anything anymore.
      debug('OperationalTransforms.moveNode: Created NOOP', opt);

      return [];
    }

    return [opt];
  }

  // If we're here, it's because the node was removed.
  debug('OperationalTransforms.moveNode: Nothing triggered, node was removed');

  return [];
}

export function insertNode(op: InsertNodeOperation, trans: Operation): Operation[] {
  if (
    (trans.type === 'merge_node' || trans.type === 'remove_node') &&
    Path.equals(op.path, trans.path)
  ) {
    return [cloneDeep(op)]; // if the node already there is removed, we don't care.
  }

  const newPath = PathTransformWrapper(op.path, trans);

  if (!newPath) {
    return [];
  }

  const opt = cloneDeep(op);
  opt.path = newPath;

  return [opt];
}

export function removeNode(op: RemoveNodeOperation, trans: Operation): Operation[] {
  if (trans.type === 'split_node' && Path.equals(op.path, trans.path)) {
    // If the node is split, set the property on both resulting nodes.
    const ret = [];
    ret.push(op);

    const opt = cloneDeep(op);
    opt.path = PathTransformWrapper(op.path, trans)!;

    ret.push(opt);

    return ret;
  }

  if (
    trans.type === 'merge_node' &&
    (Path.equals(op.path, trans.path) || Path.equals(op.path, Path.previous(trans.path)))
  ) {
    return [];
  }

  const newPath = PathTransformWrapper(op.path, trans);

  if (!newPath) {
    return [];
  }

  const opt = cloneDeep(op);
  opt.path = newPath;

  return [opt];
}

export function setNode(op: SetNodeOperation, trans: Operation): Operation[] {
  if (trans.type === 'split_node' && Path.equals(op.path, trans.path)) {
    // If the node is split, set the property on both resulting nodes.
    const ret = [];
    ret.push(op);

    const opt = cloneDeep(op);
    opt.path = PathTransformWrapper(op.path, trans)!;

    ret.push(opt);

    return ret;
  }

  const newPath = PathTransformWrapper(op.path, trans);

  if (!newPath) {
    return [];
  }

  const opt = cloneDeep(op);
  opt.path = newPath;

  return [opt];
}

export function generateOperation(
  ops: Operation[],
  trans: Operation,
  winTiebreakers: boolean
): Operation[] {
  return ops.flatMap(op => {
    if (Operation.isTextOperation(op)) {
      return textTransform(op, trans, winTiebreakers);
    } else if (op.type === 'split_node') {
      return splitNode(op, trans);
    } else if (op.type === 'merge_node') {
      return mergeNode(op, trans);
    } else if (op.type === 'move_node') {
      return moveNode(op, trans, winTiebreakers);
    } else if (op.type === 'insert_node') {
      return insertNode(op, trans);
    } else if (op.type === 'remove_node') {
      return removeNode(op, trans);
    } else if (op.type === 'set_node') {
      return setNode(op, trans);
    } else {
      throw new Error(`Unknown operation: ${op.type}`);
    }
  });
}

function transform(
  operation: Operation[],
  transform: Operation,
  winTiebreakers: boolean
): { transformedOperations: Operation[]; transformedTranforms: Operation[] } {
  if (!operation.length) {
    return { transformedOperations: [], transformedTranforms: [transform] };
  }

  const transformedOperations = generateOperation(operation, transform, winTiebreakers);
  const transformedTranforms = operation.reduce(
    (result, op) => generateOperation(result, op, !winTiebreakers),
    [transform]
  );

  return { transformedOperations, transformedTranforms };
}

export function transformAll(
  operations: Operation[],
  transformBy: Operation[],
  winTiebreakers: boolean
): Operation[] {
  debug('transformAll', operations, transformBy);

  const results: Operation[] = [];

  let transforms = [...transformBy];
  for (const operation of operations) {
    let transformedOp = [operation];
    const newTransforms: Operation[] = [];

    for (const trans of transforms) {
      const res = transform(transformedOp, trans, winTiebreakers);
      newTransforms.push(...res.transformedTranforms);
      transformedOp = res.transformedOperations;
    }

    transforms = newTransforms;
    results.push(...transformedOp);
  }

  return results;
}
