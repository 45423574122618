import { Node, Range, NodeEntry, Text } from 'slate';
import { KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { EditorType } from '../types';

export const hexColorRegex = /#[0-9a-fA-F]{6}/;
export const rgbaColorRegex = /rgba?\(\d+,\s?\d+,\s\d+,\s?0?\.\d+\)/;

export function colorPreviewDecorations(_editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [node, path] = entry;

  if (!Text.isText(node) || !path.length) {
    return [];
  }

  const text = KitemakerNode.safeString(node);
  let hashIndex = text.indexOf('#');
  const decorations: Range[] = [];

  while (hashIndex !== -1) {
    const maybeColor = text.substr(hashIndex, 7);
    if (maybeColor.match(hexColorRegex)) {
      const dec = {
        anchor: {
          path,
          offset: hashIndex,
        },
        focus: {
          offset: hashIndex + 7,
          path,
        },
        colorPreview: maybeColor,
      };
      decorations.push(dec);
    }

    hashIndex = text.indexOf('#', hashIndex + 1);
  }

  let rgbIndex = text.indexOf('rgb');
  while (rgbIndex !== -1) {
    const closedParen = text.indexOf(')', rgbIndex + 1);
    if (closedParen !== -1) {
      const maybeColor = text.substring(rgbIndex, closedParen + 1);
      if (maybeColor.match(rgbaColorRegex)) {
        const dec = {
          anchor: {
            path,
            offset: rgbIndex,
          },
          focus: {
            offset: closedParen + 1,
            path,
          },
          colorPreview: maybeColor,
        };
        decorations.push(dec);
      }
    }
    rgbIndex = text.indexOf('rgb', rgbIndex + 1);
  }

  return decorations;
}
