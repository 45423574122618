import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../../shared/utils/terms';
import {
  CodeReviewRequestState,
  Initiative as InitiativeModel,
  Release,
} from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { ReleaseStatusIndicator } from '../../screens/releaseScreen/releaseStatusIndicator';
import {
  useAddIssuesToInitiatives,
  useRemoveIssuesFromInitiatives,
} from '../../syncEngine/actions/intiatives';
import { useMoveIssues } from '../../syncEngine/actions/issues';
import {
  useAddIssuesToRelease,
  useRemoveIssuesFromRelease,
} from '../../syncEngine/actions/releases';
import { codeReviewsForIssueSelector } from '../../syncEngine/selectors/codeReviewRequests';
import {
  initiativeIdsForIssueSelector,
  initiativePath,
  initiativeSelector,
  initiativesForIssueSelector,
} from '../../syncEngine/selectors/intiatives';
import {
  issuePublicSelector,
  issueSelector,
  statusForIssueSelector,
} from '../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../syncEngine/selectors/issueStatuses';
import {
  releasePath,
  releaseSelector,
  releasesForIssueSelector,
} from '../../syncEngine/selectors/releases';
import { isDescendant } from '../../utils/dom';
import { EntityDueDate } from './dueDateMetadata';
import { Icon, IconSize } from './icon';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from './menu/dropdownMenu';
import CodeReviewRequest, { CodeReviewTooltipContents } from './metadata/codeReviewRequest';
import Initiative, { InitiativeIcon } from './metadata/initiative';
import { ReleaseMetadata } from './metadata/release';
import { MetadataSize } from './metadata/size';
import { InitiativePicker, workItemsToInitiativePickerState } from './pickers/initiativePicker';
import { issuesToReleasePickerState, ReleasePicker } from './pickers/releasePicker';
import {
  entitiesToStatusColumnPickerState,
  StatusColumnPicker,
} from './pickers/statusColumnPicker';
import { StatusIcon } from './statusIcon';
import { FollowTooltipContent, MetadataTooltip, Tooltip } from './tooltip';

export function WorkItemCodeReviewRequests({
  id,
  size,
  includeClosed,
  interactable,
}: {
  id: string;
  size?: MetadataSize;
  includeClosed?: boolean;
  interactable?: boolean;
}) {
  const codeReviews = useRecoilValue(codeReviewsForIssueSelector(id));
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement | null>(null);
  if (!codeReviews.length) {
    return null;
  }

  const hasOpen = codeReviews.find(review => review.state === CodeReviewRequestState.Open);
  const hasMerged = codeReviews.find(review => review.state === CodeReviewRequestState.Merged);

  if (!hasOpen && !hasMerged && !includeClosed) {
    return null;
  }

  let state = CodeReviewRequestState.Closed;
  if (hasMerged) {
    state = CodeReviewRequestState.Merged;
  }
  if (hasOpen) {
    state = CodeReviewRequestState.Open;
  }

  const content = (
    <div
      ref={ref}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
      }}
    >
      <CodeReviewRequest size={size} state={state} />
    </div>
  );

  if (!interactable) {
    return content;
  }

  return (
    <MetadataTooltip
      onPointerDownOutside={e => {
        if (
          ref.current &&
          e.target &&
          e.target instanceof Element &&
          isDescendant(ref.current, e.target)
        ) {
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      }}
      open={open}
      onOpenChange={setOpen}
      side="bottom"
      align="start"
      content={<CodeReviewTooltipContents codeReviews={codeReviews} />}
    >
      {content}
    </MetadataTooltip>
  );
}

export function WorkItemInitiativeTooltip({
  initiative: initiative,
}: {
  initiative: InitiativeModel;
}) {
  const organization = useOrganization();

  const link = {
    pathname: initiativePath(organization, initiative),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: initiative.id,
    },
  };

  return (
    <FollowTooltipContent link={link}>
      <InitiativeIcon size={IconSize.Size20} color={initiative.color} />
      <span className="ml6 bodyM">{initiative.title}</span>
    </FollowTooltipContent>
  );
}

export function WorkItemInitiative({
  id,
  issueId,
  interactable,
  iconOnly,
  className,
}: {
  id: string;
  issueId: string;
  interactable?: boolean;
  iconOnly?: boolean;
  className?: string;
}) {
  const initiative = useRecoilValue(initiativeSelector(id));
  const initiatives = useRecoilValue(initiativesForIssueSelector(issueId));
  const issue = useRecoilValue(issueSelector(issueId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [hoverOpen, setHoverOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addIssuesToInitiative = useAddIssuesToInitiatives();
  const removeIssuesFromInitiative = useRemoveIssuesFromInitiatives();

  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  const onDone = React.useCallback(() => {
    if (removeSelfRef.current && initiative && issue) {
      removeIssuesFromInitiative([initiative.id], [issue.id]);
    }
  }, [issue, initiative, removeIssuesFromInitiative]);

  const [removeSelf, setRemoveSelf] = React.useState(false);
  const removeSelfRef = React.useRef(false);

  if (!initiative || !issue) {
    return null;
  }

  const content = (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={cn({ invisible: removeSelf })}
    >
      <Initiative
        title={initiative.title}
        key={initiative.id}
        color={initiative.color}
        iconOnly={iconOnly}
      />
    </div>
  );

  if (!interactable) {
    return <div className={className}>{content}</div>;
  }

  const state = workItemsToInitiativePickerState([issue], initiatives);
  if (removeSelf) {
    state[issue.id] = state[issue.id].filter(t => t !== initiative.id);
  }

  return (
    <MetadataTooltip
      disabled={menuOpen}
      open={hoverOpen}
      side="bottom"
      align="start"
      className="followTooltip"
      onOpenChange={setHoverOpen}
      content={<WorkItemInitiativeTooltip initiative={initiative} />}
    >
      <div className={className}>
        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenuTrigger
            asChild
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div>{content}</div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="start"
            className="menuPicker menuHuge"
          >
            {issue && (
              <InitiativePicker
                state={state}
                onInitiativeAdded={(issueIds, initiativeId) => {
                  if (initiativeId === initiative.id) {
                    removeSelfRef.current = false;
                    setRemoveSelf(false);
                  } else {
                    addIssuesToInitiative([initiativeId], issueIds);
                  }
                }}
                onInitiativeRemoved={(issueIds, initiativeId) => {
                  if (initiativeId === initiative.id) {
                    removeSelfRef.current = true;
                    setRemoveSelf(true);
                  } else {
                    removeIssuesFromInitiative([initiativeId], issueIds);
                  }
                }}
                onDone={() => {
                  onDone();
                  closeMenu();
                }}
              />
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </MetadataTooltip>
  );
}

export function WorkItemInitiatives({
  id,
  interactable,
  iconOnly,
  ignoredInitiatives: ignoreInitiatives,
  className,
}: {
  id: string;
  interactable?: boolean;
  iconOnly?: boolean;
  ignoredInitiatives?: string[];
  className?: string;
}) {
  const initiatives = useRecoilValue(initiativeIdsForIssueSelector(id));
  let filteredInitiatives = initiatives;

  if (ignoreInitiatives) {
    filteredInitiatives = filteredInitiatives.filter(t => !ignoreInitiatives.includes(t));
  }

  return (
    <>
      {filteredInitiatives.map(initiativeId => (
        <WorkItemInitiative
          iconOnly={iconOnly}
          interactable={interactable}
          id={initiativeId}
          issueId={id}
          className={className}
          key={initiativeId}
        />
      ))}
    </>
  );
}

export function WorkItemReleaseTooltip({ release }: { release: Release }) {
  const organization = useOrganization();

  const link = {
    pathname: releasePath(organization, release),
    state: {
      backUrl: location.pathname,
      backSearch: location.search,
      entity: release.id,
    },
  };

  return (
    <FollowTooltipContent link={link}>
      <div className="row gap8">
        <Icon icon="release" size={IconSize.Size20} />
        <span className="ml6 bodyM">{release.title}</span>
        <ReleaseStatusIndicator release={release} />
        {release.dueDate && <EntityDueDate item={release} />}
      </div>
    </FollowTooltipContent>
  );
}

export function WorkItemRelease({
  id,
  issueId,
  iconOnly,
  interactable,
  size,
  className,
}: {
  id: string;
  issueId: string;
  iconOnly?: boolean;
  interactable?: boolean;
  size?: MetadataSize;
  className?: string;
}) {
  const release = useRecoilValue(releaseSelector(id));
  const releases = useRecoilValue(releasesForIssueSelector(issueId));
  const issue = useRecoilValue(issueSelector(issueId));
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [hoverOpen, setHoverOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const addIssuesToRelease = useAddIssuesToRelease();
  const removeIssuesFromRelease = useRemoveIssuesFromRelease();

  React.useEffect(() => {
    setHoverOpen(false);
  }, [menuOpen]);

  const onDone = React.useCallback(() => {
    if (removeSelfRef.current && release && issue) {
      removeIssuesFromRelease([release.id], [issue.id]);
    }
  }, [issue, release, removeIssuesFromRelease]);

  const [removeSelf, setRemoveSelf] = React.useState(false);
  const removeSelfRef = React.useRef(false);

  if (!release || !issue) {
    return null;
  }

  const content = (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={cn({ invisible: removeSelf })}
    >
      <ReleaseMetadata
        title={release.title}
        releaseStatus={release.releaseStatus}
        key={release.id}
        iconOnly={iconOnly}
        size={size}
      />
    </div>
  );

  if (!interactable) {
    return <div className={className}>{content}</div>;
  }

  const state = issuesToReleasePickerState([issue], releases);
  if (removeSelf) {
    state[issue.id] = state[issue.id].filter(t => t !== release.id);
  }

  return (
    <MetadataTooltip
      disabled={menuOpen}
      open={hoverOpen}
      side="bottom"
      align="start"
      className="followTooltip"
      onOpenChange={setHoverOpen}
      content={<WorkItemReleaseTooltip release={release} />}
    >
      <div className={className}>
        <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
          <DropdownMenuTrigger
            asChild
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div>{content}</div>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            onClick={e => {
              e.stopPropagation();
            }}
            side="bottom"
            align="start"
            className="menuPicker menuHuge"
          >
            {issue && (
              <ReleasePicker
                state={state}
                onReleaseAdded={(issueIds, releaseId) => {
                  if (releaseId === release.id) {
                    removeSelfRef.current = false;
                    setRemoveSelf(false);
                  } else {
                    addIssuesToRelease([releaseId], issueIds);
                  }
                }}
                onReleaseRemoved={(issueIds, releaseId) => {
                  if (releaseId === release.id) {
                    removeSelfRef.current = true;
                    setRemoveSelf(true);
                  } else {
                    removeIssuesFromRelease([releaseId], issueIds);
                  }
                }}
                onDone={() => {
                  onDone();
                  closeMenu();
                }}
              />
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </MetadataTooltip>
  );
}

export function WorkItemReleases({
  id,
  interactable,
  iconOnly,
  className,
}: {
  id: string;
  interactable?: boolean;
  iconOnly?: boolean;
  className?: string;
}) {
  const releases = useRecoilValue(releasesForIssueSelector(id));

  return (
    <>
      {releases.map(({ id: releaseId }) => (
        <WorkItemRelease
          iconOnly={iconOnly}
          interactable={interactable}
          id={releaseId}
          issueId={id}
          className={className}
          key={releaseId}
        />
      ))}
    </>
  );
}

export function WorkItemStatus({
  id,
  size,
  className,
  style,
}: {
  id: string;
  size?: IconSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  const status = useRecoilValue(statusForIssueSelector(id));
  const workItem = useRecoilValue(issueSelector(id));
  const isDefault = useRecoilValue(isDefaultStatusSelector(status?.id));
  const moveIssues = useMoveIssues();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  if (!status) {
    return <Icon className={className} size={size} icon="none" />;
  }
  const content = (
    <Tooltip content={status.name}>
      <div className="row">
        <StatusIcon
          style={style}
          className={className}
          size={size}
          type={status?.statusType}
          isDefault={isDefault}
        />
      </div>
    </Tooltip>
  );
  if (!workItem) {
    return content;
  }
  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <DropdownMenuTrigger
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>{content}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="start"
        className="menuStandard"
      >
        <StatusColumnPicker
          state={entitiesToStatusColumnPickerState([workItem])}
          onPicked={(issueIds, statusId) => {
            // without this, the z to undo hotkey doesn't seem to work
            moveIssues(issueIds, statusId);
            closeMenu();
          }}
          onDone={closeMenu}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function WorkItemPublic({
  id,
  size,
  className,
  style,
}: {
  id: string;
  size?: IconSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  const pub = useRecoilValue(issuePublicSelector(id));
  if (!pub) {
    return null;
  }
  return (
    <Tooltip content={`This ${issueTerm} is public`}>
      <div style={{ display: 'inherit' }}>
        <Icon className={className} style={style} icon={'public'} size={size} />
      </div>
    </Tooltip>
  );
}
