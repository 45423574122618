import * as React from 'react';
import { hide, toast } from '../toast';

const AUTOSORT_TOAST_ID = 'autosort_toast';

export function autoSortedMessage(message: string | React.ReactNode, extended: boolean) {
  toast.raw(
    <>
      {message}
      {extended && ' To manually rearrange work items, switch to manual ordering.'}
    </>,
    {
      id: AUTOSORT_TOAST_ID,
      center: true,
      hideClose: true,
    }
  );
}

export function hideAutoSortedMessage() {
  hide(AUTOSORT_TOAST_ID);
}
