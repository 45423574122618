import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { RenderElementProps } from 'slate-react';
import { InlineExternalIssueLinkElement } from '../../../shared/slate/types';
import { ExternalIssue, IntegrationType } from '../../../sync/__generated/models';
import { BlinkingDots } from '../../components/blinkingDots';
import { IntegrationIcon } from '../../components/new/integrationIcon';
import { externalIssueSelector } from '../../syncEngine/selectors/externalIssues';
import { stringifyIntegrationType } from '../../utils/integrations';
import { openExternalUrl } from '../../utils/urls';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './inlineExternalIssueLink.module.scss';
import { VoidInline } from './voidBlock';

function RenderExternalIssue({ externalIssue }: { externalIssue: ExternalIssue | null }) {
  if (!externalIssue) {
    return <div className="grayed">Issue not found</div>;
  }

  return (
    <>
      <span className="grayed mr6">{externalIssue.number}</span>
      {!externalIssue.fauxDeleted && <span className="semiBold mr2">{externalIssue.title}</span>}
      {externalIssue.fauxDeleted && (
        <span className="grayed mr2">
          Issue deleted in {stringifyIntegrationType(externalIssue.type)}
        </span>
      )}
    </>
  );
}

export function StaticInlineExternalIssueLink({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: InlineExternalIssueLinkElement }) {
  const { externalIssueType, externalIssueId } = element;
  const type = externalIssueType.toUpperCase() as IntegrationType;
  const externalIssue = useRecoilValue(externalIssueSelector(externalIssueId));
  const loading = !externalIssueId || !externalIssue;

  return (
    <span
      className={cn(styles.inlineExternalIssueLink, 'clickable')}
      {...attributes}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (externalIssue) {
          openExternalUrl(externalIssue.url);
        }
      }}
    >
      <IntegrationIcon integrationType={type} className={cn(styles.icon, 'inlineIcon')} />
      {loading && (
        <>
          <span className="grayed mr4">Loading issue from {stringifyIntegrationType(type)}</span>
          <BlinkingDots className={styles.loading} />
        </>
      )}
      {!loading && <RenderExternalIssue externalIssue={externalIssue} />}
      {children}
    </span>
  );
}

export function InlineExternalIssueLink({
  attributes,
  element,
  children,
}: RenderElementProps & { element: InlineExternalIssueLinkElement }) {
  const { externalIssueType, externalIssueId } = element;
  const type = externalIssueType.toUpperCase() as IntegrationType;
  const externalIssue = useRecoilValue(externalIssueSelector(externalIssueId));
  const loading = !externalIssueId || !externalIssue;

  return (
    <VoidInline
      attributes={attributes}
      element={element}
      className={cn(styles.inlineExternalIssueLink, styles.slate)}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (externalIssue) {
          openExternalUrl(externalIssue.url);
        }
      }}
    >
      <IntegrationIcon integrationType={type} className={cn(styles.icon, 'inlineIcon')} />
      {loading && (
        <>
          <span className="grayed mr4">Loading issue from {stringifyIntegrationType(type)}</span>
          <BlinkingDots className={styles.loading} />
        </>
      )}
      {!loading && <RenderExternalIssue externalIssue={externalIssue} />}
      {children}
    </VoidInline>
  );
}
