import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { FilterType } from '../../../shared/filtering';
import { useCurrentUser } from '../../contexts/userContext';
import { assignIssueKey, labelIssueKey, searchKey, selfFilterHotKey } from '../../utils/config';
import { FilterableEntityType, filterState } from '../../utils/filtering';
import { ContentProps, FilterMenu, MenuMode, addFilter, findFilter, removeFilter } from './filters';
import { KeyNavigationProvider } from './keyNavigation';
import { LISTVIEW_ID, ListView, ListViewItem, NO_KEYNAV } from './listView';
import menuStyles from './menu/menu.module.scss';

function DefaultContents({ id, onModeChanged, onClose }: ContentProps) {
  const setFilters = useSetRecoilState(filterState(id));
  const user = useCurrentUser();

  const items: ListViewItem[] = [
    {
      id: 'owners',
      icon: 'member',
      contents: 'Owners',
      onSelected: () => {
        onModeChanged(MenuMode.Users, {
          userFilterType: FilterType.Member,
          placeholder: 'Owners',
        });
      },
      hotkey: assignIssueKey,
      className: menuStyles.item,
    },
    {
      id: 'people',
      icon: 'member',
      contents: 'People',
      onSelected: () => {
        onModeChanged(MenuMode.People);
      },
      hotkey: 'p',
      className: menuStyles.item,
    },
    {
      id: 'companies',
      icon: 'org',
      contents: 'Companies',
      onSelected: () => {
        onModeChanged(MenuMode.Companies);
      },
      hotkey: 'o',
      className: menuStyles.item,
    },
    {
      id: 'tags',
      icon: 'label',
      contents: 'Tags',
      onSelected: () => {
        onModeChanged(MenuMode.Tags);
      },
      className: menuStyles.item,
      hotkey: labelIssueKey,
    },
    {
      id: 'my',
      icon: 'my_work',
      contents: 'Own feedback',
      onSelected: () => {
        setFilters(previous => {
          if (findFilter(previous, f => f.type === FilterType.Member && f.id === user.id)) {
            return removeFilter(previous, f => f.type === FilterType.Member && f.id === user.id);
          }
          return addFilter(previous, { type: FilterType.Member, id: user.id });
        });
        onClose();
      },
      hotkey: selfFilterHotKey,
      className: menuStyles.item,
    },
    {
      id: 'freeText',
      icon: 'search_content',
      contents: 'Title',
      onSelected: () => {
        onModeChanged(MenuMode.FreeText);
      },
      hotkey: searchKey,
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-4',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'createdSince',
      icon: 'none',
      contents: 'Created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'after',
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'c',
      className: menuStyles.item,
    },
    {
      id: 'notCreatedSince',
      icon: 'none',
      contents: 'Not created since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'before',
          dateFilterType: FilterType.CreatedAt,
        });
      },
      hotkey: 'shift+c',
      className: menuStyles.item,
    },
    {
      id: 'updatedSince',
      icon: 'none',
      contents: 'Updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'after',
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'u',
      className: menuStyles.item,
    },
    {
      id: 'notUpdatedSince',
      icon: 'none',
      contents: 'Not updated since',
      onSelected: () => {
        onModeChanged(MenuMode.RelativeDate, {
          dateFilterDirection: 'before',
          dateFilterType: FilterType.UpdatedAt,
        });
      },
      hotkey: 'shift+u',
      className: menuStyles.item,
    },
    {
      id: NO_KEYNAV,
      key: 'sep-5',
      contents: () => <div className={menuStyles.separator} />,
    },
    {
      id: 'clear',
      icon: 'exit',
      contents: 'Clear all filters',
      onSelected: () => {
        setFilters(null);
        onClose();
      },
      hotkey: 'backspace',
      className: menuStyles.item,
    },
  ];

  return (
    <div>
      <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
        <ListView items={items} />
      </KeyNavigationProvider>
    </div>
  );
}

export function FeedbackFilterMenu({
  entityType,
  id,
  className,
  style,
}: {
  entityType?: FilterableEntityType;
  id: string;
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <div className={className}>
      <FilterMenu
        id={id}
        entityType={entityType}
        style={style}
        options={{ freeTextTitleOnly: true }}
        DefaultContents={DefaultContents}
        compact
      />
    </div>
  );
}
