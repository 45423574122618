import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  EntityArchivedAction,
  EntityArchivedActivityDetails,
} from '../../../../sync/__generated/models';
import { entitySelector, entityTypeString } from '../../../syncEngine/selectors/entities';
import ActorName from '../../actorName';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function EntityArchivedActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as EntityArchivedActivityDetails;
  const entity = useRecoilValue(entitySelector(activity.entityId));

  if (!entity) {
    return null;
  }

  const entityString = entityTypeString(entity.__typename);
  const action = details.action === EntityArchivedAction.Archived ? 'archived' : 'un-archived';

  return (
    <Activity
      activity={activity}
      icon={details.action === EntityArchivedAction.Archived ? 'archive' : undefined}
    >
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> {action} this{' '}
      {entityString}.
      <ActivityTimestamp timestamp={activity.updatedAt} />
    </Activity>
  );
}

export const EntityArchivedActivity = React.memo(EntityArchivedActivityComponent);
