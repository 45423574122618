import cn from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { EntityCommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { InitiativeListItem } from '../../../components/new/initiativeListItem';
import { KeyNavigationProvider } from '../../../components/new/keyNavigation';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { useOrganization } from '../../../contexts/organizationContext';
import { useMaybeSpace } from '../../../contexts/spaceContext';
import { useEntityNumberWidths } from '../../../syncEngine/selectors/entities';
import {
  archivedInitiativesSelector,
  filteredArchivedInitiativesForOrganizationSelector,
} from '../../../syncEngine/selectors/intiatives';
import { LocationState, silentlyUpdateHistoryState } from '../../../utils/history';
import { ArchivePlaceholder } from './placeholder';

export function InitiativeArchive() {
  const organization = useOrganization();
  const space = useMaybeSpace();
  const location = useLocation<LocationState>();
  const numberColumnWidth = useEntityNumberWidths(space ? [space.id] : []);
  const copyEntities = useCopyEntitiesToClipboard();

  const initiatives = useRecoilValue(
    space
      ? archivedInitiativesSelector({
          spaceId: space.id ?? '',
          filterId: `archive-${space.id}`,
        })
      : filteredArchivedInitiativesForOrganizationSelector({
          organizationId: organization.id,
          filterId: `archive-${organization.id}`,
        })
  );

  const focusedElementId = React.useMemo(() => {
    // if we come back from the initiative screen, force the selection to where it was before
    if (location.state?.entity) {
      const { entity, ...rest } = location.state;
      silentlyUpdateHistoryState(rest);
      return entity;
    }

    return initiatives[0];
  }, []);

  if (initiatives.length === 0) {
    return <ArchivePlaceholder type="initiative" />;
  }

  return (
    <KeyNavigationProvider
      initiallyFocusedElementId={focusedElementId}
      columnIds={['archive-initiatives']}
      disableEnsureVisible
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <EntityCommandContext />
      <VirtualizedListView
        id={`archive-initiatives`}
        className={'fullWidth grow mt16'}
        sectionIds={['archived']}
        itemIds={{ archived: initiatives }}
        sectionHeaderHeight={0}
        itemHeight={41}
        spacerHeight={32}
        renderSectionHeader={() => <></>}
        renderItem={(id, _sectionId, isFirst, isLast, _edit) => {
          return (
            <InitiativeListItem
              id={id}
              className={cn('listItem', {
                first: isFirst,
                last: isLast,
              })}
              style={
                {
                  '--number-column-width': `${numberColumnWidth}px`,
                } as React.CSSProperties
              }
            />
          );
        }}
        renderPlaceholder={() => {
          return <></>;
        }}
        renderAccessories={grid => {
          return <ResizeItemsOnStateChange ids={grid} />;
        }}
        onCopy={copyEntities}
      />
    </KeyNavigationProvider>
  );
}
