import { sortBy } from 'lodash';
import { selectorFamily, useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { snippetsBySpace } from '../../../sync/__generated/indexes';
import { Snippet } from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { spacesForOrganizationSelector } from './spaces';

export const snippetsSelector = selectorFamily({
  key: 'Snippets',
  get:
    (snippetId: string) =>
    ({ get }) => {
      return get(syncEngineState(snippetId)) as Snippet | null;
    },
});

export function useSnippetForCallback() {
  return useRecoilCallback(({ snapshot }) => (snippetId: string) => {
    return snapshot.getLoadable(snippetsSelector(snippetId)).getValue();
  });
}

export const snippetsForSpaceSelector = selectorFamily({
  key: 'SnippetsForSpace',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      if (!spaceId) {
        return [];
      }

      const snippetIds = get(indexKeyState(indexKey(snippetsBySpace, spaceId)));
      return sortBy(
        filterNotDeletedNotNull(
          snippetIds.map(snippetId => get(syncEngineState(snippetId)) as Snippet | null)
        ),
        snippet => snippet.name
      );
    },
});

export const snippetsForOrganizationSelector = selectorFamily({
  key: 'SnippetsForOrganization',
  get:
    (organizationId: string | undefined) =>
    ({ get }) => {
      if (!organizationId) {
        return [];
      }
      const spaces = get(spacesForOrganizationSelector(organizationId));
      const snippets = spaces.flatMap(space => get(snippetsForSpaceSelector(space.id)));
      return sortBy(snippets, 'name');
    },
});
