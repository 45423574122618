import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../../../sync/__generated/models';
import { externalAuthFlow } from '../../../../api/auth';
import BackButton from '../../../../components/new/backButton';
import { ButtonStyle } from '../../../../components/new/button';
import {
  AdminButton,
  IntegrationSettingsPage,
  SettingsSection,
} from '../../../../components/new/settings';
import { useConfiguration } from '../../../../contexts/configurationContext';
import { useOrganization } from '../../../../contexts/organizationContext';
import { isElectron, openLinkInBrowser } from '../../../../electronIntegration';
import { integrationsSelector } from '../../../../syncEngine/selectors/integrations';
import { organizationPath } from '../../../../syncEngine/selectors/organizations';
import { IntegrationNotFound } from '../manageIntegrationScreen';

export function EditLinearIntegration({ id }: { id: string }) {
  const integration = useRecoilValue(integrationsSelector(id));
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'settings/integrations');

  const config = React.useMemo(
    () =>
      JSON.parse(integration?.config || '{}') as {
        organizationId: string;
        organizationName: string;
        organizationUrl: string;
      },
    [integration]
  );

  if (!integration) {
    return <IntegrationNotFound />;
  }

  function manageOnLinear() {
    if (!config.organizationUrl) {
      return;
    }
    if (isElectron()) {
      openLinkInBrowser(`${config.organizationUrl}/settings/integrations/kitemaker`);
    } else {
      window.open(`${config.organizationUrl}/settings/integrations/kitemaker`);
    }
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Linear}
      description={
        <>
          <p>Easily create and link Linear issues, right from Kitemaker.</p>
          <p>
            The integration is connected to the Linear organization &quot;{config.organizationName}
            &quot;.
          </p>
        </>
      }
    >
      <SettingsSection>
        <div className="rowBetween">
          <BackButton defaultPath={defaultPath}>Back</BackButton>
          <div className="row">
            {config.organizationUrl && (
              <AdminButton buttonStyle={ButtonStyle.Primary} onClick={manageOnLinear}>
                Manage in Linear
              </AdminButton>
            )}
          </div>
        </div>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}

export function NewLinearIntegration({ redirectUrl }: { redirectUrl?: string }) {
  const organization = useOrganization();
  const { host, electronScheme } = useConfiguration();

  function startInstallation() {
    externalAuthFlow(
      `${host}/integrations/linear/install?organizationId=${organization.id}`,
      electronScheme,
      {
        redirectBaseUrl: redirectUrl,
        redirectQueryParams: {
          pendingIntegration: 'linear',
        },
        exchange: true,
      }
    );
  }

  return (
    <IntegrationSettingsPage
      integrationType={IntegrationType.Linear}
      description={
        <>
          <p>Easily create and link Linear issues, right from Kitemaker.</p>
          <p>
            To set up the integration, we'll need to install the Kitemaker app in your Linear
            organization.
          </p>
        </>
      }
    >
      <SettingsSection>
        <AdminButton buttonStyle={ButtonStyle.Primary} onClick={startInstallation}>
          Install Linear integration
        </AdminButton>
      </SettingsSection>
    </IntegrationSettingsPage>
  );
}
