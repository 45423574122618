import moment from 'moment';
import { Initiative } from '../../sync/__generated/models';

export type Item = {
  start_time: number;
  end_time: number;
  color: string;
  id: string;
  group: number;
  title: string;
  object: Initiative | null;
  placeholder?: 'release' | 'issue';
  canMove?: boolean;
  canResize?: boolean;
  open?: boolean;
  edit?: boolean;
};

export const oneDay = moment.duration(1, 'days').asMilliseconds();

export function utcToLocalDate(date: number): number {
  return moment.utc(date).local(true).valueOf();
}

export function localToUtc(date: number): number {
  return moment(date).utc(true).valueOf();
}

export function localToUtcStartDay(date: number): number {
  return moment(date).utc(true).startOf('day').valueOf();
}

export function findNearestDayBoundary(time: number): number {
  const mom = moment(time);
  const nextDay = mom.clone().endOf('day').valueOf() + 1;
  const startOfDay = mom.startOf('day').valueOf();
  return time - startOfDay < nextDay - time ? startOfDay : nextDay;
}
