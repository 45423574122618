import { KeyboardEvent } from 'react';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { Marks } from '../../../shared/slate/types';
import { metaKeyDown } from '../../utils/keyEvents';
import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';

const keysToMarks: { [index: string]: Marks } = {
  b: Marks.Bold,
  i: Marks.Italic,
  u: Marks.Underline,
  e: Marks.Code,
  'shift+s': Marks.Strikethrough,
};

export function hotkeysOnKeyDown(editor: EditorType, e: KeyboardEvent): boolean {
  if (KitemakerElement.focusIsInNonInteractiveElement(editor)) {
    return false;
  }

  // FIXME: can we use mousetrap somehow here so we don't need to reinvent hotkeys?
  const key = e.shiftKey ? `shift+${e.key.toLowerCase()}` : e.key;
  if (metaKeyDown(e) && keysToMarks[key]) {
    e.preventDefault();
    KitemakerEditor.toggleMark(editor, keysToMarks[key]);
    return true;
  }
  return false;
}
