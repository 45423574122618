import * as RadixAvatar from '@radix-ui/react-avatar';
import cn from 'classnames';
import * as React from 'react';
import { twoLetterAvatarString } from '../../../utils/avatars';
import styles from './avatar.module.scss';

export enum AvatarSize {
  Size20 = 20,
  Size24 = 24,
  Size32 = 32,
  Size40 = 40,
  Size96 = 96,
}

const sizesToStyles = {
  [AvatarSize.Size20]: styles.size20,
  [AvatarSize.Size24]: styles.size24,
  [AvatarSize.Size32]: styles.size32,
  [AvatarSize.Size40]: styles.size40,
  [AvatarSize.Size96]: styles.size96,
};

export default function Avatar({
  img,
  name,
  size = AvatarSize.Size20,
  className,
  style,
}: {
  img?: string | null;
  name: string;
  size?: AvatarSize;
  className?: string;
  style?: React.CSSProperties;
}) {
  const twoLetters = twoLetterAvatarString(name);
  return (
    <RadixAvatar.Root className={cn(styles.root, sizesToStyles[size], className)} style={style}>
      {img && <RadixAvatar.Image className={styles.image} src={img} />}
      <RadixAvatar.Fallback className={styles.fallback} delayMs={img ? 600 : undefined}>
        {twoLetters}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}

export function AvatarCricle({
  content,
  delay,
  size = AvatarSize.Size20,
  className,
}: {
  content: string | React.ReactNode;
  delay?: number;
  size?: AvatarSize;
  className?: string;
}) {
  return (
    <RadixAvatar.Root className={cn(styles.root, sizesToStyles[size], className)}>
      <RadixAvatar.Fallback className={styles.fallback} delayMs={delay}>
        {content}
      </RadixAvatar.Fallback>
    </RadixAvatar.Root>
  );
}
