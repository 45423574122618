import * as Sentry from '@sentry/browser';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import {
  organizationMembersByUser,
  updatesByOrganizationMember,
} from '../../../sync/__generated/indexes';
import { OrganizationMember, Update } from '../../../sync/__generated/models';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { SyncEngineGetters, useModelManager } from '../../graphql/modelManager';

function updatesForUserAndOrganization(
  { getIndex, get }: SyncEngineGetters,
  organizationId: string,
  userId: string
): Update[] {
  const membershipIds = getIndex(organizationMembersByUser, userId);
  const memberships = filterNotDeletedNotNull(
    membershipIds.map(membershipId => get<OrganizationMember>(membershipId))
  );
  const membership = memberships.find(membership => membership.organizationId === organizationId);
  if (!membership) {
    return [];
  }

  const updateIds = getIndex(updatesByOrganizationMember, membership.id);
  const updates = filterNotDeletedNotNull(updateIds.map(updateId => get<Update>(updateId)));
  return updates.filter(update => {
    if (!update.dependencyIds) {
      Sentry.captureMessage('Update without dependencyIds', {
        extra: {
          updateId: update.id,
          organizationId,
          userId,
        },
      });
      return false;
    }
    return true;
  });
}

export function useMarkUpdatesReadByKey() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (key: string) => {
    modelManager.transaction((tx, getters) => {
      const updates = updatesForUserAndOrganization(getters, organization.id, user.id).filter(
        update => update.key === key && !update.read
      );
      for (const update of updates) {
        tx.update<Update>(update.id, { read: true });
      }
    });
  };
}

export function useMarkUpdatesReadByDependencyId() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (dependencyId: string) => {
    modelManager.transaction((tx, getters) => {
      const updates = updatesForUserAndOrganization(getters, organization.id, user.id).filter(
        update => !update.read && update.dependencyIds.includes(dependencyId)
      );
      for (const update of updates) {
        tx.update<Update>(update.id, { read: true });
      }
    });
  };
}

export function useMarkUpdatesRead() {
  const modelManager = useModelManager();
  return (updateIds: string[], read: boolean) => {
    modelManager.transaction(tx => {
      for (const updateId of updateIds) {
        tx.update<Update>(updateId, { read });
      }
    });
  };
}
