import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Application } from '../../../../sync/__generated/models';
import ExternalLink from '../../../components/externalLink';
import LinkButton from '../../../components/linkButton';
import { Button, ButtonStyle, IconButton } from '../../../components/new/button';
import { Icon } from '../../../components/new/icon';
import {
  Setting,
  SettingsListItem,
  SettingsPage,
  SettingsSection,
} from '../../../components/new/settings';
import { Switch } from '../../../components/new/switch';
import { TextInput } from '../../../components/new/textInput';
import { Tooltip } from '../../../components/new/tooltip';
import { useConfiguration } from '../../../contexts/configurationContext';
import { useConfirmation } from '../../../contexts/confirmationContext';
import { Modals, useModals } from '../../../contexts/modalContext';
import { useOrganization } from '../../../contexts/organizationContext';
import { useHasAdminRights } from '../../../contexts/userContext';
import { useUpdateApplications } from '../../../syncEngine/actions/applications';
import { useUpdateOrganization } from '../../../syncEngine/actions/organizations';
import { useUpdatePersonalAccessTokens } from '../../../syncEngine/actions/personalAccessTokens';
import { applicationsForOrganizationSelector } from '../../../syncEngine/selectors/organizations';
import { patsForCurrentUserSelector } from '../../../syncEngine/selectors/personalAccessTokens';
import styles from './settingsScreen.module.scss';

function ListRow({
  name: initialName,
  status,
  onRevoke,
  onEditName,
  isRevoked,
  isFirst,
  isLast,
}: {
  name: string;
  status?: string;
  onRevoke: () => void;
  onEditName: (newName: string) => void;
  isRevoked: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}) {
  const [edit, setEdit] = React.useState(false);
  const [name, setName] = React.useState(initialName);

  if (edit) {
    return (
      <SettingsListItem isFirst={isFirst} isLast={isLast} onClickOutside={() => setEdit(false)}>
        <TextInput
          className="grow mr8"
          autoFocus
          value={name}
          onChange={e => setName(e.currentTarget.value)}
          onKeyDown={e => {
            if (e.key.toLowerCase() === 'enter') {
              e.preventDefault();
              e.stopPropagation();
              onEditName(name);
              setEdit(false);
              return;
            }
            if (e.key.toLowerCase() === 'escape') {
              setEdit(false);
            }
          }}
        />
        <Button
          className="mr8"
          onClick={() => {
            setName(initialName);
            setEdit(false);
          }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle={ButtonStyle.Primary}
          onClick={() => {
            onEditName(name);
            setEdit(false);
          }}
        >
          Save
        </Button>
      </SettingsListItem>
    );
  }

  return (
    <SettingsListItem
      onClick={() => {
        onEditName(name);
        setEdit(true);
      }}
      isFirst={isFirst}
      isLast={isLast}
      meta={
        <>
          {!isRevoked && (
            <div className={styles.meta}>
              <Tooltip content="Edit">
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  icon="edit"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEdit(true);
                  }}
                />
              </Tooltip>
              <Tooltip content="Revoke">
                <IconButton
                  buttonStyle={ButtonStyle.BareSubtle}
                  className={styles.deleteIcon}
                  icon="delete"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRevoke();
                  }}
                />
              </Tooltip>
            </div>
          )}
        </>
      }
    >
      <span style={isRevoked ? {} : { color: 'var(--grayA12)' }}>
        {name}
        {status && <span className="bodyS"> ({status})</span>}
      </span>
    </SettingsListItem>
  );
}

export function DeveloperSettingsScreen() {
  const modalManager = useModals();
  const { host } = useConfiguration();
  const { confirm } = useConfirmation();
  const organization = useOrganization();
  const updateOrganization = useUpdateOrganization();

  const hasAdminRights = useHasAdminRights();

  const applications: Application[] = useRecoilValue(
    applicationsForOrganizationSelector(organization.id)
  );
  const updateApplications = useUpdateApplications();

  const personalAccessTokens = useRecoilValue(patsForCurrentUserSelector(organization.id));
  const updatePats = useUpdatePersonalAccessTokens();

  const svixLink = organization.webhooksEnabled ? (
    <ExternalLink className="link" href={`${host}/developers/webhooks/settings/${organization.id}`}>
      Manage webhooks in Svix
    </ExternalLink>
  ) : null;

  return (
    <SettingsPage
      title="Developer"
      description={
        <>
          <p>
            Using our APIs you can retrieve data, or create and edit work items, initiatives, and
            feedback.
          </p>

          <LinkButton
            to="https://guide.kitemaker.co/graphql-api-basics"
            className="row inlineFlex ml-4"
          >
            Read more in the Kitemaker Guide <Icon icon="external_link" className="ml4" />
          </LinkButton>
        </>
      }
    >
      <Setting
        title={'Webhooks'}
        description={<>Enable or disable webhooks for your organization. {svixLink}</>}
      >
        <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
          <Switch
            checked={organization.webhooksEnabled}
            onChange={v => updateOrganization({ webhooksEnabled: v })}
            disabled={!hasAdminRights}
          />
        </Tooltip>
      </Setting>

      <SettingsSection>
        <div className="rowBetween">
          <span className={styles.heading}>Applications</span>
          <Tooltip disabled={hasAdminRights} content={<>Only admins can perform this action</>}>
            <Button
              icon="add"
              buttonStyle={ButtonStyle.BareSubtle}
              disabled={!hasAdminRights}
              onClick={() => {
                modalManager.openModal(Modals.NewToken, { tokenType: 'Application' });
              }}
            >
              Add new application
            </Button>
          </Tooltip>
        </div>

        <div className="mt16">
          {applications
            .filter(a => !a.internal)
            .map((app, index) => (
              <ListRow
                key={index}
                name={app.name}
                isRevoked={app.revoked}
                status={app.revoked ? 'Revoked' : `Token ending in ...${app.token.slice(-4)}`}
                onEditName={name => updateApplications([app.id], { name })}
                onRevoke={async () => {
                  const confirmed = await confirm(
                    `Sure you want to revoke this application's token?`,
                    'Once revoked, this token will no longer be usable',
                    { label: 'Revoke', destructive: true }
                  );
                  if (!confirmed) {
                    return;
                  }

                  updateApplications([app.id], { revoked: true });
                }}
                isFirst={index === 0}
                isLast={index === applications.length - 1}
              />
            ))}
        </div>
      </SettingsSection>

      <SettingsSection>
        <div className="rowBetween mt28">
          <h2 className={styles.heading}>Personal access tokens</h2>
          <Button
            icon="add"
            buttonStyle={ButtonStyle.BareSubtle}
            onClick={() => {
              modalManager.openModal(Modals.NewToken, { tokenType: 'PAT' });
            }}
          >
            Add new PAT
          </Button>
        </div>

        <div className="mt16">
          {personalAccessTokens.map((pat, index) => (
            <ListRow
              key={index}
              name={pat.name}
              isRevoked={pat.revoked}
              status={pat.revoked ? 'Revoked' : `Token ending in ...${pat.token.slice(-4)}`}
              onEditName={name => updatePats([pat.id], { name })}
              onRevoke={async () => {
                const confirmed = await confirm(
                  `Sure you want to revoke this personal access token?`,
                  'Once revoked, this token will no longer be usable',
                  { label: 'Revoke', destructive: true }
                );
                if (!confirmed) {
                  return;
                }

                updatePats([pat.id], { revoked: true });
              }}
              isFirst={index === 0}
              isLast={index === personalAccessTokens.length - 1}
            />
          ))}
        </div>
      </SettingsSection>
    </SettingsPage>
  );
}
