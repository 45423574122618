import * as React from 'react';

export enum PlaceholderType {
  Issue,
  Cycle,
  Feedback,
  Initiative,
  Document,
  Release,
}

export function DescriptionPlaceholder({ type }: { type?: PlaceholderType }) {
  return (
    <div className="bodyL">
      {type === PlaceholderType.Issue && (
        <p className="mb16">
          Use this section to share context, flesh out what to build, and break the work down into
          tasks.
        </p>
      )}
      {type === PlaceholderType.Cycle && (
        <p>
          Add notes to your cycle to provide more context. Jot down any challenges, do your
          retrospective or record goals for your next cycle.
        </p>
      )}
      {type === PlaceholderType.Feedback && (
        <p>Collect user feedback and capture relevant insights here</p>
      )}
      {type === PlaceholderType.Initiative && (
        <p>Plan your initiatives and keep an overview in one place.</p>
      )}
      {type === PlaceholderType.Release && <p>Manage your release notes.</p>}

      <p className="mb4">
        You can use markdown to format text, like <span className="inlineCode">#</span>,{' '}
        <span className="inlineCode">*</span> and <span className="inlineCode">[]</span> followed by
        space
      </p>
      <p>
        To see all of the blocks you can add, press <span className="inlineCode">/</span> on an
        empty line
      </p>
    </div>
  );
}
