import cn from 'classnames';
import * as React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { FilterType } from '../../../../shared/filtering';
import { KitemakerElement } from '../../../../shared/slate/kitemakerNode';
import { DocumentLike, Elements } from '../../../../shared/slate/types';
import { documentFromString, stringifyDocument } from '../../../../shared/slate/utils';
import { ButtonSize, ButtonStyle, IconButton } from '../../../components/new/button';
import { EntityCommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { DocumentListItem, EditDocumentListItem } from '../../../components/new/documentListItem';
import { EntityFilterMenu2 } from '../../../components/new/entityFilters2';
import { EditFeedbackListItem, FeedbackListItem } from '../../../components/new/feedbackListItem';
import { addFilter } from '../../../components/new/filters';
import { Filters as Filters2 } from '../../../components/new/filters2';
import { Hotkey } from '../../../components/new/hotkey';
import { Icon } from '../../../components/new/icon';
import {
  EditInitiativeListItem,
  InitiativeListItem,
} from '../../../components/new/initiativeListItem';
import {
  KeyNavigationProvider,
  useHasKeyNavigationFocus,
  useKeyNavigationWatcher,
  useMove,
  useSetKeyNavigationFocus,
} from '../../../components/new/keyNavigation';
import { EditReleaseListItem, ReleaseListItem } from '../../../components/new/releaseListItem';
import { ScreenHeader } from '../../../components/new/screenHeader';
import { Tab, Tabs } from '../../../components/new/tabs';
import { Tooltip } from '../../../components/new/tooltip';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { EditWorkItemListItem, WorkItemListItem } from '../../../components/new/workItemListItem';
import {
  OrganizationBackCommand,
  useBackBehavior,
} from '../../../components/organizationBackHotkey';
import { Screen } from '../../../components/screen';
import TitleSetter from '../../../components/titleSetter';
import { useOrganization } from '../../../contexts/organizationContext';
import { useIsSearchIndexed, useSearch } from '../../../contexts/searchContext';
import { SpaceProvider } from '../../../contexts/spaceContext';
import { useCurrentUser } from '../../../contexts/userContext';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { labelSuggestionMatcher } from '../../../slate/plugins/suggestions/labelSuggestionMatcher';
import { userSuggestionMatcher } from '../../../slate/plugins/suggestions/userSuggestionMatcher';
import { TextArea, TextAreaHandle, TextAreaType } from '../../../slate/textArea';
import {
  entitySelector,
  filteredEntitiesSelector,
  recentLocalEntityIdsSelector,
  sortedEntityIdsForOrganizationSelector,
  spaceIdsForEntitiesSelector,
  useEntityNumberWidths,
} from '../../../syncEngine/selectors/entities';
import { uniqueLabelsForOrganizationSelector } from '../../../syncEngine/selectors/labels';
import {
  activeUsersForOrganizationSelector,
  organizationPath,
} from '../../../syncEngine/selectors/organizations';
import { spacesHaveLoadedNonArchivedItemsSelector } from '../../../syncEngine/selectors/smartLoader';
import { searchKey } from '../../../utils/config';
import { filterState, hasFiltersSelector } from '../../../utils/filtering';
import { LocationState } from '../../../utils/history';
import { getQueryParameter, removeQueryParameter, setQueryParameter } from '../../../utils/query';
import LoadingScreen from '../../loadingScreen';
import styles from './searchScreen.module.scss';

function extractSearchMetadata(content: DocumentLike) {
  if (!content.length || !KitemakerElement.isElement(content[0])) {
    return null;
  }

  for (const node of content[0].children) {
    if (!KitemakerElement.isElement(node)) {
      continue;
    }

    switch (node.type) {
      case Elements.User:
        return { type: 'user', id: node.userId };
      case Elements.Label:
        return { type: 'label', id: node.labelId };
    }
  }

  return null;
}

function deleteSearchMetadata(ref: React.RefObject<TextAreaHandle>) {
  if (!ref.current) {
    return;
  }

  const editor = ref.current.raw();
  const content = editor.children;

  if (!content.length || !KitemakerElement.isElement(content[0])) {
    return;
  }

  for (let index = 0; index < content[0].children.length; index++) {
    const node = content[0].children[index];
    if (!KitemakerElement.isElement(node)) {
      continue;
    }

    if ([Elements.User, Elements.Label].includes(node.type)) {
      Transforms.removeNodes(editor, { at: [0, index] });
      Transforms.delete(editor, { distance: 1, unit: 'character', reverse: true });
      return;
    }
  }
}

function SearchInput({
  searchQuery,
  onSearchQueryChanged,
  inputRef,
}: {
  searchQuery: string;
  onSearchQueryChanged: (search: string) => void;
  inputRef: React.RefObject<TextAreaHandle>;
}) {
  const setFilter = useSetRecoilState(filterState('search'));
  const organization = useOrganization();
  const user = useCurrentUser();
  const focused = useHasKeyNavigationFocus('input');
  const setFocus = useSetKeyNavigationFocus();
  const labels = useRecoilValue(uniqueLabelsForOrganizationSelector(organization.id));
  const users = useRecoilValue(
    activeUsersForOrganizationSelector({ organizationId: organization.id })
  );
  const move = useMove();
  const goBack = useBackBehavior();

  const labelsRef = React.useRef(labels);
  labelsRef.current = labels;
  const usersRef = React.useRef(users);
  usersRef.current = users;

  React.useEffect(() => {
    if (focused && inputRef.current && !ReactEditor.isFocused(inputRef.current.raw())) {
      inputRef.current?.focus();
      inputRef.current?.selectAll();
    }

    if (!focused) {
      inputRef.current?.clearSelection();
    }
  }, [focused]);

  useKeyNavigationWatcher(({ focused }) => {
    if (focused !== 'input' && inputRef.current && ReactEditor.isFocused(inputRef.current.raw())) {
      inputRef.current.blur();
    }
  });

  return (
    <div className="relative row fullWidth maxWidth">
      <TextArea
        ref={inputRef}
        placeholder="Search..."
        className={styles.input}
        type={TextAreaType.InputMedium}
        oneLine
        initialValue={documentFromString(searchQuery)}
        onKeyDown={e => {
          if (['enter', 'arrowup'].includes(e.key.toLowerCase())) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (e.key.toLowerCase() === 'arrowdown') {
            e.preventDefault();
            e.stopPropagation();
            inputRef.current?.blur();
            move('down');
          }

          if (e.key.toLocaleLowerCase() === 'escape') {
            goBack();
          }
        }}
        onIdle={() => {
          if (!inputRef.current) {
            return;
          }
          onSearchQueryChanged(stringifyDocument(inputRef.current.raw().children));
        }}
        onFocus={() => {
          if (!focused) {
            setFocus('input');
          }
        }}
        onChange={value => {
          const metadata = extractSearchMetadata(value);
          if (metadata) {
            switch (metadata.type) {
              case 'user':
                setFilter(previous =>
                  addFilter(previous, { type: FilterType.Member, id: metadata.id! })
                );
                break;
              case 'label':
                setFilter(previous =>
                  addFilter(previous, { type: FilterType.Label, id: metadata.id! })
                );
                break;
            }
          }
          deleteSearchMetadata(inputRef);
        }}
        suggestions={[
          labelSuggestionMatcher(null, () => labelsRef.current),
          userSuggestionMatcher(user, usersRef.current),
        ]}
      />
      <Icon icon="search" className={styles.searchIcon} />
      <Tooltip content="Clear search">
        <IconButton
          icon="exit"
          className={styles.clearSearchButton}
          size={ButtonSize.ExtraSmall}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={() => {
            inputRef.current?.clear();
            onSearchQueryChanged('');
          }}
        />
      </Tooltip>
      <Hotkey
        hotkey={searchKey}
        priority={0}
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();

          if (!focused) {
            setFocus('input');
          }
        }}
      />
    </div>
  );
}

function SearchResult({
  id,
  style,
  siblingEntities,
  className,
  onEdit,
  onEditComplete,
}: {
  id: string;
  siblingEntities: string[];
  style: React.CSSProperties;
  className?: string;
  onEdit?: () => void;
  onEditComplete?: () => void;
}) {
  const organization = useOrganization();

  const routingState = {
    siblingEntities,
    breadcrumbs: [
      {
        name: 'Search',
        link: organizationPath(organization, 'search'),
      },
    ],
  };

  const entity = useRecoilValue(entitySelector(id));
  if (entity?.__typename === 'Issue') {
    return (
      <SpaceProvider spaceId={entity.spaceId}>
        {onEditComplete ? (
          <EditWorkItemListItem
            id={id}
            onDone={onEditComplete}
            className={className}
            style={style}
          />
        ) : (
          <WorkItemListItem
            showStatus
            id={id}
            className={className}
            style={style}
            routingState={routingState}
            onChangeTitle={onEdit}
          />
        )}
      </SpaceProvider>
    );
  }

  if (entity?.__typename === 'Initiative') {
    return (
      <>
        {onEditComplete ? (
          <EditInitiativeListItem
            id={id}
            className={className}
            style={style}
            onDone={onEditComplete}
          />
        ) : (
          <InitiativeListItem
            id={id}
            className={className}
            style={style}
            routingState={routingState}
            onChangeTitle={onEdit}
          />
        )}
      </>
    );
  }
  if (entity?.__typename === 'Feedback') {
    return (
      <>
        {onEditComplete ? (
          <EditFeedbackListItem
            id={id}
            className={className}
            style={style}
            onDone={onEditComplete}
          />
        ) : (
          <FeedbackListItem
            id={id}
            className={className}
            style={style}
            routingState={routingState}
            onChangeTitle={onEdit}
          />
        )}
      </>
    );
  }
  if (entity?.__typename === 'Doc') {
    return (
      <>
        {onEditComplete ? (
          <EditDocumentListItem
            id={id}
            className={className}
            style={style}
            onDone={onEditComplete}
          />
        ) : (
          <DocumentListItem
            id={id}
            className={className}
            style={style}
            routingState={routingState}
            onChangeTitle={onEdit}
            showIcon
          />
        )}
      </>
    );
  }

  if (entity?.__typename === 'Release') {
    return (
      <>
        {onEditComplete ? (
          <EditReleaseListItem
            id={id}
            className={className}
            style={style}
            onDone={onEditComplete}
          />
        ) : (
          <ReleaseListItem
            id={id}
            className={className}
            style={style}
            routingState={routingState}
            onChangeTitle={onEdit}
          />
        )}
      </>
    );
  }

  return null;
}

function Header({ query }: { query: string }) {
  const hasFilters = useRecoilValue(hasFiltersSelector('search'));
  return (
    <div className="listHeaderContainer">
      <div className={styles.header}>
        {query && <span className="ellipsis oneLine">Search results for &quot;{query}&quot;</span>}
        {!query && hasFilters && <span className="ellipsis oneLine">Filtered results</span>}
        {!query && !hasFilters && <>Recent items</>}
      </div>
    </div>
  );
}

function Placeholder({ hasQuery }: { hasQuery: boolean }) {
  return (
    <div className="fullWidth row grayed ml16">
      {hasQuery ? `No results found` : 'No recent items'}
    </div>
  );
}

function KeyNavReset({
  inputRef,
  results,
}: {
  inputRef: React.RefObject<TextAreaHandle>;
  results: string[];
}) {
  const setFocus = useSetKeyNavigationFocus();
  React.useEffect(() => {
    if (results.length && !(inputRef.current && ReactEditor.isFocused(inputRef.current.raw()))) {
      setFocus(results[0]);
    }
  }, [results]);

  return null;
}

function SearchScreenContents() {
  const organization = useOrganization();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const copyEntities = useCopyEntitiesToClipboard();

  const inputRef = React.useRef<TextAreaHandle>(null);

  const initialQuery = React.useMemo(() => {
    const searchFromQuery = getQueryParameter(history, 'q');
    if (searchFromQuery) {
      removeQueryParameter(history, 'q');
      return searchFromQuery;
    }
    return '';
  }, []);
  const [searchOnLoad, setSearchOnLoad] = React.useState(!!initialQuery);
  const recentIds = useRecoilValue(recentLocalEntityIdsSelector);
  const [resultQuery, setResultQuery] = React.useState(initialQuery);

  const hasFilter = useRecoilValue(hasFiltersSelector('search'));
  const allEntities = useRecoilValue(sortedEntityIdsForOrganizationSelector(organization.id));
  const emptyQuerySet = React.useMemo(() => {
    return hasFilter ? allEntities : recentIds;
  }, [hasFilter, allEntities, recentIds]);

  const entityType = useRouteMatch<{ entityType?: string }>().params.entityType;
  const [results, setResults] = React.useState(initialQuery ? [] : emptyQuerySet);
  const filteredResults = useRecoilValue(
    filteredEntitiesSelector({ entityIds: results, filterId: 'search', entityType })
  );
  const spaceIds = useRecoilValue(spaceIdsForEntitiesSelector(filteredResults));
  const numberColumnWidth = useEntityNumberWidths(spaceIds);

  const selectedEntity: string | undefined = React.useMemo(() => {
    // if we come back to search, force the selection to where it was before
    if (location.state?.entity) {
      const { entity, ...rest } = location.state;
      history.replace({ pathname: location.pathname, search: location.search, state: rest });
      return entity;
    }

    return 'input';
  }, []);

  const search = useSearch(
    (resultEntities, resultSearchQuery) => {
      if (!resultSearchQuery) {
        return;
      }
      setResults(resultEntities.map(i => i.id));
      setResultQuery(resultSearchQuery ?? '');

      // FIXME: hack hack hack
      setTimeout(() => setSearchOnLoad(false), 100);
    },
    {
      searchDescription: true,
    }
  );

  React.useEffect(() => {
    if (!resultQuery && hasFilter) {
      setResults(allEntities);
    } else if (!resultQuery && !hasFilter) {
      setResults(emptyQuerySet);
    }
  }, [hasFilter, resultQuery, allEntities, emptyQuerySet]);

  useComponentDidMount(() => {
    if (initialQuery) {
      search(initialQuery);
    }
  });

  const deferredResults = React.useDeferredValue(filteredResults);
  const deferredQuery = React.useDeferredValue(resultQuery);

  const tabs: Tab[] = React.useMemo(() => {
    const t = [
      {
        id: 'all',
        name: 'All',
      },
      {
        id: 'work-items',
        name: 'Work items',
      },
      {
        id: 'initiatives',
        name: 'Initiatives',
      },
      {
        id: 'feedback',
        name: 'Feedback',
      },
    ];

    if (organization.releasesEnabled) {
      t.push({
        id: 'releases',
        name: 'Releases',
      });
    }

    if (organization.documentsEnabled) {
      t.push({
        id: 'documents',
        name: 'Documents',
      });
    }

    return t;
  }, [organization.documentsEnabled, organization.releasesEnabled]);

  return (
    <KeyNavigationProvider
      initiallyFocusedElementId={selectedEntity}
      columnIds={['search']}
      multiSelect
    >
      <Screen>
        <TitleSetter title={`${organization.name}`} />
        <EntityCommandContext />
        <OrganizationBackCommand name="search" />
        <ScreenHeader showSidebarOpener compensateForMacOSTrafficLights="auto">
          <SearchInput
            inputRef={inputRef}
            searchQuery={initialQuery}
            onSearchQueryChanged={query => {
              if (!query) {
                setQueryParameter(history, 'q', '');
                setResultQuery('');
                setResults(emptyQuerySet);
                return;
              }
              setQueryParameter(history, 'q', query);
              search(query.trim());
            }}
          />
        </ScreenHeader>
        <div className={styles.filterSection}>
          <Tabs
            tabs={tabs}
            currentTab={entityType ?? 'all'}
            onTabChanged={id => {
              history.push({
                search: history.location.search,
                pathname: organizationPath(organization, `search${id === 'all' ? '' : `/${id}`}`),
              });
            }}
          />
          <EntityFilterMenu2 className="ml10" id="search" />
        </div>
        <Filters2 id="search" />

        <KeyNavReset results={results} inputRef={inputRef} />

        {!searchOnLoad && (
          <VirtualizedListView
            id={`search`}
            className={'fullWidth grow'}
            sectionIds={['search']}
            itemIds={{ search: deferredResults }}
            sectionHeaderHeight={60}
            itemHeight={41}
            spacerHeight={32}
            additionalKeyNavigationIds={['input']}
            renderSectionHeader={() => <Header query={deferredQuery} />}
            renderItem={(id, _sectionId, isFirst, isLast, edit) => {
              return (
                <SearchResult
                  id={id}
                  className={cn('listItem', {
                    ['first']: isFirst,
                    ['last']: isLast,
                  })}
                  style={
                    {
                      '--number-column-width': `${numberColumnWidth}px`,
                    } as React.CSSProperties
                  }
                  siblingEntities={deferredResults}
                  onEdit={edit?.start}
                  onEditComplete={edit?.end}
                />
              );
            }}
            renderPlaceholder={() => {
              return <Placeholder hasQuery={!!deferredQuery.length || hasFilter} />;
            }}
            renderAccessories={grid => {
              return <ResizeItemsOnStateChange ids={grid} />;
            }}
            onCopy={copyEntities}
          />
        )}
        {searchOnLoad && <div className="basePadding grayed mt20 ml16">Please wait...</div>}
      </Screen>
    </KeyNavigationProvider>
  );
}

export function SearchScreen() {
  const organization = useOrganization();
  const loaded = useRecoilValue(spacesHaveLoadedNonArchivedItemsSelector(organization.id));
  const indexed = useIsSearchIndexed();

  if (!loaded || !indexed) {
    return <LoadingScreen />;
  }

  return <SearchScreenContents />;
}
