import { Node, NodeEntry, Range, Text } from 'slate';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { safeSelection } from '../../../shared/slate/utils';
import { parseGithubLink } from '../../../shared/utils/github';
import { normalizeExternalIssueLink } from '../../api/externalIssues';
import { normalizeFigmaLink } from '../../api/figma';
import { normalizeLoomLink } from '../../api/loom';
import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';

const linkValidators = [
  { validator: normalizeFigmaLink, mark: 'figmaLink' },
  { validator: normalizeLoomLink, mark: 'loomLink' },
  { validator: parseGithubLink, mark: 'githubLink' },
  { validator: normalizeExternalIssueLink, mark: 'externalIssueLink' },
];

export function linkDecorations(editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [node, path] = entry;
  if (!path.length || !KitemakerEditor.pathIsInCollapsedFocus(editor, path) || !Text.isText(node)) {
    return [];
  }

  let parent: NodeEntry<Node> | undefined = KitemakerEditor.above(editor, {
    match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
    at: path,
  });

  if (!parent) {
    // Are we right at the beginning of a text node? Maybe the thing before us is a link
    const selection = safeSelection(editor);
    if (selection?.focus.offset === 0) {
      const previous = KitemakerEditor.previous(editor, { at: path });
      if (previous) {
        const [previousNode] = previous;
        if (KitemakerElement.isElement(previousNode) && previousNode.type === Elements.Link) {
          parent = previous;
        }
      }
    }
  }

  if (!parent) {
    return [];
  }

  const [parentNode, parentPath] = parent;
  if (
    !KitemakerElement.isElement(parentNode) ||
    parentNode.type !== Elements.Link ||
    parentNode.fromHover
  ) {
    return [];
  }

  let decoration: string | null = null;
  for (const { validator, mark } of linkValidators) {
    if (validator(parentNode.url ?? '')) {
      decoration = mark;
    }
  }

  if (!decoration) {
    return [];
  }

  const edges = KitemakerEditor.edges(editor, parentPath);
  const edgeRange: Range = {
    anchor: edges[0],
    focus: edges[1],
  };

  return [
    {
      ...edgeRange,
      [decoration]: true,
    },
  ];
}
