import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { Initiative } from '../../../sync/__generated/models';
import { ButtonStyle, IconButtonTrigger } from '../../components/new/button';
import { ColorPickerContent } from '../../components/new/colorPicker';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
} from '../../components/new/keyNavigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  Submenu,
  SubmenuContent,
  SubmenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { InitiativeIcon } from '../../components/new/metadata/initiative';
import { TextInput } from '../../components/new/textInput';
import {
  useRemoveInitiativesFromRoadmaps,
  useUpdateInitiatives,
} from '../../syncEngine/actions/intiatives';
import { findNearestDayBoundary, oneDay } from '../../utils/timelines';
import { RESIZE_INDCICATOR_ID } from './timeline';
import styles from './timelineViewScreen.module.scss';

const RESIZE_WIDTH = 75;
const RESIZE_PADDING = 14;

export function DragPlaceholder({ itemProps, style }: { itemProps: any; style: any }) {
  return <div {...itemProps} style={style} className={styles.placeholder}></div>;
}

export function TimelinePopover({
  onEdit,
  initiative: initiative,
  roadmapId,
}: {
  onEdit: () => void;
  initiative: Initiative;
  roadmapId: string;
}) {
  const updateInitiatives = useUpdateInitiatives();
  const color = initiative.color;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const removeInitiativesFromRoadmaps = useRemoveInitiativesFromRoadmaps();

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <IconButtonTrigger
        className={cn(styles.moreButton, {
          [styles.menuOpen]: menuOpen,
        })}
        buttonStyle={ButtonStyle.BareSubtle}
        icon={'more'}
      />
      <DropdownMenuContent
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        align="center"
        className="menuMedium"
      >
        <DropdownMenuItem onClick={onEdit}>Change title</DropdownMenuItem>
        <Submenu>
          <SubmenuTrigger>Change color</SubmenuTrigger>
          <SubmenuContent>
            <ColorPickerContent
              noCustomColor
              onColorPicked={color => {
                updateInitiatives([initiative.id], { color: color ?? 'gray' });
                closeMenu();
              }}
              color={color}
            />
          </SubmenuContent>
        </Submenu>
        <DropdownMenuItem
          onClick={() => {
            removeInitiativesFromRoadmaps([initiative.id], [roadmapId]);
          }}
        >
          Remove from timeline
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function AddToTimeline({
  item,
  getItemProps,
  addToTimeline,
  onBlur,
}: {
  item: any;
  getItemProps: any;
  addToTimeline: any;
  onBlur: () => void;
}): JSX.Element {
  const { style, className, ...itemProps } = getItemProps();

  const { background, borderLeftWidth, borderRightWidth, border, color, fontSize, ...otherStyles } =
    style;

  const [focused, setFocused] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  const mappedColor = item.color;

  return (
    <div
      tabIndex={0}
      className={cn(styles.item, styles.placeholder, {
        [styles.focused]: focused,
      })}
      ref={itemProps.ref}
      key={itemProps.key}
      style={otherStyles}
    >
      <div className={styles.input} style={{ borderColor: mappedColor }}>
        <TextInput
          className={cn(styles.titleEdit, 'ignore-rct-events')}
          autoFocus
          tabIndex={-1}
          ref={inputRef}
          value={title}
          onChange={e => {
            setTitle(e.currentTarget.value);
          }}
          placeholder={'Add initiative'}
          onFocus={() => {
            disableKeyNav('timeline');
            setFocused(true);
          }}
          onBlur={() => {
            enableKeyNav('timeline');
            onBlur();
            setFocused(false);
          }}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              inputRef.current?.blur();
            }

            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              if (title) {
                addToTimeline(title);
              }
            }
          }}
        />
      </div>
    </div>
  );
}

function DateIndicator({ parentStyle, itemContext }: { parentStyle: any; itemContext: any }) {
  const { height, width, lineHeight, ...resizeIndicatorStyle } = parentStyle;

  const left =
    itemContext.resizeEdge === 'right'
      ? itemContext.dimensions.left + itemContext.dimensions.width + RESIZE_PADDING
      : itemContext.dimensions.left - (RESIZE_WIDTH + RESIZE_PADDING);

  let time = findNearestDayBoundary(itemContext.resizeTime ?? itemContext.dragTime);

  if (itemContext.resizeEdge === 'right') {
    time = time - oneDay;
  }

  return (
    <div
      style={{
        ...resizeIndicatorStyle,
        left: `${left}px`,
        top: `${itemContext.dimensions.top + 4}px`,
      }}
      className={styles.resizeIndicator}
    >
      {moment(time).format('D MMM')}
    </div>
  );
}

export function CalenderItem({
  className,
  children,
  itemContext,
  getResizeProps,
  itemProps,
  style,
  margin,
  interacting,
  onMouseEnter,
  onMouseLeave,
  edit,
}: {
  className: string;
  children: JSX.Element;
  itemContext: any;
  getResizeProps: any;
  itemProps: any;
  style: any;
  margin?: number;
  edit?: boolean;
  interacting: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  if (margin && !itemContext.dragging) {
    style = { ...style };
    // cannot use itemContext.dimensions.width because it automatically snaps to day boundaries
    // that makes the drag hover preview also snap. We don't want to do that.
    style.left = `${parseInt(style.left.substring(0, style.left.length - 2), 10) + margin}px`;
    style.width = `${itemContext.dimensions.width - margin * 2}px`;
  }

  const { ref, key, ...otherProps } = itemProps;

  const interactionProps = edit ? {} : otherProps;

  return (
    <>
      <div
        tabIndex={0}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={cn(styles.item, className, 'rct-item', {
          [styles.transitioning]: !itemContext.resizing && !itemContext.dragTime && interacting,
          [styles.resizing]: itemContext.resizing,
        })}
        ref={ref}
        key={key}
        style={style}
        {...interactionProps}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        {children}
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
      {((itemContext.resizing && itemContext.resizeEdge) || itemContext.dragTime) &&
        ReactDOM.createPortal(
          <DateIndicator parentStyle={style} itemContext={itemContext} />,
          document.getElementById(RESIZE_INDCICATOR_ID)!
        )}
    </>
  );
}

export function TimelineItem({
  initiative,
  itemContext,
  roadmapId,
  onEdit,
  onEditComplete,
  edit,
}: {
  initiative: Initiative;
  itemContext: any;
  roadmapId: string;
  onEdit: () => void;
  onEditComplete: () => void;
  edit: boolean;
}) {
  const [title, setTitle] = React.useState(initiative.title);
  const large = itemContext.dimensions.width > 100;
  const updateInitiatives = useUpdateInitiatives();
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  return (
    <div
      className={cn('rowBetween', { [styles.editing]: edit })}
      style={
        {
          width: '100%',
          height: '100%',
        } as React.CSSProperties
      }
    >
      {edit && (
        <TextInput
          className={cn(styles.titleEdit, 'ignore-rct-events')}
          placeholder="Give this timeline a title"
          value={title}
          onChange={e => setTitle(e.currentTarget.value)}
          autoFocus={true}
          onFocus={() => {
            disableKeyNav('timeline');
          }}
          onBlur={() => {
            enableKeyNav('timeline');
            updateInitiatives([initiative.id], { title });
            onEditComplete();
          }}
          onKeyDown={e => {
            if (e.key === 'Escape') {
              e.preventDefault();
              e.stopPropagation();
              onEditComplete();
              return;
            }

            if (e.key === 'Enter' && title.length) {
              e.preventDefault();
              e.stopPropagation();
              updateInitiatives([initiative.id], { title });
              onEditComplete();
              return;
            }
          }}
        />
      )}
      {!edit && (
        <>
          <div className={cn(styles.headingName, 'row')}>
            <InitiativeIcon className="mr6" color={initiative.color} />
            <div className="ellipsis">{large && itemContext.title}</div>
          </div>
          <div className={styles.operations}>
            <TimelinePopover roadmapId={roadmapId} initiative={initiative} onEdit={onEdit} />
          </div>
        </>
      )}
    </div>
  );
}
