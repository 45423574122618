import cn from 'classnames';
import { isNumber, min } from 'lodash';
import * as React from 'react';
import {
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { RecoilValueReadOnly, useRecoilValue } from 'recoil';
import { useVirtualizedEnsureVisible } from '../../../hooks/new/useVirtualizedEnsureVisible';
import { useIsTinyScreen } from '../../../hooks/useResponsiveDesign';
import { findScrollParent } from '../../../utils/dom';
import { scrollIntoView } from '../../../utils/scrolling';
import { boardMetadataConfig } from '../../metadataConfig';
import { Count } from '../count';
import {
  FocusReason,
  KeyNavigationDisablingElement,
  useKeyNavigationColumn,
  useKeyNavigationWatcher,
  useSelectKeyNavigationElementAfterScroll,
  useSetKeyNavigationFocus,
} from '../keyNavigation';
import { Card, DraggableCard, ResizableCard } from './card';
import styles from './column.module.scss';
import { SizeCacheProvider } from './sizeCacheContext';

export interface RenderColumnProperties {
  renderColumnHeader: (columnId: string, onNewCard: () => void) => React.ReactNode;
  renderCard: (
    cardId: string,
    columnId: string,
    edit?: { start?: () => void; end?: () => void }
  ) => React.ReactNode;
  renderNewCard?: (columnId: string, index: number, onDone: () => void) => React.ReactNode;
  renderPlaceholder: (columnId: string, onCreateNew: () => void) => React.ReactNode;
  renderColumnAccessories?: (columnId: string) => React.ReactNode;
  onDragOver?: (
    ref: React.RefObject<HTMLDivElement>,
    id: string
  ) => (e: React.DragEvent<HTMLDivElement>) => void;
  spacerHeight?: number;
  keyNavFocus?: React.RefObject<string | null>;
}

const NEW_CARD_ID = 'new-card-';
const PLACEHOLDER_ID = 'placeholder-';
const DEFAULT_HEIGHT = 91;
const SPACER = 'spacer';

function EnsureVisible({ ensureVisible }: { ensureVisible: (id: string) => void }) {
  useKeyNavigationWatcher(({ focused, focusedReason }) => {
    if (focused && focusedReason !== FocusReason.Mouse) {
      ensureVisible(focused);
    }
  });

  return null;
}

function InnerDraggableCardComponent({
  cardId,
  columnId,
  cardIndex,
  onEditCard,
  renderCard,
}: {
  cardId: string;
  columnId: string;
  cardIndex: number;
  renderCard: (
    cardId: string,
    columnId: string,
    edit?: { start?: () => void; end?: () => void }
  ) => React.ReactNode;
  onEditCard: (editCardPosition: { columnId: string; index: number } | null) => void;
}) {
  const start = React.useCallback(
    () => onEditCard({ columnId, index: cardIndex }),
    [onEditCard, columnId, cardIndex]
  );

  const renderProps = React.useMemo(
    () => ({
      start,
    }),
    [start]
  );

  return <>{renderCard(cardId, columnId, renderProps)}</>;
}

const InnerDraggableCard = React.memo(InnerDraggableCardComponent);

export function Column({
  id,
  boardId,
  draggingId,
  disabledColumnDndMessages,
  dragCount,
  dragAndDropDisabled,
  newCardIndex,
  getSelector,
  onNewCard,
  editCardIndex,
  onEditCard,
  renderColumnHeader,
  renderNewCard,
  renderPlaceholder,
  renderCard,
  renderColumnAccessories,
  onDragOver,
  spacerHeight,
  columnHeight,
  keyNavFocus,
}: {
  id: string;
  boardId: string;
  draggingId: string | null;
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  dragCount: number | null;
  newCardIndex?: number | null;
  getSelector: (columnId: string) => RecoilValueReadOnly<string[]>;
  onNewCard: (newCardPosition: { columnId: string; index: number } | null) => void;
  editCardIndex?: number | null;
  onEditCard: (editCardPosition: { columnId: string; index: number } | null) => void;
  dragAndDropDisabled: boolean;
  columnHeight: number;
} & RenderColumnProperties) {
  const tinyScreen = useIsTinyScreen();
  const screenWidth = React.useMemo(() => window.innerWidth, []);
  const metaConfig = useRecoilValue(boardMetadataConfig(boardId));

  const [invalidations, setInvalidations] = React.useState<number[]>([]);
  const skipInvalidations = React.useRef(false);
  const setFocus = useSetKeyNavigationFocus();
  const ref = React.useRef<HTMLDivElement>(null);
  useSelectKeyNavigationElementAfterScroll(id, ref);
  const listRef = React.useRef<List | null>();
  const disabledDndMessage = disabledColumnDndMessages?.[id];
  const cardIds = useRecoilValue(getSelector(id));

  const visibleRangeRef = React.useRef<{
    startIndex: number;
    stopIndex: number;
  } | null>(null);

  const setVisibleRange = React.useCallback(
    (visibleRange: { startIndex: number; stopIndex: number }) => {
      visibleRangeRef.current = visibleRange;
    },
    []
  );

  const sizeCache = React.useRef(
    new CellMeasurerCache({
      defaultHeight: DEFAULT_HEIGHT,
      fixedWidth: true,
    })
  );
  const previousGridRef = React.useRef<string[] | null>(null);

  const grid = [...cardIds];
  if (isNumber(newCardIndex)) {
    grid.splice(newCardIndex, 0, `${NEW_CARD_ID}${id}`);
  } else if (!grid.length) {
    grid.push(`${PLACEHOLDER_ID}${id}`);
  }
  grid.push(SPACER);
  const gridRef = React.useRef(grid);
  gridRef.current = grid;

  // don't incude the spacer in keynav
  useKeyNavigationColumn(id, grid.slice(0, -1));

  const cardIdsRef = React.useRef(cardIds);
  cardIdsRef.current = cardIds;

  function ensureColumnIsVisible(force?: boolean) {
    if (!ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const parent = findScrollParent(ref.current, { horizontal: true });
    if (!parent) {
      return;
    }
    const parentRect = parent.getBoundingClientRect();
    // need to handle the fact that the sidebar might be there so this 300 is just to avoid
    // any issues with stuff hiding just behind the sidebar
    if (force || rect.left < 300 || rect.right > parentRect.right) {
      scrollIntoView(ref.current, {
        inline: 'center',
        scrollMode: 'if-needed',
        behavior: 'auto',
      });
    }
  }

  const justEnsuredVisibleRef = React.useRef(false);
  const ensureCardIsVisible = useVirtualizedEnsureVisible(gridRef, listRef, visibleRangeRef, () => {
    ensureColumnIsVisible();
    justEnsuredVisibleRef.current = true;
  });

  const initialListRowRef = React.useRef<number | null>(
    gridRef.current.indexOf(keyNavFocus?.current ?? '')
  );

  // FIX FIX move this fool outta here
  React.useLayoutEffect(() => {
    if (previousGridRef.current) {
      const heightsById: Record<string, number> = {};
      const widthsById: Record<string, number> = {};

      // figure out how big the things are by id
      for (let i = 0; i < previousGridRef.current.length; i++) {
        const id = previousGridRef.current[i];
        // only worry about normal cards
        if (id.includes('-')) {
          continue;
        }
        const height = sizeCache.current.getHeight(i, 0);
        const width = sizeCache.current.getWidth(i, 0);
        if (height && width && height !== DEFAULT_HEIGHT) {
          heightsById[id] = height;
          widthsById[id] = width;
        }
      }

      sizeCache.current.clearAll();

      // refill the cache with the known sizes
      for (let i = 0; i < gridRef.current.length; i++) {
        const id = gridRef.current[i];
        if (heightsById[id] && widthsById[id]) {
          sizeCache.current.set(i, 0, widthsById[id], heightsById[id]);
        }
      }
      listRef.current?.recomputeRowHeights();
    }

    previousGridRef.current = [...gridRef.current];

    if (keyNavFocus?.current && gridRef.current.indexOf(keyNavFocus.current) !== -1) {
      ensureCardIsVisible(keyNavFocus.current);
    }

    skipInvalidations.current = true;
  }, [JSON.stringify(grid)]);

  React.useLayoutEffect(() => {
    if (!invalidations.length) {
      skipInvalidations.current = false;
      return;
    }

    if (!skipInvalidations.current) {
      for (const row of invalidations) {
        sizeCache.current.clear(row, 0);
      }
      const minRow = min(invalidations) ?? 0;
      listRef.current?.recomputeRowHeights(minRow);
      setInvalidations([]);
    }
    skipInvalidations.current = false;
  }, [invalidations]);

  React.useEffect(() => {
    sizeCache.current.clearAll();
    listRef.current?.recomputeRowHeights();
  }, [tinyScreen, metaConfig]);

  const addNewCard = React.useCallback(() => {
    if (grid.length > 1) {
      setFocus(grid[0]);
    }
    // if we scroll to top, the grid change will interrupt it. But this seems to work nicely
    initialListRowRef.current = 0;
    onNewCard({ columnId: id, index: 0 });
  }, [onNewCard, id]);

  const newCardComplete = React.useCallback(() => {
    onNewCard(null);
  }, [onNewCard]);

  const invalidateSizeCache = React.useCallback((index: number) => {
    setInvalidations(previous => [...previous, index]);
  }, []);

  const context = React.useMemo(
    () => ({
      invalidateSizeCache,
    }),
    [invalidateSizeCache]
  );

  return (
    <SizeCacheProvider value={context}>
      {renderColumnAccessories?.(id)}
      <EnsureVisible ensureVisible={ensureCardIsVisible} />
      {(isNumber(newCardIndex) || isNumber(editCardIndex)) && (
        <KeyNavigationDisablingElement disableKey={`column-${id}`} />
      )}
      <Droppable
        droppableId={id}
        type="issue"
        mode={'virtual'}
        renderClone={(
          provided: DraggableProvided,
          snapshot: DraggableStateSnapshot,
          rubric: DraggableRubric
        ) => {
          const cardIndex = rubric.source.index;
          const cardId = cardIdsRef.current[cardIndex];
          const padding = ref.current
            ? getComputedStyle(ref.current).getPropertyValue('--card-padding')
            : 0;

          const { style, ...rest } = provided.draggableProps;
          const draggingOverAutoSorted = disabledColumnDndMessages?.[snapshot.draggingOver ?? ''];

          if (draggingOverAutoSorted && snapshot.isDropAnimating) {
            (style as any).transitionDuration = `0.001s`;
          }

          return (
            <div id={`clone-${cardId}`} {...rest} style={style} className="relative">
              {(dragCount ?? 1) > 1 && <Count className={styles.dragCount} count={dragCount!} />}
              <Card
                style={{
                  paddingTop: padding,
                  paddingBottom: padding,
                }}
              >
                {renderCard(cardId, id)}
              </Card>
            </div>
          );
        }}
      >
        {(droppableProvided, droppableSnapshot) => {
          const itemCount = droppableSnapshot.isUsingPlaceholder ? grid.length + 1 : grid.length;
          return (
            <div onDragOver={onDragOver?.(ref, id)} className={styles.column} ref={ref}>
              <div className={styles.header}>{renderColumnHeader(id, addNewCard)}</div>
              <div className="grow relative">
                <List
                  className={styles.virtualizedList}
                  rowCount={itemCount}
                  height={columnHeight}
                  width={tinyScreen ? screenWidth - 16 : 370}
                  overscanRowCount={10}
                  scrollToIndex={
                    initialListRowRef.current !== null && initialListRowRef.current !== -1
                      ? initialListRowRef.current
                      : undefined
                  }
                  onScroll={() => {
                    if (initialListRowRef.current !== null) {
                      setTimeout(() => (initialListRowRef.current = null));
                      return;
                    }
                    if (justEnsuredVisibleRef.current) {
                      justEnsuredVisibleRef.current = false;
                      return;
                    }
                  }}
                  rowHeight={cardIndex => {
                    // we need to add the height of the thing we're currently dragging over this
                    // list or else the bottom item can get clipped off strangely.
                    if (droppableSnapshot.draggingOverWith && cardIndex.index === grid.length) {
                      const clone = document.getElementById(
                        `clone-${droppableSnapshot.draggingOverWith}`
                      );
                      if (clone) {
                        return clone.getBoundingClientRect().height + 8; // need to include some margins
                      }
                      return 0;
                    }

                    const height = sizeCache.current.rowHeight(cardIndex);
                    return height;
                  }}
                  ref={ref => {
                    listRef.current = ref;
                    // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/virtual/react-virtualized/board.jsx#L123
                    // leaving the variable names alone as a sign of respect :)
                    if (ref) {
                      // eslint-disable-next-line react/no-find-dom-node
                      const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
                      if (whatHasMyLifeComeTo instanceof HTMLElement) {
                        droppableProvided.innerRef(whatHasMyLifeComeTo);
                      }
                    }
                  }}
                  onRowsRendered={setVisibleRange}
                  rowRenderer={({ key, parent, index: cardIndex, style }) => {
                    if (!grid[cardIndex]) {
                      return null;
                    }

                    const cardId = grid[cardIndex];

                    if (cardId.startsWith(PLACEHOLDER_ID)) {
                      return (
                        <CellMeasurer
                          key={key}
                          cache={sizeCache.current}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={cardIndex}
                        >
                          <ResizableCard
                            key={cardId}
                            style={style}
                            onResized={() => {
                              sizeCache.current.clear(cardIndex, 0);
                              listRef.current?.recomputeRowHeights(cardIndex);
                            }}
                          >
                            {renderPlaceholder(id, addNewCard)}
                          </ResizableCard>
                        </CellMeasurer>
                      );
                    }

                    if (cardId.startsWith(NEW_CARD_ID)) {
                      return (
                        <CellMeasurer
                          key={key}
                          cache={sizeCache.current}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={cardIndex}
                        >
                          <ResizableCard
                            key={cardId}
                            style={style}
                            onResized={() => {
                              sizeCache.current.clear(cardIndex, 0);
                              listRef.current?.recomputeRowHeights(cardIndex);
                            }}
                          >
                            {renderNewCard?.(id, cardIndex, newCardComplete)}
                          </ResizableCard>
                        </CellMeasurer>
                      );
                    }

                    if (cardId === SPACER) {
                      return (
                        <CellMeasurer
                          key={key}
                          cache={sizeCache.current}
                          parent={parent}
                          columnIndex={0}
                          rowIndex={cardIndex}
                        >
                          <div style={{ ...style, minHeight: spacerHeight }}></div>
                        </CellMeasurer>
                      );
                    }
                    let dragSize = 0;
                    const draggingIndex = gridRef.current.findIndex(id => id === draggingId);
                    if (draggingIndex > -1 && cardIndex > draggingIndex) {
                      dragSize = sizeCache.current.getHeight(draggingIndex, 0);
                    }

                    return (
                      <CellMeasurer
                        key={key}
                        cache={sizeCache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={cardIndex}
                      >
                        {editCardIndex !== cardIndex && (
                          <div style={style}>
                            <DraggableCard
                              key={cardId}
                              id={cardId}
                              draggingId={draggingId}
                              index={cardIndex}
                              disabled={dragAndDropDisabled}
                              className={cn({
                                op50: disabledDndMessage && droppableSnapshot.isDraggingOver,
                              })}
                              noTransform={!!disabledDndMessage}
                              dragSize={dragSize}
                            >
                              <InnerDraggableCard
                                renderCard={renderCard}
                                onEditCard={onEditCard}
                                cardId={cardId}
                                columnId={id}
                                cardIndex={cardIndex}
                              />
                            </DraggableCard>
                          </div>
                        )}
                        {editCardIndex === cardIndex && (
                          <ResizableCard
                            key={cardId}
                            style={style}
                            onResized={() => {
                              sizeCache.current.clear(cardIndex, 0);
                              listRef.current?.recomputeRowHeights(cardIndex);
                            }}
                          >
                            {renderCard(cardId, id, {
                              end: () => {
                                onEditCard(null);
                                sizeCache.current.clear(cardIndex, 0);
                                listRef.current?.recomputeRowHeights(cardIndex);
                              },
                            })}
                          </ResizableCard>
                        )}
                      </CellMeasurer>
                    );
                  }}
                />
              </div>
            </div>
          );
        }}
      </Droppable>
    </SizeCacheProvider>
  );
}
