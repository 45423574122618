import cn from 'classnames';
import debugModule from 'debug';
import { pick } from 'lodash';
import * as React from 'react';
import { Editor, Transforms } from 'slate';
import {
  ReactEditor,
  RenderElementProps,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from 'slate-react';
import isURL from 'validator/lib/isURL';
import { KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { Elements } from '../../../shared/slate/types';
import { Icon } from '../../components/new/icon';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
} from '../../components/new/keyNavigation';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import { TextInput, TextInputType } from '../../components/new/textInput';
import { toast } from '../../components/toast';
import { useMaybeOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { isFirefox } from '../../utils/config';
import { fileUploader, prepareUpload, UploadResult } from '../../utils/fileUploader';
import { metaKeyDown } from '../../utils/keyEvents';
import { attachmentsPath } from '../../utils/paths';
import { DummyNode } from '../elements/dummyNode';
import { VoidBlock } from '../elements/voidBlock';
import { useSelectionCollapsed } from '../hooks/useSelectionCollapsed';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import styles from './uploadPlaceholder.module.scss';

const debug = debugModule('uploadPlaceholder');

export default function UploadPlaceholder({
  attributes,
  element,
  children,
  placeholder,
  focusedPlaceholder,
  icon,
  onUploadPrepared,
  onSubmit,
  validateInput,
  requireUrl,
}: RenderElementProps & {
  placeholder: string;
  focusedPlaceholder?: string;
  icon?: string;
  onUploadPrepared?: (result: UploadResult[]) => void;
  onSubmit: (url: string) => void;
  validateInput?: (url: string) => string;
  requireUrl?: boolean;
}) {
  const editor = useSlateStatic();
  const readonly = useReadOnly();
  const selected = useSelected();
  const selectionCollapsed = useSelectionCollapsed();
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  const blurringRef = React.useRef(false);
  const organization = useMaybeOrganization();
  const space = useMaybeSpace();
  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop({ fileDropDisabled: true });

  const uploadPath = attachmentsPath(organization, space);
  const [uploading, setUploading] = React.useState(false);
  const [dragOver, setDragOver] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  const uploader = React.useRef(
    fileUploader(uploadPath, {
      onBeforeUpload: () => setUploading(true),
      multi: true,
    })
  );

  const inputRef = React.useRef<HTMLInputElement>(null);
  const dragTimeoutRef = React.useRef(-1);
  const [input, setInput] = React.useState('');

  React.useEffect(() => {
    if (selected && selectionCollapsed && !blurringRef.current) {
      inputRef.current?.focus();
      if (isFirefox) {
        setTimeout(() => inputRef.current?.focus(), 10);
      }
    }
  }, [selected, selectionCollapsed]);

  useComponentDidMount(() => {
    return () => {
      enableKeyNav('upload-placeholder');
    };
  });

  if (readonly) {
    return (
      <DummyNode attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  function openFileInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.onchange = async e => {
      if (!e.currentTarget) {
        return;
      }
      const input = e.currentTarget as HTMLInputElement;
      if (!input.files?.length) {
        return;
      }

      try {
        setUploading(true);
        const uploadResults = prepareUpload(uploader.current, uploadPath, Array.from(input.files));
        const uploadResultsCleaned = uploadResults.map(
          res => pick(res, ['url', 'file', 'size', 'name']) as UploadResult
        );

        onUploadPrepared?.(uploadResultsCleaned);

        await uploader.current.upload();
        uploader.current.reset();
      } catch (err) {
        debug('Error uploading from file input', err);
        toast.error('There was an error uploading your file');
      } finally {
        setUploading(false);
      }
    };
    input.click();
  }

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <VoidBlock element={element} className="fullWidth" forceSelected={focused}>
        <div
          className={cn(styles.uploadPlaceholder, {
            [styles.dragOver]: dragOver,
          })}
          onDrop={async e => {
            e.preventDefault();
            e.stopPropagation();
            if (dragOver) {
              setDragOver(false);
            }

            const files = Array.from(e.dataTransfer.files);
            if (!files.length) {
              return;
            }

            try {
              setUploading(true);

              const uploadResults = prepareUpload(uploader.current, uploadPath, files);
              const uploadResultsCleaned = uploadResults.map(
                res => pick(res, ['url', 'file', 'size', 'name']) as UploadResult
              );

              onUploadPrepared?.(uploadResultsCleaned);

              await uploader.current.upload();
              // FIXME-SLATE this should allow multi upload
              uploader.current.reset();
            } catch (err) {
              debug('Error uploading from drag and drop', err);
              toast.error('There was an error uploading your file');
            } finally {
              setUploading(false);
            }
          }}
          onDragOver={e => {
            // if we are dragging a block, the type is application/json. In that case, don't try to
            // show the drop placeholder text and prevent default.
            if (e.dataTransfer.types.some(t => t === 'application/json')) {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            setDragOver(true);
            if (dragTimeoutRef.current) {
              clearTimeout(dragTimeoutRef.current);
              dragTimeoutRef.current = -1;
            }
          }}
          onDragLeave={e => {
            e.preventDefault();
            e.stopPropagation();
            dragTimeoutRef.current = window.setTimeout(() => setDragOver(false), 100);
          }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className={styles.dropIndicator}>Drop file to upload</div>
          {uploading && <LoadingSpinner className={styles.loading} />}
          {!uploading && (
            <>
              {icon && (
                <Icon
                  icon={icon}
                  className="mr12"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    openFileInput();
                  }}
                />
              )}

              <TextInput
                className="fullWidth"
                ref={inputRef}
                inputType={TextInputType.Bare}
                autoFocus={false}
                placeholder={focused ? focusedPlaceholder ?? placeholder : placeholder}
                onFocus={() => {
                  setFocused(true);
                  disableKeyNav('upload-placeholder');
                }}
                onBlur={() => {
                  setFocused(false);
                  blurringRef.current = true;
                  setTimeout(() => {
                    blurringRef.current = false;
                  });
                  enableKeyNav('upload-placeholder');
                }}
                onPaste={e => {
                  e.stopPropagation();
                }}
                value={input}
                onChange={e => {
                  setInput(e.currentTarget.value);
                }}
                onKeyDown={async e => {
                  if (e.key.toLowerCase() === 'a' && e.shiftKey && metaKeyDown(e)) {
                    e.preventDefault();
                    e.stopPropagation();
                    openFileInput();
                    return;
                  }

                  if (!e || e.metaKey || e.ctrlKey || e.shiftKey) {
                    return;
                  }

                  if ((e.key === 'Backspace' || e.key === 'Escape') && !e.currentTarget.value) {
                    e.preventDefault();
                    e.stopPropagation();
                    ReactEditor.focus(editor);

                    const path = ReactEditor.findPath(editor, element);
                    Transforms.removeNodes(editor, { at: path });
                    return;
                  }

                  if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    e.stopPropagation();
                    ReactEditor.focus(editor);

                    const path = ReactEditor.findPath(editor, element);
                    const previous = KitemakerNode.previousSibling(editor, path);
                    if (!previous) {
                      Transforms.insertNodes(
                        editor,
                        [{ type: Elements.Paragraph, children: [{ text: '' }] }],
                        { at: [0] }
                      );
                      Transforms.move(editor, { distance: 1, unit: 'line', reverse: true });
                      return;
                    }
                    const [, previousPath] = previous;
                    const [, end] = Editor.edges(editor, previousPath);
                    Transforms.select(editor, { anchor: end, focus: end });
                    return;
                  }

                  if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    e.stopPropagation();
                    ReactEditor.focus(editor);

                    const path = ReactEditor.findPath(editor, element);
                    const next = KitemakerNode.nextSibling(editor, path);
                    if (!next) {
                      Transforms.insertNodes(
                        editor,
                        [{ type: Elements.Paragraph, children: [{ text: '' }] }],
                        {
                          at: [editor.children.length],
                        }
                      );
                      Transforms.move(editor, { distance: 1, unit: 'line' });
                      return;
                    }
                    const [, nextPath] = next;
                    const [start] = Editor.edges(editor, nextPath);
                    Transforms.select(editor, { anchor: start, focus: start });
                    return;
                  }

                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();

                    if (input === '') {
                      openFileInput();
                      return;
                    }

                    if (!requireUrl || isURL(input)) {
                      if (!validateInput) {
                        onSubmit(input);
                        return;
                      }

                      try {
                        const normalizedLink = await validateInput(input);
                        onSubmit(normalizedLink);
                      } catch (e) {
                        toast.error(e.message);
                      }
                    }
                  }
                }}
              />
            </>
          )}
        </div>
      </VoidBlock>
      {children}
    </div>
  );
}
