import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Screen } from '../../components/screen';
import { useOrganization } from '../../contexts/organizationContext';
import { FetchedMarker, OrganizationMarker } from '../../graphql/smartLoad';
import { feedbackIdByNumberSelector } from '../../syncEngine/selectors/feedback';
import { markerState } from '../../syncEngine/selectors/smartLoader';
import { trackerPageLoad } from '../../tracker';
import { NotFoundScreen } from '../errorScreens';
import LoadingScreen from '../loadingScreen';
import { FeedbackScreen } from './feedbackScreen';

export default function FeedbackScreenWrapper() {
  const organization = useOrganization();

  React.useEffect(() => trackerPageLoad('FeedbackScreen open'), []);
  const currentOrgFullyFetched = useRecoilValue(
    markerState(OrganizationMarker.id(organization.id, false))
  );
  const fetched = useRecoilValue(markerState(FetchedMarker.id));
  const matches = useRouteMatch<{ feedbackNumber?: string }>();
  const feedbackNumber = matches.params.feedbackNumber;
  const feedbackId = useRecoilValue(
    feedbackIdByNumberSelector({
      organizationId: organization.id,
      feedbackNumber: feedbackNumber ?? '',
    })
  );

  if (!currentOrgFullyFetched) {
    return <LoadingScreen />;
  }

  if (feedbackNumber && !feedbackId) {
    if (fetched) {
      return (
        <Screen>
          <NotFoundScreen />
        </Screen>
      );
    }
    return <LoadingScreen />;
  }

  return <FeedbackScreen />;
}
