import cn from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Range } from 'slate';
import { RenderElementProps, useSlateStatic } from 'slate-react';
import { safeSelection } from '../../../shared/slate/utils';
import { Comment as CommentModel } from '../../../sync/__generated/models';
import { ZIndexContext } from '../../components/modal';
import { Comment as CommentComponent, NewCommentEditor } from '../../components/new/comments';
import {
  useEnsureFocusedElementIsVisible,
  useHasKeyNavigationFocus,
  useMove,
  useSetKeyNavigationFocus,
} from '../../components/new/keyNavigation';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import {
  useMarkUpdatesReadByDependencyId,
  useMarkUpdatesReadByKey,
} from '../../syncEngine/actions/updates';
import {
  allCommentsForThreadDeletedSelector,
  commentSelector,
  commentsForThreadSelector,
} from '../../syncEngine/selectors/comments';
import { entityHasActivityFeedSelector } from '../../syncEngine/selectors/entities';
import {
  useCurrentInlineComment,
  useFindSmallestCommentAtLocation,
  useIsCommentOpenInActivityFeed,
} from '../../utils/comments';
import Hover, { HoverProvider } from '../hovers';
import { TextAreaHandle, TextAreaType } from '../textArea';
import { CommentElement } from '../types';
import styles from './comment.module.scss';
import { DummyNode } from './dummyNode';

function Reply({ comment, onClose }: { comment: CommentModel; onClose: () => void }) {
  const editorRef = React.useRef<TextAreaHandle | null>(null);

  const move = useMove();
  const markUpdatesReadByKey = useMarkUpdatesReadByKey();

  return (
    <NewCommentEditor
      ref={editorRef}
      entityId={comment.entityId}
      threadId={comment.threadId}
      className={styles.commentInput}
      placeholder={<>Leave a reply</>}
      onSubmit={() => {
        markUpdatesReadByKey(comment.threadId);
      }}
      onUp={() => {
        if (editorRef.current) {
          editorRef.current.blur();
          move('up');
        }
      }}
      onClear={onClose}
      type={TextAreaType.InputMedium}
      showPlaceholderOnFirstParagraph
      inline
    />
  );
}

export function InlineCommentThreadContents({
  comment,
  onClose,
  deleted,
}: {
  comment: CommentModel;
  onClose: () => void;
  deleted?: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const markUpdatesReadByDependencyId = useMarkUpdatesReadByDependencyId();
  const user = useCurrentUser();

  const commentsForThread =
    useRecoilValue(commentsForThreadSelector(comment?.threadId))?.filter(
      c => !c.reply || !c.deleted
    ) ?? [];

  useComponentDidMount(() => {
    setTimeout(() => {
      ensureVisible();

      if (commentsForThread.length) {
        markUpdatesReadByDependencyId(commentsForThread[0].id);
      }

      const userHasParticipatedInThread = !!commentsForThread.find(c => c.actorId === user.id);

      ref.current?.scrollTo({
        top: userHasParticipatedInThread ? ref.current?.scrollHeight ?? 0 : 0,
      });
    });
  });

  if (!comment) {
    return null;
  }
  return (
    <HoverProvider>
      <div
        className={styles.comments}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {deleted && (
          <div className={styles.deleted}>This comment refers to content that has been deleted</div>
        )}
        {commentsForThread?.map((comment, index) => (
          <CommentComponent
            disableHotkeys
            key={comment.id}
            comment={comment}
            actionsAlwaysVisible={index === 0}
            className="noBorderRadius"
          />
        ))}
        <div className="p8">
          <Reply comment={comment} onClose={onClose} />
        </div>
      </div>
    </HoverProvider>
  );
}

export function Comment({
  attributes,
  children,
  element,
}: RenderElementProps & { element: CommentElement }) {
  const ref = React.useRef<HTMLSpanElement | null>(null);
  const setFocus = useSetKeyNavigationFocus();
  const focused = useHasKeyNavigationFocus(element.commentId);

  const editor = useSlateStatic();
  const comment = useRecoilValue(commentSelector(element.commentId));
  const hasActivityFeed = useRecoilValue(entityHasActivityFeedSelector(comment?.entityId));
  const threadDeleted = useRecoilValue(allCommentsForThreadDeletedSelector(comment?.threadId));
  const [open, setOpen] = React.useState(false);
  const { isOpen: confirmationOpen } = useConfirmation();
  const findSmallestComment = useFindSmallestCommentAtLocation();

  const openInActivityFeed = useIsCommentOpenInActivityFeed([comment?.id ?? '']);
  const highlighted = openInActivityFeed || focused;

  useCurrentInlineComment(element.commentId, open);

  React.useEffect(() => {
    function onOpen(commentElement: CommentElement) {
      if (commentElement === element) {
        setOpen(true);
      }
    }
    editor.on('openComment', onOpen);
    return () => {
      editor.off('openComment', onOpen);
    };
  }, [element, editor, setOpen]);

  if (!comment) {
    return (
      <DummyNode {...attributes} element={element} as="span">
        {children}
      </DummyNode>
    );
  }

  return (
    <Hover
      hoverId={`comment-${element.commentId}`}
      open={open}
      onOpenChange={setOpen}
      contentOptions={{
        onInteractOutside: e => {
          if (confirmationOpen) {
            e.preventDefault();
          }
        },
        side: 'top',
      }}
      content={
        <ZIndexContext.Provider value={300}>
          <InlineCommentThreadContents comment={comment} onClose={() => setOpen(false)} />
        </ZIndexContext.Provider>
      }
    >
      <a
        {...attributes}
        ref={r => {
          ref.current = r;
          if (isFunction(attributes.ref)) {
            attributes.ref(r);
          } else {
            attributes.ref.current = r;
          }
        }}
        data-comment-id={element.commentId}
        className={cn(styles.comment, {
          [styles.unresolved]: !comment.resolved && !threadDeleted,
          [styles.highlighted]: highlighted,
          [styles.open]: open,
        })}
        onClick={e => {
          const selection = safeSelection(editor);

          if (
            comment.deleted ||
            comment.resolved ||
            !selection ||
            !Range.isCollapsed(selection) ||
            threadDeleted
          ) {
            e.preventDefault();
            return;
          }
          const smallest = findSmallestComment(e.clientX, e.clientY);
          if (smallest !== element.commentId) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          if (open) {
            return;
          }

          if (hasActivityFeed) {
            // focus the comment in the activity feed so it scrolls into view
            setFocus(element.commentId);
          }
          setOpen(true);
        }}
      >
        {children}
      </a>
    </Hover>
  );
}
