import { maxBy, uniq } from 'lodash';
import React from 'react';
import { atom, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { emptyDocument } from '../../../shared/slate/utils';
import { filterNotNull } from '../../../shared/utils/convenience';
import { generateId } from '../../../shared/utils/id';
import { randomString } from '../../../shared/utils/random';
import { between } from '../../../shared/utils/sorting';
import { issueTerm } from '../../../shared/utils/terms';
import {
  initiativeIssues,
  initiativeLabels,
  initiativeMembers,
} from '../../../sync/__generated/collections';
import {
  collaborativeDocsByEntity,
  initiativeSpacesByInitiative,
  initiativeSpacesBySpace,
  initiativesByOrganization,
  roadmapColumnsByRoadmap,
  roadmapInitiativesByColumn,
  roadmapInitiativesByInitiative,
} from '../../../sync/__generated/indexes';
import {
  CollaborativeDoc,
  Initiative,
  InitiativeSpace,
  Issue,
  Roadmap,
  RoadmapColumn,
  RoadmapColumnStatusType,
  RoadmapInitiative,
} from '../../../sync/__generated/models';
import { EntityLink } from '../../components/new/entityLink';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { DocumentLike } from '../../slate/types';
import { trackerEvent } from '../../tracker';
import { labelColors } from '../../utils/config';
import { nextAvailableNumber } from '../../utils/entities';
import { initiativesSelector } from '../selectors/intiatives';
import { issuesSelector } from '../selectors/issues';
import { createCollaborativeDocHelper } from './collaborativeDoc';
import { indexHelper, updateSortableSorts } from './helpers';
import { duplicateTodosHelper } from './todos';

function createInitiativeHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  title: string,
  actorId: string,
  organizationId: string,
  options?: {
    roadmaps?: {
      sort?: string;
      roadmapId: string;
      roadmapColumnId?: string;
    }[];
    color?: string;
    effortId?: string | null;
    impactId?: string | null;
    spaceIds?: string[];
    memberIds?: string[];
    labelIds?: string[];
    issueIds?: string[];
    description?: DocumentLike | null;
    archivedAt?: number | null;
  }
) {
  const {
    roadmaps,
    memberIds,
    spaceIds,
    labelIds,
    issueIds,
    description,
    color,
    archivedAt,
    impactId,
    effortId,
  } = options ?? {};
  const initiatives = getters.getIndex(initiativesByOrganization, organizationId);
  const lastInitiative = initiatives.length
    ? getters.get<Initiative>(initiatives[initiatives.length - 1])
    : null;

  const intiative: SyncEngineCreate<Initiative> = {
    __typename: 'Initiative',
    title,
    actorId,
    organizationId,
    number: nextAvailableNumber(getters, organizationId, 'initiative'),
    color: color ?? labelColors[initiatives.length % labelColors.length],
    sort: between({ after: lastInitiative?.sort }),
    startDate: null,
    dueDate: null,
    memberIds: [],
    issueIds: [],
    labelIds: [],
    watcherIds: [],
    oldTheme: null,
    impactId: impactId ?? null,
    effortId: effortId ?? null,
    archivedAt: archivedAt ?? null,
    displayedUpdatedAt: Date.now(),
  };
  const intiativeResult = tx.create<Initiative>(intiative);

  createCollaborativeDocHelper(
    tx,
    organizationId,
    description ?? emptyDocument(),
    intiativeResult.id
  );

  if (memberIds?.length) {
    tx.addToCollection(initiativeMembers, intiativeResult.id, memberIds);
  }
  if (labelIds?.length) {
    tx.addToCollection(initiativeLabels, intiativeResult.id, labelIds);
  }
  if (issueIds?.length) {
    tx.addToCollection(initiativeIssues, intiativeResult.id, issueIds);
  }

  if (spaceIds?.length) {
    addSpacesToInitiative(tx, getters, intiativeResult.id, spaceIds);
  }

  const roadmapInitiatives = [];

  if (roadmaps) {
    for (const roadmap of roadmaps) {
      let columnId = roadmap.roadmapColumnId;

      if (!columnId) {
        const columns = indexHelper<RoadmapColumn>(
          getters,
          roadmapColumnsByRoadmap,
          roadmap.roadmapId
        );
        const column =
          columns.find(c => c.columnType === RoadmapColumnStatusType.Future) ?? columns[0];
        columnId = column.id;
      }

      const sort = roadmap.sort ?? between({ after: lastSortForColumn(getters, columnId) });

      roadmapInitiatives.push(
        tx.create<RoadmapInitiative>({
          __typename: 'RoadmapInitiative',
          columnId,
          initiativeId: intiativeResult.id,
          sort,
          previousColumnId: null,
          lastColumnUpdate: Date.now(),
        })
      );
    }
  }

  return { initiative: intiativeResult, roadmapInitiatives };
}

export function addSpacesToInitiative(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  initiativeId: string,
  spaceIds: string[]
) {
  const initiativeSpaces = indexHelper<InitiativeSpace>(
    getters,
    initiativeSpacesByInitiative,
    initiativeId
  );
  const spaceIdsToAdd = spaceIds.filter(id => !initiativeSpaces.some(s => s.spaceId === id));
  for (const spaceId of spaceIdsToAdd) {
    const spaceInitiatives = indexHelper<InitiativeSpace>(
      getters,
      initiativeSpacesBySpace,
      spaceId
    );
    const maxSort = maxBy(spaceInitiatives, s => s.sort);
    const sort = between({ after: maxSort?.sort });
    tx.create<InitiativeSpace>({
      __typename: 'InitiativeSpace',
      initiativeId,
      spaceId,
      sort,
    });
  }
}

function lastSortForColumn(getters: SyncEngineGetters, columnId: string): string {
  const initiativesInColumn = indexHelper<RoadmapInitiative>(
    getters,
    roadmapInitiativesByColumn,
    columnId
  );
  return initiativesInColumn[initiativesInColumn.length - 1]?.sort;
}

function firstSortForColumn(getters: SyncEngineGetters, columnId: string): string {
  const initiativesInColumn = indexHelper<RoadmapInitiative>(
    getters,
    roadmapInitiativesByColumn,
    columnId
  );
  return initiativesInColumn[0]?.sort;
}

export function useCreateInitiative() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (
    title: string,
    options?: {
      roadmaps?: {
        sort?: string;
        roadmapId: string;
        roadmapColumnId?: string;
      }[];
      spaceIds?: string[];
      memberIds?: string[];
      effortId?: string | null;
      impactId?: string | null;
      color?: string;
      labelIds?: string[];
      description?: DocumentLike | null;
    }
  ): { initiative: Initiative; roadmapInitiatives: RoadmapInitiative[] } => {
    return modelManager.transaction((tx, getters) => {
      return createInitiativeHelper(tx, getters, title, user.id, organization.id, options);
    });
  };
}

export function useUpdateInitiatives() {
  const modelManager = useModelManager();
  return (
    initiativeIds: string[],
    rawUpdate: Omit<SyncEngineUpdateWithoutDelete<Initiative>, 'sort'>
  ) => {
    const update: SyncEngineUpdateWithoutDelete<Initiative> = { ...rawUpdate };

    modelManager.transaction((tx, { get }) => {
      for (const initiativeId of initiativeIds) {
        const existingInitiative = get<Initiative>(initiativeId);
        if (!existingInitiative) {
          continue;
        }

        // TODO: something to do with previous column probably goes here?

        if (update.title) {
          trackerEvent('Initiative Updated', { id: initiativeId, type: 'Title' });
        }

        if (update.color) {
          trackerEvent('Initiative Updated', { id: initiativeId, type: 'Color' });
        }

        tx.update(initiativeId, update);
      }
    });
  };
}

export function useUpdateInitiativeSortInTimeline() {
  const modelManager = useModelManager();
  return (initiativeId: string, roadmapId: string, sort: string) => {
    modelManager.transaction((tx, getters) => {
      const columns = indexHelper<RoadmapColumn>(getters, roadmapColumnsByRoadmap, roadmapId);
      const roadmapInitiatives = columns.flatMap(c =>
        indexHelper<RoadmapInitiative>(getters, roadmapInitiativesByColumn, c.id)
      );
      const roadmapInitiative = roadmapInitiatives.find(ri => ri.initiativeId === initiativeId);
      if (roadmapInitiative) {
        tx.update<RoadmapInitiative>(roadmapInitiative.id, { sort });
      }
    });
  };
}

export function useAddSpacesToInitiatives() {
  const modelManager = useModelManager();
  return (initiativeIds: string[], spaceIds: string[]) => {
    modelManager.transaction((tx, getters) => {
      for (const initiativeId of initiativeIds) {
        addSpacesToInitiative(tx, getters, initiativeId, spaceIds);
      }
    });
  };
}

export function useRemoveSpacesFromInitiatives() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (initiativeIds: string[], spaceIds: string[], noConfirm = false) => {
        const initiatives = snapshot.getLoadable(initiativesSelector(initiativeIds)).getValue();
        const allIssueids = initiatives.flatMap(i => i.issueIds);
        const allIssues = snapshot.getLoadable(issuesSelector(allIssueids)).getValue() ?? [];

        const issuesToRemove = allIssues.filter(i => spaceIds.includes(i.spaceId));

        if (issuesToRemove.length > 0 && !noConfirm) {
          const confirmed = await confirm(
            `Remove space${spaceIds.length > 1 ? 's' : ''} from initiative${
              initiativeIds.length > 1 ? 's' : ''
            }`,
            `Removing ${spaceIds.length > 1 ? `these spaces` : `this space`} from the initiative${
              initiativeIds.length > 1 ? 's' : ''
            } will also remove ${issuesToRemove.length} connected ${issueTerm}${
              issuesToRemove.length > 1 ? 's' : ''
            }`,
            {
              label: 'Remove',
              destructive: true,
            }
          );

          if (!confirmed) {
            return;
          }
        }

        modelManager.transaction((tx, getters) => {
          for (const initiativeId of initiativeIds) {
            const initiative = getters.get<Initiative>(initiativeId);

            const issueIdsToRemove =
              initiative?.issueIds.filter(i => {
                const issue = getters.get<Issue>(i);
                if (!issue) {
                  return false;
                }
                return spaceIds.includes(issue.spaceId);
              }) ?? [];

            if (issueIdsToRemove) {
              tx.removeFromCollection(initiativeIssues, initiativeId, issueIdsToRemove);
            }

            const initiativeSpaces = indexHelper<InitiativeSpace>(
              getters,
              initiativeSpacesByInitiative,
              initiativeId
            );
            for (const initiativeSpace of initiativeSpaces) {
              if (spaceIds.includes(initiativeSpace.spaceId)) {
                tx.update<InitiativeSpace>(initiativeSpace.id, { deleted: true });
              }
            }
          }
        });
      }
  );
}

const deleteInitiativesContext = atom<{
  onDelete?: (initiatives: Initiative[]) => void;
} | null>({
  key: 'DeleteInitiativesContext',
  default: null,
});

export function useDeleteInitiativesContext(callbacks: {
  onDelete?: (initiatives: Initiative[]) => void;
}) {
  const setContext = useSetRecoilState(deleteInitiativesContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useDeleteInitiatives() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const context = useRecoilValue(deleteInitiativesContext);

  return async (initiativeIds: string[]): Promise<boolean> => {
    if (!initiativeIds.length) {
      return false;
    }

    const confirmed = await confirm(
      `Delete initaitive${initiativeIds.length > 1 ? 's' : ''}`,
      `${
        initiativeIds.length > 1 ? `These initiatives` : `This initiative`
      } will be deleted permanently. There is no way to undo this operation. Are you sure you want to proceed?`,
      { destructive: true, label: 'Delete' }
    );

    if (!confirmed) {
      return false;
    }

    const deleted: Initiative[] = [];

    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      for (const initiativeId of initiativeIds) {
        const initiative = get<Initiative>(initiativeId);
        if (!initiative || initiative.deleted) {
          continue;
        }

        tx.removeFromCollection(initiativeMembers, initiativeId, initiative.memberIds);
        tx.removeFromCollection(initiativeIssues, initiativeId, initiative.issueIds);

        const roadmapInitiativeIds = getIndex(roadmapInitiativesByInitiative, initiativeId);
        for (const id of roadmapInitiativeIds) {
          tx.update<RoadmapInitiative>(id, { deleted: true });
        }

        // do the actual deletion
        tx.update<Initiative>(initiativeId, {
          deleted: true,
          title: `__deleted__${randomString(8)}`,
        });

        const initiativeSpaces = indexHelper<InitiativeSpace>(
          getters,
          initiativeSpacesByInitiative,
          initiativeId
        );
        for (const initiativeSpace of initiativeSpaces) {
          tx.update<InitiativeSpace>(initiativeSpace.id, { deleted: true });
        }

        const docIds = getIndex(collaborativeDocsByEntity, initiativeId);
        for (const docId of docIds) {
          tx.update<CollaborativeDoc>(docId, { deleted: true, content: emptyDocument() });
        }

        deleted.push(initiative);
        trackerEvent('Initiative Deleted', { id: initiativeId });
      }
    });

    context?.onDelete?.(deleted);
    return true;
  };
}

export function useArchiveInitatives() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  return (initiativeIds: string[]) => {
    const update: SyncEngineUpdateWithoutDelete<Initiative> = { archivedAt: Date.now() };

    modelManager.transaction((tx, { get }) => {
      for (const initiativeId of initiativeIds) {
        const existingInitiative = get<Initiative>(initiativeId);
        if (!existingInitiative) {
          continue;
        }
        if (existingInitiative.archivedAt) {
          continue;
        }
        tx.update(initiativeId, update);
      }
      const action = 'archived';
      setUndo(`Initiative${initiativeIds.length > 1 ? 's' : ''} ${action}`, () => {
        modelManager.transaction(tx => {
          for (const initiativeId of initiativeIds) {
            tx.update<Initiative>(initiativeId, {
              archivedAt: null,
            });
          }
        });
      });
    });
  };
}

export function useUnarchiveInitatives() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  return (initiativeIds: string[]) => {
    const update: SyncEngineUpdateWithoutDelete<Initiative> = { archivedAt: null };

    modelManager.transaction((tx, { get }) => {
      for (const initiativeId of initiativeIds) {
        const existingInitiative = get<Initiative>(initiativeId);
        if (!existingInitiative) {
          continue;
        }
        if (!existingInitiative.archivedAt) {
          continue;
        }

        tx.update(initiativeId, update);
      }
      const action = 'unarchived';
      setUndo(`Initiative${initiativeIds.length > 1 ? 's' : ''} ${action}`, () => {
        modelManager.transaction(tx => {
          for (const initiativeId of initiativeIds) {
            tx.update<Initiative>(initiativeId, {
              archivedAt: Date.now(),
            });
          }
        });
      });
    });
  };
}

export function useDuplicateInitiatives() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (
    initiativesToDuplicate: string[],
    columnId: string,
    sortAfterId?: string,
    sortBeforeId?: string
  ) => {
    return modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const column = get<RoadmapColumn>(columnId);
      if (!column) {
        throw Error(`Unable to find column ${columnId}`);
      }
      const roadmap = get<Roadmap>(column.roadmapId);
      if (!roadmap) {
        throw Error(`Unable to find roadmap ${column.roadmapId}`);
      }

      const roadmapInitiatives = indexHelper<RoadmapInitiative>(
        getters,
        roadmapInitiativesByColumn,
        columnId
      );

      const sortAfter = roadmapInitiatives.find(i => i.initiativeId === sortAfterId);
      const sortBefore = roadmapInitiatives.find(i => i.initiativeId === sortBeforeId);

      const results: string[] = [];
      let sort = between({ after: sortAfter?.sort, before: sortBefore?.sort });
      for (const initiativeId of initiativesToDuplicate) {
        const initaitive = get<Initiative>(initiativeId);
        if (!initaitive) {
          continue;
        }

        const initiativeSpaces = indexHelper<InitiativeSpace>(
          getters,
          initiativeSpacesByInitiative,
          initiativeId
        );
        const labelIds = initaitive.labelIds;

        const id = generateId();
        const docs = indexHelper<CollaborativeDoc>(
          getters,
          collaborativeDocsByEntity,
          initaitive.id
        );
        const doc = docs[0];

        const { contents: description, createTodos } = duplicateTodosHelper(
          getters,
          doc?.content ?? emptyDocument(),
          id,
          user.id
        );

        const { initiative: created } = createInitiativeHelper(
          tx,
          getters,
          `${initaitive.title} (copy)`,
          user.id,
          organization.id,
          {
            roadmaps: [
              {
                roadmapColumnId: columnId,
                roadmapId: roadmap.id,
                sort,
              },
            ],
            color: initaitive.color,
            memberIds: initaitive.memberIds,
            labelIds,
            spaceIds: initiativeSpaces.map(s => s.spaceId),
            description,
          }
        );
        results.push(created.id);
        sort = between({ after: sort, before: sortBefore?.sort });
        createTodos(tx, getters);
      }

      return results;
    });
  };
}

export function useAddInitiativesToRoadmapColumn() {
  const modelManager = useModelManager();

  return (initiativeIds: string[], columnId: string) => {
    modelManager.transaction((tx, getters) => {
      const roadmapColumn = getters.get<RoadmapColumn>(columnId);
      if (!roadmapColumn) {
        return;
      }

      let sort = between({ after: lastSortForColumn(getters, columnId) });

      for (const initiativeId of initiativeIds) {
        tx.create<RoadmapInitiative>({
          __typename: 'RoadmapInitiative',
          columnId,
          initiativeId: initiativeId,
          sort,
          previousColumnId: null,
          lastColumnUpdate: Date.now(),
        });
        sort = between({ after: sort });
      }
    });
  };
}

export function useDeleteRoadmapInitiatives() {
  const modelManager = useModelManager();

  return (roadmapInitaitiveIds: string[]) => {
    modelManager.transaction(tx => {
      for (const roadmapInitaitiveId of roadmapInitaitiveIds) {
        tx.update<RoadmapInitiative>(roadmapInitaitiveId, { deleted: true });
      }
    });
  };
}

export function useAddInitiativesToRoadmaps() {
  const modelManager = useModelManager();

  return (initiativeIds: string[], roadmapIds: string[], inputSort?: string) => {
    modelManager.transaction((tx, getters) => {
      for (const roadmapId of roadmapIds) {
        const columns = indexHelper<RoadmapColumn>(getters, roadmapColumnsByRoadmap, roadmapId);
        const column =
          columns.find(c => c.columnType === RoadmapColumnStatusType.Future) ?? columns[0];

        let sort = inputSort ?? between({ after: lastSortForColumn(getters, column.id) });

        for (const initiativeId of initiativeIds) {
          tx.create<RoadmapInitiative>({
            __typename: 'RoadmapInitiative',
            columnId: column.id,
            initiativeId: initiativeId,
            sort,
            previousColumnId: null,
            lastColumnUpdate: Date.now(),
          });
          sort = between({ after: sort });
        }
      }
    });
  };
}

export function useRemoveInitiativesFromRoadmaps() {
  const modelManager = useModelManager();

  return (initiativeIds: string[], roadmapIds: string[]) => {
    modelManager.transaction((tx, getters) => {
      for (const roadmapId of roadmapIds) {
        const columns = indexHelper<RoadmapColumn>(getters, roadmapColumnsByRoadmap, roadmapId);
        const roadmapInitiatives = columns.flatMap(c =>
          indexHelper<RoadmapInitiative>(getters, roadmapInitiativesByColumn, c.id)
        );
        const roadmapInitiativesToDelete = roadmapInitiatives.filter(ri =>
          initiativeIds.includes(ri.initiativeId)
        );
        for (const roadmapInitiative of roadmapInitiativesToDelete) {
          tx.update(roadmapInitiative.id, { deleted: true });
        }
      }
    });
  };
}

export function useUpdateRoadmapInitiativeSorts() {
  const modelManager = useModelManager();

  return (
    columnId: string,
    roadmapInitiativeIds: string[],
    afterInitaitiveId?: string,
    beforeInitiativeId?: string
  ) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const column = get<RoadmapColumn>(columnId);
      if (!column) {
        return;
      }

      const columns = indexHelper<RoadmapColumn>(
        getters,
        roadmapColumnsByRoadmap,
        column.roadmapId
      );
      const roadmapInitiatives = columns
        .flatMap(c => indexHelper<RoadmapInitiative>(getters, roadmapInitiativesByColumn, c.id))
        .filter(ri => ri.columnId === columnId || roadmapInitiativeIds.includes(ri.id));

      const updates = updateSortableSorts(
        roadmapInitiatives,
        roadmapInitiativeIds,
        afterInitaitiveId,
        beforeInitiativeId
      );

      for (const initiativeId in updates) {
        tx.update<RoadmapInitiative>(initiativeId, {
          columnId,
          sort: updates[initiativeId],
        });
      }
    });
  };
}

export function useAddIssuesToInitiatives() {
  const modelManager = useModelManager();

  return (initiativeIds: string[], issueIds: string[]) => {
    modelManager.transaction((tx, { get, getIndex }) => {
      for (const initiativeId of initiativeIds) {
        tx.addToCollection(initiativeIssues, initiativeId, issueIds);
        const spaceIds = uniq(
          filterNotNull(issueIds.map(issueId => get<Issue>(issueId)?.spaceId ?? null))
        );
        addSpacesToInitiative(tx, { get, getIndex }, initiativeId, spaceIds);
      }
    });
  };
}

export function useRemoveIssuesFromInitiatives() {
  const modelManager = useModelManager();
  return (initiativeIds: string[], issueIds: string[]) => {
    modelManager.transaction((tx, { get, getIndex }) => {
      for (const initiativeId of initiativeIds) {
        tx.removeFromCollection(initiativeIssues, initiativeId, issueIds);

        const initiative = get<Initiative>(initiativeId);
        const initiativeSpaces = indexHelper<InitiativeSpace>(
          { get, getIndex },
          initiativeSpacesByInitiative,
          initiativeId
        );
        const initiativeSpaceIds = initiativeSpaces.map(i => i.spaceId);
        const initiativeIssueIds = initiative?.issueIds ?? [];
        const remainingIds = initiativeIssueIds.filter(id => !issueIds.includes(id));
        const issues = remainingIds.map(i => get<Issue>(i));
        const remainingSpaceIds = uniq(issues.map(i => i?.spaceId));
        const spaceIdsToRemove = initiativeSpaceIds.filter(id => !remainingSpaceIds.includes(id));

        for (const space of initiativeSpaces) {
          if (spaceIdsToRemove.includes(space.spaceId)) {
            tx.update<InitiativeSpace>(space.id, { deleted: true });
          }
        }
      }
    });
  };
}

const moveRoadmapInitiativeContext = atom<{
  onMove?: (issues: RoadmapInitiative[], statusId: string) => (() => void) | null;
} | null>({
  key: 'MoveRoadmapInitiativeContext',
  default: null,
});

export function useMoveRoadmapInitiativesContext(callbacks: {
  onMove?: (roadmapInitiatives: RoadmapInitiative[], columnId: string) => (() => void) | null;
}) {
  const setContext = useSetRecoilState(moveRoadmapInitiativeContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useMoveInitiatives() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  const context = useRecoilValue(moveRoadmapInitiativeContext);

  return (
    roadmapInitaitiveIds: string[],
    columnId: string,
    options?: { onUndo?: () => void; top?: boolean; disableUndo?: boolean }
  ) => {
    const { onUndo, top, disableUndo } = options ?? {};
    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      const column = get<RoadmapColumn>(columnId);
      if (!column) {
        return;
      }

      const roadmapInitiatives = filterNotNull(
        roadmapInitaitiveIds.map(roadmapInitiativeId => get<RoadmapInitiative>(roadmapInitiativeId))
      );
      if (!roadmapInitiatives.length) {
        return;
      }
      const moveType = 'Moved';

      const undoMoves: Array<{
        roadmapInitiativeId: string;
        columnId: string;
        sort: string;
      }> = [];

      const firstSort = firstSortForColumn({ getIndex, get }, columnId);
      const lastSort = lastSortForColumn({ getIndex, get }, columnId);
      let sort = top ? between({ before: firstSort }) : between({ after: lastSort });

      const contextUndo = context?.onMove?.(roadmapInitiatives, columnId);

      for (const ri of roadmapInitiatives) {
        undoMoves.push({
          roadmapInitiativeId: ri.id,
          columnId: ri.columnId,
          sort: ri.sort,
        });

        tx.update<RoadmapInitiative>(ri.id, {
          columnId,
          sort,
        });

        trackerEvent('Roadmap Initaitive moved', { id: ri.id, type: moveType });
        sort = top ? between({ after: sort, before: firstSort }) : between({ after: sort });
      }

      if (disableUndo) {
        return;
      }

      const action = `moved to ${column.name}`;
      let actionString = <>Initatives {action}</>;

      if (roadmapInitiatives.length === 1) {
        actionString = (
          <>
            <EntityLink id={roadmapInitiatives[0].initiativeId} /> {action}
          </>
        );
      }

      setUndo(actionString, () => {
        modelManager.transaction(tx => {
          for (const undo of undoMoves) {
            tx.update<RoadmapInitiative>(undo.roadmapInitiativeId, {
              columnId: undo.columnId,
              sort: undo.sort,
            });

            trackerEvent('Roadmap Initiative Updated', {
              id: undo.roadmapInitiativeId,
              type: moveType,
              undo: true,
            });
          }
        });

        contextUndo?.();
        onUndo?.();
      });
    });
  };
}

export function useUpdateInitiativeSorts() {
  const organization = useOrganization();
  const modelManager = useModelManager();

  return (initiativeIds: string[], afterInitiativeId?: string, beforeInitiativeId?: string) => {
    modelManager.transaction((tx, getters) => {
      const initiatives = indexHelper<Initiative>(
        getters,
        initiativesByOrganization,
        organization.id
      );

      const updates = updateSortableSorts(
        initiatives,
        initiativeIds,
        afterInitiativeId,
        beforeInitiativeId
      );
      for (const initiativeId in updates) {
        tx.update<Initiative>(initiativeId, {
          sort: updates[initiativeId],
        });
      }
    });
  };
}

export function useUpdateInitiativeSpaceSorts() {
  const modelManager = useModelManager();

  return (
    spaceId: string,
    initiativeSpaceIds: string[],
    afterInitiativeSpaceId?: string,
    beforeInitiativeSpaceId?: string
  ) => {
    modelManager.transaction((tx, getters) => {
      const initiativeSpaces = indexHelper<InitiativeSpace>(
        getters,
        initiativeSpacesBySpace,
        spaceId
      );
      const updates = updateSortableSorts(
        initiativeSpaces,
        initiativeSpaceIds,
        afterInitiativeSpaceId,
        beforeInitiativeSpaceId
      );
      for (const initiativeId in updates) {
        tx.update<Initiative>(initiativeId, {
          sort: updates[initiativeId],
        });
      }
    });
  };
}
