import * as React from 'react';
import { useRecoilValue } from 'recoil';
import uuid from 'uuid';
import { Elements } from '../../../../shared/slate/types';
import { stringifyDocument } from '../../../../shared/slate/utils';
import { issueTerm } from '../../../../shared/utils/terms';
import {
  Entity,
  Initiative,
  Issue,
  Organization,
  Space,
  Todo,
  User,
} from '../../../../sync/__generated/models';
import { Icon, IconSize } from '../../../components/new/icon';
import { KeyboardShortcut } from '../../../components/new/keyboardShortcut';
import { InitiativeIcon } from '../../../components/new/metadata/initiative';
import Pill from '../../../components/new/metadata/pill';
import { statusToIcon } from '../../../components/new/statusIcon';
import { Tooltip } from '../../../components/new/tooltip';
import { TooltipIfTruncated } from '../../../components/new/tooltipIfTruncated';
import { useConfiguration } from '../../../contexts/configurationContext';
import {
  SpaceBoundEntity,
  entityKeySelector,
  entitySelector,
  isMentionableEntity,
  isSpaceBoundEntity,
  spaceForEntitySelector,
} from '../../../syncEngine/selectors/entities';
import { isInitiative } from '../../../syncEngine/selectors/intiatives';
import { isIssue, statusSelector } from '../../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../../syncEngine/selectors/issueStatuses';
import {
  todoKeySelector,
  todoSelector,
  todoStatusToIcon,
} from '../../../syncEngine/selectors/todos';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { SuggestionMatcher, SuggestionOption } from './withSuggestions';

function EntityItem({
  entityId,
  disableTooltip,
  showDeletedPlaceholder,
  todoCount,
}: {
  entityId: string;
  disableTooltip?: boolean;
  showDeletedPlaceholder?: boolean;
  todoCount?: number;
}) {
  const entity = useRecoilValue(entitySelector(entityId));
  const space = useRecoilValue(spaceForEntitySelector(entity?.id));
  const status = useRecoilValue(statusSelector((entity as Issue)?.statusId));
  const isDefault = useRecoilValue(isDefaultStatusSelector(status?.id));
  const entityKey = useRecoilValue(entityKeySelector(entityId));
  const { featureFlags } = useConfiguration();

  if (!entity || (isSpaceBoundEntity(entity) && !space)) {
    if (showDeletedPlaceholder) {
      return (
        <>
          <Icon size={IconSize.Size20} icon={'none'} />
          <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
            Inaccessible work item or initiative
          </span>
        </>
      );
    }
    return null;
  }
  const term = isIssue(entity) ? issueTerm : entity.__typename.toLowerCase();

  let icon = undefined;

  if (status) {
    icon = <Icon size={IconSize.Size20} icon={statusToIcon(status.statusType, isDefault)} />;
  } else if (isInitiative(entity)) {
    icon = entity.archivedAt ? (
      <Icon size={IconSize.Size20} icon="archive" />
    ) : (
      <InitiativeIcon color={entity.color} />
    );
  }

  if (!featureFlags.FEATURE_TOGGLE_TODO_SUGGESTIONS || todoCount === 0) {
    return (
      <>
        {icon}
        <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
          {entityKey}
        </span>
        <TooltipIfTruncated disableTooltip={disableTooltip}>
          {entity.title || `Untitled ${term}`}
        </TooltipIfTruncated>
      </>
    );
  }

  return (
    <div className="rowBetween fullWidth">
      <div className="row metadataGap">
        {icon}
        <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
          {entityKey}
        </span>
        <TooltipIfTruncated disableTooltip={disableTooltip}>
          {entity.title || `Untitled ${term}`}
        </TooltipIfTruncated>
      </div>
      <Tooltip
        content={
          <>
            Press <KeyboardShortcut shortcut="TAB" /> to see todos
          </>
        }
      >
        <Pill>{todoCount} todos</Pill>
      </Tooltip>
    </div>
  );
}

function TodoItem({
  todoId,
  disableTooltip,
  showDeletedPlaceholder,
}: {
  todoId: string;
  disableTooltip?: boolean;
  showDeletedPlaceholder?: boolean;
}) {
  const todo = useRecoilValue(todoSelector(todoId));
  const todoKey = useRecoilValue(todoKeySelector(todoId));

  if (!todo && showDeletedPlaceholder) {
    return (
      <>
        <Icon size={IconSize.Size20} icon={'none'} />
        <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
          Inaccessible todo
        </span>
      </>
    );
  }

  if (!todo) {
    return null;
  }

  return (
    <>
      <Icon icon={todoStatusToIcon(todo.status)} />
      <span className="noWrap" style={{ color: 'var(--grayA10)' }}>
        {todoKey}
      </span>
      <TooltipIfTruncated disableTooltip={disableTooltip}>
        {stringifyDocument(todo.todoContents)}
      </TooltipIfTruncated>
    </>
  );
}

export function entitySuggestionMatcher(
  organization: Organization,
  currentUser: User,
  spaces: Space[],
  defaultOptionsForSpace: (spaceId: string) => Entity[],
  defaultEntitiesForOrganization: (organizationId: string) => Entity[],
  searchEntities: (searchQuery: string | null) => Promise<Entity[]>,
  createIssue: (title: string, spaceId: string) => Issue | null,
  createInitiative: (title: string) => Initiative | null,
  filterInitiatives: (initiatives: Initiative[]) => Initiative[],
  getTodos: (entityId: string) => Todo[]
): SuggestionMatcher[] {
  function toOption(entity: Entity): SuggestionOption {
    const todoCount = getTodos(entity.id).length;

    return {
      id: entity.id,
      value: entity.number,
      render: function RenderEntitySuggestion() {
        return <EntityItem entityId={entity.id} todoCount={todoCount} />;
      },
      expandable: todoCount > 0,
    };
  }

  function todoOption(todo: Todo): SuggestionOption {
    return {
      id: `todo-${todo.id}`,
      value: todo.key,
      render: () => {
        return <TodoItem todoId={todo.id} />;
      },
    };
  }

  function defaultOptions(spaceId: string): SuggestionOption[] {
    const space = spaces.find(s => s.id === spaceId);
    if (!space || space.deleted) {
      return [];
    }

    return defaultOptionsForSpace(space.id).map(toOption);
  }

  const initiativeMatcher: SuggestionMatcher = {
    id: `initiatives`,
    prefix: `I-`,
    options: () =>
      filterInitiatives(
        defaultEntitiesForOrganization(organization.id).filter(e => isInitiative(e)) as Initiative[]
      ).map(toOption),
    className: 'menuHuge',
    search: async (toSearch: string) => {
      const searchString = toSearch.match(/^\d+$/) ? `I-${toSearch}` : toSearch;
      const results = (await searchEntities(searchString)).filter(r =>
        isInitiative(r)
      ) as Initiative[];
      const options = filterInitiatives(results).map(toOption);
      options.push({
        id: `create-initiative-${toSearch}`,
        value: toSearch,
        render: function RenderNewInitiative() {
          return <div>Create initiative &quot;{toSearch}&quot;</div>;
        },
        footer: true,
        suggestionAutoCompleteOnly: true,
      });
      return options;
    },
    onMatch: (editor, option, range, autocomplete) => {
      if (option.id.startsWith('todo-')) {
        KitemakerTransforms.insertMention(
          editor,
          {
            type: Elements.TodoMention,
            actorId: currentUser.id,
            todoId: option.id.replace('todo-', ''),
            mentionId: uuid.v4(),
            children: [{ text: '' }],
          },
          range,
          autocomplete
        );
        return;
      }

      let entityId = option.id;
      if (entityId.startsWith('create-initiative-')) {
        const entity = createInitiative(option.value);
        if (!entity) {
          return;
        }
        entityId = entity.id;
      }
      KitemakerTransforms.insertMention(
        editor,
        {
          type: Elements.Entity,
          actorId: currentUser.id,
          entityId,
          mentionId: uuid.v4(),
          children: [{ text: '' }],
        },
        range,
        autocomplete
      );
    },
    expandedOptions: option => {
      const todos = getTodos(option.id);

      if (todos.length === 0) {
        return [
          {
            id: `no-todos`,
            value: 'no-todos',
            render: () => {
              return <div>No todos found for this initative</div>;
            },
            suggestionAutoCompleteOnly: true,
          },
        ];
      }

      return todos.map(todoOption);
    },
    handleTrailingSpace: true,
  };

  const spaceEntityMatchers: SuggestionMatcher[] = spaces.map(space => {
    return {
      id: `entities-${space.id}`,
      prefix: `${space.key}-`,
      options: () => defaultOptions(space.id),
      className: 'menuHuge',
      search: async (toSearch: string) => {
        const searchString = toSearch.match(/^\d+$/) ? `${space.key}-${toSearch}` : toSearch;
        const results = (await searchEntities(searchString)).filter(
          r => isMentionableEntity(r) && isSpaceBoundEntity(r)
        ) as SpaceBoundEntity[];
        const options = results.filter(result => result.spaceId === space.id).map(toOption);
        options.push({
          id: `create-work-item-${toSearch}`,
          value: toSearch,
          render: function RenderNewIssue() {
            return (
              <div>
                Create {issueTerm} &quot;{toSearch}&quot;
              </div>
            );
          },
          footer: true,
          suggestionAutoCompleteOnly: true,
        });
        return options;
      },
      onMatch: (editor, option, range, autocomplete) => {
        if (option.id.startsWith('todo-')) {
          KitemakerTransforms.insertMention(
            editor,
            {
              type: Elements.TodoMention,
              actorId: currentUser.id,
              todoId: option.id.replace('todo-', ''),
              mentionId: uuid.v4(),
              children: [{ text: '' }],
            },
            range,
            autocomplete
          );
          return;
        }

        let entityId = option.id;
        if (entityId.startsWith('create-work-item-')) {
          const entity = createIssue(option.value, space.id);
          if (!entity) {
            return;
          }
          entityId = entity.id;
        }
        KitemakerTransforms.insertMention(
          editor,
          {
            type: Elements.Entity,
            actorId: currentUser.id,
            entityId,
            mentionId: uuid.v4(),
            children: [{ text: '' }],
          },
          range,
          autocomplete
        );
      },
      expandedOptions: option => {
        const todos = getTodos(option.id);

        if (todos.length === 0) {
          return [
            {
              id: `no-todos`,
              value: 'no-todos',
              render: function RenderNewIssue() {
                return <div>No todos found for this entity</div>;
              },
              suggestionAutoCompleteOnly: true,
            },
          ];
        }

        return todos.map(todoOption);
      },
      handleTrailingSpace: true,
    };
  });

  return [...spaceEntityMatchers, initiativeMatcher];
}
