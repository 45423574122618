import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { DefaultFocusElement } from '../../../components/defaultFocusElement';
import { ListView, ListViewItem, NO_KEYNAV } from '../../../components/new/listView';
import styles from '../../../components/new/menu/menu.module.scss';
import { useOrganization } from '../../../contexts/organizationContext';
import { starredSelector } from '../../../syncEngine/selectors/starred';

function hotkey(index: number) {
  if (index === 9) {
    return '0';
  }
  if (index < 9) {
    return `${index + 1}`;
  }
  return undefined;
}

export function StarredContents({ onClose }: { onClose: () => void }) {
  const organization = useOrganization();
  const starred = useRecoilValue(starredSelector(organization.id));
  const history = useHistory();

  const entityItems: ListViewItem[] = starred.map((favorite, index) => ({
    id: favorite.id,
    icon: favorite.icon,
    contents: (
      <div
        className="row"
        style={
          {
            gap: `var(--menu-item-gap)`,
          } as React.CSSProperties
        }
      >
        {favorite.name}
      </div>
    ),
    hotkey: hotkey(index),
    onSelected: () => {
      onClose();
      history.push({
        pathname: favorite.link,
        state: {
          backUrl: location.pathname,
          backSearch: location.search,
        },
      });
    },
  }));

  return (
    <div className="rowStretch fullWidth fullHeight">
      <DefaultFocusElement />
      <ListView
        className={styles.content}
        itemClassName={styles.item}
        items={[
          {
            id: NO_KEYNAV,
            key: 'header',
            contents: <div className={styles.listItemHeader}>Starred</div>,
          },
          ...entityItems,
        ]}
      />
    </div>
  );
}
