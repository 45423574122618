import cn from 'classnames';
import * as React from 'react';
import styles from './divider.module.scss';

export function Divider({
  children,
  className,
  style,
  leftAlign,
}: {
  leftAlign?: boolean;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <div className={cn(styles.divider, { [styles.leftAlign]: leftAlign }, className)} style={style}>
      {children && (
        <>
          <div className={styles.line} />
          <div className="bodyM">{children}</div>
          <div className={styles.line} />
        </>
      )}
      {!children && <div className={styles.plainLine} />}
    </div>
  );
}
