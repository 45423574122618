import { flatMap, keyBy, partition } from 'lodash';
import React from 'react';
import { atom, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { randomString } from '../../../shared/utils/random';
import { between } from '../../../shared/utils/sorting';
import {
  roadmapColumnsByRoadmap,
  roadmapInitiativesByColumn,
  roadmapsByOrganization,
  spaceRoadmapsByRoadmap,
} from '../../../sync/__generated/indexes';
import {
  Initiative,
  IssueStatusSortMode,
  Roadmap,
  RoadmapColumn,
  RoadmapColumnStatusType,
  RoadmapInitiative,
  RoadmapType,
  SpaceRoadmap,
} from '../../../sync/__generated/models';
import { toast } from '../../components/toast';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { nextAvailableNumber } from '../../utils/entities';
import { sortModeToString } from '../selectors/issues';
import { spacesForRoadmapSelector } from '../selectors/spaces';
import { indexHelper, updateSortableSorts } from './helpers';
import { inverseSortMode } from './issueStatuses';

export function useCreateRoadmapColumn() {
  const modelManager = useModelManager();

  return (roadmapId: string, name: string, columnType: RoadmapColumnStatusType): RoadmapColumn => {
    return modelManager.transaction((tx, getters) => {
      const otherColumns = indexHelper<RoadmapColumn>(getters, roadmapColumnsByRoadmap, roadmapId);
      const lastSort = otherColumns[otherColumns.length - 1]?.sort ?? between({});

      const column: SyncEngineCreate<RoadmapColumn> = {
        __typename: 'RoadmapColumn',
        name,
        columnType,
        sort: between({ after: lastSort }),
        sortMode: IssueStatusSortMode.Manual,
        roadmapId,
      };
      return tx.create<RoadmapColumn>(column);
    });
  };
}

export function createRoadmapHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  name: string,
  organizationId: string,
  actorId: string,
  options?: { color?: string; sort?: string; type?: RoadmapType }
) {
  const roadmaps = indexHelper<Roadmap>(getters, roadmapsByOrganization, organizationId);

  const sort =
    options?.sort ?? between({ after: roadmaps[roadmaps.length - 1]?.sort ?? between({}) });

  const roadmapType = options?.type ?? RoadmapType.Column;

  const roadmap: SyncEngineCreate<Roadmap> = {
    __typename: 'Roadmap',
    name,
    number: nextAvailableNumber(getters, organizationId, 'roadmap'),
    sort,
    color: options?.color ?? 'gray',
    actorId,
    organizationId,
    shared: false,
    sharedMetadata: false,
    roadmapType,
  };
  const roadmapResult = tx.create<Roadmap>(roadmap);

  const nowColumn: SyncEngineCreate<RoadmapColumn> = {
    __typename: 'RoadmapColumn',
    name: roadmapType === RoadmapType.Timeline ? 'Timeline' : 'Now',
    columnType: RoadmapColumnStatusType.Present,
    sort: between({}),
    sortMode: IssueStatusSortMode.Manual,
    roadmapId: roadmapResult.id,
  };
  const nowColumnResult = tx.create<RoadmapColumn>(nowColumn);

  if (roadmapType === RoadmapType.Timeline) {
    return {
      roadmap: roadmapResult,
      nowColumn: nowColumnResult,
    };
  }

  const nextColumn: SyncEngineCreate<RoadmapColumn> = {
    __typename: 'RoadmapColumn',
    name: 'Next',
    columnType: RoadmapColumnStatusType.Future,
    sort: between({ after: nowColumnResult.sort }),
    sortMode: IssueStatusSortMode.Manual,
    roadmapId: roadmapResult.id,
  };

  const nextColumnResult = tx.create<RoadmapColumn>(nextColumn);

  const laterColumn: SyncEngineCreate<RoadmapColumn> = {
    __typename: 'RoadmapColumn',
    name: 'Later',
    columnType: RoadmapColumnStatusType.Future,
    sort: between({ after: nextColumnResult.sort }),
    sortMode: IssueStatusSortMode.Manual,
    roadmapId: roadmapResult.id,
  };

  const laterColumnResult = tx.create<RoadmapColumn>(laterColumn);
  return {
    roadmap: roadmapResult,
    nowColumn: nowColumnResult,
    nextColumn: nextColumnResult,
    laterColumn: laterColumnResult,
  };
}

export function useCreateRoadmap() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (
    name: string,
    color: string,
    initiativeIds: string[],
    spaceIds?: string[],
    type?: RoadmapType
  ): Roadmap => {
    return modelManager.transaction((tx, getters) => {
      const { nowColumn, roadmap } = createRoadmapHelper(
        tx,
        getters,
        name,
        organization.id,
        user.id,
        { color, type }
      );

      for (const spaceId of spaceIds ?? []) {
        tx.create<SpaceRoadmap>({
          __typename: 'SpaceRoadmap',
          roadmapId: roadmap.id,
          spaceId,
          sort: between({}),
        });
      }

      let sort = undefined;
      for (const initiativeId of initiativeIds) {
        sort = between({ after: sort });
        tx.create<RoadmapInitiative>({
          __typename: 'RoadmapInitiative',
          columnId: nowColumn.id,
          initiativeId,
          sort: between({ after: sort }),
          previousColumnId: null,
          lastColumnUpdate: Date.now(),
        });
      }

      return roadmap;
    });
  };
}

export function useUpdateRoadmaps() {
  const modelManager = useModelManager();
  return (roadmapIds: string[], update: SyncEngineUpdateWithoutDelete<Roadmap>) => {
    modelManager.transaction(tx => {
      for (const roadmapId of roadmapIds) {
        tx.update<Roadmap>(roadmapId, update);
      }
    });
  };
}

export function useUpdateRoadmapColumn() {
  const modelManager = useModelManager();
  return (columnIds: string[], update: SyncEngineUpdateWithoutDelete<RoadmapColumn>) => {
    modelManager.transaction(tx => {
      for (const columnId of columnIds) {
        tx.update<RoadmapColumn>(columnId, update);
      }
    });
  };
}

export function useUpdateRoadmapColumnSortMode() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();

  return (columnId: string, newSortMode: IssueStatusSortMode) => {
    modelManager.transaction((tx, { get }) => {
      const column = get(columnId) as RoadmapColumn | undefined;
      if (!column) {
        return;
      }
      trackerEvent('Column SortMode Set', { id: columnId, mode: newSortMode });
      const oldSortMode = column.sortMode;

      tx.update<RoadmapColumn>(columnId, { sortMode: newSortMode });

      let undoContent = null;
      switch (newSortMode) {
        case IssueStatusSortMode.LastStatusAsc:
        case IssueStatusSortMode.CreatedAsc:
        case IssueStatusSortMode.UpdatedAsc:
        case IssueStatusSortMode.EffortAsc:
        case IssueStatusSortMode.ImpactAsc:
        case IssueStatusSortMode.CycleAsc:
        // @ts-expect-error we intentionally want to fall through here
        // eslint-disable-next-line no-fallthrough
        case IssueStatusSortMode.ImpactEffortAsc:
          if (newSortMode !== inverseSortMode(column.sortMode)) {
            undoContent = (
              <>
                <span className="semiBold">{column.name}</span> is now ordered by{' '}
                <span className="semiBold">{sortModeToString(newSortMode, true)}.</span>
              </>
            );
            break;
          }

        // eslint-disable-next-line no-fallthrough
        case IssueStatusSortMode.LastStatusDesc:
        case IssueStatusSortMode.CreatedDesc:
        case IssueStatusSortMode.UpdatedDesc:
        case IssueStatusSortMode.EffortDesc:
        case IssueStatusSortMode.ImpactDesc:
        case IssueStatusSortMode.ImpactEffortDesc:
        case IssueStatusSortMode.CycleDesc:
          undoContent = (
            <>
              You reversed the order of <span className="semiBold">{column.name}.</span>
            </>
          );
          break;

        case IssueStatusSortMode.Manual:
          undoContent = (
            <>
              <span className="semiBold">{column.name}</span> is now ordered manually. Drag & drop{' '}
              initiatives to rearrange them.
            </>
          );
          break;
      }

      setUndo(undoContent, () => {
        modelManager.transaction(tx => {
          tx.update<RoadmapColumn>(columnId, {
            sortMode: oldSortMode,
          });
        });
      });
    });
  };
}

export function useDeleteColumn() {
  const modelManager = useModelManager();
  return (columnId: string) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const roadmapInitiatives = indexHelper<RoadmapInitiative>(
        getters,
        roadmapInitiativesByColumn,
        columnId
      );
      const inititaives = filterNotDeletedNotNull(
        roadmapInitiatives.map(ri => get<Initiative>(ri.initiativeId))
      );

      const [archived, active] = partition(inititaives, i => !!i.archivedAt);

      if (active.length) {
        toast.info('Only empty columns may be deleted');
        return;
      }

      if (archived.length) {
        const roadmapInitiativesByColumn = keyBy(roadmapInitiatives, ri => ri.initiativeId);
        for (const initiative of archived) {
          const roadmapInitiative = roadmapInitiativesByColumn[initiative.id];
          tx.update<RoadmapInitiative>(roadmapInitiative.id, {
            deleted: true,
          });
        }
      }

      tx.update<RoadmapColumn>(columnId, {
        deleted: true,
        name: `__deleted__${randomString(8)}`,
      });
    });
  };
}

const deleteRoadmapsContext = atom<{
  onDelete?: (roadmaps: Roadmap[]) => void;
} | null>({
  key: 'DeleteRoadmapsContext',
  default: null,
});

export function useDeleteRoadmapsContext(callbacks: { onDelete?: (roadmaps: Roadmap[]) => void }) {
  const setContext = useSetRecoilState(deleteRoadmapsContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useDeleteRoadmaps() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const context = useRecoilValue(deleteRoadmapsContext);

  const getSpaces = useRecoilCallback(
    ({ snapshot }) =>
      (roadmapIds: string[]) => {
        return flatMap(roadmapIds, roadmapId =>
          snapshot.getLoadable(spacesForRoadmapSelector(roadmapId)).getValue()
        );
      },
    []
  );

  return async (roadmapIds: string[]): Promise<boolean> => {
    if (!roadmapIds.length) {
      return false;
    }
    const spaces = getSpaces(roadmapIds);

    let spacesContent = null;

    if (spaces.length > 0) {
      spacesContent = (
        <div>
          {roadmapIds.length > 1 ? `These roadmaps are` : `This roadmap is`} in{' '}
          {spaces.length > 1 ? (
            <>
              the following spaces:
              <ul className="bulletedList">
                {spaces.map(space => (
                  <li key={space.key}>
                    <span className="semiBold">{space.name}</span>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <>
              the space <span className="semiBold">{spaces[0].name}</span>
            </>
          )}
        </div>
      );
    }

    const confirmed = await confirm(
      `Delete roadmap${roadmapIds.length > 1 ? 's' : ''}`,
      <>
        {roadmapIds.length > 1 ? `These roadmaps` : `This roadmap`} will be deleted permanently.
        There is no way to undo this operation. Are you sure you want to proceed?
        {spacesContent}
      </>,
      {
        label: 'Delete',
        destructive: true,
      }
    );

    if (!confirmed) {
      return false;
    }

    const deleted: Roadmap[] = [];

    modelManager.transaction((tx, getters) => {
      const { get } = getters;
      for (const roadmapId of roadmapIds) {
        const roadmap = get<Roadmap>(roadmapId);
        if (!roadmap || roadmap.deleted) {
          continue;
        }

        const roadmapColumns = indexHelper<SpaceRoadmap>(
          getters,
          roadmapColumnsByRoadmap,
          roadmapId
        );

        const roadmapInitiatives = roadmapColumns.flatMap(column =>
          indexHelper<RoadmapInitiative>(getters, roadmapInitiativesByColumn, column.id)
        );
        for (const roadmapInitiative of roadmapInitiatives) {
          tx.update<RoadmapInitiative>(roadmapInitiative.id, {
            deleted: true,
          });
        }

        for (const roadmapColumn of roadmapColumns) {
          tx.update<RoadmapColumn>(roadmapColumn.id, {
            deleted: true,
          });
        }

        // do the actual deletion
        tx.update<Roadmap>(roadmapId, {
          deleted: true,
          name: `__deleted__${randomString(8)}`,
        });

        const spaceRoadmaps = indexHelper<SpaceRoadmap>(getters, spaceRoadmapsByRoadmap, roadmapId);
        for (const spaceRoadmap of spaceRoadmaps) {
          tx.update<SpaceRoadmap>(spaceRoadmap.id, {
            deleted: true,
          });
        }

        deleted.push(roadmap);
        trackerEvent('Roadmap Deleted', { id: roadmapId });
      }
    });

    context?.onDelete?.(deleted);
    return true;
  };
}

export function useUpdateRoadmapSorts() {
  const modelManager = useModelManager();

  return (roadmapId: string, afterRoadmapId?: string, beforeRoadmapId?: string) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;
      const roadmap = get<Roadmap>(roadmapId);
      const organizationId = roadmap?.organizationId;

      if (!organizationId || !roadmap) {
        return;
      }

      const roadmaps = indexHelper<Roadmap>(getters, roadmapsByOrganization, organizationId);

      const updates = updateSortableSorts(roadmaps, [roadmapId], afterRoadmapId, beforeRoadmapId);
      for (const roadmapId in updates) {
        tx.update<Roadmap>(roadmapId, {
          sort: updates[roadmapId],
        });
      }
    });
  };
}
