import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  Activity as ActivityModel,
  IntegrationType,
  MentionedInIntegrationActivityDetails,
} from '../../../../sync/__generated/models';
import { entityTypeSelector } from '../../../syncEngine/selectors/entities';
import { useEmojifyMarkdown } from '../../../utils/emoji';
import { openExternalUrl } from '../../../utils/urls';
import ActorName from '../../actorName';
import Markdown from '../../markdown';
import { ButtonSize, ButtonStyle, IconButton } from '../button';
import { Hotkey } from '../hotkey';
import { useHasKeyNavigationFocus } from '../keyNavigation';
import { Tooltip } from '../tooltip';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function AdditionalActionsForActivity({
  activityDetails,
}: {
  activityDetails: MentionedInIntegrationActivityDetails;
}) {
  if (
    activityDetails.type === IntegrationType.Github &&
    activityDetails.publicMetadata?.branch &&
    activityDetails.publicMetadata?.repoUrl
  ) {
    return (
      <Tooltip content={<>Create pull request in GitHub</>}>
        <IconButton
          icon="git_pull_request_open"
          buttonStyle={ButtonStyle.BareSubtle}
          size={ButtonSize.Small}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            openExternalUrl(
              `${activityDetails.publicMetadata.repoUrl}/compare/${activityDetails.publicMetadata.branch}?expand=1`
            );
          }}
        />
      </Tooltip>
    );
  }

  return null;
}

function MentionedInIntegrationActivityComponent({ activity }: { activity: ActivityModel }) {
  const details = activity.details as MentionedInIntegrationActivityDetails;
  const focused = useHasKeyNavigationFocus(activity.id);
  const entityType = useRecoilValue(entityTypeSelector(activity.entityId));
  const description = React.useMemo(() => {
    return details.description.replace('{{entityType}}', entityType);
  }, [details.description, entityType]);
  const emojify = useEmojifyMarkdown();

  return (
    <Activity
      activity={activity}
      actorId={activity.actorId}
      additionalActions={<AdditionalActionsForActivity activityDetails={details} />}
    >
      <div>
        <ActorName actorId={activity.actorId} className={styles.activityHighlight} />
        <span> </span>
        <Markdown inline>{description}</Markdown>.
        <ActivityTimestamp timestamp={activity.createdAt} />
      </div>
      <div className={styles.activityContext}>
        <Markdown>{emojify(details.context)}</Markdown>
      </div>
      {focused && (
        <Hotkey
          hotkey="enter"
          handler={() => {
            openExternalUrl(details.link);
          }}
        />
      )}
    </Activity>
  );
}

export const MentionedInIntegrationActivity = React.memo(MentionedInIntegrationActivityComponent);
