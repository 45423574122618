import cn from 'classnames';
import { isFunction } from 'lodash';
import * as React from 'react';
import {
  DragDropContext,
  DraggableProvided,
  DraggableRubric,
  DraggableStateSnapshot,
  Droppable,
} from 'react-beautiful-dnd';
import ReactDOM from 'react-dom';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { useRecoilState } from 'recoil';
import { CommandGroup } from '../../../commands';
import { useVirtualizedEnsureVisible } from '../../../hooks/new/useVirtualizedEnsureVisible';
import { isMobileOS } from '../../../utils/config';
import { autoSortedMessage, hideAutoSortedMessage } from '../autoSortedToast';
import { Count } from '../count';
import {
  FocusReason,
  KeyNavigationDisablingElement,
  useClearSelection,
  useGetKeyNavigationState,
  useKeyNavigationColumn,
  useKeyNavigationWatcher,
  useSelectKeyNavigationElementAfterScroll,
  useSetKeyNavigationFocus,
} from '../keyNavigation';
import { DraggableItem } from './draggableItem';
import { CopyAndPasteProperties, Hotkeys } from './hotkeys';
import { ResizableItem } from './resizableItem';
import { RowHeightProvider } from './rowHeightContext';
import { collapsedSections } from './state';
import styles from './virtualizedListView.module.scss';

const NEW_ITEM_ID = 'new-item-';
const PLACEHOLDER_ID = 'placeholder-';
const SECTION_HEADER_ID = 'header-';
const SPACER = 'spacer';
const DEFAULT_HEIGHT = 41;

function EnsureVisible({ ensureVisible }: { ensureVisible: (id: string) => void }) {
  useKeyNavigationWatcher(({ focused, focusedReason }) => {
    if (focused && focusedReason !== FocusReason.Mouse) {
      ensureVisible(focused);
    }
  });

  return null;
}

export function VirtualizedListView({
  id,
  sectionIds,
  itemIds,
  disabledColumnDndMessages,
  onMoveItems,
  onCopy,
  onPaste,
  renderSectionHeader,
  renderItem,
  renderNewItem,
  renderPlaceholder,
  renderAccessories,
  canCreate,
  defaultCollapsed,
  itemHeight,
  resizableItems,
  sectionHeaderHeight,
  spacerHeight,
  interactiveHeaders,
  additionalKeyNavigationIds,
  commandGroup,
  keyNavFocus,
  className,
  style,
}: {
  id: string;
  sectionIds: string[];
  itemIds: Record<string, string[]>;
  disabledColumnDndMessages?: { [columnId: string]: string | React.ReactNode | null };
  onMoveItems?: (ids: string[], toSection: string, toIndex: number) => void;
  renderSectionHeader: (
    sectionId: string,
    collapsed: boolean,
    toggleCollapsed: () => void,
    onNewItem: () => void
  ) => React.ReactNode;
  renderItem: (
    itemId: string,
    sectionId: string,
    isFirst: boolean,
    isLast: boolean,
    edit?: { start?: () => void; end?: () => void }
  ) => React.ReactNode;
  renderNewItem?: (
    sectionId: string,
    index: number,
    isFirst: boolean,
    isLast: boolean,
    onDone: () => void
  ) => React.ReactNode;
  renderPlaceholder: (sectionId: string, onCreateNew: () => void) => React.ReactNode;
  renderAccessories?: (grid: string[]) => React.ReactNode;
  canCreate?: () => boolean;
  defaultCollapsed?: string[];
  itemHeight?: number;
  resizableItems?: boolean;
  sectionHeaderHeight: number | ((sectionId: string) => number);
  spacerHeight?: number;
  interactiveHeaders?: boolean;
  additionalKeyNavigationIds?: string[];
  commandGroup?: CommandGroup;
  keyNavFocus?: React.RefObject<string | null>;
  className?: string;
  style?: React.CSSProperties;
} & CopyAndPasteProperties) {
  const [draggingId, setDraggingId] = React.useState<string | null>(null);
  const [dragCount, setDragCount] = React.useState<number | null>(null);
  const ref = React.useRef<HTMLDivElement | null>(null);
  useSelectKeyNavigationElementAfterScroll(id, ref);

  const sectionsRef = React.useRef(sectionIds);
  sectionsRef.current = sectionIds;
  const itemsRef = React.useRef(itemIds);
  itemsRef.current = itemIds;

  const sizeCache = React.useRef(
    new CellMeasurerCache({
      defaultHeight: DEFAULT_HEIGHT,
      fixedWidth: true,
    })
  );

  const listRef = React.useRef<List | null>();
  const [visibleRange, setVisibleRange] = React.useState<{
    startIndex: number;
    stopIndex: number;
  } | null>(null);
  const visibleRangeRef = React.useRef(visibleRange);
  visibleRangeRef.current = visibleRange;

  const [newItem, _setNewItem] = React.useState<{ sectionId: string; index: number } | null>(null);
  const [editItem, _setEditItem] = React.useState<{ sectionId: string; index: number } | null>(
    null
  );
  const [hoveringOverSection, setHoveringOverSection] = React.useState<string | null>(null);

  const setNewItem = React.useCallback(
    (pos: { sectionId: string; index: number } | null) => {
      if (canCreate && !canCreate()) {
        return;
      }
      _setEditItem(null);
      _setNewItem(pos);
    },
    [canCreate]
  );

  function setEditItem(pos: { sectionId: string; index: number } | null) {
    _setNewItem(null);
    _setEditItem(pos);
  }

  const clearSelection = useClearSelection();
  const setFocus = useSetKeyNavigationFocus();
  const collapsedKey = defaultCollapsed ? { key: id, default: defaultCollapsed } : id;
  const [collapsed, setCollapsed] = useRecoilState(collapsedSections(collapsedKey));

  const grid: string[] = sectionIds.flatMap(sectionId => {
    if (collapsed.includes(sectionId)) {
      return [`${SECTION_HEADER_ID}${sectionId}`];
    }

    const sectionItems = [...(itemIds[sectionId] ?? [])].map(id => `${sectionId}/${id}`);
    if (newItem?.sectionId === sectionId) {
      sectionItems.splice(newItem?.index, 0, `${NEW_ITEM_ID}${sectionId}`);
    } else if (!sectionItems.length) {
      sectionItems.push(`${PLACEHOLDER_ID}${sectionId}`);
    }

    sectionItems.unshift(`${SECTION_HEADER_ID}${sectionId}`);
    return sectionItems;
  });

  const keyNavGrid = [
    ...(additionalKeyNavigationIds ?? []),
    ...grid
      .filter(itemId => interactiveHeaders || !itemId.startsWith(SECTION_HEADER_ID))
      .map(itemId => (itemId.includes('/') ? itemId.substring(itemId.indexOf('/') + 1) : itemId)),
  ];

  const keyNavGridRef = React.useRef(keyNavGrid);
  keyNavGridRef.current = keyNavGrid;

  grid.push(SPACER);
  const gridRef = React.useRef(grid);
  gridRef.current = grid;

  const ensureVisibleGridRef = React.useRef<string[]>([]);
  ensureVisibleGridRef.current = grid.map(itemId =>
    itemId.includes('/') ? itemId.substring(itemId.indexOf('/') + 1) : itemId
  );

  useKeyNavigationColumn(id, keyNavGrid);
  const getKeyNavState = useGetKeyNavigationState();

  const justEnsuredVisibleRef = React.useRef(false);
  const ensureItemIsVisible = useVirtualizedEnsureVisible(
    ensureVisibleGridRef,
    listRef,
    visibleRangeRef,
    () => {
      justEnsuredVisibleRef.current = true;
    }
  );

  const previousGridRef = React.useRef<string[] | null>(null);

  React.useEffect(() => {
    if (!itemHeight) {
      if (previousGridRef.current) {
        const heightsById: Record<string, number> = {};
        const widthsById: Record<string, number> = {};

        let firstNonHeader = true;

        // figure out how big the things are by id
        for (let i = 0; i < previousGridRef.current.length; i++) {
          const id = previousGridRef.current[i];
          // only worry about normal items and skip the top one because things
          // may render differently when they're the first item
          if (id.includes('-')) {
            continue;
          }
          if (firstNonHeader && id.includes('/')) {
            firstNonHeader = false;
            continue;
          }
          const height = sizeCache.current.getHeight(i, 0);
          const width = sizeCache.current.getWidth(i, 0);
          if (height && width && height !== DEFAULT_HEIGHT) {
            heightsById[id] = height;
            widthsById[id] = width;
          }
        }

        sizeCache.current.clearAll();

        // refill the cache with the known sizes
        for (let i = 0; i < gridRef.current.length; i++) {
          const id = gridRef.current[i];
          if (heightsById[id] && widthsById[id]) {
            sizeCache.current.set(i, 0, widthsById[id], heightsById[id]);
          }
        }
        listRef.current?.recomputeRowHeights();
      }

      previousGridRef.current = [...gridRef.current];
    }

    if (keyNavFocus?.current && keyNavGridRef.current.indexOf(keyNavFocus.current) !== -1) {
      ensureItemIsVisible(keyNavFocus.current);
    }
  }, [JSON.stringify(grid)]);

  const initialListRowRef = React.useRef<number | null>(
    keyNavFocus?.current ? gridRef.current.findIndex(id => id.endsWith(keyNavFocus.current!)) : 0
  );

  const recomputeRowHeights = React.useCallback((index?: number) => {
    if (!itemHeight) {
      sizeCache.current.clear(index ?? 0, 0);
    }
    listRef.current?.recomputeRowHeights(index);
  }, []);

  const context = React.useMemo(
    () => ({
      recomputeRowHeights,
    }),
    [recomputeRowHeights]
  );

  const newItemComplete = React.useCallback(() => setNewItem(null), [setNewItem]);

  const placeholderClick = React.useCallback(
    (sectionId: string) => {
      return () => {
        if (!canCreate || !canCreate()) {
          return;
        }
        setNewItem({ sectionId, index: 0 });
      };
    },
    [canCreate, setNewItem]
  );

  return (
    <DragDropContext
      onBeforeDragStart={drag => {
        const { selected } = getKeyNavState();
        const sourceId = drag.draggableId;
        const [, sourceItemId] = sourceId.split('/');
        if (selected && selected.length && !selected.includes(sourceItemId)) {
          clearSelection();
        }
      }}
      onDragStart={drag => {
        setDraggingId(drag.draggableId);
        const { selected } = getKeyNavState();
        setDragCount(selected?.length ?? 1);
        const sourceSectionId = drag.draggableId.split('/')[0];
        const disabledMessage = disabledColumnDndMessages?.[sourceSectionId];
        if (!disabledMessage) {
          return;
        }
        autoSortedMessage(disabledMessage, true);
      }}
      onDragUpdate={initial => {
        const destination = initial.destination;
        if (!destination) {
          return;
        }
        const destinationId = gridRef.current[destination.index];
        let sectionId: string | null = null;

        if (
          destinationId.startsWith(SECTION_HEADER_ID) ||
          destinationId.startsWith(PLACEHOLDER_ID)
        ) {
          sectionId = destinationId.split('-')[1];
        } else if (destinationId.includes('/')) {
          sectionId = destinationId.split('/')[0];
        }
        const sourceSectionId = initial.draggableId.split('/')[0];

        setHoveringOverSection(sectionId);
        if (sectionId) {
          const disabledDndMessage = disabledColumnDndMessages?.[sectionId];
          if (disabledDndMessage) {
            autoSortedMessage(disabledDndMessage, sectionId === sourceSectionId);
          } else {
            hideAutoSortedMessage();
          }
        } else {
          hideAutoSortedMessage();
        }
      }}
      onDragEnd={async ({ source, destination }) => {
        setDraggingId(null);
        setHoveringOverSection(null);
        setTimeout(hideAutoSortedMessage, 100);

        if (!destination) {
          return;
        }

        // don't allow dragging back up above the top header
        if (destination.index === 0) {
          destination.index = 1;
        }

        const sourceId = gridRef.current[source.index];
        const [sourceSectionId, sourceItemId] = sourceId.split('/');

        const destinationId = gridRef.current[destination.index];
        let destinationIndex = -1;
        let destinationSectionId: string | null = null;

        if (
          destinationId.startsWith(SECTION_HEADER_ID) ||
          destinationId.startsWith(PLACEHOLDER_ID)
        ) {
          if (source.index < destination.index) {
            const [, sectionId] = destinationId.split('-');
            destinationSectionId = sectionId;
            destinationIndex = 0;
          } else {
            const previous = gridRef.current[destination.index - 1];
            if (previous.includes('/')) {
              const [sectionId, itemId] = previous.split('/');
              destinationSectionId = sectionId;
              destinationIndex = (itemsRef.current[sectionId] ?? []).indexOf(itemId) + 1;
            } else {
              destinationSectionId = previous
                .replace(SECTION_HEADER_ID, '')
                .replace(PLACEHOLDER_ID, '');
              destinationIndex = 0;
            }
          }
        } else if (destinationId.includes('/')) {
          const [sectionId, itemId] = destinationId.split('/');
          const isLowerSection =
            sectionsRef.current.indexOf(sectionId) > sectionsRef.current.indexOf(sourceSectionId);
          destinationSectionId = sectionId;
          destinationIndex =
            (itemsRef.current[sectionId] ?? []).indexOf(itemId) + (isLowerSection ? 1 : 0);
        }

        if (destinationIndex !== -1 && destinationSectionId) {
          const { selected } = getKeyNavState();
          const itemIds =
            selected && selected.length && selected.includes(sourceItemId)
              ? selected
              : [sourceItemId];
          onMoveItems?.(itemIds, destinationSectionId, destinationIndex);
        }
      }}
    >
      <RowHeightProvider value={context}>
        <Droppable
          droppableId={id}
          mode="virtual"
          renderClone={(
            provided: DraggableProvided,
            snapshot: DraggableStateSnapshot,
            rubric: DraggableRubric
          ) => {
            const itemIndex = rubric.source.index;
            const itemId = gridRef.current[itemIndex];
            const [sectionId, listItemId] = itemId.split('/');

            const { style, ...rest } = provided.draggableProps;
            const draggingOverAutoSorted = disabledColumnDndMessages?.[hoveringOverSection ?? ''];

            if (draggingOverAutoSorted && snapshot.isDropAnimating) {
              (style as any).transitionDuration = `0.001s`;
            }

            return (
              <div id={`clone-${listItemId}`} {...rest} style={style} className="relative">
                {(dragCount ?? 1) > 1 && <Count className={styles.dragCount} count={dragCount!} />}
                {renderItem(listItemId, sectionId, false, false)}
              </div>
            );
          }}
        >
          {droppableProvided => (
            <div
              className={cn(styles.list, className)}
              style={style}
              ref={r => {
                ref.current = r;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Property 'current' does not exist on type 'RefObject<HTMLElement>'
                droppableProvided.innerRef.current = r;
              }}
              {...droppableProvided.droppableProps}
            >
              {renderAccessories?.(keyNavGrid)}
              <Hotkeys
                onMoveItems={onMoveItems}
                onNewItem={renderNewItem ? setNewItem : undefined}
                sectionsRef={sectionsRef}
                itemsRef={itemsRef}
                gridRef={gridRef}
                onCopy={onCopy}
                onPaste={onPaste}
                commandGroup={commandGroup}
              />
              <EnsureVisible ensureVisible={ensureItemIsVisible} />
              {(newItem !== null || editItem !== null) && (
                <KeyNavigationDisablingElement disableKey={`list-${id}`} />
              )}
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    scrollToIndex={
                      initialListRowRef.current !== null && initialListRowRef.current !== -1
                        ? initialListRowRef.current
                        : undefined
                    }
                    onScroll={() => {
                      if (initialListRowRef.current !== null) {
                        setTimeout(() => (initialListRowRef.current = null));
                        return;
                      }
                      if (justEnsuredVisibleRef.current) {
                        justEnsuredVisibleRef.current = false;
                        return;
                      }
                    }}
                    onRowsRendered={setVisibleRange}
                    width={width}
                    height={height}
                    rowCount={grid.length}
                    rowHeight={({ index }) => {
                      const id = grid[index];
                      if (id.startsWith(SECTION_HEADER_ID)) {
                        if (isFunction(sectionHeaderHeight)) {
                          return sectionHeaderHeight(id.replace(SECTION_HEADER_ID, ''));
                        }
                        return sectionHeaderHeight;
                      }
                      if (id === SPACER) {
                        return spacerHeight ?? 0;
                      }
                      if (itemHeight) {
                        return itemHeight;
                      }

                      const height = sizeCache.current.rowHeight({ index });
                      return height;
                    }}
                    ref={ref => {
                      listRef.current = ref;
                      // https://github.com/atlassian/react-beautiful-dnd/blob/master/stories/src/virtual/react-virtualized/board.jsx#L123
                      // leaving the variable names alone as a sign of respect :)
                      if (ref) {
                        // eslint-disable-next-line react/no-find-dom-node
                        const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);
                        if (whatHasMyLifeComeTo instanceof HTMLElement) {
                          droppableProvided.innerRef(whatHasMyLifeComeTo);
                        }
                      }
                    }}
                    rowRenderer={({ key, index, style, parent }) => {
                      if (!grid[index]) {
                        return null;
                      }

                      const itemId = grid[index];
                      if (itemId === SPACER) {
                        return <div key={key} style={style}></div>;
                      }

                      let element: React.ReactNode = null;

                      if (itemId.startsWith(PLACEHOLDER_ID)) {
                        const sectionId = itemId.replace(PLACEHOLDER_ID, '');
                        element = renderPlaceholder(sectionId, placeholderClick(sectionId));
                      } else if (itemId.startsWith(NEW_ITEM_ID)) {
                        const sectionId = itemId.replace(NEW_ITEM_ID, '');
                        const sectionHeaderIndex = grid.indexOf(`${SECTION_HEADER_ID}${sectionId}`);
                        const calculatedIndex = index - sectionHeaderIndex - 1;

                        element = renderNewItem?.(
                          sectionId,
                          calculatedIndex,
                          calculatedIndex === 0,
                          calculatedIndex === (itemIds[sectionId] ?? []).length,
                          newItemComplete
                        );
                      } else if (itemId.startsWith(SECTION_HEADER_ID)) {
                        // FIX FIX move to a separate component so we can memoize and do the collapsed stuff
                        const sectionId = itemId.replace(SECTION_HEADER_ID, '');
                        element = renderSectionHeader(
                          sectionId,
                          collapsed.includes(sectionId),
                          () => {
                            if (newItem?.sectionId === sectionId) {
                              setNewItem(null);
                              setTimeout(() => setFocus(itemId));
                            }
                            setCollapsed(previous => {
                              if (previous.includes(sectionId)) {
                                return previous.filter(collapsedId => collapsedId !== sectionId);
                              }

                              return [...previous, sectionId];
                            });
                          },
                          () => setNewItem({ sectionId, index: 0 })
                        );
                      } else {
                        const [sectionId, listItemId] = itemId.split('/');
                        const itemIdsForSection = itemIds[sectionId] ?? [];
                        const itemIndex = itemIdsForSection.indexOf(listItemId);
                        const isEditItem =
                          editItem?.sectionId === sectionId && editItem.index === itemIndex;

                        element = renderItem(
                          listItemId,
                          sectionId,
                          listItemId === itemIdsForSection[0],
                          listItemId === itemIdsForSection[itemIdsForSection.length - 1],
                          {
                            start: !isEditItem
                              ? () => setEditItem({ sectionId, index: itemIndex })
                              : undefined,
                            end: isEditItem ? () => setEditItem(null) : undefined,
                          }
                        );

                        if (resizableItems) {
                          element = (
                            <ResizableItem
                              onResized={() => {
                                sizeCache.current.clear(index, 0);
                                listRef.current?.recomputeRowHeights(index);
                              }}
                            >
                              {element}
                            </ResizableItem>
                          );
                        }
                        if (!itemHeight) {
                          element = (
                            <CellMeasurer
                              key={key}
                              cache={sizeCache.current}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              {element}
                            </CellMeasurer>
                          );
                        }
                      }

                      let dragSize = 0;
                      let noTransform = false;
                      if (hoveringOverSection) {
                        noTransform = !!disabledColumnDndMessages?.[hoveringOverSection];
                        const draggingIndex = gridRef.current.findIndex(id => id === draggingId);
                        if (draggingIndex > -1 && index > draggingIndex) {
                          dragSize = sizeCache.current.getHeight(draggingIndex, 0);
                        }
                      }

                      return (
                        <div key={key} style={style} className="basePadding">
                          <DraggableItem
                            noTransform={noTransform}
                            className={cn({
                              op50:
                                noTransform &&
                                hoveringOverSection &&
                                itemId.startsWith(hoveringOverSection),
                            })}
                            itemId={itemId}
                            dragSize={dragSize}
                            draggingId={draggingId}
                            index={index}
                            dragDisabled={
                              itemId.includes('-') ||
                              !!newItem ||
                              !!editItem ||
                              isMobileOS ||
                              !onMoveItems
                            }
                            key={`draggable-${itemId}`}
                          >
                            {element}
                          </DraggableItem>
                        </div>
                      );
                    }}
                  />
                )}
              </AutoSizer>
            </div>
          )}
        </Droppable>
      </RowHeightProvider>
    </DragDropContext>
  );
}
