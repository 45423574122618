import { Location } from 'history';
import { Breadcrumb } from '../components/new/breadcrumbs';

export function silentlyUpdateHistoryState(state: Record<string, unknown>) {
  window.history.replaceState({ ...window.history.state, state }, '');
}

export function silentlyUpdatePath(path: string) {
  if (window.location.pathname === path) {
    return;
  }
  history.pushState({}, '', path);
}

export interface LocationState extends Record<string, unknown> {
  backUrl?: string;
  backSearch?: string;
  breadcrumbs?: Breadcrumb[];
  backBreadcrumbs?: Breadcrumb[];
  update?: string;
  entity?: string;
  folder?: string;
  todo?: string;
  node?: string;
  commentThreadId?: string;
  focusDescription?: boolean;
  from?: Location;
  filterId?: string;
  siblingEntities?: string[];
}
