import cn from 'classnames';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useClient } from 'urql';
import {
  SharedIssueQuery,
  SharedIssueQueryVariables,
} from '../../../../graphql__generated__/graphql';
import { emptyDocument } from '../../../shared/slate/utils';
import { generateId } from '../../../shared/utils/id';
import { Issue, MemberRole, Space } from '../../../sync/__generated/models';
import { Breadcrumb, Breadcrumbs } from '../../components/new/breadcrumbs';
import { Button, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import AvatarGroup from '../../components/new/metadata/avatarGroup';
import Effort from '../../components/new/metadata/effort';
import Impact from '../../components/new/metadata/impact';
import Label from '../../components/new/metadata/label';
import { MetadataSize } from '../../components/new/metadata/size';
import { ScreenHeader } from '../../components/new/screenHeader';
import { statusToIcon } from '../../components/new/statusIcon';
import TitleSetter from '../../components/titleSetter';
import { OrganizationProvider } from '../../contexts/organizationContext';
import { SpaceProvider } from '../../contexts/spaceContext';
import { messageFromGraphQLError } from '../../graphql/errors';
import { sharedIssueQuery } from '../../graphql/queries';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { StaticSlateDocument } from '../../slate/staticSlate';
import {
  collaborativeDocIdByEntitySelector,
  collaborativeDocSelector,
} from '../../syncEngine/selectors/collaborativeDoc';
import { effortSelector } from '../../syncEngine/selectors/effortLevels';
import { impactSelector } from '../../syncEngine/selectors/impactLevels';
import { issueSelector, statusSelector } from '../../syncEngine/selectors/issues';
import { isDefaultStatusSelector } from '../../syncEngine/selectors/issueStatuses';
import { labelsSelector } from '../../syncEngine/selectors/labels';
import { organizationsSelector } from '../../syncEngine/selectors/organizations';
import { spaceSelector } from '../../syncEngine/selectors/spaces';
import { usersAndMemberSelector } from '../../syncEngine/selectors/users';
import { useStateTransaction } from '../../syncEngine/state';
import { SyncEngineObject } from '../../syncEngine/types';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from '../errorScreens';
import LoadingScreen from '../loadingScreen';
import workItemStyles from '../new/workItemScreen/workItemScreen.module.scss';
import styles from './sharedIssueScreen.module.scss';

function Members({ organizationId, ids }: { organizationId: string; ids: string[] }) {
  const userMembers = useRecoilValue(usersAndMemberSelector({ organizationId, userIds: ids }));
  return (
    <AvatarGroup
      max={null}
      avatarData={userMembers.map(user => ({
        name: user.name || user.username,
        img: user.avatar,
        id: user.id,
        deactivated: user.member?.deactivated,
        invited: user.member?.invited,
        guest: user.member?.role === MemberRole.Guest,
      }))}
    />
  );
}

function SharedIssuesScreenContents({
  issueId,
  renderMetadata,
}: {
  issueId: string;
  renderMetadata: boolean;
}) {
  const issue = useRecoilValue(issueSelector(issueId));
  const docId = useRecoilValue(collaborativeDocIdByEntitySelector(issueId));
  const doc = useRecoilValue(collaborativeDocSelector(docId ?? ''));
  const space = useRecoilValue(spaceSelector(issue?.spaceId));
  const organization = useRecoilValue(organizationsSelector(space?.organizationId));
  const status = useRecoilValue(statusSelector(issue?.statusId));
  const impact = useRecoilValue(impactSelector(issue?.impactId));
  const effort = useRecoilValue(effortSelector(issue?.effortId));
  const labels = useRecoilValue(labelsSelector(issue?.labelIds ?? []));
  const location = useLocation<{ board: Breadcrumb }>();
  const isDefault = useRecoilValue(isDefaultStatusSelector(issue?.statusId));

  if (!issue || !space || !organization) {
    return null;
  }

  const renderedLabels = labels.map(label => (
    <Label
      key={label.id}
      name={label.name}
      color={label.color}
      size={MetadataSize.Medium}
      noHover
    />
  ));

  return (
    <OrganizationProvider organizationId={organization.id}>
      <SpaceProvider spaceId={space.id}>
        <div className={styles.sharedIssueScreen}>
          <ScreenHeader hideNotifications>
            <Breadcrumbs
              breadcrumbs={[
                { name: space.name },
                location.state?.board ?? { name: 'Work items' },
                { name: `${space.key}-${issue.number}` },
              ]}
            />
          </ScreenHeader>
          <TitleSetter
            title={`${organization.name} · ${space.name} · ${issue.title} by Kitemaker`}
          />
          <Logo className={styles.logo} link />
          <div className="rowStretch grow overflowScrollY fullWidth mb24">
            <div className={cn(workItemStyles.gutter, workItemStyles.left)} />
            <div className={workItemStyles.content}>
              <div className={cn('col', 'noGrow', workItemStyles.contentHeader)}>
                <div className="row rowBetween fullWidth">
                  {renderMetadata && (
                    <>
                      <div className={cn('row', 'flexWrap', workItemStyles.metadataGap)}>
                        {status && (
                          <Button
                            buttonStyle={ButtonStyle.Secondary}
                            icon={statusToIcon(status.statusType, isDefault)}
                            className="mr12"
                          >
                            {status.name}
                          </Button>
                        )}
                      </div>
                      <div className="row noShrink">
                        <Members organizationId={organization.id} ids={issue.assigneeIds} />
                      </div>
                    </>
                  )}
                </div>
                <div className="heading3XL fullWidth">{issue.title || 'Untitled work item'}</div>
                <div className="row metadataGapL maxWidth flexWrap">
                  {renderMetadata && (
                    <>
                      {effort && (
                        <Effort
                          color={effort.color}
                          size={MetadataSize.Medium}
                          longName={effort.name}
                          shortName={effort.abbrevation}
                        />
                      )}
                      {impact && (
                        <Impact
                          color={impact.color}
                          size={MetadataSize.Medium}
                          longName={impact.name}
                          shortName={impact.abbrevation}
                        />
                      )}
                      {renderedLabels}
                    </>
                  )}
                </div>
              </div>
              <StaticSlateDocument
                className={workItemStyles.description}
                value={doc?.content ?? emptyDocument()}
              />
            </div>
            <div className={workItemStyles.gutter} />
          </div>
        </div>
      </SpaceProvider>
    </OrganizationProvider>
  );
}

export function SharedIssueScreen() {
  const { params } = useRouteMatch<{ issueId: string }>();
  const client = useClient();
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');
  const [renderMetadata, setRenderMetadata] = React.useState(false);
  const stateTransaction = useStateTransaction();

  trackerPageLoad('Shared Work Item', {
    issueId: params.issueId,
  });

  useComponentDidMount(() => {
    (async () => {
      const result = await client
        .query<SharedIssueQuery, SharedIssueQueryVariables>(sharedIssueQuery, {
          id: params.issueId,
        })
        .toPromise();

      if (result.error) {
        setError(
          messageFromGraphQLError(result.error.graphQLErrors) ?? 'An unknown error has occurred'
        );
        return;
      }

      if (!result.data?.sharedIssue) {
        setError('An unknown error has ocurred');
        return;
      }

      const {
        members,
        labels,
        space,
        statuses,
        impactLevels,
        effortLevels,
        organization,
        metadata,
        issue,
        todos,
        collaborativeDocs,
      } = result.data.sharedIssue;

      const now = Date.now();
      const defaultProps = {
        updatedAt: now,
        createdAt: now,
        deleted: false,
        version: 0,
      };

      const todosToLoad: SyncEngineObject[] = todos.map(todo => ({
        ...todo,
        ...defaultProps,
        __typename: 'Todo',
      }));

      const labelsToLoad: SyncEngineObject[] = (labels ?? []).map(label => ({
        ...label,
        ...defaultProps,
        __typename: 'IssueLabel',
      }));

      const usersToLoad: SyncEngineObject[] = (members ?? []).map(user => ({
        ...user,
        ...defaultProps,
        __typename: 'User',
      }));

      const organizationToLoad: SyncEngineObject = {
        ...organization,
        ...defaultProps,
        __typename: 'Organization',
      };

      const spaceToLoad: Partial<Space> & SyncEngineObject = {
        ...space,
        ...defaultProps,
        __typename: 'Space',
      };

      const organizationMembersToLoad: SyncEngineObject[] = usersToLoad.map(user => ({
        id: generateId(),
        userId: user.id,
        organizationId: organizationToLoad.id,
        ...defaultProps,
        __typename: 'OrganizationMember',
      }));

      const statusesToLoad: SyncEngineObject[] = (statuses ?? []).map(status => ({
        ...status,
        ...defaultProps,
        __typename: 'IssueStatus',
      }));

      const impactsToLoad: SyncEngineObject[] = (impactLevels ?? []).map(impact => ({
        ...impact,
        ...defaultProps,
        __typename: 'Impact',
      }));

      const effortsToLoad: SyncEngineObject[] = (effortLevels ?? []).map(effort => ({
        ...effort,
        ...defaultProps,
        __typename: 'Effort',
      }));

      const issueToLoad: Partial<Issue> & SyncEngineObject = {
        ...issue,
        ...defaultProps,
        __typename: 'Issue',
      } as any;

      const docsToLoad: SyncEngineObject[] = (collaborativeDocs ?? []).map(doc => ({
        ...doc,
        ...defaultProps,
        __typename: 'CollaborativeDoc',
      }));

      const allObjects = [
        ...todosToLoad,
        ...labelsToLoad,
        ...usersToLoad,
        ...organizationMembersToLoad,
        ...statusesToLoad,
        ...impactsToLoad,
        ...effortsToLoad,
        ...docsToLoad,
        organizationToLoad,
        spaceToLoad,
        issueToLoad,
      ];
      stateTransaction(({ set }) => {
        set(allObjects);
      });
      setRenderMetadata(metadata);
      setLoaded(true);
    })();
  });

  if (!loaded) {
    return <LoadingScreen />;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{error}</BasicErrorScreen>;
  }

  return <SharedIssuesScreenContents issueId={params.issueId} renderMetadata={renderMetadata} />;
}
