import * as React from 'react';
import { useComponentDidMount } from '../hooks/useComponentDidMount';

export function DefaultFocusElement() {
  const ref = React.useRef<HTMLDivElement>(null);

  useComponentDidMount(() => {
    ref.current?.focus();
  });

  return <div tabIndex={-1} ref={ref} />;
}
