import cn from 'classnames';
import debugModule from 'debug';
import { pick } from 'lodash';
import * as React from 'react';
import Modal, { ModalButtonWrapper } from '../components/modal';
import { Button, ButtonStyle } from '../components/new/button';
import { Icon, IconSize } from '../components/new/icon';
import { LoadingSpinner } from '../components/new/loadingSpinner';
import { TextInput } from '../components/new/textInput';
import { toast } from '../components/toast';
import { Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useCurrentUser } from '../contexts/userContext';
import { useUpdateOrganization } from '../syncEngine/actions/organizations';
import { organizationPath } from '../syncEngine/selectors/organizations';
import { allEmojisById } from '../utils/emoji';
import { UploadResult, fileUploader, prepareUpload } from '../utils/fileUploader';
import styles from './newCustomEmojiModal.module.scss';

function ImageUploadControl({ onUploaded }: { onUploaded: (url: string) => void }) {
  const organization = useOrganization();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const acceptedTypes = ['image/png', 'image/gif', 'image/jpeg'];
  const uploadPath = organizationPath(organization, 'emojis').substr(1);
  const debug = debugModule('upload');
  const [dragOver, setDragOver] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [url, setUrl] = React.useState('');

  async function upload(dataTransfer: DataTransfer) {
    if (!dataTransfer.files?.length) {
      return;
    }

    let maxSize: { width: number; height: number } | undefined = { width: 128, height: 128 };
    for (const file of dataTransfer.files) {
      if (!acceptedTypes.includes(file.type)) {
        return;
      }
      if (file.type === 'image/gif') {
        maxSize = undefined;
      }
    }

    try {
      const uploader = fileUploader(uploadPath, {
        onBeforeUpload: () => setUploading(true),
        multi: false,
        maxSize,
      });

      const uploadResults = prepareUpload(uploader, uploadPath, Array.from(dataTransfer.files));
      const uploadResultsCleaned = uploadResults.map(
        res => pick(res, ['url', 'file', 'size', 'name']) as UploadResult
      );

      await uploader.upload();
      onUploaded(uploadResultsCleaned[0].url);
      setUrl(uploadResultsCleaned[0].url);
    } catch (err) {
      toast.error(`There was an error uploading your file${err.message ? `: ${err.message}` : ''}`);
      debug('There was an error uploading your file', err);
    } finally {
      setUploading(false);
    }
  }

  return (
    <div className="rowStretch">
      <div
        onDragLeave={e => {
          e.preventDefault();
          e.stopPropagation();
          setDragOver(false);
        }}
        onDragOver={e => {
          if (e.dataTransfer.types.some(t => t === 'application/json')) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          setDragOver(true);
        }}
        onDrop={async e => {
          e.preventDefault();
          e.stopPropagation();
          if (dragOver) {
            setDragOver(false);
          }

          upload(e.dataTransfer);
        }}
      >
        <input
          type="file"
          className={styles.fileInput}
          ref={fileInputRef}
          accept={acceptedTypes.join(', ')}
          onChange={e => {
            upload(e.currentTarget as unknown as DataTransfer);
          }}
        />
        <div className={cn(styles.imagePreview, { [styles.dragOver]: dragOver })}>
          {uploading && <LoadingSpinner />}
          {!uploading && (
            <>
              {url && <img src={url} className={styles.image} />}
              {!url && <Icon icon="image" size={IconSize.Size40} />}
            </>
          )}
        </div>
      </div>

      <div className="colEnd ml32">
        Select an image
        <Button onClick={() => fileInputRef.current?.click()} className="mt8">
          Upload image
        </Button>
      </div>
    </div>
  );
}

function NewCustomEmojiContent() {
  const modals = useModals();
  const organization = useOrganization();
  const user = useCurrentUser();
  const updateOrganization = useUpdateOrganization();

  const [url, setUrl] = React.useState('');
  const [name, setName] = React.useState('');
  const duplicate =
    Object.keys(organization.customEmojis ?? {}).includes(name) || allEmojisById.has(name);
  const disabled = !url || !name || duplicate;

  const [error, setError] = React.useState('');
  const nameRef = React.useRef(name);
  nameRef.current = name;

  return (
    <form
      className="fullWidth"
      onSubmit={e => {
        e.preventDefault();
        e.stopPropagation();

        if (disabled) {
          return;
        }

        updateOrganization({
          customEmojis: {
            ...organization.customEmojis,
            [name.replace(/[^a-zA-Z0-9_-]/g, '')]: {
              url,
              actorId: user.id,
              timestamp: Date.now(),
            },
          },
        });

        modals.closeModal(Modals.NewCustomEmoji);
      }}
    >
      <div className={styles.content}>
        <p>
          Your custom emoji will be available to everyone in your organization. You will find it in
          the custom section of the emoji picker.
        </p>
        <div className={styles.inputGroup}>
          <span className="oneLine headingM">Give your emoji a name</span>
          <TextInput
            autoFocus
            placeholder="e.g. lol"
            value={name}
            onKeyDown={e => {
              if (!e.key.match(/[a-zA-Z0-9_-]/)) {
                e.preventDefault();
              }
            }}
            onChange={e => {
              setError('');
              const val = e.currentTarget.value;
              setName(val);
            }}
            onIdle={() => {
              if (
                Object.keys(organization.customEmojis ?? {}).includes(nameRef.current) ||
                allEmojisById.has(nameRef.current)
              ) {
                setError('There’s already an emoji with that name. Try a different name.');
              }
            }}
          />
          {error && <div className="errorText mt8">{error}</div>}
        </div>
        <div className={styles.inputGroup}>
          <span className="oneLine headingM">Upload an image</span>
          <ImageUploadControl onUploaded={setUrl} />
        </div>
      </div>

      <ModalButtonWrapper>
        <Button
          type="button"
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => modals.closeModal(Modals.NewCustomEmoji)}
        >
          Cancel
        </Button>

        <Button type="submit" buttonStyle={ButtonStyle.Primary} disabled={disabled}>
          Add emoji
        </Button>
      </ModalButtonWrapper>
    </form>
  );
}

export default function NewCustomEmojiModal() {
  return (
    <Modal modalId={Modals.NewCustomEmoji} title={`Add a custom emoji`}>
      <NewCustomEmojiContent />
    </Modal>
  );
}
