import { capitalize } from 'lodash';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';
import { Effort as EffortModel, Impact as ImpactModel } from '../../../../sync/__generated/models';
import ExternalLink from '../../../components/externalLink';
import { Button, ButtonSize, ButtonStyle } from '../../../components/new/button';
import { ColorPicker } from '../../../components/new/colorPicker';
import Effort from '../../../components/new/metadata/effort';
import Impact from '../../../components/new/metadata/impact';
import { MetadataSize } from '../../../components/new/metadata/size';
import {
  Setting,
  SettingsCard,
  SettingsPage,
  SettingsSection,
} from '../../../components/new/settings';
import { TextInput, TextInputSize } from '../../../components/new/textInput';
import { useMaybeSpace, useSpace } from '../../../contexts/spaceContext';
import {
  useCreateEffort,
  useDeleteEfforts,
  useReorderEfforts,
  useUpdateEfforts,
} from '../../../syncEngine/actions/effort';
import {
  useCreateImpact,
  useDeleteImpacts,
  useReorderImpacts,
  useUpdateImpacts,
} from '../../../syncEngine/actions/impact';
import { effortLevelsForSpaceSelector } from '../../../syncEngine/selectors/effortLevels';
import { impactLevelsForSpaceSelector } from '../../../syncEngine/selectors/impactLevels';
import styles from './settingsScreen.module.scss';

function ImpactEffortItem({
  level,
  index,
  updateLevel,
  deleteLevel,
  editing,
  setEditing,
  onCancel,
}: {
  level: ImpactModel | EffortModel;
  index: number;
  updateLevel: (levelIds: string[], update: any) => void;
  deleteLevel: (levelIds: string[]) => void;
  editing: boolean;
  setEditing: (val: boolean) => void;
  onCancel: () => void;
}) {
  const [update, setUpdate] = React.useState<{ name: string; color: string; abbrevation: string }>({
    name: level.name,
    color: level.color,
    abbrevation: level.abbrevation,
  });
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);

  React.useEffect(() => {
    if (!editing) {
      setUpdate({
        name: level.name,
        color: level.color,
        abbrevation: level.abbrevation,
      });
    }
  }, [editing, level]);

  const mode = level.__typename === 'Effort' ? 'effort' : 'impact';
  const disableSave =
    update.name === '' ||
    update.abbrevation === '' ||
    (update.abbrevation === level.abbrevation &&
      update.color === level.color &&
      update.name === level.name);

  return (
    <Draggable draggableId={level.id} index={index} isDragDisabled={editing}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <SettingsCard
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setEditing(true);
            }}
            className="mb8"
            handleIdentifier={<span style={{ width: 10 }}>{index + 1}</span>}
            draggable
            editing={editing}
            onEdit={() => setEditing(true)}
            onEditingComplete={() => {
              if (!colorPickerOpen) {
                setEditing(false);
              }
            }}
            onDelete={async () => {
              deleteLevel([level.id]);
            }}
            renderEdit={() => (
              <div className={styles.impactEffortListItem}>
                <ColorPicker
                  renderPreview={(c, forceDarkMode) => (
                    <Impact
                      forceDarkMode={forceDarkMode}
                      color={c}
                      shortName={'EX'}
                      longName={'Example'}
                    />
                  )}
                  initialColor={update.color}
                  onColorPicked={c => setUpdate({ ...update, color: c ?? 'gray' })}
                  onOpenChanged={setColorPickerOpen}
                />
                <TextInput
                  inputSize={TextInputSize.Small}
                  value={update.abbrevation}
                  error={update.abbrevation.length === 0}
                  style={{ width: 52 }}
                  autoFocus={true}
                  onChange={e => {
                    const value = e.currentTarget.value;
                    if (value.length < 3) {
                      setUpdate({ ...update, abbrevation: e.currentTarget.value });
                    }
                  }}
                  onKeyDown={e => {
                    if (e.key.toLowerCase() === 'enter' && !disableSave) {
                      e.preventDefault();
                      e.stopPropagation();
                      updateLevel([level.id], update);
                      setEditing(false);
                    }
                    if (e.key.toLowerCase() === 'escape') {
                      onCancel();
                      setEditing(false);
                    }
                  }}
                />
                <TextInput
                  inputSize={TextInputSize.Small}
                  error={update.name.length === 0}
                  className={'grow mr8'}
                  value={update.name}
                  onChange={e => setUpdate({ ...update, name: e.currentTarget.value })}
                  onKeyDown={e => {
                    if (e.key.toLowerCase() === 'enter' && !disableSave) {
                      e.preventDefault();
                      e.stopPropagation();
                      updateLevel([level.id], update);
                      setEditing(false);
                    }
                    if (e.key.toLowerCase() === 'escape') {
                      onCancel();
                      setEditing(false);
                    }
                  }}
                />
                <Button
                  size={ButtonSize.Small}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onCancel();
                    setEditing(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size={ButtonSize.Small}
                  buttonStyle={ButtonStyle.Primary}
                  disabled={disableSave}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    updateLevel([level.id], update);
                    setEditing(false);
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          >
            <div className={styles.previewPill}>
              {mode === 'effort' && (
                <Effort
                  size={MetadataSize.Medium}
                  color={level.color}
                  shortName={level.abbrevation}
                  longName={level.name}
                />
              )}
              {mode === 'impact' && (
                <Impact
                  size={MetadataSize.Medium}
                  color={level.color}
                  shortName={level.abbrevation}
                  longName={level.name}
                />
              )}
            </div>
            {level.name}
          </SettingsCard>
        </div>
      )}
    </Draggable>
  );
}
function dragReorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const [removed] = list.splice(startIndex, 1);
  list.splice(endIndex, 0, removed);
  return list;
}

export function LevelsSection({
  mode,
  levels,
  create,
  reorderLevels,
  updateLevel,
  deleteLevel,
  currentlyEditing,
  setCurrentlyEditing,
  newlyCreated,
  setNewlyCreated,
}: {
  mode: 'impact' | 'effort';
  levels: Array<ImpactModel | EffortModel>;
  create: (spaceId: string | null, name: string, abbrevation: string) => any;
  reorderLevels: (levelIds: string[]) => void;
  updateLevel: (levelIds: string[], update: any) => void;
  deleteLevel: (levelIds: string[], force?: boolean) => void;
  currentlyEditing: string | null;
  setCurrentlyEditing: (val: string | null) => void;
  newlyCreated: string | null;
  setNewlyCreated: (val: string | null) => void;
}) {
  const space = useMaybeSpace();

  return (
    <DragDropContext
      onDragEnd={async ({ source, destination }) => {
        if (!destination || !source) {
          return;
        }
        const orderedIds = dragReorder(
          levels.map(l => l.id),
          source.index,
          destination.index
        );
        reorderLevels(orderedIds);
      }}
    >
      <SettingsSection>
        <div className="rowBetween">
          <div className={styles.heading}>{capitalize(mode)}</div>
          <Button
            buttonStyle={ButtonStyle.BareSubtle}
            icon="add_circle"
            onClick={() => {
              const resp = create(space?.id ?? null, '', '');
              setCurrentlyEditing(resp.id);
              setNewlyCreated(resp.id);
            }}
          >
            Add {mode} level
          </Button>
        </div>
        <Setting vertical>
          <Droppable droppableId={mode}>
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps} className="fullWidth">
                {levels.map((level, index) => (
                  <ImpactEffortItem
                    updateLevel={updateLevel}
                    deleteLevel={deleteLevel}
                    index={index}
                    level={level}
                    key={level.id}
                    editing={currentlyEditing === level.id}
                    setEditing={(val: boolean) => {
                      setCurrentlyEditing(val ? level.id : null);
                    }}
                    onCancel={() => {
                      if (newlyCreated === level.id) {
                        deleteLevel([level.id], true);
                      }
                    }}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </Setting>
      </SettingsSection>
    </DragDropContext>
  );
}

function ImpactSection({
  currentlyEditing,
  setCurrentlyEditing,
  newlyCreated,
  setNewlyCreated,
}: {
  currentlyEditing: string | null;
  setCurrentlyEditing: (val: string | null) => void;
  newlyCreated: string | null;
  setNewlyCreated: (val: string | null) => void;
}) {
  const space = useSpace();
  const levels = useRecoilValue(impactLevelsForSpaceSelector(space.id));
  const create = useCreateImpact();
  const update = useUpdateImpacts();
  const deleteLevel = useDeleteImpacts();
  const reorderLevels = useReorderImpacts();
  return (
    <LevelsSection
      deleteLevel={deleteLevel}
      updateLevel={update}
      mode={'impact'}
      levels={levels}
      create={create}
      reorderLevels={reorderLevels}
      currentlyEditing={currentlyEditing}
      setCurrentlyEditing={setCurrentlyEditing}
      newlyCreated={newlyCreated}
      setNewlyCreated={setNewlyCreated}
    />
  );
}
function EffortSection({
  currentlyEditing,
  setCurrentlyEditing,
  newlyCreated,
  setNewlyCreated,
}: {
  currentlyEditing: string | null;
  setCurrentlyEditing: (val: string | null) => void;
  newlyCreated: string | null;
  setNewlyCreated: (val: string | null) => void;
}) {
  const space = useSpace();
  const levels = useRecoilValue(effortLevelsForSpaceSelector(space.id));
  const create = useCreateEffort();
  const reorderLevels = useReorderEfforts();
  const deleteLevel = useDeleteEfforts();
  const update = useUpdateEfforts();
  return (
    <LevelsSection
      deleteLevel={deleteLevel}
      updateLevel={update}
      mode={'effort'}
      levels={levels}
      create={create}
      reorderLevels={reorderLevels}
      currentlyEditing={currentlyEditing}
      setCurrentlyEditing={setCurrentlyEditing}
      newlyCreated={newlyCreated}
      setNewlyCreated={setNewlyCreated}
    />
  );
}

export function ImpactEffortSettings() {
  const [currentlyEditing, setCurrentlyEditing] = React.useState<string | null>(null);
  const [newlyCreated, setNewlyCreated] = React.useState<string | null>(null);
  return (
    <SettingsPage
      title="Impact & Effort"
      description={
        <>
          <p>
            Using impact and/or effort is a great way to communicate and share rough estimations on
            initiatives, work items, and todos. You can customize the levels to your teams' needs.
          </p>
          <p>
            <ExternalLink
              className="link"
              href="https://guide.kitemaker.co/overview/effort-and-impact-and-prioritization"
            >
              Read more in the guide
            </ExternalLink>
          </p>
        </>
      }
    >
      <ImpactSection
        currentlyEditing={currentlyEditing}
        setCurrentlyEditing={setCurrentlyEditing}
        newlyCreated={newlyCreated}
        setNewlyCreated={setNewlyCreated}
      />
      <EffortSection
        currentlyEditing={currentlyEditing}
        setCurrentlyEditing={setCurrentlyEditing}
        newlyCreated={newlyCreated}
        setNewlyCreated={setNewlyCreated}
      />
    </SettingsPage>
  );
}
