import cn from 'classnames';
import * as React from 'react';
import styles from './archivedNotice.module.scss';
import { Button, ButtonSize, ButtonStyle } from './new/button';
import { Icon } from './new/icon';

export function ArchivedNotice({
  type,
  onUnarchive,
  className,
}: {
  type: string;
  onUnarchive: () => void;
  className?: string;
}) {
  return (
    <div className={cn(className, styles.archivedNotice)}>
      <Icon icon="archive" className="grayIcon mr6" />
      <span className="bodyM mr16">
        <span className="headingS">Archived</span> - This {type} has been archived and is inactive
      </span>
      <Button
        buttonStyle={ButtonStyle.SecondarySubtle}
        size={ButtonSize.Small}
        onClick={onUnarchive}
      >
        Unarchive
      </Button>
    </div>
  );
}
