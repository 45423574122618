import cn from 'classnames';
import * as React from 'react';
import downLeft from '../../assets/images/new/arrow_down_left.svg?url';
import downRight from '../../assets/images/new/arrow_down_right.svg?url';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Hotkey } from '../../components/new/hotkey';
import { Icon, IconSize } from '../../components/new/icon';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerPageLoad } from '../../tracker';
import styles from './onboardingScreen.module.scss';

export function CollaborativeSpecsStep({ onNext }: { onNext: () => void }) {
  useComponentDidMount(() => {
    trackerPageLoad('TutorialCollabrativeSpecs');
  });

  return (
    <div className="colAlignCenter">
      <div className={cn('headingXL', styles.heading)}>
        Collaborative specs to share context with the whole team
      </div>

      <div className={styles.collaborativeSpecsGrid}>
        <div className={cn('colBetween noGrow noMinWidth', styles.tipOne)}>
          <div className={cn(styles.tip, 'bodyXL', 'slightlyGrayed', 'mt8vh')}>
            Every work item is a collaborative document to{' '}
            <span className="semiBold">spec what needs to be built.</span>
            <img
              className={styles.hideOnSmall}
              src={downRight}
              style={{ position: 'absolute', left: 'calc(100% - 20px)' }}
            />
          </div>
          <div className={cn(styles.tip, 'bodyXL', 'slightlyGrayed', 'mb4vh')}>
            <span className="semiBold">Break down tasks directly in your descriptions</span>, avoid
            micro-splitting tasks.
            <img
              className={styles.hideOnSmall}
              src={downRight}
              style={{
                position: 'absolute',
                left: 'calc(100% - 30px)',
                transform: 'rotate(350deg)',
              }}
            />
          </div>
        </div>
        <div className={styles.image}>
          <img
            src="https://static.kitemaker.co/onboarding/collaborative_specs.png"
            className={styles.onboardingImage}
          />
        </div>
        <div className={cn('col noGrow noMinWidth', styles.tipTwo)}>
          <div className={cn(styles.tip, 'bodyXL', 'slightlyGrayed', 'mt4vh')}>
            All activity <span className="semiBold">(Github, Slack, Figma)</span>, discussions and
            context in one place.
            <div className={cn('row metadataGapL mt8', styles.logos)}>
              <Icon icon="github" size={IconSize.Size32} />
              <Icon icon="slack_color" size={IconSize.Size32} />
              <Icon icon="figma_color" size={IconSize.Size32} />
            </div>
            <img
              className={styles.hideOnSmall}
              src={downLeft}
              style={{ position: 'absolute', left: '-70px' }}
            />
          </div>
        </div>
      </div>

      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onNext();
        }}
      >
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          className="fullWidth"
        >
          Continue
        </Button>
      </form>
      <Hotkey
        hotkey="enter"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onNext();
        }}
      />
    </div>
  );
}
