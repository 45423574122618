import { flatMap, keyBy, partition } from 'lodash';
import { GetRecoilValue, selectorFamily } from 'recoil';
import {
  filterNotDeletedNotNull,
  filterNotNull,
  notDeleted,
} from '../../../shared/utils/convenience';
import { looksLikeId } from '../../../shared/utils/id';
import {
  cycleEntitesByCycle,
  cycleEntitiesByEntity,
  cycleGoalsByCycle,
  cycleTodosByCycle,
  cyclesBySpace,
} from '../../../sync/__generated/indexes';
import {
  Cycle,
  CycleEntity,
  CycleGoal,
  CycleStatus,
  CycleTodo,
  Issue,
  IssueStatusType,
  Organization,
  Space,
  TodoStatus,
} from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { SyncEngineObject } from '../types';
import { issueSelector } from './issues';
import { statusesForSpaceSelector } from './issueStatuses';
import { spacePath, spaceSelector, spacesForOrganizationSelector } from './spaces';
import { todoSelector } from './todos';

export function isCycle(cycle: SyncEngineObject): cycle is Cycle {
  return cycle.__typename === 'Cycle';
}

export function cycleStatusToIcon(cycleState: CycleStatus) {
  switch (cycleState) {
    case CycleStatus.NotStarted:
      return 'cycle_upcoming';
    case CycleStatus.Started:
      return 'cycle_current';
    case CycleStatus.Stopped:
      return 'cycle_completed';
  }
}

export const cycleSelector = selectorFamily({
  key: 'Cycle',
  get:
    (cycleId: string | undefined | null) =>
    ({ get }) => {
      if (!cycleId) {
        return null;
      }
      return get(syncEngineState(cycleId)) as Cycle | null;
    },
});

export const cyclesSelector = selectorFamily({
  key: 'Cycles',
  get:
    (cycleIds: string[]) =>
    ({ get }) => {
      return filterNotNull(cycleIds.map(cycleId => get(cycleSelector(cycleId))));
    },
});

export const activeCyclesSelector = selectorFamily({
  key: 'ActiveCycles',
  get:
    ({ organizationId, spaceId }: { organizationId: string; spaceId?: string | undefined }) =>
    ({ get }) => {
      const spaces = filterNotDeletedNotNull(
        spaceId ? [get(spaceSelector(spaceId))] : get(spacesForOrganizationSelector(organizationId))
      );
      return filterNotNull(
        spaces.flatMap(space => {
          if (space.activeCycleId) {
            const activeCycle = get(cycleSelector(space.activeCycleId));
            if (activeCycle) {
              return { cycle: activeCycle, space };
            }
          }
          return null;
        })
      );
    },
});

export const upcomingCyclesSelector = selectorFamily({
  key: 'UpcomingCycles',
  get:
    ({ organizationId, spaceId }: { organizationId: string; spaceId?: string | undefined }) =>
    ({ get }) => {
      const spaces = filterNotDeletedNotNull(
        spaceId ? [get(spaceSelector(spaceId))] : get(spacesForOrganizationSelector(organizationId))
      );
      return filterNotNull(
        spaces.flatMap(space => {
          if (space.upcomingCycleId) {
            const upcomingCycle = get(cycleSelector(space.upcomingCycleId));
            if (upcomingCycle) {
              return { cycle: upcomingCycle, space };
            }
          }
          return null;
        })
      );
    },
});

export const cycleEntitySelector = selectorFamily({
  key: 'CycleEntity',
  get:
    (cycleEntityId: string | undefined | null) =>
    ({ get }) => {
      if (!cycleEntityId) {
        return null;
      }
      return get(syncEngineState(cycleEntityId)) as CycleEntity | null;
    },
});

export const cycleNumberSelector = selectorFamily({
  key: 'CycleNumber',
  get:
    (cycleId: string | undefined | null) =>
    ({ get }) => {
      if (!cycleId) {
        return null;
      }
      return get(cycleSelector(cycleId))?.number;
    },
});

export const cyclesForOrganizationSelector = selectorFamily({
  key: 'CyclesForOrganization',
  get:
    (organizationId: string) =>
    ({ get }) => {
      const spaces = get(spacesForOrganizationSelector(organizationId));
      const cycles = flatMap(spaces, space => get(cyclesForSpaceSelector(space.id)));
      return cycles;
    },
});

export const cyclesForSpaceSelector = selectorFamily({
  key: 'CyclesForSpace',
  get:
    (spaceId: string) =>
    ({ get }) => {
      const cycleIds = get(indexKeyState(indexKey(cyclesBySpace, spaceId)));
      return filterNotDeletedNotNull(cycleIds.map(cycleId => get(cycleSelector(cycleId))));
    },
});

export const currentCycleSelector = selectorFamily({
  key: 'CurrentCycle',
  get:
    (spaceId: string | undefined) =>
    ({ get }) => {
      const space = get(spaceSelector(spaceId));
      if (!space || !space.activeCycleId) {
        return null;
      }

      const cycle = get(cycleSelector(space.activeCycleId));
      return cycle;
    },
});

export const cycleEntitiesForCycleSelector = selectorFamily({
  key: 'CycleEntitiesForCycle',
  get:
    (cycleId: string) =>
    ({ get }) => {
      const cycleEntityIds = get(indexKeyState(indexKey(cycleEntitesByCycle, cycleId)));
      return filterNotDeletedNotNull(
        cycleEntityIds.map(
          cycleEntityId => get(syncEngineState(cycleEntityId)) as CycleEntity | null
        )
      );
    },
});

export const cycleEntitiesForEntitySelector = selectorFamily({
  key: 'CycleEntitiesForEntity',
  get:
    (entityId: string) =>
    ({ get }) => {
      const cycleEntityIds = get(indexKeyState(indexKey(cycleEntitiesByEntity, entityId)));
      return filterNotDeletedNotNull(
        cycleEntityIds.map(cycleEntityId => get(cycleEntitySelector(cycleEntityId)))
      );
    },
});

export const cycleGoalSelector = selectorFamily({
  key: 'CycleGoal',
  get:
    (cycleGoalId: string | undefined | null) =>
    ({ get }) => {
      if (!cycleGoalId) {
        return null;
      }
      return notDeleted(get(syncEngineState(cycleGoalId)) as CycleGoal | null);
    },
});

export const cycleGoalsForCycleSelector = selectorFamily({
  key: 'CycleGoalsForCycle',
  get:
    (cycleId: string) =>
    ({ get }) => {
      const cycleGoalIds = get(indexKeyState(indexKey(cycleGoalsByCycle, cycleId)));
      return filterNotDeletedNotNull(
        cycleGoalIds.map(cycleGoalId => get(syncEngineState(cycleGoalId)) as CycleGoal | null)
      ).filter(goal => !goal.entityId);
    },
});

export const cycleGoalsForEntity = selectorFamily({
  key: 'CycleGoalsForEntity',
  get:
    ({ cycleId, entityId }: { cycleId: string; entityId: string }) =>
    ({ get }) => {
      const cycleGoalIds = get(indexKeyState(indexKey(cycleGoalsByCycle, cycleId)));
      return filterNotDeletedNotNull(
        cycleGoalIds.map(cycleGoalId => get(syncEngineState(cycleGoalId)) as CycleGoal | null)
      ).filter(goal => goal.entityId === entityId);
    },
});

export const cycleTodosForCycleSelector = selectorFamily({
  key: 'CycleTodosForCycle',
  get:
    (cycleId: string) =>
    ({ get }) => {
      const cycleTodoIds = get(indexKeyState(indexKey(cycleTodosByCycle, cycleId)));
      return filterNotDeletedNotNull(
        cycleTodoIds.map(cycleTodoId => get(syncEngineState(cycleTodoId)) as CycleTodo | null)
      );
    },
});

export const cycleTodosForCycleEntitySelector = selectorFamily({
  key: 'CycleTodosForCycleEntity',
  get:
    (cycleEntityId: string) =>
    ({ get }) => {
      const cycleEntity = get(syncEngineState(cycleEntityId)) as CycleEntity | null;
      if (!cycleEntity) {
        return [];
      }
      const cycleTodos = get(cycleTodosForCycleSelector(cycleEntity.cycleId));
      return cycleTodos.filter(ct => {
        const todo = get(todoSelector(ct.todoId));
        return todo?.entityId && todo.entityId === cycleEntity.entityId;
      });
    },
});

export const entitiesInCycleSelector = selectorFamily({
  key: 'EntitiesInCycle',
  get:
    ({
      cycleId,
      entityIds,
    }: {
      cycleId: string | undefined | null;
      entityIds: (string | undefined)[];
    }) =>
    ({ get }) => {
      const nonNullEntityIds = filterNotNull(entityIds);
      if (!cycleId || !nonNullEntityIds.length) {
        return { inCycle: [], notInCycle: [] };
      }

      const currentCycle = get(cycleSelector(cycleId));
      if (!currentCycle) {
        return { inCycle: [], notInCycle: [] };
      }

      const cycleEntities = get(cycleEntitiesForCycleSelector(currentCycle.id))
        .filter(ce => !ce.ghost)
        .map(ce => ce.entityId);
      const [inCycle, notInCycle] = partition(nonNullEntityIds, entityId =>
        cycleEntities.includes(entityId)
      );
      return { inCycle, notInCycle };
    },
});

export const entityInCycleSelector = selectorFamily({
  key: 'EntityInCycle',
  get:
    ({ cycleId, entityId }: { cycleId: string | undefined | null; entityId: string | undefined }) =>
    ({ get }) => {
      if (!cycleId || !entityId) {
        return false;
      }

      const cycleEntity = get(cycleEntityForCycleAndEntity({ cycleId, entityId }));
      return cycleEntity?.ghost === false;
    },
});

export const cycleEntityForCycleAndEntity = selectorFamily({
  key: 'CycleEntityForCyleAndEntity',
  get:
    ({ cycleId, entityId }: { cycleId: string | undefined | null; entityId: string | undefined }) =>
    ({ get }) => {
      if (!cycleId || !entityId) {
        return null;
      }

      const cycle = get(cycleSelector(cycleId));
      if (!cycle) {
        return null;
      }

      const cycleEntities = get(cycleEntitiesForCycleSelector(cycle.id));
      const cycleEntity = cycleEntities.find(ce => ce.entityId === entityId);
      if (cycleEntity?.ghost) {
        // if all of the todos are orphaned, we don't want to show the entity
        const todos = get(cycleTodosForCycleEntitySelector(cycleEntity.id));
        if (
          todos.every(t => {
            const todo = get(todoSelector(t.todoId));
            return todo?.orphaned;
          })
        ) {
          return null;
        }
      }

      return cycleEntity;
    },
});

export const todoInCycleSelector = selectorFamily({
  key: 'TodoInCycle',
  get:
    ({
      cycleId,
      todoId,
    }: {
      cycleId: string | undefined | null;
      todoId: string | undefined | null;
    }) =>
    ({ get }) => {
      if (!cycleId || !todoId) {
        return false;
      }
      const cycle = get(cycleSelector(cycleId));
      if (!cycle) {
        return false;
      }

      const cycleTodos = get(cycleTodosForCycleSelector(cycle.id));
      return !!cycleTodos.find(ct => ct.todoId === todoId);
    },
});

export const cyclesForTodoSelector = selectorFamily({
  key: 'CyclesForTodo',
  get:
    (cycleId: string | undefined) =>
    ({ get }) => {
      if (!cycleId) {
        return [];
      }
      const cycleTodos = get(cycleTodosForCycleSelector(cycleId));
      return filterNotDeletedNotNull(cycleTodos.map(ct => get(cycleSelector(ct.cycleId))));
    },
});

export const cycleByNumberSelector = selectorFamily({
  key: 'CycleByNumber',
  get:
    ({ spaceId, cycleNumber }: { spaceId: string; cycleNumber: string }) =>
    ({ get }) => {
      const cycles = get(cyclesForSpaceSelector(spaceId));
      return cycles.find(i => i.number === cycleNumber || i.id === cycleNumber);
    },
});

export const cycleIdByNumberSelector = selectorFamily({
  key: 'CycleIdByNumber',
  get:
    ({ spaceId, cycleNumber }: { spaceId: string; cycleNumber: string }) =>
    ({ get }) => {
      if (looksLikeId(cycleNumber)) {
        return cycleNumber;
      }

      return get(cycleByNumberSelector({ spaceId, cycleNumber }))?.id;
    },
});

export const cycleCountSelector = selectorFamily({
  key: 'CycleCount',
  get:
    ({ cycleId, statusId }: { cycleId: string; statusId?: string }) =>
    ({ get }) => {
      const { total } = get(cycleCompletedSelector({ cycleId, statusId }));
      return total ?? 0;
    },
});

export const cycleCompletedSelector = selectorFamily({
  key: 'CycleCompleted',
  get:
    ({ cycleId, statusId }: { cycleId: string; statusId?: string }) =>
    ({ get }) => {
      const cycle = get(cycleSelector(cycleId));
      if (!cycle) {
        return {};
      }

      if (cycle.cycleStatus === CycleStatus.Stopped) {
        const history = cycle.history;
        if (!history) {
          return {};
        }
        const completed = history.completedEntities.length + history.completedTodos.length;
        const total =
          completed + history.incompleteEntities.length + history.incompleteTodos.length;

        return {
          total,
          completed,
        };
      }

      const cycleEntities = get(cycleEntitiesForCycleSelector(cycleId)).filter(ce => !ce.ghost);
      let issues = filterNotDeletedNotNull(
        cycleEntities.map(ce => get(issueSelector(ce.entityId)))
      );
      if (statusId) {
        issues = issues.filter(i => i.statusId === statusId);
      }

      const statuses = get(statusesForSpaceSelector(cycle.spaceId));
      const doneStatusIds = statuses
        .filter(s => s.statusType === IssueStatusType.Done)
        .map(s => s.id);

      const completedEntities = issues.filter(i => doneStatusIds.includes(i.statusId));

      const cycleTodos = get(cycleTodosForCycleSelector(cycleId));

      const todos = filterNotDeletedNotNull(cycleTodos.map(ce => get(todoSelector(ce.todoId))));

      const completedTodos = todos.filter(t => t.status === TodoStatus.Done);

      return {
        total: issues.length + todos.length,
        completed: completedEntities.length + completedTodos.length,
      };
    },
});

export const cycleStarredIdSelector = selectorFamily({
  key: 'CycleStarredId',
  get:
    ({
      cycleId,
      ignoreCurrentAndUpcoming,
    }: {
      cycleId: string;
      ignoreCurrentAndUpcoming?: boolean;
    }) =>
    ({ get }) => {
      if (ignoreCurrentAndUpcoming) {
        return cycleId;
      }
      const cycle = get(cycleSelector(cycleId));
      if (!cycle) {
        return cycleId;
      }

      const space = get(spaceSelector(cycle.spaceId));
      if (!space) {
        return cycleId;
      }
      if (space.activeCycleId === cycleId) {
        return `current-${space.id}`;
      }
      if (space.upcomingCycleId === cycleId) {
        return `upcoming-${space.id}`;
      }
      return cycle.id;
    },
});

export function cyclePath(organization: Organization, space: Space, cycle: Cycle) {
  return spacePath(organization, space, `cycles/${cycle.number}`);
}

export function sortIssuesByCycles(
  get: GetRecoilValue,
  spaceId: string,
  issues: Issue[],
  reversed = false
) {
  const space = get(spaceSelector(spaceId));
  if (!space) {
    return;
  }

  const activeCycleEntities = space.activeCycleId
    ? get(cycleEntitiesForCycleSelector(space.activeCycleId))
    : [];
  const upcomingCycleEntities = space.upcomingCycleId
    ? get(cycleEntitiesForCycleSelector(space.upcomingCycleId))
    : [];

  const activeCycleEntityIndex = keyBy(activeCycleEntities, 'entityId');
  const upcomingCycleEntityIndex = keyBy(upcomingCycleEntities, 'entityId');

  const sorts: Record<string, string> = {};

  for (const issue of issues) {
    const activeSort = activeCycleEntityIndex[issue.id]?.sort;
    const upcomingSort = upcomingCycleEntityIndex[issue.id]?.sort;

    let sort: string;
    if (activeSort) {
      sort = `a-${activeSort}`;
    } else if (upcomingSort) {
      sort = `b-${upcomingSort}`;
    } else {
      sort = 'c';
    }

    sorts[issue.id] = sort;
  }

  issues.sort((ai: Issue, bi: Issue) => {
    const a = reversed ? bi : ai;
    const b = reversed ? ai : bi;

    const aSort = sorts[a.id];
    const bSort = sorts[b.id];

    if (aSort === bSort) {
      return a.displayedUpdatedAt - b.displayedUpdatedAt;
    }

    return aSort > bSort ? -1 : 1;
  });
}
