import { capitalize, uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { atom, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { createEditor } from 'slate';
import { emptyDocument } from '../../../shared/slate/utils';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { generateId } from '../../../shared/utils/id';
import { randomString } from '../../../shared/utils/random';
import { between } from '../../../shared/utils/sorting';
import { issueTerm } from '../../../shared/utils/terms';
import {
  initiativeIssues,
  insightEntities,
  issueAssignees,
  issueLabels,
  issueWatchers,
  releaseIssues,
} from '../../../sync/__generated/collections';
import {
  collaborativeDocsByEntity,
  cycleEntitesByCycle,
  dependenciesByDependsOnId,
  dependenciesByEnablesId,
  effortLevelsBySpace,
  impactLevelsBySpace,
  initiativeSpacesByInitiative,
  initiativesByIssue,
  insightsByEntity,
  issueLabelsBySpace,
  issueStatusesBySpace,
  issuesBySpace,
} from '../../../sync/__generated/indexes';
import {
  CollaborativeDoc,
  CreatedFromProvenance,
  CycleEntity,
  Dependency,
  DependencyType,
  Effort,
  EntityProvenance,
  EntityProvenanceType,
  Impact,
  Initiative,
  InitiativeSpace,
  Issue,
  IssueLabel,
  IssueStatus,
  IssueStatusType,
  Organization,
  Snippet,
  Space,
} from '../../../sync/__generated/models';
import { EntityLink } from '../../components/new/entityLink';
import NewEntityToastContents from '../../components/newEntityToastContents';
import { toast } from '../../components/toast';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { KitemakerTransforms } from '../../slate/kitemakerTransforms';
import { withCollaboration } from '../../slate/plugins/withCollaboration';
import { withSchema } from '../../slate/plugins/withSchema';
import { DocumentLike } from '../../slate/types';
import { trackerEvent } from '../../tracker';
import { nextAvailableNumber } from '../../utils/entities';
import { checkTierExceededAndShowToast } from '../../utils/paywall';
import {
  collaborativeDocIdByEntitySelector,
  collaborativeDocSelector,
} from '../selectors/collaborativeDoc';
import { createCollaborativeDocHelper } from './collaborativeDoc';
import { indexHelper, updateSortableSorts } from './helpers';
import { addSpacesToInitiative } from './intiatives';
import {
  convertTodosHelper,
  duplicateTodosHelper,
  updateConnectedTodosForWorkItemStatusChange,
} from './todos';

function lastSortForStatus(getters: SyncEngineGetters, spaceId: string, statusId: string): string {
  const issuesInSpace = indexHelper<Issue>(getters, issuesBySpace, spaceId);
  const issuesInStatus = issuesInSpace.filter(issue => issue.statusId === statusId);
  return issuesInStatus[issuesInStatus.length - 1]?.sort;
}

function firstSortForStatus(getters: SyncEngineGetters, spaceId: string, statusId: string): string {
  const issuesInSpace = indexHelper<Issue>(getters, issuesBySpace, spaceId);
  const issuesInStatus = issuesInSpace.filter(issue => issue.statusId === statusId);
  return issuesInStatus[0]?.sort;
}

function defaultStatusForSpace(getters: SyncEngineGetters, spaceId: string): string {
  const space = getters.get<Space>(spaceId);
  if (space?.defaultNewStatusId) {
    return space.defaultNewStatusId;
  }
  // shouldn't happen, but just in case
  return (
    indexHelper<IssueStatus>(getters, issueStatusesBySpace, spaceId).find(
      i => i.statusType === IssueStatusType.Backlog || i.statusType === IssueStatusType.Todo
    )?.id ?? ''
  );
}

function bestEffortLabels(
  getters: SyncEngineGetters,
  spaceId: string,
  labelIds: string[]
): string[] {
  const { get } = getters;
  const labelsInNewSpace = indexHelper<IssueLabel>(getters, issueLabelsBySpace, spaceId);
  const labelsInOldSpace = filterNotDeletedNotNull(
    labelIds.map(labelId => get<IssueLabel>(labelId))
  );
  return labelsInNewSpace
    .filter(label => labelsInOldSpace.find(l => l.name.toLowerCase() === label.name.toLowerCase()))
    .map(label => label.id);
}

function tryResolveImpactEffort(
  getters: SyncEngineGetters,
  spaceId: string,
  impactId: string | null,
  effortId: string | null
): { newImpactId?: string; newEffortId?: string } {
  const { get } = getters;
  let impact: Impact | undefined = undefined;
  let effort: Effort | undefined = undefined;

  if (impactId) {
    const currentImpact = get<Impact>(impactId);
    const impactLevelsForNewSpace = indexHelper<Impact>(getters, impactLevelsBySpace, spaceId);
    impact = impactLevelsForNewSpace.find(
      i => i.name.toLowerCase() === currentImpact?.name.toLowerCase()
    );
  }

  if (effortId) {
    const currentEffort = get<Effort>(effortId);
    const effortLevelsForNewSpace = indexHelper<Effort>(getters, effortLevelsBySpace, spaceId);
    effort = effortLevelsForNewSpace.find(
      e => e.name.toLowerCase() === currentEffort?.name.toLowerCase()
    );
  }

  return { newImpactId: impact?.id, newEffortId: effort?.id };
}

function createIssueHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  title: string,
  spaceId: string,
  statusId: string,
  actorId: string,
  options?: CreateIssueOptions
) {
  const {
    id,
    sort,
    effortId,
    impactId,
    assigneeIds,
    labelIds,
    initiativeIds,
    releaseIds,
    provenance,
    description: rawDescription,
    convertSmartTodos,
    cycleId,
    dueDate,
  } = options ?? {};

  let contents = rawDescription;
  let createTodos:
    | null
    | ((
        tx: SyncEngineTransaction,
        getters: SyncEngineGetters,
        entityId: string,
        actorId: string
      ) => void) = null;

  const space = getters.get<Space>(spaceId)!;

  if (contents && convertSmartTodos) {
    const converted = convertTodosHelper(contents);
    contents = converted.contents;
    createTodos = converted.createTodos;
  }

  const issue: SyncEngineCreate<Issue> = {
    __typename: 'Issue',
    id,
    title,
    statusId,
    sort: sort ?? between({ after: lastSortForStatus(getters, spaceId, statusId) }),
    spaceId: spaceId,
    effortId: effortId ?? null,
    impactId: impactId ?? null,
    provenance: provenance ?? null,
    number: nextAvailableNumber(getters, spaceId),
    previousStatusId: null,
    actorId,
    partial: false,
    public: false,
    publicMetadata: false,
    displayedUpdatedAt: Date.now(),
    lastStatusUpdate: Date.now(),
    closedAt: null,
    archivedAt: null,
    assigneeIds: [],
    watcherIds: [],
    labelIds: [],
    codeReviewRequestIds: [],
    dueDate: dueDate?.getTime() ?? null,
  };

  const result = tx.create<Issue>(issue);

  createCollaborativeDocHelper(tx, space.organizationId, contents ?? emptyDocument(), result.id);

  tx.addToCollection(issueWatchers, result.id, [actorId]);
  if (assigneeIds?.length) {
    tx.addToCollection(issueAssignees, result.id, assigneeIds);
  }
  if (labelIds?.length) {
    tx.addToCollection(issueLabels, result.id, labelIds);
  }
  if (initiativeIds?.length) {
    for (const initiativeId of initiativeIds) {
      tx.addToCollection(initiativeIssues, initiativeId, [result.id]);
    }
  }

  if (releaseIds?.length) {
    for (const releaseId of releaseIds) {
      tx.addToCollection(releaseIssues, releaseId, [result.id]);
    }
  }

  if (cycleId) {
    const cycleEntityIds = getters.getIndex(cycleEntitesByCycle, cycleId);
    const cycleEntities = filterNotDeletedNotNull(
      cycleEntityIds.map(cycleEntityId => getters.get<CycleEntity>(cycleEntityId))
    );
    const sort = between({ after: cycleEntities[cycleEntities.length - 1]?.sort });
    const cycleEntity: SyncEngineCreate<CycleEntity> = {
      __typename: 'CycleEntity',
      cycleId,
      entityId: result.id,
      sort,
      ghost: false,
    };
    tx.create<CycleEntity>(cycleEntity);
  }

  createTodos?.(tx, getters, result.id, actorId);

  let action: string | undefined = undefined;
  if (provenance?.provenanceType === EntityProvenanceType.Duplicated) {
    action = 'duplicated';
  } else if (provenance?.provenanceType === EntityProvenanceType.CreatedFrom) {
    const createdFrom = provenance as CreatedFromProvenance;
    if (createdFrom.createdFromCommentId) {
      action = 'from comment';
    } else if (createdFrom.createdFromTodoId) {
      action = 'from todo';
    } else {
      action = 'from description';
    }
  }

  trackerEvent('Work Item Created', { id: result.id, action });

  toast.success(<NewEntityToastContents entityId={result.id} />);

  return result;
}

export type CreateIssueOptions = {
  statusId?: string;
  sort?: string;
  assigneeIds?: string[];
  labelIds?: string[];
  initiativeIds?: string[];
  releaseIds?: string[];
  effortId?: string | null;
  impactId?: string | null;
  provenance?: EntityProvenance | null;
  description?: DocumentLike | null;
  cycleId?: string | null;
  convertSmartTodos?: boolean;
  id?: string;
  dueDate?: Date | null;
};

export function useCreateIssue() {
  const history = useHistory();
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (title: string, spaceId: string, options?: CreateIssueOptions): Issue | null => {
    return modelManager.transaction((tx, getters) => {
      const { get } = getters;
      const space = get<Space>(spaceId);
      if (!space) {
        throw Error(`Unable to find space ${spaceId}`);
      }
      const organization = get<Organization>(space.organizationId);
      if (!organization) {
        throw Error(`Unable to find organization ${space.organizationId}`);
      }

      const payTierExceeded = checkTierExceededAndShowToast(organization, history);
      if (payTierExceeded) {
        return null;
      }

      const { statusId, ...otherOptions } = options ?? {};
      const result = createIssueHelper(
        tx,
        getters,
        title,
        space.id,
        statusId ?? defaultStatusForSpace(getters, spaceId),
        user.id,
        otherOptions
      );

      return result;
    });
  };
}

export function useUpdateIssues() {
  const modelManager = useModelManager();

  return (issueIds: string[], rawUpdate: Omit<SyncEngineUpdateWithoutDelete<Issue>, 'sort'>) => {
    const update: SyncEngineUpdateWithoutDelete<Issue> = { ...rawUpdate };

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const issueId of issueIds) {
        const existingIssue = get<Issue>(issueId);
        if (!existingIssue) {
          continue;
        }

        // if we're only updating a status, there's no need to update the updatedAt time
        const statusOnly = Object.keys(update).length === 1 && !!update.statusId;
        if (!statusOnly) {
          update.displayedUpdatedAt = Date.now();
        }

        if (update.statusId) {
          // FIXME weird place to do this, no?
          if (existingIssue.partial) {
            modelManager.fetchIssue(issueId);
          }
          if (update.statusId !== existingIssue.statusId) {
            update.previousStatusId = existingIssue.statusId;
          }

          update.sort = between({
            after: lastSortForStatus({ get, getIndex }, existingIssue.spaceId, update.statusId),
          });

          updateConnectedTodosForWorkItemStatusChange(
            { get, getIndex },
            tx,
            issueId,
            update.statusId
          );
        }

        if (update.title) {
          trackerEvent('Work Item Updated', { id: issueId, type: 'Title' });
        }

        if (update.statusId) {
          trackerEvent('Work Item Updated', { id: issueId, type: 'Status' });
        }

        if (update.effortId) {
          trackerEvent('Work Item Updated', { id: issueId, type: 'Effort' });
        }

        if (update.impactId) {
          trackerEvent('Work Item Updated', { id: issueId, type: 'Impact' });
        }

        if (update.public !== undefined && update.public) {
          trackerEvent('Work Item Updated', { id: issueId, type: 'Shared Public' });
        }

        if (update.public !== undefined && !update.public) {
          trackerEvent('Work Item Updated', { id: issueId, type: 'Unshared Public' });
        }

        tx.update(issueId, update);
      }
    });
  };
}
const deleteIssuesContext = atom<{
  onDelete?: (issues: Issue[]) => void;
} | null>({
  key: 'DeleteIssuesContext',
  default: null,
});

export function useDeleteIssuesContext(callbacks: { onDelete?: (issues: Issue[]) => void }) {
  const setContext = useSetRecoilState(deleteIssuesContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useDeleteIssues() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const context = useRecoilValue(deleteIssuesContext);

  return async (issueIds: string[]): Promise<boolean> => {
    if (!issueIds.length) {
      return false;
    }

    const confirmed = await confirm(
      `Delete ${issueTerm}${issueIds.length > 1 ? 's' : ''}`,
      `${
        issueIds.length > 1 ? `These ${issueTerm}s` : `This ${issueTerm}`
      } will be deleted permanently. There is no way to undo this operation. Are you sure you want to proceed?`,
      {
        label: 'Delete',
        destructive: true,
      }
    );

    if (!confirmed) {
      return false;
    }

    const deleted: Issue[] = [];

    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      for (const issueId of issueIds) {
        const issue = get<Issue>(issueId);
        if (!issue || issue.deleted) {
          continue;
        }

        // do the actual deletion
        tx.update<Issue>(issueId, {
          deleted: true,
          title: `__deleted__${randomString(8)}`,
        });

        const docIds = getIndex(collaborativeDocsByEntity, issueId);
        for (const docId of docIds) {
          tx.update<CollaborativeDoc>(docId, { deleted: true, content: emptyDocument() });
        }

        tx.removeFromCollection(issueLabels, issueId, issue.labelIds);
        tx.removeFromCollection(issueAssignees, issueId, issue.assigneeIds);
        tx.removeFromCollection(issueWatchers, issueId, issue.watcherIds);

        const insightIds = getIndex(insightsByEntity, issueId);
        for (const insightId of insightIds) {
          tx.removeFromCollection(insightEntities, insightId, [issueId]);
        }

        const enablesDependencyIds = getIndex(dependenciesByEnablesId, issue.id);
        const dependsDependencyIds = getIndex(dependenciesByDependsOnId, issue.id);
        for (const depndencyId of uniq([...enablesDependencyIds, ...dependsDependencyIds])) {
          tx.update<Dependency>(depndencyId, { deleted: true });
        }

        deleted.push(issue);
        trackerEvent('Work Item Deleted', { id: issueId });
      }
    });

    context?.onDelete?.(deleted);
    return true;
  };
}

const moveIssuesContext = atom<{
  onMove?: (issues: Issue[], statusId: string) => (() => void) | null;
} | null>({
  key: 'MoveIssueContext',
  default: null,
});

export function useMoveIssuesContext(callbacks: {
  onMove?: (issues: Issue[], statusId: string) => (() => void) | null;
}) {
  const setContext = useSetRecoilState(moveIssuesContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useMoveIssues() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  const context = useRecoilValue(moveIssuesContext);

  return (
    issueIds: string[],
    statusId: string,
    options?: { onUndo?: () => void; top?: boolean; disableUndo?: boolean }
  ) => {
    const { onUndo, top, disableUndo } = options ?? {};
    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      const status = get<IssueStatus>(statusId);
      if (!status) {
        return;
      }
      const space = get<Space>(status.spaceId);
      if (!space) {
        return;
      }

      const issues = filterNotNull(issueIds.map(issueId => get<Issue>(issueId)));
      if (!issues.length) {
        return;
      }
      const sameSpace = issues.every(issue => issue.spaceId === status.spaceId);
      const moveType = sameSpace ? 'Moved' : 'Moved space';

      const activeCycleEntities =
        space.activeCycleId !== null
          ? indexHelper<CycleEntity>(getters, cycleEntitesByCycle, space.activeCycleId)
          : [];

      let hadIssuesRemovedFromCycle = false;

      const undoMoves: Array<{
        issueId: string;
        statusId: string;
        sort: string;
        spaceId?: string;
        number?: string;
        labelIdsToRestore?: string[];
        cycleEntityIdsToRestore?: string[];
      }> = [];

      const firstSort = firstSortForStatus({ getIndex, get }, space.id, statusId);
      const lastSort = lastSortForStatus({ getIndex, get }, space.id, statusId);
      let sort = top ? between({ before: firstSort }) : between({ after: lastSort });

      const contextUndo = context?.onMove?.(issues, statusId);

      for (const issue of issues) {
        const labelIds = issue.labelIds;

        // remove issues from active and upcoming cycles if archived or backlogged
        let removedCycleEntities: string[] = [];
        if (status.statusType === IssueStatusType.Backlog) {
          const cycleEntitiesToRemove = activeCycleEntities.filter(
            ce => issueIds.includes(ce.entityId) && !ce.deleted
          );

          for (const ce of cycleEntitiesToRemove) {
            tx.update<CycleEntity>(ce.id, {
              deleted: true,
            });
          }

          removedCycleEntities = cycleEntitiesToRemove.map(ce => ce.id);
          hadIssuesRemovedFromCycle = hadIssuesRemovedFromCycle
            ? hadIssuesRemovedFromCycle
            : removedCycleEntities.length > 0;
        }

        undoMoves.push({
          issueId: issue.id,
          statusId: issue.statusId,
          sort: issue.sort,
          spaceId: issue.spaceId !== space.id ? issue.spaceId : undefined,
          number: issue.spaceId !== space.id ? issue.number : undefined,
          labelIdsToRestore: issue.spaceId !== space.id ? issue.labelIds : undefined,
          cycleEntityIdsToRestore: removedCycleEntities,
        });

        let impactId: string | undefined;
        let effortId: string | undefined;

        // if we're moving space, drop all of the labels. We'll try to add them back after we move it
        if (issue.spaceId !== space.id) {
          tx.removeFromCollection(issueLabels, issue.id, labelIds);

          const { newImpactId, newEffortId } = tryResolveImpactEffort(
            getters,
            space.id,
            issue.impactId,
            issue.effortId
          );

          impactId = newImpactId;
          effortId = newEffortId;
        }

        tx.update<Issue>(issue.id, {
          spaceId: issue.spaceId !== space.id ? space.id : undefined,
          number:
            issue.spaceId !== space.id
              ? nextAvailableNumber({ get, getIndex }, space.id)
              : undefined,
          statusId,
          sort,
          impactId,
          effortId,
        });

        if (issue.statusId !== statusId) {
          updateConnectedTodosForWorkItemStatusChange(getters, tx, issue.id, statusId);
        }

        // try to add back the labels and impact/effort after a space move
        if (issue.spaceId !== space.id) {
          const newLabelIds = bestEffortLabels(getters, space.id, labelIds);
          tx.addToCollection(issueLabels, issue.id, newLabelIds);
        }

        trackerEvent('Work Item Updated', { id: issue.id, type: moveType });
        sort = top ? between({ after: sort, before: firstSort }) : between({ after: sort });

        const initiatives = indexHelper<Initiative>(getters, initiativesByIssue, issue.id);
        for (const initiative of initiatives) {
          addSpacesToInitiative(tx, getters, initiative.id, [space.id]);
        }
      }

      if (disableUndo) {
        return;
      }

      let action = `moved to ${status.name}`;
      if (status.statusType === IssueStatusType.Archived) {
        action = 'archived';
      }
      if (!sameSpace) {
        action = `moved to ${status.name} in ${space.name}`;
      }

      if (hadIssuesRemovedFromCycle) {
        action += ` and ${
          issueIds.length > 1 ? 'were' : 'was'
        } removed from the active and upcoming cycles`;
      }

      let actionString = (
        <>
          {capitalize(issueTerm)}s {action}
        </>
      );
      if (issues.length === 1) {
        actionString = (
          <>
            <EntityLink id={issues[0].id} /> {action}
          </>
        );
      }

      setUndo(actionString, () => {
        modelManager.transaction(tx => {
          for (const undo of undoMoves) {
            tx.update<Issue>(undo.issueId, {
              statusId: undo.statusId,
              sort: undo.sort,
              // FIXME: this is a filthy hack we use to tell the server "trust us, this number is cool for us to use since it's an undo"
              number: undo.number ? `U${undo.number}` : undefined,
              spaceId: undo.spaceId ? undo.spaceId : undefined,
            });

            if (undo.labelIdsToRestore?.length) {
              tx.addToCollection(issueLabels, undo.issueId, undo.labelIdsToRestore);
            }
            for (const cycleEntityId of undo.cycleEntityIdsToRestore ?? []) {
              tx.update<CycleEntity>(cycleEntityId, {
                deleted: false,
              });
            }
            trackerEvent('Work Item Updated', { id: undo.issueId, type: moveType, undo: true });
          }
        });

        contextUndo?.();
        onUndo?.();
      });
    });
  };
}

export function useUnarchiveIssues() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();

  return (issueIds: string[]) => {
    modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;
      const issues = filterNotNull(issueIds.map(issueId => get<Issue>(issueId)));
      if (!issues.length) {
        return;
      }

      const undoMoves: Array<{
        issueId: string;
        statusId: string;
        sort: string;
      }> = [];

      const sorts: Record<string, string> = {};

      for (const issue of issues) {
        if (!issue.previousStatusId) {
          continue;
        }
        const space = get<Space>(issue.spaceId);
        if (!space) {
          continue;
        }

        const previousSort = sorts[issue.previousStatusId];
        const sort = previousSort
          ? between({ after: previousSort })
          : between({
              after: lastSortForStatus({ getIndex, get }, space.id, issue.previousStatusId),
            });
        sorts[issue.previousStatusId] = sort;

        undoMoves.push({
          issueId: issue.id,
          statusId: issue.statusId,
          sort: issue.sort,
        });

        tx.update<Issue>(issue.id, {
          statusId: issue.previousStatusId,
          sort,
        });

        updateConnectedTodosForWorkItemStatusChange(getters, tx, issue.id, issue.previousStatusId);

        trackerEvent('Work Item Updated', { id: issue.id, type: 'Moved' });
      }

      const action = `unarchived`;

      setUndo(`${capitalize(issueTerm)}${issues.length > 1 ? 's' : ''} ${action}`, () => {
        modelManager.transaction(tx => {
          for (const undo of undoMoves) {
            tx.update<Issue>(undo.issueId, {
              statusId: undo.statusId,
              sort: undo.sort,
            });

            trackerEvent('Work Item Updated', { id: undo.issueId, type: 'Moved', undo: true });
          }
        });
      });
    });
  };
}

export function useAddDependenciesToIssues() {
  const modelManager = useModelManager();

  return (
    issueIds: string[],
    property: 'dependsOnId' | 'enablesId',
    type: DependencyType,
    toAdd: string[]
  ) => {
    const inverseProperty = property === 'dependsOnId' ? 'enablesId' : 'dependsOnId';
    modelManager.transaction(tx => {
      for (const issueId of issueIds) {
        for (const toAddId of toAdd) {
          const dependency = {
            __typename: 'Dependency',
            dependencyType: type,
            [property]: issueId,
            [inverseProperty]: toAddId,
          } as SyncEngineCreate<Dependency>;
          tx.create(dependency);
        }
      }
    });
  };
}

export function useRemoveDependenciesFromIssues() {
  const modelManager = useModelManager();

  return (
    issueIds: string[],
    property: 'dependsOnId' | 'enablesId',
    type: DependencyType,
    toRemove: string[]
  ) => {
    const inverseProperty = property === 'dependsOnId' ? 'enablesId' : 'dependsOnId';
    modelManager.transaction((tx, { getIndex, get }) => {
      for (const issueId of issueIds) {
        const dependencyIds =
          property === 'dependsOnId'
            ? getIndex(dependenciesByDependsOnId, issueId)
            : getIndex(dependenciesByEnablesId, issueId);
        const dependencies = filterNotDeletedNotNull(
          dependencyIds.map(dependencyId => get<Dependency>(dependencyId))
        ).filter(dependency => {
          return (
            toRemove.includes(dependency[inverseProperty]) && dependency.dependencyType === type
          );
        });

        for (const dependency of dependencies) {
          tx.update(dependency.id, { deleted: true });
        }
      }
    });
  };
}

export function useDuplicateIssues() {
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (
    issuesToDuplicate: string[],
    statusId: string,
    sortAfterId?: string,
    sortBeforeId?: string
  ) => {
    modelManager.flushCollaborativeDocs();

    return modelManager.transaction((tx, getters) => {
      const { get, getIndex } = getters;

      const status = get<IssueStatus>(statusId);
      if (!status) {
        throw Error(`Unable to fund status ${statusId}`);
      }
      const space = get<Space>(status.spaceId);
      if (!space) {
        throw Error(`Unable to find space ${status.spaceId}`);
      }

      const sortAfter = get<Issue>(sortAfterId ?? '');
      const sortBefore = get<Issue>(sortBeforeId ?? '');
      const results: string[] = [];
      let sort = between({ after: sortAfter?.sort, before: sortBefore?.sort });
      for (const issueId of issuesToDuplicate) {
        const issue = get<Issue>(issueId);
        if (!issue) {
          continue;
        }

        // PRIVATE-INITIATIVES-AND-ROADMAPS
        const initiativeIds = getIndex(initiativesByIssue, issue.id).filter(initiativeId => {
          if (space.id === issue.spaceId) {
            return true;
          }
          const initiativeSpaceIds = getIndex(initiativeSpacesByInitiative, initiativeId);
          for (const initiativeSpaceId of initiativeSpaceIds) {
            const initiativeSpace = get<InitiativeSpace>(initiativeSpaceId);
            if (!initiativeSpace) {
              continue;
            }
            const space = get<Space>(initiativeSpace.spaceId);
            if (space?.private) {
              return false;
            }
          }
          return true;
        });

        const labelIds =
          space.id === issue.spaceId
            ? issue.labelIds
            : bestEffortLabels(getters, space.id, issue.labelIds);

        let impactId = issue.impactId;
        let effortId = issue.effortId;
        let appendCopy = true;
        if (space.id !== issue.spaceId) {
          appendCopy = false;
          const { newImpactId, newEffortId } = tryResolveImpactEffort(
            getters,
            space.id,
            issue.impactId,
            issue.effortId
          );

          impactId = newImpactId ?? null;
          effortId = newEffortId ?? null;
        }

        const id = generateId();
        const docs = indexHelper<CollaborativeDoc>(getters, collaborativeDocsByEntity, issue.id);
        const doc = docs[0];
        const { contents: description, createTodos } = duplicateTodosHelper(
          getters,
          doc?.content ?? emptyDocument(),
          id,
          user.id
        );
        const created = createIssueHelper(
          tx,
          getters,
          `${issue.title}${appendCopy ? ` (copy)` : ''}`,
          space.id,
          statusId,
          user.id,
          {
            id,
            provenance: { entityId: issueId, provenanceType: EntityProvenanceType.Duplicated },
            sort,
            assigneeIds: issue.assigneeIds,
            labelIds,
            initiativeIds,
            effortId,
            impactId,
            description,
          }
        );

        const dependsOnDependencies = indexHelper<Dependency>(
          getters,
          dependenciesByDependsOnId,
          issue.id
        );
        const enablesDependencies = indexHelper<Dependency>(
          getters,
          dependenciesByEnablesId,
          issue.id
        );
        for (const dependency of dependsOnDependencies) {
          tx.create<Dependency>({
            __typename: 'Dependency',
            dependencyType: dependency.dependencyType,
            dependsOnId: created.id,
            enablesId: dependency.enablesId,
          });
        }
        for (const dependency of enablesDependencies) {
          tx.create<Dependency>({
            __typename: 'Dependency',
            dependencyType: dependency.dependencyType,
            dependsOnId: dependency.dependsOnId,
            enablesId: created.id,
          });
        }

        results.push(created.id);
        sort = between({ after: sort, before: sortBefore?.sort });
        createTodos(tx, getters);
      }

      return results;
    });
  };
}

export function useUpdateIssueSorts() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();

  return (statusId: string, issueIds: string[], afterIssueId?: string, beforeIssueId?: string) => {
    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const status = get<IssueStatus>(statusId);
      if (!status) {
        return;
      }

      const space = get<Space>(status.spaceId);
      if (!space) {
        return;
      }

      const issues = indexHelper<Issue>(getters, issuesBySpace, status.spaceId).filter(
        issue => issue.statusId === statusId || issueIds.includes(issue.id)
      );
      const updates = updateSortableSorts(issues, issueIds, afterIssueId, beforeIssueId);
      for (const issueId in updates) {
        const issue = issues.find(i => i.id === issueId);
        tx.update<Issue>(issueId, {
          statusId,
          sort: updates[issueId],
          displayedUpdatedAt: issue?.statusId !== statusId ? Date.now() : undefined,
        });

        updateConnectedTodosForWorkItemStatusChange(getters, tx, issueId, statusId);
      }

      if (status.statusType === IssueStatusType.Backlog) {
        const activeCycleEntities =
          space.activeCycleId !== null
            ? indexHelper<CycleEntity>(getters, cycleEntitesByCycle, space.activeCycleId)
            : [];

        const cycleEntitiesToRemove = activeCycleEntities.filter(
          ce => issueIds.includes(ce.entityId) && !ce.deleted
        );

        for (const ce of cycleEntitiesToRemove) {
          tx.update<CycleEntity>(ce.id, {
            deleted: true,
          });
        }

        const removedCount = uniq(cycleEntitiesToRemove.map(ce => ce.entityId)).length;

        if (removedCount) {
          const actionString = `${removedCount} ${issueTerm}${
            removedCount > 1 ? 's were' : ' was'
          } removed from the current and upcoming cycle as a result from moving ${
            removedCount > 1 ? 'these work items' : 'this work item'
          }.`;

          setUndo(actionString, () => {
            modelManager.transaction(tx => {
              for (const ce of cycleEntitiesToRemove) {
                tx.update<CycleEntity>(ce.id, {
                  deleted: false,
                });
              }
            });
          });
        }
      }
    });
  };
}

export function useCreateIssueFromSnippet() {
  const history = useHistory();
  const modelManager = useModelManager();
  const user = useCurrentUser();

  return (
    snippetId: string,
    title: string,
    spaceId: string,
    options?: {
      statusId?: string;
      sort?: string;
      assigneeIds?: string[];
      labelIds?: string[];
      effortId?: string | null;
      impactId?: string | null;
      duplicatedFromIssueId?: string | null;
    }
  ): Issue | null => {
    return modelManager.transaction((tx, getters) => {
      const { get } = getters;
      const snippet = get<Snippet>(snippetId);
      if (!snippet) {
        throw Error(`Unable to find snippet ${snippetId}`);
      }
      const space = get<Space>(spaceId);
      if (!space) {
        throw Error(`Unable to find space ${spaceId}`);
      }
      const organization = get<Organization>(space.organizationId);
      if (!organization) {
        throw Error(`Unable to find organization ${space.organizationId}`);
      }

      const payTierExceeded = checkTierExceededAndShowToast(organization, history);
      if (payTierExceeded) {
        return null;
      }

      const description = JSON.parse(snippet.contents);

      const { statusId, ...otherOptions } = options ?? {};
      const result = createIssueHelper(
        tx,
        getters,
        title,
        space.id,
        statusId ?? defaultStatusForSpace(getters, spaceId),
        user.id,
        { ...otherOptions, description }
      );

      return result;
    });
  };
}

export function useReplaceDescription() {
  const modelManager = useModelManager();

  return useRecoilCallback<
    [
      entityIdid: string,
      options: {
        document: DocumentLike;
      }
    ],
    void
  >(({ snapshot }) => {
    return (
      entityId: string,
      options: {
        document: DocumentLike;
      }
    ) => {
      const documentId = snapshot
        .getLoadable(collaborativeDocIdByEntitySelector(entityId))
        .getValue();
      if (!documentId) {
        return;
      }
      let editor = modelManager.getCollaborativeDocumentEditor(documentId);
      const hasActiveEditor = !!editor;

      if (!editor) {
        const document = snapshot
          .getLoadable(collaborativeDocSelector(documentId ?? ''))
          .getValue();

        if (!document) {
          return;
        }

        const contents = document.content;

        // instantiate a collaborative editor with the smart todo stuff turned on and do a quick edit
        const collaborationPlugin = withCollaboration(
          modelManager,
          document.id,
          'CollaborativeDoc',
          document.version
        );
        const nonCollaborativeEditor = withSchema(createEditor());
        KitemakerTransforms.insertNodes(nonCollaborativeEditor, contents);
        editor = collaborationPlugin(nonCollaborativeEditor);
        modelManager.setCollaborativeDocumentEditor(document.id, editor);
      }

      // Select all and remove
      if (editor !== null) {
        editor.children.forEach(_ => KitemakerTransforms.delete(editor!, { at: [0] }));
        KitemakerTransforms.insertFragment(editor, options.document);
      }

      //
      if (editor && !hasActiveEditor) {
        modelManager.unsetCollaborativeDocumentEditor(documentId);
      }
    };
  });
}

export function useAppendToDocument() {
  const modelManager = useModelManager();

  return useRecoilCallback<
    [
      entityIdid: string,
      options: {
        document: DocumentLike;
      }
    ],
    void
  >(({ snapshot }) => {
    return (
      entityId: string,
      options: {
        document: DocumentLike;
      }
    ) => {
      const documentId = snapshot
        .getLoadable(collaborativeDocIdByEntitySelector(entityId))
        .getValue();
      if (!documentId) {
        return;
      }
      let editor = modelManager.getCollaborativeDocumentEditor(documentId);
      const hasActiveEditor = !!editor;

      if (!editor) {
        const document = snapshot
          .getLoadable(collaborativeDocSelector(documentId ?? ''))
          .getValue();

        if (!document) {
          return;
        }

        const contents = document.content;

        // instantiate a collaborative editor with the smart todo stuff turned on and do a quick edit
        const collaborationPlugin = withCollaboration(
          modelManager,
          document.id,
          'CollaborativeDoc',
          document.version
        );
        const nonCollaborativeEditor = withSchema(createEditor());
        KitemakerTransforms.insertNodes(nonCollaborativeEditor, contents);
        editor = collaborationPlugin(nonCollaborativeEditor);
        modelManager.setCollaborativeDocumentEditor(document.id, editor);
      }

      if (editor !== null) {
        KitemakerTransforms.moveSelectionToEnd(editor);
        KitemakerTransforms.insertText(editor, '\n');
        KitemakerTransforms.insertFragment(editor, options.document);
      }

      if (editor && !hasActiveEditor) {
        modelManager.unsetCollaborativeDocumentEditor(documentId);
      }
    };
  });
}
