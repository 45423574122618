import debugModule from 'debug';
import { VERSION } from '../../shared/utils/version';

const debug = debugModule('version');

export enum VersionCheckResult {
  None,
  NewVersionAvailable,
  ForceNewVersion,
}

export interface ServerVersionInformation {
  current: number;
  minor: number;
  force: number;
}

export async function getServerVersion(): Promise<ServerVersionInformation | null> {
  try {
    const result = await fetch('/version', {
      credentials: 'same-origin',
    });

    if (!result.ok) {
      debug('Received non 2XX response fetching version');
      return null;
    }

    const jsonResult = await result.json();
    return jsonResult as ServerVersionInformation;
  } catch (e) {
    debug(`Error fetching version ${e}`);
    return null;
  }
}

export async function checkIfNewVersionAvailable(): Promise<VersionCheckResult> {
  const serverVersion = await getServerVersion();

  if (serverVersion) {
    if (serverVersion.force > VERSION) {
      return VersionCheckResult.ForceNewVersion;
    }
    if (serverVersion.current > VERSION) {
      return VersionCheckResult.NewVersionAvailable;
    }
  }

  return VersionCheckResult.None;
}
