import { atom, selector, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil';

export enum CommandGroup {
  Other = 'Other',
  Board = 'Board',
  Entities = 'entities',
  Todos = 'todos',
  Settings = 'Settings',
  SettingsNavigation = 'SettingsNavigation',
  SpaceSettingsNavigation = 'SpaceSettingsNavigation',
  Space = 'Space',
  EntitySearch = 'EntitySearch',
  Organizations = 'Organizations',
  Developer = 'Developer',
  Navigation = 'Navigation',
  Status = 'Status',
  Horizon = 'Horizon',
  Comment = 'Comment',
  Cycle = 'Cycle',
  Activity = 'Activity',
  Update = 'Update',
  Roadmap = 'Roadmap',
}

export interface CommandDefinition {
  id: string;
  description: string;
  handler: () => void;
  group?: CommandGroup;
  aliases?: string[];
  priority?: number;
  hotkey?: string;
  hotkeyHintOnly?: boolean;
  icon?: string;
  global?: boolean;
}

interface BaseCommandGroupContext {
  group: CommandGroup;
}

export type CommandGroupContext =
  | SpaceCommandGroupContext
  | StatusCommandGroupContext
  | EntityCommandGroupContext
  | TodoCommandGroupContenxt
  | CommentGroupContext
  | ActivityGroupContext
  | CycleCommandGroupContext
  | RoadmapCommandGroupContext;

export interface SpaceCommandGroupContext {
  group: CommandGroup.Space;
  spaceId: string;
}

export interface StatusCommandGroupContext {
  group: CommandGroup.Status;
  statusId: string;
}

export interface EntityCommandGroupContext extends BaseCommandGroupContext {
  group: CommandGroup.Entities;
  entityIds: string[];
  nonEntityIds?: string[];
  focusedEntityId?: string | null;
  roadmapId?: string;
}

export interface RoadmapCommandGroupContext extends BaseCommandGroupContext {
  group: CommandGroup.Roadmap;
  roadmapId: string;
  columnId?: string;
  spaceId?: string;
}

export interface TodoCommandGroupContenxt extends BaseCommandGroupContext {
  group: CommandGroup.Todos;
  todoId: string;
  focusedTodoId?: string | null;
}

export interface CycleCommandGroupContext extends BaseCommandGroupContext {
  group: CommandGroup.Cycle;
  focusedCycleId: string;
  cycleIds: string[];
}

export interface CommentGroupContext extends BaseCommandGroupContext {
  group: CommandGroup.Comment;
  commentId: string;
}

export interface ActivityGroupContext extends BaseCommandGroupContext {
  group: CommandGroup.Activity;
  activityId: string;
}

export const customCommandMenuCommands = atom<Record<string, CommandDefinition>>({
  key: 'customCommandMenuCommands',
  default: {},
});

export const customCommandSelector = selector({
  key: 'CustomCommands',
  get: ({ get }) => Object.values(get(customCommandMenuCommands)),
});

export function useRegisterCustomCommand() {
  const setCustomCommands = useSetRecoilState(customCommandMenuCommands);

  return {
    registerCommand: (command: CommandDefinition) =>
      setCustomCommands(previous => {
        return {
          ...previous,
          [command.id]: { ...command, custom: true, id: `custom-${command.id}` },
        };
      }),
    unregisterCommand: (id: string) =>
      setCustomCommands(previous => {
        const updated = { ...previous };
        delete updated[id];
        return updated;
      }),
  };
}

export const commandMenuContext = atom<CommandGroupContext[]>({
  key: 'CommandMenuContext',
  default: [],
});

export const commandMenuContextByGroup = selectorFamily({
  key: 'CommandMenuContextByGroup',
  get:
    (group: CommandGroup) =>
    ({ get }) =>
      get(commandMenuContext).find(c => c.group === group) ?? null,
});

export function useRegisterContext() {
  const setContext = useSetRecoilState(commandMenuContext);

  return {
    registerContext: (context: CommandGroupContext) =>
      setContext(previous => {
        return [...previous.filter(c => c.group !== context.group), context];
      }),
    unregisterContext: (context: CommandGroupContext) =>
      setContext(previous => {
        return previous.filter(c => c.group !== context.group);
      }),
  };
}

export function useCommandMenuContext<T extends CommandGroupContext>(group: CommandGroup) {
  return useRecoilValue(commandMenuContextByGroup(group)) as T | null;
}
