import cn from 'classnames';
import * as React from 'react';
import icon from '../../assets/images/new/spinner.svg?url';
import styles from './loadingSpinner.module.scss';

export function LoadingSpinner({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  return <img className={cn(styles.loadingSpinner, className)} style={style} src={icon} />;
}
