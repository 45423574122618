import { keys } from 'lodash';
import * as React from 'react';
import { atom, selector, useRecoilState } from 'recoil';

export interface FeatureFlagMap {
  [index: string]: boolean;
}
export interface IntegrationConfig {
  url: string;
}

export interface IntegrationMap {
  [index: string]: IntegrationConfig;
}

export interface Configuration {
  host: string;
  electronScheme: string;
  production: boolean;
  maintenanceMode: boolean;
  featureFlags: FeatureFlagMap;
  integrations: IntegrationMap;
  stripeKey?: string;
  intercomAppId?: string;
  ssoOrganization?: string | null;
  pricingChangeGrandfatherDate?: number;
  websocketToken?: string | null;
  setConfiguration(config: Partial<Configuration>): void;
  error?: string;
  errorMessage?: string;
  defaultOrganizationId?: string;
  europeanDataResidency?: boolean;
  isDeveloper: boolean;

  developer?: {
    allFeatures: string[];
    enabledFeatures: FeatureFlagMap;
    setFeatures: (state: FeatureFlagMap) => void;
    defaultFeatureFlags: FeatureFlagMap;
  };
}

export const configurationContext = React.createContext<Configuration | null>(null);

const configurationAtom = atom<Omit<Configuration, 'setConfiguration' | 'developer'>>({
  key: 'configuration',
  default: JSON.parse(document.getElementById('initial-data')!.innerText) as Omit<
    Configuration,
    'setConfiguration' | 'developer'
  >,
});

export const devFeaturesAtom = atom<FeatureFlagMap>({
  key: 'DevFeatures',
  default: JSON.parse(localStorage.getItem('__dev__features__') ?? '{}') as FeatureFlagMap,
});

export const featureFlagsSelector = selector<FeatureFlagMap>({
  key: 'featureFlags',
  get: ({ get }) => {
    const data = get(configurationAtom);
    const devFeatures = get(devFeaturesAtom);

    return {
      ...data.featureFlags,
      ...devFeatures,
    };
  },
});

export function ConfigurationProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = useRecoilState(configurationAtom);
  const [enabledFeatures, setEnabledFeatures] = useRecoilState(devFeaturesAtom);

  React.useEffect(() => {
    localStorage.setItem('__dev__features__', JSON.stringify(enabledFeatures));
  }, [enabledFeatures]);

  const value: Configuration = React.useMemo(() => {
    return {
      ...data,
      setConfiguration: (config: Partial<Configuration>) => {
        setData({ ...data, ...config });
      },
      ...(data.isDeveloper
        ? {
            developer: {
              setFeatures: (state: FeatureFlagMap) => {
                setEnabledFeatures(state);
              },
              allFeatures: keys(data.featureFlags),
              enabledFeatures,
              defaultFeatureFlags: data.featureFlags,
            },
            featureFlags: Object.assign({}, data.featureFlags, enabledFeatures),
          }
        : {}),
    };
  }, [data, enabledFeatures]);

  return <configurationContext.Provider value={value}>{children}</configurationContext.Provider>;
}

export function useConfiguration(): Configuration {
  const config = React.useContext(configurationContext)!;
  return config;
}
