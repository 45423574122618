import debugModule from 'debug';
import { pickBy } from 'lodash';
// eslint-disable-next-line import/no-unresolved

import { CACHE_VERSION } from '../../shared/utils/version';
import { SyncEngineValue } from '../syncEngine/types';
import { cache } from '../utils/cache';
import { cacheData } from '../workers/cache.worker';
import Worker from '../workers/cache.worker?worker';

const debug = debugModule('offlineCache');
function cacheKey(organizationSlug: string) {
  return `__offlineCache_${organizationSlug}`;
}
const currentOrganizationKey = '__currentOrganization';

const worker = new Worker();
let reloadPending = false;

export async function fetchCache(
  organizationSlug?: string
): Promise<{ data: Record<string, SyncEngineValue>; slug?: string }> {
  debug('Fetching cached data');
  try {
    let slug: string | null | undefined = organizationSlug;
    if (!slug) {
      slug = await cache()?.getItem<string>(currentOrganizationKey);
    }
    if (!slug) {
      return { data: {} };
    }
    const cached = await cache()?.getItem<{
      data: Record<string, SyncEngineValue>;
      metadata?: Record<string, unknown>;
    }>(cacheKey(slug));
    if (!cached) {
      debug('No cached data found');
      return { data: {} };
    }

    if (cached.metadata?.version !== CACHE_VERSION) {
      debug('Found %s cached objects, but version is out of date', cached.data.length);
      await clearCache();
      return { data: {} };
    }

    debug('Found %s cached objects', Object.keys(cached.data).length);
    return { data: cached.data, slug };
  } catch (e) {
    debug('Error reading from cache', e);
    return { data: {} };
  }
}

export async function updateCache(
  organizationSlug: string,
  objects: Record<string, SyncEngineValue>
) {
  // if we've just cleared the cache, don't write any values. Wait for the reload
  if (reloadPending) {
    return;
  }

  const filteredObjects = pickBy(objects, (_value, key) => !key.startsWith('__'));
  debug('Replacing cache with %s objects', Object.values(filteredObjects).length);
  await cache()?.setItem(currentOrganizationKey, organizationSlug);
  cacheData(worker, cacheKey(organizationSlug), filteredObjects, { version: CACHE_VERSION });
}

export async function clearCacheAndReload() {
  debug('Clearing cache and reloading');
  try {
    reloadPending = true;
    await cache()?.clear();
    window.location.reload();
  } catch (e) {
    debug('Error clearing cache', e);
  }
}

export async function clearCache(disableFurtherFlushes = false) {
  debug('Clearing cache');
  try {
    if (disableFurtherFlushes) {
      reloadPending = true;
    }
    await cache()?.clear();
  } catch (e) {
    debug('Error clearing cache', e);
  }
}
