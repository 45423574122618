import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Hotkey } from '../../components/new/hotkey';
import { Switch } from '../../components/new/switch';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useUpdateOrganization } from '../../syncEngine/actions/organizations';
import { useAddBacklog, useRemoveBacklog, useUpdateSpaces } from '../../syncEngine/actions/spaces';
import { boardsForSpaceSelector } from '../../syncEngine/selectors/boards';
import { spacesForOrganizationSelector } from '../../syncEngine/selectors/spaces';
import { trackerPageLoad } from '../../tracker';
import styles from './onboardingScreen.module.scss';

function Setting({
  enabled,
  onEnabledChanged,
  name,
  description,
}: {
  enabled: boolean;
  onEnabledChanged: (enabled: boolean) => void;
  name: string;
  description: string;
}) {
  return (
    <div className={styles.setting}>
      <div className="col mr4">
        <div className="headingM mb4">{name}</div>
        <div className="bodyM grayed">{description}</div>
      </div>
      <Switch checked={enabled} onChange={onEnabledChanged} />
    </div>
  );
}

export function VisualizeAndPlanWorkStep({ onNext }: { onNext: () => void }) {
  useComponentDidMount(() => {
    trackerPageLoad('TutorialVisualizeAndPlanWork');
  });

  const organization = useOrganization();
  const user = useCurrentUser();
  const isOwner = user.id === organization.actorId;

  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const space = spaces[0] ?? null;
  const boards = useRecoilValue(boardsForSpaceSelector(space?.id));
  const hasBacklog = !!boards.find(b => b.key === 'backlog');
  const addBacklog = useAddBacklog();
  const removeBacklog = useRemoveBacklog();
  const updateOrganization = useUpdateOrganization();
  const updateSpaces = useUpdateSpaces();
  const modals = useModals();

  return (
    <div className={styles.visualizeContainer}>
      <div className={cn('headingXL', styles.heading)}>Visualize and plan your work</div>
      <p className={cn('slightlyGrayed bodyXL mt16 mb20 textCenter', styles.heading)}>
        Visualize the state of your work in one clear board, with editable drag-and-drop columns.
      </p>

      <div className={styles.visualizeGrid}>
        <div className={styles.image}>
          <img
            src="https://static.kitemaker.co/onboarding/board.png"
            className={styles.onboardingImage}
          />
        </div>
        {isOwner && space && (
          <>
            <Setting
              name="Planning board"
              description="The planning board gives you an additional board to organize your team’s upcoming work."
              enabled={hasBacklog}
              onEnabledChanged={enabled => {
                if (enabled) {
                  addBacklog(space.id);
                } else {
                  removeBacklog(space.id, true);
                }
              }}
            />
            <Setting
              name="Roadmaps"
              description="Effectively organize large initiatives with roadmaps."
              enabled={organization.newRoadmapsEnabled ?? false}
              onEnabledChanged={enabled => {
                updateOrganization({ newRoadmapsEnabled: enabled });
              }}
            />
            <Setting
              name="Cycles"
              description="Cycles let you plan and track work in regular intervals."
              enabled={space.cyclesEnabled ?? false}
              onEnabledChanged={enabled => {
                if (enabled) {
                  modals.openModal(Modals.EnableCycles, { spaceId: space.id });
                  return;
                }
                updateSpaces([space.id], { cyclesEnabled: !space.cyclesEnabled });
              }}
            />
          </>
        )}
      </div>

      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onNext();
        }}
      >
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          className="fullWidth"
        >
          Continue
        </Button>
      </form>
      <Hotkey
        hotkey="enter"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onNext();
        }}
      />
    </div>
  );
}
