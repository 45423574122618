import cn from 'classnames';
import * as React from 'react';
import { BlockMath } from 'react-katex';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';
import { MathExpressionElement } from '../../../shared/slate/types';
import { CommentButton } from '../../components/new/commentButton';
import { VoidActions } from '../../components/new/voidActions';
import { useInteractivityDisabled } from '../hooks/useInteractivityDisabled';
import { MathHover } from '../hovers/mathHover';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { useDragAndDrop } from '../plugins/dragAndDrop/useDragAndDrop';
import { OptionalAttributesRenderElementProps } from '../types';
import styles from './mathExpression.module.scss';
import { VoidBlock } from './voidBlock';

export function StaticMathExpression({
  element,
  children,
  attributes,
}: OptionalAttributesRenderElementProps & { element: MathExpressionElement }) {
  const katex = element.katex;

  return (
    <div className={cn(styles.block, styles.mathContainer)} {...attributes}>
      <div className={styles.math}>
        {katex && <BlockMath math={katex} />}
        {!katex && <div className={styles.mathPlaceholder}>Add a TeX expression</div>}
        {children}
      </div>
    </div>
  );
}

export function MathExpression({
  attributes,
  element,
  children,
}: RenderElementProps & { element: MathExpressionElement }) {
  const { katex } = element;
  const editor = useSlateStatic();
  const interactivityDisabled = useInteractivityDisabled();

  const { dndAttributes, dndComponents, dndClassName } = useDragAndDrop();

  const [showHover, setShowHover] = React.useState(false);

  function focus() {
    ReactEditor.focus(editor);
    setTimeout(() => {
      const path = ReactEditor.findPath(editor, element);
      KitemakerTransforms.moveSelectionToPath(editor, path);
    }, 100);
  }

  const content = (
    <>
      <div className="rowCenter">{children}</div>
      <VoidBlock
        element={element}
        onClick={e => {
          if (showHover) {
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          setShowHover(true);
        }}
      >
        <div className={styles.math}>
          {katex && <BlockMath math={katex} />}
          {!katex && <div className={styles.mathPlaceholder}>Add a TeX expression</div>}
          {editor.inlineComments && editor.entityId && (
            <VoidActions floating className={styles.actions}>
              <CommentButton element={element} />
            </VoidActions>
          )}
        </div>
      </VoidBlock>
    </>
  );

  if (!showHover || interactivityDisabled) {
    return (
      <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
        {dndComponents}
        {content}
      </div>
    );
  }

  return (
    <div {...attributes} {...dndAttributes} className={cn('block', dndClassName)}>
      {dndComponents}
      <MathHover
        katex={katex ?? ''}
        onChange={value => {
          const path = ReactEditor.findPath(editor, element);
          KitemakerTransforms.setNodes(editor, { katex: value }, { at: path });
          focus();
        }}
        onHide={escape => {
          setShowHover(false);
          if (escape) {
            focus();
          }
        }}
      >
        <div>{content}</div>
      </MathHover>
    </div>
  );
}
