import * as React from 'react';
import { Tooltip } from '../tooltip';
import Pill, { PillStyle } from './pill';
import { MetadataSize } from './size';

export default function Label({
  color,
  name,
  size,
  noHover,
  forceDarkMode,
  className,
}: {
  color: string;
  name: string;
  size: MetadataSize;
  noHover?: boolean;
  forceDarkMode?: boolean;
  className?: string;
}) {
  const ref = React.useRef<HTMLSpanElement>(null);
  const [toolTipEnabled, setToolTipEnabled] = React.useState(false);

  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth < ref.current.scrollWidth) {
      setToolTipEnabled(true);
    }
  }, []);
  const content = (
    <Pill
      size={size}
      color={color}
      pillStyle={PillStyle.Secondary}
      className={className}
      textOnly
      noHover={noHover}
      forceDarkMode={forceDarkMode}
    >
      <span ref={ref}>{name}</span>
    </Pill>
  );

  if (!toolTipEnabled) {
    return content;
  }
  return (
    <Tooltip disableHoverableContent content={<div>{name}</div>}>
      {content}
    </Tooltip>
  );
}
