import { partition } from 'lodash';
import { selectorFamily } from 'recoil';
import { currentUserMembershipState } from './users';

export const idsSnoozedSelector = selectorFamily({
  key: 'IdsSnoozed',
  get:
    ({ ids, organizationId }: { ids: string[] | undefined | null; organizationId: string }) =>
    ({ get }) => {
      if (!ids || !organizationId) {
        return { snoozed: [], unsnoozed: [] };
      }
      const organizationMember = get(currentUserMembershipState(organizationId));
      if (!organizationMember) {
        return { snoozed: [], unsnoozed: [] };
      }

      const [snoozed, unsnoozed] = partition(ids, id => {
        const snoozed = organizationMember.snoozed?.find(s => s.id === id);
        if (!snoozed) {
          return false;
        }
        return snoozed.snoozedUntil > Date.now().valueOf();
      });
      return { snoozed, unsnoozed };
    },
});
