import * as React from 'react';
import { atom, useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { emptyDocument } from '../../../shared/slate/utils';
import { filterNotDeletedNotNull } from '../../../shared/utils/convenience';
import { between } from '../../../shared/utils/sorting';
import { docWatchers } from '../../../sync/__generated/collections';
import {
  documentsByFolder,
  documentsByOrganization,
  foldersByOrganization,
  foldersByParent,
} from '../../../sync/__generated/indexes';
import { Doc, Folder } from '../../../sync/__generated/models';
import { EntityLink } from '../../components/new/entityLink';
import NewEntityToastContents from '../../components/newEntityToastContents';
import { toast } from '../../components/toast';
import { useConfirmation } from '../../contexts/confirmationContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useUndo } from '../../contexts/undoContext';
import { useCurrentUser } from '../../contexts/userContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineTransaction,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { DocumentLike } from '../../slate/types';
import { trackerEvent } from '../../tracker';
import { nextAvailableNumber } from '../../utils/entities';
import { syncEngineState } from '../state';
import { isSyncEngineObject } from '../types';
import { createCollaborativeDocHelper } from './collaborativeDoc';
import { indexHelper, updateSortableSorts } from './helpers';

function createDocumentHelper(
  tx: SyncEngineTransaction,
  getters: SyncEngineGetters,
  properties: {
    title: string;
    actorId: string;
    organizationId: string;
    folderId?: string | null;
    sort?: string;
    content?: DocumentLike;
    icon?: string;
  }
) {
  const { title, actorId, organizationId, folderId, sort: rawSort, content, icon } = properties;

  let sort = rawSort;
  if (!sort) {
    const docs = folderId
      ? indexHelper<Doc>(getters, documentsByFolder, folderId)
      : indexHelper<Doc>(getters, documentsByOrganization, organizationId).filter(d => !d.folderId);
    sort = between({ before: docs?.[0]?.sort });
  }

  const doc: SyncEngineCreate<Doc> = {
    __typename: 'Doc',
    title,
    actorId,
    sort,
    organizationId,
    number: nextAvailableNumber(getters, organizationId, 'document'),
    icon: icon ?? null,
    folderId: folderId ?? null,
    archivedAt: null,
    watcherIds: [],
  };
  const docResult = tx.create<Doc>(doc);

  createCollaborativeDocHelper(tx, organizationId, content ?? emptyDocument(), docResult.id);
  tx.addToCollection(docWatchers, docResult.id, [actorId]);

  return docResult;
}

export function useCreateDocument() {
  const modelManager = useModelManager();
  const user = useCurrentUser();
  const organization = useOrganization();

  return (
    title: string,
    options?: {
      sort?: string;
      content?: DocumentLike;
      folderId?: string | null;
    }
  ) => {
    return modelManager.transaction((tx, getters) => {
      const result = createDocumentHelper(tx, getters, {
        title,
        actorId: user.id,
        organizationId: organization.id,
        ...options,
      });
      if (result) {
        trackerEvent('Document Created', {
          id: result.id,
        });
      }
      toast.success(<NewEntityToastContents entityId={result.id} />);
      return result;
    });
  };
}

const deleteDocumentsContext = atom<{
  onDelete?: (docs: Doc[]) => void;
} | null>({
  key: 'DeleteDocumentsContext',
  default: null,
});

export function useDeleteDocumentsContext(callbacks: { onDelete?: (docs: Doc[]) => void }) {
  const setContext = useSetRecoilState(deleteDocumentsContext);

  React.useEffect(() => {
    setContext(callbacks);
    return () => setContext(null);
  });
}

export function useDeleteDocumentsAndFolders() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();
  const context = useRecoilValue(deleteDocumentsContext);

  return async (docIds: string[], folderIds: string[]) => {
    if (!docIds.length && !folderIds.length) {
      return;
    }

    let title = `Delete document${docIds.length > 1 ? 's' : ''} and folder${
      folderIds.length > 1 ? 's' : ''
    }`;
    let message = `Are you sure you want to delete ${
      docIds.length > 1 ? 'these documents' : 'this document'
    } and ${
      folderIds.length > 1 ? 'these folders' : 'this folder'
    }? There is no way to undo this operation`;

    if (!folderIds.length) {
      title = `Delete document${docIds.length > 1 ? 's' : ''}`;
      message = `Are you sure you want to delete ${
        docIds.length > 1 ? 'these documents' : 'this document'
      }? There is no way to undo this operation`;
    } else if (!docIds.length) {
      title = `Delete folder${folderIds.length > 1 ? 's' : ''}`;
      message = `Are you sure you want to delete ${
        folderIds.length > 1 ? 'these folders' : 'this folder'
      }? There is no way to undo this operation`;
    }

    const confirmed = await confirm(title, message, {
      label: 'Delete',
      destructive: true,
    });

    if (!confirmed) {
      return [];
    }

    const deleted: Doc[] = [];

    modelManager.transaction((tx, { get, getIndex }) => {
      for (const docId of docIds) {
        const doc = get<Doc>(docId);
        if (!doc || doc.deleted) {
          continue;
        }

        tx.update<Doc>(docId, {
          deleted: true,
        });
        deleted.push(doc);

        trackerEvent('Document Deleted', {
          id: doc.id,
        });
      }

      const recursiveDelete = (folderId: string) => {
        const folder = get<Folder>(folderId);
        if (!folder || folder.deleted) {
          return;
        }

        const childDocs = indexHelper<Folder>({ get, getIndex }, documentsByFolder, folderId);
        for (const childDoc of childDocs) {
          tx.update<Doc>(childDoc.id, {
            deleted: true,
          });
        }

        const childFolders = indexHelper<Folder>({ get, getIndex }, foldersByParent, folderId);
        for (const childFolder of childFolders) {
          recursiveDelete(childFolder.id);
        }

        tx.update<Folder>(folderId, {
          deleted: true,
        });
      };

      for (const folderId of folderIds) {
        recursiveDelete(folderId);
        trackerEvent('Folder Deleted', {
          id: folderId,
        });
      }
    });

    context?.onDelete?.(deleted);
    return deleted;
  };
}

export function useUpdateDocuments() {
  const modelManager = useModelManager();
  return (docIds: string[], update: SyncEngineUpdateWithoutDelete<Doc>) => {
    modelManager.transaction((tx, { get }) => {
      for (const docId of docIds) {
        const doc = get<Doc>(docId);
        if (!doc || doc.deleted) {
          continue;
        }
        tx.update<Doc>(docId, update);

        if (update.title) {
          trackerEvent('Document Updated', {
            id: doc.id,
            type: 'Title',
          });
        }
      }
    });
  };
}

export function useUpdateDocumentSorts() {
  const modelManager = useModelManager();
  const organization = useOrganization();

  return (folderId: string | null, docIds: string[], afterDocId?: string, beforeDocId?: string) => {
    modelManager.transaction((tx, getters) => {
      const docs = folderId
        ? indexHelper<Doc>(getters, documentsByFolder, folderId)
        : indexHelper<Doc>(getters, documentsByOrganization, organization.id).filter(
            d => !d.folderId
          );

      const updates = updateSortableSorts(docs, docIds, afterDocId, beforeDocId);
      for (const docId in updates) {
        tx.update<Doc>(docId, {
          sort: updates[docId],
        });
      }
    });
  };
}

export function useMoveDocumentsAndFoldersToFolder() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  const organization = useOrganization();

  return (docIds: string[], folderIds: string[], parentId: string | null) => {
    if (!docIds.length && !folderIds.length) {
      return;
    }

    modelManager.transaction((tx, { get, getIndex }) => {
      const undoDocMoves: Array<{ docId: string; oldFolderId: string | null; oldSort: string }> =
        [];
      const docs = filterNotDeletedNotNull(docIds.map(docId => get<Doc>(docId)));
      const folderName = get<Folder>(parentId ?? '')?.name ?? 'Documents';
      const docsForFolder = parentId
        ? indexHelper<Doc>({ get, getIndex }, documentsByFolder, parentId)
        : indexHelper<Doc>({ get, getIndex }, documentsByOrganization, organization.id).filter(
            d => !d.folderId
          );
      const firstDocSort = docsForFolder?.[0]?.sort ?? between({});

      let sort = between({ before: firstDocSort });

      for (const doc of docs) {
        undoDocMoves.push({
          docId: doc.id,
          oldFolderId: doc.folderId,
          oldSort: doc.sort,
        });

        tx.update<Doc>(doc.id, {
          folderId: parentId,
        });
        sort = between({ before: firstDocSort, after: sort });
      }

      const undoFolderMoves: Array<{
        folderId: string;
        oldParentId: string | null;
        oldSort: string;
      }> = [];
      const folders = filterNotDeletedNotNull(folderIds.map(folderId => get<Folder>(folderId)));
      const existingChildFolders = parentId
        ? indexHelper<Folder>({ get, getIndex }, foldersByParent, parentId)
        : indexHelper<Folder>({ get, getIndex }, foldersByOrganization, organization.id).filter(
            f => !f.parentId
          );
      const firstFolderSort = existingChildFolders?.[0]?.sort ?? between({});

      sort = between({ before: firstFolderSort });

      for (const folder of folders) {
        undoFolderMoves.push({
          folderId: folder.id,
          oldParentId: folder.parentId,
          oldSort: folder.sort,
        });

        tx.update<Folder>(folder.id, {
          parentId,
        });
        sort = between({ before: firstFolderSort, after: sort });
      }

      let action = (
        <>
          {docs.length > 1 ? `${docs.length} documents` : <EntityLink id={docs[0]?.id} />} and{' '}
          {folders.length > 1 ? `${folders.length} folders` : `folder ${folders[0]?.name}`} moved to{' '}
          {folderName}
        </>
      );

      if (!folders.length) {
        action = (
          <>
            {docs.length > 1 ? `${docs.length} documents` : <EntityLink id={docs[0].id} />} moved to{' '}
            {folderName}
          </>
        );
      } else if (!docs.length) {
        action = (
          <>
            {folders.length > 1 ? `${folders.length} folders` : `folder ${folders[0].name}`} moved
            to {folderName}
          </>
        );
      }

      setUndo(action, () => {
        modelManager.transaction(tx => {
          for (const { docId, oldFolderId, oldSort } of undoDocMoves) {
            tx.update<Doc>(docId, {
              folderId: oldFolderId,
              sort: oldSort,
            });
          }
          for (const { folderId: docId, oldParentId, oldSort } of undoFolderMoves) {
            tx.update<Folder>(docId, {
              parentId: oldParentId,
              sort: oldSort,
            });
          }
        });
      });
    });
  };
}

export function usePartitionDocsAndFolders() {
  return useRecoilCallback(({ snapshot }) => (docsAndFolderIds: string[]) => {
    const docIds: string[] = [];
    const folderIds: string[] = [];

    for (const id of docsAndFolderIds) {
      const item = snapshot.getLoadable(syncEngineState(id)).getValue();
      if (!item) {
        continue;
      }
      if (isSyncEngineObject(item)) {
        if (item.__typename === 'Doc') {
          docIds.push(id);
        } else if (item.__typename === 'Folder') {
          folderIds.push(id);
        }
      }
    }

    return { docIds, folderIds };
  });
}

export function useArchiveDocuments() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  return (documentIds: string[]) => {
    const update: SyncEngineUpdateWithoutDelete<Doc> = { archivedAt: Date.now() };

    modelManager.transaction((tx, { get }) => {
      for (const documentId of documentIds) {
        const existingDocument = get<Doc>(documentId);
        if (!existingDocument) {
          continue;
        }
        if (existingDocument.archivedAt) {
          continue;
        }
        tx.update(documentId, update);
      }
      setUndo(`Document${documentIds.length > 1 ? 's' : ''} archived`, () => {
        modelManager.transaction(tx => {
          for (const documentId of documentIds) {
            tx.update<Doc>(documentId, {
              archivedAt: null,
            });
          }
        });
      });
    });
  };
}

export function useUnarchiveDocuments() {
  const modelManager = useModelManager();
  const { setUndo } = useUndo();
  return (documentIds: string[]) => {
    const update: SyncEngineUpdateWithoutDelete<Doc> = { archivedAt: null };

    modelManager.transaction((tx, { get }) => {
      for (const documentId of documentIds) {
        const existingDocument = get<Doc>(documentId);
        if (!existingDocument) {
          continue;
        }
        if (!existingDocument.archivedAt) {
          continue;
        }

        tx.update(documentId, update);
      }
      const action = 'unarchived';
      setUndo(`Document${documentIds.length > 1 ? 's' : ''} ${action}`, () => {
        modelManager.transaction(tx => {
          for (const documentId of documentIds) {
            tx.update<Doc>(documentId, {
              archivedAt: Date.now(),
            });
          }
        });
      });
    });
  };
}
