import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useClient } from 'urql';
import { resetPassword, validateResetPasswordToken } from '../../api/auth';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { TextInput, TextInputSize } from '../../components/new/textInput';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { toast } from '../../components/toast';
import { trackerPageLoad } from '../../tracker';
import { BasicErrorScreen } from '../errorScreens';

export function ResetPasswordScreenContents({
  onSubmit,
}: {
  onSubmit: (data: { onError: () => void; password: string }) => void;
}) {
  const [password, setPassword] = React.useState('');
  const [verifyPassword, setVerifyPassword] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const valid = React.useMemo(() => {
    return password.length && password === verifyPassword;
  }, [password, verifyPassword]);

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL oneLine mb4">Reset password</div>
      <div className="bodyM grayed mb32">Enter your new password to continue.</div>
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          setRequestInProgress(true);
          onSubmit({ password, onError: () => setRequestInProgress(false) });
        }}
      >
        <label htmlFor="password">Password</label>
        <TextInput
          name="password"
          inputSize={TextInputSize.Large}
          type="password"
          className="fullWidth mb24"
          placeholder="New password"
          value={password}
          disabled={requestInProgress}
          autoFocus
          onChange={e => setPassword(e.currentTarget.value)}
        />
        <label htmlFor="repeatPassword">Repeat password</label>
        <TextInput
          name="repeatPassword"
          inputSize={TextInputSize.Large}
          type="password"
          className="fullWidth"
          placeholder="Repeat your new password"
          value={verifyPassword}
          disabled={requestInProgress}
          onChange={e => setVerifyPassword(e.currentTarget.value)}
        />
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          type="submit"
          className="fullWidth mt24"
          disabled={!valid || requestInProgress}
        >
          <div className="row">Reset password</div>
        </Button>
      </form>
    </div>
  );
}

export function ResetPasswordScreen() {
  const client = useClient();
  const history = useHistory();
  const { params } = useRouteMatch<{ token: string }>();
  const { token } = params;
  const [error, setError] = React.useState('');
  const [tokenValidated, setTokenValidated] = React.useState(false);

  React.useEffect(() => {
    trackerPageLoad('Reset password');
  }, []);

  React.useEffect(() => {
    (async () => {
      const tokenValid = await validateResetPasswordToken(client, token);
      if (!tokenValid) {
        setError('This reset password link appears to be invalid.');
      }
      setTokenValidated(true);
    })();
  }, [token, client]);

  if (!tokenValidated) {
    return null;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{error}</BasicErrorScreen>;
  }

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Reset password" />
      <ResetPasswordScreenContents
        onSubmit={async ({ password, onError }) => {
          try {
            await resetPassword(token, password);
            toast.info(
              `Password successfully reset. Hang on and we'll send you back so you can login`
            );
            setTimeout(() => history.replace('/'), 3000);
          } catch (e) {
            toast.error(e.message);
            onError();
          }
        }}
      />
    </AuxiliaryScreen>
  );
}
