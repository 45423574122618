import * as React from 'react';
import {
  Activity as ActivityModel,
  CompanyPersonChangedActivityDetails,
} from '../../../../sync/__generated/models';
import ActorName from '../../actorName';
import { Company, Person } from '../companyPerson';
import styles from './activities.module.scss';
import { Activity, ActivityTimestamp } from './activity';

function CompanyPersonChangedActivityComponent({ activity }: { activity: ActivityModel }) {
  const { companyId, personId } = activity.details as CompanyPersonChangedActivityDetails;

  let content;

  if (companyId) {
    content = <Company companyId={companyId} noLogo />;
  } else if (personId) {
    content = <Person personId={personId} noLogo />;
  } else {
    content = 'anonymous';
  }

  return (
    <Activity activity={activity} icon="org">
      <ActorName actorId={activity.actorId} className={styles.activityHighlight} /> set the source
      of this feedback to {content}
      .
      <ActivityTimestamp timestamp={activity.createdAt} />
    </Activity>
  );
}

export const CompanyPersonChangedActivity = React.memo(CompanyPersonChangedActivityComponent);
