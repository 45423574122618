import { random } from 'lodash';
import * as React from 'react';
import { useConfiguration } from '../../contexts/configurationContext';
import { useModelManager } from '../../graphql/modelManager';
import { CustomCommand } from '../new/customCommand';
import { toast } from '../toast';

function ChaosMode() {
  const modelManager = useModelManager();

  async function fetch(options?: { iterations?: number }) {
    let iterations = options?.iterations ?? 20;
    toast.info('Fetch chaos mode started');
    while (iterations) {
      toast.info('Fetch chaos mode fetching');
      await modelManager.fetchData();
      toast.info('Fetch chaos mode fetched');
      iterations--;

      // sleep a bit
      await new Promise(resolve => setTimeout(resolve, random(2000, 5000)));
    }
    toast.info('Fetch chaos mode completed');
  }

  return (
    <CustomCommand
      command={{
        id: 'fetch-chaos-mode',
        description: 'Fetch chaos mode',
        handler: () => fetch({ iterations: 20 }),
      }}
    />
  );
}
export function FetchChaosMode() {
  const { production } = useConfiguration();
  if (production) {
    return null;
  }

  return <ChaosMode />;
}
