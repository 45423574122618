import { NodeEntry } from 'slate';
import { CustomRange } from '../../../shared/slate/types';
import { KitemakerEditor } from '../kitemakerEditor';
import { EditorType } from '../types';
import { bigEmojiDecorations } from './bigEmoji';
import { colorPreviewDecorations } from './colorPreview';
import { cursorLocationDecorations } from './cursorLocations';
import { emojiDecorations } from './emojiDecoration';
import { fakeSelection } from './fakeSelection';
import { linkDecorations } from './linkDecorations';
import { syntaxHighlightingDecorations } from './syntaxHighlighting';

export function decorate(
  editor: EditorType,
  richText: boolean,
  collaboration: boolean
): (entry: NodeEntry) => CustomRange[] {
  return entry => {
    const [, path] = entry;
    if (!path.length || !KitemakerEditor.hasPath(editor, path)) {
      return [];
    }

    if (richText) {
      const bigEmoji = bigEmojiDecorations(editor, entry);
      if (bigEmoji.length) {
        return bigEmoji;
      }

      const emojis = emojiDecorations(editor, entry);
      if (emojis.length) {
        return emojis;
      }

      const colorPreview = colorPreviewDecorations(editor, entry);
      if (colorPreview.length) {
        return colorPreview;
      }

      const syntaxHighlighting = syntaxHighlightingDecorations(editor, entry);
      if (syntaxHighlighting.length) {
        return syntaxHighlighting;
      }

      const links = linkDecorations(editor, entry);
      if (links.length) {
        return links;
      }
    }

    if (collaboration) {
      const cursorLocations = cursorLocationDecorations(editor, entry);
      if (cursorLocations.length) {
        return cursorLocations;
      }
    }

    const fakeSelections = fakeSelection(editor, entry);
    if (fakeSelections.length) {
      return fakeSelections;
    }

    return [];
  };
}
