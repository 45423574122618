import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import React from 'react';
import styles from './checkbox.module.scss';
import { Icon } from './icon';

export default function Checkbox({
  checked,
  onCheckedChange,
}: {
  checked: boolean;
  onCheckedChange?: (checkedState: CheckboxPrimitive.CheckedState) => void;
}) {
  return (
    <CheckboxPrimitive.Root
      className={styles.root}
      checked={checked}
      onCheckedChange={onCheckedChange}
    >
      <CheckboxPrimitive.Indicator asChild className={styles.indicator}>
        <Icon className={styles.checked} icon={'todo_filled'} />
      </CheckboxPrimitive.Indicator>
      {!checked && <Icon className={styles.blank} icon={'multiselect_blank'} />}
    </CheckboxPrimitive.Root>
  );
}
