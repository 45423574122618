import { cloneDeep } from 'lodash';
import { Descendant, Editor, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import { KitemakerElement } from '../../../shared/slate/kitemakerNode';
import { getPlainText, isDOMText } from '../../utils/dom';
import { EditorType, Elements } from '../types';
import { useSerializeToMarkdown } from './useSerializeToMarkdown';

// The bulk of this code is lifted from ReactEditor.setFragmentData. We customize it to
// get the markdown behavior as well as omitting HTML elements that we dont' want copied
// to the clipboard.
export function useCopy(editor: EditorType) {
  const serializeToMarkdown = useSerializeToMarkdown();

  return (data: Pick<DataTransfer, 'getData' | 'setData'>) => {
    const { selection } = editor;

    if (!selection) {
      return;
    }

    const [start, end] = Range.edges(selection);
    const startVoid = Editor.void(editor, { at: start.path });
    const endVoid = Editor.void(editor, { at: end.path });

    const startInline = Editor.above(editor, {
      at: start,
      match: n => KitemakerElement.isElement(n) && KitemakerElement.isInline(n),
    });
    const endInline = Editor.above(editor, {
      at: end,
      match: n => KitemakerElement.isElement(n) && KitemakerElement.isInline(n),
    });

    const startBlock = Editor.above(editor, {
      at: start,
      match: n => KitemakerElement.isElement(n) && !KitemakerElement.isInline(n),
    });
    const endBlock = Editor.above(editor, {
      at: end,
      match: n => KitemakerElement.isElement(n) && !KitemakerElement.isInline(n),
    });

    const sameInline = startInline && endInline && startInline[0] === endInline[0];
    const sameBlock = startBlock && endBlock && startBlock[0] === endBlock[0];

    if (Range.isCollapsed(selection) && !startVoid) {
      return;
    }

    // Create a fake selection so that we can add a Base64-encoded copy of the
    // fragment to the HTML, to decode on future pastes.
    const domRange = ReactEditor.toDOMRange(editor, selection);
    let contents = domRange.cloneContents();
    let attach = contents.childNodes[0] as HTMLElement;

    // Make sure attach is non-empty, since empty nodes will not get copied.
    contents.childNodes.forEach(node => {
      if (node.textContent && node.textContent.trim() !== '') {
        attach = node as HTMLElement;
      }
    });

    // COMPAT: If the end node is a void node, we need to move the end of the
    // range from the void node's spacer span, to the end of the void node's
    // content, since the spacer is before void's content in the DOM.
    if (endVoid) {
      const [voidNode] = endVoid;
      const r = domRange.cloneRange();
      const domNode = ReactEditor.toDOMNode(editor, voidNode);
      r.setEndAfter(domNode);
      contents = r.cloneContents();
    }

    // COMPAT: If the start node is a void node, we need to attach the encoded
    // fragment to the void node's content node instead of the spacer, because
    // attaching it to empty `<div>/<span>` nodes will end up having it erased by
    // most browsers. (2018/04/27)
    if (startVoid) {
      attach = contents.querySelector('[data-slate-spacer]')! as HTMLElement;
    }

    // Remove any zero-width space spans from the cloned DOM so that they don't
    // show up elsewhere when pasted.
    Array.from(contents.querySelectorAll('[data-slate-zero-width]')).forEach(zw => {
      const isNewline = zw.getAttribute('data-slate-zero-width') === 'n';
      zw.textContent = isNewline ? '\n' : '';
    });

    // Remove any elements we don't want to copy
    Array.from(contents.querySelectorAll('[data-slate-no-copy]')).forEach(el => {
      el.remove();
    });

    // Set a `data-slate-fragment` attribute on a non-empty node, so it shows up
    // in the HTML, and can be used for intra-Slate pasting. If it's a text
    // node, wrap it in a `<span>` so we have something to set an attribute on.
    if (isDOMText(attach)) {
      const span = attach.ownerDocument.createElement('span');
      // COMPAT: In Chrome and Safari, if we don't add the `white-space` style
      // then leading and trailing spaces will be ignored. (2017/09/21)
      span.style.whiteSpace = 'pre';
      span.appendChild(attach);
      contents.appendChild(span);
      attach = span;
    }

    let fragment = editor.getFragment();
    const string = JSON.stringify(fragment);
    const encoded = window.btoa(encodeURIComponent(string));
    attach.setAttribute('data-slate-fragment', encoded);
    data.setData(`application/x-slate-fragment`, encoded);

    // Add the content to a <div> so that we can get its inner HTML.
    const div = contents.ownerDocument.createElement('div');
    div.appendChild(contents);
    div.setAttribute('hidden', 'true');
    contents.ownerDocument.body.appendChild(div);
    data.setData('text/html', div.innerHTML);

    if (sameInline) {
      data.setData('text/plain', getPlainText(div));
    } else {
      if (sameBlock && fragment.length && KitemakerElement.isElement(fragment[0])) {
        fragment = cloneDeep(fragment);
        fragment[0] = { ...fragment[0], type: Elements.Paragraph } as Descendant;
      }
      data.setData('text/plain', serializeToMarkdown(fragment));
    }
    contents.ownerDocument.body.removeChild(div);
    return data;
  };
}
