import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import * as React from 'react';
import styles from './icon.module.scss';

const icons: Record<string, { default: React.JSXElementConstructor<any> }> = import.meta.glob(
  '../../assets/images/new/icons/*.svg',
  { eager: true }
);

export const allIcons = Object.keys(icons).map(icon =>
  icon.substring(icon.lastIndexOf('/') + 1).replace('.svg', '')
);

export enum IconSize {
  Size14 = 14,
  Size16 = 16,
  Size18 = 18,
  Size20 = 20,
  Size24 = 24,
  Size30 = 30,
  Size32 = 32,
  Size40 = 40,
}

export type IconProps = {
  icon: string;
  size?: IconSize;
  style?: React.CSSProperties;
  className?: string;
} & React.SVGProps<SVGSVGElement>;

export function Icon({
  icon,
  size = IconSize.Size20,
  style,
  className,
  width,
  height,
  ...props
}: IconProps) {
  if (icon === 'none') {
    return (
      <div
        style={{ flexShrink: 0, width: size, height: size, ...style }}
        className={cn(styles.icon, className)}
      />
    );
  }
  const svg = icons[`../../assets/images/new/icons/${icon}.svg`];
  if (!svg) {
    Sentry.captureMessage('Invalid icon', {
      extra: {
        icon,
      },
    });
    // eslint-disable-next-line no-console
    console.error('Invalid icon', icon);
    return (
      <div
        style={{ flexShrink: 0, width: size, height: size, ...style }}
        className={cn(styles.icon, className)}
      />
    );
  }

  const SvgIcon = svg.default;
  return (
    <SvgIcon
      {...props}
      style={style}
      width={width ?? size}
      height={height ?? size}
      className={cn(styles.icon, className)}
    />
  );
}
