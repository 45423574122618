import cn from 'classnames';
import * as React from 'react';
import ErrorLogo from '../../assets/images/new/icons/error_image.svg';
import { Logo } from '../../components/new/logo';
import styles from './errorScreen.module.scss';

export function ErrorScreen({
  showLogo,
  fullScreen,
  children,
}: {
  showLogo?: boolean;
  fullScreen?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div
      className={cn(styles.errorScreen, {
        [styles.fullScreen]: fullScreen,
      })}
    >
      <div className={styles.errorContainer}>
        {showLogo && <Logo className="mb32" />}
        <ErrorLogo />
        {children}
      </div>
    </div>
  );
}
