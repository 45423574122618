import * as React from 'react';
import { RenderPlaceholderProps, useFocused, useSlate } from 'slate-react';
import { KitemakerElement } from '../../shared/slate/kitemakerNode';
import { Elements } from './types';

export function RenderPlaceholder({
  attributes,
  placeholderElement,
  richText,
  showOnFirstParagraph,
}: RenderPlaceholderProps & {
  placeholderElement?: React.ReactNode;
  richText?: boolean;
  showOnFirstParagraph?: boolean;
}) {
  const focused = useFocused();
  const editor = useSlate();
  const firstChild = editor.children[0];
  const showInsteadOfParagraphPlaceholder =
    showOnFirstParagraph &&
    editor.children.length === 1 &&
    KitemakerElement.isElement(firstChild) &&
    firstChild.type === Elements.Paragraph;

  if (
    (focused && !showInsteadOfParagraphPlaceholder && richText) ||
    !placeholderElement ||
    !editor.children.length ||
    !KitemakerElement.isElement(firstChild) ||
    firstChild.type !== Elements.Paragraph
  ) {
    return null;
  }

  return (
    <span
      {...attributes}
      style={{
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: `inherit`,
        ...attributes.style,
        color: 'var(--gray9)',
        opacity: 1,
        top: 0,
      }}
      className="bodyM"
    >
      {placeholderElement}
    </span>
  );
}
