import cn from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { Range } from 'slate';
import { RenderElementProps, useSlateStatic } from 'slate-react';
import uuid from 'uuid';
import { emptyDocument, flattenDocument, safeSelection } from '../../../shared/slate/utils';
import { issueTerm } from '../../../shared/utils/terms';
import { Insight as InsightModel } from '../../../sync/__generated/models';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import { LISTVIEW_ID, ListView, ListViewItem } from '../../components/new/listView';
import menuStyles from '../../components/new/menu/menu.module.scss';
import { EntityItem, EntityPicker } from '../../components/new/pickers/entityPicker';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useIsProductTierExceededAndNag } from '../../index/billingChecks';
import {
  useAddEntitiesToInsights,
  useDeleteInsightIfEmpty,
  useRemoveEntitiesFromInsights,
} from '../../syncEngine/actions/insights';
import {
  useEntityPath,
  useRecentEntitiesForOrganization,
} from '../../syncEngine/selectors/entities';
import { insightSelector } from '../../syncEngine/selectors/insights';
import { isInitiative } from '../../syncEngine/selectors/intiatives';
import { isIssue } from '../../syncEngine/selectors/issues';
import Hover from '../hovers';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { DocumentLike, InsightElement } from '../types';
import { DummyNode } from './dummyNode';
import styles from './insight.module.scss';

export function InsightsEntityPicker({
  insight,
  onEntityAdded,
  onEntityRemoved,
  onDone,
  onCreateNew,
  content,
}: {
  insight?: InsightModel | null;
  onEntityAdded: (insightIds: string[], entityId: string) => void;
  onEntityRemoved: (insightIds: string[], entityId: string) => void;
  onDone: () => void;
  onCreateNew?: () => void;
  content?: string;
}) {
  const organization = useOrganization();
  const modals = useModals();
  const checkProductTierExceeded = useIsProductTierExceededAndNag();

  const recentEntityIds = useRecentEntitiesForOrganization()(organization.id)
    .filter(e => isIssue(e) || isInitiative(e))
    .map(e => e.id);

  const description = flattenDocument(
    content || (insight && insight.contents)
      ? (JSON.parse((content ?? insight?.contents)!) as DocumentLike)
      : emptyDocument()
  );

  return (
    <EntityPicker
      multi
      showPrivate
      placeholder={`Link insight to ${issueTerm}s/initiatives`}
      entityTypes={['Issue', 'Initiative', 'Doc']}
      state={insight ? { [insight.id]: insight.entityIds } : {}}
      onDone={onDone}
      onAdd={onEntityAdded}
      mouseDown
      onRemove={onEntityRemoved}
      defaultEntityIds={recentEntityIds}
      createItemOnTop
      createNewItem={({ onAdd, filter, setFilter }) => {
        return {
          id: 'create-new-entity',
          icon: 'add',
          contents: filter.length ? (
            <div>Create &quot;{filter}&quot;</div>
          ) : (
            <div>Create new {issueTerm}</div>
          ),
          onSelected: () => {
            if (checkProductTierExceeded()) {
              return;
            }
            onCreateNew?.();
            setFilter('');
            modals.openModal(Modals.NewEntity, {
              type: 'Issue',
              title: filter,
              content: description,
              onCreated: entityId => {
                if (entityId) {
                  onAdd(entityId);
                }
                onDone();
              },
            });
          },
        };
      }}
    />
  );
}

function HoverContents({
  insightId,
  onHide,
  editing,
  onEditingChanged,
  startHidingHover,
  cancelHidingHover,
}: {
  insightId: string;
  onHide: () => void;
  hideTimeoutRef: React.MutableRefObject<number>;
  editing: boolean;
  onEditingChanged: (editing: boolean) => void;
  startHidingHover: () => void;
  cancelHidingHover: () => void;
}) {
  const history = useHistory();
  const insight = useRecoilValue(insightSelector(insightId));
  const insightRef = React.useRef(insight);
  insightRef.current = insight;
  const entityPath = useEntityPath();
  const addEntitiesToInsights = useAddEntitiesToInsights();
  const removeEntitiesFromInsights = useRemoveEntitiesFromInsights();

  if (!insight) {
    return null;
  }

  const items: ListViewItem[] = insight.entityIds.map(entityId => {
    return {
      id: `entity-${entityId}`,
      contents: () => <EntityItem showDeletedPlaceholder entityId={entityId} />,
      onSelected: () => {
        const path = entityPath(entityId);
        if (path) {
          history.push({
            pathname: path,
            search: `insightId=${insight.id}`,
            state: {
              backUrl: location.pathname,
              backSearch: location.search,
            },
          });
        }
      },
    };
  });
  if (!items.length) {
    items.push({
      id: 'placeholder',
      contents: <>No insights found</>,
    });
  }
  items.push({
    id: 'change',
    icon: 'edit',
    contents: <span className="ml4">Change</span>,
    onSelected: () => {
      onEditingChanged(true);
    },
  });

  return (
    <div
      className={cn(styles.insightHover, { [styles.edit]: editing })}
      onMouseEnter={() => {
        cancelHidingHover();
      }}
      onMouseLeave={() => {
        startHidingHover();
      }}
    >
      <div className={cn({ 'menuHuge menuPicker': editing })}>
        {!editing && (
          <>
            <div className={cn('grayedLight', menuStyles.item)}>Insight connected to</div>
            <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
              <ListView items={items} itemClassName={menuStyles.item} selectFirstOnItemsChanged />
            </KeyNavigationProvider>
          </>
        )}
        {editing && (
          <InsightsEntityPicker
            insight={insight}
            onCreateNew={() => {
              onEditingChanged(false);
              onHide();
            }}
            onDone={() => {
              onEditingChanged(false);
            }}
            onEntityAdded={(insightIds: string[], entityId: string) => {
              addEntitiesToInsights(insightIds, [entityId]);
            }}
            onEntityRemoved={(insightIds: string[], entityId: string) => {
              removeEntitiesFromInsights(insightIds, [entityId]);
            }}
          />
        )}
      </div>
    </div>
  );
}

export interface InsightHoverHandle {
  show(): void;
  hide(): void;
}

export function InsightWrapper({
  children,
  attributes,
  insightId,
  handle,
  hoverId,
}: {
  children: React.ReactNode;
  insightId: string;
  handle?: React.RefObject<InsightHoverHandle>;
  hoverId?: string;
} & Partial<RenderElementProps>) {
  const id = React.useRef(hoverId ?? uuid.v4());
  const editor = useSlateStatic();
  const deleteEmptyInsight = useDeleteInsightIfEmpty();
  const [showHover, setShowHover] = React.useState(false);
  const showTimeoutRef = React.useRef(-1);
  const hideTimeoutRef = React.useRef(-1);
  const [editing, setEditing] = React.useState(false);
  const editingRef = React.useRef(editing);
  editingRef.current = editing;

  function startHidingHover() {
    hideTimeoutRef.current = window.setTimeout(() => {
      if (editingRef.current) {
        return;
      }
      setShowHover(false);
    }, 1000);
  }

  function cancelHidingHover() {
    if (hideTimeoutRef.current !== -1) {
      window.clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current === -1;
    }
  }

  function show() {
    cancelHidingHover();
    const selection = safeSelection(editor);
    if (selection && !Range.isCollapsed(selection)) {
      return;
    }
    showTimeoutRef.current = window.setTimeout(() => {
      setShowHover(true);
    }, 500);
  }

  function hide() {
    if (showTimeoutRef.current !== -1) {
      window.clearTimeout(showTimeoutRef.current);
      showTimeoutRef.current === -1;
    }
    if (showHover) {
      startHidingHover();
    }
  }

  React.useImperativeHandle(handle, () => ({
    show,
    hide,
  }));

  return (
    <Hover
      hoverId={`insight-${id.current}`}
      open={showHover}
      onOpenChange={open => {
        setShowHover(open);
        if (!open) {
          setEditing(false);
          if (deleteEmptyInsight(insightId)) {
            KitemakerTransforms.removeInsight(editor, insightId);
            return;
          }
        }
      }}
      content={
        <HoverContents
          editing={editing}
          onEditingChanged={e => setEditing(e)}
          insightId={insightId}
          hideTimeoutRef={hideTimeoutRef}
          onHide={() => {
            setShowHover(false);
          }}
          startHidingHover={startHidingHover}
          cancelHidingHover={cancelHidingHover}
        />
      }
      onKeyDown={e => {
        if (!['Shift', 'Meta', 'Ctrl'].includes(e.key)) {
          setShowHover(false);
        }
        return false;
      }}
    >
      <span
        className={styles.insight}
        onMouseEnter={show}
        onMouseLeave={hide}
        onClick={() => {
          setShowHover(true);
        }}
        {...attributes}
      >
        {children}
      </span>
    </Hover>
  );
}

export function Insight({
  attributes,
  element,
  children,
}: RenderElementProps & { element: InsightElement }) {
  const insight = useRecoilValue(insightSelector(element.insightId));
  if (!insight) {
    return (
      <DummyNode as="span" element={element} attributes={attributes}>
        {children}
      </DummyNode>
    );
  }

  return (
    <InsightWrapper insightId={insight.id} attributes={attributes}>
      {children}
    </InsightWrapper>
  );
}
