import cn from 'classnames';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { EntityCommandContext } from '../../../components/new/commandMenuContext';
import { useCopyEntitiesToClipboard } from '../../../components/new/copyAndPaste';
import { KeyNavigationProvider } from '../../../components/new/keyNavigation';
import { VirtualizedListView } from '../../../components/new/virtualizedListView';
import { ResizeItemsOnStateChange } from '../../../components/new/virtualizedListViewHelpers';
import { WorkItemListItem } from '../../../components/new/workItemListItem';
import { useSpace } from '../../../contexts/spaceContext';
import { useEntityNumberWidths } from '../../../syncEngine/selectors/entities';
import { archivedIssuesSelector } from '../../../syncEngine/selectors/issues';
import { LocationState, silentlyUpdateHistoryState } from '../../../utils/history';
import { ArchivePlaceholder } from './placeholder';

export function WorkItemArchive() {
  const space = useSpace();
  const location = useLocation<LocationState>();
  const numberColumnWidth = useEntityNumberWidths([space.id]);
  const copyEntities = useCopyEntitiesToClipboard();

  const workItemIds = useRecoilValue(
    archivedIssuesSelector({ spaceId: space.id, filterId: `archive-${space.id}` })
  );

  const focusedElementId = React.useMemo(() => {
    // if we come back from the issue screen, force the selection to where it was before
    if (location.state?.entity) {
      const { entity, ...rest } = location.state;
      silentlyUpdateHistoryState(rest);
      return entity;
    }

    return workItemIds[0];
  }, []);

  if (workItemIds.length === 0) {
    return <ArchivePlaceholder type="work item" />;
  }

  return (
    <KeyNavigationProvider
      initiallyFocusedElementId={focusedElementId}
      columnIds={['archive-workItems']}
      disableEnsureVisible
      multiSelect
      isMultiSelectable={id => !id.includes('-')}
    >
      <EntityCommandContext />
      <VirtualizedListView
        id={`archive-workItems`}
        className={'fullWidth grow mt16'}
        sectionIds={['archived']}
        itemIds={{ archived: workItemIds }}
        sectionHeaderHeight={0}
        itemHeight={41}
        spacerHeight={32}
        renderSectionHeader={() => <></>}
        renderItem={(id, _sectionId, isFirst, isLast, _edit) => {
          return (
            <WorkItemListItem
              id={id}
              className={cn('listItem', {
                first: isFirst,
                last: isLast,
              })}
              style={
                {
                  '--number-column-width': `${numberColumnWidth}px`,
                } as React.CSSProperties
              }
            />
          );
        }}
        renderPlaceholder={() => {
          return <></>;
        }}
        renderAccessories={grid => {
          return <ResizeItemsOnStateChange ids={grid} />;
        }}
        onCopy={copyEntities}
      />
    </KeyNavigationProvider>
  );
}
