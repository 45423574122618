import { capitalize } from 'lodash';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useClient } from 'urql';
import isEmail from 'validator/lib/isEmail';
import { ExternalAuthProviderType, LoginMethod } from '../../../shared/auth';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../../shared/utils/constants';
import { InvitedUser, findInvitedUser, signup } from '../../api/auth';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { ExternalAuthButton } from '../../components/new/externalAuthButton';
import { Hotkey } from '../../components/new/hotkey';
import { Logo } from '../../components/new/logo';
import { TextInputSize, TextValidationInput } from '../../components/new/textInput';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { toast } from '../../components/toast';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { trackerPageLoad } from '../../tracker';
import { getQueryParameter } from '../../utils/query';
import { BasicErrorScreen } from '../errorScreens';

export function RegisterScreenContents({
  onSubmit,
  invitedUser,
  token,
}: {
  onSubmit: (data: { email: string; password: string; onError: () => void }) => void;
  invitedUser?: InvitedUser | null;
  token?: string;
}) {
  const history = useHistory();
  const [email, setEmail] = React.useState(invitedUser?.user.primaryEmail ?? '');
  const [password, setPassword] = React.useState('');
  const [requestInProgress, setRequestInProgress] = React.useState(false);

  const valid = React.useMemo(() => {
    return (
      email &&
      password &&
      isEmail(email) &&
      password.length >= MIN_PASSWORD_LENGTH &&
      password.length <= MAX_PASSWORD_LENGTH
    );
  }, [email, password]);

  const requiredLogin = React.useMemo(() => {
    const login = getQueryParameter(history, 'requiredLoginMethod');
    return login ? (login as LoginMethod) : null;
  }, [history]);

  const submit = React.useCallback(() => {
    setRequestInProgress(true);
    onSubmit({
      email,
      password,
      onError: () => setRequestInProgress(false),
    });
  }, [email, password, onSubmit]);

  return (
    <div className="mt24 mb24 form">
      <Logo className="mb32" />
      <div className="headingXL">Create a new Kitemaker account</div>
      <div className="bodyM grayed mb32">
        {!invitedUser && !requiredLogin && (
          <>
            Already have an account?{' '}
            <Link className="headingS link subtle hoverOnly" to="/login">
              Log in.
            </Link>
          </>
        )}
        {!!invitedUser && !requiredLogin && <>Join {invitedUser.organization.name}</>}
        {requiredLogin && (
          <>
            Your organization{invitedUser ? ` ${invitedUser.organization.name}` : ``} requires that
            you log in with {capitalize(requiredLogin)}
          </>
        )}
      </div>
      {(!requiredLogin || requiredLogin === ExternalAuthProviderType.Google) && (
        <ExternalAuthButton token={token} provider={ExternalAuthProviderType.Google} />
      )}
      {(!requiredLogin || requiredLogin === ExternalAuthProviderType.Microsoft) && (
        <ExternalAuthButton
          token={token}
          provider={ExternalAuthProviderType.Microsoft}
          className="mt24"
        />
      )}
      {!requiredLogin && (
        <>
          <div className="mt24 mb24 rowCenter fullWidth grayedLight">OR</div>
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              submit();
            }}
          >
            <div className="fullWidth mb24">
              <label htmlFor="email">Email</label>
              <TextValidationInput
                name="email"
                inputSize={TextInputSize.Large}
                className="fullWidth emailInput"
                autoFocus={!invitedUser}
                disabled={requestInProgress || !!invitedUser}
                placeholder="yourname@company.com"
                value={email}
                onChange={e => {
                  setEmail(e.currentTarget.value);
                }}
              />
            </div>
            <div className="fullWidth">
              <label htmlFor="password">Password</label>
              <TextValidationInput
                name="password"
                type="password"
                inputSize={TextInputSize.Large}
                className="fullWidth"
                autoFocus={!!invitedUser}
                disabled={requestInProgress}
                placeholder="Your password"
                value={password}
                onChange={e => {
                  setPassword(e.currentTarget.value);
                }}
                validate={pw => {
                  if (pw.length > MAX_PASSWORD_LENGTH) {
                    return `Password length cannot exceed ${MAX_PASSWORD_LENGTH} characters.`;
                  }
                  if (pw.length < MIN_PASSWORD_LENGTH) {
                    return `Password must be at least 8 characters.`;
                  }
                  return null;
                }}
              />
            </div>

            <Button
              buttonStyle={ButtonStyle.PrimaryBlack}
              size={ButtonSize.Large}
              type="submit"
              className="fullWidth mt24"
              disabled={!valid || requestInProgress}
            >
              <div className="row">Sign up with email</div>
            </Button>
            <div className="bodyM grayed mt24">
              To join an existing organization, click the invite link you received via email.
            </div>
            <Hotkey
              hotkey="enter"
              handler={e => {
                // NOTE: this hotkey exists because Safari breaks the focus on the form when
                // populating strong passwords
                e?.preventDefault();
                e?.stopPropagation();
                if (!valid || requestInProgress) {
                  return;
                }
                submit();
              }}
            />
          </form>
        </>
      )}
    </div>
  );
}

export function RegisterScreen() {
  const history = useHistory();
  const client = useClient();
  const match = useRouteMatch<{ token?: string }>();
  const { token } = match.params;
  const [tokenValidated, setTokenValidated] = React.useState(!token);
  const [invitedUser, setInvitedUser] = React.useState<InvitedUser | null>(null);
  const [error, setError] = React.useState('');

  useComponentDidMount(() => {
    trackerPageLoad('Register', { invite: !!token });
  });

  React.useEffect(() => {
    if (!token) {
      return;
    }

    (async () => {
      try {
        const user = await findInvitedUser(client, token);
        setInvitedUser(user);
      } catch (e) {
        setError(e.message);
      }
      setTokenValidated(true);
    })();
  }, [token, client]);

  const onSubmit = React.useCallback(
    async ({
      email,
      password,
      onError,
    }: {
      email: string;
      password: string;
      onError: () => void;
    }) => {
      try {
        await signup(history, email, password, { token });
      } catch (e) {
        toast.error(e.message);
        onError();
      }
    },
    [history, token]
  );

  if (!tokenValidated) {
    return null;
  }

  if (error) {
    return <BasicErrorScreen showLogo>{error}</BasicErrorScreen>;
  }

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Sign up" />
      <RegisterScreenContents token={token} invitedUser={invitedUser} onSubmit={onSubmit} />
    </AuxiliaryScreen>
  );
}
