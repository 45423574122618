import { trim } from 'lodash';
import * as React from 'react';
import uuid from 'uuid';
import { Elements } from '../../../../shared/slate/types';
import { Initiative as InitiativeModel } from '../../../../sync/__generated/models';
import { InitiativeIcon } from '../../../components/new/metadata/initiative';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import styles from './initiativeSuggestionMatcher.module.scss';
import { SuggestionMatcher, SuggestionOption } from './withSuggestions';

const newInitiativeRegex = new RegExp(
  `^([\\w_\\-\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]+|"[\\s\\w_\\-\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]+")$`
);

export interface InitiativeSuggestion {
  id: string;
  title: string;
  color: string;
}

export function initiativeSuggestionMatcher(
  createInitiative: ((name: string) => InitiativeModel) | null,
  initiatives: () => InitiativeSuggestion[]
): SuggestionMatcher {
  return {
    id: 'initiative',
    prefix: '!',
    options: partialMatch => {
      if (!partialMatch) {
        return [];
      }

      const initiativeOptions: SuggestionOption[] = initiatives().map(initiative => ({
        ...initiative,
        value: initiative.title,
        render: function RenderLabelSuggestion() {
          return (
            <div className={styles.initiativeSuggestion}>
              <InitiativeIcon color={initiative.color} />
              <span className="ellipsis">{initiative.title}</span>
            </div>
          );
        },
      }));

      const validNewInitiativeSuggestion = partialMatch.match(newInitiativeRegex);
      if (validNewInitiativeSuggestion && createInitiative) {
        const value = trim(partialMatch, '"');
        initiativeOptions.push({
          id: `create-initiative-${value}`,
          value,
          render: function RenderNewInitiative() {
            return (
              <div className={styles.initiativeSuggestion}>
                Create initiative &quot;{value}&quot;
              </div>
            );
          },
          footer: true,
          suggestionAutoCompleteOnly: true,
        });
      }

      return initiativeOptions;
    },
    onMatch: (editor, option, range, autocomplete) => {
      let initiativeId = option.id;
      if (initiativeId.startsWith('create-initiative-')) {
        const initiative = createInitiative!(option.value);
        initiativeId = initiative.id;
      }

      KitemakerTransforms.insertMention(
        editor,
        {
          type: Elements.Entity,
          entityId: initiativeId,
          mentionId: uuid.v4(),
          children: [{ text: '' }],
        },
        range,
        autocomplete
      );
    },
    handleTrailingSpace: true,
  };
}
