import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { actorNameSelector } from '../syncEngine/selectors/actors';

export default function ActorName({
  actorId,
  className,
  style,
}: {
  actorId: string | null;
  className?: string;
  style?: React.CSSProperties;
}) {
  const name = useRecoilValue(actorNameSelector(actorId));
  return (
    <span className={className} style={style}>
      {name}
    </span>
  );
}
