import * as React from 'react';
import Hotkey from './hotkey';
import { HotkeyScope } from './hotkeys';
import { toast } from './toast';

export function showRollbackToast() {
  const hideToast = toast.error(
    <HotkeyScope depth={1000}>
      <Hotkey
        command={{
          id: 'no-op',
          hotkey: 'escape',
          handler: e => {
            e?.preventDefault();
            e?.stopPropagation();
          },
        }}
      />
      There was a problem applying your edits. Kitemaker will refetch the latest version.
    </HotkeyScope>,
    { duration: Infinity }
  );
  return hideToast;
}
