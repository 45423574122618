import cn from 'classnames';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { RenderElementProps } from 'slate-react';
import { LabelElement } from '../../../shared/slate/types';
import { PillStyle, pillColors } from '../../components/new/metadata/pill';
import pillStyles from '../../components/new/metadata/pill.module.scss';
import { useDarkMode } from '../../hooks/useDarkMode';
import { labelSelector } from '../../syncEngine/selectors/labels';
import { useFocusedAndSelected } from '../hooks/useFocusedAndSelected';
import { OptionalAttributesRenderElementProps } from '../types';
import { DummyNode } from './dummyNode';
import styles from './labelMention.module.scss';
import { VoidInline } from './voidBlock';

export function StaticLabelMention({
  attributes,
  element,
  children,
}: OptionalAttributesRenderElementProps & { element: LabelElement }) {
  const { labelId } = element;
  const { darkMode } = useDarkMode();

  const label = useRecoilValue(labelSelector(labelId));
  if (!label) {
    return (
      <DummyNode as="span" attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  return (
    <span
      {...attributes}
      className={cn(pillStyles.pill, pillStyles.small, styles.labelMention, 'bodyM')}
      style={pillColors(label.color, PillStyle.Secondary, darkMode)}
    >
      {label.name}
      {children}
    </span>
  );
}

export function LabelMention({
  attributes,
  element,
  children,
}: RenderElementProps & { element: LabelElement }) {
  const { labelId } = element;
  const selected = useFocusedAndSelected();
  const { darkMode } = useDarkMode();

  const label = useRecoilValue(labelSelector(labelId));
  if (!label) {
    return (
      <DummyNode as="span" attributes={attributes} element={element}>
        {children}
      </DummyNode>
    );
  }

  return (
    <VoidInline
      element={element}
      attributes={attributes}
      className={cn(pillStyles.pill, pillStyles.small, styles.labelMention, 'bodyM', {
        [styles.selected]: selected,
      })}
      style={pillColors(label.color, PillStyle.Secondary, darkMode)}
    >
      {label.name}
      {children}
    </VoidInline>
  );
}
