import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { useRecoilValue } from 'recoil';
import { IntegrationType } from '../../../sync/__generated/models';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { Logo } from '../../components/new/logo';
import { Select, SelectSize } from '../../components/new/select';
import { AuxiliaryScreen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { currentUserOrganizatonsState } from '../../syncEngine/selectors/users';
import { trackerPageLoad } from '../../tracker';
import { stringifyIntegrationType } from '../../utils/integrations';
import { ErrorScreen } from '../errorScreens/errorScreen';

function installUrl(type: IntegrationType, organizationId: string, query: string): string {
  switch (type) {
    case IntegrationType.Intercom:
      return `/integrations/intercom/install?redirect=https://app.intercom.com/appstore/redirect?install_success=true&organization_id=${organizationId}`;
    case IntegrationType.Sentry:
      return `/integrations/sentry/setup?organization_id=${organizationId}${
        query ? `&${query}` : ''
      }`;
    default:
      throw new Error('Unknown integration type');
  }
}

const supportedIntegrations = [IntegrationType.Intercom, IntegrationType.Sentry];

export function OAuthInstallFlowScreen() {
  const { params } = useRouteMatch<{ type: IntegrationType }>();
  const organizations = useRecoilValue(currentUserOrganizatonsState);
  const [orgId, setOrgId] = React.useState<string>(organizations[0].id);
  const integrationType = params.type.toUpperCase() as IntegrationType;

  React.useEffect(() => {
    trackerPageLoad('Oath screen', {
      type: integrationType,
    });
  }, []);

  let error: string | null = null;
  if (!supportedIntegrations.includes(integrationType)) {
    error = `Invalid integration type: \`${params.type}\``;
  }
  if (!organizations.length) {
    error = 'No organizations found';
  }

  if (error) {
    return (
      <ErrorScreen>
        <div>{error}</div>
      </ErrorScreen>
    );
  }

  return (
    <AuxiliaryScreen>
      <TitleSetter title="Kitemaker · Install integration" />
      <div className="mt24 mb24 form">
        <Logo className="mb32" />
        <div className="headingXL oneLine mb4">
          Install {stringifyIntegrationType(integrationType)} integration
        </div>
        <div className="bodyM grayed mb32">
          This will install the {stringifyIntegrationType(integrationType)} integration to the
          selected organization. You can manage this at any time under the integration settings in
          Kitemaker.
        </div>
        <form
          onSubmit={async e => {
            e.preventDefault();
            e.stopPropagation();
            const search = window.location.search.startsWith('?')
              ? window.location.search.substring(1)
              : window.location.search;

            window.location.href = installUrl(integrationType, orgId, search);
          }}
        >
          <Select
            size={SelectSize.Large}
            value={orgId}
            values={organizations.map(o => ({ label: o.name, value: o.id }))}
            onChange={(orgId: string) => {
              setOrgId(orgId);
            }}
            className="fullWidth mt24"
          />
          <Button
            buttonStyle={ButtonStyle.PrimaryBlack}
            size={ButtonSize.Large}
            type="submit"
            className="fullWidth mt24"
          >
            <div className="row">Install</div>
          </Button>
        </form>
      </div>
    </AuxiliaryScreen>
  );
}
