import { capitalize } from 'lodash';
import * as React from 'react';
import { ReleaseStatus } from '../../../../sync/__generated/models';
import { Icon, IconSize } from '../icon';
import Pill from './pill';
import { MetadataSize } from './size';

export function ReleaseMetadata({
  title,
  releaseStatus,
  iconOnly,
  size = MetadataSize.Small,
}: {
  title: string;
  releaseStatus: ReleaseStatus;
  iconOnly?: boolean;
  size?: MetadataSize;
}) {
  const iconSize = size === MetadataSize.Small ? IconSize.Size16 : IconSize.Size20;
  return (
    <Pill iconOnly={iconOnly} size={size}>
      <Icon
        icon={releaseStatus === ReleaseStatus.Released ? 'released' : 'release'}
        size={iconSize}
      />
      {!iconOnly && <span className="ellipsis">{capitalize(title)}</span>}
    </Pill>
  );
}
