import { groupBy, keyBy, max } from 'lodash';
import { useRecoilCallback } from 'recoil';
import { filterNotDeletedNotNull, filterNotNull } from '../../../shared/utils/convenience';
import { between } from '../../../shared/utils/sorting';
import { issueTerm } from '../../../shared/utils/terms';
import {
  cycleEntitesByCycle,
  cycleTodosByCycle,
  cyclesBySpace,
} from '../../../sync/__generated/indexes';
import {
  Cycle,
  CycleEntity,
  CycleStatus,
  CycleTodo,
  Issue,
  IssueStatusType,
  Space,
  Todo,
} from '../../../sync/__generated/models';
import { useConfirmation } from '../../contexts/confirmationContext';
import {
  SyncEngineCreate,
  SyncEngineGetters,
  SyncEngineUpdateWithoutDelete,
  useModelManager,
} from '../../graphql/modelManager';
import { trackerEvent } from '../../tracker';
import { statusesForSpaceInBoardOrderSelector } from '../selectors/boards';
import {
  cycleEntitiesForCycleSelector,
  cycleTodosForCycleSelector,
  cycleSelector,
  entityInCycleSelector,
} from '../selectors/cycles';
import { entitiesSelector } from '../selectors/entities';
import { todoSelector } from '../selectors/todos';
import { indexHelper, updateSortableSorts } from './helpers';
import { useMoveIssues } from './issues';

export function nextAvailableCycleNumber(getters: SyncEngineGetters, spaceId: string): string {
  const cycles = indexHelper<Cycle>(getters, cyclesBySpace, spaceId);

  const numbersAsStrings = cycles.map(e => e.number).filter(n => !!n);
  const parsedNumbers = numbersAsStrings.map(n =>
    parseInt(n.replace('U', '').replace('T', ''), 10)
  );
  return `T${(max(parsedNumbers) ?? 0) + 1}`;
}

export function useUpdateCycles() {
  const modelManager = useModelManager();
  return (cycleIds: string[], update: SyncEngineUpdateWithoutDelete<Cycle>) => {
    modelManager.transaction(tx => {
      for (const cycleId of cycleIds) {
        tx.update<Cycle>(cycleId, update);

        if (update.summary) {
          trackerEvent('Cycle Updated', {
            type: 'Description',
            id: cycleId,
          });
        }
      }
    });
  };
}

export function useUpdateCycleEntities() {
  const modelManager = useModelManager();
  return (
    cycleEntities: string[],
    update: Omit<SyncEngineUpdateWithoutDelete<CycleEntity>, 'sort'>
  ) => {
    modelManager.transaction(tx => {
      for (const id of cycleEntities) {
        tx.update<CycleEntity>(id, update);
      }
    });
  };
}

export function useAddEntitiesToCycle() {
  const modelManager = useModelManager();
  const moveIssues = useMoveIssues();

  return useRecoilCallback(
    ({ snapshot }) =>
      (entityIds: string[], cycleId: string) => {
        const entities = snapshot.getLoadable(entitiesSelector(entityIds)).getValue();
        const cycle = snapshot.getLoadable(cycleSelector(cycleId)).getValue();

        if (!cycle) {
          return;
        }

        trackerEvent('Cycle Updated', {
          type: 'Entity Added',
          id: cycleId,
        });

        modelManager.transaction((tx, { get, getIndex }) => {
          const cycleEntityIds = getIndex(cycleEntitesByCycle, cycleId);
          const cycleEntities = filterNotDeletedNotNull(
            cycleEntityIds.map(cycleEntityId => get<CycleEntity>(cycleEntityId))
          );

          const cycle = get<Cycle>(cycleId);
          const space = get<Space>(cycle?.spaceId ?? '');

          let cycleEntityIdsToCheck: string[] = [];
          if (space?.upcomingCycleId === cycleId && space.activeCycleId) {
            cycleEntityIdsToCheck = getIndex(cycleEntitesByCycle, space.activeCycleId);
          } else if (space?.activeCycleId === cycleId && space.upcomingCycleId) {
            cycleEntityIdsToCheck = getIndex(cycleEntitesByCycle, space.upcomingCycleId);
          }

          const cycleEntitiesToDelete = filterNotDeletedNotNull(
            cycleEntityIdsToCheck.map(cycleEntityId => get<CycleEntity>(cycleEntityId))
          ).filter(ce => entityIds.includes(ce.entityId));

          for (const ce of cycleEntitiesToDelete) {
            tx.update<CycleEntity>(ce.id, { deleted: true });
          }

          let sort = between({ after: cycleEntities[cycleEntities.length - 1]?.sort });
          for (const entityId of entityIds) {
            const existingCycleEntity = cycleEntities.find(ce => ce.entityId === entityId);
            if (existingCycleEntity) {
              tx.update<CycleEntity>(existingCycleEntity?.id, {
                ghost: false,
              });
            } else {
              const cycleEntity: SyncEngineCreate<CycleEntity> = {
                __typename: 'CycleEntity',
                cycleId,
                entityId,
                sort,
                ghost: false,
              };
              tx.create<CycleEntity>(cycleEntity);
              sort = between({ before: sort });
            }
          }
        });

        if (cycle.cycleStatus === CycleStatus.Started) {
          const issues = entities.filter(entities => entities?.__typename === 'Issue') as Issue[];
          const statuses = snapshot
            .getLoadable(statusesForSpaceInBoardOrderSelector(cycle.spaceId))
            .getValue();
          const statusesById = keyBy(statuses, 'id');
          const firstTodoStatus = statuses.find(s => s.statusType === IssueStatusType.Todo);

          const issuesToMove = issues.filter(i => {
            const status = statusesById[i.statusId];
            return status.statusType === IssueStatusType.Backlog;
          });
          if (firstTodoStatus && issuesToMove.length > 0) {
            moveIssues(
              issuesToMove.map(i => i.id),
              firstTodoStatus.id
            );
          }
        }
      },
    [modelManager]
  );
}

export function useRemoveEntitiesFromCycle() {
  const modelManager = useModelManager();
  const { confirm } = useConfirmation();

  return useRecoilCallback(
    ({ snapshot }) =>
      async (entityIds: string[], cycleId: string) => {
        trackerEvent('Cycle Updated', {
          type: 'Entity Removed',
          id: cycleId,
        });

        const cycleEntities = snapshot
          .getLoadable(cycleEntitiesForCycleSelector(cycleId))
          .getValue();
        const cycleEntitiesToDelete = cycleEntities.filter(
          i => entityIds.includes(i.entityId) && !i.ghost
        );

        const cycleTodosToDelete = [];

        const cycleTodos = snapshot.getLoadable(cycleTodosForCycleSelector(cycleId)).getValue();
        for (const cycleEntity of cycleEntitiesToDelete) {
          const todosForCycleEntity = filterNotNull(
            cycleTodos
              .map(ct => snapshot.getLoadable(todoSelector(ct.todoId)).getValue())
              .filter(t => t?.entityId === cycleEntity.entityId)
              .map(t => t?.id)
          );

          cycleTodosToDelete.push(
            ...cycleTodos.filter(ct => todosForCycleEntity.includes(ct.todoId))
          );
        }

        if (!cycleTodosToDelete.length) {
          modelManager.transaction(async tx => {
            for (const cycleEntity of cycleEntitiesToDelete) {
              tx.update<CycleEntity>(cycleEntity.id, { deleted: true });
            }
          });
          return;
        }

        const updates = [] as [string, Partial<CycleEntity | CycleTodo>][];
        const cycle = snapshot.getLoadable(cycleSelector(cycleId)).getValue();

        const multiple = cycleTodosToDelete.length > 1;
        const confirmed = await confirm(
          `Remove associated todos from ${cycle?.title ?? 'cycle'}?`,
          `Should we also remove ${cycleTodosToDelete.length} todo${
            multiple ? 's' : ''
          } from this cycle that belongs to the ${issueTerm}${multiple ? 's' : ''}.`,
          {
            label: `Remove todo${multiple ? 's' : ''}`,
            destructive: true,
            cancelLabel: `Keep todo${multiple ? 's' : ''}`,
          }
        );
        if (!confirmed) {
          // mark them as ghosts instead of deleting
          for (const cycleEntity of cycleEntitiesToDelete) {
            updates.push([cycleEntity.id, { ghost: true }]);
          }
        } else {
          for (const cycleEntity of cycleEntitiesToDelete) {
            updates.push([cycleEntity.id, { deleted: true }]);
          }
          for (const cycleTodo of cycleTodosToDelete) {
            updates.push([cycleTodo.id, { deleted: true }]);
          }
        }
        modelManager.transaction(async tx => {
          for (const update of updates) {
            tx.update<any>(update[0], update[1]);
          }
        });
      },
    [modelManager]
  );
}

export function useToggleEntitiesInCycle(cycleId: string) {
  const addEntitiesToCycle = useAddEntitiesToCycle();
  const removeEntitiesFromCycle = useRemoveEntitiesFromCycle();

  const callback = useRecoilCallback(
    ({ snapshot }) =>
      (entityIds: string[]) => {
        const entityId = entityIds[0];
        if (!entityId) {
          return;
        }
        const inCycle = snapshot
          .getLoadable(entityInCycleSelector({ cycleId, entityId }))
          .getValue();
        if (inCycle) {
          removeEntitiesFromCycle(entityIds, cycleId);
        } else {
          addEntitiesToCycle(entityIds, cycleId);
        }
      },
    [cycleId]
  );
  return callback;
}

export function useAddTodosToCycle() {
  const modelManager = useModelManager();
  return (todoIds: string[], cycleId: string) => {
    trackerEvent('Cycle Updated', {
      type: 'Todo Added',
      id: cycleId,
    });

    modelManager.transaction((tx, { get, getIndex }) => {
      const cycleTodoIds = getIndex(cycleTodosByCycle, cycleId);
      const cycleTodos = filterNotDeletedNotNull(
        cycleTodoIds.map(cycleTodoId => get<Todo>(cycleTodoId))
      );

      const cycleEntityIds = getIndex(cycleEntitesByCycle, cycleId);
      const cycleEntities = filterNotDeletedNotNull(
        cycleEntityIds.map(cycleEntityId => get<CycleEntity>(cycleEntityId))
      );

      const entityIdsInCyle = cycleEntities.map(ce => ce.entityId);

      let todoSort = between({ after: cycleTodos[cycleTodos.length - 1]?.sort });
      let entitySort = between({ after: cycleEntities[cycleEntities.length - 1]?.sort });

      const cycle = get<Cycle>(cycleId);
      const space = get<Space>(cycle?.spaceId ?? '');

      let cycleTodoIdsToCheck: string[] = [];
      if (space?.upcomingCycleId === cycleId && space.activeCycleId) {
        cycleTodoIdsToCheck = getIndex(cycleTodosByCycle, space.activeCycleId);
      } else if (space?.activeCycleId === cycleId && space.upcomingCycleId) {
        cycleTodoIdsToCheck = getIndex(cycleTodosByCycle, space.upcomingCycleId);
      }
      const cycleTodosToDelete = filterNotDeletedNotNull(
        cycleTodoIdsToCheck.map(cycleEntityId => get<CycleTodo>(cycleEntityId))
      ).filter(ct => todoIds.includes(ct.todoId));

      for (const ct of cycleTodosToDelete) {
        tx.update<CycleTodo>(ct.id, { deleted: true });
      }

      for (const todoId of todoIds) {
        const todo = get<Todo>(todoId);
        if (!todo) {
          continue;
        }

        const cycleTodo: SyncEngineCreate<CycleTodo> = {
          __typename: 'CycleTodo',
          cycleId,
          todoId,
          sort: todoSort,
        };
        tx.create<CycleTodo>(cycleTodo);
        todoSort = between({ after: todoSort });
        if (!entityIdsInCyle.includes(todo.entityId)) {
          const cycleEntity: SyncEngineCreate<CycleEntity> = {
            __typename: 'CycleEntity',
            cycleId,
            entityId: todo.entityId,
            sort: entitySort,
            ghost: true,
          };
          tx.create<CycleEntity>(cycleEntity);
          entitySort = between({ after: entitySort });
        }
      }
    });
  };
}

export function useRemoveTodosFromCycle() {
  const modelManager = useModelManager();
  return (todoIds: string[], cycleId: string) => {
    trackerEvent('Cycle Updated', {
      type: 'Todo Removed',
      id: cycleId,
    });

    modelManager.transaction((tx, { get, getIndex }) => {
      const cycleTodoIds = getIndex(cycleTodosByCycle, cycleId);
      const cycleTodos = filterNotDeletedNotNull(
        cycleTodoIds.map(cycleTodoId => get<CycleTodo>(cycleTodoId))
      );
      const cycleEntityIds = getIndex(cycleEntitesByCycle, cycleId);
      const cycleEntities = filterNotDeletedNotNull(
        cycleEntityIds.map(cycleEntityId => get<CycleEntity>(cycleEntityId))
      );

      const todos = filterNotDeletedNotNull(cycleTodos.map(ct => get<Todo>(ct.todoId)));
      const todosById = keyBy(todos, 'id');
      const todosByEntity = groupBy(todos, 'entityId');

      const cycleTodosToDelete = cycleTodos.filter(i => todoIds.includes(i.todoId));
      for (const cycleTodo of cycleTodosToDelete) {
        tx.update<CycleTodo>(cycleTodo.id, { deleted: true });
        const todo = todosById[cycleTodo.todoId];
        const cycleEntity = cycleEntities.find(ce => ce.entityId === todo.entityId);

        if (todo && cycleEntity?.ghost) {
          const todosForEntity = todosByEntity[todo.entityId].filter(t => t.id !== todo.id);
          if (todosForEntity.length === 0) {
            tx.update<CycleEntity>(cycleEntity.id, {
              deleted: true,
            });
          }
        }
      }
    });
  };
}

export function useUpdateCycleEntitiesSort() {
  const modelManager = useModelManager();

  return (
    cycleId: string,
    cycleEntityIds: string[],
    afterCycleEntityId?: string,
    beforeCycleEntityId?: string
  ) => {
    trackerEvent('Cycle Updated', {
      type: 'Sort',
      id: cycleId,
    });

    modelManager.transaction((tx, getters) => {
      const { get } = getters;

      const cycle = get<Cycle>(cycleId);
      if (!cycle) {
        return;
      }

      const cycleEntities = indexHelper<CycleEntity>(getters, cycleEntitesByCycle, cycleId);

      const updates = updateSortableSorts(
        cycleEntities,
        cycleEntityIds,
        afterCycleEntityId,
        beforeCycleEntityId
      );
      for (const cycleEntityId in updates) {
        tx.update<CycleEntity>(cycleEntityId, {
          sort: updates[cycleEntityId],
        });
      }
    });
  };
}
