import { Node, Range, NodeEntry, Text } from 'slate';
import { KitemakerNode } from '../../../shared/slate/kitemakerNode';
import { EditorType } from '../types';

export const reEmoji =
  /\p{RI}\p{RI}|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(\u{200D}\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)+|\p{EPres}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})/gu;

export function emojiDecorations(_editor: EditorType, entry: NodeEntry<Node>): Range[] {
  const [node, path] = entry;

  if (!Text.isText(node) || !path.length) {
    return [];
  }

  const text = KitemakerNode.safeString(node);

  const matches = text.matchAll(reEmoji);

  const decorations: Range[] = [];
  for (const match of matches) {
    if (match.index !== undefined) {
      const dec = {
        anchor: {
          path,
          offset: match.index,
        },
        focus: {
          offset: match.index + match[0].length,
          path,
        },
        emojiFont: true,
      };
      decorations.push(dec);
    }
  }

  return decorations;
}
