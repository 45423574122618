import { selector, selectorFamily } from 'recoil';
import { filterNotDeletedNotNull, notDeleted } from '../../../shared/utils/convenience';
import { integrationUsersByUser } from '../../../sync/__generated/indexes';
import {
  Actor,
  Integration,
  IntegrationType,
  IntegrationUser,
} from '../../../sync/__generated/models';
import { indexKey, indexKeyState, syncEngineState } from '../state';
import { integrationsForOrganizationSelector } from './organizations';
import { currentUserState } from './users';

export function isIntegration(integration: Actor): integration is Integration {
  return integration.__typename === 'Integration';
}

export function isIntegrationUser(integrationUser: Actor): integrationUser is IntegrationUser {
  return integrationUser.__typename === 'IntegrationUser';
}

export const integrationsSelector = selectorFamily({
  key: 'Integrations',
  get:
    (integrationId: string) =>
    ({ get }) => {
      return notDeleted(get(syncEngineState(integrationId)) as Integration | null);
      // return get(syncEngineState(integrationId)) as Integration | null;
    },
});

export const integrationOfTypeSelector = selectorFamily({
  key: 'IntegrationOfType',
  get:
    ({
      organizationId,
      integrationType,
    }: {
      organizationId?: string;
      integrationType: IntegrationType;
    }) =>
    ({ get }) => {
      if (!organizationId) {
        return null;
      }
      const integrations = get(integrationsForOrganizationSelector(organizationId));
      return integrations.find(integration => integration.type === integrationType) ?? null;
    },
});

export const integrationUsersForCurrentUserSelector = selector({
  key: 'IntegrationUsersForCurrentUser',
  get: ({ get }) => {
    const currentUser = get(currentUserState);
    if (!currentUser) {
      return [];
    }

    const integrationUserIds = get(indexKeyState(indexKey(integrationUsersByUser, currentUser.id)));
    return filterNotDeletedNotNull(
      integrationUserIds.map(
        integrationUserId => get(syncEngineState(integrationUserId)) as IntegrationUser | null
      )
    );
  },
});
