import cn from 'classnames';
import * as React from 'react';
import { Element } from 'slate';
import { EditorType, VoidElement } from '../../../shared/slate/types';
import { ButtonStyle, IconButton } from '../../components/new/button';
import { CommentButton } from '../../components/new/commentButton';
import { Icon, IconSize } from '../../components/new/icon';
import { Tooltip } from '../../components/new/tooltip';
import { VoidActions } from '../../components/new/voidActions';
import styles from './collapsed.module.scss';

export function CollapsedInnerVoidBlock({
  element,
  name,
  icon,
  setCollapsed,
  editor,
  selected,
}: {
  element: Element & VoidElement;
  name: string;
  icon: string;
  setCollapsed: (v: boolean) => void;
  editor?: EditorType;
  selected?: boolean;
}) {
  return (
    <div
      className={cn(styles.file, {
        [styles.voidElementSelected]: selected,
        [styles.voidElementInsight]: !!element.insightId,
      })}
    >
      <div className="row">
        <Icon size={IconSize.Size20} icon={icon} className="mr12" />
        <span className="mr8 headingS ellipsis oneLine">{name}</span>
      </div>
      <VoidActions className={styles.actions}>
        <Tooltip content={'Expand'}>
          <IconButton
            buttonStyle={ButtonStyle.BareSubtle}
            icon={'unfold_more'}
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              setCollapsed(false);
            }}
          />
        </Tooltip>
        {editor?.inlineComments && editor?.entityId && <CommentButton element={element} />}
      </VoidActions>
    </div>
  );
}
