import * as React from 'react';
import FigmaEmbedModal from '../modals/figmaEmbedModal';
import ImageModal from '../modals/imageModal';

export function BasicModals() {
  return (
    <>
      <ImageModal />
      <FigmaEmbedModal />
    </>
  );
}
