import cn from 'classnames';
import * as React from 'react';
import { CommandGroup, useCommandGroupTitles, useSortCommandGroups } from '../../commands';
import { Button, ButtonSize, ButtonStyle } from '../../components/new/button';
import { FilteredListView } from '../../components/new/filteredListView';
import { Hotkey } from '../../components/new/hotkey';
import { IconSize } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import { KeyNavigationProvider } from '../../components/new/keyNavigation';
import { LISTVIEW_ID } from '../../components/new/listView';
import menuStyles from '../../components/new/menu/menu.module.scss';
import { toast } from '../../components/toast';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { GroupedListViewItem, populate } from '../../modals/commandMenu/commandMenu';
import commandMenuStyles from '../../modals/commandMenu/commandMenu.module.scss';
import { trackerPageLoad } from '../../tracker';
import {
  addToInitiativeKey,
  archiveIssueKey,
  assignIssueKey,
  closeIssueKey,
  commandMenuKey,
  copyIssueGitBranchKey,
  copyIssueNumberKey,
  isMac,
  labelIssueKey,
  moveIssueKey,
  setEffortKey,
  setImpactKey,
} from '../../utils/config';
import styles from './onboardingScreen.module.scss';

enum Stage {
  Start,
  Menu,
  Success,
}

function KitemakerCommandKeys() {
  const [metaDown, setMetaDown] = React.useState(false);
  const [kDown, setKDown] = React.useState(false);

  useComponentDidMount(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key.toLowerCase() === 'k') {
        setKDown(true);
        return;
      }
      if (
        (isMac && e.key.toLowerCase() === 'meta') ||
        (!isMac && e.key.toLowerCase() === 'control')
      ) {
        setMetaDown(true);
      }
    }

    function onKeyUp(e: KeyboardEvent) {
      if (e.key.toLowerCase() === 'k') {
        setKDown(false);
      }
      if (
        (isMac && e.key.toLowerCase() === 'meta') ||
        (!isMac && e.key.toLowerCase() === 'control')
      ) {
        setMetaDown(false);
      }
    }

    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  });
  return (
    <div className={styles.commandKeys}>
      <div
        className={cn(styles.key, 'mr16', {
          [styles.macOS]: isMac,
        })}
      >
        <div
          className={cn(styles.keyInner, {
            [styles.nonMac]: !isMac,
            [styles.pressed]: metaDown,
          })}
        >
          {isMac ? '⌘' : 'Ctrl'}
        </div>
      </div>
      <div className={cn(styles.key, 'mr32')}>
        <div
          className={cn(styles.keyInner, {
            [styles.pressed]: kDown,
          })}
        >
          K
        </div>
      </div>
      <div className="bodyM grayed">
        <div className="mb4">
          Press <KeyboardShortcut shortcut={commandMenuKey} className="inlineFlex" /> to try the
        </div>
        <div>Kitemaker command menu.</div>
      </div>
    </div>
  );
}

function FauxCommandMenu({ onNext }: { onNext: () => void }) {
  const sortGroups = useSortCommandGroups();
  const groupTitles = useCommandGroupTitles();

  const items: GroupedListViewItem[] = [
    {
      id: 'status',
      group: CommandGroup.Entities,
      icon: 'status',
      contents: 'Change status',
      hotkey: moveIssueKey,
    },
    {
      id: 'members',
      group: CommandGroup.Entities,
      icon: 'member',
      contents: 'Change members',
      hotkey: assignIssueKey,
    },
    {
      id: 'labels',
      group: CommandGroup.Entities,
      icon: 'label',
      contents: 'Change labels',
      hotkey: labelIssueKey,
    },
    {
      id: 'initiative',
      group: CommandGroup.Entities,
      icon: 'initiative',
      contents: 'Change initiatives',
      hotkey: addToInitiativeKey,
    },
    {
      id: 'impact',
      group: CommandGroup.Entities,
      icon: 'impact',
      contents: 'Change impact',
      hotkey: setImpactKey,
    },
    {
      id: 'effort',
      group: CommandGroup.Entities,
      icon: 'effort',
      contents: 'Change effort',
      hotkey: setEffortKey,
    },
    {
      id: 'copy',
      group: CommandGroup.Entities,
      icon: 'copy',
      contents: 'Copy work item number',
      hotkey: copyIssueNumberKey,
    },
    {
      id: 'git',
      group: CommandGroup.Entities,
      icon: 'branch',
      contents: 'Copy git branch for work item',
      hotkey: copyIssueGitBranchKey,
    },
    {
      id: 'done',
      group: CommandGroup.Entities,
      icon: 'status_done',
      contents: 'Move work item to Done',
      hotkey: closeIssueKey,
    },
    {
      id: 'archive',
      group: CommandGroup.Entities,
      icon: 'archive',
      contents: 'Move work item to archive',
      hotkey: archiveIssueKey,
    },
  ];

  return (
    <div className={cn(styles.modal, commandMenuStyles.commandMenu)}>
      <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
        <FilteredListView
          items={items.map(item => ({
            ...item,
            onSelected: onNext,
          }))}
          iconSize={IconSize.Size20}
          propertiesToSearch={['id', 'contents']}
          filterClassName={commandMenuStyles.inputDiv}
          className={cn(commandMenuStyles.commands, styles.commands)}
          itemClassName={menuStyles.item}
          selectFirstOnItemsChanged={true}
          filterPlaceholder="Command and search..."
          filterLabel="Command"
          populate={(items, alwaysShownItems) => {
            return populate(items, alwaysShownItems, '', sortGroups, groupTitles);
          }}
        />
      </KeyNavigationProvider>
    </div>
  );
}
export function KitemakerCommandStep({ onNext }: { onNext: () => void }) {
  const [stage, setStage] = React.useState(Stage.Start);

  useComponentDidMount(() => {
    trackerPageLoad('TutorialKitemakerCommand');
  });

  return (
    <div className={styles.standardStep}>
      <div className={cn('headingXL mb16 textCenter', styles.heading)}>
        {stage === Stage.Start && `Work faster with shortcuts and command menu`}
        {stage === Stage.Menu && `Now try navigating the menu`}
        {stage === Stage.Success && `Nicely done.`}
      </div>

      {stage === Stage.Start && (
        <>
          <div className={cn('bodyXL slightlyGrayed textCenter', styles.heading)}>
            You can use Kitemaker with just your keyboard. Be more efficient with shortcuts or the
            command menu.
          </div>
          <KitemakerCommandKeys />
        </>
      )}
      {stage === Stage.Menu && (
        <>
          <FauxCommandMenu
            onNext={() => {
              toast.info('Yay! You used the command menu', {
                action: { label: 'Continue', onClick: onNext, hotkey: 'enter' },
              });
              setStage(Stage.Success);
            }}
          />
          <div className="bodyM grayed mb24">Try filtering the menu and select any action.</div>
        </>
      )}
      {stage === Stage.Success && (
        <div className={cn('bodyM', 'grayed', 'textCenter', 'mb24', styles.stepContent)}>
          You can bring up the command menu any place in the app with{' '}
          <KeyboardShortcut shortcut={commandMenuKey} className="inlineRowCenter" /> and perform all
          of Kitemaker's actions. You can use it to edit work items, navigate the app or search.
        </div>
      )}
      <form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          onNext();
        }}
      >
        <Button
          buttonStyle={ButtonStyle.PrimaryBlack}
          size={ButtonSize.Large}
          className="fullWidth"
        >
          Continue
        </Button>
      </form>
      <Hotkey
        hotkey="enter"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          onNext();
        }}
      />
      <Hotkey
        hotkey={commandMenuKey}
        priority={0}
        global
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          if (stage === Stage.Start) {
            setStage(Stage.Menu);
          }
        }}
      />
    </div>
  );
}
