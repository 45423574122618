import { max } from 'lodash';
import * as React from 'react';
import {
  documentsByOrganization,
  feedbackByOrganization,
  initiativesByOrganization,
  issuesBySpace,
  releasesByOrganization,
  roadmapsByOrganization,
} from '../../sync/__generated/indexes';
import { Doc, Entity, Feedback, Initiative, Release, Roadmap } from '../../sync/__generated/models';
import { SyncEngineGetters } from '../graphql/modelManager';
import { indexHelper } from '../syncEngine/actions/helpers';
import { useStateTransaction } from '../syncEngine/state';

export function nextAvailableNumber(
  getters: SyncEngineGetters,
  objectId: string,
  orgEnumerable?: 'feedback' | 'roadmap' | 'initiative' | 'document' | 'release'
): string {
  let enumerables: { number: string }[];

  // TODO: We could optimise this by ensuring all the indicies are sorted
  switch (orgEnumerable) {
    case 'feedback':
      enumerables = indexHelper<Feedback>(getters, feedbackByOrganization, objectId);
      break;

    case 'initiative':
      enumerables = indexHelper<Initiative>(getters, initiativesByOrganization, objectId);
      break;

    case 'roadmap':
      enumerables = indexHelper<Roadmap>(getters, roadmapsByOrganization, objectId);
      break;
    case 'document':
      enumerables = indexHelper<Doc>(getters, documentsByOrganization, objectId);
      break;
    case 'release':
      enumerables = indexHelper<Release>(getters, releasesByOrganization, objectId);
      break;

    default: {
      enumerables = indexHelper<Entity>(getters, issuesBySpace, objectId);
      break;
    }
  }

  const numbersAsStrings = enumerables.map(e => e.number).filter(n => !!n);
  const parsedNumbers = numbersAsStrings.map(n =>
    parseInt(n.replace('U', '').replace('T', ''), 10)
  );
  return `T${(max(parsedNumbers) ?? 0) + 1}`;
}

export function useNextAvailableNumber(
  objectId: string,
  orgEnumerable?: 'feedback' | 'roadmap' | 'initiative'
) {
  const tx = useStateTransaction();
  return React.useMemo(() => {
    let result = '';
    tx(({ get, getIndex }) => {
      result = nextAvailableNumber({ get, getIndex }, objectId, orgEnumerable);
    });
    return result;
  }, [objectId, orgEnumerable, tx]);
}

export enum EntitySortField {
  IMPACT_AND_EFFORT,
  IMPACT,
  EFFORT,
  RECENTLY_CREATED,
  RECENTLY_UPDATED,
}

export enum ImpactAndEffortScoringMode {
  IMPACT_ONLY,
  EFFORT_ONLY,
}
