import { useRecoilValue } from 'recoil';
import { issueTerm } from '../../shared/utils/terms';
import { CommandMenuView, Modals, useModals } from '../contexts/modalContext';
import { useOrganization } from '../contexts/organizationContext';
import { useCurrentUser } from '../contexts/userContext';
import { useToggleWatchersForEntities } from '../syncEngine/actions/entities';
import {
  useArchiveInitatives,
  useDeleteInitiatives,
  useMoveInitiatives,
  useRemoveInitiativesFromRoadmaps,
  useUnarchiveInitatives,
} from '../syncEngine/actions/intiatives';
import { entitySelector } from '../syncEngine/selectors/entities';
import {
  initiativesSelector,
  isInitiative,
  roadmapInitiativesForRoadmapSelector,
} from '../syncEngine/selectors/intiatives';
import { roadmapColumnSiblingIdsSelector } from '../syncEngine/selectors/roadmaps';
import {
  addExistingKey,
  archiveIssueKey,
  deleteKey,
  labelIssueKey,
  moveIssueKey,
  moveIssueToNextStatusKey,
  moveIssueToNextStatusKeyBottom,
  moveIssueToPreviousStatusKey,
  moveIssueToPreviousStatusKeyBottom,
  removeItemKey,
  roadmapInitiativeKey,
  spaceInitiativeKey,
  watchIssueKey,
} from '../utils/config';
import {
  CommandDefinition,
  CommandGroup,
  EntityCommandGroupContext,
  useCommandMenuContext,
} from './state';

function previousColumn(columnId: string, allColumnIds: string[]) {
  const columnIndex = allColumnIds.findIndex(id => id === columnId);
  return allColumnIds[columnIndex - 1];
}

function nextColumn(columnId: string, allColumnIds: string[]) {
  const columnIndex = allColumnIds.findIndex(id => id === columnId);
  return allColumnIds[columnIndex + 1];
}

export function useInitiativeCommandGroup(): CommandDefinition[] {
  const user = useCurrentUser();
  const modals = useModals();
  const organization = useOrganization();

  const moveInitiatives = useMoveInitiatives();
  const archiveInitiatives = useArchiveInitatives();
  const unarchiveInitiatives = useUnarchiveInitatives();
  const deleteInitiatives = useDeleteInitiatives();
  const toggleWatchersForEntities = useToggleWatchersForEntities();
  const removeInitiativesFromRoadmaps = useRemoveInitiativesFromRoadmaps();

  const entityContext = useCommandMenuContext<EntityCommandGroupContext>(CommandGroup.Entities);
  const entities = useRecoilValue(initiativesSelector(entityContext?.entityIds ?? []));

  const initiatives = entities.filter(isInitiative);
  const initiativeIds = new Set(initiatives.map(i => i.id));

  const focusedInitiative = useRecoilValue(entitySelector(entityContext?.focusedEntityId ?? ''));

  const allRoadmapInitiatives = useRecoilValue(
    roadmapInitiativesForRoadmapSelector(entityContext?.roadmapId ?? '')
  );

  const roadmapInitiatives = allRoadmapInitiatives.filter(ri => initiativeIds.has(ri.initiativeId));
  const roadmapInitiativeIds = roadmapInitiatives.map(ri => ri.id);

  const columnIds = roadmapInitiatives.map(ri => ri.columnId);
  const allColumnIds = useRecoilValue(roadmapColumnSiblingIdsSelector(columnIds[0] ?? ''));

  const roadmapId = entityContext?.roadmapId;

  const commands: CommandDefinition[] = [];
  const archived = !!initiatives[0]?.archivedAt;

  if (initiatives.length !== entities.length) {
    return commands;
  }

  if (focusedInitiative) {
    commands.push({
      id: 'initiative-add-issues',
      hotkey: addExistingKey,
      description: `Add existing ${issueTerm}s to initiative`,
      icon: 'add',
      handler: () => {
        modals.openModal(Modals.CommandMenu, {
          view: CommandMenuView.ChangeInitiativeIssues,
          context: {
            initiativeId: focusedInitiative.id,
          },
        });
      },
    });
  }

  if (initiatives.length) {
    commands.push(
      {
        id: 'watch-initiative',
        hotkey: watchIssueKey,
        description: `Watch initiative${initiatives.length > 1 ? 's' : ''}`,
        icon: 'watch',
        priority: 9,
        handler: () => {
          toggleWatchersForEntities(
            initiatives.map(e => e.id),
            [user.id]
          );
        },
      },
      {
        id: 'change-labels-initiative',
        hotkey: labelIssueKey,
        description: 'Change labels',
        icon: 'label',
        priority: 9,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Labels,
            context: {
              entityIds: initiatives.map(e => e.id),
              orgLevel: true,
            },
          });
        },
      },
      {
        id: 'change-spaces-initiative',
        hotkey: spaceInitiativeKey,
        description: 'Change spaces',
        icon: 'workspace',
        priority: 9,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Spaces,
            context: {
              initiativeIds: initiatives.map(e => e.id),
            },
          });
        },
      },
      {
        id: 'archive-initiatives',
        hotkey: archiveIssueKey,
        description: `${archived ? 'Unarchive' : 'Archive'} initiatives`,
        icon: 'archive',
        priority: 9,
        handler: () => {
          if (archived) {
            unarchiveInitiatives(initiatives.map(i => i.id));
          } else {
            archiveInitiatives(initiatives.map(i => i.id));
          }
        },
      },
      {
        id: 'delete-initiatives',
        hotkey: deleteKey,
        description: 'Delete initiatives',
        icon: 'delete',
        priority: 9,
        handler: () => {
          deleteInitiatives(initiatives.map(i => i.id));
        },
      }
    );
    if (organization.newRoadmapsEnabled) {
      commands.push({
        id: 'change-roadmaps-initiative',
        hotkey: roadmapInitiativeKey,
        description: 'Change roadmaps',
        icon: 'roadmap',
        priority: 9,
        handler: () => {
          modals.openModal(Modals.CommandMenu, {
            view: CommandMenuView.Roadmaps,
            context: {
              initiativeIds: initiatives.map(e => e.id),
            },
          });
        },
      });
    }

    if (columnIds.length === 1 && roadmapInitiativeIds.length && roadmapId) {
      commands.push(
        {
          id: 'change-column',
          hotkey: moveIssueKey,
          description: 'Change column',
          icon: 'roadmap',
          handler: () => {
            modals.openModal(Modals.CommandMenu, {
              view: CommandMenuView.ChangeColumn,
              context: {
                roadmapInitiativeIds: roadmapInitiativeIds,
                roadmapId,
              },
            });
          },
        },
        {
          id: `initiative-previous-column`,
          hotkey: moveIssueToPreviousStatusKey,
          description: `Move to top of previous column`,
          handler: () => {
            const prev = previousColumn(columnIds[0], allColumnIds);
            if (prev) {
              moveInitiatives(roadmapInitiativeIds, prev, { top: true });
            }
          },
        },
        {
          id: `initiative-next-column`,
          hotkey: moveIssueToNextStatusKey,
          description: `Move to top of next column`,
          handler: () => {
            const next = nextColumn(columnIds[0], allColumnIds);
            if (next) {
              moveInitiatives(roadmapInitiativeIds, next, { top: true });
            }
          },
        },
        {
          id: `initiative-previous-column-bottom`,
          hotkey: moveIssueToPreviousStatusKeyBottom,
          description: `Move to bottom of previous column`,
          handler: () => {
            const prev = previousColumn(columnIds[0], allColumnIds);
            if (prev) {
              moveInitiatives(roadmapInitiativeIds, prev);
            }
          },
        },
        {
          id: `initiative-next-column-bottom`,
          hotkey: moveIssueToNextStatusKeyBottom,
          description: `Move to top of next column`,
          handler: () => {
            const next = nextColumn(columnIds[0], allColumnIds);
            if (next) {
              moveInitiatives(roadmapInitiativeIds, next);
            }
          },
        }
      );
    }

    if (roadmapId) {
      commands.push({
        id: `initiative-remove-from-roadmap`,
        hotkey: removeItemKey,
        description: `Remove initiative${initiatives.length > 1 ? 's' : ''} from roadmap`,
        handler: () => {
          removeInitiativesFromRoadmaps(roadmapInitiativeIds, [roadmapId]);
        },
      });
    }
  }

  return commands.map(c => ({
    ...c,
    group: c.group ?? CommandGroup.Entities,
  }));
}
