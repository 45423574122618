import { CursorMarker } from '@kitemaker/react-calendar-timeline';
import { TimelineStateConsumer } from '@kitemaker/react-calendar-timeline/lib/lib/timeline/TimelineStateContext';
import moment from 'moment';
import React from 'react';
import { Item } from '../../utils/timelines';
import { Group, itemHeightRatio, itemLineHeight } from './timeline';
import styles from './timelineViewScreen.module.scss';

export function calculatePlaceholderPosition(
  items: Item[],
  group: Group,
  time: number,
  period: 'week' | 'month' | 'quarter'
) {
  let startTime = moment(time).startOf(period).valueOf();

  // Add 1 to the end time to get to the start of the next day instead of right before
  let endTime = moment(time).endOf(period).valueOf() + 1;

  const itemsInGroup = items.filter(item => item.group === group.id);
  for (const item of itemsInGroup) {
    if (time > item.start_time && time < item.end_time) {
      return { startTime: null, endTime: null };
    }

    if (Math.max(item.start_time, startTime) < Math.min(item.end_time, endTime)) {
      if (time >= item.end_time) {
        startTime = item.end_time;
      }
      if (time <= item.start_time) {
        endTime = item.start_time;
      }
    }
  }
  return { startTime, endTime };
}

export const CreateMarker = ({
  cursorRef,
  scrollRef,
  groups,
  items,
  period,
}: {
  cursorRef: any;
  scrollRef: any;
  groups: Group[];
  items: Item[];
  period: 'week' | 'month' | 'quarter';
}) => {
  const periodRef = React.useRef(period);

  React.useEffect(() => {
    periodRef.current = period;
  }, [period]);

  return (
    <TimelineStateConsumer>
      {({ getLeftOffsetFromDate }: { getLeftOffsetFromDate: (date: number) => number }) => (
        <CursorMarker>
          {({ styles: divStyles, date }: { styles: any; date: number }) => {
            const parentTop = scrollRef.current.offsetTop;
            const groupIndex = Math.floor((cursorRef.current.y - parentTop) / itemLineHeight);
            const group = groups[groupIndex];
            if (!group || group.id % 1000 != 0) {
              return <div></div>;
            }

            const { startTime, endTime } = calculatePlaceholderPosition(
              items,
              group,
              date,
              periodRef.current
            );
            if (startTime === null || endTime === null) {
              return <div></div>;
            }

            const left = getLeftOffsetFromDate(startTime);
            const right = getLeftOffsetFromDate(endTime);

            divStyles.height = `${itemLineHeight * itemHeightRatio}px`;
            const width = right - left;
            const top =
              groupIndex * itemLineHeight + (itemLineHeight * (1.0 - itemHeightRatio)) / 2;
            delete divStyles.backgroundColor;
            divStyles.left = left;
            divStyles.right = right;
            divStyles.width = width;
            divStyles.top = top;
            return <div className={styles.placeholder} style={divStyles} />;
          }}
        </CursorMarker>
      )}
    </TimelineStateConsumer>
  );
};
