import * as React from 'react';
import { Filter } from './filteredListView';
import { KeyNavigationProvider } from './keyNavigation';
import { LISTVIEW_ID, ListView } from './listView';
import menuStyles from './menu/menu.module.scss';

const defaultItems = [
  { id: '1', contents: <>Today</>, name: 'Today', aliases: ['0'], value: 1 },
  { id: '7', contents: <>7 days</>, aliases: ['week'], value: 7 },
  { id: '14', contents: <>14 days</>, aliases: ['fortnight'], value: 14 },
  { id: '30', contents: <>30 days</>, aliases: ['month'], value: 30 },
];

export function TimePicker({
  filterPlaceholder,
  filterClassName,
  onPicked,
}: {
  filterPlaceholder?: string;
  filterClassName?: string;
  onPicked: (days: number) => void;
}) {
  const [filterText, setFilterText] = React.useState('');
  const [items, setItems] = React.useState(defaultItems);

  React.useEffect(() => {
    if (filterText === '') {
      setItems(defaultItems);
      return;
    }

    const options = [];

    const [firstComponent] = filterText.split(' ');
    const filterAsNumber = Number(firstComponent);
    if (filterAsNumber && filterAsNumber > 0) {
      options.push({
        id: `${filterAsNumber}`,
        value: filterAsNumber,
        contents: <>{`${filterAsNumber} days`}</>,
        name: `${filterAsNumber} days`,
        aliases: [],
      });
      options.push({
        id: `${filterAsNumber * 7}`,
        value: filterAsNumber * 7,
        contents: <>{`${filterAsNumber} weeks`}</>,
        aliases: [],
      });
      options.push({
        id: `${filterAsNumber * 30}`,
        value: filterAsNumber * 30,
        contents: <>{`${filterAsNumber} months`}</>,
        aliases: [],
      });
    } else {
      setItems(defaultItems);
      return;
    }
    setItems(options);
  }, [filterText]);

  return (
    <KeyNavigationProvider columnIds={[LISTVIEW_ID]}>
      <Filter
        filter={filterText}
        onFilterChange={f => {
          setFilterText(f);
        }}
        placeholder={filterPlaceholder}
        className={filterClassName}
      />
      <div className={menuStyles.separator} />
      <ListView
        itemClassName={menuStyles.item}
        items={items.map(i => ({
          ...i,
          onSelected: () => {
            onPicked(i.value);
          },
        }))}
      />
    </KeyNavigationProvider>
  );
}
