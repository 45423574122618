import * as Sentry from '@sentry/browser';
import { ExtraErrorData, HttpClient } from '@sentry/integrations';
import { MINOR_VERSION, VERSION } from '../../shared/utils/version';
import { useConfiguration } from '../contexts/configurationContext';
import { checkElectronVersion } from '../electronIntegration';
import { useComponentDidMount } from '../hooks/useComponentDidMount';

export function SentryManager() {
  const { production } = useConfiguration();

  useComponentDidMount(() => {
    if (production) {
      Sentry.init({
        dsn: 'https://f1b09adb4c6f4a738d9bf5503b8af5b5@o406514.ingest.sentry.io/5274135',
        release: `monolith-frontend@v${VERSION}.${MINOR_VERSION}`,
        dist: 'web',
        tunnel: '/errors/log',
        integrations: [new HttpClient(), new ExtraErrorData()],
        beforeSend(event) {
          const filename = event?.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename ?? '';
          if (
            filename === `<anonymous>` ||
            filename.includes('/pagead/viewthroughconversion/' || filename.includes('clarity'))
          ) {
            return null;
          }
          return event;
        },
      });
      Sentry.setTag('version', `v${VERSION}.${MINOR_VERSION}`);
      // this will set another Sentry tag with the electron app version
      checkElectronVersion();
    }
  });

  return null;
}
