import moment from 'moment';
import * as React from 'react';
import { BaseNode } from 'slate';
import { Elements } from '../../../../shared/slate/types';
import { Icon } from '../../../components/new/icon';
import { KitemakerTransforms } from '../../kitemakerTransforms';
import { EditorType } from '../../types';
import { HistoryEditor } from '../history';
import { SuggestionMatcher } from './withSuggestions';

interface InsertMenuChoice {
  description: string;
  icon: string;
  aliases?: string[];
}

interface InsertMenuElementChoice extends InsertMenuChoice {
  element: Elements;
  trailingParagraph?: boolean;
}

export interface InsertMenuInlineChoice extends InsertMenuChoice {
  id: string;
  content: () => BaseNode;
}

function isInsertMenuElementChoice(choice: InsertMenuChoice): choice is InsertMenuElementChoice {
  return (choice as any).element;
}

function isInsertMenuInlineChoice(choice: InsertMenuChoice): choice is InsertMenuInlineChoice {
  return (choice as any).content;
}

function elementChoices(smartTodos?: boolean): InsertMenuElementChoice[] {
  return [
    {
      description: 'Code block',
      element: Elements.Code,
      icon: 'text_style_code',
    },
    {
      description: 'Image',
      element: Elements.Image,
      icon: 'image',
    },
    {
      description: 'Video',
      element: Elements.Video,
      icon: 'video',
    },
    {
      description: 'File',
      element: Elements.File,
      icon: 'attachment',
    },
    {
      description: 'Heading 1',
      element: Elements.Headline1,
      icon: 'h1',
      aliases: ['h1'],
    },
    {
      description: 'Heading 2',
      element: Elements.Headline2,
      icon: 'h2',
      aliases: ['h2'],
    },
    {
      description: 'Heading 3',
      element: Elements.Headline3,
      icon: 'h3',
      aliases: ['h3'],
    },
    {
      description: 'Numbered list',
      element: Elements.Numbered,
      icon: 'list_numbered',
    },
    {
      description: 'Bulleted list',
      element: Elements.Bulleted,
      icon: 'list_bulleted',
    },
    {
      description: 'Todo list',
      element: smartTodos ? Elements.SmartTodo : Elements.Todo,
      icon: 'todo_checkmark',
    },
    {
      description: 'Quote',
      element: Elements.BlockQuote,
      icon: 'quote',
    },
    {
      description: 'Divider',
      element: Elements.Line,
      icon: 'divider',
      trailingParagraph: true,
    },
    {
      description: 'Figma design',
      element: Elements.Figma,
      icon: 'figma',
    },
    {
      description: 'Loom video',
      element: Elements.Loom,
      icon: 'loom',
    },
    {
      description: 'Math expression',
      element: Elements.MathExpression,
      icon: 'math',
    },
    {
      description: 'Giphy',
      element: Elements.Giphy,
      icon: 'giphy',
    },
    {
      description: 'GitHub link',
      element: Elements.Github,
      icon: 'github',
    },
  ];
}

const snippetsChoice: InsertMenuInlineChoice = {
  id: 'snippet',
  description: 'Snippet',
  icon: 'snippet',
  content: () => ({ text: '/snippet ' }),
};

const inlineChoices: InsertMenuInlineChoice[] = [
  {
    id: 'timestamp',
    description: 'Timestamp',
    icon: 'time',
    content: () => ({ text: moment().format('MMM D, YYYY LT ') }),
  },
];

export function insertMenuSuggestionMatcher(
  editor: EditorType,
  additionalChoices?: InsertMenuInlineChoice[]
): SuggestionMatcher {
  const options = [
    ...[...elementChoices(!!editor.smartTodosEnabled)].map(choice => ({
      ...choice,
      id: choice.element,
      value: choice.element,
      suggestionAutoCompleteOnly: true,
      supportedElements: [Elements.Paragraph],
      render: function RenderInsertMenuSuggestion() {
        return (
          <div className="row">
            <Icon icon={choice.icon} className="mr8" />
            {choice.description}
          </div>
        );
      },
    })),
    ...inlineChoices,
    ...(additionalChoices ?? []),
  ];

  if (editor.snippetsEnabled) {
    options.push(snippetsChoice);
  }

  return {
    id: 'insert',
    prefix: '/',
    propertiesToSearch: ['description', 'id', 'aliases'],
    className: 'menuTiny',
    header: () => (
      <div className="headingSmall" style={{ color: 'var(--grayA9' }}>
        Insert
      </div>
    ),
    options: partialMatch => {
      if (partialMatch.includes(' ')) {
        return [];
      }
      return options.map(choice => ({
        ...choice,
        id: choice.id,
        value: choice.id,
        suggestionAutoCompleteOnly: true,
        supportedElements: [Elements.Paragraph],
        render: function RenderInsertMenuSuggestion() {
          return (
            <div className="row">
              <Icon icon={choice.icon} className="mr8" />
              {choice.description}
            </div>
          );
        },
      }));
    },
    onMatch: (editor, option, range) => {
      const choiceOption = option as unknown as InsertMenuChoice;

      if (isInsertMenuElementChoice(choiceOption)) {
        HistoryEditor.asBatch(editor, () => {
          KitemakerTransforms.setBlockElementAndSplitIfNeeded(editor, choiceOption.element, {
            at: range,
          });
          if (choiceOption.trailingParagraph) {
            KitemakerTransforms.insertNodes(editor, [
              { type: Elements.Paragraph, children: [{ text: '' }] },
            ]);
          }
        });
      } else if (isInsertMenuInlineChoice(choiceOption)) {
        HistoryEditor.asBatch(editor, () => {
          KitemakerTransforms.insertNodes(editor, choiceOption.content(), {
            at: range,
            select: true,
          });
        });
        setTimeout(() => editor.findSuggestions?.());
      }
    },
  };
}
