import cn from 'classnames';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Doc } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { ArchivedNotice } from '../../components/archivedNotice';
import { CollaborativeDocEditor } from '../../components/collaborativeDocEditor';
import { DescriptionHistory } from '../../components/descriptionHistory';
import { DescriptionPlaceholder, PlaceholderType } from '../../components/descriptionPlaceholder';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { ActivitiesTab } from '../../components/new/activities/activities';
import BackButton from '../../components/new/backButton';
import { Breadcrumbs, TitleBreadcrumb } from '../../components/new/breadcrumbs';
import { ButtonStyle, IconButton } from '../../components/new/button';
import { ButtonGroup } from '../../components/new/buttonGroup';
import { CommandContext } from '../../components/new/commandMenuContext';
import { NEW_COMMENT_ID } from '../../components/new/comments';
import {
  useCopyEntityLinksToClipboard,
  useCopyEntityNumbersToClipboard,
} from '../../components/new/copyAndPaste';
import { CustomCommand } from '../../components/new/customCommand';
import { DocumentMenuButton } from '../../components/new/documentMenu';
import { EntityInsights, InsightsTab } from '../../components/new/entityMetadata';
import {
  ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS,
  EntityScreenKeyNavHacks,
} from '../../components/new/entityScreenKeyNavHacks';
import {
  QueryParameterManager,
  useEntityTabs,
  useInitialTab,
} from '../../components/new/entityTabs';
import { EntityTitleEditor } from '../../components/new/entityTitleEditor';
import { Hotkey } from '../../components/new/hotkey';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  FocusReason,
  KeyNavigationProvider,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useSetKeyNavigationFocus,
} from '../../components/new/keyNavigation';
import { MetadataSize } from '../../components/new/metadata/size';
import { NotificationsIndicator } from '../../components/new/notifications';
import { ScreenHeader } from '../../components/new/screenHeader';
import { Tab, Tabs, TabStyle } from '../../components/new/tabs';
import { TodoList } from '../../components/new/todoList';
import { Tooltip } from '../../components/new/tooltip';
import {
  RightBar,
  RightSidebarButton,
  rightSidebarCollapsed,
  RightSidebarGroup,
} from '../../components/rightBar';
import { Screen } from '../../components/screen';
import TitleSetter from '../../components/titleSetter';
import { useOrganization } from '../../contexts/organizationContext';
import { ResponsiveDesignSize, useIsMediumScreen } from '../../hooks/useResponsiveDesign';
import useSaveScroll from '../../hooks/useSaveScroll';
import { useNextPreviousSiblings } from '../../hooks/useSiblingEntities';
import { TextAreaHandle } from '../../slate/textArea';
import { useDeleteDocumentsContext, useUpdateDocuments } from '../../syncEngine/actions/documents';
import { collaborativeDocIdByEntitySelector } from '../../syncEngine/selectors/collaborativeDoc';
import { documentSelector } from '../../syncEngine/selectors/documents';
import {
  breadcrumbsForEntitySelector,
  entityArchivedSelector,
  useUpdateRecents,
} from '../../syncEngine/selectors/entities';
import { organizationPath } from '../../syncEngine/selectors/organizations';
import { trackerPageLoad } from '../../tracker';
import { copyIssueLinkKey, copyIssueNumberKey, vimDown, vimUp } from '../../utils/config';
import { LocationState } from '../../utils/history';
import { HistoryCheckpoint } from '../../utils/revisions';
import { StarredType } from '../../utils/starred';
import styles from './documentScreen.module.scss';

function Context({ docId: docId }: { docId: string }) {
  const organization = useOrganization();
  const defaultPath = organizationPath(organization, 'documents');
  const history = useHistory();
  const { previousEntity, nextEntity } = useNextPreviousSiblings(docId);
  useDeleteDocumentsContext({
    onDelete: () => {
      if (previousEntity()) {
        return;
      }

      if (nextEntity()) {
        return;
      }

      history.push(defaultPath);
    },
  });

  return (
    <CommandContext
      context={{ group: CommandGroup.Entities, entityIds: [docId], focusedEntityId: docId }}
    />
  );
}

function LeftHeader({ doc: doc }: { doc: Doc }) {
  const organization = useOrganization();
  const location = useLocation<LocationState>();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(doc.id));
  const defaultPath = organizationPath(organization, 'documents');

  const copyEntityNumber = useCopyEntityNumbersToClipboard();
  const copyEntityLink = useCopyEntityLinksToClipboard();
  const { previousEntity, nextEntity, totalCount, position } = useNextPreviousSiblings(doc.id);
  const sidebarCollapsed = useRecoilValue(rightSidebarCollapsed(RightSidebarGroup.Documents));

  if (!entityBreadcrumbs) {
    return null;
  }

  const rightSideContent = (
    <div className="row metadataGapL">
      <Tooltip
        content={
          <>
            {`Copy number`}
            <KeyboardShortcut shortcut={copyIssueNumberKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="copy"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            copyEntityNumber([doc.id]);
          }}
        />
      </Tooltip>
      <Tooltip
        content={
          <>
            {`Copy link`}
            <KeyboardShortcut shortcut={copyIssueLinkKey} />
          </>
        }
      >
        <IconButton
          buttonStyle={ButtonStyle.BareSubtle}
          icon="link"
          onClick={e => {
            e?.preventDefault();
            e?.stopPropagation();
            copyEntityLink([doc.id]);
          }}
        />
      </Tooltip>
      {sidebarCollapsed && (
        <>
          <RightSidebarButton group={RightSidebarGroup.Documents} />
          <NotificationsIndicator />
        </>
      )}
    </div>
  );

  return (
    <ScreenHeader
      className={styles.header}
      hideNotifications
      compensateForMacOSTrafficLights="always"
      rightSideContent={rightSideContent}
    >
      <div className="row headerGap ellipsis pb4 pt4">
        <BackButton defaultPath={defaultPath} />
        <div className="row metadataGapL">
          <ButtonGroup>
            <Tooltip
              content={
                <>
                  {`Previous document`} <KeyboardShortcut shortcut={vimUp} />
                </>
              }
            >
              <IconButton disabled={position === 0} icon={'arrow_up'} onClick={previousEntity} />
            </Tooltip>
            <Tooltip
              content={
                <>
                  {`Next document`} <KeyboardShortcut shortcut={vimDown} />
                </>
              }
            >
              <IconButton
                disabled={position === totalCount - 1}
                icon={'arrow_down'}
                onClick={nextEntity}
              />
            </Tooltip>
          </ButtonGroup>

          <CustomCommand
            command={{
              id: 'previous-doc',
              icon: 'arrow_up',
              description: `Up`,
              aliases: ['navigate', 'move to', 'go to'],
              handler: () => previousEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimUp,
              priority: 20,
            }}
          />

          <CustomCommand
            command={{
              id: 'next-doc',
              icon: 'arrow_down',
              description: `Down`,
              aliases: ['navigate', 'move to', 'go to'],
              handler: () => nextEntity(),
              group: CommandGroup.Navigation,
              hotkey: vimDown,
              priority: 20,
            }}
          />

          <div className={styles.count}>
            {position + 1}
            <span className="mr2 ml2">/</span>
            {totalCount}
          </div>
        </div>
        <Breadcrumbs
          breadcrumbs={[
            ...(location.state?.breadcrumbs ?? entityBreadcrumbs),
            {
              name: <TitleBreadcrumb number={`D${doc.number}`} title={doc.title} />,
            },
          ]}
          starred={{
            type: StarredType.Document,
            id: doc.id,
          }}
        />
      </div>
    </ScreenHeader>
  );
}

function RightHeader({
  tabs,
  currentTab,
  onTabChanged,
}: {
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  return (
    <ScreenHeader className={styles.header} collapsibleGroup={RightSidebarGroup.Documents}>
      <div className="row headerGap">
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </ScreenHeader>
  );
}

function SmallScreenHeader({
  doc,
  tabs,
  currentTab,
  onTabChanged,
}: {
  doc: Doc;
  tabs: Tab[];
  currentTab: string;
  onTabChanged: (tab: string) => void;
}) {
  const organization = useOrganization();
  const entityBreadcrumbs = useRecoilValue(breadcrumbsForEntitySelector(doc.id));
  const defaultPath = organizationPath(organization, 'documents');
  const location = useLocation<LocationState>();

  if (!entityBreadcrumbs) {
    return null;
  }

  return (
    <>
      <ScreenHeader className={styles.header} hideNotifications>
        <BackButton defaultPath={defaultPath} className="mr8" />
        <Breadcrumbs
          breadcrumbs={[
            ...(location.state?.breadcrumbs ?? entityBreadcrumbs),
            { name: `D${doc.number}` },
          ]}
        />
      </ScreenHeader>
      <div className={styles.smallScreenHeader}>
        <Tabs
          tabs={tabs}
          tabStyle={TabStyle.Subtle}
          currentTab={currentTab}
          onTabChanged={onTabChanged}
        />
      </div>
    </>
  );
}

function Title({ docId: docId }: { docId: string }) {
  const updateDocuments = useUpdateDocuments();
  const doc = useRecoilValue(documentSelector(docId));
  const [editing, setEditing] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  const editTitle = React.useCallback(() => {
    updateDocuments([docId], { title });
    setTitle('');
    setEditing(false);
  }, [title, setTitle, setEditing, updateDocuments, docId]);

  if (!doc) {
    return null;
  }

  if (!editing) {
    return (
      <div
        className="heading2XL bold fullWidth fs-exclude wrap"
        onMouseDown={() => {
          setTitle(doc.title);
          setEditing(true);
        }}
      >
        <CustomCommand
          command={{
            id: 'edit-title',
            group: CommandGroup.Entities,
            description: 'Edit title',
            hotkey: 't',
            priority: 99,
            handler: () => {
              setTitle(doc.title);
              setEditing(true);
            },
          }}
        />
        {doc.title || 'Untitled document'}
      </div>
    );
  }

  return (
    <div className="row relative fullWidth fs-exclude">
      <EntityTitleEditor
        className="heading2XL bold noShrink"
        initialTitle={doc.title}
        onChange={v => {
          setTitle(v);
        }}
        autoFocus
        onSubmit={editTitle}
        onFocus={() => {
          disableKeyNav('title');
        }}
        onBlur={() => {
          enableKeyNav('title');
          editTitle();
        }}
        placeholder="Give your document a title"
      />
    </div>
  );
}

function Description({ docId }: { docId: string }) {
  const archived = useRecoilValue(entityArchivedSelector(docId));
  const [descriptionDisabled, setDescriptionDisabled] = React.useState(false);
  const [historyDescription, setHistoryDescription] = React.useState<HistoryCheckpoint | null>(
    null
  );
  const ref = React.useRef<TextAreaHandle>(null);
  const documentId = useRecoilValue(collaborativeDocIdByEntitySelector(docId));

  return (
    <>
      <CustomCommand
        command={{
          id: 'edit-description',
          group: CommandGroup.Entities,
          description: 'Edit description',
          hotkey: 'd',
          priority: 99,
          handler: () => {
            ref.current?.focus();
            ref.current?.moveSelectionToEnd();
            ref.current?.ensureBottomMarginVisible();
          },
        }}
      />
      <CollaborativeDocEditor
        key={`description-${docId}`}
        className={cn(styles.description, 'fs-exclude')}
        documentId={documentId ?? ''}
        entityId={docId}
        inlineComments
        historyDescription={historyDescription}
        textAreaRef={ref}
        disabled={descriptionDisabled || archived}
        placeholder={<DescriptionPlaceholder type={PlaceholderType.Document} />}
      />

      <HideIfSmallerThan size={ResponsiveDesignSize.Medium}>
        <DescriptionHistory
          documentId={documentId ?? ''}
          onDescriptionChanged={d => {
            setHistoryDescription(d);
          }}
          onRestore={d => {
            setHistoryDescription(null);
            setTimeout(() => ref.current?.replaceValue(d.content));
          }}
          onOpen={() => {
            setDescriptionDisabled(true);
          }}
          onClose={() => {
            setDescriptionDisabled(false);
          }}
        />
      </HideIfSmallerThan>
    </>
  );
}

function MainScrollArea({ children, docId: docId }: { children: React.ReactNode; docId: string }) {
  const ref = React.useRef<HTMLDivElement>(null);
  useKeyNavigationColumn(`left-${docId}`, [`main-${docId}`]);
  const selected = useHasKeyNavigationFocus(`main-${docId}`);
  const setFocus = useSetKeyNavigationFocus();

  useSaveScroll(docId, ref);

  React.useEffect(() => {
    if (selected && document.activeElement === document.body) {
      ref.current?.focus();
    }
  }, [selected]);

  React.useEffect(() => {
    setFocus(`main-${docId}`);
  }, [docId]);

  return (
    <div className="rowStretch grow overflowScrollY fullWidth" ref={ref} tabIndex={-1}>
      {children}
      {selected && (
        <Hotkey
          hotkey="right"
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            setFocus(NEW_COMMENT_ID, FocusReason.Keyboard);
          }}
        />
      )}
    </div>
  );
}

function DocumentScreen({ docId }: { docId: string }) {
  const doc = useRecoilValue(documentSelector(docId));
  const organization = useOrganization();
  const mediumScreen = useIsMediumScreen();
  const updateDocuments = useUpdateDocuments();

  const [openCommentThreadId, setOpenCommentThreadId] = React.useState<string | null>(null);
  const initialTab = useInitialTab();
  const [currentTab, setCurrentTab] = React.useState(initialTab);
  const tabs = useEntityTabs(docId);

  useUpdateRecents(docId);

  React.useEffect(() => {
    trackerPageLoad('Document');
  }, [docId]);

  if (!doc || !organization) {
    return null;
  }

  return (
    <KeyNavigationProvider
      columnIds={[`left-${docId}`, ...ENTITY_SCREEN_KEY_NAV_HACKS_COLUMN_IDS, `right-${docId}`]}
    >
      <Screen hideSidebar row={!mediumScreen} className={styles.documentScreen}>
        <TitleSetter title={`${organization.name} · ${doc.title}`} />
        <Context docId={docId} />
        {mediumScreen && (
          <SmallScreenHeader
            tabs={tabs}
            currentTab={currentTab}
            onTabChanged={setCurrentTab}
            doc={doc}
          />
        )}
        <EntityScreenKeyNavHacks />

        {(!mediumScreen || currentTab === 'description') && (
          <div className={cn(styles.left)}>
            {!mediumScreen && <LeftHeader doc={doc} />}
            {doc.archivedAt && (
              <ArchivedNotice
                className="fullWidth"
                type="document"
                onUnarchive={() => updateDocuments([doc.id], { archivedAt: null })}
              />
            )}

            <MainScrollArea docId={docId} key={docId}>
              <div className={styles.gutter} />
              <div className={styles.content}>
                <div className={cn('col', 'noGrow', styles.contentHeader)}>
                  <div className="row rowBetween fullWidth hideWhilePrinting">
                    <div className={cn('row', 'flexWrap', styles.metadataGap)}></div>
                    <div className="row metadataGapL noShrink flexAlignStart">
                      <DocumentMenuButton doc={doc} buttonStyle={ButtonStyle.Bare} />
                    </div>
                  </div>
                  <Title docId={docId} key={`title-${docId}`} />
                  <div className="row metadataGapL maxWidth flexWrap hideWhilePrinting">
                    <EntityInsights interactable size={MetadataSize.Medium} entity={doc} />
                  </div>
                </div>
                <Description docId={docId} key={`description-${docId}`} />
              </div>
              <div className={styles.gutter} />
            </MainScrollArea>
          </div>
        )}
        {(currentTab === 'activities' || currentTab === 'insights' || currentTab === 'todos') && (
          <RightBar className={styles.right} collapsibleGroup={RightSidebarGroup.Documents}>
            {!mediumScreen && (
              <RightHeader tabs={tabs} currentTab={currentTab} onTabChanged={setCurrentTab} />
            )}
            {currentTab === 'activities' && (
              <ActivitiesTab entityId={docId} openCommentThreadId={openCommentThreadId} />
            )}
            {currentTab === 'insights' && <InsightsTab entityId={docId} entityType="document" />}
            {currentTab === 'todos' && (
              <TodoList
                entityId={docId}
                className={styles.todoTab}
                keyNavColumnId={`right-${docId}`}
              />
            )}
          </RightBar>
        )}
        <QueryParameterManager
          onOpenThreadIdChanged={threadId => {
            if (threadId && threadId !== openCommentThreadId) {
              setCurrentTab('activities');
            }
            setOpenCommentThreadId(threadId);
          }}
          onCommentOrActivityIdChanged={commentOrActivityId => {
            if (commentOrActivityId) {
              setCurrentTab('activities');
            }
          }}
          onInsightIdChanged={insightId => {
            if (insightId) {
              setCurrentTab('insights');
            }
          }}
        />
      </Screen>
    </KeyNavigationProvider>
  );
}

export default React.memo(DocumentScreen);
